<template>
  <v-card class="copy-existing-inbound">
    <v-card-title class="text-body-1 font-weight-medium my-2 px-4">Copy existing inbound</v-card-title>
    <v-card-text class="px-0 pr-3">
      <v-overlay absolute color="white" :value="!data">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <v-row>
        <data-tables
          style="width: 100%"
          :data="data"
          :page-size="5"
          :pagination-props="{class: 'd-none'}"
          :table-props="{ 'row-key': row => row.inbound_key }"
          @row-click="handleRowClick">
          <el-table-column prop="timeline_key" width="55">
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-4 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            key="inbound_key"
            prop="inbound_key"
            column-key="inbound_key"
            label="Inbound"
          >
            <template slot-scope="scope">
              {{ scope.row.inbound_key }}
            </template>
          </el-table-column>
          <el-table-column
            key="date_key"
            prop="date_key"
            column-key="date_key"
            label="Date"
          >
            <template slot-scope="scope">
              {{ scope.row.date_key }}
            </template>
          </el-table-column>
          <el-table-column
            key="repeat"
            prop="repeat"
            column-key="repeat"
            label="Repeat"
          >
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                disabled
                class="mt-0 ml-4 pt-0"
                :value="scope.row.repeat"
                :ripple="false"
                @input="scope.row.repeat = $event"/>
            </template>
          </el-table-column>
          <el-table-column
            key="pieces_total"
            prop="pieces_total"
            column-key="pieces_total"
            label="Pieces"
          >
            <template slot-scope="scope">
              {{ scope.row.pieces_total }}
            </template>
          </el-table-column>
          <el-table-column
            key="prepacks"
            prop="prepacks"
            column-key="prepacks"
            label="Prepacks"
          >
            <template slot-scope="scope">
              {{ scope.row.prepacks }}
            </template>
          </el-table-column>
          <el-table-column
            key="info"
            width="50"
          >
            <template slot-scope="scope">
              <v-menu
                top
                open-on-hover
                offset-x
                content-class="option-info-menu"
                :open-delay="200"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :disabled="!getPiecesHTML(scope.row.pieces)"
                    class="mr-3 icon-has-action c-pointer"
                    v-bind="attrs"
                    v-on="on"
                  >info</v-icon>
                </template>
                <v-card
                  class="py-2 px-4 p-relative"
                  v-html="getPiecesHTML(scope.row.pieces)"
                />
              </v-menu>
            </template>
          </el-table-column>
        </data-tables>
      </v-row>
      <v-row class="mt-6 pl-4">
        <v-col cols="4">
          <v-menu
            transition="scale-transition"
            offset-y
            class="mx-2"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="pt-0 mt-0 "
                hide-details="auto"
                label="Order Date"
                :value="selectedRow.order_date_key"
                v-on="on"
              />
            </template>
            <v-date-picker
              :first-day-of-week="1"
              :locale-first-day-of-year="4"
              :show-week="true"
              no-title
              v-model="selectedRow.order_date_key"
            />
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-menu
            transition="scale-transition"
            offset-y
            class="mx-2"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="pt-0 mt-0"
                hide-details="auto"
                label="Shop Date"
                :value="selectedRow.date_key"
                v-on="on"
              />
            </template>
            <v-date-picker
              :first-day-of-week="1"
              :locale-first-day-of-year="4"
              :show-week="true"
              no-title
              v-model="selectedRow.date_key"
            />
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-4">
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="onClose">Cancel</v-btn>
      <v-btn color="blue darken-1" :disabled="!isRowSelected" outlined @click="onAdd">Add</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { copyExistingInbound } from '@/api/shop'
import { formatString } from '@/variables'

export default {
  name: 'CopyExistingInbound',
  emits: ['close', 'saved'],
  props: {
    optionKey: {
      type: String,
      required: true
    },
    shopItem: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    selectedRows () {
      return this.data.filter(r => r.selected)
    },
    isRowSelected () {
      return this.selectedRows.length > 0
    },
    selectedRow () {
      return this.selectedRows.length ? this.selectedRows[0] : {}
    },
  },
  methods: {
    getPiecesHTML (pieces) {
      if (!pieces) {
        return ''
      }
      let dataString = ''
      for (const key of Object.keys(pieces).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))) {
        const val =
          '<b>' + formatString(key) + '</b>: ' + (pieces[key] ? pieces[key].toLocaleString() : '')
        dataString += val + '<br>'
      }
      return dataString
    },
    onClose () {
      this.$emit('close')
    },
    async onAdd () {
      try {
        const payload = this.selectedRows.map((selectedRow) => ({
          shop_date_key: selectedRow.date_key,
          order_date_key: selectedRow.order_date_key,
          store_key: selectedRow.store_key,
          inbound_key: selectedRow.inbound_key,
          option_key: this.optionKey,
          schedule_key: this.shopItem.schedule_key,
          payment_key: this.shopItem.payment_key,
          scenario_key: this.shopItem.scenario_key,
          shop_item_key: this.shopItem.shop_item_key,
          budget_key: this.shopItem.budget_key,
          supplier_key: this.shopItem.supplier_key,
        }))
        await copyExistingInbound(payload)
        this.$emit('saved')
        this.$emit('close')
      } catch (e) {
        console.error(e)
      }
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      const shopDate = new Date(row.date_key)
      shopDate.setDate(shopDate.getDate() - this.shopItem.leadtime || 0)
      row.order_date_key = shopDate.toISOString().slice(0, 10)
      this.$set(row, 'selected', val)
    }
  },
}
</script>

<style scoped>

</style>
