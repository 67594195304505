<template>
  <div class="strategy-dimensions-tabs mx-n6">
    <v-tabs
      v-model="activeTab"
      show-arrows
      @change="tableFilters = [{ value: '' }]"
    >
      <v-tabs-slider />
      <v-tab
        v-for="(tab, i) in tabs"
        :key="getTabId(tab, i)"
        :href="'#' + getTabId(tab, i)"
        class="px-8"
        style="width: 180px"
      >
        <template v-if="tab.isCustom">
          <v-text-field
            v-model="tab.name"
            hide-details="auto"
            @keyup.stop
            @blur="$emit('tabs-changed')"
          />
        </template>
        <template v-else>
          {{ (tabsTitles[tab.name] || tab.name) | formatString }}
        </template>
      </v-tab>
      <v-tab
        v-if="dimensions.length"
        class="px-8"
        key="newCustomTab"
        href="#newCustomTab"
        @click="handleAddCustomTabClick"
      >
        <v-icon>add</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="activeTab"
      style="width: 100%"
    >
      <v-tab-item
        v-for="(tab, i) in tabs"
        :key="getTabId(tab, i)"
        :value="getTabId(tab, i)"
        :transition="false"
        :reverse-transition="false"
      >
        <v-row v-if="tab.isCustom">
          <v-col cols="3" class="pt-8 pb-4 px-8">
            <collapsible-select
              v-model="tab.dimensions"
              label="Dimension"
              autocomplete
              clearable
              multiple
              :filter="headersFilters"
              hide-details="auto"
              class="pt-0"
              :items="getDimensionsItems(tab)"
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu'
              }"
              @change="customDimensionsChanged = true"
              @blur="handleCustomDimensionsChanged(i)"
            />
          </v-col>
          <v-col cols="3" class="pt-8 pb-4 px-8">
            <collapsible-select
              v-model="tab.dimensionsChilds"
              label="Childs"
              clearable
              autocomplete
              multiple
              hide-details="auto"
              class="pt-0"
              :items="getDimensionsChildItems(tab)"
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu'
              }"
              @change="customDimensionsChildsChanged = true"
              @blur="handleCustomDimensionChildsChanged(i)"
            />
          </v-col>
          <v-col cols="6" class="pt-8 pb-4 px-8 text-right">
            <v-icon @click="handleDeleteCustomTabClick(tab)">delete</v-icon>
          </v-col>
        </v-row>
        <v-row class="my-4" justify="center">
          <v-btn-toggle
            v-model="currentMetric"
            mandatory
            dense
            active-class="bg-gray"
            light
            @change="handleMetricChange"
          >
            <v-btn
              v-for="(metric, index) in metrics"
              :key="index"
              small
              color="white"
            >
              <span class="text-capitalize">{{ metric | formatString }}</span>
            </v-btn>
          </v-btn-toggle>
        </v-row>
        <div v-if="metrics.length">
          <ChartElement
            v-if="views[i] && views[i].data.length < 500"
            :ref="'chart-' + getTabId(tab, i)"
            class="pr-4"
            title="strategy_cube_home_chart"
            :id="`strategy_cube_home_charts_${getTabId(tab, i)}`"
            :chartData="chartData[i]"
            :params="{}"
            :forcedHeight="'360px'"
            :padding="false"
            :fixedHeight="false"
            :avoidRedraw="true"
            @chart-click="data => handleChartClicked(data, i, tab)"
          />
        </div>
        <StrategyDimensionMetricsTable
          v-if="views[i]"
          class="pt-4"
          :ref="'dimensionMetricsTable-' + getTabId(tab, i)"
          :is-custom-dimension="tab.isCustom"
          :metrics-data="views[i]"
          :dimensions="tab.dimensions"
          :child-dimensions="tab.dimensionsChilds"
          :store-keys-to-names="storeKeysToNames"
          @cell-click="cellData => handleCellClick({ ...cellData, tab })"
          @cell-value-change="cellData => handleCellChange({ ...cellData, tab })"
          @drilldown-click="rows => handleDrilldownClick(tab, rows)"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import StrategyDimensionMetricsTable from '@/components/Strategy/StrategyDimensionMetricsTable.vue'
import ChartElement from '@/chart/ChartElement.vue'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import { formatString } from '@/variables'
import {headersFilters} from '@/utils'

export default {
  name: 'StrategyDimensionsTabs',
  components: { StrategyDimensionMetricsTable, ChartElement, CollapsibleSelect },
  props: {
    dimensions: {
      type: Array,
      default: () => [],
    },
    savedTabs: {
      type: Array,
      default: () => [],
    },
    metrics: {
      type: Array,
      default: () => [],
    },
    views: {
      type: Array,
      default: () => [],
    },
    cubeProperties: {
      type: Array,
      default: () => [],
    },
    storeKeysToNames: {
      type: Map,
      default: () => new Map(),
    },
    noStores: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      activeTab: null,
      currentMetric: 0,
      tabsTitles: {
        store_keys: 'Stores',
        week_numbers: 'Weeks',
      },
      tableFilters: [{ value: '' }],
      customTabs: this.savedTabs.slice(),
      customDimensionsChanged: false,
      customDimensionsChildsChanged: false,
    }
  },
  computed: {
    tabs () {
      return [
        ...this.dimensions,
        ...this.customTabs,
      ]
    },
    metricName () {
      return this.metrics[this.currentMetric]
    },
    chartData () {
      return this.tabs.map((tab, i) => this.getChart(i, tab.isCustom ? 'custom' : tab.name))
    },
    cubePropertiesGrouped () {
      const result = []
      const propertiesSorted = this.cubeProperties.slice().sort()
      let lastHeader = null
      let lastHeaderObject = {}
      for (const prop of propertiesSorted) {
        const parts = prop.split('/')

        if (!lastHeader || parts[0] !== lastHeader) {
          lastHeaderObject = {
            header: formatString(parts[0]),
            text: [formatString(parts[0])]
          }
          result.push(lastHeaderObject)

          lastHeader = parts[0]
        }
        lastHeaderObject.text.push(formatString(parts[1]))
        result.push({
          value: prop,
          text: formatString(parts[1]),
          headerTitle: lastHeader
        })
      }
      return result
    },
  },
  watch: {
    dimensions () {
      setTimeout(() => {
        const newTab = this.tabs[0]
        this.activeTab = this.getTabId(newTab, 0)
      }, 50)
    },
    'metrics.length' () {
      if (!this.currentMetric) {
        this.currentMetric = 0
      }
    },
    savedTabs () {
      this.customTabs = this.savedTabs.slice()
    },
    activeTab () {
      this.updateTotalTableWidth()
    },
    views () {
      this.updateTotalTableWidth()
    },
  },
  methods: {
    headersFilters,
    handleDrilldownClick (tab, rows) {
      const dimension = tab.isCustom ? tab.dimensions[0] : tab.name

      this.$emit('drilldown-click', {
        rows,
        dimension,
      })
    },
    handleChartClicked (clickData, chartIndex, tab) {
      let pointDataValue

      if (tab.name === 'store_keys') {
        pointDataValue = [...this.storeKeysToNames][clickData.point?.index][0]
      } else {
        pointDataValue = this.chartData[chartIndex].labels[clickData.point?.index]
      }

      this.$emit('chart-click', {
        activeTab: tab,
        clickData,
        pointDataValue,
        chartIndex,
      })
    },
    handleMetricChange () {
      if (this.metrics.length) {
        this.$refs['chart-' + this.activeTab][0].drawGraph()
      }
    },
    handleAddCustomTabClick () {
      this.customTabs.push({
        name: 'Custom',
        isCustom: true,
        dimensions: [],
        dimensionsChilds: [],
      })

      setTimeout(() => {
        const newTab = this.customTabs.slice(-1)[0]
        this.activeTab = this.getTabId(newTab, this.tabs.length - 1)
      }, 50)

      this.$emit('tabs-changed')
    },
    handleDeleteCustomTabClick (tabToDelete) {
      this.customTabs = this.customTabs.filter(tab => tab !== tabToDelete)
      this.$emit('tabs-changed')
    },
    async handleCustomDimensionsChanged () {
      if (this.customDimensionsChanged) {
        this.$emit('custom-dimension-change')
        this.customDimensionsChanged = false
      }
    },
    async handleCustomDimensionChildsChanged () {
      if (this.customDimensionsChildsChanged) {
        this.$emit('custom-dimension-childs-change')
        this.customDimensionsChildsChanged = false
      }
    },
    handleCellClick ({ row, column, isTotalRow, tab }) {
      if (!column.property) {
        return
      }

      const propNameParts = column.property.split(' ')

      if (propNameParts.length === 2) {
        this.$emit('cell-click', {
          row,
          column,
          activeTab: tab,
          isTotalRow,
        })
      }
    },
    handleCellChange ({ row, column, isTotalRow, tab }) {
      if (!column.property) {
        return
      }

      const propNameParts = column.property.split(' ')

      if (propNameParts.length === 2) {
        this.$emit('cell-change', {
          row,
          column,
          activeTab: tab,
          isTotalRow,
        })
      }
    },
    getDimensionsItems (tab) {
      return this.cubePropertiesGrouped.map(p => ({
        ...p,
        disabled: (
          tab.dimensions.length >= 3 && !tab.dimensions.includes(p.value)
        ) || (
          this.noStores && p.value?.startsWith('store_keys/')
        )
      }))
    },
    getDimensionsChildItems (tab) {
      return this.cubePropertiesGrouped.map(p => ({
        ...p,
        disabled: (
          tab.dimensionsChilds.length >= 3 && !tab.dimensionsChilds.includes(p.value)
        ) || (
          this.noStores && p.value?.startsWith('store_keys/')
        )
      }))
    },
    updateTotalTableWidth () {
      const refName = `dimensionMetricsTable-${this.activeTab}`

      if (this.$refs[refName] && this.$refs[refName][0]) {
        this.$refs[refName][0].updateTotalTableWidth()
      }
    },
    getTabId (tab, index) {
      return tab.isCustom ? `custom-${index}` : tab.name
    },
    getChart (i, dimension) {
      const view = this.getView(i)

      let labels = view.data.map(item => {
        if (this.tabs[i].isCustom) {
          return this.tabs[i].dimensions.map(d => item[d]).join(', ')
        } else {
          return item[view.columns[0]]
        }
      })
      if (view.columns.length && view.columns[0] === 'store_keys') {
        labels = labels.map(k => this.storeKeysToNames.get(k))
      }
      return {
        data: view.data,
        dimension,
        metric: this.metricName,
        labels: labels,
      }
    },
    getView (index) {
      const view = this.views[index]
      if (this.views.length === 0 || !view) {
        return { data: [], columns: [] }
      }

      return view
    },
  },
}
</script>

<style lang="scss">
.strategy-dimensions-tabs {
    .v-tab {
        .v-input {
            padding-top: 0;
            input {
                text-transform: uppercase;
                text-align: center;
                color: rgba(0, 0, 0, 0.54);
                cursor: pointer;
                font-size: 14px;
                padding-bottom: 11px;
            }
            .v-input__control > .v-input__slot:before {
                border-color: #fff !important;
            }
        }
    }
    .el-table__append-wrapper {
        position: sticky;
        bottom: 0;
    }
    .table .el-table__body-wrapper {
        max-height: 50vh;
        overflow-y: auto;
    }
    .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
        width: 4px;
    }
    .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    }
    .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 3px;
    }
}
</style>
