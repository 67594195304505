<template>
  <div class="sidebar">
    <side-navigation-drawer v-bind:items="items" ref="drawer" />
    <div class="sidebar-wrapper d-none d-md-block ">
      <div class="sidebar-items">
        <router-link :to="{ name: item.route }"
          v-for="(item, idx) in items"
          :key="idx"
          style="text-decoration: none; color: inherit;">
          <div
            class="sidebar-item"
            :class="{ 'item-active': itemActive(item) }"
          >
            <v-icon class="sidebar-icon"> {{ item.icon }} </v-icon>
          </div>

        </router-link>
        <div class="drawer-open-btn" @click="clickDrawer">
          <v-icon>
            chevron_right
          </v-icon>
        </div>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
import SideNavigationDrawer from '@/components/SideNavigationDrawer.vue'

export default {
  name: 'StockSidebar',
  data () {
    return {
      drawer: false,
      items: [
        {
          name: 'Home',
          icon: 'mdi-home',
          route: 'stock-home-page'
        },
        {
          name: 'Operate',
          icon: 'mdi-wrench',
          route: 'stock-operate'
        },
        {
          name: 'Transfers',
          icon: 'swap_calls',
          route: 'stock-transfers'
        },
        {
          name: 'Consolidate',
          icon: 'mdi-table-merge-cells',
          route: 'stock-consolidate'
        },
        {
          name: 'Manage',
          icon: 'mdi-shape',
          route: 'stock-manage'
        },
        {
          name: 'Analytics',
          icon: 'mdi-chart-box',
          route: 'stock-analytics'
        },
        {
          name: 'Settings',
          icon: 'mdi-cog',
          route: 'stock-settings-main'
        },
      ],
    }
  },
  methods: {
    itemActive (item) {
      if (item.name === 'Settings') {
        if (this.$route.path.split('/')[1] === 'settings') {
          return true
        }
      }
      return this.$route.name === item.route
    },
    clickDrawer () {
      this.$refs.drawer.clickDrawer()
    }

  },
  components: {
    SideNavigationDrawer
  }
}
</script>

<style lang="scss">
.sidebar {
    .title-active {
        color: var(--primary) !important;
        a {
            i {
                color: var(--primary) !important;
            }
        }
        path {
          fill: var(--primary) !important;
        }
    }
}
.sidebar {
        path {
          fill: rgba(0,0,0,0.58) !important;
        }
}
</style>
