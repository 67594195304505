import {defineStore} from 'pinia'
import {GetCubeInputData, GetCubesBrowser, GetStrategyCubes} from '@/api/cube.js'
import {Cube, CubeInputData, CubesBrowser} from '@/models/Cubes'
import {Optional} from '@/models/TypeHelpers'

interface CubeInputsPayload {
  cubeKey: string;
  versionKey: string;
  forceReload: boolean;
}

export const useCubesStore = defineStore('cubes', {
  state: () => ({
    cubes: null as Optional<Cube[]>,
    cubesBrowser: null as Optional<CubesBrowser>,
    cubeInputData: null as Optional<CubeInputData>,
  }),
  getters: {
    metricFormat: (state) => state.cubesBrowser?.metric_format,
    cubeKeysToNames: (state): Map<string, string> => {
      if (!state.cubesBrowser?.cube_keys || !state.cubes) {
        return new Map()
      }
      const m = new Map<string, string>()
      for (const key of state.cubesBrowser.cube_keys) {
        const keyNum = Number(key)
        const cube = state.cubes.find(d => Number(d.cube_key) === keyNum)
        m.set(key, cube?.cube_name || key)
      }
      return m
    }
  },
  actions: {
    $reset () {
      this.cubes = null
      this.cubesBrowser = null
      this.cubeInputData = null
    },
    async loadCubes () {
      try {
        this.cubes = await GetStrategyCubes()
      } catch (e) {
        console.error(e)
      }
    },
    async loadCubesBrowser () {
      try {
        this.cubesBrowser = await GetCubesBrowser()
      } catch (e) {
        console.error(e)
      }
    },
    async getAndLoadCubeInputs (payload: CubeInputsPayload) {
      try {
        const cubeBrowserData = JSON.parse(localStorage.getItem('cubeBrowserData') || 'null')
        if (cubeBrowserData && !payload.forceReload) {
          this.cubeInputData = cubeBrowserData
          return cubeBrowserData
        }
      } catch (e) {
        console.error(e)
      }
      // If we don't have the data in the local storage, or we want to force a reload, we fetch the data from the API
      try {
        const response = await GetCubeInputData(payload.cubeKey, payload.versionKey)
        this.cubeInputData = response
        localStorage.setItem('cubeBrowserData', JSON.stringify(response))
        return response
      } catch (e) {
        console.error(e)
      }
    },
  },
})
