//https://css-tricks.com/snippets/javascript/lighten-darken-color -
// Simplified
/**
 * Lighten or darken a color
 * @param color
 * @param deviation
 */
export function LightenDarkenColor(color: string, deviation: number = 80) : string {
  let usePound = false;

  if (color[0] == "#") {
    color = color.slice(1);
    usePound = true;
  }

  const num = parseInt(color,16);

  const r = Math.min(255, Math.max(0, (num >> 16) + deviation));
  const b = Math.min(255, Math.max(0, ((num >> 8) & 0x00FF) + deviation));
  const g = Math.min(255, Math.max(0, (num & 0x0000FF) + deviation));

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export function GetAvatarColor(firstName: string, lastName: string) : string {
  const colors = [
    "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5",
    "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50",
    "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800",
    "#ff5722", "#795548", "#9e9e9e", "#607d8b"
  ];

  const index = (firstName?.charCodeAt(0) + firstName?.charCodeAt(1) + lastName.charCodeAt(1) + firstName.length + 2) % colors.length
  return colors[index||0]
}
