<template>
  <div
    class="chart-container mt-4 mx-4"
    style="position: relative; height: 350px;"
  >
    <canvas id="chart"></canvas>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import { Chart } from 'chart.js/auto'
import { chartColors } from '@/variables'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  data () {
    this.chart = null

    return {
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    storeNames () {
      return (this.appConfig?.stores || []).reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    prepacksKeys () {
      return Object
        .keys(this.chartData[0])
        .filter(key => key.startsWith('prepack_'))
    },
    chartDataOrdered () {
      const fieldsToOrder = [
        x => this.prepacksKeys.reduce((sum, key) => sum + x[key], 0), 'service_level',
      ]

      return orderBy(this.chartData, fieldsToOrder, ['desc', 'desc'])
    },
  },
  methods: {
    drawChart () {
      const ctx = document.getElementById('chart')

      if (this.chart) {
        this.chart.destroy()
      }

      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.chartDataOrdered.map(() => ''),
          datasets: [
            ...this.prepacksKeys.map((key, i) => ({
              type: 'bar',
              yAxisID: 'y',
              label: this.$options.filters.formatString(key),
              data: this.chartDataOrdered.map(x => x[key]),
              backgroundColor: chartColors[i] + '4d', // +4d for opacity,
              borderColor: chartColors[i],
              borderWidth: 2,
            })),
            {
              type: 'line',
              yAxisID: 'y',
              label: 'Service level',
              data: this.chartDataOrdered.map(x => x.service_level),
              borderColor: 'transparent',
              backgroundColor: '#E35500',
              pointRadius: 3,
              fill: false,
            },
            {
              type: 'line',
              yAxisID: 'y',
              label: 'Committed',
              data: this.chartDataOrdered.map(x => x.committed),
              borderColor: 'transparent',
              backgroundColor: '#FF9800',
              pointRadius: 3,
              fill: false,
            },
          ]
        },
        options: {
          maintainAspectRatio: false,
          animation: {
            duration: 0,
          },
          scales: {
            x: {
              stacked: true,
              title: {
                display: true,
                text: 'Prepacks',
                font: {
                  size: 14,
                },
                padding: {
                  top: 15,
                  bottom: 10,
                },
              },
              offset: true,
            },
            y: {
              position: 'left',
              stacked: true,
              title: {
                display: true,
                font: {
                  size: 14,
                },
                text: 'Pieces',
              },
              border: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                usePointStyle: true,
              },
            },
            tooltip: {
              callbacks: {
                title: tooltipItem => {
                  const chartDataPoint = this.chartDataOrdered[tooltipItem[0].dataIndex]

                  return this.storeNames[chartDataPoint.store_key]
                },
              }
            },
          },
        },
      })
    },
  },
}
</script>
