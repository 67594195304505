<template>
  <v-card class="buy-manage-beliefs-modal">
    <v-card-title class="text-body-1 font-weight-medium my-2 justify-space-between px-12">
      {{ `${selectedBelief && selectedBelief.index !== undefined ? 'Edit Belief' : 'Add Belief'}` }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text--primary px-12">
      <h6 class="text-subtitle-1 font-weight-medium mt-4">General</h6>
      <v-row class="align-baseline mt-1 mb-4">
        <v-col class="py-0 pr-3">
          <collapsible-select
            :items="stores"
            v-model="selectedBelief.store_keys"
            clearable
            autocomplete
            show-select-all
            item-text="store_name"
            item-value="store_key"
            label="Stores"
            hide-details
            @input="selectedBelief = {...selectedBelief}"
          ></collapsible-select>
        </v-col>
      </v-row>
      <v-row class="align-baseline mt-1 mb-4">
        <v-col class="py-0 pr-3">
          <collapsible-select
            :items="storeSets"
            v-model="selectedStoreSets"
            clearable
            item-text="set_name"
            item-value="stores_set_key"
            label="Store Sets"
            return-object
            show-select-all
            autocomplete
            hide-details="auto"
          ></collapsible-select>
        </v-col>
      </v-row>
      <v-row class="align-baseline mt-0 mb-4">
        <v-col class="py-0 pr-3">
          <collapsible-select
            :items="sku_names.map((item)=> {return {value: item, text: item}})"
            v-model="selectedBelief.sku_names"
            clearable
            autocomplete
            show-select-all
            label="SKUs"
            hide-details
            @input="selectedBelief = {...selectedBelief}"
          ></collapsible-select>
        </v-col>
      </v-row>
      <v-row class="align-baseline pt-3 my-2 mr-0 pr-0">
        <v-col class="py-0 pr-3" col="6">
          <WeekSelector
            :start-year="0"
            only-future
            :value="this.selectedBelief['week_min'].toString()"
            label="Start Week"
            class="pr-6 py-0 col pl-0"
            @input="(value)=> this.selectedBelief.week_min = value *1"
          ></WeekSelector>
        </v-col>
        <v-col class="pa-0" col="6">
          <WeekSelector
            :value="this.selectedBelief['week_max'].toString()"
            :start-year="0"
            only-future
            label="End Week"
            class="pa-0 col"
            @input="(value)=> this.selectedBelief.week_max = value *1"
          ></WeekSelector>

        </v-col>
      </v-row>
      <v-row class="justify-space-between align-baseline mx-0 my-0">
        <v-text-field
          label="Factor"
          type="number"
          :value="selectedBelief.factor"
          :error-messages="selectedBelief.factor < 0 ? 'Must be greater or equal to 0' : ''"
          hide-details="auto"
          @input="selectedBelief.factor = parseFloat($event.replace(',', '.') || 0); selectedBelief = {...selectedBelief}"
        ></v-text-field>
      </v-row>

      <h6 class="text-subtitle-1 font-weight-medium pt-3 pb-5">Properties</h6>
      <v-row class="align-baseline my-0" :key="propertiesSectionKey">
        <v-row class="py-0 justify-space-between align-center mr-2 ml-0 mb-3 mt-0"  v-for="(property,index) in selectedBelief.properties" :key="index"
        >
          <beliefs-properties
            class="xs11"
            :ref="'properties'+index"
            :property="property"
            :index="index"
            :all-properties="allProperties"
            :properties-values="propertiesValues"
            @index-changed="_onIndexChange"
            @property-changed="(event)=> addProperty(event, false)"
          />
          <v-icon @click="()=> _onDeleteClick(index)" class="align-self-baseline mt-1">delete</v-icon>
        </v-row>
        <v-row  class="py-0 justify-space-between align-center mr-2 ml-0 mt-0 mb-0">
          <beliefs-properties
            :key="newPropertyKey"
            class="xs11"
            :index="lastProperty"
            :all-properties="allProperties"
            :properties-values="propertiesValues"
            :used-properties="Object.keys(selectedBelief.properties || {})"
            @index-changed="_onIndexChange"
            @property-changed="addProperty"
          >
          </beliefs-properties>
        </v-row>
      </v-row>
      <v-row class="align-baseline mt-5 mx-0 mb-1">
        <v-col class="ma-0 pa-0">
          <h4>Note</h4>
          <v-textarea
            class="mt-0"
            v-model="selectedBelief.note"
            placeholder="Note of certain amount length here"
            :counter="200"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="!isEdit" class="align-baseline mt-5 mx-0 mb-1">
        <v-col class="ma-0 pa-0">
          <v-checkbox
            v-model="addToExistingItem"
            label="Add to existing shop items"
            color="primary"
            dense
            :ripple="false"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row class="dialog-footer text-right py-2 px-4">
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleCloseClicked"
              >Cancel</v-btn>
              <v-btn
                color="primary"
                depressed
                :disabled="selectedBelief.factor < 0"
                outlined
                @click="handleBudgetSave"
                class="mx-4"
              >Save</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash'
import CollapsibleSelect from '../components/CollapsibleSelect'
import BeliefsProperties from './BeliefsProperties'
import WeekSelector from '../components/WeekSelector'
import { getPropertiesAndValues } from '@/api/options'

export default {
  name: 'BuyManageBeliefsModal',
  components: { WeekSelector, BeliefsProperties, CollapsibleSelect },
  props: {
    selectedBelief: {
      type: Object,
      default () {
        return {
          note: '',
          week_max: '',
          week_min: '',
          sku_names: [],
          properties: {},
          store_keys: [],
          factor: 0,
        }
      }
    },
    isEdit: {
      type: Boolean,
      default () {
        return false
      }
    },
    stores: {
      type: Array,
      default: () => [],
    },
    sku_names: {
      type: Array,
      default () {
        return []
      }
    },
    properties: {
      type: Object,
      default () {
        return {}
      }
    },
    storeSets: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      cardKey: 0,
      propertiesSectionKey: 0,
      newPropertyKey: 0,
      allProperties: [],
      lastProperty: '',
      selectedWeekKeys: '',
      propertiesValues: {},
      propertiesList: [],
      addToExistingItem: false,
      selectedStoreSets: [],
    }
  },
  methods: {
    _onIndexChange ({ value }) {
      this.lastProperty = value
      if (!this.propertiesValues[value]) {
        this.propertiesList.push(value)
      }
    },
    _onDeleteClick (index) {
      delete this.selectedBelief.properties[index]
      this.forceUpdatePropertiesSection()
    },
    parseWeek (week) {
      if (!week) {
        return '-'
      }
      const year = ('' + week).substr(0, 4)
      const weeks = ('' + week).substr(4)
      return `${year}-W${weeks}` || '-'
    },
    addProperty ({ value, index }, adding = true) {
      if (!this.lastProperty && adding) {
        return
      }
      this.selectedBelief.properties[!adding ? index : this.lastProperty] = value
      if (adding) {
        this.forceUpdateNewProperty()
      }
      this.lastProperty = null
    },
    addDimensions (event) {
      if (!event) {
        return
      }
      this.selectedBelief.dimensions.push(event)
      // this.cardKey++
      this.lastDimension = ''
    },
    forceUpdateNewProperty () {
      setTimeout(() => {
        this.newPropertyKey++
      })
    },
    forceUpdatePropertiesSection () {
      setTimeout(() => {
        this.propertiesSectionKey++
      })
    },
    handleCloseClicked () {
      this.$emit('close')
      this.selectedStoreSets = []
    },
    async handleBudgetSave () {
      if (this.selectedBelief.factor < 0) {
        return
      }
      const storeKeysIncludingSets = _.concat(
        this.selectedBelief.store_keys,
        _.flatMap(this.selectedStoreSets, 'store_keys')
      )
      // const obj = {}
      // for (const element in this.$refs) {
      //     const key = this.$refs[element][0]._data.indexData
      //     const value = this.$refs[element][0]._data.propertyData
      //     obj[key] = value
      // }
      //
      this.selectedBelief.store_keys = _.uniq(storeKeysIncludingSets)
      // this.selectedBelief.properties = obj
      delete this.selectedBelief.properties_show
      this.selectedStoreSets = []
      this.$emit('save', {
        belief: this.selectedBelief,
        addToExistingItem: this.addToExistingItem,
      })
    },
    callProperties () {
      getPropertiesAndValues('skus', 'aod')
        .then(r => {
          this.allProperties = r.properties || []
          this.propertiesValues = r.values || {}
        })
    }
  },
  created () {
    this.propertiesList = Object.keys(this.selectedBelief.properties || {})
    this.callProperties()
  }
}
</script>

<style scoped lang="scss">
  .buy-manage-beliefs-modal {
    .factor-titles {
      color: rgba(0,0,0,0.6);
      font-size: 14px;
    }
  }
</style>
