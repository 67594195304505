import axios from '@/services/axios'
import baseURLs from '@/api/APIBaseURLs'
import * as models from '@/models/StockOperate'
import {findOptionsByFiltersTSQL} from '@/api/options'


export async function findOptionsByFilters (payload: models.FindOptionsByFiltersPayload):  Promise<models.FindOptionsByFiltersResponse>{
  // TODO remove this dependency and use the TSQL API without wrapping
  const d = await findOptionsByFiltersTSQL(payload)
  if (d === null) {
    return {
      options: [],
      refreshTime: ''
    }
  }
  return  {
    options: d.options as unknown as models.SearchResult[],
    refreshTime: d.refreshTime
  }
}

export async function findOptionsBySearchKey (payload: models.FindOptionsByOptionsKeyPayload) {
  const response = await axios.post<{
    data: models.SearchResult[]
    refresh_time: string
      }>(
        baseURLs.tsqlAPI + '/options_finder',
        payload
      )
  return {
    data: response.data.data,
    refreshTime:  response.data.refresh_time,
  }
}

export async function findOptionsSetByOptionSetKey (optionSetKey: number) {
  const response = await axios.get<{ options_sets: models.OptionSet[] }>(
    `${baseURLs.manageAPI}/options_sets?options_set_key=${optionSetKey}`
  )
  return response.data
}

export interface Option {
  [key: string]: string | number
}

export function createOptionsFromColumnsAndData (columns: string[], data: (string|number)[][]): Option[] {
  const options = [] as Option[]
  // build Options objects
  for (const option of data) {
    const optionObj = {} as Option
    for (let i = 0; i < columns.length; i++) {
      optionObj[columns[i]] = option[i]
    }
    options.push(optionObj)
  }
  return options
}

export async function getOptionsPreviewByCriteria (payload: { options_set: models.OptionSet, columns?: string[] }): Promise<models.GetOptionsPreviewByCriteriaResponse> {
  const response = await axios.post(
    baseURLs.tsqlAPI + '/options_finder',
    {
      format: 'lists',
      columns: payload.columns,
      ...payload.options_set
    }
  )
  const columns = response.data.columns as string[]
  const data = response.data.data as (string|number)[][]
  return {
    options: createOptionsFromColumnsAndData(columns, data) as unknown as models.SearchResult[],
    refreshTime: response.data.refresh_time
  }
}

export async function getOperations (importOperation = false): Promise<models.Operation[]> {
  const response = await axios.get<models.Operation[]>(baseURLs.manageAPI + '/options_batch' + (importOperation ? '?import=true' : ''))
  return response.data
}

export async function applyBatchOperation (payload: models.BatchOperationPayload) {
  await axios.post(baseURLs.manageAPI + '/options_batch', payload)
}


export interface ImportBatchOperationPayload {
  operation: string,
  parameters: Record<string, unknown>,
  file: string[][],
  columns: string[],
}
export async function applyImportBatchOperation (payload: ImportBatchOperationPayload) {
  return (await axios.post(baseURLs.manageAPI + '/options_batch', payload)).data
}

export async function applyOptionSetOperation (payload: models.OptionSetOperationPayload) {
  await axios.post(baseURLs.manageAPI + '/options_sets_include_options', payload)
}
