<template>
  <div class="collapsible-select">
    <component
      :is="autocomplete ? 'v-autocomplete' : 'v-select'"
      :search-input.sync="searchInput"
      :items="optionsListToShow"
      multiple
      chips
      :filter="filter"
      :return-object="returnObject"
      small-chips
      deletable-chips
      :clearable="!notClearable"
      v-bind="$attrs"
      v-on="$listeners"
      :menu-props="{
        left: true,
        offsetY: true,
        contentClass: 'select-dropdown-menu',
      }"
      @change="handleChange"
      @blur="handleOptionsBlur"
    >
      <template v-if="showSelectAll" v-slot:prepend-item>
        <v-list-item
          :disabled="autocomplete && Boolean(searchInput)"
          ripple
          @click="handleSelectAllClick"
        >
          <v-list-item-action>
            <v-icon :color="allSelected ? 'primary' : null">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Select All
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template v-slot:selection="{ item, index }">
        <slot name="selection" :item="item" :index="index">
          <v-chip
            v-if="index === 0"
            small
            close
            outlined
            @click="handleFirstOptionClick"
            @click:close="handleSelectedDeleteClick"
          >
            <span>{{ getItemText(item) }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text caption ml-1 plus-number">
            +{{ $attrs.value.length - 1 }}
          </span>
        </slot>
      </template>
      <template v-for="(index, name) in $slots" v-slot:[name]>
        <slot :name="name" />
      </template>
    </component>
  </div>
</template>

<script>
import { VAutocomplete, VSelect } from 'vuetify/lib'

export default {
  props: {
    autocomplete: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => ([])
    },
    notClearable: {
      type: Boolean,
      default: false,
    },
    showSelectAll: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Function
    },
    clickFirstFiltersSelected: {
      type: Boolean,
      default: false,
    },
    // If true, selected items will be shown first in the list
    showSelectedFirst: {
      type: Boolean,
      default: false,
    },
  },
  components: { VSelect, VAutocomplete },
  data () {
    return {
      searchInput: '',
      showOnlyCurrentOptions: false,
      orderedItems: [],
      queueSort: false,
    }
  },
  watch: {
    'items': {
      handler () {
        if (this.items.length === 0) {
          this.showOnlyCurrentOptions = false
          return
        }
        this.orderedItems = Array.from(this.items)
        this.sortItems(this.orderedItems)
      },
      immediate: true,
    },
  },
  computed: {
    valueMap () {
      const m = new Set()
      if (!this.$attrs.value) {
        return m
      }
      if (this.$attrs.value.length && typeof this.$attrs.value[0] !== 'object') {
        for (const v of this.$attrs.value) {
          m.add(v)
        }
        return m
      }
      const valueKey = this.$attrs['item-value'] || 'value'
      for (const v of this.$attrs.value) {
        m.add(v[valueKey])
      }
      return m
    },
    optionsListToShow () {
      let items = []
      if (this.showSelectedFirst) {
        items = this.orderedItems
      } else {
        items = this.items
      }

      if (items.length === 0) {
        return this.$attrs.value
      }
      if (!this.showOnlyCurrentOptions) {
        return items
      }
      const valueKey = this.$attrs['item-value'] || 'value'
      if (items.length && typeof items[0] !== 'object') {
        return items.filter(
          o => this.$attrs.value.includes(o)
        )
      }
      return items.filter(o => this.$attrs.value.includes(o[valueKey]))
    },
    allSelected () {
      return this.items && this.$attrs.value && (this.items.length === this.$attrs.value.length)
    },
    someSelected () {
      return this.items && this.$attrs.value && (this.$attrs.value.length > 0) && (this.$attrs.value.length < this.items.length)
    },
    icon () {
      if (this.someSelected) {
        return 'mdi-close-box'
      }

      if (this.allSelected) {
        return 'mdi-checkbox-marked'
      }

      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    sortItems (items) {
      if (!this.showSelectedFirst) {
        return
      }
      const valueKey = this.$attrs['item-value'] || 'value'
      items.sort((a, b) => {
        if (typeof a === 'object') {
          a = a[valueKey]
          b = b[valueKey]
        }
        const aInValue = this.valueMap.has(a)
        const bInValue = this.valueMap.has(b)
        if (aInValue && !bInValue) {
          return -1
        }
        if (!aInValue && bInValue) {
          return 1
        }
        return 0
      })
    },
    getItemText (item) {
      if (typeof item !== 'object') {
        return item
      }
      const itemTextKey = this.$attrs['item-text'] ? this.$attrs['item-text'] : 'value'
      return (typeof itemTextKey === 'function') ? itemTextKey(item) : item[itemTextKey]
    },
    handleChange ($event) {
      this.$emit('change', $event)
      // emitting input to update the v-model if bound
      this.$emit('input', $event)
      this.queueSort = true
    },
    handleSelectAllClick () {
      const itemsAreFlat = this.items?.length > 0 && typeof this.items[0] !== 'object'
      if (this.allSelected) {
        this.$attrs.value = []
      } else if (!this.$attrs['item-value'] && !this.$attrs['item-text'] && itemsAreFlat) {
        this.$attrs.value = [...this.items]
      } else {
        this.$attrs.value = this.items.map(item => {
          const valueKey = this.$attrs['item-value'] ? this.$attrs['item-value'] : 'value'
          return item[valueKey]
        })
      }

      this.handleChange(this.$attrs.value)
    },
    handleSelectedDeleteClick () {
      // only first tag is shown, so we can splice from index 0
      this.$attrs.value.splice(0, 1)

      this.handleChange(this.$attrs.value)
    },
    handleFirstOptionClick () {
      if (this.clickFirstFiltersSelected) {
        this.showOnlyCurrentOptions = true
      }
    },
    handleOptionsBlur () {
      setTimeout(() => {
        this.showOnlyCurrentOptions = false
      }, 100)
      if (this.queueSort) {
        setTimeout(() => {
          this.sortItems(this.orderedItems)
          this.queueSort = false
        }, 600)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.collapsible-select {
    .v-input {
        padding-top: 0;
    }
    .v-chip {
    }
    .v-chip__content {
        > span {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
</style>
