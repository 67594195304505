import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'
import { useGeneralStore } from '@/store/pinia/generalStore'

export function getStoreSets (query = '') {
  const url = baseURLs.manageAPI + '/stores_sets' + query

  return axios.get(url)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function getStoresPropertiesValues (keys) {
  const generalStore = useGeneralStore()
  const storeProperties = generalStore.appConfig?.properties.stores || {}
  const result = {}
  for (const key of keys) {
    result[key] = storeProperties[key]
  }
  return result
}

export function getStoresPropertyKeys () {
  const generalStore = useGeneralStore()
  return Object.keys(generalStore.appConfig?.properties.stores || {})
}
