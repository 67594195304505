// src/stores/closure.ts
import {defineStore} from 'pinia'
import {createClosures, deleteClosure, getClosures, updateClosures} from '@/api/closureAPI'
import {Closure, ClosureCreateRequest, ClosuresUpdateRequest} from '@/models/Closure'

export const useClosuresStore = defineStore('closure', {
  state: () => ({
    loadingClosures: false,
    closuresList: [] as Closure[],
    deletingClosure: false,
    addingClosures: false,
    updatingClosures: false
  }),

  actions: {
    setClosure (closure: Closure) {
      for (let i = 0; i < this.closuresList.length; i++) {
        if (this.closuresList[i].rn === closure.rn) {
          this.closuresList[i] = closure
          break
        }
      }
    },
    async loadClosures (past: boolean, stores: number[] = []) {
      this.loadingClosures = true
      try {
        this.closuresList = await getClosures(past, stores)
      } finally {
        this.loadingClosures = false
      }
    },

    async deleteClosure (closure: Closure) {
      this.deletingClosure = true
      try {
        await deleteClosure(closure)
      } finally {
        this.deletingClosure = false
      }
    },

    async createClosure (request: ClosureCreateRequest) {
      this.addingClosures = true
      try {
        await createClosures(request)
      } finally {
        this.addingClosures = false
      }
    },
    async updateClosures (request: ClosuresUpdateRequest) {
      this.updatingClosures = true
      try {
        await updateClosures(request)
      } finally {
        this.updatingClosures = false
      }
    }
  }
})
