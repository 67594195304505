<template>
  <div class="capacity-blocks-table">
    <v-row class="align-center ma-0">
      <h6 v-if="!optionKey" class="text-subtitle-1 font-weight-medium ml-6 pt-4">Blocks</h6>
      <h6 v-else class="text-subtitle-1 font-weight-medium mt-2 ml-4">Option capacity blocks</h6>
    </v-row>
    <v-row class="mt-0">
      <div class="col pt-0">
        <v-row class="ma-4 mt-0 justify-center">
          <v-btn-toggle
            v-model="typeIndex"
            dense
            active-class="bg-gray"
          >
            <v-btn
              small
              :key="0"
            >
              <span class="text-capitalize">Pieces</span>
            </v-btn>
            <v-btn
              small
              :key="1"
            >
              <span class="text-capitalize">Options</span>
            </v-btn>
            <v-btn
              small
              :key="2"
            >
              <span class="text-capitalize">SKUs</span>
            </v-btn>
          </v-btn-toggle>
        </v-row>
        <v-row class="align-center mb-4 mr-0">
          <h4 class="text-no-wrap px-6 ">{{ selectedItemsRows.length }} line(s) selected
            <span v-if="selectedItemsRows.length > 1">(changes are applied to all selected rows)</span>
          </h4>
          <div class="col text-right pr-0">
            <TableSearch
              class="mx-2"
              @searchChanged="val => handleSearchChanged('capacityTableFilters', columnsToSearch, val)"
            />
          </div>
          <v-menu offset-y offset-x left nudge-bottom="5" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="action-btn-primary mr-6" v-bind="attrs" v-on="on"
              >filter_list
              </v-icon>
            </template>
            <v-card max-height="400" class="overflow-auto">
              <h5 class="px-2 pt-2">Columns</h5>
              <el-checkbox-group v-model="selectedColumns" @input="saveSelectedColumns" style="font-size: 14px"
                class="d-flex flex-column pa-2">
                <el-checkbox v-for="item of columnsToSelect" :label="item" :key="item"></el-checkbox>
              </el-checkbox-group>
            </v-card>
          </v-menu>
        </v-row>
        <data-tables
          style="width: 100%"
          :data="capacitiesForTable"
          :page-size="5"
          two-rows-column
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :filters="capacityTableFilters"
          filter-multiple
          @filtered-data="filteredData = $event"
          @filter-change="filters => handleFilterChange('capacityTableFilters', filters)"
        >
          <data-tables-column prop="capacity_block_key" width="55">
            <template v-slot:header>
              <v-row class="ma-0 flex-nowrap">
                <v-simple-checkbox
                  color="primary"
                  :value="allSelectedItems"
                  :ripple="false"
                  dense
                  hide-details
                  class="ml-3"
                  @input="handleSelectAllItemsChange"
                />
              </v-row>
            </template>
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-3 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectItemRowChange($event, scope.row)"
              />
            </template>
          </data-tables-column>
          <data-tables-column
            prop="options_set_name"
            column-key="options_set_name"
            label="Option set"
            v-if="selectedColumns.includes('Option set')"
            sortable="custom"
            :filters="getColumnFilterValues('capacityBlocks', 'options_set_name')"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.options_set_name }}</span>
              <v-chip
                label
                outlined
                small
                color="#4FB4AB"
                class="mr-8 ml-4"
                @click.stop="handleRowOptionSetClick(scope.row)"
              >
                {{ scope.row.options_set_count }}
              </v-chip>
            </template>
          </data-tables-column>
          <data-tables-column
            prop="store_name"
            v-if="selectedColumns.includes('Store')"
            column-key="store_name"
            label="Store"
            sortable="custom"
            :filters="getColumnFilterValues('capacityBlocks', 'store_name')"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.store_name }}</span>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Block type')"
            prop="block_type"
            column-key="block_type"
            label="Block type"
            sortable="custom"
            :filters="getColumnFilterValues('capacityBlocks', 'block_type')"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.block_type }}</span>
            </template>
          </data-tables-column>

          <data-tables-column prop="date_min" label="Since" width="120"  sortable="custom"
            column-key="date_min"
            v-if="selectedColumns.includes('Since')"

            :filters="getColumnFilterValues('capacityBlocks', 'date_min')"

          >
            <template slot-scope="scope">
              {{ scope.row.date_min }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Value')"
            column-key="value"
            :filters="getColumnFilterValues('capacityBlocks', 'value')"
            prop="value" label="Value" width="80" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.value }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Must')"
            column-key="must_have"
            :filters="getColumnFilterValues('capacityBlocks', 'must_have')"
            prop="must_have" label="Must" width="60" sortable="custom">
            <template v-slot="scope">
              <span :style="{color: getBackground(scope.row, 'must_have', true)}">
                {{ scope.row.must_have }}
              </span>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Nice')"
            column-key="nice_have"
            :filters="getColumnFilterValues('capacityBlocks', 'nice_have')"
            prop="nice_have" label="Nice" width="60" sortable="custom">
            <template v-slot="scope">
              <span :style="{color: getBackground(scope.row, 'nice_have', true)}">
                {{ scope.row.nice_have }}
              </span>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Soft')"
            column-key="soft_max_have"
            :filters="getColumnFilterValues('capacityBlocks', 'soft_max_have')"
            prop="soft_max_have" label="Soft" width="60"  sortable="custom">
            <template slot-scope="scope">
              <span :style="{color: getBackground(scope.row, 'soft_max_have')}">
                {{ scope.row.soft_max_have }}
              </span>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Max')"
            column-key="max_have"
            :filters="getColumnFilterValues('capacityBlocks', 'max_have')"
            prop="max_have" label="Max" width="60" sortable="custom">
            <template slot-scope="scope">
              <span :style="{color: getBackground(scope.row, 'max_have')}">
                {{ scope.row.max_have }}
              </span>
            </template>
          </data-tables-column>

          <data-tables-column
            prop="threshold"
            column-key="threshold"
            label="Threshold"
            v-if="selectedColumns.includes('Threshold')"
            sortable="custom"
            width="80"
            :filters="getColumnFilterValues('capacityBlocks', 'threshold')"
          >
            <template slot-scope="scope">
              <v-text-field
                class="threshold-input"
                v-model.number="scope.row.threshold"
                type="number"
                :error-messages="getThresholdErrors(scope.row.threshold)"
                @change="e => debouncedUpdate(scope.row, 'threshold', e)"
              ></v-text-field>
            </template>
          </data-tables-column>
          <!--                    Show sources as chips-->
          <data-tables-column
            prop="sources"
            column-key="sources"
            label="Sources"
            sortable="custom"
            v-if="selectedColumns.includes('Sources')"
            width="170px"
            :filters="getColumnFilterValues('capacityBlocks', 'sources')">
            <template slot-scope="scope">
              <collapsible-select
                v-model="scope.row.sources"
                :items="availableSources"
                multiple
                small-chips
                dense
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
                @change="e => debouncedUpdate(scope.row, 'sources', e)"
              />
            </template>
          </data-tables-column>

          <data-tables-column
            prop="breaching"
            column-key="breaching"
            label="Breaching"
            v-if="selectedColumns.includes('Breaching')"
            sortable="custom"
            width="80"
            :filters="getColumnFilterValues('capacityBlocks', 'breaching')"
          >
            <template slot-scope="scope">
              <div class="d-flex justify-center">
                <v-simple-checkbox
                  disabled
                  dense
                  hide-details
                  class="shrink mt-0 pt-0"
                  :value="scope.row.breaching"
                  @input="e => debouncedUpdate(scope.row, 'breaching', e)"
                />
              </div>
            </template>
          </data-tables-column>
          <!--                  Editable checkbox 'active'-->
          <data-tables-column
            prop="active"
            v-if="selectedColumns.includes('Active')"
            column-key="active"
            width="80"
            label="Active"
            sortable="custom"
            :filters="getColumnFilterValues('capacityBlocks', 'active')"
          >
            <template slot-scope="scope">
              <div class="d-flex justify-center">
                <v-simple-checkbox
                  v-model="scope.row.active"
                  hide-details="auto"
                  class="shrink mt-0 pt-0"
                  color="primary"
                  @input="e => debouncedUpdate(scope.row, 'active', e)"
                />
              </div>
            </template>
          </data-tables-column>

          <data-tables-column
            prop="changed_at"
            v-if="selectedColumns.includes('Changed at')"
            column-key="changed_at"
            label="Changed at"
            width="120"
            sortable="custom"
            :filters="getColumnFilterValues('capacityBlocks', 'changed_at', (value)=> value.substring(0,10), true)"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.changed_at.substring(0,10) }}</span>
            </template>
          </data-tables-column>
          <data-tables-column
            prop="changed_by"
            v-if="selectedColumns.includes('Changed by')"
            column-key="changed_by"
            label="Changed by"
            sortable="custom"
            :filters="getColumnFilterValues('capacityBlocks', 'changed_at', getUserById, true)"
          >
            <template slot-scope="scope">
              <span>{{ getUserById(scope.row.changed_by) }}</span>
            </template>
          </data-tables-column>
        </data-tables>
      </div>
    </v-row>
    <!-- Option set form dialog -->
    <option-set-form-dialog
      ref="optionSetFormDialog"
      :is-open="optionSetFormDialogVisible"
      :current-option-set="currentOptionSet"
      @close="optionSetFormDialogVisible = false"
    />
  </div>
</template>

<script>
import TableSearch from '@/components/TableSearch.vue'
import columnFilters from '@/mixins/columnFilters'
import { getCapacityBlocks, saveCapacityBlock } from '@/api/stockCapacityGroups'
import OptionSetFormDialog from '@/components/Assortment/OptionSets/OptionSetFormDialog.vue'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { tableSelectMixin } from '@/components/Tables/mixins/selectMixin'
import _ from 'lodash'

import { useStockManageStore } from '@/store/pinia/stockManageStore'


const columnsToSelect = ['Option set', 'Store', 'Block type', 'Since', 'Value', 'Must', 'Nice', 'Soft', 'Max', 'Threshold', 'Sources', 'Breaching', 'Active', 'Changed at', 'Changed by', 'Exclude']
const defaultColumns = ['Option set', 'Store', 'Value', 'Must', 'Nice', 'Soft', 'Max', 'Threshold', 'Sources', 'Breaching', 'Active']

export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  name: 'BlocksTable',
  mixins: [columnFilters, tableSelectMixin],
  components: { CollapsibleSelect, OptionSetFormDialog, TableSearch },
  props: {
    optionKey: {
      default: null,
    }
  },
  data () {
    return {
      columnsToSelect,
      selectedColumns: JSON.parse(localStorage.getItem('blockCapacitySelectedColumns')) || defaultColumns,
      tableKey: 0,
      typeIndex: 0,
      capacityBlocks: [],
      columnsToSearch: ['options_set_name', 'store_name'],
      capacityTableFilters: [{ value: '' }],
      optionSetFormDialogVisible: false,
      currentOptionSet: undefined,
      storeSetFormDialogVisible: false,
      currentStoreSet: undefined,
      currentStoreSetEditMode: false,
      availableSources: ['all', 'allocation', 'replenishment', 'transfers'],

      debouncedUpdate: _.debounce(this.handleParamChange, 500),
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    capacitiesForTable () {
      const capacityType = ['pieces', 'options', 'skus'][this.typeIndex]
      return this.capacityBlocks.filter(item => item.capacity_type === capacityType)
    },
    stores () {
      return (this.appConfig?.stores || [])
    },
    editedOptionSet () {
      return this.stockManageStore.capacityTab.editedOptionSet
    },
    capacityOptionSetsList () {
      return this.stockManageStore.capacityTab.capacityOptionSetsList
    },
    users () {
      return this.appConfig?.users || []
    },
  },
  methods: {

    saveSelectedColumns (value) {
      localStorage.setItem('blockCapacitySelectedColumns', JSON.stringify(value))
    },
    getUserById (id){
      const user = this.users.find(user=> user.id === id)
      if(!user) {
        return ''
      }
      return `${user.first_name} ${user.last_name}`
    },
    getBackground (row, key, checkOutGoing = false) {
      if (row.block_type === 'outgoing' && checkOutGoing) {
        if (row.value < row[key]) {
          return 'red'
        }
      }
      if (row.block_type === 'incoming' && !checkOutGoing) {
        if (row.value > row[key]) {
          return 'red'
        }
      }
      return 'unset'
    },
    getThresholdErrors (threshold) {
      if (Number.isInteger(threshold) && threshold >= 0 && threshold <= 5) {
        return
      }
      return ['Value: 0 - 5']
    },
    async loadCapacityBlocks () {
      let data = []
      if (this.stores?.length) {
        data = (await getCapacityBlocks(this.optionKey)).data
      } else {
        const [blocks] = await Promise.all([
          getCapacityBlocks(this.optionKey),
        ])
        data = blocks.data
      }
      this.capacityBlocks = data?.map(b => {
        return {
          ...b,
          options_set_name: this.getOptionByKey(b.options_set_key),
          options_set_count: this.getOptionCountByKey(b.options_set_key),
          store_name: this.getStoreByKey(b.store_key)
        }
      }) || []
      this.tableKey++
    },
    handleRowOptionSetClick (row) {
      const payload = {
        tab: 'capacity',
        key: row.options_set_key,
      }
      this.optionSetFormDialogVisible = true
      this.stockManageStore.getOptionSet(payload)
        .then(() => {
          this.currentOptionSet = { ...this.editedOptionSet }
          this.$nextTick(this.optionSetFormRef()?.searchOptions)
        })
    },
    optionSetFormRef () {
      return this.$refs.optionSetFormDialog.$refs.optionSetForm
    },
    getStoreByKey (storeKey) {
      if (!storeKey || !this.stores) {
        return ''
      }
      const arr = this.stores.filter(s =>
        storeKey === s.store_key).map(s => s.store_name)
      return arr.length > 0 ? arr[0] : ''
    },
    getOptionByKey (optionKey) {
      if (!optionKey || !this.capacityOptionSetsList) {
        return ''
      }
      const arr = this.capacityOptionSetsList.filter(s => {
        return optionKey === s.options_set_key
      }).map(s => s.set_name)
      return arr.length > 0 ? arr[0] : ''
    },
    getOptionCountByKey (optionKey) {
      if (!optionKey || !this.capacityOptionSetsList) {
        return ''
      }
      const arr = this.capacityOptionSetsList.filter(s =>
        optionKey === s.options_set_key).map(s => s.options)
      return arr.length > 0 ? arr[0] : 0
    },
    async handleParamChange (row, key, value) {
      // if (this.getThresholdErrors(row.threshold)) {
      //   return
      // }
      if(!row.selected) {
        // Validate threshold
        if(this.getThresholdErrors(row.threshold)) {
          return
        }
        return this.handleSingleChange(row, key, value)
      }
      // Validate threshold for all selected rows
      if(this.selectedItemsRows.some(item => this.getThresholdErrors(item.threshold))) {
        return
      }
      const keys = this.selectedItemsRows.map(item => item.capacity_block_key)
      try {
        await saveCapacityBlock({
          capacity_block_keys: keys,
          [key]: value,
        })
        // await this.loadCapacityBlocks()
        this.selectedItemsRows.forEach(item => {
          this.$set(item, key, value)
        })
      } catch (e) {
        console.error(e)
      }
    },
    async handleSingleChange (row, key, value) {
      try {
        await saveCapacityBlock({
          capacity_block_keys: [row.capacity_block_key],
          [key]: value,
        })
      } catch (e) {
        console.error(e)
      }
    },
  },
  watch: {
    capacityOptionSetsList: {
      handler (newValue) {
        if (newValue && newValue.length) {
          this.loadCapacityBlocks()
        }
      },
      immediate: true
    }
  }
}

</script>

<style lang="scss">
  .capacity-blocks-table {
    .cell:has(> div.threshold-input) {
      padding-top: 6px!important;
    }
    .cell {
      div.v-text-field__details {
        display: none;
      }
      .threshold-input {
        div.v-text-field__details {
          display: flex;
        }
      }
      .collapsible-select .v-chip {
        padding-right: 12px;
      }
      .collapsible-select .v-chip__content > span {
        width: 100%!important;
      }
    }
  }
</style>
