<template>
  <div class="operate-search-properties p-relative" :class="{ 'compact-layout': compactLayout }">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <div class="blocks-wrapper d-flex">
      <div class="block-wrapper mr-7">
        <div class="text-body-1 font-weight-medium pt-6 pl-6">Properties include</div>
        <div class="container ml-6 properties-container">
          <v-row class="align-baseline property-row" v-for="(_, k) in propertiesInclude" :key="k">
            <v-col :cols="11">
              <v-row>
                <v-col :cols="4" class="pl-0 text-body-2 property-name">
                  <v-autocomplete
                    :items="getPropertiesIncludeList(k)"
                    :value="k"
                    @change="val => handlePropIncludeChanged(k, val)"
                    placeholder="Select property"
                    hide-details="auto"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu'
                    }"
                  />
                </v-col>
                <v-col :cols="8" class="pt-6 property-values">
                  <collapsible-select
                    :value="propertiesInclude[k]"
                    :items="propertiesValues[k]"
                    autocomplete
                    show-select-all
                    hide-details="auto"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu'
                    }"
                    @change="val => $emit('propIncludeValuesChanged', {
                      prop: k,
                      value: val
                    })"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="1" class="text-right">
              <v-icon
                class="action-btn-danger"
                @click="$emit('propIncludeDeleted', k)"
              >
                delete
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pl-0 pb-6">
              <v-icon
                :disabled="addIncludeDisabled"
                class="action-btn-primary"
                @click="handlePropIncludeAddClick"
              >add</v-icon>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="block-wrapper ml-7">
        <div class="text-body-1 font-weight-medium pt-6 pl-6">Properties exclude</div>
        <div class="container ml-6 properties-container">
          <v-row class="align-center" v-for="(_, k) in propertiesExclude" :key="k">
            <v-col :cols="11">
              <v-row>
                <v-col :cols="4" class="pl-0 text-body-2">
                  <v-autocomplete
                    :items="getPropertiesExcludeList(k)"
                    :value="k"
                    placeholder="Select property"
                    hide-details="auto"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu'
                    }"
                    @change="val => handlePropExcludeChanged(k, val)"
                  />
                </v-col>
                <v-col :cols="8" class="pt-6">
                  <collapsible-select
                    :value="propertiesExclude[k]"
                    :items="propertiesValues[k]"
                    autocomplete
                    show-select-all
                    hide-details="auto"
                    @change="val => $emit('propExcludeValuesChanged', {
                      prop: k,
                      value: val
                    })"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="1" class="text-right delete-col">
              <v-icon
                class="action-btn-danger"
                @click="$emit('propExcludeDeleted', k)"
              >
                delete
              </v-icon>
            </v-col>
          </v-row>
          <v-row class="align-baseline">
            <v-col class="pl-0 pb-6">
              <v-icon
                :disabled="addExcludeDisabled"
                class="action-btn-primary"
                @click="handlePropExcludeAddClick"
              >add</v-icon>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import { mapState } from 'pinia'
import { usePropertiesStore } from '@/store/pinia/propertiesStore'

export default {
  props: {
    propertiesInclude: {
      type: Object,
      default: () => ({}),
    },
    propertiesExclude: {
      type: Object,
      default: () => ({}),
    },
    compactLayout: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
    }
  },
  components: {
    CollapsibleSelect,
  },
  computed: {
    ...mapState(usePropertiesStore, ['optionsPropertiesList', 'optionsValuesList']),
    allProperties () {
      return (this.optionsPropertiesList || []).map(x => ({
        text: this.$options.filters.formatString(x || ''),
        value: x,
      }))
    },
    propertiesValues () {
      const propValues = {}
      for (const prop in this.optionsValuesList) {
        propValues[prop] = this.optionsValuesList[prop].map(x => ({
          text: this.$options.filters.formatString(x || ''),
          value: x,
        }))
      }

      return propValues
    },
    allIncludePropsAdded () {
      return this.getPropertiesIncludeList().length === 1
    },
    allExcludePropsAdded () {
      return this.getPropertiesExcludeList().length === 1
    },
    allIncludePropsHaveValues () {
      return Object.keys(this.propertiesInclude).every(
        prop => this.propertiesInclude[prop].length
      )
    },
    allExcludePropsHaveValues () {
      return Object.keys(this.propertiesExclude).every(
        prop => this.propertiesExclude[prop].length
      )
    },
    addIncludeDisabled () {
      return this.allIncludePropsAdded || !this.allIncludePropsHaveValues
    },
    addExcludeDisabled () {
      return this.allExcludePropsAdded || !this.allExcludePropsHaveValues
    },
  },
  methods: {
    getPropertiesIncludeList (prop) {
      return this.allProperties
        .filter(p =>
          p.value === prop ||
          !Object.keys(this.propertiesInclude).includes(p.value)
        )
    },
    getPropertiesExcludeList (prop) {
      return this.allProperties
        .filter(p =>
          p.value === prop ||
          !Object.keys(this.propertiesExclude).includes(p.value)
        )
    },
    handlePropIncludeAddClick () {
      const newProp = this.getPropertiesIncludeList()[0]?.value
      this.$emit('propIncludeAdded', newProp)
    },
    handlePropExcludeAddClick () {
      const newProp = this.getPropertiesExcludeList()[0]?.value
      this.$emit('propExcludeAdded', newProp)
    },
    handlePropIncludeChanged (oldProp, newProp) {
      this.$emit('propIncludeChanged', { oldProp, newProp })
    },
    handlePropExcludeChanged (oldProp, newProp) {
      this.$emit('propExcludeChanged', { oldProp, newProp })
    },
  },
}
</script>

<style lang="scss">
.operate-search-properties {
  .el-select {
    width: 200px;
  }
  .block-wrapper {
    width: 460px;
  }
  &.compact-layout {
    .block-wrapper {
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .blocks-wrapper {
      flex-wrap: wrap;
    }
  }
}
</style>
