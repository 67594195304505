<template>
  <div class="chart-container" style="position: relative; height: 400px">
    <canvas ref="chartCanvas" class=""></canvas>
  </div>
</template>

<script>
import _ from 'lodash'
import { Chart } from 'chart.js/auto'
import { formatLargeNumber } from '@/utils'
import { colormapCover, formatString } from '@/variables'
import { useStockOverviewStore } from '@/store/pinia/stockOverviewStore'

export default {
  props: {
    dimension: {
      type: String,
      required: true,
    },
    outliers: {
      type: Boolean,
      required: true,
    },
  },
  setup () {
    return {
      stockOverviewStore: useStockOverviewStore(),
    }
  },
  data () {
    this.chart = null

    return {
      min: null,
      max: null,
    }
  },
  computed: {
    chartAxis () {
      return this.stockOverviewStore.chartAxis
    },
    chartData () {
      if (this.stockOverviewStore.overviewChartData) {
        if (!this.outliers) {
          return this.stockOverviewStore.overviewChartData.filter(p => p.outlier === false)
        } else {
          return this.stockOverviewStore.overviewChartData
        }
      } else {
        return []
      }
    },
    colorProperties () {
      return this.stockOverviewStore.chartColorData || []
    },
  },
  methods: {
    plotGraph () {
      if (this.chart) {
        this.chart.destroy()
      }

      const pointBackgroundColors = []
      for (let i = 0; i < this.chartData.length; i++) {
        pointBackgroundColors.push(this.getPointGradeColor(this.chartData[i][this.chartAxis[2]]))
      }

      this.chart = new Chart(this.$refs.chartCanvas, {
        type: 'bubble',
        data: {
          datasets: [{
            data: this.chartData.map(p => ({
              x: p[this.chartAxis[0]],
              y: p[this.chartAxis[1]],
              grade: p[this.chartAxis[2]],
              label: '',
              r: 5
            })),
            backgroundColor: pointBackgroundColors,
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          clip: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              footerFont: {
                size: 11,
                weight: 'normal',
              },
              callbacks: {
                label: tooltipItem => {
                  const dataItem = this.chartData[tooltipItem.dataIndex]

                  return dataItem[this.dimension]
                },
                footer: (tooltipItems) => {
                  const dataItem = this.chartData[tooltipItems[0].dataIndex]

                  return [
                    `${formatString(this.chartAxis[0])} = ${_.round(dataItem[this.chartAxis[0]], 2)}`,
                    `${formatString(this.chartAxis[1])} = ${_.round(dataItem[this.chartAxis[1]], 2)}`,
                    `${formatString(this.chartAxis[2])} = ${_.round(dataItem[this.chartAxis[2]], 2)}`,
                  ]
                }
              }
            },
          },
          hover: {
            mode: 'null'
          },
          scales: {
            x: {
              title: {
                display: true,
                text: this.$options.filters.formatString(this.chartAxis[0]),
                font: {
                  size: 14,
                },
              },
              min: 0,
              ticks: {
                callback: (value)=> formatLargeNumber(value),
              }
            },
            y: {
              id: 'Y',
              title: {
                display: true,
                text: this.$options.filters.formatString(this.chartAxis[1]),
                font: {
                  size: 14,
                },
              },
              min: 0,
              ticks: {
                callback: (value)=> formatLargeNumber(value),
              },
            },
          },
        }
      })

      this.chart.update()
    },
    getPointGradeColor (value) {
      const colorData = this.colorProperties.find(p => p.name === this.chartAxis[2])
      const min = (colorData && colorData.min) || this.min
      const max = (colorData && colorData.max) || this.max
      const result = (value - min) / (max - min)

      return colormapCover(result)
    },
    updateMinMax () {
      this.min = Math.min.apply(Math, this.chartData.map(p => p[this.chartAxis[2]]))
      this.max = Math.max.apply(Math, this.chartData.map(p => p[this.chartAxis[2]]))
    },
  },
  watch: {
    chartData: function () {
      this.updateMinMax()
      this.plotGraph()
    },
  }
}
</script>
<style>
.yMax {
    width: 250px;
    float: right;
    margin-right: 25px;
}
</style>
