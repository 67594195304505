<template>
  <div class="transferTable p-relative">
    <v-overlay absolute color="white" :value="!!stockTransfersStore.loadingTransfersList[lowerCaseType] || loadingBox">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="my-0 mx-0 pl-4 align-center flex-nowrap">
      <v-col cols="8" class="pl-0">
        <v-row class="flex-nowrap">
          <v-col cols="2" >
            <v-select
              v-model="activeType"
              @input="loadData"
              :items="['Combinations', 'Send', 'Receive']"
            ></v-select>
          </v-col>

          <v-col cols="2">
            <div class="d-flex">
              <v-tooltip top :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-on="on" v-bind="attrs"
                    v-model.number="properties.profit_min"
                    clearable
                    @input="loadDataDelayed()"
                    type="number"
                    label="Profit min"
                  ></v-text-field>
                </template>
                <span>
                  <b>Profit min:</b> Minimum profit per piece
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="d-flex">
              <v-tooltip top :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-on="on" v-bind="attrs"
                    v-model.number="properties.send_min"
                    clearable
                    @input="loadDataDelayed()"
                    type="number"
                    label="Send min"
                  ></v-text-field>

                </template>
                <span>
                  <b>Send min:</b> Minimum pieces send by a store
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col cols="2" >
            <div class="d-flex">
              <v-tooltip top :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-on="on" v-bind="attrs"
                    v-model.number="properties.send_max"
                    clearable
                    type="number"
                    @input="loadDataDelayed()"
                    label="Send max"
                  ></v-text-field>
                </template>
                <span>
                  <b>Send max:</b> Maximum pieces send by a store
                </span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" class="align-self-center pa-0 d-flex align-center justify-end">
        <label class="v-label theme--light mr-2">Optimized</label>
        <v-switch
          v-model="optimized"
          hide-details
          class="d-inline-block pt-0 mt-0 mr-2"
          @change="$emit('optimized-changed')"
        />
      </v-col>
    </v-row>
    <v-row class="pt-0 px-0 ma-0">
      <v-col class="text-right pr-3 pt-0">
        <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
        <DataExport
          @item-click="downloadData"
        />
      </v-col>
    </v-row>
    <data-tables
      ref="transfersTable"
      :key="transfersListKey"
      :data="tableData"
      :table-props="{
        'expand-icon': 'add',
        'summary-method': getSummary,
        'show-summary': true,
      }"
      :page-size="10"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 mb-4 mr-2'
      }"
      :filters="transfersTableFilters"
      @expand-change="handleExpandChange"
    >
      <el-table-column width="45" class="expand-column">
        <template slot-scope="scope">
          <v-icon @click="handleExpandClick(scope.row)" class="my-2">
            <template v-if="expandedRow !== scope.row">
              chevron_right
            </template>
            <template v-else>
              expand_more
            </template>
          </v-icon>
        </template>
      </el-table-column>
      <el-table-column type="expand" width="1">
        <template slot-scope="props">
          <!-- Using slot-scope here to prevent double render of TransferDetails component.
                         Not sure why this happens -->
          <TransferDetails :type="lowerCaseType" :row="props.row" @accepted="loadDataDelayed" ref="transfersDetails" @transfers-changed="detailsChanged= true" />
        </template>
      </el-table-column>
      <el-table-column
        label="Store"
        sortable="custom"
        prop="store_key"
      >
        <template slot-scope="scope">
          <template v-if="lowerCaseType === 'receive'">
            Multi
          </template>
          <template v-else>
            <div class="one-line" v-if="findStore(scope.row.store_key)">
              {{ findStore(scope.row.store_key).store_name }}
            </div>
            <span v-else>{{scope.row.store_key}}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        label="To Store"
        sortable="custom"
        prop="to_store_key"
      >
        <template slot-scope="scope">
          <template v-if="lowerCaseType === 'send'">
            Multi
          </template>
          <template v-else>
            <div class="one-line" v-if="findStore(scope.row.to_store_key)">
              {{ findStore(scope.row.to_store_key).store_name }}
            </div>
            <span v-else>{{scope.row.to_store_key}}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Stock" sortable="custom" prop="stock">
        <template slot-scope="scope">
          {{ scope.row.stock | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="Potential" sortable="custom" prop="potential">
        <template slot-scope="scope">
          {{ scope.row.potential | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="To Stock" sortable="custom" prop="to_stock">
        <template slot-scope="scope">
          {{ scope.row.to_stock | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="To Potential" sortable="custom" prop="to_potential">
        <template slot-scope="scope">
          {{ scope.row.to_potential | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="Optimal delta" sortable="custom" prop="pieces">
        <template slot-scope="scope">
          {{ scope.row.pieces | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="Optimal profit" sortable="custom" prop="profit">
        <template slot-scope="scope">
          {{ priceFormatter(scope.row.profit) }}
        </template>
      </el-table-column>
      <el-table-column >
        <template slot-scope="scope">
          <v-btn
            color="primary"
            depressed
            :disabled="!scope.row.pieces
              || !!scope.row.accepted
              || (expandedRow && expandedRow !== scope.row)"
            outlined
            width="130"
            @click="handleAcceptClick(scope.row)"
          >
            <span v-if="scope.row.accepted">Accepted</span>
            <span v-else>Accept</span></v-btn>
        </template>
      </el-table-column>
    </data-tables>

    <!-- Close confirm dialog -->
    <v-dialog
      :value="closeDialogVisible"
      persistent
      content-class="align-self-start mt-10"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Close transfer
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">You will lose any changes made in this transfer. Continue?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleCloseCancel"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleCloseConfirm"
                class="red--text mx-4"
              >Ok</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { downloadData } from '@/utils'
import TableSearch from '@/components/TableSearch.vue'
import TransferDetails from '@/components/Transfers/TransferDetails.vue'
import DataExport from '../DataExport'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'
import _ from 'lodash'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore(),
      stockTransfersStore: useStockTransfersStore(),
    }
  },
  components: {
    DataExport,
    TableSearch,
    TransferDetails,
  },
  data () {
    return {
      loadDataDelayed: _.debounce(this.loadData, 1200),
      properties: {
        min_profit: null,
        send_min: null,
        send_max: null,
      },
      optimized: true,
      loadingBox: false,
      detailsChanged: false,
      transfersListKey: 0,
      transfersTableFilters: [
        {
          value: ''
        }
      ],
      activeType: 'Combinations',
      expandedRow: null,
      rowExpandClicked: null,
      closeDialogVisible: false,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['findStoreWithKey']),
    transferDetailsData () {
      return this.stockTransfersStore.transferDetailsData
    },
    priceFormatter () {
      const currency = this.$auth.getUserCurrency().currency || 'EUR'
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      return formatter.format
    },
    lowerCaseType () {
      return this.activeType.toLowerCase()
    },
    tableData () {
      return this.stockTransfersStore.transferTablesData[this.lowerCaseType].data
    },
    totalsData () {
      return this.stockTransfersStore.transferTablesData[this.lowerCaseType].total
    },
  },
  watch: {
    'stockTransfersStore.detailsRowExpanded.accepted' (newVal) {
      if (newVal) {
        // close row if there is opened one
        if (this.expandedRow) {
          this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
          this.expandedRow = null
        }
      }
    },
  },
  methods: {
    getValue (scope) {
      if(this.expandedRow){
        return this.$refs.transfersDetails.getValue(scope)
      }else {
        return scope.row.pieces
      }
    },
    async handleAcceptClick (row) {
      if(this.expandedRow){
        this.$refs.transfersDetails.handleAcceptClick()
        return
      }
      this.loadingBox = true
      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()
      const params = {
        method: this.optimized ? 'optimized' : 'greedy',
        dimensions: ['option_key', 'sku_key', 'store_key', 'to_store_key'],
        ...this.properties,
        filters: {
          store_key: row.store_key ? [row.store_key] : (pageFiltersValues.stores_dc || []),
          to_store_key: row.to_store_key ? [row.to_store_key] : (pageFiltersValues.to_stores_dc || []),
          group_key: pageFiltersValues.groups || [],
          collection_key: pageFiltersValues.collections || [],
          option_key: pageFiltersValues.options || [],
        }
      }

      if (['combinations', 'send'].includes(this.lowerCaseType)) {
        params.store_key = [row.store_key]
      }

      if (['combinations', 'receive'].includes(this.lowerCaseType)) {
        params.to_store_key = [row.to_store_key]
      }

      // load data for opened row
      const data = await this.stockTransfersStore.getTransferBoxDetails(params)
      const keys = ['pieces', 'profit', 'sku_key', 'store_key', 'to_store_key', 'type']
      const payload = data.map((item, index) => {
        const row = {}
        keys.forEach(key => {
          row[key] = item[key]
        })
        row.delta = this.getValue({ row: item, $index: index })
        row.keep = item.stock - row.delta
        row.type = 'box'
        row.pieces = row.delta
        row.profit = item['profits'][row.delta - 1]
        return row
      })

      await this.stockTransfersStore.acceptTransfer(payload.filter(item => item.pieces))
      await this.loadDataDelayed()
    },

    downloadData (sep) {
      const data = this.tableData
      downloadData(sep, data, 'Transfers_box')
    },
    async loadData () {
      this.stockTransfersStore.setDetailsRowExpanded(this.lowerCaseType, false)
      if (this.expandedRow) {
        this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
        this.expandedRow = null
      }
      await this.stockTransfersStore.getTransfersBoxList(this.lowerCaseType, this.optimized, this.getDimensionsByType(), this.properties)
      this.loadingBox = false
    },
    getDimensionsByType () {
      if (this.lowerCaseType === 'combinations') {
        return ['store_key', 'to_store_key']
      }

      if (this.lowerCaseType === 'send') {
        return ['store_key']
      }

      if (this.lowerCaseType === 'receive') {
        return ['to_store_key']
      }
    },
    findStore (storeKey) {
      return this.findStoreWithKey(storeKey)
    },
    getSummary (params) {
      if (!this.totalsData) {
        return []
      }

      const sums = ['', '', 'Total', '']
      const { columns } = params
      columns.forEach((column, index) => {
        const value = this.totalsData[column.property]
        if (value !== undefined) {
          if (column.property === 'profit') {
            sums[index] = this.priceFormatter(value)
          } else if(typeof value !== 'number' || isNaN(value)){
            sums[index] = ''
          }else {
            sums[index] = this.$options.filters.formatThousands(value)
          }
        }
      })

      return sums
    },
    handleExpandChange (row) {
      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()
      const params = {
        method: this.optimized ? 'optimized' : 'greedy',
        dimensions: ['option_key', 'sku_key', 'store_key', 'to_store_key'],
        ...this.properties,
        filters: {
          store_key: row.store_key ? [row.store_key] : (pageFiltersValues.stores_dc || []),
          to_store_key: row.to_store_key ? [row.to_store_key] : (pageFiltersValues.to_stores_dc || []),
          group_key: pageFiltersValues.groups || [],
          collection_key: pageFiltersValues.collections || [],
          option_key: pageFiltersValues.options || [],
        }
      }

      if (['combinations', 'send'].includes(this.lowerCaseType)) {
        params.store_key = [row.store_key]
      }

      if (['combinations', 'receive'].includes(this.lowerCaseType)) {
        params.to_store_key = [row.to_store_key]
      }

      // close row if there is opened one
      if (this.expandedRow) {
        this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
        this.stockTransfersStore.setTransferDetailsData([])
      }

      // save opended row in component and state
      this.expandedRow = this.expandedRow ? null : row
      this.stockTransfersStore.setDetailsRowExpanded(this.lowerCaseType, !!this.expandedRow)

      // load data for opened row
      if (this.expandedRow) {
        this.stockTransfersStore.getTransferBoxDetails(params)
      }
    },
    handleSearchChanged (val) {
      this.transfersTableFilters[0].value = val
      this.transfersListKey++
    },
    handleExpandClick (row) {
      if (this.expandedRow || this.stockTransfersStore.detailsRowExpanded.accepted) {
        this.rowExpandClicked = row
        if (this.detailsChanged) {
          this.closeDialogVisible = true
        } else {
          this.handleCloseConfirm()
        }
      } else {
        this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(row, row !== this.expandedRow)
      }
    },
    handleCloseCancel () {
      this.closeDialogVisible = false
      this.rowExpandClicked = null
    },
    handleCloseConfirm () {
      this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.rowExpandClicked, this.rowExpandClicked !== this.expandedRow)
      this.closeDialogVisible = false
      this.rowExpandClicked = null
      this.detailsChanged = false
    },
  },
  created () {
    this.loadData()
  },
  beforeDestroy () {
    // cleanup opened row, because table will be recreated
    if (!this.stockTransfersStore.detailsRowExpanded.accepted) {
      this.stockTransfersStore.setTransferDetailsData([])
      this.stockTransfersStore.setDetailsRowExpanded(this.lowerCaseType, false)
    }
  },
}
</script>

<style lang="scss">
.transferTable {
    .el-table__expand-icon {
        display: none;
    }
    .el-table__expanded-cell[class*=cell] {
        padding: 16px;
        background: #f9f9f9;
        &:hover {
            background: #f9f9f9 !important;
        }
    }
    .one-line {
        white-space: nowrap;
        overflow: hidden;
    }
}
</style>
