<template>
  <div class="transferDetails p-relative">
    <v-overlay absolute color="white" :value="!!stockTransfersStore.loadingTransferDetails || !!acceptingTransfer">
      <v-progress-circular color="primary" indeterminate size="64" width="2"/>
    </v-overlay>
    <v-row class="ma-0 pt-2 pb-3 justify-end">
      <TableSearch
        class="mx-2"
        @searchChanged="val => handleSearchChanged('tableFilters', Object.keys(currentPageRows[0]), val)"
      />
    </v-row>
    <data-tables
      class="table"
      :filters="tableFilters"
      @filtered-data="filteredData = $event"
      @update:currentPage="currentPage = $event"
      @update:pageSize="pageSize = $event"
      :data="currentPageRows"

      :table-props="{'span-method': objectSpanMethod, 'row-index': 'option_key'}"
      :page-size="parseInt(pageSize)"
      :pagination-props="{
        pageSizes: pageSizes,
        class: 'el-pagination text-right mt-6 mb-4 mr-2' + (currentPageRows.length <= 100 ? ' d-none' : ''),
      }"
    >
      <el-table-column
        column-key="store_name"
        v-if="type === 'Option'"
        label="Store" sortable="custom" prop="store_name">
        <template slot-scope="scope">
          <div class="one-line" v-if="scope.row.store_name">
            {{ scope.row.store_name }}
          </div>
          <span v-else>{{scope.row.store_key | formatString}}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="type === 'Option'"
        column-key="to_store_name"
        label="To Store" sortable="custom" prop="to_store_name">
        <template slot-scope="scope">
          <div class="one-line" v-if="scope.row.to_store_name">
            {{ scope.row.to_store_name }}
          </div>
          <span v-else>{{scope.row.to_store_key | formatString}}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-else
        label="Option" prop="option_name" width="250" column-key="option_name">
        <template slot-scope="scope">
          <OptionDataCell
            nameKey="option_name"
            :option="{...scope.row}"
            open-in-new-tab
          />
        </template>
      </el-table-column>
      <el-table-column v-if="type === 'Receive'" label="Store" prop="store_name">
        <template slot-scope="scope">
          <div class="one-line" v-if="scope.row.store_name">
            {{ scope.row.store_name }}
          </div>
          <span v-else>{{ scope.row.store_name }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="type === 'Send'" label="To store" prop="to_store_key">
        <template slot-scope="scope">
          <div class="one-line" v-if="scope.row.to_store_name">
            {{ scope.row.to_store_name }}
          </div>
          <span v-else>{{ scope.row.to_store_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Sku" prop="sku_name">
        <template slot-scope="scope">
          {{ scope.row.sku_name }}
        </template>
      </el-table-column>
      <el-table-column label="Stock" prop="stock">
        <template slot-scope="scope">
          {{ scope.row.stock }}
        </template>
      </el-table-column>
      <el-table-column label="Keep" prop="keep" v-if="type === 'Combinations'">
        <template slot-scope="scope">
          {{ scope.row.keep }}
        </template>
      </el-table-column>
      <el-table-column label="Transfer">
        <template slot-scope="scope">
          <v-select
            :items="getTransferOptions(scope.row)"
            dense
            v-model="scope.row.transfer"
            class="pt-0 pb-1"
            hide-details
            @input="handleChange(scope.row)"
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"/>
        </template>
      </el-table-column>
      <el-table-column label="Picked">
        <template slot-scope="scope">
          {{ scope.row.picked }}
        </template>
      </el-table-column>
      <el-table-column label="Done">
        <template slot-scope="scope">
          <div class="d-flex">
            <v-simple-checkbox
              disabled
              dense
              hide-details
              class="shrink mt-0 pt-0"
              :value="!!scope.row.done"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Delete" width="180">
        <template slot-scope="scope">
          <div class="text-center">
            <v-icon class="mr-2 action-btn-danger" @click="handleDeleteClick(scope.row)">delete</v-icon>
            <div class="mt-2">Send: {{ totalDeltasByOption[getRowKey(scope.row)].send }}</div>
            <div class="mt-n2" v-if="type === 'Combinations'">Keep: {{ totalDeltasByOption[getRowKey(scope.row)].keep }}</div>
          </div>
        </template>
      </el-table-column>
    </data-tables>
    <div class="pt-4 transfers-summary">
      <div class="d-flex justify-end align-center">
        <div>
          <div>Selected pieces: {{ selectedSummary | formatThousands }}</div>
        </div>
      </div>
    </div>

    <!-- Delete confirm dialog -->
    <v-dialog
      :value="!!deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="p-relative rounded-0">
        <v-overlay absolute color="white" :value="!!stockTransfersStore.deletingOptionAcceptedTransfer">
          <v-progress-circular color="primary" indeterminate size="64" width="2"/>
        </v-overlay>
        <v-card-title class="text-body-1 font-weight-medium">
          Delete transfer
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Do you want to delete this transfer? Only open tasks can be deleted.</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleDeleteCancel"
              >Cancel
              </v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteConfirm"
                class="red--text mx-4"
              >Ok
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import OptionDataCell from '@/components/Transfers/OptionDataCell.vue'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'

export default {
  setup () {
    return {
      stockTransfersStore: useStockTransfersStore(),
    }
  },
  mixins: [ columnFilters ],
  props: {
    row: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true
    }
  },
  components: {
    TableSearch,
    OptionDataCell,
  },
  data () {
    return {
      tableFilters: [],
      filteredData: [],
      selectedTransfers: {},
      currentPage: 1,
      pageSize: 10,
      pageSizes: [5, 10, 15, 25, 50],
      currentPageRows: [],
      acceptingTransfer: false,
      deleteDialogVisible: false,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['findStoreWithKey']),
    transferDetailsData () {
      return this.stockTransfersStore.transferDetailsData
    },
    transfersDetailsKey (){
      if(this.type.toLowerCase() === 'option'){
        return 'store_key'
      }
      return 'option_key'
    },
    selectedSummary () {
      let result = 0

      for (const optKey in this.totalDeltasByOption) {
        result += this.totalDeltasByOption[optKey].send
      }

      return result
    },
    totalDeltasByOption () {
      const result = {}

      this.currentPageRows.forEach(row => {
        if (!result[this.getRowKey(row)]) {
          result[this.getRowKey(row)] = {
            send: 0,
            keep: 0,
          }
        }
        result[this.getRowKey(row)].send += row.transfer
        result[this.getRowKey(row)].keep += (row.stock - row.transfer)
      })

      return result
    },
    // needed for rowspan
    optionsRowsCount () {
      const counts = {}
      this.filteredData.forEach((row, i) => {
        if (!counts[row[this.transfersDetailsKey]]) {
          counts[row[this.transfersDetailsKey]] = {
            count: 1,
            firstRowIndex: i,
          }
        } else {
          counts[row[this.transfersDetailsKey]].count++
        }
      })

      return counts
    },
    storesRowsCount () {
      const counts = {}

      this.filteredData.forEach((row, i) => {
        const key = `${row.store_key}_${row.to_store_key}`

        if (!counts[key]) {
          counts[key] = {
            count: 1,
            firstRowIndex: i,
          }
        } else {
          counts[key].count++
        }
      })

      return counts
    },
  },
  watch: {
    currentPageRows: {
      handler () {
        const rowsLength = this.currentPageRows.length
        if (!rowsLength || this.alreadyChanged) {
          return
        }
        this.alreadyChanged = true
        if (rowsLength <= 100) {
          this.pageSizes = [this.currentPageRows.length]
          this.pageSize = rowsLength
        } else {
          this.pageSizes = [100, ...this.pageSizes]
          this.pageSize = 100
        }
      },
      immediate: true
    },
    transferDetailsData: {
      handler (val) {
        if (val.length) {
          _
            .uniqBy(val, 'option_key')
            .forEach(item => {
              this.setTransfersOptionsValues(item.option_key)
            })

          this.currentPageRows = [...this.transferDetailsData]
            .map((item) => ({
              ...item,
              store_name: this.findStore(item.store_key)?.store_name,
              to_store_name: this.findStore(item.to_store_key)?.store_name
            }))
        }
      },
      immediate: true
    }
  },
  methods: {
    getRowKey (row) {
      if (this.type === 'Option') {
        return `${row.store_key}_${row.to_store_key}`
      }
      return row.option_key
    },
    getTransferOptions (row) {
      return [...Array(row.stock + 1).keys()].map((d, i) => ({
        text: `${i}`,
        value: d
      }))
    },
    findStore (storeKey) {
      return this.findStoreWithKey(storeKey)
    },
    objectSpanMethod ({ row, rowIndex, columnIndex }) {
      const pageIndex = this.pageSize * (this.currentPage - 1)
      const { count, firstRowIndex } = this.optionsRowsCount[row[this.transfersDetailsKey]]
      if (columnIndex === 0 || columnIndex === 7) {
        if (firstRowIndex === (rowIndex + pageIndex)) { // This means it is the first row of a new product
          return {
            rowspan: count, // Set rowspan equal to number of rows coming with this product
            colspan: 1
          }
        } else if (!rowIndex) {
          return {
            rowspan: (firstRowIndex + count > (pageIndex + this.pageSize)) ? count : (firstRowIndex + count) % pageIndex,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      return {
        rowspan: 1,
        colspan: 1,
      }
    },

    setCurrentPage (val) {
      this.currentPage = val
    },
    setTransfersOptionsValues (optionKey) {
      this.transferDetailsData
        .filter(item => item.option_key === optionKey)
        .forEach(item => {
          if (!this.selectedTransfers[item.option_key]) {
            this.$set(this.selectedTransfers, item.option_key, {})
          }

          this.$set(this.selectedTransfers[item.option_key], item.sku_key, item.transfer)
        })
    },
    handleDeleteClick (row) {
      this.rowToDelete = row
      this.deleteDialogVisible = true
    },
    handleDeleteCancel () {
      this.rowToDelete = null
      this.deleteDialogVisible = false
    },
    handleDeleteConfirm () {
      const payload = { ...this.rowToDelete }
      if (this.type === 'Send') {
        delete payload.to_store_key
      }
      if (this.type === 'Receive') {
        delete payload.store_key
      }
      payload.isChild = true
      this.stockTransfersStore.deleteOptionAcceptedTransfer(payload)
        .then(() => {
          this.currentPageRows = this.currentPageRows.filter((item) => item[this.transfersDetailsKey] !== this.rowToDelete[this.transfersDetailsKey])
          if(!this.currentPageRows?.length){
            this.$emit('delete-detail')
          }
          this.rowToDelete = null
          this.deleteDialogVisible = false
        })
    },
    handleChange (row) {
      const payload = [{
        to_store_key: row.to_store_key,
        store_key: row.store_key,
        sku_key: row.sku_key,
        pieces: row.transfer,
        stock: row.stock,
        keep: (row.stock - row.transfer),
        profit: row.profit,
      }]
      this.stockTransfersStore.saveTransfers(payload).then(() => this.$emit('update-details'))
    },
  },
}
</script>

<style lang="scss">
.transferDetails {
  thead {
    th {
      padding: 12px 0;
    }
  }

  .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
    width: 4px;
  }

  .table .el-table__body-wrapper {
    max-height: 60vh;
    overflow-y: auto;
  }

  .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }

  .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }

  .el-pagination * {
    background-color: transparent !important;
  }
  .v-select__selection {
    font-size: 14px;
  }
}
</style>
