<template>
  <div>
    <label for="email">
      Email
    </label>
    <v-text-field
      id="email"
      v-model="email"
      :error-messages="emailError"
      @change="validateEmail"
      required
      type="email"
      outlined
      autofocus
    />
    <v-btn
      class="action"
      outlined
      @click="submit"
      v-animate-css.click="'tada'"
      v-animate-css.hover="'pulse'"
      :disabled="submitLock"
    >
      Reset
    </v-btn>
    <router-link to="/login" class="text-decoration-none d-block mt-6">
      <v-icon>mdi-arrow-left</v-icon> Back to login
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ForgotPasswordForm',
  data () {
    return {
      email: null,
      emailError: null,
      submitLock: false
    }
  },
  props: {
    errorMessage: {
      type: String,
      default: null
    },
    successMessage: {
      type: String,
      default: null
    }
  },
  mounted () {
    this.email = this.$route.query.email || ''
  },
  methods: {
    isValidEmail (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    },
    validateEmail () {
      if (!this.email) {
        this.emailError = 'Email is required!'
      } else if (!this.isValidEmail(this.email)) {
        this.emailError = 'Email is not valid!'
      } else {
        this.emailError = null
      }
      return !this.emailError
    },
    setError (error) {
      this.$emit('update:errorMessage', error)
    },
    async submit () {
      // To avoid double submit
      if (this.submitLock) {
        return
      }
      this.setError(null)
      if (!this.validateEmail()) {
        return
      }
      this.submitLock = true
      try {
        await this.$auth.forgotPassword(this.email.toLowerCase())
        this.$emit('update:successMessage', 'In case this email address existed, an email has been sent to your email address.')
      } catch (error) {
        this.setError('Something went wrong, please try again later.')
        console.error(error)
      }
      this.submitLock = false
    }
  }
}
</script>
