<template>
  <div class="pricingHomeCarousel">
    <v-card class="card pa-4 p-relative" min-height="462">
      <v-row class="ma-2">
        <h4 class="text-center">Predictions</h4>
      </v-row>
      <v-carousel
        height="auto"
        class="pb-15"
        delimiter-icon="circle"
        :perPage="1"
        light
        hide-delimiter-background
        :show-arrows="false"
        @change="(value)=> this.chartIndex = value">
        <v-carousel-item eager>
          <v-overlay absolute color="white" :value="!scatterData">
            <v-progress-circular color="primary" indeterminate size="64" width="2" />
          </v-overlay>
          <h4 class="text-center">Stock vs Residual</h4>
          <data-sets-scatter-chart
            class="pl-3 mt-0"
            :flat="true"
            :view="scatterData || {view: {}}"
            :hide="true"
            :index="null"
            height="340"
            @point-click="p => $emit('point-click', p)"
          />
        </v-carousel-item>
        <v-carousel-item>
          <v-overlay absolute color="white" :value="!mdSalesGraph">
            <v-progress-circular color="primary" indeterminate size="64" width="2" />
          </v-overlay>
          <h4 class="mb-2 text-center">Markdown Sales dist.</h4>
          <data-sets-line-chart
            v-if="mdSalesGraph"
            :view="mdSalesGraph"
            index="1"
            :flat="true"
            :hide="true"
            height="332"

          />
        </v-carousel-item>
        <v-carousel-item>
          <v-overlay absolute color="white" :value="!mdStockGraph">
            <v-progress-circular color="primary" indeterminate size="64" width="2" />
          </v-overlay>
          <h4 class="mb-2 text-center">Markdown Stock dist.</h4>
          <data-sets-line-chart
            v-if="mdStockGraph"
            :view="mdStockGraph"
            index="2"
            :flat="true"
            :hide="true"
            height="332"
          />
        </v-carousel-item>
        <v-carousel-item >
          <v-overlay absolute color="white" :value="!mdSTGraph">
            <v-progress-circular color="primary" indeterminate size="64" width="2" />
          </v-overlay>
          <h4 class="mb-2 text-center">Markdown ST%</h4>
          <data-sets-line-chart
            v-if="mdSTGraph"
            :view="mdSTGraph"
            index="2"
            :flat="true"
            :hide="true"
            height="332"
          />
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script>
import DataSetsScatterChart from '@/components/AutomateDatasets/DataSetsScatterChart'
import { performViewData } from '@/api/analytics'
import DataSetsLineChart from '@/components/AutomateDatasets/DataSetsLineChart'

export default {
  name: 'PricingGraphCarousel',
  components: {
    DataSetsLineChart,
    DataSetsScatterChart,
  },
  data () {
    return {
      chartIndex: 0,
      mdSalesGraph: undefined,
      mdStockGraph: undefined,
      mdSTGraph: undefined,
      erroredViews: [],
    }
  },
  props: {
    scatterData: {
      type: Object,
      default: undefined,
    },
  },
  created () {
    this.loadViews()
  },
  computed: {
    allChartsLoaded () {
      return this.scatterData && this.mdSalesGraph && this.mdStockGraph && this.mdSTGraph
      // return true
    },
  },
  methods: {
    async loadViews () {
      this.erroredViews = []
      try {
        const viewOne = {
          'dataset': 'pricing_statistics',
          'view_name': 'Markdown Sales dist.',
          'chart_type': 'bar_chart',
          'dimensions': ['properties/calendar/week_number', 'properties/article/markdown_bucket'],
          'measures': [{
            'axis': 'y1',
            'name': 'Sales',
            'type': 'bar',
            'color_code': '#FF9800',
            'calculation': 'sales',
            'fill_color_code': '',
            'border_color_code': '#FF9800'
          }],
          'filters': {
          },
          'properties': {
            'axis': {
              'y1': 'Pieces'
            },
            'bar_type': 'stacked',
            'limit': null,
          },
          'creation_date': '2022-10-31 13:11:08'
        }
        const viewTwo = {
          'dataset': 'pricing_statistics',
          'view_name': 'Markdown Stock dist.',
          'chart_type': 'bar_chart',
          'dimensions': ['properties/calendar/week_number', 'properties/article/markdown_bucket'],
          'measures': [{
            'axis': 'y1',
            'name': 'Stock',
            'type': 'bar',
            'color_code': '#FF9800',
            'calculation': 'stock',
            'fill_color_code': '',
            'border_color_code': '#FF9800'
          }],
          'filters': {
          },
          'properties': {
            'axis': {
              'y1': 'Pieces'
            },
            'bar_type': 'stacked',
            'limit': null,
          },
          'creation_date': '2022-10-31 13:12:33'
        }
        const viewThree = {
          'dataset': 'pricing_statistics',
          'view_name': 'Markdown ST%',
          'chart_type': 'line_chart',
          'dimensions': ['properties/calendar/week_number', 'properties/article/markdown_bucket'],
          'measures': [{
            'axis': 'y1',
            'name': 'Sellthrough',
            'type': 'line',
            'color_code': '#FF9800',
            'calculation': 'sellthrough'
          }],
          'filters': {
          },
          'properties': {
            'axis': {
              'y1': 'Percentage',
            },
            'bar_type': 'stacked',
            'limit': null
          },
          'creation_date': '2023-05-08 07:42:45'
        }
        this.mdSalesGraph = await this.loadViewData(viewOne)
        this.mdStockGraph = await this.loadViewData(viewTwo)
        this.mdSTGraph = await this.loadViewData(viewThree)
      } catch (e) {
        console.error(e)
      }
    },
    async loadViewData (view) {
      const pricingOptionsFilter = JSON.parse(localStorage.getItem('pricingOptionsFilter') || '[]')

      if (pricingOptionsFilter.length) {
        view.filters['properties/article/option_key'] = pricingOptionsFilter
      }

      try {
        const data = await performViewData(view, false)
        if (data === 'error') {
          return { view: { ...view, errored: true }, data: [] }
        }
        return data
      } catch (e) {
        this.erroredViews = [...this.erroredViews, { ...view }]
        return { view: { ...view, errored: true }, data: [] }
      }
    },
  },
}
</script>

<style lang="scss">
.pricingHomeCarousel {
  .VueCarousel-pagination {
    .VueCarousel-dot-container {
      margin: 0 !important;
      .VueCarousel-dot {
        margin-top: 10px !important;
      }
    }
  }
}
</style>
