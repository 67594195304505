<template>
  <div class="auth-page" v-animate-css="'fadeIn'">
    <div class="login-header">
      <kinesis-container>
        <kinesis-element :strength="20">
          <img
            :src="thunderstockNewLogo"
            @click="$router.push({ name: 'thunderstock' })"
            alt="Thunderstock"/>
        </kinesis-element>
      </kinesis-container>
    </div>
    <div class="auth-main">
      <div class="top-section"/>
      <v-alert
        v-if="errorMessage"
        dense
        outlined
        type="error"
        transition="scale-transition"
        dismissible
        class="alert"
        @click:close="errorMessage = null"
      >
        {{ errorMessage }}
      </v-alert>
      <v-alert
        v-else-if="successMessage"
        dense
        outlined
        type="success"
        transition="scale-transition"
        dismissible
        class="alert"
        @click:close="successMessage = null"
      >
        {{ successMessage }}
      </v-alert>
      <div class="mid-section-background"/>
      <div class="mid-section">
        <v-card class="login-card">
          <v-row class="ma-0 justify-space-between card-head">
            <v-flex align-self-center class="mt-2">
              <h3
                class="auth-title ma-auto"
                v-animate-css.hover="'pulse'"
              >
                {{ title }}
              </h3>
            </v-flex>
            <v-flex v-if="superuser" class="text-right" align-self-center>
              <label class="v-label theme--light mr-2 dev-mode">Dev mode</label>
              <v-switch
                :input-value="devMode"
                hide-details
                class="d-inline-block pt-2 mt-0"
                @change="handleDevModeChanged"
              />
            </v-flex>
          </v-row>
          <router-view :success-message.sync="successMessage" :error-message.sync="errorMessage"/>
        </v-card>
      </div>
      <div class="bottom-section"/>
    </div>
  </div>
</template>
<script>
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import thunderstockNewLogo from '@/assets/img/thunderstock-new-logo.png'

export default {
  name: 'AuthLayout',
  data () {
    return {
      thunderstockNewLogo,
      superuser: localStorage.getItem('devMode') === 'true',
      errorMessage: null,
      successMessage: null
    }
  },
  watch: {
    '$route' () {
      this.errorMessage = null
      this.successMessage = null
    },
    errorMessage (val) {
      if (val) {
        this.successMessage = null
        this.$vuetify.goTo(0)
      }
    },
    successMessage (val) {
      if (val) {
        this.errorMessage = null
        this.$vuetify.goTo(0)
      }
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['devMode']),
    title () {
      return this.$route.meta['title'] || 'Login'
    }
  },
  methods: {
    handleDevModeChanged () {
      useGeneralStore().setDevMode(!this.devMode)
    },
  },
  async created () {
    const accessToken = localStorage.getItem('access_token')
    if (accessToken) {
      this.$auth.refreshTokens().then((response) => {
        if (response.status === 200) {
          // setInterval(this.$auth.refreshTokens, 30000)
          this.$router.push({ path: '/stock/home' })
        }
      })
    }
  },
}
</script>
<style lang="scss">
.auth-page {
  zoom: calc(1 / var(--zoom));
  .auth-main{
    margin-top: -56px;
    background-color: black !important;
    height: 100vh;
    .card-head {
      margin-bottom: 14px!important;
      max-height: 55px;
    }
    .v-alert {
      position: absolute !important;
      top: 15px!important;
      left: 25%;
      right: 25%;
      margin-left: auto;
      margin-right: auto;
      max-width: 300px;
      z-index: 100;
      background-color: rgba(255, 255, 255, 0.9)!important;
      button {
        width: 40px;
      }
    }
    .auth-title {
      color: #ff9800;
      font-size: 20px;
    }
    .error-msg {
      color: var(--danger);
      text-align: center;
    }

    label {
      display: inline-block;
      font-size: 15px;
      color: #011413;
      font-weight: 500;
      margin-bottom: 8px;
    }
    // offset switch label
    label.dev-mode {
      position: relative;
      top: -5px;
    }
    button {
      height: 40px!important;
      font-size: 15px!important;
      width: 100%;
      &.action {
        color: #ff9800 !important;
      }
      &.v-btn {
        font-weight: 500;
      }
    }
    a {
      font-size: 15px;
      text-decoration: none!important;
    }
    .v-input__slot {
      min-height: 48px!important;
      height: 48px!important;
      width: 100%;
    }

    .login-card {
      width: 450px;
      min-height: 490px;
      padding: 10px 50px 50px;
      display: flex;
      flex-direction: column;
      border-radius: 10px !important;

      .divider-row {
        width: 350px;
        text-align: center;
        margin-inline: auto;
      }
    }

    .top-section {
      height: 15%;
      width: 100vw;
      background-color: black;
    }

    .bottom-section {
      background-image: url("../../assets/img/top-section-background.jpg");
      width: 100vw;
      height: 15%;
    }
    .mid-section {
      height: 80% !important;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      position: relative;
    }

    .mid-section-background {
      position: absolute;
      background-image: url("../../assets/img/modules-background.jpg");
      transform: skewY(-4deg);
      width: 100%;
      height: 75%
    }
  }

  .login-header {
    position: fixed;
    top: 0;
    width: 100%;
    margin-top: 30px;
    margin-left: 30px;

    img {
      width: 196.56px;
      height: 40px;
    }
  }
}

</style>
