import { Chart } from 'chart.js/auto'
import { addDays, format, getISOWeek } from 'date-fns'
import { chartColors } from '@/variables'
import { formatLargeNumber, hexToRgbA } from '@/utils'

/* Chart.Tooltip.positioners.custom = function (elements, eventPosition) {
  return {
    x: eventPosition.x,
    y: eventPosition.y,
  }
} */

export default class ChartClass {
  constructor (ctx, labels, data, scaleData, type = 'line', lineToday = false, lineTodayColor = '#E35500', daysCover = 0, week = false, legend = null, onClick = () => ({}), annotation, defaultTooltip = false) {
    const datasets = ChartClass.handleData(data)
    const scales = ChartClass.handleScales(scaleData)
    const annotationList = {}
    if (annotation && labels.includes(annotation.value)) {
      annotationList['annotation1'] = annotation
    }

    if (lineToday) {
      let lineTodayLabel
      if (typeof daysCover === 'string' && daysCover[0] === 'z') {
        lineTodayLabel = daysCover.substring(1)
      } else {
        const coverEnd = addDays(new Date(), daysCover)
        lineTodayLabel = week
          ? 'W' + getISOWeek(coverEnd)
          : format(coverEnd, 'dd MMM')
      }

      if (labels.includes(lineTodayLabel)) {
        annotationList['lineToday'] = {
          type: 'line',
          mode: 'vertical',
          scaleID: 'X',
          value: lineTodayLabel,
          borderColor: lineTodayColor,
          borderWidth: 2,
          borderDash: [5, 10],
          label: {
            display: false
          }
        }
      }
    }

    const chartOptions = {
      type: type,
      data: {
        datasets,
        labels,
      },
      options: {
        clip: false,
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            display: legend?.display === undefined ? true : legend?.display,
            labels: {
              usePointStyle: true,
            }
          },
          tooltip: {
            enabled: true,
          },
          annotation: {
            annotations: annotationList,
          },
        },
        responsive: true,
        maintainAspectRatio: data.maintainAspectRatio || false,
        hover: {
          mode: 'index',
          intersect: false,
        },
        scales,
        onClick: event => {
          const elements = this.chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false)

          onClick({
            point: elements[0],
            chart: this.chart,
          })
        },
      }
    }
    if (defaultTooltip) {
      chartOptions.options.plugins.tooltip.callbacks = {
        // this callback is used to create the tooltip label
        label: function (tooltipItem) {
          return tooltipItem.dataset.label + ': ' + tooltipItem.dataset.data[tooltipItem.dataIndex].toLocaleString()
        }
      }
    }
    if (legend && legend.labels && legend.labels.generateLabels) {
      chartOptions.options.plugins.legend.labels.generateLabels = legend.labels.generateLabels
    }

    this.chart = new Chart(ctx, chartOptions)
  }

  static handleData (data) {
    return data.map((item, index) => {
      return {
        type: item.type,
        yAxisID: item.yAxisID,
        xAxisID: item.xAxisID || 'X',
        label: item.label,
        hidden: item.hidden || false,
        data: item.data,
        fill: 'fill' in item ? item.fill : true,
        backgroundColor: 'backgroundColor' in item ? hexToRgbA(item.backgroundColor, 0.3) : hexToRgbA(chartColors[index % chartColors.length], 0.3),
        borderColor: 'borderColor' in item ? hexToRgbA(item.borderColor, 1) : hexToRgbA(chartColors[index % chartColors.length], 1),
        borderWidth: 2,
        lineTension: 'lineTension' in item ? item.lineTension : 0.4,
        radius: 'radius' in item ? item.radius : 0,
        borderDash: 'borderDash' in item ? item.borderDash : [0, 0],
        showLine: item.showLine,
        pointRadius: item.pointRadius || null,
      }
    })
  }

  static handleScales (scales) {
    if (!scales.yAxes && !scales.xAxes) {
      return scales
    }

    const returnData = { 'yAxes': [], 'xAxes': [] }
    for (const y of scales.yAxes) {
      const ax = {
        id: y.id,
        stacked: y.stacked,
        position: y.position,
        min: y.min,
        max: y.max,
        title: {
          display: true,
          text: y.title.text,
          font: {
            size: 14,
          },
        },
        grid: {
          display: y.id === 'A',
          drawTicks: false,
        },
        ticks: {
          maxTicksLimit: (y.ticks && y.ticks.maxTicksLimit) || undefined,
          fontSize: 14,
          padding: 16,
          min: 'min' in y ? y.min : 0,
          max: 'max' in y ? y.max : undefined,
          suggestedMax: (y.ticks && y.ticks.suggestedMax) || undefined,
          callback: (y.ticks && y.ticks.callback) || ((value)=> formatLargeNumber(value)),
        }
      }

      returnData['yAxes'].push(ax)
    }
    const ax = {
      id: 'X',
      stacked: scales.xAxes.stacked,
      title: {
        display: true,
        text: scales.xAxes.label,
        font: {
          size: 14
        }
      },
      grid: {
        display: false,
      },
      layout: {
        padding : {
          left: 0,
          right: 0,
        }
      },
      ticks: {
        padding: 10,
        fontSize: 14,
        beginAtZero: true,
        userCallback: function (item, index) {
          if (!(index % 0)) {
            return item
          }
        },
        display: !('showXLabels' in scales.xAxes)
      }
    }

    returnData['xAxes'].push(ax)

    return ChartClass.adjustScalesFormat(returnData)
  }

  static adjustScalesFormat (scales) {
    return {
      ...scales.xAxes.reduce((acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }), {}),
      ...scales.yAxes.reduce((acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }), {})
    }
  }

  update (labels, data, type, scales) {
    this.chart.type = type
    this.chart.data.labels = labels
    this.chart.data.datasets = ChartClass.handleData(data)
    this.chart.options.scales = ChartClass.handleScales(scales)
    this.chart.update()
  }

  delete () {
    this.chart.destroy()
  }
}
