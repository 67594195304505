<template>
  <div id="strategy-markdown" class="mx-n6">
    <h6 class="text-subtitle-1 font-weight-medium mx-6">Markdown</h6>
    <div
      class="chart-container mt-4 mx-4"
      style="position: relative; height: 350px;"
    >
      <canvas id="markdown1Chart"></canvas>
    </div>
    <data-tables
      style="width: 100%"
      class="mt-6 table"
      :data="tableData"
      :page-size="tableData.length"
      :pagination-props="{
        pageSizes: [tableData.length],
        class: 'd-none'
      }"
    >
      <el-table-column
        v-for="(c, index) in (tableData.length ? Object.keys(tableData[0]) : [])"
        :key="c"
        :prop="c"
        :label="c | formatString"
        :label-class-name="index === 0 ? 'text-left': 'text-center'"
        sortable
        :column-key="c"
      >
        <template slot-scope="scope">
          <div class="text-center">
            {{ formatMetric(scope.row[c], c) }}
          </div>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { Chart } from 'chart.js/auto'
import { formatLargeNumber } from '@/utils'
import { useCubesStore } from '@/store/pinia/cubesStore'
import { cloneDeep } from 'lodash'

const chartLabelCallback = context => {
  const v = context.raw
  if (typeof v === 'number') {
    return v.toLocaleString('en-GB')
  }
  return v
}

export default {
  name: 'StrategyMarkdown',
  props: {
    chartsData: {
      type: Object || null,
      default: null,
    },
    chartsOptions: {
      type: Object || null,
      default: null
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  setup () {
    return {
      cubesStore: useCubesStore(),
    }
  },
  data () {
    this.markdown1Chart = null

    return {
      chartOptions: {
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
        hover: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          x: {
            title: {
              display: false,
            },
            grid: {
              display: false
            },
            ticks: {
              font: {
                size: 14,
              }
            },
            offset: true,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
            }
          },
          tooltip: {
            callbacks: {
              label: chartLabelCallback,
            }
          },
        },
      },
    }
  },
  computed: {
    metricFormats () {
      return this.cubesStore.metricFormat
    },
  },
  methods: {
    formatMetric (value, metricName) {
      let format = this.metricFormats[metricName.toLowerCase().replace(' ', '_')]

      if (this.currentMetric === 'pieces' && typeof value === 'number') {
        format = 'integer'
      }

      return format ? this.$options.filters[format](value) : value
    },
    drawCharts () {
      ['markdown1Chart'].forEach(chart => {
        if (this[chart]) {
          this[chart].destroy()
        }
      })

      const ctx = document.getElementById('markdown1Chart')
      const options = cloneDeep(this.chartOptions)

      options.scales.y1 = {
        position: 'left',
        title: {
          display: true,
          text: 'Intake',
          font: {
            size: 14,
          },
        },
        grid: {
          drawTicks: false,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          callback: (value)=> formatLargeNumber(value),
          padding: 16,
          font: {
            size: 14,
          }
        },
      }

      this.markdown1Chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.chartsData.markdown1.weekNumbers,
          datasets: this.chartsOptions.markdown1.measures.map(o => {
            return {
              label: o.name,
              data: this.chartsData.markdown1[o.name],
              yAxisID: o.axis,
              borderColor: o.color_code,
              borderWidth: 2,
              backgroundColor: '#FFFFFF',
              pointRadius: null,
              borderDash: o.type === 'dotted' ? [5, 5] : [],
              tension: 0.4,
            }
          })
        },
        options,
      })
    },
    updateCharts () {
      this.drawCharts()
    },
  },
}
</script>

<style lang="scss">
#strategy-markdown {
  .el-table td {
    padding: 0;
  }
  .el-table__append-wrapper {
    position: sticky;
    bottom: 0;
  }
  .table .el-table__body-wrapper {
    max-height: 50vh;
    overflow-y: auto;
  }
  .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
    width: 4px;
  }
  .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }
  .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
  .total-row_column {
    background-color: #f5f7fa;
  }
}
</style>
