<template>
  <div>
    <collapsible-select
      v-if="showStoresSelect"
      :value="selectedStores||[]"
      :label="label"
      :items="storesList"
      :error-messages="errorMessages"
      @change="handleDirectStoresChange"
      item-text="store_name"
      item-value="store_key"
      show-select-all
      autocomplete
    />
    <v-row
      class="ma-0"
      v-for="(item, index) in propertyList"
      :key="index"
    >
      <v-col cols="5" class="py-0 pl-0">
        <v-autocomplete
          :menu-props="{
            left: true,
          }"
          label="Properties"
          class="pt-3 mt-1"
          v-model="item.selectedProperty"
          @input="handlePropertyChange(item)"
          :items="getStoreProperties(item.selectedProperty)"
        />
      </v-col>
      <v-col cols="6" class="py-0 pr-0">
        <CollapsibleSelect
          label="Values"
          show-select-all
          class="pt-3 mt-0"
          autocomplete
          :return-object="item.selectedProperty === 'Store sets'"
          v-model="item.selectedValue"
          @input="handleValuesChange($event, item)"
          :items="propertyValues(item)"
          :item-text="item.selectedProperty === 'Store sets' ? 'label': 'text'"
        />
      </v-col>
      <v-col cols="1" class="d-flex justify-end py-0 pr-0">
        <v-icon
          class="ml-2 action-btn-danger"
          @click="deleteProperty(index)"
        >delete</v-icon>
      </v-col>
    </v-row>
    <v-icon
      @click="propertyList = [...propertyList, {selectedValue: [], selectedProperty: ''}]"
      class="add-prop-btn"
    >add</v-icon>
  </div>
</template>

<script>
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'
import { intersectionOfSets, unionOfSets } from '@/libs/math'

export default {
  name: 'StoresSelectByProperties',
  components: { CollapsibleSelect },
  emits: ['search-results-changed', 'update:selectedStores'],
  props: {
    label: {
      type: String,
      default: 'Stores'
    },
    errorMessages: {
      type: String,
      default: '',
    },
    showStoresSelect: {
      type: Boolean,
      default: false
    },
    selectedStores: {
      type: Array,
    },
    customStores: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    return {
      generalStore: useGeneralStore(),
      storeAndOptionSetsStore: useStoreAndOptionSetsStore(),
    }
  },
  data () {
    return {
      propertyList: [],
    }
  },
  computed: {
    storesList () {
      if (this.customStores?.length) {
        return this.customStores
      }
      return (this.generalStore?.appConfig?.stores || [])
    },
    properties () {
      if (!this.storesList?.length) {
        return []
      }
      return [...Object.keys(this.storesList[0].properties), 'Store sets']
    }
  },
  watch: {
    storesList: {
      handler (stores) {
        if (!stores?.length || this.selectedStores) {
          return
        }
        this.handleValuesChange()
      },
      immediate: true
    }
  },
  methods: {
    handleDirectStoresChange (val) {
      this.resetPropertyList()
      this.setSelectedStores(new Set(val))
    },
    resetPropertyList () {
      this.propertyList = []
    },
    setSelectedStores (stores) {
      this.$emit('update:selectedStores', Array.from(stores.values()))
      this.$emit('search-results-changed', stores)
    },
    deleteProperty (index) {
      this.propertyList.splice(index, 1)
      this.setSelectedStoresToAll()
      this.handleValuesChange()
    },
    // Get intersection of stores by store sets
    getStoresByStoreSets (storeSets) {
      if (!storeSets.length) {
        return new Set()
      }
      const storesByStoreSetsArr = storeSets.map(storeSet => {
        return new Set(storeSet.store_keys)
      })
      return unionOfSets(storesByStoreSetsArr)
    },
    getStoresByProperty (prop, values) {
      const stores = this.storesList.filter(store => {
        return values.includes(store.properties[prop])
      })
      return new Set(stores.map(item => item.store_key))
    },
    setSelectedStoresToAll () {
      this.setSelectedStores(new Set(this.storesList.map(item => item.store_key)))
    },
    getStoresByPropertyList (propertyList) {
      let stores = new Set(this.storesList.map(item => item.store_key))
      propertyList.forEach(listItem => {
        if (!listItem.selectedProperty || !listItem.selectedValue.length) {
          return
        }
        let storesByProperty
        if (listItem.selectedProperty === 'Store sets') {
          storesByProperty = this.getStoresByStoreSets(listItem.selectedValue)
        } else {
          storesByProperty = this.getStoresByProperty(listItem.selectedProperty, listItem.selectedValue)
        }
        stores = intersectionOfSets([stores, storesByProperty])
      })
      return stores
    },
    getStoreObjectsFromKeys (storeKeys) {
      return this.storesList.filter(store => storeKeys.has(store.store_key))
    },
    // If property is changed, reset the selected values since they are not valid anymore
    handlePropertyChange (item) {
      item.selectedValue = []
      this.handleValuesChange()
    },
    handleValuesChange () {
      if (!this.propertyList.length) {
        this.setSelectedStoresToAll()
        return
      }
      const filteredStores = this.getStoresByPropertyList(this.propertyList)
      // this.setSelectedStores(intersectionOfSets([this.selectedStores, filteredStores]))
      this.setSelectedStores(filteredStores)
    },
    getStoreSetsList () {
      return this.storeAndOptionSetsStore.storeSets?.map(item => ({
        store_keys: item.store_keys, label: item.set_name, value: item.stores_set_key
      }))
    },
    // Returns non-empty valued properties, it is an individualized list for each property
    getStoreProperties (currProp) {
      const stores = this.getStoresByPropertyList(this.propertyList)
      const storeObjects = this.getStoreObjectsFromKeys(stores)
      const properties = new Set()
      storeObjects.forEach((store) => {
        Object.keys(store.properties).forEach((prop) => {
          // Skip the already selected properties except the current one
          if (this.propertyList.find(item => item.selectedProperty === prop && item.selectedProperty !== currProp)) {
            return
          }
          if (store.properties[prop] !== undefined) {
            properties.add(prop)
          }
        })
      })
      // Add 'Store sets' property if it is not selected already or it is the current property
      if (currProp === 'Store sets' || !this.propertyList.find(item => item.selectedProperty === 'Store sets')) {
        properties.add('Store sets')
      }
      return Array.from(properties).map(item => ({ value: item, text: item }))
    },
    // Get unique values of store property from selected stores
    getStorePropertyValues (prop) {
      // Get stores filtered without the selected property
      const propertiesListWithoutProp = this.propertyList.filter(item => item.selectedProperty !== prop)
      const stores = this.getStoresByPropertyList(propertiesListWithoutProp)
      const storeObjects = this.getStoreObjectsFromKeys(stores)
      const values = new Set()
      storeObjects.forEach((store) => {
        if (store.properties[prop] !== undefined) {
          const value = store.properties[prop]
          values.add(value)
        }
      })
      return Array.from(values).map(item => ({ value: item, text: item }))
    },
    propertyValues ({selectedProperty }) {
      if (!selectedProperty) {
        return []
      }
      if (selectedProperty === 'Store sets') {
        return this.getStoreSetsList()
      }
      return this.getStorePropertyValues(selectedProperty)
    },
  },
  created () {
    this.resetPropertyList()
    this.storeAndOptionSetsStore.loadStoreSets()
  }
}
</script>
