<template>
  <div class="settings-configuration-page">
    <v-container fluid class="pa-0">
      <v-row class="my-2">
        <v-col class="py-0 d-flex align-center">
          <h3
            class="my-6 mr-2 breadcrumb-lvl-0"
            @click="$router.push({ name: currentModule + '-settings-main' })"
          >Settings</h3>/
          <h3
            class="my-6 mx-2 breadcrumb-lvl-0"
            @click="$router.push({ name: currentModule + '-settings-configuration' })"
          >Configuration</h3>/
          <h3 class="my-6 ml-2">Buy</h3>
        </v-col>
      </v-row>
      <v-card class="my-4 mb-8">
        <v-tabs v-model="activeTab">
          <v-tabs-slider />
          <v-tab class="px-8" style="width: 260px">GENERAL</v-tab>
          <v-tab class="px-8" style="width: 260px">CURRENCY</v-tab>
          <v-tab class="px-8" style="width: 260px">SKU RANGES</v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab" class="flex xs12">
          <v-tab-item :transition="false" :reverse-transition="false" class="flex">
            <v-row>
              <buy-settings-general v-if="activeTab === 0"/>
            </v-row>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <v-row>
              <buy-settings-currencies v-if="activeTab === 1"/>
            </v-row>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <v-row>
              <SKURanges v-if="activeTab === 2"/>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>

import BuySettingsGeneral from './BuySettingsGeneral'
import BuySettingsCurrencies from './BuySettingsCurrencies'
import SKURanges from '@/components/BuySettingsConfiguration/SKURanges'
import { useGeneralStore } from '@/store/pinia/generalStore'
export default {
  components: { SKURanges, BuySettingsCurrencies, BuySettingsGeneral },
  data () {
    return {
      activeTab: 0,
      generalStore: useGeneralStore()
    }
  },
  computed: {
    currentModule () {
      return this.generalStore.currentModule
    },
  },
}
</script>

<style lang="scss">
.settings-configuration-page {
  .breadcrumb-lvl-0 {
    cursor: pointer;
    color: var(--text-sub-black);
  }
}
</style>
