<template>
  <el-card class="size-curve-table px-0">
    <div class="row text-right justify-content-end">
      <div class="col pt-4 px-7">
        <TableSearch @searchChanged="handleSearchChanged" />

        <DataExport
          @item-click="downloadData"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <data-tables
          :data="tableData"
          :filters="filters"
          :table-props="{
            'summary-method': getSummary,
            'show-summary': activeTabName === 'weeks',
          }"
          :page-size="['size_totals', 'size_curves'].includes(activeTabName) ? 10 : 5"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 pb-4 mr-2' + (['size_totals', 'size_curves'].includes(activeTabName) ? ' d-none' : '')
          }"
          class="mb-4">
          <el-table-column v-for="prop of tableKeys" :prop="prop" :label="prop" :key="prop" class-name="px-4">
            <template slot-scope="scope">
              <div>{{scope.row[prop]}}</div>
            </template>
          </el-table-column>
        </data-tables>
      </div>
    </div>
  </el-card>
</template>

<script>
import { downloadData } from '@/utils'
import TableSearch from '../TableSearch'
import DataExport from '../DataExport'
import { round } from 'lodash'

export default {
  components: { DataExport, TableSearch },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    activeTabName: {
      type: String,
      default: ''
    },
    tableKeys: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      filters: [
        {
          value: '',
        },
      ],
      items: [
        { title: 'CSV (comma)', value: ',' },
        { title: 'CSV (semicolon)', value: ';' },
        { title: 'CSV (semicolon, decimal comma)', value: 'dutch' }
      ]
    }
  },
  methods: {
    getSummary (params) {
      const sums = []
      const { columns } = params
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total'
          return
        }
        const emptyColumns = [ 'Week', 'Projection', 'Cover' ]
        if (emptyColumns.includes(column.property)) {
          sums[index] = ''
          return
        }
        sums[index] = round(this.tableData.reduce((acc, cur) => acc + cur[column.property], 0), 2)
      })
      sums[4] = (sums[2] / sums[3] * 100).toFixed(0)
      return sums
    },

    handleSearchChanged (val) {
      this.filters[0].value = val
      this.tasksListKey = 0
    },
    downloadData (sep) {
      const data = this.tableData.map(
        row => this.tableKeys.reduce(
          (acc, cur) => Object.assign(acc, { [cur]: row[cur] }),
          {}
        )
      )
      downloadData(sep, data, this.$route.query.option_key)
    },
  },

}
</script>

<style lang="scss">
.size-curve-table {
    .el-pagination {
        padding: 1.25rem 5px 0 5px;
    }
  .el-card__body {
    padding: 0;
  }
    .sc-table .pagination-wrap {
        text-align: right;
    }
}
</style>
