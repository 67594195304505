<template>
  <div id="strategy-warnings">
    <h6 class="text-subtitle-1 font-weight-medium">Warnings</h6>

    <div class="mx-n6">
      <v-tabs
        v-model="activeTab"
        grow
        show-arrows
      >
        <v-tabs-slider />
        <v-tab
          v-for="metric in Object.keys(warningsConfig)"
          :key="'tab-' + metric"
        >{{ warningsConfig[metric].caption }}</v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="activeTab"
      >
        <v-tab-item
          v-for="metric in Object.keys(warningsData)" :key="'tab-item-' + metric"
          :transition="false" :reverse-transition="false"
        >
          <data-tables
            v-if="warningsData[metric]"
            style="width: 100%"
            class="table"
            :data="warningsData[metric]"
            :page-size="warningsData[metric].length"
            :pagination-props="{
              pageSizes: [warningsData[metric].length],
              class: 'd-none',
            }"
          >
            <el-table-column
              v-for="(c, index) in (warningsData[metric].length ? Object.keys(warningsData[metric][0]) : [])"
              :key="c"
              :prop="c"
              :label="c | formatString"
              :label-class-name="index === 0 ? 'text-left': 'text-center'"
              sortable
              :column-key="c"
            >
              <template slot-scope="scope">
                <div :style="getCellStyle(scope.row, c)">
                  {{ formatMetric(scope.row[c], c) }}
                </div>
              </template>
            </el-table-column>
          </data-tables>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { round } from 'lodash'
import { colormapOTB } from '@/variables'
import { useCubesStore } from '@/store/pinia/cubesStore'

export default {
  name: 'StrategyWarnings',
  props: {
    warningsData: {
      type: Object || null,
      default: null,
    },
    warningsConfig: {
      type: Object || null,
      default: null,
    },
  },
  setup () {
    return {
      cubesStore: useCubesStore(),
    }
  },
  data () {
    return {
      activeTab: 0,
    }
  },
  computed: {
    metricFormats () {
      return this.cubesStore.metricFormat
    },
  },
  methods: {
    formatMetric (value, metricName) {
      const parts = metricName.toLowerCase().split(' (')
      let format = this.metricFormats[parts[0].replace(' ', '_')]

      if (metricName.substring(metricName.length - 5) === '(idx)') {
        format = 'integer'
      }

      if (parts[0].endsWith('%')) {
        value = round(parseFloat(value) * 100, 2)
        format = 'percentage'
      }

      if (!format && typeof value === 'number') {
        format = 'integer'
      }

      return format ? this.$options.filters[format](value) : value
    },
    getCellStyle (row, property) {
      const style = typeof row[property] === 'number' ? {
        textAlign: 'center'
      } : {}

      if (property === 'OTB') {
        Object.assign(style, {
          background: this.getCellGradeColor(row[property]),
          borderRadius: '4px',
          color: '#fff',
          padding: '13px 0',
        })
      }

      return style
    },
    getCellGradeColor (value) {
      const min = this.otbMinMax.min
      const max = this.otbMinMax.max
      const result = min === max ? 0.5 : ((value - min) / (max - min))

      return colormapOTB(result)
    },
  },
}
</script>

<style lang="scss">
#strategy-warnings {
    .el-table td {
        padding: 0;
    }
    .el-table__append-wrapper {
        position: sticky;
        bottom: 0;
    }
    .table .el-table__body-wrapper {
        max-height: 50vh;
        overflow-y: auto;
    }
    .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
        width: 4px;
    }
    .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    }
    .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 3px;
    }
    .total-row_column {
        background-color: #f5f7fa;
    }
}
</style>
