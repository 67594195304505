<template>
  <v-row class="pa-5 pt-0 my-7">
    <v-row justify="end">
      <div class="col text-right pr-6 pb-3">
        <TableSearch
          class="mx-2"
          @searchChanged="v => this.searchQuery = v"
        />
        <v-icon class="mx-2 action-btn-primary" @click="handleAddSingle">add</v-icon>
        <v-icon class="mx-2 action-btn-danger" :disabled="selectedRows.length < 1" @click="handleDeleteClick">delete</v-icon>
      </div>
    </v-row>
    <data-tables
      style="width: 100%"
      :data="filteredData"
      :page-size="10"
      :pagination-props="{ pageSizes: [5, 10, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
      :table-props="{ 'row-key': 'sku_range_key' }"
    >
      <el-table-column prop="properties_key" width="55">
        <template v-slot="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="mt-0 ml-2 pt-0"
            :value="scope.row.selected"
            :ripple="false"
            @input="handleSelectRowChange($event, scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        max-width="300"
        prop="sku_range_name"
        column-key="sku_range_name"
        label="Name"
      >
        <template v-slot="scope">
          <v-text-field
            class="pr-4"
            v-model="scope.row.sku_range_name"
            placeholder="SKU Name"
            @change="saveSkuRange(scope.row)"
            @keyup.enter="saveSkuRange(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="sku_names"
        column-key="sku_names"
        label="SKUs"
      >
        <template v-slot="scope">
          <v-combobox
            class="pr-8"
            v-model="scope.row.sku_names"
            placeholder="SKU One, SKU Two, SKU Three..."
            multiple
            chips
            small-chips
            deletable-chips
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @input="saveSkuRange(scope.row)"
          />
        </template>
      </el-table-column>
    </data-tables>
    <SKUModal :isOpen="addModalOpen" ref="propertyEditModal"
      @save="saveSkuRange" @close="addModalOpen = false"/>
    <delete-dialog
      content-text="Are you sure you want to delete those properties?"
      content-title="Delete Properties"
      :visible="deleteModalOpen"
      @confirm="handleDelete"
      @cancel="deleteModalOpen = false"
    />
  </v-row>
</template>

<script>
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'
import DeleteDialog from '@/components/DeleteDialog'
import { deleteSkuRange, getSkuRanges, upsertSkuRange } from '@/api/skusAPI'
import SKUModal from '@/components/BuySettingsConfiguration/SKUModal'

export default {
  name: 'SKURanges',
  mixins: [columnFilters],
  components: {
    SKUModal,
    DeleteDialog,
    TableSearch
  },
  data () {
    return {
      searchQuery: '',
      deleteModalOpen: false,
      addModalOpen: false,
      data: [],
      tableFilters: [],
    }
  },
  beforeMount () {
    this.getData()
  },
  computed: {
    filteredData () {
      return this.data.filter(r => {
        return this.searchQuery.length < 1 || r.sku_range_name.toLowerCase().includes(this.searchQuery.toLowerCase())
      })
    },
    selectedRows () {
      return this.data.filter(d => d.selected)
    },
  },
  methods: {
    async getData () {
      const skuRanges = getSkuRanges()
      this.data = skuRanges.reverse()
    },
    async handleDelete () {
      await deleteSkuRange(this.selectedRows[0])
      this.deleteModalOpen = false
      await this.getData()
    },
    async saveSkuRange (range) {
      if (!range) {
        range = this.selectedRows[0]
      }
      const res = await upsertSkuRange(range)
      this.updateSKURange(res.sku_range)
      this.addModalOpen = false
    },
    updateSKURange (range) {
      const index = this.data.findIndex(d => d.sku_range_key === range.sku_range_key)
      if (index > -1) {
        this.data[index] = range
        return
      }
      this.data = [range, ...this.data]
    },
    handleAddSingle () {
      this.addModalOpen = true
    },
    handleDeleteClick () {
      this.deleteModalOpen = true
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.data.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
    },
  }
}
</script>

<style scoped>

</style>
