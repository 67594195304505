import axios from '@/services/axios.js'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

export function getDatasets () {
  const url = baseURLs.tsqlAPI + '/analytics'
  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  }).then(({ data }) => data)
}

export function getDashboards () {
  const url = baseURLs.manageAPI + '/analytics_dashboards'
  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  }).then(({ data: { dashboards } }) => dashboards)
    .catch(e => console.log(e))
}

export function getSharedDashboards () {
  const url = baseURLs.manageAPI + '/analytics_dashboards_shared'
  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  }).then(({ data: { dashboards } }) => dashboards)
    .catch(e => console.log(e))
}

export function getViewsCopy () {
  const url = baseURLs.manageAPI + '/analytics_views_copy'
  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  }).then(({ data: { views } }) => views)
    .catch(e => console.log(e))
}

export function getViewDetails (viewKey) {
  const url = baseURLs.manageAPI + '/analytics_view?view_key=' + viewKey
  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  }).then(({ data: { view } }) => view)
    .catch(e => console.log(e))
}

export function getDashboardDetails (chosenDashboard) {
  const url = baseURLs.manageAPI + '/analytics_dashboard?dashboard_key=' + chosenDashboard
  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  }).then(({ data}) => data)
}

export function getDashboardManage () {
  const url = baseURLs.manageAPI + '/analytics_dashboards_manage'
  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  }).then(({ data: { dashboards } }) => dashboards)
    .catch(e => console.log(e))
}

export async function loadViewData (payload, view, totals) {
  const url = baseURLs.tsqlAPI + '/analytics'
  return axios.post(url, payload)
    .then(({ data }) => {
      if(payload.total && data.total){
        if(payload.properties.matrix){
          totals.totals_sum = [data.total]
        }else {
          totals.total_row = [data.total]
        }
      }
      view.limited = data.limited
      view.filters_applied = data.filters_applied
      view.refresh_time_ago = data.refresh_time_ago
      return payload.format === 'doa' ? performTableData(data.data, view) : data.data
    })
}

export async function downloadAnalytics (payload, child = false) {
  const url = baseURLs.tsqlAPI + '/analytics'
  payload = await performViewData(payload, false, payload.filters, true, child)
  return axios.post(
    url,
    payload)
    .then(({ data }) => {
      return data
    })
}

function performTableData (data, view) {
  const newData = []
  const keys = Object.keys(data)
  data[keys[0]].forEach((item, index) => {
    const newObject = {}
    keys.forEach((key) => {
      newObject[key] = data[key][index]
    })
    if(view?.properties?.child_rows){
      newObject.hasChildren = true
    }
    newData.push(newObject)
  })
  return newData
}

const getAnalyticsPayload = (view, expanded = false, additionalFilters = {})=> {
  let dimensions
  const filter = {}
  if (expanded) {
    dimensions = view[0].dimensions
    for (const dimension of dimensions) {
      filter[dimension] = [view[1][dimension]]
    }
    view = view[0]
  }
  if (!view.filters) {
    view.filters = {}
  }

  if (!view.properties) {
    view.properties = { }
  }
  const payload = { ...view, properties: { ...view.properties } }
  payload.measures = payload.measures.filter(item => item.calculation)
  payload.dimensions = view.dimensions ? [...view.dimensions] : []
  if(payload.chart_type === 'table') {
    if (payload.properties.matrix) {
      payload.dimensions.splice(0, 0, payload.properties.matrix)
    }
    if (expanded) {
      payload.dimensions.splice(0, 0, payload.properties.child_rows)
      payload.filters = {...payload.filters, ...filter}
      if (payload.properties.child_limit) {
        payload.properties.limit = parseInt(payload.properties.child_limit)
      }
    } else {
      payload.properties.limit = parseInt(payload.properties.parent_limit)
    }
  }
  payload.filters = { ...(additionalFilters || {}), ...payload.filters }
  if (payload.chart_type === 'table') {
    payload.format = 'doa'
  }

  return payload
}

const getTotalsData = async  (payload, view, totals)=> {
  const promises = []
  if ((payload.properties.total_row && payload.properties.matrix) || payload.chart_type === 'doughnut_chart') {
    const dimensions = []
    if (payload.properties.matrix) {
      dimensions.push(payload.properties.matrix)
    }
    promises.push(
      loadViewData({ ...payload, dimensions }, view)
        .then(data => totals.total_row = data)
        .catch((e)=> {
          console.log(e)
          totals.total_row = []
        })
    )
  }
  if (payload.properties.total_column && payload.properties.matrix) {
    const dimensions = payload.dimensions.slice(1)
    promises.push(
      loadViewData({ ...payload, dimensions }, view)
        .then(data => totals.total_column = data )
        .catch(()=> totals.total_column = [])
    )
  }
  await Promise.all(promises)
  return totals
}

export async function performViewData (view, expanded = false, additionalFilters = {}, needForDownload = false, downloadForChild = false) {
  const payload = getAnalyticsPayload(view, expanded, additionalFilters)
  if (needForDownload) {
    if (downloadForChild) {
      payload.dimensions = [...payload.dimensions, view.properties.child_rows]
      delete payload.limit
    }
    return payload
  }
  let data
  const totals = {total_row: null, total_column: null, totals_sum: null}
  try {
    const promises = []
    if(!expanded){
      promises.push(
        getTotalsData(payload, view, totals)
      )
    } else if (payload.properties.total_column && payload.properties.matrix) {
      const dimensions = payload.dimensions.filter(dimension => dimension !== payload.properties.matrix)
      promises.push(
        loadViewData( { ...payload, dimensions }, view).then(res => totals.total_column = res)
      )
    }
    if(payload.chart_type === 'doughnut_chart'){
      payload.properties.sort = payload.measures[0].name
      payload.properties.sort_order = 'ascending'
    }
    let callWithTotal = false
    if(payload.chart_type === 'table'){
      if(payload.properties.matrix){
        if(payload.properties.total_row && payload.properties.total_column){
          callWithTotal = true
        }
      }else if(payload.properties.total_row){
        callWithTotal = true
      }
    }
    promises.push(
      loadViewData({...payload, total: callWithTotal}, view, totals).then(res => {
        if (expanded) {
          payload.measuresProps = payload.measures.reduce((prev, cur) => {
            let name = cur.name
            let count = 1
            while(prev[name]){
              count++
              name = `${cur.name} (${count})`
            }
            prev[name] = cur
            return prev
          }, {})
          view.childViews = {
            view: payload,
            data: res,
          }
          data = { ...view }
          return
        }
        view.measuresProps = view.measures.reduce((prev, cur) => {
          let name = cur.name
          let count = 1
          while(prev[name]){
            count++
            name = `${cur.name} (${count})`
          }
          prev[name] = cur
          return prev
        }, {})
        data =  { view, data: res }
      })
    )
    await Promise.all(promises)
    // Once everything is resolved we can set totals to data
    if (expanded) {
      data.childViews.total_row = totals.total_row
      data.childViews.total_column = totals.total_column
      return data
    }
    return { ...data, ...totals }
  } catch (e) {
    console.error(e)
    return { view, errored: true }
  }
}

export function loadFilterProperties (dataset) {
  const url = baseURLs.tsqlAPI + '/analytics_properties'
  const dataSets = dataset === 'none' ? {} : { dataset }
  return axios.post(
    url,
    dataSets)
    .then(({ data }) => data)
    .catch(console.log)
}

export function saveDashboard (payload) {
  const url = baseURLs.manageAPI + '/analytics_dashboard'

  return axios.post(
    url,
    payload)
    .catch(e => console.log(e))
}

export function duplicateDashboard (payload) {
  const url = baseURLs.manageAPI + '/analytics_dashboards_manage'

  return axios.post(
    url,
    payload)
    .catch(e => console.log(e))
}

export function deleteDashboard (payload) {
  const url = baseURLs.manageAPI + '/analytics_dashboard'

  return axios.delete(
    url,
    {
      headers: getHeadersWithAccessToken(),
      data: payload,
    })
    .catch(e => console.log(e))
}

export function saveView (payload) {
  const url = baseURLs.manageAPI + '/analytics_view'
  payload.dashboard_key = parseInt(payload.dashboard_key)
  return axios.post(
    url,
    payload)
    .then(({ data: { view } }) => view)
    .catch(e => e)
}

export function setDashboardName (payload) {
  const url = baseURLs.manageAPI + '/analytics_dashboard'
  return axios.post(
    url,
    payload)
}
