import BuyLayout from '@/views/modules/BuyLayout.vue'
import BuyHomePage from '@/views/BuyHomePage.vue'
import SettingsMainPage from '@/views/SettingsMainPage.vue'
import StockSettingsDataPage from '@/views/StockSettingsDataPage.vue'
import StockSettingsUsersPage from '@/views/StockSettingsUsersPage.vue'
import BuyShopItemPage from '@/views/BuyShopItemPage.vue'
import BuyShoppingHomePage from '@/views/BuyShoppingHomePage.vue'
import BuyStudioItemPage from '@/views/BuyStudioItemPage.vue'
import BuyManageMain from '@/views/BuyManageMain.vue'
import BuyStudioHomePage from '@/views/BuyStudioHomePage.vue'
import DataSetsMainPage from '@/views/DataSetsMainPage.vue'
import BuyOrderHome from '@/views/BuyOrderHome.vue'
import BuySettingsConfigurationPage from '@/views/BuySettingsConfigurationPage.vue'
import StockSettingsConfigurationPage from '@/views/StockSettingsConfigurationPage.vue'
import SettingsConfiguration from '@/views/SettingsConfiguration.vue'
import StockSettingsStoresPage from '@/views/StockSettingsStoresPage.vue'

export const buyRoutes = {
  path: '/buy',
  component: BuyLayout,
  children: [
    {
      path: 'home',
      name: 'buy-home-page',
      component: BuyHomePage,
    },
    {
      path: 'reorder',
      name: 'buy-reorder-page',
      component: BuyOrderHome
    },
    {
      path: 'analytics',
      name: 'buy-analytics',
      component: DataSetsMainPage,
    },
    {
      path: 'studio',
      name: 'buy-studio-home-page',
      component: BuyStudioHomePage
    },
    {
      path: 'manage',
      name: 'buy-manage-main',
      component: BuyManageMain
    },
    {
      path: 'studio-item',
      name: 'buy-studio-item-page',
      component: BuyStudioItemPage
    },
    {
      path: 'shopping',
      name: 'buy-shopping-home-page',
      component: BuyShoppingHomePage
    },
    {
      path: 'shop-item',
      name: 'buy-shop-item-page',
      component: BuyShopItemPage
    },
    {
      path: 'settings',
      name: 'buy-settings-main',
      component: SettingsMainPage
    },
    {
      path: 'settings/data',
      name: 'buy-settings-data',
      component: StockSettingsDataPage
    },
    {
      path: 'settings/users',
      name: 'buy-settings-users',
      component: StockSettingsUsersPage
    },
    {
      path: 'settings/stores',
      name: 'buy-settings-stores',
      component: StockSettingsStoresPage
    },
    {
      path: 'settings/configuration',
      name: 'buy-settings-configuration',
      component: SettingsConfiguration
    },
    {
      path: 'settings/configuration/stock',
      name: 'buy-settings-configuration-stock',
      component: StockSettingsConfigurationPage
    },
    {
      path: 'settings/configuration/buy',
      name: 'buy-settings-configuration-buy',
      component: BuySettingsConfigurationPage,
    },
  ]
}
