<template>
  <v-snackbar
    multi-line
    dark
    max-width="300"
    v-model="generalStore.snackOpened"
  >
    <span class="snack-box">
      <span v-if="generalStore.snackType=== 'warning'">Something went wrong <br></span>
      {{generalStore.snackText}}
    </span>
    <template v-slot:action="{ attrs }">
      <v-btn
        color="primary"
        text
        v-bind="attrs"
        @click="generalStore.snackOpened = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>

import {useGeneralStore} from '@/store/pinia/generalStore'

export default {
  setup () {
    return {
      generalStore: useGeneralStore(),
    }
  },
  name: 'Snackbar',
}
</script>

<style lang="scss">
.snack-box {
  font-size: 18px;
}
</style>
