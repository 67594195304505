<template>
  <div v-if="resetRouteActive">
    <router-view/>
  </div>
  <v-app v-else :class="{ 'overlay-opened': overlayOpened, 'visible-scroll': analyticsFilterOpened }" @resize="zoomFix">
    <AccessDeniedDialog />
    <v-main class="main" v-if="!authRouteActive">
      <router-view/>
    </v-main>
    <router-view v-else/>
    <Snackbar/>
  </v-app>
</template>

<script>
import _ from 'lodash'
import { Chart } from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation'
import AccessDeniedDialog from './components/AccessDeniedDialog'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useAnalyticsFiltersStore } from '@/store/pinia/analyticsFiltersStore'
import { zoomFix } from '@/libs/zoomFix'
import Snackbar from '@/components/Snackbar.vue'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore(),
      analyticsFiltersStore: useAnalyticsFiltersStore(),
      generalStore: useGeneralStore(),
    }
  },
  components: {
    Snackbar,
    AccessDeniedDialog,
  },
  computed: {
    resetRouteActive () {
      return this.routeName === 'reset-me'
    },
    appConfig () {
      return this.generalStore.appConfig
    },
    filtersConfig () {
      return this.appConfig?.page_filters || null
    },
    routeName () {
      return this.$route?.name || ''
    },
    authRouteActive () {
      return this.routeName.includes('login') ||
        this.routeName.includes('forgot-password') ||
        this.routeName.includes('resend-token') ||
        this.routeName.includes('reset-password')
    },
    overlayOpened () {
      return this.filtersStore.filtersOpened || this.analyticsFiltersStore.filtersOpened
    },
    analyticsFilterOpened () {
      return this.filtersStore.filtersOpened || this.analyticsFiltersStore.filtersOpened
    },
  },
  watch: {
    $route (to) {
      this.setCurrentPageFilters(to)
    }
  },
  methods: {
    zoomFix,
    setCurrentPageFilters (pageRoute) {
      if (!this.filtersConfig) {
        return
      }
      const pathParts = pageRoute.path.split('/').filter(p => p.length)
      const pagesConfig = this.filtersConfig[pathParts[0]]
      let pageFiltersToSet = []

      if (pagesConfig) {
        const pageFilters = pagesConfig[pathParts[1]]

        if (pageFilters && pageFilters.length) {
          pageFiltersToSet = pageFilters
        }
      }

      this.filtersStore.setCurrentPageFilters(pageFiltersToSet.map(_.camelCase))
    },
  },
  async created () {
    Chart.register(annotationPlugin)
    Chart.register({
      id: 'zoomCSS',
      beforeEvent (chart, {event}) {
        const zoom = document.documentElement.style.getPropertyValue('--zoom') || 1
        if (zoom !== 1) {
          event.x = Math.round(event.native.offsetX / zoom)
          event.y = Math.round(event.native.offsetY / zoom)
        }
      }
    }
    )
    // If the user is logged in, start the interval to refresh the tokens and config
    this.zoomFix()
    window.onresize = this.zoomFix

    if (
      this.routeName !== 'reset-password' &&
      this.$auth.isAccessTokenValid()
    ) {
      // && !this.routeName.includes('storepage')
      this.$auth.refreshTokens()
      if (this.$auth.isAccessTokenValid()) {
        setInterval(this.$auth.refreshTokens.bind(this.$auth), 30000)
      }
    }
    await this.$auth.initAppConfig()
    setInterval(this.$auth.checkAppFullConfigExpiration.bind(this.$auth), 1000 * 60)
    this.setCurrentPageFilters(this.$route)
    // TODO explain why this is here
    localStorage.removeItem('cubeBrowserData')
  }
}
</script>

<style lang="scss">
.main {
  margin-top: 56px;
  background-color: #f9f9f9;
}
.v-application {
  &.overlay-opened:not(.visible-scroll){
    overflow: hidden;
    height: calc(100vh / var(--zoom));
  }
}

.main-layout-wrapper {
  margin-left: 102px;
}
@media (max-width: 960px) {
  .main-layout-wrapper {
    margin-left: 32px;
  }
}
</style>
