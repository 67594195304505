<template>
  <v-flex class="mx-0">
    <h3 class="mb-5">Properties</h3>
    <store-edit-property-entry
      v-for="(prop, index) in managedProperties"
      :key="index"
      :handle-prop-delete-click="handlePropDeleteClick"
      :prop="prop"
      :store="obj"
      :properties="managedProperties"
      @propertyChanged="handlePropertyChanged"
    />
    <v-row class="mt-0" v-if="!addManaged">
      <v-col cols="9">
        <v-icon class="plus-icon" @click="addManaged = true">add</v-icon>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col :cols="5">
        <v-autocomplete
          v-model="newManaged"
          item-text="property_name"
          return-object
          :items="remainingManagedProperties"
          label="Property"
          hide-details
          @change="handleNewManagedChanged"
        />
      </v-col>
      <v-col :cols="5" v-if="newManaged.property_name">
        <v-autocomplete
          v-if="newManaged.value_dropdown && newManaged.values"
          v-model="newManaged.value"
          :items="newManaged.values"
          label="Value"
          hide-details="auto"
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"
          @keyup.enter="handleNewManagedValueChanged"
        />
        <v-text-field
          v-else-if="newManaged.value_type !== 'boolean'"
          v-model="newManaged.value"
          label="Value"
          hide-details="auto"
          :type="newManaged.value_type === 'text' ? 'text' : 'number'"
          @keyup.enter="handleNewManagedValueChanged"
        />
        <v-simple-checkbox
          v-else
          v-model="newManaged.value"
          color="primary"
          dense
          hide-details
          class="mt-0 pt-0"
          :ripple="false"
        />
      </v-col>
      <v-col cols="2" v-if="addManaged">
        <v-icon v-if="newManaged.property_name" class="plus-icon mt-5 mr-2" @click="handleNewManagedValueChanged">save</v-icon>
        <v-icon class="plus-icon mt-5" @click="clearAddManaged">close</v-icon>
      </v-col>
    </v-row>
  </v-flex>
</template>

<script>
import { getObjectValues, getPropertiesOfStores } from '@/api/dataProperties'
import StoreEditPropertyEntry from '@/components/GeneralSettings/ModalPropertyEntry'

export default {
  name: 'ModalPropertyManagement',
  components: { StoreEditPropertyEntry },
  data () {
    return {
      addManaged: false,
      newManaged: '',
      properties: [],
      storeProperties: [],
    }
  },
  async mounted () {
    await this.getPropertiesOfStores()
    this.extractProperties()
  },
  props: {
    obj: {
      type: Object,
      required: true,
    },
    objectName: {
      type: String,
      default: 'stores',
    },
  },
  watch: {
    store: {
      handler () {
        this.extractProperties()
      }
    },
  },
  computed: {
    managedProperties () {
      if (!this.obj || !this.properties || !this.obj.properties || !this.storeProperties || !this.storeProperties.length) {
        return []
      }
      const managed = this.storeProperties.filter(prop => {
        return prop.force || this.obj.properties[prop.property_name] !== undefined || !prop.property_name
      }).sort((a, b) => a.force === b.force ? 0 : (a.force ? -1 : 1))
      const other = this.properties.filter(prop => {
        return !this.storeProperties.find(p => p.property_name === prop.property_name)
      })
      return managed.concat(other)
    },
    remainingManagedProperties () {
      if (!this.obj || !this.properties || !this.obj.properties || !this.storeProperties || !this.storeProperties.length) {
        return []
      }
      return this.storeProperties
        .filter(prop => {
          return !(prop.force || this.obj.properties[prop.property_name] !== undefined || !prop.property_name)
        })
        .sort((a, b) => a.force === b.force ? 0 : (a.force ? -1 : 1))
    },
  },
  methods: {
    addPropertiesToObject (properties) {
      properties.forEach(prop => {
        if (!this.obj.properties) {
          this.obj.properties = {}
        }
        if (!this.obj.properties[prop.property_name] && (prop.value !== undefined || prop.value_default !== undefined)) {
          this.obj.properties[prop.property_name] = prop.value || prop.value_default
        }
      })
    },
    handlePropertyChanged (name, value) {
      if (name !== undefined && value !== undefined) {
        this.$set(this.obj.properties, name, value)
        this.obj.properties = { ...this.obj.properties }
      }
      if (this.managedProperties.length === 0) {
        this.$emit('validation', true)
        return
      }
      const areValid = this.managedProperties.every(prop => {
        const value = this.obj.properties[prop.property_name]
        return !prop.force || !!value || value === false || !prop.property_name
      })
      this.$emit('validation', areValid)
    },
    async getPropertiesOfStores () {
      let data = await getPropertiesOfStores(this.objectName)
      data = data.filter(prop => prop.object_name === this.objectName)
      const values = (await getObjectValues(this.objectName)).data
      const objValues = {}
      values.forEach(value => {
        objValues[value.property_name] = value.values
      })
      data = data.map(prop => {
        if (prop.value_dropdown && objValues[prop.property_name]) {
          prop.values = objValues[prop.property_name]
          if (prop.values === undefined || prop.values.length === 0 ||
            prop.values[0] === undefined || prop.values[0] === null) {
            prop.values = null
          }
        }
        return prop
      })
      this.storeProperties = data
      this.addPropertiesToObject(this.storeProperties.filter(prop => prop.force))
    },
    extractProperties () {
      if (!this.obj || !this.obj.properties) {
        return
      }
      this.properties = Object.keys(this.obj.properties).map(key => {
        return {
          property_name: key,
          value: this.obj.properties[key],
          value_type: this.getValueType(this.obj.properties[key]),
          value_dropdown: false,
          force: false,
        }
      })
      this.handlePropertyChanged()
    },
    getValueType (v) {
      if (typeof v === 'boolean') {
        return 'boolean'
      } else if (typeof v === 'number') {
        return 'number'
      } else {
        return 'text'
      }
    },
    handlePropDeleteClick (prop) {
      delete this.obj.properties[prop.property_name]
      this.extractProperties()
    },
    handleNewManagedChanged () {
      if (this.newManaged.value_default) {
        this.newManaged.value = this.newManaged.value_default
      }
    },
    handleNewManagedValueChanged () {
      this.obj.properties[this.newManaged.property_name] = this.newManaged.value || ''
      this.extractProperties()
      this.newManaged = ''
      this.addManaged = false
    },
    clearAddManaged () {
      this.newManaged = ''
      this.addManaged = false
    },
  }
}
</script>
