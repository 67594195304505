<template>
  <div class="shop-item-order-form-crossdocks">
    <data-tables
      style="width: 100%"
      class="mb-4"
      :data="crossdocksRows"
      :page-size="crossdocksRows.length"
      :pagination-props="{ pageSizes: [crossdocksRows.length], hideOnSinglePage: true }">
      <el-table-column
        label="#"
        class-name="pl-8"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="store_keys" label="Stores" width="200" class-name="pr-4">
        <template slot-scope="scope">
          <collapsible-select
            label="Stores"
            class="pb-2"
            v-model="scope.row.store_keys"
            :items="getStoresOptions(scope.row)"
            :error-messages="scope.row.storeKeysError"
            autocomplete
            show-select-all
            hide-details
            @change="handleStoresChanged(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-for="sku in skuCols"
        :key="sku"
        :prop="sku"
        :label="sku"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.cross_dock && Number.isInteger(scope.row.cross_dock[sku])">
            {{ scope.row.cross_dock[sku] | formatThousands }}
          </template>
          <template v-else>
            -
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="total" label="Total">
        <template slot-scope="scope">
          {{ getTotal(scope.row) | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column prop="percentage" label="Percentage">
        <template slot-scope="scope">
          <v-text-field
            class="mb-5"
            type="number"
            v-model.number="scope.row.percentage"
            :error-messages="scope.row.percentageError"
            hide-details
            @keyup="handlePercentageChanged(scope.row)"
          ></v-text-field>
        </template>
      </el-table-column>
      <el-table-column class-name="pr-8">
        <template slot-scope="scope">
          <v-icon
            class="mx-2 action-btn-danger"
            @click="handleDeleteClick(scope.row)"
          >delete</v-icon>
        </template>
      </el-table-column>
    </data-tables>
    <div class="px-8">
      <v-icon @click="handleAddClick">add</v-icon>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  components: {
    CollapsibleSelect,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    orderLine: {
      type: Object,
      required: true,
    },
    skuCols: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      crossdocksRows: [],
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    crossdocksValid () {
      return this.crossdocksRows.every(cd => {
        return !cd.storeKeysError && !cd.percentageError
      })
    },
  },
  watch: {
    orderLine () {
      this.crossdocksRows = [ ...this.convertPercentageFromApi(this.orderLine.cross_docks) ]
    },
  },
  methods: {
    // convert percents to 0..1 form
    convertPercentageToApi (crossDocks) {
      return crossDocks.map(cd => ({ ...cd, percentage: cd.percentage / 100 }))
    },
    // convert percents to 0..100 form
    convertPercentageFromApi (crossDocks) {
      return crossDocks.map(cd => ({ ...cd, percentage: cd.percentage * 100 }))
    },
    getStoresOptions (row) {
      const otherCrossdocks = this.crossdocksRows.filter(cd => cd !== row)
      const otherCrossdocksStores = _.flatten(otherCrossdocks.map(cd => cd.store_keys))

      return (this.appConfig?.stores || [])
        .filter(s => {
          return this.item.store_keys.includes(s.store_key) &&
            !otherCrossdocksStores.includes(s.store_key)
        })
        .map(s => ({
          text: s.store_name,
          value: s.store_key,
        }))
    },
    getTotal (row) {
      if (!row.cross_dock) {
        return null
      }

      return this.skuCols.reduce((sum, sku) => sum + row.cross_dock[sku], 0)
    },
    validateCrossdocks () {
      this.crossdocksRows.forEach(cd => {
        this.$set(cd, 'storeKeysError', null)
        this.$set(cd, 'percentageError', null)

        if (!cd.store_keys.length) {
          this.$set(cd, 'storeKeysError', 'At least one required')
        }

        if (!Number.isInteger(cd.percentage) || cd.percentage < 0) {
          this.$set(cd, 'percentageError', 'Should be >= 0')
        }
      })
    },
    validateAndEmitCrossdocks () {
      this.validateCrossdocks()
      this.$emit('crossdocks-validated', this.crossdocksValid)
      this.$emit('crossdocks-changed', this.convertPercentageToApi(this.crossdocksRows))
    },
    handleAddClick () {
      this.crossdocksRows.push({
        store_keys: [],
        percentage: 0,
      })
      this.validateAndEmitCrossdocks()
    },
    handleStoresChanged () {
      this.validateAndEmitCrossdocks()
    },
    handlePercentageChanged () {
      this.validateAndEmitCrossdocks()
    },
    handleDeleteClick (row) {
      this.crossdocksRows.splice(this.crossdocksRows.indexOf(row), 1)
      this.validateAndEmitCrossdocks()
    },
  },
  created () {
    this.crossdocksRows = [ ...this.convertPercentageFromApi(this.orderLine.cross_docks) ]
  },
}
</script>

<style lang="scss">
.shop-item-order-form-crossdocks {
    .el-table::before {
        display: none;
    }
    .el-table__row:last-child {
        td {
            border-bottom: 0;
        }
    }
    .v-text-field {
        font-size: 13px;
    }
}
</style>
