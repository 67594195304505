export function getCurrentDateISO (): string {
  return new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().substring(0, 10)
}

export function FormatStringTimestamp(timestamp: string, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
  if (!timestamp) {
    return '';
  }
  // Parse the timestamp string into a Date object
  const date = new Date(timestamp);

  // Extract the necessary components from the Date object
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Replace the format placeholders with the corresponding values
  return format
    .replace('YYYY', year.toString())
    .replace('MM', month)
    .replace('DD', day)
    .replace('HH', hours)
    .replace('mm', minutes)
    .replace('ss', seconds);
}
