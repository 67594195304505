<template>
  <div class="shop-item-order-form-schedule">

    <v-row class="align-center ma-0 px-15 mb-4">
      <v-col class="d-flex align-center py-0 px-6">
      </v-col>
      <v-col class="pa-0 justify-center">
        <div class="d-flex align-center justify-space-between">
          <v-icon @click="reduceDates">
            arrow_left
          </v-icon>
          <h3 class="mx-2">
            Move dates
          </h3>
          <v-icon @click="increaseDates">
            arrow_right
          </v-icon>
        </div>
      </v-col>
      <v-col></v-col>
      <v-col></v-col>
    </v-row>
    <div v-for="(step, index) in scheduleSteps" :key="index" class="d-flex px-6 mb-4">
      <div class="ruleblock-mark p-relative d-flex align-center">
        <v-icon>radio_button_checked</v-icon>
      </div>
      <div class="py-3 px-6 grow ruleblock-content">
        <v-row class="align-center">
          <v-col class="d-flex align-center py-0">
            {{ step.point | formatString }}
          </v-col>
          <v-col class="d-flex align-center py-0">
            <v-menu
              :close-on-content-click="false"
              min-width="auto"
              offset-x
              class="date-picker"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="pt-0"
                  :value="`${step.date_key} (W${format((new Date(step.date_key)), 'ww')})`"
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                :locale-first-day-of-year="4"
                :show-week="true"
                v-model="step.date_key"
                @change="handleStepChanged"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="d-flex align-center justify-center py-0">
            <div class="ml-4 mr-2">Tasks:</div>
            <v-switch
              v-model="step.task"
              @change="handleStepChanged"
              dense
            ></v-switch>
          </v-col>
          <v-col class="text-right justify-end">

          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  components: {
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    orderSchedule: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      scheduleSteps: [],
      newStep: null,
    }
  },
  watch: {
    orderSchedule () {
      this.scheduleSteps = [ ...this.orderSchedule ]
    }
  },
  methods: {
    reduceDates () {
      this.scheduleSteps.forEach((item) => {
        const stepDate = new Date(item.date_key)
        stepDate.setDate(stepDate.getDate() - 1)

        const newDate = stepDate.toISOString().substr(0, 10)
        this.$set(item, 'date_key', newDate)
      })
    },
    format,
    increaseDates () {
      this.scheduleSteps.forEach((item) => {
        const stepDate = new Date(item.date_key)
        stepDate.setDate(stepDate.getDate() + 1)
        const newDate = stepDate.toISOString().substr(0, 10)
        this.$set(item, 'date_key', newDate)
      })
    },
    emitSchedule () {
      this.$emit('schedule-changed', this.scheduleSteps)
    },
    handleStepChanged () {
      this.emitSchedule()
    },
  },
  created () {
    this.scheduleSteps = [ ...this.orderSchedule ]
  },
}
</script>

<style lang="scss">
.shop-item-order-form-schedule {
    .ruleblock-mark {
        width: 40px;
        .v-icon {
            cursor: pointer;
        }
    }
    .ruleblock-header {
        cursor: pointer;
    }
    .ruleblock-content {
        border: 1px solid var(--border-color);
        .block-name-select {
            width: 200px;
        }
    }
}
</style>
