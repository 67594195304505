<template>
  <div class="settings-configuration-page">
    <v-container fluid class="pa-0">
      <v-row class="my-2">
        <v-col class="py-0 d-flex align-center">
          <h3
            class="my-6 mr-2 breadcrumb-lvl-0"
            @click="$router.push({ name: currentModule + '-settings-main' })"
          >Settings</h3>/
          <h3
            class="my-6 mx-2 breadcrumb-lvl-0"
            @click="$router.push({ name: currentModule + '-settings-configuration' })"
          >Configuration</h3>/
          <h3 class="my-6 ml-2">Stock</h3>
        </v-col>
      </v-row>
      <v-card class="my-4">
        <v-tabs v-model="activeTab">
          <v-tabs-slider />
          <v-tab class="px-8" style="width: 260px">Transfers</v-tab>
        </v-tabs>
        <v-card class="flex">
          <TransfersSettings></TransfersSettings>
          <transfers-combinations></transfers-combinations>
        </v-card>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import TransfersSettings from './TransfersSettings'
import TransfersCombinations from './TransfersCombinations'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  components: {
    TransfersCombinations,
    TransfersSettings
  },
  data () {
    return {
      activeTab: 0,
      generalStore: useGeneralStore()
    }
  },
  computed: {
    currentModule () {
      return this.generalStore.currentModule
    },
  },
}
</script>

<style lang="scss">
.settings-configuration-page {
    .breadcrumb-lvl-0 {
        cursor: pointer;
        color: var(--text-sub-black);
    }
}
</style>
