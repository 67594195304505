<template>
  <v-card class="rounded-0">
    <v-overlay v-if="false" absolute color="white" :value="saving">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">
      {{this.outerValues ? 'Edit Combination' : 'Add Combination'}}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="px-0 text--primary">
      <div class="container px-12">
        <v-row class="align-baseline mt-0">
          <v-col class="py-0">
            <collapsible-select
              :items="appConfig.stores"
              v-model="values.from_stores"
              label="From Stores"
              item-text="store_name"
              :disabled="!!outerValues"
              item-value="store_key"
              :error-messages="errors.storeErrors"
              show-select-all
              autocomplete
              @change="fromStoresChangedDirectly"
              @input="(value)=>errors.storeErrors = !value ? 'Can not be Empty' : ''"
            />
          </v-col>
        </v-row>
        <StoresSelectByProperties
          ref="fromStoresSelectByProperties"
          :selected-stores="values.from_stores"
          @search-results-changed="handleFromStoresSearchResultsChanged"
        />
        <v-row class="align-baseline mt-3">
          <v-col class="py-0">
            <collapsible-select
              :items="appConfig.stores"
              v-model="values.to_stores"
              :disabled="!!outerValues"
              item-text="store_name"
              label="To Stores"
              autocomplete
              :error-messages="errors.storeKeysErrors"
              show-select-all
              item-value="store_key"
              @change="toStoresChangedDirectly"
              @input="(value)=>errors.storeKeysErrors = !value.length ? 'Can not be Empty' : ''"
            />
          </v-col>
        </v-row>
        <StoresSelectByProperties
          ref="toStoresSelectByProperties"
          :selected-stores="values.to_stores"
          @search-results-changed="handleToStoresSearchResultsChanged"
        />
        <v-row class="align-baseline mt-3 ">
          <v-col class="py-0">
            <v-text-field
              type="number"
              v-model.number="values.costs"
              label="Costs"
              step=".1"
              :error-messages="errors.costsErrors"
              @input="(value)=> errors.costsErrors = value < 0 ? 'Must be 0 or higher than 0': ''"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-baseline mt-3">
          <v-col class="py-0">
            <v-text-field
              type="number"
              v-model.number="values.days"
              label="Days"
              step="1"
              :error-messages="errors.daysErrors"
              @input="(value)=> errors.daysErrors = value < 0 ? 'Must be 0 or higher than 0': ''"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-col class="align-baseline mt-0 mt-4 my-0 py-0 mx-0" cols="6">
          <v-row class="py-0" align="center" justify="space-between">
            <label>Allowed</label>
            <v-checkbox
              v-model="values.allowed"
            ></v-checkbox>
          </v-row>
          <v-row class="py-0 my-0" align="center" justify="space-between">
            <label>Automated</label>
            <v-checkbox
              v-model="values.automated"
            ></v-checkbox>
          </v-row>
        </v-col>
      </div>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-row class="dialog-footer text-right py-2 px-4">
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="$emit('close')"
              >Cancel</v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                class="mx-4"
                @click="saveCombinations"
              >
                {{this.outerValues ? 'Update' : 'Create'}}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import CollapsibleSelect from './CollapsibleSelect'
import StoresSelectByProperties from '@/components/StoresSelectByProperties.vue'
import { addCombinations, editCombinations } from '@/api/combinations'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'TransfersCombinationsModal',
  components: { CollapsibleSelect, StoresSelectByProperties },
  props: ['outerValues'],
  data () {
    return {
      saving: false,
      values: {
        allowed: true,
        automated: false,
        to_stores: [],
        from_stores: [],
        costs: 0,
        days: 0,
      },
      errors: {
        costsErrors: '',
        daysErrors: '',
        storeErrors: '',
        storeKeysErrors: '',
      },
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
  },
  mounted () {
    if (this.outerValues) {
      this.values = this.outerValues
    }
  },
  methods: {
    toStoresChangedDirectly (val) {
      this.$refs.toStoresSelectByProperties?.handleDirectStoresChange(val)
    },
    fromStoresChangedDirectly (val) {
      this.$refs.fromStoresSelectByProperties?.handleDirectStoresChange(val)
    },
    async saveCombinations () {
      this.saving = true
      this.errors.storeErrors = this.values.store_key === null ? 'Can not be Empty' : ''
      this.errors.storeKeysErrors = !this.values.to_stores.length ? 'Can not be Empty' : ''
      for (const key in this.errors) {
        if (this.errors[key]) {
          return
        }
      }
      delete this.values.selected
      const updateFunction = this.outerValues ? editCombinations : addCombinations
      await updateFunction(this.values)
      this.saving = false
      this.$emit('close')
      this.$emit('save')
    },
    handleFromStoresSearchResultsChanged (val) {
      this.values.from_stores = Array.from(val)
    },
    handleToStoresSearchResultsChanged (val) {
      this.values.to_stores = Array.from(val)
    },
  }
}
</script>

<style scoped>

</style>
