<template>
  <div class="stock-overview-options-table">
    <v-row>
      <v-col class="p-relative">
        <v-overlay absolute color="white" :value="stockOverviewStore.optionsTableDataLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <h6 class="text-subtitle-1 font-weight-medium ml-6">Options table</h6>
        <v-row>
          <v-col :cols="6" :offset="3" class="d-flex justify-center">
            <h6 class="text-subtitle-1 font-weight-medium" v-if="dimension1Value">
              {{ selectedDimension1 | formatString }} = {{ dimension1Value }}
            </h6>
          </v-col>
          <v-col :cols="3" class="text-right pr-6">
            <TableSearch class="mx-2" @searchChanged="handleOptionsSearchChanged" />
            <DataExport
              @item-click="downloadData"
            />
          </v-col>
        </v-row>
        <div class="row center-align">
          <div class="col-12">
            <data-tables
              style="width: 100%"
              :key="optionListKey"
              :tableProps="tableProps"
              :filters="optionsTableFilters"
              :data="tableData"
              :page-size="10"
              :pagination-props="{
                pageSizes: [5, 10,15, 25, 50],
                class: 'el-pagination text-right mt-6 mb-4 mr-2'
              }"
            >
              <el-table-column
                prop="option"
                label="Option"
                :width="220"
                sortable>
                <template slot-scope="scope">
                  <OptionDataCell
                    :option="scope.row"
                    :url="scope.row.route"
                    name-key="option_name"
                    open-in-new-tab
                    class="ml-4"
                  />
                </template>
              </el-table-column>
              <el-table-column
                v-for="col in columns"
                :key="col"
                :prop="col"
                :label="col | formatString"
                sortable>
                <template slot-scope="scope">
                  <div v-if="col.indexOf('perc') !== -1" :style="getCellStyle(scope.row, col)">
                    {{ scope.row[col] + '%' }}
                  </div>
                  <div v-else :style="getCellStyle(scope.row, col)">
                    {{ scope.row[col] | formatThousands }}
                  </div>
                </template>
              </el-table-column>
            </data-tables>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { downloadData } from '@/utils'
import { colormapCover } from '@/variables'
import OptionDataCell from '@/components/OptionDataCell.vue'
import TableSearch from '@/components/TableSearch.vue'
import DataExport from '../DataExport'
import { useStockOverviewStore } from '@/store/pinia/stockOverviewStore'

export default {
  setup () {
    return {
      stockOverviewStore: useStockOverviewStore(),
    }
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 15,
      optionListKey: 0,
      optionsTableFilters: [
        {
          value: '',
          prop: 'option_name',
        }
      ],
      tableProps: {
        'cell-class-name': this.getCellClassName,
        'header-cell-class-name': this.getHeaderCellClassName,
      },
    }
  },
  components: {
    DataExport,
    OptionDataCell,
    TableSearch,
  },
  computed: {
    selectedDimension1 () {
      return this.stockOverviewStore.selectedDimensions[0]
    },
    selectedDimension2 () {
      return this.stockOverviewStore.selectedDimensions[1]
    },
    dimension1Value () {
      return this.stockOverviewStore.dimension1Value
    },
    selectedView () {
      return this.stockOverviewStore.selectedView
    },
    columns () {
      return this.stockOverviewStore.optionsColumnsList
    },
    tableData () {
      return this.stockOverviewStore.optionsTableData || []
    },
    currentPageRows () {
      return this.tableData.slice(this.pageSize * this.currentPage - this.pageSize, this.pageSize * this.currentPage)
    },
    colorProperties () {
      return this.stockOverviewStore.optionsColorData || []
    },
    minMaxByProperty () {
      return this.colorProperties
        .map(p => p.name)
        .reduce((acc, cur) => {
          return {
            ...acc,
            [cur]: {
              min: Math.min.apply(Math, this.tableData.map(r => r[cur])),
              max: Math.max.apply(Math, this.tableData.map(r => r[cur])),
            },
          }
        }, {})
    },
  },
  methods: {
    getRowKey (row) {
      return row.dimension
    },
    setCurrentPage (val) {
      this.currentPage = val
    },
    setPageSize (val) {
      this.pageSize = val
    },
    getCellStyle (row, property) {
      if (this.colorProperties.map(p => p.name).includes(property)) {
        return {
          background: this.getCellGradeColor(property, row[property]),
          borderRadius: '4px',
          color: '#fff',
          padding: '13px 0',
        }
      }
    },
    getCellClassName ({ column }) {
      return ['width', 'depth'].includes(column.property) ? 'has-borders' : ''
    },
    getHeaderCellClassName ({ columnIndex }) {
      return columnIndex === 0 ? 'option-cell pl-4' : ''
    },
    getCellGradeColor (property, value) {
      const colorData = this.colorProperties.find(p => p.name === property)
      const min = (colorData && colorData.min) || this.minMaxByProperty[property].min
      const max = (colorData && colorData.max) || this.minMaxByProperty[property].max
      const result = (value - min) / (max - min)

      return colormapCover(result)
    },
    downloadData (sep) {
      const columns = ['option_name', 'option_key', ...this.columns].slice()
      const data = this.tableData.map(
        row => columns.reduce(
          (acc, cur) => Object.assign(acc, { [cur]: row[cur] }),
          {}
        )
      )

      downloadData(sep, data, 'overview_options_overview')
    },
    handleOptionsSearchChanged (val) {
      this.optionsTableFilters[0].value = val
      this.optionListKey++
    },
  },
}
</script>

<style lang="scss">
.stock-overview-options-table {
    table .cell {
        text-align: center;
    }
    table .option-cell .cell  {
        text-align: left;
    }
    td:nth-of-type(1) .cell {
        text-align: left;
    }
    .sc-table .pagination-wrap {
        text-align: right;
    }
    .el-table td {
        padding: 0;
    }
    .el-table .cell {
        padding: 0 10px;
    }
    .cell-content {
        padding: 10px;
    }
}
</style>
