export const dimensionsChart1 = {
  'view_key': 2762,
  'dataset': 'stock_performance',
  'chart_type': 'bar_chart',
  'dimensions': [],
  'measures': [{
    'axis': 'y1',
    'name': 'Stock',
    'type': 'bar',
    'color_code': '#FF9800',
    'calculation': 'stock',
    'fill_color_code': '',
    'border_color_code': '#007AFF'
  }, {
    'axis': 'y1',
    'name': 'Needed',
    'type': 'bar',
    'color_code': '#FF9800',
    'calculation': 'needed',
    'fill_color_code': '#FF9800',
    'border_color_code': '#FF9800'
  }, {
    'axis': 'y1',
    'name': 'Must have',
    'type': 'bar',
    'hidden': true,
    'color_code': '#FF9800',
    'calculation': 'must_have',
    'fill_color_code': '',
    'border_color_code': '#FFD202'
  }, {
    'axis': 'y1',
    'name': 'Needed%',
    'type': 'point',
    'color_code': '#085D70',
    'percentage': true,
    'calculation': ['needed', '/', 'must_have'],
    'fill_color_code': '',
    'border_color_code': '#FF9800'
  }],
  'filters': {},
  'properties': {
    'axis': {
      'y1': 'Pieces',
      'y2': '%',
      'y2_max': 100,
      'y2_min': 0
    },
    'sort': 'Needed',
    'bar_type': 'shadow',
    'sort_order': 'descending',
    'child_limit': 10000,
    'parent_limit': 25,
    'limit': 25
  },
  'view_name': 'Bar dimension 1',
  'creation_date': '2024-11-28T09:00:42.548049',
  'user_id': 3
}

export const dimensionsChart2 = {
  'view_key': 2763,
  'dataset': 'stock_performance',
  'chart_type': 'bar_chart',
  'dimensions': [],
  'measures': [{
    'axis': 'y1',
    'name': 'Stock',
    'type': 'bar',
    'color_code': '#FF9800',
    'calculation': 'stock',
    'fill_color_code': '',
    'border_color_code': '#007AFF'
  }, {
    'axis': 'y1',
    'name': 'Needed',
    'type': 'bar',
    'color_code': '#FF9800',
    'calculation': 'needed',
    'fill_color_code': '#FF9800',
    'border_color_code': '#FF9800'
  }, {
    'axis': 'y1',
    'name': 'Must have',
    'type': 'bar',
    'hidden': true,
    'color_code': '#FF9800',
    'calculation': 'must_have',
    'fill_color_code': '',
    'border_color_code': '#FFD202'
  }, {
    'axis': 'y1',
    'name': 'Needed%',
    'type': 'point',
    'color_code': '#085D70',
    'percentage': true,
    'calculation': ['needed', '/', 'must_have'],
    'fill_color_code': '',
    'border_color_code': '#FF9800'
  }],
  'filters': {},
  'properties': {
    'axis': {
      'y1': 'Pieces',
      'y2': '%',
      'y2_max': 100,
      'y2_min': 0
    },
    'sort': 'Needed',
    'bar_type': 'shadow',
    'sort_order': 'descending',
    'child_limit': 10000,
    'parent_limit': 20,
    'limit': 20
  },
  'view_name': 'Bar dimension 2',
  'creation_date': '2024-11-28T09:00:42.560590',
  'user_id': 3
}

export const neededTableView = {
  'view_key': 2764,
  'dataset': 'stock_performance',
  'chart_type': 'table',
  'dimensions': [],
  'measures': [{
    'axis': 'y1',
    'name': 'Stores',
    'type': 'bar',
    'calculation': 'stores'
  }, {
    'axis': 'y1',
    'name': 'Options',
    'type': 'bar',
    'calculation': 'options'
  }, {
    'axis': 'y1',
    'name': 'SKUs',
    'type': 'bar',
    'calculation': 'skus'
  }, {
    'axis': 'y1',
    'name': 'SKU names',
    'type': 'bar',
    'calculation': 'sku_names'
  }, {
    'axis': 'y1',
    'name': 'Fairstock',
    'type': 'bar',
    'calculation': 'fairstock'
  }, {
    'axis': 'y1',
    'name': 'Availability%',
    'type': 'bar',
    'decimals': 2,
    'percentage': true,
    'calculation': 'availability'
  }, {
    'axis': 'y1',
    'name': 'Must have',
    'type': 'bar',
    'calculation': 'must_have'
  }, {
    'axis': 'y1',
    'name': 'Needed',
    'type': 'bar',
    'calculation': 'needed'
  }, {
    'max': '100',
    'min': '0',
    'axis': 'y1',
    'name': 'Needed %',
    'type': 'bar',
    'first': 25,
    'third': 75,
    'second': 50,
    'percentage': true,
    'calculation': ['needed', '/', 'must_have'],
    'first_color': '#9BE48E',
    'third_color': '#FF9800',
    'second_color': '#FFD202',
    'color_grading': true,
    'max_color_code': '#FF5722',
    'min_color_code': '#4CAF50'
  }],
  'filters': {
    'properties/article/sku_has_stock': ['true'],
    'properties/settings/store_included': ['true']
  },
  'properties': {
    'rows': 10,
    'sort': 'Needed',
    'total_row': true,
    'sort_order': 'descending',
    'child_limit': 10000,
    'parent_limit': 10000,
    'limit': 10000
  },
  'view_name': 'Needed Dimension 1 x Dimension 2',
  'creation_date': '2024-11-28T09:00:42.578635',
  'user_id': 3,
  'format': 'doa'
}

export const neededMatrixView = {
  'view_key': 2765,
  'dataset': 'stock_performance',
  'chart_type': 'table',
  'dimensions': ['properties/article/hierarchy_2'],
  'measures': [{
    'axis': 'y1',
    'name': 'Needed',
    'type': 'bar',
    'first': 25,
    'third': 75,
    'width': 65,
    'second': 50,
    'calculation': 'needed',
    'first_color': '#9BE48E',
    'third_color': '#FF9800',
    'second_color': '#FFD202',
    'color_grading': true,
    'max_color_code': '#FF5722',
    'min_color_code': '#4CAF50'
  }, {
    'axis': 'y1',
    'name': 'Must have',
    'type': 'bar',
    'width': 65,
    'calculation': 'must_have'
  }, {
    'axis': 'y1',
    'name': 'Needed%',
    percentage: true,
    'type': 'bar',
    'width': 65,
    'calculation': ['needed', '/', 'must_have']
  }, {
    'axis': 'y1',
    'name': 'Stores',
    'type': 'bar',
    'width': 65,
    'calculation': 'stores'
  }, {
    'axis': 'y1',
    'name': 'Options',
    'type': 'bar',
    'width': 65,
    'calculation': 'options'
  }, {
    'axis': 'y1',
    'name': 'SKU names',
    'type': 'bar',
    'width': 65,
    'calculation': 'sku_names'
  }, {
    'axis': 'y1',
    'name': 'SKUs',
    'type': 'bar',
    'width': 65,
    'calculation': 'skus'
  }],
  'filters': {
    'properties/article/sku_has_stock': ['true']
  },
  'properties': {
    'rows': 10,
    'sort': 'Needed',
    'matrix': 'properties/article/hierarchy_2',
    'total_row': true,
    'sort_order': 'descending',
    'total_column': true,
    'limit': null
  },
  'view_name': 'Needed matrix (Dynamic column selection)',
  'creation_date': '2024-11-28T09:00:42.590046',
  'user_id': 3,
  'format': 'doa'
}

export const neededTableOptionsLoad = {
  'view_key': 2805,
  'dataset': 'stock_performance',
  'chart_type': 'table',
  'dimensions': ['properties/article/option_key'],
  'measures': [{
    'axis': 'y1',
    'name': 'Economical',
    'type': 'bar',
    'calculation': 'economical'
  }],
  'filters': {
    'properties/article/sku_has_stock': ['true'],
    'properties/settings/store_included': ['true']
  },
  'properties': {
    'sort': null,
    'total_row': false,
    'limit': null
  },
  'view_name': 'Filtered options',
  'creation_date': '2024-12-16T16:15:49.794643',
  'user_id': 3,
  'format': 'doa'
}
