<template>
  <div class="mx-6 mx-md-12">
    <StorePrintNavBar  ref="sideBar" v-if="storePrintActive"/>
    <StoreNavBar v-else  ref="sideBar"/>
    <StorePageMenu :isPrint="storePrintActive" />
  </div>

</template>
<script lang="ts">
import {openSidebarMixin} from '@/views/modules/openSidebarMixin'
import StorePrintNavBar from '@/components/StorePrintNavBar.vue'
import StoreNavBar from '@/components/StoreNavBar.vue'
import StorePageMenu from '@/storepage/StorePageMenu.vue'

export default {
  name: 'StockLayout',
  components: {StorePageMenu, StoreNavBar, StorePrintNavBar},
  mixins: [openSidebarMixin],
  computed: {
    storePrintActive () {
      return this.$route.name === 'storepage-transfers-print'
    },
  }
}
</script>
