<template>
  <div class="stacked-progress-bar" :style="{ backgroundColor }">
    <v-tooltip
      v-for="(s, i) in segments"
      :key="i"
      top
      :max-width="250"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          class="segment"
          :style="{
            backgroundColor: s.color,
            width: s.width,
          }"
        ></div>
      </template>
      <span>{{ s.caption }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: '#e3e3e3',
    },
    segments: {
      type: Array,
      default: () => [],
    }
  },
  data () {
    return {
    }
  },
}
</script>

<style lang="scss">
.stacked-progress-bar {
    display: flex;
    border-radius: 3px;
    overflow: hidden;
}
</style>
