<template>
  <div class="settings-users-list p-relative">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="ml-4 mt-2">
      <v-col cols="2">
        <v-select
          v-model="selectedUser"
          label="User"
          :items="usersSelectList"
          @change="refetch"
          hide-details
        />
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="selectedModule"
          label="Module"
          :items="modules"
          @change="refetch"
          hide-details
        />
      </v-col>
      <v-col cols="2">
        <v-menu
          ref="dateMinPicker"
          v-model="dateMinPickerActive"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              clearable
              hide-details
              :value="selectedFromDate"
              label="From Date"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :first-day-of-week="1"
            :locale-first-day-of-year="4"
            :show-week="true"
            no-title
            @input="refetch"
            v-model="selectedFromDate"
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="px-3 mt-6">
      <UserActivityGraph
        :data="userActivity.data"
        :users="usersWithEvents"
        :loading="loading"
      />
    </v-row>
    <h6 class="text-subtitle-1 font-weight-medium mb-4 mt-6 ml-6">User overview</h6>
    <v-row>
      <v-col>
        <data-tables
          ref="usersTable"
          style="width: 100%"
          :data="usersTableData.data"
          :default-sort="defaultSort"
          :page-size="10"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :row-class-name="selectedRowClass"
          @row-click="handleRowClick">
          <el-table-column width="70px">
            <template v-slot="scope">
              <div class="text-center">
                <avatar
                  :first-name="scope.row.first_name"
                  :last-name="scope.row.last_name"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column v-for="key in usersTableData.keys" :key="key" :prop="key" :label="formatString(key)" sortable="custom">
            <template v-slot="scope">
              {{ scope.row[key] }}
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>
    <v-row class="px-3">
      <ModuleActivityGraph
        :data="moduleActivity.data"
        :modules="rawModules"
        :loading="loading"
      />
    </v-row>

  </div>
</template>
<script>
import { useGeneralStore } from '@/store/pinia/generalStore'
import { getModuleActivity, getUserActivity } from '@/api/userAPI'
import { formatString } from '@/utils'
import Avatar from '@/components/Users/Avatar.vue'
import { FormatStringTimestamp, getCurrentDateISO } from '@/libs/date_utils'
import UserActivityGraph from '@/components/GeneralSettings/UserActivityGraph.vue'
import ModuleActivityGraph from '@/components/GeneralSettings/ModuleActivityGraph.vue'

export default {
  name: 'UserActivity',
  components: { ModuleActivityGraph, UserActivityGraph, Avatar },
  setup () {
    return {
      generalStore: useGeneralStore()
    }
  },
  data () {
    return {
      defaultSort: { prop: 'events_total', order: 'descending' },
      loading: false,
      userActivity: {
        keys:  [
          'date',
          'user_id',
          'events',
          'last_login',
          'first_page_view',
          'last_page_view',
          'days_online',
          'events_total'
        ],
        data: []
      },
      allUserActivity: {
        keys: [
          'date',
          'user_id',
          'events',
          'last_login',
          'first_page_view',
          'last_page_view',
          'days_online',
          'events_total'
        ],
        data: []
      },
      moduleActivity: {
        keys: [
          'date',
          'module',
          'events'
        ],
        data: []
      },
      selectedUser: 0,
      selectedModule: 'all',
      dateMinPickerActive: false,
      // current date - 6 months
      selectedFromDate: getCurrentDateISO()
    }
  },
  computed: {
    usersSelectList () {
      const users = this.users.map(u => ({
        text: `${u.first_name} ${u.last_name}`,
        value: u.id
      })) || []
      return [{ text: 'All', value: 0 }, ...users]
    },
    users () {
      return this.generalStore.appConfig?.users || []
    },
    usersWithEvents () {
      return this.users.filter(u => this.userActivity.data.some(ud => ud.user_id === u.id && ud.events_total > 0))
    },
    modules () {
      let modules = []
      if (this.users.length) {
        modules = Object.keys(this.users[0].user_access)
      }
      return ['all', ...modules].map(m => ({ text: m.charAt(0).toUpperCase() + m.slice(1), value: m }))
    },
    rawModules () {
      return ['strategy', 'buy', 'stock', 'pricing', 'settings']
    },
    usersTableData () {
      return {
        keys: ['full_name', 'last_login', 'first_page_view', 'last_page_view', 'days_online', 'events_total'],
        data: this.users.map(u => {
          const ud = this.allUserActivity.data.find(ud => ud.user_id === u.id)
          if (!ud) {
            return {
              user_id: u.id,
              first_name: u.first_name,
              last_name: u.last_name,
              full_name: `${u.first_name} ${u.last_name}`,
              last_login: '-',
              first_page_view: '-',
              last_page_view: '-',
              days_online: 0,
              events_total: 0
            }
          }
          return {
            user_id: u.id,
            first_name: u.first_name,
            last_name: u.last_name,
            full_name: `${u.first_name} ${u.last_name}`,
            last_login: FormatStringTimestamp(ud?.last_login) || '-',
            first_page_view: FormatStringTimestamp(ud?.first_page_view) || '-',
            last_page_view: FormatStringTimestamp(ud?.last_page_view) || '-',
            days_online: ud?.days_online  || 0,
            events_total: ud?.events_total || 0
          }
        })
      }
    }
  },
  methods: {
    formatString,
    selectedRowClass ({ row }) {
      return row.user_id === this.selectedUser ? 'selected-row' : ''
    },
    setAllUserActivity () {
      this.allUserActivity = this.userActivity
      // Sort the table after the data is loaded
      setTimeout(() => {
        this.$refs.usersTable?.sort(this.defaultSort.prop, this.defaultSort.order)
      }, 100)
    },
    async loadUserActivity () {
      return getUserActivity(this.selectedFromDate, this.selectedUser, this.selectedModule).then(response => {
        this.userActivity = response
        if (this.selectedUser === 0 || this.allUserActivity.data.length === 0) {
          this.setAllUserActivity()
        }
      }).catch(console.error)
    },
    async loadModuleActivity () {
      return getModuleActivity(this.selectedFromDate, this.selectedUser, this.selectedModule).then(response => {
        this.moduleActivity = response
      }).catch(console.error)
    },
    refetch () {
      this.loading = true
      Promise.all([this.loadUserActivity(), this.loadModuleActivity()]).finally(() => {
        this.loading = false
      })
    },
    handleRowClick (row) {
      if(row.user_id && row.user_id !== this.selectedUser) {
        this.selectedUser = row.user_id
        this.refetch()
      } else if (row.user_id === this.selectedUser) {
        this.selectedUser = 0
        this.refetch()
      }
    },
  },
  created () {
    this.refetch()
  }
}
</script>

<style>
.selected-row {
  background-color: #f5f5f5!important;
}
</style>
