<template>
  <v-dialog
    v-model="isOpen"
    scrollable
    persistent
    content-class="align-self-start mt-14"
    width="600px"
    max-width="90%">
    <v-card>
      <v-card-title class="text-body-1 font-weight-medium mx-4 my-2">Batch Upload</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="text--primary">
        <div class="container px-4 mt-4">
          <div class="pb-2">
            <h3 class="font-weight-medium">Info</h3>
            <div class="text-subtitle-1 grey--text text--darken-2 font-weight-regular pt-3 pb-2">
              Use template to upload multiple dummies. Unknown drops will be created on upload.
            </div>
            <v-btn
              class="px-0"
              text
              color="primary" @click="downloadTemplate">Download Template</v-btn>
          </div>
          <div class="pb-3 col col-8 px-0">
            <h3 class="font-weight-medium mb-5">Input</h3>
            <!--Budget Key Selection-->
            <v-autocomplete

              class="pb-3"
              v-model="budget_key"
              :items="budget_keys"
              clearable
              item-value="budget_key"
              item-text="budget_name"
              label="Budget"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            />
            <!--Scenario Key Selection-->
            <v-autocomplete
              class="pb-3"
              v-model="scenario_key"
              :items="scenario_keys"
              clearable
              item-value="scenario_key"
              item-text="scenario_name"
              label="Scenario"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            />
          </div>
          <drag-and-drop @error="setError" @results="setResults" ref="dragndrop"/>
          <div class="mt-6 grey--text text--darken-2 text-caption font-weight-medium" :class="{ 'error--text': importError }">
            <template v-if="importError">
              {{ importError }}
            </template>
            <template v-else-if="importSuccess">
              {{ importSuccess }}
            </template>
            <template v-else>
              No import error
            </template>
          </div>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="justify-end">
        <div class="mx-5 my-6">
          <v-btn
            color="primary"
            depressed
            text
            @click="$emit('close')"
          >Cancel</v-btn>
        </div>
        <div class="mx-5 my-6">
          <v-btn
            color="primary"
            depressed
            outlined
            :disabled="!!importError || results.length === 0"
            @click="handleUpload"
            :loading="uploading"
          >Upload</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const DragAndDrop = () => import('@/components/DragAndDrop')
import { getDummiesBatchTemplate } from '@/api/dummies'
import { downloadData } from '@/utils'
import * as shopApi from '@/api/shop'
import { getBudgets } from '@/api/budgetAPI'

export default {
  name: 'BatchUploadDummies',
  components: { DragAndDrop },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      results: [],
      importError: null,
      importSuccess: null,
      uploading: false,
      budget_key: 0,
      scenario_key: 0,
      budget_keys: [],
      scenario_keys: [],
    }
  },
  watch: {
    budget_key () {
      this.loadScenarios(this.budget_key)
      this.emitSetFilters()
    },
    scenario_key () {
      this.emitSetFilters()
    },
  },
  mounted () {
    this.loadBudgets()
  },
  methods: {
    handleUpload () {
      this.uploading = true
      this.$emit('upload', this.results)
    },

    setError (error) {
      this.importError = error
      this.uploading = false
      if (!this.uploading && !this.importError) {
        this.$refs.dragndrop.Reset()
      }
    },
    setResults (results) {
      this.importError = null
      this.results = results
    },
    async downloadTemplate () {
      const data = await getDummiesBatchTemplate(this.budget_key, this.scenario_key)
      downloadData(',', [data], 'dummies-batch.csv')
    },
    emitSetFilters () {
      this.$emit('setFilters', {
        budget_key: this.budget_key,
        scenario_key: this.scenario_key,
      })
    },
    async loadBudgets () {
      this.budget_keys = getBudgets().filter(item => item.active)
    },
    async loadScenarios (budget_key) {
      this.scenario_keys = await shopApi.getScenarios(budget_key)
    },
  },
}
</script>

<style lang="scss">
.file-drop-zone {
  input {
    position: absolute;
    opacity: 0;
  }
  label {
    border: 5px dashed #ccc;
    height: 250px;
    padding-top: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 48px;
      margin-bottom: 20px;
    }
  }
  &:hover,
  &.hover {
    label {
      background: rgba(0, 122, 255, 0.06);
    }
  }
}

</style>
