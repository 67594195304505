<template>
  <v-menu v-if="items?.length" offset-y nudge-bottom="5">
    <template v-slot:activator="{ on, attrs }">
      <div v-if="items.length" v-on="on" class="c-pointer">
        <v-chip
          label
          small
          outlined
          :color="color"
          v-bind="attrs"
          @click.stop="$emit('item-click', items[0])"
        >
          {{ items[0] }}
        </v-chip>
        <span v-if="items.length > 1" class="grey--text caption ml-1">
          +{{ items.length - 1 }}
        </span>
        <v-icon class="ml-1">arrow_drop_down</v-icon>
      </div>
    </template>
    <v-card class="pb-2 pl-2">
      <div v-for="(i, index) in items" :key="index" class="pt-2">
        <v-chip label small outlined @click="$emit('item-click', i)">
          <span>{{ i }}</span>
        </v-chip>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String || null,
      default: null,
    },
  },
}
</script>
