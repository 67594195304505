<template>
  <div class="settings-closures-list p-relative">
    <v-overlay absolute color="white" :value="closuresStore.loadingClosures">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="mt-0 pt-2">
      <v-col :cols="6">
        <h6 class="text-subtitle-1 font-weight-medium ml-6">Closures overview</h6>
      </v-col>
      <v-col :cols="3" :offset="3" class="text-right pr-6 d-flex align-center justify-end">
        <label class="v-label theme--light mr-2">Historic</label>
        <v-switch
          v-model="historic"
          :disabled="editMode"
          hide-details
          class="d-inline-block pt-0 mt-0 mr-2"
          @change="handleHistoricChange"
        />
      </v-col>
    </v-row>
    <v-row>
      <div class="col text-right pr-6">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('closuresTableFilters', columnsToSearch, val)"
        />
        <v-icon
          v-if="!editMode"
          class="mx-2 action-btn-primary"
          @click="handleEditClick"
        >edit</v-icon>
        <v-icon
          v-else
          class="mx-2 action-btn-primary"
          :disabled="tableHasErrors"
          @click="handleSaveTableClick"
        >save</v-icon>
        <v-icon
          :disabled="editMode"
          class="mx-2 action-btn-primary"
          @click="handleAddClick"
        >add</v-icon>
      </div>
    </v-row>
    <v-row>
      <div class="col">
        <data-tables
          style="width: 100%"
          :data="closuresList"
          :page-size="10"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :table-props="{ 'row-key': 'rn' }"
          filter-multiple
          :filters="closuresTableFilters"
          @filter-change="filters => handleFilterChange('closuresTableFilters', filters)">
          <el-table-column
            prop="store_name"
            column-key="store_name"
            label="Store"
            sortable="custom"
            class-name="pl-4"
            :filters="getColumnFilterValues('closuresList', 'store_name')"
          >
            <template slot-scope="scope">
              {{ scope.row.store_name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="country"
            column-key="country"
            label="Country"
            sortable="custom"
            :filters="getColumnFilterValues('closuresList', 'country')"
          >
            <template slot-scope="scope">
              {{ scope.row.country }}
            </template>
          </el-table-column>
          <el-table-column
            prop="start_date"
            label="From date"
            sortable="custom"
          >
            <template slot-scope="scope">
              <template v-if="editMode">
                <v-menu
                  ref="datePicker"
                  v-model="tableDatepickerFromActive[scope.row.rn]"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="scope.row.start_date"
                      :error-messages="tableDateFromErrors[scope.row.rn]"
                      dense
                      label="From date"
                      hide-details
                      class="pb-2 mt-5 inline-date-input"
                      v-on="on"
                      @change="handleTableInlineChange(scope.row)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :locale-first-day-of-year="4"
                    :show-week="true"
                    no-title
                    v-model="scope.row.start_date"
                    @change="handleTableDateFromSelected(scope.row)"
                  />
                </v-menu>
              </template>
              <template v-if="!editMode">
                {{ scope.row.start_date }}
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="end_date" label="To date" sortable="custom">
            <template slot-scope="scope">
              <template v-if="editMode">
                <v-menu
                  ref="datePicker"
                  v-model="tableDatepickerToActive[scope.row.rn]"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="scope.row.end_date"
                      :error-messages="tableDateToErrors[scope.row.rn]"
                      dense
                      label="To date"
                      hide-details
                      class="pb-2 mt-5 inline-date-input"
                      v-on="on"
                      @change="handleTableInlineChange(scope.row)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :locale-first-day-of-year="4"
                    :show-week="true"
                    no-title
                    v-model="scope.row.end_date"
                    :min="getRowMinDateTo(scope.row)"
                    @change="handleTableDateToSelected(scope.row)"
                  />
                </v-menu>
              </template>
              <template v-if="!editMode">
                {{ scope.row.end_date }}
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="block_proposals" label="Zero proposal" sortable="custom">
            <template slot-scope="scope">
              <v-simple-checkbox
                v-model="scope.row.block_proposals"
                color="primary"
                dense
                hide-details
                class="d-inline-block mt-0 pt-0"
                :ripple="false"
                :disabled="!editMode"
                @input="handleTableInlineChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column width="70" class-name="pr-4">
            <template slot-scope="scope">
              <v-icon
                :disabled="editMode"
                class="mx-2 my-3 action-btn-danger"
                @click="handleDeleteClick(scope.row)"
              >delete</v-icon>
            </template>
          </el-table-column>
        </data-tables>
      </div>
    </v-row>

    <!-- Form dialog -->
    <v-dialog
      :value="formDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="closuresStore.addingClosures">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">New closure</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 text--primary">
          <div class="container px-12">
            <template>
              <v-row class="align-baseline mt-0 mb-4">
                <v-col class="py-0">
                  <collapsible-select
                    v-model="newClosuresForm.stores"
                    label="Select stores"
                    :items="appConfig.stores"
                    :error-messages="closuresFormErrors.stores"
                    item-text="store_name"
                    item-value="store_key"
                    show-select-all
                    autocomplete
                    hide-details="auto"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                  />
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-0 mb-4">
                <v-col class="py-0">
                  <v-menu
                    v-model="fromDatePickerActive"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="newClosuresForm.start_date"
                        :error-messages="closuresFormErrors.startDate"
                        clearable
                        label="From date"
                        hide-details="auto"
                        v-on="on"
                        @click:clear="newClosuresForm.start_date = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      :locale-first-day-of-year="4"
                      :show-week="true"
                      no-title
                      :max="newClosuresForm.end_date"
                      v-model="newClosuresForm.start_date"
                      @click="fromDatePickerActive = false"
                    />
                  </v-menu>
                </v-col>
                <v-col class="py-0">
                  <v-menu
                    v-model="toDatePickerActive"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="newClosuresForm.end_date"
                        :error-messages="closuresFormErrors.endDate"
                        clearable
                        label="To date"
                        hide-details="auto"
                        v-on="on"
                        @click:clear="newClosuresForm.end_date = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      :locale-first-day-of-year="4"
                      :show-week="true"
                      no-title
                      v-model="newClosuresForm.end_date"
                      :min="newClosuresForm.start_date"
                      @click="toDatePickerActive = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="align-center mt-8 mb-0">
                <v-col cols="4" class="py-0">
                  <label>Zero proposal</label>
                </v-col>
                <v-col class="py-0">
                  <v-simple-checkbox
                    v-model="newClosuresForm.block_proposals"
                    color="primary"
                    dense
                    hide-details
                    class="d-inline-block mt-0 pt-0"
                    :ripple="false"
                  />
                </v-col>
              </v-row>
            </template>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    depressed
                    text
                    @click="handleSaveFormCancelClick"
                  >Cancel</v-btn>
                  <v-btn
                    color="primary"
                    depressed
                    outlined
                    @click="handleSaveFormClick"
                    class="mx-4"
                  >Add</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useClosuresStore } from '@/store/pinia/closuresStore'

export default {
  mixins: [columnFilters],
  setup () {
    const generalStore = useGeneralStore()
    const closuresStore = useClosuresStore()
    return { generalStore, closuresStore }
  },
  data () {
    return {
      editMode: false,
      historic: false,
      originalClosuresList: [],
      formDialogVisible: false,
      fromDatePickerActive: false,
      toDatePickerActive: false,
      tableDatepickerFromActive: [],
      tableDatepickerToActive: [],
      tableDateFromErrors: [],
      tableDateToErrors: [],
      newClosuresForm: {
        block_proposals: false,
        start_date: null,
        end_date: null,
        stores: [],
      },
      closuresFormErrors: {
        stores: null,
        startDate: null,
        endDate: null
      },
      closuresTableFilters: [],
      columnsToSearch: ['store_name', 'country', 'start_date', 'end_date'],
    }
  },
  components: {
    TableSearch,
    CollapsibleSelect,
  },
  computed: {
    appConfig () {
      return this.generalStore.appConfig
    },
    closuresList () {
      return this.closuresStore.closuresList
    },
    tableHasErrors () {
      return this.tableDateFromErrors.length > 0 || this.tableDateToErrors.length > 0
    }
  },
  methods: {
    getRowMinDateTo (row) {
      return row.start_date ? new Date(row.start_date).toISOString().slice(0, 10) : null
    },
    handleAddClick () {
      this.formDialogVisible = true
    },
    handleEditClick () {
      this.editMode = true
    },
    handleHistoricChange () {
      this.loadData()
    },
    handleDeleteClick (row) {
      this.closuresStore.deleteClosure(row)
        .then(this.loadData)
    },
    handleSaveFormCancelClick () {
      this.formDialogVisible = false
      this.resetForm()
    },
    handleSaveFormClick () {
      if (!this.validateClosure()) {
        return
      }

      this.closuresStore.createClosure(this.newClosuresForm)
        .then(() => {
          this.formDialogVisible = false
          this.resetForm()
          this.loadData()
        })
    },
    handleSaveTableClick () {
      this.closuresStore.updateClosures({
        original: this.originalClosuresList,
        updated: this.closuresList,
      })
        .then(() => {
          this.editMode = false
          this.loadData()
        })
    },
    handleTableInlineChange (row) {
      this.closuresStore.setClosure(row)

      this.tableDateFromErrors = _.remove(this.tableDateFromErrors, x => x === row.rn)
      this.tableDateToErrors = _.remove(this.tableDateToErrors, x => x === row.rn)
    },
    handleTableDateFromSelected (row) {
      this.tableDatepickerFromActive[row.rn] = false
      this.handleTableInlineChange(row)
    },
    handleTableDateToSelected (row) {
      this.tableDatepickerToActive[row.rn] = false
      this.handleTableInlineChange(row)
    },
    validateClosure () {
      this.resetFormErrors()

      if (!this.newClosuresForm.stores) {
        this.closuresFormErrors.stores = 'Can not be empty'
      }

      return !this.formHasErrors()
    },
    resetForm () {
      this.newClosuresForm = { block_proposals: false }
      this.resetFormErrors()
    },
    formHasErrors () {
      return !Object.values(this.closuresFormErrors).every(val => val === null)
    },
    resetFormErrors () {
      const errors = this.closuresFormErrors

      Object.keys(errors).forEach(key => {
        errors[key] = null
      })
    },
    loadData () {
      this.closuresStore.loadClosures(this.historic)
        .then(() => {
          // save original data to send it in update request
          this.originalClosuresList = structuredClone(this.closuresList)
        })
    },
  },
  created () {
    this.loadData()
  },
}
</script>

<style lang="scss">
.settings-closures-list {
  .inline-date-input {
    font-size: 12px;
    width: 150px;
  }
}
</style>
