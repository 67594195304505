import axios from '@/services/axios'
import baseURLs from '@/api/APIBaseURLs'
import * as models from '@/models/StockOverview'

export async function getDimensionsAndViews () {
  const url = `${baseURLs.tsqlAPI}/overview`
  const response = await axios.get(url)
  return response.data
}

export async function getOverviewData (payload: models.OverviewDataAPIPayload) {
  const url = `${baseURLs.tsqlAPI}/overview`
  const response = await axios.post(url, payload)
  return response.data
}

export async function getDimensionTableChildData (payload: models.DimensionTableChildDataAPIPayload) {
  const url = `${baseURLs.tsqlAPI}/overview`
  const response = await axios.post(url, payload)
  return response.data
}

export async function getOptionsTableData (payload: models.OptionsTableDataAPIPayload) {
  const url = `${baseURLs.tsqlAPI}/overview`
  const response = await axios.post(url, payload)
  return response.data
}
