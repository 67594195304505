import _ from 'lodash'

const defaultTextTransform = (value) => value

export default {
  methods: {
    getColumnFilterValues (listProp, col, textTransform = defaultTextTransform, forced = false) {
      // flatten to handle columns with array of values properly
      const values = _.flatten(this[listProp].map(x => _.get(x, col)))
      return _.uniq(values).filter((item) => item !== null)
        .map(v => {
          return ({
            text: typeof v === 'string' && !forced ? this.$options.filters.formatString(v) : textTransform(v),
            value: v,
          })
        }).sort((a = '', b = '') => a.text > b.text ? 1 : a.text < b.text ? -1 : 0)
    },
    handleFilterChange (filtersProp, filters) {
      const key = Object.keys(filters)[0]
      if (!filters[key].length) {
        this[filtersProp] = this[filtersProp].filter(f => f.prop !== key)
      } else {
        const existingFilter = this[filtersProp].find(f => f.prop === key)

        if (existingFilter) {
          existingFilter.value = filters[key]
        } else {
          this[filtersProp].push({
            prop: key,
            value: filters[key]
          })
        }
      }
      this.$set(this, filtersProp, [...this[filtersProp]])

    },
    handleSearchChanged (filtersProp, columns, val) {
      // search filter has array of columns to filter
      // single column filters have string with column name
      // remove current search filter
      this[filtersProp] = this[filtersProp].filter(f => !Array.isArray(f.prop))

      // and add new one if smth is typed in search field
      if (val) {
        this[filtersProp].push({
          prop: columns,
          value: val
        })
      }
    },
    filterData (list, filters) {
      if (filters.length) {
        return list.filter(item => {
          return filters.every(filter => {
            const prop = filter.prop
            const value = filter.value

            if (Array.isArray(prop)) {
              return prop.every(p => {
                return value.includes(item[p])
              })
            } else {
              return value.includes(item[prop])
            }
          })
        })
      }
      return list
    },
    equalsFilter (value, row, column) {
      const property = column['property']
      return row[property] === value
    }
  }
}
