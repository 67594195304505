<template>
  <div class="store-sets-search-filters">
    <div class="text-body-1 font-weight-medium mt-4 pt-2 px-6">Filter</div>
    <v-container fluid :key="containerKey">
      <div style="width: 475px">
        <v-row
          v-for="(filter, index) in selectedFilters"
          :key="index"
          class="align-center"
        >
          <v-col cols="11">
            <v-row>
              <v-col cols="4" class="pl-6">
                <v-autocomplete
                  label="Filter"
                  :value="filter.type"
                  :items="filters"
                  item-text="label"
                  item-value="value"
                  hide-details="auto"
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                  @change="t => swapFilter(index, t)"
                />
              </v-col>
              <v-col cols="8" class="px-6">
                <v-select
                  v-if="!isMultipleFilter(filter.type)"
                  v-model="filter.values"
                  label="Value"
                  :items="getFilterByType(filter.type).options"
                  item-text="label"
                  hide-details="auto"
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                  @change="$emit('filtersChanged')"
                />
                <collapsible-select
                  v-else
                  v-model="filter.values"
                  label="Values"
                  :items="getFilterByType(filter.type).options"
                  hide-details="auto"
                  class="pt-3"
                  item-text="label"
                  show-select-all
                  @change="$emit('filtersChanged')"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1" class="px-0">
            <v-icon class="action-btn-danger" @click="handleDeleteFilterClick(filter)">delete</v-icon>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="11" class="pt-0 px-6 pb-6">
            <v-autocomplete
              v-model="newFilter"
              placeholder="Add filter"
              :items="filtersAvailable"
              item-text="label"
              hide-details="auto"
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
              @change="handleNewFilterChanged"
            />
          </v-col>
          <v-col cols="1">
            <slot></slot>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { getStoresFilterValuesByType } from '@/utils'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

import { useStockManageStore } from '@/store/pinia/stockManageStore'
export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  props: ['selectedFilters', 'staticSet', 'storesKeysLoading'],
  data () {
    return {
      newFilter: null,
      containerKey: 0,
    }
  },
  components: {
    CollapsibleSelect,
  },
  computed: {
    ...mapState(useGeneralStore, ['mappedConfigStores']),
    filters () {
      return [
        {
          label: 'Country',
          value: 'country',
          options: (this.stockManageStore.storeSetsFiltersValues.country || []).map(v => ({ label: v, value: v })),
        },
        {
          label: 'DC key',
          value: 'dc_key',
          options: (this.stockManageStore.storeSetsFiltersValues.dc_key || []).map(v => ({ label: v, value: v })),
        },
        {
          label: 'Proposal key',
          value: 'proposal_key',
          options: (this.stockManageStore.storeSetsFiltersValues.proposal_key || []).map(v => ({ label: v, value: v })),
        },
        {
          label: 'One stock',
          value: 'one_stock',
          options: [{
            label: 'Any',
            value: 'any',
          }, {
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
        {
          label: 'Stores include',
          value: 'store_keys_include',
          options: this.mappedConfigStores,
        },
        {
          label: 'Stores exclude',
          value: 'store_keys_exclude',
          options: this.mappedConfigStores,
        }
      ]
    },
    filtersAvailable () {
      return this.filters.filter(
        o => o.value !== 'store_keys_include' && !this.selectedFilters.map(
          f => f.type
        ).includes(o.value)
      )
    },
  },
  methods: {
    swapFilter (index, type) {
      const values = getStoresFilterValuesByType(type)
      this.selectedFilters.splice(index, 1, {
        type: type,
        values: values,
        errors: [],
      })
      this.$emit('filtersChanged')
      this.containerKey++
    },
    handleNewFilterChanged () {
      const values = getStoresFilterValuesByType(this.newFilter)
      this.$emit('filterAdded', {
        type: this.newFilter,
        values,
        errors: [],
      })

      this.newFilter = null
    },
    getFilterByType (type) {
      return this.filters.find(o => o.value === type)
    },
    handleDeleteFilterClick (filter) {
      this.$emit('filterRemoved', filter)
    },
    isMultipleFilter (type) {
      return ['country', 'store_keys_include', 'store_keys_exclude'].includes(type)
    },
    validateFilters () {
      return true
    },
  },
}
</script>

<style lang="scss">
.store-sets-search-filters {
}
</style>
