<template>
  <v-row class="strategy-r2-kpi-tiles d-flex flex-wrap justify-space-around mx-n4 px-3">
    <v-col
      cols="5"
      md="2"
      sm="4"
      xs="5"
      v-for="(item, i) in kpiItems"
      :key="i"
      class="text-center align-center rounded p-relative rounded elevation-2 kpi-tile mx-2 my-2"
    >
      <div class="mt-1 tile-title">{{ item.title }}</div>
      <div class="mt-2 tile-value">{{ formatKPIValue(item.title, item.budget) }}</div>
      <div class="mt-2 tile-subtitle-lg">{{ formatKPIValue(item.title, item.actual) }}</div>
      <div class=" tile-subtitle" :style="{ color: colormapRedGreen(item.r2) }">
        ({{item.r2 > 0 ? '+': ''}}{{item.r2}})
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { colormapRedGreen } from '@/variables'

export default {
  name: 'StrategyR2KPITiles',
  components: {},
  props: {
    kpiItems: {
      type: Array,
      default: () => [],
    }
  },
  data () {
    return {
    }
  },
  computed: {
    nonDecimalPriceFormatter () {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.$auth.getUserCurrency().currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
    priceFormatter () {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.$auth.getUserCurrency().currency,
        minimumFractionDigits: 0,
      })
    },
  },
  methods: {
    colormapRedGreen,
    formatKPIValue (title, value) {
      const numberFormatter = new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })

      if (title === 'Sales' || title === 'sales') {
        return numberFormatter.format(value)
      }
      if (title === 'Revenue' || title === 'revenue') {
        return this.nonDecimalPriceFormatter.format(value)
      }
      return this.priceFormatter.format(value)
    },
  }
}
</script>

<style lang="scss">
  .strategy-r2-kpi-tiles {
    .kpi-tile {
      background-color: white;
      height: 130px;
      flex-basis: 130px;
      display: inline-block;
      .tile-title {
        font-size: 12px;
        font-weight: 500;
      }
      .tile-value {
        font-size: 16px;
        font-weight: 500;
      }
      .tile-subtitle-lg {
        font-size: 14px;
        font-weight: 500;
      }
      .tile-subtitle {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  @media (min-width: 1200px) {
    .strategy-r2-kpi-tiles {
      .kpi-tile {
        flex-basis: 170px !important;
      }
    }
  }
  @media (min-width: 1600px) {
    .strategy-r2-kpi-tiles {
      .kpi-tile {
        flex-basis: 190px !important;
      }
    }
  }
  @media (min-width: 1900px) {
    .strategy-r2-kpi-tiles {
      .kpi-tile {
        flex-basis: 220px !important;
      }
    }
  }
</style>
