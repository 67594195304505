<template>
  <div class="block-base">
    <div class="text-body-2 font-weight-medium py-4 px-4">Values for property: {{ propertyName }}</div>
    <v-row>
      <v-col class="text-right mr-6">
        <TableSearch
          class="pb-2"
          @searchChanged="handleSearchChanged"
        />
        <v-icon class="mx-2 action-btn-primary" @click="handleAddClick">add</v-icon>
      </v-col>
    </v-row>
    <data-tables
      ref="resultsTable"
      style="width: 100%"
      :key="tableKey"
      :data="propertyValuesList"
      :page-size="5"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
      :table-props="{ 'row-key': 'option_key' }"
      :filters="tableFilters"
      @row-click="handleRowClick">
      <el-table-column prop="option_key" width="55">
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="ml-3"
            :value="scope.row.selected"
            :ripple="false"
            @input="handleSelectRowChange($event, scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Value" sortable="custom">
        <template slot-scope="scope">
          {{ scope.row.name | formatString }}
        </template>
      </el-table-column>
      <el-table-column prop="count" label="Options" sortable="custom">
        <template slot-scope="scope">
          {{ scope.row.count }}
        </template>
      </el-table-column>
      <el-table-column prop="score" label="RoS impact" sortable="custom">
        <template slot-scope="scope">
          {{ scope.row.score }}
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import TableSearch from '@/components/TableSearch.vue'

export default {
  components: { TableSearch },
  props: {
    propertyName: {
      type: String,
      default: '',
    },
    propertyValues: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      tableKey: 0,
      tableFilters: [
        {
          value: ''
        }
      ],
    }
  },
  computed: {
    propertyValuesList () {
      if (!this.propertyValues) {
        return []
      }

      return Object.keys(this.propertyValues).reduce(
        (acc, cur, i) => {
          return [ ...acc, {
            name: cur,
            count: this.propertyValues[cur].count,
            score: this.propertyValues[cur].score,
            selected: i === 0,
          }]
        },
        []
      )
    },
    selectedRows () {
      return this.propertyValuesList.filter(r => r.selected)
    },
  },
  methods: {
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      // can deselect row only by selecting other row
      if (!val) {
        return
      }

      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
      this.$emit('property-selected', row)
    },
    handleSearchChanged (val) {
      this.tableFilters[0].value = val
      this.tableKey++
    },
    handleAddClick () {
      this.$emit('property-value-add', this.selectedRows[0])
    },
  }
}
</script>
