<template>
  <div class="consolidate-exclusions p-relative">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="mt-0">
      <v-col class="pb-0">
        <h4 class="px-4 py-1">Exclusions</h4>
      </v-col>
    </v-row>

    <v-col justify="center" class="pt-6" style=" position: relative; min-height: 400px; max-height: 400px; width: 100%">
      <LineChart
        ref="transferChart"
        :options="lineChartOptions"
        :labels="lineChartData.labels"
        :data="chartData"/>
    </v-col>
    <v-row>
      <v-col class="text-right pt-0 pr-6">
        <TableSearch
          class="mx-2 mb-6"
          @searchChanged="val => handleSearchChanged('exclusionsTableFilters', columnsToSearch, val)"
        />
        <v-icon
          class="mx-2 action-btn-danger"
          :disabled="!selectedRows[0]"
          @click="handleDeleteClick">delete</v-icon>
      </v-col>
    </v-row>
    <data-tables
      style="width: 100%"
      :data="exclusionsList"
      :page-size="5"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 mb-4 mr-2',
      }"
      :table-props="{
        'row-key': 'rowKey',
      }"
      :filters="exclusionsTableFilters"
      filter-multiple
      @filter-change="filters => handleFilterChange('exclusionsTableFilters', filters)"
    >
      <data-tables-column width="55">
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="mt-0 ml-4 pt-0"
            :value="scope.row.selected"
            :ripple="false"
            @input="handleSelectRowChange($event, scope.row)"
          />
        </template>
      </data-tables-column>
      <data-tables-column
        prop="created_at"
        column-key="created_at"
        label="Created"
        sortable
        :filters="getColumnFilterValues('exclusionsList', 'created_at')"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.created_at }}</div>
        </template>
      </data-tables-column>
      <data-tables-column
        prop="user_name"
        column-key="user_name"
        label="User"
        sortable
        :filters="getColumnFilterValues('exclusionsList', 'user_name')"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.user_name }}</div>
        </template>
      </data-tables-column>
      <data-tables-column
        prop="page"
        column-key="page"
        label="Source"
        sortable
        :filters="getColumnFilterValues('exclusionsList', 'page')"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.page | formatString}}</div>
        </template>
      </data-tables-column>
      <data-tables-column
        prop="stores_names"
        column-key="stores_names"
        label="Stores"
        sortable
        :filters="getColumnFilterValues('exclusionsList', 'stores_names')"
      >
        <template slot-scope="scope">
          <ChipsDropdown :items="scope.row.stores_names" />
        </template>
      </data-tables-column>
      <data-tables-column
        prop="options_keys"
        column-key="options_keys"
        label="Options"
        sortable
        :filters="getColumnFilterValues('exclusionsList', 'options_keys')"
      >
        <template slot-scope="scope">
          <ChipsDropdown :items="scope.row.options_keys" />
        </template>
      </data-tables-column>
      <data-tables-column prop="payload" label="Exclusions" sortable label-class-name="text-center">
        <template slot-scope="scope">
          <div class="text-center">
            <v-tooltip top :max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <v-icon :size="21" v-bind="attrs" v-on="on">info</v-icon>
              </template>
              <span>{{ JSON.stringify(scope.row.payload.exclusions) }}</span>
            </v-tooltip>
          </div>
        </template>
      </data-tables-column>
    </data-tables>

    <div class="text-right mx-4">
      <div class="d-inline-block" style="width: 250px;">
        <v-autocomplete
          :items="appConfig?.stores_open || []"
          item-text="store_name"
          item-value="store_key"
          label="Transfer remaining"
          clearable
          hide-details
          :menu-props="{
            left: true,
          }"
          @change="val => remainderStoreKey = val"
        />
        <v-btn
          color="primary"
          depressed
          outlined
          class="d-block my-4"
          :disabled="!selectedRows.length"
          @click="handleBuildTransferClick"
        >
          Build transfers
        </v-btn>
      </div>
    </div>

    <v-row v-if="consolidateTransfersData" class="mt-0">
      <v-col>
        <h4 class="px-4 py-1">Consolidate transfers</h4>
        <ConsolidateTransfersTable
          ref="transfersTable"
          :building-transfers="buildingTransfers"
          :transfers-data="consolidateTransfersData"
          :store-keys="selectedRows[0]?.payload.store_keys"
          :exclusions="selectedRows[0]?.payload.exclusions"
          :remainder-store-key="remainderStoreKey"
          @type-changed="handleTransfersTypeChanged"
          @accept-transfers-click="handleAcceptTransfersClick"
          @transfers:accepted="$emit('transfers:accepted')"
        />
      </v-col>
    </v-row>

    <!-- Accept transfers confirm dialog -->
    <v-dialog
      :value="transfersDialogVisible"
      persistent
      content-class="align-self-start mt-10"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Accept transfers
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to accept the shown transfers?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="transfersDialogVisible = false"
              >Cancel
              </v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                @click="handleTransfersConfirm"
                class="mx-4"
              >Ok
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Revert exclusions confirm dialog -->
    <v-dialog
      :value="revertExclusionsDialogVisible"
      persistent
      content-class="align-self-start mt-10"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Revert exclusions
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">All shown exclusions will be removed, this action can not be undone. Are you sure?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="revertExclusionsDialogVisible = false"
              >Cancel
              </v-btn>
              <v-btn
                depressed
                outlined
                @click="handleRevertExclusionsConfirm"
                class="red--text mx-4"
              >Delete
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { getTransfersOverviewData } from '@/utils'
import {loadConsolidateExcludedChart, loadConsolidateTransfers, revertExcludeOptions} from '@/api/consolidate'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'
import ChipsDropdown from '@/components/ChipsDropdown.vue'
import ConsolidateTransfersTable from '@/components/Stock/Consolidate/ConsolidateTransfersTable'
import LineChart from '@/charts/LineChart.vue'
import DataTablesColumn from '@/components/Tables/components/DataTablesColumn.vue'

export default {
  name: 'ConsolidateExclusions',
  components: {DataTablesColumn, LineChart, TableSearch, ChipsDropdown, ConsolidateTransfersTable },
  mixins: [columnFilters],
  props: {
    exclusions: {
      type: Array,
      default: () => [],
    }
  },
  data () {
    return {
      loading: false,
      exclusionsList: [],
      lineChartData: {
        labels: [],
        datasets: [],
      },
      exclusionsTableFilters: [],
      columnsToSearch: ['stores_names', 'options_keys'],
      remainderStoreKey: null,
      consolidateTransfersData: null,
      buildingTransfers: false,
      transfersDialogVisible: false,
      revertExclusionsDialogVisible: false,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    chartData () {
      return [
        {
          label: 'Options excluded',
          data: this.lineChartData.datasets.map(item => item['options_excluded']),
          fill: false,
          borderDash: [10, 5],
          borderColor: '#00000033',
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'Exclusions',
          data: this.lineChartData.datasets.map(item => item['combinations_excluded']),
          fill: false,
          borderDash: [10, 5],
          borderColor: '#4CAF50',
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'Transfers',
          data: this.lineChartData.datasets.map(item => item['transfers_pieces']),
          fill: true,
          borderColor: '#007AFF',
          pointBackgroundColor: '#FFF',
          backgroundColor: (context) => {
            if (!context.chart?.chartArea) {
              return
            }
            const { ctx, chartArea: { top, bottom } } = context.chart
            const proposalGradient = ctx.createLinearGradient(0, top, 0, bottom)
            proposalGradient.addColorStop(1, 'RGBA(0,122,255,0)')
            proposalGradient.addColorStop(0.5, 'RGBA(0,122,255,0.05)')
            proposalGradient.addColorStop(0, 'RGBA(0,122,255,0.2)')
            return proposalGradient
          },
        },
      ]
    },
    lineChartOptions () {
      return {
        scales: {
          y: {
            title: {
              display: true,
              text: 'Count',
            },
            min: 0,
            ticks: {
              // Set maximum number of ticks, (ticks are the values on the y-axis)
              maxTicksLimit: 5,
              minTicksLimit: 5,
              callback: (v) => (v >= 500) ? v / 1000 + 'K' : v,
            },
            position: 'left',
            border: {
              display: false,
            },
          },
        }
      }
    },
    usersList () {
      return (this.appConfig?.users || [])
    },
    storesMap () {
      return (this.appConfig?.stores || []).reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    usersMap () {
      const usersMap = new Map()
      this.usersList.forEach(user => {
        usersMap.set(user.id, user.first_name + ' ' + user.last_name)
      })
      return usersMap
    },
    selectedRows () {
      return this.exclusionsList.filter(r => r.selected)
    },
  },
  watch: {
    exclusions: {
      handler () {
        this.exclusionsList = this.exclusions.map(row => {
          return {
            ...row,
            selected: false,
            created_at: this.$options.filters.formatDate(row.created_at, 'yyyy-MM-dd HH:mm:ss'),
            user_name: this.usersMap.get(row.user_id) || '',
            stores_names: row.payload.store_keys?.map(key => this.storesMap[key]),
            options_keys: Object.keys(row.payload.exclusions),
            originalData: row,
          }
        })
      },
      immediate: true,
    },
  },
  created (){
    this.getLineChartData()
  },
  methods: {
    async getLineChartData () {
      const { data } = await loadConsolidateExcludedChart()
      this.lineChartData.labels = data.map(item => `W${item.week}`)
      this.lineChartData.datasets = data
    },
    handleSelectRowChange (val, row) {
      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
    },
    async handleBuildTransferClick () {
      const view = this.$refs.transfersTable?.lowerCaseType || 'combinations'

      if (view === 'overview') {
        this.buildTransfersOverview()
      } else {
        this.buildTransfers()
      }
    },
    async buildTransfers () {
      this.loading = true
      this.buildingTransfers = true

      const payload = this.getPayload()

      try {
        const transfersData = await loadConsolidateTransfers(payload)
        this.consolidateTransfersData = Object.freeze(transfersData)

        if (this.$refs.transfersTable) {
          this.$refs.transfersTable.transfersBtnDisabled = false
        }
      } finally {
        this.loading = false
        this.buildingTransfers = false
      }
    },
    async buildTransfersOverview () {
      this.loading = true
      this.buildingTransfers = true

      const payload = this.getPayload()

      try {
        const payloadSend = {
          ...payload,
          view: 'send'
        }
        const transfersDataSend = await loadConsolidateTransfers(payloadSend)

        const payloadReceive = {
          ...payload,
          view: 'receive'
        }
        const transfersDataReceive = await loadConsolidateTransfers(payloadReceive)
        this.consolidateTransfersData = Object.freeze(getTransfersOverviewData(transfersDataSend, transfersDataReceive))

        if (this.$refs.transfersTable) {
          this.$refs.transfersTable.transfersBtnDisabled = false
          this.$refs.transfersTable.updateTotalTableWidth()
        }
      } finally {
        this.loading = false
        this.buildingTransfers = false
      }
    },
    async handleTransfersTypeChanged () {
      const view = this.$refs.transfersTable?.lowerCaseType || 'combinations'

      if (view === 'overview') {
        this.buildTransfersOverview()
      } else {
        this.buildTransfers()
      }
    },
    handleAcceptTransfersClick () {
      this.transfersDialogVisible = true
    },
    async handleTransfersConfirm () {
      this.loading = true

      const payload = {
        ...this.getPayload(),
        accept: true,
      }

      try {
        await loadConsolidateTransfers(payload)
        this.transfersDialogVisible = false
        this.$refs.transfersTable.transfersBtnDisabled = true
        this.$emit('transfers:accepted')
      } finally {
        this.loading = false
      }
    },
    handleDeleteClick () {
      this.revertExclusionsDialogVisible = true
    },
    async handleRevertExclusionsConfirm () {
      this.loading = true

      try {
        await revertExcludeOptions(this.selectedRows[0]?.originalData)

        this.exclusionsList = this.exclusionsList.filter(row => row !== this.selectedRows[0])
        this.revertExclusionsDialogVisible = false
      } finally {
        this.loading = false
      }
    },
    getPayload () {
      const view = this.$refs.transfersTable?.lowerCaseType || 'combinations'
      const payload = {
        store_keys: this.selectedRows[0]?.payload.store_keys,
        exclusions: this.selectedRows[0]?.payload.exclusions,
        view,
      }

      if (this.remainderStoreKey) {
        payload.remainder_store_key = this.remainderStoreKey
      }

      return payload
    },
  },
}
</script>

<style lang="scss">
.consolidate-exclusions {
}
</style>
