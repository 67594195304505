import {defineStore} from 'pinia'
import {useGeneralStore} from '@/store/pinia/generalStore'
import {useStoreAndOptionSetsStore} from '@/store/pinia/useStoreAndOptionSetsStore'
import * as models from '@/models/StockFilters'
import {PossibleFiltersData, SelectedProperties} from '@/models/StockFilters'
import {filtersData} from '@/variables'
import {getFilterOptionsByProperties} from '@/utils'
import _ from 'lodash'

export const useFiltersStore = defineStore('filters', {
  state: (): models.State => ({
    filtersDataLoaded: false,
    filtersOpened: false,
    filtersChanged: false,
    currentPageFilters: [],
    filtersApplied: false,
    optionsSearchVisible: false,
    filtersData: {
      stores: [],
      toStores: [],
      storesDc: [],
      toStoresDc: [],
      groups: [],
      collections: [],
      suppliers: [],
      payments: [],
      schedules: [],
    },
    selectedFiltersOptions: {
      stores: [],
      toStores: [],
      storesDc: [],
      toStoresDc: [],
      groups: [],
      dummies: {
        name_search: null,
        created_from: null,
        created_to: null,
        price_from: null,
        price_to: null,
        properties: {},
      },
      shopItems: {
        week_from: null,
        week_to: null,
        cost_price_from: null,
        cost_price_to: null,
        weeks_left_from: null,
        weeks_left_to: null,
        currency: null,
      },
      collections: [],
      options: [],
      suppliers: [],
      payments: [],
      schedules: [],
    },
    loaded: {
      stores: false,
      toStores: false,
      storesDc: false,
      toStoresDc: false,
      groups: false,
      collections: false,
      dummies: false,
      shopItems: false,
      options: false,
      suppliers: false,
      payments: false,
      schedules: false,
    },
    selectedProperties: {
      options: [],
      stores: [],
      toStores: [],
      storesDc: [],
      toStoresDc: [],
      groups: [],
      shopItems: {
        week_from: null,
        week_to: null,
        cost_price_from: null,
        cost_price_to: null,
        weeks_left_from: null,
        weeks_left_to: null,
        currency: null,
      },
      collections: [],
    },
    optionPropertiesValues: {
      stores: {},
      toStores: {},
      storesDc: {},
      toStoresDc: {},
      groups: {},
      collections: {},
    },
    selectedValues: {
      stores: {},
      toStores: {},
      storesDc: {},
      toStoresDc: {},
      groups: {},
      collections: {},
    },
  }),

  actions: {
    getPageFiltersNonDefaultValues () {
      const nonDefaultFilters = this.currentPageFilters.filter(f => {
        return ['options', 'dummies', 'shopItems'].includes(f) ||
          this.selectedFiltersOptions[f as keyof models.SelectedValues].length !== this.filtersData[f as keyof models.SelectedValues].length
      })

      return {
        // getting selected values for current page filters and converting them to request body
        ...nonDefaultFilters.reduce((acc, cur) => ({
          ...acc,
          [_.snakeCase(cur)]: this.selectedFiltersOptions[cur as keyof models.SelectedValues],
        }), {}) as Record<string, number[]>,
      }
    },
    setFiltersDataLoaded (payload: boolean) {
      this.filtersDataLoaded = payload
    },
    setFiltersOpened (payload: boolean) {
      this.filtersOpened = payload
    },
    setFiltersChanged (payload: boolean) {
      this.filtersChanged = payload
    },
    setCurrentPageFilters (payload: string[]) {
      this.currentPageFilters = payload
    },
    setFiltersApplied (payload: boolean) {
      this.filtersApplied = payload
    },
    setInitialFromLocal (group: keyof models.SelectedFiltersOptions) {
      const items = localStorage.getItem(group)
      if (items) {
        try {
          this.selectedFiltersOptions[group] = JSON.parse(items)
        } catch (e) {
          console.error(e)
        }
      }
    },
    // TODO handle payload.data.data case for calls
    setInitial (payload: models.SetInitialPayload, useLocal = true) {
      this.filtersData[payload.group] = payload.data || []
      this.loaded[payload.group] = true
      if (useLocal && localStorage.getItem(payload.group) !== null) {
        this.setInitialFromLocal(payload.group)
      } else if (payload.data) {
        this.selectedFiltersOptions[payload.group] = payload.data.map(a => a[filtersData[payload.group].keyProp]) as string[]
      }
    },
    setOptionsFilter () {
      const optionsListStr = localStorage.getItem('optionsList') || '[]'
      try {
        this.selectedFiltersOptions.options = JSON.parse(optionsListStr) as string[]
      } catch (e) {
        console.error(e)
      }
    },
    revertFilter (filterName: keyof PossibleFiltersData, useLocal = true) {
      if (filterName as string === 'options') {
        this.revertOptionsFilter()
        return
      }
      const allSelectedOptions = this.filtersData[filterName].map(x => String(x[filtersData[filterName].keyProp]))
      this.selectedFiltersOptions[filterName] = allSelectedOptions
      const selectedPropKey = filterName as keyof SelectedProperties
      const selectedValueKey = filterName as keyof models.SelectedValues
      if (selectedPropKey in this.selectedProperties && selectedPropKey !== 'shopItems') {
        this.selectedProperties[selectedPropKey] = []
      } else if (selectedPropKey === 'shopItems') {
        this.selectedProperties[selectedPropKey] = {
          week_from: null,
          week_to: null,
          cost_price_from: null,
          cost_price_to: null,
          weeks_left_from: null,
          weeks_left_to: null,
          currency: null,
        }
      }
      this.optionPropertiesValues[selectedValueKey] = {}
      this.selectedValues[selectedValueKey] = {}

      if (useLocal) {
        localStorage.setItem(filterName, JSON.stringify(allSelectedOptions))
      }
    },
    revertOptionsFilter () {
      this.selectedFiltersOptions.options = []
      localStorage.removeItem('optionsList')
    },
    revertShopItemsFilter (useLocal = true) {
      this.selectedFiltersOptions.shopItems = {
        week_from: null,
        week_to: null,
        cost_price_from: null,
        cost_price_to: null,
        weeks_left_from: null,
        weeks_left_to: null,
        currency: null,
      }

      if (useLocal) {
        localStorage.removeItem('shopItems')
      }
    },
    revertDummiesFilter (useLocal = true) {
      this.selectedFiltersOptions.dummies = {
        name_search: null,
        created_from: null,
        created_to: null,
        price_from: null,
        price_to: null,
        properties: {},
      }
      if (useLocal) {
        localStorage.removeItem('dummies')
      }
    },
    getPropertyValues (payload: { filterName: keyof models.SelectedValues; val: string }) {
      const { filterName, val } = payload
      this.optionPropertiesValues[filterName][val] = this.filtersData[filterName].reduce((acc: unknown[], cur) => {
        if (cur.properties && cur.properties[val] && !acc.includes(cur.properties[val])) {
          acc.push(cur.properties[val])
        }
        return acc
      }, [])

      if (val === 'Store sets') {
        const storeAndOptionSetsStore = useStoreAndOptionSetsStore()
        this.optionPropertiesValues[filterName][val] = storeAndOptionSetsStore.storeSets.map(item => ({
          store_keys: item.store_keys,
          text: item.set_name,
          value: item.stores_set_key
        }))
      }
      this.selectedValues[filterName][val] = []
    },
    deleteProperty (payload: { filterName: keyof models.SelectedValues; propName: string }) {
      const { filterName, propName } = payload
      this.selectedProperties[filterName] = this.selectedProperties[filterName].filter(p => p !== propName)
      this.setSelectedPropertyValues(payload)
    },
    setSelectedFilterOptions (payload: { filterName: keyof models.SelectedProperties; value: unknown }) {
      this.selectedFiltersOptions[payload.filterName] = payload.value as never
    },
    setSelectedPropertyValues (payload: { filterName: keyof models.SelectedValues }) {
      const { filterName } = payload
      // Special case for store sets
      const storeSetSelectedValues = this.selectedValues[filterName]?.['Store sets'] as models.StoreSet[]
      if (this.selectedProperties[filterName].includes('Store sets') || storeSetSelectedValues?.length) {
        // exclude store sets from selected properties during filtering
        const selectedValues = structuredClone(this.selectedValues[filterName])
        delete selectedValues['Store sets']
        let filteredStoresWithoutSet = getFilterOptionsByProperties(filterName, this.filtersData[filterName], selectedValues)
        // Add additional filter for store sets, only include stores if they are in the selected store sets
        if (this.selectedProperties[filterName].includes('Store sets')) {
          filteredStoresWithoutSet = filteredStoresWithoutSet.filter(store_key => {
            if (storeSetSelectedValues.length) {
              return storeSetSelectedValues.some((set: models.StoreSet) => set.store_keys.includes(Number(store_key)))
            }
            return true
          })
        }
        this.selectedFiltersOptions[filterName] = filteredStoresWithoutSet
      } else {
        this.selectedFiltersOptions[filterName] = getFilterOptionsByProperties(filterName, this.filtersData[filterName], this.selectedValues[filterName])
      }
      localStorage.setItem(filterName, JSON.stringify(this.selectedFiltersOptions[filterName]))
    },
    setLocalStorage (filterName: keyof models.SelectedFiltersOptions) {
      const shopItemsOrDummies = filterName === 'shopItems' || filterName === 'dummies'
      const value = JSON.stringify(this.selectedFiltersOptions[filterName])
      if (shopItemsOrDummies) {
        localStorage.setItem(filterName, value)
        return
      }
      const haveSelectedFiltersOptions = !!this.selectedFiltersOptions[filterName].length
      if (haveSelectedFiltersOptions) {
        localStorage.setItem(filterName, value)
      }
    },
    setSelectedProperties (payload: { filterName: Exclude<keyof models.SelectedProperties, 'shopItems'>; value: string[] }) {
      this.selectedProperties[payload.filterName] = payload.value
    },
    setOptionsSearchVisible (value: boolean) {
      this.optionsSearchVisible = value
    },
    getFilterDataStores () {
      const generalStore = useGeneralStore()
      const stores = generalStore.appConfig?.stores_open || []

      this.setInitial({
        data: stores.map(x => ({ ...x, store_key: String(x.store_key) })),
        group: 'stores',
      })
      this.setInitial({
        data: stores.map(x => ({ ...x, store_key: String(x.store_key) })),
        group: 'toStores',
      })
    },
    getFilterDataGroups () {
      const generalStore = useGeneralStore()
      const groups = generalStore.appConfig?.groups || []

      this.setInitial({
        data: groups,
        group: 'groups',
      })
    },
    getFilterDataCollections () {
      const generalStore = useGeneralStore()
      const collections = generalStore.appConfig?.collections || []

      this.setInitial({
        data: collections,
        group: 'collections',
      })
    },
    getFilterDataStoresDc () {
      const generalStore = useGeneralStore()
      const stores = generalStore.appConfig?.stores_open || []

      this.setInitial({
        data: stores.map(x => ({ ...x, store_key: String(x.store_key) })),
        group: 'storesDc',
      })
      this.setInitial({
        data: stores.map(x => ({ ...x, store_key: String(x.store_key) })),
        group: 'toStoresDc',
      })
    },
    getFilterDataSuppliers () {
      const generalStore = useGeneralStore()
      const suppliers = generalStore.appConfig?.suppliers || []

      this.setInitial({
        data: suppliers.map(x => ({ ...x, supplier_key: String(x.supplier_key) })),
        group: 'suppliers',
      })
    },
    getFilterDataPayments () {
      const generalStore = useGeneralStore()
      const payments = generalStore.appConfig?.payments || []

      this.setInitial({
        data: payments.map(x => ({ ...x, payment_key: String(x.payment_key) })),
        group: 'payments',
      })
    },
    getFilterDataSchedules () {
      const generalStore = useGeneralStore()
      const schedules = generalStore.appConfig?.schedules || []

      this.setInitial({
        data: schedules.map(x => ({ ...x, schedule_key: String(x.schedule_key) })),
        group: 'schedules',
      })
    },
    getFilterDataShopItems () {
      this.setInitialFromLocal('shopItems')
      this.loaded.shopItems = true
    },
    getFilterDataDummies () {
      this.setInitialFromLocal('dummies')
      this.loaded.dummies = true
    },
  },
})
