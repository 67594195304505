<template>
  <div class="pricing-markdown-page">
    <div class="my-6">
      <h3 class="card-title text-left mb-0">Markdowns</h3>
    </div>
    <span v-if="!optionKey && !loading" style="font-size: 18px" >
      Start reordering by searching for an article in the top right of this page
    </span>
    <div class="container-fluid mb-8" v-if="optionKey">
      <v-row class="row ma-0">
        <div class="flex xs4">
          <OptionInfo class="mr-4" @paramChanged="parametersChanged" :optionKey="optionKey" :order-settings="orderSettings"></OptionInfo>
        </div>
        <h4 v-if="!Object.keys(orderData).length && !loading" class="pa-4">
          No predictions for this option. Check if reordering is active. Changes to settings can take up to one day.'</h4>

        <div class="flex xs8 fixed-height">
          <v-card
            style="height: 100%;"
            class="py-8">

            <v-overlay absolute color="white" :value="!orderData.summary">
              <v-progress-circular color="primary" indeterminate size="64" width="2" />
            </v-overlay>
            <v-carousel
              height="100%"
              class="pb-15"
              delimiter-icon="circle"
              :perPage="1"
              light
              v-model="carouselStep"
              hide-delimiter-background
              :hide-delimiters="false"
              :show-arrows="false"
            >
              <v-carousel-item :eager="true" v-if="orderData.potentials">
                <h4 class="chart-title pa-6 pt-2">
                  Potentials
                </h4>
                <ChartElement
                  :key=" carouselStep"
                  v-if="orderData.potentials"
                  title="markdown_potential_chart"
                  ref="markdown_potential_chart"
                  :params="{}"
                  :chartData="orderData"
                  :padding="false"
                  :fixedHeight="false"
                />
              </v-carousel-item>
              <v-carousel-item :eager="true" v-if="orderData.stores">
                <h4 class="chart-title pa-6 pt-2">
                  Stores Delta
                </h4>

                <GroupsChartElement
                  title="markdown_discount_groups_chart"
                  :chart-data="orderData"
                  :padding="false"
                />
              </v-carousel-item>
              <!--              <v-carousel-item :eager="true" v-if="orderData.store_elasticity">
                <h4 class="chart-title pa-6 pt-2">
                  Stores Elasticity
                </h4>

                <ChartCandles
                  :chartData="orderData.store_elasticity"
                ></ChartCandles>
              </v-carousel-item>-->
            </v-carousel>
          </v-card>
        </div>
      </v-row>
      <v-card>
        <v-tabs v-model="activeTab" class=" mt-8">
          <v-tabs-slider />
          <v-tab class="px-8" style="width: 260px" key="Proposal">Proposal</v-tab>
          <!--          <v-tab class="px-8" style="width: 260px" key="Margin">Margin</v-tab>-->
          <v-tab class="px-8" style="width: 260px" key="Trade-Off">Trade-Off</v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab" class="flex xs12">
          <v-tab-item :transition="false" :reverse-transition="false" key="Proposal" class="flex py-4 px-0">
            <MDProjectionGraph
              :data="orderData"
            />
            <v-row class="ma-0 pt-6" v-if="orderData.summary">
              <h4 class="chart-title pa-6 pt-2">
                Summary
              </h4>

              <div class="text-right pr-6 pb-4">
                <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
                <DataExport class="mx-2" @item-click="downloadOptionsData" />
              </div>
              <data-tables
                style="width: 100%; min-height: 100%"
                :data="orderData.summary.table"
                :key="listKey"

                :filters="tableFilters"
                :page-size="orderData.summary.table.length"
                :pagination-props="{pageSizes: [orderData.summary.table.length], class: 'el-pagination d-none text-right mt-6 mb-4 mr-2' }"
              >
                <el-table-column
                  v-for="col in orderData.summary.columns"
                  sortable="custom"
                  label-class-name="mx-5"
                  :key="col"
                  :prop="col"
                  :label="col | formatString">
                  <template slot-scope="scope">
                    <div class="mx-5">
                      <span v-if="shouldUsePriceFormatting(scope.row[col], col, scope.row.Name)">
                        {{ formatPrice(scope.row[col]) }}
                      </span>
                      <span v-else>{{scope.row[col]}}</span>
                    </div>
                  </template>
                </el-table-column>
              </data-tables>
            </v-row>

            <div class="row flex xs12"
              v-if="orderData.proposals">
              <div class="col-12">
                <PricingProposalOverview
                  :option_key="optionKey"
                />
              </div>
            </div>
          </v-tab-item>
          <!--          <v-tab-item :transition="false" :reverse-transition="false" key="Margin" class="flex py-4 px-0">-->
          <!--            <v-row class="ma-0 mx-2 pt-6 flex-column" v-if="orderData.store_margin_graph">-->
          <!--              <h4 class="chart-title pa-6 pt-2">-->
          <!--                Store margin-->
          <!--              </h4>-->
          <!--              <ChartElement-->
          <!--                v-if="orderData.store_margin_graph"-->
          <!--                title="store_margin_graph"-->
          <!--                ref="store_margin_graph"-->
          <!--                :params="{}"-->
          <!--                :chartData="orderData"-->
          <!--                :padding="false"-->
          <!--                :fixedHeight="false"-->
          <!--              />-->
          <!--            </v-row>-->
          <!--            <v-row class="ma-0 pt-6" v-if="orderData.store_margin_table">-->
          <!--              <h4 class="chart-title pa-6 pt-2">-->
          <!--                Store margin-->
          <!--              </h4>-->

          <!--              <div class="text-right pr-6 pb-4">-->
          <!--                <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />-->
          <!--                <DataExport class="mx-2" @item-click="downloadOptionsData" />-->
          <!--              </div>-->
          <!--              <data-tables-->
          <!--                style="width: 100%; min-height: 100%"-->
          <!--                :data="orderData.store_margin_table.table"-->
          <!--                :page-size="5"-->
          <!--                :key="listKey"-->
          <!--                :table-props="{-->
          <!--                  'row-key': 'store_key'-->
          <!--                }"-->
          <!--                :filters="tableFilters"-->
          <!--                :pagination-props="{-->
          <!--                  pageSizes: [5, 10,15, 25, 50],-->
          <!--                  class: 'el-pagination text-right mt-6 mb-4 mr-2',-->
          <!--                }">-->
          <!--                <el-table-column-->
          <!--                  v-for="col in orderData.store_margin_table.columns"-->
          <!--                  sortable="custom"-->
          <!--                  label-class-name="mx-5"-->
          <!--                  :key="col"-->
          <!--                  :prop="col"-->
          <!--                  :label="col | formatString">-->
          <!--                  <template slot-scope="scope">-->
          <!--                    <div class="mx-5">-->
          <!--                      <span v-if="col=== 'store_key'">{{ getStoreName(scope.row[col])}}</span>-->
          <!--                      <span v-else-if="typeof scope.row[col] === 'number'">{{ scope.row[col] | formatThousands }}</span>-->
          <!--                      <span v-else>{{scope.row[col]}}</span>-->
          <!--                    </div>-->
          <!--                  </template>-->
          <!--                </el-table-column>-->
          <!--              </data-tables>-->
          <!--            </v-row>-->
          <!--          </v-tab-item>-->
          <v-tab-item :transition="false" :reverse-transition="false" key="Trade-Off" class="flex py-4 px-0">
            <MarkdownTradeoff :trade-off-data="orderData.pieces_value_trade_off"/>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import ChartElement from '@/chart/ChartElement'
import OptionInfo from '@/components/Pricing/OptionInfo'
import GroupsChartElement from '@/chart/GroupsChartElement'
import DataExport from '@/components/DataExport'
import { downloadData } from '@/utils'

import '@/filters'
import TableSearch from '@/components/TableSearch.vue'
import { optionViewCall, saveOptionSettings } from '@/api/options'
import MDProjectionGraph from '@/components/Pricing/MDProjectionGraph'
import { getMarkdownOrdersData, getMarkdownOrderSettings } from '@/api/markdown_proposals'
import PricingProposalOverview from '@/components/Pricing/PricingProposalOverview'
import MarkdownTradeoff from '@/components/Pricing/MarkdownTradeoff'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'PricingMarkdownPage',
  data () {
    return {
      activeTab: 0,
      optionKey: null,
      carouselStep: 0,
      orderSettings: {
        elasticity_min: 0,
      },
      loading: false,
      orderData: {},
      listKey: 0,
      tableFilters: [
        {
          value: ''
        }
      ],
    }
  },
  components: {
    MarkdownTradeoff,
    PricingProposalOverview,
    MDProjectionGraph,
    DataExport,
    TableSearch,
    GroupsChartElement,
    OptionInfo,
    ChartElement,
  },
  computed: {
    ...mapState(useGeneralStore, ['findStoreWithKey']),
    priceFormatter () {
      return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: this.$auth.getUserCurrency().currency,
        minimumFractionDigits: 0,
      })
    },
  },
  methods: {
    async notifyPageView (optionKey) {
      await optionViewCall(optionKey)
    },
    formatPrice (price) {
      return this.priceFormatter.format(price)
    },
    shouldUsePriceFormatting (value, col, rowName) {
      return typeof value === 'number' && col !== 'Name' &&
        (rowName === 'Value (cur)' || rowName === 'Value (opt)' || rowName === 'Value (val)' || rowName === 'Value (pcs)')
    },
    getStoreName (key) {
      const store = this.findStoreWithKey(key)
      return store ? store.store_name : null
    },
    handleSearchChanged (val) {
      this.tableFilters[0].value = val
      this.listKey++
    },
    downloadOptionsData (sep) {
      downloadData(sep, this.orderData.summary.table, 'assortment_timelines_mapping_options')
    },
    handleOptionKeyChange () {
      this.loading = true
      const optionKey = this.$route.query.option_key
      if (optionKey) {
        this.notifyPageView(optionKey).then()
        this.optionKey = optionKey
        this.getOrderSettings()
          .then(this.getOrderData)
      }
    },
    async getOrderData () {
      this.loading = true
      this.optionKey = this.$route.query.option_key
      this.orderSettings.option_key = this.optionKey
      this.orderData = await getMarkdownOrdersData(this.orderSettings)
      this.orderData.store_names = this.orderData.store_keys.map(key => this.findStoreWithKey(key)?.store_name || key)
      this.loading = false
    },
    async getOrderSettings () {
      try {
        this.orderSettings = await getMarkdownOrderSettings(this.optionKey.toString())
      } catch (e) {
        console.error(e)
      }
    },
    parametersChanged (data) {
      this.orderSettings = { ...this.orderSettings, ...data }
      saveOptionSettings(this.orderSettings, this.$route.query)
      this.getOrderData()
    },
  },
  watch: {
    $route () {
      this.handleOptionKeyChange()
    },
  },
  created () {
    this.handleOptionKeyChange()
  }
}
</script>
<style lang="scss">
.pricing-markdown-page {
}
</style>
