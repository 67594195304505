<template>
  <div class="buy-manage-budgets-main mb-6">
    <v-row class="ma-0" justify="space-between">
      <h4 class="px-6 pl-7 py-0">Costs</h4>
    </v-row>
    <v-row class="ma-4  mt-0 justify-end " >
      <div>
        <table-search
          @searchChanged="filters => handleSearchChanged('costsFilters',Object.keys(costs[0]), filters)"></table-search>
        <v-icon class="mx-2" @click="budgetModalOpen = true; isEdit=true" :disabled="!selectedCost">edit</v-icon>
        <v-icon class="mr-2" @click="budgetModalOpen = true; isEdit=false">add</v-icon>
        <v-icon @click="deleteDialogVisible = true">delete</v-icon>
      </div>
    </v-row>

    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <data-tables
      v-if="!loading"
      :data="costs"
      :filters="costsFilters"
      :table-props="{'row-key': 'cost_key'}"
      :page-size="5"
      @row-click="(row)=> selectBudget(row)"
      @filter-change="filters => handleFilterChange('costsFilters', filters)"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
    >
      <el-table-column
        label=""
        width="55"
      >
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="ml-3"
            :value="selectedCost && selectedCost === scope.row.cost_key"
            :ripple="false"
            :input-value="selectedCost && selectedCost === scope.row.cost_key"
            @change="()=> selectBudget(scope.row)"
            @click.stop="()=> selectBudget(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="cost_name"
        sortable
        label="Name"
      >
        <template slot-scope="scope">
          {{scope.row.cost_name}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="currency"
        label="Currency"
        column-key="currency"
        :filters="getColumnFilterValues('costs', 'currency')">
        <template slot-scope="scope">
          {{ scope.row.currency }}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="cost_per_piece"
        label="Per Piece">
        <template slot-scope="scope">
          {{scope.row.cost_per_piece || '-'}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="cost_per_order"
        label="Per Order">
        <template slot-scope="scope">
          {{scope.row.cost_per_order || '-'}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="perc_of_cost_price"
        label="Buy Price %">
        <template slot-scope="scope">
          {{getPercentages(scope.row.perc_of_cost_price)}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="perc_of_retail_price"
        label="Retail Price %">
        <template slot-scope="scope">
          {{getPercentages(scope.row.perc_of_retail_price)}}
        </template>
      </el-table-column>
    </data-tables>
    <v-divider></v-divider>
    <v-dialog
      v-model="budgetModalOpen"
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <buy-manage-costs-modal
        :payments="payments"
        v-if="budgetModalOpen"
        :cost="isEdit ? {...costs.filter((cost)=> cost.cost_key === selectedCost)[0]} : {}"
        @close="budgetModalOpen = false"
        :isEdit="isEdit"
        @save="budgetModalOpen = false; loadData()"
      ></buy-manage-costs-modal>
    </v-dialog>
    <v-dialog
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-10"
      width="600px"
      max-width="90%">
      <v-card class="p-relative rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Delete cost
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Do you want to delete this cost?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteConfirm"
                class="red--text mx-4"
              >Ok</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from '../services/axios'
import { getHeadersWithAccessToken } from '@/variables'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '../components/TableSearch'
import BuyManageCostsModal from './BuyManageCostsModal'
import baseURLs from '@/api/APIBaseURLs'

export default {
  name: 'BuyManageCosts',
  mixins: [columnFilters],
  components: { BuyManageCostsModal, TableSearch },
  props: ['properties', 'payments', 'costsData'],
  data () {
    return {
      costs: [],
      selectedCost: null,
      costsFilters: [],
      budgetModalOpen: false,
      deleteDialogVisible: false,
      isEdit: false,
      loading: true,
    }
  },
  mounted () {
    if (this.costsData) {
      this.loading = false
      this.costs = [...this.costsData]
    }
  },
  watch: {
    costsData (newValue) {
      if (newValue) {
        this.loading = false
        this.costs = [...newValue]
      }
    }
  },
  methods: {
    selectBudget (budget) {
      if (this.selectedCost === budget.cost_key) {
        this.selectedCost = null
        budget = null
      } else {
        this.selectedCost = budget.cost_key
      }
    },
    getPercentages (perc) {
      if (!perc) {
        return '-'
      }
      perc *= 100
      const fixedPerc = perc.toFixed(2).split('.')
      if (fixedPerc[1] === '00') {
        return perc.toFixed(0) + '%'
      }
      return perc.toFixed(2) + '%'
    },
    loadData () {
      this.$emit('load-costs')
    },
    handleDeleteConfirm () {
      const url = baseURLs.manageAPI + '/cost'
      axios.delete(
        url,
        { headers: getHeadersWithAccessToken(), data: { cost_key: this.selectedCost }
        }).then(() => {
        this.loadData()
        this.selectedCost = null
        this.deleteDialogVisible = false
      })
    },
  }
}
</script>

<style scoped>
.buy-manage-budgets-main {
}
</style>
