<template>
  <v-row>
    <v-col>
      <h6 class="text-subtitle-1 font-weight-medium mt-2 px-5">Prepacks for option</h6>
      <v-row>
        <div class="col text-right pb-8 px-8">
          <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
        </div>
      </v-row>
      <data-tables
        v-if="!!prepacks?.table"
        :data="prepacks.table"
        :key="listKey"
        class="option-prepacks-table"
        :filters="tableFilters"
        :page-size="prepacks.table.length"
        :pagination-props="{ class: 'd-none' }"
        :table-props="{ 'row-key': 'Prepack', 'row-class-name': ({row})=> row.Prepack }"
      >
        <el-table-column
          class-name="px-3"
          :prop="column"
          v-for="column of prepacks.columns"
          :key="column"
          :label="column | formatString"
        >
          <template v-slot="scope">
            <v-text-field
              v-if="column === 'Keep' && scope.row.Prepack !=='Pieces' && scope.row.Prepack !== 'Total'"
              type="number"
              class="mt-0 pt-0"
              hide-details="auto"
              :value="getKeepValue(scope.row)"
              @input="amountChange($event, scope.row)"
              :error-messages="amountErrors"
            ></v-text-field>
            <span v-else-if="column !== 'Keep'">
              {{scope.row[column]}}
            </span>
          </template>
        </el-table-column>
      </data-tables>
    </v-col>
  </v-row>
</template>

<script>
import TableSearch from '@/components/TableSearch.vue'
import _ from 'lodash'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'
import { useArticleStore } from '@/store/pinia/stockArticlesStore'

export default {
  setup () {
    return {
      proposalStore: useStockProposalStore(),
      articlesStore: useArticleStore(),
    }
  },
  name: 'OptionPrepacks',
  props: ['prepacks'],
  components: { TableSearch },
  data () {
    return {
      amountChange: _.debounce(this.onAmountChange, 1000),
      amountErrors: null,
      tableFilters: [
        {
          value: ''
        }
      ],
      listKey: 0,
    }
  },
  methods: {
    getKeepValue (prepack) {
      const setting = this.articlesStore.articleSettings || {}
      const prepacks_keep = setting.stock_parameters?.prepacks_keep || {}
      return prepacks_keep[prepack.Prepack] || 0
    },
    async onAmountChange (value, prepack) {
      if (!value && value !== 0) {
        return
      }
      value = parseFloat(value)
      if (value < 0 || value > prepack.Amount) {
        this.amountErrors = ['Must be between 0 and ' + prepack.Amount]
        return
      }
      this.amountErrors = null
      const setting = this.articlesStore.articleSettings || {}
      try {
        const prepacks_keep = setting.stock_parameters?.prepacks_keep || {}
        prepacks_keep[prepack.Prepack] = parseFloat(value)
        setting.stock_parameters.prepacks_keep = prepacks_keep
        await this.articlesStore.saveOptionSettings(setting, this.$route.query)
        this.$emit('changed')
      } catch (e) {
        console.log(e)
      }
    },
    handleSearchChanged (val) {
      this.tableFilters[0].value = val
      this.listKey++
    },
  }
}
</script>

<style lang="scss">
.option-prepacks-table {
  .el-table{
    .Total {
      background-color: #f5f7fa;
    }
    .cell {
      overflow: visible;
    }
  }
}
</style>
