<template>
  <div class="shop-item-order-form-pieces">
    <data-tables
      style="width: 100%"
      class="mb-4"
      :data="piecesRows"
      :page-size="piecesRows.length"
      :pagination-props="{ pageSizes: [piecesRows.length], hideOnSinglePage: true }"
      :table-props="{ 'row-key': 'name' }">
      <el-table-column
        prop="name"
        label="Name"
        class-name="pl-8"
      >
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column
        v-for="sku in skuCols"
        :key="sku"
        :prop="sku"
        :label="sku"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.name !== 'Order'">
            <div class="pr-1">{{ scope.row[sku] | formatThousands }}</div>
            <div class="d-flex flex-column align-baseline justify-start" v-if="scope.row.name === 'Order %'">
              <v-icon size="16" @click="arrowUpClick(sku)" :disabled="scope.row[sku] === '100%'">arrow_drop_up</v-icon>
              <v-icon size="16" @click="arrowDownClick(sku)" :disabled="scope.row[sku] === '0%'">arrow_drop_down</v-icon>
            </div>
          </template>
          <template v-else>
            <v-text-field
              class="pt-0 mb-5"
              type="number"
              v-model.number="piecesToOrder[sku]"
              :error-messages="piecesToOrderErrors[sku]"
              hide-details
              @input="handleToOrderChanged()"
            ></v-text-field>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="total" label="Total">
        <template slot-scope="scope">
          <template v-if="scope.row.name !== 'Order'">
            {{ scope.row.total | formatThousands }}
          </template>
          <template v-else>
            <v-text-field
              type="number"
              class="pt-0 pieces-total-field"
              :messages="!Number.isInteger(scope.row.total / item.order_settings.box_size) ? `Box size: ${item.order_settings.box_size}`: ''"
              :value="scope.row.total"
              @input="handleToOrderTotalChangedDebounced"
            ></v-text-field>
          </template>
        </template>
      </el-table-column>
      <el-table-column width="82" class-name="pr-8">
        <template slot-scope="scope">
          <template v-if="scope.row.name === 'Order'">
            <v-icon
              class="my-1"
              @click="handlePiecesResetClick"
            >replay</v-icon>
          </template>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import _ from 'lodash'
// import * as shopApi from '@/api/shop'

export default {
  components: {
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    orders: {
      type: Array,
      default: () => [],
    },
    commitments: {
      type: Object,
      required: true,
    },
    orderLine: {
      type: Object,
      required: true,
    },
    skuCols: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      handleToOrderTotalChangedDebounced: _.debounce(this.handleToOrderTotalChanged, 400),
      piecesToOrderErrors: {},
      piecesToOrder: {},
      piecesToOrderDefault: {},
    }
  },
  computed: {
    piecesCommitted () {
      return this.skuCols.reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: Math.round(this.commitments.committed[cur] || 0),
        }),
        {},
      )
    },
    piecesOrdered () {
      const otherOrders = this.orders.filter(o => o.order_key && o.order_key !== this.order.order_key && o.status !== 'to_order')
      return this.skuCols.reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: _.sumBy(otherOrders, o => o.pieces[cur] || 0),
        }),
        {},
      )
    },
    piecesToOrdered () {
      const otherOrders = this.orders.filter(o => o.order_key && o.order_key !== this.order.order_key && o.status === 'to_order')

      return this.skuCols.reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: _.sumBy(otherOrders, o => o.pieces[cur] || 0),
        }),
        {},
      )
    },
    piecesToOrderValid () {
      return Object.keys(this.piecesToOrderErrors).every(sku => {
        return this.piecesToOrderErrors[sku] === null
      })
    },
    piecesPercentage () {
      return this.skuCols.reduce(
        (acc, curSku) => ({
          ...acc,
          [curSku]: Math.round(((this.piecesToOrder[curSku] / this.piecesToOrderTotal) * 100) || 0) + '%'
        }),
        {},
      )
    },
    piecesCommittedTotal () {
      return this.skuCols.reduce(
        (acc, curSku) => acc + this.piecesCommitted[curSku],
        0,
      )
    },
    piecesOrderedTotal () {
      return this.skuCols.reduce(
        (acc, curSku) => acc + this.piecesOrdered[curSku],
        0,
      )
    },
    piecesToOrderTotal () {
      return this.skuCols.reduce(
        (acc, curSku) => acc + this.piecesToOrder[curSku],
        0,
      )
    },
    piecesToOrderedTotal () {
      return this.skuCols.reduce(
        (acc, curSku) => acc + this.piecesToOrdered[curSku],
        0,
      )
    },
    piecesPercentageTotal () {
      return '100%'
    },
    piecesRows () {
      return [{
        name: 'Committed',
        ...this.piecesCommitted,
        total: this.piecesCommittedTotal,
      }, {
        name: 'Ordered + In progress',
        ...this.piecesOrdered,
        total: this.piecesOrderedTotal,
      }, {
        name: 'To order',
        ...this.piecesToOrdered,
        total: this.piecesToOrderedTotal,
      }, {
        name: 'Order',
        ...this.piecesToOrder,
        total: this.piecesToOrderTotal,
      }, {
        name: 'Order %',
        ...this.piecesPercentage,
        total: this.piecesPercentageTotal,
      }]
    },
  },
  watch: {
  },
  methods: {
    arrowUpClick (sku) {
      this.createNewPercentages(sku, 1)
    },
    arrowDownClick (sku) {
      this.createNewPercentages(sku, -1)
    },
    createNewPercentages (sku, increaseValue) {
      const total = this.piecesToOrderTotal
      const newValue = parseInt(total / 100 * increaseValue)
      this.changeOtherPercentage(sku, increaseValue, newValue, total)
      this.validatePiecesToOrder()
      this.$emit('pieces-to-order-validated', this.piecesToOrderValid)
      this.$emit('pieces-to-order-changed', this.piecesToOrder)
    },
    changeOtherPercentage (sku, increaseValue, newValue, oldTotal) {
      if (increaseValue === 1 && (this.oldPercentages[sku] === '98%' ||
        this.oldPercentages[sku] === '99%')) {
        this.piecesToOrder[sku] = oldTotal
        for (const key in this.piecesToOrder) {
          if (key !== sku) {
            this.piecesToOrder[key] = 0
          }
        }
        return
      }
      if (increaseValue === -1 && this.oldPercentages[sku] === '1%') {
        newValue = this.piecesToOrder[sku] * increaseValue
      }
      const keys = []
      for (const key in this.oldPercentages) {
        if (key === sku) {
          continue
        }
        const newKeyPerc = parseInt(this.oldPercentages[key].replace('%', ''))
        if ((increaseValue === 1 && newKeyPerc > 0) || (increaseValue === -1 && newKeyPerc < 100)) {
          keys.push(key)
        }
      }
      this.piecesToOrder[sku] += newValue
      for (const key of keys) {
        this.piecesToOrder[key] += (parseInt(this.oldPercentages[key].replace('%', '') * newValue * -1 / 100))
      }
      const total = this.skuCols.reduce(
        (acc, curSku) => acc + this.piecesToOrder[curSku],
        0,
      )
      const difference = total - oldTotal
      const differenceEach = difference / keys.length
      const differenceEachInt = Math.round(differenceEach)
      for (const key of keys) {
        this.piecesToOrder[key] -= differenceEachInt
      }
      this.piecesToOrder[sku] -= difference - (differenceEachInt * keys.length)
      this.oldPercentages = { ...this.piecesPercentage }
    },
    validatePiecesToOrder () {
      this.skuCols.forEach(sku => {
        this.piecesToOrderErrors = {
          ...this.piecesToOrderErrors,
          [sku]: !Number.isFinite(this.piecesToOrder[sku]) || this.piecesToOrder[sku] < 0 ? 'Should be >= 0' : null,
        }
      })
    },
    handleToOrderChanged (savePercentages = true) {
      if (savePercentages) {
        this.oldPercentages = { ...this.piecesPercentage }
      }
      this.validatePiecesToOrder()
      this.$emit('pieces-to-order-validated', this.piecesToOrderValid)
      this.$emit('pieces-to-order-changed', this.piecesToOrder)
    },
    handleToOrderTotalChanged (newVal) {
      let piecesDelta = 0
      const totalDelta = parseInt(newVal)

      this.skuCols.forEach(sku => {
        const newPieces = !newVal
          ? 0
          : Math.round(this.oldPercentages[sku].replace('%', '') * newVal / 100)
        piecesDelta += newPieces
        this.piecesToOrder[sku] = newPieces
      })
      const keys = Object.keys(this.piecesToOrder)
      for (const key in this.piecesToOrder) {
        this.piecesToOrder[key] += Math.round((totalDelta - piecesDelta) / keys.length)
      }
      const piecesNewDelta = keys.reduce((prev, cur) => this.piecesToOrder[cur] + prev, 0)
      const difference = piecesNewDelta - totalDelta
      for (let i = 0; i < Math.abs(difference); i++) {
        this.piecesToOrder[keys[i]] -= difference
      }
      this.handleToOrderChanged(false)
    },
    handlePiecesResetClick () {
      this.skuCols.forEach(sku => {
        this.piecesToOrder[sku] = this.piecesToOrderDefault[sku] || 0
      })
      this.handleToOrderChanged()
    },
  },
  created () {
    this.piecesToOrderDefault = { ...this.orderLine.pieces }
    this.piecesToOrder = this.skuCols.reduce((acc, cur) => ({
      ...acc,
      [cur]: this.orderLine.pieces[cur] || 0,
    }), {})
    this.handleToOrderChanged()
  },
}
</script>

<style lang="scss">
.shop-item-order-form-pieces {
  .el-table::before {
    display: none;
  }
  .el-table__row:last-child {
    td {
      border-bottom: 0;
    }
  }
  .el-table {
    td{
      padding: 0;
      .cell {
        display: flex;
        align-items: center;
        padding: 8px;
      }
    }
  }
  .el-table {
      td{
        padding: 0;
        .cell {
          display: flex;
          align-items: center;
          padding: 8px;
        }
      }
    }
    .v-text-field {
    width: 80px;
    font-size: 13px;
  }
  .pieces-total-field {
    .v-messages__message {
      color: #FF9800
    }
  }
}
</style>
