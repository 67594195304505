<template>
  <div class="stockTasks">
    <div class="container-fluid">
      <div class="row">
        <div :class="width > 1800 ? 'col-6' : 'col-12'" v-for="(_, key) in tasksStore.taskEndpoints" :key="key">
          <app-stock-tasks-table :taskType="key" class="mb-50"></app-stock-tasks-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StockTasksTable from '@/components/Home/StockTasksTable.vue'

import { useStockTasksStore } from '@/store/pinia/tasksStore'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

export default {
  setup () {
    return {
      proposalStore: useStockProposalStore(),
      tasksStore: useStockTasksStore()
    }
  },
  data () {
    return {
      loaded: true,
      width: 0
    }
  },
  components: {
    appStockTasksTable: StockTasksTable
  },
  methods: {
    handleResize () {
      this.width = window.innerWidth
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  mounted () {
    // Check if all tasks are loaded, and if not dispatch an action
    const allTrue = Object.values(this.tasksStore.tasksLoaded).every(Boolean)
    if (!allTrue) {
      this.tasksStore.getAllTasks().then(() => {
        this.loaded = true
      })
    }
    // Already load the rules and defaults, so that proposal is faster later on
    if (!this.proposalStore.rulesLoaded || !this.proposalStore.defaultsLoaded) {
      this.proposalStore.setRules()
      this.proposalStore.setDefaults()
    }

    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.width = window.innerWidth
      })
    })
  }
}
</script>

<style lang="scss">
.stockTasks {
    table.v-table thead tr th {
        font-size: 14px;
    }
    table.v-table tbody tr td {
        font-size: 14px;
    }
    .searchbar {
        width: 200px !important;
    }
    .btn-outline-primary {
        font-size: 14px;
    }
    a {
            color: #007bff;
    }
    .btn-outline-success {
        font-size: 14px;
        font-weight: 500;
        color: #28a745;
    }
    .btn-outline-success:hover {
        color: #fff;
    }
}
</style>
