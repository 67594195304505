<template>
  <v-autocomplete
    ref="weekSelector"
    class="weekSelector"
    :items="visibleWeeks"
    :label="label"
    :clearable="clearable"
    :menu-props="{
      left: true,
      offsetY: true,
      // attach: rootEl,
      contentClass: `week-selector-menu ${label.replace(' ','-')  || 'no-label'}`,
    }"
    :error-messages="errorMessages"
    :hideDetails="hideDetails"
    :value="value?.toString()"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @focus="scrollToCurrentWeek"
  ></v-autocomplete>
</template>

<script>
import { getWeeksSelectorOptions } from '@/utils'
import { format } from 'date-fns'

export default {
  name: 'WeekSelector',
  data () {
    return {
      isBlur: false,
      rootEl: null,
    }
  },
  props: {
    value: {
      type: [String, Number],
      default () {
        return ''
      }
    },
    label: {
      type: String,
      default () {
        return ''
      }
    },
    clearable: {
      type: Boolean,
      default () {
        return false
      }
    },
    hideDetails: {
      type: [Boolean, String],
      default () {
        return false
      }
    },
    errorMessages: {
      type: [Array, String],
      default () {
        return null
      }
    },
    startYear: {
      type: Number,
      default () {
        return -1
      }
    },
    endYear: {
      type: Number,
      default () {
        return 2
      }
    },
    onlyFuture: {
      type: Boolean,
      default () {
        return false
      }
    },
    hasNoWeekItem: {
      type: Boolean,
      default () {
        return false
      }
    },
    lowerLimit: {
      type: [String, Number],
      default () {
        return ''
      }
    },
    higherLimit: {
      type: [String, Number],
      default () {
        return ''
      }
    },
  },
  computed: {
    currentWeek () {
      return format(new Date(), 'yyyyww')
    },
    currentIndex () {
      return this.visibleWeeks.findIndex((item) => item.value === this.currentWeek)
    },
    visibleWeeks () {
      const weeks = getWeeksSelectorOptions(this.startYear, this.endYear, this.onlyFuture).filter(w => {
        const lower = !this.lowerLimit || parseInt(w.value) >= parseInt(this.lowerLimit)
        const higher = !this.higherLimit || parseInt(w.value) <= parseInt(this.higherLimit)
        return lower && higher
      })
      if (this.value && weeks.findIndex((w) => w.value.toString() === this.value.toString()) === -1) {
        weeks.splice(0, 0, {
          text: this.$options.filters.formatWeekNumber(this.value),
          value: this.value.toString(),
          disabled: true,
        })
      }

      if (this.hasNoWeekItem) {
        return [{ text: 'No Week', value: '' }, ...weeks]
      }
      return weeks
    },
  },
  methods: {
    scrollToCurrentWeek () {
      let scrollElement = document.querySelector(`.week-selector-menu.${this.label.replace(' ', '-') || 'no-label'}`)
      if (this.value || this.onlyFuture) {
        return
      }
      setTimeout(() => {
        if (!scrollElement) {
          scrollElement = document.querySelector(`.week-selector-menu.${this.label.replace(' ', '-') || 'no-label'}`)
        }
        scrollElement?.scroll(0, this.currentIndex * 48 - 10)
      }, 300)

      setTimeout(() => {
        if (!scrollElement) {
          scrollElement = document.querySelector(`.week-selector-menu.${this.label.replace(' ', '-') || 'no-label'}`)
        }
        scrollElement?.scroll(0, this.currentIndex * 48 - 10)
      }, 400)

      setTimeout(() => {
        if (!scrollElement) {
          scrollElement = document.querySelector(`.week-selector-menu.${this.label.replace(' ', '-') || 'no-label'}`)
        }
        scrollElement?.scroll(0, this.currentIndex * 48 - 10)
      }, 500)
      setTimeout(() => {
        if (!scrollElement) {
          scrollElement = document.querySelector(`.week-selector-menu.${this.label.replace(' ', '-') || 'no-label'}`)
        }
        const titles = scrollElement?.querySelectorAll('.v-list-item__title')
        if (titles) {
          for (const title of titles) {
            if (title.innerText === format(new Date(), 'yyyy - \'W\'ww')) {
              title.parentNode.parentNode.style.backgroundColor = ' rgba(0, 0, 0, 0.08)'
            }
          }
        }
        scrollElement?.scroll(0, this.currentIndex * 48 - 10)
      }, 600)
    },
  },
  mounted () {
    this.rootEl = this.$el
  },
}
</script>
