import axios from '@/services/axios'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

export async function getPlaceholders (payload) {
  const url = baseURLs.manageAPI + '/data/strategy/collections/get_placeholders'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data.data)
}

export async function getStrategyCollectionChunks (payload) {
  const url = baseURLs.manageAPI + '/data/strategy/collections/get_chunks'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data.data)
}

export async function createStrategyCollectionChunk (payload) {
  const url = baseURLs.manageAPI + '/chunks'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken(),
  }).then(response => response.data.chunk)
}

export async function updateStrategyCollectionChunk (payload) {
  const url = baseURLs.manageAPI + '/chunks'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken(),
  }).then(response => response.data)
}

export async function deleteStrategyCollectionChunk (chunkKey) {
  const url = baseURLs.manageAPI + '/chunks'

  return axios.delete(url, {
    headers: getHeadersWithAccessToken(),
    data: {
      chunk_key: chunkKey,
    }
  }).then(response => response.data)
}

export async function getStrategyCollectionProducts (payload) {
  const url = baseURLs.manageAPI + '/data/strategy/collections/get_products'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data.data)
}
export async function getStrategyCollectionProduct (payload) {
  const url = baseURLs.manageAPI + '/data/strategy/collections/get_product'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data.data)
}

export async function updateStrategyCollectionProduct (payload) {
  const url = baseURLs.manageAPI + '/products'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken(),
  }).then(response => response.data)
}

export async function deleteStrategyCollectionProduct (productKey) {
  const url = baseURLs.manageAPI + '/products'

  return axios.delete(url, {
    headers: getHeadersWithAccessToken(),
    data: {
      product_key: productKey,
    }
  }).then(response => response.data)
}

export async function deletePlaceholder (placeholderKey) {
  const url = baseURLs.manageAPI + '/collections_placeholders'

  return axios.delete(url, {
    headers: getHeadersWithAccessToken(),
    data: {
      placeholder_key: placeholderKey,
    }
  }).then(response => response.data)
}

export async function getOptionsWithoutChunks (payload) {
  const url = baseURLs.manageAPI + '/data/strategy/collections/get_options_without_chunks'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data.data)
}

export async function copyExistingOptionToPlaceholder (payload) {
  const url = baseURLs.manageAPI + '/collections_copy_existing'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data.data)
}

export async function getStrategyPeriods () {
  const url = baseURLs.manageAPI + '/strategy_periods'

  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data.data)
}

export async function getTimeMachineData (payload) {
  const url = baseURLs.tsqlAPI + '/time_machine'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data)
}

export async function getCubeHistoricAnomaly (payload) {
  const url = baseURLs.logicAPI + '/cube_historic_anomaly'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data)
}

export async function getOptionStory (payload) {
  const url = baseURLs.tsqlAPI + '/options_story'
  payload.option_keys = [...(new Set(payload.option_keys))]
  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data)
}

export async function getTimeMachineDataTSQL (payload, cubeKey, versionKey) {
  const url = `${baseURLs.tsqlAPI}/strategy_collection?cube_key=${cubeKey}&version_key=${versionKey}`

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data)
}

export async function getCollectionOverview (payload) {
  const url = `${baseURLs.tsqlAPI}/strategy_collection_overview`

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data)
}

export async function generateProducts (payload) {
  const url = `${baseURLs.tsqlAPI}/strategy_collection_fills`

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data)
}
