<template>
  <div class="import-combinations pt-6">
    <h4 class="card-title text-left px-6">Upload</h4>
    <div class="ml-6 grey--text text--darken-2 text-caption font-weight-medium pt-3 pb-6">
      Upload set of combinations. Download combinations table for required upload file format. Uploading will overwrite existing combination. Rows which are not in the upload, will not be deleted.
    </div>
    <template v-if="!file">
      <h6 class="text-subtitle-1 font-weight-medium mb-4 ml-6">1. Import</h6>
      <div class="mx-6 overflow-hidden">
        <div class="p-relative file-drop-zone" @dragover="dragover" @dragleave="dragleave" @drop="drop">
          <input
            type="file"
            id="assetsFieldHandle"
            @change="onFileChange"
            ref="file"
            accept=".csv"
          />
          <label for="assetsFieldHandle">
            <span class="material-icons icon">system_update_alt</span>
            <span>Choose a file or drag it here</span>
          </label>
        </div>
      </div>
    </template>
    <template v-if="file">
      <h6 class="text-subtitle-1 font-weight-medium ml-6">1. File</h6>
      <div class="d-flex align-center ml-6 mt-2 mb-4">
        <span class="text-caption font-weight-medium grey--text text--darken-2">{{ file.name }}</span>
        <v-icon class="ml-2 action-btn-danger" @click="removeFile">delete</v-icon>
      </div>
      <div class="rounded elevation-2 mx-6">
        <data-tables
          style="width: 100%"
          :data="parsedResults.slice(0, rowsNum)"
          :pagination-props="{ pageSize: rowsNum, layout: '' }">
          <el-table-column v-for="(col, i) in columns" :key="i" :prop="col" :label="col">
            <template v-slot:header="{ column }">
              <div class="ml-3">
                {{ formatColumns(column.label) }}
              </div>
            </template>
            <template slot-scope="scope">
              <div class="ml-3">
                {{ scope.row[col] }}
              </div>
            </template>
          </el-table-column>
        </data-tables>
        <div class="d-flex justify-end py-6 pr-6">
          <div class="grey--text text--darken-2 text-caption font-weight-medium">
            <div>Showing first {{ rowsNum }} rows and columns</div>
            <div>Total rows: {{ parsedResults.length }}</div>
          </div>
        </div>
      </div>
      <div class="mt-6 ml-6 grey--text text--darken-2 text-caption font-weight-medium" :class="{ 'error--text': importError }">
        <template v-if="importError">
          {{ importError }}
        </template>
        <template v-else-if="importSuccess">
          {{ importSuccess }}
        </template>
        <template v-else>
          No import error
        </template>
      </div>
    </template>
    <div class="mx-6 my-6">
      <v-btn
        color="primary"
        depressed
        outlined
        :disabled="!!importError || !file"
        @click="handleUpload"
        :loading="uploading"
      >Upload</v-btn>
    </div>
  </div>
</template>

<script>
import * as Papa from 'papaparse'
import { formatString } from './variables'
import { uploadCombinations } from './api/combinations'

export default {
  name: 'ImportCombinations',
  data () {
    return {
      rowsNum: 5,
      colsNum: 5,
      file: null,
      importError: null,
      importSuccess: null,
      uploading: false,
      parsedResults: [],
      options: [],
      chosenOption: '',
      chosenColumn: '',
    }
  },
  computed: {
    columns () {
      return this.parsedResults[0] ? Object.keys(this.parsedResults[0]).slice(0, 5) : []
    }
  },
  methods: {
    dragover (event) {
      event.preventDefault()
      event.currentTarget.classList.add('hover')
    },
    formatColumns (item) {
      return formatString(item)
    },
    dragleave (event) {
      event.currentTarget.classList.remove('hover')
    },
    drop (event) {
      event.preventDefault()
      event.currentTarget.classList.remove('hover')

      this.$refs.file.files = event.dataTransfer.files
      this.onFileChange()
    },
    onFileChange () {
      this.file = this.$refs.file.files[0]
      this.importError = null
      this.importSuccess = null
      this.parsedResults = []

      Papa.parse(this.file, {
        header: true,
        dynamicTyping: true,
        complete: results => {
          this.parsedResults = results.data.map((item) => {
            if (typeof item.costs !== 'string') {
              return item
            }
            item.costs = parseFloat(item.costs.replace(',', '.'))
            return item
          })
          if (!results.data.length) {
            this.importError = 'No rows found'
          } else {
            this.parsedResults = this.parsedResults.filter(item => item.store_key)
          }
        }
      })
    },
    removeFile () {
      this.file = null
      this.parsedResults = []
    },
    handleUpload () {
      this.uploading = true
      uploadCombinations({ combinations: this.parsedResults }).then(r => {
        this.uploading = false
        this.importSuccess = r
      })
        .catch(error => {
          this.uploading = false
          this.importError = error.response.data.message
        })
    }
  },
}
</script>

<style lang="scss">
.import-combinations {
  .file-drop-zone {
    input {
      position: absolute;
      opacity: 0;
    }
    label {
      border: 5px dashed #ccc;
      height: 250px;
      padding-top: 30px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .icon {
        font-size: 48px;
        margin-bottom: 20px;
      }
    }
    &:hover,
    &.hover {
      label {
        background: rgba(0, 122, 255, 0.06);
      }
    }
  }
}

</style>
