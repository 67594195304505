import {defineStore} from 'pinia'
import * as api from '@/api/stockOverviewAPI'
import * as models from '@/models/StockOverview'

export const useStockOverviewStore = defineStore('stockOverview', {
  state: (): models.StockOverviewState => ({
    dimensionsList: [],
    viewsList: [],
    selectedDimensions: ['properties/store/name', 'properties/article/group'],
    chartAxis: [],
    dataLoading: false,
    overviewChartData: [],
    overviewDimensionsData: [],
    dimensionsColumnsList: [],
    chartColorData: [],
    dimensionsColorData: [],
    optionsColorData: [],
    selectedView: 'projection',
    dimensionTableChildData: [],
    dimension1Value: null,
    optionsColumnsList: [],
    optionsTableData: [],
    optionsTableDataLoading: false,
  }),
  actions: {
    async getDimensionsAndViews () {
      try {
        const { properties, views } = await api.getDimensionsAndViews()
        this.dimensionsList = properties
        this.viewsList = views
      } catch (error) {
        console.error(error)
      }
    },
    async getOverviewData (payload: models.OverviewDataPayload) {
      this.dataLoading = true
      try {
        const data = await api.getOverviewData({
          ...payload,
          filters: {
            collection_key: payload.filters.collections,
            group_key: payload.filters.groups,
            option_key: payload.filters.options,
            store_key: payload.filters.stores_dc,
          },
        })
        this.dataLoading = false
        return data
      } catch (error) {
        console.error(error)
      }
    },
    async getDimensionTableChildData (payload: models.DimensionTableChildDataPayload) {
      this.dataLoading = true
      try {
        const { collections, groups, options, stores_dc, ...dimension1 } = payload.filters
        const response = await api.getDimensionTableChildData({
          ...payload,
          filters: {
            ...dimension1,
            collection_key: collections,
            group_key: groups,
            option_key: options,
            store_key: stores_dc,
          },
        })
        this.dataLoading = false
        this.dimensionTableChildData = response.data
      } catch (error) {
        console.error(error)
      }
    },
    setDimensionTableChildData (data: unknown[]) {
      this.dimensionTableChildData = data
    },
    async getOptionsTableData (payload: models.OptionsTableDataPayload) {
      this.optionsTableDataLoading = true
      try {
        const { collections, groups, options, stores_dc, ...dimension1 } = payload.filters
        const response = await api.getOptionsTableData({
          ...payload,
          filters: {
            ...dimension1,
            collection_key: collections,
            group_key: groups,
            option_key: options,
            store_key: stores_dc,
          },
        })
        this.optionsTableDataLoading = false
        this.optionsColumnsList = response.columns
        this.optionsTableData = response.data
        this.optionsColorData = response.colors
      } catch (error) {
        console.error(error)
      }
    },
    setOverviewChartData (data: unknown[]) {
      this.overviewChartData = data
    },
    setOverviewDimensionsData (data: unknown[]) {
      this.overviewDimensionsData = data
    },
    setChartAxis (axis: unknown[]) {
      this.chartAxis = axis
    },
    setDimensionsColumnsList (columns: unknown[]) {
      this.dimensionsColumnsList = columns
    },
    setChartColorData (data: unknown[]) {
      this.chartColorData = data
    },
    setDimensionsColorData (data: unknown[]) {
      this.dimensionsColorData = data
    },
    setSelectedDimensions (dimensions: string[]) {
      this.selectedDimensions = dimensions.slice()
    },
    setSelectedView (view: string) {
      this.selectedView = view
    },
    setDimension1Value (value: unknown) {
      this.dimension1Value = value
    },
    setOptionsTableData (data: unknown[]) {
      this.optionsTableData = data
    },
  },
})
