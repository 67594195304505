<template>
  <div
    id="strategy-period-option-story"
  >
    <v-row v-if="option">
      <v-col cols="3">
        <v-img
          :lazy-src="placeholder_image"
          height="160"
          contain
          class="mt-6 mb-4"
          :src="option.image_url"
        />
        <div v-if="option['name'].split(' / ').length === 2" class="option-name-wrapper">
          <router-link class="card-title" :to="option.route">
            {{ option['name'].split(" / ")[0] }}
          </router-link>
          <p class="card-subtitle">{{ option['name'].split(" / ")[1] }}</p>
        </div>
        <template v-else>
          <p class="card-title">
            {{ option['name'].split(" / ") }}
          </p>
        </template>
        <p class="price">
          {{ option.original_price | currency }}
          <span v-if="option.original_price !== option.retail_price"> / {{ option.retail_price | currency }}</span>
        </p>
        <v-row>
          <v-col>
            <v-icon>savings</v-icon>
            <span class="pl-2 kpi-value">{{ option.revenue | currency_no_decimals }}</span>
          </v-col>
          <v-col>
            <v-icon>shopping_basket</v-icon>
            <span class="pl-2 kpi-value">{{ option.sales_net }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-icon>mdi-margin</v-icon>
            <span class="pl-2 kpi-value">{{ option.markdown | formatPercents }}%</span>
          </v-col>
          <v-col class="pt-0">
            <v-icon>percent</v-icon>
            <span class="pl-2 kpi-value">{{ option.margin | formatPercents }}%</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="9">
        <strategy-period-option-story-chart
          :story="story"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StrategyPeriodOptionStoryChart from '@/components/Strategy/StrategyPeriodOptionStoryChart.vue'
import placeholder_image from '@/assets/img/placeholder-image.png'

export default {
  name: 'StrategyPeriodOptionStory',
  data () {
    return {
      placeholder_image
    }
  },
  components: { StrategyPeriodOptionStoryChart },
  props: {
    story: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    option () {
      return this.story?.info.data[0]
    },
  }
}
</script>

<style lang="scss">
#strategy-period-option-story {
    .option-name-wrapper {
        overflow: hidden;
    }

    .card-title {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: var(--text-black);
        text-decoration: none;
        white-space: nowrap;
    }

    .card-title:hover {
        color: var(--primary);
    }

    .card-subtitle {
        font-size: 14px;
        color: var(--text-sub-black);
        margin: 0;
    }

    .price {
        color: var(--text-black);
        font-size: 14px;
        font-weight: 500;
        margin: 12px 0;
    }

    .kpi-value {
        color: var(--text-black);
        font-size: 14px;
        font-weight: 500;
    }
}
</style>
