<template>
  <v-card class="positions-modal">
    <v-card-title class="text-body-1 font-weight-medium py-6 justify-space-between px-12">
      Positions
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="">
      <v-row class="ma-0">
        <v-col cols="3" class="text-center">A</v-col>
        <v-col cols="3" class="text-center">B</v-col>
        <v-col cols="3" class="text-center">C</v-col>
        <v-col cols="3" class="text-center">D</v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row
        class="ma-0 flex-nowrap">
        <v-col cols="auto" class=" d-flex align-center"><div>0</div></v-col>
        <v-row class="ma-0">
          <v-col cols="3" v-for="(view_key, secondaryIndex) of [null,null,null, null]" :key="view_key">
            <div class="empty-view"
              :id="`-1.${secondaryIndex}`"
              @drop="ondrop"
              @dragover="allowDrop"></div>
          </v-col>
        </v-row>
      </v-row>
      <v-row
        class="ma-0 flex-nowrap"
        v-for="(viewsList, firstIndex) of dashboardDetails.view_keys" :key="firstIndex">
        <v-col cols="auto" class=" d-flex align-center"><div>{{firstIndex+1}}</div></v-col>
        <v-row class="ma-0">

          <v-col cols="3" v-for="(view_key, secondaryIndex) of [...viewsList, null,null,null, null].slice(0,4)" :key="view_key">
            <div v-if="view_key">
              <v-card
                :id="`${firstIndex}.${secondaryIndex}`"
                draggable="true"
                @dragstart="drag"
                @drop="ondrop"
                @dragover="allowDrop"
                class="pa-3">
                <v-row class="ma-0 align-center justify-space-between text-center">
                  <v-icon class="mr-3">{{ getIcon(view_key) }}</v-icon>
                  <h4 class="text-center">{{viewsMap[view_key]?.name}}</h4>
                  <v-icon class="mr-3" style="visibility:hidden;">{{ getIcon(view_key) }}</v-icon>
                </v-row>
                <v-row class="ma-0 flex-nowrap align-center">
                  <v-autocomplete
                    clearable
                    hide-details
                    prefix="W"
                    class="pt-0 mt-0 pr-2"
                    :items="getWidthPositions(view_key, viewsList)"
                    v-model="viewsMap[view_key].properties.length"
                    @input="changedViews = [...changedViews, view_key]"
                  ></v-autocomplete>
                  x
                  <v-autocomplete
                    clearable
                    hide-details
                    prefix="H"
                    class="pt-0 mt-0 pl-2"
                    :items="getHeightPositions()"
                    v-model="viewsMap[view_key].properties.height"
                    @input="changedViews = [...changedViews, view_key]"
                  ></v-autocomplete>
                </v-row>
              </v-card>
            </div>
            <div class="empty-view" v-else
              :id="`${firstIndex}.${secondaryIndex}`"
              @drop="ondrop"
              @dragover="allowDrop"></div>
          </v-col>
        </v-row>
      </v-row>
      <v-row
        class="ma-0 flex-nowrap">
        <v-col cols="auto" class=" d-flex align-center"><div>{{dashboardDetails.view_keys.length+1}}</div></v-col>
        <v-row class="ma-0">
          <v-col cols="3" v-for="(view_key, secondaryIndex) of [null,null,null, null]" :key="view_key">
            <div class="empty-view"
              :id="`${dashboardDetails.view_keys.length}.${secondaryIndex}`"
              @drop="ondrop"
              @dragover="allowDrop"></div>
          </v-col>
        </v-row>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <v-row class="dialog-footer align-center justify-space-between py-2 px-4">
        <v-col>
          <div
            class="delete-view text-center"
            @dragover="allowDrop"
            v-if="dragStarted"
            @drop="deleteView"
          >Delete</div>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            depressed
            text
            @click="handleResetClick"
          >Reset sizes</v-btn>
          <v-btn
            color="primary"
            depressed
            text
            @click="$emit('close')"
          >Cancel</v-btn>
          <v-btn
            color="primary"
            depressed
            outlined
            class="mx-4"
            @click="handleSaveClick"
          >Save</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'PositionsModal',
  data () {
    return {
      changedViews: [],
      dragStarted: false
    }
  },
  props: {
    viewsMap: {
      type: Object,
      default () {
        return {}
      },
    },
    dashboardDetails: {
      type: Object,
      default () {
        return {}
      },
    },
  },
  methods: {
    deleteView (ev){
      ev.preventDefault()
      const oldTarget = ev.dataTransfer.getData('text').split('.')
      this.dashboardDetails.view_keys[oldTarget[0]].splice(oldTarget[1], 1)
      this.dragStarted = false
    },
    handleResetClick (){
      for(const viewsList of this.dashboardDetails.view_keys){
        for(const view_key of viewsList){
          if(this.viewsMap[view_key]){
            this.$set(this.viewsMap[view_key]?.properties, 'length', null)
            this.$set(this.viewsMap[view_key]?.properties, 'height', null)
          }
        }
      }
      this.changedViews = Object.keys(this.viewsMap).map(item=> parseInt(item))
    },
    getWidthPositions (key, viewsList) {
      const totalLength = 11
      const countList = viewsList.filter((item) => {
        return !!item && item !== key
      })
      const count = countList.reduce((prev, cur) => {
        const singleCount = this.viewsMap[cur]?.properties?.length || 2
        return prev + singleCount
      }, 0)
      return Array.apply(null, Array(totalLength - count)).map((item, index) => index + 2)
    },
    getHeightPositions () {
      return Array.apply(null, Array(11)).map((item, index) => index + 2)
    },
    moveAllViews (newTarget, oldTarget) {
      const newArray = [null, null, null]
      newArray[newTarget[1]] = this.dashboardDetails.view_keys[oldTarget[0]][oldTarget[1]]
      this.dashboardDetails.view_keys[oldTarget[0]][oldTarget[1]] = null
      this.dashboardDetails.view_keys.splice(0, 0, newArray)
      this.$set(this.dashboardDetails, 'view_keys', [...this.dashboardDetails.view_keys])
    },
    handleSaveClick () {
      this.$emit('close')
      this.$emit('saved', { map: this.viewsMap, views: this.changedViews })
    },
    allowDrop (ev) {
      ev.preventDefault()
    },
    ondrop (ev) {
      ev.preventDefault()
      const oldTarget = ev.dataTransfer.getData('text').split('.')
      const newTarget = ev.currentTarget.id.split('.')
      if (newTarget[0] === '-1') {
        this.moveAllViews(newTarget, oldTarget)
        return
      }
      if (!this.dashboardDetails.view_keys[newTarget[0]]) {
        this.dashboardDetails.view_keys[newTarget[0]] = []
      }
      const oldViewKey = this.dashboardDetails.view_keys[oldTarget[0]][oldTarget[1]]
      const newViewKey = this.dashboardDetails.view_keys[newTarget[0]][newTarget[1]]
      this.dashboardDetails.view_keys[oldTarget[0]].splice(Number(oldTarget[1]), 1, newViewKey)
      this.dashboardDetails.view_keys[newTarget[0]].splice(Number(newTarget[1]), 1, oldViewKey)
      this.$set(this.dashboardDetails, 'view_keys', [...this.dashboardDetails.view_keys])
      this.dragStarted = false
    },
    drag (ev) {
      this.dragStarted = true
      ev.dataTransfer.dropEffect = 'move'
      ev.dataTransfer.setData('text', ev.currentTarget.id)
    },
    getIcon (view_key) {
      const style = this.viewsMap[view_key]?.style
      switch (style) {
      case 'kpi_tile':
        return 'calculate'
      case 'filters_tile':
        return 'filter_list'
      case 'text_tile':
        return 'message'
      case 'image_tile':
        return 'camera_alt'
      case 'line_chart':
        return 'timeline'
      case 'doughnut_chart':
        return 'donut_large'
      case 'scatter_chart':
        return 'bubble_chart'
      case 'bar_chart':
      case 'waterfall_chart':
        return 'bar_chart'
      case 'table':
        return 'mdi-view-compact-outline'
      default:
        return 'calculate'
      }
    }
  }
}
</script>
<style lang="scss">
.positions-modal {
  .v-text-field__prefix {
    font-size: 12px;
  }
}
</style>
<style scoped>
.empty-view {
  border: 1.5px dashed black;
  display: flex;
  flex-grow: 1;
  padding: 40px;
  border-radius: 4px;
}
.delete-view {
  border: 1.5px dashed var(--error);
  display: flex;
  justify-content: center;
  flex-grow: 1;
  color: var(--error);
  padding: 12px;
  border-radius: 4px;
}
</style>
