import Vue from 'vue'
import { filtersData } from '@/variables'
import { addYears, eachWeekOfInterval, format, setDay } from 'date-fns'

export const arrayToCSV = function (objArray, seperator = ';') {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
  const str = `${Object.keys(array[0]).map(value => `"${value}"`).join(seperator)}` + '\r\n'

  return array.reduce((str, next) => {
    str += `${Object.values(next).map(value => `"${value}"`).join(seperator)}` + '\r\n'
    return str
  }, str)
}

export const headersFilters = (item, queryText, itemText) => {
  return (itemText.toLocaleLowerCase() + item.headerTitle?.toLocaleLowerCase()).indexOf(queryText.toLocaleLowerCase()) > -1
}

export const downloadData = function (sep, tableData, fileName) {
  const d = JSON.parse(JSON.stringify(tableData))

  // Get store names and if dutch then replace . with ,
  for (const idx in d) {
    for (const key of Object.keys(d[idx])) {
      if (sep === 'dutch') {
        d[idx][key] = String(d[idx][key])
        d[idx][key] = d[idx][key].replace(',', '')
        d[idx][key] = d[idx][key].replace('.', ',')
      }
    }
  }

  // Replacing done so now set seperator to ;
  if (sep === 'dutch') {
    sep = ';'
  }
  // Convert to csv and download
  const csv = arrayToCSV(d, sep)
  const data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
  const link = document.createElement('a')
  link.setAttribute('href', data)
  const today = new Date()
  const dd = today.getDate()
  const mm = today.getMonth() + 1
  const yyyy = today.getFullYear()
  link.setAttribute('download', fileName + '_' + String(dd) + '_' + String(mm) + '_' + String(yyyy) + '.csv')
  link.click()
}

/* export const optionSetFiltersToFormParameters = function (filters) {
  const discountFilter = filters.find(f => f.type === 'discount')
  const activeFilter = filters.find(f => f.type === 'stock_active')
  const priceFilter = filters.find(f => f.type === 'asp')
  const dcStock = filters.find(f => f.type === 'dc_stock')
  const inbounds = filters.find(f => f.type === 'inbounds')
  const origPriceFilter = filters.find(f => f.type === 'osp')
  const stockFilter = filters.find(f => f.type === 'stock')
  const optionsIncludeFilter = filters.find(f => f.type === 'option_keys_include')
  const skusFilter = filters.find(f => f.type === 'skus')

  return {
    collections: [],
    groups: [],
    dc_stock_min: dcStock ? dcStock.values[0] : null,
    dc_stock_max: dcStock ? dcStock.values[1] : null,
    inbounds_min: inbounds ? inbounds.values[0] : null,
    inbounds_max: inbounds ? inbounds.values[1] : null,
    discount_min: discountFilter ? discountFilter.values[0] : null,
    discount_max: discountFilter ? discountFilter.values[1] : null,
    stock_active: activeFilter ? activeFilter.values[0] : null,
    osp_min: origPriceFilter ? origPriceFilter.values[0] || null : null,
    osp_max: origPriceFilter ? origPriceFilter.values[1] || null : null,
    asp_min: priceFilter ? priceFilter.values[0] || null : null,
    asp_max: priceFilter ? priceFilter.values[1] || null : null,
    stock_min: stockFilter ? stockFilter.values[0] || null : null,
    stock_max: stockFilter ? stockFilter.values[1] || null : null,
    option_keys_include: optionsIncludeFilter ? optionsIncludeFilter.values : [],
    skus_min: skusFilter ? parseFloat(skusFilter.values[0]) || null : null,
    skus_max: skusFilter ? parseFloat(skusFilter.values[1]) || null : null,
    option_keys_exclude: [],
  }
} */

export const optionsFiltersToFormParameters = function (filters) {
  const result = {
    collections: [],
    groups: [],
    option_keys_exclude: [],
  }
  const booleanFilters = ['stock_active', 'stock_frozen', 'stock_allow_transfers', 'stock_optimize_margin', 'stock_force_minimum', 'stock_exclude_minimum', 'stock_safe_guard', 'stock_infinite_supply', 'stock_exclude_deficit']
  const rangeFilters = ['osp', 'asp', 'stock', 'dc_stock', 'inbounds', 'days_until_inbound', 'skus', 'stores_included']
  const percentageRangeFilters = ['discount']
  const singleValueFilters = ['store_inclusions', 'store_exclusions', 'store_not_inclusions', 'store_not_exclusions']
  const datesFilters = ['date_min_days', 'date_max_days']
  const multipleValueFilters = ['option_keys_include']

  booleanFilters.forEach(filterName => {
    const filter = filters.find(f => f.type === filterName)

    result[filterName] = filter ? filter.values[0] : null
  })

  rangeFilters.forEach(filterName => {
    const filter = filters.find(f => f.type === filterName)
    if (!filter) {
      result[filterName + '_min'] = null
      result[filterName + '_max'] = null
      return
    }
    const min = parseFloat(filter.values[0])
    const max = parseFloat(filter.values[1])
    result[filterName + '_min'] = isNaN(min) ? null : min
    result[filterName + '_max'] = isNaN(max) ? null : max
  })

  percentageRangeFilters.forEach(filterName => {
    const filter = filters.find(f => f.type === filterName)

    result[filterName + '_min'] = filter ? parseFloat(filter.values[0] / 100) : null
    result[filterName + '_max'] = filter ? parseFloat(filter.values[1] / 100) : null
  })

  singleValueFilters.forEach(filterName => {
    const filter = filters.find(f => f.type === filterName)

    result[filterName] = filter ? filter.values[0] : null
  })

  datesFilters.forEach(filterName => {
    const filter = filters.find(f => f.type === filterName)

    result[filterName + '_before'] = filter ? filter.values[0] : null
    result[filterName + '_after'] = filter ? filter.values[1] : null
  })

  multipleValueFilters.forEach(filterName => {
    const filter = filters.find(f => f.type === filterName)

    result[filterName] = filter ? filter.values : []
  })

  return result
}

export const storesFiltersToFormParameters = function (filters) {
  const country = filters.find(f => f.type === 'country')
  const dcKey = filters.find(f => f.type === 'dc_key')
  const proposalKey = filters.find(f => f.type === 'proposal_key')
  const oneStock = filters.find(f => f.type === 'one_stock')
  const storesInclude = filters.find(f => f.type === 'store_keys_include')
  const storesExclude = filters.find(f => f.type === 'store_keys_exclude')

  return {
    country: country ? country.values : [],
    dc_key: dcKey ? dcKey.values : null,
    proposal_key: proposalKey ? proposalKey.values : null,
    one_stock: oneStock ? oneStock.values : null,
    store_keys_include: storesInclude ? storesInclude.values : [],
    store_keys_exclude: storesExclude ? storesExclude.values : [],
  }
}

export const optionSetParametersToFiltersDynamic = function (parameters) {
  const booleanFilters = ['stock_active', 'stock_frozen', 'stock_allow_transfers', 'stock_optimize_margin', 'stock_force_minimum', 'stock_exclude_minimum', 'stock_safe_guard', 'stock_infinite_supply', 'stock_exclude_deficit']
  const rangeFilters = ['osp', 'asp', 'stock', 'dc_stock', 'inbounds', 'skus', 'stores_included']
  const percentageRangeFilters = ['discount']
  const singleValueFilters = ['store_inclusions', 'store_exclusions', 'store_not_inclusions', 'store_not_exclusions']
  const datesFilters = ['date_min_days', 'date_max_days']
  const multipleValueFilters = ['option_keys_include']
  const filters = []

  booleanFilters.forEach(filterName => {
    if (parameters[filterName] !== null && parameters[filterName] !== undefined) {
      filters.push({
        type: filterName,
        values: getOptionsFilterValuesByType(filterName, [parameters[filterName]]),
        errors: [],
      })
    }
  })

  rangeFilters.forEach(filterName => {
    if (parameters[filterName + '_min'] || parameters[filterName + '_max']) {
      filters.push({
        type: filterName,
        values: getOptionsFilterValuesByType(filterName, [parameters[filterName + '_min'], parameters[filterName + '_max']]),
        errors: [],
      })
    }
  })

  percentageRangeFilters.forEach(filterName => {
    if (parameters[filterName + '_min'] || parameters[filterName + '_max']) {
      filters.push({
        type: filterName,
        values: getOptionsFilterValuesByType(filterName, [parameters[filterName + '_min'], parameters[filterName + '_max']]),
        errors: [],
      })
    }
  })

  singleValueFilters.forEach(filterName => {
    if (parameters[filterName]) {
      filters.push({
        type: filterName,
        values: getOptionsFilterValuesByType(filterName, [parameters[filterName]]),
        errors: [],
      })
    }
  })

  datesFilters.forEach(filterName => {
    if (parameters[filterName + '_before'] || parameters[filterName + '_after']) {
      filters.push({
        type: filterName,
        values: getOptionsFilterValuesByType(filterName, [parameters[filterName + '_before'], parameters[filterName + '_after']]),
        errors: [],
      })
    }
  })

  multipleValueFilters.forEach(filterName => {
    if (parameters[filterName]) {
      filters.push({
        type: filterName,
        values: getOptionsFilterValuesByType(filterName, parameters[filterName]),
        errors: [],
      })
    }
  })

  return filters
}

export const optionSetParametersToFiltersStatic = function (parameters) {
  const filters = []

  if (parameters.option_keys_include.length) {
    filters.push({
      type: 'option_keys_include',
      values: getOptionsFilterValuesByType('stock', parameters.option_keys_include),
      errors: [],
    })
  }

  return filters
}

export const getOptionsFilterValuesByType = function (type, defaultValues = []) {
  let values = defaultValues.slice()

  if (type === 'discount') {
    values[0] = defaultValues[0] || 0
    values[1] = defaultValues[1] || 100
  } else if (['dc_stock', 'inbounds', 'days_until_inbound', 'skus'].includes(type)) {
    values[0] = defaultValues[0] || null
    values[1] = defaultValues[1] || null
  } else if (['stock_active', 'stock_frozen', 'stock_allow_transfers', 'stock_optimize_margin', 'stock_force_minimum', 'stock_exclude_minimum', 'stock_safe_guard', 'stock_infinite_supply', 'stock_exclude_deficit'].includes(type)) {
    values[0] = defaultValues[0]
  } else if (['price', 'original_price', 'asp', 'osp', 'stock', 'skus', 'stores_included'].includes(type)) {
    values[0] = defaultValues[0] || undefined
    values[1] = defaultValues[1] || undefined
  } else if (['date_min_days', 'date_max_days'].includes(type)) {
    values[0] = defaultValues[0] || undefined
    values[1] = defaultValues[1] || undefined
  } else if (type === 'option_keys_include') {
    values = [...defaultValues]
  } else if (['collection_keys', 'group_keys', 'option_keys_exclude', 'store_inclusions', 'store_exclusions', 'store_not_inclusions', 'store_not_exclusions'].includes(type)) {
    values = []
  } else {
    throw new Error('Unknown filter type ' + type)
  }

  return values
}

export const getStoresFilterValuesByType = function (type, defaultValues = []) {
  let values = defaultValues.slice()

  if (['country', 'store_keys_include', 'store_keys_exclude'].includes(type)) {
    values = []
  } else if (['dc_key', 'proposal_key'].includes(type)) {
    values = null
  } else if (['proposal_key', 'one_stock'].includes(type)) {
    values = 'any'
  } else {
    throw new Error('Unknown filter type ' + type)
  }

  return values
}

export const storeSetParametersToFilters = function (parameters) {
  const filters = []
  const singleFilters = ['dc_key', 'proposal_key', 'one_stock']

  singleFilters.forEach(f => {
    if (parameters[f]) {
      filters.push({
        type: f,
        values: parameters[f],
        errors: [],
      })
    }
  })

  if (parameters.country && parameters.country.length) {
    filters.push({
      type: 'country',
      values: parameters.country,
      errors: [],
    })
  }

  if (parameters.store_keys_include && parameters.store_keys_include.length) {
    filters.push({
      type: 'store_keys_include',
      values: parameters.store_keys_include,
      errors: [],
    })
  }

  if (parameters.store_keys_exclude && parameters.store_keys_exclude.length) {
    filters.push({
      type: 'store_keys_exclude',
      values: parameters.store_keys_exclude,
      errors: [],
    })
  }

  return filters
}

export const getStoresSearchDefaultParams = function () {
  return {
    org_key: 0,
    stores_set_key: null,
    set_name: null,
    label: ['All'],
    one_stock: null,
    country: [],
    dc_key: null,
    proposal_key: null,
    properties_include: {},
    properties_exclude: {},
    store_keys_include: [],
    store_keys_exclude: [],
    static_set: false,
  }
}

export const getFilterOptionsByProperties = function (filterName, data, selectedPropertiesValues) {
  const result = []

  for (const item of data) {
    let match = true
    for (const prop of Object.keys(selectedPropertiesValues)) {
      if (selectedPropertiesValues[prop].length === 0) {
        match = true
      } else if (
        (prop !== 'undefined') &&
        item.properties &&
        !selectedPropertiesValues[prop].includes(item.properties[prop])) { // Check if object is not empty (undefined as string) and then check if value is possible given already selected values
        match = false
      }
    }
    if (match === true) {
      result.push(item[filtersData[filterName].keyProp].toString())
    }
  }

  return result
}



// In case if option has only one row, row and colspan should be 1.
// Otherwise first row of the option should have rowspan = count, other rows of this option should have it 0
export const getTransferDetailsTableSpanMethod = function (cellInfo, optionsRowsCount, spanColumnsIndexes) {
  const { row, rowIndex, columnIndex } = cellInfo
  const { count, firstRowIndex } = optionsRowsCount[row.option_key]
  let spanObj = {
    rowspan: 1,
    colspan: 1
  }
  if (count !== 1) {
    spanObj = {
      rowspan: (firstRowIndex === rowIndex) ? count : 0,
      colspan: (firstRowIndex === rowIndex) ? 1 : 0,
    }
  }
  if (spanColumnsIndexes.includes(columnIndex)) {
    return spanObj
  }
}

export const getTransferDetailsTableSpanMethodStores = function (cellInfo, optionsRowsCount, spanColumnsIndexes) {
  const { row, rowIndex, columnIndex } = cellInfo
  const key = `${row.store_key}_${row.to_store_key}`
  const { count, firstRowIndex } = optionsRowsCount[key]
  let spanObj = {
    rowspan: 1,
    colspan: 1
  }
  if (count !== 1) {
    spanObj = {
      rowspan: (firstRowIndex === rowIndex) ? count : 0,
      colspan: (firstRowIndex === rowIndex) ? 1 : 0,
    }
  }
  if (spanColumnsIndexes.includes(columnIndex)) {
    return spanObj
  }
}

export const serializeObjToQuery = function (obj) {
  const str = []
  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  }
  return str.join('&')
}

export const getDynamicCapacityErrors = function (capacity) {
  const errors = {}
  if (!capacity.options_set_key) {
    errors.optionSets = 'At least one required'
  }

  if (!capacity.stores_set_key  && !capacity.store_keys?.length ) {
    errors.storeSets = 'At least one required'
  }
  if(!capacity.capacity_type) {
    errors.capacityType = 'Required'
  }

  const mustHave = parseInt(capacity.must_have)
  const niceHave = parseInt(capacity.nice_have)
  const maxHave = parseInt(capacity.max_have)
  const softHave = parseInt(capacity.soft_max_have)

  if (isNaN(mustHave) && isNaN(niceHave) && isNaN(maxHave) && isNaN(softHave)) {
    errors.mustHave = 'At least one required'
    errors.niceToHave = 'At least one required'
    errors.maxHave = 'At least one required'
    errors.softHave = 'At least one required'
  }

  if (
    (!isNaN(mustHave) && !isNaN(niceHave)) &&
    (niceHave < mustHave)
  ) {
    errors.niceToHave = 'Should be >= must have'
  }
  if (
    ((!isNaN(mustHave) || !isNaN(niceHave)) && !isNaN(maxHave)) &&
    ((maxHave < mustHave) || (maxHave < niceHave))
  ) {
    errors.maxHave = 'Should be >= must and nice to have'
  }
  return errors
}

export const getCapacityOptionErrors = function (option) {
  const optionErrors = {}

  if ((!option.option_keys?.length && !option.options_set_key)) {
    optionErrors.options = 'At least one required'
  }
  if (!option.store_keys?.length && !option.stores_set_key) {
    optionErrors.stores = 'At least one required'
  }

  if (!option.capacity_type) {
    optionErrors.capacityType = 'Required'
  }

  const mustHave = parseInt(option.must_have)
  const niceHave = parseInt(option.nice_have)
  const maxHave = parseInt(option.max_have)

  if (isNaN(mustHave) && isNaN(niceHave) && isNaN(maxHave)) {
    optionErrors.mustHave = 'At least one required'
    optionErrors.niceToHave = 'At least one required'
    optionErrors.maxHave = 'At least one required'
  }

  if (
    (!isNaN(mustHave) && !isNaN(niceHave)) &&
    (niceHave < mustHave)
  ) {
    optionErrors.niceToHave = 'Should be >= must have'
  }

  if (
    (!isNaN(mustHave) && !isNaN(niceHave) && !isNaN(maxHave)) &&
    ((maxHave < mustHave) || (maxHave < niceHave))
  ) {
    optionErrors.maxHave = 'Should be >= must and nice to have'
  }

  return optionErrors
}

export const getDummyStatus = function (dummy, requiredProperties) {
  let hasRequiredProperties = false
  if (dummy.properties) {
    if (!requiredProperties.every(p =>
      (dummy.properties[p]?.force && !dummy.properties[p]) || dummy.properties[p]?.errors)
    ) {
      hasRequiredProperties = true
    }
  }
  return (hasRequiredProperties &&
    dummy.option_name.length &&
    dummy.supplier_key &&
    dummy.sku_names.length &&
    dummy.prices && Object.keys(dummy.prices).length
  ) ? 'completed' : 'in progress'
}

export const getWeeksSelectorOptions = function (startYear = -1, endYear = 2, onlyFuture = false) {
  const date = new Date()
  return eachWeekOfInterval({
    start: onlyFuture ? date : addYears(date, startYear),
    end: addYears(date, endYear),
  }).map(w => {
    w = setDay(w, 4)
    return {
      text: format(w, 'yyyy - \'W\'ww'),
      value: format(w, 'yyyyww'),
    }
  })
}

export const weekStringToNumber = function (input) {
  return parseInt(input.replace('-W', ''))
}

export const hexToRgbA = function (hex, opacity) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')'
  } else {
    return hex
  }
}

export const getModuleFromPath = function (path) {
  let currentModule = null
  const modules = ['stock', 'store', 'buy', 'pricing', 'strategy']

  modules.forEach(m => {
    if (path.startsWith('/' + m)) {
      currentModule = m
    }
  })

  return currentModule
}

export const formatValueWithCurrency = function (value, digits) {
  if (!value) {
    return value
  }

  const currency = Vue.prototype.$auth.getUserCurrency().currency || 'EUR'
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })

  return formatter.format(value)
}

export const formatString = function (value) {
  if (typeof value === 'boolean') {
    value = value ? 'yes' : 'no'
  }
  if (typeof value !== 'string') {
    return value
  }
  let variable = value.replace('_', ' ')
  variable = variable.replace('_', ' ')
  variable = variable.replace('_', ' ')
  variable = variable.replace('_', ' ')

  return variable.charAt(0).toUpperCase() + variable.slice(1)
}

export const groupAnalyticsProperties = function (analyticsProperties) {
  const array = []
  const headers = {}
  const propertiesSorted = analyticsProperties.slice().sort()

  for (const key of propertiesSorted) {
    const title = key.split('/')
    if (headers[title[1]] === undefined) {
      headers[title[1]] = { header: formatString(title[1] || ''), text: [] }
      array.push(headers[title[1]])
    }
    headers[title[1]].text.push(formatString(title[2] || ''), formatString(title[1] || ''))
    array.push({
      value: key,
      headerTitle: formatString(title[1] || ''),
      text: formatString(title[2] || '')
    })
  }
  if (!array.length) {
    return []
  }
  return array
}

export const formatLargeNumber = function (value, precision) {
  if(!value){
    return '0'
  }
  if(Math.abs(value) < 1){
    return value.toFixed(1)
  }
  const ranges = [
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'k' }
  ]
  const formatter = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: precision || 0,
    maximumFractionDigits: precision || 1,
  })
  function formatNumber (n) {
    for (let i = 0; i < ranges.length; i++) {
      if (Math.abs(n) >= ranges[i].divider) {
        return formatter.format(n / ranges[i].divider) + ranges[i].suffix
      }
    }
    return n
  }

  return formatNumber(value)
}

export const formatThousands = function (value) {
  if (Math.abs(value) >= 1e3) {
    return (value / 1e3).toFixed(3).toString().replace('.', ',')
  }

  return Math.round(value)
}

export const formatMillions = function (value) {
  if (Math.abs(value) >= 1e6) {
    return (value / 1e6).toFixed(3).toString().replace('.', ',') + 'K'
  }

  return Math.round(value)
}

export const getAvailabilityColorClass = function (value) {
  if (value <= 100 && value > 80) {
    return 'green--text'
  }

  if (value < 80 && value >= 50) {
    return 'orange--text'
  }

  if (value < 50 && value >= 0) {
    return 'red--text'
  }
}

export const getTransfersOverviewData = function (transfersDataSend, transfersDataReceive) {
  const total = {
    stock: transfersDataSend.total.stock,
    receive: 0,
    pieces: transfersDataSend.total.pieces,
    goal: 0
  }
  const visitedStores = new Set()
  const parentTable = transfersDataSend.parent_table.map(rowSend => {
    const toStore = transfersDataReceive.parent_table.find(rowReceive => rowSend.store_key === rowReceive.to_store_key)
    visitedStores.add(rowSend.store_key)
    const receive = toStore ? toStore.pieces : 0
    const goal = rowSend.stock - rowSend.pieces + (toStore ? toStore.pieces : 0)

    total.receive += receive
    total.goal += goal

    return {
      store_key: rowSend.store_key,
      stock: rowSend.stock,
      receive: receive,
      pieces: rowSend.pieces,
      goal: goal,
    }
  })
  const storesNotVisited = transfersDataReceive.parent_table.filter(row => !visitedStores.has(row.to_store_key))
    .map(row => {
      total.receive += row.pieces
      total.goal += row.pieces
      return {
        store_key: row.to_store_key,
        stock: 0,
        receive: row.pieces,
        pieces: 0,
        goal: row.pieces,
      }
    })

  return {
    parent_table: [...parentTable, ...storesNotVisited],
    total,
  }
}

export const getStatusColorClass = function (product, suffix) {
  if (product.status === 'generated') {
    return 'yellow' + suffix
  }

  if (product.status === 'placeholder') {
    return 'orange' + suffix
  }

  if (product.status === 'planned') {
    return 'primary' + suffix
  }

  if (product.status === 'ordered') {
    return 'green' + suffix
  }

  if (product.status === 'delivered') {
    return 'purple' + suffix
  }
}
