<template>
  <v-col v-if="orderPoints" class="order-points pb-15 pt-10" cols="12">
    <v-divider></v-divider>
    <v-row class="ma-0 mt-4" justify="space-between">
      <h4 class="pa-6 pb-8 pt-8">Order Points</h4>
    </v-row>
    <el-table
      :data="[...orderPoints, '']"
    >
      <el-table-column
        width="60"
        align="center"
        label="Nr"
      >
        <template slot-scope="scope" >
          <span v-if="scope.$index !== orderPoints.length">
            {{scope.$index + 1}}
          </span>
          <v-icon class="plus-icon" v-else @click="addOrder">add</v-icon>
        </template>
      </el-table-column>
      <el-table-column
        label="Name">
        <template slot-scope="scope">
          <span v-if="scope.$index !== orderPoints.length ">
            {{scope.row}}
          </span>
          <v-text-field
            class="flex xs2"
            v-model="newOrder"
            v-else
            :error-messages="newOrderErrors"
            label="Order Point"
            @keydown.enter="addOrder"
          ></v-text-field>
        </template>
      </el-table-column>
      <el-table-column
        width="80"
      >
        <template slot-scope="scope">
          <v-btn
            icon
            v-if="scope.$index !== orderPoints.length"
            @click="removeItem(scope.$index)"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </template>
      </el-table-column>
    </el-table>
  </v-col>
</template>

<script>
import * as usersApi from '@/api/userAPI'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'orderPoints',
  data () {
    return {
      url: '',
      newOrder: '',
    }
  },
  computed: {
    ...mapState(useGeneralStore, {
      orderPoints: (state) => state.buySettings?.order_points || [],
    }),
    newOrderErrors () {
      if (!this.newOrder || this.checkString(this.newOrder)) {
        return ''
      }
      return 'Unexpected Symbol'
    }
  },
  methods: {
    checkString (str) {
      return !!str.match('^[a-zA-Z0-9 _-]+$')
    },
    checkChar (char, range) {
      return !(char < range[0] || char > range[1])
    },
    addOrder () {
      if (!this.newOrder || this.newOrderErrors) {
        return
      }
      this.orderPoints.push(this.newOrder)
      this.newOrder = ''

      usersApi
        .saveBuySettings({
          buy_settings: {
            order_points: this.orderPoints,
          }
        })
        .then(() => {
          this.$auth.refreshAppConfig()
        })
    },
    removeItem (index) {
      this.orderPoints.splice(index, 1)

      usersApi
        .saveBuySettings({
          buy_settings: {
            order_points: this.orderPoints,
          }
        })
        .then(() => {
          this.$auth.refreshAppConfig()
        })
    }
  },
}
</script>

<style lang="scss">
.order-points {
  .v-text-field {
    .v-label {
      font-size: 13px;
    }
  }
  .plus-icon {
    font-size: 20px;
  }
}
</style>
