
export default {
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    filters: {
      type: [Object, Array],
      default () {
        return []
      }
    },
    tableProps: {
      type: Object,
      default () {
        return {}
      }
    },
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 20
    },
    paginationProps: {
      type: Object,
      default () {
        return {}
      },
    },
    paginationShow: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  mounted () {
    const elTableVm = this.$refs['elTable']
    const oldEmit = elTableVm?.$emit
    elTableVm.$emit = (...args) => {
      this.$emit.apply(this, args)
      oldEmit.apply(elTableVm, args)
    }
  },
  data () {
    return {
      innerCurrentPage: 1,
      innerPageSize: 20,
      sortData: (this.tableProps && this.tableProps.defaultSort) || {},
      innerPaginationProps: {}
    }
  },
  watch: {
    pageSize: {
      immediate: true,
      handler (val) {
        this.innerPageSize = val
      }
    },
    innerPageSize (val) {
      this.$emit('update:pageSize', val)
    },
    currentPage: {
      immediate: true,
      handler (val) {
        this.innerCurrentPage = val
      }
    },
    innerCurrentPage (val) {
      this.$emit('update:currentPage', val)
    },
    paginationProps: {
      immediate: true,
      handler (val) {
        if (this.paginationShow) {
          this.innerPaginationProps = Object.assign({
            pageSizes: [20, 50, 100],
            layout: 'prev, pager, next, jumper, sizes, total',
          }, val)

          if (this.innerPaginationProps.pageSizes.indexOf(this.innerPageSize) === -1) {
            console.warn(`pageSize ${this.innerPageSize} is not included in pageSizes[${this.innerPaginationProps.pageSizes}], set pageSize to pageSizes[0]: ${this.innerPaginationProps.pageSizes[0]}`)
            this.innerPageSize = this.innerPaginationProps.pageSizes[0]
          }
        }
      }
    },
  },
}
