<template>
  <resizable
    id="strategy-chunks-options"
    class="mx-n6 p-relative"
    @resize="updateTotalTableWidth"
  >
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row>
      <div class="col text-right pr-6">
        <v-icon
          class="mx-2 action-btn-primary"
          :disabled="!selectedRows[0]"
          @click="handleAddClick"
        >add</v-icon>
      </div>
    </v-row>
    <v-row>
      <v-col>
        <data-tables
          style="width: 100%"
          :data="optionsList"
          :page-size="5"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 mb-4 mr-2',
          }"
          :table-props="{
            'row-key': 'option_key',
            'expand-icon': 'add',
          }"
          @filtered-data="filteredData = $event"

          @row-click="handleRowClick"
        >
          <data-tables
            slot="append"
            :index="null"
            class="total-table"
            :data="totals"
            :pagination-props="{class: 'd-none'}"
            v-if="optionsList.length"
          >
            <el-table-column label-class-name="d-none" class-name="total-row_column" width="45" />
            <el-table-column label-class-name="d-none" class-name="total-row_column" width="16" />
            <el-table-column
              class-name="total-row_column"
              label-class-name="d-none"
              width="215"
            >
              <template>
                <div>Total</div>
              </template>
            </el-table-column>
            <el-table-column
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.stock | formatThousands }}</div>
              </template>
            </el-table-column>
            <el-table-column
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.inbounds | formatThousands }}</div>
              </template>
            </el-table-column>
            <el-table-column
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.chunks | formatThousands }}</div>
              </template>
            </el-table-column>
            <el-table-column
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ priceFormatter.format(scope.row.original_value) }}</div>
              </template>
            </el-table-column>
            <el-table-column
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ priceFormatter.format(scope.row.retail_value) }}</div>
              </template>
            </el-table-column>
            <el-table-column
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ priceFormatter.format(scope.row.cost_value) }}</div>
              </template>
            </el-table-column>
          </data-tables>
          <el-table-column width="55">
            <template v-slot:header>
              <v-simple-checkbox
                color="primary"
                :value="allRowsSelected"
                :ripple="false"
                dense
                hide-details
                class="ml-3"
                @input="handleSelectAllChange"
              />
            </template>
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-3 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="option_name"
            label="Name"
            width="220"
          >
            <template slot-scope="scope">
              <OptionDataCell
                :option="scope.row"
                name-key="option_name"
              />
            </template>
          </el-table-column>
          <el-table-column prop="stock" label="Stock" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.stock }}
            </template>
          </el-table-column>
          <el-table-column prop="inbounds" label="Inbounds" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.inbounds }}
            </template>
          </el-table-column>
          <el-table-column prop="chunks" label="Chunks" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.chunks }}
            </template>
          </el-table-column>
          <el-table-column prop="original_value" label="Original value" sortable="custom">
            <template slot-scope="scope">
              {{ priceFormatter.format(scope.row.original_value) }}
            </template>
          </el-table-column>
          <el-table-column prop="retail_value" label="Retail value" sortable="custom">
            <template slot-scope="scope">
              {{ priceFormatter.format(scope.row.retail_value) }}
            </template>
          </el-table-column>
          <el-table-column prop="cost_value" label="Cost value" sortable="custom">
            <template slot-scope="scope">
              {{ priceFormatter.format(scope.row.cost_value) }}
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>
  </resizable>
</template>

<script>
import OptionDataCell from '@/components/OptionDataCell'
import Resizable from '@/components/Utility/Resizable.vue'

export default {
  name: 'StrategyChunksOptions',
  components: { Resizable, OptionDataCell },
  props: {
    options: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      filteredData: [],
      loading: false,
      optionsList: [],
    }
  },
  computed: {
    priceFormatter () {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.$auth.getUserCurrency().currency,
        minimumFractionDigits: 0,
      })
    },
    selectedRows () {
      return this.optionsList.filter(r => r.selected)
    },
    allRowsSelected () {
      return (this.filteredData.length > 0) && (this.selectedRows.length === this.filteredData.length)
    },
    totals () {
      const total = {
        stock: 0,
        inbounds: 0,
        chunks: 0,
        original_value: 0,
        retail_value: 0,
        cost_value: 0,
      }
      this.optionsList.forEach(md => {
        Object.keys(total).forEach(key => {
          total[key] += md[key]
        })
      })

      return [total]
    },
  },
  watch: {
    options () {
      this.optionsList = this.options.map(o => ({
        ...o,
        selected: false,
      }))
    },
    totals: {
      handler () {
        this.updateTotalTableWidth()
      }
    },
  },
  methods: {
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
    handleSelectAllChange (val) {
      this.filteredData.forEach(r => this.$set(r, 'selected', val))
    },
    handleAddClick () {
      this.$emit('options-copy', this.selectedRows)
    },
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = document.querySelector('#strategy-chunks-options .el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 100)
    },
  },
}
</script>

<style lang="scss">
#strategy-chunks-options {
    .total-row_column {
        background-color: #f5f7fa;
    }
}
</style>
