<template>
  <div class="shapValueTable">
    <v-row>
      <v-col>
        <data-tables
          :tableProps="tableProps"
          :data="values"
          :page-size="values.length"
          :pagination-props="{ pageSizes: [values.length], hideOnSinglePage: true }"
        >
          <el-table-column
            v-for="(col, idx) in columns"
            :key="String(col) + String(idx)"
            :prop="col"
            :label="col | formatString"
            align="center"
            :sortable="false"
          >
            <template slot-scope="scope">
              <div
                v-if="col !== 'Total' && !['Base', 'Total'].includes(scope.row.variable)"
                class="d-flex align-center justify-center colored-area"
                :style="{
                  'background-color': getCellColor(scope.row, col),
                  'padding': '10px',
                }">{{ scope.row[col] }}</div>
              <div
                v-else
                :style="{
                  'padding': '10px',
                }"
              >{{ scope.row[col] }}</div>
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import interpolate from 'color-interpolate'

const redToWhite = interpolate(['#FF000082', '#F35D5DD3', '#EF9B9BCF', '#FFF4F4'])
const whiteToGreen = interpolate(['#FFF4F4', '#C4F8CCFD', '#9BFF9E87', '#51F15A87'])

export default {
  props: {
    tableData: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      tableProps: {
        'row-key': 'variable',
        'cell-class-name': this.getCellClassName,
      },
    }
  },
  computed: {
    values () {
      return this.tableData.table_data.map(item => {
        const { variable, ...rest } = item

        return {
          variable: this.$options.filters.formatString(variable),
          ...this.tableData.columns.reduce((acc, cur) => ({
            ...acc,
            [cur]: rest[cur].toFixed(2),
          }), {})
        }
      })
    },
    columns () {
      return [
        'variable',
        ...this.tableData.columns,
      ]
    },
  },
  methods: {
    getCellClassName ({ row, column }) {
      const classes = []

      if (row.variable === 'Total') {
        classes.push('total-td')
      } else if (row.variable === 'Base') {
        classes.push('base-td')
      } else {
        if (column.property !== 'variable') {
          classes.push('colored-td')
        }
      }

      return classes.join(' ')
    },
    getCellColor (row, column) {
      if (row[column] < 1) {
        return redToWhite(row[column])
      }

      if (row[column] > 1.1 && row[column] <= 10) {
        return whiteToGreen(row[column] / 10)
      }

      if (row[column] > 10) {
        return whiteToGreen(1)
      }
    },
  },
}
</script>

<style lang="scss">

.shapValueTable {
    .el-table td {
        height: 45px;
        padding: 0;
        border-bottom: none;
    }
    .el-table .cell {
        padding: 0;
    }
    .total-td {
        border-top: 1px solid #ebeef5;
        color: #909399;
        font-weight: 500;
    }
    .el-table td.base-td {
        border-bottom: 1px solid #ebeef5;
        color: #909399;
        font-weight: 500;
    }
    .colored-td {
        .cell {
            height: 100%;
            .colored-area {
                height: 100%;
            }
        }
    }
}
</style>
