<script lang="ts">
import {defineComponent} from 'vue'
import TableColumnHeader from '@/components/Tables/components/TableColumnHeader.vue'
interface filter  {
  text: string,
  value:string
}

interface GteOrLte {
  gte: number,
  lte: number,
}
export default defineComponent({
  name: 'DataTablesColumn',
  components: {TableColumnHeader},
  props: {
    columnKey:{
      type: String,
    },
    filters: {
      type: Array<filter>,
      default: ()=> []
    },
    filteredValue: {
      type: Array<string>,
      default: null
    }
  },
  data (){
    return {
      filterText: '',
      gte: null as null | number,
      lte: null as null | number,
      filteredFilters: [] as Array<filter>,
      selectedFilters: [],
      sortedFilteredValues: [] as (GteOrLte | string)[],
      menuOpened: false,
      noNeedReset: false,
      columnUpdateKey: 0,
      menuKey: 0,
    }
  },
  computed: {
    columnOrTableParent (){
      return this.$parent?.$attrs?.filters?.filter(item=> item.prop === this.columnKey)
    },
    isHighlighted (){
      return this.sortedFilteredValues?.length || this.gte != undefined || this.lte != undefined
    },
  },
  watch: {
    filters: {
      handler (val) {
        this.filteredFilters = val
      },
      immediate: true,
    },
    filteredValue: {
      handler () {
        this.$nextTick(()=> {
          this.columnUpdateKey++

        })
      },
      immediate: true,
    },
  },
  methods:{
    onNameClick (element: MouseEvent){
      element.currentTarget?.parentElement?.parentElement?.parentElement?.click()
    },
  },
})
</script>

<template>
  <el-table-column
    class="data-tables-column"
    :filters="filters"
    v-bind="$attrs"
    :key="columnUpdateKey"
    :label-class-name="'data-tables-column '+ ($attrs['label-class-name'] || '') +(isHighlighted ? ' highlight': '')"
    :columnKey="columnKey"
    v-on="$listeners"
    :filtered-value="filteredValue || columnOrTableParent"
  >
    <template slot-scope="scope">
      <slot v-bind="scope" />
    </template>
    <template v-slot:header>
      <span style="position: relative" class="data-tables-column">
        <TableColumnHeader
          @filter-change="$parent?.$emit('filter-change', $event)"
          @on-name-click="onNameClick"
          :filters="filters"
          :filteredValue="filteredValue"
          :column-key="columnKey"
          :label="$attrs.label"></TableColumnHeader>
      </span>
      <slot name="header" slot="header" >
      </slot>
    </template>
  </el-table-column>
</template>


<style lang="scss">
.el-table {
  .cell.data-tables-column {

    text-overflow: clip;

    .caret-wrapper {
      width: 0 !important;
      left: -36px
    }

    .header-title {
      display: inline;

    }

    .el-table__column-filter-trigger {
      line-height: normal;
      transform: none;
      vertical-align: middle;

      .el-icon-arrow-down {
        &::before {
          display: none;
        }
      }
    }
  }
}
  .el-table.two-rows-column {
    .cell.data-tables-column {
      .header-title {
        display: block;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        word-wrap: break-word;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }


      .caret-wrapper {
        left: -42px
      }
    }
  }

</style>
