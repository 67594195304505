<template>
  <div class="main-layout-wrapper">
    <TopNavbar @menu-clicked="openSideBar"/>
    <StrategySidebar ref="sideBar"/>
  </div>
</template>
<script lang="ts">
import {openSidebarMixin} from '@/views/modules/openSidebarMixin'
import TopNavbar from '@/components/TopNavbar'
import StrategySidebar from '@/components/StrategySidebar.vue'

export default {
  name: 'StrategyLayout',
  mixins: [openSidebarMixin],
  components: {
    StrategySidebar,
    TopNavbar
  }
}
</script>
