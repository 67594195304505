<template>
  <div class="shop-item-order-form-costs">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <data-tables
      :data="costsGroupsRowsWithTotal"
      :page-size="costsGroupsRowsWithTotal.length"
      ref="costsTable"
      :table-props="{
        rowKey: 'cost_key',
        expandRowKeys: expandRowKeys,
      }"
      :pagination-props="{
        pageSizes: [costsGroupsRowsWithTotal.length],
        hideOnSinglePage: true,
        class: 'el-pagination text-right mt-6 pb-4 mr-2',
      }"
      @row-click="handleRowClick"
    >
      <el-table-column
        width="45">
        <template slot-scope="scope">
          <v-icon v-if="!scope.row.isTotalRow" class="my-2">
            <template>
              {{ expandedRows.includes(scope.row.cost_key) ? 'expand_more' : 'chevron_right' }}
            </template>
          </v-icon>
        </template>
      </el-table-column>
      <el-table-column prop="cost_name" label="Name" min-width="320">
        <template slot-scope="scope">
          {{ scope.row.cost_name }}
          <v-menu
            v-if="!scope.row.isTotalRow"
            top
            open-on-hover
            offset-x
            min-width="200"
            max-width="320"
            content-class="option-info-menu"
            :open-delay="200"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :disabled="!getProperties(scope.row)"
                class="mr-3 icon-has-action"
                v-bind="attrs"
                v-on="on"
              >info</v-icon>
            </template>
            <v-card
              class="py-2 px-4 p-relative"
              v-html="getProperties(scope.row)"
            />
          </v-menu>
        </template>
      </el-table-column>

      <el-table-column prop="cost" label="Costs p.p." min-width="160">
        <template v-if="!scope.row.isTotalRow" slot-scope="scope">
          {{ currencySymbols[scope.row.currency] }}{{ ((scope.row.cost / pieces_total)).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column prop="cost" label="Costs" min-width="160">
        <template v-if="!scope.row.isTotalRow" slot-scope="scope">
          {{ currencySymbols[scope.row.currency] }}{{ Math.round(scope.row.cost) | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column prop="cost_default_currency" :label="'Costs p.p. ' + defaultCurrencySymbol" min-width="160">
        <template slot-scope="scope">
          {{ defaultCurrencySymbol }} {{ (scope.row.cost_default_currency / pieces_total).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column prop="cost_default_currency" :label="'Costs ' + defaultCurrencySymbol" min-width="160">
        <template slot-scope="scope">
          {{ defaultCurrencySymbol }}{{ Math.round(scope.row.cost_default_currency) | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <v-icon
            v-if="!scope.row.isTotalRow && scope.row.cost_key !== 0"
            class="mx-2 my-1 action-btn-danger"
            @click.stop="handleDeleteCostsGroupClick(scope.row)"
          >delete</v-icon>
        </template>
      </el-table-column>
      <el-table-column type="expand" :expanded="true" width="1">
        <template slot-scope="scope">
          <data-tables
            :data="costsByCostKey[scope.row.cost_key]"
            class="costs-child"
            :table-props="{
              'row-class-name': 'isSpecial'
            }"
            :pagination-props="{
              class: 'el-pagination text-right mt-6 pb-4 mr-2 d-none',
            }"
          >
            <el-table-column
              label-class-name="d-none"
              width="45">
            </el-table-column>
            <el-table-column class-name="costs-child" label-class-name="d-none" prop="cost_name" label="Name" min-width="320">
              <template slot-scope="scope">
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-x
                  class="date-picker"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="pt-0 mt-0"
                      :value="`${scope.row.date_key} (W${format((new Date(scope.row.date_key)), 'ww')})`"
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :locale-first-day-of-year="4"
                    :show-week="true"
                    v-model="scope.row.date_key"
                    :allowed-dates="date => getCostAlowedDates(date, scope.row)"
                    @change="handleCostChangedDelayed"
                  ></v-date-picker>
                </v-menu>
              </template>
            </el-table-column>
            <el-table-column prop="cost" label="Costs p.p." min-width="160" label-class-name="d-none">

              <template slot-scope="scope">

                <div class="mt-3 cost-input">
                  {{ currencySymbols[scope.row.currency] }}{{ ((scope.row.cost / pieces_total)).toFixed(2) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="cost" label="Costs" min-width="160" label-class-name="d-none">
              <template slot-scope="scope">
                <v-text-field
                  class="pt-0 mt-0"
                  :value="scope.row.cost"
                  hide-details
                  @input="scope.row.cost = parseFloat($event.replace(',', '') || 0)"
                  @keyup="handleCostChangedDelayed"
                ></v-text-field>
              </template>
            </el-table-column>
            <el-table-column label-class-name="d-none"  :label="'Costs p.p. ' + defaultCurrencySymbol" min-width="160">
              <template slot-scope="scope">
                {{ defaultCurrencySymbol }}{{ ((scope.row.cost_default_currency / pieces_total)).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label-class-name="d-none" :label="'Costs ' + defaultCurrencySymbol" min-width="160">

              <template slot-scope="scope">

                <div class="mt-3 cost-input">
                  {{ defaultCurrencySymbol }}{{ scope.row.cost_default_currency }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label-class-name="d-none">
              <template slot-scope="scope">
                <v-icon
                  class="action-btn-danger mr-5"
                  @click="handleDeleteCostsRowClick(scope.row)"
                >delete</v-icon>
              </template>
            </el-table-column>
          </data-tables>

          <v-icon
            class="ml-12"
            @click="handleAddCostClick(scope.row)"
          >add</v-icon>
        </template>
      </el-table-column>
    </data-tables>
    <v-row class="my-2 mx-10">
      <v-col :cols="3">
        <v-select
          :items="costsSelectOptions"
          class="pt-0 new-cost-select"
          v-model="newCostGroup"
          placeholder="New cost"
          hide-details
          return-object
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"
          @change="handleNewCostGroupSelected" />
      </v-col>
    </v-row>
    <v-row class="my-2 mx-10">
      <v-col>
        <div class="d-flex flex-wrap justify-space-between mx-n4 kpi-tiles">
          <div
            v-for="(item, i) in kpiItems"
            :key="i"
            class="d-flex flex-column align-center rounded p-relative rounded elevation-2 kpi-tile mx-4 my-4"
          >
            <div class="mt-4 tile-title">{{ item.title }}</div>
            <div class="mt-6 tile-value">{{i !== kpiItems.length -1 ? defaultCurrencySymbol : ''}}{{ item.value }}</div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash'
import { addDays, format } from 'date-fns'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  components: {
  },
  props: {
    currencies: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      required: true,
    },
    dummy: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    orderCosts: {
      type: Object,
      required: true,
    },
    orderLine: {
      type: Object,
      required: true,
    },
    pieces_total: {
      type: Number,
      required: false,
    },
    kpiItems: {
      type: Array,
      required: true,
    },
    skuCols: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      costsGroupsRows: [],
      costsRows: [],
      expandedRows: [],
      expandRowKeys: [],
      newCostGroup: null,
      handleCostChangedDelayed: null,
      defaultCurrency: localStorage.getItem('defaultCurrency'),
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    twoDecimals () {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
    },
    costsOptions () {
      return (this.appConfig?.costs || [])
    },
    currencySymbols () {
      return this.currencies.reduce((acc, cur) => ({
        ...acc,
        [cur.iso_code]: cur.symbol,
      }), {})
    },
    defaultCurrencySymbol () {
      return this.currencySymbols[this.defaultCurrency]
    },
    costsByCostKey () {
      return this.costsGroupsRows.reduce((acc, cur) => ({
        ...acc,
        [cur.cost_key]: this.costsRows.filter(cr => cr.cost_key === cur.cost_key)
      }), {})
    },
    costsSelectOptions () {
      const usedKeys = this.costsGroupsRows.map(row => row.cost_key)

      return this.costsOptions
        .filter(co => !usedKeys.includes(co.cost_key))
        .map(co => ({
          text: co.cost_name,
          value: co.cost_key,
        }))
    },
    costsGroupsTotalRow () {
      const totalCost = this.costsRows.reduce((acc, cur) => (acc + cur.cost_default_currency), 0)

      return {
        isTotalRow: true,
        cost_name: 'Total',
        cost_default_currency: totalCost,
      }
    },
    costsGroupsRowsWithTotal () {
      return [
        ...this.costsGroupsRows.map(gr => ({
          ...gr,
          cost_default_currency: _.sumBy(this.costsRows.filter(cr => cr.cost_key === gr.cost_key), 'cost_default_currency')
        })),
        this.costsGroupsTotalRow,
      ]
    },
  },
  watch: {
    orderCosts () {
      this.setCosts()
      this.expandRowKeys = [ ...this.expandedRows ]
      /* this.costsGroupsRows
                .filter(row => this.expandedRows.includes(row.cost_key))
                .forEach(row => {
                    this.$refs.costsTable.$refs.elTable.toggleRowExpansion(row, true)
                }) */
    },
  },
  methods: {
    getProperties (row) {
      let dataString = ''
      const columns = [
        { value: 'cost_per_piece', label: 'Per piece', formatter: (value) => value },
        { value: 'cost_per_order', label: 'Per order', formatter: (value) => value },
        { value: 'perc_of_cost_price', label: 'Buy price', formatter: (value) => this.$options.filters.formatPercents(value) + '%' },
        { value: 'perc_of_retail_price', label: 'Retail price', formatter: (value) => this.$options.filters.formatPercents(value) + '%' },
      ]
      for (const item of columns) {
        const val =
          '<b>' + item.label + '</b>: ' + item.formatter(row[item.value])
        dataString += val + '<br>'
      }
      return dataString
    },
    getCostAlowedDates (date, costGroup) {
      return !this.costsByCostKey[costGroup.cost_key].includes(date)
    },
    format,
    setCosts () {
      this.costsGroupsRows = [ ...this.orderCosts.costs_grouped ]
      this.costsRows = [ ...this.orderCosts.costs ]

      this.costsGroupsRows = this.costsGroupsRows.map(gr => {
        if (gr.cost_key === 0) {
          gr.cost_name = 'buy_price'
          gr.perc_of_cost_price = 1
          gr.perc_of_retail_price = 0
          gr.cost_per_piece = 0
          gr.cost_per_order = 0
        }

        return gr
      })
    },
    handleRowClick (row) {
      if (row.isTotalRow) {
        return
      }

      if (this.expandedRows.includes(row.cost_key)) {
        this.expandedRows = this.expandedRows.filter(key => key !== row.cost_key)
        this.$refs.costsTable.$refs.elTable.toggleRowExpansion(row, false)
      } else {
        this.expandedRows = [...this.expandedRows, row.cost_key]
        this.$refs.costsTable.$refs.elTable.toggleRowExpansion(row, true)
      }
    },
    handleDeleteCostsGroupClick (costGroup) {
      const data = this.orderCosts.costs.filter(c =>
        c.cost_key !== costGroup.cost_key
      )

      this.$emit('costs-changed', data)
    },
    handleDeleteCostsRowClick (cost) {
      const data = this.orderCosts.costs.filter(c =>
        c.cost_key !== cost.cost_key || c.date_key !== cost.date_key
      )

      this.$emit('costs-changed', data)
    },
    handleAddCostClick (costGroup) {
      const lastCostOfGroup = _.last(this.costsByCostKey[costGroup.cost_key])
      const newDateKey = format(addDays(new Date(lastCostOfGroup.date_key), 1), 'yyyy-MM-dd')
      const data = [
        ...this.orderCosts.costs,
        {
          ...lastCostOfGroup,
          date_key: newDateKey,
        },
      ]

      this.$emit('costs-changed', data)
    },
    handleCostChanged () {
      const data = _.flatten(Object.values(this.costsByCostKey))

      this.$emit('costs-changed', data)
    },
    handleNewCostGroupSelected (costGroup) {
      this.$emit('new-cost-group-selected', costGroup)
    },
  },
  created () {
    this.handleCostChangedDelayed = _.debounce(this.handleCostChanged, 2000)
    this.setCosts()
  },
}
</script>

<style lang="scss">
.shop-item-order-form-costs {
    .el-table__expanded-cell,
    .el-table__expanded-cell:hover {
        background: #dfdfdf !important;
    }
    .el-table__expanded-cell {
        input {
            font-size: 13px;
            padding-bottom: 4px;
        }
    }
  .costs-child {
    background-color: transparent;
    display: flex;
    .el-table {
      background-color: transparent;
    }
  }
    .v-input {
        font-size: 14px;
    }
    .cost-input {
        width: 98px;
        margin-right: 124px;
    }
    .kpi-tiles {
        .kpi-tile {
            height: 130px;
            flex-basis: 180px;
            display: inline-block;
            .tile-title {
                font-size: 12px;
                font-weight: 500;
            }
            .tile-value {
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
  .isSpecial, .isSpecial td, .isSpecial tr, {
    background-color: transparent !important;
  }
  .isSpecial .cell {
    overflow: visible !important;
  }
  .el-table__cell.el-table__expanded-cell {
    padding: 2px 0;
    .el-table td {
      padding: 0;
      height: 40px;
      border-bottom: none;
    }
    .el-table::before {
      display: none;
    }
  }
}
</style>
