<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'DataPagination',
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5
    },
    totalPages: {
      type: Number,
      required: true
    },
    pageSizes: {
      type: Array,
      required: false,
      default (){
        return [5, 10, 15, 25, 50, 'all']
      }
    },
    total: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },

  },
  computed: {
    startPage () {
      const startPage = this.currentPage - 2
      if(startPage < 1){
        return 1
      }
      return startPage

    },
    endPage () {

      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages)

    },
    pages () {
      const range = []

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        })
      }

      return range
    },
    isInFirstPage () {
      return this.currentPage === 1
    },
    isInLastPage () {
      return this.currentPage === this.totalPages
    },
  },
  methods: {
    onPageChange (value: number){

      if(value < 1){
        value = 1
      }
      this.$emit('pagechanged', 1)
      this.$emit('size-change', parseInt(value))
    },
    onClickFirstPage () {
      this.$emit('pagechanged', 1)
    },
    onClickPreviousPage () {
      this.$emit('pagechanged', this.currentPage - 3 )
    },
    goToPrevPage () {
      this.$emit('pagechanged', this.currentPage - 1 )
    },
    onClickPage (page: number) {
      this.$emit('pagechanged', page)
    },
    onClickNextPage () {
      this.$emit('pagechanged', this.currentPage + 3)
    },
    goToNextPage () {
      this.$emit('pagechanged', this.currentPage + 1)
    },
    onClickLastPage () {
      this.$emit('pagechanged', this.totalPages)
    },
    isPageActive (page: number) {
      return this.currentPage === page
    },
  },
})
</script>

<template>
  <div class="d-flex flex-nowrap align-center el-pagination">
    <ul class="pagination">
      <li
        class="pagination-item el-pager"
      >
        <button type="button" class="btn-prev"
          :disabled="isInFirstPage"
          @click="goToPrevPage"
        ><i class="el-icon el-icon-arrow-left"></i>
        </button>
      </li>
      <li
        v-if="currentPage > 3"
        class="pagination-item el-pager"
      >
        <button class="px-3"
          type="button"
          @click="onClickFirstPage"
          aria-label="Go to first page"
        >
          1
        </button>
      </li>

      <li
        class="pagination-item el-pager"
        v-if="currentPage > 4"

      >
        <button class="px-0"
          type="button"
          @click="onClickPreviousPage"
          aria-label="Go to previous page"
        >
          <li class="el-icon more btn-quickprev el-icon-more less-icon">

          </li>
        </button>
      </li>

      <li v-for="page in pages" :key="page.name" class="pagination-item el-pager">
        <button class="px-3"
          type="button"
          @click="onClickPage(page.name)"
          :class="{ active: isPageActive(page.name), disabled: page.isDisabled }"
          :aria-label="`Go to page number ${page.name}`"
        >
          {{ page.name }}
        </button>
      </li>

      <li class="pagination-item el-pager"              v-if="currentPage < totalPages -3"
      >
        <button class="px-0"
          type="button"
          @click="onClickNextPage"
          aria-label="Go to next page"
        >
          <li class="el-icon more btn-quicknext el-icon-more more-icon"></li>
        </button>
      </li>

      <li class="pagination-item el-pager"              v-if="currentPage < totalPages -3"
      >
        <button class="px-3"
          type="button"
          @click="onClickLastPage"
          aria-label="Go to last page"
        >
          {{ totalPages }}
        </button>
      </li>
      <li class="pagination-item el-pager">

        <button type="button" class="btn-next " @click="goToNextPage" :disabled="isInLastPage">
          <i class="el-icon el-icon-arrow-right"></i>
        </button>
      </li>

    </ul>

    <div class="d-flex justify-end align-center el-pagination__sizes pl-5" style="font-weight: 700">
      <span>Rows:</span>
      <v-combobox
        class="mx-4 mt-0 py-0 font-weight-regular size-selector"
        style="width: 64px"
        :items="pageSizes"
        :menu-props="{
          left: true,
        }"
        type="number"
        hide-details
        :value="perPage"
        @change="onPageChange"
      >
      </v-combobox>
      <span>
        Total: {{total}}
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">

.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}


.more-icon:hover{
  &:before{
    content: "\e6dc";
  }
}
.less-icon:hover{
  &:before{
    content: "\e6dc";
  }
}

.active {
  color: var(--primary)
}
</style>
<style lang="scss">
.pagination-wrap {
  .size-selector {
    input {
      text-align: right;
      font-size: 13px;
      color:rgb(96, 98, 102);
      font-weight: 700;
      max-width: 32px;
    }
    .v-icon .notranslate .mdi .mdi-menu-down .theme--light{
      margin-bottom: -6px;
    }
    &.v-select.v-input--is-focused input{
      min-width: 0;
    }
  }
}
</style>
