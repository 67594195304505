import axios from '../services/axios'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

export function getCombinations () {
  return axios.get(baseURLs.manageAPI + '/transfers_combinations')
    .then(({ data: { combinations } }) => {
      return combinations
    })
    .catch(error => console.log(error))
}

export function addCombinations (payload) {
  return axios.post(baseURLs.manageAPI + '/transfers_combinations', payload)
    .then(({ data: { combinations } }) => {
      return combinations
    })
    .catch(error => console.log(error))
}

export function editCombinations (payload) {
  return axios.put(baseURLs.manageAPI + '/transfers_combinations', payload)
    .then(({ data: { combinations } }) => {
      return combinations
    })
    .catch(error => console.log(error))
}

export function deleteCombinations (payload) {
  return axios.delete(baseURLs.manageAPI + '/transfers_combinations', { headers: getHeadersWithAccessToken(), data: payload })
    .then(({ data: { combinations } }) => {
      return combinations
    })
    .catch(error => console.log(error))
}

export function getCombinationsUpload () {
  return axios.get(baseURLs.manageAPI + '/transfers_combinations_upload')
    .then(({ data: { combinations } }) => {
      return combinations
    })
    .catch(error => console.log(error))
}

export function uploadCombinations (payload) {
  return axios.put(baseURLs.manageAPI + '/transfers_combinations_upload', payload)
    .then(({ data: { message } }) => {
      return message
    })
    .catch((error) => {
      throw error
    })
}
