<template>
  <v-card class="not-found ma-10 ml-4">
    <div class="my-auto justify-center align-center pa-10">
      <v-card-text>
        <Logo width="80px" to="/" class="mx-auto mb-10 pl-5"/>
        <div>
          <h1 class="c404 mb-12">404</h1>
          <h2 class="not-found mb-10">Page not found</h2>
          <div class="text-subtitle-1 pb-5"> The page you are looking for does not exist.</div>

          <v-btn
            color="primary"
            outlined
            class="mx-auto"
            to="/"
          >
            Go to home
          </v-btn>
        </div>
      </v-card-text>
    </div>
  </v-card>

</template>

<script>
import Logo from '@/components/Logo.vue'
export default {
  name: 'NotFound',
  components: {
    Logo
  }
}
</script>

<style scoped lang="scss">
.not-found {
    text-align: center;
    height: 100%;
    .c404 {
        font-size: 80px;
        font-weight: 450;
    }
    .not-found {
        font-size: 30px;
        font-weight: normal;
    }
}

</style>
