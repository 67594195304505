<template>
  <div class="assortment-option-dynamic">
    <v-overlay absolute color="white" :value="stockManageStore.capacityTab.loadingOptionDynamic">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <h6 v-if="!optionKey" class="text-subtitle-1 font-weight-medium mt-8 ml-6">Low level capacity</h6>
    <h6 v-else class="text-subtitle-1 font-weight-medium mt-2 ml-4">Option low level capacity</h6>
    <v-row>
      <div class="col text-right pr-6">
        <TableSearch class="mx-2"
          @searchChanged="val => handleSearchChanged('optionsTableFilters', columnsToSearch, val)" />
        <v-icon class="mx-2 action-btn-primary" :disabled="!selectedRows[0]" @click="handleEditClick">edit</v-icon>
        <v-icon class="mx-2 action-btn-primary" @click="handleAddClick">add</v-icon>
        <v-icon class="mx-2 action-btn-danger" :disabled="!selectedRows[0] || selectedRows[0].in_use"
          @click="handleDeleteClick">delete</v-icon>

        <v-menu offset-y offset-x left nudge-bottom="5" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on"
            >filter_list
            </v-icon>
          </template>
          <v-card max-height="400" class="overflow-auto">
            <h5 class="px-2 pt-2">Columns</h5>
            <el-checkbox-group v-model="selectedColumns" @input="saveSelectedColumns" style="font-size: 14px"
              class="d-flex flex-column pa-2">
              <el-checkbox v-for="item of columnsToSelect" :label="item" :key="item"></el-checkbox>
            </el-checkbox-group>
          </v-card>
        </v-menu>
      </div>
    </v-row>
    <v-row>
      <div class="col">
        <data-tables
          two-rows-column

          :current-page="currentPage"
          @update:currentPage="currentPage = $event"
          :key="tableKey" style="width: 100%" :data="optionsList" :page-size="5"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :table-props="{ 'row-key': 'index' }" :filters="optionsTableFilters" filter-multiple
          @filter-change="filters => handleFilterChange('optionsTableFilters', filters)" @row-click="handleRowClick">
          <data-tables-column width="55">
            <template slot-scope="scope">
              <v-simple-checkbox color="primary" dense hide-details class="mt-0 ml-4 pt-0" :value="scope.row.selected"
                :ripple="false" @input="handleSelectRowChange($event, scope.row)" />
            </template>
          </data-tables-column>

          <data-tables-column
            v-if="selectedColumns.includes('Option set')"
            prop="option_set" column-key="option_set"
            min-width="180" label="Option set" sortable="custom"
            :filters="getColumnFilterValues('optionsList', 'option_set')">
            <template slot-scope="scope">
              <div class="d-flex flex-wrap align-center">
                <div class="text-no-wrap" :class="{'mr-4': scope.row.option_set}">
                  {{ scope.row.option_set }}
                </div>
                <v-chip label outlined small color="#4FB4AB"
                  v-if="scope.row.option_set_count"
                  @click.stop="handleRowOptionSetClick(scope.row)">
                  {{ scope.row.option_set_count }}
                </v-chip>
              </div>
            </template>
          </data-tables-column>

          <data-tables-column
            v-if="selectedColumns.includes('Options')"
            prop="option_keys"
            min-width="120" column-key="option_keys" label="Options" sortable="custom"
            :filters="getColumnFilterValues('optionsList', 'option_keys')">
            <template slot-scope="scope">
              <ChipsDropdown :items="scope.row.option_keys || []" @item-click="openOptionPage" />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Store set')"
            prop="store_set"
            min-width="160" column-key="store_set" label="Store set" sortable="custom"
            :filters="getColumnFilterValues('optionsList', 'store_set')">
            <template slot-scope="scope">
              <div class="d-flex flex-wrap align-center">
                <div class="text-no-wrap" :class="{'mr-4': scope.row.store_set}">
                  {{ scope.row.store_set }}
                </div>
                <v-chip label outlined small color="#4FB4AB"
                  v-if="scope.row.store_set_count"
                  @click.stop="handleRowStoreSetClick(scope.row)">
                  {{ scope.row.store_set_count }}
                </v-chip>
              </div>
            </template>
          </data-tables-column>

          <data-tables-column prop="store_keys"
            v-if="selectedColumns.includes('Stores')"
            min-width="120"
            column-key="store_keys" label="Stores" sortable="custom"
            :filters="getColumnFilterValues('optionsList', 'store_keys', (store_key)=> appConfig?.stores?.find(s => s.store_key === store_key)?.store_name)">
            <template slot-scope="scope">
              <ChipsDropdown :items="getStoresByKeys(scope.row.store_keys)" />
            </template>
          </data-tables-column>
          <data-tables-column prop="capacity_type"
            v-if="selectedColumns.includes('Type')"

            min-width="100" column-key="capacity_type" label="Type" sortable="custom"
            :filters="getColumnFilterValues('optionsList', 'capacity_type', humanizeCapacityType)">
            <template slot-scope="scope">
              {{ humanizeCapacityType(scope.row.capacity_type) | formatString }}
            </template>
          </data-tables-column>

          <data-tables-column
            v-if="selectedColumns.includes('Exclude')"
            prop="exclude" label="Exclude"
            min-width="100"
            sortable column-key="exclude"
            :filters="getColumnFilterValues('optionsList', 'exclude')">
            <template slot-scope="scope">
              <v-checkbox v-model="scope.row.exclude" disabled></v-checkbox>
              <!--              {{ scope.row.exclude ? 'Yes' : 'No' }}-->
            </template>
          </data-tables-column>
          <data-tables-column prop="sku_names"
            v-if="selectedColumns.includes('SKUs')"
            width="80"
            column-key="sku_names" label="SKUs" sortable="custom"
            :filters="getColumnFilterValues('optionsList', 'sku_names')">
            <template v-slot="scope">
              <ChipsDropdown :items="scope.row.sku_names" />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Start')"
            prop="date_min" label="Start" column-key="date_min" sortable
            width="100"
            :filters="getColumnFilterValues('optionsList', 'date_min')">
            <template slot-scope="scope">
              {{ scope.row.date_min }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('End')"
            prop="date_max" label="End"
            min-width="100"
            sortable column-key="date_max"
            :filters="getColumnFilterValues('optionsList', 'date_max')">
            <template slot-scope="scope">
              {{ scope.row.date_max }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Must')"
            column-key="must_have"
            prop="must_have" label="Must" width="60" sortable="custom"
            :filters="getColumnFilterValues('optionsList', 'must_have')">
            <template slot-scope="scope">
              {{ scope.row.must_have }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Nice')"
            column-key="nice_have"
            prop="nice_have" label="Nice" width="60" sortable="custom"
            :filters="getColumnFilterValues('optionsList', 'nice_have')">
            <template slot-scope="scope">
              {{ scope.row.nice_have }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Max')"
            prop="max_have" label="Max" width="60" sortable="custom"
            column-key="max_have"
            :filters="getColumnFilterValues('optionsList', 'max_have')">
            <template slot-scope="scope">
              {{ scope.row.max_have }}
            </template>
          </data-tables-column>
          <data-tables-column
            column-key="note"
            v-if="selectedColumns.includes('Note')"
            :filters="getColumnFilterValues('optionsList', 'note')"
            prop="note" label="Note" sortable="custom" min-width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.note }}</span>
            </template>
          </data-tables-column>
          <data-tables-column

            v-if="selectedColumns.includes('Changed at')"
            prop="changed_at" label="Changed at"
            min-width="100"
            sortable column-key="changed_at"
            :filters="getColumnFilterValues('optionsList', 'changed_at')">
            <template slot-scope="scope">
              {{ scope.row.changed_at }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Changed by')"
            prop="changed_by" label="Changed by"
            min-width="100"
            sortable column-key="changed_by"
            :filters="getColumnFilterValues('optionsList', 'changed_by')">
            <template slot-scope="scope">
              {{ scope.row.changed_by }}
            </template>
          </data-tables-column>
        </data-tables>
      </div>
    </v-row>

    <!-- Form dialog -->
    <AddEditDialog v-if="currentCapacityOption" :is-open="formDialogVisible"
      :current-capacity-option="currentCapacityOption" :is-new-option="isNewOption" :skus="skus"
      :current-capacity-option-errors="currentCapacityOptionErrors" :fixed-option-key="optionKey"
      @option-key-delete="handleOptionKeyDeleteClick" @save="handleSaveConfirm" @close="formDialogVisible = false"
      @cancel="handleSaveCancel" />
    <!-- Option set form dialog -->
    <option-set-form-dialog ref="optionSetFormDialog" :is-open="optionSetFormDialogVisible"
      :current-option-set="currentOptionSet" @close="optionSetFormDialogVisible = false" />

    <!-- Store set form dialog -->
    <store-set-form-dialog ref="storeSetFormDialog" :is-open="storeSetFormDialogVisible"
      :current-store-set="currentStoreSet" @close="storeSetFormDialogVisible = false" />

    <!-- Delete dialog -->
    <v-dialog :title="selectedRows[0] ? selectedRows[0].name : null" :value="deleteDialogVisible" persistent
      content-class="align-self-start mt-14" width="600px" max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="stockManageStore.capacityTab.dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium">Delete capacity</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete this capacity option</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn color="primary" depressed text @click="deleteDialogVisible = false">Cancel</v-btn>
              <v-btn depressed outlined @click="handleDeleteConfirm" class="red--text mx-4">Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'
import ChipsDropdown from '../ChipsDropdown'
import {getCapacityOptionErrors} from '@/utils'
import OptionSetFormDialog from '@/components/Assortment/OptionSets/OptionSetFormDialog'
import StoreSetFormDialog from '@/components/Assortment/StoreSets/StoreSetFormDialog'
import { mapState } from 'pinia'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'
import {useGeneralStore} from '@/store/pinia/generalStore'
import AddEditDialog from '@/components/Assortment/AddEditStaticDialog.vue'
import { useStockManageStore } from '@/store/pinia/stockManageStore'

const columnsToSelect = ['Option set', 'Options', 'Store set', 'Stores', 'Type', 'Exclude', 'SKUs', 'Start', 'End', 'Must', 'Nice', 'Max', 'Note', 'Changed at', 'Changed by']
const defaultColumns = ['Option set', 'Options', 'Store set', 'Stores', 'Type', 'SKUs', 'Start', 'End', 'Must', 'Nice', 'Max', 'Note']

export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  mixins: [columnFilters],
  props: {
    optionKey: {
      type: String,
      default: null,
    },
    skus: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      currentPage: 1,
      oldCurrentPage: 1,
      columnsToSelect,
      tableKey: 0,
      selectedColumns: JSON.parse(localStorage.getItem('lowLevelCapacitySelectedColumns')) || defaultColumns,
      formDialogVisible: false,
      deleteDialogVisible: false,
      currentCapacityOption: null,
      isNewOption: false,
      dateMinPickerActive: false,
      dateMaxPickerActive: false,
      insertConfirmVisible: false,
      currentCapacityOptionErrors: {
        optionSets: null,
        storeSets: null,
        mustHave: null,
        niceToHave: null,
        maxHave: null,
      },
      optionsTableFilters: [],
      columnsToSearch: ['option_set', 'store_set', 'date_min', 'date_max', 'must_have', 'nice_have', 'max_have', 'note'],
      optionSetFormDialogVisible: false,
      currentOptionSet: null,
      storeSetFormDialogVisible: false,
      currentStoreSet: null,
      defaultOption: {
        'org_key': 0,
        'capacity_key': null,
        'options_set_key': undefined,
        'stores_set_key': undefined,
        'date_min': null,
        'date_max': null,
        'must_have': null,
        'nice_have': null,
        'max_have': null,
        'exclude': false,
        'capacity_label': null,
        'tags': [],
        'store_keys': [],
        'option_keys': [],
        'sku_names': [],
        'note': null,
        'changed_by': null,
        'changed_at': null
      },
    }
  },
  components: {
    AddEditDialog,
    StoreSetFormDialog,
    OptionSetFormDialog,
    TableSearch,
    ChipsDropdown,
  },
  created () {
    this.loadCapacity()
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    ...mapState(useStoreAndOptionSetsStore, ['storeSets']),
    optionsList () {
      return this.stockManageStore.capacityTab.lowLevelCapacityList.map((c, i) => {
        c.index = i
        c.date_min = c.date_min || ''
        c.date_max = c.date_max || ''
        c.changed_at = c.changed_at?.substring(0, 10) || ''
        return c
      })
    },
    editedOption () {
      return this.stockManageStore.capacityTab.editedDynamicCapacityOption
    },
    editedOptionSet () {
      return this.stockManageStore.capacityTab.editedOptionSet
    },
    editedStoreSet () {
      return this.stockManageStore.capacityTab.editedStoreSet
    },
    selectedRows () {
      return this.optionsList.filter(r => r.selected)
    },
  },
  methods: {

    saveSelectedColumns (value) {
      localStorage.setItem('lowLevelCapacitySelectedColumns', JSON.stringify(value))
    },
    handleOptionKeyDeleteClick (index) {
      this.$delete(this.currentCapacityOption.option_keys, index)
    },
    openOptionPage (option_key) {
      const routeData = this.$router.resolve({ name: 'stock-option', query: { option_key } })
      window.open(routeData.href, '_blank')
    },
    getStoresByKeys (storeKeys) {

      if (!storeKeys || !(this.appConfig?.stores || [])) {
        return []
      }
      return (this.appConfig?.stores || []).filter(s => storeKeys.includes(s.store_key)).map(s => s.store_name)
    },
    humanizeCapacityType (capacityType) {
      const capacityTypes = {
        'pieces': 'Pieces',
        'unique_skus': 'Unique SKUs',
        'serial_skus': 'Serial SKUs',
      }
      return capacityTypes[capacityType] || capacityType
    },
    async loadCapacity () {
      if (this.optionKey) {
        await this.stockManageStore.loadOptionCapacity(this.optionKey)
        return
      }
      await this.stockManageStore.loadLowLevelCapacityList()
      this.tableKey++
    },
    handleEditClick () {
      this.formDialogVisible = true
      this.isNewOption = false
      this.stockManageStore.getDynamicCapacityOption(this.selectedRows[0].capacity_key)
        .then(() => {
          this.currentCapacityOption = { ...this.editedOption }
        })
    },
    handleAddClick () {
      this.currentCapacityOption = Object.assign({}, this.defaultOption)
      this.formDialogVisible = true
      this.isNewOption = true
    },
    handleDeleteClick () {
      this.deleteDialogVisible = true
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
    },
    handleDeleteConfirm () {
      this.oldCurrentPage = this.currentPage
      this.stockManageStore.deleteDynamicCapacityOption(this.selectedRows[0].capacity_key)
        .then(async () => {
          this.currentPage = this.oldCurrentPage
          this.deleteDialogVisible = false
          await this.loadCapacity()
          this.$emit('capacityUpdated')
        })
    },
    handleSaveConfirm (value) {
      this.currentCapacityOption = value

      if (!this.validateCapacityOption()) {
        return
      }

      this.stockManageStore.saveCapacityOption({
        ...this.currentCapacityOption,
        must_have: this.currentCapacityOption.must_have ?? null,
        nice_have: this.currentCapacityOption.nice_have ?? null,
        max_have: this.currentCapacityOption.max_have ?? null,
        capacity_label: 'sellable'
      })
        .then(async () => {
          this.formDialogVisible = false
          this.resetForm()
          await this.loadCapacity()
          this.$emit('capacityUpdated')
        })
    },
    handleSaveCancel () {
      this.formDialogVisible = false
      this.resetForm()
    },
    validateCapacityOption () {
      this.resetFormErrors()
      this.currentCapacityOptionErrors = getCapacityOptionErrors(this.currentCapacityOption)
      return !this.formHasErrors()
    },
    resetForm () {
      this.currentCapacityOption = null
      this.insertConfirmVisible = false
      this.resetFormErrors()
    },
    resetFormErrors () {
      const errors = this.currentCapacityOptionErrors

      Object.keys(errors).forEach(key => {
        errors[key] = null
      })
    },
    formHasErrors () {
      return Object.values(this.currentCapacityOptionErrors).some(val => !!val)
    },
    optionSetFormRef () {
      return this.$refs.optionSetFormDialog.$refs.optionSetForm
    },
    storeSetFormRef () {
      return this.$refs.storeSetFormDialog.$refs.storeSetForm
    },
    handleRowOptionSetClick (row) {
      console.log(row)
      const payload = {
        tab: 'capacity',
        key: row.options_set_key,
      }
      this.optionSetFormDialogVisible = true
      this.stockManageStore.getOptionSet(payload)
        .then(() => {
          this.currentOptionSet = { ...this.editedOptionSet }
          this.$nextTick(this.optionSetFormRef().searchOptions)
        })
    },
    handleRowStoreSetClick (row) {
      const payload = {
        tab: 'capacity',
        key: row.stores_set_key,
      }
      this.storeSetFormDialogVisible = true
      this.stockManageStore.getStoreSet(payload)
        .then(() => {
          this.currentStoreSet = { ...this.editedStoreSet }
          this.$nextTick(this.storeSetFormRef().searchStores)
        })
    },
  }
}
</script>

<style lang="scss">
.assortment-option-dynamic {}
</style>
