<script lang="ts">
import {defineComponent} from 'vue'
import {isNumber} from 'lodash'

interface GteOrLte {
  gte: number,
  lte: number,
}
interface filter  {
  text: string,
  value:string
}
export default defineComponent({
  name: 'TableColumnHeader',
  data () {
    return {
      filterText: '',
      menuOpened: false,
      noNeedReset: false,
      menuKey: 0,
      filteredFilters: [] as Array<filter>,
      sortedFilteredValues: [] as (GteOrLte | string)[],
      gte: null as null | number,
      lte: null as null | number,
    }
  },
  props: {
    columnKey: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },

    filters: {
      type: Array<filter>,
      default: ()=> []
    },
    filteredValue: {
      type: Array<string>,
      default: null
    }
  },
  computed: {
    allSelected (){
      if(this.sortedFilteredValues?.length  !== this.filteredFilters?.length){
        return false
      }
      return this.filteredFilters?.every(item=> this.sortedFilteredValues.includes(item.value)) || false
    },
    isNumeric (){
      return this.filters?.every(item=> {
        return isNumber(item.text)
      }) || false
    },
  },
  methods: {

    onSelectAllChange (value:boolean){
      this.sortedFilteredValues = value ? this.filteredFilters.map(item=> item.value) : []
    },
    onFilterUpdate (){
      this.filteredFilters = this.filters.filter(item=> item.text?.toString().toLowerCase().includes(this.filterText.toLowerCase()))
    },
    updateFilters (closeMenu = true){
      this.noNeedReset = true
      this.menuOpened = !closeMenu
      if(!closeMenu){
        return
      } else{
        this.menuKey++
      }
      const payload: {[key: string]: (string|GteOrLte)[]} = {[this.columnKey as string]: this.sortedFilteredValues.filter(item=> typeof item !== 'object' )}
      if(this.isNumeric && (this.gte != undefined || this.lte != undefined)){
        payload[this.columnKey as string].push({gte: this.gte, lte: this.lte})
      }
      this.$emit('filter-change', payload)
    },
  },
  mounted () {
    this.sortedFilteredValues = this.filteredValue || []
    if(!this.isNumeric) {
      this.onFilterUpdate()
    }
    const gteOrLte: GteOrLte | undefined = this.sortedFilteredValues.find(item=> typeof item === 'object') as GteOrLte
    if(gteOrLte){
      this.gte = gteOrLte.gte
      this.lte = gteOrLte.lte
    }
  }
})
</script>

<template>
  <span style="position: relative" class="data-tables-column">
    <div @click="$emit('on-name-click', $event)" class=" header-title">{{label}} </div>
    <v-menu :disabled="!filters?.length" left bottom :close-on-content-click="false" :value="menuOpened" :key="menuKey" @input="updateFilters(!$event)">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          :class="{'icon-is-hidden': !filters?.length}"
          class="action-btn-primary" v-bind="attrs" v-on="on" size="18" :color="sortedFilteredValues?.length ? 'primary': 'rgb(144, 147, 153)'"
          style="width: 18px; margin-left: 18px; right: -4px"
        >filter_list
        </v-icon>
      </template>
      <v-card max-width="260" class="overflow-auto pt-4">
        <v-card-text style="max-height:360px; overflow: auto" class="pa-2">
          <v-row class="ma-0 flex-nowrap pb-4" v-if="isNumeric">
            <v-text-field
              dense v-model="gte" label="Min" hide-details type="number" class="mx-2" />
            <v-text-field
              dense v-model="lte" label="Max" hide-details type="number" class="mx-2"/>
          </v-row>
          <template v-else>
            <v-text-field
              dense hide-details class="pa-2" label="Filter" v-model="filterText" @input="onFilterUpdate"></v-text-field>
            <v-checkbox class="px-2 pt-2 ma-0" hide-details :input-value="allSelected" :key="sortedFilteredValues?.length" @change="onSelectAllChange" label="Select all" ></v-checkbox>
            <v-col class="pa-0 px-2">
              <v-checkbox v-model="sortedFilteredValues" hide-details class="ma-0" v-for="item of filteredFilters" :label="item.text?.toString()" :key="item.value" :value="item.value"></v-checkbox>
            </v-col>
          </template>
        </v-card-text>
        <v-card-actions class="pa-0">
          <div class="el-table-filter__bottom pa-2" style="width: 100%">
            <button :disabled="!sortedFilteredValues?.length && !gte && !lte" :class="{'is-disabled': (!sortedFilteredValues?.length && !gte && !lte)}" @click="updateFilters()">confirm</button>
            <button  @click="sortedFilteredValues= [];lte=null;gte=null; updateFilters()">reset</button>
          </div>
        </v-card-actions>
      </v-card>
    </v-menu>
  </span>

</template>

<style scoped lang="scss">

</style>

<style scoped lang="scss">
.icon-is-hidden {
  visibility: hidden;
}
</style>
