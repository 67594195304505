import DataTables from './components/DataTables'
import DataTablesColumn from './components/DataTablesColumn.vue'
DataTables.install = function (Vue) {
  Vue.component(DataTables.name, DataTables)
  Vue.component(DataTablesColumn.name, DataTablesColumn)
}


const install = function (Vue) {
  DataTables.install(Vue)
  DataTablesColumn.install(Vue)
}

export {
  install,
  DataTables,
  DataTablesColumn,
}

export default {
  install
}
