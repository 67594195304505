<template>
  <div id="strategy-chunks-stars" class="mx-n6 p-relative">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row>
      <v-col>
        <data-tables
          style="width: 100%"
          :data="starsList"
          :page-size="5"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 mb-4 mr-2'
          }"
          :table-props="{
            'row-key': 'option_key',
            'expand-icon': 'add',
          }"
          @row-click="handleRowClick"
        >
          <el-table-column width="55">
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-4 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column prop="stars" label="Stars">
            <template slot-scope="scope">
              {{ scope.row.stars }}
            </template>
          </el-table-column>
          <el-table-column prop="options" label="Options">
            <template slot-scope="scope">
              {{ scope.row.options }}
            </template>
          </el-table-column>
          <el-table-column prop="weeks" label="Weeks">
            <template slot-scope="scope">
              {{ scope.row.weeks }}
            </template>
          </el-table-column>
          <el-table-column prop="sales_min" label="Sales min">
            <template slot-scope="scope">
              {{ scope.row.sales_min }}
            </template>
          </el-table-column>
          <el-table-column prop="sales_mean" label="Sales mean">
            <template slot-scope="scope">
              {{ scope.row.sales_mean }}
            </template>
          </el-table-column>
          <el-table-column prop="sales_p95" label="Sales P95">
            <template slot-scope="scope">
              {{ scope.row.sales_p95 }}
            </template>
          </el-table-column>
          <el-table-column prop="sales_max" label="Sales max">
            <template slot-scope="scope">
              {{ scope.row.sales_max }}
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>
    <div class="px-6">
      <h6 class="text-subtitle-1 font-weight-medium mb-4">New placeholder builder</h6>
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="newPlaceholderForm.name.value"
            label="Name"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <collapsible-select
            :value="newPlaceholderForm.skus.value"
            :items="skuNames"
            class="mb-3"
            label="Sku names"
            placeholder="Search"
            autocomplete
            multiple
            chips
            clearable
            small-chips
            deletable-chips
            hide-details
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu'
            }"
          ></collapsible-select>
        </v-col>
        <v-col cols="12" sm="4">
          <WeekSelector
            v-model="newPlaceholderForm.weekMin.value"
            clearable
            label="Week min"
            hide-details
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu'
            }"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <div class="slider-label mr-8">Weeks</div>
          <div class="d-flex align-center">
            <div class="slider-value-from mr-2">1</div>
            <v-slider
              v-model="newPlaceholderForm.weeks.value"
              thumb-label
              hide-details="auto"
              :min="1"
              :max="52"
            />
            <div class="slider-value-to ml-2">52</div>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="slider-label mr-8">Sellthrough</div>
          <div class="d-flex align-center">
            <div class="slider-value-from mr-2">0.01</div>
            <v-slider
              v-model="newPlaceholderForm.sellthrough.value"
              thumb-label
              hide-details="auto"
              :min="0.01"
              :max="1.0"
              :step="0.01"
            />
            <div class="slider-value-to ml-2">1.0</div>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="slider-label mr-8">OSP</div>
          <div class="d-flex align-center">
            <div class="slider-value-from mr-2">5</div>
            <v-slider
              v-model="newPlaceholderForm.osp.value"
              thumb-label
              hide-details="auto"
              :min="5"
              :max="100"
            />
            <div class="slider-value-to ml-2">100</div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Amount of sales expected: <b>{{ salesExpected | roundToStep(50) }}</b><br/>
          Intake value: <b>{{ intakeExpected }}</b>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            depressed
            outlined
            :disabled="!selectedRows[0]"
          >Add new placeholder</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import WeekSelector from '../WeekSelector'

export default {
  name: 'StrategyChunksStars',
  components: { WeekSelector, CollapsibleSelect },
  props: {
    stars: {
      type: Array,
      default: () => []
    },
    generatedName: {
      type: String || null,
      default: null,
    },
    skuNames: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      loading: false,
      starsList: [],
      newPlaceholderForm: {
        name: {
          value: '',
          error: null,
        },
        skus: {
          value: [],
          error: null,
        },
        weekMin: {
          value: null,
          error: null,
        },
        weeks: {
          value: 12,
          error: null,
        },
        sellthrough: {
          value: 0.95,
          error: null,
        },
        osp: {
          value: 50,
          error: null,
        },
      }
    }
  },
  computed: {
    selectedRows () {
      return this.starsList.filter(r => r.selected)
    },
    salesExpected () {
      if (!this.selectedRows[0]) {
        return 0
      }

      return this.selectedRows[0].sales_mean * this.newPlaceholderForm.weeks.value / this.newPlaceholderForm.sellthrough.value
    },
    intakeExpected () {
      if (!this.selectedRows[0]) {
        return 0
      }

      return this.$options.filters.roundToStep(this.salesExpected, 50) * this.newPlaceholderForm.osp.value
    },
  },
  watch: {
    stars () {
      this.starsList = this.stars.map(s => ({
        ...s,
        selected: s.stars === 3,
      }))
    },
    generatedName (val) {
      this.newPlaceholderForm.name.value = val
    },
    skuNames (val) {
      this.newPlaceholderForm.skus.value = val
    },
    selectedRows () {
      if (this.selectedRows[0]) {
        this.setFormValuesFromRow(this.selectedRows[0])
      }
    },
  },
  methods: {
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
    },
    setFormValuesFromRow (row) {
      this.newPlaceholderForm.weeks.value = row.weeks
    },
  },
}
</script>

<style lang="scss">
#strategy-chunks-stars {
    .slider-value-from,
    .slider-value-to {
        width: 32px;
    }
}
</style>
