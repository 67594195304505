<template>
  <v-dialog
    v-model="isOpen"
    scrollable
    persistent
    content-class="align-self-start mt-14"
    width="600px"
    max-width="90%"
  >
    <v-card>
      <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">{{isEdit? 'Edit' : 'Add'}} Property</v-card-title>
      <v-divider/>
      <v-card-text class="px-0 text--primary">
        <div class="container px-12">
          <v-text-field
            color="primary"
            v-model="localProperty.property_name"
            label="Name"
            hide-details="auto"
            required
            class="my-4"
            :disabled="isEdit"
          />
          <v-text-field
            hide-details="auto"
            color="primary"
            v-model="localProperty.property_alias"
            label="Alias"
            class="mt-2 mb-4"
          />
          <v-select label="Value Type"
            hide-details="auto"
            class="mt-2 mb-4"
            v-model="localProperty.value_type"
            :items="['text',  'boolean', 'integer', 'decimal']"
          />
          <v-text-field
            v-if="valueType !== 'boolean'"
            hide-details="auto"
            color="primary"
            v-model="localProperty.value_default"
            :type="valueType"
            :error-messages="errors"
            label="Default Value"
            class="mt-2 mb-4"
          />
          <v-row class="justify-center pl-3 mt-3" v-else>
            <div class="col col-4 justify-start px-0">
              <span class="text--primary">Default Value</span>
            </div>
            <div class="col justify-start">
              <v-simple-checkbox
                color="primary"
                dense
                v-model="localProperty.value_default"
                :ripple="false"
              />
            </div>
          </v-row>
          <v-row class="justify-center pl-3 mt-3" v-if="valueType !== 'boolean'">
            <div class="col col-4 justify-start px-0">
              <span class="text--primary">Value Dropdown</span>
            </div>
            <div class="col justify-start">
              <v-simple-checkbox
                color="primary"
                dense
                v-model="localProperty.value_dropdown"
                :ripple="false"
              />
            </div>
          </v-row>
          <v-row class="justify-center pl-3">
            <div class="col col-4 justify-start px-0">
              <span class="text--primary">Force</span>
            </div>
            <div class="col justify-start">
              <v-simple-checkbox
                color="primary"
                dense
                v-model="localProperty.force"
                :ripple="false"
              />
            </div>
          </v-row>

        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2">
          <v-col>
            <v-btn
              color="primary"
              depressed
              text
              @click="handleCancel"
            >Cancel</v-btn>
            <v-btn
              color="primary"
              depressed
              :disabled="!isLocalPropertyValid"
              @click="handleSave"
              class="mx-4"
            >Save</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PropertyModal',
  data () {
    return {
      localProperty: {
        property_name: '',
        property_alias: '',
        force: false,
        value_type: 'text',
        value_dropdown: true,
        value_default: '',
      },
      errors: [],
      isEdit: false,
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isLocalPropertyValid () {
      return this.localProperty.property_name.length > 0
    },
    valueType () {
      if (!this.localProperty || !this.localProperty.value_type) {
        return 'text'
      }
      if (this.localProperty.value_type === 'decimal' || this.localProperty.value_type === 'integer') {
        return 'number'
      }
      if (this.localProperty.value_type === 'boolean') {
        return 'boolean'
      }
      return 'text'
    }
  },
  watch: {
    'localProperty.value_type': function (val) {
      if (val === 'boolean') {
        this.localProperty.value_default = true
      }
    }
  },
  methods: {
    handleCancel () {
      this.$emit('close')
    },
    validate (value, value_type) {
      this.errors = []
      if (!value) {
        return true
      }
      if (value_type === 'decimal' &&
        (!value.match(/^\d+$/) && !value.match(/^\d+\.\d+$/))) {
        this.errors.push('value must be a decimal')
        return false
      }
      if (value_type === 'integer' && !value.match(/^\d+$/)) {
        this.errors.push('value must be an integer')
        return false
      }
      return true
    },
    handleSave () {
      if (!this.validate(this.localProperty.value_default, this.localProperty.value_type)) {
        return
      }
      this.$emit('close')
      this.$emit('save', this.localProperty)
    },
    setLocalProperty (property) {
      if (property) {
        this.localProperty = Object.assign({}, property)
        this.isEdit = true
      } else {
        this.localProperty = {
          property_name: '',
          property_alias: '',
          force: false,
          value_type: 'text',
          value_dropdown: true,
          value_default: '',
        }
        this.isEdit = false
      }
    },
  },

}
</script>
