<template>
  <div class="dummy-similar-options p-relative">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <h4 class="mt-2 mb-6">Lookalikes</h4>

    <v-row>
      <v-col class="text-right mr-6">
        <TableSearch class="mx-2" @searchChanged="onSearchChange" />
        <v-icon
          class="mx-2 action-btn-primary"
          :disabled="!selectedRows.length"
          @click="handleCopyImageClick"
        >camera_alt</v-icon>
      </v-col>
    </v-row>
    <data-tables
      ref="resultsTable"
      style="width: 100%"
      :key="optionListKey"
      :data="optionsTableData"
      :page-size="5"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
      :table-props="{ 'row-key': 'option_key', 'row-class-name': isSpecial }"
      @row-click="handleRowClick">
      <el-table-column prop="option_key" width="55">
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="ml-3"
            :value="scope.row.selected"
            :ripple="false"
            @input="handleSelectRowChange($event, scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-for="(col, i) in columns"
        sortable="custom"
        :key="col"
        :prop="col"
        :label="columnsLabels[i]"
        :width="col === 'option' ? '320' : null">
        <template slot-scope="scope">
          <div v-if="col === 'option' && scope.row['option'] !== null"
            style="display: grid; grid-template-columns: 6fr 1fr 1fr; justify-items: center"
            class="justify-space-between align-center">
            <div class="flex-shrink-1">
              <OptionDataCell
                :option="scope.row"
                name-key="option_name"
                open-in-new-tab
              />
            </div>
            <v-menu
              top
              open-on-hover
              offset-x
              min-width="200"
              max-width="200"
              content-class="option-info-menu"
              :open-delay="200"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="flex-shrink-0 option-info-icon-wrapper">
                  <v-icon
                    :disabled="!getProperties(scope.row)"
                    class="ml-6"
                    v-bind="attrs"
                    v-on="on"
                  >info</v-icon>
                </div>
              </template>

              <v-card
                class="py-2 px-4 p-relative"
                v-html="getProperties(scope.row)"
              />
            </v-menu>
            <v-icon
              v-if="!scope.row.isSelected" @click.stop="$emit('options-added', [scope.row]); $set(scope.row, 'isSelected', true)" dense >
              add
            </v-icon>
            <v-icon v-else @click.stop="$emit('options-deleted', [scope.row]); $set(scope.row, 'isSelected', false)">
              delete
            </v-icon>
          </div>
          <div v-else>{{ scope.row[col] }}</div>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { formatString } from '@/variables.js'
import TableSearch from '@/components/TableSearch.vue'
import OptionDataCell from '@/components/OptionDataCell.vue'

export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    optionsSelected: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      loading: false,
      maxSimilarOptions: 3,
      columns: ['option', 'ros', 'return_%', 'discount_%', 'sales', 'stores', 'life_time'],
      columnsLabels: ['Option', 'RoS', 'Return %', 'Discount %', 'Sales LY', 'Stores', 'Lifetime'],

      optionListKey: 0,
    }
  },
  components: {
    TableSearch,
    OptionDataCell,
  },
  computed: {
    selectedRows () {
      return this.optionsTableData.filter(r => r.selected)
    },
    optionsTableData () {
      return [...this.selected.map(item => ({
        ...item,
        isSelected: true
      })), ...this.options].sort((a, b) => parseInt(a.option_key, 10) - parseInt(b.option_key, 10))
    }
  },
  methods: {

    isSpecial ({ row }) {
      const isSpecial = row.isSelected
      return isSpecial ? 'bg-special cursor-pointer' : 'cursor-pointer'
    },
    onSearchChange (value) {
      this.$emit('table-search', value)
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.optionsTableData.forEach(item => {
        this.$set(item, 'selected', false)
      })
      this.$set(row, 'selected', val)
    },
    handleCopyImageClick () {
      this.$emit('image-copied', this.selectedRows[0].image_url)
    },
    getProperties (article) {
      let dataString = ''
      const data = article['properties']
      for (const key in data) {
        const val =
          '<b>' + formatString(key) + '</b>: ' + data[key].toLocaleString()
        dataString += val + '<br>'
      }
      return dataString
    },
  }
}
</script>

<style lang="scss">
.dummy-similar-options {
    .option-info-icon-wrapper {
        width: 50px;
    }
}
</style>
