<template>
  <resizable
    id="strategy-intake-products-modal"
    @resize="updateTotalTableWidth"
  >
    <data-tables
      style="width: 100%"
      class="table"
      :data="productsData.data"
      :page-size="5"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 mr-2',
      }"
    >
      <el-table-column
        prop="product_key"
        label="Product key"
        width="220"
        class-name="pl-8"
        sortable
      >
        <template slot-scope="scope">
          <OptionDataCell
            :option="{
              image_url: scope.row[1],
              product_name: scope.row[2],
            }"
            name-key="product_name"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-for="(column, i) in metricsColumns"
        :key="column"
        :prop="column"
        :label="column | formatString"
        class-name="text-capitalize"
        sortable
      >
        <template slot-scope="scope">
          {{ formatMetric(scope.row[i + 3], column) }}
        </template>
      </el-table-column>
      <template v-slot:append>
        <data-tables
          :data="productsData.total"
          class="total-table"
          :pagination-props="{class: 'd-none'}"
        >
          <el-table-column
            class-name="total-row_column pl-8"
            label-class-name="d-none"
            align="left"
            width="220"
          >
            <template>
              <div>
                Total
              </div>
            </template>
          </el-table-column>
          <el-table-column
            class-name="total-row_column pl-1"
            label-class-name="d-none"
            align="left"
            v-for="(column, i) in metricsColumns"
            :prop="column"
            :key="column"
          >
            <template slot-scope="scope">
              <div>
                {{ formatMetric(scope.row[i], column) }}
              </div>
            </template>
          </el-table-column>
        </data-tables>
      </template>
    </data-tables>
  </resizable>
</template>

<script>
import OptionDataCell from '@/components/OptionDataCell.vue'
import Resizable from '@/components/Utility/Resizable.vue'
import { useCubesStore } from '@/store/pinia/cubesStore'

export default {
  name: 'StrategyIntakeProductsModal',
  components: { Resizable, OptionDataCell },
  props: {
    productsData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup () {
    return {
      cubesStore: useCubesStore(),
    }
  },
  computed: {
    metricsColumns () {
      return this.productsData.columns.filter(c => !['product_key', 'image_url', 'product_name'].includes(c))
    },
    metricFormats () {
      return this.cubesStore.metricFormat
    },
  },
  methods: {
    formatMetric (value, metricName) {
      const format = this.metricFormats[metricName]

      return format ? this.$options.filters[format](value) : value
    },
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = this.$el.querySelector('.el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 100)
    },
  }
}
</script>

<style lang="scss">
#strategy-intake-products-modal {
    .total-row_column {
        background-color: #f5f7fa;
    }
}
</style>
