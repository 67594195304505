import {defineStore} from 'pinia'
import {getDatasets, loadFilterProperties} from '@/api/analytics'
import {Dataset} from '@/models/Dataset'

type filterProperty = { [key: string]: string[] }
type datasetsType = { [key: string]: Dataset }
export const usePricingFiltersStore = defineStore('pricingFilters', {
  state: () => ({
    filtersOpened: false,
    filtersApplied: false,
    propertiesWithDataset: {} as {[key: string]: filterProperty},
    optionsSearchVisible: false,
    datasets: null as datasetsType | null,
  }),

  actions: {
    async loadAndGetDatasets (force = false): Promise<datasetsType> {
      if (this.datasets && !force) {
        return this.datasets
      }
      const datasets = await getDatasets()
      if (!datasets) {
        return {}
      }
      this.datasets = datasets
      return datasets
    },
    async loadProperties (dataset?: string) {
      if (!dataset) {
        return
      }
      if (this.propertiesWithDataset[dataset]) {
        return
      }
      const properties = await loadFilterProperties(dataset)
      if (!properties || !dataset) {
        return
      }
      this.propertiesWithDataset = {...this.propertiesWithDataset, [dataset]: properties}
    },

    toggleFilter () {
      this.filtersOpened = !this.filtersOpened
    },

    setOptionsSearchVisible (value: boolean) {
      this.optionsSearchVisible = value
    },

    setFiltersOpened (value: boolean) {
      this.filtersOpened = value
    },

    setFiltersApplied (value: boolean) {
      this.filtersApplied = value
    }
  },
})
