<script lang="ts">
import {defineComponent} from 'vue'
import {mapState} from 'pinia'
import {useGeneralStore} from '@/store/pinia/generalStore'
import {useStockProposalStore} from '@/store/pinia/stockProposalStore'
import columnFilters from '@/mixins/columnFilters'
import {round} from 'lodash'
import TableSearch from '@/components/TableSearch.vue'
import DataExport from '@/components/DataExport.vue'
import {downloadData, hexToRgbA} from '@/utils'

export default defineComponent({
  name: 'DistributionTable',
  components: {DataExport, TableSearch},
  data (){
    return {
      proposalStore: useStockProposalStore(),
      filters: [],
    }
  },
  mixins: [columnFilters],
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    distributionData () {
      const stores = this.appConfig?.stores || []
      const skuNames = this.proposalStore?.sku_names || []
      return this.proposalStore.storeKeys.map((store_key, index) => {
        const store = stores.find(x => x.store_key === store_key)
        const children = this.proposalStore.proposal[index]?.children?.map((item)=> ({...item, isChild: true, store_name: item.Store, store_key: store_key}))
        const skuData = {}
        let fairstock = 0
        let fairshare = 0
        let min = 0
        let max = 0
        skuNames.forEach((name, sku_index) =>{
          const matrices = this.proposalStore.proposalMatrices
          skuData[name] = matrices?.fairstock[index][sku_index]
          skuData[`${name}_extra`] = skuData[name] - matrices.fairshare[index][sku_index]
          fairstock += skuData[name]
          fairshare += matrices?.fairshare[index][sku_index]
          min += Math.min(skuData[name],  matrices?.fairshare[index][sku_index])
          max += Math.max(skuData[name],  matrices?.fairshare[index][sku_index])
        })
        let distribution
        if(!min && !max ){
          distribution = 1
        }else if(!min || !max) {
          distribution = 0
        }else {
          distribution = min / max
        }
        return {
          ...skuData,
          fairstock,
          fairshare,
          children,
          distribution,
          storeKey: store_key,
          store_name: store ? store.store_name : store_key,
        }
      })
    }
  },
  methods: {

    getSummary (params) {
      const sums: (number | string)[] = []
      const { columns } = params
      let distributionIndex = 0
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total'
          return
        }
        sums[index] = round(this.distributionData.reduce((acc, cur) => acc + cur[column.property], 0), 2)
        if(column.property === 'distribution') {
          distributionIndex = index
        }
      })
      const distributionValue = sums[distributionIndex] / this.distributionData.length
      sums[distributionIndex] = (distributionValue * 100).toFixed(0) + '%'

      return sums
    },
    getColorCoding (value: number, isChild:boolean){
      if (value === 0 || isChild) {
        return 'transparent'
      }
      if (value < 0) {
        return 'rgba(238, 55, 102, 0.3)'
      }
      return hexToRgbA('#FF9800', 0.3)
    },

    handleDownloadClick (sep: string) {
      downloadData(sep, this.distributionData, this.$route.query.option_key + ' Distribution')
    },
  }
})
</script>

<template>

  <v-col class="ma-0 distribution-table pa-0">
    <v-row class="my-0">
      <v-col class="text-right pr-8">
        <TableSearch class="mx-2"
          @searchChanged="val => handleSearchChanged('filters', [...proposalStore?.sku_names, 'fairshare', 'fairstock', 'store_name'], val)" />
        <DataExport
          @item-click="handleDownloadClick"
        >
        </DataExport>
      </v-col>
    </v-row>
    <data-tables
      v-if="distributionData && distributionData.length"
      :data="distributionData"
      @filter-change="handleFilterChange('filters')"
      :filters="filters"
      :page-size="15"
      filter-multiple
      :table-props="{
        'summary-method': getSummary,
        'show-summary': true,
        'tree-props': {
          children: 'children',
        },
        'row-key' : (row)=> row['store_name'] + row['store_key'],
        'cell-class-name': 'colored-td',
      }"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 pb-4 mr-2'
      }">

      <el-table-column
        column-key="store_name"
        prop="store_name"
        label="Store"
        key="store_name"
        align="left"
        width="200px"
        sortable="custom"
        label-class-name="pa-0"
        class-name="px-6 p-relative store-name-td"
      >
        <template v-slot="scope">
          <div class="store-name-cell">
            {{scope.row.store_name}}
          </div>
          <v-icon
            v-if="!scope.row.isChild"
            :size="21"
            class="store-info-icon ml-2"
            @click="$emit('store-clicked', scope.row)"
          >info</v-icon>
        </template>
      </el-table-column>
      <el-table-column
        :column-key="col"
        label-class-name="text-center"
        v-for="col of proposalStore?.sku_names" :prop="col" :label="col" :key="col"
        sortable="custom">
        <template v-slot="scope">
          <div
            class="d-flex align-center justify-center colored-area"
            :style="{
              'background-color': getColorCoding(scope.row[`${col}_extra`],scope.row.isChild),
              padding: '12px',
              'font-size': '14px'
            }">
            {{scope.row[col]}}

            <span class="ml-1" v-if="scope.row[`${col}_extra`]"> (<span>{{ scope.row[`${col}_extra`] > 0 ? '+' : '' }}</span>{{scope.row[`${col}_extra`]}})</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        column-key="fairstock"
        prop="fairstock"
        sortable="custom"
        class-name="text-center"
        label="Fairstock"
        key="fairstock"
      >
        <template v-slot="scope">
          {{scope.row.fairstock}}
        </template>
      </el-table-column>

      <el-table-column
        column-key="fairshare"
        prop="fairshare"
        label="Fairshare"
        class-name="text-center"
        sortable="custom"
        key="fairshare"
      >
        <template v-slot="scope">
          {{scope.row.fairshare}}
        </template>
      </el-table-column>
      <el-table-column
        column-key="distribution"
        prop="distribution"
        label="Distribution"
        sortable="custom"
        class-name="text-center"
        key="distribution"
      >
        <template v-slot="scope" >
          <span v-if="!scope.row.isChild">{{(scope.row.distribution * 100 ).toFixed(0)}}%</span>
        </template>
      </el-table-column>
    </data-tables>
  </v-col>
</template>

<style lang="scss">
.distribution-table {
  .store-info-icon {
    position: absolute;
    top: 12px;
    right: 24px;
    cursor: pointer;
  }

  .store-name-td {
    .cell {
      display: inline-flex;
    }
  }

  .store-name-cell {
    max-width: 110px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .el-table__row--level-1 {
    background-color: #fafafa !important;
  }
  .el-table td {
    padding: 0;
    height: 45px;
    border-bottom: none;
    .cell {
      padding: 0;
    }
  }
}
</style>
