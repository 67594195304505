<template>
  <div class="operate-items-operations" id="operate-menu">
    <div class="d-flex operations-selector mx-3 mb-6">
      <div class="d-flex flex-grow-1 justify-center align-center rounded p-relative rounded elevation-2 text-button text-uppercase operation-option mx-3" :class="{ active: selectedTab === 0 }" @click="handleOperationClick(0)">
        Search
      </div>
      <div class="d-flex flex-grow-1 justify-center align-center rounded p-relative rounded elevation-2 text-button text-uppercase operation-option mr-3" :class="{ active: selectedTab === 1 }" @click="handleOperationClick(1)">
        Batch Settings
      </div>
    </div>
    <template v-if="selectedTab === 0">
      <v-container class="mx-6">
        <v-row class="bordered-list-header">
          <v-col cols="3" class="pl-1">Search</v-col>
        </v-row>
        <v-row class="bordered-list-row align-baseline py-2">
          <div class="d-flex">
            <v-autocomplete
              v-model="chosenColumns"
              label="Column Name"
              clearable
              class="pt-0 mt-0"
              item-text="name"
              dense
              item-value="value"
              :items="formatKeys(columns)"
            ></v-autocomplete>
            <v-icon class="d-flex pt-1 px-4">arrow_right_alt</v-icon>
            <v-autocomplete
              v-model="chosenOptions"
              label="Map to"
              clearable
              class="pt-0 mt-0"
              dense
              item-text="name"
              item-value="value"
              :items="formatKeys(options_keys)"
            />
          </div>
          <div class="ml-4">
            <v-checkbox
              v-model="inverseSearch"
              label="Inverse search"
              class="mt-2"
            ></v-checkbox>
          </div>
        </v-row>
        <v-row class="my-6">
          <v-btn
            color="primary"
            depressed
            outlined
            :disabled="fileSearchDisabled"
            @click="handleFileSearchClick"
          >Search</v-btn>
        </v-row>
      </v-container>
    </template>
    <template v-else-if="selectedTab === 1">
      <v-container class="mx-6">
        <v-row class="bordered-list-header">
          <v-col cols="3" class="pl-1">Operation</v-col>
          <v-col cols="3">Example file</v-col>
          <v-col cols="3">Columns needed</v-col>
          <v-col cols="3">Error</v-col>
        </v-row>
        <v-row class="bordered-list-row align-baseline py-2">
          <v-col cols="3" class="pb-0 pl-1">
            <v-autocomplete
              v-model="selectedOperation"
              label="Operation"
              clearable
              class="pt-0 mt-0"
              item-text="name"
              return-object
              dense
              :items="operationsList"
              @change="handleSelectedOperationChange"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3" class="pb-0">
            <span>
              <v-btn
                color="primary"
                icon
                @click="downloadExampleFile"
              >
                <v-icon>download</v-icon>
              </v-btn>
            </span>
          </v-col>
          <v-col cols="3" class="pb-0">
            <!--            List of columns from selected operation, comma separated-->
            <span>
              {{ selectedOperation?.variables.join(', ') }}
            </span>
          </v-col>
          <v-col cols="3" class="pb-0">
            <span v-if="columnErrors.length">
              {{ columnErrors.join('\n') }}
            </span>
            <span v-else>
              No errors
            </span>
          </v-col>
          <v-col cols="3" class="pb-0 pl-0">
            <span v-if="!columnErrors.length">
              <v-btn
                @click="handleApplyClick"
                color="primary"
                depressed
                outlined>
                Apply
              </v-btn>
            </span><br>
            <span>{{applyMessage}}</span>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <confirm-dialog
      :isOpen="applyConfirmDialog"
      title="Apply operation"
      :text="`Are you sure you want to ${selectedOperation?.name} for ${parsedResults.length} combinations?`"
      @confirm="handleApplyConfirm"
      @cancel="applyConfirmDialog = false"
    />
  </div>
</template>
<script>
import { formatString } from '@/variables'
import { useStockOperateStore } from '@/store/pinia/stockOperateStore'
import { getPropertiesAndValues } from '@/api/options'
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog.vue'
import { applyImportBatchOperation } from '@/api/stockOperateAPI'

export default {
  name: 'OptionsSearchOperations',
  emits: ['search-selected'],
  components: { ConfirmDialog },
  setup () {
    return {
      operateStore: useStockOperateStore()
    }
  },
  data () {
    return {
      chosenColumns: '',
      chosenOptions: '',
      applyMessage: '',
      selectedTab: 0,
      selectedOperation: null,
      applyConfirmDialog: false,
      columnErrors: [],
      options_keys: [],
      inverseSearch: false
    }
  },
  mounted () {
    getPropertiesAndValues('skus_all_keys', 'aod')
      .then(r => {
        this.options_keys = ['option_key', ...r.mappings]
      })
  },
  computed:{
    operationsList () {
      return this.operateStore.operationsImportList
        .map(o => ({ name: formatString(o.operation), ...o }))
    },
    parsedResults: {
      get () {
        return this.operateStore.fileTab.parsedResults
      },
      set (value) {
        this.operateStore.setFileTabParsedResults(value)
      }
    },
    columns () {
      return this.operateStore.getFileTabParsedResultsColumns
    },
    fileSearchDisabled () {
      return !(this.operateStore.fileTab.selectedOptionsKey.length && this.chosenColumns && this.chosenOptions)
    },
  },
  methods: {
    downloadExampleFile () {
      const link = document.createElement('a')
      const filename = 'operate_import_example.csv'
      link.setAttribute('href', location.origin + '/' + filename)
      link.setAttribute('download', filename)
      link.click()
    },
    handleApplyClick () {
      this.applyConfirmDialog = true
    },
    async handleApplyConfirm () {
      const results = this.parsedResults.map(i => {
        return this.selectedOperation.variables.reduce((acc, column) => {
          acc[column] = i[column]
          return acc
        }, {})
      }) // Filter out irrelevant columns
      try {
        this.applyMessage = await applyImportBatchOperation({
          operation: this.selectedOperation.operation,
          parameters: {},
          file: results.map((item) => {
            return this.selectedOperation.variables.map((variable) => item[variable])
          }),
          columns: this.selectedOperation.variables,
        })
      } catch (e) {
        console.error(e)
      }
      this.applyConfirmDialog = false
    },
    handleFileSearchClick () {
      const values = this.operateStore.fileTab.selectedOptionsKey.map((item) => item[this.chosenColumns])
      const payload = {
        inverse_search: this.inverseSearch,
      }
      if (this.chosenOptions === 'option_key') {
        if (!this.inverseSearch) {
          payload.option_keys_include = values
        } else {
          payload.option_keys_exclude = values
        }
      } else {
        if (!this.inverseSearch) {
          payload.properties_include = {
            [this.chosenOptions]: values
          }
        } else {
          payload.properties_exclude = {
            [this.chosenOptions]: values
          }
        }
      }
      this.operateStore.findOptionsByOptionsKey(payload)
    },
    handleSelectedOperationChange () {
      this.columnErrors = []
      this.applyMessage = ''
      if (this.selectedOperation) {
        this.columnErrors = this.operateStore.validateImportDataColumns(this.selectedOperation.variables)
      }
    },
    handleOperationClick (index) {
      this.selectedTab = index
      this.$emit('search-selected', index === 0)
    },
    formatKeys (keys) {
      return keys.map((key) => {
        return {
          name: formatString(key),
          value: key
        }
      })
    },
  }
}
</script>
