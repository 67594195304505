<template>
  <div class="buy-studio-item-page p-relative pb-8">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <div class="mb-6 mt-6 title-div">
      <h3>
        <span class="mr-1 text-capitalize route-name" @click="$router.push({ path: '/buy/studio' })">
          Studio
        </span>
        /
        <span class="ml-1">Studio item</span>
      </h3>
    </div>
    <v-row>
      <v-col :cols="12">
        <DummyVariantForm
          v-if="currentVariant && dummyProperties"
          ref="dummyVariantForm"
          :dummiesForVariant="dummiesForVariant"
          :dummyProperties="dummyProperties"
          :selectedDummyKey="selectedDummyKey"
          :variant="currentVariant"
          :variant-object-properties="variantObjectProperties"
          :expandable="variantFormExpandable"
          :variantPropertiesForDummies="variantProperties"
          @update-dummy="updateDummy"
          @properties-changed="updateVariantInfo"
          @selection-changed="handleDummySelectionChanged"
          @dummy-added="redirectToDummyPage"
          @dummy-duplicated="redirectToDummyPage"
          @dummy-export-changed="handleVariantDummiesChanged"
          @batch-upload-done="handleVariantDummiesChanged"
          @dummy-deleted="handleVariantDummiesChanged"
          @properties-validated="handlePropertiesValidated"
        />
      </v-col>
    </v-row>

    <template v-if="currentDummy">
      <v-card class="mt-6" :key="currentDummyKey">
        <v-row>
          <v-col :cols="4" class="pr-0">
            <DummyInfo
              v-if="properties && currentDummy"
              :key="currentDummyKey+1"
              :dummy="currentDummy"
              :managed-properties-valid="managedPropertiesValid"
              :variant-properties-valid="variantPropertiesValid"
              @export-changed="handleDummyExportChanged"
            />
          </v-col>
          <v-col :cols="8" class="pl-0">
            <DummyInputForm
              :dummy="currentDummy"
              :variants="variantsList"
              @dummy-changed="updateDummyInfo"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-tabs v-model="activeTab" show-arrows>
            <v-tabs-slider />
            <v-tab class="px-8" style="width: 220px">Properties</v-tab>
            <v-tab v-if="false" class="px-8" style="width: 220px">ML</v-tab>
            <v-tab class="px-8" style="width: 220px">Lookalikes</v-tab>
            <v-tab class="px-8" style="width: 220px">Price</v-tab>
            <v-tab class="px-8" style="width: 220px">Skus</v-tab>
          </v-tabs>

          <v-tabs-items v-model="activeTab" style="width: 100%">
            <v-tab-item :transition="false" :reverse-transition="false">
              <DummyProperties
                ref="dummyProperties"
                v-if="properties && currentDummy"
                :shap-properties="properties"
                :dummy="currentDummy"
                :variant="currentVariant"
                @properties-changed="updateDummyInfo"
                @properties-validated="handlePropertiesValidated"
              />
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false" v-if="false">
              <DummyML
                v-if="properties"
                :dummy="currentDummy"
                :properties="properties"
                @properties-changed="updateDummyInfo"
                @ros-changed="val => ros = val"
              />
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false">
              <DummyLookalikes
                :dummy="currentDummy"
                :properties="properties"
                @options-added="updateDummyInfo"
                @options-deleted="updateDummyInfo"
              />
            </v-tab-item>

            <v-tab-item :transition="false" :reverse-transition="false">
              <DummyPrice
                :dummy="currentDummy"
                :ros="ros"
                @dummy-saved="updateDummyInfo"
              />
            </v-tab-item>

            <v-tab-item :transition="false" :reverse-transition="false">
              <DummySkus
                :dummy="currentDummy"
                :skus="skus"
                @dummy-saved="updateDummyInfo"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-card>
    </template>
  </div>
</template>

<script>

import * as dummiesApi from '@/api/dummies'
import { loadDummiesList } from '@/api/dummies'
import { getObjectValues } from '@/api/dataProperties'
import DummyVariantForm from '@/components/Dummies/DummyVariantForm'
import DummyInfo from '@/components/Dummies/DummyInfo.vue'
import DummyInputForm from '@/components/Dummies/DummyInputForm.vue'
import DummyML from '@/components/Dummies/DummyML.vue'
import DummyProperties from '@/components/Dummies/DummyProperties.vue'
import DummyLookalikes from '@/components/Dummies/DummyLookalikes.vue'
import DummyPrice from '@/components/Dummies/DummyPrice.vue'
import DummySkus from '@/components/Dummies/DummySkus.vue'
import { getSKUNames, getSKUProperties } from '@/api/skusAPI'

export default {
  mixins: [],
  components: {
    DummyVariantForm,
    DummyInfo,
    DummyInputForm,
    DummyProperties,
    DummyML,
    DummyLookalikes,
    DummyPrice,
    DummySkus,
  },
  data () {
    return {
      loading: false,
      currentDummyKey: 0,
      dummiesForVariant: [],
      variantProperties: [],
      dummyProperties: [],
      currentVariant: null,
      currentDummy: null,
      activeTab: 0,
      properties: null,
      skus: null,
      dummyObjectProperties: [],
      variantObjectProperties: [],
      ros: null,
      managedPropertiesValid: false,
      variantPropertiesValid: false,
      variantsList: [],
      selectedDummyKey: null,
      variantFormExpandable: false,
    }
  },
  watch: {
    currentDummy: {
      async handler (value, oldValue) {
        if (!value || !oldValue || (value.variant_key === oldValue.variant_key && value.dummy_key === oldValue.dummy_key)) {
          return
        }
        await this.$router.replace({ query: { variant_key: value.variant_key, dummy_key: value.dummy_key } })
      },
      immediate: true
    },
    activeTab () {
      this.$router.replace({ ...this.$route, query: { ...this.$route.query, propTab: this.activeTab } })
    },
    $route: {
      handler () {
        this.activeTab = parseInt(this.$route.query?.propTab || 0)
      },
      immediate: true
    }
  },
  methods: {
    async updateDummy (payload) {
      await this.handleListChange(payload)
      this.needToUpdate = true
      await this.loadPage()
      const routeDummyKey = parseInt(this.$route.query.dummy_key)
      if (routeDummyKey) {
        this.$emit('update-dummy', payload)
      }
    },

    handleListChange (payload) {
      return loadDummiesList(payload).then(({ dummies }) => this.loadDummiesAfterRefresh(payload, dummies))
    },
    loadDummiesAfterRefresh (payload, data) {
      const changedDummies = this.dummiesForVariant.filter((dummy) => payload.dummy_keys.includes(dummy.dummy_key))
      for (const dummies of changedDummies) {
        const dummy = data.find(item => item.dummy_key === dummies.dummy_key)
        this.$set(dummies, 'status', dummy.status)
      }
    },
    async loadDummyInfo (dummyKey, update = true) {
      const response = await dummiesApi.loadDummy(dummyKey)
      if (!response) {
        return
      }
      this.currentDummy = {
        ...response.dummy,
        in_use: response.in_use,
      }

      if (update) {
        await this.updateDummyForcedProperties()
      }

      if (this.currentDummy.variant_key) {
        if (this.needToUpdate || !this.currentVariant ||
          this.currentVariant.variant_key !== this.currentDummy.variant_key) {
          return this
            .loadVariantInfo(this.currentDummy.variant_key)
            .then(this.loadVariantDummies)
        }
      } else {
        this.currentVariant = null
        this.dummiesForVariant = []
      }
    },
    loadProperties () {
      this.properties = getSKUProperties()
      this.skus = getSKUNames()
    },
    handlePropertiesValidated () {
      this.managedPropertiesValid = this.checkManagedPropertiesValid()
      this.variantPropertiesValid = this.checkVariantPropertiesValid()
      for (const key in this.currentDummy.properties) {
        if (this.currentDummy.properties[key] === '') {
          this.currentDummy.properties[key] = null
        }
      }
      this.dummiesForVariant = [...this.dummiesForVariant]
    },
    loadDummyObjectProperties () {
      return getObjectValues('dummies')
        .then(response => {
          this.dummyObjectProperties = response.data
        })
    },
    loadVariants () {
      this.loading = this.needLoader

      return dummiesApi
        .getAllVariants()
        .then(variants => {
          this.variantsList = variants
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadVariantInfo (variantKey) {
      return dummiesApi
        .getVariant(variantKey)
        .then(variant => {
          this.currentVariant = variant
          this.updateVariantForcedProperties()
          this.updateVariantValidations()
        })
    },
    loadVariantDummies () {
      const payload = {
        variant_key: this.currentVariant.variant_key
      }

      return loadDummiesList(payload)
        .then(response => {
          this.needToUpdate = false
          this.dummiesForVariant = response.dummies
          this.dummyProperties = response.properties_dummy
          this.variantProperties = response.properties_variant
        })
    },
    loadVariantObjectProperties () {
      return getObjectValues('variants')
        .then(response => {
          this.variantObjectProperties = response.data
        })
    },
    updateDummyInfo (updatedDummy) {
      this.currentDummy = updatedDummy
      this.loadVariantDummies()
    },
    async updateVariantInfo (updatedVariant) {
      const routeDummyKey = parseInt(this.$route.query.dummy_key)

      await Promise.all([
        this.loadDummyObjectProperties(),
        this.loadVariantObjectProperties(),
      ])

      if (routeDummyKey) {
        await this.loadDummyInfo(routeDummyKey)
      }
      this.currentVariant = updatedVariant
      this.currentDummyKey++
      this.updateVariantValidations()
      this.loadVariantDummies()
    },
    updateVariantValidations () {
      this.checkVariantPropertiesValid()
      this.handlePropertiesValidated()
    },
    defaultValueTyper (prop) {
      if (prop.value_type !== 'boolean') {
        return prop.value_default
      }
      return prop.value_default === 'True' || prop.value_default === true
    },
    getDummyForcedProperties () {
      return this.dummyObjectProperties
        .filter(prop => prop.force)
        .reduce((acc, cur) => ({
          ...acc,
          [cur.property_name]: this.defaultValueTyper(cur),
        }), {})
    },
    updateDummyForcedProperties () {
      this.currentDummy.properties = {
        ...this.getDummyForcedProperties(),
        ...this.currentDummy.properties
      }

      for (const key in this.currentDummy.properties) {
        if (this.currentDummy.properties[key] === '') {
          this.currentDummy.properties[key] = null
        }
      }
      return dummiesApi.saveDummy(this.currentDummy)
    },
    getVariantForcedProperties () {
      return this.variantObjectProperties
        .filter(prop => prop.force)
        .reduce((acc, cur) => ({
          ...acc,
          [cur.property_name]: this.defaultValueTyper(cur),
        }), {})
    },
    updateVariantForcedProperties () {
      this.currentVariant.properties = {
        ...this.getVariantForcedProperties(),
        ...this.currentVariant.properties
      }

      return dummiesApi.saveVariant(this.currentVariant)
    },
    handleVariantDummiesChanged () {
      this.loading = this.needLoader
      this
        .loadVariantDummies()
        .then(() => {
          let route

          if (this.dummiesForVariant.length) {
            this.selectedDummyKey = this.dummiesForVariant[0].dummy_key

            route = {
              name: 'buy-studio-item-page',
              query: { dummy_key: this.selectedDummyKey }
            }
          } else {
            route = {
              name: 'buy-studio-item-page',
              query: { variant_key: this.currentVariant.variant_key }
            }
          }

          this.$router.push(route)
          this.$router.go()
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleDummySelectionChanged (dummy) {
      if (!dummy) {
        this.currentDummy = null
        return
      }
      this.loading = this.needLoader
      this.loadDummyInfo(dummy.dummy_key, false)
        .finally(() => {
          this.loading = false
        })
    },
    handleDummyExportChanged (val) {
      this.dummiesForVariant = this.dummiesForVariant.map(d => ({
        ...d,
        export: d.dummy_key === this.currentDummy.dummy_key ? val : d.export,
      }))
    },
    checkManagedPropertiesValid () {
      if (!this.loading) {
        return this.$refs.dummyProperties.managedProperties.every(prop => {
          const el = this.$refs.dummyProperties.$refs[prop.property_name][0]
          return !el.errorMessages || !el.errorMessages.length
        })
      }

      return true
    },
    checkVariantPropertiesValid () {
      if (!this.$refs.dummyVariantForm) {
        return false
      }
      if(this.loading){
        return false
      }
      return this.$refs.dummyVariantForm.variantProperties.every(prop => {
        return this.$refs.dummyVariantForm.validatePropValue(prop)
      })

    },
    getDummyPageUrl (dummyKey) {
      return {
        name: 'buy-studio-item-page',
        query: { dummy_key: dummyKey }
      }
    },
    redirectToDummyPage (dummyKey) {
      this.$router.push(this.getDummyPageUrl(dummyKey))
      this.$router.go()
    },
    async loadPage (needLoader = true) {
      const routeDummyKey = parseInt(this.$route.query.dummy_key)
      let currentVariantKey = null
      this.needLoader = needLoader
      this.loading = this.needLoader

      await Promise.all([
        this.loadDummyObjectProperties(),
        this.loadVariantObjectProperties(),
      ])

      if (this.$route.query.variant_key) {
        currentVariantKey = this.$route.query.variant_key
      }

      if (routeDummyKey) {
        await this.loadDummyInfo(routeDummyKey, false)

        if (this.currentDummy.variant_key) {
          currentVariantKey = this.currentDummy.variant_key
        }
      }
      if (currentVariantKey) {
        if (!routeDummyKey) {
          await this.loadVariantInfo(currentVariantKey)
          await this.loadVariantDummies()
        }
        if (this.dummiesForVariant.length) {
          this.selectedDummyKey = routeDummyKey || this.dummiesForVariant[0].dummy_key
        }

        this.$nextTick(() => {
          if (this.$refs.dummyVariantForm && this.$refs.variantDummiesTable && this.$refs.dummyVariantForm.$el.clientHeight > this.$refs.variantDummiesTable.$el.clientHeight) {
            this.variantFormExpandable = true
            this.$refs.dummyVariantForm?.setExpanded(false)
          }
        })
      }

      await Promise.all([
        this.loadProperties(),
        this.loadVariants(),
      ])

      this.loading = false
      this.needLoader = true
    },
  },
  async created () {
    await this.loadPage()
  },
}
</script>

<style lang="scss">
.buy-studio-item-page {
    .title-div {
        display: flex;
        align-items: center;
        grid-row: 1/2;
        grid-column: 1/4;
        .route-name {
            cursor: pointer;
            color: var(--text-sub-black);
        }
    }
    .v-tab {
        width: 267px;
    }
}
</style>
