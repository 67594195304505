<template>
  <v-card class="buy-manage-beliefs-modal ma-0 ma-0 pa-0">
    <v-card-title class="text-body-1 font-weight-medium py-6 justify-space-between px-12">
      Manage Dashboards
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text--primary  my-0 pa-0">
      <v-row class="my-2 mx-0">
        <v-col class="text-right">
          <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
          <v-icon
            class="mx-2 action-btn-primary"
            :disabled="!selectedRow"
            @click="duplicateDashboard"
          >file_copy</v-icon>
          <v-icon
            class="mx-2 action-btn-primary"
            @click="addDashboard"
          >add</v-icon>
          <v-icon
            class="mx-2 action-btn-danger"
            :disabled="!selectedRow"
            @click="deleteDialogVisible = true"
          > delete</v-icon>
        </v-col>

      </v-row>
      <data-tables
        ref="transfersTable"
        :key="dashboardsKey"
        :data="dashboards"
        @filter-change="val => handleFilterChange('filters', val)"
        :filters="filters"
        :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
        :page-size="10"
        @row-click="handleRowClick"
      >
        <el-table-column prop="option_key" width="24" class-name="text-center">
        </el-table-column>
        <el-table-column
          prop="option_key"
          width="55"
          class-name="text-center"
        >
          <template slot-scope="scope">
            <v-simple-checkbox
              color="primary"
              dense
              hide-details
              class="ml-3"
              :value="selectedRow && scope.row.dashboard_key === selectedRow.dashboard_key"
              :ripple="false"
              @click.stop="()=> handleRowClick(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="Name"
          prop="dashboard_name"
          column-key="dashboard_name"
          :filters="getColumnFilterValues('dashboards', 'dashboard_name')"
          sortable
          key="dashboard_name">
          <template class="my-2" slot-scope="scope">
            <template v-if="scope.row.user_name === 'System'">
              {{scope.row.dashboard_name}}
            </template>
            <v-text-field
              v-else
              :value="scope.row.dashboard_name"
              @change="(value)=> changeName(scope.row,value)"
              @click.stop
            ></v-text-field>
          </template>
        </el-table-column>
        <el-table-column
          label="Owner"
          prop="user_name"
          sortable
          column-key="user_name"
          :filters="getColumnFilterValues('dashboards', 'user_name')"
          key="user_name">
          <template class="my-2"  slot-scope="scope">
            {{scope.row.user_name}}
          </template>
        </el-table-column>
        <el-table-column
          label="Created"
          prop="creation_date"
          sortable
          :filters="getColumnFilterValues('dashboards', 'creation_date')"
          column-key="creation_date"
          key="creation_date">
          <template class="my-2"  slot-scope="scope">
            {{scope.row.creation_date}}
          </template>
        </el-table-column>
        <el-table-column
          label="View names"
          prop="view_names"
          sortable
          column-key="view_names"
          :filters="getColumnFilterValues('dashboards', 'view_names')"
          key="view_names">
          <template class="my-2"  slot-scope="scope">
            <chips-dropdown
              :items="[...scope.row.view_names].sort()"
            ></chips-dropdown>
          </template>
        </el-table-column>
        <el-table-column
          label="Shared"
          prop="shared"
          sortable
          column-key="shared"
          :filters="getColumnFilterValues('dashboards', 'shared')"
          key="shared">
          <template class="my-2"  slot-scope="scope">
            <v-switch
              :input-value="scope.row.shared"
              :disabled="scope.row.user_name === 'System'"
              @change="(value)=> changeShared(scope.row,value)"
              @click.stop
            ></v-switch>
          </template>
        </el-table-column>
      </data-tables>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row class="dialog-footer text-right py-2 px-4">
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                v-if="!!selectedRow"
                color="primary"
                depressed
                text
                @click="$emit('open-dashboard', selectedRow.dashboard_key)"
              >Open Dashboard</v-btn>
              <v-btn
                v-else
                color="primary"
                depressed
                text
                @click="$emit('close')"
              >Close</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-dialog
      v-model="deleteDialogVisible"
      content-class="align-self-start mt-10"
      width="600px"
      max-width="90%">
      <v-card class="p-relative rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Delete dashboard
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Do you want to delete this dashboard?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="deleteDashboard"
                class="red--text mx-4"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { duplicateDashboard, getDashboardManage, deleteDashboard, setDashboardName } from '../../api/analytics'
import ChipsDropdown from '../ChipsDropdown'
import TableSearch from '../TableSearch'
import columnFilters from '../../mixins/columnFilters'

export default {
  name: 'ManageDashboardsModal',
  mixins: [columnFilters],
  components: { ChipsDropdown, TableSearch },
  data () {
    return {
      selectedRow: null,
      deleteDialogVisible: false,
      dashboardsKey: 0,
      dashboards: [],
      filters: [
        {
          value: '',
        },
      ],
    }
  },
  methods: {
    loadDashboard () {
      getDashboardManage().then((dashboards) => {
        this.dashboards = dashboards
          .filter((item, index, self) => {
            const innerIndex = self.findIndex((dashboard) => dashboard.dashboard_key === item.dashboard_key)
            return index === innerIndex
          })
      })
    },
    handleRowClick (row) {
      if ((this.selectedRow || {}).dashboard_key === row.dashboard_key) {
        this.selectedRow = null
      } else {
        this.selectedRow = row
      }
    },
    handleSearchChanged (val) {
      this.filters[0].value = val
      this.dashboardsKey++
    },
    duplicateDashboard () {
      duplicateDashboard({ dashboard_key: this.selectedRow.dashboard_key }).then(this.loadDashboard)
    },
    deleteDashboard () {
      this.deleteDialogVisible = false
      deleteDashboard({ dashboard_key: this.selectedRow.dashboard_key }).then(this.loadDashboard)
    },
    changeName (dashboard, value) {
      const changeDashboard = { ...dashboard, dashboard_name: value }
      setDashboardName(changeDashboard).then(this.loadDashboard)
    },
    changeShared (dashboard, value) {
      const changeDashboard = { ...dashboard, shared: value }
      setDashboardName(changeDashboard).then(this.loadDashboard)
    },
    addDashboard () {
      const changeDashboard = { ...this.dashboards[0] }
      delete changeDashboard.dashboard_name
      delete changeDashboard.dashboard_key
      delete changeDashboard.shared
      changeDashboard.view_keys = []
      changeDashboard.view_names = []
      setDashboardName(changeDashboard).then(this.loadDashboard)
    },
  },
  mounted () {
    this.loadDashboard()
  }
}
</script>

<style scoped>

</style>
