<template>
  <v-dialog
    :value="isOpen"
    scrollable
    persistent
    content-class="align-self-start mt-14"
    min-width="600px"
    max-width="90%"
  >
    <v-card>
      <v-card-title class="text-body-1 font-weight-medium mx-1 my-2 justify-space-between">
        <span>{{title}}</span>
        <v-icon class="mx-3 action-btn-primary c-pointer" @click="$emit('close')">close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="py-5 px-5 text--primary">
        <div class="p-relative">
          <v-row justify="end">
            <v-col cols="1" class="text-right mr-2">
              <TableSearch
                class="pb-2 mr-2"
                @searchChanged="handleSearchChanged"
              />
              <v-menu :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon v-bind="attrs" v-on="on" class="mx-0">download</v-icon>
                  </v-btn>
                </template>
                <v-list dense class="p-relative">
                  <v-list-item-group>
                    <v-list-item @click.native="download(',')">
                      <v-list-item-title>CSV (comma)</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click.native="download(';')">
                      <v-list-item-title>CSV (semicolon)</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click.native="download('dutch')">
                      <v-list-item-title>CSV (semicolon, decimal comma)</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <data-tables
            class="shopItemCommitTilesModal"
            ref="resultsTable"
            style="width: 100%"
            :data="rows"
            :page-size="5"
            :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
            :table-props="{ 'row-key': 'option_key'}"
            :filters="tableFilters">
            <el-table-column
              v-for="(column, index) in data.columns"
              v-bind:key="index"
              :label-class-name="index && 'text-center'"
              :prop="column" :label="column" sortable="custom">
              <template v-if="column === 'Store'" v-slot="scope">
                {{ storesMap[scope.row[column]] }}
              </template>
              <template v-else v-slot="scope">
                <div
                  class="d-flex align-center justify-center colored-area"
                  :style="{
                    color: getCellColor(scope.row[column]),
                  }">{{ scope.row[column].toFixed((isStandard ? 0: 2)) }}
                  {{isStandard ? '%' : ''}}
                </div>
              </template>
            </el-table-column>
            <div slot="append" class="d-flex justify-space-between">
              <data-tables
                class="shopItemCommitTilesModal totals_row"
                ref="totalsTable"
                style="width: 100%"
                :data="[data.total]"
                :page-size="1"
                :pagination-props="{ class: 'd-none' }">
                <el-table-column
                  v-for="(column) in data.columns"
                  :key="column"
                  label-class-name="d-none"
                  :prop="column">
                  <template v-slot="scope">
                    <template v-if="column === 'Store'">
                      <div
                        class="d-flex align-center colored-area"
                        :style="{
                          padding: '13px 0',
                          borderRadius: '4px',
                        }">
                        Total
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="d-flex align-center justify-center colored-area"
                      >{{ round(scope.row[column] * (isStandard ? 100 : 1), (isStandard ? 0: 2)) }}
                        {{isStandard ? '%' : ''}}
                      </div>
                    </template>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </data-tables>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2">
          <v-col>
            <v-btn
              color="primary"
              depressed
              text
              @click="$emit('close')"
            >Close</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TableSearch from '@/components/TableSearch.vue'
import { downloadData } from '@/utils'
import _ from 'lodash'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

import interpolate from 'color-interpolate'

const grad = interpolate(['#16a61c', '#acb66b', '#b74f4f', '#bd0909'])

export default {
  name: 'ShopReturnsPerStoreModal',
  components: {
    TableSearch
  },
  data () {
    return {
      returnsPerStore: [],
      tableSearch: '',
      tableFilters: [{ value: '' }],
      round: _.round,
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    data: {
      type: Object,
      default: () => ({
        columns: [],
        data: [],
      })
    },
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    isStandard () {
      return this.title.includes('Return')
    },
    rows () {
      const data = JSON.parse(JSON.stringify(this.data))
      return data.data.filter(row => {
        return this.filteredStoreKeys.includes(row.Store)
      }).map(row => {
        for (let i = 0; i < data.columns.length; i++) {
          const column = data.columns[i]
          if (column !== 'Store') {
            row[column] = parseFloat(row[column] * (this.isStandard ? 100 : 1))
          }
        }
        return {
          ...row,
          'Store': row.Store,
        }
      })
    },
    filteredStoreKeys () {
      const toSearch = this.tableSearch.toLowerCase()
      let filtered = this.stores
      if (toSearch) {
        filtered = this.stores.filter(store => {
          return store.store_name.toLowerCase().includes(toSearch)
        })
      }
      return filtered.map(store => store.store_key)
    },
    storesMap () {
      return this.stores.reduce((acc, store) => {
        acc[store.store_key] = store.store_name
        return acc
      }, {})
    },
    stores () {
      return this.appConfig?.stores || []
    },
    averageRow () {
      const total = {}
      this.rows.forEach(row => {
        this.data.columns.forEach((column, index) => {
          if (index < 1) {
            total[column] = 'Total'
            return
          }
          if (!total[column]) {
            total[column] = 0
          }
          total[column] += row[column]
        })
      })
      return total
    }
  },
  methods: {
    handleSearchChanged (search) {
      this.tableSearch = search
    },
    download (delimiter) {
      downloadData(delimiter, this.rows, 'returns-per-store')
    },
    getCellColor (value) {
      value = value / 100
      return grad(value)
    }
  }
}
</script>

<style lang="scss">

.shopItemCommitTilesModal {
  .el-table td {
    height: 45px;
    padding: 0;
  }
  .el-table tr {
    height: 45px;
    padding: 0;
  }
  .total-td {
    border-top: 1px solid #ebeef5;
    color: #909399;
    font-weight: 500;
  }
  .el-table td.base-td {
    border-bottom: 1px solid #ebeef5;
    color: #909399;
    font-weight: 500;
  }
  .colored-td {
    .cell {
      margin:1px;
      height: 100%;
      .colored-area {
        height: 100%;
      }
    }
  }
}

.shopItemCommitTilesModal.totals_row .el-table__header-wrapper {
    display: none;
}
</style>
