<template>
  <div class="stock-overview-page p-relative mb-12">
    <v-overlay absolute color="white" :value="!filtersDataLoaded">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <h3 class="mb-6 mt-6">Overview</h3>

    <v-card class="pt-4" min-height="350px">
      <v-row>
        <v-col>
          <h6 class="text-subtitle-1 font-weight-medium ml-6">Filters</h6>
        </v-col>
        <v-col class="text-right">
          <v-btn
            :to="{ name: 'stock-analytics'}"
            class="mr-6"
            color="primary"
            depressed
            outlined
          >New analytics</v-btn>
        </v-col>
      </v-row>
      <div style="width: 250px;">
        <v-row class="pt-2">
          <v-col class="ml-6">
            <v-select
              v-model="selectedDimension1"
              label="Dimension 1"
              :items="dimensionsList"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 ml-6">
            <v-select
              v-model="selectedDimension2"
              label="Dimension 2"
              :items="dimensionsList"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 ml-6">
            <v-select
              v-model="selectedView"
              label="View"
              :items="viewsList"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            />
          </v-col>
        </v-row>
      </div>

      <template v-if="filtersDataLoaded">
        <v-divider class="mt-10" />
        <overview-graphs ref="graphs" class="mt-4" />

        <v-divider class="mt-10" />
        <overview-dimension-table
          ref="dimensionTable"
          class="mt-4"
          :key="dimensionTableKey"
          @dimension1ValueChanged="handleDimension1ValueChanged"
        />

        <v-divider class="mt-10" />
        <overview-options-table ref="optionsTable" class="my-4" />
      </template>
    </v-card>
  </div>
</template>

<script>
import OverviewGraphs from '@/components/Overview/OverviewGraphs.vue'
import OverviewDimensionTable from '@/components/Overview/OverviewDimensionTable.vue'
import OverviewOptionsTable from '@/components/Overview/OverviewOptionsTable.vue'
import { useStockOverviewStore } from '@/store/pinia/stockOverviewStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  components: {
    OverviewGraphs,
    OverviewDimensionTable,
    OverviewOptionsTable,
  },
  setup () {
    return {
      filtersStore: useFiltersStore(),
      stockOverviewStore: useStockOverviewStore(),
    }
  },
  data () {
    return {
      dimensionTableKey: 0,
    }
  },
  computed: {
    filtersDataLoaded () {
      return this.filtersStore.filtersDataLoaded
    },
    dimensionsList () {
      const result = []
      const propertiesSorted = this.stockOverviewStore.dimensionsList?.slice().sort()
      let lastHeader = null

      for (const prop of propertiesSorted) {
        const parts = prop.split('/')

        if (!lastHeader || parts[1] !== lastHeader) {
          result.push({
            header: this.$options.filters.formatString(parts[1]),
          })

          lastHeader = parts[1]
        }

        result.push({
          value: prop,
          text: this.$options.filters.formatString(parts[2]),
        })
      }

      return result
    },
    selectedDimension1: {
      get () {
        return this.stockOverviewStore.selectedDimensions[0]
      },
      set (value) {
        this.stockOverviewStore.setSelectedDimensions([
          value,
          this.selectedDimension2,
        ])
        this.dimensionTableKey++
        this.loadDimensionsData()
        this.loadChartData()
      },
    },
    selectedDimension2: {
      get () {
        return this.stockOverviewStore.selectedDimensions[1]
      },
      set (value) {
        this.stockOverviewStore.setSelectedDimensions([
          this.selectedDimension1,
          value,
        ])

        if (this.dimension1Value) {
          this.dimensionTableKey++
          this.loadDimensionsData()
          this.loadChartData()
        }
      },
    },
    dimension1Value () {
      return this.stockOverviewStore.dimension1Value
    },
    viewsList () {
      return this.stockOverviewStore.viewsList.map(v => ({
        text: this.$options.filters.formatString(v),
        value: v,
      }))
    },
    selectedView: {
      get () {
        return this.stockOverviewStore.selectedView
      },
      set (value) {
        this.stockOverviewStore.setSelectedView(value)
        this.stockOverviewStore.setDimension1Value(null)

        this.dimensionTableKey++

        this.loadDimensionsData()
        this.loadChartData()
        this.loadOptionsData()
      }
    },
  },
  watch: {
    'filtersStore.filtersChanged': {
      handler (newVal) {
        if (newVal) {
          this.handleFilterChanged()
        }
      },
    },
  },
  methods: {
    watchFiltersDataLoaded () {
      this.$watch(
        'filtersStore.filtersDataLoaded',
        function (newVal) {
          if (newVal) {
            this.handleFilterChanged()
          }
        },
        { immediate: true }
      )
    },
    handleFilterChanged () {
      this.loadDimensionsData()
      this.loadChartData()
      this.loadOptionsData()

      this.dimensionTableKey++
    },
    handleDimension1ValueChanged () {
      this.loadChartData()
      this.loadOptionsData()
    },
    loadDimensionsData () {
      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()

      this.stockOverviewStore.getOverviewData({
        view: this.selectedView,
        dimension: this.selectedDimension1,
        filters: pageFiltersValues,
        format: 'aod',
      })
        .then(response => {
          this.stockOverviewStore.setOverviewDimensionsData(response.data)
          this.stockOverviewStore.setDimensionsColumnsList(response.columns)
          this.stockOverviewStore.setDimensionsColorData(response.colors)
        })
    },
    loadChartData () {
      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()

      this.stockOverviewStore.getOverviewData({
        view: this.selectedView,
        dimension: this.dimension1Value ? this.selectedDimension2 : this.selectedDimension1,
        filters: pageFiltersValues,
        format: 'aod',
      })
        .then(response => {
          this.stockOverviewStore.setOverviewChartData(response.data)
          this.stockOverviewStore.setChartAxis(response.chart_axis)
          this.stockOverviewStore.setChartColorData(response.colors)
        })
    },
    loadOptionsData () {
      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()

      this.stockOverviewStore.getOptionsTableData({
        dimension: 'option_key',
        view: this.selectedView,
        filters: this.dimension1Value ? {
          ...pageFiltersValues,
          [this.selectedDimension1]: [this.dimension1Value],
        } : pageFiltersValues,
        format: 'aod',
      })
    },
  },
  created () {
    this.watchFiltersDataLoaded()
    this.stockOverviewStore.getDimensionsAndViews()
  },
  destroyed () {
    this.stockOverviewStore.setOverviewDimensionsData([])
    this.stockOverviewStore.setOverviewChartData([])
    this.stockOverviewStore.setDimensionTableChildData([])
    this.stockOverviewStore.setOptionsTableData([])
  },
}
</script>

<style lang="scss">
.stock-overview-page {
}
</style>
