<template>
  <div class="settings-data-page">
    <v-container fluid class="pa-0">
      <v-row class="my-2">
        <v-col class="py-0 d-flex align-center">
          <h3
            class="my-6 mr-2 breadcrumb-lvl-0"
            @click="$router.push({ name: currentModule + '-settings-main' })"
          >Settings</h3>/
          <h3 class="my-6 ml-2"> Data</h3>
        </v-col>
      </v-row>
      <v-card>
        <v-tabs v-model="activeTab" grow show-arrows>
          <v-tabs-slider/>
          <v-tab
            style="max-width: 260px"
            v-for="item of tabs"
            :key="item"
            class="px-8"
          >
            <template>
              {{item}}
            </template>
          </v-tab>
        </v-tabs>
        <jobs-settings v-if="!activeTab"></jobs-settings>
        <settings-data-optimization v-else-if="activeTab === 1"></settings-data-optimization>
        <settings-data-features v-else-if="activeTab === 2"></settings-data-features>
        <settings-data-properties v-else-if="activeTab === 3"/>
        <SettingsDataExports v-else-if="activeTab === 4"></SettingsDataExports>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import SettingsDataExports from './SettingsDataExports'
import SettingsDataOptimization from './SettingsDataOptimization'
import SettingsDataProperties from './StockSettingsDataProperties'
import JobsSettings from '../components/GeneralSettings/JobsSettings'
import SettingsDataFeatures from './SettingsDataFeatures'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  data () {
    return {
      activeTab: 0,
      tabs: ['Jobs', 'Optimization', 'Features', 'Properties', 'Export'],
      generalStore: useGeneralStore()
    }
  },
  computed: {
    currentModule () {
      return this.generalStore.currentModule
    },
  },
  methods: {
  },
  components: {
    SettingsDataProperties,
    SettingsDataFeatures,
    JobsSettings,
    SettingsDataOptimization,
    SettingsDataExports,
  },
}
</script>

<style lang="scss">
.settings-data-page {
  .breadcrumb-lvl-0 {
    cursor: pointer;
    color: var(--text-sub-black);
  }
}
</style>
