<template>
  <resizable
    id="stock-pocktickets-open"
    @resize="updateTotalTableWidth"
  >
    <data-tables
      style="width: 100%"
      class="table"
      :data="picktickets.table"
      :page-size="5"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 mr-2',
      }"
    >
      <el-table-column
        v-for="(column, i) in picktickets.columns"
        :key="column"
        :prop="column"
        :label="column | formatString"
        :label-class-name="i === 0 ? 'pl-3' : null"
        :class-name="'text-capitalize ' + (i === 0 ? 'pl-3' : '')"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row[column] }}
        </template>
      </el-table-column>
      <template v-slot:append>
        <data-tables
          :data="[picktickets.total]"
          class="total-table"
          :pagination-props="{class: 'd-none'}"
        >
          <el-table-column
            class-name="total-row_column pl-3"
            label-class-name="d-none"
          >
            <template>Total</template>
          </el-table-column>
          <el-table-column
            class-name="total-row_column"
            label-class-name="d-none"
            v-for="column in picktickets.columns.slice(1)"
            :prop="column"
            :key="column"
          >
            <template slot-scope="scope">
              {{ scope.row[column] }}
            </template>
          </el-table-column>
        </data-tables>
      </template>
    </data-tables>
  </resizable>
</template>

<script>
import Resizable from '@/components/Utility/Resizable.vue'

export default {
  name: 'StockPickticketsOpen',
  components: { Resizable },
  props: {
    picktickets: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = this.$el.querySelector('.el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 100)
    },
  }
}
</script>

<style lang="scss">
#stock-pocktickets-open {
    .total-row_column {
        background-color: #f5f7fa;
    }
}
</style>
