<template>
  <data-sets-line-chart
    v-if="trendGraph"
    :view="trendGraph"
    index="1"
    ref="chart"
    :flat="true"
    :hide="true"
    height="350"
  ></data-sets-line-chart>
</template>

<script>
import DataSetsLineChart from '@/components/AutomateDatasets/DataSetsLineChart'
import { performViewData } from '@/api/analytics'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  name: 'StockTrendGraph',
  components: { DataSetsLineChart },
  data () {
    return {
      trendView: {
        'org_key': 0,
        'view_key': 728,
        'user_id': 51,
        'dataset': 'sales_statistics',
        'view_name': 'Top carrousel: 1. Trend graph',
        'chart_type': 'line_chart',
        'dimensions': ['properties/calendar/week_number'],
        'measures': [{ 'axis': 'y2', 'name': 'Sales', 'color_code': '#4DBD33', 'calculation': 'sales' }, {
                       'axis': 'y2',
                       'name': 'Sales LY',
                       'type': 'dotted',
                       'color_code': '#4CAF50',
                       'calculation': 'sales_ly'
                     }, {
                       'axis': 'y2',
                       'name': 'Potential',
                       'type': 'dotted',
                       'color_code': '#FF9800',
                       'calculation': 'potential_stocked'
                     }, {
                       'axis': 'y2',
                       'name': 'Belief',
                       'type': 'line',
                       'color_code': '#FF9800',
                       'calculation': 'potential_belief_stocked'
                     }, {
                       'axis': 'y1',
                       'name': 'Stock',
                       'type': 'line',
                       'color_code': '#007AFF',
                       'hasGradient': true,
                       'calculation': 'stock_projection'
                     },
                     { 'axis': 'y2', 'name': 'Inbound', 'color_code': '#085D70', 'calculation': 'inbounds', hidden: true }],
        'filters': { 'properties/calendar/relative_week': ['10', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0', '-1', '-2', '-3', '-4', '-5', '-6', '-7', '-8', '-9', '-10'] },
        'properties': {
          'axis': {
            'x': 'Stock',
            'y1': 'Stock',
            'y2': 'Sales',
            'y1_min': 0,
            'y2_min': 0,
            'grading_max': 100000,
            'grading_min': 0,
            'color_grading': 'Uplift'
          },
          'bar_type': 'grouped'
        },
        'creation_date': '2023-01-05 13:13:05',
        'limited': false,
        'filters_applied': ['properties/calendar/relative_week']
      },
      trendGraph: null,
    }
  },
  methods: {
    redrawGraph () {
      const pageFilters = this.filtersStore.getPageFiltersNonDefaultValues()
      this.trendView.filters.collection_key = pageFilters.collections || []
      this.trendView.filters.group_key = pageFilters.groups || []
      this.trendView.filters.store_key = pageFilters.stores || []
      this.trendView.filters['properties/article/option_key'] = pageFilters.options || []
      performViewData(this.trendView).then(r => {
        this.trendGraph = r
        this.trendGraph.chartName = 'stock_trend_graph'
        this.$emit('load')
      })
    }
  },
  watch: {
    'filtersStore': function () {
      this.redrawGraph()
    }
  },
  mounted () {
    this.redrawGraph()
  }
}
</script>

<style scoped>

</style>
