<template>
  <v-dialog
    :value="visible"
    persistent
    content-class="align-self-start mt-14"
    width="600px"
    max-width="90%"
  >
    <v-card class="rounded-0">
      <v-card-text>
        <div class="text-body-1 pt-8">Add current selection as inclusions or discard?</div>
      </v-card-text>
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2">
          <v-col>
            <v-btn
              color="primary"
              depressed
              text
              @click="$emit('discard-click')"
            >Discard</v-btn>
            <v-btn
              color="primary"
              depressed
              outlined
              @click="$emit('add-click')"
              class="mx-4"
            >Add</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {}
  }
}
</script>
