import StockOverviewPage from '@/views/StockOverviewPage.vue'
import StockAssortmentPage from '@/views/StockAssortmentPage.vue'
import StockPickticketsPage from '@/views/StockPickticketsPage.vue'
import StockTransfersPage from '@/views/StockTransfersPage.vue'
import DataSetsMainPage from '@/views/DataSetsMainPage.vue'
import SettingsMainPage from '@/views/SettingsMainPage'
import StockSettingsDataPage from '@/views/StockSettingsDataPage.vue'
import StockSettingsUsersPage from '@/views/StockSettingsUsersPage.vue'
import StockSettingsStoresPage from '@/views/StockSettingsStoresPage.vue'
import SettingsConfiguration from '@/views/SettingsConfiguration.vue'
import StockSettingsConfigurationPage from '@/views/StockSettingsConfigurationPage.vue'
import BuySettingsConfigurationPage from '@/views/BuySettingsConfigurationPage.vue'
import StockOptionPage from '@/views/StockOptionPage.vue'
import OperatePage from '@/views/OperatePage.vue'
import StockConsolidatePage from '@/views/StockConsolidatePage.vue'
import StockLayout from '@/views/modules/StockLayout.vue'
import StockHomePage from '@/views/StockHomePage.vue'
import StockTasksPage from '@/views/StockTasksPage.vue'

export const stockRoutes = {
  path: '/stock',
  component: StockLayout,
  children:[
    {
      path: '/tasks',
      name: 'stock-tasks',
      component: StockTasksPage
    },
    {
      path: 'overview',
      name: 'stock-overview',
      component: StockOverviewPage
    },
    {
      path: 'home',
      name: 'stock-home-page',
      component: StockHomePage
    },
    {
      path: 'manage',
      name: 'stock-manage',
      component: StockAssortmentPage
    },
    {
      path: 'picktickets',
      name: 'stock-picktickets',
      component: StockPickticketsPage
    },
    {
      path: 'transfers',
      name: 'stock-transfers',
      component: StockTransfersPage
    },
    {
      path: 'analytics',
      name: 'stock-analytics',
      component: DataSetsMainPage
    },
    {
      path: 'settings',
      name: 'stock-settings-main',
      component: SettingsMainPage
    },
    {
      path: 'settings/data',
      name: 'stock-settings-data',
      component: StockSettingsDataPage
    },
    {
      path: 'settings/users',
      name: 'stock-settings-users',
      component: StockSettingsUsersPage
    },
    {
      path: 'settings/stores',
      name: 'stock-settings-stores',
      component: StockSettingsStoresPage
    },
    {
      path: 'settings/configuration',
      name: 'stock-settings-configuration',
      component: SettingsConfiguration
    },
    {
      path: 'settings/configuration/stock',
      name: 'stock-settings-configuration-stock',
      component: StockSettingsConfigurationPage
    },
    {
      path: 'settings/configuration/buy',
      name: 'stock-settings-configuration-buy',
      component: BuySettingsConfigurationPage
    },
    {
      path: 'option',
      name: 'stock-option',
      component: StockOptionPage
    },
    {
      path: 'operate',
      name: 'stock-operate',
      component: OperatePage
    },
    {
      path: 'consolidate',
      name: 'stock-consolidate',
      component: StockConsolidatePage
    },
  ]
}
