<template>
  <div class="d-flex flex-column justify-start">
    <v-row class="mt-0 mx-0 justify-end">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon style="height: 24px" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
        </template>
        <p><b>Prediction score:</b> Correlation between sales L5W and potential L5W</p>
        <p><b>Distribution score:</b> Correlation between fairshare and fairstock</p>
      </v-tooltip>
    </v-row>
    <v-row class="px-4 justify-center ma-0 mt-9 flex-nowrap" :key="chartKey">
      <v-col cols="6" class="text-center">
        <h4 class="pb-2">Prediction score</h4>
        <div class="d-flex pa-4" style="position: relative;"
          :style="{ height: '220px'}">
          <canvas
            id="stock-gauge-prediction_score"></canvas>
        </div>

      </v-col>
      <v-col cols="6" class="text-center">
        <h4 class="pb-2">Distribution score</h4>

        <div class="d-flex pa-4" style="position: relative;"
          :style="{height: '220px'}">
          <canvas
            id="stock-gauge-distribution_score"></canvas>
        </div>

      </v-col>
    </v-row>
    <v-row class="justify-end ma-0">
      <div class="d-flex align-end">
        <div>Score scale</div>
        <div class="d-flex align-center"><div style="background-color: #ff5721" class="color-scale"></div> 0-30 </div>
        <div class="d-flex align-center"><div style="background-color: #FFAB00" class="color-scale"></div> 30-70 </div>
        <div class="d-flex align-center"><div style="background-color: #4caf50" class="color-scale"></div> 70 - 100 </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import { Chart } from 'chart.js/auto'
import interpolate from 'color-interpolate'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

export default {
  setup () {
    return {
      proposalStore: useStockProposalStore()
    }
  },
  name: 'StockGaugeChart',
  props: {
  },
  data () {
    this.chart = null

    return {
      chartKey: 0,
      loaded: false,
    }
  },
  methods: {
    drawChart (dataIndex) {
      if (!this.chart) {
        this.chart = {}
      }
      if (this.chart[dataIndex]) {
        this.chart[dataIndex].destroy()
      }
      try {
        this.loaded = false
        const ctx = document.getElementById(`stock-gauge-${dataIndex}`).getContext('2d')
        const data = this.prepareChartData(this.chartData, dataIndex)
        const chartData = data[0]
        const options = data[1]
        const plugins = [{
          afterDatasetDraw: () => {
            const centerY = (this.chart[dataIndex].chartArea.bottom / 2)
            const width = this.chart[dataIndex].width
            // const height = this.chart[dataIndex].chartArea.bottom - this.chart[dataIndex].legend.height
            const ctx = this.chart[dataIndex].ctx
            ctx.restore()
            ctx.font = '1.07em sans-serif'
            ctx.textBaseline = 'middle'
            ctx.fillStyle = 'rgba(0, 0, 0, 0.86)'
            const text = this.$options.filters.formatPercents(this.chartData[dataIndex])
            const textX = Math.round((width - ctx.measureText(text).width) / 2)
            ctx.fillText(text, textX, centerY)
            ctx.save()
          }
        }]
        this.chart[dataIndex] = new Chart(ctx, { type: 'doughnut', data: chartData, options, plugins })
        this.$emit('loaded')
        this.loaded = true
      } catch (e) {
        console.log(e)
        this.$emit('loaded')
        this.loaded = true
      }
    },
    prepareChartData (data, dataIndex) {
      const datasetData = [data[dataIndex] * 100]
      const realValue = datasetData[0]
      const middleStart = 30
      const highStart = 50
      const highEnd = 100
      let interpolateColors
      let backgroundColor
      if (realValue < middleStart) {
        interpolateColors = interpolate(['#ff5721', '#FFAB00'])
        backgroundColor = [interpolateColors(realValue / middleStart), '#e5e5e5']
      } else if (realValue < highStart) {
        interpolateColors = interpolate(['#FFAB00', '#FFD202'])
        backgroundColor = [interpolateColors((realValue - middleStart) / (highStart - middleStart)), '#e5e5e5']
      } else {
        interpolateColors = interpolate(['#FFD202', '#4caf50'])
        backgroundColor = [interpolateColors((realValue - highStart) / (highEnd - highStart)), '#e5e5e5']
      }
      const difference = highEnd - datasetData[0]
      if (difference > 0) {
        datasetData.push(difference)
      } else {
        backgroundColor.push(chartColors[datasetData.length + 2])
      }
      return [{
        labels: [],
        datasets: [{
          label: [],
          data: datasetData,
          backgroundColor,
        }],
      }, {
        responsive: true,
        cutout: '85%',
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
            align: 'center',
            labels: {
              generateLabels: (chart) => {
                const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)

                // Potential label
                const newLabels = labels[0].text.reduce((prev, cur, currentIndex) => {
                  return [...prev, {
                    'text': cur,
                    'fillStyle': backgroundColor[currentIndex],
                    'hidden': false,
                    'lineWidth': 2,
                    'strokeStyle': '#fff',
                    'datasetIndex': 0
                  }]
                }, [])
                if (newLabels.length === 1 && newLabels[0].text === undefined) {
                  return []
                }
                return newLabels
              },
            }
          },
          tooltip: {
            callbacks: {
              // this callback is used to create the tooltip label
              label: tooltipItem => {
                return datasetData[tooltipItem.dataIndex].toLocaleString()
              }
            }
          },
        },
      }]
    }
  },
  watch: {
    chartData: {
      handler (value) {
        const keys = Object.keys(value || {})
        if (keys.length < 2) {
          return
        }
        setTimeout(() => {
          keys.forEach((key) => this.drawChart(key))
        }, 300)
      },
      immediate: true
    },
  },
  computed: {
    chartData () {
      return this.proposalStore.gaugeChart
    }
  }
}
</script>

<style scoped>

.color-scale {
  min-width: 16px;
  min-height: 6px;
  margin: 0 6px 0 16px;
  border-radius: 6px;
}
</style>
