<template>
  <v-row class="ma-0 flex py-0">
    <v-col class="ma-0 py-0">
      <v-autocomplete
        :menu-props="{
          left: true,
        }"
        v-model="indexData"
        label="Property"
        class="pt-0"
        :items="allProperties.filter((item)=> usedProperties.indexOf(item) === -1)"
        @input="(value)=> $emit('index-changed', {value, oldValue: index})"
      />
    </v-col>
    <v-col class="ma-0 pr-0 py-0">
      <v-autocomplete
        :menu-props="{
          left: true,
        }"
        v-if="singleSelect"
        v-model="propertyData"
        class="pt-0"
        :items="propertiesValues[indexData] || []"
        label="Value"
        clearable
        autocomplete
        @change="(value)=> changeDebounce({value, index})"
      />
      <collapsible-select
        v-else
        class="pt-0"
        v-model="propertyData"
        :items="propertiesValues[indexData] || []"
        label="Values"
        clearable
        autocomplete
        @change="(value)=> changeDebounce({value, index})"
      />
    </v-col>
  </v-row>
</template>

<script>
import CollapsibleSelect from '../components/CollapsibleSelect'
import _ from 'lodash'
export default {
  name: 'BeliefsProperties',
  components: { CollapsibleSelect },
  props: {
    allProperties: {
      type: Array,
      default () {
        return []
      }
    },
    index: {
      type: String,
      default: '',
    },
    property: {
      type: Array,
      default () {
        return []
      }
    },
    usedProperties: {
      type: Array,
      default () {
        return []
      }
    },
    singleSelect: {
      type: Boolean,
      default () {
        return false
      }
    },
    propertiesValues: {
      type: Object,
      default () {
        return {}
      },
    }
  },
  data () {
    return {
      indexData: '',
      propertyData: [],
      changeDebounce: _.debounce(this.emitChange, 1000),
    }
  },
  watch: {
    index (newValue) {
      if (!newValue) {
        this.indexData = ''
      }
    }
  },
  methods: {
    emitChange (payload) {
      this.$emit('property-changed', payload)
    }
  },
  mounted () {
    this.propertyData = this.property || []
    this.indexData = this.index || ''
  }
}
</script>

<style scoped>

</style>
