import axios from '@/services/axios'
import {getHeadersWithAccessToken} from '@/variables'
import baseURLs from '@/api/APIBaseURLs'
import {Config} from '@/models/Config'

interface ConfigResponse {
  data: Config
  ts: string
}

export async function getConfig (ts: null|string = null, keys: Array<string> = []): Promise<ConfigResponse> {
  let url = baseURLs.manageAPI + '/config'
  const query = []
  if (ts) {
    query.push(`ts=${ts}`)
  }

  if (keys.length) {
    query.push(`keys=${keys.join(',')}`)
  }

  url += query.length ? `?${query.join('&')}` : ''

  const response = await axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
  return response.data as ConfigResponse
}
