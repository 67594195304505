<template>
  <div class="settings-users-list p-relative">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <h6 class="text-subtitle-1 font-weight-medium mt-4 ml-6">Users</h6>
    <v-row>
      <div class="col text-right pr-6">
        <TableSearch class="mx-2" @searchChanged="handleUsersSearchChanged" />
        <v-icon
          v-if="usersType === 'active'"
          :disabled="!selectedRows[0]"
          class="mx-2 action-btn-primary"
          @click="handleEditClick"
        >edit</v-icon>
        <v-icon class="mx-2 action-btn-primary" @click="handleAddClick">add</v-icon>
        <v-icon class="mx-2 action-btn-danger" :disabled="!selectedRows[0] || selectedRows[0].in_use" @click="handleDeleteClick">delete</v-icon>
        <v-icon class="mx-2 action-btn-danger" :disabled="!selectedRows[0]" @click="handleEmailClick">email</v-icon>
      </div>
    </v-row>
    <v-row>
      <div class="col">
        <data-tables
          style="width: 100%"
          :key="usersListKey"
          :data="usersList"
          :page-size="10"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :table-props="{ 'row-key': 'id' }"
          :filters="usersTableFilters"
          @row-click="handleRowClick">
          <el-table-column prop="id" width="55">
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-4 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column width="70px">
            <template slot-scope="scope">
              <div class="text-center">
                <avatar
                  :first-name="scope.row.first_name"
                  :last-name="scope.row.last_name"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="first_name" label="First name" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.first_name }}
            </template>
          </el-table-column>
          <el-table-column prop="last_name" label="Last name" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.last_name }}
            </template>
          </el-table-column>
          <el-table-column prop="email" label="Email" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.email }}
            </template>
          </el-table-column>
          <el-table-column prop="external" label="External" sortable="custom">
            <template slot-scope="scope">
              <v-checkbox
                v-model="scope.row.external"
                :disabled="true"
              ></v-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            v-if="usersType === 'active'"
            prop="last_login"
            label="Last login"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ scope.row.last_login }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="superuser && usersType === 'active'"
            prop="superuser"
            label="Superuser"
            sortable="custom"
          >
            <template slot-scope="scope">
              <v-switch
                v-model="scope.row.superuser"
                hide-details
                class="pt-0 my-3"
                @change="handleSuperuserCheckboxChange(scope.row)"
              />
            </template>
          </el-table-column>
        </data-tables>
      </div>
    </v-row>

    <!-- Form dialog -->
    <v-dialog
      :value="formDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0" v-if="editedUser">
        <v-overlay absolute color="white" :value="usersStore.savingUser">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">{{ `${editedUser.id ? `Edit user ${editedUser.email}` : 'New invite'}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 text--primary">
          <div class="container px-12">
            <template>
              <h6 class="text-subtitle-1 font-weight-medium mt-4">General</h6>
              <v-row class="align-baseline mt-5 mb-4">
                <v-col class="py-0">
                  <v-text-field
                    v-model="editedUser.first_name"
                    :error-messages="editedUserFormErrors.firstName"
                    :disabled="editedUser.external"
                    label="First name"
                    hide-details="auto"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-5 mb-4">
                <v-col class="py-0">
                  <v-text-field
                    v-model="editedUser.last_name"
                    :error-messages="editedUserFormErrors.lastName"
                    label="Last name"
                    :disabled="editedUser.external"
                    hide-details="auto"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="!editedUser.id" class="align-baseline mt-5 mb-4">
                <v-col class="py-0">
                  <v-text-field
                    v-model="editedUser.email"
                    :error-messages="editedUserFormErrors.email"
                    label="Email"
                    hide-details="auto"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <h6 class="text-subtitle-1 font-weight-medium mt-6">Access</h6>
              <v-row class="align-baseline mt-4 mb-4">
                <v-col class="py-0">
                  <v-select
                    v-model="editedUser.user_access.strategy"
                    label="Strategy"
                    :items="accessOptions"
                    hide-details="auto"
                    clearable
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                  />
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-4 mb-4">
                <v-col class="py-0">
                  <v-select
                    v-model="editedUser.user_access.buy"
                    label="Buy"
                    :items="accessOptions"
                    hide-details="auto"
                    clearable
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                  />
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-4 mb-4">
                <v-col class="py-0">
                  <v-select
                    v-model="editedUser.user_access.stock"
                    label="Stock"
                    :items="accessOptions"
                    hide-details="auto"
                    clearable
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                  />
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-4 mb-4">
                <v-col class="py-0">
                  <v-select
                    v-model="editedUser.user_access.pricing"
                    label="Pricing"
                    :items="accessOptions"
                    hide-details="auto"
                    clearable
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                  />
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-4 mb-4">
                <v-col class="py-0">
                  <v-select
                    v-model="editedUser.user_access.settings"
                    label="Settings"
                    :items="settingsAccessOptions"
                    hide-details="auto"
                    clearable
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                  />
                </v-col>
              </v-row>
            </template>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    depressed
                    text
                    @click="handleSaveCancel"
                  >Cancel</v-btn>
                  <v-btn
                    color="primary"
                    depressed
                    outlined
                    @click="handleSaveClick"
                    class="mx-4"
                  >{{ editedUser.id ? 'Save' : 'Invite' }}</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog -->
    <v-dialog
      :title="selectedRows[0] ? selectedRows[0].name : null"
      :value="deleteDialogVisible"
      persistent
      width="600px"
      max-width="90%">
      <v-card>
        <v-overlay absolute color="white" :value="usersStore.deletingUser">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium">Delete user</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete this user?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteConfirm"
                class="red--text mx-4"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="invitedModal"
      persistent
      width="600px"
      max-width="90%">
      <v-card>
        <v-card-title class="text-body-1 font-weight-medium">Email sent</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <div class="text-body-1 pt-8">Email has been (re)sent to user</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="invitedModal = false"
              >Cancel</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableSearch from '@/components/TableSearch.vue'
import Avatar from '@/components/Users/Avatar.vue'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useUsersStore } from '@/store/pinia/usersStore'

export default {
  props: {
    usersType: {
      type: String,
      required: true,
    }
  },
  setup () {
    return {
      generalStore: useGeneralStore(),
      usersStore: useUsersStore(),
    }
  },
  data () {
    return {
      loading: false,
      deleteDialogVisible: false,
      invitedModal: false,
      formDialogVisible: false,
      editedUser: null,
      usersListKey: 0,
      editedUserFormErrors: {
        firstName: null,
        lastName: null,
        email: null,
      },
      usersTableFilters: [
        {
          value: ''
        }
      ],
      accessOptions: [{
        text: 'No access',
        value: 'no_access',
      }, {
        text: 'Read',
        value: 'read',
      }, {
        text: 'Write',
        value: 'write',
      }],
      settingsAccessOptions: [{
        text: 'No access',
        value: 'no_access',
      }, {
        text: 'Read',
        value: 'read',
      }, {
        text: 'Write',
        value: 'write',
      }, {
        text: 'Admin',
        value: 'admin',
      }],
    }
  },
  components: {
    TableSearch,
    Avatar,
  },
  computed: {
    appConfig () {
      return this.generalStore.appConfig
    },
    superuser () {
      return (localStorage.getItem('superuser') === 'true')
    },
    usersList () {
      return (this.appConfig?.users || []).filter(u => {
        if (this.usersType === 'active') {
          return u.last_login && u.last_login !== 'None'
        }

        if (this.usersType === 'invited') {
          return !u.last_login || u.last_login === 'None'
        }

        return true
      })
    },
    selectedRows () {
      return this.usersList.filter(r => r.selected)
    },
  },
  methods: {
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleEmailClick () {
      this.usersStore.saveUser({
        ...this.selectedRows[0],
        id: 0,
        user_access: {
          strategy: 'read',
          pricing: 'read',
          ...this.selectedRows[0].user_access,
        }
      }).then(() => {
        this.$auth.refreshAppConfig()
        this.invitedModal = true
      })
    },
    handleSelectRowChange (val, row) {
      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
    },
    handleEditClick () {
      this.editedUser = {
        ...this.selectedRows[0],
        user_access: {
          strategy: 'read',
          pricing: 'read',
          ...this.selectedRows[0].user_access,
        }
      }
      this.formDialogVisible = true
    },
    handleAddClick () {
      this.editedUser = {
        user_access: {
          strategy: 'no_access',
          pricing: 'no_access',
          stock: 'no_access',
          buy: 'no_access',
          settings: 'no_access',
        }
      }
      this.formDialogVisible = true
    },
    handleDeleteClick () {
      this.deleteDialogVisible = true
    },
    handleDeleteConfirm () {
      this.usersStore.deleteUser(this.selectedRows[0].email)
        .then(() => {
          this.hardReloadUsers()
          this.deleteDialogVisible = false
        })
    },
    handleSuperuserCheckboxChange (row) {
      this.usersStore.changeRole(row)
        .then(() => {
          this.hardReloadUsers()
        })
    },
    handleSaveClick () {
      if (!this.validateUser()) {
        return
      }
      this.usersStore.saveUser(this.editedUser)
        .then(() => {
          this.formDialogVisible = false
          this.resetForm()
          this.hardReloadUsers()
        })
    },
    // Should be replaced with loading users list from config?
    hardReloadUsers () {
      this.loading = true
      this.$auth.refreshAppConfig()
        .then(() => {
          this.loading = false
          this.usersListKey++
        })
    },
    handleSaveCancel () {
      this.formDialogVisible = false
      this.resetForm()
    },
    validateUser () {
      this.resetFormErrors()

      if (!this.editedUser.first_name) {
        this.editedUserFormErrors.firstName = 'Can not be empty'
      }

      if (!this.editedUser.last_name) {
        this.editedUserFormErrors.lastName = 'Can not be empty'
      }

      if (!this.editedUser.email) {
        this.editedUserFormErrors.email = 'Can not be empty'
      }

      return !this.formHasErrors()
    },
    resetForm () {
      this.editedUser = null
      this.resetFormErrors()
    },
    handleUsersSearchChanged (val) {
      this.usersTableFilters[0].value = val
      this.usersListKey++
    },
    resetFormErrors () {
      const errors = this.editedUserFormErrors

      Object.keys(errors).forEach(key => {
        errors[key] = null
      })
    },
    formHasErrors () {
      return !Object.values(this.editedUserFormErrors).every(val => val === null)
    },
  },
}
</script>
