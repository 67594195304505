<template>
  <div class="sidebar">
    <side-navigation-drawer v-bind:items="items" ref="drawer" />
    <div class="sidebar-wrapper d-none d-md-block ">
      <div class="sidebar-items">
        <router-link
          :to="{ name: item.route }"
          v-for="(item, idx) in items"
          :key="idx"
          style="text-decoration: none; color: inherit;">
          <div
            class="sidebar-item"
            :class="{ 'item-active': itemActive(item) }"
          >
            <v-icon class="sidebar-icon"> {{ item.icon }} </v-icon>
          </div>
        </router-link>

        <div class="sidebar-item drawer-open-btn" @click="clickDrawer">
          <v-icon>
            chevron_right
          </v-icon>
        </div>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
import SideNavigationDrawer from '@/components/SideNavigationDrawer.vue'

export default {
  name: 'PricingSidebar',
  components: { SideNavigationDrawer},
  data () {
    return {
      items: [
        {
          name: 'Proposal',
          route: 'pricing-home-page',
          icon: 'mdi-list-box',
        },
        {
          name: 'Builder',
          icon: 'mdi-hammer-screwdriver',
          route: 'pricing-markdown-builder',
        },
        {
          name: 'Operate',
          icon: 'mdi-wrench',
          route: 'pricing-operate'
        },
        {
          name: 'Manage',
          icon: 'mdi-shape',
          route: 'pricing-manage',
        },
        {
          name: 'Analytics',
          icon: 'mdi-chart-box',
          route: 'pricing-analytics'
        },
        {
          name: 'Settings',
          icon: 'mdi-cog',
          route: 'pricing-settings-main',
        },
      ],
    }
  },
  methods: {
    itemActive (item) {
      if (item.name === 'Settings') {
        if (this.$route.path.split('/')[2] === 'settings') {
          return true
        }
      }
      return this.$route.name === item.route
    },
    clickDrawer () {
      this.$refs.drawer.clickDrawer()
    }

  },
}
</script>

<style lang="scss">
</style>
