<template>
  <div class="sidebar">
    <side-navigation-drawer v-bind:items="items" ref="drawer" />
    <div class="d-none d-md-block sidebar-wrapper">
      <div class="sidebar-items">
        <router-link
          :to="{ name: item.route }"
          v-for="(item, idx) in items"
          :key="idx"
          style="text-decoration: none; color: inherit;">
          <div
            class="sidebar-item"
            :class="{ 'item-active': itemActive(item) }"
          >
            <v-icon class="sidebar-icon"> {{ item.icon }} </v-icon>
          </div>
        </router-link>

        <div class="sidebar-item drawer-open-btn" @click="clickDrawer">
          <v-icon>
            chevron_right
          </v-icon>
        </div>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
import SideNavigationDrawer from '@/components/SideNavigationDrawer.vue'

export default {
  name: 'BuySidebar',
  components: { SideNavigationDrawer},
  props: ['menuOpened'],
  data () {
    return {
      items: [
        {
          name: 'Shopping',
          icon: 'mdi-cart',
          route: 'buy-home-page',
        },
        {
          name: 'Studio',
          icon: 'mdi-hammer-screwdriver',
          route: 'buy-studio-home-page',
        },
        {
          name: 'Reorder',
          icon: 'mdi-list-box',
          route: 'buy-reorder-page',
        },
        {
          name: 'Manage',
          icon: 'mdi-shape',
          route: 'buy-manage-main',
        },
        {
          name: 'Analytics',
          icon: 'mdi-chart-box',
          route: 'buy-analytics'
        },
        {
          name: 'Settings',
          icon: 'mdi-cog',
          route: 'buy-settings-main',
        },
      ],
    }
  },
  methods: {
    itemActive (item) {
      if (item.name === 'Settings') {
        if (this.$route.path.split('/')[2] === 'settings') {
          return true
        }
      }
      if (item.name === 'Shopping' && this.$route.name === 'buy-shop-item-page') {
        return true
      }
      if (item.name === 'Studio' && this.$route.name === 'buy-studio-item-page') {
        return true
      }
      return this.$route.name === item.route
    },
    clickDrawer () {
      this.$refs.drawer.clickDrawer()
    }

  },
}
</script>

<style lang="scss">
</style>
