<template>
  <v-navigation-drawer v-model="drawer" fixed temporary class="sidebar-drawer">
    <v-list dense class="pt-0">
      <v-list-item-group>
        <v-list-item
          v-for="(item, idx) in items"
          class="expanded-item"
          :class="{ 'title-active': itemActive(item) }"
          :key="idx"
        >
          <router-link :to="{ name: item.route }">
            <v-list-item-icon class="sidebar-icon align-center" style="height: unset">
              <v-icon class="sidebar-icon ml-2"> {{ item.icon }} </v-icon>
              <v-list-item-title
                :class="{ 'title-active': itemActive(item) }"
                class="item-title ml-7"
              >{{ item.name }}</v-list-item-title
              >
            </v-list-item-icon>
          </router-link>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div class="drawer-close-btn px-6" @click="clickDrawer">
      <v-icon class="mr-6">
        chevron_left
      </v-icon>
      <div class="text-subtitle-2 font-weight-bold">
        Close sidebar
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'SideNavigationDrawer',
  props: ['items'],
  data () {
    return {
      drawer: false,
    }
  },
  methods: {
    clickDrawer () {
      this.drawer = !this.drawer
    },
    itemActive (item) {
      if (item.name === 'Settings') {
        if (this.$route.path.split('/')[1] === 'settings') {
          return true
        }
      }
      return this.$route.name === item.route
    },
  },
}
</script>

<style lang="scss">
.expanded-item {
    width: 100%;
    display: flex;
    height: 70px;
    color: var(--text-sub-black);
    border-bottom: 1px solid var(--border-color);
}

.sidebar-drawer {
    box-shadow: none !important;
    box-shadow: inset 0px 2px 2px #ccc !important;
    left: 0 !important;
    top: 56px !important;
    height: calc(100% - 56px) !important;
    position: fixed;
    z-index: 4;
    .item-title {
        color: var(--text-sub-black);
        font-size: 14px !important;
        font-weight: 600 !important;
    }
    .v-list-item.title-active {
        border-right: 2px solid var(--primary);
    }
    a {
        text-decoration: none;
    }
}
</style>
