<template>
  <resizable
    class="pricing-markdown-builder-proposals p-relative"
    @resize="updateTotalTableWidth"
  >
    <v-row>
      <v-col class="text-right mr-6">
        <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
        <DataExport @item-click="downloadTableData" />
        <v-menu
          offset-y
          offset-x
          nudge-bottom="5"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on"
            >filter_list
            </v-icon>
          </template>
          <v-card max-height="400" class="overflow-auto">
            <h5 class="px-2 pt-2">Columns</h5>
            <el-checkbox-group
              v-model="selectedColumns"
              @input="handleSelectedColumnsChanged"
              style="font-size: 14px"
              class="d-flex flex-column pa-2">
              <el-checkbox
                v-for="item of columnsToSelect"
                :label="item"
                :key="item"
              />
            </el-checkbox-group>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <data-tables
          style="width: 100%"
          class="table"
          :filters="filters"
          :key="proposalsListKey"
          :data="proposalsTable.rows"
          :page-size="proposalsTable.rows.length > 200 ? 200 : proposalsTable.rows.length"
          :pagination-props="{
            pageSizes: [proposalsTable.rows.length > 200 ? 200 : proposalsTable.rows.length],
            class: proposalsTable.rows.length > 200 ? 'el-pagination text-right mt-6 mb-4 mr-2' : 'd-none',
          }"
          :table-props="{
            'row-key': 'rowKey',
            'row-class-name': ({ row }) => row.isChild ? 'child-row' : null,
            'span-method': tableSpanMethod,
          }"
        >
          <data-tables
            slot="append"
            :index="null"
            class="total-table"
            :data="proposalsTable.total"
            :pagination-props="{class: 'd-none'}"
          >
            <el-table-column label-class-name="d-none" class-name="total-row_column" width="56" />
            <el-table-column
              class-name="total-row_column"
              label-class-name="d-none"
              width="164"
            >
              <template>
                <div>Total</div>
              </template>
            </el-table-column>
            <el-table-column v-if="selectedColumns.includes('Discount')" label-class-name="d-none" class-name="total-row_column" width="120" />
            <el-table-column v-if="selectedColumns.includes('Price')" label-class-name="d-none" class-name="total-row_column" width="120" />
            <el-table-column
              v-if="selectedColumns.includes('Intake')"
              class-name="total-row_column"
              label-class-name="d-none"
              width="80"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.intake | formatThousands }}</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedColumns.includes('Stock')"
              class-name="total-row_column"
              label-class-name="d-none"
              width="80"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.stock | formatThousands }}</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedColumns.includes('Sellthrough')"
              class-name="total-row_column"
              label-class-name="d-none"
              width="80"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.sellthrough }}%</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedColumns.includes('Weeks on hand')"
              class-name="total-row_column"
              label-class-name="d-none"
              width="80"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.weeks_on_hand | formatThousands }}</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedColumns.includes('Weeks left')"
              class-name="total-row_column"
              label-class-name="d-none"
              width="80"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.weeks_left | formatThousands }}</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedColumns.includes('Residual')"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.residual | formatThousands }}</div>
                <div
                  class="delta-value"
                  :class="getDeltaClass(scope.row.residual_change, true)"
                >({{ scope.row.residual_change | formatThousands | addSign }})</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedColumns.includes('Value')"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.value | currency_no_decimals }}</div>
                <div
                  class="delta-value"
                  :class="getDeltaClass(scope.row.value_change)"
                >({{ scope.row.value_change | currency_no_decimals | addSign }})</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedColumns.includes('Markdown perm')"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.markdown_perm | currency_no_decimals }}</div>
                <div
                  class="delta-value"
                  :class="getDeltaClass(scope.row.markdown_perm_change)"
                >({{ scope.row.markdown_perm_change | formatThousands | addSign }})</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedColumns.includes('Sales n5w')"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.sales_n5w | formatThousands }}</div>
                <div
                  class="delta-value"
                  :class="getDeltaClass(scope.row.sales_n5w_change)"
                >({{ scope.row.sales_n5w_change | formatThousands | addSign }})</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedColumns.includes('Markdown perc n5w')"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.markdown_perc_n5w }}%</div>
                <div
                  class="delta-value"
                  :class="getDeltaClass(scope.row.markdown_perc_n5w_change)"
                >({{ scope.row.markdown_perc_n5w_change | formatThousands | addSign }})</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedColumns.includes('Margin perc n5w')"
              class-name="total-row_column"
              label-class-name="d-none"
              width="100"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.margin_perc_n5w }}%</div>
                <div
                  class="delta-value"
                  :class="getDeltaClass(scope.row.margin_perc_n5w_change)"
                >({{ scope.row['margin_perc_n5w_change'] }}%)</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedColumns.includes('Sales lxw')"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.sales_lxw | formatThousands }}</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedColumns.includes('Sellthrough lxw')"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.sellthrough_lxw | formatThousands }}</div>
              </template>
            </el-table-column>
          </data-tables>
          <el-table-column prop="option_key" label="Name" width="220" colspan="14" sortable>
            <template slot-scope="scope">
              <template v-if="scope.row.isEmpty">
                <div class="text-center">No scenarios</div>
              </template>
              <template v-else>
                <template v-if="scope.row.isChild">
                  <div class="d-flex align-center">
                    <div class="mr-6 add-scenario-icon-wrapper">
                      <v-icon
                        class="mx-2 action-btn-primary add-scenario-icon"
                        @click="$emit('add-scenario-click', scope.row)"
                      >add</v-icon>
                    </div>
                    <div>
                      {{ scope.row.week_number | formatWeekNumber }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <OptionDataCell
                    :option="scope.row"
                    :url="scope.row.route"
                    name-key="name"
                    open-in-new-tab
                  />
                </template>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Discount')" prop="discount" label="Discount" width="120" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>
                  <v-chip
                    label
                    small
                    outlined
                    class="discount-cur"
                    :color="scope.row.discountCurColor"
                    @click="discountClickable && !scope.row.isChild ? $emit('discount-click', scope.row) : null"
                  >
                    {{ scope.row.discount_cur | formatPercents }}%
                  </v-chip>
                  <template v-if="scope.row.discount_cur !== scope.row.discount">
                    <v-chip
                      label
                      small
                      outlined
                      :color="scope.row.discountColor"
                      class="ml-2 discount"
                    >
                      {{ scope.row.discount | formatPercents }}%
                    </v-chip>
                  </template>
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Price')" prop="price" label="Price" width="120" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>
                  <v-chip
                    label
                    small
                    outlined
                    text-color="success"
                    class="price-cur"
                  >
                    {{ scope.row.price_cur | currency_no_decimals }}
                  </v-chip>
                  <template v-if="scope.row.price_cur !== scope.row.price">
                    <v-chip
                      label
                      small
                      outlined
                      text-color="success"
                      class="ml-2 price"
                    >
                      {{ scope.row.price | currency_no_decimals }}
                    </v-chip>
                  </template>
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Intake')" prop="intake" label="Intake" width="80" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>{{ scope.row.intake | formatThousands }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Stock')" prop="stock" label="Stock" width="80" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>{{ scope.row.stock | formatThousands }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Sellthrough')" prop="sellthrough" label="Sellthrough" width="80" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>{{ scope.row.sellthrough }}%</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Weeks on hand')" prop="weeks_on_hand" label="Weeks on hand" width="80" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>{{ scope.row.weeks_on_hand | formatThousands }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Weeks left')" prop="weeks_left" label="Weeks left" width="80" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>{{ scope.row.weeks_left | formatThousands }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Residual')" prop="residual" label="Residual" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>{{ scope.row.residual | formatThousands }}</div>
                <div
                  class="delta-value"
                  :class="getDeltaClass(scope.row.residual_change, true)"
                >({{ scope.row.residual_change | formatThousands | addSign }})</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Value')" prop="value" label="Value" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>{{ scope.row.value | currency_no_decimals }}</div>
                <div
                  class="delta-value"
                  :class="getDeltaClass(scope.row.value_change)"
                >({{ scope.row.value_change | currency_no_decimals | addSign }})</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Markdown perm')" prop="markdown_perm" label="Markdown perm" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>{{ scope.row.markdown_perm | currency_no_decimals }}</div>
                <div
                  class="delta-value"
                  :class="getDeltaClass(scope.row.markdown_perm_change)"
                >({{ scope.row.markdown_perm_change | formatThousands | addSign }})</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Sales n5w')" prop="sales_n5w" label="Sales n5w" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>{{ scope.row.sales_n5w | formatThousands }}</div>
                <div
                  class="delta-value"
                  :class="getDeltaClass(scope.row.sales_n5w_change)"
                >({{ scope.row.sales_n5w_change | formatThousands | addSign }})</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Markdown perc n5w')" prop="markdown_perc_n5w" label="Markdown perc n5w" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>{{ scope.row.markdown_perc_n5w }}%</div>
                <div
                  class="delta-value"
                  :class="getDeltaClass(scope.row.markdown_perc_n5w_change)"
                >({{ scope.row.markdown_perc_n5w_change | formatThousands | addSign }})</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Margin perc n5w')" prop="margin_perc_n5w" label="Margin perc n5w" width="100" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>{{ scope.row.margin_perc_n5w }}%</div>
                <div
                  class="delta-value"
                  :class="getDeltaClass(scope.row.margin_perc_n5w_change)"
                >({{ scope.row['margin_perc_n5w_change'] | formatThousands | addSign }})</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Sales lxw')" prop="sales_lxw" label="Sales lxw" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>{{ scope.row.sales_lxw | formatThousands }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="selectedColumns.includes('Sellthrough lxw')" prop="sellthrough_lxw" label="Sellthrough lxw" sortable>
            <template slot-scope="scope">
              <template v-if="!scope.row.isEmpty">
                <div>{{ scope.row.sellthrough_lxw | formatThousands }}</div>
              </template>
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>
  </resizable>
</template>

<script>
import { colormapGreenRed } from '@/variables'
import { downloadData } from '@/utils'
import OptionDataCell from '@/components/OptionDataCell.vue'
import TableSearch from '@/components/TableSearch.vue'
import DataExport from '@/components/DataExport'
import Resizable from '@/components/Utility/Resizable.vue'

const columnsToSelect = ['Week number', 'Discount', 'Price', 'Intake', 'Stock', 'Sellthrough', 'Weeks on hand', 'Weeks left', 'Residual', 'Value', 'Markdown perm', 'Sales n5w', 'Markdown perc n5w', 'Margin perc n5w', 'Sales lxw', 'Sellthrough lxw']
const defaultColumns = ['Week number', 'Discount', 'Price', 'Intake', 'Stock', 'Sellthrough', 'Weeks on hand', 'Weeks left', 'Residual', 'Value', 'Markdown perm']

export default {
  components: { Resizable, OptionDataCell, TableSearch, DataExport },
  props: {
    proposals: {
      type: Object,
      default: () => ({})
    },
    discountClickable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      filters: [{ value: '' }],
      proposalsListKey: 0,
      proposalsTable: {
        options: {
          pagination: {
            rowsPerPage: 10,
          },
        },
        headers: [],
        rows: [],
        total: [],
      },
      columnsToSelect,
      selectedColumns: JSON.parse(localStorage.getItem('markdownBuilderSelectedColumns')) || defaultColumns,
    }
  },
  computed: {
  },
  watch: {
    'proposals': {
      handler () {
        if (this.proposals) {
          this.proposalsTable.rows.forEach((r, index) => {
            r.rowKey = `${r.option_key}-${index}`
          })
          this.proposalsTable.rows = this.proposals.table
          this.proposalsTable.total = [this.proposals.total]
          this.updateColorIndexes()
          this.updateTotalTableWidth()
        }
      },
      immediate: true,
    },
  },
  methods: {
    tableSpanMethod ({ row }) {
      if (row.isEmpty) {
        return {
          rowspan: 1,
          colspan: 14,
        }
      }

      return {
        rowspan: 1,
        colspan: 1,
      }
    },
    updateColorIndexes () {
      this.proposalsTable.rows.forEach(r => {
        if (r.isEmpty) {
          return
        }

        r.discountColor = colormapGreenRed(r.discount)
        r.discountCurColor = colormapGreenRed(r.discount_cur)
      })
    },
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = this.$el.querySelector('.el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 100)
    },
    getDeltaClass (val, inverse) {
      if (inverse) {
        return val > 0 ? 'error--text' : (val < 0 ? 'success--text' : null)
      } else {
        return val < 0 ? 'error--text' : (val > 0 ? 'success--text' : null)
      }
    },
    handleSearchChanged (val) {
      this.filters[0].value = val
      this.proposalsListKey++
      this.updateTotalTableWidth()
    },
    downloadTableData (sep) {
      const data = this.proposalsTable.rows.map(row => {
        return {
          ...row,
        }
      })
      downloadData(sep, data, 'markdown_builder_proposals')
    },
    handleSelectedColumnsChanged (value) {
      localStorage.setItem('markdownBuilderSelectedColumns', JSON.stringify(value))
      this.updateTotalTableWidth()
    },
  },
}
</script>
<style lang="scss">
.pricing-markdown-builder-proposals {
    .el-table__row.child-row {
        background: #fafafa;
    }
    .el-table__append-wrapper {
        position: sticky;
        bottom: 0;
    }
    .table .el-table__body-wrapper {
        max-height: 60vh;
        overflow-y: auto;
    }
    .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
        width: 4px;
    }
    .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    }
    .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 3px;
    }
    .total-row_column {
        background-color: #f5f7fa;
    }
    .v-chip {
        &.price,
        &.price-cur,
        &.discount,
        &.discount-cur {
            width: 42px;
            padding: 0;
            display: inline-block;
            text-align: center;
        }
    }
    .add-scenario-icon-wrapper {
        width: 33px;
        height: 46px;
        .add-scenario-icon {
            position: absolute;
            top: 15px;
            left: 7px;
        }
    }
    .delta-value {
        font-size: 11px;
    }
}
</style>
