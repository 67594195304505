<template>
  <div class="buy-manage-page">
    <v-row class="ma-0">
      <h3 class="card-title text-left my-6">Manage</h3>
    </v-row>
    <v-card>
      <v-tabs v-model="activeTab">
        <v-tabs-slider />
        <v-tab class="px-8"
          style="width: 260px">Budgets</v-tab>
        <v-tab class="px-8"
          style="width: 260px">Costs / Payments</v-tab>
        <v-tab class="px-8"
          style="width: 260px">Suppliers / Schedules</v-tab>
        <v-tab class="px-8"
          style="width: 260px">Sets</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab" class="pb-6">
        <v-tab-item :transition="false" :reverse-transition="false">
          <buy-manage-budgets
            :properties="properties"
            :sku_names="sku_names"
            :store-sets="storeSets"
          ></buy-manage-budgets>
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false">
          <buy-manage-costs-payments
            :costs="costs"
            :payments="payments"
            @payments-update="handlePaymentsChanged"
            @load-costs="$auth.refreshAppConfig"
          />
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false">
          <buy-manage-suppliers-schedules
            :costs="costs"
            :schedules="schedules"
            :payments="payments"
            @schedule-added="handleSchedulesChanged"
            @schedule-deleted="handleSchedulesChanged"
          ></buy-manage-suppliers-schedules>
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false">
          <storeSets/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import BuyManageBudgets from './BuyManageBudgets'
import BuyManageCostsPayments from './BuyManageCostsPayments'
import BuyManageSuppliersSchedules from './BuyManageSuppliersSchedules'
import storeSets from '@/components/Assortment/StoreSets/StoreSets'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'
import { useStockManageStore } from '@/store/pinia/stockManageStore'
import { getSKUNames, getSKUProperties } from '@/api/skusAPI'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore(),
      stockManageStore: useStockManageStore()
    }
  },
  name: 'BuyManageMain',
  components: { storeSets, BuyManageSuppliersSchedules, BuyManageCostsPayments, BuyManageBudgets },
  data () {
    return {
      activeTab: 0,
      properties: {},
      sku_names: [],
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    ...mapState(useStoreAndOptionSetsStore, ['storeSets']),
    payments () {
      const payments = this.appConfig?.payments || []

      return payments.map(p => {
        if (!p.schedule.map) {
          return p
        }
        return {
          ...p,
          schedule: p.schedule.map(s => {
            return {
              ...s,
              perc: s.perc * 100,
            }
          })
        }
      })
    },
    schedules () {
      return this.appConfig?.schedules || []
    },
    costs () {
      return (this.appConfig?.costs || [])
    },
  },
  methods: {

    setStoresSetsLabel (label){
      const generalStore = useGeneralStore()
      if(label === 'sets'){
        generalStore.setCurrentStoresSetsLabel('all')
        useStoreAndOptionSetsStore().loadStoreSets()
        useStoreAndOptionSetsStore().loadOptionSets()
      }else{
        generalStore.setCurrentStoresSetsLabel('stock')
        useStoreAndOptionSetsStore().loadStoreSets()
        useStoreAndOptionSetsStore().loadOptionSets()
      }
    },
    revertSchedulesFilter () {
      const payload = {
        filterName: 'schedules',
        value: this.schedules.map(s => s.schedule_key.toString()),
      }

      this.filtersStore.setSelectedFilterOptions(payload)
      this.filtersStore.setLocalStorage('schedules')
    },
    revertPaymentsFilter () {
      const payload = {
        filterName: 'payments',
        value: this.payments.map(s => s.payment_key.toString()),
      }

      this.filtersStore.setSelectedFilterOptions(payload)
      this.filtersStore.setLocalStorage('payments')
    },
    handleSchedulesChanged () {
      this
        .$auth.refreshAppConfig()
        .then(this.revertSchedulesFilter)
    },
    handlePaymentsChanged () {
      this
        .$auth.refreshAppConfig()
        .then(this.revertPaymentsFilter)
    },
  },
  watch: {
    activeTab (val){
      this.setStoresSetsLabel (val === 0 ? 'sets' : 'buy')
    }
  },
  created () {
    this.setStoresSetsLabel ('all')
    this.stockManageStore.getStoreSetsFilterOptionValues('country')
    this.stockManageStore.getStoreSetsFilterOptionValues('dc_key')
    this.stockManageStore.getStoreSetsFilterOptionValues('proposal_key')
    this.properties = getSKUProperties()
    this.sku_names = getSKUNames()
  }
}
</script>

<style scoped>

</style>
