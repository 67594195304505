<template>
  <div class="buy-manage-beliefs-main">
    <v-row class="ma-6 mb-2" justify="space-between">
      <h4>Beliefs for {{budget.budget_name}}</h4>
    </v-row>
    <v-row class="ma-4  mt-0 justify-end">
      <table-search
        @searchChanged="filters => handleSearchChanged('beliefsFilters',Object.keys(beliefs[0]), filters)"></table-search>
      <v-icon class="mx-2" @click="budgetModalOpen = true; isEdit=true" :disabled="selectedBelief === null">edit</v-icon>
      <v-icon class="mr-2" @click="budgetModalOpen = true; isEdit=false">add</v-icon>
      <v-icon @click="deleteDialogVisible = true">delete</v-icon>
    </v-row>
    <data-tables
      :data="beliefs"
      :filters="beliefsFilters"
      :key="budget.budget_name"
      :page-size="5"
      @row-click="(row)=> selectBelief(row.index)"
      :pagination-props="{ pageSizes: [5, 10, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
    >
      <el-table-column prop="option_key" width="55">
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="ml-3"
            :value="selectedBelief !== null && selectedBelief === scope.row.index"
            :ripple="false"
            :input-value="selectedBelief !== null && selectedBelief === scope.row.index"
            @change="()=> selectBelief(scope.row.index)"
            @click.stop="()=> selectBelief(scope.row.index)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="store_keys"
        sortable
        label="Stores"
      >
        <template slot-scope="scope">
          <chips-dropdown
            :items="scope.row.store_keys.map(item=> stores_names[item])">
          </chips-dropdown>
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="properties"
        label="Properties">
        <template slot-scope="scope">
          <chips-dropdown
            :items="scope.row.properties_show">
          </chips-dropdown>
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="week_min"
        label="Week from">
        <template slot-scope="scope">
          {{ parseWeek(scope.row.week_min)}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="week_max"
        label="Week to">
        <template slot-scope="scope">
          {{ parseWeek(scope.row.week_max)}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="factor"
        label="Factor ">
        <template slot-scope="scope">
          {{ scope.row.factor  || '-'}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="note"
        label="Note">
        <template slot-scope="scope">
          {{ scope.row.note  || '-'}}
        </template>
      </el-table-column>
    </data-tables>
    <v-divider/>
    <v-dialog
      v-model="budgetModalOpen"
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <buy-manage-beliefs-modal
        v-if="budgetModalOpen"
        @close="budgetModalOpen = false"
        @save="saveBelief"
        :stores="stores"
        :properties="properties"
        :sku_names="sku_names"
        :store-sets="storeSets"
        :isEdit="isEdit"
        :selectedBelief="getBelief(beliefs[selectedBelief])"
      ></buy-manage-beliefs-modal>
    </v-dialog>
    <delete-dialog
      :isOpen="deleteDialogVisible"
      @close="deleteDialogVisible = false"
      @delete="onDeleteClick"
      resource="Belief"
    />
  </div>
</template>

<script>
import { applyBudgetBelief, saveBudget } from '@/api/budgetAPI'
import ChipsDropdown from '@/components/ChipsDropdown'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch'
import BuyManageBeliefsModal from '@/views/BuyManageBeliefsModal'
import { parseWeek } from '@/variables'
import DeleteDialog from '@/components/Dialogs/DeleteDialog'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'BuyManageBudgetsBeliefs',
  mixins: [columnFilters],
  components: { DeleteDialog, BuyManageBeliefsModal, ChipsDropdown, TableSearch },
  props: ['budget', 'beliefs', 'properties', 'sku_names', 'storeSets'],
  data () {
    return {
      selectedBelief: null,
      beliefsFilters: [],
      budgetModalOpen: false,
      deleteDialogVisible: false,
      isEdit: false,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    stores () {
      return (this.appConfig?.stores || [])
    },
    stores_names () {
      const obj = {}
      this.stores.forEach((item) => {
        obj[item.store_key] = item.store_name
      })
      return obj
    }
  },
  methods: {
    selectBelief (index) {
      if (this.selectedBelief === index) {
        this.selectedBelief = null
      } else {
        this.selectedBelief = index
      }
    },
    parseWeek,
    getBelief (belief) {
      if (this.isEdit && belief) {
        return { ...belief }
      }
      return {
        note: '',
        week_max: '',
        week_min: '',
        sku_names: [],
        properties: {},
        store_keys: []
      }
    },
    async saveBelief ({ belief, addToExistingItem }) {
      this.budgetModalOpen = false
      const beliefs = [ ...this.beliefs ]

      if (this.isEdit) {
        beliefs[this.selectedBelief] = belief
      } else {
        beliefs.push(belief)
      }

      delete this.budget.properties_show

      await saveBudget(this.budget, beliefs)

      if (addToExistingItem) {
        await applyBudgetBelief({
          budget_key: this.budget.budget_key,
          belief: belief,
        })
      }

      this.$emit('saved', this.budget)
    },
    async onDeleteClick () {
      this.deleteDialogVisible = false
      this.beliefs.splice(0, 1)
      delete this.budget.properties_show

      await saveBudget(this.budget, this.beliefs)

      this.$emit('saved', this.beliefs)
    }
  }
}
</script>

<style scoped>
.buy-manage-beliefs-main {
}
</style>
