import {defineStore} from 'pinia'
import {useGeneralStore} from '@/store/pinia/generalStore'
import * as api from '@/api/stockTransfersAPI'
import * as models from '@/models/Transfers'
import {Optional} from '@/models/TypeHelpers'
import {useFiltersStore} from '@/store/pinia/filtersStore'

export const useStockTransfersStore = defineStore('stockTransfers', {
  state: (): State => ({
    loadingTransfersList: {
      combinations: false,
      send: false,
      receive: false,
      build: false,
      accepted: false,
    },
    loadingTransferDetails: false,
    loadingTransferDetailsFree: false,
    deletingAcceptedTransfer: false,
    detailsRowExpanded: {
      combinations: false,
      send: false,
      receive: false,
      build: false,
      accepted: false,
    },
    transferTablesData: {
      combinations: {} as models.Combination,
      send: {} as models.Send,
      receive: {} as models.Receive,
      free: [] as models.Free[],
      totals_free: {} as models.TotalsFree,
      childs_free: {} as models.ChildsFree,
      skuNames: {} as models.SkuNames,
      build: {} as models.Build,
      accepted: [] as models.Accepted[],
      mailingData: [] as models.MailingData[]
    },
    transferDetailsData: [],
    transferAccepted: false,
    acceptedTransferStoreKey: null,
    transferDeleted: false,
    deletingOptionAcceptedTransfer: false,
    transfersBuildVisible: false,
    loadingTransferBuildDetails: false,
    buildingTransfers: false,
    transfersBuildForm: { ...buildFormDefaults, ...getSavedBuildFormStores() },
    transferBuildDetailsData: [],
    builtTransfersAcceptedAll: false,
    acceptingAllTransfers: false,
    acceptingTransferDetailsFree: false,
    transfersBuildParams: {} as models.TransfersBuildParams,
  }),
  actions: {
    getStockPageFilters () {
      const stockFiltersStore = useFiltersStore()
      return stockFiltersStore.getPageFiltersNonDefaultValues()
    },
    async getTransfersBoxList (t: models.ExpandableRow, optimized: boolean, dimensions: string[], properties: object) {
      this.loadingTransfersList[t] = true
      try {
        const pageFilters = this.getStockPageFilters()
        const params: TransfersBoxParams = {
          type: t,
          method: optimized ? 'optimized' : 'greedy',
          dimensions,
          ...properties,
          filters: {
            store_key: pageFilters.stores_dc || [],
            to_store_key: pageFilters.to_stores_dc || [],
            group_key: pageFilters.groups || [],
            collection_key: pageFilters.collections || [],
            option_key: pageFilters.options || [],
          }
        }
        const r = await api.getTransferBoxDetails(params)
        this.setTransferTablesData(t, r)
      } catch (error) {
        console.error(error)
      }
      this.loadingTransfersList[t] = false
    },
    async getTransfersList (t: models.ExpandableRow, params: GetTransfersListParams, optimized = false, noReload = false) {
      if (!noReload) {
        this.loadingTransfersList[t] = true
      }
      try {
        const { parent_table, data } = await api.getTransfersList(t, params, optimized)
        if (noReload) {
          return parent_table
        }
        this.setTransferTablesData(t, t === 'accepted' ? parent_table : data)
        if (t === 'accepted' && params.receive === 'all' && params.send === 'all' && !params.option_view) {
          this.setTransferTablesData('mailingData', parent_table)
        }
      } catch (error) {
        console.error(error)
      }
      if (!noReload) {
        this.loadingTransfersList[t] = false
      }
    },
    async getTransfersFree (params: GetTransfersFreeParams) {
      this.loadingTransferDetailsFree = true
      if (params.accept) {
        this.acceptingTransferDetailsFree = true
      }
      try {
        params.filters = this.getStockFilters()
        const { data, total } = await api.getTransfersFree(!!params.accept, params)
        this.transferTablesData.free = data
        this.transferTablesData.totals_free = total
      } catch (error) {
        console.error(error)
      }
      this.loadingTransferDetailsFree = false
      this.acceptingTransferDetailsFree = false
    },
    getStockFilters (): Record<string, unknown> {
      const filters: Record<string, unknown> = {}
      const keysMap: Record<string, string> = {
        stores_dc: 'store_key',
        to_stores_dc: 'to_store_key',
        groups: 'group_key',
        collections: 'collection_key',
        options: 'option_key',
      }
      const pageFilters = this.getStockPageFilters()
      for (const key in pageFilters) {
        filters[keysMap[key]] = pageFilters[key]
      }
      return filters
    },
    async getTransfersFreeChildRows (payload: GetTransfersFreeChildRowsParams) {
      this.loadingTransferDetailsFree = true
      const optionKey = payload.child_filters.option_key
      const filters = {
        ...this.getStockFilters(),
        ...(payload.additionalFilters || {}),
      }
      try {
        const response = await api.getTransfersFreeChildRows({ ...payload, filters })
        if (!payload.records) {
          this.transferTablesData.childs_free = {
            ...this.transferTablesData.childs_free,
            [optionKey]: response.data
          }
          this.transferTablesData.skuNames = {
            ...this.transferTablesData.skuNames,
            [optionKey]: response.total?.sku_names
          }
        }
        return response.records
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingTransferDetailsFree = false
      }
    },
    async getTransferDetails (type: string, params: GetTransferDetailsParams, optimized = false) {
      this.loadingTransferDetails = true
      try {
        this.transferDetailsData = await api.getTransferDetails(type, params, optimized)
        this.loadingTransferDetails = false
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingTransferDetails = false
      }
    },
    async getTransferBoxDetails (params: TransfersBoxParams) {
      this.loadingTransferDetails = true
      try {
        const { data } = await api.getTransferBoxDetails(params)
        this.transferDetailsData = data
        return data
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingTransferDetails = false
      }
    },
    async getTransferDetailsTSQL (params: TransferDetailsTSQLParams) {
      this.loadingTransferBuildDetails = true
      try {
        const { child_table } = await api.getTransferDetailsTSQL(params)
        this.transferBuildDetailsData = child_table
        return child_table
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingTransferBuildDetails = false
      }
    },
    async deleteAcceptedTransfer (params: DeleteAcceptedTransferParams) {
      this.deletingAcceptedTransfer = true
      this.transferDeleted = false
      try {
        await api.deleteAcceptedTransfer(params.option_key, params.store_key, params.to_store_key, params.type)
        setTimeout(() => {
          this.transferDeleted = true
        }, 1000)
      } catch (error) {
        console.error(error)
      } finally {
        this.deletingAcceptedTransfer = false
      }
    },
    async deleteOptionAcceptedTransfer (params: DeleteAcceptedTransferParams) {
      try {
        await api.deleteAcceptedTransfer(params.option_key, params.store_key, params.to_store_key, params.type, params.isChild)
      } catch (error) {
        console.error(error)
      }
    },
    async saveTransfers (transfersToSave: api.SaveTransferRequest[]) {
      try {
        return await api.saveTransfers(transfersToSave)
      } catch (error) {
        console.error(error)
      }
    },
    async acceptTransfer (payload: api.SaveTransferRequest[]) {
      this.transferAccepted = false
      if (payload.length === 0) {
        return
      }
      this.acceptedTransferStoreKey = payload[0].store_key
      try {
        await api.saveTransfers(payload)
        setTimeout(() => {
          this.transferAccepted = true
          this.transferDetailsData = []
        }, 1000)
      } catch (error) {
        console.error(error)
      }
    },
    async acceptFreeTransfer (payload: api.SaveTransferRequest[]) {
      this.acceptingTransferDetailsFree = true
      try {
        await api.saveTransfers(payload)
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => {
          this.acceptingTransferDetailsFree = false
        }, 500)
      }
    },
    async acceptAllBuiltTransfers (accept: boolean, store_exclusion: boolean) {
      this.builtTransfersAcceptedAll = false
      this.acceptingAllTransfers = true
      try {
        await api.acceptAllBuiltTransfers({ ...this.transfersBuildParams, accept, store_exclusion })
        setTimeout(() => {
          this.builtTransfersAcceptedAll = true
        }, 1000)
      } catch (error) {
        console.error(error)
      } finally {
        this.acceptingAllTransfers = false
      }
    },
    async buildTransfers (params: models.TransfersBuildParams) {
      this.buildingTransfers = true
      this.transfersBuildParams = params
      try {
        const { parent_table, total } = await api.buildTransfers(params)
        this.transferTablesData.build = {
          data: parent_table,
          total: total,
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.buildingTransfers = false
      }
    },
    async saveSettings () {
      const generalStore = useGeneralStore()
      const payload = {
        transfers_settings: generalStore.appConfig?.transfer_settings[0].transfers_settings,
        transfers_slicers: generalStore.appConfig?.transfers_slicers,
      }
      try {
        await api.saveSettings(payload)
      } catch (error) {
        console.error(error)
      }
    },
    setTransferTablesData (t: models.TransferTablesDataKeys, value: unknown) {
      switch (t) {
      case 'combinations':
        this.transferTablesData.combinations = value as models.Combination
        break
      case 'send':
        this.transferTablesData.send = value as models.Send
        break
      case 'receive':
        this.transferTablesData.receive = value as models.Receive
        break
      case 'free':
        this.transferTablesData.free = value as models.Free[]
        break
      case 'totals_free':
        this.transferTablesData.totals_free = value as models.TotalsFree
        break
      case 'childs_free':
        this.transferTablesData.childs_free = value as models.ChildsFree
        break
      case 'build':
        this.transferTablesData.build = value as models.Build
        break
      case 'accepted':
        this.transferTablesData.accepted = value as models.Accepted[]
        break
      case 'mailingData':
        this.transferTablesData.mailingData = value as models.MailingData[]
        break
      }
    },
    setTransferDetailsData (data: models.TransferDetail[]) {
      this.transferDetailsData = data
    },
    setDetailsRowExpanded (row: models.ExpandableRow, value: boolean) {
      this.detailsRowExpanded[row] = value
    },
    setAcceptingTransferDetailsFree (p: boolean) {
      this.acceptingTransferDetailsFree = p
    },
    setTransfersBuildVisible (p: boolean) {
      this.transfersBuildVisible = p
    },
    setTransferBuildDetailsData (p: models.TransferBuildDetail[]) {
      this.transferBuildDetailsData = p
    },
    resetBuildForm () {
      localStorage.setItem('buildForm', JSON.stringify(buildFormDefaults))
      this.transfersBuildForm = { ...buildFormDefaults }
    },
    setBuildForm (payload: models.TransfersBuildForm) {
      localStorage.setItem('buildForm', JSON.stringify(payload))
      this.transfersBuildForm = payload
    }
  }
})

// ------------------------------ Helpers ------------------------------
function getSavedBuildFormStores () {
  try {
    const {fromStores, toStores} = JSON.parse(localStorage.getItem('buildForm') || '')
    return {
      fromStores: fromStores || [],
      toStores: toStores || []
    }
  } catch (_) {
    return {
      fromStores: [],
      toStores: []
    }
  }
}

const buildFormDefaults: models.TransfersBuildForm = {
  fromStores: [],
  toStores: [],
  method: 'fairshare',
  to_method: 'all',
  to_delta: 0,
  delta: 0,
  split: 'fairshare',
}

// ------------------------------ Types ------------------------------
export interface State {
  loadingTransfersList: models.LoadingTransfersList;
  loadingTransferDetails: boolean;
  loadingTransferDetailsFree: boolean;
  deletingAcceptedTransfer: boolean;
  detailsRowExpanded: models.DetailsRowExpanded;
  transferTablesData: models.TransferTablesData;
  transferDetailsData: models.TransferDetail[];
  transferAccepted: boolean;
  acceptedTransferStoreKey: Optional<string | number>;
  transferDeleted: boolean;
  deletingOptionAcceptedTransfer: boolean;
  transfersBuildVisible: boolean;
  loadingTransferBuildDetails: boolean;
  buildingTransfers: boolean;
  transfersBuildForm: models.TransfersBuildForm;
  transferBuildDetailsData: models.TransferBuildDetail[];
  builtTransfersAcceptedAll: boolean;
  acceptingAllTransfers: boolean;
  acceptingTransferDetailsFree: boolean;
  transfersBuildParams: models.TransfersBuildParams;
}

export interface TransfersBoxParams {
  type: models.ExpandableRow,
  method: 'optimized' | 'greedy'
  dimensions: string[]
  filters: Record<string, unknown>
}
export interface GetTransfersListParams {
  receive: string
  send: string
  option_view: boolean
}

export interface GetTransferDetailsParams {
  child_store_key: string | number | null;
  child_to_store_key: string | number | null;
  send: 'multi' | 'all';
  receive: 'multi' | 'all';
  type: number;
  filters: {
    group_key: number[];
    collection_key: number[];
    option_key: number[];
    store_key: number[];
    to_store_key: number[];
  };
  child_option_key?: number;
  option_view?: boolean;
}
export interface TransferDetailsTSQLParams {
  store_keys: string[] | number[];
  to_store_keys: string[] | number[];
  method: string;
  to_method: string;
  to_delta: string;
  delta: string;
  split: string;
  child_store_key: string;
  child_to_store_key: string;
  filters: Record<string, unknown>;
  view?: string;
  store_exclusion?: boolean;
}

export interface DeleteAcceptedTransferParams {
  store_key?: string;
  option_key?: string;
  to_store_key?: string;
  type?: string;
  isChild?: boolean;
}

export interface GetTransfersFreeParams {
  view: string;
  accept?: boolean;
  filters?: Record<string, unknown>;
  profit_min?: number;
}

export interface GetTransfersFreeChildRowsParams {
  child_filters: { option_key: string }
  additionalFilters?: Record<string, unknown>
  records?: Record<string, unknown>
}
