<template>
  <v-card class="buy-manage-budget-modal">
    <v-card-title class="text-body-1 font-weight-medium my-2 justify-space-between px-12">
      {{ `${budget && budget.budget_key ? 'Edit Budget -> '+budget.budget_key : 'Add Budget'}` }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text--primary px-12">
      <h6 class="text-subtitle-1 font-weight-medium my-4">General</h6>
      <v-row class="align-baseline mb-0">
        <v-col class="pt-0 pb-2 pr-8">
          <v-text-field
            :value="budget.budget_name"
            clearable
            label="Budget name"
            :error-messages="errorsList.budget_name"
            @input="budgetChanged"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="align-baseline my-0">
        <v-col class="py-0 pr-8">
          <v-autocomplete
            :menu-props="{
              left: true,
            }"
            v-model="budget.budgets_external_key"
            :items="externalBudgetsOptions"
            clearable
            :error-messages="errorsList.budgets_external_key"
            class="pt-0"
            label="External budget key"
            @input="budget.properties = {...externalBudgetDetails.properties}; budget.dimensions = externalBudgetDetails.dimensions; budget.source = 'default'"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row class="align-baseline my-0">
        <v-col class="py-0 pr-8">
          <v-autocomplete
            :menu-props="{
              left: true,
            }"
            v-model="budget.source"
            :items="externalBudgetDetails.sources"
            clearable
            :error-messages="errorsList.source"
            class="pt-0"
            label="Source"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <h6 class="text-subtitle-1 font-weight-medium" v-if="budget.budgets_external_key">Properties</h6>
      <v-row class="align-baseline mb-4 ma-0">
        <v-row class="py-0 align-center " v-for="(propertyValue,property) in externalBudgetDetails.properties" :key="property"
        >
          <v-col cols="3">
            <h4>{{formatString(property)}}:</h4>
          </v-col>
          <v-col cols="6">
            <collapsible-select
              show-select-all
              multiple
              :error-messages="errorsList.properties[property]"
              autocomplete
              v-model="budget.properties[property]"
              label="Properties Values"
              @input="$set(budget, 'dimensions', Object.keys(budget.properties))"
              :items="propertyValue.map(value=> {return {text: value, value,}})"
            ></collapsible-select>
          </v-col>
          <v-col cols="1">
            <v-icon @click="deleteProperty(property, propertyValue)">delete</v-icon>
          </v-col>
        </v-row>
      </v-row>
      <h6 class="text-subtitle-1 font-weight-medium mb-2 pt-0"
        v-if="budget.budgets_external_key"
        style="margin-top: -6px;">Dimensions</h6>

      <v-col class="align-baseline my-0 justify-space-between py-0" v-if="budget.budgets_external_key">
        <v-row class="py-0 px-0 pb-3 xs6 flex mt-0">
          <collapsible-select
            clearable
            :items="externalBudgetDetails.dimensions"
            v-model="budget.dimensions"
            label="Dimension"
            disabled
            class="my-0 pb-0"
          />
        </v-row>
      </v-col>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions class="col">
      <v-row class="dialog-footer text-right py-2 px-4">
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="$emit('close')"
              >Cancel</v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                @click="handleBudgetSave"
                class="mx-4"
              >Save</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { formatString, getHeadersWithAccessToken } from '@/variables'
import axios from '../services/axios'
import CollapsibleSelect from '@/components/CollapsibleSelect'
import baseURLs from '@/api/APIBaseURLs'
import { saveBudget } from '@/api/budgetAPI'

export default {
  name: 'BuyManageBudgetModal',
  components: { CollapsibleSelect },
  props: {
    budget_key: {
      type: Number,
      default () {
        return null
      }
    },
    budgets: {
      type: Array,
      default () {
        return []
      }
    },
    externalBudgets: {
      type: Array,
      default () {
        return []
      }
    },
    isEdit: {
      type: Boolean,
      default () {
        return false
      }
    },
    properties: {}
  },
  data () {
    return {
      errorsList: { properties: {} },
      lastProperty: '',
      lastDimension: '',
      name_error: '',
      propertiesValues: {},
      propertiesList: [],
      budget: {
        budget_name: '',
        dimensions: [
        ],
        properties: {},
        budgets_external_key: null
      },
    }
  },
  computed: {
    externalBudgetsOptions () {
      return this.externalBudgets.map(b => ({
        text: b.budgets_external_key,
        value: b.budgets_external_key,
      }))
    },
    externalBudgetDetails () {
      const budgets = this.externalBudgets
        .find((item) => item.budgets_external_key === this.budget.budgets_external_key) || {}
      return Object.assign({}, budgets)
    },
  },
  methods: {
    formatString,
    deleteProperty (property) {
      delete this.budget.properties[property]
      this.budget.dimensions = Object.keys(this.budget.properties)
      this.budget = { ...this.budget }
    },
    budgetChanged (event) {
      // this.budget.budget_name = event
      if (!event) {
        this.name_error = 'Budget name shouldn\'t be empty'
      } else if (event !== this.budget.budget_name && (this.budgets.indexOf(event) !== -1)) {
        this.name_error = 'Budget name already exists'
      } else {
        this.name_error = ''
      }
      this.budget.budget_name = event
    },
    checkSave () {
      let ableToSave = true
      if (!this.budget.budget_name) {
        this.errorsList.budget_name = 'Budget name must not be empty'
        ableToSave = false
      } else {
        this.errorsList.budget_name = ''
      }
      if (!this.budget.budgets_external_key) {
        this.errorsList.budgets_external_key = 'You have to select external budget key'
        ableToSave = false
      } else {
        this.errorsList.budgets_external_key = ''
      }
      if (!this.budget.source || this.budget.source === 'default') {
        this.errorsList.source = 'You have to select source'
        ableToSave = false
      } else {
        this.errorsList.source = ''
      }
      this.errorsList = { ...this.errorsList }
      return ableToSave
    },
    async handleBudgetSave () {
      if (!this.checkSave()) {
        return
      }
      await saveBudget(this.budget)
      this.$emit('save')
    }
  },
  mounted () {
    if (this.isEdit) {
      const url = baseURLs.manageAPI + '/budget?budget_key=' + this.budget_key
      axios.get(url, {
        headers: getHeadersWithAccessToken(),
      })
        .then(({ data: { budget } }) => {
          this.budget = budget
        })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
