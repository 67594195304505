<template>
  <v-card class="jobs-settings mb-12">
    <v-row class="ma-0 pb-6 pt-7 justify-center"><h4> Error Count</h4></v-row>
    <StoresGroupsExpectedChart
      class="pb-15 errorsChart px-4 mr-6"
      ref="errors_count"
      title="errors_count"
      endpoint="settings/jobs/graph_errors"
      forcedHeight="370px"
      :params="{format: 'doa'}"
    />
    <v-row class="ma-0 align-center pl-6 pt-2">
      <h4>Overview</h4>
      <v-col class="text-right pr-8">
        <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
      </v-col>
    </v-row>
    <data-tables
      :data="tableData"
      :page-size="10"
      ref="jobsTable"
      :key="jobsSettingsKey"
      :filters="filters"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 pb-4 mr-2',
      }"
      @row-click="handleRowClick"
    >
      <el-table-column
        width="45">
        <template
          slot-scope="scope">
          <v-icon class="my-2">
            <template>
              {{expandedRows.includes(scope.$index) ? 'expand_more' : 'chevron_right' }}
            </template>
          </v-icon>
        </template>
      </el-table-column>
      <el-table-column
        sortable
        v-for="title in keys"
        :prop="title"
        :label="formatString(title)"
        :key="title"
      >
        <template slot-scope="scope">
          {{scope.row[title]}}
        </template>
      </el-table-column>
      <el-table-column type="expand" :expanded="true" width="1" >
        <template slot-scope="scope">
          <job-expanded-table :row="scope.row"></job-expanded-table>
        </template>
      </el-table-column>
    </data-tables>
  </v-card>
</template>

<script>
import axios from '../../services/axios'
import { formatString } from '@/variables'
import JobExpandedTable from './JobExpandedTable'
import TableSearch from '../TableSearch'
import StoresGroupsExpectedChart from '../Home/StoresGroupsExpectedChart'
import baseURLs from '@/api/APIBaseURLs'

export default {
  name: 'JobsSettings',
  components: { StoresGroupsExpectedChart, JobExpandedTable, TableSearch },
  data () {
    return {
      tableData: [],
      keys: [],
      expandedRows: [],
      jobsSettingsKey: 0,
      filters: [
        {
          value: ''
        }
      ],
    }
  },
  methods: {
    formatString,
    handleSearchChanged (val) {
      this.filters[0].value = val
      this.jobsSettingsKey++
    },
    handleRowClick (row) {
      if (this.expandedRows.includes(row.index)) {
        this.expandedRows = this.expandedRows.filter((key) => key !== row.index)
        this.$refs.jobsTable.$refs.elTable.toggleRowExpansion(row, false)
      } else {
        this.expandedRows = [...this.expandedRows, row.index]
        this.$refs.jobsTable.$refs.elTable.toggleRowExpansion(row, true)
      }
    }
  },
  created () {
    const url = baseURLs.manageAPI + '/data/settings/jobs/table_agg'
    axios.get(url).then(({ data }) => {
      this.tableData = data.data.map((item, index) => {
        return {
          ...item,
          index,
        }
      })
      this.keys = data.keys
    })
  }
}
</script>

<style scoped>

</style>

<style lang="scss">
.jobs-settings {
  .el-table__expanded-cell[class*=cell] {
    padding: 16px;
    background: #f9f9f9;
    &:hover {
      background: #f9f9f9 !important;
    }
  }
  thead {
    th {
      padding: 12px 0;
    }
  }
  .sc-table {
    background: #fff;
  }
  .one-line {
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
<style scoped>
.errorsChart {
  border-bottom: 1px solid #E3E3E3;
}
</style>
