<template>
  <div class="buy-studio-home-page">
    <h3 class="mb-6 mt-6">Studio</h3>

    <v-card min-height="350px" class="my-6 p-relative">
      <v-overlay absolute color="white" :value="loading">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>

      <DummiesTable
        :dummies="dummiesList"
        :dummyProperties="dummyProperties"
        :variantProperties="variantProperties"
        @saved="handleListChange"
        @dummy-added="redirectToDummyPage"
        @dummy-duplicated="redirectToDummyPage"
        @dummy-edit-clicked="redirectToDummyPage"
        @dummy-export-changed="handleDummyExportChanged"
        @batch-upload-done="handleDummiesBatchUploadDone"
        @selection-changed="row=> log(row)"
        @dummy-deleted="handleDummyDeleted"
        @dummy-copy="handleDummyCopy"
      >
        <h4 class="px-5 py-4">Variations / Options</h4>
      </DummiesTable>
    </v-card>
  </div>
</template>

<script>
import columnFilters from '@/mixins/columnFilters'
import DummiesTable from '@/components/Dummies/DummiesTable.vue'
import { loadDummiesList } from '@/api/dummies'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  mixins: [ columnFilters ],
  components: { DummiesTable },
  data () {
    return {
      dummiesList: [],
      dummyProperties: [],
      variantProperties: [],
      tableFilters: [],
      loading: false,
      selectedVariant: null,
    }
  },
  watch: {
    'filtersStore.filtersChanged': {
      handler (newVal) {
        if (newVal) {
          this.handleFilterChanged()
        }
      },
    },
  },
  methods: {
    log (row) {
      const item = this.dummiesList.find(item => row.dummy_key === item.dummy_key)
      item.selected = row.selected
      this.$set(item, 'selected', row.selected)
      this.dummiesList = [...this.dummiesList]
    },
    watchFiltersDataLoaded () {
      this.$watch(
        'filtersStore.filtersDataLoaded',
        function (newVal) {
          if (newVal) {
            this.loadDummies()
          }
        },
        { immediate: true }
      )
    },
    handleFilterChanged () {
      this.loadDummies()
    },
    redirectToDummyPage (dummyKey) {
      this.$router.push(this.getDummyPageUrl(dummyKey))
    },
    getDummyPageUrl (dummyKey) {
      return {
        name: 'buy-studio-item-page',
        query: { dummy_key: dummyKey }
      }
    },
    loadDummies () {
      this.loading = true

      const payload = {
        dummies: this.filtersStore.selectedFiltersOptions['dummies'],
      }
      return loadDummiesList(payload)
        .then(response => {
          response.dummies.forEach(item => {
            item.properties_dummy = item.properties_dummy || {}
            item.properties_variant = item.properties_variant || {}
          })
          this.dummiesList = response.dummies
          this.dummyProperties = response.properties_dummy
          this.variantProperties = response.properties_variant
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleListChange (payload) {
      return loadDummiesList(payload).then(({ dummies }) => this.loadDummiesAfterRefresh(payload, dummies))
    },
    loadDummiesAfterRefresh (payload, data) {
      const changedDummies = this.dummiesList.filter((dummy) => payload.dummy_keys.includes(dummy.dummy_key))
      for (const dummies of changedDummies) {
        const dummy = data.find(item => item.dummy_key === dummies.dummy_key)
        this.$set(dummies, 'status', dummy.status)
      }
    },
    handleVariantSelectionChanged (variant) {
      this.selectedVariant = variant
    },
    handleDummyExportChanged () {
      this.loadDummies()
    },
    handleDummiesBatchUploadDone () {
      this.loadDummies()
    },
    handleDummyDeleted () {
      this.loadDummies()
    },
    handleDummyCopy () {
      this.loadDummies()
    }
  },
  created () {
    // if page has filters, wait till filters data is loaded
    if (this.filtersStore.currentPageFilters.length) {
      this.watchFiltersDataLoaded()
    } else {
      this.loadDummies()
    }
  },

}
</script>

<style lang="scss">
.buy-studio-home-page {
}
</style>
