<template>
  <div class="assortment-factors">
    <v-overlay absolute color="white" :value="stockManageStore.factorsTab.loadingFactors">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <h6 v-if="!optionKey" class="text-subtitle-1 font-weight-medium mt-8 ml-6">Factors</h6>
    <h6 v-else class="text-subtitle-1 font-weight-medium mt-2 ml-4">Factors for option</h6>
    <v-row>
      <v-col class="text-right pr-6">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('factorsTableFilters', factorsColumnsToSearch, val)"
        />
        <v-icon class="mx-2 action-btn-primary" :disabled="!selectedRows[0]" @click="handleEditClick">edit</v-icon>
        <v-icon class="mx-2 action-btn-primary" :disabled="!!selectedRows[0]" @click="handleAddClick">add</v-icon>
        <v-icon class="mx-2 action-btn-danger" :disabled="deleteDisabled" @click="handleDeleteClick">delete</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <data-tables
          style="width: 100%"
          :data="factorsList"
          :page-size="5"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :table-props="{ 'row-key': 'factor_key' }"
          :filters="factorsTableFilters"
          filter-multiple
          @row-click="handleRowClick"
          @filter-change="filters => handleFilterChange('factorsTableFilters', filters)">
          <el-table-column prop="factor_key" width="55">
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-4 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="set_name"
            column-key="set_name"
            label="Option set"
            sortable="custom"
            :filters="getColumnFilterValues('factorsList', 'set_name')"
          >
            <template slot-scope="scope">
              <span>{{ getOptionByKey(scope.row.options_set_key) }}</span>
              <v-chip
                label
                outlined
                small
                color="#4FB4AB"
                class="mr-8 ml-4"
                @click.stop="handleRowOptionSetClick(scope.row)"
              >
                {{getOptionCountByKey(scope.row.options_set_key) }}
              </v-chip>
            </template>
          </el-table-column>
          <el-table-column
            prop="storeNames"
            column-key="storeNames"
            label="Stores"
            sortable="custom"
            :filters="getColumnFilterValues('factorsList', 'storeNames')"
          >
            <template slot-scope="scope">
              <ChipsDropdown :items="scope.row.storeNames" />
            </template>
          </el-table-column>
          <el-table-column prop="date_min" label="Start" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.date_min }}
            </template>
          </el-table-column>
          <el-table-column prop="date_max" label="End" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.date_max }}
            </template>
          </el-table-column>
          <el-table-column prop="factor" label="Factor" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.factor }}
            </template>
          </el-table-column>
          <el-table-column prop="note" label="Note" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.note }}
            </template>
          </el-table-column>
          <el-table-column
            prop="active"
            column-key="active"
            label="Active"
            sortable="custom"
            :filters="getColumnFilterValues('factorsList', 'active')"
          >
            <template slot-scope="scope">
              <div class="d-flex">
                <v-simple-checkbox
                  v-model="scope.row.active"
                  hide-details="auto"
                  color="primary"
                  disabled
                />
              </div>
            </template>
          </el-table-column>

        </data-tables>
      </v-col>
    </v-row>
    <template v-if="!optionKey">
      <v-divider class="mt-6" />
      <h6 class="text-subtitle-1 font-weight-medium mt-8 ml-6">Double factors</h6>
      <v-row>
        <v-col class="text-right pr-6">
          <TableSearch
            class="mx-2"
            @searchChanged="val => handleSearchChanged('doubleFactorsTableFilters', doubleFactorsColumnsToSearch, val)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <data-tables
            style="width: 100%"
            :data="doubleFactorsList"
            :page-size="5"
            :pagination-props="{ pageSizes: [5, 10, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
            :table-props="{ 'row-key': 'factor_key' }"
            :filters="doubleFactorsTableFilters"
            filter-multiple
            @filter-change="filters => handleFilterChange('doubleFactorsTableFilters', filters)">
            <el-table-column
              prop="options_sets"
              column-key="options_sets"
              label="Option sets"
              class-name="pl-4"
              sortable="custom"
              :filters="getColumnFilterValues('doubleFactorsList', 'options_sets')"
            >
              <template slot-scope="scope">
                <ChipsDropdown :items="scope.row.options_sets" />
              </template>
            </el-table-column>
            <el-table-column
              prop="store"
              column-key="store"
              label="Store"
              sortable="custom"
              :filters="getColumnFilterValues('doubleFactorsList', 'store')"
            >
              <template slot-scope="scope">
                <div class="ml-4">
                  {{ scope.row.store }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="date_min" label="Start" sortable="custom">
              <template slot-scope="scope">
                {{ scope.row.date_min }}
              </template>
            </el-table-column>
            <el-table-column prop="date_max" label="End" sortable="custom">
              <template slot-scope="scope">
                {{ scope.row.date_max }}
              </template>
            </el-table-column>
            <el-table-column prop="factors" label="Factors" sortable="custom">
              <template slot-scope="scope">
                <ChipsDropdown :items="scope.row.factors" />
              </template>
            </el-table-column>
          </data-tables>
        </v-col>
      </v-row>
    </template>
    <!-- Form dialog -->
    <v-dialog
      :value="formDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="stockManageStore.factorsTab.dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">{{ `${currentFactor && currentFactor.factor_key ? 'Edit factor' : 'Add factor'}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 text--primary">
          <div class="container pt-0 px-12">
            <template v-if="currentFactor">
              <v-row class="align-baseline mt-4 mb-4">
                <v-col class="py-0">
                  <v-autocomplete
                    v-model="currentFactor.options_set_key"
                    label="Option set"
                    :items="stockManageStore.factorsTab.factorsOptionSetsList"
                    hide-details="auto"
                    item-text="set_name"
                    item-value="options_set_key"
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                  />
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-4 mb-4">
                <v-col class="pt-1">
                  <collapsible-select
                    v-model="currentFactor.store_keys"
                    label="Stores"
                    :items="appConfig.stores_open"
                    :error-messages="currentFactorStoreError"
                    item-text="store_name"
                    item-value="store_key"
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    show-select-all
                    autocomplete
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                    @change="storesChangedDirectly"
                  />
                  <StoresSelectByProperties
                    ref="storesSelectByProperties"
                    class="stores-select"
                    :selected-stores="currentFactor.store_keys"
                    :custom-stores="appConfig?.stores_open"
                    @search-results-changed="storesChanged"
                  />
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-4 mb-4">
                <v-col class="py-0 pr-8">
                  <v-menu
                    ref="dateMinPicker"
                    v-model="dateMinPickerActive"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="currentFactor.date_min"
                        :error-messages="currentFactorFromDateError"
                        :disabled="dateMinDisabled"
                        clearable
                        label="Start"
                        v-on="on"
                        @input="onDateMinInput"
                        @change="onDateMinChange"
                        @click:clear="currentFactor.date_min = null"
                      />
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      :locale-first-day-of-year="4"
                      :show-week="true"
                      no-title
                      v-model="currentFactor.date_min"
                      :max="currentFactor.date_max"
                      @click="dateMinPickerActive = false"
                    />
                  </v-menu>
                </v-col>
                <v-col class="py-0 pl-8">
                  <v-menu
                    ref="dateMaxPicker"
                    v-model="dateMaxPickerActive"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="currentFactor.date_max"
                        :error-messages="currentFactorToDateError"
                        clearable
                        label="End"
                        v-on="on"
                        @input="onDateMaxInput"
                        @change="onDateMaxChange"
                        @click:clear="currentFactor.date_max = null"
                      />
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      :locale-first-day-of-year="4"
                      :show-week="true"
                      no-title
                      v-model="currentFactor.date_max"
                      :min="currentFactor.date_min"
                      @input="dateMaxPickerActive = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-n1">
                <v-col class="py-0">
                  <div class="d-flex align-center">
                    <label for="factor-value" class="v-label slider-label">Factor</label>

                    <div class="v-label mr-3 ml-16">0</div>
                    <v-slider
                      v-model="currentFactor.factor"
                      id="factor-value"
                      thumb-label
                      hide-details="auto"
                      track-color="#ccc"
                      track-fill-color="primary"
                      :thumb-size="24"
                      :min="0"
                      :max="5.0"
                      :step="0.1"
                    />
                    <div class="v-label ml-3">5</div>
                  </div>
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-5 mb-4">
                <v-col class="py-0">
                  <v-text-field
                    v-model="currentFactor.note"
                    label="Note"
                    hide-details="auto"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    depressed
                    text
                    @click="handleSaveCancel"
                  >Cancel</v-btn>
                  <v-btn
                    color="primary"
                    depressed
                    outlined
                    @click="handleSaveClick"
                    class="mx-4"
                  >Save</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Option set form dialog -->
    <v-dialog
      :value="optionSetFormDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="1200px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="stockManageStore.factorsTab.dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title
          v-if="currentOptionSet"
          class="text-body-1 font-weight-medium mx-4 my-2"
        >
          <template v-if="currentOptionSetEditMode">
            {{ `${currentOptionSet.options_set_key ? 'Edit' : 'Add'} option set` }}
          </template>
          <template v-else>
            Option set
            <v-icon class="mx-2">arrow_forward</v-icon>
            {{ currentOptionSet.set_name }}
            <v-btn
              color="primary"
              small
              depressed
              outlined
              @click="currentOptionSetEditClick"
              class="ml-4"
            >Edit</v-btn>
          </template>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 text--primary">
          <OptionSetForm
            ref="optionSetForm"
            tab="factors"
            :option-set="currentOptionSet"
            :edit-mode="currentOptionSetEditMode"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <template v-if="currentOptionSetEditMode">
                <v-btn
                  color="primary"
                  depressed
                  text
                  @click="handleOptionSetSaveCancel"
                >Cancel</v-btn>
                <v-btn
                  color="primary"
                  depressed
                  outlined
                  @click="handleOptionSetSaveConfirm"
                  class="mx-4"
                >Save</v-btn>
              </template>
              <template v-else>
                <v-btn
                  color="primary"
                  depressed
                  text
                  @click="handleOptionSetSaveCancel"
                >Close</v-btn>
              </template>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog -->
    <v-dialog
      :title="selectedRows[0] ? selectedRows[0].name : null"
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="stockManageStore.factorsTab.dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium">Delete factor</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete this factor?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteConfirm"
                class="red--text mx-4"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { startOfDay } from 'date-fns'
import columnFilters from '@/mixins/columnFilters'
import OptionSetForm from '@/components/Assortment/OptionSets/OptionSetForm.vue'
import TableSearch from '@/components/TableSearch.vue'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import ChipsDropdown from '@/components/ChipsDropdown.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import StoresSelectByProperties from '@/components/StoresSelectByProperties.vue'

import { useStockManageStore } from '@/store/pinia/stockManageStore'

export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  mixins: [columnFilters],
  props: {
    optionKey: {
      default: null,
    },
  },
  data () {
    return {
      deleteDialogVisible: false,
      formDialogVisible: false,
      optionSetFormDialogVisible: false,
      currentFactor: null,
      dateMinPickerActive: false,
      dateMaxPickerActive: false,
      currentFactorStoreError: null,
      currentFactorFromDateError: null,
      currentFactorToDateError: null,
      factorsTableFilters: [],
      doubleFactorsTableFilters: [],
      factorsColumnsToSearch: ['set_name', 'store_keys'],
      doubleFactorsColumnsToSearch: ['options_sets', 'store'],
      currentOptionSet: null,
      currentOptionSetEditMode: false,
    }
  },
  components: {
    StoresSelectByProperties,
    OptionSetForm,
    TableSearch,
    CollapsibleSelect,
    ChipsDropdown,
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    editedFactor () {
      return this.stockManageStore.factorsTab.editedFactor
    },
    editedOptionSet () {
      return this.stockManageStore.factorsTab.editedOptionSet
    },
    factorsList () {
      const { factorsList } = this.stockManageStore.factorsTab

      factorsList.forEach(x => {
        x.storeNames = x.store_keys.map(sk => this.getStoreName(sk))
      })

      return factorsList
    },
    doubleFactorsList () {
      return this.stockManageStore.factorsTab.doubleFactorsList
    },
    selectedRows () {
      return this.factorsList.filter(r => r.selected)
    },
    deleteDisabled () {
      return !this.selectedRows[0] ||
        this.selectedRows[0].in_use ||
        startOfDay(new Date(this.selectedRows[0].date_min)) < startOfDay(new Date())
    },
    dateMinDisabled () {
      return this.selectedRows[0] &&
        (startOfDay(new Date(this.selectedRows[0].date_min)) < startOfDay(new Date()))
    }
  },
  watch: {
    'currentFactor.date_min': {
      handler: function (val) {
        if (!val || this.currentFactor.date_max === null) {
          return
        }
        this.validateDates()
      },
    },
    'currentFactor.date_max': {
      handler: function (val) {
        if (!val || this.currentFactor.date_min === null) {
          return
        }
        this.validateDates()
      },
    },
  },
  created () {
    this.loadFactors()
  },
  methods: {
    storesChangedDirectly (stores) {
      this.$refs.storesSelectByProperties?.handleDirectStoresChange(stores)
    },
    storesChanged (stores) {
      this.currentFactor.store_keys = Array.from(stores)
    },
    async loadFactors () {
      return this.stockManageStore.loadFactors(this.optionKey)
    },
    validateDates () {
      if (!this.currentFactor.date_min) {
        this.currentFactorFromDateError = 'Required'
      } else {
        this.currentFactorFromDateError = null
      }

      if (!this.currentFactor.date_max) {
        this.currentFactorToDateError = 'Required'
      } else if (startOfDay(new Date(this.currentFactor.date_max)) < startOfDay(new Date())) {
        this.currentFactorToDateError = 'Must be greater or equal to today'
      } else if (startOfDay(new Date(this.currentFactor.date_max)) < startOfDay(new Date(this.currentFactor.date_min))) {
        this.currentFactorToDateError = 'Must be greater than start date'
      } else {
        this.currentFactorToDateError = null
      }
    },
    getOptionByKey (optionKey) {
      if (!optionKey || !this.stockManageStore.capacityTab.capacityOptionSetsList) {
        return ''
      }
      const arr = this.stockManageStore.capacityTab.capacityOptionSetsList.filter(s =>
        optionKey === s.options_set_key).map(s => s.set_name)
      return arr.length > 0 ? arr[0] : ''
    },
    getOptionCountByKey (optionKey) {
      if (!optionKey || !this.stockManageStore.capacityTab.capacityOptionSetsList) {
        return ''
      }
      const arr = this.stockManageStore.capacityTab.capacityOptionSetsList.filter(s =>
        optionKey === s.options_set_key).map(s => s.options)
      return arr.length > 0 ? arr[0] : 0
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
    },
    handleEditClick () {
      this.formDialogVisible = true
      this.stockManageStore.getFactor(this.selectedRows[0].factor_key)
        .then(() => {
          this.currentFactor = { ...this.editedFactor }
        })
    },
    handleAddClick () {
      this.currentFactor = {
        options_set_key: null,
        store_keys: [],
        date_min: null,
        date_max: null,
        factor: 0.2,
        note: null,
      }
      this.formDialogVisible = true
    },
    handleDeleteClick () {
      this.deleteDialogVisible = true
    },
    handleDeleteConfirm () {
      this.stockManageStore.deleteFactor(this.selectedRows[0].factor_key)
        .then(() => {
          this.loadFactors()
          this.deleteDialogVisible = false
        })
    },
    handleSaveClick () {
      if (!this.validateFactor()) {
        return
      }

      this.stockManageStore.saveFactor(this.currentFactor)
        .then(() => {
          this.formDialogVisible = false
          this.resetForm()
          this.loadFactors()
          this.stockManageStore.loadDoubleFactors()
        })
    },
    handleSaveCancel () {
      this.formDialogVisible = false
      this.resetForm()
    },
    validateFactor () {
      this.currentFactorStoreError = null
      this.currentFactorFromDateError = null
      this.currentFactorToDateError = null

      if (!this.currentFactor.store_keys.length) {
        this.currentFactorStoreError = 'At least one required'
      }
      this.validateDates()
      return !this.currentFactorStoreError && !this.currentFactorFromDateError && !this.currentFactorToDateError
    },
    resetForm () {
      this.currentFactor = null
      this.currentFactorStoreError = null
      this.currentFactorFromDateError = null
      this.currentFactorToDateError = null
    },
    getStoreName (key) {
      const store = (this.appConfig?.stores || []).find(s => s.store_key === key)

      return store ? store.store_name : null
    },
    handleRowOptionSetClick (row) {
      const payload = {
        tab: 'factors',
        key: row.options_set_key,
      }
      this.currentOptionSetEditMode = false
      this.optionSetFormDialogVisible = true
      this.stockManageStore.getOptionSet(payload)
        .then(() => {
          this.currentOptionSet = { ...this.editedOptionSet }
          this.$nextTick(this.$refs.optionSetForm.searchOptions)
        })
    },
    handleOptionSetSaveConfirm () {
      if (!this.$refs.optionSetForm.validateOptionSet()) {
        return
      }

      const payload = this.$refs.optionSetForm.getSavePayload()

      this.stockManageStore.setDialogLoading({
        tab: 'factors',
        value: true,
      })
      this.stockManageStore.saveOptionSet(payload)
        .then(() => {
          this.optionSetFormDialogVisible = false
          this.$refs.optionSetForm.resetForm()

          this.loadFactors().finally(() => {
            this.stockManageStore.setDialogLoading({
              tab: 'factors',
              value: false,
            })
          })
        })
    },
    handleOptionSetSaveCancel () {
      this.optionSetFormDialogVisible = false
      this.$refs.optionSetForm.resetForm()
    },
    currentOptionSetEditClick () {
      this.currentOptionSetEditMode = true
    },
    onDateMinInput (v) {
      this.dateMinPickerActive = false
      this.currentFactor.date_min = v
    },
    onDateMinChange () {
      this.validateDates()
    },
    onDateMaxInput (v) {
      this.dateMaxPickerActive = false
      this.currentFactor.date_max = v
    },
    onDateMaxChange () {
      this.validateDates()
    }
  }
}
</script>

<style lang="scss">
.assortment-factors {
}
</style>
