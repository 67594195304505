<template>
  <div class="row mb-5" >

    <v-overlay absolute color="white" :value="ordersLoading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-card height="400px" v-if="!projection_table || !sku_table"></v-card>
    <div class="col-12">
      <h4 class="my-6 mx-4">Projection</h4>
      <span class="mx-4" style="font-size: 14px;color: orange" v-if="!ordersLoading && Object.keys(reorderData).length <=1">No commitment for shop item</span>
      <ChartElement
        v-else
        title="shop_item_reorder"
        id="shop_item_reorder"
        :chartData="reorderData"
        :params="{}"
        forcedHeight="300px"
        :padding="true"
        :fixedHeight="false"
      />
    </div>

    <v-tabs v-model="activeTab">
      <v-tabs-slider />
      <v-tab key="Orders" class="px-8"
        style="width: 180px">Orders</v-tab>
      <v-tab key="Weeks" class="px-8"
        style="width: 180px">Weeks</v-tab>
      <v-tab key="SKU" class="px-8"
        style="width: 180px">SKU</v-tab>
      <v-tab key="SKU%" class="px-8"
        style="width: 180px">SKU%</v-tab>
      <v-tab :disabled="!carryOverVisible" key="Carry Over" class="px-8"
        style="width: 180px">Carry Over</v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab" style="width: 100%">
      <v-tab-item key="Orders" :transition="false" :reverse-transition="false"
      >
        <v-col>
          <slot name="orders"></slot>
        </v-col>
      </v-tab-item>
      <v-tab-item key="Weeks" :transition="false" :reverse-transition="false">
        <div class="col-12" v-if="projection_table">
          <v-row>
            <v-col class="text-right pr-6">
              <TableSearch
                class="mx-2"
                @searchChanged="val => handleSearchChanged('projection_table_filters', projection_table.columns, val)"
              />
              <DataExport
                @item-click="downloadData(projection_table, $event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <data-tables
                ref="dataTables"
                style="width: 100%"

                :table-props="{
                  'summary-method': this.getSummary,
                  'show-summary': true,
                }"
                class="main-table"
                :data="projection_table.table"
                :filters="projection_table_filters"
                :page-size="10"
                :pagination-props="{
                  pageSizes: [5, 10,15, 25, 50],
                  class: 'el-pagination text-right mt-6 mb-4 mr-2',
                }"
              >
                <el-table-column
                  v-for="col in projection_table.columns"
                  :key="col"
                  :prop="col"
                  :label="col | formatString"
                  sortable
                  class-name="pl-4"
                >
                  <template slot-scope="scope">
                    <div v-if="col === 'Week'">
                      {{ parseWeek(scope.row[col]) }}
                    </div>
                    <div v-else>
                      {{ scope.row[col] | formatThousands }}
                    </div>
                  </template>
                </el-table-column>
              </data-tables>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>

      <v-tab-item key="SKU" :transition="false" :reverse-transition="false">
        <div class="col-12"
          v-if="sku_table">
          <v-row>
            <v-col class="text-right pr-6">
              <TableSearch
                class="mx-2"
                @searchChanged="val => handleSearchChanged('sku_table_filters', sku_table.columns, val)"
              />
              <DataExport
                @item-click="downloadData(sku_table, $event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <data-tables
                ref="dataTables"
                style="width: 100%"
                class="main-table"
                :data="sku_table.table"
                :filters="sku_table_filters"
                :page-size="10"
                :pagination-props="{
                  pageSizes: [5, 10,15, 25, 50],
                  class: 'el-pagination text-right mt-6 mb-4 mr-2 d-none',
                }"
              >
                <el-table-column
                  v-for="col in sku_table.columns"
                  :key="col"
                  :prop="col"
                  :label="col | formatString"
                  sortable
                  class-name="pl-4"
                >
                  <template slot-scope="scope">
                    <div v-if="col === 'Week'">
                      {{ parseWeek(scope.row[col]) }}
                    </div>
                    <div v-else>
                      {{ scope.row[col] | formatThousands }}
                    </div>
                  </template>
                </el-table-column>
              </data-tables>
            </v-col>
          </v-row>
        </div>

      </v-tab-item>
      <v-tab-item key="SKU%" :transition="false" :reverse-transition="false">
        <div class="col-12"
          v-if="sku_table_perc">
          <v-row>
            <v-col class="text-right pr-6">
              <TableSearch
                class="mx-2"
                @searchChanged="val => handleSearchChanged('sku_table_perc_filters', sku_table_perc.columns, val)"
              />
              <DataExport
                @item-click="downloadData(sku_table_perc, $event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <data-tables
                ref="dataTables"
                style="width: 100%"
                class="main-table"
                :data="sku_table_perc.table"
                :filters="sku_table_perc_filters"
                :page-size="10"
                :pagination-props="{
                  pageSizes: [5, 10,15, 25, 50],
                  class: 'el-pagination text-right mt-6 mb-4 mr-2 d-none',
                }"
              >
                <el-table-column
                  v-for="col in sku_table_perc.columns"
                  :key="col"
                  :prop="col"
                  :label="col | formatString"
                  sortable
                  class-name="pl-4"
                >
                  <template slot-scope="scope">
                    <div v-if="col === 'Type'">
                      {{ scope.row[col] }}
                    </div>
                    <div v-else>
                      {{ scope.row[col] }} <span v-if="scope.row[col]">%</span>
                    </div>
                  </template>
                </el-table-column>
              </data-tables>
            </v-col>
          </v-row>
        </div>

      </v-tab-item>
      <v-tab-item key="Carry Over" :transition="false" :reverse-transition="false">
        <slot name="carry over"></slot>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ChartElement from '@/chart/ChartElement'
import TableSearch from '@/components/TableSearch.vue'
import DataExport from '@/components/DataExport'
import { downloadData } from '@/utils'
import columnFilters from '@/mixins/columnFilters'
import { parseWeek } from '@/variables'
import { round } from 'lodash'
export default {
  mixins: [columnFilters],
  name: 'ShopItemProjection',
  data () {
    return {
      projection_table_filters: [],
      sku_table_filters: [],
      sku_table_perc_filters: [],
      activeTab: 0,
    }
  },
  props: {
    reorderData: {
      type: Object
    },
    ordersLoading: {
      type: Boolean
    },
    carryOverVisible: {
      type: Boolean
    },
    projection_table: {
      type: Object
    },
    sku_table: {
      type: Object
    },
    sku_table_perc: {
      type: Object
    }
  },
  methods: {
    getSummary (params) {
      const sums = []
      const { columns } = params
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total'
          return
        }
        const emptyColumns = [ 'Inbounds', 'Commitment', 'Sales LY' ]
        if (!emptyColumns.includes(column.property)) {
          sums[index] = ''
          return
        }
        sums[index] = round(this.projection_table.table.reduce((acc, cur) => acc + cur[column.property], 0), 2)
      })
      return sums
    },

    parseWeek,
    downloadData (table, sep) {
      const data = table.table.map(
        row => table.columns.reduce(
          (acc, cur) => Object.assign(acc, { [cur]: row[cur] }),
          {}
        )
      )

      downloadData(sep, data, 'overview_dimensions_overview')
    },
  },
  components: { DataExport, ChartElement, TableSearch }
}
</script>

<style scoped>

</style>
