import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'
import { StockOptionExclusion } from '@/models/Option'

export async function getExclusions (): Promise<StockOptionExclusion[]> {
  const url = baseURLs.manageAPI + '/action?module=stock&action_type=options_exclude'
  const response = await axios.get(url)
  return response.data.data as StockOptionExclusion[]
}

