<template>
  <resizable @resize="handleResize">
    <div id="strategy-collection-matrix">
      <v-row class="align-baseline">
        <v-col cols="2">
          <v-select
            label="Dimension"
            v-model="selectedDimension"
            :items="dimensionOptions"
            class="text-body-2"
            hide-details
            @change="handleSelectedDimensionChange"
          />
        </v-col>
        <v-col cols="8" class="text-center">
          <v-btn-toggle
            v-model="currentMatrixSize"
            mandatory
            dense
            active-class="bg-gray"
            light
          >
            <v-btn value="mini" small color="white">
              <span class="text-capitalize">Mini</span>
            </v-btn>
            <v-btn value="small" small color="white">
              <span class="text-capitalize">Small</span>
            </v-btn>
            <v-btn value="large" small color="white">
              <span class="text-capitalize">Large</span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="2" class="pb-0 pt-0 text-right">
          <v-menu
            :close-on-content-click="false"
            attach="#strategy-collection-page"
            offset-y
            left
            min-width="450"
            max-width="450"
            nudge-bottom="20"
            content-class="settings-menu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on">tune</v-icon>
            </template>

            <Settings
              :metricsListProp="matrixMetrics"
              :selected-metrics-prop="selectedMetrics"
              :selected-show-total-as-prop="selectedShowTotalAs"
              @selectedMetrics:change="handleSelectedMetricsChange"
              @selectedOrder:changed="handleSelectedOrderChange"
              @selectedTotals:changed="handleSelectedTotalsChange"
              @selectedShowTotalAs:changed="handleSelectedTotalAsChanged"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" :class="matrixExpanded ? 'd-none' : ''">
          <div class="d-flex justify-center">
            <div class="period-select">
              <v-select
                v-model="selectedPeriod1"
                :items="periodsList"
                class="text-body-2 mb-4"
                hide-details
              />
            </div>
          </div>
          <MatrixTable
            ref="leftTable"
            :table-data="period1TableData"
            :table-shares="periodsTablesShares[0]"
            :table-totals="periodsTablesTotals[0]"
            :dimension-column="selectedDimension"
            :sum-metrics="selectedSum"
            :visible-metrics="selectedMetrics"
            :show-share="selectedShowTotalAs.includes('share')"
            :show-value="selectedShowTotalAs.includes('value')"
            :is-planned="selectedPeriod1 === 'planned'"
            :size="currentMatrixSize"
            :selectedOrder="selectedOrder"
            @product-deleted="prod => $emit('product-deleted', prod)"
          />
        </v-col>
        <v-col :cols="matrixExpanded ? 12 : 6" class="p-relative">
          <v-icon
            class="c-pointer action-btn-primary expand-matrix-btn"
            :class="matrixExpanded ? 'matrix-expanded' : ''"
            @click="handleToggleMatrixClick"
          >
            {{ matrixExpanded ? 'chevron_right' : 'chevron_left' }}
          </v-icon>
          <div class="d-flex justify-center">
            <div class="period-select">
              <v-select
                v-model="selectedPeriod2"
                :items="periodsList"
                class="text-body-2 mb-4"
                hide-details
              />
            </div>
          </div>
          <MatrixTable
            ref="rightTable"
            :table-data="period2TableData"
            :table-shares="periodsTablesShares[1]"
            :table-totals="periodsTablesTotals[1]"
            :dimension-column="selectedDimension"
            :sum-metrics="selectedSum"
            :visible-metrics="selectedMetrics"
            :show-share="selectedShowTotalAs.includes('share')"
            :show-value="selectedShowTotalAs.includes('value')"
            :is-planned="selectedPeriod2 === 'planned'"
            :analyticsProperties="analyticsProperties"
            :size="currentMatrixSize"
            :selectedOrder="selectedOrder"
            show-deltas
            @item:dropped="$emit('item:dropped')"
            @product-deleted="prod => $emit('product-deleted', prod)"
          />
        </v-col>
      </v-row>
    </div>
  </resizable>
</template>

<script>
import { groupBy, uniq, orderBy, upperFirst, round, debounce } from 'lodash'
import { getYear } from 'date-fns'
import Resizable from '@/components/Utility/Resizable.vue'
import Settings from '@/components/Strategy/CollectionMatrix/Settings.vue'
import MatrixTable from '@/components/Strategy/CollectionMatrix/MatrixTable.vue'

export default {
  name: 'StrategyCollectionMatrix',
  components: { Settings, MatrixTable, Resizable },
  props: {
    optionsProperties: {
      type: Array,
      default: () => ([]),
    },
    periodsData: {
      type: Array,
      default: () => ([]),
    },
    periodsOptionsGrouped: {
      type: Object,
      default: () => ({}),
    },
    analyticsProperties: {
      type: Object,
      default: () => ({})
    },
  },
  data () {
    this.matrixMetrics = [{
      value: 'original_value',
      text: 'Original value',
    }, {
      value: 'cost_value',
      text: 'Cost value',
    }, {
      value: 'initial_margin',
      text: 'Initial margin',
    }, {
      value: 'original_price',
      text: 'Original price',
    }, {
      value: 'cost_price',
      text: 'Cost price',
    }, {
      value: 'pieces',
      text: 'Pieces',
    }, {
      value: 'stars',
      text: 'Stars',
    }, {
      value: 'stores',
      text: 'Stores',
    }, {
      value: 'revenue',
      text: 'Revenue',
    }, {
      value: 'sales',
      text: 'Sales',
    }, {
      value: 'margin',
      text: 'Margin',
    }, {
      value: 'sellthrough_fp',
      text: 'Sellthrough FP',
    }, {
      value: 'lifetime',
      text: 'Lifetime',
    }, {
      value: 'gmroi',
      text: 'Gmroi',
    }, {
      value: 'potential_missed',
      text: 'Potential missed',
    }]
    this.alignTablesDebounce = debounce(this.alignTables, 100)

    const selectedMetrics = JSON.parse(localStorage.getItem('strategyCollectionMatrixSelectedMetrics')) || this.matrixMetrics
    const selectedDimension = JSON.parse(localStorage.getItem('strategyCollectionMatrixSelectedDimension')) || null
    const selectedPeriod1 = JSON.parse(localStorage.getItem('strategyCollectionMatrixSelectedPeriod1')) || null
    const selectedPeriod2 = JSON.parse(localStorage.getItem('strategyCollectionMatrixSelectedPeriod2')) || null
    const selectedShowTotalAs = JSON.parse(localStorage.getItem('strategyCollectionSelectedShowTotalAs')) || ['value']

    return {
      selectedDimension: selectedDimension || 'original_price',
      selectedOrder: 'pieces',
      selectedSum: ['width', 'pieces'],
      selectedShowTotalAs,
      selectedPeriod1,
      selectedPeriod2,
      selectedMetrics,
      currentMatrixSize: 'large',
      matrixExpanded: false,
    }
  },
  computed: {
    dimensionOptions () {
      const result = [{
        value: 'original_price',
        text: 'Original price',
      }, {
        value: 'cost_price',
        text: 'Cost price',
      }, {
        value: 'intros',
        text: 'Intros',
      }, {
        value: 'stars',
        text: 'Stars',
      }, {
        value: 'stores',
        text: 'Stores',
      }, {
        value: 'lifetime',
        text: 'Lifetime',
      }]

      this.optionsProperties.forEach(op => {
        const parts = op.split('/')

        result.push({
          value: op,
          text: this.$options.filters.formatString(parts.slice(-1)[0])
        })
      })

      return result
    },
    periodsList () {
      if (!this.periodsData) {
        return []
      }

      return [
        ...this.periodsData.map(p => ({
          text: p.period,
          value: parseInt(p.period),
        })),
        {
          text: 'Collection',
          value: 'planned',
        }
      ]
    },
    periodsTablesData () {
      if (!this.periodsOptionsGrouped || !this.periodsOptionsGrouped['planned']) {
        return []
      }

      return this.getMatrixTablesData(this.periodsOptionsGrouped)
    },
    periodsTablesTotals () {
      if (!this.periodsOptionsGrouped || !this.periodsOptionsGrouped['planned']) {
        return []
      }

      const table1Totals = this.calculateTableTotals(this.period1TableData)
      const table2Totals = this.calculateTableTotals(this.period2TableData)

      table2Totals['totalByDepthDelta'] = round(table2Totals['totalByDepth'] - table1Totals['totalByDepth'], 2)

      return [table1Totals, table2Totals]
    },
    periodsTablesShares () {
      if (!this.periodsOptionsGrouped || !this.periodsOptionsGrouped['planned']) {
        return []
      }

      const table1Shares = this.calculateTableShares(this.period1TableData, this.periodsTablesTotals[0])
      const table2Shares = this.calculateTableShares(this.period2TableData, this.periodsTablesTotals[1], table1Shares)

      return [
        table1Shares,
        table2Shares,
      ]
    },
    period1TableData () {
      return this.periodsTablesData[0]
    },
    period2TableData () {
      return this.periodsTablesData[1]
    },
  },
  watch: {
    periodsList: {
      handler () {
        if (!this.selectedPeriod1) {
          this.selectedPeriod1 = getYear(new Date()) - 1
        }

        if (!this.selectedPeriod2) {
          this.selectedPeriod2 = 'planned'
        }
      },
      immediate: true,
    },
    selectedPeriod1: {
      handler () {
        localStorage.setItem('strategyCollectionMatrixSelectedPeriod1', JSON.stringify(this.selectedPeriod1))
        this.$emit('selected-period1:changed')
      },
    },
    selectedPeriod2: {
      handler () {
        localStorage.setItem('strategyCollectionMatrixSelectedPeriod2', JSON.stringify(this.selectedPeriod2))
        this.$emit('selected-period2:changed')
      },
    },
    periodsTablesData: {
      handler () {
        setTimeout(this.alignTables, 100)
      },
      immediate: true,
    },
    selectedMetrics: {
      handler () {
        setTimeout(this.alignTables, 100)
      },
      immediate: true,
    },
    currentMatrixSize: {
      handler () {
        setTimeout(this.alignTables, 100)
      },
    },
    matrixExpanded: {
      handler () {
        setTimeout(this.alignTables, 100)
      },
    },
  },
  methods: {
    nullPropToUndefined (option) {
      return {
        ...option,
        [this.selectedDimension]: option[this.selectedDimension] === null ? undefined : option[this.selectedDimension]
      }
    },
    getMatrixTablesData (options) {
      const period1Options = options[this.selectedPeriod1]?.map(this.nullPropToUndefined) || []
      const period2Options = options[this.selectedPeriod2]?.map(this.nullPropToUndefined) || []

      const period1OptionsGrouped = groupBy(period1Options, this.selectedDimension)
      const period2OptionsGrouped = groupBy(period2Options, this.selectedDimension)
      const uniqueKeysSorted = uniq([
        ...Object.keys(period1OptionsGrouped),
        ...Object.keys(period2OptionsGrouped),
      ])
        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
        .sort(a => a === 'undefined' ? -1 : 1)
      let table1Data = []
      let table2Data = []

      uniqueKeysSorted.forEach(key => {
        if (period1OptionsGrouped[key]) {
          table1Data.push({
            [this.selectedDimension]: key,
            options: orderBy(period1OptionsGrouped[key], [this.selectedOrder], ['desc']),
          })
        } else {
          table1Data.push({
            [this.selectedDimension]: key,
          })
        }

        if (period2OptionsGrouped[key]) {
          table2Data.push({
            [this.selectedDimension]: key,
            options: orderBy(period2OptionsGrouped[key], [this.selectedOrder], ['desc']),
          })
        } else {
          table2Data.push({
            [this.selectedDimension]: key,
          })
        }
      })

      table1Data = this.addAggregationColumns(table1Data)
      table2Data = this.addAggregationColumns(table2Data)

      const columns = [...this.selectedSum]
      if (!columns.includes('pieces')) {
        columns.push('pieces')
      }

      table2Data = table2Data.map((row, i) => {
        const deltas = columns.reduce((acc, cur) => ({
          ...acc,
          [`totalBy${upperFirst(cur)}Delta`]: round(row[`totalBy${upperFirst(cur)}`] - table1Data[i][`totalBy${upperFirst(cur)}`], 2)
        }), {})

        deltas['totalByWidthDelta'] = round(row['totalByWidth'] - table1Data[i]['totalByWidth'], 2)
        deltas['totalByDepthDelta'] = round(row['totalByDepth'] - table1Data[i]['totalByDepth'], 2)

        return {
          ...row,
          ...deltas,
        }
      })

      return [
        table1Data,
        table2Data,
      ]
    },
    addAggregationColumns (tableData) {
      const columns = [...this.selectedSum]
      if (!columns.includes('pieces')) {
        columns.push('pieces')
      }

      return tableData.map(row => {
        const sums = columns.reduce((acc, cur) => ({
          ...acc,
          [`totalBy${upperFirst(cur)}`]: row.options ? round(row.options.reduce((sum, opt) => sum + opt[cur], 0), 2) : 0,
        }), {})

        sums['totalByWidth'] = row.options ? row.options.length : 0
        sums['totalByDepth'] = Math.round(sums['totalByPieces'] / sums['totalByWidth']) | 0

        return {
          ...row,
          ...sums,
        }
      })
    },
    calculateTableTotals (tableData) {
      const columns = [...this.selectedSum.filter(ss => ss !== 'depth')]
      if (!columns.includes('pieces')) {
        columns.push('pieces')
      }

      const tableTotals = columns.reduce((acc, cur) => {
        const metricKey = `totalBy${upperFirst(cur)}`
        const metricDeltaKey = `${metricKey}Delta`
        const metricValue = tableData ? round(tableData.reduce((sum, row) => sum + row[metricKey], 0)) : 0
        const metricDeltaValue = tableData ? round(tableData.reduce((sum, row) => sum + row[metricDeltaKey], 0)) : 0

        return {
          ...acc,
          [metricKey]: metricValue,
          [metricDeltaKey]: metricDeltaValue
        }
      }, {})

      tableTotals['totalByWidth'] = tableData.reduce((sum, row) => sum + (row.options ? row.options.length : 0), 0)
      tableTotals['totalByDepth'] = Math.round(tableTotals['totalByPieces'] / tableTotals['totalByWidth'])

      return tableTotals
    },
    calculateTableShares (tableData, tableTotals, oppositeTableShares) {
      const columns = [
        ...this.selectedSum.filter(c => c !== 'depth'),
      ]
      if (!columns.includes('pieces')) {
        columns.push('pieces')
      }

      return tableData.map((row, rIndex) => {
        const shares = columns.reduce((acc, cur) => {
          const rowMetricShare = round(row[`totalBy${upperFirst(cur)}`] / tableTotals[`totalBy${upperFirst(cur)}`] * 100)
          const shareProps = {
            [`shareBy${upperFirst(cur)}`]: rowMetricShare,
          }

          if (oppositeTableShares) {
            shareProps[`shareBy${upperFirst(cur)}Delta`] = rowMetricShare - oppositeTableShares[rIndex][`shareBy${upperFirst(cur)}`]
          }

          return {
            ...acc,
            ...shareProps,
          }
        }, {})

        return {
          ...row,
          ...shares,
        }
      })
    },
    updatePlaceholdersCellsHeight () {
      let placeholderHeight = 300
      const zoom = document.documentElement.style.getPropertyValue('--zoom') || 1

      const cards = document.getElementsByClassName('strategy-collection-option-card')

      if (cards.length) {
        placeholderHeight = cards[0].closest('.el-table__row')?.getBoundingClientRect().height || placeholderHeight
      }
      placeholderHeight = placeholderHeight / zoom

      const placeholders = [
        ...this.$refs['leftTable'].$el.getElementsByClassName('placeholder'),
        ...this.$refs['rightTable'].$el.getElementsByClassName('placeholder'),
      ]

      for (let i = 0; i < placeholders.length; i++) {
        const cell = placeholders[i].closest('.el-table__cell')

        if (!cell.classList.contains('total-row_column')) {
          cell.style.height = placeholderHeight + 'px'
        }
      }
    },
    resetCellsHeight () {
      const cells = [
        ...this.$refs['leftTable'].$el.getElementsByClassName('dimension-cell'),
        ...this.$refs['rightTable'].$el.getElementsByClassName('dimension-cell'),
        ...this.$refs['leftTable'].$el.getElementsByClassName('options-cell'),
        ...this.$refs['rightTable'].$el.getElementsByClassName('options-cell'),
        ...this.$refs['leftTable'].$el.getElementsByClassName('placeholder'),
        ...this.$refs['rightTable'].$el.getElementsByClassName('placeholder'),
      ]

      for (let i = 0; i < cells.length; i++) {
        const cell = cells[i].closest('.el-table__cell')

        if (!cell.classList.contains('total-row_column')) {
          cell.style.height = null
        }
      }
    },
    setOptionsColumnDisplay (display) {
      document.querySelectorAll('.options-cell-td').forEach(el => {
        el.style.display = display
      })
    },
    alignTables () {
      this.resetCellsHeight()

      if (this.currentMatrixSize === 'mini') {
        this.setOptionsColumnDisplay('none')
        this.alignTablesCells('td.dimension-cell-td')
      }

      if (this.currentMatrixSize === 'small') {
        this.setOptionsColumnDisplay(null)
        this.alignTablesCells('td.options-cell-td')
      }

      if (this.currentMatrixSize === 'large') {
        this.setOptionsColumnDisplay(null)
        this.updatePlaceholdersCellsHeight()
      }
    },
    alignTablesCells (selector) {
      const leftCells = this.$refs['leftTable'].$el.querySelectorAll(selector)
      const rightCells = this.$refs['rightTable'].$el.querySelectorAll(selector)

      leftCells.forEach((leftCell, index) => {
        const rightCell = rightCells.item(index)
        const newHeight = Math.max(leftCell.offsetHeight, rightCell.offsetHeight)
        rightCells.item(index).style.height = newHeight + 'px'
        leftCell.style.height = newHeight + 'px'
      })
    },
    handleSelectedDimensionChange (dimension) {
      localStorage.setItem('strategyCollectionMatrixSelectedDimension', JSON.stringify(dimension))
    },
    handleSelectedMetricsChange (metrics) {
      // doing filter instead of plain assignment to preserve items order
      this.selectedMetrics = this.matrixMetrics.filter(s => metrics.includes(s.value))
      localStorage.setItem('strategyCollectionMatrixSelectedMetrics', JSON.stringify(this.selectedMetrics))
    },
    handleSelectedOrderChange (order) {
      this.selectedOrder = order
    },
    handleSelectedTotalsChange (totals) {
      this.selectedSum = totals
    },
    handleSelectedTotalAsChanged (value) {
      this.selectedShowTotalAs = value

      localStorage.setItem('strategyCollectionSelectedShowTotalAs', JSON.stringify(this.selectedShowTotalAs))
    },
    handleToggleMatrixClick () {
      this.matrixExpanded = !this.matrixExpanded
    },
    handleResize () {
      this.alignTablesDebounce()
    }
  },
}
</script>

<style lang="scss">
#strategy-collection-matrix {
  .period-select {
    width: 150px;
  }
  .expand-matrix-btn {
    position: absolute !important;
    left: -12px;
    &.matrix-expanded {
      left: 6px;
    }
  }
}
</style>
