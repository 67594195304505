<template>
  <div id="strategy-skips">
    <data-tables
      style="width: 100%"
      class="table"
      :data="tableData"
      :page-size="tableData.length"
      :pagination-props="{
        pageSizes: [tableData.length],
        class: 'd-none'
      }"
    >
      <el-table-column
        v-for="(c, index) in (tableData.length ? Object.keys(tableData[0]) : [])"
        :key="c"
        :prop="c"
        :label="c | formatString"
        :label-class-name="index === 0 ? 'text-left': 'text-center'"
        sortable
        :column-key="c"
      >
        <template slot-scope="scope">
          {{ formatMetric(scope.row[c], c) }}
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { useCubesStore } from '@/store/pinia/cubesStore'

export default {
  name: 'StrategyCubeSkips',
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  setup () {
    return {
      cubesStore: useCubesStore(),
    }
  },
  computed: {
    metricFormats () {
      return this.cubesStore.metricFormat
    },
  },
  methods: {
    formatMetric (value, metricName) {
      let format = this.metricFormats[metricName.toLowerCase().replace(' ', '_')]

      if (this.currentMetric === 'pieces' && typeof value === 'number') {
        format = 'integer'
      }

      return format ? this.$options.filters[format](value) : value
    },
  },
}
</script>

<style lang="scss">
#strategy-skips {
  .el-table td {
    padding: 0;
  }
  .el-table__append-wrapper {
    position: sticky;
    bottom: 0;
  }
  .table .el-table__body-wrapper {
    max-height: 50vh;
    overflow-y: auto;
  }
  .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
    width: 4px;
  }
  .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }
  .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
}
</style>
