<template>
  <v-card class="pt-3 transfers-table" :flat="showTitle">
    <v-row class="justify-space-between" v-if="showTitle">
      <v-col>
      </v-col>
      <v-col class="text-end mr-5">
        <TableSearch class="mx-2" @searchChanged="handleSearch" />
        <DataExport class="" @item-click="onDownload" />
      </v-col>
    </v-row>
    <v-card-text class="x-0">
      <data-tables
        :key="listKey"
        v-if="data && data.length"
        :data="tableData"
        @filter-change="f => handleFilterChange('filters', f)"
        :filters="filters"
        :page-size="showTitle? 15 : 10"
        filter-multiple
        :table-props="{
          'row-key' : 'Store',
          'cell-class-name': 'colored-td',
        }"
        :pagination-props="{
          pageSizes: [5, 10,15, 25, 50],
          class: 'el-pagination text-right mt-6 pb-4 mr-2'
        }">
        <el-table-column
          :column-key="skuNames.includes(col) ? `${col}_value`: col === 'change' ? 'delta': col "
          v-for="(col) of columns.filter(item=> item !== 'delta')" :prop="skuNames.includes(col) ? `${col}_value`: col === 'change' ? 'delta': col" :label="col === 'store_name' ? 'Store':col | formatString" :key="skuNames.includes(col) ? `${col}_value`: col"
          sortable="custom">
          <template v-slot="scope">
            <div v-if="col==='store_name'" class="d-flex align-center colored-area align-self-center" style="width: 190px">{{scope.row[col]}}</div>
            <div
              v-else-if="skuNames.includes(col)"
              class="d-flex align-center justify-center colored-area"
              :style="{
                'background-color': getColorCoding(scope.row[col]?.delta),
                'padding': '10px',
                'font-size': '14px',
              }">
              {{ getLabelWithDelta(scope.row, col) }}</div>
            <div v-else-if="col === 'change'"
              class="d-flex align-center justify-center colored-area">
              {{scope.row.delta}}
            </div>
            <div v-else
              class="d-flex align-center justify-center colored-area">
              <span v-if="col === 'profit'">
                {{defaultCurrencySymbol}}
              </span>{{scope.row[col]}}
            </div>
          </template>
        </el-table-column>
        <data-tables
          class="total-table"
          slot="append"
          :data="getSummaries"
          :pagination-props="{class: 'd-none'}"
        >
          <el-table-column
            label-class-name="d-none" v-for="col of columns" :prop="col" :label="col === 'store_name' ? 'Stores':col" :key="col" sortable="custom">
            <template v-slot="scope">
              <div v-if="col==='store_name'" class="d-flex colored-area " style="width: 190px">
                <span v-if="scope.$index">Deltas</span>
                <span v-else>Total</span>
              </div>

              <div
                v-else
                class="d-flex align-center justify-center colored-area">
                <span v-if="scope.$index">
                  {{ scope.row[col].delta ? scope.row[col].delta.toFixed(0) : scope.row[col].delta }}
                </span>
                <span v-else>

                  <span v-if="col === 'profit'">
                    {{defaultCurrencySymbol}}
                  </span>
                  {{ scope.row[col].value ? scope.row[col].value.toFixed(0) : scope.row[col].value }}
                </span>
              </div>
            </template>
          </el-table-column>
        </data-tables>
      </data-tables>
      <v-row justify="end" v-if="showTitle">
        <v-btn
          color="primary"
          class="my-4 mr-4"
          outlined
          @click="setOptionsList"
          :href="$router.resolve({ name: 'stock-transfers', query: { option_name: option_name } }).href"
          target="_blank"
        >
          To transfers
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import DataExport from '@/components/DataExport'

import TableSearch from '@/components/TableSearch.vue'
import columnFilters from '@/mixins/columnFilters'
import { downloadData } from '@/utils'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

import interpolate from 'color-interpolate'
import { useFiltersStore } from '@/store/pinia/filtersStore'

const redToWhite = interpolate(['#EE37664D'])
const whiteToGreen = interpolate(['#4FB4AB4D'])

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  mixins: [columnFilters],
  name: 'OptionTransfersTable',
  components: { DataExport, TableSearch },
  data () {
    return {
      filters: [{ value: '' }],
      listKey: 0,
    }
  },
  props: {
    showTitle: {
      default: true,
    },
    option_name: {
      type: String,
      default: ''
    },
    option_key: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    skuNames: {
      type: Array,
      default (){
        return []
      }
    }
  },
  computed: {
    columns () {
      return ['store_name', ...this.skuNames, 'goal', 'change', 'profit']
    },
    tableData (){
      return this.data.map(item=> ({
        ...item,
        store_name: this.getStoreName(item.store_key)
      }))
    },
    currencies () {
      return this.appConfig?.currencies || []
    },
    currencySymbols () {
      return this.currencies.reduce((acc, cur) => ({
        ...acc,
        [cur.iso_code]: cur.symbol,
      }), {})
    },
    defaultCurrencySymbol () {
      return this.currencySymbols[localStorage.getItem('defaultCurrency')]
    },
    getSummaries () {
      // eslint-disable-next-line
      const columns= this.columns
      const sums = {}
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[column] = 'Total'
          return
        }
        const values = this.data.map(item => ({
          value: Number(item[column]?.goal) || 0,
          delta: Number(item[column]?.delta) || 0
        }))
        if (this.skuNames.includes(column)) {
          sums[column] = values.reduce((prev, curr) => {
            const value = curr.value
            const delta = curr.delta > 0 ? curr.delta : 0
            // const delta = curr.delta
            prev.value += value
            prev.delta += delta
            return prev
          }, {value: 0, delta: 0})
        } else if (column === 'change'){
          sums[column] = {
            value: '',
            delta: this.data.reduce((prev, curr) => {
              return prev + curr.deltas.reduce((prev, curr)=> prev + (curr > 0 ? curr : 0), 0 )
            }, 0)
          }
        }else {
          sums[column] = {
            value: this.data.reduce((prev, curr) => {
              return prev + curr[column]
            }, 0),
            delta: ''
          }
        }
      })
      return [sums, sums]
    },

    ...mapState(useGeneralStore, ['appConfig']),
    stores () {
      return (this.appConfig?.stores || []) || []
    },
  },
  methods: {
    setOptionsList (){
      localStorage.setItem('optionsList', JSON.stringify([this.$route.query.option_key]))

      this.filtersStore.setOptionsFilter()
    },
    onDownload (sep) {
      const cols = ['store_name', ...this.columns].filter(c => c !== 'Store')
      const data = this.data.map(
        row => cols.reduce(
          (acc, cur) => Object.assign(acc, { [cur]: row[cur] }),
          {}
        )
      )
      downloadData(sep, data, 'transfers')
    },
    handleSearch (val) {
      this.handleSearchChanged('filters', ['store_name', 'delta', 'profit', 'goal', ...this.skuNames.map(item=> `${item}_value`)], val)
      this.listKey++
    },
    getStoreName (key) {
      return this.stores.find(store => store.store_key === key).store_name || ''
    },
    getColorCoding (value) {
      if (value < -5) {
        return redToWhite(0)
      }
      if (value === 0) {
        return 'white'
      }
      if (value <= 0) {
        return redToWhite(value / -5)
      }
      if (value <= 5) {
        return whiteToGreen(value / 5)
      }
      if (value > 5) {
        return whiteToGreen(1)
      }
    },
    getLabelWithDelta (row, col) {
      if (!row[col]) {
        return '0'
      }
      const deltaLabel = `${(row[col].delta > 0 ? '+' : '')}${row[col].delta}`
      return row[col].goal + (row[col].delta === 0 ? '' : ' (' + deltaLabel + ')')
    }
  }
}
</script>

<style lang="scss">
.transfers-table {
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: white !important;
  }
  .total-table td{
    background-color: #f5f7fa;
    color: #606266;
  }
  .colored-td {
    padding: 0 !important;

    .cell {
      height: 100%;
      padding: 0 !important;

      .colored-area {
        padding: 0 !important;
        height: 100%;
      }
    }
  }

  .cell {
    text-align: center;
  }

  td:first-child, th:first-child {
    div.cell {
      padding: 12px !important;
      padding-left: 24px !important;
      text-align: left !important;
    }
  }
}
</style>
