<template>
  <resizable
    id="strategy-wssi-page"
    class="p-relative pt-0"
    @resize="updateTotalTableWidth"
  >
    <v-overlay absolute color="white" :value="loading" >
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="align-baseline mt-3 mb-3">
      <v-col cols="3" class="py-0">
        <h3>WSSI</h3>
      </v-col>
      <v-spacer />
      <v-col md="2" cols="4" class="py-0">
        <StrategyCubeSelect
          ref="cubeSelect"
          @cube-inputs-loaded="handleCubeInputsLoaded"
        />
      </v-col>
    </v-row>
    <v-card class="pt-4" min-height="100vh">
      <v-card-text class="pa-0">
        <div class="px-6">
          <template v-if="filtersDimensions.length">
            <v-row class="align-center">
              <v-col cols="11" class="pb-0">
                <StrategyFilters
                  ref="filters"
                  :dimensions="filtersDimensions"
                  @filters-loaded="handleFiltersLoaded"
                  @filters-changed="handleFiltersChanged"
                />
              </v-col>
              <v-col cols="1" class="pb-0 pt-0 text-right">
                <v-menu
                  v-model="settingsMenuOpened"
                  :close-on-content-click="false"
                  attach="#strategy-wssi-page"
                  offset-y
                  left
                  min-width="600"
                  max-width="600"
                  nudge-bottom="20"
                  content-class="settings-menu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on">tune</v-icon>
                  </template>

                  <v-card>
                    <h4 class="ml-4 pt-6">
                      Settings
                    </h4>
                    <div class="input-parameter">
                      <div class="parameter-name">Metrics</div>
                      <div class="parameter-value">
                        <collapsible-select
                          autocomplete
                          clearable
                          v-model="currentMetrics"
                          :items="metricList"
                          multiple
                          chips
                          small-chips
                          deletable-chips
                          hide-details
                          :menu-props="{
                            left: true,
                            offsetY: true,
                            contentClass: 'select-dropdown-menu',
                          }"
                          @change="settingsChanged = true"
                        />
                      </div>
                    </div>
                    <div class="input-parameter">
                      <div class="parameter-name">Reference years</div>
                      <div class="parameter-value">
                        <collapsible-select
                          autocomplete
                          v-model="referenceYears"
                          :items="referenceYearList"
                          multiple
                          hide-details
                          clearable
                          :menu-props="{
                            left: true,
                            offsetY: true,
                            contentClass: 'select-dropdown-menu',
                          }"
                          @change="settingsChanged = true"
                        />
                      </div>
                    </div>
                    <div class="input-parameter">
                      <div class="parameter-name">Font size</div>
                      <div class="parameter-value">
                        <v-select
                          clearable
                          v-model="fontSize"
                          hide-details
                          class="pt-0 mt-0"
                          :items="['Small', 'Medium', 'Large']"
                          :menu-props="{
                            left: true,
                            offsetY: true,
                            contentClass: 'select-dropdown-menu',
                          }"
                          @change="settingsChanged = true"
                        />
                      </div>
                    </div>

                    <div class="input-parameter">
                      <div class="parameter-name">LFL</div>
                      <div class="parameter-value">
                        <v-checkbox
                          v-model="lfl"
                          hide-details
                          class="mt-0 pt-0"
                          @change="settingsChanged = true"
                        ></v-checkbox>
                      </div>
                    </div>
                    <div class="input-parameter">
                      <div class="parameter-name">Total YTD</div>
                      <div class="parameter-value">
                        <v-checkbox
                          v-model="total_YTD"
                          hide-details
                          class="mt-0 pt-0"
                          @change="totalsData.total_YTD.show = $event; settingsChanged = true"
                        ></v-checkbox>
                      </div>
                    </div>
                    <div class="input-parameter">
                      <div class="parameter-name">Total future</div>
                      <div class="parameter-value">
                        <v-checkbox
                          v-model="total_future"
                          hide-details
                          class="mt-0 pt-0"
                          @change="totalsData.total_future.show = $event; settingsChanged = true"

                        ></v-checkbox>
                      </div>
                    </div>
                    <div class="input-parameter">
                      <div class="parameter-name">Total</div>
                      <div class="parameter-value">
                        <v-checkbox
                          v-model="total"
                          hide-details
                          class="mt-0 pt-0"
                          @change="totalsData.total.show = $event; settingsChanged = true"
                        ></v-checkbox>
                      </div>
                    </div>
                    <div class="input-parameter">
                      <div class="parameter-name">Sub total</div>
                      <div class="parameter-value">
                        <v-checkbox
                          v-model="subTotal"
                          hide-details
                          class="mt-0 pt-0"
                          @change="totalsData.subTotal.show = $event; settingsChanged = true"
                        ></v-checkbox>
                      </div>
                    </div>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
          </template>
        </div>

        <data-tables
          class="table my-8 el-table--border "
          :class="`font-style-${fontSize}`"
          style="width: 100%"
          ref="wssiTable"
          expand-icon="keyboard_arrow_down"
          :data="tableData"
          :key="ordersKey + total + total_YTD + total_future"
          :page-size="tableData.length"
          :table-props="{
            'row-key': (row)=> row.id+(row.parent||''),
            'tree-props': {
              children: 'children',
              hasChildren: 'hasChildren',
            },
          }"
          :pagination-props="{
            pageSizes: [tableData.length],
            class: 'd-none',
          }"
        >
          <el-table-column
            label="Calendar"
            prop="calendar"
            width="150px"
            class-name="calendar-cell"
            label-class-name="text-start pl-1 label-height bg-gray"
          >
            <template slot-scope="scope">
              <span>{{ scope.row['id'] | formatString}}</span>
            </template>
            <template slot="header" >
              <span class="font-weight-bold c-pointer" @click="expandAllRows">Rows</span>/<span @click="expandAllColumns" class="font-weight-bold c-pointer">Columns</span>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(metric) in columns.level1"
            :key="metric+ordersKey"
            :label="metric | formatString"
            :prop="metric"
            min-width="150"
            label-class-name="text-center bg-gray label-height"
          >
            <template slot="header" >
              <div class="el-table__expand-icon"
                @click="()=> {secondOrderColumns[metric] = !secondOrderColumns[metric]; ordersKey++}"
              ><i class="el-icon-arrow-right"
                 :class="{'el-table__expand-icon--expanded': secondOrderColumns[metric]}"

               ></i>
                {{metric}}
              </div>

            </template>

            <template slot-scope="scope">
              <v-row class="ma-0 align-self-center justify-space-between">
                <v-col cols="8" class="pa-0">
                  <span v-if="scope.row.id === 'sellthrough' || scope.row.parent === 'sellthrough'">
                    {{ scope.row[metric]}}%
                  </span>
                  <span v-else>
                    <span v-if="scope.row.id === 'revenue' || scope.row.parent === 'revenue'">{{defaultCurrencySymbol}}</span>{{ scope.row[metric] |formatThousands}}
                  </span>
                </v-col>
                <v-col cols="3" class="pa-0">{{scope.row[`${metric} (idx)`] | toFixedZero}}</v-col>
              </v-row>
            </template>
            <el-table-column
              v-for="childMetric in secondOrderColumns[metric] ? columns.level2[metric]: []"
              :key="childMetric+ordersKey"
              :prop="childMetric"
              min-width="150"
              class-name="tier-1"
              :label="childMetric | formatString"
              label-class-name="text-center  label-height"
            >
              <template slot="header">
                <span class="c-pointer" @click="()=> {thirdOrderColumns[childMetric] = !thirdOrderColumns[childMetric]; ordersKey++}">
                  <v-icon size="16" v-if="thirdOrderColumns[childMetric]">mdi-arrow-up</v-icon>
                  <v-icon size="16" v-else >mdi-arrow-down</v-icon>
                  {{childMetric}}
                </span>
              </template>

              <template slot-scope="scope">
                <v-row class="ma-0 align-self-center justify-space-between">
                  <v-col cols="8" class="pa-0">

                    <span v-if="scope.row.id === 'sellthrough' || scope.row.parent === 'sellthrough'">
                      {{ scope.row[childMetric]}}%
                    </span>
                    <span v-else>
                      <span v-if="scope.row.id === 'revenue' || scope.row.parent === 'revenue'">{{defaultCurrencySymbol}}</span>{{ scope.row[childMetric] |formatThousands}}
                    </span>
                  </v-col>
                  <v-col cols="3" class="pa-0">{{scope.row[`${childMetric} (idx)`] | toFixedZero}}</v-col>
                </v-row>
              </template>
              <el-table-column
                v-for="lastMetric in (thirdOrderColumns[childMetric] ? columns.level3[metric][childMetric] : [])"
                :key="lastMetric"
                :prop="lastMetric"
                class-name="tier-2"
                min-width="150"
                :label="lastMetric"
                label-class-name="text-center  label-height"
              >
                <template slot-scope="scope">
                  <v-row class="ma-0 align-self-center justify-space-between">
                    <v-col cols="8" class="pa-0">

                      <span v-if="scope.row.id === 'sellthrough' || scope.row.parent === 'sellthrough'">
                        {{ scope.row[lastMetric]}}%
                      </span>
                      <span v-else>
                        <span v-if="scope.row.id === 'revenue' || scope.row.parent === 'revenue'">{{defaultCurrencySymbol}}</span>{{ scope.row[lastMetric] |formatThousands}}
                      </span></v-col>
                    <v-col cols="3" class="pa-0">{{scope.row[`${lastMetric} (idx)`] | toFixedZero}}</v-col>
                  </v-row>
                </template>
              </el-table-column>

              <el-table-column class-name="tier-2 tier-cell" min-width="150" v-if="thirdOrderColumns[childMetric]">
                <template slot-scope="scope">
                  <v-row class="ma-0 align-self-center justify-space-between">
                    <v-col cols="8" class="pa-0">

                      <span v-if="scope.row.id === 'sellthrough' || scope.row.parent === 'sellthrough'">
                        {{ scope.row[childMetric]}}%
                      </span>
                      <span v-else>
                        <span v-if="scope.row.id === 'revenue' || scope.row.parent === 'revenue'">{{defaultCurrencySymbol}}</span>{{ scope.row[childMetric] |formatThousands}}
                      </span></v-col>
                    <v-col cols="3" class="pa-0">{{scope.row[`${childMetric} (idx)`] | toFixedZero}}</v-col>
                  </v-row>
                </template>
              </el-table-column>

            </el-table-column>
            <el-table-column class-name="tier-cell" min-width="150" v-if="secondOrderColumns[metric]">
              <template slot-scope="scope">
                <v-row class="ma-0 align-self-center justify-space-between">
                  <v-col cols="8" class="pa-0">

                    <span v-if="scope.row.id === 'sellthrough' || scope.row.parent === 'sellthrough'">
                      {{ scope.row[metric]}}%
                    </span>
                    <span v-else>
                      <span v-if="scope.row.id === 'revenue' || scope.row.parent === 'revenue'">{{defaultCurrencySymbol}}</span>{{ scope.row[metric] |formatThousands}}
                    </span></v-col>
                  <v-col cols="3" class="pa-0">{{scope.row[`${metric} (idx)`] | toFixedZero}}</v-col>
                </v-row>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            fixed="right"
            :label="totalColumn | formatString"
            v-for="totalColumn in Object.keys(totalsData|| {}).filter(key=> totalsData[key]?.show)"
            :key="totalColumn"
            :column-key="totalColumn"
            :prop="totalColumn"
            width="150"
            label-class-name="text-center bg-gray label-height"
          >
            <template slot-scope="scope">
              <v-row class="ma-0 align-self-center justify-space-between text-no-wrap" v-if="totalsData[totalColumn]?.data">
                <v-col cols="8" class="pa-0">
                  <span v-if="scope.row.id === 'sellthrough' || scope.row.parent === 'sellthrough'">
                    {{getTotalsData(totalsData[totalColumn]?.data, scope.$index) | toFixedZero }}%
                  </span>
                  <span v-else>
                    <span v-if="scope.row.id === 'revenue' || scope.row.parent === 'revenue'">{{defaultCurrencySymbol}}</span>
                    {{getTotalsData(totalsData[totalColumn]?.data, scope.$index) | formatThousands}}
                  </span>
                </v-col>
                <v-col cols="3" class="pa-0">
                  {{getTotalsData(totalsData[totalColumn]?.data, scope.$index, 1) | toFixedZero }}
                </v-col>
              </v-row>
            </template>
          </el-table-column>
        </data-tables>
      </v-card-text>
    </v-card>
  </resizable>
</template>

<script>
import { GetCubeWSSI } from '@/api/cube'
import StrategyCubeSelect from '@/components/Strategy/StrategyCubeSelect'
import StrategyFilters from '@/components/Strategy/StrategyFilters'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import Resizable from '@/components/Utility/Resizable.vue'
import { useCubesStore } from '@/store/pinia/cubesStore'

import { useStrategyFiltersStore } from '@/store/pinia/strategyFiltersStore'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'StrategyWSSIPage',
  components: { Resizable, StrategyCubeSelect, StrategyFilters, CollapsibleSelect },
  setup () {
    return {
      cubesStore: useCubesStore(),
      strategyFiltersStore: useStrategyFiltersStore()
    }
  },
  data () {
    return {
      loading: true,
      allExpandedRows: false,
      allExpandedColumns: false,
      filtersDimensions: [],
      filtersValues: {},
      totalsData: {},
      total: JSON.parse(localStorage.getItem('strategyBudgetCubesSettings'))?.total | true,
      subTotal: JSON.parse(localStorage.getItem('strategyBudgetCubesSettings'))?.subTotal | true,
      total_YTD: JSON.parse(localStorage.getItem('strategyBudgetCubesSettings') || '{}')?.total_YTD | true,
      total_future: JSON.parse(localStorage.getItem('strategyBudgetCubesSettings') || '{}')?.total_future | true,
      filtersLoaded: false,
      tableData: [],
      ordersKey: 0,
      secondOrderColumns: {},
      thirdOrderColumns: {},
      settingsMenuOpened: false,
      settingsChanged: false,
      referenceYearList: [],
      referenceYears: [],
      metricList: [],
      currentMetrics: [],
      lfl: JSON.parse(localStorage.getItem('strategyBudgetCubesSettings') || '{}').lfl | false,
      fontSize: JSON.parse(localStorage.getItem('strategyBudgetCubesSettings') || '{}').fontSize || 'Medium',
      wssiData: {quarter: {}},
      columns: {
        level1: new Set(),
        level2: {},
        level3: {}
      },
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    filtersOpened () {
      return this.strategyFiltersStore.filtersOpened
    },

    currencies () {
      return this.appConfig?.currencies || []
    },
    currencySymbols () {
      return this.currencies.reduce((acc, cur) => ({
        ...acc,
        [cur.iso_code]: cur.symbol,
      }), {})
    },
    defaultCurrencySymbol () {
      return this.currencySymbols[localStorage.getItem('defaultCurrency')]
    },
    metricFormats () {
      return this.cubesStore.metricFormat
    },
    totalData () {
      return [{
        label: 'total_YTD'
      }, {
        label: 'total_future'
      }, {
        label: 'total'
      }]
    },
  },
  watch: {
    async filtersOpened (newValue) {
      const state = this.strategyFiltersStore

      if (!newValue && state.filtersChanged) {
        if (state.period) {
          this.$refs.filters.setWeeksFromPeriod(state.period)
        }

        this.loading = true

        try {
          await this.loadCubeWSSI()
        } finally {
          this.loading = false
        }
      }
    },
    async settingsMenuOpened (val) {
      if (!val && this.settingsChanged) {
        this.loading = true
        try {
          await this.loadCubeWSSI()
          this.settingsChanged = false
          localStorage.setItem('strategyBudgetCubesSettings', JSON.stringify({
            currentMetrics: this.currentMetrics,
            referenceYears: this.referenceYears,
            lfl: this.lfl,
            fontSize: this.fontSize,
            subTotal: this.subTotal,
            total: this.total,
            total_YTD: this.total_YTD,
            total_future: this.total_future,
          }))
        } finally {
          this.loading = false
        }
      }
    },
  },
  methods: {
    getTotalsData (data, index, shiftSecond = 0) {
      if (!data) {
        return 0
      }
      const index1 = parseInt(index / 4)
      if (!data[index1]) {
        return 0
      }
      const index2 = index % 4 * 2 + shiftSecond
      return data[index1][index2] ?? 0
    },
    formatMetric (value, metricName) {
      const format = this.metricFormats[metricName.toLowerCase().replace(' ', '_')] || 'integer'
      return this.$options.filters[format](value)
    },
    expandAllColumns () {
      this.allExpandedColumns = !this.allExpandedColumns
      for(const metric of this.columns.level1){
        this.secondOrderColumns[metric] = this.allExpandedColumns
        for(const childMetric of this.columns.level2[metric]){
          this.thirdOrderColumns[childMetric] = this.allExpandedColumns
        }
      }
      this.ordersKey ++
    },
    expandAllRows (){
      this.allExpandedRows = !this.allExpandedRows
      this.tableData.forEach((row)=> {
        this.$refs.wssiTable.$refs.elTable.toggleRowExpansion(row, this.allExpandedRows)
      })
    },
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = this.$el.querySelector('.el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 100)
    },
    isIndexColumn (col) {
      return col.includes('(idx)')
    },
    async handleCubeInputsLoaded ({ dimensions }) {
      this.filtersDimensions = dimensions
      this.setCubeData()
      this.setCubeInputData()

      if (this.filtersLoaded) {
        this.loading = true
        try {
          await this.loadCubeWSSI()
        } finally {
          this.loading = false
        }
      }
    },
    async handleFiltersLoaded (values) {
      this.filtersLoaded = true
      this.filtersValues = values
      this.loading = false

      if (this.filtersDimensions.length) {
        this.loading = true
        try {
          await this.loadCubeWSSI()
        } finally {
          this.loading = false
        }
      }
    },
    async handleFiltersChanged (values) {
      this.filtersValues = values
      this.loading = true
      try {
        await this.loadCubeWSSI()
      } finally {
        this.loading = false
      }
    },
    async loadCubeWSSI () {
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}
      const payload = {
        cube_key: this.$refs.cubeSelect.currentCube,
        version_key: this.$refs.cubeSelect.currentVersion,
        filters: {
          ...this.getNonEmptyFiltersPayload(),
          ...additionalFilters,
        },
        'dimensions':['week_numbers/quarter', 'week_numbers/period', 'week_numbers/week'],
        'sources':[
          {'type': 'actuals', 'alias':'actuals'},
          {'type':'actuals', 'shift_years':1, 'alias':'actuals_ly'},
          {'type':'actuals', 'reference_years':[2022], 'alias': '2022'}],
        'kpis':[
          {'metric':'revenue', 'idx':true},
          {'metric':'sales_net', 'idx':true},
          {'metric':'projection', 'idx':true},
          {'metric':'sellthrough', 'idx':true}]
      }
      const data = await GetCubeWSSI(payload)
      this.totalsData = {
        total: {...data.total, show: this.total},
        total_YTD: {...data.total_YTD, show: this.total_YTD},
        total_future: {...data.total_future, show: this.total_future}
      }
      const child_columns = data.child_columns.filter(item=> !item.includes('(idx)'))
      this.tableData = data.columns.map((col, idx)=> {
        return {
          id:col,
          ...this.reduceData(data, idx, 0),
          children: child_columns.slice(1).map((childCol, childIdx)=> {
            return {
              id: childCol,
              parent: col,
              ...this.reduceData(data, idx, (childIdx * 2) + 2)
            }
          })
        }
      })
    },
    reduceData (data, idx, columnIndex){
      return{...data.hierachy_level_0.reduce((prev, curr) => {
               const column = curr['week_numbers/quarter']
               this.columns['level1'].add(column)
               prev[column] = curr.data[idx][columnIndex]
               prev[`${column} (idx)`] = curr.data[idx][columnIndex + 1]
               return prev
             }, {}),
             ...
               data.hierachy_level_1.reduce((prev, curr) => {

                 const column = curr['week_numbers/period']

                 if (!this.columns['level2'][curr['week_numbers/quarter']]) {
                   this.columns['level2'][curr['week_numbers/quarter']] = new Set()
                 }
                 this.columns['level2'][curr['week_numbers/quarter']].add(curr['week_numbers/period'])
                 prev[column] = curr.data[idx][columnIndex]
                 prev[`${column} (idx)`] = curr.data[idx][columnIndex + 1]
                 return prev
               }, {}),
             ...
               data.hierachy_level_2.reduce((prev, curr) => {
                 const column = curr['week_numbers/week']
                 if (!this.columns['level3'][curr['week_numbers/quarter']]) {
                   this.columns['level3'][curr['week_numbers/quarter']] = {}
                 }
                 if (!this.columns['level3'][curr['week_numbers/quarter']][curr['week_numbers/period']]) {
                   this.columns['level3'][curr['week_numbers/quarter']][curr['week_numbers/period']] = new Set()
                 }
                 this.columns['level3'][curr['week_numbers/quarter']][curr['week_numbers/period']].add(curr['week_numbers/week'])
                 prev[column] = curr.data[idx][columnIndex]
                 prev[`${column} (idx)`] = curr.data[idx][columnIndex + 1]
                 return prev
               }, {})
      }
    },
    setCubeInputData () {
      const { reference_years } = this.cubesStore.cubeInputData

      this.referenceYearList = reference_years
    },
    setCubeData () {
      const { metrics } = this.cubesStore.cubesBrowser

      this.metricList = metrics
      this.setInitialCurrentMetrics()
    },
    setInitialCurrentMetrics () {
      if (this.currentMetrics.length === 0) {
        // Check that metric list contains sales and revenue and add them if they are there
        if (this.metricList.includes('revenue')) {
          this.currentMetrics.push('revenue')
        }
        if (this.metricList.includes('sales_net')) {
          this.currentMetrics.push('sales_net')
        }
        if (this.currentMetrics.length === 0) {
          this.currentMetrics = this.metricList.slice(0, 2)
        } else if (this.currentMetrics.length === 1) {
          if (this.currentMetrics[0] === 'sales_net') {
            this.currentMetrics.push('revenue')
          } else {
            this.currentMetrics.push('sales_net')
          }
        }
      }
    },
    getNonEmptyFiltersPayload () {
      const filtersPayload = {}

      Object.keys(this.filtersValues).forEach(name => {
        if (this.filtersValues[name]) {
          if (!Array.isArray(this.filtersValues[name]) || this.filtersValues[name].length) {
            filtersPayload[name] = this.filtersValues[name]
          }
        }
      })

      return filtersPayload
    },
  },
  created () {
    const fixedColumns = ['total', 'total_YTD', 'total_future']
    fixedColumns.forEach(item=> {
      const storedData = JSON.parse(localStorage.getItem('strategyBudgetCubesSettings'))
      if(!storedData) {
        return
      }
      if(storedData[item] === undefined){
        this[item] = true
      }else{
        this[item] = storedData[item]
      }
    })
  }
}
</script>

<style lang="scss">
#strategy-wssi-page {
  .bg-gray {
    background-color: #c4c5c8;
  }
    .settings-menu {
        .input-parameter {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            border-bottom: 1px solid var(--border-color);
            padding: 0 16px;
            .parameter-name {
                width: 200px;
            }
            .parameter-value {
                width: 250px;
                padding: 8px 0;
            }
            .slider {
                padding-top: 4px;
                .slider-value-from,
                .slider-value-to {
                    color: #666;
                    font-size: 14px;
                }
            }
            &:last-child {
                padding-bottom: 8px;
            }
        }
    }
    .el-table {
        th, td {
            font-size: 11px;
            height: auto;
        }
        td {
            padding: 4px 0;
            text-align: center;
        }
        .cell {
            padding-left: 4px;
            padding-right: 4px;
        }
    }
    .el-table--border {
        border-left: 0;
    }
    .el-table__append-wrapper {
        position: sticky;
        bottom: 0;
    }
    .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
        width: 4px;
    }
    .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    }
    .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 3px;
    }
    .total-table .el-table__header-wrapper tr,
    .total-table-cell {
        background-color: #f5f7fa;
    }
    th.total-table-cell {
        height: 1px;
        line-height: 1px;
        padding: 0;
        border-bottom: 1px solid #f5f7fa;
    }
    td.calendar-cell {
        text-align: left;
    }
    td.total-table-cell.calendar-cell {
        padding-left: 8px;
    }
  .label-height {
    max-height: 50px;
    overflow: visible;
  }

  .el-icon-arrow-right::before {
    //content: "\F0045";
    //content: "\2193";
  content: "\e6eb"
  }
  .el-table__expand-icon {
    font-size: 12px;
  }
  .el-icon-arrow-right {
    font-weight: 600;
  }
  .el-table__expand-icon--expanded {
    transform: rotate(180deg);
  }

  //:style="{
//fontSize: fontSize=== 'Medium' ? '12px' : fontSize ==='Small' ? '10px' : '14px'
//}"
  .font-style-Medium * {
    font-size: 12px;
  }

  .font-style-Small * {
    font-size: 10px
  }

  .font-style-Large * {
    font-size: 14px
  }
  th.tier-1 {
    background: rgba(196,197,200,0.7);
  }
  th.tier-2 {
    background: rgba(196,197,200,0.3);
  }
  .el-table--border th.tier-cell {
    padding-top: 0;
    border-top: none;
    background-color: #c4c5c8;
  }
  .el-table--border td.tier-cell {
    background-color: #c4c5c8;
  }
  .el-table--border th.tier-cell.tier-2 {
    padding-top: 0;
    border-top: none;
    background-color: rgba(196,197,200,0.7);
  }
  .el-table--border td.tier-cell.tier-2 {
    background-color: rgba(196,197,200,0.7);
  }
  .el-table--border th {
    border-bottom: none;
    border-top: 1px solid #ebeef5;
  }
  .el-table--border td {
    border-bottom: none;
    border-top: 1px solid #ebeef5;
  }
  .el-table--border td:last-child {
    border-bottom: 1px solid #ebeef5;
  }
}
</style>
