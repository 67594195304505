<template>
  <div class="settings-page">
    <v-container fluid>
      <v-row class="mx-n6">
        <v-col class="py-0">
          <h3 class="mb-6 mt-6">Settings</h3>
        </v-col>
        <v-col :cols="3" :offset="3" class="text-right d-flex align-center justify-end py-0">
          <template v-if="superuser">
            <label class="v-label theme--light mr-2">Dev mode</label>
            <v-switch
              :input-value="devMode"
              hide-details
              class="d-inline-block pt-0 mt-0"
              @change="handleDevModeChanged"
            />
          </template>
        </v-col>
      </v-row>
      <v-row class="mt-0 mx-n9">
        <v-col :cols="4" class="px-6 pb-6">
          <v-card class="pa-4 justify-center">
            <v-row class="mt-15 text-center">
              <div class="tile-image mx-auto">
                <img
                  class="flex-grow-0"
                  :src="logoSrc"
                  alt="logo"
                />
              </div>
            </v-row>
            <v-row class="text-center">
              <div v-if="organisations.length <= 1" class="text-uppercase mt-6 mb-12 tile-text mx-auto">{{ orgName }}</div>
              <v-menu offset-y nudge-left="25%" v-else>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-uppercase mt-6 mb-12 tile-text mx-auto" v-bind="attrs" v-on="on">
                    {{ orgName }}
                    <v-icon class="action-btn-primary">arrow_drop_down</v-icon>
                  </div>
                </template>
                <v-list>
                  <v-list-item
                    v-for="org in organisations.filter(o => o.org_key.toString() !== orgKey.toString())"
                    :key="org.org_key"
                    class="c-pointer text-center px-8"
                    @click="changeOrganisation(org)">
                    <v-list-item-title>{{org.organisation}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </v-card>
        </v-col>
        <v-col :cols="4" class="px-6 pb-6">
          <v-card class="pa-4 d-flex justify-center" @click="$router.push({ name: currentModule + '-settings-users' })">
            <div class="mt-16 text-center">
              <v-icon class="action-btn-primary tile-icon">people</v-icon>
              <div class="text-uppercase mt-8 mb-10 tile-text">Users</div>
            </div>
          </v-card>
        </v-col>
        <v-col :cols="4" class="px-6 pb-6">
          <v-card class="pa-4 d-flex justify-center" @click="$router.push({ name: currentModule + '-settings-stores' })">
            <div class="mt-16 text-center">
              <v-icon class="action-btn-primary tile-icon">maps_home_work</v-icon>
              <div class="text-uppercase mt-8 mb-10 tile-text">Stores</div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mx-n9">
        <v-col :cols="4" class="pa-6">
          <v-card class="pa-4 d-flex justify-center" @click="$router.push({ name: currentModule + '-settings-data' })">
            <div class="mt-16 text-center">
              <v-icon class="action-btn-primary tile-icon">source</v-icon>
              <div class="text-uppercase mt-8 mb-10 tile-text">Data</div>
            </div>
          </v-card>
        </v-col>
        <v-col :cols="4" class="pa-6">
          <v-card class="pa-4 d-flex justify-center" @click="$router.push({ name: currentModule + '-settings-configuration' })">
            <div class="mt-16 text-center">
              <v-icon class="action-btn-primary tile-icon">settings</v-icon>
              <div class="text-uppercase mt-8 mb-10 tile-text">Configuration</div>
            </div>
          </v-card>
        </v-col>
        <v-col :cols="4" class="pa-6">
          <v-card class="pa-4 d-flex justify-center" @click="handleLogoutClick">
            <div class="mt-16 text-center">
              <v-icon class="action-btn-primary tile-icon">logout</v-icon>
              <div class="text-uppercase mt-8 mb-10 tile-text">Log out</div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="end" class="pt-0 mt-0">
        <v-col :cols="2" class="text-right pa-0">
          <span>
            Debug Info <v-icon class="c-pointer" @click="openDebugOverlay">info</v-icon>
          </span>
        </v-col>
      </v-row>
      <v-dialog
        v-model="debugOverlay"
        max-width="600">
        <DebugOverlay
          :devMode="devMode"
          :superuser="superuser"
          @close="debugOverlay = false"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { useGeneralStore } from '@/store/pinia/generalStore'
import { mapState } from 'pinia'
import DebugOverlay from '@/components/DebugOverlay.vue'

export default {
  components: { DebugOverlay },
  data () {
    return {
      orgName: localStorage.getItem('org'),
      orgKey: localStorage.getItem('org_key'),
      debugOverlay: false
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['devMode', 'currentModule']),

    superuser () {
      return (localStorage.getItem('superuser') === 'true')
    },
    logoSrc () {
      return `${import.meta.env.VITE_LOGOS_HOST}system/logo_${this.orgKey}.png`
    },
    organisations () {
      return this.$auth.getOrganisations()
    }
  },
  methods: {
    openDebugOverlay () {
      this.debugOverlay = true
    },
    changeOrganisation (org) {
      this.$auth.setOrgKey(org.org_key)
    },
    handleDevModeChanged () {
      useGeneralStore().setDevMode(!this.devMode)
      this.logout()
    },
    handleLogoutClick () {
      this.logout()
    },
    logout () {
      this.$auth.logout()
      this.$router.push({ 'path': '/login' })
    },
  },
}
</script>

<style lang="scss">
.settings-page {
    .v-card {
        .tile-icon {
            cursor: pointer;
            font-size: 100px;
        }
        .tile-text {
            font-size: 20px;
            font-weight: 500;
        }
        .tile-image {
            height: 115px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        &:hover {
            .tile-icon {
                color: var(--primary)
            }
        }
    }
}
</style>
