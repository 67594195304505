// src/api/store.ts
import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'
import {Store} from '@/models/Store'

export async function createStore (payload: Store) {
  const url = baseURLs.manageAPI + '/stores'
  await axios.post(url, payload)
}

export async function updateStores (payload: Store|Store[]){
  const url = baseURLs.manageAPI + '/stores'
  await axios.put(url, payload)
}

export async function deleteStore (payload: Store) {
  const url = baseURLs.manageAPI + '/stores'
  await axios.delete(url, { data: payload })
}
