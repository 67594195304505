<script>
import {defineComponent} from 'vue'
import DataTablesColumn from '@/components/Tables/components/DataTablesColumn.vue'
import WeekSelector from '@/components/WeekSelector.vue'

export default defineComponent({
  name: 'ProductsOrders',
  components: {WeekSelector, DataTablesColumn},
  props: {
    product: {
      type: Object,
      default: ()=> ({})
    },
    isDisabled: {
      type:Boolean,
    }
  },
  data (){
    return {
      newChunk: {}
    }
  },
  methods: {
    handleAddChunkClick (){
      let fails = false
      if(!this.newChunk.week_number){
        this.newChunk.week_number = undefined
        fails = true
      }
      if(!this.newChunk.pieces){
        this.newChunk.pieces = undefined
        fails = true
      }
      if(!this.newChunk.original_price){
        this.newChunk.original_price = undefined
        fails = true
      }
      if(!this.newChunk.cost_price){
        this.newChunk.cost_price = undefined
        fails = true
      }
      if(fails){
        return
      }
      this.setDefaultChunk()
      this.$emit('chunk-created', this.newChunk)
    },
    handleDeleteChunkClick (row){
      row.product = this.product
      this.$emit('chunk-deleted', row)
    },
    setDefaultChunk (){
      this.newChunk = {
        original_value: null,
        cost_value: null,
        original_price: this.product.original_price,
        cost_price: this.product.cost_price,
        product: this.product,
        product_key: this.product.product_key,
        week_number: null,
        pieces: null,
        isChild: true,
        isNewChunk: true,
        isChildEditable: true,
        type: 'placeholder',
        rowKey: `new-chunk-row-${this.product.product_key}`,
      }
    }
  },
  async mounted () {
    this.setDefaultChunk()
  },
  computed:{

    priceFormatterForPrice () {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.$auth.getUserCurrency().currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    },
  }
})
</script>

<template>
  <div>
    <data-tables
      class="pt-4"
      :data="[...product.chunks, newChunk]"
      :page-size="10"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 mb-4 mr-2',
      }"
    >
      <data-tables-column
        column-key="type"
        prop="type"
        label="Type"
      >
        <template slot-scope="scope">
          {{scope.row.type}}
        </template>
      </data-tables-column>
      <data-tables-column
        column-key="week_number"
        prop="week_number"
        label="Week number"
      >
        <template slot-scope="scope">
          <WeekSelector
            v-if="scope.row.type === 'placeholder'"
            v-model="scope.row.week_number"
            :start-year="0"
            only-future
            class="pt-0 mb-1"
            hasNoWeekItem
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            hide-details="auto"
            :error-messages="scope.row.week_number === undefined ? 'Required' :null"
          />
          <div v-else-if="scope.row.week_number">{{ scope.row.week_number | formatWeekNumber }}</div>
          <div v-else>{{ scope.row.week_number }}</div>
        </template>
      </data-tables-column>
      <data-tables-column
        column-key="original_price"
        prop="original_price"
        label="Original price"
      >
        <template slot-scope="scope">
          <v-text-field
            v-if="scope.row.type === 'placeholder'"
            v-model.number="scope.row.original_price"
            hide-details="auto"
            class="mb-1 pt-0"
            :error-messages="scope.row.original_price === undefined ? 'Required' : null"
          />
          <div v-else>{{ priceFormatterForPrice.format(scope.row.original_price) }}</div>        </template>
      </data-tables-column>
      <data-tables-column
        column-key="cost_price"
        prop="cost_price"
        label="Cost price"
      >
        <template slot-scope="scope">
          <v-text-field
            v-if="scope.row.type === 'placeholder'"
            v-model.number="scope.row.cost_price"
            hide-details="auto"
            class="mb-1 pt-0"
            :error-messages="scope.row.cost_price === undefined ? 'Required' : null"
          />
          <div v-else>{{ priceFormatterForPrice.format(scope.row.cost_price) }}</div>
        </template>
      </data-tables-column>
      <data-tables-column
        column-key="pieces"
        prop="pieces"
        label="Pieces"
      >
        <template slot-scope="scope">
          <v-text-field
            v-if="scope.row.type === 'placeholder'"
            v-model.number="scope.row.pieces"
            hide-details="auto"
            class="mb-1 pt-0"
            :error-messages="scope.row.pieces === undefined ? 'Required' : null"
          />
          <div v-else>{{scope.row.pieces}}</div>
        </template>
      </data-tables-column>
      <el-table-column width="50">
        <template slot-scope="scope">
          <v-icon
            v-if="scope.row.isNewChunk"
            class="my-2"
            @click="handleAddChunkClick(scope.row)"
          >add</v-icon>
          <v-icon
            v-else
            class="my-2 action-btn-danger"
            :disabled="scope.row.type !== 'placeholder'"
            @click="handleDeleteChunkClick(scope.row)"
          >delete</v-icon>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<style scoped lang="scss">

</style>
