<template>
  <v-row class="align-baseline" id="pricing-markdown-builder-filters">
    <v-col cols="11">
      <v-row>
        <v-col>
          <v-combobox
            v-model="inputForm.moments.value"
            :items="markdownMomentsList"
            multiple
            chips
            small-chips
            deletable-chips
            clearable
            hide-details
            class="pt-0"
            label="Markdown moments"
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @blur="saveInputForm(); $emit('form-changed')"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip
                v-if="index === 0"
                small
                close
                outlined
              >
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption mx-1">
                +{{ inputForm.moments.value.length - 1 }}
              </span>
            </template>
          </v-combobox>
        </v-col>
        <v-col>
          <v-combobox
            v-model="inputForm.weeks.value"
            :items="weeks"
            multiple
            chips
            small-chips
            deletable-chips
            clearable
            hide-details
            class="pt-0"
            label="Weeks"
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @blur="saveInputForm(); $emit('form-changed')"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip
                v-if="index === 0"
                small
                close
                outlined
              >
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption mx-1">
                +{{ inputForm.weeks.value.length - 1 }}
              </span>
            </template>
          </v-combobox>
        </v-col>
        <v-col>
          <v-select
            v-model="inputForm.method.value"
            :items="methodsList"
            hide-details
            class="pt-0"
            label="Method"
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @blur="saveInputForm(); $emit('form-changed')"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1" class="text-right">
      <v-menu
        v-model="menuOpened"
        :close-on-content-click="false"
        attach="#pricing-markdown-builder-filters"
        offset-y
        left
        min-width="450"
        max-width="450"
        nudge-bottom="20"
        content-class="input-menu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on">tune</v-icon>
        </template>

        <v-card>
          <h4 class="ml-4 mb-6 pt-6">
            Input
          </h4>
          <div class="input-parameter">
            <div class="parameter-name">Dimension</div>
            <div class="parameter-value">
              <v-select
                v-model="inputForm.dimension.value"
                :multiple="false"
                :items="analyticsPropertiesGrouped"
                hide-details
                class="pt-0"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @change="formChanged = true"
              />
            </div>
          </div>
          <div class="input-parameter">
            <div class="parameter-name">From discount</div>
            <div class="parameter-value">
              <div class="d-flex align-center slider">
                <div class="slider-value-from mr-2">0</div>
                <v-range-slider
                  v-model="inputForm.from_discount.value"
                  hide-details
                  thumb-label
                  strict
                  track-color="#ccc"
                  track-fill-color="primary"
                  min="0"
                  max="1"
                  step="0.05"
                  @change="formChanged = true" />
                <div class="slider-value-to ml-2">1</div>
              </div>
            </div>
          </div>
          <div class="input-parameter">
            <div class="parameter-name">To discount</div>
            <div class="parameter-value">
              <div class="d-flex align-center slider">
                <div class="slider-value-from mr-2">0</div>
                <v-range-slider
                  v-model="inputForm.to_discount.value"
                  hide-details
                  thumb-label
                  strict
                  track-color="#ccc"
                  track-fill-color="primary"
                  min="0"
                  max="1"
                  step="0.05"
                  @change="formChanged = true" />
                <div class="slider-value-to ml-2">1</div>
              </div>
            </div>
          </div>
          <div class="input-parameter">
            <div class="parameter-name">Discount delta</div>
            <div class="parameter-value">
              <div class="d-flex align-center slider">
                <div class="slider-value-from mr-2">0</div>
                <v-slider
                  v-model="inputForm.discount_delta.value"
                  hide-details
                  thumb-label
                  strict
                  track-color="#ccc"
                  track-fill-color="primary"
                  min="0"
                  max="1"
                  step="0.05"
                  @change="formChanged = true" />
                <div class="slider-value-to ml-2">1</div>
              </div>
            </div>
          </div>
          <div class="input-parameter">
            <div class="parameter-name">Discount value delta</div>
            <div class="parameter-value">
              <v-text-field
                type="number"
                v-model.number="inputForm.discount_value_delta.value"
                hide-details="auto"
                class="pt-0"
                @change="formChanged = true"
              ></v-text-field>
            </div>
          </div>
          <div class="input-parameter">
            <div class="parameter-name">Weeks on hand min</div>
            <div class="parameter-value">
              <div class="d-flex align-center slider">
                <div class="slider-value-from mr-2">0</div>
                <v-slider
                  v-model="inputForm.weeks_on_hand_min.value"
                  hide-details
                  thumb-label
                  strict
                  track-color="#ccc"
                  track-fill-color="primary"
                  min="0"
                  max="52"
                  @change="formChanged = true" />
                <div class="slider-value-to ml-2">52</div>
              </div>
            </div>
          </div>
          <div class="input-parameter">
            <div class="parameter-name">Sellthrough</div>
            <div class="parameter-value">
              <div class="d-flex align-center slider">
                <div class="slider-value-from mr-2">0</div>
                <v-range-slider
                  v-model="inputForm.sellthrough.value"
                  hide-details
                  thumb-label
                  strict
                  track-color="#ccc"
                  track-fill-color="primary"
                  min="0"
                  max="1"
                  step="0.05"
                  @change="formChanged = true" />
                <div class="slider-value-to ml-2">1</div>
              </div>
            </div>
          </div>
          <div class="input-parameter">
            <div class="parameter-name">Sellthrough end</div>
            <div class="parameter-value">
              <div class="d-flex align-center slider">
                <div class="slider-value-from mr-2">0</div>
                <v-range-slider
                  v-model="inputForm.sellthrough_end.value"
                  hide-details
                  thumb-label
                  strict
                  track-color="#ccc"
                  track-fill-color="primary"
                  min="0"
                  max="1"
                  step="0.05"
                  @change="formChanged = true" />
                <div class="slider-value-to ml-2">1</div>
              </div>
            </div>
          </div>
          <div class="input-parameter">
            <div class="parameter-name">Residual min</div>
            <div class="parameter-value">
              <v-text-field
                type="number"
                v-model.number="inputForm.residual_min.value"
                hide-details="auto"
                class="pt-0"
                @change="formChanged = true"
              ></v-text-field>
            </div>
          </div>
          <div class="input-parameter">
            <div class="parameter-name">Stock budget</div>
            <div class="parameter-value">
              <v-text-field
                type="number"
                v-model.number="inputForm.stock_budget.value"
                hide-details="auto"
                class="pt-0"
                @change="formChanged = true"
              ></v-text-field>
            </div>
          </div>
          <div class="input-parameter">
            <div class="parameter-name">Markdown perm budget</div>
            <div class="parameter-value">
              <v-text-field
                type="number"
                v-model.number="inputForm.markdown_perm_budget.value"
                hide-details="auto"
                class="pt-0"
                @change="formChanged = true"
              ></v-text-field>
            </div>
          </div>
          <div class="input-parameter">
            <div class="parameter-name">Weeks history (=lxw)</div>
            <div class="parameter-value">
              <div class="d-flex align-center slider">
                <div class="slider-value-from mr-2">1</div>
                <v-slider
                  v-model="inputForm.weeks_history.value"
                  hide-details
                  thumb-label
                  strict
                  track-color="#ccc"
                  track-fill-color="primary"
                  min="0"
                  max="52"
                  @change="formChanged = true" />
                <div class="slider-value-to ml-2">26</div>
              </div>
            </div>
          </div>
          <div class="input-parameter">
            <div class="parameter-name">Sellthrough lxw</div>
            <div class="parameter-value">
              <div class="d-flex align-center slider">
                <div class="slider-value-from mr-2">0</div>
                <v-range-slider
                  v-model="inputForm.sellthrough_lxw.value"
                  hide-details
                  thumb-label
                  strict
                  track-color="#ccc"
                  track-fill-color="primary"
                  min="0"
                  max="1"
                  step="0.05"
                  @change="formChanged = true" />
                <div class="slider-value-to ml-2">1</div>
              </div>
            </div>
          </div>

        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { groupAnalyticsProperties } from '@/utils'
import { loadFilterProperties } from '@/api/analytics'

export default {
  name: 'PricingMarkdownBuilderFilters',
  props: {
    weeks: {
      type: Array,
      default: () => []
    },
    markdownMomentsList: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      methodsList: [
        { text: 'Residual', value: 'residual' },
        { text: 'Value', value: 'value' },
        { text: 'Relative', value: 'relative' },
      ],
      analyticsProperties: [],
      menuOpened: false,
      formChanged: false,
      inputForm: {
        moments: {
          value: [],
          error: null,
        },
        weeks: {
          value: [],
          error: null,
        },
        method: {
          value: 'relative',
          error: null,
        },
        dimension: {
          value: 'properties/article/group',
          error: null,
        },
        from_discount: {
          value: [0, 1],
          error: null,
        },
        to_discount: {
          value: [0, 1],
          error: null,
        },
        discount_delta: {
          value: 0,
          error: null,
        },
        discount_value_delta: {
          value: undefined,
          error: null,
        },
        weeks_on_hand_min: {
          value: 0,
          error: null,
        },
        sellthrough: {
          value: [0, 1],
          error: null,
        },
        sellthrough_end: {
          value: [0, 1],
          error: null,
        },
        residual_min: {
          value: 100,
          error: null,
        },
        stock_budget: {
          value: null,
          error: null,
        },
        markdown_perm_budget: {
          value: null,
          error: null,
        },
        weeks_history: {
          value: 5,
          error: null,
        },
        sellthrough_lxw: {
          value: [0, 1],
          error: null,
        },
        elasticity: {
          value: 0,
          error: null,
        },
      }
    }
  },
  computed: {
    analyticsPropertiesGrouped () {
      return groupAnalyticsProperties(this.analyticsProperties)
    },
  },
  watch: {
    menuOpened (val) {
      if (!val) {
        this.saveInputForm()
        this.$emit('form-changed')
      }
    }
  },
  methods: {
    saveInputForm () {
      localStorage.setItem('pricingMarkdownBuilderFilters', JSON.stringify(this.inputForm))
    },
    loadInputForm () {
      const inputForm = localStorage.getItem('pricingMarkdownBuilderFilters')

      if (inputForm) {
        this.inputForm = JSON.parse(inputForm)
        if (!this.inputForm.sellthrough_lxw) {
          this.inputForm.sellthrough_lxw = {
            value: [0, 1],
            error: null,
          }
        }
        if(!this.inputForm.discount_value_delta) {
          this.inputForm.discount_value_delta = {
            value: undefined,
            error: null,
          }
        }
      }
    }
  },
  async created () {
    const properties = await loadFilterProperties('skus')
    this.analyticsProperties = Object.keys(properties)
    this.loadInputForm()
  },
}
</script>

<style lang="scss">
.input-menu {
    .input-parameter {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        border-bottom: 1px solid var(--border-color);
        padding: 0 16px;
        .parameter-name {
            width: 200px;
        }
        .parameter-value {
            width: 250px;
            padding: 8px 0;
        }
        .slider {
            padding-top: 4px;
            .slider-value-from,
            .slider-value-to {
                color: #666;
                font-size: 14px;
            }
        }
        &:last-child {
            padding-bottom: 8px;
        }
    }
}
</style>
