<template>
  <div id="strategy-margin-charts" class="mx-n6">
    <h6 class="text-subtitle-1 font-weight-medium mx-6">Margin</h6>
    <v-carousel
      height="auto"
      class="pb-15"
      delimiter-icon="circle"
      :perPage="1"
      light
      hide-delimiter-background
      :show-arrows="false"
    >
      <v-carousel-item eager>
        <div
          class="chart-container mt-4 mx-4"
          style="position: relative; height: 350px;"
        >
          <canvas id="percentagesChart"></canvas>
        </div>
      </v-carousel-item>
      <v-carousel-item eager>
        <div
          class="chart-container mt-4 mx-4"
          style="position: relative; height: 350px;"
        >
          <canvas id="marginChart"></canvas>
        </div>
      </v-carousel-item>
    </v-carousel>

    <h6 class="text-subtitle-1 font-weight-medium mx-6">Margin waterfall</h6>
    <div
      class="chart-container mt-4 mx-4 pb-8"
      style="position: relative; height: 350px;"
    >
      <canvas id="waterfallChart"></canvas>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { Chart } from 'chart.js/auto'
import { formatLargeNumber } from '@/utils'

export default {
  name: 'StrategyMarginCharts',
  props: {
    chartsData: {
      type: Object || null,
      default: null,
    },
    chartsOptions: {
      type: Object || null,
      default: null,
    },
  },
  data () {
    this.chartOptions = {
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      hover: {
        mode: 'index',
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          grid: {
            display: false
          },
          ticks: {
            font: {
              size: 14,
            },
            callback: (value)=> formatLargeNumber(value),
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
          }
        },
        tooltip: {
          callbacks: {
            label: context => {
              return this.$options.filters.formatThousands(context.raw)
            },
          }
        },
      },
    }
    this.percentagesChart = null
    this.marginChart = null

    return {
    }
  },
  methods: {
    drawCharts () {
      ['percentagesChart', 'marginChart', 'waterfallChart'].forEach(chart => {
        if (this[chart]) {
          this[chart].destroy()
        }
      })

      // percentages chart
      let ctx = document.getElementById('percentagesChart')
      let options = cloneDeep(this.chartOptions)

      options.scales.y1 = {
        position: 'left',
        title: {
          display: true,
          text: 'Percentage',
          font: {
            size: 14,
          }
        },
        grid: {
          drawTicks: false,
        },
        beginAtZero: true,
        ticks: {
          callback: (value)=> formatLargeNumber(value),
          padding: 16,
          font: {
            size: 14,
          }
        },
      }
      options.plugins.legend.labels.generateLabels = chart => {
        const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)

        for (const index in labels) {
          if (this.chartsOptions.percentages.measures[index].type === 'dotted') {
            labels[index].lineDash = [5, 5]
          }
        }

        return labels
      }

      this.percentagesChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.chartsData.percentages.weekNumbers,
          datasets: this.chartsOptions.percentages.measures.map(o => {
            return {
              label: o.name,
              data: this.chartsData.percentages[o.name],
              yAxisID: o.axis,
              borderColor: o.color_code,
              borderWidth: 2,
              backgroundColor: '#FFFFFF',
              pointRadius: null,
              borderDash: o.type === 'dotted' ? [5, 5] : [],
              tension: 0.4,
            }
          })
        },
        options,
      })

      // margin chart
      ctx = document.getElementById('marginChart')
      options = cloneDeep(this.chartOptions)

      options.scales.x.stacked = true
      options.scales.y1 = {
        position: 'left',
        stacked: true,
        title: {
          display: true,
          text: 'Euros',
          font: {
            size: 14,
          },
        },
        grid: {
          drawTicks: false,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          callback: (value)=> formatLargeNumber(value),
          padding: 16,
          font: {
            size: 14,
          }
        },
      }
      options.scales.y2 = {
        position: 'right',
        stacked: true,
        title: {
          display: true,
          text: 'Percentage',
          font: {
            size: 14,
          }
        },
        grid: {
          display: false,
          drawTicks: false,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          callback: (value)=> formatLargeNumber(value),
          padding: 16,
          font: {
            size: 14,
          }
        },
      }
      this.marginChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.chartsData.margin.weekNumbers,
          datasets: this.chartsOptions.margin.measures.map(o => {
            let dataset = {
              label: o.name,
              data: this.chartsData.margin[o.name],
              yAxisID: o.axis,
              type: 'bubble',
              showLine: false,
            }

            if (o.type === 'bar') {
              dataset = {
                ...dataset,
                type: 'bar',
                backgroundColor: o.fill_color_code + '4d',
                borderColor: o.border_color_code,
                borderWidth: 2,
              }
            }

            return dataset
          })
        },
        options,
      })

      // waterfall chart
      const metrics = ['Margin value (bdg)', 'Revenue ov delta', 'Discount delta', 'Cost value delta', 'VAT delta', 'Margin value (act)']
      let data = metrics.map(m => parseInt(this.chartsData.waterfall[m][0]))
      let acc = 0

      // Preparing data for waterfall chart: every entry is array of start and end value. Start value for each bar is end of previous bar.
      data = metrics.map((m, i) => {
        const start = i === data.length - 1 ? 0 : acc
        const end = start + data[i]
        acc += data[i]

        return [start, end]
      })
      const backgroundColors = data.map(m => (m[1] - m[0]) > 0 ? '#007AFF' : '#EE3766')
      ctx = document.getElementById('waterfallChart')
      options = cloneDeep(this.chartOptions)
      options.plugins.legend.display = false
      options.plugins.tooltip = {
        callbacks: {
          label: context => {
            return this.$options.filters.formatThousands(context.raw[1] - context.raw[0])
          },
        }
      }
      options.scales.y = {
        position: 'left',
        stacked: true,
        title: {
          display: true,
          text: 'Euros',
          font: {
            size: 14,
          },
        },
        grid: {
          drawTicks: false,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          callback: (value)=> formatLargeNumber(value),
          padding: 16,
          font: {
            size: 14,
          }
        },
      }
      this.waterfallChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: metrics,
          datasets: [{
            data: data,
            backgroundColor: backgroundColors,
            barPercentage: 1
          }],
        },
        options,
      })
    },
    updateCharts () {
      this.drawCharts()
    },
  },
}
</script>

<style lang="scss">
#strategy-margin-charts {
}
</style>
