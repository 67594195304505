<template>
  <v-row class="bordered-list-row rule-data-row">
    <v-col cols="3" class="px-6 py-4">
      <v-tooltip top :max-width="250">
        <template v-slot:activator="{ on, attrs }">
          <v-icon :size="21" class="mr-2" v-bind="attrs" v-on="on">info</v-icon>
        </template>
        <span>{{ getRuleInfo(rule.id).description }}</span>
      </v-tooltip>
      {{ getRuleInfo(rule.id).name }}
    </v-col>
    <v-col cols="4" offset="2">
      <form class="form-inline" v-for="(item, idx) in getRuleInfo(rule.id).variables" :key="idx">
        <!-- Check if we need a slider, input field or switch -->
        <template v-if="getRuleInfo(rule.id).types[idx] === 'range-slider'">
          <div class="d-flex align-center">
            <div class="slider-value-from mr-2">{{ getRuleInfo(rule.id).minmax[idx][0] }}</div>
            <v-range-slider
              v-model="rule[item]"
              hide-details
              thumb-label
              track-color="#ccc"
              track-fill-color="primary"
              :max="getRuleInfo(rule.id).minmax[idx][1]"
              :min="getRuleInfo(rule.id).minmax[idx][0]"
              @change="$emit('change')" />
            <div class="slider-value-to ml-2">{{ getRuleInfo(rule.id).minmax[idx][1] }}</div>
          </div>
        </template>
        <template v-if="getRuleInfo(rule.id).types[idx] === 'slider'">
          <div class="d-flex align-center">
            <div class="slider-value-from mr-2">{{ getRuleInfo(rule.id).minmax[idx][0] }}</div>
            <v-slider
              v-model="rule[item]"
              hide-details
              thumb-label
              track-color="#ccc"
              track-fill-color="primary"
              :max="getRuleInfo(rule.id).minmax[idx][1]"
              :min="getRuleInfo(rule.id).minmax[idx][0]"
              @change="$emit('change')" />
            <div class="slider-value-to ml-2">{{ getRuleInfo(rule.id).minmax[idx][1] }}</div>
          </div>
        </template>
        <template v-else-if="getRuleInfo(rule.id).types[idx] === 'int-float'">
          <v-text-field
            type="number"
            dense
            v-model="rule[item]"
            hide-details
            @change="$emit('change')" />
        </template>
        <template v-else-if="getRuleInfo(rule.id).types[idx] === 'bool'">
          <el-switch
            v-model="rule[item]"
            class="mr-10"
          >
          </el-switch>
        </template>
      </form>
    </v-col>
    <v-col class="text-right">
      <v-icon class="mx-2 action-btn-danger" @click="$emit('delete', index)">delete</v-icon>
    </v-col>
  </v-row>
</template>

<script>
import { useRulesStore } from '@/store/pinia/rulesStore'

export default {
  props: ['rule', 'index'],
  setup () {
    return {
      rulesStore: useRulesStore()
    }
  },
  computed: {
    rules () {
      return this.rulesStore.rules
    },
  },
  components: {
  },
  methods: {
    getRuleInfo (id) {
      const defaultRule = JSON.parse(JSON.stringify(this.rules.find(x => x.id === id)))
      defaultRule['variables'] = []
      defaultRule['types'] = []
      defaultRule['minmax'] = []
      try {
        defaultRule['args_key'] = Object.values(defaultRule['args_key'])
      } catch (error) {
        defaultRule['args_key'] = []
      }
      for (const def in defaultRule.args_default) {
        defaultRule['variables'].push(def)
        if (Object.keys(defaultRule).includes('args_slider') && Object.keys(defaultRule['args_slider']).includes(def)) {
          if (Array.isArray(defaultRule['args_default'][def])) {
            defaultRule['types'].push('range-slider')
            defaultRule['minmax'].push(defaultRule['args_slider'][def])
          } else {
            defaultRule['types'].push('slider')
            defaultRule['minmax'].push(defaultRule['args_slider'][def])
          }
        } else if (Array.isArray(defaultRule.args_default[def])) {
          defaultRule['types'].push('int array')
          defaultRule['minmax'].push([])
        } else if (typeof defaultRule.args_default[def] === 'boolean') {
          defaultRule['types'].push('bool')
          defaultRule['minmax'].push([])
        } else if (defaultRule.args_default[def] === Object(defaultRule.args_default[def])) {
          defaultRule['types'].push('obj')
          defaultRule['minmax'].push([])
        } else {
          defaultRule['types'].push('int-float')
          defaultRule['minmax'].push([])
        }
      }

      return defaultRule
    },
  }
}
</script>

<style lang="scss">
.rule-data-row {
}
</style>
