<template>
  <v-col class="pa-0 shop-grid p-relative">

    <v-overlay absolute color="white" :value="!loaded">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <h4 class="pt-4 px-4">
      Budget grid
    </h4>
    <v-row class="justify-center p-relative py-4 ma-0 mx-4 p-relative">
      <v-btn-toggle
        :value="chartIndex"
        @change="saveChartIndex"
        dense
        active-class="bg-gray"
        light
      >
        <v-btn
          v-for="(type, index) in types"
          :key="index"
          small
          color="white"
        >
          <span class="text-capitalize">{{ type.name | formatString}}</span>
        </v-btn>
      </v-btn-toggle>
      <v-col class="d-flex pa-0 p-absolute right"  style="right: 0" cols="4">
        <WeekSelector
          :value="kpiFilters.week_min"
          clearable
          @input="$emit('filters-changed', {week_max: kpiFilters.week_max, week_min: $event})"
          class="mr-2 pt-0 mt-0 "
          label="Week min"
          :higher-limit="kpiFilters.week_max"
          hide-details
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"                        >

        </WeekSelector>
        <WeekSelector
          :value="kpiFilters.week_max"
          clearable
          :lower-limit="kpiFilters.week_min"
          @input="$emit('filters-changed', {week_min: kpiFilters.week_min, week_max: $event})"
          class="ml-2 pt-0 mt-0 "
          label="Week max"
          hide-details
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"                        >

        </WeekSelector>
      </v-col>

    </v-row>
    <data-tables
      :data="data.map((item)=> ({...item}))"
      :filters="tableFilters"
      :page-size="10"
      @row-click="handleRowClick"
      @filtered-data="filteredData = $event"
      @filter-change="filters => handleFilterChange('tableFilters', filters)"
      :table-props="{
        'row-class-name': isSpecial,
        'summary-method': getSummary,
        'show-summary': true,
        'expand-row-keys': [currentShopItemRow],
        'row-key': tableProps,
        'tree-props': {children: 'ct'}
      }"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
    >

      <el-table-column
        v-for="(dimension, index) in dimensions"
        :key="dimension"
        :label="dimension | formatString"
        :column-key="dimension"
        :prop="dimension"
        class-name="pl-3"
        :filters="getColumnFilterValues('data', dimension)"
        sortable="custom"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.ct" >
            {{scope.row[dimension]}}
          </div>
          <v-row v-else-if="index === 0" class="justify-start ma-0" style="min-width: 100%">
            <OptionDataCell
              :option="scope.row"

              @image-click="$router.push({
                name: 'buy-shop-item-page',
                query: {
                  shop_item_key: scope.row.shop_item_key,
                  budget_key: $route.query.budget_key,
                  scenario_key: $route.query.scenario_key,
                },
              })"
              :url="{
                name: 'buy-shop-item-page',
                query: {
                  shop_item_key: scope.row.shop_item_key,
                  budget_key: $route.query.budget_key,
                  scenario_key: $route.query.scenario_key,
                },
              }"
              name-key="name"
            />
          </v-row>
          <div v-else></div>
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"

        label="Ordered"
        :column-key="`ordered_${type}`"
        :prop="`ordered_${type}`"
        :key="`ordered_${type}`"
      >
        <template slot-scope="scope">
          {{defaultCurrencySymbol}} {{scope.row[`ordered_${type}`] | formatThousands}}
          <span v-if="!scope.row.ct" class="pl-1">
            ({{(scope.row[`ordered_${type}_perc`] || 0) | formatPercents}}%)
          </span>
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"

        label="In Progress"
        :column-key="`in_progress_${type}`"
        :prop="`in_progress_${type}`"
        :key="`in_progress_${type}`"
      >
        <template slot-scope="scope">
          {{defaultCurrencySymbol}} {{scope.row[`in_progress_${type}`] | formatThousands}}
          <span v-if="!scope.row.ct" class="pl-1">
            ({{(scope.row[`in_progress_${type}_perc`] || 0) | formatPercents}}%)
          </span>
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        label="To order"
        :column-key="`to_order_${type}`"
        :prop="`to_order_${type}`"
        :key="`to_order_${type}`"
      >
        <template slot-scope="scope">

          {{defaultCurrencySymbol}} {{scope.row[`to_order_${type}`] | formatThousands}}
          <span v-if="!scope.row.ct" class="pl-1">
            ({{(scope.row[`to_order_${type}_perc`] || 0) | formatPercents}}%)
          </span>                </template>
      </el-table-column>
      <el-table-column
        label="Total"
        :column-key="`total_${type}`"
        sortable="custom"
        :prop="`total_${type}`"
        :key="`total_${type}`"
      >
        <template slot-scope="scope">

          {{defaultCurrencySymbol}} {{scope.row[`total_${type}`] | formatThousands}}
          <span v-if="!scope.row.ct" class="pl-1">
            ({{(scope.row[`total_${type}_perc`] || 0) | formatPercents}}%)
          </span>
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        label="Budget"
        :column-key="`budget_${type}`"
        :prop="`budget_${type}`"
        :key="`budget_${type}`"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.ct">
            {{defaultCurrencySymbol}} {{scope.row[`budget_${type}`] | formatThousands}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="Open"
        :column-key="`open_${type}`"
        sortable="custom"
        :prop="`open_${type}`"
        :key="`open_${type}`"
      >
        <template slot-scope="scope">

          <span v-if="scope.row.ct" :class="{'error--text': scope.row[`open_${type}`] < 0}">
            {{defaultCurrencySymbol}} {{scope.row[`open_${type}`] | formatThousands}}
            ({{(scope.row[`open_${type}`]/ scope.row[`budget_${type}`] || 0) | formatPercents}}%)

          </span>
        </template>
      </el-table-column>
    </data-tables>
  </v-col>
</template>

<script>
import { getShopGridData } from '@/api/shop'
import OptionDataCell from '@/components/OptionDataCell'
import columnFilters from '@/mixins/columnFilters'
import WeekSelector from '@/components/WeekSelector'
import _ from 'lodash'

export default {
  name: 'ShopGrid',
  components: { WeekSelector, OptionDataCell },
  mixins: [columnFilters],
  data () {
    return {
      dimensions: [],
      loadGridDataDebounce: _.debounce(this.loadGridData, 1000),
      filteredData: [],
      defaultCurrency: localStorage.getItem('defaultCurrency'),
      tableFilters: [
        {
          value: '',
        },
      ],
      loaded: false,
      types: [{ value: 'revenue', name: 'value' }, { value: 'pieces', name: 'pieces' }, { value: 'cost', name: 'costs' }],
      data: [],
      total: {}
    }
  },
  props: {
    chartIndex: {
      type: Number,
      default () {
        return 0
      }
    },
    kpiFilters: {
      type: Object,
      default () {
        return {}
      }
    },
    currencies: {
      type: Array,
      default () {
        return []
      }
    },
  },
  methods: {

    handleRowClick (row) {
      console.log(row)
      if (!row.ct) {
        this.$router.push(this.getShopPageUrl(row.shop_item_key))
      }
    },
    isSpecial ({ row }) {
      const isSpecial = parseInt(row.shop_item_key) === parseInt(this.$route.query.shop_item_key)
      return isSpecial ? 'bg-special cursor-pointer' : 'cursor-pointer'
    },
    getSummary (params) {
      const sums = []
      const { columns } = params
      columns.forEach((column, index) => {

        if (index === 0) {
          sums[0] = 'Total'
          return
        }
        if (index < this.dimensions.length) {
          sums[index] = ''
          return
        }
        const sum = this.filteredData.reduce((prev, cur)=> {

          return prev + cur[column.property]
        }, 0)
        sums[index] = this.defaultCurrencySymbol + ' ' + this.$options.filters.formatThousands(sum)
      })
      return sums
    },
    getShopPageUrl (shopKey) {
      return {
        name: 'buy-shop-item-page',
        query: {
          shop_item_key: shopKey,
          budget_key: this.$route.query.budget_key,
          scenario_key: this.$route.query.scenario_key,
        },
      }
    },
    saveChartIndex (value) {
      this.$emit('chart-index-changed', value)
      localStorage.setItem('buy-chart-carousel', value)
    },
    loadGridData () {
      if(this.gridLoading){
        return
      }
      this.gridLoading = true
      this.loaded = false
      getShopGridData({ ...this.$route.query, ...this.kpiFilters }).then(({ data: { table }, dimensions }) => {
        if (!table) {
          this.loaded = true
          this.data = []
          this.total = {}
          return
        }
        this.data = table.map((item) => ({ ...item, ...item.properties }))
        this.dimensions = dimensions
        this.loaded = true
        this.gridLoading = false
      })
    },

    tableProps (row) {
      if (!row.ct) {
        return row.shop_item_key
      }
      return this.dimensions.reduce((prev, cur) => prev + row[cur], '/')
    },
    load (row, treeNode, resolve) {
      resolve(row.ct)
    }
  },
  watch: {
    kpiFilters: {
      handler () {
        this.loadGridDataDebounce()
      },
      deep: true
    },
    '$route.query': {
      handler () {
        this.loadGridDataDebounce()
      },
      immediate: true
    }
  },

  computed: {
    currentShopItemRow () {
      if (!this.$route.query.shop_item_key) {
        return null
      }
      const data = this.data.find((data) => data.ct.some((item) => item.shop_item_key.toString() === this.$route.query.shop_item_key.toString()))
      if (!data) {
        return null
      }
      return this.dimensions.reduce((prev, cur) => prev + data[cur], '/')
    },
    currencySymbols () {
      return this.currencies.reduce((acc, cur) => ({
        ...acc,
        [cur.iso_code]: cur.symbol,
      }), {})
    },
    defaultCurrencySymbol () {
      return this.chartIndex === 1 ? '' : this.currencySymbols[this.defaultCurrency] || ''
    },
    type () {
      return this.types[this.chartIndex || 0].value
    }
  }
}
</script>

<style lang="scss">
.shop-grid {
  .el-table{
    .cell {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding-left: 0;
    }
  }
  .bg-special td {
    background-color: rgb(227, 227, 227) !important;
  }
  .el-table {
    .el-table__row--level-1:not(.bg-special) {
      background-color: #fafafa !important;
    }
  }
}

</style>
