import axios from '@/services/axios'
import {getHeadersWithAccessToken} from '@/variables'
import baseURLs from '@/api/APIBaseURLs'
import {useGeneralStore} from '@/store/pinia/generalStore'
import {Budget} from '@/models/Config'
import authService from '@/auth/Auth'

export async function loadBeliefs (budget_key = '') {
  const url = baseURLs.manageAPI + '/beliefs'
  try {
    const {data: {beliefs}} = await axios.get(url + '?budget_key=' + budget_key)
    return beliefs.map((item: Record<string, unknown>, index: number) => {
      return {...item, index}
    })
  } catch (message) {
    return console.error(message)
  }
}

// Scenarios
export async function loadScenarios (budget_key = '') {
  const url = baseURLs.manageAPI + '/scenarios'
  try {
    const {data: {scenarios}} = await axios.get(url + '?budget_key=' + budget_key)
    return scenarios.map((item: Record<string, unknown>, index: number) => {
      return {...item, index, budget_key}
    })
  } catch (message) {
    return console.error(message)
  }
}

export async function saveScenario (scenario: unknown) {
  const url = baseURLs.manageAPI + '/scenario'
  try {
    const {data} = await axios.post(url, scenario)
    authService.refreshAppConfig().catch(console.error)
    return data
  } catch (message) {
    return console.error(message)
  }
}

export async function deleteScenario (scenario: unknown) {
  const url = baseURLs.manageAPI + '/scenario'
  try {
    const {data} = await axios.delete(url, {
      headers: getHeadersWithAccessToken(),
      data: scenario
    })
    authService.refreshAppConfig().catch(console.error)
    return data
  } catch (message) {
    return console.error(message)
  }
}

export async function getScenarioMergeHistory (budget_key = '') {
  const url = baseURLs.manageAPI + '/scenario_merge'
  try {
    const {data: {merges}} = await axios.get(url + '?budget_key=' + budget_key)
    return merges.map((item: Record<string, unknown>, index: number) => {
      return {...item, index}
    })
  } catch (message) {
    return console.error(message)
  }
}

export async function mergeScenarios (payload: Record<string, unknown>, dryRun = true) {
  const url = baseURLs.manageAPI + '/scenario_merge'
  try {
    const {data} = await axios.post(url, {...payload, to_merge: !dryRun})
    authService.refreshAppConfig().catch(console.error)
    return data.merge_impact
  } catch (message) {
    return console.error(message)
  }
}

export function getBudgets (): Budget[] {
  const generalStore = useGeneralStore()
  return generalStore.appConfig?.budgets || []
}

export async function deleteBudgets (payload: unknown) {
  const url = baseURLs.manageAPI + '/budget'

  try {
    const response = await axios.delete(url, {
      headers: getHeadersWithAccessToken(),
      data: payload
    })
    authService.refreshAppConfig().catch(console.error)
    return response
  } catch (error) {
    return console.error(error)
  }
}

export async function saveBudget (budget: Budget, beliefs?: unknown) {
  const url = baseURLs.manageAPI + '/budget'
  const payload = {...budget, beliefs: beliefs}
  if (!beliefs) {
    delete payload.beliefs
  }
  try {
    const response = await axios.post(url, payload)
    authService.refreshAppConfig().catch(console.error)
    return response
  } catch (error) {
    return console.error(error)
  }
}

export async function applyBudgetBelief (payload: unknown) {
  const url = baseURLs.manageAPI + '/budget_belief'
  try {
    const {data} = await axios.post(url, payload)
    authService.refreshAppConfig().catch(console.error)
    return data
  } catch (message) {
    return console.error(message)
  }
}

export async function getExternalBudgets () {
  const url = baseURLs.manageAPI + '/data/buy/manage/get_budgets_external_keys'
  try {
    const {data} = await axios.get(url)
    authService.refreshAppConfig().catch(console.error)
    return data.data
  } catch (message) {
    return console.error(message)
  }
}
