import axios from '@/services/axios.js'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

export function loadDummies (payload) {
  const url = baseURLs.manageAPI + '/dummiesv2'

  return axios.post(
    url,
    payload,
  )
    .then(response => response.data)
    .catch(error => console.log(error))
}
export function loadDummiesList (payload) {
  const url = baseURLs.manageAPI + '/dummies'

  return axios.post(
    url,
    payload,
  )
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function dummyExportChange (payload) {
  const url = baseURLs.manageAPI + '/dummy_export'

  return axios.post(
    url,
    payload,
  )
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function copyVariant (payload) {
  const url = baseURLs.manageAPI + '/variant_copy'

  return axios.post(
    url,
    payload,
  )
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function copyDummyWithVariant (payload) {
  const url = baseURLs.manageAPI + '/dummy_copy'

  return axios.post(
    url,
    payload,
  )
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function copyOption (payload) {
  const url = baseURLs.manageAPI + '/studio_copy_existing'
  return axios.post(
    url,
    payload,
  )
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function loadDummy (key) {
  const url = baseURLs.manageAPI + '/dummy?dummy_key=' + key

  return axios.get(url)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function addDummy (variant_key) {
  const url = baseURLs.manageAPI + '/dummy'

  return axios.post(url, {
    option_name: 'New Option',
    variant_key,
  }, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function copyDummy (dummy_key) {
  const url = baseURLs.manageAPI + '/dummy_copy'

  return axios.post(url, {
    dummy_key
  }, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function copyDummyFromOption (option_key, variant_key) {
  const url = baseURLs.manageAPI + '/dummy_copy_option'

  return axios.post(url, {
    option_key,
    variant_key,
  }, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.new_dummy[0])
    .catch(error => console.log(error))
}

export function saveDummy (payload) {
  const url = baseURLs.manageAPI + '/dummy'

  return axios.put(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.dummy)
    .catch(error => console.log(error))
}

export function deleteDummy (dummyKeys) {
  const url = baseURLs.manageAPI + '/dummy'

  return axios.delete(url, {
    headers: getHeadersWithAccessToken(),
    data: { dummy_keys: dummyKeys },
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function getSimilarOptions (payload) {
  const url = baseURLs.logicAPI + '/studio_similar_options'

  return axios.post(url, payload)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function getDummiesBatchTemplate (budget_key, scenario_key) {
  const url = baseURLs.manageAPI + '/dummy_batch_import'

  return axios.get(url + `?budget_key=${budget_key}&scenario_key=${scenario_key}`)
    .then(response => response.data)
    .catch(console.log)
}

export function batchUploadDummies (rows, budget_key, scenario_key) {
  const url = baseURLs.manageAPI + '/dummy_batch_import'

  return axios.post(url, {
    budget_key,
    scenario_key,
    data: rows
  }, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data) // Explicit Error Handling required
}

export function getAllVariants () {
  const url = baseURLs.manageAPI

  return axios.get(url + '/data/buy/studio/variants?ignore_cache=True')
    .then(response => response.data.data)
    .catch(console.log)
}

function variantResponseHandler (response) {
  Object.keys(response.data.variant.properties).forEach(key => {
    if (response.data.variant.properties[key] === 'True') {
      response.data.variant.properties[key] = true
    } else if (response.data.variant.properties[key] === 'False') {
      response.data.variant.properties[key] = false
    }
  })
  return response.data.variant
}

export function getVariant (variantKey) {
  const url = baseURLs.manageAPI + '/variant'

  return axios.get(url + `?variant_key=${variantKey}`)
    .then(variantResponseHandler)
    .catch(console.log)
}

export function saveVariant (data) {
  const url = baseURLs.manageAPI + '/variant'
  return axios
    .post(url, data, {
      headers: getHeadersWithAccessToken()
    })
    .then(variantResponseHandler)
    .catch(console.log)
}

export function deleteVariant (variantKey) {
  const url = baseURLs.manageAPI + '/variant'

  return axios.delete(url, {
    headers: getHeadersWithAccessToken(),
    data: { variant_key: variantKey },
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}
