import axios from '@/services/axios'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

export function getMarkdownMoments () {
  const url = baseURLs.manageAPI + '/markdown_moments'

  return axios.get(url)
    .then(response => {
      return response.data.markdown_moments.map(
        markdownMoment => {
          return {
            ...markdownMoment,
            discounts: markdownMoment.discounts.map(discount => discount * 100)
          }
        }
      )
    }).catch(console.log)
}

export function saveMarkdownMoment (mm) {
  const url = baseURLs.manageAPI + '/markdown_moments'
  mm = {
    ...mm,
    discounts: mm.discounts.map(discount => Number(discount) / 100)
  }
  return axios.post(url, mm)
}

export function deleteMarkdownMoments (keys) {
  const url = baseURLs.manageAPI + '/markdown_moments'

  return axios.delete(url, { headers: getHeadersWithAccessToken(), data: { markdown_moment_keys: keys } })
}
