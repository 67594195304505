<template>
  <div class="storepage container-fluid pt-7" v-show=loaded>
    <v-row class="row mb-50 justify-space-between pb-3">
      <h3 class="page-title">Transfers</h3>
      <h3 class="page-title fr">{{fromStoreName}}</h3>
    </v-row>
    <v-row>
      <v-card class="mt-2 pt-2 flex" min-height="unset"
        v-if="tableData.length">
        <StorePageTransfersTable
          :expanded-row="expanded"
          @storeClicked="getSkuTransfers"
          :table-data="tableData"
          :width="width"></StorePageTransfersTable>
      </v-card>
      <h3 v-else class="pt-12 font-weight-regular">No tasks available</h3>
    </v-row>
    <v-row v-if="expanded" class="pt-5">
      <v-tabs v-model="activeTab" grow show-arrows>
        <v-tabs-slider />
        <v-tab
          class="px-8"
        >
          <!-- show badge for alerts tab and only if there is any alert -->
          <template>
            <span>To Do: {{ (toDoData || []).length}}</span>
          </template>
        </v-tab>
        <v-tab
          class="px-8"
          :disabled="!this.storepageStore.skuTransfersDoneTable.length"
        >
          <!-- show badge for alerts tab and only if there is any alert -->
          <template>
            <span>Done: {{doneData.length}}</span>
          </template>
        </v-tab>
      </v-tabs>
      <v-card class="flex pb-4" v-if="!activeTab">
        <StorePageSkuTransfersTodoTable
          :to-do-data="toDoData"
          @pickedChanged="skuPicked"
          @resetRow="resetRow"
          :width="width"
          @saveClick="_onSaveClick"></StorePageSkuTransfersTodoTable>
      </v-card>
      <v-card class="flex pb-4" v-else>
        <StorePageSkuTransfersDoneTable
          :doneData="doneData"
          @pickedChanged="skuPicked"
          @resetRow="resetRow"
          :width="width"
          @saveClick="_onSaveClick"></StorePageSkuTransfersDoneTable>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import axios from '@/services/axios'
import { getHeadersWithAccessToken, headers } from '@/variables'

import StorePageTransfersTable from '../storepage/StorePageTransfersTable.vue'
import StorePageSkuTransfersDoneTable from '../storepage/StorePageSkuDoneTransfersTable.vue'
import StorePageSkuTransfersTodoTable from '../storepage/StorePageSkuTodoTransfersTable.vue'
import baseURLs from '@/api/APIBaseURLs'
import { useStorepageStore } from '@/store/pinia/storePageStore'

export default {
  name: 'StorePageTransfers',
  setup () {
    return {
      storepageStore: useStorepageStore()
    }
  },
  data () {
    return {
      loaded: false,
      loading: false,
      expanded: null,
      width: 0,
      activeTab: 0
    }
  },
  computed: {
    fromStoreKey () {
      return this.storepageStore.transfersFromStoreKey
    },
    toDoData () {
      return this.storepageStore.skuTransfersTodoTable
    },
    doneData () {
      return this.storepageStore.skuTransfersDoneTable
    },
    fromStoreName () {
      return this.storepageStore.transfersFromStoreName
    },
    toStoreKey () {
      return this.storepageStore.transfersToStoreKey
    },
    toStoreName () {
      return this.storepageStore.transfersToStoreName
    },
    tableData () {
      if (this.storepageStore.transfersTable !== null) {
        return this.storepageStore.transfersTable
      } else {
        return []
      }
    },
  },
  components: {
    StorePageTransfersTable,
    StorePageSkuTransfersDoneTable,
    StorePageSkuTransfersTodoTable
  },
  methods: {
    _onSaveClick () {
      this.getStoreTransfers()
      this.expanded = null
      this.activeTab = 0
    },
    getStoreTransfers () {
      this.loading = true
      const payload = { store_key: parseInt(this.$route.params.store_key) }
      if (localStorage.getItem('storepage_token')) {
        payload['token'] = localStorage.getItem('storepage_token')
      }
      this.storepageStore.getTransfersTable(payload).then(() => {
        this.loaded = true
        this.loading = false
      })
    },
    getSkuTransfers (toStoreKey) {
      this.getStoreTransfers()
      if (this.expanded) {
        this.expanded = null
        this.activeTab = 0
        return
      }
      if (toStoreKey === null) {
        this.storepageStore.$reset()
      } else {
        this.loading = true
        this.expanded = toStoreKey
        const payload = { store_key: parseInt(this.$route.params.store_key), to_store_key: parseInt(toStoreKey) }

        if (localStorage.getItem('storepage_token')) {
          payload['token'] = localStorage.getItem('storepage_token')
        }
        this.storepageStore.getSkuTransfersTable(payload).then(() => {
          // Stop the spinner.
          this.loading = false
          // And scroll down to the table.
          const el = this.$el.getElementsByClassName('to-store-title')[0]
          if (el) {
            el.scrollIntoView({ behavior: 'smooth' })
          }
        })
      }
    },
    completeTransfers (toStoreKey, data) {
      const h = (localStorage.getItem('access_token') ? getHeadersWithAccessToken() : headers)
      const payload = { token: localStorage.getItem('storepage_token'), store_key: parseInt(this.$route.params.store_key), to_store_key: parseInt(toStoreKey), data: data }
      axios.post(baseURLs.storepageAPI + '/complete_transfers', payload, { headers: h })
    },
    cancelTransfers (toStoreKey, data) {
      const h = (localStorage.getItem('access_token') ? getHeadersWithAccessToken() : headers)
      const payload = { token: localStorage.getItem('storepage_token'), store_key: parseInt(this.$route.params.store_key), to_store_key: parseInt(toStoreKey), data: data }
      axios.post(baseURLs.storepageAPI + '/cancel_transfers', payload, { headers: h }).catch(console.error)
    },
    skuPicked (row) {
      this.completeTransfers(this.storepageStore.transfersToStoreKey, [row])
    },
    resetRow (row) {
      this.cancelTransfers(this.storepageStore.transfersToStoreKey, [row])
    },
    handleResize () {
      this.width = window.innerWidth
    }
  },
  created () {
    if (this.$auth.isAccessTokenValid()) {
      // User is logged in, remove token from LS if exists
      if (localStorage.getItem('storepage_token')) {
        localStorage.removeItem('storepage_token')
      }
    }

    // If the token is explicitly present in the route, then we save it.
    if (Object.keys(this.$route.query).includes('token')) {
      localStorage.setItem('storepage_token', this.$route.query.token)
    }

    // Create listener to react when the screen gets resized.
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  mounted () {
    this.getStoreTransfers()
  }
}
</script>
<style lang="scss">
.storepage {
    tr>td>.cell>img {
        cursor: pointer;
    }
    .el-checkbox {
        margin-bottom: 0;
        transform: scale(2.2) !important;
        line-height: 3em !important;
    }
    .el-table--enable-row-hover .el-table__body tr:hover>td{
        // Override the background color on hover.
        background-color: white !important;
    }
}
</style>
