<template>
  <div class="buy-manage-scenarios-main">
    <v-row class="ma-6 mb-3" justify="space-between">
      <h4>Scenarios for {{budget.budget_name}}</h4>
    </v-row>
    <v-row class="ma-4  mt-0 justify-end">
      <table-search
        @searchChanged="filters => handleSearchChanged('scenarioFilters',Object.keys(scenarios[0]||{}), filters)"/>
      <v-icon class="mx-2" :disabled="scenarios.length >= 4" @click="openAddModal">add</v-icon>
      <v-icon @click="deleteDialogVisible = true">delete</v-icon>
    </v-row>
    <data-tables
      :data="scenarios"
      :filters="scenarioFilters"
      :key="budget.budget_name"
      :pagination-props="{class: 'd-none'}"
      @row-click="(row)=> selectScenario(row.index)"
    >
      <el-table-column prop="option_key" width="55">
        <template v-slot="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="ml-3"
            :value="selectedScenario !== null && selectedScenario === scope.row.index"
            :ripple="false"
            :input-value="selectedScenario !== null && selectedScenario === scope.row.index"
            @change="()=> selectScenario(scope.row.index)"
            @click.stop="()=> selectScenario(scope.row.index)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="scenario_key" label="ID">
        <template v-slot="scope">
          {{scope.row.scenario_key}}
        </template>
      </el-table-column>
      <el-table-column prop="scenario_name" label="Name" >
        <template v-slot="scope">
          <v-text-field
            :disabled="scope.row.scenario_key === 1"
            class="hide-details"
            v-model="scope.row.scenario_name"
            dense
            placeholder="Name"
            @input="saveDebounce(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="created" label="Created">
        <template v-slot="scope">
          {{formatDate(scope.row.created, 'yyyy-MM-dd')}}
        </template>
      </el-table-column>
      <el-table-column prop="latest_merge" label="Latest Merge">
        <template v-slot="scope">
          {{formatDate(scope.row.latest_merge, 'yyyy-MM-dd HH:mm:ss')}}
        </template>
      </el-table-column>
      <el-table-column prop="shop_items" label="Shop Items">
        <template v-slot="scope">
          {{scope.row.shop_items}}
        </template>
      </el-table-column>
      <el-table-column prop="orders" label="Orders">
        <template v-slot="scope">
          {{scope.row.orders}}
        </template>
      </el-table-column>
    </data-tables>
    <div class="ma-3 pt-2 ml-6">
      <span class="subtitle-2 ">
        Maximum of 3 scenarios per budget.
      </span>
    </div>
    <v-divider/>
    <delete-dialog
      :isOpen="deleteDialogVisible"
      @close="deleteDialogVisible = false"
      @delete="deleteSelectedScenario"
      resource="Scenario"
    />
    <scenario-modal
      :isOpen="scenarioModalOpen"
      @close="scenarioModalOpen = false"
      @save="saveScenario"
    />
    <scenario-merge
      ref="scenarioMerge"
      :isOpen="mergeScenarioModalOpen"
      :budget="budget"
      @close="mergeScenarioModalOpen = false"
      @merged="handleMerged"
    />
  </div>
</template>

<script>
import TableSearch from '@/components/TableSearch'
import columnFilters from '@/mixins/columnFilters'
import { format } from 'date-fns'
import _ from 'lodash'
import { deleteScenario, saveScenario } from '@/api/budgetAPI'
import DeleteDialog from '@/components/Dialogs/DeleteDialog'
import ScenarioModal from '@/components/BuyManageBudgets/ScenarioModal'
import ScenarioMerge from '@/components/BuyManageBudgets/ScenarioMerge'

export default {
  mixins: [columnFilters],
  name: 'BuyManageScenarios',
  data () {
    return {
      scenarioFilters: [],
      selectedScenario: null,
      mergeScenarioModalOpen: false,
      deleteDialogVisible: false,
      scenarioModalOpen: false,
      saveDebounce: null
    }
  },
  props: {
    scenarios: {
      type: Array,
      default: () => []
    },
    sku_names: {
      type: Array,
      default: () => []
    },
    budget: {
      type: Object,
      default: () => ({})
    },
    properties: {
      type: Object,
      default: () => ({})
    },
  },
  components: {
    ScenarioMerge,
    ScenarioModal,
    DeleteDialog,
    TableSearch
  },
  watch: {
    scenarios: {
      handler: function () {
        this.scenarioFilters = []
      },
      deep: true
    }
  },
  created () {
    this.saveDebounce = _.debounce(this.saveScenario, 500)
  },
  methods: {
    handleMerged () {
      this.$emit('merged')
    },
    async deleteSelectedScenario () {
      const scenario = this.scenarios[this.selectedScenario]
      this.deleteDialogVisible = true
      await deleteScenario(scenario)
      this.$emit('saved', this.budget)
      this.deleteDialogVisible = false
      this.updateMergeScenarios()
    },
    async saveScenario (scenario) {
      await saveScenario({ ...scenario, budget_key: this.budget.budget_key })
      this.$emit('saved', this.budget)
      this.updateMergeScenarios()
    },
    selectScenario (index) {
      if (this.selectedScenario === index) {
        this.selectedScenario = null
      } else {
        this.selectedScenario = index
      }
    },
    openMergeModal () {
      this.$refs.scenarioMerge.step = 0
      this.mergeScenarioModalOpen = true
    },
    openAddModal () {
      if (this.scenarios.length >= 4) {
        return
      }
      this.scenarioModalOpen = true
    },
    formatDate (date, pattern) {
      if (date) {
        return format(new Date(date), pattern)
      }
      return ''
    },
    updateMergeScenarios () {
      this.$refs.scenarioMerge.loadScenarios()
    }
  }
}
</script>

<style lang="scss">
.buy-manage-scenarios-main {
  .hide-details {
    .v-text-field__details {
      display: none!important;
    }
  }
}

</style>
