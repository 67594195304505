<template>
  <div class="d-flex justify-space-between stepper">
    <div
      v-for="(name, i) in steps"
      :key="i"
      class="flex-grow-1 step"
      :class="{ active: i === currentStep }"
      @click="$emit('step-clicked', i)"
    >
      <div class="number">
        <div class="number-value">
          {{ i + 1 }}
        </div>
      </div>
      <div class="name">{{ name }}</div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
}
</script>

<style lang="scss">
.stepper {
    height: 55px;
    .step {
        position: relative;
        text-align: center;
        .number {
            position: relative;
            .number-value {
                cursor: pointer;
                background: rgba(0,0,0,0.38);
                color: #fff;
                font-size: 8px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 15px;
                height: 15px;
                border-radius: 15px;
            }
            &:after {
                content: '';
                border-top: 1px solid #E3E3E3;
                position: absolute;
                top: 12px;
                margin-left: 6px;
                width: calc(100% - 27px);
            }
        }
        .name {
            cursor: pointer;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 28px;
            font-size: 16px;
            color: rgba(0,0,0,0.6);
            font-weight: 500;
        }
        &:last-child {
            .number {
                &:after {
                    content: none;
                }
            }
        }
        &.active {
            .number {
                .number-value {
                    background: var(--primary);
                }
            }
            .name {
                color: var(--primary);
            }
        }
    }
}
</style>
