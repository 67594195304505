<template>
  <v-card min-height="unset">
    <h3 class="pa-4">Store</h3>
    <v-overlay absolute :value="loading" color="white">
      <v-progress-circular color="primary" indeterminate size="64" width="2" v-if="loading"/>
    </v-overlay>
    <data-tables
      :data="overViewData"
      :pagination-props="{ hideOnSinglePage: true }">
      <el-table-column
        v-for="(prop, index) of overviewKeys" :prop="prop" :label="prop" :key="prop"
        :min-width="cellWidths[index]"
      >
        <template  v-slot:header>
          <div
            class="d-flex justify-md-start text-capitalize"
            :class="{'justify-md-center': index=== 2 || index=== 6,
                     'justify-center': index,
                     'pl-2': !index
            }">
            <div class="lines">
              <div class="one-line">
                <span class="option-name text-no-wrap">{{getLabel(prop)}}</span>
                <br>
              </div>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div
            :style="getCellStyle(scope.row, prop)"
            class="d-flex justify-md-start"
            :class="{'justify-md-center': index=== 2 || index=== 6,
                     'justify-center': index,
                     'pl-2': !index
            }">
            <div class="lines">
              <div class="one-line">
                <span class="option-name text-no-wrap">{{scope.row[prop]}}</span>
                <br>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </data-tables>
  </v-card>
</template>

<script>
import axios from '@/services/axios'
import { colormapCover, getHeadersWithAccessToken, headers } from '../variables'
import baseURLs from '@/api/APIBaseURLs'

export default {
  name: 'StorePageStoreTable',
  props: ['coloredRows', 'cellWidths'],
  data () {
    return {
      overViewData: [],
      overviewKeys: [],
      loading: true,
    }
  },
  methods: {
    getCellStyle (row, property) {
      if (this.isColored(property)) {
        return {
          background: this.getCellGradeColor(property, row[property]),
          borderRadius: '4px',
          color: '#fff',
          padding: '13px 0',
        }
      }
    },
    getCellGradeColor (property, value) {
      let result
      if (property.indexOf('cover') !== -1) {
        result = Math.min(1, Math.max(0, (value - 4) / 14))
      } else {
        const min = Math.min.apply(Math, this.overViewData.map(r => r[property]))
        const max = Math.max.apply(Math, this.overViewData.map(r => r[property]))
        result = (value - min) / (max - min)
      }
      return colormapCover(result)
    },
    isColored (property) {
      return this.coloredRows.indexOf(property) !== -1
    },
    getLabel (property) {
      if (property === 'dimension') {
        return 'Group'
      }
      return property.split('_').join(' ')
    }
  },
  created () {
    const payload = {
      store_key: this.$route.params.store_key,
      table: 'dimensions',
      dimension: 'store',
      token: localStorage.getItem('storepage_token')
    }
    const h = (localStorage.getItem('access_token') ? getHeadersWithAccessToken() : headers)
    axios.post(baseURLs.storepageAPI + '/overview_data', payload, { headers: h }).then(response => {
      this.overViewData = response.data.data
      this.overviewKeys = response.data.columns
      this.loading = false
      this.$emit('data-loaded', this.overViewData[0].dimension)
    })
  },
}
</script>
