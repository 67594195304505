<template>
  <div class="d-inline">
    <v-menu offset-y :left="true" :close-on-content-click="closeOnClick" nudge-bottom="5" :attach="rootEl">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" class="mx-2 action-btn-primary">download</v-icon>
      </template>
      <v-list dense>
        <slot name="additional-list-item"></slot>
        <v-list-item
          class="c-pointer"
          v-for="(item, index) in items"
          :key="index"
          @click.native="$emit('item-click', item.value)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

export default {
  name: 'DataExport',
  props: ['closeOnClick'],
  data () {
    return {
      rootEl: null,
      items: [
        { title: 'CSV (comma)', value: ',' },
        { title: 'CSV (semicolon)', value: ';' },
        { title: 'CSV (semicolon, decimal comma)', value: 'dutch' }
      ]
    }
  },
  mounted () {
    this.rootEl = this.$el
  },
}
</script>

<style scoped>

</style>
