<template>
  <div id="strategy-chunks" class="p-relative pt-0 mb-12">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="align-baseline mt-3 mb-3">
      <v-col cols="3" class="py-0">
        <h3>Chunks</h3>
      </v-col>
      <v-spacer />
      <v-col md="2" cols="4" class="py-0">
        <StrategyCubeSelect
          ref="cubeSelect"
          @cube-inputs-loaded="handleCubeInputsLoaded"
        />
      </v-col>
    </v-row>

    <v-card class="pt-4" min-height="350px">
      <div class="px-6">
        <template v-if="filtersDimensions.length">
          <v-row class="align-center">
            <v-col cols="11" class="pb-0">
              <StrategyFilters
                :dimensions="filtersDimensions"
                @filters-loaded="handleFiltersLoaded"
                @filters-changed="handleFiltersChanged"
              />
            </v-col>
            <v-col cols="1" class="pb-0 pt-0 text-right">
              <v-menu
                :close-on-content-click="false"
                attach="#strategy-chunks"
                offset-y
                left
                min-width="450"
                max-width="450"
                nudge-bottom="20"
                content-class="settings-menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on">tune</v-icon>
                </template>

                <v-card>
                  <h4 class="ml-4 pt-6">
                    Settings
                  </h4>
                  <div class="input-parameter">
                    <div class="parameter-name">Dimension</div>
                    <div class="parameter-value">
                      <v-select
                        :multiple="false"
                        v-model="selectedAnalyticsProperty"
                        :items="analyticsPropertiesGrouped"
                        class="text-body-2"
                        hide-details
                        @change="handleAnalyticsPropertyChanged"
                      />
                    </div>
                  </div>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </template>
        <StrategyChunksCharts
          ref="charts"
          :charts-data="chartsData"
          class="mt-4"
        />
        <h6 class="text-subtitle-1 font-weight-medium my-4">Placeholders</h6>
        <StrategyCollectionProducts
          :products="products"
          @product-deleted="handleProductDeleted"
        />

        <div class="mx-n6">
          <v-tabs v-model="activeTab" class="mt-10">
            <v-tabs-slider />
            <v-tab>Existing</v-tab>
            <v-tab>New - Stars</v-tab>
            <v-tab>New - Copy</v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab" class="mt-2 pb-6 px-6">
            <v-tab-item :transition="false" :reverse-transition="false" eager>
              <v-row>
                <v-col>
                  <StrategyChunksOptions
                    :options="options"
                    @options-copy="handleOptionsCopy"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false" eager>
              <v-row>
                <v-col>
                  <StrategyChunksStars
                    :stars="starsData.stars"
                    :generated-name="starsData.generatedName"
                    :sku-names="starsData.skuNames"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false" eager>
              <v-row>
                <v-col>
                  <StrategyChunksCopy
                    :options="copyData.options"
                    :generated-name="starsData.generatedName"
                    :sku-names="starsData.skuNames"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { groupAnalyticsProperties } from '@/utils'
import { getPlaceholders, getOptionsWithoutChunks, copyExistingOptionToPlaceholder, getTimeMachineData } from '@/api/strategy'
import { GetCubeIntake } from '@/api/cube'
import { loadFilterProperties } from '@/api/analytics'
import StrategyCubeSelect from '@/components/Strategy/StrategyCubeSelect'
import StrategyFilters from '@/components/Strategy/StrategyFilters'
import StrategyChunksCharts from '@/components/Strategy/StrategyChunksCharts'
import StrategyCollectionProducts from '@/components/Strategy/StrategyCollectionProducts'
import StrategyChunksOptions from '@/components/Strategy/StrategyChunksOptions'
import StrategyChunksStars from '@/components/Strategy/StrategyChunksStars'
import StrategyChunksCopy from '@/components/Strategy/StrategyChunksCopy'

export default {
  name: 'StrategyChunksPage',
  components: { StrategyCubeSelect, StrategyFilters, StrategyChunksCharts, StrategyCollectionProducts, StrategyChunksOptions, StrategyChunksStars, StrategyChunksCopy },
  data () {
    return {
      loading: true,
      filtersDimensions: [],
      filtersLoaded: false,
      analyticsProperties: [],
      selectedAnalyticsProperty: 'properties/article/season',
      collectionsOptions: [],
      selectedDimension: null,
      filtersValues: {},
      chartsData: {
        intakeChart: null,
        gridChart: null,
      },
      products: [],
      options: [],
      stars: [],
      starsData: {
        stars: [],
        generatedName: null,
        skuNames: [],
      },
      copyData: {
        options: [],
      },
      activeTab: 0,
    }
  },
  computed: {
    analyticsPropertiesGrouped () {
      return groupAnalyticsProperties(this.analyticsProperties)
    },
  },
  methods: {
    async handleCubeInputsLoaded ({ dimensions }) {
      this.filtersDimensions = dimensions
      this.loading = false

      if (this.filtersLoaded) {
        this.loading = true
        await Promise.all([
          this.loadPageData(),
          this.loadTimeMachineData(),
        ])
        this.loading = false
      }
    },
    async handleFiltersLoaded (values) {
      this.filtersLoaded = true
      this.filtersValues = values

      if (this.filtersDimensions.length) {
        this.loading = true
        await Promise.all([
          this.loadPageData(),
          this.loadTimeMachineData(),
        ])
        this.loading = false
      }
    },
    async handleFiltersChanged (values) {
      this.loading = true
      this.filtersValues = values

      await Promise.all([
        this.loadPageData(),
        this.loadTimeMachineData(),
      ])

      this.loading = false
    },
    async handleAnalyticsPropertyChanged () {
      if (this.filtersLoaded) {
        this.loading = true
        await this.loadTimeMachineData()
        this.loading = false
      }
    },
    async loadPageData () {
      return Promise
        .all([
          this.loadIntakeChartsData(),
          this.loadProducts(),
          this.loadOptionsWithoutChunks(),
        ])
    },
    async loadIntakeChartsData () {
      const payload = {
        cube_key: this.$refs.cubeSelect.currentCube,
        version_key: this.$refs.cubeSelect.currentVersion,
        filters: this.getCompanySpecificFiltersPayload(),
      }

      const chartsData = await GetCubeIntake(payload)
      const { intake_chart } = chartsData

      this.chartsData.intakeChart = Object.freeze(intake_chart)
    },
    async loadProducts () {
      const { week_min, week_max, ...filters } = this.getNonEmptyFiltersPayload()
      const payload = {
        filters,
      }
      const products = await getPlaceholders(payload)
      this.products = Object.freeze(products)
    },
    async loadTimeMachineData () {
      const payload = {
        dimension: this.selectedAnalyticsProperty,
        sales_min: 0,
        stock_min: 100,
        filters: this.getCompanySpecificFiltersPayload(),
      }
      const timeMachineData = await getTimeMachineData(payload)

      this.starsData.stars = Object.freeze(timeMachineData.stars.data)
      this.starsData.generatedName = timeMachineData.generated_name
      this.starsData.skuNames = Object.freeze(timeMachineData.sku_names)
      this.copyData.options = Object.freeze(timeMachineData.options.data)
      this.chartsData.gridChart = timeMachineData.grid
    },
    async loadOptionsWithoutChunks () {
      const { week_min, week_max, ...filters } = this.getNonEmptyFiltersPayload()
      const payload = {
        filters,
      }
      const options = await getOptionsWithoutChunks(payload)
      this.options = Object.freeze(options)
    },
    async handleOptionsCopy (options) {
      this.loading = true
      await copyExistingOptionToPlaceholder({
        option_keys: options.map(o => o.option_key),
      })
      await Promise.all([
        this.loadProducts(),
        this.loadOptionsWithoutChunks(),
      ])
      this.loading = false
    },
    handleProductDeleted (product) {
      this.products = this.products.filter(p => p.product_key !== product.product_key)
    },
    getNonEmptyFiltersPayload () {
      const filtersPayload = {}

      Object.keys(this.filtersValues).forEach(name => {
        if (this.filtersValues[name]) {
          if (!Array.isArray(this.filtersValues[name]) || this.filtersValues[name].length) {
            filtersPayload[name] = this.filtersValues[name]
          }
        }
      })

      return filtersPayload
    },
    getCompanySpecificFiltersPayload () {
      const { store_keys, week_numbers, week_min, week_max, ...payload } = this.getNonEmptyFiltersPayload()

      return payload
    },
  },
  async created () {
    this.loading = true
    const properties = await loadFilterProperties('skus')
    this.loading = false
    this.analyticsProperties = Object.keys(properties)
  },
}
</script>

<style lang="scss">
#strategy-chunks {
    .settings-menu {
        .input-parameter {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            border-bottom: 1px solid var(--border-color);
            padding: 0 16px;
            .parameter-name {
                width: 200px;
            }
            .parameter-value {
                width: 250px;
                padding: 8px 0;
            }
            .slider {
                padding-top: 4px;
                .slider-value-from,
                .slider-value-to {
                    color: #666;
                    font-size: 14px;
                }
            }
            &:last-child {
                padding-bottom: 8px;
            }
        }
    }
}
</style>
