<template>
  <v-container class="step-container">
    <v-row class="card-row">
      <v-col cols="2">
        Budget:
      </v-col>
      <v-col cols="2">
        {{ budget.budget_name }}
      </v-col>
    </v-row>
    <v-row class="card-row">
      <v-col cols="2">From Scenario:</v-col>
      <v-col cols="2">{{fromScenario.scenario_name}}</v-col>
    </v-row>
    <v-row class="card-row">
      <v-col cols="2">To Scenario:</v-col>
      <v-col cols="2">{{toScenario.scenario_name}}</v-col>
    </v-row>
    <v-row class="card-row">
      <v-col cols="2">Shop Items:</v-col>
      <v-col cols="2">
        {{items.length}}
      </v-col>
    </v-row>
    <v-row class="card-row">
      <v-col cols="2">
        <span :style="{color: shopItemStatusColorMap['unchanged']}">Unchanged:</span>
      </v-col>
      <v-col cols="2">
        <span>{{unchanged.length}}</span>
      </v-col>
      <v-col cols="2">
        <span :style="{color: shopItemStatusColorMap['add']}">Add:</span>
      </v-col>
      <v-col cols="2" class="mr">
        <span>{{append.length}}</span>
      </v-col>
      <v-col cols="2">
        <span :style="{color: shopItemStatusColorMap['overwrite']}">Overwrite:</span>
      </v-col>
      <v-col cols="2">
        <span>{{overwrite.length}}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="color: #ff490e" class="subtitle-1">
        <v-icon class="mr-2" color="#ff490e">warning</v-icon> Merging can not be undone!
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { shopItemStatusColorMap } from '@/variables'

export default {
  name: 'ScenarioMergeStep',
  data () {
    return {
      shopItemStatusColorMap
    }
  },
  props: {
    budget: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    fromScenario: {
      type: Object,
      required: true
    },
    toScenario: {
      type: Object,
      required: true
    }
  },
  computed: {
    unchanged () {
      return this.items.filter(i => (i.merge_effect || '').toLowerCase() === 'unchanged')
    },
    append () {
      return this.items.filter(i => (i.merge_effect || '').toLowerCase() === 'add')
    },
    overwrite () {
      return this.items.filter(i => (i.merge_effect || '').toLowerCase() === 'overwrite')
    },

  },
}
</script>

<style scoped>
  .card-row {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 16px;
    font-size: 1rem;
  }
  .step-container {
    padding-inline: 50px;
  }
</style>
