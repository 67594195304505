<template>
  <v-dialog :value="isOpen" persistent scrollable content-class="align-self-start mt-14" width="600px" max-width="90%">
    <v-card class="rounded-0 add-edit-dynamic-dialog">
      <v-overlay absolute color="white" :value="stockManageStore.capacityTab.dialogLoading">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>

      <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">
        {{ `${isNewOption ? 'Add' : 'Edit'}` }} capacity groups</v-card-title>
      <v-divider />
      <v-card-text class="px-0 text--primary">
        <div class="container px-12">
          <template v-if="currentCapacityOption">
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0">
                <v-autocomplete
                  :menu-props="{
                    left: true,
                  }" label="Option set" v-model="currentCapacityOption.options_set_key"
                  :items="stockManageStore.capacityTab.capacityOptionSetsList"
                  :error-messages="currentCapacityOptionErrors.optionSets" item-text="set_name"
                  show-select-all
                  @input="$emit('capacity-update', currentCapacityOption)"
                  item-value="options_set_key" hide-details />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0">
                <v-autocomplete
                  :menu-props="{
                    left: true,
                  }"
                  label="Store set" v-model="currentCapacityOption.stores_set_key"
                  :items="stockManageStore.capacityTab.capacityStoreSetsList"
                  @input="$emit('capacity-update', currentCapacityOption)"
                  :error-messages="currentCapacityOptionErrors.storeSets" item-text="set_name"
                  item-value="stores_set_key" hide-details />
                <collapsible-select
                  class="mt-2 pt-4" label="Stores"
                  autocomplete
                  @input="$emit('capacity-update', currentCapacityOption)"
                  show-select-all
                  :error-messages="currentCapacityOptionErrors.storeSets"
                  v-model="currentCapacityOption.store_keys" :items="stores" item-text="store_name" item-value="store_key"
                  hide-details />
                <v-select
                  class="mt-2 pt-4"
                  label="Type"
                  @input="onTypeChange"
                  :error-messages="currentCapacityOptionErrors.capacityType"
                  show-select-all
                  v-model="currentCapacityOption.capacity_type"
                  :items="[{value: 'pieces', text: 'Pieces'},{value: 'options', text: 'Options'},{value: 'skus', text: 'SKUs'},]"
                  hide-details />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-0 mb-4">
              <v-col class="py-0 pt-2">
                <collapsible-select :items="skus.map((item) => { return { value: item, text: item } })"
                  @input="$emit('capacity-update', currentCapacityOption)"
                  :disabled="currentCapacityOption.capacity_type === 'options'"

                  v-model="currentCapacityOption.sku_names" clearable autocomplete show-select-all label="SKUs"
                  hide-details="auto" />
                <v-expansion-panels
                  class="mt-4"
                  flat
                >
                  <v-expansion-panel
                    flat
                  >
                    <v-expansion-panel-header class="pa-0" style="min-height: 0" hide-actions>
                      <div style="color: rgb(144, 147, 153); font-weight: 500">Combinations <v-icon>keyboard_arrow_down</v-icon>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="capacity-info-table px-0"
                    >

                      <data-tables
                        class="capacity-info-table"
                        :data="infoData"
                        :pagination-show="false"
                      >
                        <el-table-column
                          label="Type"
                          width="80"
                          class-name="px-0"

                        >
                          <template slot-scope="scope">
                            {{scope.row.type}}
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="SKUs"
                          class-name="px-0"
                          width="70"

                        >
                          <template slot-scope="scope">
                            {{scope.row.skus}}
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Impact"
                          class-name="px-0"
                        >
                          <template slot-scope="scope">
                            {{scope.row.impact}}
                          </template>
                        </el-table-column>
                      </data-tables>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

              </v-col>
            </v-row>
            <v-row class=" align-center mt-4 mb-5 ml-0">
              <label class="v-label theme--light mr-2">Low level</label>
              <v-switch
                v-model="currentCapacityOption.low_level"
                @change="$emit('capacity-update', currentCapacityOption)"
                class="mt-0 pt-0 mr-1"
                hide-details
              />
              <v-tooltip top :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                </template>
                <span>
                  When 'Low Level' is activated, the capacity input is split to dynamic capacities on the underlying Options. This is then used directly for: allocation, replenishment and transfers.                    </span>
              </v-tooltip>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4" v-if="currentCapacityOption.low_level">
              <v-col class="py-0 pt-2">
                <collapsible-select
                  v-model="currentCapacityOption.dimensions"
                  @change="$emit('capacity-update', currentCapacityOption)"
                  label="Dimensions"
                  hide-details
                  show-select-all
                  autocomplete
                  :items="propertyStore.optionsPropertiesList"
                ></collapsible-select>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0 pr-8">
                <v-menu ref="dateMinPicker" v-model="dateMinPickerActive" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on }">
                    <v-text-field :value="currentCapacityOption.date_min" clearable hide-details label="Start" v-on="on"
                      @click:clear="currentCapacityOption.date_min = null"
                      @input="$emit('capacity-update', currentCapacityOption)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :locale-first-day-of-year="4"
                    :show-week="true" no-title v-model="currentCapacityOption.date_min"
                    @input="$emit('capacity-update', currentCapacityOption)"
                    @click="dateMinPickerActive = false" />
                </v-menu>
              </v-col>
              <v-col class="py-0 pl-8">
                <v-menu ref="dateMaxPicker" v-model="dateMaxPickerActive" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on }">
                    <v-text-field :value="currentCapacityOption.date_max" clearable hide-details label="End" v-on="on"
                      @click:clear="currentCapacityOption.date_max = null"></v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :locale-first-day-of-year="4"
                    :show-week="true" no-title v-model="currentCapacityOption.date_max"
                    @input="dateMaxPickerActive = false" />
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0 pr-8">
                <v-text-field type="number" v-model.number="currentCapacityOption.must_have"
                  @input="doubleCheckField($event, 'must_have')"
                  :error-messages="currentCapacityOptionErrors.mustHave" label="Must have" hide-details></v-text-field>
              </v-col>
              <v-col class="py-0 px-8">
                <v-text-field type="number" v-model.number="currentCapacityOption.nice_have"
                  @input="doubleCheckField($event, 'niceToHave')"
                  :error-messages="currentCapacityOptionErrors.niceToHave" label="Nice to have"
                  hide-details></v-text-field>
              </v-col>
              <v-col class="py-0 pl-8">
                <v-text-field
                  type="number"
                  v-model.number="currentCapacityOption.soft_max_have"
                  :error-messages="currentCapacityOptionErrors.softHave"
                  @input="doubleCheckField($event, 'soft_max_have')"
                  label="Soft"
                  hide-details />
              </v-col>
              <v-col class="py-0 pl-8">
                <v-text-field
                  type="number"
                  v-model.number="currentCapacityOption.max_have"
                  @input="doubleCheckField($event, 'max_have')"
                  :error-messages="currentCapacityOptionErrors.maxHave" label="Maximum" hide-details />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-0 mb-4">
              <v-col class="py-0">
                <v-textarea rows="4" v-model="currentCapacityOption.note"
                  :error-messages="currentCapacityOptionErrors.note" label="Note" hide-details />
              </v-col>
            </v-row>
          </template>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2 px-4">
          <v-col>
            <v-row>
              <v-col>
                <v-btn color="primary" depressed text @click="$emit('close')">Cancel</v-btn>
                <v-btn color="primary" depressed outlined @click="$emit('save', { ...currentCapacityOption})"
                  class="mx-4">Save</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CollapsibleSelect from '@/components/CollapsibleSelect'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

import { useStockManageStore } from '@/store/pinia/stockManageStore'
import {usePropertiesStore} from '@/store/pinia/propertiesStore'
export default {
  setup (){
    return {
      stockManageStore: useStockManageStore(),
      propertyStore: usePropertiesStore(),
    }
  },
  name: 'AddEditDynamicDialog',
  components: { CollapsibleSelect },
  data () {
    return {
      dateMinPickerActive: false,
      dateMaxPickerActive: false,
      capacityTypes: [
        { text: 'Pieces', value: 'pieces' },
        { text: 'Unique SKUs', value: 'unique_skus' },
        { text: 'Serial SKUs', value: 'serial_skus' },
      ],
      infoData: [
        {
          type: 'Pieces',
          skus: 'Empty',
          impact: 'Set total pieces within selected Options',
        },
        {
          type: 'Pieces',
          skus: 'Filled',
          impact: 'Set total pieces within selected Options and SKUs',
        },
        {
          type: 'Options',
          skus: 'N/A',
          impact: 'Set amount of Options within selected Options',
        },
        {
          type: 'SKUs',
          skus: 'Empty',
          impact: 'Set amount of unique SKUs within selected Options',
        },
        {
          type: 'SKUs',
          skus: 'Filled',
          impact: 'Set amount of unique SKUs within selected Options and SKUs',
        },
      ]
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    stores () {
      return (this.appConfig?.stores_open || [])
    },
  },
  props: {
    skus: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    currentCapacityOption: {
      type: Object,
      required: true,
    },
    isNewOption: {
      type: Boolean,
      default: false,
    },
    currentCapacityOptionErrors: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onTypeChange (value) {
      this.$emit('capacity-update', this.currentCapacityOption)
      if (value === 'options') {
        this.currentCapacityOption.sku_names = []
      }
    },
    doubleCheckField (value, key) {
      if (value === '') {
        this.currentCapacityOption[key] = null
      }
    }
  }
}
</script>

<style lang="scss">
.add-edit-dynamic-dialog {
  .v-expansion-panel-content__wrap {
    padding: 0
  }
}
.capacity-info-table {
  td {
    font-size: 12px
  }
}
</style>
