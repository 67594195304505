<template>
  <div>
    <v-overlay absolute color="white" :value="!loaded || !chartData">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <div v-show="loaded" class="chart-container" style="position: relative;">
      <div class="container">
        <div class="container-body">
          <canvas id="stores_proposal_graph"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hexToRgbA } from '@/utils'
import { Chart } from 'chart.js/auto'

export default {
  props: ['chartData', 'height'],
  data () {
    this.chart = null

    return {
      loaded: false,
      legendItems: []
    }
  },
  watch: {
    chartData () {
      this.drawGraph()
    }
  },
  methods: {
    drawGraph () {
      try {
        this.loaded = false
        const ctx = document.getElementById('stores_proposal_graph').getContext('2d')
        const labels = this.chartData['store_names'].map(item => item.substring(0, 14))

        const data = {
          labels,
          datasets: [{
            label: 'Stock',
            data: this.chartData['stock'],
            radius: 0,
            fill: true,
            borderWidth: 1,
            borderColor: '#007AFF',
            backgroundColor: hexToRgbA('#007AFF', 0.3),
          }, {
            label: 'Stock extra',
            data: this.chartData['stock_extra'],
            radius: 0,
            fill: true,
            borderWidth: 1,
            pointRadius: 6,
            borderColor: '#FF9800',
            backgroundColor: hexToRgbA('#FF9800', 0.3),
          }]
        }

        this.legendItems = data.datasets.map((dataset) => {
          return {
            label: dataset.label,
            color: dataset.borderColor,
          }
        })

        const options = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              stacked: true,
              grid: {
                display: true
              },
              label: 'Amount',
              title: {
                display: true,
                text: 'Amount',
                font: {
                  size: 14,
                },
              },
              ticks: {
                font: {
                  size: 14,
                },
                padding: 16,
                min: 0,
                callback: function (value) {
                  const ranges = [
                    { divider: 1e6, suffix: 'M' },
                    { divider: 1e3, suffix: 'k' }
                  ]

                  function formatNumber (n) {
                    for (let i = 0; i < ranges.length; i++) {
                      if (n >= ranges[i].divider) {
                        return (n / ranges[i].divider).toString() + ranges[i].suffix
                      }
                    }
                    return n
                  }
                  return formatNumber(value)
                },
              }
            },
            x: {
              grid: {
                display: false
              },
              title: {
                display: true,
                text: '',
                font: {
                  size: 14,
                },
              },
              stacked: true,
              label: '',
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
              align: 'center',
              labels: {
                boxHeight: 60,
                usePointStyle: true,
              }
            }
          }
        }

        if (this.chart) {
          this.chart.destroy()
        }
        const minBarWidth = 8
        const barWidth = (window.innerWidth * 0.9) / labels.length
        if (barWidth < minBarWidth) {
          document.querySelectorAll('.chart-container > .container > .container-body').forEach((el) => {
            el.style.width = `${labels.length * minBarWidth}px`
          })
        }

        this.chart = new Chart(ctx, { type: 'bar', data, options })

        this.$emit('loaded')
        this.loaded = true
      } catch (e) {
        this.$emit('loaded')
        console.log(e)
        this.loaded = true
      }
    },
  },
  mounted () {
    this.drawGraph()
  }
}
</script>

<style lang="scss">
.chart-container {
  .container {
    overflow-x: auto;
    max-width: 100%;
    .container-body {
      height: 350px;
    }
  }
  .chart-legend-label {
    font-size: 13px;
    color: #606266;
  }
}
</style>
