import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'

export function loadConsolidateData (payload) {
  const url = baseURLs.tsqlAPI + '/consolidate'
  return axios
    .post(url, payload)
    .then(response => response.data)
}

export function loadConsolidateTransfers (payload) {
  const url = baseURLs.tsqlAPI + '/transfers_consolidate'
  return axios
    .post(url, payload)
    .then(response => response.data)
}

export async function excludeOptions (payload) {
  const url = baseURLs.manageAPI + '/exclude_stores_options'
  return axios
    .post(url, payload)
    .then(response => response.data)
}

export async function loadConsolidateExcludedChart (payload) {
  const url = baseURLs.manageAPI + '/data/stock/transfers/consolidate'
  return axios
    .post(url, payload)
    .then(response => response.data)
}

export async function revertExcludeOptions (payload) {
  const url = baseURLs.manageAPI + '/revert_exclude_stores_options'
  return axios
    .post(url, payload)
    .then(response => response.data)
}
