<template>
  <div class="mb-10 form-div">
    <div class="mb-2 title-wrapper">
      <h4 class="mb-5">
        {{ filterData.label }} ({{ selectedFilterOptions.length }}/{{ filterOptionsList.length
        }})
      </h4>
      <v-icon @click="$emit('revert-click', filterName)">replay</v-icon>
    </div>

    <CollapsibleSelect
      v-model="selectedFilterOptions"
      :items="filterOptionsList
        .filter(x => x[filterData.nameProp])
        .map(x => ({
          value: x[filterData.keyProp].toString(),
          label: x[filterData.nameProp]
        }))"
      autocomplete
      item-text="label"
      show-select-all
      class="mt-1 mb-4 text-body-2"
      hide-details
      :label="filterData.label"
    />

    <v-row
      v-for="(prop, index) in selectedProperties[filterName]"
      :key="index"
      class="mt-4 mx-0 mb-6"
    >
      <v-col :cols="5" class="py-0 pl-0">
        <v-autocomplete
          v-model="selectedProperties[filterName][index]"
          :disabled="index !== selectedProperties[filterName].length - 1"
          :items="getFilterPropertiesList(filterData.filterPropertiesListProp).map(p => ({
            value: p,
            text: $options.filters.formatString(p),
          }))"
          :item-disabled="item => selectedProperties[filterName].indexOf(item.value) !== -1"
          label="Property"
          hide-details
          class="pt-0"
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"
          @change="$emit('property-change', {
            val: $event,
            filterName
          })"
        />
      </v-col>
      <v-col :cols="6" class="py-0">
        <CollapsibleSelect
          v-if="optionPropertiesValues[filterName][prop]"
          v-model="selectedValues[filterName][prop]"
          autocomplete
          :return-object="prop === 'Store sets'"
          :disabled="index !== selectedProperties[filterName].length - 1"
          :items="propertyValues(filterName, prop)"
          show-select-all
          label="Values"
          item-text="text"
          hide-details
          class="values-select pt-0"
          @change="$emit('property-value-change', filterName)"
        />
      </v-col>
      <v-col :cols="1" class="d-flex align-end justify-end py-0 pr-0">
        <v-icon
          v-if="selectedValues[filterName][prop]"
          class="ml-2 action-btn-danger"
          @click="$emit('property-delete', {
            filterName,
            propName: prop
          })"
        >delete</v-icon>
      </v-col>
    </v-row>
    <v-icon
      v-if="allPropsHaveValues(filterName)"
      class="action-btn-primary"
      @click="$emit('add-property-click', filterName)"
    >add</v-icon>
  </div>
</template>

<script>
import { filtersData } from '@/variables'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  props: {
    filterName: {
      type: String,
      required: true,
    },
    filterData: {
      type: Object,
      default: () => ({}),
    },
    filterOptionsList: {
      type: Array,
      default: () => [],
    },
    selectedProperties: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { CollapsibleSelect },
  data () {
    return {
      filtersData,
    }
  },
  computed: {
    selectedFilterOptions: {
      get () {
        return this.filtersStore.selectedFiltersOptions[this.filterName]
      },
      set (val) {
        this.$emit('filter-changed', {
          filterName: this.filterName,
          value: val,
        })
      },
    },
    storeProperties () {
      if (this.filtersStore.loaded.stores) {
        return Object.keys(
          this.filtersStore.filtersData.stores[0].properties
        )
      } else {
        return []
      }
    },
    storeDcProperties () {
      if (this.filtersStore.loaded.storesDc) {
        return Object.keys(
          this.filtersStore.filtersData.storesDc[0].properties
        )
      } else {
        return []
      }
    },
    groupProperties () {
      if (this.filtersStore.loaded.groups) {
        return Object.keys(
          this.filtersStore.filtersData.groups[0].properties
        )
      } else {
        return []
      }
    },
    collectionProperties () {
      if (this.filtersStore.loaded.collections) {
        return Object.keys(
          this.filtersStore.filtersData.collections[0].properties
        )
      } else {
        return []
      }
    },
    optionPropertiesValues () {
      return this.filtersStore.optionPropertiesValues
    },
    selectedValues () {
      return this.filtersStore.selectedValues
    },
  },
  methods: {
    propertyValues (filterName, prop) {
      if (prop === 'Store sets') {
        return this.optionPropertiesValues[filterName][prop]
      }
      return this.optionPropertiesValues[filterName][prop].map(v => ({
        value: v,
        text: v,
      }))
    },
    getFilterPropertiesList (filterName) {
      const props = this[filterName] || []
      if (this.filterName.toLowerCase().includes('store')) {
        return [...props, 'Store sets']
      }
      return props
    },
    allPropsHaveValues (filterName) {
      const props = this.selectedProperties[filterName]
      const propsWithValues = props.filter(prop =>
        this.selectedValues[filterName][prop] &&
        this.selectedValues[filterName][prop].length
      )

      return props.length === propsWithValues.length
    },
  },
}
</script>

<style scoped lang="scss">
.form-div {
    width: 100%;
    .title-wrapper {
        display: flex;
        justify-content: space-between;
    }
}
</style>
