import axios from '@/services/axios'
import baseURLs from '@/api/APIBaseURLs'

export function getMarkdownBuilderData () {
  const url = baseURLs.tsqlAPI + '/markdowns_builder'

  return axios
    .get(url)
    .then(response => {
      return response.data
    })
    .catch(console.log)
}

export function getMarkdownBuilder (payload) {
  const url = baseURLs.tsqlAPI + '/markdowns_builder'
  return axios
    .post(url, payload)
    .then(response => {
      return response.data
    })
    .catch(console.log)
}
