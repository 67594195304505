
<template>
  <div class="">
    <div class="homePage container-fluid">

      <v-row class="mx-0 justify-space-between align-center"  v-show="!isDialogView" style="margin-top: -4px; margin-bottom: 6px">
        <h3 >Shopping</h3>
        <v-col cols="3" class=" pa-0">
          <v-autocomplete
            :menu-props="{
              left: true,
            }"
            :items="scenariosList"
            clearable
            :filter="mainFilter"
            :label="(scenariosValue || {}).budget_name? '': 'Budget (scenario)'"
            v-model="scenariosValue"
            @input="saveParams"
            :prefix="(scenariosValue || {}).budget_name ?(scenariosValue || {}).budget_name + ' / ' : ''"
            style="font-size: 16px; font-weight: bold"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-card class="card  p-relative" min-height="300px">

        <v-overlay absolute color="white" :value="loading" >
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <v-row class="justify-center align-center p-relative ma-0 py-4 mx-4">
          <h4 class="text-center p-absolute left" style="left: 0">Intake overview</h4>

          <v-btn-toggle
            v-model="chartIndex"
            @change="saveChartIndex"
            dense
            active-class="bg-gray"
            light
          >
            <v-btn
              :key="0"
              small
              width="90"
              color="white"
            >
              <v-icon>show_chart</v-icon>
              <span class="text-capitalize">Graphs</span>
            </v-btn>
            <v-btn
              :key="1"
              small
              width="90"
              color="white"
            >
              <img :src="view_compact_icon" alt="" width="18" class="mr-1"/>
              <span class="text-capitalize">KPI</span>
            </v-btn>
          </v-btn-toggle>
          <v-col class="d-flex pa-0 p-absolute right"  style="right: 0" cols="4">
            <WeekSelector
              v-model="kpiFilters.week_min"
              clearable
              @change="loadKPIData()"
              :higher-limit="kpiFilters.week_max"
              class="mr-2 pt-0 mt-0 "
              label="Week min"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"                        >

            </WeekSelector>
            <WeekSelector
              v-model="kpiFilters.week_max"
              @change="loadKPIData()"
              clearable
              :lower-limit="kpiFilters.week_min"
              class="ml-2 pt-0 mt-0 "
              label="Week max"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"                        >

            </WeekSelector>
          </v-col>
        </v-row>
        <div class="p-relative">
          <v-overlay absolute color="white" :value="loading" >
            <v-progress-circular color="primary" indeterminate size="64" width="2" />
          </v-overlay>
          <BuyChartCarousel v-if="chartIndex===0 && Object.keys(kpiData).length" class="mb-8" ref="carousel"
            :params="params"
            :data="kpiData"
            v-show="!isDialogView"
            :chartIndex="valueChartIndex"
            @chart-index-changed="valueChartIndex = $event"
            @cumulative-change="loadKPIData($event)"
          />
          <BuyChartKPI v-if="chartIndex" class="mb-8" ref="carousel"
            :chart-data="kpiData"
            v-show="!isDialogView"
          />
        </div>
      </v-card>
      <BuyShoppingHomePage v-if="scenariosValue?.budget_key" ref="shopItems" @dialog-view="isDialogView = true"
        @chart-index-changed="valueChartIndex = $event"
        :valueChartIndex="valueChartIndex"
        :kpi-data="kpiData"
        :kpiFilters="kpiFilters"
        :scenariosValue="scenariosValue"
        @items-updated="loadKPIData()"
        @filters-changed="kpiFilters = $event; loadKPIData()"
        @modal-closed="isDialogView = false"/>
      <div id="tasks" class="row" v-show="!isDialogView">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <tasks-component v-if="scenariosValue?.budget_key" ref="tasksComponent"></tasks-component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { headersFilters } from '@/utils'
import TasksComponent from '@/components/Order/TasksComponent.vue'
import BuyChartCarousel from './BuyChartCarousel'
import { formatString } from '@/variables'
import { loadBudgetsScenarios } from '@/api/buyHomePage'
import BuyShoppingHomePage from '@/views/BuyShoppingHomePage'
import { loadBudgetKPIs } from '@/api/shop'
import BuyChartKPI from './BuyChartKPI'
import WeekSelector from '../components/WeekSelector'
import vue_compact_icon from '@/assets/img/view-compact-icon-original.svg'
import { useOrderTasksStore } from '@/store/pinia/tasksStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore(),
      tasksStore: useOrderTasksStore()
    }
  },
  data () {
    return {
      view_compact_icon: vue_compact_icon,
      valueChartIndex: parseInt(localStorage.getItem('buy-chart-carousel') || 0),
      pageFilters: ['groups', 'collections'],
      loading: true,
      kpiFilters: JSON.parse(localStorage.getItem('kpiFilters')) || {},
      isDialogView: false,
      chartIndex: parseInt(localStorage.getItem('buy-chart-index') || 0),
      kpiData: {},
      params: {},
      scenariosValue: {},
      budgetScenariosList: [],
    }
  },
  components: {
    WeekSelector,
    BuyChartKPI,
    BuyShoppingHomePage,
    BuyChartCarousel,
    TasksComponent,
  },
  watch: {
    watch: {
      chartIndex (newValue, oldValue) {
        if (newValue === undefined) {
          this.chartIndex = oldValue
        }
      },
    },
    params () {
      this.scenariosValue = this.budgetScenariosList.find(item => item.scenario_key.toString() === this.params.scenario_key.toString() && item.budget_key.toString() === this.params.budget_key.toString()) || this.budgetScenariosList[0]
    },
    'filtersStore.filtersChanged': {
      handler (newVal) {
        if (newVal) {
          this.filterChanged()
        }
      },
    },
  },
  methods: {
    mainFilter: headersFilters,
    saveChartIndex () {
      localStorage.setItem('buy-chart-index', this.chartIndex)
    },
    saveParams (item) {
      localStorage.setItem('buy_home_params', JSON.stringify(item))
      this.params = item
      this.loadTasks(false)

      const query = { ...this.$route.query }
      if (item.scenario_key) {
        query.scenario_key = item.scenario_key
      }
      if (item.budget_key) {
        query.budget_key = item.budget_key
      }
      this.$router.replace({
        name: this.$route.name,
        query,
      })
      this.loadKPIData()
      this.$refs.shopItems?.handleParamsChange(item)
      this.$refs.tasksComponent?.$refs?.productTable?.loadTasks()
      // TODO
    },
    watchFiltersDataLoaded () {
      this.$watch(
        'filtersStore.filtersDataLoaded',
        function (newVal) {
          if (newVal) {
            this.loadPage()
          }
        },
        { immediate: true }
      )
    },
    filterChanged () {
      this.loadTasks()
    },
    loadPage () {
      this.loadTasks()
    },
    loadKPIData (isCumulative) {
      if (!this.scenariosValue?.scenario_key) {
        return
      }
      localStorage.setItem('kpiFilters', JSON.stringify(this.kpiFilters))
      if (isCumulative !== undefined) {
        this.isCumulative = isCumulative
      }
      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()
      const weekFilters = {}
      if (this.kpiFilters.week_min) {
        weekFilters.week_min = parseInt(this.kpiFilters.week_min)
      }
      if (this.kpiFilters.week_max) {
        weekFilters.week_max = parseInt(this.kpiFilters.week_max)
      }
      loadBudgetKPIs({
        scenario_key: this.scenariosValue?.scenario_key,
        budget_key: this.scenariosValue?.budget_key,
        filters: pageFiltersValues,
        cumulative: !!this.isCumulative,
        ...weekFilters
      }).then(data => {
        this.kpiData = data
      })
    },
    loadTasks (setParams = true) {
      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()
      if (!this.$route.query.scenario_key || !this.$route.query.budget_key) {
        this.params = JSON.parse(localStorage.getItem('buy_home_params')) || {}
      } else if (setParams) {
        this.params = this.$route.query
      }
      this.tasksStore.getTasksOfType({
        taskType: 'buy',
        groups: pageFiltersValues.groups || [],
        collections: pageFiltersValues.collections || [],
        ...this.params
      })
      this.loading = false
    },
    routeQueryReplacement () {
      const query = { ...this.$route.query }
      if (query.scenario_key?.toString() !== this.scenariosValue?.scenario_key.toString()
        || query.budget_key?.toString() !== this.scenariosValue?.budget_key.toString()) {
        if (this.scenariosValue?.scenario_key) {
          query.scenario_key = this.scenariosValue.scenario_key
        }
        if (this.scenariosValue?.budget_key) {
          query.budget_key = this.scenariosValue.budget_key
        }
        this.$router.replace({
          name: this.$route.name,
          query,
        })
      }
    }
  },
  created () {
    // if page has filters, wait till filters data is loaded

    loadBudgetsScenarios().then((data) => {
      this.budgetScenariosList = data
      if (!this.$route.query.scenario_key || !this.$route.query.budget_key) {
        this.params = JSON.parse(localStorage.getItem('buy_home_params')) || {}
      } else {
        this.params = {
          scenario_key: parseInt(this.$route.query.scenario_key),
          budget_key: parseInt(this.$route.query.budget_key),
        }
      }
      this.scenariosValue = this.budgetScenariosList.find(item => item.scenario_key === this.params.scenario_key && item.budget_key === this.params.budget_key) || this.budgetScenariosList[0]

      this.routeQueryReplacement()
      this.loadKPIData()

      if (this.filtersStore.currentPageFilters.length) {
        this.watchFiltersDataLoaded()
      } else {
        this.loadPage()
      }
    })
  },
  computed: {
    scenariosList () {
      const list = []
      const headers = {}
      for (const item of this.budgetScenariosList) {
        if (headers[item.budget_key] === undefined) {
          headers[item.budget_key] = { header: formatString(item.budget_name), text: [] }
          list.push(headers[item.budget_key])
        }
        headers[item.budget_key].text.push(formatString(item.scenario_name), formatString(item.budget_name))
        list.push({
          value: item,
          headerTitle: item.budget_name,
          text: formatString(item.scenario_name)
        })
      }
      if (!list.length) {
        return []
      }

      return list
    },
  },
}
</script>

<style lang="scss">
.homePage {
    .el-table td {
        padding: 0;
    }
}
</style>
