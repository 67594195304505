<template>
  <div
    class="pricing-markdown-builder-page p-relative mb-12"
  >
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <h3 class="mb-6 mt-6">Builder</h3>

    <v-card class="pt-5" min-height="350px">
      <div class="px-6 mb-8">
        <PricingMarkdownBuilderFilters
          ref="filters"
          :weeks="weeksList"
          :markdownMomentsList="markdownMomentsList"
          @form-changed="handleFiltersChanged"
        />
      </div>

      <v-tabs v-model="activeTab">
        <v-tabs-slider />
        <v-tab
          href="#results"
          class="px-8"
          style="width: 180px"
        >Results ({{ $refs.searchResults?.proposalsTable.rows.length }})</v-tab>
        <v-tab
          href="#selected"
          class="px-8"
          style="width: 180px"
        >Selected ({{ markdownsSelected?.length || 0 }})</v-tab>
        <v-tab
          href="#blocked"
          class="px-8"
          style="width: 180px"
        >Blocked ({{ markdownsBlocked?.length || 0 }})</v-tab>
        <v-tab
          href="#planned"
          class="px-8"
          style="width: 180px"
        >Planned ({{ plannedResults?.table.length }})</v-tab>
        <v-tab
          href="#all"
          class="px-8"
          style="width: 180px"
        >All ({{ allResults?.table.length }})</v-tab>
        <v-tab
          href="#statistics"
          class="px-8"
          style="width: 180px"
        >Statistics</v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="activeTab"
        style="width: 100%"
      >
        <v-tab-item
          value="results"
          :transition="false"
          :reverse-transition="false"
        >
          <MarkdownBuilderSearchResults
            ref="searchResults"
            class="mt-8"
            :selectedResultsOptKeys="markdownsSelectedOptKeys"
            @discount-click="handleSearchResultsDiscountClick"
            @add-scenario-click="handleAddScenarioClick"
            @add-clicked="handleAddResults"
            @blocked="handleBlockResults"
          />
        </v-tab-item>
        <v-tab-item
          value="selected"
          eager
          :transition="false"
          :reverse-transition="false"
        >
          <MarkdownBuilderSelectedResults
            ref="selectedResults"
            class="mt-8"
            :rows="markdownsSelected"
            :total="selectedTotal"
            @delete-clicked="handleDeleteResults"
          />
        </v-tab-item>
        <v-tab-item
          value="blocked"
          eager
          :transition="false"
          :reverse-transition="false"
        >
          <MarkdownBuilderSelectedResults
            ref="blockedResults"
            class="mt-8"
            :rows="markdownsBlocked"
            :total="blockedTotal"
            @delete-clicked="handleDeleteBlocked"
          />
        </v-tab-item>
        <v-tab-item
          value="planned"
          :transition="false"
          :reverse-transition="false"
        >
          <MarkdownBuilderProposals
            :proposals="plannedResults"
            class="mt-8"
          />
        </v-tab-item>
        <v-tab-item
          value="all"
          :transition="false"
          :reverse-transition="false"
        >
          <MarkdownBuilderProposals
            :proposals="allResults"
            discount-clickable
            class="mt-8"
            @discount-click="handleProposalDiscountClick"
            @add-scenario-click="handleAddScenarioClick"
          />
        </v-tab-item>
        <v-tab-item
          value="statistics"
          :transition="false"
          :reverse-transition="false"
        >
          <MarkdownBuilderStatistics
            v-if="statistics"
            :statistics="statistics"
            class="mt-8"
          />
        </v-tab-item>
      </v-tabs-items>

      <v-divider class="mt-8 mb-4" />
      <h6 class="text-subtitle-1 font-weight-medium mb-4 ml-6">Impact</h6>
      <v-row justify="center" class="align-center mb-5">
        <v-btn-toggle
          v-model="impactIndex"
          dense
          active-class="bg-gray"
          class="mr-12 pr-3"
          light
        >
          <v-btn
            v-for="(type, index) in impactTypes"
            :key="index"
            small
            color="white"
          >
            <span class="text-capitalize">{{ type.name }}</span>
          </v-btn>
        </v-btn-toggle>
      </v-row>
      <v-row>
        <v-col>
          <div
            class="chart-container mt-4 mx-4"
            style="position: relative; height: 350px;"
          >
            <canvas id="chart"></canvas>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right pr-8 mb-2">
          <v-btn
            color="primary"
            depressed
            outlined
            :disabled="!markdownsSelected?.length"
            @click="acceptConfirmVisible = true"
          >Accept</v-btn>
        </v-col>
      </v-row>

      <template v-if="acceptConfirmVisible">
        <v-row>
          <v-col :cols="3" :offset="9" class="pr-8">
            <v-select
              :multiple="false"
              v-model="acceptStatus"
              :items="acceptStatusOptions"
              class="text-body-2"
              label="Status"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right pr-8">
            <div class="text-body-1 font-weight-medium">
              Are you sure you want to accept {{ markdownsSelected?.length || 0}} proposals with status {{ acceptStatus | capitalize }}?
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right pr-8">
            <v-btn
              color="primary"
              depressed
              text
              @click="acceptConfirmVisible = false"
            >No</v-btn>
            <v-btn
              color="primary"
              depressed
              outlined
              @click="handleAcceptConfirm"
              class="ml-4"
            >Yes</v-btn>
          </v-col>
        </v-row>
      </template>
    </v-card>
    <v-card class="mt-10 py-5">
      <PricingProposalOverview
        ref="proposalOverview"
        title="Future markdowns"
        :statuses="['accepted', 'planned']"
        :show-optimisation-type="false"
        @proposals-loaded="handleProposalsLoaded"
      />
    </v-card>
  </div>
</template>

<script>

import { Chart } from 'chart.js/auto'
import { saveMarkdownProposals } from '@/api/markdown_proposals'
import * as markdownMomentsApi from '@/api/markdownMoments'
import * as markdownBuilderApi from '@/api/markdownBuilder'
import { formatString, parseWeek } from '@/variables'
import PricingMarkdownBuilderFilters from '@/components/Pricing/PricingMarkdownBuilderFilters'
import MarkdownBuilderStatistics from '@/components/Pricing/MarkdownBuilderStatistics'
import MarkdownBuilderSearchResults from '@/components/Pricing/MarkdownBuilderSearchResults'
import MarkdownBuilderSelectedResults from '@/components/Pricing/MarkdownBuilderSelectedResults'
import MarkdownBuilderProposals from '@/components/Pricing/MarkdownBuilderProposals'
import PricingProposalOverview from '@/components/Pricing/PricingProposalOverview'

import { usePricingFiltersStore } from '@/store/pinia/pricingFiltersStore'

export default {
  setup () {
    return {
      pricingFiltersStore: usePricingFiltersStore()
    }
  },
  name: 'PricingMarkdownBuilderPage',
  components: { PricingMarkdownBuilderFilters, MarkdownBuilderStatistics, MarkdownBuilderSearchResults, MarkdownBuilderSelectedResults, MarkdownBuilderProposals, PricingProposalOverview },
  data () {
    this.chart = null

    return {
      loading: false,
      activeTab: null,
      expandedOptionKeys: {
        result: null,
        all: null,
      },
      distribution: null,
      acceptConfirmVisible: false,
      proposalsLoaded: false,
      markdownMomentsNamesDict: {},
      markdownMomentsKeysList: [],
      statistics: null,
      weeksList: [],
      pricingProposals: [],
      markdownsSelected: [],
      selectedTotal: [],
      markdownsBlocked: [],
      blockedTotal: [],
      plannedResults: null,
      allResults: null,
      acceptStatusOptions: [{
        value: 'planned',
        text: 'Planned',
      }, {
        value: 'accepted',
        text: 'Accepted',
      }],
      acceptStatus: 'planned',

      impactIndex: 0,
      impactTypes: [
        { value: 'stock', name: 'Stock' },
        { value: 'options', name: 'Options' },
      ],
    }
  },
  computed: {
    filtersOpened () {
      return this.pricingFiltersStore.filtersOpened
    },
    markdownMomentsList () {
      return this.markdownMomentsKeysList.map(key => ({
        value: key,
        text: this.markdownMomentsNamesDict[key],
      }))
    },
    markdownsSelectedOptKeys () {
      return this.markdownsSelected.map(m => m.option_key)
    },
    markdownsBlockedOptKeys () {
      return this.markdownsBlocked.map(m => m.option_key)
    },
  },
  watch: {
    impactIndex () {
      this.drawChart()
    },
    filtersOpened (newValue) {
      if (!newValue) {
        this.loadMarkdownProposals()
      }
    },
    activeTab () {
      this.updateTotalTableWidth()
    },
  },
  methods: {
    loadMarkdownMoments () {
      return markdownMomentsApi
        .getMarkdownMoments()
        .then(data => {
          this.markdownMomentsNamesDict = data.reduce((acc, cur) => ({
            ...acc,
            [cur.markdown_moment_key]: cur.markdown_moment_name
          }), {})
        })
    },
    loadMarkdownBuilderData () {
      return markdownBuilderApi
        .getMarkdownBuilderData()
        .then(data => {
          this.weeksList = data.week_number.map(wn => ({
            text: parseWeek(wn),
            value: wn,
          }))
          this.markdownMomentsKeysList = data.markdown_moment_key
        })
    },
    loadPage () {
      this.loading = true

      return Promise
        .all([
          this.loadMarkdownMoments(),
          this.loadMarkdownBuilderData(),
        ])
        .then(() => {
          this.loadMarkdownProposals()
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = this.$el.querySelector('.v-window-item--active .el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 100)
    },
    handleProposalsLoaded (proposals) {
      if (this.proposalsLoaded) {
        return
      }

      this.pricingProposals = proposals
        .slice()
        .filter(p => ['planned', 'accepted'].includes(p.status))
        .map(p => ({
          ...p,
          discount: parseInt(p.discount) / 100,
        }))
      this.loadPage()
      this.proposalsLoaded = true
    },
    handleFiltersChanged () {
      this.loadMarkdownProposals()
    },
    handleBlockResults (selected) {
      this.updateBlockedResults([
        ...this.markdownsBlocked,
        ...(selected
          .slice()
          .filter(r => !this.markdownsBlockedOptKeys.includes(r.option_key)))
      ])
      this.loadMarkdownProposals()
    },
    handleAddResults (selected) {
      this.updateSelectedResults([
        ...this.markdownsSelected,
        ...(selected
          .slice()
          .filter(r => !this.markdownsSelectedOptKeys.includes(r.option_key)))
      ])
      this.loadMarkdownProposals()
    },
    handleDeleteBlocked (toDelete) {
      this.updateBlockedResults(this.markdownsBlocked.filter(md => !toDelete.includes(md.option_key)))
      this.loadMarkdownProposals()
    },
    handleDeleteResults (toDelete) {
      this.updateSelectedResults(
        this.markdownsSelected
          .filter(md => !toDelete.includes(md.option_key))
      )
      this.loadMarkdownProposals()
    },
    async handleAcceptConfirm () {
      this.loading = true

      const payload = this.markdownsSelected.map(r => ({
        ...r,
        week: r.week_number,
        markdown_value: r.markdown_perm,
        optimization_type: 'builder',
        status: this.acceptStatus,
      }))

      const result = await saveMarkdownProposals(payload)

      if (result) {
        this.updateSelectedResults([])
        this.acceptConfirmVisible = false
        this.$refs.proposalOverview.loadOverview()

        this.pricingProposals = [
          ...this.pricingProposals,
          ...result,
        ]
        this.loadMarkdownProposals()
      }

      this.loading = false
    },
    async handleSearchResultsDiscountClick (option) {
      // clear previous childs
      this.$refs.searchResults.proposalsTable.rows = this.$refs.searchResults.proposalsTable.rows.filter(r => !r.isChild)

      if (this.expandedOptionKeys.results === option.option_key) {
        this.expandedOptionKeys.results = null
        return
      }

      const scenarios = await this.loadScenarios(option)
      this.expandedOptionKeys.results = option.option_key

      if (scenarios.length) {
        scenarios.forEach(s => {
          s.isChild = true
        })
      } else {
        scenarios.push({
          isChild: true,
          isEmpty: true,
        })
      }

      const index = this.$refs.searchResults.proposalsTable.rows.indexOf(option)

      this.$refs.searchResults.proposalsTable.rows.splice(index + 1, 0, ...scenarios)
    },
    async handleProposalDiscountClick (option) {
      // clear previous childs
      this.allResults = {
        ...this.allResults,
        table: this.allResults.table.filter(r => !r.isChild),
      }

      if (this.expandedOptionKeys.all === option.option_key) {
        this.expandedOptionKeys.all = null
        return
      }

      const scenarios = await this.loadScenarios(option)
      this.expandedOptionKeys.all = option.option_key

      if (scenarios.length) {
        scenarios.forEach(s => {
          s.isChild = true
        })
      } else {
        scenarios.push({
          isChild: true,
          isEmpty: true,
        })
      }

      const index = this.allResults.table.indexOf(option)

      this.allResults.table.splice(index + 1, 0, ...scenarios)
    },
    async handleAddScenarioClick (scenario) {
      this.updateSelectedResults([
        ...this.markdownsSelected,
        scenario,
      ])
      this.loadMarkdownProposals()
    },
    updateSelectedResults (ls) {
      this.markdownsSelected = ls.map(r => ({
        ...r,
        selected: false,
      }))
    },
    updateBlockedResults (ls) {
      this.markdownsBlocked = ls.map(r => ({
        ...r,
        selected: false,
      }))
    },
    loadScenarios (option) {
      const inputForm = this.$refs.filters.inputForm
      const payload = {
        option_keys: [option.option_key],
        week_numbers: inputForm.weeks.value.map(w => w.value),
        markdown_moment_keys: inputForm.moments.value.map(v => v.value),
      }

      this.loading = true

      return markdownBuilderApi
        .getMarkdownBuilder(payload)
        .then(result => result.markdowns_scenarios.table)
        .finally(() => {
          this.loading = false
        })
    },
    loadMarkdownProposals () {
      const inputForm = this.$refs.filters.inputForm
      const filters = JSON.parse(localStorage.getItem('pricingFilters')) || {}
      const optionsFilter = JSON.parse(localStorage.getItem('pricingOptionsFilter')) || []
      const formData = {
        uplift: inputForm.method.value,
        filters: {
          ...filters,
          option_key: optionsFilter,
        },
        markdown_moment_keys: inputForm.moments.value.map(v => v.value),
        dimension: inputForm.dimension.value,
        weeks_on_hand_min: inputForm.weeks_on_hand_min.value,
        sellthrough_min: inputForm.sellthrough.value[0],
        sellthrough_max: inputForm.sellthrough.value[1],
        sellthrough_end_min: inputForm.sellthrough_end.value[0],
        sellthrough_end_max: inputForm.sellthrough_end.value[1],
        sellthrough_lxw_min: inputForm.sellthrough_lxw?.value[0] || 0,
        sellthrough_lxw_max: inputForm.sellthrough_lxw?.value[1] || 1,
        week_numbers: inputForm.weeks.value.map(w => w.value),
        from_discount_min: inputForm.from_discount.value[0],
        from_discount_max: inputForm.from_discount.value[1],
        to_discount_min: inputForm.to_discount.value[0],
        to_discount_max: inputForm.to_discount.value[1],
        discount_delta: inputForm.discount_delta.value,
        discount_value_delta: inputForm.discount_value_delta.value,
        residual_min: inputForm.residual_min.value,
        stock_budget: inputForm.stock_budget.value,
        markdown_perm_budget: inputForm.markdown_perm_budget.value,
        weeks_history: inputForm.weeks_history.value,
        selected: this.markdownsSelected,
        blocked: this.markdownsBlocked,
        planned: this.pricingProposals,
      }

      this.loading = true

      return markdownBuilderApi
        .getMarkdownBuilder(formData)
        .then(data => {
          this.$nextTick(() => {
            // Check if searchResults is mounted TODO: refactor this into using just props
            if (this.$refs.searchResults) {
              this.$refs.searchResults.proposalsTable.headers = data.columns
                // name and route are used in image column
                .filter(c => !['name', 'route'].includes(c))
                .map(c => ({
                  text: formatString(c),
                  value: c,
                }))
              this.$refs.searchResults.proposalsTable.rows = data.data.map(r => ({
                ...r,
                selected: this.markdownsSelectedOptKeys.includes(r.option_key),
              }))
              this.$refs.searchResults.proposalsTable.total = [data.total]
            }
            this.markdownsSelected = data.selected?.table || this.markdownsSelected
            this.selectedTotal = [data.selected.total]
            this.statistics = Object.freeze(data.statistics)
            this.plannedResults = Object.freeze(data.planned)
            this.allResults = Object.freeze(data.all)
            this.markdownsBlocked = data.blocked?.table || this.markdownsBlocked
            this.blockedTotal = [data.blocked.total]
          })

          this.distribution = data.distribution
          this.loading = false

          this.$nextTick(this.drawChart)
        })
    },
    drawChart () {
      const ctx = document.getElementById('chart')

      if (this.chart) {
        this.chart.destroy()
      }
      const currentName = this.impactTypes[this.impactIndex].value + '_current'
      const proposedName = this.impactTypes[this.impactIndex].value + '_proposed'
      const current = this.distribution[currentName]
      const proposed = this.distribution[proposedName]

      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.distribution.discount_bucket,
          datasets: [{
            type: 'bar',
            label: 'Current',
            data: current,
            backgroundColor: 'transparent',
            borderColor: '#007AFF',
            borderWidth: 2,
          }, {
            type: 'bar',
            label: 'Proposed',
            data: proposed,
            backgroundColor: '#FF980022',
            borderColor: '#FF9800',
            borderWidth: 2,
          }]
        },
        options: {
          maintainAspectRatio: false,
          animation: {
            duration: 0,
          },
          scales: {
            x: {
              stacked: true,
              title: {
                display: true,
                text: 'Discount bucket',
                font: {
                  size: 14,
                },
                padding: {
                  top: 15,
                  bottom: 10,
                },
              },
            },
            y: {
              position: 'left',
              title: {
                display: true,
                font: {
                  size: 14,
                },
                text: 'Pieces',
              },
              ticks: {
                callback: value => {
                  return this.$options.filters.formatThousands(value)
                },
              },
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                usePointStyle: true,
              },
            },
            tooltip: {
              callbacks: {
                label: context => {
                  return this.$options.filters.formatThousands(context.raw)
                },
              }
            },
          },
        },
      })
    },
  },
}
</script>
<style lang="scss">
.pricing-markdown-builder-page {
}
</style>
