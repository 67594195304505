<template>
  <div class="pricing-home-page">
    <h3 class="my-6">Proposal</h3>
    <PricingGraphCarousel
      :scatterData="scatterData"
      @point-click="pointClick"
    />
    <h4 class="my-6 ">High residual</h4>
    <div class="bestseller-carousel mb-3">
      <v-overlay absolute color="white" :value="!residualData">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <carousel :perPageCustom="[[256,1], [768, 3], [1024, 6], [1200, 7]]" :navigationEnabled=false v-if="residualData && residualData.length > 0">
        <slide v-for="product in residualData" :key="product.option_key">
          <v-card class="vcard" height="unset">
            <!-- Card Icon -->
            <v-row class="card-icon justify-end" style="height: 30px">
              <v-icon dense v-show="product.Proposals > 0" class="orange-dot" @click="onDotClick(product)">circle</v-icon>
            </v-row>

            <!-- Card Image -->
            <v-row class="mt-0 mb-4 image-row">
              <img height="160px" :src="product['image_url'] || '@/assets/img/ts_placeholder.png'" @error="loadPlaceholder" alt=""/>
            </v-row>

            <!-- Card Title -->
            <div v-if="product['name'].split(' / ').length === 2" class="option-name-wrapper">
              <router-link class="card-title" :to="product.route">{{product['name'].split(" / ")[0]}}</router-link>
              <p class="card-subtitle">{{product['name'].split(" / ")[1]}}</p>
            </div>

            <template v-else>
              <p class="card-title" @click="$router.push({ 'name': 'stock-option', 'query': {'option_key': product['option_key']} })">{{product['name'].split(" / ")}}</p>
            </template>

            <!-- Price subtitle -->
            <p class="price d-flex justify-space-between">
              <span style="color: #4CAF50">{{$auth.getUserCurrency().symbol}}{{product.OSP}}</span>
              <template v-if="product.OSP > product.ASP">
                <span>/</span>
                <span style="color: #FF9800">{{$auth.getUserCurrency().symbol}}{{product.ASP}}</span>
              </template>
              <template v-else>
                <span></span>
                <span class="px-3"></span>
              </template>                            <template v-if="product.ASP > product.MSP">
                <span>/</span>
                <span  style="color: #FFD202">{{$auth.getUserCurrency().symbol}}{{product.MSP}}</span>
              </template>

              <template v-else>
                <span></span>
                <span class="px-3"></span>
              </template>
            </p>

            <!-- Stock -->
            <v-divider class="mx-0"></v-divider>
            <v-row class="stock-row">
              <v-col class="stock-col pb-0">
                <v-icon class="stock-row-icon">warehouse</v-icon>
                <v-row class="ma-0 justify-space-between align-center flex-nowrap">
                  <v-col class="pa-0">
                    {{product.Stock | formatThousands}}
                  </v-col><v-col class="pa-0">
                    <v-icon style="font-size: 48px">arrow_right_alt </v-icon>
                  </v-col><v-col class="pa-0 text-right">
                    {{product.Residual | formatThousands}}
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="stock-col pt-0">
                <v-icon class="stock-row-icon">savings</v-icon>
                <v-row class="ma-0 justify-space-between align-center flex-nowrap">
                  <v-col class="pa-0">
                    {{$auth.getUserCurrency().symbol}}{{product['Stock value'] | formatThousands}}
                  </v-col><v-col class="pa-0">
                    <v-icon style="font-size: 48px">arrow_right_alt </v-icon>
                  </v-col><v-col class="pa-0 text-right">
                    {{$auth.getUserCurrency().symbol}}{{product['Residual value'] | formatThousands}}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mx-0"></v-divider>
            <budget-progress-item
              class="mt-5"
              :progress-data="progress(product)"
            ></budget-progress-item>
          </v-card>
        </slide>
      </carousel>
    </div>
    <v-card ref="md_proposal_table">
      <v-overlay absolute color="white" :value="!tableData">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <data-sets-tables
        v-if="tableData"
        :view="tableData"
        :index="0"
        ref="datasetTable"
        :firstColumnWider="true"
        :showDownload="true"
        :key="datasetKey"
        :filters="{column: 'main', value: chosenPoint}"
        :has-title="false"
        :child-views="tableData.view.childViews"
        @child-clicked="onDotClick"
        @expand-change="(...data)=>loadChildViews(data)"
      >
        <v-col cols="10" style="margin-right: -100px; padding-right: 100px">
          <h4 class="px-3">Markdown proposals</h4>
          <v-col cols="3" class="py-0">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              :items="dimensions"
              :filter="headersFilters"
              :value="chosenDimension"
              @input="onDimensionsChange"
            />
          </v-col>
        </v-col>
      </data-sets-tables>
    </v-card>
    <div style="padding-bottom: 32px; margin-bottom: -32px">
      <v-card class="mt-8 py-5">
        <PricingProposalOverview
          ref="overview_table"
          @filters-removed="chosenProduct = ''"
          :key="overviewKey"
          :filters="{column: 'option_name', value: chosenProduct}"
        />
      </v-card>
    </div>
  </div>
</template>

<script>

import { performViewData } from '@/api/analytics'
import DataSetsTables from '@/components/AutomateDatasets/DataSetsTables'
import BudgetProgressItem from '@/components/Shop/BudgetProgressItem'
import PricingGraphCarousel from '@/components/Pricing/PricingGraphCarousel'
import PricingProposalOverview from '@/components/Pricing/PricingProposalOverview'
import { formatString } from '@/variables'
import { headersFilters } from '@/utils'

import ts_placeholder from '@/assets/img/ts_placeholder.png'

import { usePricingFiltersStore } from '@/store/pinia/pricingFiltersStore'
import { pricingHomeViews } from '@/staticConfigs/pricingHomePage'
import { pricingViews } from '@/api/pricing'

export default {
  setup () {
    return {
      pricingFiltersStore: usePricingFiltersStore()
    }
  },
  name: 'PricingHomePage',
  data () {
    return {
      chosenPoint: '',
      chosenProduct: '',
      datasetKey: 0,
      chosenDimension: 'properties/article/group',
      overviewKey: 0,
      views: pricingHomeViews,
      scatterData: null,
      tableData: null,
      datasets: {},
      residualData: null,
    }
  },
  components: {
    PricingProposalOverview,
    PricingGraphCarousel,
    BudgetProgressItem,
    DataSetsTables
  },
  computed: {
    filtersOpened () {
      return this.pricingFiltersStore.filtersOpened
    },
    dimensions () {
      const dimensions = {}
      if (!this.datasets['pricing_markdowns']) {
        return []
      }
      const dimensionsList = this.datasets['pricing_markdowns'].dimensions
      dimensionsList.forEach((item) => {
        const data = item.split('/')
        const header = { header: formatString(data[1] || ''), text: [] }
        if (!dimensions[data[1]]) {
          dimensions[data[1]] = [header]
        }
        const text = data[2] === 'name' ? `Name (${formatString(data[1])})` : formatString(data[2] || '')
        dimensions[data[1]].push({ text, value: item, headerTitle: header.header })
        dimensions[data[1]][0].text.push(formatString(data[2] || ''), formatString(data[1] || ''))
      })
      const data = []
      for (const key in dimensions) {
        data.push(...dimensions[key])
      }
      return data
    },

  },
  watch: {
    filtersOpened (newValue) {
      if (!newValue) {
        this.loadData()
      }
    }
  },
  methods: {
    headersFilters,
    onChildRowClick (row) {
      this.chosenProduct = row['properties/article/group']
      this.overviewKey++
    },
    onDotClick (product) {
      setTimeout(() => {
        this.chosenProduct = product.name
        this.overviewKey++
        this.$refs.overview_table.$el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 350) // Smooth filtering
    },
    onDimensionsChange (input) {
      this.chosenDimension = input
      this.loadData()
    },
    pointClick (point) {
      setTimeout(() => {
        this.chosenPoint = point
        this.datasetKey++
        this.$refs.md_proposal_table.$el.scrollIntoView({ behavior: 'smooth', block: 'end' })
        setTimeout(() => this.$refs.datasetTable.allColumnExpand(), 100)
      }, 350) // Smooth filtering
    },
    progress (product) {
      return {
        segments: [
          {
            value: product['Weeks on hand'],
            color: '#007AFF',
            caption: 'Weeks on hand'
          },
          {
            value: product['Weeks left'],
            color: '#E3E3E3',
            caption: 'Weeks left'
          }
        ]
      }
    },
    loadPlaceholder (event) {
      event.target.src = ts_placeholder
    },
    loadChildViews (view) {
      this.views.forEach((view, index) => {
        view.filters = JSON.parse(localStorage.getItem('pricingFilters')) || {}
        if (index !== 1) {
          view.dimensions[0] = this.chosenDimension
        }
      })
      performViewData(view, true).then(() => {
        this.tableData = { ...this.tableData }
      })
        .catch(console.log)
    },
    loadData () {
      const pricingOptionsFilter = JSON.parse(localStorage.getItem('pricingOptionsFilter') || '[]')

      this.views.forEach((view, index) => {
        view.filters = JSON.parse(localStorage.getItem('pricingFilters')) || {}

        if (pricingOptionsFilter.length) {
          view.filters['properties/article/option_key'] = pricingOptionsFilter
        }

        if (index !== 1) {
          view.dimensions[0] = this.chosenDimension
        }
        view.properties.parent_limit = 100
        performViewData(view)
          .then(r => {
            switch (index) {
            case 0:
              this.scatterData = r
              break
            case 1:
              this.residualData = r.data
              break
            case 2:
              this.tableData = r
              break
            }
          })
      })
    }
  },
  async created () {
    pricingViews(
      {'module': 'pricing', 'page': 'home', 'payload': JSON.parse(localStorage.getItem('pricingFilters')) || {}}
    ).catch(console.error)
    this.loadData()
    this.datasets = await this.pricingFiltersStore.loadAndGetDatasets()
  },
}
</script>
<style lang="scss">
.pricing-home-page {
  position: relative;
  .shop-budget-progress-item {
    .total {
      display: none;
    }
    .progress-bar{
      height: 15px;
    }
  }
}
</style>
