<template>
  <v-dialog
    :value="isOpen"
    persistent
    scrollable
    content-class="align-self-start mt-14"
    width="600px"
    max-width="90%">
    <v-card class="rounded-0" v-if="editedStore">
      <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">{{ `${editedStore.store_key ? `Edit store ${editedStore.store_key}` : 'Add store'}` }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-0 text--primary">
        <div class="container px-12">
          <template>
            <!-- General -->
            <h6 class="text-subtitle-1 font-weight-medium mt-4">General</h6>
            <v-row v-if="!editedStore.store_key" class="align-baseline mt-2 mb-4">
              <v-col class="py-0">
                <v-text-field
                  type="number"
                  v-model.number="editedStore.newStoreKey"
                  :error-messages="editedStoreFormErrors.newStoreKey"
                  @input="onValueChange('newStoreKey',$event)"
                  label="Store key"
                  hide-details="auto"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-2 mb-4">
              <v-col class="py-0">
                <v-text-field
                  v-model="editedStore.store_name"
                  :error-messages="editedStoreFormErrors.store_name"
                  @input="onValueChange('store_name',$event)"
                  label="Store name"
                  hide-details="auto"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-5 mb-4">
              <v-col class="py-0">
                <v-autocomplete
                  v-model="editedStore.store_type"
                  :error-messages="editedStoreFormErrors.store_type"
                  @input="onValueChange('store_type',$event)"
                  label="Type"
                  :items="typeOptions"
                  hide-details="auto"
                  clearable
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-5 mb-4">
              <v-col class="py-0">
                <v-autocomplete
                  v-model="editedStore.proposal_key"
                  @input="onValueChange('proposal_key',$event)"
                  label="Proposal key"
                  :items="(appConfig?.stores_open || [])"
                  item-text="store_name"
                  item-value="store_key"
                  hide-details="auto"
                  clearable
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-5 mb-4">
              <v-col class="py-0">
                <v-autocomplete
                  v-model="editedStore.stock_key"
                  @input="onValueChange('stock_key',$event)"
                  label="Stock key"
                  :items="[...(appConfig?.stores_open || []), ...additionalStoresStock]"
                  item-text="store_name"
                  item-value="store_key"
                  :item-color="editedStore.stock_key === additionalStoresStock[0]?.store_key ? 'red' : undefined"
                  hide-details="auto"
                  clearable
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-5 mb-4">
              <v-col class="py-0">
                <v-autocomplete
                  v-model="editedStore.dc_key"
                  :error-messages="editedStoreFormErrors.dc_key"
                  @input="onValueChange('dc_key',$event)"
                  label="DC key"
                  :items="[...(appConfig?.stores_open || []), ...additionalStoresDC]"
                  item-text="store_name"
                  item-value="store_key"
                  :item-color="editedStore.dc_key === additionalStoresDC[0]?.store_key ? 'red' : undefined"
                  hide-details="auto"
                  clearable
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-5 mb-4">
              <v-col class="py-0">
                <v-text-field
                  v-model="editedStore.country"
                  :error-messages="editedStoreFormErrors.country"
                  @input="onValueChange('country',$event)"
                  label="Country"
                  hide-details="auto"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-5 mb-4">
              <v-col class="py-0">
                <v-autocomplete
                  v-model="editedStore.looks_like_store_key"
                  label="Looks like store"
                  :items="appConfig.stores"
                  item-text="store_name"
                  item-value="store_key"
                  class="store-status-dropdown pt-0 my-1 mr-4 text-body-2"
                  hide-details
                  clearable
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                />
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  type="number"
                  step="1"
                  v-model.number="editedStore.looks_like_percentage"
                  :error-messages="editedStoreFormErrors.looks_like_percentage"
                  @input="onValueChange('looks_like_percentage',$event)"
                  label="Looks like percentage"
                  hide-details="auto"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-center mt-5 mb-4">
              <v-col cols="4" class="py-0">
                <label>One stock</label>
              </v-col>
              <v-col class="py-0">
                <v-simple-checkbox
                  v-model="editedStore.one_stock"
                  color="primary"
                  dense
                  hide-details
                  class="d-inline-block mt-0 pt-0"
                  :ripple="false"
                />
              </v-col>
            </v-row>
            <v-row class="align-center mt-5 mb-4">
              <v-col cols="4" class="py-0">
                <label>Export proposals</label>
              </v-col>
              <v-col class="py-0">
                <v-simple-checkbox
                  v-model="editedStore.export_proposals"
                  color="primary"
                  dense
                  hide-details
                  class="d-inline-block mt-0 pt-0"
                  :ripple="false"
                />
              </v-col>
            </v-row>
            <v-row class="align-center mt-5 mb-4">
              <v-col cols="4" class="py-0">
                <label>Allow prepacks</label>
              </v-col>
              <v-col class="py-0">
                <v-simple-checkbox
                  v-model="editedStore.prepacks_allowed"
                  color="primary"
                  dense
                  hide-details
                  class="d-inline-block mt-0 pt-0"
                  :ripple="false"
                />
              </v-col>
            </v-row>

            <!-- Opening -->
            <h6 class="text-subtitle-1 font-weight-medium mt-8">Opening</h6>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0">
                <v-menu
                  ref="datePicker"
                  v-model="openFromDatePickerActive"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="editedStore.date_min"
                      clearable
                      label="Open from"
                      hide-details="auto"
                      v-on="on"
                      @click:clear="editedStore.date_min = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :locale-first-day-of-year="4"
                    :show-week="true"
                    no-title
                    v-model="editedStore.date_min"
                    :max="editedStore.date_max"
                    @click="openFromDatePickerActive = false"
                  />
                </v-menu>
              </v-col>
              <v-col class="py-0">
                <v-menu
                  ref="datePicker"
                  v-model="openToDatePickerActive"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="editedStore.date_max"
                      clearable
                      label="Open to"
                      hide-details="auto"
                      v-on="on"
                      @click:clear="editedStore.date_max = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :locale-first-day-of-year="4"
                    :show-week="true"
                    no-title
                    v-model="editedStore.date_max"
                    :min="editedStore.date_min"
                    @click="openToDatePickerActive = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-5 mb-4">
              <v-col class="py-0">
                <collapsible-select
                  v-model="editedStore.sunday"
                  label="Sunday openings"
                  :items="sundayOptions"
                  item-text="text"
                  show-select-all
                  class="mt-1 mb-4 text-body-2"
                  hide-details
                />
              </v-col>
            </v-row>

            <!-- Logic -->
            <h6 class="text-subtitle-1 font-weight-medium mt-6">Logic</h6>
            <v-row class="align-baseline mt-2 mb-4">
              <v-col class="py-0">
                <v-text-field
                  type="number"
                  v-model.number="editedStore.logic_parameters.delivery_days"
                  :error-messages="editedStoreFormErrors.logicParameters.deliveryDays"
                  @input="onValueChange('deliveryDays',$event, true)"
                  label="Delivery days"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:append-outer>
                    <v-tooltip top :max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                      </template>
                      <span>Estimate amount of days between sending and product being sellable</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-5 mb-4">
              <v-col class="py-0">
                <v-text-field
                  type="number"
                  v-model.number="editedStore.logic_parameters.redelivery_days"
                  :error-messages="editedStoreFormErrors?.logicParameters?.redeliveryDays"
                  @input="onValueChange('redeliveryDays',$event, true)"
                  label="Redelivery days"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:append-outer>
                    <v-tooltip top :max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                      </template>
                      <span>Average amount of time between deliveries</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-5 mb-4">
              <v-col class="py-0">
                <v-text-field
                  type="number"
                  v-model.number="editedStore.logic_parameters.return_costs"
                  :error-messages="editedStoreFormErrors.logicParameters.returnCosts"
                  @input="onValueChange('returnCosts',$event, true)"
                  label="Return costs"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:append-outer>
                    <v-tooltip top :max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                      </template>
                      <span>Estimated return costs per piece</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-5 mb-4">
              <v-col class="py-0">
                <v-text-field
                  type="number"
                  v-model.number="editedStore.logic_parameters.constant_costs"
                  :error-messages="editedStoreFormErrors.logicParameters.constantCosts"
                  @input="onValueChange('constantCosts',$event, true)"
                  label="Constant costs"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:append-outer>
                    <v-tooltip top :max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                      </template>
                      <span>Constant costs per piece sold</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-5 mb-4">
              <v-col class="py-0">
                <v-text-field
                  type="number"
                  v-model.number="editedStore.logic_parameters.variable_costs"
                  :error-messages="editedStoreFormErrors.logicParameters.variableCosts"
                  @input="onValueChange('variable_costs',$event, true)"
                  label="Variable costs"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:append-outer>
                    <v-tooltip top :max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                      </template>
                      <span>Percentage (0.01=1%) of variable costs on turnover</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <!-- External -->
            <template v-if="editedStore.store_key && editedStore.storepage_settings">
              <h6 class="text-subtitle-1 font-weight-medium mt-8">External</h6>
              <v-row class="align-center mt-5 mb-4">
                <v-col cols="4" class="py-0">
                  <label>Mail active</label>
                </v-col>
                <v-col class="py-0">
                  <v-simple-checkbox
                    v-model="editedStore.storepage_settings.mail_active"
                    color="primary"
                    hide-details
                    class="d-inline-block mt-0 pt-0"
                    :ripple="false"
                  />
                </v-col>
              </v-row>
              <v-row class="align-center mt-5 mb-4">
                <v-col class="py-0">
                  <v-combobox
                    v-if="editedStore.storepage_settings.mail"
                    v-model="editedStore.storepage_settings.mail"
                    label="Mail"
                    :items="editedStore.storepage_settings.mail.concat(newEmails)"
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    hide-details
                    class="pt-0"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                    @input="handleEmailChanged"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0 " small close outlined @click:close="editedStore.storepage_settings.mail.splice(0, 1)">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption mx-1">
                        +{{ editedStore.storepage_settings.mail.length - 1 }}
                      </span>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </template>
            <modal-property-management
              class="mt-9 pt-1"
              :obj="editedStore"
              objectName="stores"
              @validation="handlePropertyManagementValidation"
            />
          </template>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2 px-4">
          <v-col>
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  depressed
                  text
                  @click="handleSaveCancel"
                >Cancel</v-btn>
                <v-btn
                  color="primary"
                  :disabled="!arePropertiesValid"
                  depressed
                  outlined
                  @click="handleSaveClick"
                  class="mx-4"
                >Save</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CollapsibleSelect from '@/components/CollapsibleSelect'
import _ from 'lodash'
import ModalPropertyManagement from '@/components/GeneralSettings/ModalPropertyManagement'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'StoreEditModal',
  data () {
    return {
      arePropertiesValid: true,
      editedStore: null,
      editedStoreFormErrors: {
        newStoreKey: null, // camelcased cause not directly sent to backend
        logicParameters: {
          deliveryDays: null,
          redeliveryDays: null,
          returnCosts: null,
          constantCosts: null,
          variableCosts: null,
        }
      },
      openFromDatePickerActive: false,
      openToDatePickerActive: false,
      sundayOptions: [{
        text: 'First',
        value: 1,
      }, {
        text: 'Second',
        value: 2,
      }, {
        text: 'Third',
        value: 3,
      }, {
        text: 'Fourth',
        value: 4,
      }, {
        text: 'Last',
        value: -1,
      }],
      newEmails: [],
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    typeOptions: {
      type: Array,
      default: () => []
    },
    savingStoreSettings: {
      type: Boolean,
      default: false
    },

  },
  components: {
    ModalPropertyManagement,
    CollapsibleSelect,
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    storesList () {
      return (this.appConfig?.stores || [])
    },
    additionalStoresDC () {
      if (!this.editedStore.dc_key) {
        return []
      }
      const storesOpened = this.appConfig?.stores_open || []
      const additionalStoreIndex = storesOpened.findIndex(store => store.store_key === this.editedStore.dc_key)
      if (additionalStoreIndex !== -1) {
        return []
      }
      const additionalStore = {
        color: 'red',
        ...this.storesList.find(store => store.store_key === this.editedStore.dc_key)
      }
      return [additionalStore]
    },
    additionalStoresStock () {
      if (!this.editedStore.stock_key) {
        return []
      }
      const storesOpened = this.appConfig?.stores_open || []
      const additionalStoreIndex = storesOpened.findIndex(store => store.store_key === this.editedStore.stock_key)
      if (additionalStoreIndex !== -1) {
        return []
      }
      const additionalStore = {
        color: 'red',
        ...this.storesList.find(store => store.store_key === this.editedStore.stock_key)
      }
      return [additionalStore]
    }
  },
  methods: {
    handleSaveClick () {
      if (!this.validateStore()) {
        return
      }
      if (this.editedStore.looks_like_percentage === null || this.editedStore.looks_like_percentage === undefined) {
        this.editedStore.looks_like_percentage = 100
      }
      if (!this.editedStore.date_min) {
        this.editedStore.date_min = null
      }
      if (!this.editedStore.date_max) {
        this.editedStore.date_max = null
      }
      this.$emit('save', this.editedStore)
    },
    handleSaveCancel () {
      this.$emit('cancel')
      this.close()
    },
    close () {
      this.$emit('close')
    },
    setEditedStore (store) {
      this.editedStore = { ...store, storepage_settings: { mail: [], ...store.storepage_settings } }
    },
    resetForm () {
      this.editedStore = null
      this.resetFormErrors()
    },
    resetFormErrors () {
      const errors = this.editedStoreFormErrors

      Object.keys(errors)
        .filter(key => key !== 'logicParameters')
        .forEach(key => {
          errors[key] = null
        })

      Object.keys(errors.logicParameters)
        .forEach(key => {
          errors.logicParameters[key] = null
        })
    },
    onValueChange (key, value, isLogic) {
      if (key === 'newStoreKey') {
        if (!this.editedStore.store_key) {
          if (!this.editedStore.newStoreKey) {
            this.editedStoreFormErrors.newStoreKey = 'Can not be empty'
          } else if (this.storesList.map(s => s.store_key).includes(value)) {
            this.editedStoreFormErrors.newStoreKey = 'Should be unique'
          } else {
            this.editedStoreFormErrors.newStoreKey = ''
          }
        }
        return
      }
      if (isLogic) {
        if (!value) {
          this.editedStoreFormErrors.logicParameters[key] = 'Can not be empty'
        } else if (value < 0) {
          this.editedStoreFormErrors.logicParameters[key] = 'Should be >= 0'
        } else {
          this.editedStoreFormErrors.logicParameters[key] = ''
        }
      } else {
        this.editedStoreFormErrors[key] = value ? '' : 'Can not be empty'
      }
    },
    validateStore () {
      this.resetFormErrors()

      if (!this.editedStore.store_key) {
        if (!this.editedStore.newStoreKey) {
          this.editedStoreFormErrors.newStoreKey = 'Can not be empty'
        } else {
          if (this.storesList.map(s => s.store_key).includes(this.editedStore.newStoreKey)) {
            this.editedStoreFormErrors.newStoreKey = 'Should be unique'
          }
        }
      }

      if (!this.editedStore.store_type) {
        this.editedStoreFormErrors.store_type = 'Can not be empty'
      }

      if (!this.editedStore.store_name) {
        this.editedStoreFormErrors.store_name = 'Can not be empty'
      }

      if (!this.editedStore.dc_key) {
        this.editedStoreFormErrors.dc_key = 'Can not be empty'
      }

      if (!this.editedStore.country) {
        this.editedStoreFormErrors.country = 'Can not be empty'
      }

      if (this.editedStore.looks_like_percentage < 0 || this.editedStore.looks_like_percentage > 100) {
        this.editedStoreFormErrors.looks_like_percentage = 'Should be >= 0 and <= 100'
      }

      ['delivery_days', 'redelivery_days', 'return_costs', 'constant_costs', 'variable_costs'].forEach(key => {
        if (!this.editedStore.logic_parameters[key] && !_.isNumber(this.editedStore.logic_parameters[key])) {
          this.editedStoreFormErrors.logicParameters[_.camelCase(key)] = 'Can not be empty'
        } else {
          if (['delivery_days', 'redelivery_days'].includes(key)) {
            if (this.editedStore.logic_parameters[key] < 1) {
              this.editedStoreFormErrors.logicParameters[_.camelCase(key)] = 'Should be >= 1'
            }
          }

          if (['return_costs', 'constant_costs', 'variable_costs'].includes(key)) {
            if (this.editedStore.logic_parameters[key] < 0) {
              this.editedStoreFormErrors.logicParameters[_.camelCase(key)] = 'Should be >= 1'
            }
          }
        }
      })

      return !this.formHasErrors()
    },
    formHasErrors () {
      const mainHasErrors = !Object.values(this.editedStoreFormErrors)
        .filter(val => typeof val !== 'object')
        .every(val => val === null)

      const logicHasErrors = !Object.values(this.editedStoreFormErrors.logicParameters)
        .every(val => val === null)

      return mainHasErrors || logicHasErrors
    },
    handleEmailChanged (val) {
      val.forEach(mail => {
        if (!this.editedStore.storepage_settings.mail.includes(mail)) {
          this.newEmails.push(mail)
        }
      })
      this.editedStore.storepage_settings.mail = this.editedStore.storepage_settings.mail.filter(m => m.includes('@') && m.includes('.'))
    },
    handlePropertyManagementValidation (v) {
      this.arePropertiesValid = v
    }
  },
}
</script>

<style scoped>

</style>
