<template>
  <resizable
    class="strategy-dimension-metrics-table overflow-y-auto overflow-x-hidden"
    @resize="updateTotalTableWidth"
  >
    <v-row class="text-right pr-6 pb-4" justify="end">
      <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
      <div class="ml-2 mr-1">

        <v-tooltip left :max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on" v-bind="attrs"
              class="px-1 action-btn-primary drilldown-icon"
              :disabled="hasChilds"
              @click="handleDrilldownClick"
            >mdi-arrow-expand-down</v-icon>
          </template>
          <span>
            Filter down on selected dimension(s)
          </span>
        </v-tooltip>
      </div>
      <DataExport class="mx-2" @item-click="downloadMetricsData" />
    </v-row>
    <data-tables
      style="width: 100%;"
      ref="parentsTable"
      :data="tableData"
      :filters="tableFilters"
      :key="tableKey"
      :page-size="tableData.length < 10 ? tableData.length : 10"
      :table-props="{
        'row-key': getRowKey,
        'tree-props': {
          children: 'children',
          hasChildren: 'hasChildren'
        },
      }"
      @cell-click="(row, column)=> handleCellClick(row, column, false)"
      :pagination-show="tableData.length > 10 "
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
      @expand-change="handleExpandChange"
      @filter-change="filters => handleFilterChange('tableFilters', filters)"
    >
      <data-tables
        slot="append"
        :data="[metricsData.total]"
        class="total-table"
        :key="tableKey"
        :pagination-props="{class: 'd-none'}"
        @cell-click="(row, column)=> handleCellClick(row,column, true)"
      >
        <el-table-column
          v-if="!hasChilds"
          class-name="total-row_column"
          label-class-name="d-none"
          width="55"
        >
        </el-table-column>
        <el-table-column
          class-name="total-row_column pl-1"
          label-class-name="d-none"
          :align="index === 0 ? 'left' : 'center'"
          v-for="(item, index) in tableColumns"
          :prop="item"
          :key="item"
          :min-width="(!isCustomDimension && index === 0) || (index < dimensionsColumnsNumber) ? '120' : isIndexColumn(item) ? '100px' : null"
        >
          <template slot-scope="scope">
            <span v-if="index === 0">
              <!-- Total -->
            </span>
            <template v-else-if="index >= dimensions.length">
              <span
                v-if="isIndexColumn(item)"
                class="d-flex align-center justify-center colored-area c-pointer"
                :style="{
                  'background-color': getCellColor(scope.row, item),
                  'padding': '12px 10px',
                  height: '50px'
                }"
              >
                <v-text-field
                  @click.stop
                  class="pt-0 pa-0 ma-0 pb-3 change-field"
                  hide-details
                  :key="totalsInputKey"
                  :ref="`textField-total-${item.replaceAll(' ', '')}`"
                  :value="formatNumber(scope.row[item], item, scope.row)"
                  @blur="changedColumn = null; changedRow = null; oldValue = null"
                  @focus="changedColumn = item; changedRow = scope.row; oldValue = scope.row[item]"
                  @keydown.enter="handleEnterClick(scope, true)"
                  @change="updateIndexValue(scope.row, scope.column, $event, true)"
                  v-if="editableMetrics.includes(item.split(' ')[0])"
                ></v-text-field>
                <span v-else>
                  {{ scope.row[item] === null ? '-' : formatNumber(scope.row[item], item) }}
                </span>
              </span>

              <span v-else-if="!isChangebleField(item)" class="c-pointer">
                {{ scope.row[item] === null ? '-' : formatNumber(scope.row[item], item )}}
              </span>
              <v-text-field
                v-else
                @click.stop
                class="pt-0 pb-3 change-field"
                :key="totalsInputKey"
                hide-details
                :ref="`textField-total-${item.replaceAll(' ', '')}`"
                :id="`textField-${tableData.length}-${item.replaceAll(' ', '')}`"
                :value="formatNumber(scope.row[item], item, scope.row)"
                @blur="changedColumn = null; changedRow = null; oldValue = null"
                @focus="changedColumn = item; changedRow = scope.row; oldValue = scope.row[item]"
                @keydown.enter="handleEnterClick(scope, true)"
                @change="updateValue(scope.row, scope.column, $event, true)"
              >
              </v-text-field>
            </template>
          </template>
        </el-table-column>
      </data-tables>

      <template v-if="!hasChilds">
        <el-table-column prop="option_key" width="55" fixed>
          <template v-slot:header>
            <v-simple-checkbox
              color="primary"
              :value="allRowsSelected"
              :ripple="false"
              dense
              hide-details
              class="ml-3"
              @input="handleSelectAllChange"
            />
          </template>
          <template slot-scope="scope">
            <v-simple-checkbox
              color="primary"
              dense
              hide-details
              class="ml-3"
              :value="scope.row.selected"
              :ripple="false"
              @input="handleSelectRowChange($event, scope.row)"
            />
          </template>
        </el-table-column>
      </template>
      <el-table-column
        v-for="(item, index) in tableColumns"
        :key="item"
        :align="(!isCustomDimension && index === 0) || (index < dimensionsColumnsNumber) ? 'left' : 'center'"
        :fixed="(!isCustomDimension && index === 0) || (index < dimensionsColumnsNumber) ? 'left' : false"
        :prop="item"
        :label="formatLabel(item)"
        :min-width="(!isCustomDimension && index === 0) || (index < dimensionsColumnsNumber) ? '120' : isIndexColumn(item) ? '100px' : null"
        :class-name="'text-capitalize pl-1'"
        sortable
      >
        <template slot-scope="scope">
          <span v-if="item === 'store_keys'">
            {{ storeKeysToNames.get(scope.row[item]) }}
          </span>
          <span v-else-if="(!isCustomDimension && index === 0) || (index < dimensionsColumnsNumber)">
            <template v-if="scope.row.isChild && index >= childDimensions.length">
              -
            </template>
            <template v-else>
              {{ scope.row[item] }}
            </template>
          </span>
          <span
            v-else-if="isIndexColumn(item)"
            class="d-flex align-center justify-center colored-area c-pointer"
            :style="{
              'background-color': getCellColor(scope.row, item),
              'padding': '12px 10px',
              height: '50px'
            }"
          >
            <v-text-field
              @click.stop
              class="pa-0 ma-0 pt-0 change-field"
              hide-details
              dense
              :ref="`textField-${scope.$index}-${item.replaceAll(' ', '')}`"
              :id="`textField-${scope.$index}-${item.replaceAll(' ', '')}`"
              :value="formatNumber(scope.row[item], item, scope.row)"
              @blur="changedColumn = null; changedRow = null; oldValue = null"
              @focus="changedColumn = item; changedRow = scope.row; oldValue = scope.row[item]"

              @keydown.enter="handleEnterClick(scope)"
              @change="updateIndexValue(scope.row, scope.column, $event, false)"
              v-if="editableMetrics.includes(item.split(' ')[0])"
            ></v-text-field>
            <span v-else>
              {{ scope.row[item] === null ? '-' : formatNumber(scope.row[item], item) }}
            </span>
          </span>
          <span v-else-if="!isChangebleField(item)" class="c-pointer">
            {{ scope.row[item] === null ? '-' : formatNumber(scope.row[item], item )}}
          </span>
          <v-text-field
            v-else
            @click.stop
            class="pt-0 pb-3 change-field"
            hide-details
            :ref="`textField-${scope.$index}-${item.replaceAll(' ', '')}`"
            :id="`textField-${scope.$index}-${item.replaceAll(' ', '')}`"
            :value="formatNumber(scope.row[item], item, scope.row)"
            @blur="changedColumn = null; changedRow = null; oldValue = null"
            @focus="changedColumn = item; changedRow = scope.row; oldValue = scope.row[item]"

            @keydown.enter="handleEnterClick(scope)"
            @change="updateValue(scope.row, scope.column, $event, false)"
          >
          </v-text-field>
        </template>
      </el-table-column>
    </data-tables>
  </resizable>
</template>

<script>
import DataExport from '@/components/DataExport.vue'
import TableSearch from '@/components/TableSearch.vue'
import { downloadData } from '@/utils'
import { formatString } from '@/variables'
import interpolate from 'color-interpolate'
import Resizable from '@/components/Utility/Resizable.vue'
import { useCubesStore } from '@/store/pinia/cubesStore'

const redToWhite = interpolate(['#EE37664D', '#E85B7F4D', '#E88BA54D', '#FFF4F44D'])
const whiteToGreen = interpolate(['#FFF4F44D', '#aaccc54D', '#7ec1b84D', '#4FB4AB4D'])

export default {
  name: 'StrategyDimensionMetricsTable',
  components: { Resizable, DataExport, TableSearch },
  props: {
    isCustomDimension: {
      type: Boolean,
      default: false,
    },
    metricsData: {
      type: Object,
      default: () => ({}),
    },
    dimensions: {
      type: Array,
      default: () => [],
    },
    childDimensions: {
      type: Array,
      default: () => [],
    },
    storeKeysToNames: {
      type: Map,
      default: () => new Map(),
    },
  },
  setup () {
    return {
      cubesStore: useCubesStore(),
    }
  },
  data () {
    return {
      oldValue: null,
      totalsInputKey: 0,
      changedRow: null,
      changedColumn: null,
      tableKey: 0,
      tableFilters: [{ value: '' }],
    }
  },
  computed: {
    editableMetrics () {
      const views = this.cubesStore.cubesBrowser.metric_editable

      return this.noStores ? views : views.filter(v => !v.no_stores)
    },
    tableData () {
      return this.metricsData.data.map((parent, pIndex) => {
        const result = {
          ...parent,
          selected: false,
          index: pIndex,
          children: this.metricsData.childs
            .filter(child => this.metricsData.columns
              .slice(0, this.dimensions.length)
              .every(col => parent[col] === child[col])
            )
            .map((child, cIndex) => {
              const result = {
                ...child,
                index: cIndex,
                isChild: true,
              }

              for (let i = 0; i < this.dimensionsColumnsNumber; i++) {
                result[`dimension_${i + 1}`] = child[Object.keys(child).slice(this.dimensions.length)[i]]
              }

              return result
            })
        }

        for (let i = 0; i < this.dimensionsColumnsNumber; i++) {
          result[`dimension_${i + 1}`] = i >= this.dimensions.length ? '-' : parent[Object.keys(parent)[i]]
        }

        return result
      })
    },
    tableColumns () {
      return [
        ...[...Array(this.dimensionsColumnsNumber)].map((x, i) => `dimension_${i + 1}`),
        ...this.metricsData.columns.slice(this.dimensions.length)
      ]
    },
    dimensionsColumnsNumber () {
      return Math.max(this.dimensions.length, this.childDimensions.length)
    },
    hasChilds () {
      return this.metricsData.childs.length > 0
    },
    selectedRows () {
      return this.tableData.filter(r => r.selected)
    },
    allRowsSelected () {
      return (this.tableData.length > 0) && (this.selectedRows.length === this.tableData.length)
    },
    metricFormats () {
      return this.cubesStore.metricFormat
    },
  },
  watch: {
    metricsData: {
      handler () {
        this.$el.querySelector('.el-table__append-gutter').innerHTML = '<div class="footer-total-caption">Total</div>'
      },
    }
  },
  methods: {
    handleExpandChange (row, value){
      row.isExpanded = value
    },
    isChangebleField (fieldName){
      const columnProps = fieldName.split(' ')
      if(!columnProps[1]?.toLowerCase()?.includes('bdg')){
        return false
      }
      return this.editableMetrics.includes(columnProps[0])
    },
    updateValue (row, column, value, isTotalRow){
      if(value === this.oldValue || Number.isNaN(parseFloat(value))){
        return
      }
      row[column.property] = parseFloat(value)
      this.$emit('cell-value-change', {row, column, isTotalRow})
    },
    updateIndexValue (row, column, value, isTotalRow){
      if(value === this.oldValue || Number.isNaN(parseFloat(value))){
        return
      }
      const fieldName = column.property
      const newValue = row[fieldName.replace('(idx)', '(ref)')] * (value / 100)
      row[column.property] = parseFloat(value)
      this.updateValue(row, {...column, property: fieldName.replace('(idx)', '(bdg)')}, newValue, isTotalRow )
      // this.$emit('cell-value-change', {row, column, isTotalRow})
    },
    handleEnterClick (scope, isTotalRow = false){
      let index = scope.$index
      const column = scope.column
      if(isTotalRow ){
        this.totalsInputKey++
        return
      }

      if(!scope.row.isExpanded && scope.row.children?.length){
        index += scope.row.children.length
      }
      index++

      let newField = this.$refs[`textField-${index}-${column.property?.replaceAll(' ', '')}`]

      if(newField){
        newField.forEach(item=> item.focus())
      }else {
        newField = this.$refs[`textField-total-${column.property?.replaceAll(' ', '')}`]
        newField?.forEach(item=> item?.focus())
      }
      this.$nextTick(() => {
        newField?.forEach(item=> item?.$emit('focus'))
      })
    },
    formatString,
    isIndexColumn (col) {
      return col.includes('(idx)')
    },
    formatLabel (label) {
      const labelParts = label.split('/')

      if (labelParts.length > 1) {
        label = labelParts[1]
      }

      return label === 'store_keys' ? 'Stores' : this.isIndexColumn(label) ? 'idx' : formatString(label)
    },
    formatNumber (value, metricName, row) {
      if(row && this.changedRow && row === this.changedRow && metricName === this.changedColumn) {
        return value
      }
      let format = this.metricFormats[metricName.substring(0, metricName.length - 6)]

      if (this.isIndexColumn(metricName) && typeof value === 'number') {
        format = 'integer'
      }

      if (!format) {
        return value
      }

      return this.$options.filters[format](value)
    },
    getRowKey (row) {
      const metricsKey = this.metricsData.columns
        .slice(0, this.dimensions.length)
        .map(c => row[c])
        .join('-')
      return `${metricsKey}-${row.index}${(row.isChild ? '-child' : '-parent')}`
    },
    getCellColor (row, column) {
      if (!row[column] && row[column] !== 0) {
        return 'transparent'
      }
      if (row[column] < 100) {
        return redToWhite(row[column] / 100)
      }

      if (row[column] === 100) {
        return 'white'
      }

      if (row[column] > 100 && row[column] < 200) {
        return whiteToGreen((row[column] - 100) / 100)
      }

      if (row[column] > 200) {
        return whiteToGreen(1)
      }
    },
    getSummary (params) {
      const sums = []
      const { columns } = params
      columns.forEach((column, index) => {
        if ((!this.isCustomDimension && index === 0) || (index < this.dimensionsColumnsNumber)) {
          if (this.hasChilds) {
            if (index === 0) {
              sums[index] = 'Total'
            } else {
              sums[index] = ''
            }
          } else {
            if (index === 1) {
              sums[index] = 'Total'
            } else {
              sums[index] = ''
            }
          }
        } else {
          // const isIndexColumn = this.isIndexColumn(column.property)

          sums[index] = this.formatNumber(this.metricsData.total[column.property], column.property)
        }
      })
      return sums
    },
    handleSelectAllChange (val) {
      this.tableData.forEach(r => this.$set(r, 'selected', val))
    },
    handleSelectRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
    handleSearchChanged (val) {
      this.tableFilters[0].value = val
      this.tableKey++
      this.updateTotalTableWidth()
    },
    handleDrilldownClick () {
      this.$emit('drilldown-click', this.selectedRows)
    },
    handleCellClick (row, column, isTotalRow = false) {
      const columnProps = column.property.split(' ')
      if(!this.editableMetrics.includes(columnProps[0])){
        return
      }else {
        if(this.isIndexColumn(column.property) || this.isChangebleField(column.property)){
          const index = isTotalRow ? this.tableData.length : row.index
          const newField = this.$refs[`textField-${index}-${column.property?.replaceAll(' ', '')}`]
          if(newField){
            newField.forEach(item=> item.focus())
          }
          return
        }
      }
      this.$emit('cell-click', { row, column, isTotalRow })
    },
    handleSummaryClick (event) {
      const clickedCell = event.target.closest('.el-table__cell')
      const clickedCellIndex = Array.prototype.indexOf.call(clickedCell.parentNode.children, clickedCell)
      const hiddenTotalTableCell = this.$el.querySelector(`#hidden-total-table .el-table__body .el-table__cell:nth-of-type(${clickedCellIndex + 1})`)

      hiddenTotalTableCell.click()
    },
    downloadMetricsData (sep) {
      const data = this.tableData.map(
        row => this.metricsData.columns.reduce(
          (acc, cur) => {
            if (cur === 'store_keys') {
              return Object.assign(acc, { [cur]: this.storeKeysToNames.get(row[cur]) })
            }
            return Object.assign(acc, { [cur]: row[cur] })
          }, {}
        )
      )

      downloadData(sep, data, 'dimension_metrics')
    },
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = this.$el.querySelector('.el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 300)
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.$el.querySelector('.el-table__append-gutter').innerHTML = '<div class="footer-total-caption">Total</div>'
      this.$el
        .querySelectorAll('.el-table__footer-wrapper .el-table__cell')
        .forEach(cell => cell.addEventListener('click', this.handleSummaryClick))
    })
  },
  unmounted () {
    this.$el
      .querySelectorAll('.el-table__footer-wrapper .el-table__cell')
      .forEach(cell => cell.removeEventListener('click', this.handleSummaryClick))
  },
}
</script>

<style lang="scss">
.strategy-dimension-metrics-table {
  .el-table td {
    padding-top: 0;
    padding-bottom: 0;
  }
  .total-row_column {
    border-top: 1px solid #ebeef5;
    background-color: #f5f7fa;
  }
  .el-table__fixed {
    background: #f5f7fa;
  }
  .el-table__body-wrapper.is-scrolling-left~.el-table__fixed {
    background: transparent;
  }
  .el-table__append-gutter {
    position: relative;
    .footer-total-caption {
      position: absolute;
      font-size: 13px;
      top: 18px;
      left: 69px;
    }
  }
  .el-table__expand-icon {
    display: none;
  }
  .el-table__row--level-1 {
    background: #fafafa;
    .el-table__indent {
        display: none;
    }
    .el-table__placeholder {
        width: 23px;
    }
  }
  .drilldown-icon {
    font-size: 18px;
    line-height: 24px;
  }
  .el-table__footer-wrapper {
    .el-table__cell {
      cursor: pointer;
    }
  }
  .change-field {
    box-sizing: border-box;
    color:rgb(96, 98, 102);
    font-size: 13px;
  }
  .change-field input{
    color:rgb(96, 98, 102);
    text-align: center;
  }
}

</style>
