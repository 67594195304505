<template>
  <div class="proposalTable">
    <v-row class="my-0">
      <v-col class="text-right pr-8">
        <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
        <DataExport
          :closeOnClick="false"
          @item-click="handleDownloadClick"
        >
          <v-overlay
            absolute
            color="white"
            :value="loadingExportData"
            slot="additional-list-item"
          >
            <v-progress-circular color="primary" indeterminate size="64" width="2" />
          </v-overlay>
        </DataExport>
      </v-col>
    </v-row>
    <v-row class="my-0">
      <v-col class="pt-0">
        <data-tables
          ref="proposalTable"
          class="proposal-data-tables"
          :key="proposalsListKey"
          :filters="filters"
          :data="proposal"
          :tableProps="tableProps"
          :sort-method="sortMethod"
          :page-size="15"
          :pagination-props="{
            pageSizes: [5, 10, 15, 25, 50],
            class: 'el-pagination text-right mt-6 mb-4 mr-2'
          }"
        >
          <el-table-column
            prop="Store"
            align="left"
            width="200px"
            :label="'Stores (' + proposal.length + '/' + appConfig.stores_open.length + ')'"
            key="Store"
            sortable="custom"
            class-name="pl-6 p-relative"
          >
            <template slot-scope="scope">
              {{ scope.row.Store.substring(0, 14) }}
              <v-icon
                :size="21"
                class="store-info-icon"
                @click="$emit('store-clicked', scope.row)"
              >info</v-icon>
            </template>
          </el-table-column>

          <el-table-column v-for="(title,idx) in headers" :prop="title" align="center" :label="title | formatString" :key="String(title)+String(idx)" sortable="custom">
            <template slot-scope="scope">
              <div
                v-if="title !== 'Total' && title !== 'Cover'"
                class="d-flex align-center justify-center colored-area"
                :style="(title === 'Store' || scope.row['child'] === true) ? {
                  'display': 'inline'
                } : {
                  'background-color': getCellColor(scope.row, title),
                  'padding': '10px',
                  'font-size': '14px',
                }">{{ scope.row[title] }}</div>
              <div
                v-else
                :style="{
                  'padding': '10px',
                  'font-size': '14px',
                }"
              >{{ scope.row[title] }}</div>
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from '@/services/axios.js'
import { getHeadersWithAccessToken } from '@/variables.js'
import { downloadData } from '@/utils'
import TableSearch from '@/components/TableSearch.vue'
import DataExport from '../DataExport'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import baseURLs from '@/api/APIBaseURLs'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

import { useArticleStore } from '@/store/pinia/stockArticlesStore'

export default {
  setup () {
    return {
      articlesStore: useArticleStore(),
      proposalStore: useStockProposalStore()}
  },
  data () {
    return {
      sortMethod: {
        Store (a, b) {
          const collator = new Intl.Collator(['zh-Hans-CN', 'zh-CN'])
          const flag = a - b
          return Number.isNaN(flag) ? collator.compare(a, b) : flag
        }
      },
      tableProps: {
        'row-key': 'id',
        'default-expand-all': false,
        'summary-method': this.getSummaries,
        'show-summary': true,
        'tree-props': {
          children: 'children',
        },
        'cell-class-name': this.getCellClassName,
      },
      filters: [
        {
          value: ''
        }
      ],
      loadingExportData: false,
      proposalsListKey: 0,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    headers () {
      const proposalHeaders = this.proposalStore.getProposalHeaders
      const updatedHeaders = []
      for (const k of proposalHeaders) {
        if (updatedHeaders.indexOf(k) !== -1 || k === 'impact' || k === 'children' || k === 'child' || k === 'id' || k === 'Store' || k === 'ImpactRule') {
          continue
        }
        updatedHeaders.push(k)
      }
      return updatedHeaders
    },
    proposal () {
      const { stores } = this.appConfig

      return this.proposalStore.getProposal.map(p => {
        const store = stores.find(x => parseInt(x.store_key) === parseInt(p.Store))
        return {
          ...p,
          storeKey: p.Store,
          Store: store ? store.store_name : p.Store,
        }
      })
    }
  },
  components: {
    DataExport,
    TableSearch,
  },
  methods: {
    getSummaries (param) {
      // eslint-disable-next-line
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total'
          return
        }
        if (column.property === 'Cover') {
          sums[index] = this.proposalStore.totalRowCover
          return
        }
        const values = this.proposal.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = _.round(values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0), 2)
        } else {
          sums[index] = 'N/A'
        }
      })

      return sums
    },
    getCellClassName ({ column }) {
      return column.property !== 'Store' ? 'colored-td' : ''
    },
    getCellColor (row, title) {
      let color = '#ffcc80'
      const colors = this.proposalStore.getProposalColors
      if (title === 'Proposal') {
        if (this.proposalStore.singleSku && row['impact']) {
          color = colors[row['impact']]
        }
      } else {
        if (row['impact'][title] !== -1) {
          color = colors[row['impact'][title]]
        }
      }

      return color + '4d' // +4d for opacity
    },
    handleSearchChanged (val) {
      this.filters[0].value = val
      this.proposalsListKey++
    },
    handleDownloadClick (sep) {
      const url = baseURLs.logicAPI + '/proposal'

      this.loadingExportData = true

      const params = { ...this.articlesStore.articleSettings }
      params['option_key'] = this.$route.query.option_key
      params['rules'] = this.proposalStore.proposalSetting.rules
      params['params'] = this.proposalStore.proposalSetting.parameters
      params['format'] = 'raw'

      return axios.post(url, params, {
        headers: getHeadersWithAccessToken()
      })
        .then(response => {
          this.loadingExportData = false

          downloadData(sep, response.data.data, `${this.$route.query.option_key}`)
        })
        .catch(error => {
          console.log(error)
        })
    },
  }
}
</script>

<style lang="scss">
.proposalTable {
    .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: white !important;
    }
    input:focus {
        outline: none !important;
        box-shadow:none
    }
    .el-table td {
        height: 45px;
        padding: 0;
        border-bottom: none;
    }
    .el-table .cell {
        padding: 0;
    }
    .el-table__expanded-cell {
        padding: 20px 50px !important;
    }
    .el-table__row--level-1 {
        background-color: #fafafa !important;
        .store-info-icon {
            display: none;
        }
    }
    .el-table__footer-wrapper tbody td {
        background-color: #fff;
    }
    .proposal-data-tables {
        .colored-td {
            .cell {
                height: 100%;
                .colored-area {
                    height: 100%;
                }
            }
        }
    }
    .store-info-icon {
        position: absolute;
        top: 12px;
        right: 24px;
        cursor: pointer;
    }
}
</style>
