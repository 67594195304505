<template>
  <div class="chart-container" style="position: relative; height: 400px">
    <canvas ref="chartEl" class=""></canvas>
  </div>
</template>

<script>
import { Chart } from 'chart.js/auto'
import { toFont } from 'chart.js/helpers'

export default {
  props: {
    chartData: {
      type: Object,
      default: () => ({}),
    },
    ros: {
      type: Object,
      default: () => ({}),
    },
    defaultCurrencyPrice: {
      type: Number || null,
      required: false,
    },
  },
  data () {
    this.chart = null

    return {}
  },
  computed: {
  },
  methods: {
    getPointOptionInfo (dataPoint) {
      const dataKey1 = (dataPoint.datasetIndex === 0 ? 'similar' : 'lookalikes')
      const dataKey2 = (dataPoint.datasetIndex === 0 ? 'similar_option' : 'lookalike_option_key')

      return this.chartData[dataKey1][dataKey2][dataPoint.index]
    },
    plotGraph () {
      const me = this // to use inside tooltip callback

      if (this.chart) {
        this.chart.destroy()
      }

      const datasets = [{
        label: 'Similar',
        data: this.chartData.similar.price.map((p, i) => ({
          x: p,
          y: this.chartData.similar.similar_ros[i],
          label: '',
          r: 5,
        })),
        backgroundColor: '#FF9800',
      }, {
        label: 'Lookalike',
        data: this.chartData.lookalikes.price.map((p, i) => ({
          x: p,
          y: this.chartData.lookalikes.lookalike_ros[i],
          label: '',
          r: 5,
        })),
        backgroundColor: '#007AFF',
      }]

      if (this.defaultCurrencyPrice && this.ros) {
        datasets.push({
          label: 'Dummy',
          data: [{
            x: this.defaultCurrencyPrice,
            y: this.ros.total,
            label: '',
            r: 5,
          }],
          backgroundColor: '#4CAF50',
        })
      }

      this.chart = new Chart(this.$refs.chartEl, {
        type: 'bubble',
        data: {
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          /* hover: {
            mode: 'null'
          }, */
          scales: {
            x: {
              title: {
                display: true,
                text: 'Price',
                font: {
                  size: 14,
                },
              },
              /* ticks: {
                callback: value => `$${value}`
              } */
            },
            y: {
              title: {
                display: true,
                text: 'RoS',
                font: {
                  size: 14,
                },
              },
            }
          },
          plugins: {
            legend: {
              position: 'bottom',
              align: 'center',
              display: true,
              labels: {
                usePointStyle: true,
              }
            },
            tooltip: {
              enabled: false,
              filter: function (tooltipItem) {
                // don't show tooltip for dummy price dataset
                return tooltipItem.datasetIndex !== 2
              },
              external: function (context) {
                const tooltipModel = context.tooltip

                // Tooltip Element
                let tooltipEl = document.getElementById('chartjs-tooltip')

                // don't show tooltip for dummy price dataset
                if (!tooltipModel.dataPoints || !tooltipModel.dataPoints.length) {
                  if (tooltipEl) {
                    tooltipEl.style.opacity = 0
                  }

                  return
                }

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div')
                  tooltipEl.id = 'chartjs-tooltip'
                  tooltipEl.innerHTML = '<div id="tooltip-root" style="padding: 6px 8px; background-color: rgba(0, 0, 0, 0.7); color: #fff; border-radius: 5px;"></div>'
                  document.body.appendChild(tooltipEl)
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0
                  return
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform')
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign)
                } else {
                  tooltipEl.classList.add('no-transform')
                }

                const dataPoint = {
                  datasetIndex: tooltipModel.dataPoints[0].datasetIndex,
                  index: tooltipModel.dataPoints[0].dataIndex,
                }
                const optionInfo = me.getPointOptionInfo(dataPoint)
                const optionInfoParts = optionInfo.option_name.split(' / ')

                const tooltipRoot = tooltipEl.querySelector('#tooltip-root')

                tooltipRoot.innerHTML = `<div style="display: flex">
                  <div>
                      <img alt="option" src="${optionInfo.image_url}" style="width: 30px;">
                  </div>
                  <div style="margin-left: 12px;">
                      <div style="margin-top: 8px; font-weight: bold;">${optionInfoParts[0]}</div>
                      <div style="margin-top: 8px; margin-bottom: 12px;">${optionInfoParts[1]}</div>
                  </div>
                </div>`

                const position = context.chart.canvas.getBoundingClientRect()
                const bodyFont = toFont(tooltipModel.options.bodyFont)

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1
                tooltipEl.style.position = 'absolute'
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
                tooltipEl.style.font = bodyFont.string
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
                tooltipEl.style.pointerEvents = 'none'
              }
            },
          },
        }
      })

      this.chart.update()
    },
  },
  watch: {
    chartData: function () {
      this.plotGraph()
    }
  }
}
</script>
<style>
.yMax {
  width: 250px;
  float: right;
  margin-right: 25px;
}
</style>
