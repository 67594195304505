<template>
  <div class="buy-manage-costs-payments mb-6">
    <buy-manage-payments
      :payments="payments"
      @payments-update="$emit('payments-update')"
    />
    <buy-manage-costs
      :costsData="costs"
      :payments="payments"
      @load-costs="r => $emit('load-costs', r)"
    />
  </div>
</template>

<script>

import BuyManageCosts from './BuyManageCosts'
import BuyManagePayments from './BuyManagePayments'
export default {
  name: 'BuyManageCostsPayments',
  props: ['payments', 'costs'],
  components: { BuyManagePayments, BuyManageCosts },
}
</script>

<style scoped>
  .buy-manage-costs-payments {
    display: flex;
    flex-direction: column;
  }
</style>
