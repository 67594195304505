<template>
  <v-dialog
    :value="dialogVisible"
    persistent
    scrollable
    content-class="align-self-start mt-14"
    width="600px"
    max-width="90%">
    <v-card class="rounded-0">
      <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">Create Alert</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-0 text--primary">
        <div class="alert-modal">
          <v-overlay absolute color="white" :value="sending">
            <v-progress-circular color="primary" indeterminate size="64" width="2" />
          </v-overlay>

          <div class="container px-12">
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0">
                <v-text-field
                  label="Name"
                  v-model="createForm.name.value"
                  :error-messages="createForm.name.error"
                  disabled
                  hide-details="auto" />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0">
                <v-select
                  label="User"
                  v-model="createForm.user.value"
                  :error-messages="createForm.user.error"
                  :items="users"
                  item-value="id"
                  :item-text="item => item.first_name + ' ' + item.last_name"
                  placeholder="Click to select"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0">
                <v-menu
                  ref="datePicker"
                  v-model="datePickerActive"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="createForm.date.value"
                      :error-messages="createForm.date.error"
                      clearable
                      label="Pick a date"
                      hide-details="auto"
                      v-on="on"
                      @click:clear="createForm.date.value = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    :first-day-of-week="1"
                    :locale-first-day-of-year="4"
                    v-model="createForm.date.value"
                    :min="new Date().toISOString().slice(0, 10)"
                    @click="datePickerActive = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0">
                <v-textarea
                  label="Comment"
                  v-model="createForm.comment.value"
                  :error-messages="createForm.comment.error"
                  placeholder="Max 100 char."
                />
              </v-col>
            </v-row>

            <v-alert v-if="failedError !==''" type="error">{{ failedError }}</v-alert>
          </div>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2 px-4">
          <v-col>
            <v-btn
              color="primary"
              depressed
              text
              @click="$emit('close-click')"
            >Cancel</v-btn>
            <v-btn
              color="primary"
              depressed
              outlined
              @click="submitForm"
              class="red--text mx-4"
            >Create</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { useGeneralStore } from '@/store/pinia/generalStore'
import { useAlertStore } from '@/store/pinia/alertStore'

export default {
  props: ['dialogVisible', 'optionName', 'optionKey'],
  setup () {
    return { generalStore: useGeneralStore(), alertStore: useAlertStore() }
  },
  data () {
    return {
      currUser: null,
      datePickerActive: false,
      sending: false,
      failedError: '',
      createForm: {
        name: {
          value: '',
          error: null,
        },
        user: {
          value: '',
          error: null,
        },
        date: {
          value: '',
          error: null,
        },
        comment: {
          value: '',
          error: null,
        },
      },

    }
  },
  computed: {
    users () {
      return this.generalStore.appConfig?.users || []
    },
  },
  watch: {
    optionKey () {
      this.reset()
    },
    optionName: {
      handler (val) {
        this.createForm.name.value = val
      },
      immediate: true,
    },
    users: {
      handler (newVal) {
        if (newVal) {
          this.currUser = this.users.filter(user => user.email === localStorage.getItem('email')).pop()
          if (!this.currUser) {
            return
          }
          this.createForm.user.value = this.currUser?.id || ''
        }
      },
      immediate: true,
    },
  },
  methods: {
    submitForm () {
      if (!this.validateAlertForm()) {
        return
      }

      this.$emit('form-submit')
    },
    validateAlertForm () {
      this.clearAlertFormErrors()

      if (!this.createForm.name.value) {
        this.createForm.name.error = 'Please input name'
      }

      if (!this.createForm.user.value) {
        this.createForm.user.error = 'Please select user'
      }

      if (!this.createForm.date.value) {
        this.createForm.date.error = 'Please pick a date in the future.'
      }

      if (this.createForm.comment.value.length > 100) {
        this.createForm.comment.error = 'Comment too long. Max 100 char.'
      }

      return !this.alertFormHasErrors()
    },
    clearAlertFormErrors () {
      for (const key in this.createForm) {
        this.createForm[key].error = null
      }
    },
    alertFormHasErrors () {
      let hasErrors = false

      for (const key in this.createForm) {
        if (this.createForm[key].error) {
          hasErrors = true
        }
      }

      return hasErrors
    },
    createAlert (payload) {
      this.sending = true
      this.alertStore.createAlert({
        ...payload,
        payload: {
          ...payload.payload,
          name: this.createForm.name.value,
          comment: this.createForm.comment.value || 'No comment',
        },
        time_start: this.createForm.date.value,
        user_id: this.createForm.user.value,
      })
        .then(() => {
          this.reset()
          this.$nextTick(() => {
            this.$emit('alert-create')
          })
        })
        .catch(error => {
          this.failedError = JSON.stringify(error.response.data.message)
          console.log(error)
        })
        .finally(() => {
          this.sending = false
        })
    },
    reset () {
      this.sending = false
      this.createForm.comment.value = ''
      this.createForm.date.value = new Date().toISOString().substr(0, 10)
      this.createForm.user.value = this.currUser.id
      this.clearAlertFormErrors()
    },
    hideForm () {
      this.reset()
    },
  },
  created () {
    this.createForm.date.value = new Date().toISOString().substr(0, 10)
  },
}
</script>

<style scoped lang="scss">
.base-alert-modal {
}
</style>
