<template>
  <v-card
    v-if="!isChangeView"
    :style="!height && 'min-height: 400px'"
    :flat="flat || inModal"
    class="pa-4 pt-2 overflow-hidden data-sets-line"
    :id="`bar-chart-container-${view.view.view_key}`"
  >
    <div class="d-flex align-center ma-0 justify-space-between pb-2" v-if="index !== null && !hide">
      <h4 class="text-no-wrap">{{positionTitle}}. {{ view.view.view_name }}</h4>
      <div class="d-flex show-on-hover flex-nowrap" v-if="!view.view.errored && !view.errored" >
        <v-icon class="show-on-hover action-btn-primary " @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" v-if="!inModal">edit</v-icon>
        <v-overlay absolute color="white" :value="downloadingLoaded">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <DataExport
          @item-click="downloadData"
        />
        <v-icon
          class="mr-1 action-btn-primary"
          @click="$emit('full-screen',view,index)"
          v-if="!inModal"
        >fullscreen</v-icon>
        <data-sets-view-filter :view="view" @change="filterChanged" :index="index" ></data-sets-view-filter>
      </div>

      <template v-else-if="view.view.errored || view.errored" >
        <div class="d-flex show-on-hover flex-nowrap">
          <v-icon class="show-on-hover action-btn-primary " @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" v-if="!inModal">edit</v-icon>
        </div>
      </template>
    </div>
    <v-overlay absolute color="white" :value="(!view.data) && !(view.view.errored || view.errored)">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <DataSetsBarChart
      :resizing="index !== null && !hide"
      style="max-width: 100%"
      v-if="view.view.chart_type === 'bar_chart' && !view.view.errored && !view.errored"
      :title="'data_sets_'+view.view.chart_type"
      :index="index +'.' + secondaryIndex + (inModal ? 1 : 0)"
      :chartData="view"
      :height="height || '100%'"
    />
    <chart-element
      style="max-width: 100%"
      v-else-if="!view.view.errored && !view.errored && view.data && view.data.length"
      :ref="'data_sets_'+view.view.chart_type"
      :title="'data_sets_'+view.view.chart_type"
      :forced-height="height? `${height}px`: '100%'"
      :id="'data_sets_'+view.view.chart_type+ index +'.' + secondaryIndex+(inModal ? 'true' : '' ) "
      :params="{}"
      :data-type="'date_sets_'+view.view.chart_type"
      :chartData="{...view, index: index +'.' + secondaryIndex+(inModal ? 'true' : '' ) }"
    ></chart-element>
    <h4 v-else-if="view.view.errored || view.errored || (view.data && !view.data.length)" class="row justify-center pb-8">Could not retrieve data</h4>
  </v-card>
  <datasets-new-view
    v-else
    :dashboard-details="dashboardDetails"
    :viewsMap="viewsMap"
    @saved-positions="$emit('saved-positions', $event)"
    :changeView="{...view.view, position}"
    :positions="positions"
    :datasets="datasets"
    :dataSetForChoose="dataSetForChoose"
    @cancel="isChangeView = false;$emit('toggle-view', false)"
    @delete-view="$emit('delete-view',$event)"
    @save="changeView">
  </datasets-new-view>
</template>

<script>
import ChartElement from '../../chart/ChartElement'
import DataExport from '../DataExport'
import DataSetsBarChart from './DataSetsBarChart'

import DataSetsViewFilter from '@/components/AutomateDatasets/DataSetsViewFilter'
import { downloadAnalytics } from '@/api/analytics'
import { useAnalyticsFiltersStore } from '@/store/pinia/analyticsFiltersStore'
import DatasetsNewView from '@/components/AutomateDatasets/DatasetsNewView.vue'

export default {
  name: 'DataSetsLineChart',
  components: {DatasetsNewView, DataSetsViewFilter, DataSetsBarChart, DataExport, ChartElement },
  props: ['view', 'index', 'positions', 'datasets', 'dataSetForChoose', 'shared', 'hide', 'flat',
          'height', 'inModal', 'secondaryIndex', 'viewsMap', 'dashboardDetails'],
  setup () {
    return {
      analyticsFiltersStore: useAnalyticsFiltersStore()
    }
  },
  computed: {
    positionTitle () {
      if(this.dashboardDetails?.view_keys[this.index]?.filter(item=> item).length === 1){
        return this.index + 1
      }
      return this.position
    },
    downlaodFilters () {
      return { ...this.view.view.filters, ...this.analyticsFiltersStore.dashboardDetails.filters }
    },
    position () {
      return `${this.index + 1}${['A', 'B', 'C', 'D'][this.secondaryIndex]}`
    },
  },
  data () {
    return {
      isChangeView: false,
      downloadingLoaded: false,
    }
  },
  methods: {
    filterChanged (value) {
      this.changeView({ view: value, position: this.index })
    },
    downloadData (sep) {
      this.downloadingLoaded = true
      const downloadPayload = { ...this.view.view, filters: this.downlaodFilters, csv: true, csv_separator: sep, csv_decimal: '.' }
      if (sep === 'dutch') {
        downloadPayload.csv_separator = ';'
        downloadPayload.csv_decimal = ','
      }
      downloadAnalytics(downloadPayload)
        .then(({ url }) => {
          this.downloadingLoaded = false
          const link = document.createElement('a')
          link.setAttribute('href', url)
          const today = new Date()
          const dd = today.getDate()
          const mm = today.getMonth() + 1
          const yyyy = today.getFullYear()
          link.setAttribute('download', this.view.view.view_name + '_' + String(dd) + '_' + String(mm) + '_' + String(yyyy) + '.csv')
          link.click()
        })
    },
    changeView (value) {
      this.isChangeView = false
      this.$emit('toggle-view', false)
      this.$emit('change-view', value)
    },
  }
}
</script>

<style scoped>

</style>
