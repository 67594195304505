<template>
  <div id="strategy-collection-kpi-charts">
    <v-row class="tiles d-flex flex-wrap justify-space-around mx-n4 px-3">
      <v-col
        v-for="item in items"
        :key="item.title"
        class="text-center align-center rounded p-relative rounded elevation-2 tile mx-2 my-2"
      >
        {{ item.title }}
        <div
          class="chart-container"
          style="position: relative; height: 150px;"
        >
          <canvas :id="item.title + 'Chart'"></canvas>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-wrap justify-center">
        <div
          v-for="item in chartsLegend"
          :key="item.name"
          class="d-flex align-center mr-2"
        >
          <div
            :key="item.name"
            :style="{
              width: '18px',
              height: '18px',
              borderRadius: '9px',
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: item.borderColor,
              backgroundColor: item.color,
              marginRight: '2px'
            }"></div>
          <span class="kpis-legend-text">{{ item.name }}</span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { Chart } from 'chart.js/auto'
import { chartColors } from '@/variables'
import { formatLargeNumber } from '@/utils'

export default {
  name: 'StrategyCollectionKPICharts',
  components: {},
  props: {
    kpis: {
      type: Object,
      default: () => ({})
    },
  },
  data () {
    this.chartOptions = {
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      hover: {
        mode: 'index',
        intersect: false,
      },
      layout: {
        padding: {
          left: -10,
        },
      },
      scales: {
        x: {
          display: false,
          stacked: true,
        },
        y: {
          position: 'left',
          title: {
            display: true,
            font: {
              size: 14,
            },
          },
          grid: {
            drawTicks: false,
          },
          ticks: {
            callback: val => formatLargeNumber(val, 1),
            padding: 16,
            font: {
              size: 14,
            }
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: tooltipItems => {
              const tooltipItem = tooltipItems[0]

              if (tooltipItem.datasetIndex === 0) {
                if (tooltipItem.dataIndex < this.referenceYearsWithData.length) {
                  return this.referenceYearsWithData[tooltipItem.dataIndex]
                } else {
                  return 'Collection'
                }
              }

              if (tooltipItem.datasetIndex === 1) {
                return 'Budget'
              }
            },
            label: tooltipItem => {
              return this.$options.filters.formatThousands(tooltipItem.raw)
            },
          }
        },
      },
    }
    this.charts = {}
    this.metricsList = [{
      value: 'original_value',
      name: 'Original value',
    }, {
      value: 'cost_value',
      name: 'Cost value',
    }, {
      value: 'margin',
      name: 'Margin%',
    }, {
      value: 'original_price',
      name: 'Original price',
    }, {
      value: 'cost_price',
      name: 'Cost price',
    }, {
      value: 'pieces',
      name: 'Pieces',
    }]

    const year = (new Date()).getFullYear()
    const referenceYearsWithData = []

    for (let i = 3; i >= 0; i--) {
      referenceYearsWithData.push(year - i)
    }

    return {
      referenceYearsWithData,
      items: [],
      budgetItems: [],
    }
  },
  computed: {
    chartsLegend () {
      return [
        ...this.referenceYearsWithData.map((year, i) => ({
          name: year,
          borderColor: chartColors[i],
          color: chartColors[i],
        })),
        {
          name: 'Collection',
          borderColor: '#ccc',
          color: '#eee',
        },
        {
          name: 'Budget',
          borderColor: '#000',
          color: 'transparent',
        },
      ]
    },
  },
  watch: {
    kpis: {
      handler () {
        this.updateItems()
        this.$nextTick(this.drawCharts)
      },
      immediate: true,
    }
  },
  methods: {
    updateItems () {
      if (!this.kpis?.metrics) {
        return []
      }

      const visibleMetrics = [
        {
          value: 'width',
          name: 'Width',
        }, {
          value: 'depth',
          name: 'Depth',
        },
        ...this.metricsList.filter(metric => this.kpis.metrics.includes(metric.value)),
      ]

      this.items = visibleMetrics.map(metric => {
        return {
          title: metric.name,
          data: [
            ...this.referenceYearsWithData.map(year => ({
              periodName: year,
              value: this.kpis.periodsData[`reference_${year}`]?.[metric.value],
            })), {
              periodName: 'Collection',
              value: this.kpis.periodsData['collection']?.[metric.value],
            }
          ],
        }
      })

      this.budgetItems = visibleMetrics.map(metric => {
        return {
          title: metric.name,
          data: [{
            periodName: 'Budget',
            value: this.kpis.periodsData['budget']?.[metric.value],
          }],
        }
      })
    },
    drawCharts () {
      this.items.forEach((metric, mIndex) => {
        if (this.charts[metric.title + 'Chart']) {
          this.charts[metric.title + 'Chart'].destroy()
        }

        const ctx = document.getElementById(metric.title + 'Chart')
        const chartOptions = cloneDeep(this.chartOptions)

        // chartOptions.scales.y.title.text = this.$options.filters.formatString(metricName)

        const borderColors = metric.data.map((period, i) => {
          if (period.periodName === 'Collection') {
            return 'rgba(0, 0, 0, 0.2)'
          } else {
            return chartColors[i]
          }
        })

        const bkgColors = metric.data.map((period, i) => {
          if (period.periodName === 'Collection') {
            return 'rgba(0, 0, 0, 0.2)'
          } else {
            return chartColors[i]
          }
        })

        this.charts[metric.title + 'Chart'] = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: metric.data.map((x, i) => {
              if (i === metric.data.length - 1) {
                return 'Collection / Budget'
              } else {
                return this.items[mIndex].data[i].periodName
              }
            }),
            datasets: [{
              data: metric.data.map(x => x.value || 0),
              borderColor: borderColors,
              borderWidth: 2,
              backgroundColor: bkgColors,
            }, {
              data: metric.data.map((x, i) => {
                if (i === metric.data.length - 1) {
                  return this.budgetItems[mIndex].data[0].value
                } else {
                  return 0
                }
              }),
              borderColor: '#000',
              borderWidth: 2,
              backgroundColor: 'transparent',
            }],
          },
          options: chartOptions,
        })
      })
    },
  },
}
</script>

<style lang="scss">
#strategy-collection-kpi-charts {
  .tiles {
    .tile {
      color: var(--text-sub-black);
      background-color: white;
      display: inline-block;
      width: calc(12.5% - 16px);
      flex-basis: calc(12.5% - 16px);
      canvas {
        margin-left: -24px;
      }
    }
  }

  .kpis-legend-text {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    filter: blur(0.3px);
  }

  @media screen and (max-width: 1300px) {
    .tiles {
      .tile {
        width: calc(25% - 16px);
        flex-basis: calc(25% - 16px);
      }
    }
  }
}
</style>
