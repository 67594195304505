<template>
  <div class="operate-search-results">
    <h6 class="text-subtitle-1 font-weight-medium mb-4 ml-6">3. Selected</h6>
    <v-row>
      <v-col class="text-subtitle-1 font-weight-medium mb-4 ml-6">{{ `${selectedRows.length} line${selectedRows.length !== 1 ? 's' : ''} selected` }}</v-col>
      <v-col class="text-right mr-6">
        <TableSearch class="mx-2" @searchChanged="handleOptionsSearchChanged" />
        <v-icon class="mx-2 action-btn-danger" @click="handleRemoveClick">delete</v-icon>
        <DataExport
          @item-click="downloadResultsData"
        />
      </v-col>
    </v-row>
    <data-tables
      style="width: 100%"
      :key="optionListKey"
      :data="selectedResults"
      @filtered-data="filteredData = $event"
      :page-size="5"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
      :table-props="{ 'row-key': 'option_key' }"
      :filters="optionsTableFilters"
      @row-click="handleRowClick">
      <el-table-column prop="option_key" width="55">
        <template v-slot:header>
          <v-simple-checkbox
            color="primary"
            :value="allRowsSelected"
            dense
            hide-details
            class="ml-3"
            :ripple="false"
            @input="handleSelectAllChange"
          />
        </template>
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="ml-3"
            :value="scope.row.selected"
            :ripple="false"
            @input="handleSelectRowChange($event, scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-for="(col, i) in columns"
        sortable="custom"
        :key="col"
        :prop="col"
        :label="columnsLabels[i]"
        :width="col === 'option' ? '320' : null">
        <template slot-scope="scope">
          <div v-if="col === 'option' && scope.row['option'] !== null">
            <v-menu
              :key="scope.row.option_key"
              right
              bottom
              :open-on-hover="!infoMenuOpened[scope.row.option_key]"
              nudge-bottom="10"
              :nudge-left="200"
              :disabled="!scope.row.option_key"
              v-model="infoMenuOpened[scope.row.option_key]"
              @input="onInfoMenuChange(scope.row.option_key, $event)"
              offset-x
              offset-y
              :open-delay="3000"
              :close-delay="1000"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <OptionDataCell
                    :option="scope.row"
                    :url="optionsRoute ? scope.row.route : null"
                    name-key="name"
                    open-in-new-tab
                  />

                </div>
              </template>
              <info-menu
                @close="infoMenuOpened[scope.row.option_key] = false"
                v-if="infoMenuOpened[scope.row.option_key]"
                :row="scope.row"></info-menu>
            </v-menu>

          </div>
          <div v-else-if="col === 'active'">
            {{ scope.row[col] ? 'Yes' : 'No' }}
          </div>
          <div v-else-if="col === 'price'">
            {{ scope.row.osp | currency }}
            <span v-if="scope.row.osp !== scope.row.asp"> / {{ scope.row.asp | currency }}</span>
          </div>
          <div v-else>{{ scope.row[col] }}</div>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import TableSearch from '@/components/TableSearch.vue'
import OptionDataCell from '@/components/OptionDataCell.vue'
import { downloadData } from '@/utils'
import DataExport from '../DataExport'
import InfoMenu from '@/components/Home/InfoMenu'

import { useStockOperateStore } from '@/store/pinia/stockOperateStore'

export default {
  setup () {
    return {
      operateStore: useStockOperateStore()
    }
  },
  props: {
    tab: {
      type: String,
      default: '',
    },
    optionsRoute: {
      type: String || null,
      default: null,
    }
  },
  data () {
    return {
      infoMenuOpened: {},
      filteredData: [],
      columns: ['option', 'stock', 'dc_stock', 'inbounds', 'price', 'active'],
      columnsLabels: ['Option', 'Stock', 'DC stock', 'Inbound', 'Price', 'Active'],
      optionsTableFilters: [
        {
          value: ''
        }
      ],
      optionListKey: 0,
    }
  },
  components: {
    InfoMenu,
    DataExport,
    TableSearch,
    OptionDataCell,
  },
  computed: {
    selectedResults () {
      const results = this.operateStore.searchResultsSelected.slice()

      results.forEach(item => {
        if (this.optionsRoute && item.route) {
          item.route = item.route.replace('stock/option', this.optionsRoute)
        }
      })

      return results || []
    },
    selectedRows () {
      return this.filteredData.filter(r => r.selected)
    },
    allRowsSelected () {
      return (this.filteredData.length > 0) && (this.selectedRows.length === this.filteredData.length)
    },
  },
  methods: {

    onInfoMenuChange (option_key, value) {
      if (value) {
        this.infoMenuOpened = { [option_key]: value }
      }
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectAllChange (val) {
      this.filteredData.forEach(r => this.$set(r, 'selected', val))
    },
    handleSelectRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
    handleRemoveClick () {
      this.operateStore.removeSearchResultsSelected(this.tab, this.selectedRows)
    },
    handleOptionsSearchChanged (val) {
      this.optionsTableFilters[0].value = val
      this.optionListKey++
    },
    downloadResultsData (sep) {
      const columns = {
        option_key: 'Option',
        name: 'Name',
        stock: 'Stock',
        dc_stock: 'Dc Stock',
        inbounds: 'Inbounds',
        osp: 'Original Price',
        asp: 'Price',
        active: 'Active',
        variation_key: 'Variation Key'
      }
      const data = this.selectedResults.map(row => {
        const formattedRow = {}
        Object.entries(columns).forEach(([key, label]) => {
          formattedRow[label] = row[key]
        })
        return formattedRow
      })
      downloadData(sep, data, 'operate_selected_results')
    },
  }
}
</script>

<style lang="scss">
.operate-selected-results {
}
</style>
