<template>
  <resizable
    id="consolidate-grids-chart"
    @resize="updateTotalsPosition"
  >
    <v-menu
      v-model="menuVisible"
      top
      left
      nudge-left="20"
      nudge-top="20"
      content-class="consolidate-grid-menu"
      :position-x="x"
      :position-y="y"
      :close-on-click="false"
      :close-on-content-click="false"
    >
      <div class="white pa-4">
        <template v-if="menuData">
          <div class="text-center">
            <router-link
              target="_blank"
              :to="getOptionUrl(menuData.option)"
            >
              <img
                :alt="menuData.option.name"
                :src="menuData.option.image_url || '@/assets/img/ts_placeholder.png'"
                height="90px"
                width="60px"
                @error="loadPlaceholder"
              >
            </router-link>
          </div>
          <router-link
            target="_blank"
            class="option-name-link"
            :to="getOptionUrl(menuData.option)"
          >
            <div v-if="menuData.option.name.split(' / ').length === 2" class="pt-2 option-name-wrapper">
              <div class="text-body-2 mb-0 font-weight-medium option-name">{{ menuData.option.name.split(" / ")[0] }}</div>
              <p class="text-body-2 mb-0 text--secondary">{{ menuData.option.name.split(" / ")[1] }}</p>
            </div>
            <div v-else class="pt-2 option-name-wrapper">
              <div class="text-body-2 font-weight-medium option-name">{{ menuData.option.name }}
              </div>
            </div>
          </router-link>
          <div><b>Store</b>: {{ storesMap[menuData.store.store_key] }}</div>
          <div><b>Fairshare</b>: {{ menuData.fairshare }}</div>
          <div><b>Stock</b>: {{ menuData.stock }}</div>
          <div><b>Missing</b>: {{ menuData.required }}</div>
          <div><b>Surplus</b>: {{ menuData.surplus }}</div>
        </template>
      </div>
    </v-menu>

    <v-row class="align-center mt-4">
      <v-col cols="4" class="d-flex">
        <v-select
          v-model="activeSortStores"
          label="Sort stores"
          class="ml-4 sorting-input-wrapper"
          :items="['Key', 'Stock', 'Reached', 'Exclusions']"
          @change="handleStoresSortChanged"
        ></v-select>
        <v-select
          v-model="activeSortOptions"
          label="Sort options"
          class="ml-4 sorting-input-wrapper"
          :items="['Key', 'Stock', 'Reached', 'Exclusions']"
          @change="handleOptionsSortChanged"
        ></v-select>
      </v-col>
      <v-col class="mb-2 text-center">
        <v-btn-toggle
          v-model="currentMode"
          mandatory
          dense
          class="ml-3"
          active-class="bg-gray"
          light
        >
          <v-btn
            v-for="(mode, index) in modes"
            :key="index"
            :value="mode.value"
            small
            color="white"
          >
            <span class="text-capitalize">{{ mode.text }}</span>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="4" class="text-right">
        <DataExport
          @item-click="downloadGridData"
        />
      </v-col>
    </v-row>

    <div
      class="grid-wrapper px-4 p-relative"
      :class="{ large: currentMode === 'large' }"
    >
      <!-- row labels -->
      <div
        ref="rowsLabels"
        class="row-labels"
        :class="{ large: currentMode === 'large' }"
        @scroll="handleRowsLabelsScroll"
      >
        <div
          v-for="(label, index) in rowLabels"
          :key="index"
          class="row-label"
        >
          <div class="option-image">
            <router-link
              target="_blank"
              :to="getOptionUrl({ option_key: label.option_key })"
            >
              <v-img
                :lazy-src="placeholder_image"
                max-height="40"
                max-width="24"
                :src="label.image_url"
              />
            </router-link>
          </div>
          <div
            class="option-titles"
            :class="{
              'red--text': rowsExcluded.includes(getRowIndexAcrossPages(index)),
              'green--text': rowsIncluded.includes(getRowIndexAcrossPages(index)),
            }"
            @click="handleRowLabelClick(index)"
          >
            <div class="option-title">{{ label.nameParts[0] }}</div>
            <div class="option-subtitle">{{ label.nameParts[1] }}</div>
          </div>
        </div>
      </div>

      <!-- col labels -->
      <div
        ref="colsLabels"
        class="col-labels"
        :class="{ large: currentMode === 'large' }"
      >
        <div
          v-for="(store, index) in colsLabels"
          :key="index"
          class="col-label"
          :class="{
            'red--text': colsExcluded.includes(getRowIndexAcrossPages(index)),
            'green--text': colsIncluded.includes(getRowIndexAcrossPages(index)),
          }"
          @click="handleColLabelClick(index)"
        >
          <div class="store-key">{{ store.store_key }}</div>
          <div class="store-name">{{ storesMap[store.store_key].substring(0, 20) }}</div>
        </div>
      </div>

      <!-- rows totals -->
      <div
        ref="rowsTotalsLabels"
        class="rows-totals-labels"
        :class="{ large: currentMode === 'large' }"
      >
        <div class="col-label sum">Stock<br/>(+/-)</div>
        <div class="col-label count">Reached</div>
        <div class="col-label removed">Exclusions</div>
      </div>
      <div
        ref="rowsTotals"
        class="rows-totals"
        :class="{ large: currentMode === 'large' }"
        @scroll="handleRowsTotalsScroll"
      >
        <div
          v-for="(total, index) in rowsTotals"
          :key="index"
        >
          <div class="labels-wrapper">
            <div class="row-label sum">
              <div class="sum-row">
                {{ total.stock }}
              </div>
              <div class="sum-row">
                &nbsp;({{ total.required > 0 ? '+' + total.required : total.required }})
              </div>
            </div>
            <div class="row-label count">
              {{ total.count }}
            </div>
            <div class="row-label removed">
              {{ total.removed }}
            </div>
          </div>
        </div>
      </div>

      <!-- cols totals -->
      <div
        ref="colsTotalsLabels"
        class="cols-totals-labels"
        :class="{ large: currentMode === 'large' }"
      >
        <div class="col-label sum">Stock (+/-)</div>
        <div class="col-label count">Reached</div>
        <div class="col-label removed">Exclusions</div>
      </div>
      <div
        ref="colsTotals"
        class="cols-totals"
        :class="{ large: currentMode === 'large' }"
      >
        <div
          v-for="(store, index) in colsTotals"
          :key="index"
          class="labels-wrapper"
        >
          <div class="col-label sum">
            <div class="sum-row">
              {{ store.stock }}
            </div>
            <div class="sum-row">
              &nbsp;({{ store.required > 0 ? '+' + store.required : store.required }})
            </div>
          </div>
          <div class="col-label count">
            {{ store.count }}
          </div>
          <div class="col-label removed">
            {{ store.removed }}
          </div>
        </div>
      </div>

      <!-- full totals -->
      <div
        ref="fullTotals"
        class="full-totals"
        :class="{ large: currentMode === 'large' }"
      >
        <div class="col-label sum">
          <div class="sum-row">
            {{ fullTotals.stock }}
          </div>
          <div class="sum-row">
            &nbsp;({{ fullTotals.required > 0 ? '+' + fullTotals.required : fullTotals.required }})
          </div>
        </div>
        <div class="col-label count">
          {{ fullTotals.count }}
        </div>
        <div class="col-label removed">
          {{ fullTotals.removed }}
        </div>
      </div>

      <!-- grid -->
      <div
        ref="gridContainer"
        class="grid-container"
        :class="{ large: currentMode === 'large' }"
        @scroll="handleGridContainerScroll"
      >
        <div
          v-for="(row, rIdx) in currentPageData"
          :key="rIdx"
          class="grid-row"
          :class="{ selected: row.option.option_key === selectedOption?.option_key }"
        >
          <div
            v-for="(cell, cIdx) in row.cells"
            :key="cIdx"
            class="p-relative grid-col"
            :class="[{
              selected: cell.store.store_key === selectedStore?.store_key,
              highlighted: markedCells[`${getRowIndexAcrossPages(rIdx)}-${cIdx}`],
            }, `${cell.color}-bkg`]"
            @click.left="handleCellClick(getRowIndexAcrossPages(rIdx), cIdx)"
            @mouseover="e => handleCellMouseover(e, getRowIndexAcrossPages(rIdx), cIdx)"
            @mouseout="e => handleGridMouseout(e)"
          >
            <div class="cell-data">
              {{ cell.stock }}<br/>
              <!-- if cell is green or highlighted with green -->
              <template v-if="(cell.color === 'green' && !markedCells[`${getRowIndexAcrossPages(rIdx)}-${cIdx}`]) || (cell.color === 'red' && markedCells[`${getRowIndexAcrossPages(rIdx)}-${cIdx}`])">
                (<template v-if="(cell.required - cell.surplus) > 0">+</template>{{ cell.required - cell.surplus }})
              </template>

              <!-- if cell is red or highlighted with red -->
              <template v-if="(cell.color === 'red' && !markedCells[`${getRowIndexAcrossPages(rIdx)}-${cIdx}`]) || (cell.color === 'green' && markedCells[`${getRowIndexAcrossPages(rIdx)}-${cIdx}`])">
                ({{ -1 * cell.stock }})
              </template>
            </div>
            <div class="highlighted-marker"></div>
          </div>
        </div>
      </div>
    </div>

    <v-pagination
      v-if="needsPagination"
      class="el-pagination mt-4 mb-2"
      v-model="currentPage"
      :length="pagesNumber"
      :total-visible="7"
    ></v-pagination>

    <div class="text-right mx-4">
      <div class="d-inline-block" style="width: 250px;">
        <v-autocomplete
          :items="appConfig?.stores_open || []"
          item-text="store_name"
          item-value="store_key"
          label="Transfer remaining"
          clearable
          hide-details
          :menu-props="{
            left: true,
          }"
          @change="val => $emit('transferRemaining:changed', val)"
        />
      </div>
    </div>
    <v-row class="mb-0">
      <v-col class="d-flex justify-end pt-8 pb-4">
        <div class="text-right">
          <v-btn
            color="primary"
            depressed
            outlined
            :disabled="exclusionsBtnDisabled"
            @click="handleInsertExclusionsClick"
          >
            Insert exclusions
          </v-btn>
          <div
            v-if="!exclusionsBtnDisabled && showExclusionsWarning"
            class="mt-1 text-right red--text"
          >Exclusions are not yet inserted</div>
        </div>
        <div class="mx-4 text-right">
          <v-btn color="primary" depressed outlined @click="handleTransfersClick">
            <template v-if="!transfersClicked">
              Build transfer
            </template>
            <template v-else>
              Refresh table
            </template>
          </v-btn>
          <div
            v-if="transfersClicked && showGridWarning"
            class="mt-1 text-right red--text"
          >Changes in grid</div>
        </div>
      </v-col>
    </v-row>
  </resizable>
</template>

<script>
import { cloneDeep, orderBy } from 'lodash'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { downloadData } from '@/utils'
import ts_placeholder from '@/assets/img/ts_placeholder.png'
import placeholder_image from '@/assets/img/placeholder-image.png'
import Resizable from '@/components/Utility/Resizable.vue'
import DataExport from '@/components/DataExport'

export default {
  name: 'ConsolidateGridsChart',
  components: { Resizable, DataExport },
  props: {
    consolidateData: {
      type: Object || null,
      default: null,
    },
    selectedOption: {
      type: Object || null,
      default: null,
    },
    selectedStore: {
      type: Object || null,
      default: null,
    },
  },
  data () {
    return {
      placeholder_image: placeholder_image,
      activeSortStores: 'Reached',
      activeSortOptions: 'Reached',
      currentMode: 'small',
      modes: [{
        value: 'small',
        text: 'Small',
      }, {
        value: 'large',
        text: 'Large',
      }],
      currentPage: 1,
      menuVisible: false,
      menuData: null,
      x: 0,
      y: 0,
      markedCells: {},
      colsExcluded: [],
      rowsExcluded: [],
      colsIncluded: [],
      rowsIncluded: [],
      gridDataSorted: [],
      transfersClicked: false,
      showExclusionsWarning: false,
      showGridWarning: false,
      exclusionsBtnDisabled: false,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    storesMap () {
      return (this.appConfig?.stores || []).reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    needsPagination () {
      return this.gridData.length > 150
    },
    gridData () {
      if (!this.consolidateData) {
        return []
      }

      const rows = []

      for (let oIdx = 0; oIdx < this.consolidateData.grids.inclusions[0]?.length; oIdx++) {
        const row = []

        this.consolidateData.grids.inclusions.forEach((store, sIdx) => {
          const cell = this.consolidateData.grids.inclusions[sIdx][oIdx]

          row.push({
            inclusions: cell,
            fairshare: this.consolidateData.grids.fairshare[sIdx][oIdx],
            required: this.consolidateData.grids.required[sIdx][oIdx],
            stock: this.consolidateData.grids.stock[sIdx][oIdx],
            surplus: this.consolidateData.grids.surplus[sIdx][oIdx],
            color: this.getCellColor(cell),
            store: this.consolidateData.stores.data[sIdx],
          })
        })

        rows.push({
          index: oIdx,
          option: this.consolidateData.options.data[oIdx],
          cells: row,
        })
      }

      return rows
    },
    pageSize () {
      if (!this.needsPagination) {
        return this.gridData.length
      }

      return Math.floor(window.innerHeight * 0.7 / (this.currentMode === 'large' ? 40 : 20))
    },
    pagesNumber () {
      return Math.ceil(this.gridData.length / this.pageSize)
    },
    currentPageData () {
      if (!this.needsPagination) {
        return this.gridDataSorted.slice()
      }

      return this.gridDataSorted.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
    rowLabels () {
      return this.currentPageData.map(row => {
        const nameParts = row.option.name ? row.option.name.split(' / ') : []

        return {
          nameParts,
          image_url: row.option.image_url,
          option_key: row.option.option_key,
        }
      })
    },
    rowsTotals () {
      return this.currentPageData.map((row, rIndex) => {
        const rowIndex = this.getRowIndexAcrossPages(rIndex)
        const rowTotals = {
          stock: 0,
          required: 0,
          count: 0,
          removed: 0,
        }

        row.cells.forEach((cell, colIdx) => {
          const isHighlighted = this.markedCells[`${rowIndex}-${colIdx}`]

          rowTotals.stock += cell.stock

          if ((cell.color === 'green' && !isHighlighted) || (cell.color === 'red' && isHighlighted)) {
            rowTotals.count += 1
          }

          // if cell is green or highlighted with green
          if ((cell.color === 'green' && !isHighlighted) || (cell.color === 'red' && isHighlighted)) {
            rowTotals.required += cell.required
          }

          // if cell is red or highlighted with red
          if ((cell.color === 'red' && !isHighlighted) || (cell.color === 'green' && isHighlighted)) {
            rowTotals.required += -1 * cell.stock
            rowTotals.removed += 1
          }
        })

        return rowTotals
      })
    },
    colsLabels () {
      return this.currentPageData[0]?.cells.map(cell => cell.store)
    },
    colsTotals () {
      return this.gridDataSorted[0]?.cells.map((col, colIdx) => {
        const colTotals = {
          stock: 0,
          required: 0,
          count: 0,
          removed: 0,
        }

        this.gridDataSorted.forEach((row, rowIndex) => {
          const cell = row.cells[colIdx]
          const isHighlighted = this.markedCells[`${rowIndex}-${colIdx}`]

          colTotals.stock += cell.stock

          if ((cell.color === 'green' && !isHighlighted) || (cell.color === 'red' && isHighlighted)) {
            colTotals.count += 1
          }

          // if cell is green or highlighted with green
          if ((cell.color === 'green' && !isHighlighted) || (cell.color === 'red' && isHighlighted)) {
            colTotals.required += cell.required
          }

          // if cell is red or highlighted with red
          if ((cell.color === 'red' && !isHighlighted) || (cell.color === 'green' && isHighlighted)) {
            colTotals.required += -1 * cell.stock
            colTotals.removed += 1
          }
        })

        return colTotals
      })
    },
    fullTotals () {
      return this.colsTotals.reduce((acc, cur) => ({
        stock: acc.stock + cur.stock,
        required: acc.required + cur.required,
        count: acc.count + cur.count,
        removed: acc.removed + cur.removed,
      }), {
        stock: 0,
        required: 0,
        count: 0,
        removed: 0,
      })
    },
    userExclusions () {
      return this.gridDataSorted.reduce((acc, cur, rowIndex) => {
        const gridDataMarkedCells = cur.cells
          .map(cell => ({
            color: cell.color,
            store_key: cell.store.store_key,
          }))
          .filter(({ color }, colIdx) => color === 'green' && this.markedCells[`${rowIndex}-${colIdx}`])
          .map(({ store_key }) => store_key)

        return {
          ...acc,
          [cur.option.option_key]: gridDataMarkedCells
        }
      }, {})
    },
    userInclusions () {
      return this.gridDataSorted.reduce((acc, cur, rowIndex) => {
        const gridDataMarkedCells = cur.cells
          .map(cell => ({
            color: cell.color,
            store_key: cell.store.store_key,
          }))
          .filter(({ color }, colIdx) => color === 'red' && this.markedCells[`${rowIndex}-${colIdx}`])
          .map(({ store_key }) => store_key)

        return {
          ...acc,
          [cur.option.option_key]: gridDataMarkedCells
        }
      }, {})
    },
  },
  watch: {
    gridData: {
      handler () {
        this.updateTotalsPosition()
        this.currentPage = 1
        this.markedCells = {}
        this.gridDataSorted = cloneDeep(this.gridData)
        this.updateSortedGridData(true, true)
      },
      immediate: true,
    },
    markedCells (val) {
      if (Object.keys(val).length) {
        this.showGridWarning = true
      }
    },
    currentMode () {
      this.updateTotalsPosition()
      this.currentPage = 1
    },
    userExclusions () {
      this.$emit('userExclusions:updated', this.userExclusions)
    },
    userInclusions () {
      this.$emit('userInclusions:updated', this.userInclusions)
    },
  },
  methods: {
    getCellColor (val) {
      let color = 'white'

      if (val === 2) {
        color = 'orange'
      }

      if (val === 1) {
        color = 'green'
      }

      if (val === -1) {
        color = 'red'
      }

      return color
    },
    getOptionUrl (option) {
      return {
        name: 'stock-option',
        query: {
          'option_key': option.option_key
        }
      }
    },
    getRowIndexAcrossPages (rIdx) {
      return (this.currentPage - 1) * this.pageSize + rIdx
    },
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    updateTotalsPosition () {
      this.$nextTick(() => {
        const left = 162 + this.$refs.gridContainer.offsetWidth + 16 + 'px'

        this.$refs.rowsTotalsLabels.style.left = left
        this.$refs.rowsTotals.style.left = left
        this.$refs.fullTotals.style.left = left
      })
    },
    updateSortedGridData (sortRows = true, sortCols = true) {
      let newSortedData = []
      let changedRowsIndexes = null
      let changedColsIndexes = null
      const optionsKeysMap = {
        'Key': 'name',
        'Stock': 'stock',
        'Reached': 'stores_reached',
        'Exclusions': 'stores_removed',
      }
      const storesKeysMap = {
        'Key': 'store_key',
        'Stock': 'stock',
        'Reached': 'options_reached',
        'Exclusions': 'options_removed',
      }

      if (sortRows) {
        changedRowsIndexes = {}
        newSortedData = orderBy(this.gridDataSorted, [
          (row) => {
            const option = this.consolidateData.options.data.find(option => option.option_key === row.option.option_key)

            // when sorting by Stock, just return default order from BE
            if (this.activeSortOptions === 'Stock') {
              return this.consolidateData.options.data.indexOf(option)
            }

            if (this.activeSortOptions === 'Exclusions') {
              return option.stores_removed.length
            }

            // for now only if activeSortOptions === 'Reached' we come to this line, cause 'Stock' and 'Removed' have special logic above
            return option[optionsKeysMap[this.activeSortOptions]]
          }
        ], [this.activeSortOptions === 'Key' ? 'asc' : 'desc'])

        this.gridDataSorted.forEach((row1, rIdx) => {
          changedRowsIndexes[rIdx] = newSortedData.findIndex(row2 => row2.option.option_key === row1.option.option_key)
        })

        this.gridDataSorted = cloneDeep(newSortedData)
      }

      if (sortCols) {
        changedColsIndexes = {}
        const storesSorted = orderBy(this.consolidateData.stores.data, [
          store => {
            if (this.activeSortStores === 'Key') {
              return this.consolidateData.stores.data.indexOf(store)
            }

            if (this.activeSortStores === 'Exclusions') {
              return store.options_removed.length
            }

            // for now only if activeSortStores === 'Stock' or 'Reached' we come to this line, cause 'Key' and 'Removed' have special logic above
            return store[storesKeysMap[this.activeSortStores]]
          }
        ], [this.activeSortStores === 'Key' ? 'asc' : 'desc'])

        this.gridDataSorted[0]?.cells.forEach((cell, index) => {
          changedColsIndexes[index] = storesSorted.findIndex(ss => ss.store_key === cell.store.store_key)
        })

        newSortedData = this.gridDataSorted.map(row => {
          const newCells = []

          row.cells.forEach((cell, cIdx) => {
            newCells[changedColsIndexes[cIdx]] = cell
          })

          return {
            ...row,
            cells: newCells,
          }
        })

        this.gridDataSorted = cloneDeep(newSortedData)
      }

      return {
        rows: changedRowsIndexes,
        cols: changedColsIndexes,
      }
    },
    updateMarkedCells (changedIndexes) {
      const newMarkedCells = {}

      for (const key in this.markedCells) {
        const [rowIndex, colIndex] = key.split('-')
        const newRowIndex = changedIndexes.rows?.[rowIndex]
        const newColIndex = changedIndexes.cols?.[colIndex]
        let newKey = key

        if (newRowIndex !== undefined) {
          newKey = `${newRowIndex}-${colIndex}`
        }

        if (newColIndex !== undefined) {
          newKey = `${rowIndex}-${newColIndex}`
        }

        newMarkedCells[newKey] = true
      }

      this.$set(this, 'markedCells', newMarkedCells)
    },
    handleStoresSortChanged () {
      const changedIndexes = this.updateSortedGridData(false, true)
      this.updateMarkedCells(changedIndexes)
    },
    handleOptionsSortChanged () {
      const changedIndexes = this.updateSortedGridData(true, false)
      this.updateMarkedCells(changedIndexes)
    },
    handleCellMouseover (event, rowIndex, colIndex) {
      this.menuData = {
        fairshare: this.gridDataSorted[rowIndex].cells[colIndex].fairshare,
        required: this.gridDataSorted[rowIndex].cells[colIndex].required,
        stock: this.gridDataSorted[rowIndex].cells[colIndex].stock,
        surplus: this.gridDataSorted[rowIndex].cells[colIndex].surplus,
        option: this.gridDataSorted[rowIndex].option,
        store: this.gridDataSorted[rowIndex].cells[colIndex].store,
        isExcluded: this.gridDataSorted[rowIndex].cells[colIndex].color === 'red'
      }
      this.showMenu(event)
    },
    handleCellClick (rowIndex, colIndex) {
      const color = this.gridDataSorted[rowIndex].cells[colIndex].color

      if (color === 'white') {
        return
      }

      const key = `${rowIndex}-${colIndex}`

      if (this.markedCells[key]) {
        this.$delete(this.markedCells, key)
      } else {
        this.$set(this.markedCells, key, true)
      }
    },
    handleGridMouseout (event) {
      if (!event.relatedTarget?.closest('.v-menu__content')) {
        this.hideMenu()
      }
    },
    toggleColumnCells (colIndex, color, val) {
      this.gridDataSorted.forEach((row, rowIndex) => {
        const cellColor = this.gridDataSorted[rowIndex].cells[colIndex].color
        const key = `${rowIndex}-${colIndex}`

        if (cellColor === color) {
          if (this.markedCells[key] && !val) {
            this.$delete(this.markedCells, key)
          }

          if (!this.markedCells[key] && val) {
            this.$set(this.markedCells, key, true)
          }
        }
      })
    },
    toggleRowCells (rowIndex, color, val) {
      this.gridDataSorted[rowIndex].cells.forEach((cell, colIndex) => {
        const key = `${rowIndex}-${colIndex}`

        if (cell.color === color) {
          if (this.markedCells[key] && !val) {
            this.$delete(this.markedCells, key)
          }

          if (!this.markedCells[key] && val) {
            this.$set(this.markedCells, key, true)
          }
        }
      })
    },
    showMenu (event) {
      this.x = event.clientX / document.documentElement.style.getPropertyValue('--zoom')
      this.y = event.clientY / document.documentElement.style.getPropertyValue('--zoom')
      this.menuVisible = true
    },
    hideMenu () {
      this.menuVisible = false
      this.menuData = null
    },
    downloadGridData (sep) {
      const data = this.gridDataSorted.map(row => {
        const rowData = {
          option: row.option.name,
        }

        row.cells.forEach((cell, i) => {
          const storeName = this.storesMap[this.colsLabels[i].store_key]

          rowData[storeName] = cell.stock
        })

        return rowData
      })

      downloadData(sep, data, 'consolidate_grid')
    },
    handleGridContainerScroll () {
      this.$refs.rowsLabels.scrollTop = this.$refs.gridContainer.scrollTop
      this.$refs.rowsTotals.scrollTop = this.$refs.gridContainer.scrollTop
      this.$refs.colsLabels.scrollLeft = this.$refs.gridContainer.scrollLeft
      this.$refs.colsTotals.scrollLeft = this.$refs.gridContainer.scrollLeft
    },
    handleRowsLabelsScroll () {
      this.$refs.gridContainer.scrollTop = this.$refs.rowsLabels.scrollTop
      this.$refs.rowsTotals.scrollTop = this.$refs.rowsLabels.scrollTop
    },
    handleRowsTotalsScroll () {
      this.$refs.gridContainer.scrollTop = this.$refs.rowsTotals.scrollTop
      this.$refs.rowsLabels.scrollTop = this.$refs.rowsTotals.scrollTop
    },
    handleInsertExclusionsClick () {
      this.$emit('insert-exclusions-click')
    },
    handleTransfersClick () {
      this.transfersClicked = true
      this.showExclusionsWarning = false
      this.showGridWarning = false
      this.$emit('build-transfer-click')
    },
    handleRowLabelClick (rowIndex) {
      rowIndex = this.getRowIndexAcrossPages(rowIndex)
      const excludedIndex = this.rowsExcluded.indexOf(rowIndex)
      const includedIndex = this.rowsIncluded.indexOf(rowIndex)

      if (excludedIndex === -1 && includedIndex === -1) {
        // if row is not marked as excluded and as included, mark it's green cells
        this.rowsExcluded.push(rowIndex)
        this.toggleRowCells(rowIndex, 'green', true)
      } else if (includedIndex === -1) {
        // if row is marked as excluded, reset it's green cells...
        this.rowsExcluded.splice(excludedIndex, 1)
        this.toggleRowCells(rowIndex, 'green', false)

        // ...and mark red cells
        this.rowsIncluded.push(rowIndex)
        this.toggleRowCells(rowIndex, 'red', true)
      } else {
        // if row is marked as included, reset it's cells to default
        this.rowsIncluded.splice(includedIndex, 1)
        this.toggleRowCells(rowIndex, 'red', false)
      }
    },
    handleColLabelClick (colIndex) {
      const excludedIndex = this.colsExcluded.indexOf(colIndex)
      const includedIndex = this.colsIncluded.indexOf(colIndex)

      if (excludedIndex === -1 && includedIndex === -1) {
        // if column is not marked as excluded and as included, mark it's green cells
        this.colsExcluded.push(colIndex)
        this.toggleColumnCells(colIndex, 'green', true)
      } else if (includedIndex === -1) {
        // if column is marked as excluded, reset it's green cells...
        this.colsExcluded.splice(excludedIndex, 1)
        this.toggleColumnCells(colIndex, 'green', false)

        // ...and mark red cells
        this.colsIncluded.push(colIndex)
        this.toggleColumnCells(colIndex, 'red', true)
      } else {
        // if column is marked as included, reset it's cells to default
        this.colsIncluded.splice(includedIndex, 1)
        this.toggleColumnCells(colIndex, 'red', false)
      }
    },
  },
}
</script>

<style lang="scss">
#consolidate-grids-chart {
  .sorting-input-wrapper {
    max-width: 300px;
  }
  .grid-wrapper {
    padding-top: 72px;
    padding-bottom: 160px;
    &.large {
      padding-top: 152px;
      padding-bottom: 120px;
    }
  }
  .row-labels {
    position: absolute;
    top: 72px; // same as .col-labels height + padding
    bottom: 0;
    overflow: scroll;
    z-index: 2;
    display: inline-block;
    width: 150px;
    max-height: 70vh;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .row-label {
      font-size: 12px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      height: 20px;
      .option-image {
        flex-basis: 24px;
        margin-right: 12px;
      }
      .option-image {
        display: none;
      }
      .option-titles {
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        .option-subtitle {
          display: none;
        }
      }
    }
    &.large {
      top: 152px; // same as .col-labels height + padding
      .row-label {
        height: 40px;
        .option-image {
          display: block;
        }
        .option-subtitle {
          display: block;
        }
      }
    }
  }
  .col-labels {
    position: absolute;
    z-index: 2;
    left: 178px;
    right: 200px; // same as .rows-totals + padding
    top: 0;
    overflow: hidden;
    display: flex;
    height: 62px;
    box-sizing: content-box;
    padding-bottom: 12px;
    .col-label {
      font-size: 12px;
      min-width: 20px;
      writing-mode: vertical-rl;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      .store-name {
        display: none;
      }
    }
    &.large {
      height: 140px;
      .col-label {
        min-width: 40px;
        .store-key {
          display: none;
        }
        .store-name {
          display: block;
        }
      }
    }
  }
  .rows-totals-labels {
    position: absolute;
    z-index: 2;
    top: 0;
    overflow: hidden;
    display: flex;
    height: 62px;
    box-sizing: content-box;
    padding-bottom: 12px;
    .col-label {
      font-size: 12px;
      writing-mode: vertical-rl;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: center;
      &.sum {
        width: 80px;
      }
      &.count,
      &.removed {
        width: 40px;
      }
    }
    &.large {
      height: 140px;
    }
  }
  .rows-totals {
    position: absolute;
    top: 72px; // same as .col-labels height + padding
    bottom: 0;
    overflow: scroll;
    z-index: 2;
    display: inline-block;
    width: 160px;
    max-height: 70vh;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .labels-wrapper {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      .row-label {
        font-size: 12px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        height: 20px;
        &.sum {
          width: 80px;
          display: flex;
          justify-content: center;
          .sum-row {
            display: inline;
          }
        }
        &.count,
        &.removed {
          display: flex;
          justify-content: center;
          width: 40px;
        }
      }
    }
    &.large {
      top: 152px; // same as .col-labels height + padding
      .row-label {
        height: 40px;
      }
    }
  }
  .cols-totals-labels {
    position: absolute;
    bottom: 0;
    overflow: scroll;
    z-index: 2;
    display: inline-block;
    width: 150px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .col-label {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      height: 20px;
      &.sum {
        height: 80px;
      }
      &.count,
      &.removed {
        height: 40px;
      }
    }
    &.large {
      .col-label {
        &.sum {
          height: 40px;
        }
      }
    }
  }
  .cols-totals {
    position: absolute;
    z-index: 2;
    left: 178px;
    right: 196px; // same as .rows-totals + padding
    bottom: 0;
    overflow: hidden;
    display: flex;
    height: 160px;
    box-sizing: content-box;
    .labels-wrapper {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      writing-mode: vertical-rl;
      .col-label {
        font-size: 12px;
        min-width: 20px;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &.sum {
          display: flex;
          justify-content: center;
          flex-wrap: nowrap;
          min-height: 80px;
        }
        &.count,
        &.removed {
          display: flex;
          justify-content: center;
          min-height: 40px;
        }
      }
    }
    &.large {
      height: 120px;
      .labels-wrapper {
        .col-label {
          width: 40px;
          writing-mode: horizontal-tb;
          &.sum {
            flex-wrap: wrap;
            min-height: 40px;
            height: 40px;
            .sum-row {
              flex-basis: 100%;
              text-align: center;
            }
          }
          &.count,
          &.removed {
            writing-mode: horizontal-tb;
          }
        }
      }
    }
  }
  .full-totals {
    position: absolute;
    z-index: 2;
    bottom: 0;
    height: 160px;
    width: 160px;
    .col-label {
      font-size: 12px;
      &.sum {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        .sum-row {
          width: 100%;
          text-align: center;
        }
      }
      &.count,
      &.removed {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
      }
      &.count {
        margin-left: 80px;
      }
      &.removed {
        margin-left: 120px;
      }
    }
    &.large {
      height: 120px;
      .col-label {
        &.sum {
          height: 40px;
        }
      }
    }
  }
  .grid-container {
    display: inline-block;
    // - left margin, - rows totals width + padding
    max-width: calc(100% - 162px - 160px - 16px);
    max-height: calc(70vh + 8px);
    margin-left: 162px; // same as .row-labels width + padding
    margin-right: 160px;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 4px;
    }
    .grid-row {
      display: flex;
      .grid-col {
        min-width: 20px;
        min-height: 20px;
        outline: 1px solid #DDD;
        .highlighted-marker {
          position: absolute;
          z-index: 2;
          display: none;
          left: 0;
          right: 0.5px;
          bottom: 1px;
          height: 50%;
        }
        .cell-data {
          position: absolute;
          z-index: 3;
          display: none;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          font-size: 12px;
          align-items: center;
          justify-content: center;
          text-align: center;
          cursor: default;
        }
        &.green-bkg {
          background-color: #4CAF50;
          .highlighted-marker {
            background-color: #FF675B;
          }
        }
        &.red-bkg {
          background-color: #FF675B;
          .highlighted-marker {
            background-color: #4CAF50;
          }
        }
        &.orange-bkg {
          background-color: #eb922b;
        }
        &.white-bkg {
          background-color: #FFF;
        }
        &:hover,
        &.selected {
          &.green-bkg {
            background-color: #4CAF50B3;
            .highlighted-marker {
              background-color: #FF675BB3;
            }
          }
          &.red-bkg {
            background-color: #FF675BB3;
            .highlighted-marker {
              background-color: #4CAF50B3;
            }
          }
          &.white-bkg {
            background-color: #EEE;
          }
        }
        &.selected {
          border-left: 2px solid #999;
          border-right: 3px solid #999;
        }
        &.highlighted {
          .highlighted-marker {
            display: block;
          }
        }
      }
      &.selected {
        .grid-col {
          border-top: 2px solid #999;
          border-bottom: 3px solid #999;
          &.green-bkg {
            background-color: #4CAF50B3;
          }
          &.red-bkg {
            background-color: #FF675BB3;
          }
          &.white-bkg {
            background-color: #EEE;
          }
        }
      }
    }
    &.large {
      .grid-row {
        .grid-col {
          min-width: 40px;
          min-height: 40px;
          .highlighted-marker {
            bottom: 0.5px;
          }
          .cell-data {
            display: flex;
          }
        }
      }
    }
  }
  .v-pagination__navigation,
  .v-pagination__item {
    box-shadow: none;
  }
  .v-pagination__more {
    text-align: center;
  }
  .v-pagination > li {
    button {
      margin: 0;
      padding: 0 12px;
    }
    &:last-child {
      button {
        padding-left: 0;
      }
    }
  }
  .v-pagination__item.v-pagination__item--active.primary {
    background: transparent !important;
    border: 0;
    color: var(--primary);
  }
}
</style>
