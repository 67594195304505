<template>
  <div>
    <v-card class="pa-4 pt-2 fill-height overflow-auto" :flat="flat || inModal" v-show="!isChangeView">
      <div class="d-flex align-center ma-0 justify-space-between pb-2" v-if="index !== null">
        <h4 class="text-no-wrap">{{positionTitle}}. {{ view.view.view_name }}</h4>
        <div class="d-flex show-on-hover flex-nowrap pt-1" v-if="!view.view?.errored && !view.errored" >
          <v-icon class="show-on-hover action-btn-primary " @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" v-if="!inModal">edit</v-icon>
          <v-overlay absolute color="white" :value="downloadingLoaded">
            <v-progress-circular color="primary" indeterminate size="64" width="2"/>
          </v-overlay>
          <DataExport @item-click="downloadData"/>
          <v-icon class="mr-1 action-btn-primary" @click="$emit('full-screen', view, index)" v-if="!inModal">fullscreen</v-icon>
          <data-sets-view-filter :view="view" @change="filterChanged" :index="index"></data-sets-view-filter>
        </div>

        <template v-else-if="view.view.errored || view.errored" >
          <div class="d-flex show-on-hover flex-nowrap">
            <v-icon class="show-on-hover  action-btn-primary" @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" v-if="!inModal">edit</v-icon>
          </div>
        </template>
      </div>
      <div class="p-relative" style="height: 90%"
        v-if="(!view.view?.errored && !view.errored) && !(view.view?.errored || view.errored)">
        <div class="chart-container"
          style="position: relative; padding-left: 0; padding-right: 0; width: 100%"
          :style="{ height: height ? height + 'px' : '430px' }">
          <canvas ref="chartCanvas" :id="`scatter-chart-canvas.${position}`" class=""></canvas>
        </div>

        <v-tooltip top :max-width="250" v-if="view.view?.properties?.color_bar">
          <template v-slot:activator="{ on, attrs }">
            <v-row class="ma-0 pb-2 pt-4 justify-center align-center c-pointer"  v-bind="attrs" v-on="on" >
              Color Grading: {{ view?.view?.properties?.axis?.color_grading }}

              <div class="d-flex align-center">
                <div style="background-color: #085D70;
                      width: 32px;
                      height: 13px;
                      border-radius: 2px;"
                  class="ml-9 mr-2">
                </div>
                Min
              </div>
              <div class="d-flex align-center" v-bind="attrs" v-on="on" >
                <div style="background-color: #FFAB00;
                      width: 32px;
                      height: 13px;
                      border-radius: 2px;"
                  class="ml-9 mr-2">
                </div>
                Max
              </div>

            </v-row>

          </template>
          <div>
            <div>{{ view.view?.properties.axis.color_grading }} Max: {{max}}</div>
            <div>{{ view.view?.properties.axis.color_grading }} Min: {{min}}</div>
          </div>
        </v-tooltip>
        <v-overlay absolute color="white" :value="(!dataLoaded || !view.data || !view.data.length)">
          <v-progress-circular color="primary" indeterminate size="64" width="2"/>
        </v-overlay>
      </div>
      <h4 v-else-if="view.view?.errored || view.errored" class="row justify-center pb-8">Could not retrieve data</h4>
    </v-card>
    <datasets-new-view
      v-if="isChangeView"
      :dashboard-details="dashboardDetails"
      :viewsMap="viewsMap"
      @saved-positions="$emit('saved-positions', $event)"
      :changeView="{...view.view, position}"
      :positions="positions"
      :datasets="datasets" :dataSetForChoose="dataSetForChoose"
      @cancel="isChangeView = false; $emit('toggle-view', false)"
      @delete-view="$emit('delete-view',$event)"
      @save="changeView"/>
  </div>
</template>

<script>
import { Chart } from 'chart.js/auto'
import { formatLargeNumber } from '@/utils'
import { colormapCover, formatString } from '@/variables'
import _ from 'lodash'
import DataExport from '../DataExport'
import DataSetsViewFilter from '@/components/AutomateDatasets/DataSetsViewFilter'
import { downloadAnalytics } from '@/api/analytics'

// import axios from '@/services/axios.js'
import regression from 'regression'
import { useAnalyticsFiltersStore } from '@/store/pinia/analyticsFiltersStore'

const DatasetsNewView = () => import('@/components/AutomateDatasets/DatasetsNewView')

// Define the linear regression function
function linearRegression (data) {
  const result = regression.linear(data.map((d) => [d.x, d.y]))
  const gradient = result.equation[0]
  const yIntercept = result.equation[1]
  return data.map((d) => ({ x: d.x, y: gradient * d.x + yIntercept }))
}

export default {
  name: 'DataSetsScatterChart',
  components: { DataSetsViewFilter, DataExport, DatasetsNewView },
  props: ['view', 'index', 'positions', 'datasets', 'dataSetForChoose', 'shared', 'flat',
          'height', 'inModal', 'secondaryIndex', 'viewsMap', 'dashboardDetails'],
  setup () {
    return {
      analyticsFiltersStore: useAnalyticsFiltersStore()
    }
  },
  computed: {
    downlaodFilters () {
      return { ...this.view.view.filters, ...this.analyticsFiltersStore.dashboardDetails.filters }
    },
    positionTitle () {
      if(this.dashboardDetails?.view_keys[this.index]?.filter(item=> item).length === 1){
        return this.index + 1
      }
      return this.position
    },
    position () {
      return `${this.index + 1}${['A', 'B', 'C', 'D'][this.secondaryIndex]}`
    },
    linearRegressionData () {
      return linearRegression(this.view.data.map(p => ({
        x: p[this.view.view.properties.axis.x],
        y: p[this.view.view.properties.axis.y1],
        grade: this.view.view.properties.axis.color_grading ? p[this.view.view.properties.axis.color_grading] : 0,
        label: '',
        r: 5
      })))
    }
  },
  data () {
    this.chart = null

    return {
      dataLoaded: false,
      downloadingLoaded: false,
      min: null,
      max: null,
      isChangeView: false
    }
  },
  watch: {
    'view.view': {
      handler (value) {
        if (!value || !Object.keys(value)) {
          return
        }
        setTimeout(() => {
          this.updateMinMax()
          this.plotGraph()
        }, 400)
      },
      immediate: true,
    }
  },
  methods: {
    filterChanged (value) {
      this.changeView({ view: value, position: this.index })
    },
    plotGraph () {
      if ((this.view.view.errored && !this.view.errored && !this.view.data && !this.view.data.length) || !this.view.data) {
        this.dataLoaded = true
        return
      }
      if(this.chart){
        this.chart.destroy()
      }
      const canvas = document.getElementById(`scatter-chart-canvas.${this.position}`)
      if (!canvas) {
        console.error('Canvas not found in scatter chart')
        return
      }
      const pointBackgroundColors = []
      const y1Min = this.view.view.properties.axis.y1_min
      const y1Max = this.view.view.properties.axis.y1_max
      const min = this.view.view.measures[3]?.min != null ? this.view.view.measures[3]?.min : this.view.data.reduce(
        (cur, prev) => cur < prev[this.view.view.measures[3]?.name] ? cur : prev[this.view.view.measures[3]?.name],
        Infinity)
      const max = this.view.view.measures[3]?.max != null ? this.view.view.measures[3]?.max : this.view.data.reduce(
        (cur, prev) => cur > prev[this.view.view.measures[3]?.name] ? cur : prev[this.view.view.measures[3]?.name],
        Number.MIN_VALUE)
      const datasets = [
        {
          data: this.view.data.map(p => {
            const radiusProp = this.view.view.measures[3]?.name
            let result = 30 * (p[radiusProp] - min) / (max - min)
            const minRadius = 5
            const maxRadius = 30
            if (result === Infinity || !result || !p[radiusProp] || result < 0) {
              result = 0
            }
            let r = radiusProp ? 2 * result : 5
            if (r < minRadius) {
              r = minRadius
            }
            if (r > maxRadius) {
              r = maxRadius
            }
            return {
              x: p[this.view.view.properties.axis.x],
              y: p[this.view.view.properties.axis.y1],
              grade: this.view.view.properties.axis.color_grading ? p[this.view.view.properties.axis.color_grading] : 0,
              label: '',
              r,
            }
          }),
          backgroundColor: pointBackgroundColors,
        }
      ]
      if (this.view.view.properties.regression_line) {
        datasets.push({
          type: 'line',
          label: 'Linear Regression Line',
          radius: 0,
          pointRadius: 0,
          borderDash: [5, 5],
          data: this.linearRegressionData,
          fill: false
        }
        )
      }
      const chartData = {
        type: 'bubble',
        data: {
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          hover: {
            mode: 'null',
            onHover: (e) => {
              const point = this.chart.getElementAtEvent(e)
              if (point.length) {
                e.target.style.cursor = 'pointer'
              } else {
                e.target.style.cursor = 'default'
              }
            }
          },
          scales: {
            x: {
              title: {
                display: true,
                text: this.$options.filters.formatString(this.view.view.properties.axis.x),
                font: {
                  size: 14
                }
              },
              min: this.view.view.properties.axis.x_min,
              max: this.view.view.properties.axis.x_max,
              ticks: {
                callback: (value)=> formatLargeNumber(value),
              },
              offset: true,
            },
            y: {
              id: 'Y',
              title: {
                display: true,
                text: this.$options.filters.formatString(this.view.view.properties.axis.y1),
                font: {
                  size: 14
                },
              },

              min: typeof y1Min === 'number' ? y1Min : undefined,
              max: typeof y1Max === 'number' ? y1Max : undefined,
              ticks: {
                suggestedMax: undefined,
                callback: (value)=> formatLargeNumber(value),
              },
              border: {
                display: false,
              },
            }
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              footerFontStyle: 'normal',
              footerFontSize: 11,
              callbacks: {
                label: tooltipItem => {
                  const dataItem = this.view.data[tooltipItem.dataIndex]
                  return dataItem[this.view.view.dimensions[0]]
                },
                footer: (tooltipItems) => {
                  const dataItem = this.view.data[tooltipItems[0].dataIndex]
                  return [
                    `${formatString(this.view.view.properties.axis.x)} = ${_.round(dataItem[this.view.view.properties.axis.x], 2)}`,
                    `${formatString(this.view.view.properties.axis.y1)} = ${_.round(dataItem[this.view.view.properties.axis.y1], 2)}`,

                    (this.view.view.measures[3] ? `${formatString(this.view.view.measures[3]?.name)} = ${_.round(dataItem[this.view.view.measures[3]?.name], 2)}` : ''),

                    (this.view.view.properties.axis.color_grading ? `${formatString(this.view.view.properties.axis.color_grading)} = ${_.round(dataItem[this.view.view.properties.axis.color_grading], 2)}` : '')
                  ].filter(line => line !== '')
                }
              }
            }
          }
        }
      }

      if (this.chart) {
        this.chart.destroy()
      }
      this.dataLoaded = true

      this.chart = new Chart(canvas, chartData)
      canvas.onclick = (evt) => {
        const activePoint = this.chart.getElementAtEvent(evt)
        if (activePoint.length > 0) {
          const dataItem = this.view.data[activePoint[0]._index]
          this.$emit('point-click', dataItem[this.view.view.dimensions[0]])
        }
      }

      for (let i = 0; i < this.chart.data.datasets[0].data.length; i++) {
        pointBackgroundColors.push(this.getPointGradeColor(this.chart.data.datasets[0].data[i].grade))
      }
      this.$set(this, 'dataLoaded', true)
      this.chart.update()
    },
    getPointGradeColor (value) {
      if (this.min === 0 && this.max === 0) {
        return '#FFAB00'
      }
      const result = (value - this.min) / (this.max - this.min)
      return colormapCover(result)
    },
    downloadData (sep) {
      this.downloadingLoaded = true
      const downloadPayload = {
        ...this.view.view,
        filters: this.downlaodFilters,
        csv: true,
        csv_separator: sep,
        csv_decimal: '.'
      }
      if (sep === 'dutch') {
        downloadPayload.csv_separator = ';'
        downloadPayload.csv_decimal = ','
      }
      downloadAnalytics(downloadPayload)
        .then(({ url }) => {
          this.downloadingLoaded = false
          const link = document.createElement('a')
          link.setAttribute('href', url)
          const today = new Date()
          const dd = today.getDate()
          const mm = today.getMonth() + 1
          const yyyy = today.getFullYear()
          link.setAttribute('download', this.view.view.view_name + '_' + String(dd) + '_' + String(mm) + '_' + String(yyyy) + '.csv')
          link.click()
        })
    },
    updateMinMax () {
      if (!this.view.view?.properties?.axis) {
        this.min = 0
        this.max = 0
        return
      }
      const min = this.view.view.properties.axis.grading_min
      const max = this.view.view.properties.axis.grading_max
      if (!this.view.view.properties.axis.color_grading) {
        this.min = min || 0
        this.max = max || 0
      } else {
        const values = (this.view.data || []).map(p => p[this.view.view.properties.axis.color_grading])
        this.min = min || min === 0 ? min : Math.min.apply(Math, values)
        this.max = max || max === 0 ? max : Math.max.apply(Math, values)
      }
    },
    changeView (value) {
      this.isChangeView = false
      this.$emit('toggle-view', false)
      this.$emit('change-view', value)
    },
  },
}
</script>
<style>
</style>
