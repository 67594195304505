<template>
  <div ref="resizableElement" class="resizable">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {debounce} from 'lodash'

export default defineComponent({
  name: 'Resizable',
  emits: ['resize'],
  data () {
    return {
      resizableElement: null as HTMLDivElement | null,
      resizeObserver: null as ResizeObserver | null,
      resizeDebounce: debounce(this.handleResize, 500) as ((entries: ResizeObserverEntry[]) => void)
    }
  },
  mounted () {
    this.resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) =>  this.resizeDebounce(entries))
    this.resizableElement = this.$refs.resizableElement as HTMLDivElement
    if (this.resizableElement) {
      this.resizeObserver.observe(this.resizableElement)
    }
  },
  methods: {
    handleResize (entries: ResizeObserverEntry[]) {
      // Add Debounce
      for (const entry of entries) {
        this.$emit('resize', entry.contentRect)
      }
    }
  },
  beforeUnmount () {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }
})
</script>
<style scoped lang="scss">
.resizable {
  box-sizing: border-box;
}
</style>
