import axios from '@/services/axios'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

function getAPIBase (logic = false) {
  if (logic) {
    return baseURLs.logicAPI
  }
  return baseURLs.manageAPI
}

export const getMarkdownOrderSettings = async (option_key) => {
  try {
    const { data } = await axios.get(`${getAPIBase()}/option_settings?option_key=${option_key}&format=d&module=pricing&page=markdown`, {
      headers: getHeadersWithAccessToken(),
    })
    return data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getMarkdownOrdersData = async (order_settings) => {
  try {
    const { data } = await axios.post(
      getAPIBase(true) + '/markdown', order_settings)
    return data
  } catch (e) {
    console.error(e)
    return {}
  }
}

export const getMarkdownProposalsForOption = async (option_key, statuses) => {
  try {
    const { data } = await axios.get(getAPIBase() +
          `/data/pricing/markdown/markdown_proposals?ignore_cache=true&status=%7B${statuses.join(',')}%7D&history=false&option_key=${option_key.toString()}`)
    return data.data.map(changeResponse)
  } catch (e) {
    console.log(e)
    return []
  }
}

export const saveMarkdownProposal = async (payload) => {
  const arr = await saveMarkdownProposals(payload)
  return (arr && arr.length) ? arr[0] : null
}

export const saveMarkdownProposals = async (payload) => {
  try {
    if (Array.isArray(payload)) {
      payload = payload.map(changeRequest)
    } else {
      payload = [changeRequest(payload)]
    }
    const { data } = await axios.post(getAPIBase() + '/markdown_proposals', payload)
    return Array.isArray(data.markdown_proposals) ? data.markdown_proposals.map(changeResponse) : changeResponse(data.markdown_proposals)
  } catch (e) {
    console.error(e)
    return null
  }
}

export const deleteMarkdownProposal = async (payload) => {
  try {
    const { data } = await axios.delete(getAPIBase() + '/markdown_proposals', {
      headers: getHeadersWithAccessToken(),
      data: payload
    })
    return data
  } catch (e) {
    console.log(e)
    return []
  }
}

const changeRequest = (v) => {
  let discount = Number(v.discount) / 100
  if (!discount || discount < 0) {
    discount = 0
  }
  if (discount > 1) {
    discount = 1
  }
  const price = Number(v.price) || 0
  return { ...v, discount, price }
}

const changeResponse = (v) => ({ ...v, discount: ((v.discount || 0) * 100).toFixed(0) })
