<template>
  <resizable
    class="stock-picktickets-page p-relative mb-12"
    id="stock-picktickets-page"
    @resize="updateTotalTableWidth"
  >
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-row class="align-center">
      <v-col cols="2" offset="10" class="pb-0 pr-6 text-right">
        <v-menu
          v-model="settingsMenuOpened"
          :close-on-content-click="false"
          attach="#stock-picktickets-page"
          offset-y
          left
          min-width="450"
          max-width="450"
          nudge-bottom="20"
          content-class="settings-menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on">tune</v-icon>
          </template>

          <v-card>
            <h4 class="ml-4 mb-2 pt-6">
              Settings
            </h4>
            <div class="input-parameter">
              <div class="parameter-name">Reserve dc</div>
              <div class="parameter-value">
                <v-checkbox
                  v-model="settings.reserve_dc"
                  hide-details
                  class="my-1 pt-0"
                  @change="loadPicktickets"
                ></v-checkbox>
              </div>
            </div>
            <div class="input-parameter">
              <div class="parameter-name">Reserve web</div>
              <div class="parameter-value">
                <v-checkbox
                  v-model="settings.reserve_web"
                  hide-details
                  class="my-1 pt-0"
                  @change="loadPicktickets"
                ></v-checkbox>
              </div>
            </div>
            <div class="input-parameter">
              <div class="parameter-name">Require sales</div>
              <div class="parameter-value">
                <v-checkbox
                  v-model="settings.require_sales"
                  hide-details
                  class="my-1 pt-0"
                  @change="loadPicktickets"
                ></v-checkbox>
              </div>
            </div>
            <div class="input-parameter">
              <div class="parameter-name">Not inbound sales</div>
              <div class="parameter-value">
                <v-checkbox
                  v-model="settings.not_inbound"
                  hide-details
                  class="my-1 pt-0"
                  @change="loadPicktickets"
                ></v-checkbox>
              </div>
            </div>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <StockPickticketsCriteria
      ref="criteria"
      class="px-6"
      :store-sets="storeSets"
      @changed="handleCriteriaChanged"
    />

    <template v-if="picktickets.parents">
      <h6 class="px-6 mt-4 text-subtitle-1 font-weight-medium">Results</h6>
      <v-row>
        <v-col cols="3" offset="9" class="text-right my-2 pr-6">
          <v-menu offset-y :close-on-content-click="true" nudge-bottom="5">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on">download</v-icon>
            </template>

            <v-list dense>
              <v-list-item
                class="c-pointer"
                v-for="(item, index) in downloadItems"
                :key="index"
                @click.native="handleDownloadClick(item.value)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <data-tables
        style="width: 100%"
        ref="pickticketsTable"
        class="table mt-4"
        :data="picktickets.parents.table"
        :page-size="picktickets.parents.table.length"
        :pagination-props="{
          pageSizes: [picktickets.parents.table.length],
          class: 'd-none',
        }"
        :table-props="{
          'row-key': row => `${row.store_key}-${row.to_store_key}`,
          'expand-icon': 'add',
        }"
        @expand-change="handleExpandChange"
      >
        <el-table-column width="45" class="expand-column">
          <template slot-scope="scope">
            <v-icon @click="handleExpandClick(scope.row)" class="my-2">
              <template v-if="expandedRow !== scope.row">
                chevron_right
              </template>
              <template v-else>
                expand_more
              </template>
            </v-icon>
          </template>
        </el-table-column>
        <el-table-column type="expand" width="1">
          <template slot-scope="scope">
            <v-row class="pb-3">
              <v-col class="text-right pr-6">
                <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
                <DataExport
                  @item-click="downloadData"
                />
              </v-col>
            </v-row>
            <data-tables
              class="table"
              style="width: 100%"
              :table-props="{
                'row-key': 'name'
              }"
              :key="`${scope.row.store_key}-${scope.row.to_store_key}-${childsTableKey}`"
              :data="pickticketsChilds?.table"
              :page-size="pickticketsChilds?.table.length"
              :pagination-props="{
                pageSizes: [pickticketsChilds?.table.length],
                class: 'd-none',
              }"
              :filters="childsTableFilters"
            >
              <el-table-column
                prop="name"
                label="Name"
                width="220"
                class-name="pl-8"
              >
                <template slot-scope="scope">
                  <OptionDataCell
                    :option="scope.row"
                    name-key="name"
                    open-in-new-tab
                  />
                </template>
              </el-table-column>
              <el-table-column
                v-for="column in childsColumns"
                :key="column"
                :prop="column"
                :label="formatChildColumnName(column)"
                :width="column === 'sku_name' ? 125 : null"
                class-name="text-capitalize"
                label-class-name="text-center"
                sortable
              >
                <template slot-scope="scope">
                  <div class="text-center">
                    {{ scope.row[column] | formatThousands }}
                  </div>
                </template>
              </el-table-column>
            </data-tables>
          </template>
        </el-table-column>
        <el-table-column
          v-for="column in parentColumns"
          :key="column"
          :prop="column"
          :label="formatParentColumnName(column)"
          :width="['store_key', 'to_store_key'].includes(column) ? 150 : null"
          class-name="text-capitalize"
          :label-class-name="['store_key', 'to_store_key'].includes(column) ? null : 'text-center'"
          sortable
        >
          <template slot-scope="scope">
            <template v-if="['store_key', 'to_store_key'].includes(column)">
              <div class="store-name-cell">
                {{ storeKeysToNames.get(scope.row[column]) }}
              </div>
            </template>
            <template v-else>
              <div class="text-center">
                {{ scope.row[column] | formatThousands }}
              </div>
            </template>
          </template>
        </el-table-column>
        <template v-slot:append>
          <data-tables
            :data="[parentTotal]"
            class="total-table"
            :pagination-props="{class: 'd-none'}"
          >
            <el-table-column
              v-for="column in parentTotalColumns"
              class-name="total-row_column pl-1"
              label-class-name="d-none"
              align="left"
              :prop="column"
              :key="column"
              :width="column === 'total' ? 345 : null"
            >
              <template slot-scope="scope">
                <template v-if="column === 'total'">
                  Total
                </template>
                <template v-else>
                  <div class="text-center">
                    {{ scope.row[column] | formatThousands }}
                  </div>
                </template>
              </template>
            </el-table-column>
          </data-tables>
        </template>
      </data-tables>
    </template>
    <div class="mx-6 py-6 text-right">
      <v-btn
        color="primary"
        outlined
        :disabled="!picktickets.parents?.table.length"
        @click="savePicktickets"
      >Save</v-btn>
    </div>

    <template v-if="picktickets.picktickets_open?.source">
      <h6 class="px-6 mt-8 text-subtitle-1 font-weight-medium">Picktickets</h6>
      <StockPickticketsOpen
        :picktickets="picktickets.picktickets_open.source"
        class="pb-8"
      />
    </template>
  </resizable>
</template>

<script>
import { getStoreSets } from '@/api/storeSets'
import { requestPicktickets } from '@/api/picktickets'
import { formatString } from '@/variables'
import { downloadData } from '@/utils'
import TableSearch from '@/components/TableSearch.vue'
import DataExport from '@/components/DataExport'
import OptionDataCell from '@/components/OptionDataCell.vue'
import StockPickticketsOpen from '@/components/Stock/StockPickticketsOpen.vue'
import StockPickticketsCriteria from '@/components/Stock/StockPickticketsCriteria.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import Resizable from '@/components/Utility/Resizable.vue'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  name: 'StockReplenishment',
  components: {Resizable, OptionDataCell, TableSearch, DataExport, StockPickticketsOpen, StockPickticketsCriteria },
  data () {
    return {
      loading: false,
      activeTab: 1,
      settingsMenuOpened: false,
      storeSets: [],
      settings: {
        reserve_dc: true,
        reserve_web: true,
        require_sales: false,
        not_inbound: true,
      },
      criteria: {
        fromStores: [],
        toStores: [],
        filters: {},
        rules: [],
      },
      picktickets: [],
      pickticketsChilds: null,
      childsTableKey: 0,
      childsTableFilters: [
        {
          value: ''
        }
      ],
      expandedRow: null,
      downloadItems: [
        { title: 'CSV (comma)', value: ',' },
        { title: 'CSV (semicolon)', value: ';' },
        { title: 'CSV (semicolon, decimal comma)', value: 'dutch' }
      ],
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    stores () {
      return this.appConfig?.stores || []
    },
    storeKeysToNames () {
      const result = new Map()

      for (const store of this.stores) {
        result.set(store.store_key, store.store_name)
      }

      return result
    },
    parentColumns () {
      return this.picktickets.parents?.columns.filter(c => !['image_url', 'name'].includes(c))
    },
    parentTotalColumns () {
      if (!this.picktickets?.parents?.columns) {
        return ['total']
      }
      return [
        'total',
        ...this.picktickets.parents.columns
          .filter(c => !['image_url', 'name', 'store_key', 'to_store_key']
            .includes(c))
      ]
    },
    childsColumns () {
      return this.pickticketsChilds?.columns.filter(c => !['image_url', 'name', 'option_key', 'store_key', 'to_store_key'].includes(c))
    },
    parentTotal () {
      return this.picktickets.parents?.total || []
    },
  },
  watch: {
    'filtersStore.filtersChanged': {
      handler (newVal) {
        if (newVal) {
          this.loadPicktickets()
        }
      },
    },
  },
  methods: {
    setCriteria (...params){
      this.$refs.criteria.setCriteria(params)
    },
    formatString,
    downloadData (sep) {
      const data = this.pickticketsChilds?.table
      downloadData(sep, data, 'Stock Picktickets')
    },
    formatParentColumnName (name) {
      if (name === 'store_key') {
        name = 'Store'
      }

      if (name === 'to_store_key') {
        name = 'To store'
      }

      return this.$options.filters.formatString(name)
    },
    formatChildColumnName (name) {
      if (name === 'sku_name') {
        name = 'SKU'
      }

      return this.$options.filters.formatString(name)
    },
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = this.$el.querySelector('.el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 100)
    },
    handleSearchChanged (val) {
      this.childsTableFilters[0].value = val
      this.childsTableKey++
      this.updateTotalTableWidth()
    },
    handleCriteriaChanged (val) {
      this.criteria = val
      this.loadPicktickets()
      this.saveUserInput()
    },
    saveUserInput () {
      const input = {
        fromStores: this.criteria.fromStores,
        toStores: this.criteria.toStores,
        filters: this.criteria.filters,
        rules: this.criteria.rules,
      }

      localStorage.setItem('pickticketsInput', JSON.stringify(input))
    },
    loadUserInput () {
      const input = JSON.parse(localStorage.getItem('pickticketsInput'))

      if (input) {
        this.$refs.criteria.setCriteria(input)
        this.criteria.fromStores = input.fromStores || []
        this.criteria.toStores = input.toStores || []
        this.criteria.filters = input.filters || {}
        this.criteria.rules = input.rules || []
      }
    },
    async loadStoreSets () {
      const storesLabel = useGeneralStore().currentStoresSetsLabel
      const { stores_sets } = await getStoreSets(`?label=${storesLabel}`)

      this.storeSets = Object.freeze(stores_sets)
    },
    async loadPicktickets () {
      if (!this.criteria.fromStores.length || !this.criteria.toStores.length) {
        return
      }

      const payload = {
        store_keys: this.criteria.fromStores,
        to_store_keys: this.criteria.toStores,
        rules: this.criteria.rules,
        filters: {
          ...this.criteria.filters,
          option_key: [
            ...this.filtersStore.selectedFiltersOptions.options,
          ],
        },
        ...this.settings,
      }

      // close childs table if opened
      if (this.expandedRow) {
        this.$refs.pickticketsTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
      }

      this.loading = true
      this.picktickets = Object.freeze(await requestPicktickets(payload))
      this.loading = false
      this.updateTotalTableWidth()
    },
    async loadPickticketsChilds () {
      if (!this.expandedRow) {
        return
      }

      const payload = {
        filters: {
          ...this.criteria.filters,
          option_key: [
            ...this.filtersStore.selectedFiltersOptions.options,
          ],
        },
        store_keys: this.criteria.fromStores,
        to_store_keys: this.criteria.toStores,
        rules: this.criteria.rules,
        child_store_key: this.expandedRow.store_key,
        child_to_store_key: this.expandedRow.to_store_key,
        ...this.settings,
      }

      this.loading = true
      this.pickticketsChilds = Object.freeze((await requestPicktickets(payload)).childs)
      this.loading = false
    },
    async savePicktickets () {
      const payload = {
        store_keys: this.criteria.fromStores,
        to_store_keys: this.criteria.toStores,
        rules: this.criteria.rules,

        filters: {
          ...this.criteria.filters,
          option_key: [
            ...this.filtersStore.selectedFiltersOptions.options,
          ],
        },
        save: true,
      }
      await this.saveCriteria(payload)
    },
    async saveCriteria (payload){

      this.loading = true
      await requestPicktickets(payload)
      await this.loadPicktickets()
      this.loading = false
    },
    async handleExpandClick (row) {
      const elTable = this.$refs.pickticketsTable.$refs.elTable
      const alreadyExpanded = row === this.expandedRow

      if (this.expandedRow) {
        elTable.toggleRowExpansion(this.expandedRow, false)
      }

      if (!alreadyExpanded) {
        elTable.toggleRowExpansion(row, row !== this.expandedRow)
      }
    },
    async handleExpandChange (row) {
      this.expandedRow = this.expandedRow ? null : row
      this.childsTableFilters[0].value = ''

      if (this.expandedRow) {
        this.loadPickticketsChilds()
      } else {
        this.pickticketsChilds = null
      }
    },
    async handleDownloadClick (sep) {
      const payload = {

        filters: {
          ...this.criteria.filters,
          option_key: [
            ...this.filtersStore.selectedFiltersOptions.options,
          ],
        },
        store_keys: this.criteria.fromStores,
        to_store_keys: this.criteria.toStores,
        rules: this.criteria.rules,
        csv: true,
        csv_separator: sep,
        csv_decimal: '.',
        ...this.settings,
      }
      if (sep === 'dutch') {
        payload.csv_separator = ';'
        payload.csv_decimal = ','
      }

      this.loading = true

      try {
        const url = await requestPicktickets(payload)

        this.downloadingLoaded = false
        const link = document.createElement('a')
        link.setAttribute('href', url.url)
        link.setAttribute('download', url.url)
        link.click()
      } finally {
        this.loading = false
      }
    },
  },
  async mounted () {
    this.loading = true
    await Promise.all([
      this.loadStoreSets(),
    ])
    this.loading = false

    this.loadUserInput()
    this.loadPicktickets()
  },
}
</script>

<style lang="scss">
.stock-picktickets-page {
  .settings-menu {
    .input-parameter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      padding: 0 16px;
      .parameter-name {
        width: 200px;
      }
      .parameter-value {
        width: 250px;
        padding: 8px 0;
      }
      .slider {
        padding-top: 4px;
        .slider-value-from,
        .slider-value-to {
          color: #666;
          font-size: 14px;
        }
      }
    }
  }
  .store-name-cell {
    white-space: nowrap;
    overflow: hidden;
  }
  .el-table__expand-icon {
    display: none;
  }
  .el-table__expanded-cell[class*=cell] {
    padding: 16px 0;
    background: #f9f9f9;
    &:hover {
      background: #f9f9f9 !important;
    }
  }
  .el-table__append-wrapper {
    position: sticky;
    bottom: 0;
  }
  .table .el-table__body-wrapper {
    max-height: 50vh;
    overflow-y: auto;
  }
  .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
    width: 4px;
  }
  .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }
  .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
  .total-row_column {
    background-color: #f5f7fa;
  }
}
</style>
