import axios from '@/services/axios'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

export function getTransfersRules (optionKey = undefined) {
  const optionKeyQuery = optionKey ? `?option_key=${optionKey}` : ''
  const url = baseURLs.manageAPI + '/transfers_rules' + optionKeyQuery
  return axios.get(url)
    .then(({ data }) => data).catch(console.error)
}
export function saveTransfersRules (payload) {
  const url = baseURLs.manageAPI + '/transfers_rules'
  return axios.post(url, payload)
    .then(({ data }) => data).catch(console.error)
}

export function deleteTransfersRules (keys) {
  const url = baseURLs.manageAPI + '/transfers_rules'
  return axios.delete(url, {
    headers: getHeadersWithAccessToken(),
    data: {
      transfer_rule_key: keys
    }
  })
    .then(({ data }) => data).catch(console.error)
}

export function getStockTransfersChart (payload) {
  const url = baseURLs.manageAPI + '/data/stock/transfers/performance'
  return axios.post(url, payload).then(({ data }) => data).catch(console.error)
}

export function sendMail (payload) {
  const url = baseURLs.manageAPI + '/transfers_mail'
  return axios.post(url, payload)
    .then(({ data }) => data).catch(console.error)
}

export function getAccepted (from, to, type) {
  const url = baseURLs.tsqlAPI + '/transfers_accepted'
  const payload = {
    child_store_key: from,
    child_to_store_key: to,
    send: 'all',
    receive: 'all',
    type: type,
    filters: {
      group_key: [],
      collection_key: [],
      option_key: [],
      store_key: [],
      to_store_key: [],
    },
  }
  return axios.post(url, payload)
    .then(({ data }) => data).catch(console.error)
}
