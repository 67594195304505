import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'
import * as models from '@/models/Proposal'
import {getRuleblocks} from '@/api/stockManageAPI'

export async function getDefaults (): Promise<models.RuleBlock[]> {
  return getRuleblocks()
}

export async function getRules (): Promise<{
  rules: models.Rule[]
  value: unknown[]
  parameters: models.Parameters
}> {
  const response = await axios.post<{
    rules: models.Rule[]
    value: unknown[]
    parameters: models.Parameters
  }>(baseURLs.logicAPI + '/rules', {})
  return response.data
}

export async function getProposalSettings (route: string): Promise<models.ProposalSetting[]> {
  try {
    const response = await axios.get<models.ProposalSetting[]>(baseURLs.manageAPI + '/stock_settings?' + route)
    return response.data
  }catch (e) {
    console.log(e)
    throw e
  }
}

export async function getProposalSetting (route: string): Promise<models.ProposalSetting[]> {
  const response = await axios.get<models.ProposalSetting[]>(baseURLs.manageAPI + '/stock_settings?' + route)
  return response.data
}

export async function addProposalSetting (route: string, blockKey: number): Promise<models.ProposalSetting> {
  const response = await axios.put<models.ProposalSetting>(baseURLs.manageAPI + '/stock_settings?' + route, {
    block_key: blockKey,
  })
  return response.data
}

export async function deleteProposalSetting (route: string, id: number): Promise<void> {
  await axios.delete(baseURLs.manageAPI + '/stock_settings?' + route, {
    data: { id },
  })
}

export async function getProposal (params: models.ProposalParams): Promise<models.ProposalData> {
  const response = await axios.post<models.ProposalData>(baseURLs.logicAPI + '/proposal', params)
  return response.data
}

export async function saveRuleblock (query: string, setting: models.ProposalSetting): Promise<void> {
  await axios.put(baseURLs.manageAPI + '/stock_settings?' + query, setting)
}
