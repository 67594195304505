<template>
  <div class="gantt-lib-test" ref="main">
    <div class="px-4 mb-4 p-relative">
      <svg id="dropsChart"></svg>
    </div>

    <v-dialog
      v-model="dropModalOpen"
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <drop-table-modal
        v-if="dropModalOpen"
        @close="dropModalOpen = false"
        :isEdit="isEdit"
        :drop="{...selectedDrop}"
        :budgets="budgets"
        :stores="stores"
        @save="itemSaved"
      ></drop-table-modal>
    </v-dialog>
    <div ref="test"></div>
  </div>
</template>

<script>
import _ from 'lodash'
import Gantt from '@/libs/gantt/index'
import { parseWeek } from '@/variables'
import DropTableModal from '@/components/Shop/DropTableModal'

export default {
  props: ['items', 'budgets', 'stores', 'drops', 'shouldLoad'],
  data () {
    this.chart = null

    return {
      chartOptions: {
        on_drop_click: this.handleDropClick,
        on_drop_hover: this.handleDropHover,
        on_chart_click: this.handleChartClick,
        on_date_change: _.debounce(this.handleDropDateChanged, 200),
        view_mode: 'Week',
      },
      changeParams: {},
      selectedDrop: {},
      dropModalOpen: false,
      isEdit: false,
    }
  },
  components: {
    DropTableModal,
  },
  methods: {
    itemSaved (item) {
      this.dropModalOpen = false
      this.selectedDrop = { ...item }
      this.loadData()
    },
    handleDropClick (drop) {
      this.selectedDrop = this.drops.filter((item) => drop.id === item.drop_key + '')[0]
      this.isEdit = true
      this.dropModalOpen = true
    },
    handleDropHover (drop, event) {
      if (this.infoElement) {
        return
      }
      const l = document.createElement('div')
      l.style.left = event.pageX + 'px'
      l.style.top = event.pageY + 'px'
      l.innerText = ''
      l.id = 'drops-info'
      l.classList.add('v-card')
      l.classList.add('v-sheet')
      l.innerHTML =
        `
                <span><b> Name</b>:   ${drop['drop_name'].toLocaleString()}</span>
                <span><b> Start</b>:   ${parseWeek(drop['week_min']).toLocaleString()}</span>
                <span><b> End</b>:   ${parseWeek(drop['week_max']).toLocaleString()}</span>
                <span><b> Shop items</b>:  ${drop['shop_items'].toLocaleString()}</span>
                <span class="drops-overlay"></span>
              `
      document.body.appendChild(l)
      this.infoElement = l
    },
    loadData () {
      this.$emit('load-data')
    },
    handleChartClick () {
      this.isEdit = false
      this.dropModalOpen = true
    },
    handleDropDateChanged (drop, start, end) {
      const week_min = parseInt(this.getWeek(start))
      const week_max = parseInt(this.getWeek(end))
      this.$emit('update-drop', drop, week_min, week_max)
    },
    updateDrop () {
      const week_min = parseInt(this.getWeek(this.changeParams.start))
      const week_max = parseInt(this.getWeek(this.changeParams.end))
      this.$emit('update-drop', this.changeParams.drop, week_min, week_max)
    },
    getWeek (date) {
      const currentDate = new Date(date)
      const oneJan = new Date(currentDate.getFullYear(), 0, 1)
      const numberOfDays = Math.floor((currentDate - oneJan) / (24 * 60 * 60 * 1000))
      const weekNum = '0' + Math.floor((currentDate.getDay() + 1 + numberOfDays) / 7)
      return currentDate.getFullYear() + '' + weekNum.substr(-2)
    }
  },
  watch: {
    items () {
      if (this.shouldLoad) {
        this.chart = new Gantt('#dropsChart', this.items, this.chartOptions)
      }
    }
  },
  mounted () {
    this.chart = new Gantt('#dropsChart', this.items, this.chartOptions)
    document.body.addEventListener('mouseover', (event) => {
      if (
        !event.target.classList.contains('bar-progress') &&
        !event.target.classList.contains('drops-overlay') &&
        event.target.id !== 'drops-info' &&
        this.infoElement) {
        this.infoElement.remove()
        this.infoElement = null
      }
    })
  }
}
</script>
<style>
#drops-info {
  position: absolute;
  padding: 8px 16px;
  background: white;
  display: flex;
  flex-direction: column;
}

.drops-overlay {
  position: absolute;
  background: transparent;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}
</style>
