<template>
  <div class="buy-order-view">
    <div class="my-6">
      <h3 class="card-title text-left mb-0">Reorder</h3>
    </div>
    <reorder-home v-if="!optionKey"/>
    <buy-order-page v-else/>
  </div>
</template>

<script>

import BuyOrderPage from '@/views/BuyOrderPage'
import ReorderHome from '@/components/Order/ReorderHome'
export default {
  name: 'BuyOrderHome',
  components: { ReorderHome, BuyOrderPage },
  computed: {
    optionKey () {
      return this.$route.query.option_key
    },
  }
}
</script>
