/**
 * Mean
 */
export function mean(values: number[]): number {
  let sum = 0;
  for (let i = 0; i < values.length; i++) {
    sum += values[i];
  }
  return sum / values.length;
}

/**
 * Standard deviation
 */
export function std(values: number[]): number {
  const m = mean(values);
  let sum = 0;
  for (let i = 0; i < values.length; i++) {
    sum += Math.pow(values[i] - m, 2);
  }
  return Math.sqrt(sum / values.length);
}


export function intersectionOfSets<T>(sets: Set<T>[]): Set<T> {
  const result = new Set<T>();
  sets[0].forEach((value) => {
    if (sets.every((set) => set.has(value))) {
      result.add(value);
    }
  });
  return result;
}

export function unionOfSets<T>(sets: Set<T>[]): Set<T> {
  const result = new Set<T>();
  sets.forEach((set) => {
    set.forEach((value) => {
      result.add(value);
    });
  });
  return result;
}
