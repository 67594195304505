<template>
  <div class="transferDetails p-relative">
    <v-overlay absolute color="white" :value="stockTransfersStore.loadingTransferDetails || acceptingTransfer">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="my-0">
      <v-col class="text-right px-8">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('tableFilters', Object.keys(transferDetailsData[0]), val)"
        />
        <DataExport
          @item-click="downloadData"
        />
      </v-col>
    </v-row>
    <data-tables
      ref="transfersDetailsTable"
      :data="transferDetailsData"
      :filters="tableFilters"
      :page-size="(transferDetailsData || []).length"
      :table-props="{'span-method': getSpanMethod}"
      :pagination-props="{
        pageSizes: [(transferDetailsData || []).length],
        class: 'el-pagination text-right mt-6 mb-4 mr-2 d-none'
      }"
      class="table"
    >
      <el-table-column label="Name" prop="option_name" width="250">
        <template slot-scope="scope">

          <v-menu
            :key="scope.row.option_key"
            right
            bottom
            :open-on-hover="!infoMenuOpened[scope.row.option_key]"
            nudge-bottom="10"
            :disabled="!scope.row.option_key"
            :nudge-left="200"
            v-model="infoMenuOpened[scope.row.option_key]"
            @input="onInfoMenuChange(scope.row.option_key, $event)"
            offset-x
            offset-y
            :open-delay="3000"
            :close-delay="1000"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" >
                <OptionDataCell
                  :option="getModifiedOption(scope.row)"
                  open-in-new-tab
                />
              </div>
            </template>

            <info-menu
              @close="infoMenuOpened[scope.row.option_key] = false"
              v-if="infoMenuOpened[scope.row.option_key]"
              :row="scope.row"></info-menu>
          </v-menu>
        </template>
      </el-table-column>

      <el-table-column v-if="type === 'receive'" label="Store" prop="store_key">
        <template slot-scope="scope">
          <div class="one-line" v-if="findStore(scope.row.store_key)">
            {{ findStore(scope.row.store_key).store_name }}
          </div>
          <span v-else>{{scope.row.store_key}}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="type === 'send'" label="To store" prop="to_store_key">
        <template slot-scope="scope">
          <div class="one-line" v-if="findStore(scope.row.to_store_key)">
            {{ findStore(scope.row.to_store_key).store_name }}
          </div>
          <span v-else>{{scope.row.to_store_key}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Sku name" prop="sku_name">
        <template slot-scope="scope">
          {{ scope.row.sku_name }}
        </template>
      </el-table-column>
      <el-table-column label="Stock" prop="stock">
        <template slot-scope="scope">
          {{ scope.row.stock }}
        </template>
      </el-table-column>
      <el-table-column label="Potential">
        <template slot-scope="scope">
          {{ scope.row.potential }}
        </template>
      </el-table-column>
      <el-table-column label="To Stock">
        <template slot-scope="scope">
          {{ scope.row.to_stock }}
        </template>
      </el-table-column>
      <el-table-column label="To Potential">
        <template slot-scope="scope">
          {{ scope.row.to_potential }}
        </template>
      </el-table-column>
      <el-table-column label="Transfer" width="180">
        <template slot-scope="scope">
          <v-select
            :items="getTransferOptions(scope.row)"
            dense
            :value="getValue(scope)"
            @input="changeTransfer(scope, $event)"
            class="pt-0 pb-1"
            hide-details
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu'
            }"
            @change="currentTransferModes[scope.row.option_key] = 'custom'">
            <template v-slot:item="{ item }">
              <div class="v-list-item__content">
                <div
                  class="v-list-item__title"
                  :class="{ 'green--text': item.value === scope.row.pieces }"
                >{{ item.text }}</div>
              </div>
            </template>
          </v-select>
        </template>
      </el-table-column>
      <el-table-column label="Toggle" width="180">
        <template slot-scope="scope">
          <div class="text-center">
            <v-btn
              depressed
              class="text-capitalize white--text"
              :class="{
                'success': currentTransferModes[scope.row.option_key] === 'optimal',
                'warning': currentTransferModes[scope.row.option_key] === 'none',
                'grey': currentTransferModes[scope.row.option_key] === 'all',
                'grey lighten-2': currentTransferModes[scope.row.option_key] === 'custom'
              }"
              @click="handleTransferModeClick(scope.row.option_key)"
            >{{ currentTransferModes[scope.row.option_key] }}</v-btn>
            <div class="mt-2">Send: {{ totalDeltasByOption[scope.row.option_key].send }}</div>
            <div class="mt-n2">Keep: {{ totalDeltasByOption[scope.row.option_key].keep }}</div>
          </div>
        </template>
      </el-table-column>
    </data-tables>
    <div class="pt-4 transfers-summary">
      <div class="d-flex justify-end">
        <div>
          <div>Selected pieces: {{ selectedSummary.count | formatThousands }}</div>
          <div>Selected profit: {{ selectedSummary.profit | currency }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { downloadData, getTransferDetailsTableSpanMethod } from '@/utils'
import OptionDataCell from '@/components/Transfers/OptionDataCell.vue'
import columnFilters from '../../mixins/columnFilters'
import DataExport from '../DataExport'
import TableSearch from '@/components/TableSearch.vue'
import InfoMenu from '@/components/Home/InfoMenu'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'

export default {
  setup () {
    return {
      stockTransfersStore: useStockTransfersStore(),
    }
  },
  mixins: [ columnFilters ],
  props: {
    type: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  components: {
    InfoMenu,
    DataExport,
    TableSearch,
    OptionDataCell,
  },
  data () {
    return {
      transfersChangedData: {},
      currentPage: 1,
      tableFilters: [],
      currentTransferModes: {},
      acceptingTransfer: false,
      infoMenuOpened: false,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['findStoreWithKey']),
    transferDetailsData () {
      return this.stockTransfersStore.transferDetailsData
    },
    currentPageRows () {
      return this.transferDetailsData
    },
    selectedSummary () {
      const result = {
        count: 0,
        profit: 0,
      }

      this.transferDetailsData.forEach((row, index) => {
        if (!result[row.option_key]) {
          result[row.option_key] = {
            send: 0,
            keep: 0,
          }
        }
        const delta = this.getValue({ row, $index: index })
        const profit = delta ? row.profits[ delta - 1 ] : 0
        result.count += delta
        result.profit += profit | 0
      })

      return result
    },
    totalDeltasByOption () {
      const result = {}

      this.transferDetailsData.forEach((row, index) => {
        if (!result[row.option_key]) {
          result[row.option_key] = {
            send: 0,
            keep: 0,
          }
        }

        result[row.option_key].send += this.getValue({ row, $index: index })
        result[row.option_key].keep += row.stock - this.getValue({ row, $index: index })
      })

      return result
    },
    // needed for rowspan
    optionsRowsCount () {
      const counts = {}

      this.transferDetailsData.forEach((row, i) => {
        if (!counts[row.option_key]) {
          counts[row.option_key] = {
            count: 1,
            firstRowIndex: i,
          }
        } else {
          counts[row.option_key].count++
        }
      })

      return counts
    }
  },
  watch: {
    transferDetailsData: {
      handler (val) {
        if (val.length) {
          _
            .uniqBy(val, 'option_key')
            .forEach(item => {
              this.currentTransferModes[item.option_key] = 'optimal'
            })
          this.currentTransferModes = {...this.currentTransferModes}
        }
      }
    }
  },
  methods: {
    handleAcceptClick () {
      const keys = ['pieces', 'profit', 'sku_key', 'store_key', 'to_store_key', 'type']
      const payload = this.transferDetailsData.map((item, index) => {
        const row = {}
        keys.forEach(key => {
          row[key] = item[key]
        })
        row.delta = this.getValue({ row: item, $index: index })
        row.keep = item.stock - row.delta
        row.type = 'box'
        row.pieces = row.delta
        row.profit = item['profits'][row.delta - 1]
        return row
      })

      this.acceptingTransfer = true
      this.stockTransfersStore.acceptTransfer(payload.filter(item => item.pieces))
        .then(() => {
          this.acceptingTransfer = false
          this.$emit('accepted')
        })
    },
    getModifiedOption (row) {
      return row
    },
    onInfoMenuChange (option_key, value) {
      if (value) {
        this.infoMenuOpened = { [option_key]: value }
      }
    },
    getValue (scope) {
      switch (this.currentTransferModes[scope.row.option_key]) {
      case 'optimal':
        return scope.row.pieces
      case 'all' :
        return scope.row.stock
      case 'custom':
        return this.transfersChangedData[scope.$index] === undefined ? scope.row.pieces : this.transfersChangedData[scope.$index]
      default :''
               return 0
      }
    },
    changeTransfer (scope, value) {
      this.$emit('transfers-changed')
      this.$set(this.transfersChangedData, scope.$index, value)
    },
    downloadData (sep) {
      downloadData(sep, this.transferDetailsData, 'Transfers_box_details')
    },
    getTransferOptions (row) {
      const options = [{
        text: '0 = €0',
        value: 0
      }]

      if (row.profits) {
        row.profits.forEach((p, i) => {
          options.push({
            text: `${i + 1} = €${Math.round(p)}`,
            value: i + 1
          })
        })
      }

      return options
    },
    getSpanMethod (cellInfo) {
      let spanColumnsIndexes = [0, 7]

      if (['send', 'receive'].includes(this.type)) {
        spanColumnsIndexes = [0, 8]
      }

      return getTransferDetailsTableSpanMethod(cellInfo, this.optionsRowsCount, spanColumnsIndexes)
    },
    findStore (storeKey) {
      return this.findStoreWithKey(storeKey)
    },
    handleTransferModeClick (optionKey) {
      const currentMode = this.currentTransferModes[optionKey]
      let nextMode = 'optimal'
      if (currentMode === 'optimal') {
        nextMode = 'all'
      } else if (currentMode === 'all') {
        nextMode = 'none'
      }

      this.transferDetailsData.forEach((row, index) => {
        if(row.option_key !== optionKey) {
          return
        }
        let qty = row.pieces

        if (nextMode === 'all') {
          qty = row.stock
        }

        if (nextMode === 'none') {
          qty = 0
        }

        this.$set(this.transfersChangedData, index, qty)
      })

      this.currentTransferModes[optionKey] = nextMode
      this.$emit('transfers-changed')
    },
  },
}
</script>

<style lang="scss">

.transfers-options-graphs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;
}
.transferDetails {

  .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
    width: 4px;
  }
  .table .el-table__body-wrapper {
    max-height: 60vh;
    overflow-y: auto;
  }

  .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }

  .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
    thead {
        th {
            padding: 12px 0;
        }
    }
    .pagination-wrap {
      background: transparent;
    }
    .v-select__selection {
        font-size: 14px;
    }
}
</style>
