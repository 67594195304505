<template>
  <div class="shop-budget-progress-item">
    <div class="d-flex align-end">
      <div v-if="caption" class="pr-8 item-caption">{{ caption }}</div>
      <div class="flex-grow-1">
        <slot name="total-caption">
          <div
            v-if="!hideTotal"
            class="total"
            :class="{ 'exceed': progressData.over > 0 }"
          >
            <template v-if="progressData.total !== undefined">
              Total: {{budgetProgressType && !budgetProgressType.includes('pieces') ? $auth.getUserCurrency().symbol : ''}} {{ progressData.total | formatThousands }}
            </template>
            <template v-if="progressData.over > 0">
              &nbsp;(Exceed:
              {{!isPercentage && budgetProgressType && !budgetProgressType.includes('pieces') ? $auth.getUserCurrency().symbol : ''}} {{ progressData.over  | formatThousands }}
              <span v-if="isPercentage">%</span>)
            </template>
          </div>
        </slot>
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="progress-bar">
              <div
                v-for="(s, i) in barData" :key="i"
                class="segment"
                :style="{
                  backgroundColor: s.color,
                  width: s.width,
                }"
              >
                <span v-if="showPercentageInSegment && getFractionPercent(s.value) > 5">
                  {{getFractionPercent(s.value)}}%
                </span>
              </div>
            </div>
          </template>
          <template>
            <div
              v-for="(s, i) in barData"
              :key="i"
              :style="{ color: s.color }"
            >
              <b>{{ s.caption }}: </b>{{ formatter(s.value) }}
              <template v-if="showFractions && progressData.total">({{getFractionPercent(s.value)}}%)</template>
              <span v-if="isPercentage">%</span>
            </div>
          </template>
        </v-tooltip>
      </div>
    </div>
    <v-row v-if="showLegend" justify="start" class="mt-1">
      <v-col v-for="(s, i) in progressData.segments" :key="i" class="align-center mx-3" cols="2"
        :style="{ color: s.color, 'max-width': '120px'}">
        <b>{{ s.caption }}:</b><br>
        {{ formatter(s.value) }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { sumBy } from 'lodash'

export default {
  props: {
    showFractions: {
      type: Boolean,
      default: false,
    },
    caption: {
      type: String || null,
      default: null,
    },
    budgetProgressType: {
      type: String || null,
      default: null,
    },
    hideTotal: {
      type: Boolean,
      default: false
    },
    isPercentage: {
      type: Boolean,
      default: false
    },
    progressData: {
      type: Object,
      default: () => ({}),
    },
    // Added for single sku cases
    showPercentageInSegment: {
      type: Boolean,
      default: false,
    },
    excludeTotal: {
      type: Boolean,
      default: false,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
    }
  },
  methods: {
    getFractionPercent (value) {
      const total = this.segmentsTotal
      if (!total) {
        return ''
      }
      return Math.round((value / total) * 100)
    },
  },
  computed: {
    formatter () {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      return formatter.format
    },
    segmentsTotal () {
      if (this.progressData.total) {
        return this.progressData.total
      }
      if (this.progressData.segments?.find(s => s.caption === 'Total')?.value) {
        return this.progressData.segments.find(s => s.caption === 'Total').value
      }
      if (this.progressData.segments) {
        return sumBy(this.progressData.segments, 'value')
      }
      return 0
    },
    filteredSegments () {
      const segments = this.progressData.segments.filter(s => s.value > 0)
      if (this.excludeTotal) {
        return segments.filter(s => s.caption !== 'Total')
      }
      return segments
    },
    barData () {
      return this.filteredSegments.map(s => ({
        ...s,
        width: s.value / (this.segmentsTotal / 100) + '%',
      }))
    },
  },
}
</script>

<style lang="scss">
.shop-budget-progress-item {
    .item-caption {
        flex-basis: 100px;
    }
    .total {
        font-size: 16px;
        margin-bottom: 4px;
        &.exceed {
            color: var(--danger);
        }
        .exceed {
            color: var(--danger);
        }
    }
    .progress-bar {
        display: flex;
        width: 100%;
        border-radius: 3px;
        position: relative;
        height: 27px;
        .segment {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 500;
        }
    }

  .value-line {
    height: 55px;
    width: 2px;
    background-color: black;
    position: absolute;
    bottom: -14px;
    left: 42%;
  }
}
</style>
