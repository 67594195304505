<template>
  <div class="settings-users-page">
    <v-container fluid>
      <v-row class="mx-n6">
        <v-col class="py-0 d-flex align-center">
          <h3
            class="mb-6 mt-6 mr-2 breadcrumb-lvl-0"
            @click="$router.push({ name: currentModule + '-settings-main' })"
          >Settings</h3>/
          <h3 class="mb-6 mt-6 ml-2">Users</h3>
        </v-col>
      </v-row>
      <v-row class="mt-0 mx-n9">
        <v-col class="px-6 pb-6">
          <v-card>
            <v-tabs v-model="activeTab">
              <v-tabs-slider />
              <v-tab class="px-8">Active</v-tab>
              <v-tab class="px-8">Invited</v-tab>
              <v-tab v-if="isAdmin" class="px-8">Activity</v-tab>
            </v-tabs>

            <v-tabs-items v-model="activeTab">
              <v-tab-item :transition="false" :reverse-transition="false">
                <UsersList users-type="active" />
              </v-tab-item>
              <v-tab-item :transition="false" :reverse-transition="false">
                <UsersList users-type="invited" />
              </v-tab-item>
              <v-tab-item :transition="false" :reverse-transition="false">
                <UserActivity/>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import UsersList from '@/components/GeneralSettings/UsersList'
import { useGeneralStore } from '@/store/pinia/generalStore'
import UserActivity from '@/components/GeneralSettings/UserActivity.vue'

export default {
  components: {
    UserActivity,
    UsersList,
  },
  data () {
    return {
      activeTab: 0,
      generalStore: useGeneralStore()
    }
  },
  computed: {
    isAdmin () {
      return localStorage.getItem('superuser') === 'true'
    },
    currentModule () {
      return this.generalStore.currentModule
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
.settings-users-page {
    .breadcrumb-lvl-0 {
        cursor: pointer;
        color: var(--text-sub-black);
    }
    .v-tab {
        width: 267px;
    }
}
</style>
