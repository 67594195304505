import {defineStore} from 'pinia'
import * as api from '@/api/articleAPI'
import * as models from '@/models/Article'
import {saveOptionSettings} from '@/api/options'

export const useArticleStore = defineStore('article', {
  state: (): models.ArticleState => ({
    article: null,
    articleSettings: {},
    articleSettingsLoaded: false,
    savingOptionSettings: false,
  }),
  actions: {
    setArticle (payload: models.Article) {
      this.article = payload
    },
    setArticleSettings (payload: models.ArticleSettings) {
      if (payload.clusters === null) {
        payload.clusters = []
      }
      this.articleSettings = payload
      this.articleSettingsLoaded = true
    },
    updateArticleSetting (key: models.ArticleSettingsField, value: unknown) {
      switch (key) {
      case 'date_max':
        this.articleSettings.date_max = value as string
        break
      case 'date_min':
        this.articleSettings.date_min = value as string
        break
      case 'stores_included':
        this.articleSettings.stores_included = value as unknown[]
        break
      case 'stores_filtered':
        this.articleSettings.stores_filtered = value as unknown[]
        break
      case 'store_keys':
        this.articleSettings.store_keys = value as number[]
        break
      case 'value_parameters':
        this.articleSettings.value_parameters = value as unknown[]
      }
    },
    setSavingOptionSettings (payload: boolean) {
      this.savingOptionSettings = payload
    },
    // Payload is passed from route query
    async loadArticleSettings (payload?: Record<string, string | (string | null)[]>) {
      try {
        const settings = await api.getArticleSettings(payload)
        this.setArticleSettings(settings)
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async saveOptionSettings (data: unknown, params?: Record<string, string | (string | null)[]>) {
      try {
        this.setSavingOptionSettings(true)
        const resp = await saveOptionSettings(data, params)
        this.setArticleSettings(resp.option_setting)
        this.setSavingOptionSettings(false)
      } catch (e) {
        console.error(e)
        this.setSavingOptionSettings(false)
        throw e
      }
    }
  }
})
