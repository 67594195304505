<template>
  <div class="stock-overview-dimension-table">
    <v-row>
      <v-col class="p-relative">
        <v-overlay absolute color="white" :value="stockOverviewStore.dataLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <h6 class="text-subtitle-1 font-weight-medium ml-6">Dimensions table</h6>
        <v-row>
          <v-col class="text-right pr-6">
            <TableSearch class="mx-2" @searchChanged="handleOptionsSearchChanged" />
            <DataExport
              @item-click="downloadData"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <data-tables
              ref="dataTables"
              style="width: 100%"
              class="main-table"
              :key="dimensionsListKey"
              :data="tableData"
              :tableProps="tableProps"
              :filters="dimensionsTableFilters"
              :page-size="10"
              :pagination-props="{
                pageSizes: [5, 10,15, 25, 50],
                class: 'el-pagination text-right mt-6 mb-4 mr-2'
              }"
              @expand-change="handleExpandChange"
            >
              <el-table-column
                prop="dimension"
                label="Dimension"
                :width="220"
                sortable>
                <template slot-scope="scope">
                  {{ scope.row.dimension }}
                </template>
              </el-table-column>
              <el-table-column
                v-for="col in columns"
                :key="col"
                :prop="col"
                :label="col | formatString"
                :width="getColWidth(col)"
                sortable
              >
                <template slot-scope="scope">
                  <div v-if="col.indexOf('perc') !== -1" :style="getCellStyle(scope.row, col)">
                    {{ scope.row[col] + '%' }}
                  </div>
                  <div v-else :style="getCellStyle(scope.row, col)">
                    {{ scope.row[col] | formatThousands }}
                  </div>
                </template>
              </el-table-column>
            </data-tables>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { downloadData } from '@/utils'
import { colormapCover } from '@/variables'
import TableSearch from '@/components/TableSearch.vue'
import DataExport from '../DataExport'
import { useStockOverviewStore } from '@/store/pinia/stockOverviewStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore(),
      stockOverviewStore: useStockOverviewStore(),
    }
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 15,
      expandedRow: null,
      dimensionsListKey: 0,
      dimensionsTableFilters: [
        {
          value: '',
        }
      ],
      tableProps: {
        'row-key': this.getRowKey,
        lazy: true,
        load: this.loadChildData,
        'cell-class-name': this.getCellClassName,
        'header-cell-class-name': this.getHeaderCellClassName,
      },
    }
  },
  components: {
    DataExport,
    TableSearch,
  },
  computed: {
    dimension1Value () {
      return this.stockOverviewStore.dimension1Value
    },
    selectedDimensions () {
      return this.stockOverviewStore.selectedDimensions
    },
    selectedView () {
      return this.stockOverviewStore.selectedView
    },
    columns () {
      return this.stockOverviewStore.dimensionsColumnsList
    },
    tableData () {
      return this.stockOverviewStore.overviewDimensionsData.map(row => ({
        ...row,
        dimension: row[this.selectedDimensions[0]], // needed for row-key
        hasChildren: true,
      }))
    },
    currentPageRows () {
      return this.tableData.slice(this.pageSize * this.currentPage - this.pageSize, this.pageSize * this.currentPage)
    },
    colorProperties () {
      return this.stockOverviewStore.dimensionsColorData || []
    },
    minMaxByProperty () {
      return this.colorProperties
        .map(p => p.name)
        .reduce((acc, cur) => {
          return {
            ...acc,
            [cur]: {
              min: Math.min.apply(Math, this.tableData.map(r => r[cur])),
              max: Math.max.apply(Math, this.tableData.map(r => r[cur])),
            },
          }
        }, {})
    },
  },
  methods: {
    async loadChildData (parentRow, treeNode, resolve) {
      this.stockOverviewStore.setDimensionTableChildData([])

      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()

      await this.stockOverviewStore.getDimensionTableChildData({
        view: this.selectedView,
        dimension: this.selectedDimensions[1],
        filters: {
          ...pageFiltersValues,
          [this.selectedDimensions[0]]: [parentRow[this.selectedDimensions[0]]],
        },
        format: 'aod',
      })

      resolve(this.stockOverviewStore.dimensionTableChildData.map(r => ({
        ...r,
        dimension: r[this.selectedDimensions[1]], // needed for row-key
      })))
    },
    handleExpandChange (row, expanded) {
      this.stockOverviewStore.setDimension1Value(expanded ? row[this.selectedDimensions[0]] : null)

      if (this.expandedRow) {
        // close previously opened row
        this.$refs.dataTables.$refs.elTable.toggleRowExpansion(this.expandedRow, false)

        // set dimension 1 value again cause toggleRowExpansion calls handleExpandChange, which sets dimension 1 value to null
        this.stockOverviewStore.setDimension1Value(expanded ? row[this.selectedDimensions[0]] : null)
      }

      this.expandedRow = expanded ? row : null
      this.$emit('dimension1ValueChanged')
    },
    getColWidth (col) {
      if (col === this.selectedDimensions[0]) {
        return '220'
      }
    },
    getRowKey (row) {
      return row.dimension
    },
    setCurrentPage (val) {
      this.currentPage = val
    },
    setPageSize (val) {
      this.pageSize = val
    },
    getCellStyle (row, property) {
      if (this.colorProperties.map(p => p.name).includes(property)) {
        return {
          background: this.getCellGradeColor(property, row[property]),
          borderRadius: '4px',
          color: '#fff',
          padding: '13px 0',
        }
      }
    },
    getCellClassName ({ column }) {
      return ['width', 'depth'].includes(column.property) ? 'has-borders' : ''
    },
    getHeaderCellClassName ({ columnIndex }) {
      return columnIndex === 0 ? 'dimension-cell pl-4' : ''
    },
    getCellGradeColor (property, value) {
      const colorData = this.colorProperties.find(p => p.name === property)
      const min = (colorData && colorData.min) || this.minMaxByProperty[property].min
      const max = (colorData && colorData.max) || this.minMaxByProperty[property].max
      const result = (value - min) / (max - min)
      return colormapCover(result)
    },
    downloadData (sep) {
      const data = this.tableData.map(
        row => ['dimension', ...this.columns].reduce(
          (acc, cur) => Object.assign(acc, { [cur]: row[cur] }),
          {}
        )
      )

      downloadData(sep, data, 'overview_dimensions_overview')
    },
    handleOptionsSearchChanged (val) {
      this.dimensionsTableFilters[0].value = val
      this.dimensionsListKey++
    },
  },
  beforeDestroy () {
    this.stockOverviewStore.setDimension1Value(null)
  },
}
</script>

<style lang="scss">
.stock-overview-dimension-table {
    table .cell {
        text-align: center;
    }
    table .dimension-cell .cell  {
        text-align: left;
    }
    td:nth-of-type(1) .cell {
        text-align: left;
    }
    .el-table__expand-icon {
        float: left;
    }
    .el-table td {
        padding: 0;
    }
    .el-table__row.el-table__row--level-1 {
        background: #fafafa;
        td {
            border-bottom: 0;
            &:nth-of-type(1) {
                padding-left: 32px;
            }
        }
        .el-table__indent,
        .el-table__placeholder {
            display: none;
        }
    }
}
</style>
