<template>
  <v-dialog
    v-model="isOpen"
    scrollable
    persistent
    content-class="align-self-start mt-14"
    width="600px"
    max-width="90%"
  >
    <v-card>
      <v-card-title class="text-body-1 font-weight-medium mx-6 my-2"> Add Scenario </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-0 text--primary">
        <div class="container px-12">
          <v-text-field
            color="primary"
            v-model="scenario.scenario_name"
            label="Name"
            hide-details="auto"
            required
            class="my-4"
          />
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2">
          <v-col>
            <v-btn
              color="primary"
              depressed
              text
              @click="handleCancel"
            >Cancel</v-btn>
            <v-btn
              color="primary"
              depressed
              outlined
              :disabled="!isScenarioValid"
              @click="handleSave"
              class="mx-4"
            >Save</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ScenarioModal',
  data () {
    return {
      scenario: {
        scenario_name: '',
      },
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isScenarioValid () {
      return this.scenario.scenario_name.length > 0 && this.scenario.scenario_name.toLowerCase() !== 'production'
    }
  },
  methods: {
    handleCancel () {
      this.$emit('close')
    },
    handleSave () {
      this.$emit('close')
      this.$emit('save', { ...this.scenario })
      this.scenario.scenario_name = ''
    },
  },
}
</script>

<style scoped>

</style>
