import StorePageLayout from '@/views/modules/StorePageLayout.vue'
import StorePageTransfersPrint from '@/storepage/StorePageTransfersPrint.vue'
import StorePageResendToken from '@/storepage/StorePageResendToken.vue'
import StorePageTransfers from '@/storepage/StorePageTransfers.vue'
import StorePageOverview from '@/storepage/StorePageOverview.vue'
import StorePageHome from '@/storepage/StorePageHome.vue'

export const storeRoutes = {
  path: '/store',
  component: StorePageLayout,
  children: [
    {
      // Match only numbers
      path: ':store_key(\\d+)',
      name: 'storepage',
      redirect: ':store_key/home'
    },
    {
      path: ':store_key/home',
      name: 'storepage-home',
      // To be changed
      component: StorePageHome
    },
    {
      path: ':store_key/overview',
      name: 'storepage-overview',
      // To be changed
      component: StorePageOverview
    },
    {
      path: ':store_key/transfers',
      name: 'storepage-transfers',
      component: StorePageTransfers
    },
    {
      path: 'resend-token',
      name: 'storepage-resend-token',
      component: StorePageResendToken
    },
    {
      path: ':store_key/transfers/print',
      name: 'store-transfers-print',
      component: StorePageTransfersPrint
    },
  ]
}

export const storepageRoutes = {
  path: '/storepage',
  component: StorePageLayout,
  children: [
    {
      path: ':store_key/transfers/print',
      name: 'storepage-transfers-print',
      component: StorePageTransfersPrint
    }
  ]
}
