<template>
  <div class="assortment-group-table">
    <h6 v-if="!optionKey" class="text-subtitle-1 font-weight-medium mt-8 ml-6">Group level Capacity</h6>
    <h6 v-else class="text-subtitle-1 font-weight-medium mt-2 ml-4">Option group level capacity</h6>
    <v-row>
      <div class="col text-right pr-6">
        <TableSearch class="mx-2"
          @searchChanged="val => handleSearchChanged('capacityTableFilters', columnsToSearch, val)" />
        <v-icon v-if="!optionKey" key="action1" class="mx-2 rotate270 action-btn-primary" :disabled="!canMoveUp"
          @click="handleMoveUp">last_page</v-icon>
        <v-icon v-if="!optionKey" key="action2" class="mx-2 rotate90 action-btn-primary" :disabled="!canMoveDown"
          @click="handleMoveDown">last_page</v-icon>
        <v-icon class="mx-2 action-btn-primary" :disabled="!selectedRows.length" @click="handleEditClick">edit</v-icon>
        <v-icon class="mx-2 action-btn-primary" @click="handleAddClick">add</v-icon>
        <v-icon class="mx-2 action-btn-danger" :disabled="!selectedRows.length || selectedRows[0].in_use"
          @click="handleDeleteClick">delete</v-icon>

        <v-menu offset-y offset-x left nudge-bottom="5" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on"
            >filter_list
            </v-icon>
          </template>
          <v-card max-height="400" class="overflow-auto">
            <h5 class="px-2 pt-2">Columns</h5>
            <el-checkbox-group v-model="selectedColumns" @input="saveSelectedColumns" style="font-size: 14px"
              class="d-flex flex-column pa-2">
              <el-checkbox v-for="item of columnsToSelect" :label="item" :key="item"></el-checkbox>
            </el-checkbox-group>
          </v-card>
        </v-menu>
      </div>
    </v-row>

    <v-row>
      <div class="col">
        <data-tables
          two-rows-column
          style="width: 100%" :key="tableKey" :data="capacityGroups" :page-size="5"
          :current-page="currentPage"
          @update:currentPage="currentPage = $event"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :table-props="{ 'row-key': row => row.capacity_groups_key }" :filters="capacityTableFilters" filter-multiple
          @filter-change="filters => handleFilterChange('capacityTableFilters', filters)" @row-click="handleRowClick">
          <data-tables-column width="55">
            <template v-slot="scope">
              <v-simple-checkbox color="primary" dense hide-details class="mt-0 ml-4 pt-0" :value="scope.row.selected"
                :ripple="false" @input="handleSelectRowChange($event, scope.row)" />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Option set')"
            prop="options_set_key" column-key="options_set_key" label="Option set" sortable="custom"
            :filters="getColumnFilterValues('capacityGroups', 'options_set_key', getOptionByKey)">
            <template v-slot="scope">
              <span>{{ getOptionByKey(scope.row.options_set_key) }}</span>
              <v-chip label outlined small color="#4FB4AB" class="mr-8 ml-4"
                v-if="getOptionCountByKey(scope.row.options_set_key)"
                @click.stop="handleRowOptionSetClick(scope.row)">
                {{ getOptionCountByKey(scope.row.options_set_key) }}
              </v-chip>
            </template>
          </data-tables-column>
          <data-tables-column

            v-if="selectedColumns.includes('Store set')"
            prop="stores_set_key" column-key="stores_set_key" label="Store set" sortable="custom"
            :filters="getColumnFilterValues('capacityGroups', 'stores_set_key', getStoreSetByKey)">
            <template v-slot="scope">
              <span>{{ getStoreSetByKey(scope.row.stores_set_key) }}</span>
              <v-chip label outlined
                v-if="getStoreCountByKey(scope.row.stores_set_key)"
                small color="#4FB4AB" class="mr-8 ml-4"
                @click.stop="handleRowStoreSetClick(scope.row)">
                {{ getStoreCountByKey(scope.row.stores_set_key) }}
              </v-chip>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Stores')"
            prop="store_keys" width="200" column-key="store_keys" label="Stores" sortable="custom"
            :filters="getColumnFilterValues('capacityGroups', 'store_keys', (store_key)=> stores?.find(s => s.store_key === store_key)?.store_name)">
            <template v-slot="scope">
              <ChipsDropdown :key="scope.row.store_keys?.length" :items="getStoresByKeys(scope.row.store_keys)" />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Type')"
            prop="capacity_type" label="Type" sortable="custom" column-key="capacity_type"
            :filters="getColumnFilterValues('capacityGroups', 'capacity_type', humanizeCapacityType)">
            <template v-slot="scope">
              {{ humanizeCapacityType(scope.row.capacity_type) }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Low level')"
            prop="low_level" label="Low level" sortable="custom" column-key="low_level"
            :filters="getColumnFilterValues('capacityGroups', 'low_level')">
            <template v-slot="scope">
              <v-simple-checkbox color="primary" disabled dense hide-details class="mt-0 ml-4 pt-0" :value="scope.row.low_level"/>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Dimensions')"
            prop="dimensions" column-key="dimensions" label="Dimensions" sortable="custom"
            :filters="getColumnFilterValues('capacityGroups', 'dimensions')">
            <template v-slot="scope">
              <ChipsDropdown :items="scope.row.dimensions || []" />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('SKUs')"
            prop="sku_names" column-key="sku_names" label="SKUs" sortable="custom"
            :filters="getColumnFilterValues('capacityGroups', 'sku_names')">
            <template v-slot="scope">
              <ChipsDropdown :items="scope.row.sku_names || []" />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Start')"
            prop="date_min" label="Start" sortable="custom"
            width="100"
            column-key="date_min"
            :filters="getColumnFilterValues('capacityGroups', 'date_min')">
            <template v-slot="scope">
              {{ scope.row.date_min }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('End')"
            prop="date_max" label="End" sortable="custom"
            width="100"
            column-key="date_max"
            :filters="getColumnFilterValues('capacityGroups', 'date_max')">
            <template v-slot="scope">
              {{ scope.row.date_max }}
            </template>
          </data-tables-column>
          <data-tables-column
            :filters="getColumnFilterValues('capacityGroups', 'must_have')"
            column-key="must_have"
            v-if="selectedColumns.includes('Must')"
            prop="must_have" label="Must" width="60" sortable="custom">
            <template v-slot="scope">
              {{ scope.row.must_have }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Nice')"
            :filters="getColumnFilterValues('capacityGroups', 'nice_have')"
            column-key="nice_have"
            prop="nice_have" label="Nice" width="60" sortable="custom">
            <template v-slot="scope">
              {{ scope.row.nice_have }}
            </template>
          </data-tables-column>
          <data-tables-column
            :filters="getColumnFilterValues('capacityGroups', 'soft_max_have')"
            column-key="soft_max_have"
            v-if="selectedColumns.includes('Soft')"
            prop="soft_max_have" label="Soft" width="60" sortable="custom">
            <template v-slot="scope">
              {{ scope.row.soft_max_have }}
            </template>
          </data-tables-column>
          <data-tables-column prop="max_have"
            :filters="getColumnFilterValues('capacityGroups', 'max_have')"
            column-key="max_have"
            v-if="selectedColumns.includes('Max')"
            label="Max" width="60" sortable="custom">
            <template v-slot="scope">
              {{ scope.row.max_have }}
            </template>
          </data-tables-column>
          <data-tables-column

            :filters="getColumnFilterValues('capacityGroups', 'note')"
            column-key="note"
            v-if="selectedColumns.includes('Note')"
            prop="note" label="Note" sortable="custom">
            <template v-slot="scope">
              {{ scope.row.note }}
            </template>
          </data-tables-column>

          <data-tables-column
            v-if="selectedColumns.includes('Changed at')"
            prop="changed_at" label="Changed at"
            min-width="100"
            sortable column-key="changed_at"
            :filters="getColumnFilterValues('capacityGroups', 'changed_at')">
            <template slot-scope="scope">
              {{ scope.row.changed_at }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Changed by')"
            prop="changed_by" label="Changed by"
            min-width="100"
            sortable column-key="changed_by"
            :filters="getColumnFilterValues('capacityGroups', 'changed_by')">
            <template slot-scope="scope">
              {{ scope.row.changed_by }}
            </template>
          </data-tables-column>
        </data-tables>
      </div>
    </v-row>

    <AddEditDynamicDialog
      @capacity-update="currentCapacity = $event"
      v-if="currentCapacity" :is-open="formDialogVisible"
      :current-capacity-option="{...currentCapacity}" :is-new-option="isNewOption"
      :current-capacity-option-errors="currentCapacityErrors" :skus="skus" @save="handleSaveConfirm"
      @close="handleSaveCancel" />

    <!-- Option set form dialog -->
    <option-set-form-dialog ref="optionSetFormDialog" :is-open="optionSetFormDialogVisible"
      :current-option-set="currentOptionSet" @close="optionSetFormDialogVisible = false" />

    <!-- Store set form dialog -->
    <store-set-form-dialog ref="storeSetFormDialog" :is-open="storeSetFormDialogVisible"
      :current-store-set="currentStoreSet" @close="storeSetFormDialogVisible = false" />

    <!-- Delete dialog -->
    <delete-dialog resource="capacity option" :is-open="deleteDialogVisible" @close="deleteDialogVisible = false"
      @delete="handleDeleteConfirm" />
  </div>
</template>

<script>
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'
import { getDynamicCapacityErrors } from '@/utils'
import DeleteDialog from '@/components/Dialogs/DeleteDialog'
import { getCapacityGroups, removeCapacityGroup, saveCapacityGroup } from '@/api/stockCapacityGroups'
import AddEditDynamicDialog from '@/components/Assortment/AddEditDynamicDialog'
import OptionSetFormDialog from '@/components/Assortment/OptionSets/OptionSetFormDialog'
import StoreSetFormDialog from '@/components/Assortment/StoreSets/StoreSetFormDialog'
import ChipsDropdown from '../ChipsDropdown'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStockManageStore } from '@/store/pinia/stockManageStore'

const columnsToSelect = ['Option set', 'Store set', 'Stores', 'Type', 'Low level', 'Dimensions', 'SKUs', 'Start', 'End', 'Must', 'Nice', 'Soft', 'Max', 'Note', 'Changed at', 'Changed by']
const defaultColumns = ['Option set', 'Store set', 'Stores', 'Type', 'SKUs', 'Start', 'End', 'Must', 'Nice', 'Soft', 'Max', 'Note']

export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  mixins: [columnFilters],
  name: 'GroupTable',
  props: {
    optionKey: {
      default: null
    },
    skus: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      tableKey: 0,
      currentPage: 1,
      oldCurrentPage: 1,
      selectedColumns: JSON.parse(localStorage.getItem('groupLevelCapacitySelectedColumns')) || defaultColumns,
      columnsToSelect,
      optionSetFormDialogVisible: false,
      currentOptionSet: null,
      storeSetFormDialogVisible: false,
      currentStoreSet: null,
      maxMapRank: 999,
      columnsToSearch: ['options_set_name', 'options_set_key', 'stores_set_name', 'stores_set_key', 'store_names',  'capacity_type', 'store_keys', 'note'],
      formDialogVisible: false,
      deleteDialogVisible: false,
      currentCapacity: null,
      isNewOption: false,
      currentCapacityErrors: {
        options: null,
        stores: null,
        mustHave: null,
        niceToHave: null,
        maxHave: null,
      },
      capacityTableFilters: [],
      capacityGroups: [
        {
          org_key: 0,
          capacity_groups_key: 0,
          options_set_key: 18,
          stores_set_key: 1,
          sku_names: ['XS', 'S', 'M'],
          capacity_rank: 1,
          date_min: '2020-01-01',
          date_max: '9999-01-01',
          must_have: 10,
          nice_have: 20,
          max_have: 50,
          store_keys: null,
          capacity_label: 'sellable',
          tags: []
        }
      ],
    }
  },
  components: {
    ChipsDropdown,
    StoreSetFormDialog,
    OptionSetFormDialog,
    AddEditDynamicDialog,
    DeleteDialog,
    TableSearch,
  },
  created () {
    this.getCapacityGroups()
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    stores () {
      return (this.appConfig?.stores || [])
    },
    users () {
      return this.appConfig?.users || []
    },
    maxRealMapRank () {
      const max = Math.max(
        ...this.capacityGroups
          .filter(el => el.capacity_rank !== this.maxMapRank)
          .map(el => el.capacity_rank)
      )
      return max >= 0 ? max : 0
    },
    selectedRows () {
      return this.capacityGroups.filter(r => r.selected)
    },
    canMoveUp () {
      return this.selectedRows.length === 1 && this.selectedRows[0].capacity_rank > 1
    },
    canMoveDown () {
      return this.selectedRows.length === 1 && this.selectedRows[0].capacity_rank < this.maxRealMapRank && this.selectedRows[0].capacity_rank < this.maxMapRank
    },

    editedOptionSet () {
      return this.stockManageStore.capacityTab.editedOptionSet
    },
    editedStoreSet () {
      return this.stockManageStore.capacityTab.editedStoreSet
    },
  },
  watch: {
    // Sometimes the capacityGroup is set before the store and option sets are loaded, so we need to update the capacityGroups for the search to work
    'stockManageStore.capacityTab.capacityOptionSetsList': {
      handler () {
        this.capacityGroups = this.mapCapacityGroups(this.capacityGroups)
        this.tableKey++
      },
      deep: true
    }
  },
  methods: {
    saveSelectedColumns (value) {
      localStorage.setItem('groupLevelCapacitySelectedColumns', JSON.stringify(value))
    },
    getUserById (id){
      const user = this.users.find(user=> user.id === id)
      if(!user) {
        return ''
      }
      return `${user.first_name} ${user.last_name}`
    },
    humanizeCapacityType (capacityType) {
      const capacityTypes = {
        'pieces': 'Pieces',
        'options': 'Options',
        'unique_skus': 'Unique SKUs',
        'serial_skus': 'Serial SKUs',
      }
      return capacityTypes[capacityType] || capacityType
    },
    getStoresByKeys (storeKeys) {
      if (!storeKeys) {
        return []
      }
      return this.stores?.filter(s => storeKeys?.includes(s.store_key)).map(s => s?.store_name)
    },
    async reRankCapacityGroups () {
      let rankChanged = false
      // check if each capacity group has unique capacity_rank
      for (let i = 1; i <= this.capacityGroups.length; i++) {
        if (this.capacityGroups[i - 1].capacity_rank !== i) {
          this.capacityGroups[i - 1].capacity_rank = i
          rankChanged = true
        }
      }
      if (rankChanged) {
        await saveCapacityGroup(this.capacityGroups)
        await this.getCapacityGroups()
      }
    },
    sortCapacityGroups () {
      this.capacityGroups.sort((a, b) => a.capacity_rank - b.capacity_rank)
    },
    mapCapacityGroups (groups) {
      return groups.map(item => {
        item['date_min'] = item['date_min'] || ''
        item['date_max'] = item['date_max'] || ''
        item['changed_at'] = item['changed_at']?.substring(0, 10) || ''
        item['stores_set_name'] = this.getStoreSetByKey(item.stores_set_key) || item.stores_set_key
        item['options_set_name'] = this.getOptionByKey(item.options_set_key) || item.options_set_key
        item['store_names'] = this.getStoresByKeys(item.store_keys) || []

        return item
      }).sort((a, b) => a.capacity_rank - b.capacity_rank)
    },
    async getCapacityGroups () {
      const groups = (await getCapacityGroups(this.optionKey))
      for (let i = 0; i < groups.length; i++) {
        for (let j = 0; j < this.capacityGroups.length; j++) {
          if (groups[i].capacity_groups_key === this.capacityGroups[j].capacity_groups_key) {
            groups[i].selected = this.capacityGroups[j].selected
          }
        }
      }
      setTimeout(() => {
        this.capacityGroups = this.mapCapacityGroups(groups)
        this.tableKey++
      }, 100)
      await this.reRankCapacityGroups()
    },
    getStoreSetByKey (storeKey) {
      if (!storeKey || !this.stockManageStore.capacityTab.capacityStoreSetsList) {
        return ''
      }
      const arr = this.stockManageStore.capacityTab.capacityStoreSetsList.filter(s =>
        storeKey === s.stores_set_key).map(s => s.set_name)
      return arr.length > 0 ? arr[0] : ''
    },
    getStoreCountByKey (storeKey) {
      if (!storeKey || !this.stockManageStore.capacityTab.capacityStoreSetsList) {
        return ''
      }
      const arr = this.stockManageStore.capacityTab.capacityStoreSetsList.filter(s =>
        storeKey === s.stores_set_key).map(s => s.stores)
      return arr.length > 0 ? arr[0] : 0
    },
    getOptionByKey (optionKey) {
      if (!optionKey || !this.stockManageStore.capacityTab.capacityOptionSetsList) {
        return ''
      }
      const arr = this.stockManageStore.capacityTab.capacityOptionSetsList.filter(s =>
        optionKey === s.options_set_key).map(s => s.set_name)
      return arr.length > 0 ? arr[0] : ''
    },
    getOptionCountByKey (optionKey) {
      if (!optionKey || !this.stockManageStore.capacityTab.capacityOptionSetsList) {
        return ''
      }
      const arr = this.stockManageStore.capacityTab.capacityOptionSetsList.filter(s =>
        optionKey === s.options_set_key).map(s => s.options)
      return arr.length > 0 ? arr[0] : 0
    },
    handleEditClick () {
      this.formDialogVisible = true
      this.isNewOption = false
      this.currentCapacity = this.selectedRows[0]
    },
    handleAddClick () {
      this.currentCapacity = Object.assign({
        capacity_groups_key: 0,
        options_set_key: 0,
        stores_set_key: 0,
        capacity_rank: (this.maxRealMapRank || 0) + 1,
        sku_names: [],
        store_keys: [],
        date_min: null,
        date_max: null,
        must_have: undefined,
        nice_have: undefined,
        max_have: undefined,
        capacity_label: '',
        tags: [],
      })
      this.formDialogVisible = true
      this.isNewOption = true
    },
    handleDeleteClick () {
      this.deleteDialogVisible = true
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
    },
    async handleDeleteConfirm () {
      await removeCapacityGroup(this.selectedRows[0])
      this.capacityGroups.findIndex((el, i) => {
        if (el.capacity_groups_key === this.selectedRows[0].capacity_groups_key) {
          this.capacityGroups.splice(i, 1)
          return true
        }
      })
      await this.reRankCapacityGroups()
      this.deleteDialogVisible = false
    },
    async handleSaveConfirm (value) {
      this.currentCapacity = value
      if (!this.validateCapacity()) {
        return
      }
      try {
        await saveCapacityGroup(this.currentCapacity)
        await this.getCapacityGroups()
        this.formDialogVisible = false
        this.resetForm()
      } catch (e) {
        console.log(e)
      }
    },
    handleSaveCancel () {
      this.formDialogVisible = false
      this.resetForm()
    },
    validateCapacity () {
      this.resetFormErrors()
      this.currentCapacityErrors = getDynamicCapacityErrors(this.currentCapacity)
      return !this.formHasErrors()
    },
    resetForm () {
      this.currentCapacity = null
      this.resetFormErrors()
    },
    resetFormErrors () {
      const errors = this.currentCapacityErrors

      Object.keys(errors).forEach(key => {
        errors[key] = null
      })
    },
    formHasErrors () {
      return !Object.values(this.currentCapacityErrors).every(val => val === null)
    },
    handleOptionKeyDeleteClick (index) {
      this.$delete(this.currentCapacity.option_keys, index)
    },
    handleMoveUp () {
      this.oldCurrentPage = this.currentPage
      if (this.selectedRows.length < 1) {
        return
      }
      const selectedMapping = this.selectedRows[0]
      const payload = []

      if (selectedMapping.capacity_rank === 1) {
        return
      }
      this.capacityGroups.forEach((el, i) => {
        if (el.capacity_rank === selectedMapping.capacity_rank - 1) {
          payload[i + 1] = {
            ...el,
            capacity_rank: el.capacity_rank + 1
          }
        } else if (el.capacity_rank === selectedMapping.capacity_rank) {
          payload[i - 1] = {
            ...el,
            capacity_rank: el.capacity_rank - 1
          }
        } else {
          payload[i] = el
        }
      })
      this.sortCapacityGroups()
      saveCapacityGroup(payload).then(() => {

        this.getCapacityGroups()
      })
    },
    handleMoveDown () {
      this.oldCurrentPage = this.currentPage

      if (this.selectedRows.length < 1) {
        return
      }
      const selected = this.selectedRows[0]
      const payload = []

      if (selected.capacity_rank === this.maxRealMapRank) {
        return
      }

      this.capacityGroups.forEach((el, i) => {
        if (el.capacity_rank === selected.capacity_rank) {
          payload[i + 1] = {
            ...el,
            capacity_rank: el.capacity_rank + 1
          }
        } else if (el.capacity_rank === selected.capacity_rank + 1) {
          payload[i - 1] = {
            ...el,
            capacity_rank: el.capacity_rank - 1
          }
        } else {
          payload[i] = el
        }
      })
      this.sortCapacityGroups()
      saveCapacityGroup(payload).then(() => {
        this.getCapacityGroups()
      })
    },
    optionSetFormRef () {
      return this.$refs.optionSetFormDialog.$refs.optionSetForm
    },
    storeSetFormRef () {
      return this.$refs.storeSetFormDialog.$refs.storeSetForm
    },
    handleRowOptionSetClick (row) {
      if (!row.options_set_key) {
        return
      }
      const payload = {
        tab: 'capacity',
        key: row.options_set_key,
      }
      this.currentOptionSetEditMode = false
      this.optionSetFormDialogVisible = true
      this.stockManageStore.getOptionSet(payload)
        .then(() => {
          this.currentOptionSet = { ...this.editedOptionSet }
          this.$nextTick(this.optionSetFormRef().searchOptions)
        })
    },
    handleRowStoreSetClick (row) {
      const payload = {
        tab: 'capacity',
        key: row.stores_set_key,
      }
      this.currentStoreSetEditMode = false
      this.storeSetFormDialogVisible = true
      this.stockManageStore.getStoreSet(payload)
        .then(() => {
          this.currentStoreSet = { ...this.editedStoreSet }
          this.$nextTick(this.storeSetFormRef().searchStores)
        })
    },
  }
}
</script>

<style lang="scss">
</style>
