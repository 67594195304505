import axios from '../services/axios'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'
import authService from '@/auth/Auth'

export const getPropertiesOfStores = async (objectName) => {
  const url = baseURLs.manageAPI + `/property?object_name=${objectName}&from=true`
  try {
    const response = await axios.get(url)
    return response.data.properties.map(applyBooleanFilter)
  } catch (e) {
    console.log(e)
    return []
  }
}

export const getPropertyManagementObjects = () => {
  const url = baseURLs.manageAPI + '/property'

  return axios.get(url, {
    headers: getHeadersWithAccessToken(),
  }).then(res => {
    res.data.properties = res.data.properties.map(applyBooleanFilter)
    return res.data
  })
    .catch(err => {
      console.log(err)
      return [] // return empty array, No need for special error handling
    })
}

export const savePropertyOnObject = async (propertyObject) => {
  const url = baseURLs.manageAPI + '/property'
  return axios.post(url, applyReverseBooleanFilter(propertyObject), {
    headers: getHeadersWithAccessToken(),
  }).then(res => {
    res.data.properties = res.data.properties.map(applyBooleanFilter)
    authService.refreshAppConfig().catch(console.error)
    return res.data
  }).catch(console.error)
}

export const removePropertyOnObject = (object_name, properties) => {
  const url = baseURLs.manageAPI + '/property'
  properties = properties.map(v => {
    return {
      property_name: v.property_name,
      object_name: object_name.toLowerCase()
    }
  })
  return axios.delete(url, {
    headers: getHeadersWithAccessToken(),
    data: properties
  }).then(res => res.data).catch(console.log)
}

export const getPropertyValues = (objectName, propertyName) => {
  const url = baseURLs.manageAPI +
        `/property_values?object_name=${objectName.toLowerCase()}&property_name=${propertyName}`

  return axios.get(url, {
    headers: getHeadersWithAccessToken(),
  }).then(res => {
    if (!res.data || !res.data.properties) {
      return null
    }
    res.data.properties = res.data.properties.map(applyBooleanFilter)
    return res.data
  })
    .catch(err => {
      console.log(err)
      return [] // return empty array, No need for special error handling
    })
}

export const getObjectValues = (objectName) => {
  const url = baseURLs.manageAPI +
        `/data/settings/property/object_values?object_name=${objectName.toLowerCase()}`
  return axios.post(url, { object_name: objectName, ignore_cache: true }, {
    headers: getHeadersWithAccessToken(),
  }).then(res => res.data || null)
    .catch(err => {
      console.log(err)
      return [] // return empty array, No need for special error handling
    })
}

export const savePropertyValue = (value) => {
  const url = baseURLs.manageAPI + '/property_values'
  return axios.post(url, applyReverseBooleanFilter(value), {
    headers: getHeadersWithAccessToken(),
  }).then(res => {
    res.data.properties = res.data.properties.map(applyBooleanFilter)
    return res.data
  }).catch(console.log)
}

export const deletePropertyValue = (values) => {
  const url = baseURLs.manageAPI + '/property_values'
  return axios.delete(url, {
    headers: getHeadersWithAccessToken(),
    data: values,
  }).then(res => res.data).catch(console.log)
}

export const importPropertiesBatch = (properties) => {
  const url = baseURLs.manageAPI + '/property_values_batch'
  return axios.post(url, {
    properties
  }, {
    headers: getHeadersWithAccessToken(),
  }).then(res => res.data).catch(console.log)
}

function applyBooleanFilter (prop) {
  if (prop.value_type === 'boolean') {
    prop.value_default = prop.value_default === 'True' || prop.value_default === true
    prop.value = prop.value === 'True' || prop.value === true
  }
  return prop
}

function applyReverseBooleanFilter (prop) {
  if (prop.value_type === 'boolean') {
    prop.value_default = prop.value_default === true ? 'True' : 'False'
    prop.value = prop.value === true ? 'True' : 'False'
  }
  return prop
}
