import axios from '@/services/axios.js'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

export function savePayment (payload) {
  const paymentsUrl = baseURLs.manageAPI + '/payment'

  return axios.post(paymentsUrl, payload)
    .then(({ data: { payment } }) => payment)
    .catch((error) => console.log(error))
}

export function deletePayment (payload) {
  const paymentsUrl = baseURLs.manageAPI + '/payment'
  return axios.delete(paymentsUrl, { headers: getHeadersWithAccessToken(), data: payload })
    .then(({ data: { payment } }) => payment)
    .catch((error) => console.log(error))
}

export function deleteSchedule (payload) {
  const url = baseURLs.manageAPI + '/schedule'

  return axios.delete(url, { headers: getHeadersWithAccessToken(), data: payload })
    .then(({ data: { payment } }) => payment)
    .catch((error) => console.log(error))
}

export function saveSchedule (payload) {
  const url = baseURLs.manageAPI + '/schedule'

  return axios.post(url, payload)
    .then(({ data: { schedule } }) => schedule)
    .catch((error) => console.log(error))
}
