<template>
  <v-row class="pa-5 pt-0" v-if="property">
    <v-row>
      <div class="col text-right pr-6">
        <TableSearch
          class="mx-2"
          @searchChanged="v => this.searchQuery = v"
        />
        <v-icon class="mx-2 action-btn-primary" :disabled="selectedRows.length !== 1" @click="handleEditClick">edit</v-icon>
        <v-menu offset-y :close-on-content-click="true" nudge-bottom="5">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mx-2 action-btn-primary c-pointer">add</v-icon>
          </template>
          <v-list dense>
            <v-list-item
              class="c-pointer"
              @click="handleAddSingle"
            >
              <v-list-item-title>Add new value</v-list-item-title>
            </v-list-item>
            <v-list-item
              class="c-pointer"
              @click="handleAddBatch"
            >
              <v-list-item-title>Add batch</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-icon class="mx-2 action-btn-danger" :disabled="selectedRows.length < 1" @click="handleDeleteClick">delete</v-icon>
        <data-export @item-click="downloadTableData"/>
      </div>
    </v-row>
    <data-tables
      style="width: 100%"
      :data="filteredValues"
      :page-size="10"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
      :table-props="{ 'row-key': 'properties_key' }"
      :filters="tableFilters"
      filter-multiple
      @filter-change="f => handleFilterChange('tableFilters', f)"
      @row-click="handleRowClick"
    >
      <el-table-column prop="properties_key" width="55">
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="mt-0 ml-2 pt-0"
            :value="scope.row.selected"
            :ripple="false"
            @input="handleSelectRowChange($event, scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="property_name"
        column-key="property_name"
        label="Property Name"
        sortable="custom"
        :filters="getColumnFilterValues('filteredValues', 'property_name')"
      >
        <template slot-scope="scope">
          <span class="font-weight-medium text--black size">{{ scope.row.property_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="value"
        column-key="value"
        label="Value"
        sortable="custom"
        :filters="getColumnFilterValues('filteredValues', 'value')"
      >
        <template slot-scope="scope">
          <span class="font-weight-medium text--black"> {{ scope.row.value }} </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="value_alias"
        column-key="value_alias"
        label="Alias"
        :sortable="true"
        :filters="getColumnFilterValues('filteredValues', 'value_alias')"
      >
        <template slot-scope="scope">
          <span class="font-weight-medium text--black"> {{ scope.row.value_alias }} </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="value_map"
        column-key="value_map"
        label="Map"
        :sortable="true"
        :filters="getColumnFilterValues('filteredValues', 'value_map')"
      >
        <template slot-scope="scope">
          <span class="font-weight-medium text--black"> {{ scope.row.value_map }} </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="active"
        column-key="active"
        label="Active"
        :sortable="true"
        :filters="getColumnFilterValues('filteredValues', 'active')"
      >
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            disabled
            class="mt-0 pt-0"
            :value="scope.row.active"
            :ripple="false"
          />
        </template>
      </el-table-column>
    </data-tables>
    <value-modal
      ref="valueEditModal"
      :isOpen="editModalOpen" :property="property" :objectName="objectName"
      @save="saveValue" @close="editModalOpen = false"
    />
    <batch-upload-modal :isOpen="batchUploadModalOpen" ref="batchUploadModal" @close="batchUploadModalOpen = false" @upload="uploadBatch"/>
    <delete-dialog
      content-text="Are you sure you want to delete those properties?"
      content-title="Delete Properties"
      :visible="deleteModalOpen"
      @confirm="handleDelete"
      @cancel="deleteModalOpen = false"
    />
  </v-row>
</template>

<script>
import DataExport from '@/components/DataExport'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'
import {
  importPropertiesBatch,
  deletePropertyValue,
  getPropertyValues,
  savePropertyValue,
} from '@/api/dataProperties'
import ValueModal from '@/components/StockSettings/ValueModal'
import { downloadData } from '@/utils'
import BatchUploadModal from '@/components/StockSettings/BatchUploadModal'
import DeleteDialog from '@/components/DeleteDialog'
export default {
  mixins: [columnFilters],
  name: 'ValuesTable',
  components: {
    DeleteDialog,
    BatchUploadModal,
    ValueModal,
    TableSearch,
    DataExport,
  },
  data () {
    return {
      tableFilters: [],
      searchQuery: '',
      values: [],
      editModalOpen: false,
      deleteModalOpen: false,
      batchUploadModalOpen: false
    }
  },
  props: {
    objectName: {
      type: String,
      required: true,
    },
    property: {
      type: Object,
      default: () => ({}),
    },
  },
  beforeMount () {
    this.loadValues()
  },
  computed: {
    filteredValues () {
      if (this.searchQuery === '') {
        return this.values
      }
      return this.values.filter(r => {
        return r.value.toLowerCase().includes(this.searchQuery.toLowerCase())
      })
    },
    selectedRows () {
      return this.values.filter(r => r.selected)
    },
  },
  watch: {
    property () {
      this.loadValues()
    },
  },
  methods: {
    refreshProperties () {
      if (this.$refs.valueEditModal) {
        this.$refs.valueEditModal.getProperties()
      }
    },
    async loadValues () {
      if (!this.property || !this.objectName) {
        return
      }
      const res = await getPropertyValues(this.objectName, this.property.property_name)
      if (!res || !res.properties) {
        return
      }
      this.values = res.properties ? res.properties : []
    },
    handleAddSingle () {
      this.$refs.valueEditModal.setLocalValue(null)
      this.editModalOpen = true
    },
    handleEditClick () {
      this.$refs.valueEditModal.setLocalValue(this.selectedRows[0])
      this.editModalOpen = true
    },
    async saveValue (value) {
      value = {
        ...value,
        object_name: this.objectName.toLowerCase(),
      }
      const { properties } = await savePropertyValue(value)
      this.mergeWithUpdatedProperties(properties)
    },
    handleDeleteClick () {
      this.deleteModalOpen = true
    },
    async handleDelete () {
      const res = await deletePropertyValue(
        this.selectedRows.map(v => {
          return {
            object_name: this.objectName.toLowerCase(),
            property_name: v.property_name,
            value: v.value,
          }
        })
      )
      if (!res && !res.properties) {
        return
      }
      this.$emit('refresh')
      this.deleteModalOpen = false
      await this.loadValues()
    },
    mergeWithUpdatedProperties (updatedProperties) {
      // Merge updated properties with current properties
      updatedProperties.forEach((updatedProperty) => {
        // If updated property has same property_name and value as current property, update current property
        let updated = false
        this.values.forEach((current, i) => {
          if (current.property_name === updatedProperty.property_name && current.value === updatedProperty.value) {
            // Reactively update current property
            this.$set(this.values[i], 'value_alias', updatedProperty.value_alias)
            this.$set(this.values[i], 'value_map', updatedProperty.value_map)
            this.$set(this.values[i], 'active', updatedProperty.active)
            updated = true
          }
        })
        if (!updated) {
          // If updated property is not in current properties, add it in front
          this.values = [updatedProperty, ...this.values]
        }
      })
    },
    handleAddBatch () {
      this.batchUploadModalOpen = true
    },
    async uploadBatch (rows) {
      rows = rows.map(row => {
        return {
          object_name: row.object_name || this.objectName.toLowerCase(),
          property_name: row.property_name,
          value: row.value,
          value_alias: row.value_alias ? row.value_alias : '',
          value_map: row.value_map ? row.value_map : '',
          active: !!row.active,
          force: !!row.force,
        }
      })
      rows = rows.filter(row => row.property_name && row.value)
      try {
        const res = await importPropertiesBatch(rows)
        if (!res) {
          this.$refs.batchUploadModal.setError('Unexpected failure')
          return
        }
        const newProperties = res.new_properties.filter(row => row.object_name === this.objectName.toLowerCase())
        this.values = [...newProperties, ...this.values]
        this.$refs.batchUploadModal.setError(null)
        this.batchUploadModalOpen = false
      } catch (e) {
        this.$refs.batchUploadModal.setError('Unexpected failure')
      }
    },
    downloadTableData (sep) {
      const data = this.values.map(row => {
        return {
          object_name: row.object_name,
          property_name: row.property_name,
          value: row.value,
          value_alias: row.value_alias || '',
          value_map: row.value_map || '',
          active: row.active
        }
      })
      downloadData(sep, data, `${this.objectName}_properties`)
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    unselectAll () {
      this.values.forEach(r => this.$set(r, 'selected', false))
    },
    handleSelectRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
  }
}
</script>

<style scoped>

</style>
