<template>
  <v-row class="ma-0 pt-6 flex-column" v-if="tradeOffData">
    <h4 class="chart-title pa-6 pt-2">
      Pieces vs. Value
    </h4>
    <ChartElement
      v-if="tradeOffData"
      title="markdown_tradeoff_graph"
      ref="markdown_tradeoff_graph"
      :params="{}"
      :chartData="tradeOffData"
      :padding="false"
      :fixedHeight="false"
    />
  </v-row>
</template>

<script>
import ChartElement from '@/chart/ChartElement'
export default {
  name: 'MarkdownTradeoff',
  components: { ChartElement },
  props: {
    tradeOffData: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style scoped>

</style>
