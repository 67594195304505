<template>
  <div class="pa-4 pb-12">
    <v-row>
      <v-col :cols="6">
        <h4 class="mt-2 mb-3">Managed</h4>
      </v-col>
      <v-col :cols="6">
        <h4 class="mt-2 mb-3">Other</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="6">
        <v-row
          v-for="(prop, index) in managedProperties"
          :key="index"
          v-show="!variantProperties.includes(prop.property_name)"
        >
          <v-col :cols="5" :class="{'py-0 my-3': !prop.value_dropdown && prop.value !== 'boolean'}">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              :value="prop.property_name"
              item-text="property_name"
              item-value="property_name"
              disabled
              :items="managedProperties"
              label="Property"
              hide-details
            />
          </v-col>
          <v-col :cols="5"
            class="d-flex align-center"
            :class="{'py-0 my-1': !prop.value_dropdown && prop.value !== 'boolean'}">
            <v-simple-checkbox
              v-if="prop.value_type === 'boolean'"
              :ref="prop.property_name"
              v-model="dummy.properties[prop.property_name]"
              :error-messages="prop.errors"
              color="primary"
              dense
              hide-details="auto"
              class="mt-0 pt-0"
              :ripple="false"
              @input="handlePropValueChange(prop)"
            />
            <v-autocomplete
              v-else-if="prop.value_dropdown && prop.values"
              :ref="prop.property_name"
              v-model="dummy.properties[prop.property_name]"
              @input="dummy.properties[prop.property_name] = $event || null"
              :items="prop.values"
              label="Value"
              hide-details="auto"
              :error-messages="
                prop.force && !dummy.properties[prop.property_name] ? ['This is a required field'] :
                (prop.errors ? prop.errors : [])
              "
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
              @change="handlePropValueChange(prop)" />
            <v-text-field
              v-else-if="prop.value_type === 'text'"
              :ref="prop.property_name"
              @input="dummy.properties[prop.property_name] = $event || null"
              v-model="dummy.properties[prop.property_name]"
              label="Value"
              hide-details="auto"
              :error-messages="
                prop.force && !dummy.properties[prop.property_name] ? ['This is a required field'] :
                (prop.errors ? prop.errors : [])
              "
              type="text"
              @keyup.enter="handlePropValueChange(prop)"
              @blur="handlePropValueChange(prop)"
            />
            <v-text-field
              v-else
              :ref="prop.property_name"
              @input="dummy.properties[prop.property_name] = $event || null"
              v-model.number="dummy.properties[prop.property_name]"
              label="Value"
              hide-details="auto"
              :error-messages="
                prop.force && !dummy.properties[prop.property_name] ? ['This is a required field'] :
                (prop.errors ? prop.errors : [])
              "
              type="number"
              @keyup.enter="handlePropValueChange(prop)"
              @blur="handlePropValueChange(prop)"
            />
          </v-col>
          <v-col v-if="!prop.force" :cols="1">
            <v-icon
              class="mt-5 action-btn-danger"
              @click="handlePropDeleteClick(prop)"
            >delete</v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0" v-if="!addManaged">
          <v-col cols="7">
            <v-icon class="plus-icon" @click="addManaged = true">add</v-icon>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col :cols="5">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              v-model="newManaged"
              item-text="property_name"
              return-object
              :items="remainingManagedProperties"
              label="Property"
              hide-details
              @change="handleNewManagedChanged"
            />
          </v-col>
          <v-col :cols="5" v-if="newManaged.property_name">
            <v-autocomplete
              v-if="newManaged.value_dropdown && newManaged.values"
              v-model="newManaged.value"
              :items="newManaged.values"
              label="Value"
              hide-details="auto"
              :error-messages="managedErrors"
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
              @keyup.enter="saveManaged"
              @blur="saveManaged"
            />
            <v-text-field
              v-else-if="newManaged.value_type !== 'boolean'"
              v-model="newManaged.value"
              label="Value"
              hide-details="auto"
              :error-messages="managedErrors"
              :type="newManaged.value_type === 'text' ? 'text' : 'number'"
              @keyup.enter="saveManaged"
              @blur="saveManaged"
            />
            <v-simple-checkbox
              v-else
              v-model="newManaged.value"
              color="primary"
              dense
              hide-details
              class="mt-0 pt-0"
              :ripple="false"
              @input="saveManaged"
            />
          </v-col>
          <v-col cols="3" v-if="addManaged">
            <v-icon v-if="newManaged.property_name" class="plus-icon mt-5 mr-2" @click="saveManaged">save</v-icon>
            <v-icon class="plus-icon mt-5" @click="clearAddManaged">close</v-icon>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row
          v-for="(prop, index) in otherProperties"
          :key="index"
          v-show="!variantProperties.includes(prop.property_name)"
        >
          <v-col :cols="5">
            <v-text-field
              :value="prop.property_name"
              disabled
              label="Property"
              hide-details
            />
          </v-col>
          <v-col :cols="5">
            <v-autocomplete
              v-if="prop.values.length > 0"
              v-model="dummy.properties[prop.property_name]"
              hide-details
              :items="prop.values"
              :error-messages="prop.errors"
              label="Value"
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
              @change="handlePropValueChange(prop)"
            />
            <v-text-field
              v-else
              v-model="dummy.properties[prop.property_name]"
              label="Value"
              hide-details="auto"
              :error-messages="prop.errors"
              @keyup.enter="handlePropValueChange(prop)"
              @blur="handlePropValueChange(prop)"
            />
          </v-col>
          <v-col v-if="!prop.force" :cols="1">
            <v-icon
              class="mt-5 action-btn-danger"
              @click="handlePropDeleteClick(prop)"
            >delete</v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0" v-if="!addOther">
          <v-col cols="7">
            <v-icon class="plus-icon" @click="addOther = true">add</v-icon>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col :cols="5">
            <v-autocomplete
              v-model="newOther.property_name"
              :items="getAvailableProps()"
              label="Property"
              hide-details="auto"
              :error-messages="otherErrors"
              @change="checkProp"
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"/>
          </v-col>
          <v-col :cols="5">
            <v-autocomplete
              v-if="newOther.property_name"
              v-model="newOther.value"
              :items="getPropertyValuesOptions()"
              label="Value"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
              @change="saveOther" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getObjectValues } from '@/api/dataProperties'
import { getDummyStatus } from '@/utils'
import * as dummiesApi from '@/api/dummies'

export default {
  name: 'DummyProperties',
  data () {
    return {
      addManaged: false,
      addOther: false,
      newManaged: {
        property_name: '',
        value_type: 'text',
        value_dropdown: false,
        value: '',
        values: [],
      },
      newOther: {
        property_name: '',
        value_type: 'text',
        value_dropdown: true,
        values: [],
        value: '',

      },
      managedErrors: [],
      otherErrors: [],
      dummyPropertiesLoaded: false,
      dummyProperties: [],
      availableProperties: [],
    }
  },
  props: {
    shapProperties: {
      type: Object,
      required: true,
    },
    dummy: {
      type: Object,
      required: true,
    },
    variant: {
      type: Object || null,
      default: null,
    },
  },
  computed: {
    managedProperties () {
      return this.dummyProperties
        .filter(prop => {
          return prop.force || this.dummy.properties[prop.property_name] !== undefined || !prop.property_name
        })
        .sort((a, b) => a.property_name.toLowerCase().localeCompare(b.property_name.toLowerCase()))
    },
    variantProperties () {
      return this.variant ? Object.keys(this.variant.properties) : []
    },
    managedPropertiesNames () {
      return this.managedProperties
        .filter(p => p.force)
        .map(p => p.property_name)
    },
    remainingManagedProperties () {
      return this.dummyProperties
        .filter(prop => {
          return !(prop.force || this.dummy.properties[prop.property_name] !== undefined || !prop.property_name)
        })
        .sort((a, b) => a.property_name.toLowerCase().localeCompare(b.property_name.toLowerCase()))
    },
    otherProperties () {
      if (!this.shapProperties) {
        return []
      }
      return this.availableProperties.filter(prop => {
        return !prop.property_name || !this.managedProperties.find(managedProp => managedProp.property_name === prop.property_name)
      }).map(prop => {
        if (!this.shapProperties || !this.shapProperties[prop.property_name]) {
          prop.values = []
          return prop
        }
        prop.values = Object.keys(this.shapProperties[prop.property_name])
          .map(x => ({
            value: x,
            text: this.$options.filters.formatString(x)
          }))
        return prop
      }).sort((a, b) => {
        return a.property_name.localeCompare(b.property_name)
      })
    },
  },
  watch: {
    dummy () {
      this.getAvailableProperties()
    }
  },
  beforeMount () {
    this.getDummyProperties()
    this.getAvailableProperties()
  },
  methods: {

    getAvailableProps () {
      return [
        ...Object.keys(this.shapProperties)
          .filter(p => !this.otherProperties.map(sp => sp.property_name).includes(p))
          .map(p => ({
            value: p,
            text: this.$options.filters.formatString(p),
          })),
      ]
    },
    getPropertyValuesOptions () {
      return Object.keys(this.shapProperties[this.newOther.property_name])
        .map(x => ({
          value: x,
          text: this.$options.filters.formatString(x),
          disabled: false,
        }))
    },
    clearAddManaged () {
      this.newManaged = {
        property_name: '',
        value_type: 'text',
      }
      this.addManaged = false
    },
    saveManaged () {
      this.managedErrors = []
      if (!this.newManaged.property_name || (!this.newManaged.value && this.newManaged.value !== 0)) {
        this.managedErrors.push('name and value required')
        return
      }
      if (this.newManaged.value_type === 'decimal' &&
        (!this.newManaged.value?.toString().match(/^\d+$/) && !this.newManaged.value?.toString().match(/^\d+\.\d+$/))) {
        this.managedErrors.push('value must be a decimal')
        return
      }
      if (this.newManaged.value_type === 'integer' && !this.newManaged.value?.toString().match(/^\d+$/)) {
        this.managedErrors.push('value must be an integer')
        return
      }
      this.dummy.properties[this.newManaged.property_name] = this.newManaged.value
      this.getAvailableProperties()
      this
        .saveDummy()
        .then(updatedDummy => {
          this.$emit('properties-changed', updatedDummy)
          this.addManaged = false
          this.newManaged.value = ''
        })
    },
    clearAddOther () {
      this.newOther = {
        property_name: '',
        value_type: 'text',
      }
      this.addOther = false
    },
    checkProp () {
      this.otherErrors = []
      // Find if property exists
      let dummyHasProp = false
      let isManaged = false
      this.availableProperties.forEach(prop => {
        if (prop.property_name === this.newOther.property_name) {
          dummyHasProp = true
        }
      })
      this.managedProperties.forEach(prop => {
        if (prop.property_name === this.newOther.property_name) {
          isManaged = true
        }
      })
      if (dummyHasProp) {
        this.otherErrors.push('property exists on dummy')
        return true
      }
      if (isManaged) {
        this.otherErrors.push('property exists in managed')
        return true
      }
      this.otherErrors = []
      return false
    },
    saveOther () {
      if (!this.newOther.property_name || !this.newOther.value) {
        this.otherErrors.push('name and value required')
        return
      }
      if (this.checkProp()) {
        return
      }
      this.dummy.properties[this.newOther.property_name] = this.newOther.value
      this.getAvailableProperties()
      this
        .saveDummy()
        .then(updatedDummy => {
          this.$emit('properties-changed', updatedDummy)
          this.addOther = false
        })
    },
    getAvailableProperties () {
      this.availableProperties = Object.keys(this.dummy.properties).reduce(
        (acc, cur) => {
          return [ ...acc, {
            property_name: cur,
            value: this.dummy.properties[cur]
          }]
        },
        []
      )
    },
    getDummyProperties () {
      getObjectValues('dummies')
        .then(response => {
          // Capitalize first letter of each word
          this.dummyProperties = response.data

          // force initial validation event, due to inline errors checks for inputs
          this.$nextTick(() => {
            const newStatus = getDummyStatus(this.dummy, this.managedPropertiesNames)
            this.$emit('properties-validated', newStatus)
          })
        })
    },
    validatePropValue (prop) {
      this.$set(prop, 'errors', [])
      const value = this.dummy.properties[prop.property_name]
      if (prop.value_type === 'decimal' &&
        (!value?.toString().match(/^\d+$/) && !value?.toString().match(/^\d+\.\d+$/))) {
        prop.errors.push('value must be a decimal')
        return false
      }
      if (prop.value_type === 'integer' && !value?.toString().match(/^\d+$/)) {
        prop.errors.push('value must be an integer')
        return false
      }
      return true
    },
    handlePropValueChange (prop) {
      const isValid = this.validatePropValue(prop)

      this.$nextTick(() => {
        const newStatus = getDummyStatus(this.dummy, this.managedPropertiesNames)

        this.$emit('properties-validated', newStatus)
      })

      if (!isValid) {
        return
      }
      this.getAvailableProperties()
      this
        .saveDummy()
        .then(updatedDummy => {
          this.$emit('properties-changed', updatedDummy)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handlePropDeleteClick (prop) {
      delete this.dummy.properties[prop.property_name]
      this.getAvailableProperties()
      this.loading = true
      this
        .saveDummy()
        .then(updatedDummy => {
          this.$emit('properties-changed', updatedDummy)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleNewManagedChanged () {
      if (this.newManaged.value_default) {
        this.newManaged.value = this.newManaged.value_default
      }
    },
    async saveDummy () {
      const payload = {
        ...this.dummy,
      }
      return dummiesApi.saveDummy(payload)
    },
  }
}
</script>

<style scoped>

</style>
