<template>
  <div class="operate-items-operations" id="operate-menu">
    <h6 class="text-subtitle-1 font-weight-medium mb-4 ml-6">4. Operations</h6>
    <div class="d-flex operations-selector mx-6 mb-6">
      <div class="d-flex flex-grow-1 justify-center align-center rounded p-relative rounded elevation-2 text-button text-uppercase operation-option mr-3" :class="{ active: selectedTab === 0 }" @click="handleOperationClick(0)">
        Batch Settings
      </div>
      <div class="d-flex flex-grow-1 justify-center align-center rounded p-relative rounded elevation-2 text-button text-uppercase operation-option mx-3" :class="{ active: selectedTab === 1 }" @click="handleOperationClick(1)">
        Options Set
      </div>
      <div class="d-flex flex-grow-1 justify-center align-center rounded p-relative rounded elevation-2 text-button text-uppercase operation-option mx-3" :class="{ active: selectedTab === 2 }" @click="handleOperationClick(2)">
        Analytics
      </div>
      <div class="d-flex flex-grow-1 justify-center align-center rounded p-relative rounded elevation-2 text-button text-uppercase operation-option mx-3" :class="{ active: selectedTab === 3 }" @click="handleOperationClick(3)">
        Page filters
      </div>
    </div>
    <div v-if="selectedTab !== null" class="mt-6">
      <template v-if="selectedTab === 0">
        <v-container fluid>
          <v-row class="bordered-list-header">
            <v-col cols="3" class="pl-6">Operation</v-col>
            <v-col cols="9">Operation options</v-col>
          </v-row>
          <v-row class="bordered-list-row align-baseline">
            <v-col cols="3" class="align-baseline">
              <v-autocomplete
                v-model="selectedOperation"
                :items="operationsList"
                :item-text="item => $options.filters.formatString(item.operation)"
                item-value="operation"
                class="my-1 mb-3 ml-4 pt-0 text-body-2"
                hide-details
                placeholder="Select an operation"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @change="handleSelectedOperationChange"
              />
            </v-col>
            <v-col cols="9">
              <template v-if="selectedOperationData">
                <v-row>
                  <v-col v-if="!ruleOperations.includes(selectedOperationData.operation) && Object.keys(selectedOperationData).includes('variables') && selectedOperationData.variables.includes('store_keys')" cols="4">
                    <v-col class="pl-0">
                      <collapsible-select
                        v-model="operationStores"
                        show-select-all
                        autocomplete
                        :items="storesCollection"
                        item-text="label"
                        class="my-1 pt-0 text-body-2"
                        hide-details
                        placeholder="Select stores"
                        label="Select stores"
                        @change="checkSelectFilterStores($event)"
                      />
                    </v-col>
                    <v-row
                      class="ma-0"
                      v-for="(item, index) in propertyList"
                      :key="index"
                    >
                      <v-col class="py-0 pl-0">
                        <v-autocomplete
                          label="Properties"
                          class="pt-3 mt-1"
                          v-model="item.selectedProperty"
                          :items="[...Object.keys(storesList[0].properties), 'Store sets']"
                        ></v-autocomplete>
                      </v-col>
                      <v-col class="py-0 ">
                        <CollapsibleSelect
                          label="Values"
                          show-select-all
                          class="pt-3 mt-0"
                          autocomplete
                          :return-object="item.selectedProperty === 'Store sets'"
                          v-model="item.selectedValue"
                          @input="handleValuesChange($event, item)"
                          :items="propertyValues(item)"
                          :item-text="item.selectedProperty === 'Store sets' ? 'label': 'text'"
                        />
                      </v-col>
                    </v-row>
                    <v-icon @click="propertyList = [...propertyList, {selectedValue: [], selectedProperty: ''}]">add</v-icon>
                  </v-col>

                  <v-col v-if="ruleOperations.includes(selectedOperationData.operation)" cols="3">
                    <v-select
                      v-model="selectedBusinessRuleID"
                      :items="availableRules"
                      item-text="name"
                      item-value="id"
                      class="my-1 pt-0 text-body-2"
                      hide-details
                      placeholder="Select a rule"
                      :menu-props="{
                        left: true,
                        offsetY: true,
                        contentClass: 'select-dropdown-menu',
                      }"
                      @change="businessRuleSelected($event)"
                    />
                  </v-col>

                  <v-col v-if="selectedOperationData.operation === 'update_parameters'" cols="3">
                    <v-select
                      v-model="selectedParameter"
                      :items="parameters"
                      :item-text="x => $options.filters.formatString(x.name)"
                      item-value="name"
                      class="my-1 pt-0 text-body-2"
                      hide-details
                      return-object
                      placeholder="Select a parameter"
                      :menu-props="{
                        left: true,
                        offsetY: true,
                        contentClass: 'select-dropdown-menu',
                      }"
                      @change="handleParameterSelected"
                    />
                  </v-col>

                  <v-col v-if="selectedOperationData.operation === 'value_settings'" cols="3">
                    <v-select
                      v-model="selectedValueMethod"
                      :items="valueMethods.map(x => ({
                        text: $options.filters.capitalize(x),
                        value: x,
                      }))"
                      class="pt-0"
                      hide-details
                      placeholder="Select a method"
                      :menu-props="{
                        left: true,
                        offsetY: true,
                        contentClass: 'select-dropdown-menu',
                      }"
                    />
                  </v-col>

                  <v-col v-if="['option_start', 'option_end'].includes(selectedOperationData.operation)" cols="3">
                    <v-menu
                      v-model="datePickerActive"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="selectedValueOperationDate"
                          clearable
                          label="Pick a date"
                          hide-details="auto"
                          class="mt-0 pt-0"
                          v-on="on"
                          @click:clear="selectedValueOperationDate = null"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :first-day-of-week="1"
                        :locale-first-day-of-year="4"
                        :show-week="true"
                        no-title
                        v-model="selectedValueOperationDate"
                        @click="datePickerActive = false"
                      />
                    </v-menu>
                  </v-col>

                  <v-col v-if="
                    (ruleOperations.includes(selectedOperationData.operation) && selectedOperationData.operation !== 'delete_rule' && selectedBusinessRuleID !== null)
                  " cols="3">
                    <div class="d-inline" v-for="(item, idx) in ruleInformation(selectedBusinessRule).variables" :key="idx">
                      <!-- Check if we need a slider, input field or switch -->
                      <div v-if="ruleInformation(selectedBusinessRule).types[idx] === 'range-slider'">
                        <!-- <label class="text-body-2">{{ item | capitalize }}:</label> -->
                        <v-range-slider
                          v-model="selectedBusinessRule[item]"
                          thumb-label
                          hide-details
                          track-color="#ccc"
                          track-fill-color="primary"
                          class="my-1"
                          :max="ruleInformation(selectedBusinessRule).minmax[idx][1]"
                          :min="ruleInformation(selectedBusinessRule).minmax[idx][0]"
                        />
                      </div>
                      <div v-if="ruleInformation(selectedBusinessRule).types[idx] === 'slider'">
                        <!-- <label class="text-body-2">{{ item | capitalize }}:</label> -->
                        <v-slider
                          thumb-label
                          hide-details
                          track-color="#ccc"
                          track-fill-color="primary"
                          class="my-1"
                          :max="ruleInformation(selectedBusinessRule).minmax[idx][1]"
                          :min="ruleInformation(selectedBusinessRule).minmax[idx][0]"
                          v-model="selectedBusinessRule[item]"
                        />
                      </div>
                      <div v-else-if="ruleInformation(selectedBusinessRule).types[idx] === 'int-float'">
                        <label>{{ item | capitalize }}:</label>
                        <input onClick="this.select();" type="number" class="form-control" aria-describedby="helpInline" v-model="selectedBusinessRule[item]">
                      </div>
                      <div v-else-if="ruleInformation(selectedBusinessRule).types[idx] === 'bool'">
                        <label>{{ item | capitalize }}: </label>
                        <v-switch
                          v-model="selectedBusinessRule[item]"
                          hide-details
                          class="mt-2 pt-0"
                        />
                      </div>
                    </div>
                  </v-col>

                  <template v-if="(selectedOperationData.operation === 'update_parameters' && selectedParameter !== null)
                  ">
                    <v-col cols="3">
                      <template                        v-if="!selectedParameter.slider"
                      >
                        <v-switch
                          v-model="selectedParameterData"
                          hide-details
                          class="mt-2 pt-0"
                        />
                      </template>
                      <template v-else>
                        <v-slider
                          v-model="selectedParameterData"
                          thumb-label
                          hide-details="auto"
                          class="my-1"
                          :min="selectedParameter.slider[0]"
                          :max="selectedParameter.slider[1]"
                        />
                      </template>
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="currentOnly"
                        :items="[{
                          value: false,
                          text: 'All ruleblocks'
                        }, {
                          value: true,
                          text: 'Current ruleblock'
                        }]"
                        class="my-1 pt-0 text-body-2"
                        hide-details
                        :menu-props="{
                          left: true,
                          offsetY: true,
                          contentClass: 'select-dropdown-menu',
                        }"
                      />
                    </v-col>
                  </template>
                  <v-col
                    v-if="selectedOperationData.operation === 'reset_timelines'" cols="3">
                    <v-row class="align-center ma-0">
                      <label class="v-label theme--light mr-4">Restart</label>
                      <v-switch
                        v-model="selectedParameterData"
                        hide-details
                        class="mt-2 pt-0"
                      />
                    </v-row>
                  </v-col>

                  <v-col v-if="selectedOperationData.operation === 'value_settings' && selectedValueMethod !== null && selectedValueMethod !== 'simple'" cols="6">
                    <div class="d-flex align-center">
                      <div class="mr-4">End value</div>
                      <div class="slider-value-from mr-2">0</div>
                      <v-slider
                        v-model="selectedValueEndValue"
                        thumb-label
                        hide-details="auto"
                        class="my-1"
                        :min="0"
                        :max="100"
                      />
                      <div class="slider-value-to mr-2">100</div>
                    </div>
                  </v-col>

                  <v-col v-if="selectedOperationData.operation === 'add_rule' && selectedBusinessRuleID !== null" cols="3">
                    <v-select
                      v-model="addRulePosition"
                      :items="[{
                        value: 'first',
                        text: 'First'
                      }, {
                        value: 'last',
                        text: 'Last'
                      }]"
                      class="my-1 pt-0 text-body-2"
                      hide-details
                      placeholder="Position"
                      :menu-props="{
                        left: true,
                        offsetY: true,
                        contentClass: 'select-dropdown-menu',
                      }"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-if="selectedTab === 1">
        <v-container fluid>
          <v-row class="bordered-list-header">
            <v-col cols="3" class="pl-6">Operation</v-col>
            <v-col cols="9">Operation options</v-col>
          </v-row>
          <v-row class="bordered-list-row">
            <v-col cols="3" class="pb-0">
              <v-autocomplete
                v-model="selectedOperation"
                :items="optionSetOperationsOptions"
                class="mt-1 mb-6 ml-4 pt-0 text-body-2"
                hide-details
                placeholder="Select an operation"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @change="handleSelectedOperationChange"
              />
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-row>
                <v-col v-if="selectedOperation === 'create_options_set'">
                  <v-text-field
                    v-model="optionSetOperationData"
                    :error-messages="optionSetOperationError"
                    placeholder="New options set name"
                    class="my-1 pt-0 text-body-2"
                  />
                </v-col>
                <v-col v-if="['add_to_options_set', 'replace_options_set', 'delete'].includes(selectedOperation)">
                  <v-autocomplete
                    label="Select static options set"
                    v-model="optionSetOperationData"
                    :items="existingOptionSets"
                    :error-messages="optionSetOperationError"
                    item-text="set_name"
                    item-value="options_set_key"
                    class="my-1 pt-0 text-body-2"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-if="selectedTab === 2">
        <v-container fluid>
          <v-row class="bordered-list-header">
            <v-col cols="3" class="pl-6">Operation</v-col>
          </v-row>
          <v-row class="bordered-list-row">
            <v-col cols="3" class="pb-0 pl-6">
              <v-autocomplete
                class="dashboard-select mr-3"
                :items="dashboardForChoose"
                label="Analytics dashboards"
                return-object
                v-model="chosenDashboard"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
        <v-btn
          color="primary"
          depressed
          outlined
          v-if="chosenDashboard?.group === 'Personal'"
          class="mx-6 my-6"
          :disabled="!chosenDashboard"
          @click="handleApplyClickForDashboard(false)"
        >Apply</v-btn>
        <v-btn
          color="primary"
          depressed
          outlined
          class="mx-6 my-6"
          :disabled="!chosenDashboard"
          @click="handleApplyClickForDashboard(true)"
        >Apply and open</v-btn>
        <div class="text-subtitle-1 font-weight-medium mb-6 mx-6" v-if="showAnalyticsUpdateMessage">
          Dashboard updated with {{ selectedResults.length }} option(s)
        </div>
      </template>

      <v-btn
        v-if="[0, 1].includes(selectedTab)"
        color="primary"
        depressed
        outlined
        class="mx-6 my-6"
        :loading="applyingOperation"
        :disabled="!selectedOperation"
        @click="handleApplyClick"
      >Apply</v-btn>

      <template v-if="selectedTab === 3">
        <v-divider />
        <v-btn
          color="primary"
          depressed
          outlined
          class="mx-6 my-6"
          @click="handleUpdateOptionsClick"
        >Update</v-btn>
        <div class="text-subtitle-1 font-weight-medium mb-6 mx-6" v-if="showFiltersUpdateMessage">
          Page filters updated with {{ selectedResults.length }} option(s)
        </div>
      </template>
    </div>
    <div v-if="showWarning && selectedOperation">
      <div class="mx-6">
        <span class='text-subtitle-1 font-weight-medium' v-if="ruleOperations.includes(selectedOperation) && selectedBusinessRule">Are you sure you want to change the rule {{ selectedBusinessRule.name | formatString }} for {{ selectedResults.length }} options?</span>
        <span class='text-subtitle-1 font-weight-medium' v-else-if="selectedOperation === 'delete'">
          Are you sure you want to remove from Options set {{selectedResults.length}} options?        </span>
        <span class='text-subtitle-1 font-weight-medium' v-else>Are you sure you want {{ selectedOperation | formatStringNoUpperCase }} <span v-show="operationStores.length > 0">of {{ operationStores.length }} stores</span> to {{ selectedResults.length }} options?</span>
      </div>
      <div class="my-6 mx-6">
        <v-btn
          color="primary"
          depressed
          text
          @click="showWarning = false"
        >No</v-btn>
        <v-btn
          color="primary"
          depressed
          outlined
          @click="applyOperation"
          class="ml-4"
        >Yes</v-btn>
      </div>
    </div>
    <div v-if="!showWarning && operationApplied === true" class="text-subtitle-1 font-weight-medium my-6 mx-6">
      Operation succesfully applied
    </div>
    <div v-if="!showWarning && operationApplied === false" class="text-subtitle-1 font-weight-medium my-6 mx-6">
      Operation not succesfully applied
    </div>
  </div>
</template>

<script>
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import { getDashboards, getSharedDashboards, saveDashboard } from '@/api/analytics'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'

import { useStockOperateStore } from '@/store/pinia/stockOperateStore'
import { useRulesStore } from '@/store/pinia/rulesStore'

import { useStockManageStore } from '@/store/pinia/stockManageStore'

export default {
  setup () {
    return {
      operateStore: useStockOperateStore(),
      rulesStore: useRulesStore(),
      stockManageStore: useStockManageStore()
    }
  },
  props: ['tab'],
  data () {
    return {
      propertyList: [],
      selectedTab: null,
      chosenDashboard: null,
      dashboardForChoose: [],
      operationStores: [],
      selectedOperation: null,
      selectedProperty: '',
      selectedBusinessRule: '',
      selectedBusinessRuleID: null,
      selectedParameter: null,
      selectedParameterData: null,
      selectedValueMethod: null,
      selectedValueEndValue: null,
      selectedValueOperationDate: null,
      ruleOperations: ['change_rule', 'delete_rule', 'add_rule'],
      optionSetOperationsOptions: [{
        text: 'Create new options set',
        value: 'create_options_set'
      }, {
        text: 'Add to existing options set',
        value: 'add_to_options_set'
      }, {
        text: 'Replace for existing options set',
        value: 'replace_options_set'
      }, {
        text: 'Remove from existing options set',
        value: 'delete'
      }],
      addRulePosition: 'last',
      datePickerActive: false,
      showWarning: false,
      optionSetOperationData: null,
      optionSetOperationError: null,
      showFiltersUpdateMessage: false,
      showAnalyticsUpdateMessage: false,
      currentOnly: false,
    }
  },
  components: {
    CollapsibleSelect,
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    ...mapState(useStoreAndOptionSetsStore, ['optionSets', 'storeSets']),
    storesCollection () {
      return this.storesList.map(item => ({
        value: item.store_key,
        label: item.store_name.substring(0, 13)
      }))
    },
    storesList () {
      return (this.appConfig?.stores || [])
    },
    operationsList () {
      return this.operateStore.operationsList
    },
    optionSetOperations () {
      return this.optionSetOperationsOptions.map(o => o.value)
    },
    selectedOperationData () {
      return this.operateStore.operationsList.find(o => o.operation === this.selectedOperation)
    },
    existingOptionSets () {
      return this.optionSets.filter(item => item.static_set)
    },
    availableRules () {
      const rules = this.rulesStore.getRules
      const indices = []
      for (let idx = 0; idx < rules.length; idx++) {
        if (rules[idx].name.includes('Sku')) {
          indices.push(idx)
        }
      }
      for (const i of indices) {
        rules.splice(i, 1)
      }
      return rules
    },
    parameters () {
      return this.rulesStore.parameters
    },
    valueMethods () {
      const method = this.rulesStore.value.find(x => x.name === 'method')

      return method ? method.choices : []
    },
    selectedResults () {
      return this.operateStore.searchResultsSelected
    },
    applyingOperation () {
      return this.operateStore[`${this.tab}Tab`].applyingOperation
    },
    operationApplied () {
      return this.operateStore[`${this.tab}Tab`].operationApplied
    },
  },
  watch: {
    selectedResults () {
      this.showFiltersUpdateMessage = false
      this.showAnalyticsUpdateMessage = false
    },
  },
  methods: {
    async loadDashboards () {
      this.dashboardForChoose = []
      await getDashboards().then(dashboards => {
        this.dashboards = dashboards
        this.dashboardForChoose.push({ header: 'Personal' })
        this.dashboardForChoose = [...this.dashboardForChoose, ...dashboards.map(item => {
          return {
            text: item.dashboard_name,
            value: item.dashboard_key,
            group: 'Personal'
          }
        })]
      })
      await getSharedDashboards().then(dashboards => {
        this.dashboards = [...this.dashboards, ...dashboards]
        this.dashboardForChoose.push({ header: 'Shared' })
        this.dashboardForChoose = [...this.dashboardForChoose, ...dashboards.map(item => {
          return {
            text: item.dashboard_name,
            value: item.dashboard_key,
            group: 'Shared'
          }
        })]
      })
    },
    handleValuesChange (values, listItem) {
      if (listItem.selectedProperty === 'Store sets') {
        let valuesSet = new Set()
        values.forEach(item => {
          valuesSet = new Set([...valuesSet, ...item.store_keys])
        })
        const set = new Set([...this.operationStores, ...valuesSet])
        this.operationStores = [...set]
        return
      }
      const data = this.storesList.filter(item => {
        return values.includes(item.properties[listItem.selectedProperty])
      })
      const set = new Set([...this.operationStores, ...data.map(item => item.store_key)])
      this.operationStores = [...set]
    },

    propertyValues (listItem) {
      if (listItem.selectedProperty === 'Store sets') {
        return this.storeSets.map(item => ({
          store_keys: item.store_keys, label: item.set_name, value: item.stores_set_key
        }))
      }
      const values = new Set()
      this.storesList.forEach((item) => {
        if (item.properties[listItem.selectedProperty] !== undefined) {
          const value = item.properties[listItem.selectedProperty]
          values.add(value)
        }
      })

      return ([...values]).map(item => ({ value: item, text: item }))
    },
    handleOperationClick (index) {
      this.selectedTab = index
      this.selectedOperation = null
      this.optionSetOperationData = null
      this.optionSetOperationError = null
    },
    setOptionsFilter () {
      const optKeys = this.selectedResults.map(r => r.option_key)
      // set all options list to local store, cause it can't be loaded from server as other filters
      localStorage.setItem('optionsList', JSON.stringify(optKeys))
    },
    handleSelectedOperationChange () {
      this.showWarning = false
      this.optionSetOperationError = null
      this.optionSetOperationData = null
    },
    handleApplyClick () {
      if (this.optionSetOperations.includes(this.selectedOperation)) {
        if (!this.validateOptionSetOperation()) {
          return
        }
      }

      if (this.selectedOperation) {
        this.showWarning = true
      }
    },
    handleApplyClickForDashboard (needOpen) {
      const dashboardDetails = this.dashboards.find(item => item.dashboard_key === this.chosenDashboard.value)
      if(!dashboardDetails.filters){
        dashboardDetails.filters = {}
      }
      dashboardDetails.filters['properties/article/option_key'] = this.selectedResults.map(item => item.option_key)
      saveDashboard(dashboardDetails).then(() => {
        if (needOpen) {
          const link = this.$router.resolve({ name: 'stock-analytics', query: { dashboard_key: this.chosenDashboard.value } })
          window.open(link.href, '_blank')
        }
      })
      this.showAnalyticsUpdateMessage = true
    },
    handleUpdateOptionsClick () {
      this.setOptionsFilter()
      this.showFiltersUpdateMessage = true
    },
    applyOperation () {
      this.showWarning = false
      this.operateStore.setApplyingOperation(this.tab, true)

      if (this.selectedOperation === 'create_options_set') {
        this.createNewOptionSet()
      } else if (['add_to_options_set', 'replace_options_set', 'delete'].includes(this.selectedOperation)) {
        this.applyOptionSetOperation()
      } else {
        this.applyBatchOperation()
      }

      setTimeout(() => {
        this.operateStore.setOperationApplied(this.tab, null)
      }, 3000)
    },
    createNewOptionSet () {
      this.stockManageStore.saveOptionSet({
        set_name: this.optionSetOperationData,
        option_keys_include: this.selectedResults.map(r => r.option_key),
        static_set: true,
      })
        .then(() => {
          useStoreAndOptionSetsStore().loadOptionSets().catch(console.error)
          this.operateStore.setOperationApplied(this.tab, true)
          this.operateStore.setApplyingOperation(this.tab, false)
        })
    },
    applyOptionSetOperation () {
      let addReplace = this.selectedOperation

      if (this.selectedOperation === 'add_to_options_set') {
        addReplace = 'add'
      }

      if (this.selectedOperation === 'replace_options_set') {
        addReplace = 'replace'
      }

      this.operateStore.applyOptionSetOperation({
        tab: this.tab,
        operationData: {
          options_set_key: this.optionSetOperationData,
          option_keys_include: this.selectedResults.map(r => r.option_key),
          add_replace: addReplace,
        },
      })
    },
    applyBatchOperation () {
      const payload = {
        option_keys: this.selectedResults.map(x => x.option_key),
        operation: this.selectedOperation,
      }

      if (this.selectedOperation === 'value_settings') {
        payload['value_parameters'] = {
          method: this.selectedValueMethod,
          end_value: this.selectedValueEndValue,
        }
      } else if (this.selectedOperation === 'option_start') {
        payload['date_min'] = this.selectedValueOperationDate
      } else if (this.selectedOperation === 'option_end') {
        payload['date_max'] = this.selectedValueOperationDate
      }  else if (this.selectedOperation === 'reset_timelines') {
        payload['restart'] = !!this.selectedParameterData
      } else if (!this.ruleOperations.includes(this.selectedOperation)) {
        payload['store_keys'] = this.operationStores
        payload['parameters'] = this.selectedOperation === 'update_parameters' ? {
          [this.selectedParameter.name]: this.selectedParameterData,
        } : {}
        if (this.selectedOperation === 'update_parameters') {
          payload['current_only'] = this.currentOnly
        }
      } else {
        payload['id'] = this.selectedBusinessRuleID
        payload['parameters'] = {}

        for (const key of Object.keys(this.selectedBusinessRule.args_default)) {
          payload['parameters'][key] = this.selectedBusinessRule[key]
        }

        if (this.selectedOperation === 'add_rule') {
          payload['position'] = this.addRulePosition
        }
      }

      this.operateStore.applyBatchOperation({
        tab: this.tab,
        operationData: payload,
      })
    },
    validateOptionSetOperation () {
      this.optionSetOperationError = null

      if (this.selectedOperation === 'create_options_set') {
        if (
          !this.optionSetOperationData ||
          this.existingOptionSets.map(s => s.set_name).includes(this.optionSetOperationData)
        ) {
          this.optionSetOperationError = 'Should be unique and not empty'
        }
      }

      if (['add_to_options_set', 'replace_options_set'].includes(this.selectedOperation)) {
        if (!this.optionSetOperationData) {
          this.optionSetOperationError = 'Required'
        }
      }

      return !this.optionSetOperationError
    },
    ruleInformation () {
      const defaultRule = JSON.parse(JSON.stringify(this.selectedBusinessRule))
      defaultRule['variables'] = []
      defaultRule['types'] = []
      defaultRule['minmax'] = []
      try {
        defaultRule['args_key'] = Object.values(defaultRule['args_key'])
      } catch (error) {
        defaultRule['args_key'] = []
      }
      for (const def in defaultRule.args_default) {
        defaultRule['variables'].push(def)
        if (Object.keys(defaultRule).includes('args_slider') && Object.keys(defaultRule['args_slider']).includes(def)) {
          if (Array.isArray(defaultRule['args_default'][def])) {
            defaultRule['types'].push('range-slider')
            defaultRule['minmax'].push(defaultRule['args_slider'][def])
          } else {
            defaultRule['types'].push('slider')
            defaultRule['minmax'].push(defaultRule['args_slider'][def])
          }
        } else if (Array.isArray(defaultRule.args_default[def])) {
          defaultRule['types'].push('int array')
          defaultRule['minmax'].push([])
        } else if (typeof defaultRule.args_default[def] === 'boolean') {
          defaultRule['types'].push('bool')
          defaultRule['minmax'].push([])
        } else if (defaultRule.args_default[def] === Object(defaultRule.args_default[def])) {
          defaultRule['types'].push('obj')
          defaultRule['minmax'].push([])
        } else {
          defaultRule['types'].push('int-float')
          defaultRule['minmax'].push([])
        }
      }
      return defaultRule
    },
    checkSelectFilterStores () {
      if (this.operationStores.includes('all')) {
        this.operationStores = (this.appConfig?.stores || []).map(s => s.store_key)
      } else if (this.operationStores.includes('none')) {
        this.operationStores = []
      }
    },
    businessRuleSelected (event) {
      const rules = this.rulesStore.getRules
      const defaultRule = JSON.parse(JSON.stringify(rules.find(x => x.id === event)))
      for (const key of Object.keys(defaultRule.args_default)) {
        defaultRule[key] = defaultRule.args_default[key]
      }
      this.$set(this, 'selectedBusinessRule', defaultRule)
    },
    handleParameterSelected () {
      this.selectedParameterData = this.selectedParameter.default
    },
  },
  created () {
    useStoreAndOptionSetsStore().loadStoreSets().catch(console.error)
    this.loadDashboards()
  },

}
</script>

<style lang="scss">
.operate-items-operations {
    .operations-selector {
        .operation-option {
            height: 100px;
            display: inline-block;
            letter-spacing: 1.25px;
            cursor: pointer;
            &.active {
                position: relative;
                color: var(--primary);
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    border-bottom: 4px solid var(--primary);
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }
    }
    .bordered-list-row {
        &:last-child {
            border-bottom: 1px solid var(--border-color);
        }
    }
    .slider-value-from,
    .slider-value-to {
        color: #666;
        font-size: 14px;
    }
}
</style>
