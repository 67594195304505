<template>
  <div class="mb-10 form-div">
    <div class="mb-2 title-wrapper">
      <h4 class="mb-1">
        {{ filterData.label }} ({{ selectedFilterOptions.length }}/{{ filterOptionsList.length
        }})
      </h4>
      <v-icon @click="$emit('revert-click', filterName)">replay</v-icon>
    </div>

    <CollapsibleSelect
      v-model="selectedFilterOptions"
      :items="filterOptionsList.map(x => ({
        value: x[filterData.keyProp].toString(),
        label: x[filterData.nameProp].substring(0, 10)
      }))"
      autocomplete
      item-text="label"
      show-select-all
      class="mt-1 mb-4 text-body-2"
      hide-details
      :label="filterData.label"
    />
  </div>
</template>

<script>
import { filtersData } from '@/variables'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  props: {
    filterName: {
      type: String,
      required: true,
    },
    filterData: {
      type: Object,
      default: () => ({}),
    },
    filterOptionsList: {
      type: Array,
      default: () => [],
    },
  },
  components: { CollapsibleSelect },
  data () {
    return {
      filtersData,
    }
  },
  computed: {
    selectedFilterOptions: {
      get () {
        return this.filtersStore.selectedFiltersOptions[this.filterName]
      },
      set (val) {
        this.$emit('filter-changed', {
          filterName: this.filterName,
          value: val,
        })
      },
    },
  },
  methods: {
  },
}
</script>

<style scoped lang="scss">
.form-div {
    width: 100%;
    .title-wrapper {
        display: flex;
        justify-content: space-between;
    }
}
</style>
