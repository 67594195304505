import { downloadData } from './utils'
import interpolate from 'color-interpolate'

export const colormapCover = interpolate(['#085D70', '#329193', '#FFD202', '#FFAB00'])
export const colormapOTB = interpolate(['#FFAB00', '#FFD202', '#329193'])
export const colormapGreenRed = interpolate(['#4caf50', '#ff5721'])
export const colormapRedGreen = interpolate(['#ff5721', '#4caf50'])
export const chartColors = ['#007AFF', '#4CAF50', '#FF9800', '#FF9A9A', '#085D70', '#9BE48E', '#4FB4AB', '#F989D0', '#EE3766', '#765397', '#FF675B', '#73A1C7', '#F5907B', '#00000033', '#00000099', '#00000000']

export const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  'Content-Type': 'application/json'
}

export const currencyMap = {
  'EUR': '€',
}

export const dummyStatusColorMap = {
  completed: '#4CAF50',
  'in progress': '#FF9800',
}

export const shopItemStatusColorMap = {
  unchanged: '#007AFF',
  add: '#4CAF50',
  overwrite: '#FF9800',
  ordered: '#4CAF50',
  committed: '#FF9800',
  planned: '#FFD202',
  to_order: '#FFD202',
}

export const storeSettingsLogicDefaults = {
  return_costs: 2.5,
  delivery_days: 3,
  constant_costs: 0.10,
  variable_costs: 0.015,
  redelivery_days: 7,
}

export const filtersData = {
  stores: {
    type: 'arrayOfValuesWithProperties',
    keyProp: 'store_key',
    nameProp: 'store_name',
    filterOptionsListProp: 'stores',
    label: 'Stores',
    filterPropertiesListProp: 'storeProperties',
  },
  toStores: {
    type: 'arrayOfValuesWithProperties',
    keyProp: 'store_key',
    nameProp: 'store_name',
    filterOptionsListProp: 'stores',
    label: 'To stores',
    filterPropertiesListProp: 'storeProperties',
  },
  storesDc: {
    type: 'arrayOfValuesWithProperties',
    keyProp: 'store_key',
    nameProp: 'store_name',
    filterOptionsListProp: 'storesDc',
    label: 'Stores dc',
    filterPropertiesListProp: 'storeDcProperties',
  },
  toStoresDc: {
    type: 'arrayOfValuesWithProperties',
    keyProp: 'store_key',
    nameProp: 'store_name',
    filterOptionsListProp: 'storesDc',
    label: 'To stores dc',
    filterPropertiesListProp: 'storeDcProperties',
  },
  groups: {
    type: 'arrayOfValuesWithProperties',
    keyProp: 'group_key',
    nameProp: 'group_name',
    filterOptionsListProp: 'groups',
    label: 'Groups',
    filterPropertiesListProp: 'groupProperties',
  },
  collections: {
    type: 'arrayOfValuesWithProperties',
    keyProp: 'collection_key',
    nameProp: 'collection_name',
    filterOptionsListProp: 'collections',
    label: 'Collections',
    filterPropertiesListProp: 'collectionProperties',
  },
  options: {
    keyProp: 'option_key',
    type: 'options',
    label: 'Options',
  },
  suppliers: {
    type: 'arrayOfValues',
    keyProp: 'supplier_key',
    nameProp: 'supplier_name',
    filterOptionsListProp: 'suppliers',
    label: 'Suppliers',
  },
  payments: {
    type: 'arrayOfValues',
    keyProp: 'payment_key',
    nameProp: 'payment_name',
    filterOptionsListProp: 'payments',
    label: 'Payments',
  },
  schedules: {
    type: 'arrayOfValues',
    keyProp: 'schedule_key',
    nameProp: 'schedule_name',
    filterOptionsListProp: 'schedules',
    label: 'Schedules',
  },
  dummies: {
    keyProp: 'dummy_key',
    type: 'dummies',
    label: 'Dummies',
  },
  shopItems: {
    keyProp: 'shop_item_key',
    type: 'shopItems',
    label: 'Shop items',
  },
}

export const getHeadersWithAccessToken = function (forced_access_token = null) {
  const newHeaders = JSON.parse(JSON.stringify(headers))
  if (forced_access_token) {
    newHeaders['Authorization'] = 'Bearer ' + forced_access_token
  } else {
    newHeaders['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')
  }
  return newHeaders
}

export const getHeadersWithRefreshToken = function () {
  const newHeaders = JSON.parse(JSON.stringify(headers))
  newHeaders['Authorization'] = 'Bearer ' + localStorage.getItem('refresh_token')
  return newHeaders
}

export const excludeFormatList = ['article_code', 'Article code']

export const formatString = function (value) {
  const variable = value.replaceAll('_', ' ')
  return variable.charAt(0).toUpperCase() + variable.slice(1)
}

export const parseWeek = (week) => {
  if (!week) {
    return '-'
  }
  const year = ('' + week).substr(0, 4)
  const weeks = ('' + week).substr(4)
  return `${year}-W${weeks}` || '-'
}

export const downloadChartData = (sep, chart, fileName = '') => {
  const chartData = chart.chartData
  let data = chartData.data || chartData[chart.dataType] || chartData
  if (Array.isArray(data)) {
    data = turnArrayOfObjectsToObjectOfArrays(data)
  }
  let keys = chartData.keys
  if (!keys || !Array.isArray(keys)) {
    keys = Object.keys(data)
  }
  const downloadArray = []
  for (let i = 0; i < data[keys[0]].length; i++) {
    const item = {}
    keys.forEach((key) => {
      item[key] = data[key][i]
    })
    downloadArray.push(item)
  }
  downloadData(sep, downloadArray, fileName || chart.title)
}

function turnArrayOfObjectsToObjectOfArrays (array) {
  const object = {}
  array.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (!object[key]) {
        object[key] = []
      }
      object[key].push(item[key])
    })
  })
  return object
}
