<template>
  <resizable style="height: 90%"
    @resize="redraw"
  >
    <v-overlay absolute color="white" :value="!loaded || !chartData">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <div v-show="loaded"
      class="chart-container" style="position: relative;">
      <div class="container" >
        <div class="container-body" :style="{height: barHeight}">
          <canvas :id="title+index"></canvas>
        </div>
      </div>
    </div>
  </resizable>
</template>

<script>
import { Chart } from 'chart.js/auto'
import { hexToRgbA } from '@/utils'
import { chartColors } from '@/variables'
import Resizable from '@/components/Utility/Resizable.vue'

export default {
  components: {Resizable},
  props: {
    title: {
      type: String,
      required: true
    },
    resizing: {
      type: Boolean,
    },
    chartData: {
      type: Object,
      required: true
    },
    index: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: '370px'
    },
    minBarWidthPx: {
      type: Number,
      default: 12
    },
  },
  data () {

    return {
      loaded: false,
      mins: {}
    }
  },
  watch: {
    chartData () {
      this.drawGraph()
    }
  },
  methods: {
    redraw (prop){
      if(!this.resizing){
        return
      }
      if(this.chartWidth === prop.width && this.chartHeight === prop.height){
        return
      }
      this.chartWidth = prop.width
      this.chartHeight = prop.height

      this.drawGraph()
    },
    calculateMin (axis){
      if(this.mins[axis]){
        return this.mins[axis]
      }
      let min
      this.chartData.data.forEach(item => {
        for (const key in this.chartData.view.measuresProps) {
          if (this.chartData.view.measuresProps[key].axis === axis && item[key] < min) {
            min = item[key]
          }
        }
      })
      this.mins[axis] = min
      return min
    },
    generateYaxes (){
      const data = this.chartData
      const yAxes = []
      if(data.view.properties.axis.y2){
        const minY2 = (data.view.properties.axis.y2_min == null) ? this.calculateMin('y2') : data.view.properties.axis.y2_min
        const yAxis2 = {
          label: data.view.properties.axis.y2,
          id: 'B',
          beginAtZero: false,
          grid: {
            display: false
          },
          title: {
            display: true,
            text: data.view.properties.axis.y2,
            font: {
              size: 14,
            },
          },
          position: 'right',
          stacked: data.view.properties.bar_type === 'stacked',
          min: minY2,
          start: minY2,
          max: data.view.properties.axis.y2_max,
          suggestedMax: data.view.properties.axis.y2_max || undefined,
          suggestedMin: data.view.properties.axis.y2_min || minY2,
          ticks: {
            maxTicksLimit: undefined,
            font: {
              size: 14
            },
            padding: 16,
            callback: function (value) {
              if(!value){
                return '0'
              }
              if(Math.abs(value) < 1){
                return value.toFixed(2)
              }
              const ranges = [
                { divider: 1e6, suffix: 'M' },
                { divider: 1e3, suffix: 'k' }
              ]

              const formatter = new Intl.NumberFormat('en-GB', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              })
              function formatNumber (n) {
                for (let i = 0; i < ranges.length; i++) {
                  if (Math.abs(n) >= ranges[i].divider) {
                    return formatter.format(n / ranges[i].divider) + ranges[i].suffix
                  }
                }
                return n
              }
              return formatNumber(value)
            },
          },
        }
        yAxes.push(yAxis2)
      }
      if(data.view.properties.axis.y1){
        const min = (data.view.properties.axis.y1_min == null) ? this.calculateMin('y1') : data.view.properties.axis.y1_min
        const max = data.view.properties.axis.y1_max
        if (data.view.properties.axis.y1) {
          yAxes.push({
            label: data.view.properties.axis.y1,
            id: 'A',
            position: 'left',
            beginAtZero: false,

            title: {
              display: true,
              text: data.view.properties.axis.y1,
              font: {
                size: 14,
              },
            },
            max: max,
            min: min,
            start: min,
            ticks: {
              font: {
                size: 14
              },

              max: max,
              min: min,
              beginAtZero: false,
              padding: 16,
              suggestedMax: max,
              start: min,
              suggestedMin: min,
              callback: function (value) {
                const ranges = [
                  { divider: 1e6, suffix: 'M' },
                  { divider: 1e3, suffix: 'k' }
                ]


                const formatter = new Intl.NumberFormat('en-GB', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 1,
                })
                function formatNumber (n) {
                  for (let i = 0; i < ranges.length; i++) {
                    if (Math.abs(n) >= ranges[i].divider) {
                      return formatter.format(n / ranges[i].divider) + ranges[i].suffix
                    }
                  }
                  return n
                }
                return formatNumber(value)
              },
            },
            gridLines: {
              display: true
            },
            stacked: data.view.properties.bar_type === 'stacked',
          })
        }
        return yAxes
      }
      return yAxes
    },
    generateDatasets (externalKeys, newData){
      const label2 = this.chartData.view.dimensions[1]
      return externalKeys.map((key, index) => {
        const measure = this.chartData.view.measuresProps[label2 ? externalKeys[0] : key]
        const colorItem = !label2 && measure.color_code ? { borderColor: measure.color_code } : {}
        if (!label2 && measure.type === 'point') {
          colorItem.borderColor = measure.color_code
          colorItem.backgroundColor = measure.color_code
        } else if (!label2) {
          colorItem.borderColor = measure.border_color_code || 'rgba(0,0,0,0)'
          colorItem.backgroundColor = hexToRgbA(measure.fill_color_code || 'transparent', 0.3)
        } else {
          colorItem.borderColor = hexToRgbA(chartColors[index % chartColors.length], 1)
          colorItem.backgroundColor = hexToRgbA(chartColors[index % chartColors.length], 0.3)
        }
        let tempData
        if (label2) {
          tempData = newData[key].map(item => {
            return item[externalKeys[0]] * (measure.percentage ? 100 : 1)
          })
        } else {
          tempData = this.chartData.data.map(item => {
            return item[key] * (measure.percentage ? 100 : 1)
          })
        }
        return {
          borderWidth: 2,
          label: key,
          data: tempData,
          type: this.chartData.view.measuresProps[label2 ? externalKeys[0] : key].type === 'point' ? 'bubble' : this.chartData.view.measuresProps[label2 ? externalKeys[0] : key].type,
          yAxisID: measure.axis === 'y1' ? 'A' : 'B',
          showLine: false,
          hidden: measure.hidden,
          ...colorItem,
        }
      })
    },
    drawGraph () {
      try {
        this.loaded = false
        const ctx = document.getElementById(this.title + this.index).getContext('2d')

        const data = this.chartData
        const label = data.view.dimensions[0]
        const properties = data.view.measuresProps
        const yAxes = this.generateYaxes()

        const label2 = data.view.dimensions[1]
        const keys = Object.keys(properties)
        let newData = null
        const externalKeys = keys
        const labels = []
        if (label2) {
          newData = {}
          for (const item of data.data) {
            if (!externalKeys.includes(item[label2])) {
              externalKeys.push(item[label2])
            }
            const shortedLabel = item[label].length > 15 ? item[label].substring(0, 15) + '...' : item[label]
            if (!labels.includes(shortedLabel)) {
              labels.push(shortedLabel)
            }
          }
          externalKeys.forEach(labelTitle => {
            newData[labelTitle] = new Array(labels.length)
            data.data.forEach(item => {
              if (item[label2] === labelTitle) {
                const index = labels.findIndex(labelItem => item[label] === labelItem)
                newData[labelTitle][index] = item
              }
            })
          })
        }
        const chartData = {
          labels: label2 ? labels : data.data.map((item) => item[label].length > 15 ? item[label].substring(0, 15) + '...' : item[label]),
          datasets: this.generateDatasets(externalKeys, newData),
        }

        const options = {
          responsive: true,
          maintainAspectRatio: false,
          clip: true,
          plugins: {
            legend: {
              display: !data.view?.properties?.hide_legend,
              position: 'bottom',
              align: 'center',
            },
          },
          scales: {
            ...yAxes.reduce((acc, cur) => ({
              ...acc,
              [cur.id]: cur
            }), {}),
            x: {
              grid: {
                display: false
              },
              stacked: data.view.properties.bar_type !== 'grouped',
              label: '',
            }
          },
        }
        if (this.chart) {
          this.chart.destroy()
        }
        // Check labels length and set width of the chart
        const minBarWidth = this.minBarWidthPx
        const barWidth = (window.innerWidth * 0.9) / chartData.labels.length
        if (barWidth < minBarWidth) {
          document.querySelector('.container-body').style.width = `${chartData.labels.length * minBarWidth}px`
        }
        this.chart = new Chart(ctx, { type: 'bar', data: chartData, options })

        this.$emit('loaded')
        setTimeout(()=> {
          this.loaded = true

        }, 300)
      } catch (e) {
        console.log(e)
        this.$emit('loaded')
        this.loaded = true
      }
    },
  },
  mounted () {
    this.drawGraph()
  },
  computed:{
    barHeight (){
      if (this.height === '100%' && this.loaded) {
        const elementHeight = document.getElementById(`bar-chart-container-${this.chartData?.view?.view_key}`)?.clientHeight
        if(elementHeight){
          return elementHeight - 48 + 'px'
        }
      }
      if(this.height){
        if(parseFloat(this.height)){
          return this.height + 'px'
        }
        return `calc(${this.height} - 72px)`
      }
      return '370px'
    }
  }
}
</script>
<style lang="scss">
.chart-container {
  margin-bottom: 10px;
  .container {
    overflow-x: auto;
    max-width: 100%;
    padding-block: 2px;
  }
}
.stockHomeCarousel {
  .v-carousel__controls {
    height: 30px;
  }
}
</style>
