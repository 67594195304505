<template>
  <div
    class="pricing-filter-widget"
  >
    <v-card v-show="filtersOpened" class="filter-widget-card" id="filterForm">
      <div
        v-show="!optionsSearchVisible"
        class="filter-options pa-4"
      >
        <div class="pt-4 px-4">
          <h4 class="justify-space-between align-center d-flex">Page filters
            <v-icon @click="resetPricingFilter" class="">replay</v-icon>
          </h4>
          <v-row v-for="(prop, index) of pricingPageFilters" :key="index" class="flex-nowrap ma-0">
            <v-autocomplete
              :items="filtersList"
              :filter="headersFilters"
              class="pr-4 flex xs6"
              :value="index"
              @input="value => handleFilterPropertyChanged(value, index)"
              :label="`Property `"
            ></v-autocomplete>
            <CollapsibleSelect
              :items="(properties[index] || []).map((item)=> { return { text: formatString(item.toString()), value: item}})"
              v-model="pricingPageFilters[index]"
              @change="savePricingFilter"
              ref="values"
              autocomplete
              multiple
              show-select-all
              class="flex xs6"
              label="Values"
            />
            <v-flex class="align-baseline justify-center d-flex">
              <v-icon @click="removePricingFilter(index)">delete</v-icon>
            </v-flex>
          </v-row>

          <v-row class="flex-nowrap ma-0">
            <v-autocomplete
              :items="filtersList"
              v-model="newProperty"
              :filter="headersFilters"
              class="pr-4 flex xs6"
              :label="`Property`"
            ></v-autocomplete>
            <CollapsibleSelect
              v-if="newProperty"
              autocomplete
              :class="{hidden: !newProperty}"
              multiple
              show-select-all
              :items="((properties || {})[newProperty] || []).map((item)=> { return { text: formatString(item.toString()), value: item}})"
              v-model="newPropertyValues"
              class="flex xs6"
              label="Values"
              @blur="handleNewPropertyValueChanged"
            />
          </v-row>

          <div class="mt-4 mb-10 form-div">
            <div class="mb-2 title-wrapper">
              <h4 class="mb-1">
                Options ({{ pricingPageOptionsFilter.length }})
              </h4>
              <div>
                <v-icon @click="handleOptionsEditClick" class="ml-4">edit</v-icon>
                <v-icon @click="handleRevertOptionsFilterClick" class="ml-4">replay</v-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="optionsSearchVisible && !optionsSearchResultsVisible"
        class="options-search"
      >
        <div class="font-weight-medium my-3 px-8 text-body-1">Options</div>
        <OptionsSearch
          ref="optionsSearch"
          class="mx-2"
          @resultsUpdated="handleOptionsSearchResultsUpdated"
        />
      </div>
      <div
        v-if="optionsSearchVisible && optionsSearchResultsVisible"
        class="options-search-results"
      >
        <div class="font-weight-medium my-3 px-8 text-body-1">Results</div>
        <OptionsSearchResults
          ref="optionsSearchResults"
          class="mx-2"
          :search-results="optionsSearchResults"
        />
      </div>
      <div class="controls">
        <v-divider />
        <template v-if="optionsSearchVisible">
          <v-row class="mx-0 mt-3 pb-6 align-center">
            <v-col class="pl-12">
              <template v-if="!optionsSearchResultsVisible">
                <v-btn text color="primary" class="mr-2" @click="optionsSearchResultsVisible = true">SHOW {{ optionsSearchResults.length }} RESULTS</v-btn>
              </template>
              <template v-else>
                <v-btn text color="primary" class="mr-2" @click="optionsSearchResultsVisible = false">HIDE {{ optionsSearchResults.length }} RESULTS</v-btn>
              </template>
            </v-col>
            <v-col class="pr-6 text-right">
              <v-btn text color="primary" class="mr-2" @click="handleOptionsSearchCancelClick">Cancel</v-btn>
              <v-btn outlined color="primary" @click="handleOptionsSearchUpdateClick">Update</v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <div class="mt-6 pb-6 pr-6 text-right ">
            <v-btn text color="primary" class="mr-2" @click="resetAll"
            >Reset All</v-btn>
            <v-btn outlined color="primary" @click="applyFilters">
              Apply
            </v-btn>
          </div>
        </template>
      </div>
    </v-card>
  </div>
</template>

<script>

import { formatString } from '@/variables'
import { headersFilters } from '@/utils'
import CollapsibleSelect from '@/components/CollapsibleSelect'
import OptionsSearch from '@/components/Options/OptionsSearch.vue'
import OptionsSearchResults from '@/components/Options/OptionsSearchResults.vue'

import { usePricingFiltersStore } from '@/store/pinia/pricingFiltersStore'
import { debounce } from 'lodash'

export default {
  setup () {
    return {
      pricingFiltersStore: usePricingFiltersStore()
    }
  },
  name: 'PricingFilterWidget',
  components: { CollapsibleSelect, OptionsSearch, OptionsSearchResults },
  data () {
    return {
      pricingPageFilters: {},
      pricingPageOptionsFilter: [],
      newProperty: null,
      newPropertyValues: [],
      optionsSearchResultsVisible: false,
      optionsSearchResults: [],
      savePricingFilter: debounce(this.savePricingFilterRaw, 200)
    }
  },
  watch: {
    $route () {
      this.pricingFiltersStore.setFiltersApplied(this.hasPricingFilters())
    },
    filtersOpened () {
      this.pricingPageFilters = JSON.parse(localStorage.getItem('pricingFilters') || '{}')
      this.pricingPageOptionsFilter = JSON.parse(localStorage.getItem('pricingOptionsFilter') || '[]')
      this.pricingFiltersStore.setFiltersApplied(this.hasPricingFilters())
    },
  },
  mounted () {
    this.loadProperties('skus')
    this.pricingFiltersStore.setFiltersApplied(this.hasPricingFilters())
  },
  computed: {
    filtersList () {
      const array = []
      const headers = {}
      for (const key of Object.keys(this.properties).sort()) {
        const title = key.split('/')
        if (headers[title[1]] === undefined) {
          headers[title[1]] = { header: formatString(title[1] || ''), text: [] }
          array.push(headers[title[1]])
        }
        headers[title[1]].text.push(formatString(title[2] || ''), formatString(title[1] || ''))
        array.push({
          value: key,
          headerTitle: formatString(title[1] || ''),
          text: formatString(title[2] || '')
        })
      }
      if (!array.length) {
        return []
      }
      return array
    },
    properties () {
      const properties = {...(this.pricingFiltersStore.propertiesWithDataset['skus'] || {}) }
      for (const key in properties) {
        if (!isNaN(parseFloat(properties[key][0]))) {
          properties[key].sort((a, b) => {
            return parseFloat(b) - parseFloat(a)
          })
        } else {
          properties[key].sort()
        }
      }
      return properties
    },
    filtersOpened () {
      return this.pricingFiltersStore.filtersOpened
    },
    optionsSearchVisible () {
      return this.pricingFiltersStore.optionsSearchVisible
    },
  },
  methods: {
    headersFilters,
    applyFilters () {
      this.pricingFiltersStore.setFiltersOpened(false)
      this.savePricingFilter()
    },
    hasPricingFilters () {
      const filters = JSON.parse(localStorage.getItem('pricingFilters')) || {}
      const optionsFilter = JSON.parse(localStorage.getItem('pricingOptionsFilter')) || []
      return Object.keys(filters).length || optionsFilter.length
    },
    handleFilterPropertyChanged (prop, index) {
      delete this.pricingPageFilters[index]
      this.pricingPageFilters[prop] = []
      this.savePricingFilter()
    },
    handleNewPropertyValueChanged () {
      setTimeout(() => {
        this.$set(this.pricingPageFilters, this.newProperty, this.newPropertyValues.slice())
        this.newProperty = null
        this.newPropertyValues = []
        this.savePricingFilter()
      }, 120)
    },
    resetPricingFilter () {
      this.pricingPageFilters = {}
      this.savePricingFilter()
    },
    removePricingFilter (index) {
      delete this.pricingPageFilters[index]
      this.pricingPageFilters = { ...this.pricingPageFilters }
      this.pricingFiltersStore.setFiltersApplied(!!Object.keys(this.pricingPageFilters || {}).length)
      this.savePricingFilter()
    },
    resetAll () {
      this.pricingPageFilters = {}
      this.savePricingFilter()
      this.pricingFiltersStore.setFiltersApplied(false)
    },
    handleRevertOptionsFilterClick () {
      this.pricingPageOptionsFilter = []
      this.savePricingFilter()
      this.pricingFiltersStore.setFiltersApplied(this.hasPricingFilters())
    },
    savePricingFilterRaw () {
      localStorage.setItem('pricingFilters', JSON.stringify(this.pricingPageFilters))
      localStorage.setItem('pricingOptionsFilter', JSON.stringify(this.pricingPageOptionsFilter))
    },
    loadProperties (dataset) {
      return this.pricingFiltersStore.loadProperties(dataset || this.dataset)
    },
    async handleOptionsEditClick () {
      this.pricingFiltersStore.setOptionsSearchVisible(true)

      this.$refs.optionsSearch.searchDebounce.cancel()
      await this.$refs.optionsSearch.searchByFilters()
    },
    handleOptionsSearchCancelClick () {
      this.pricingFiltersStore.setOptionsSearchVisible(false)
    },
    handleOptionsSearchResultsUpdated (results) {
      this.optionsSearchResults = Object.freeze(results)
    },
    async handleOptionsSearchUpdateClick () {
      this.$refs.optionsSearch.searchDebounce.cancel()
      await this.$refs.optionsSearch.searchByFilters()

      const optKeys = this.optionsSearchResults.map(r => r.option_key)

      this.pricingPageOptionsFilter = [ ...optKeys ]
      this.savePricingFilter()
      this.filtersChanged = true
      this.pricingFiltersStore.setOptionsSearchVisible(false)
    },
    formatString,
  },
}
</script>

<style lang="scss">
.pricing-filter-widget {
  .form-div {
    width: 100%;
    .title-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
  position: fixed;
  top: 56px;
  right: 0;
  z-index: 6;
  .v-input {
    font-size: 14px;
    padding-top: 12px;
    margin-top: 4px;
  }
  .v-text-field.v-input--dense {
    padding-top: 0;
  }
  .view-dataset-title {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .view-panel {
    border-bottom: 1px solid #dadcdf;
  }
  .v-select__selection--comma {
    margin-bottom: 2px;
  }
  .orange--text-input {
    color: #ff9800 !important;
    * {
      color: #ff9800 !important;
    }
  }
  .v-text-field {
    .v-label {
      font-size: 14px;
    }
    .v-label--active {
      font-size: 12px;
      transform: translateY(-20px);
    }
  }
  .values-select {
    .v-chip .v-chip__content {
      &>span {
        width: 20px;
        overflow: hidden;
      }
    }
  }
  .v-select.v-select--chips .v-select__selections {
    min-height: 32px;
  }
  .operate-search-properties {
    .property-values {
      padding-top: 12px !important;
    }
    .properties-container {
      margin-left: 0 !important;
      padding-left: 36px;
    }
  }
  .filter-widget-card {
    box-shadow: inset 0px 2px 2px #ccc !important;
    width: 500px;
    height: calc(calc(100vh / var(--zoom)) - 56px);
    overflow: hidden;
    z-index: 1000;
    border-radius: 0px !important;
    justify-content: center;
    align-items: start;
  }
  h4 {
    font-size: 16px;
  }
  .filter-options {
    height: calc(100% - 85px);
    margin-right: 12px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
  }
  .options-search-results {
    height: calc(100% - 85px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
