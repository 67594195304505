<template>
  <div class="row flex xs12" >
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <div class="col-12" v-if="localData">
      <v-card
        flat
        class="pa-4 pb-0"
        min-height="350px"
      >
        <v-row justify="space-between" class="pa-3 pb-4">
          <h4 class="mb-4 text-center">Projection</h4>
        </v-row>
        <ChartElement
          title="markdown_projection_chart"
          ref="markdown_projection_chart"
          :params="{}"
          data-type="prediction_graph"
          :chartData="localData"
          :padding="false"
          :fixedHeight="false"
        />
      </v-card>
    </div>
  </div>
</template>

<script>
import ChartElement from '@/chart/ChartElement'
import { getMarkdownOrdersData, getMarkdownOrderSettings } from '@/api/markdown_proposals'

export default {
  name: 'MDProjectionGraph',
  components: { ChartElement },
  data () {
    return {
      localData: null,
      loading: false,
    }
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    option_key: {
      type: String,
      default: null,
    },
    isolatedLoad: {
      type: Boolean,
      default: false,
    },
  },
  mounted () {
    this.localData = this.data
    this.loadData()
  },
  watch: {
    option_key () {
      this.loadData()
    },
    data () {
      this.localData = this.data
    },
  },
  methods: {
    async loadData () {
      if (!this.isolatedLoad || !this.option_key) {
        return
      }
      try {
        this.loading = true
        const orderSettings = await getMarkdownOrderSettings(this.option_key.toString())
        orderSettings.option_key = this.option_key
        this.localData = await getMarkdownOrdersData(orderSettings)
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
