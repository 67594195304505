<template>
  <div class="settings-configuration-page">
    <v-container fluid>
      <v-row class="mx-n6 py-2">
        <v-col class="py-0 d-flex align-center">
          <h3
            class="mb-6 mt-6 mr-2 breadcrumb-lvl-0"
            @click="$router.push({ name: currentModule + '-settings-main' })"
          >Settings</h3>/
          <h3 class="mb-6 mt-6 ml-2">Configuration</h3>
        </v-col>
      </v-row>
      <v-row class="my-1">
        <v-col :cols="4" class="mr-6 pl-0">
          <v-card class="pa-4 d-flex justify-center"
            :to="{name: currentModule + '-settings-configuration-stock'}">
            <div class="mt-16 text-center">
              <v-icon class="action-btn-primary tile-icon">layers</v-icon>
              <div class="text-uppercase mt-8 mb-10 tile-text">Stock</div>
            </div>
          </v-card>
        </v-col>

        <v-col :cols="4" class="mr-6 pl-0">
          <v-card class="pa-4 d-flex justify-center"
            :to="{name: currentModule + '-settings-configuration-buy'}">
            <div class="mt-16 text-center">
              <v-icon class="action-btn-primary tile-icon">shopping_basket</v-icon>
              <div class="text-uppercase mt-8 mb-10 tile-text">Buy</div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'SettingsConfiguration',
  data () {
    return {
      generalStore: useGeneralStore()
    }
  },
  computed: {
    currentModule () {
      return this.generalStore.currentModule
    },
  },
}
</script>

<style scoped lang="scss">

.settings-configuration-page {
  .breadcrumb-lvl-0 {
    cursor: pointer;
    color: var(--text-sub-black);
  }
    .v-card {
      .tile-icon {
        cursor: pointer;
        font-size: 100px;
      }
      .tile-text {
        font-size: 20px;
        font-weight: 500;
      }
      .tile-image {
        height: 115px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      &:hover {
        .tile-icon {
          color: var(--primary)
        }
      }
  }
}
</style>
