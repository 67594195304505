<template>
  <div
    id="strategy-home-page"
    class="p-relative pt-0 mb-12"
  >
    <v-overlay absolute color="white" :value="loading" >
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="align-baseline mt-3 mb-3">
      <v-col cols="3" class="py-0">
        <h3>Home</h3>
      </v-col>
      <v-spacer />
      <v-col md="2" cols="4" class="py-0">
        <StrategyCubeSelect
          ref="cubeSelect"
          @cube-inputs-loaded="handleCubeInputsLoaded"
        />
      </v-col>
      <v-col md="2" cols="4" class="py-0">
        <v-autocomplete
          v-model="selectedDimension"
          label="Dimension"
          clearable
          hide-details="auto"
          class="pt-0 mb-2"
          :items="cubePropertiesGrouped"
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"
          @change="handleSelectedDimensionChanged"
        />
      </v-col>
    </v-row>
    <v-card min-height="100px" class="pt-4">
      <div class="px-6">
        <v-row>
          <v-col>
            <h4 class="my-2 mb-6">KPIs</h4>
            <StrategyKPITiles
              :kpi-items="kpiItems"
              :items-number="7"
              top-value-key="act"
              bottom-value-key="bdg"
            />
          </v-col>
        </v-row>
      </div>
      <div class="mt-8 px-6">
        <StrategyRevenueCharts
          ref="revenueCharts"
          :charts-data="revenueChartsData"
          :charts-options="revenueChartsOptions"
        />
      </div>
    </v-card>
    <v-card min-height="300px" class="pt-4 mt-8">
      <div class="px-6">
        <StrategyMarginCharts
          ref="marginCharts"
          :charts-data="marginChartsData"
          :charts-options="marginChartsOptions"
        />
      </div>
    </v-card>
    <v-card min-height="300px" class="pt-4 mt-8">
      <div class="px-6">
        <StrategyWarnings
          ref="warnings"
          :warnings-data="warningsTablesData"
          :warnings-config="warningsViewsConfig"
        />
      </div>
    </v-card>
    <v-card min-height="300px" class="pt-4 mt-8">
      <div class="px-6">
        <StrategyOtbIntake
          ref="otbIntake"
          :charts-data="otbIntakeChartsData"
          :charts-options="otbIntakeChartsOptions"
        />
        <StrategyOtbOpportunities
          :table-data="otbOpportunities"
        />
      </div>
    </v-card>
    <v-card min-height="300px" class="pt-4 mt-8">
      <div class="px-6">
        <StrategyMarkdown
          ref="markdown"
          :charts-data="markdownChartsData"
          :charts-options="markdownChartsOptions"
          :table-data="markdownTableData"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import { cloneDeep, round } from 'lodash'
import { loadViewData } from '@/api/analytics'
import { formatString } from '@/variables'
import StrategyCubeSelect from '@/components/Strategy/StrategyCubeSelect'
import StrategyKPITiles from '@/components/Strategy/StrategyKPITiles'
import StrategyRevenueCharts from '@/components/Strategy/StrategyRevenueCharts'
import StrategyMarginCharts from '@/components/Strategy/StrategyMarginCharts'
import StrategyOtbIntake from '@/components/Strategy/StrategyOtbIntake'
import StrategyOtbOpportunities from '@/components/Strategy/StrategyOtbOpportunities'
import StrategyWarnings from '@/components/Strategy/StrategyWarnings'
import StrategyMarkdown from '@/components/Strategy/StrategyMarkdown'
import {
  kpisViewConfig,
  marginChartsViewsConfig,
  markdownChartsViewsConfig,
  markdownTableViewConfig,
  otbIntakeChartsViewsConfig,
  otbOpportunitiesViewConfig,
  revenueChartsViewsConfig,
  warningsViewsConfig,
} from '@/staticConfigs/strategyHomeViews.js'
import { useCubesStore } from '@/store/pinia/cubesStore'

import { useStrategyFiltersStore } from '@/store/pinia/strategyFiltersStore'

export default {
  name: 'StrategyHomePage',
  components: { StrategyCubeSelect, StrategyKPITiles, StrategyRevenueCharts, StrategyMarginCharts, StrategyOtbIntake,
                StrategyOtbOpportunities, StrategyWarnings, StrategyMarkdown },
  setup () {
    return {
      cubesStore: useCubesStore(),
      strategyFiltersStore: useStrategyFiltersStore()
    }
  },
  data () {
    const revenueChartsData = Object
      .keys(revenueChartsViewsConfig)
      .reduce((acc, cur) => ({
        ...acc,
        [cur]: null
      }), {})

    const marginChartsData = Object
      .keys(marginChartsViewsConfig)
      .reduce((acc, cur) => ({
        ...acc,
        [cur]: null
      }), {})

    const otbIntakeChartsData = Object
      .keys(marginChartsViewsConfig)
      .reduce((acc, cur) => ({
        ...acc,
        [cur]: null
      }), {})

    const warningsTablesData = Object
      .keys(warningsViewsConfig)
      .reduce((acc, cur) => ({
        ...acc,
        [cur]: null
      }), {})

    const markdownChartsData = Object
      .keys(markdownChartsViewsConfig)
      .reduce((acc, cur) => ({
        ...acc,
        [cur]: null
      }), {})

    return {
      loading: false,
      cubeProperties: [],
      cubePropertiesAnalytics: [],
      selectedDimension: null,
      otbOpportunities: [],
      markdownTableData: [],
      kpis: null,
      revenueChartsData,
      revenueChartsOptions: Object.freeze(revenueChartsViewsConfig),
      marginChartsData,
      marginChartsOptions: Object.freeze(marginChartsViewsConfig),
      otbIntakeChartsData,
      otbIntakeChartsOptions: Object.freeze(otbIntakeChartsViewsConfig),
      warningsTablesData,
      warningsViewsConfig: Object.freeze(warningsViewsConfig),
      markdownChartsData,
      markdownChartsOptions: Object.freeze(markdownChartsViewsConfig),
    }
  },
  computed: {
    cubePropertiesGrouped () {
      const result = []
      const propertiesSorted = this.cubePropertiesAnalytics.slice().sort()
      let lastHeader = null

      for (const prop of propertiesSorted) {
        const parts = prop.split('/')

        if (!lastHeader || parts[0] !== lastHeader) {
          result.push({
            header: formatString(parts[0]),
          })

          lastHeader = parts[0]
        }

        result.push({
          value: prop,
          text: formatString(parts[1]),
        })
      }

      return result
    },
    filtersOpened () {
      return this.strategyFiltersStore.filtersOpened
    },
    kpiItems () {
      if (!this.kpis) {
        return []
      }

      // this function will return an array of objects with keys as metric names, and values as bdg and act
      return Object.keys(this.kpis).map(key => {
        let bdg = 0
        let act = 0

        Object.keys(this.kpis[key]).forEach(k => {
          if (k.endsWith('(bdg)')) {
            bdg = this.kpis[key][k]
          } else if (k.endsWith('(act)')) {
            act = this.kpis[key][k]
          }
        })

        if (['Discount', 'Margin'].includes(key)) {
          bdg = round(bdg * 100, 2)
          act = round(act * 100, 2)
        }

        return {
          title: formatString(key),
          bdg,
          act,
          delta: act === 0 ? 0 : Math.round((act - bdg) / bdg * 100),
        }
      })
    },
  },
  watch: {
    async filtersOpened (newValue) {
      const state = this.strategyFiltersStore

      if (!newValue && state.filtersChanged) {
        this.loading = true

        try {
          await this.loadPageData()

          this.$nextTick(() => {
            this.$refs.revenueCharts.drawCharts()
            this.$refs.marginCharts.drawCharts()
            this.$refs.otbIntake.drawCharts()
            this.$refs.markdown.drawCharts()
          })
        } finally {
          this.loading = false
        }
      }
    },
  },
  methods: {
    async loadPageData () {
      return Promise.allSettled([
        this.loadKPIsData(),
        this.loadRevenueData(),
        this.loadMarginData(),
        this.loadOtbIntakeData(),
        this.loadOtbOpportunities(),
        this.loadWarningsData(),
        this.loadMarkdownData(),
        this.loadMarkdownTableData(),
      ])
    },
    async loadKPIsData () {
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}

      const payload = cloneDeep(kpisViewConfig)
      payload.filters['properties/cube/cube_key'] = [this.$refs.cubeSelect.currentCube]
      payload.filters['properties/cube/version_key'] = [this.$refs.cubeSelect.currentVersion]

      Object.keys(additionalFilters).forEach(f => {
        payload.filters[`properties/${f}`] = additionalFilters[f]
      })

      payload.filters = this.mapFilterPropertiesForAnalytics(payload.filters)

      const result = await loadViewData(payload, payload)

      if (!result[0]) {
        return
      }

      this.kpis = {}
      Object
        .keys(result[0])
        .forEach(key => {
          if (key !== 'properties/cube/cube_key') {
            const metric = key.split(' (')[0]

            if (!this.kpis[metric]) {
              this.kpis[metric] = {}
            }

            this.kpis[metric][key] = result[0][key]
          }
        })
    },
    async loadRevenueData () {
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}

      for (const chart of Object.keys(revenueChartsViewsConfig)) {
        const payload = cloneDeep(revenueChartsViewsConfig[chart])
        payload.filters['properties/cube/cube_key'] = [this.$refs.cubeSelect.currentCube]
        payload.filters['properties/cube/version_key'] = [this.$refs.cubeSelect.currentVersion]

        Object.keys(additionalFilters).forEach(f => {
          payload.filters[`properties/${f}`] = additionalFilters[f]
        })

        payload.filters = this.mapFilterPropertiesForAnalytics(payload.filters)

        const result = await loadViewData(payload, payload)
        const acc = {
          weekNumbers: []
        }

        revenueChartsViewsConfig[chart].measures.forEach(m => {
          acc[m.name] = []
        })

        this.revenueChartsData[chart] = result.reduce((acc, cur) => {
          acc.weekNumbers.push(cur['properties/calendar/week'])

          revenueChartsViewsConfig[chart].measures.forEach(m => {
            acc[m.name].push(cur[m.name])
          })

          return acc
        }, acc)
      }
    },
    async loadMarginData () {
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}

      for (const chart of Object.keys(marginChartsViewsConfig)) {
        const payload = cloneDeep(marginChartsViewsConfig[chart])
        payload.filters['properties/cube/cube_key'] = [this.$refs.cubeSelect.currentCube]
        payload.filters['properties/cube/version_key'] = [this.$refs.cubeSelect.currentVersion]

        Object.keys(additionalFilters).forEach(f => {
          payload.filters[`properties/${f}`] = additionalFilters[f]
        })

        payload.filters = this.mapFilterPropertiesForAnalytics(payload.filters)

        const result = await loadViewData(payload, payload)
        const acc = {
          weekNumbers: []
        }

        marginChartsViewsConfig[chart].measures.forEach(m => {
          acc[m.name] = []
        })

        this.marginChartsData[chart] = result.reduce((acc, cur) => {
          acc.weekNumbers.push(cur['properties/calendar/week'])

          marginChartsViewsConfig[chart].measures.forEach(m => {
            acc[m.name].push(cur[m.name])
          })

          return acc
        }, acc)
      }
    },
    async loadOtbIntakeData () {
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}

      for (const chart of Object.keys(otbIntakeChartsViewsConfig)) {
        const payload = cloneDeep(otbIntakeChartsViewsConfig[chart])
        payload.filters['properties/cube/cube_key'] = [this.$refs.cubeSelect.currentCube]
        payload.filters['properties/cube/version_key'] = [this.$refs.cubeSelect.currentVersion]

        Object.keys(additionalFilters).forEach(f => {
          payload.filters[`properties/${f}`] = additionalFilters[f]
        })

        payload.filters = this.mapFilterPropertiesForAnalytics(payload.filters)

        const result = await loadViewData(payload, payload)
        const acc = {
          weekNumbers: []
        }

        otbIntakeChartsViewsConfig[chart].measures.forEach(m => {
          acc[m.name] = []
        })

        this.otbIntakeChartsData[chart] = result.reduce((acc, cur) => {
          acc.weekNumbers.push(cur['properties/calendar/week'])

          otbIntakeChartsViewsConfig[chart].measures.forEach(m => {
            acc[m.name].push(cur[m.name])
          })

          return acc
        }, acc)
      }
    },
    async loadOtbOpportunities () {
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}

      const payload = cloneDeep(otbOpportunitiesViewConfig)
      payload.filters['properties/cube/cube_key'] = [this.$refs.cubeSelect.currentCube]
      payload.filters['properties/cube/version_key'] = [this.$refs.cubeSelect.currentVersion]

      Object.keys(additionalFilters).forEach(f => {
        payload.filters[`properties/${f}`] = additionalFilters[f]
      })

      if (this.selectedDimension) {
        payload.dimensions = [`properties/${this.selectedDimension}`]
      }

      payload.filters = this.mapFilterPropertiesForAnalytics(payload.filters)

      const result = await loadViewData(payload, payload)

      this.otbOpportunities = Object.freeze(result)
    },
    async loadWarningsData () {
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}

      for (const metric of Object.keys(warningsViewsConfig)) {
        const payload = cloneDeep(warningsViewsConfig[metric])
        payload.filters['properties/cube/cube_key'] = [this.$refs.cubeSelect.currentCube]
        payload.filters['properties/cube/version_key'] = [this.$refs.cubeSelect.currentVersion]

        Object.keys(additionalFilters).forEach(f => {
          payload.filters[`properties/${f}`] = additionalFilters[f]
        })

        if (this.selectedDimension) {
          payload.dimensions = [`properties/${this.selectedDimension}`]
        }

        payload.filters = this.mapFilterPropertiesForAnalytics(payload.filters)

        const result = await loadViewData(payload, payload)

        this.warningsTablesData[metric] = Object.freeze(result)
      }
    },
    async loadMarkdownData () {
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}

      for (const chart of Object.keys(markdownChartsViewsConfig)) {
        const payload = cloneDeep(markdownChartsViewsConfig[chart])
        payload.filters['properties/cube/cube_key'] = [this.$refs.cubeSelect.currentCube]
        payload.filters['properties/cube/version_key'] = [this.$refs.cubeSelect.currentVersion]

        Object.keys(additionalFilters).forEach(f => {
          payload.filters[`properties/${f}`] = additionalFilters[f]
        })

        payload.filters = this.mapFilterPropertiesForAnalytics(payload.filters)

        const result = await loadViewData(payload, payload)
        const acc = {
          weekNumbers: []
        }

        markdownChartsViewsConfig[chart].measures.forEach(m => {
          acc[m.name] = []
        })

        this.markdownChartsData[chart] = result.reduce((acc, cur) => {
          acc.weekNumbers.push(cur['properties/calendar/week'])

          markdownChartsViewsConfig[chart].measures.forEach(m => {
            acc[m.name].push(cur[m.name])
          })

          return acc
        }, acc)
      }
    },
    async loadMarkdownTableData () {
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}

      const payload = cloneDeep(markdownTableViewConfig)
      payload.filters['properties/cube/cube_key'] = [this.$refs.cubeSelect.currentCube]
      payload.filters['properties/cube/version_key'] = [this.$refs.cubeSelect.currentVersion]

      Object.keys(additionalFilters).forEach(f => {
        payload.filters[`properties/${f}`] = additionalFilters[f]
      })

      if (this.selectedDimension) {
        payload.dimensions = [`properties/${this.selectedDimension}`]
      }

      payload.filters = this.mapFilterPropertiesForAnalytics(payload.filters)

      const result = await loadViewData(payload, payload)

      this.markdownTableData = Object.freeze(result)
    },
    async handleCubeInputsLoaded () {
      this.setCubeInputData()
      const selectedDimension = localStorage.getItem('strategyHomeDimension')
      this.selectedDimension = selectedDimension || this.cubePropertiesGrouped.find(p => !p.header).value

      this.loading = true

      try {
        await this.loadPageData()

        this.$nextTick(() => {
          this.$refs.revenueCharts?.drawCharts()
          this.$refs.marginCharts?.drawCharts()
          this.$refs.otbIntake?.drawCharts()
          this.$refs.markdown?.drawCharts()
        })
      } finally {
        this.loading = false
      }
    },
    async handleSelectedDimensionChanged () {
      localStorage.setItem('strategyHomeDimension', this.selectedDimension)
      this.loading = true

      try {
        await Promise.all([
          this.loadOtbOpportunities(),
          this.loadWarningsData(),
          this.loadMarkdownTableData(),
        ])
      } finally {
        this.loading = false
      }
    },
    setCubeInputData () {
      const { properties, analytics_properties } = this.cubesStore.cubeInputData

      this.properties = Object.freeze(properties)

      this.cubePropertiesAnalytics = analytics_properties
        .map(p => p.name)
        .filter(p => p.includes('/'))
    },
    mapFilterPropertiesForAnalytics (filters) {

      return Object
        .keys(filters)
        .reduce((acc, key) => {
          const property = this.properties.find(p => p.name === key.replace('properties/', ''))

          const newKey = property ? key.replace(property.name, property.analytics_name) : key

          return {
            ...acc,
            [newKey]: filters[key],
          }
        }, {})
    },
  },
}
</script>

<style lang="scss">
#strategy-home-page {
  .kpi-tiles {
    .kpi-tile {
      background-color: white;
      height: 130px;
      flex-basis: 170px;
      display: inline-block;
      .tile-title {
        font-size: 12px;
        font-weight: 500;
      }
      .tile-value {
        font-size: 16px;
        font-weight: 500;
      }
      .tile-subtitle-lg {
        font-size: 14px;
        font-weight: 500;
      }
      .tile-subtitle {
        font-size: 12px;
        font-weight: 500;
      }
      .increase {
        color: #4caf50;
      }
      .decrease {
        color: #f44336;
      }
    }
  }
  @media (min-width: 1600px) {
    .kpi-tiles {
      .kpi-tile {
        flex-basis: 190px!important;
      }
    }
  }
  @media (min-width: 1900px) {
    .kpi-tiles {
      .kpi-tile {
        flex-basis: 220px!important;
      }
    }
  }
}
</style>
