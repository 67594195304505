<template>
  <div class="stock-transfers-page">
    <h3 class="mb-6 mt-6">Transfers</h3>

    <template v-if="filtersDataLoaded">
      <ChartsCarousel/>
      <v-card class="my-6" ref="proposal_header_row" >
        <v-row class="mt-0 pb-2">
          <v-col>
            <h4 class="px-4 py-1">Proposals</h4>
          </v-col>
        </v-row>
        <v-tabs v-model="activeTab">
          <v-tabs-slider />
          <v-tab v-for="tab in tabsList" :key="tab" class="px-8">
            {{ tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <TransfersTable
              :key="activeType"
              :ref="activeType.toLowerCase() +'Table'"
              :type="activeType.toLowerCase()"
              @optimized-changed="handleOptimizedChange"
            />
          </v-tab-item>
          <v-tab-item>
            <TransfersFreeTable
              ref="freeTable"
              :key="freeTableKey"
              @filters-removed="removeFilters"
            />
          </v-tab-item>
          <v-tab-item>
            <TransferBuildForm
              :key="acceptedKey+'form'"
              ref="buildForm"
              :activeType="activeTypeBuild"
            />
            <v-divider />
            <TransfersBuildTable
              :key="acceptedKey + 'table'"
              v-if="stockTransfersStore.transfersBuildVisible"
              ref="buildTable"
              :active-type="activeTypeBuild"
              @type-change="onTypeChangeBuild"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <v-card class="mt-6 mb-9 pb-4 p-relative">
        <v-overlay absolute color="white" :value="stockTransfersStore.loadingTransfersList['accepted']">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <h4 class="px-4 py-4">Accepted</h4>
        <AcceptedTable ref="acceptedTable" />
      </v-card>
    </template>
  </div>
</template>

<script>
import ChartsCarousel from '@/components/Transfers/ChartsCarousel'
import TransfersTable from '@/components/Transfers/TransfersTable'
import AcceptedTable from '@/components/Transfers/AcceptedTable'
import TransferBuildForm from '@/components/Transfers/TransferBuildForm'
import TransfersBuildTable from '@/components/Transfers/TransfersBuildTable'
import TransfersFreeTable from '@/components/Transfers/TransfersFreeTable'
import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore(),
      stockTransfersStore: useStockTransfersStore(),
    }
  },
  components: {
    TransfersFreeTable,
    ChartsCarousel,
    TransfersTable,
    AcceptedTable,
    TransferBuildForm,
    TransfersBuildTable,
  },
  data () {
    return {
      freeTableKey: 0,
      acceptedKey: 0,
      tabsList: ['Box', 'Free', 'build'],
      activeTab: 0,
      activeType: 'Combinations',
      activeTypeBuild: 'Combinations',
      freeTrasfersOptions: 'Options',
      option_name: undefined,
    }
  },
  computed: {
    filtersDataLoaded () {
      return this.filtersStore.filtersDataLoaded
    },
    activeTabName () {
      return this.tabsList[this.activeTab]
    },
  },
  mounted () {
    this.option_name = this.$route.query.option_name
    if (this.option_name) {
      this.activeTab = 1
      this.focusOption(this.option_name)
    }
  },
  watch: {
    'filtersStore.filtersChanged': {
      handler (newVal) {
        if (newVal) {
          this.handleFilterChanged()
        }
      },
    },
    'stockTransfersStore.transferAccepted': {
      handler (newVal) {
        if (newVal) {
          this.handleTransferAccepted()
        }
      },
    },
    'stockTransfersStore.builtTransfersAcceptedAll': {
      handler (newVal) {
        if (newVal) {
          this.handleBuiltTransfersAcceptedAll()
        }
      },
    },
    'stockTransfersStore.transferDeleted': {
      handler (newVal) {
        if (newVal) {
          this.handleTransferDeleted()
        }
      },
    },
  },
  methods: {
    removeFilters () {
      this.option_name = undefined
      this.freeTableKey++
    },
    onTypeChangeBuild (type){
      this.activeTypeBuild = type
      setTimeout(()=> {
        this.$refs.buildForm.buildTransfers()
      })
    },
    focusOption (optionName) {
      const interval = setInterval(() => {
        if (this.$refs.proposal_header_row?.$el) {
          this.$refs.proposal_header_row.$el.scrollIntoView({ behavior: 'smooth', block: 'start' })
          clearInterval(interval)
          this.option_name = optionName
          this.freeTableKey++
        }
      }, 400)
    },
    handleFilterChanged () {
      this.$refs['acceptedTable'].loadData()

      if (this.activeTab === 1) {
        this.$refs['freeTable'].loadTransfersFree()
      }

      if (this.activeTab === 0) {
        this.$refs[`${this.activeType.toLowerCase()}Table`].loadData()
      }
    },
    handleOptimizedChange () {
      this.$nextTick(() => {
        this.$refs['acceptedTable'].loadData()

        if (this.activeTab === 0) {
          this.$refs[`${this.activeType.toLowerCase()}Table`].loadData()
        }
      })
    },
    handleTransferDeleted () {
      if (this.activeTab === 0) {
        this.$refs[`${this.activeType.toLowerCase()}Table`].loadData()
      }
    },
    handleTransferAccepted () {
      setTimeout(() => {
        this.$refs['acceptedTable'].loadData()
      }, 500)

      if (this.activeTab === 2) {
        this.acceptedKey++
      }
      // if (this.activeTab === 1) {
      //   this.$refs['freeTable'].loadData()
      // }
    },
    handleBuiltTransfersAcceptedAll () {
      this.$refs['acceptedTable'].loadData()
      this.$refs['buildForm'].resetForm()
    },
  },
}
</script>

<style lang="scss">
.stock-transfers-page {
    .v-tab {
        width: 220px;
    }
}
</style>
