<template>
  <v-card class="jobs-settings pb-4 mb-12" flat min-height="400px">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-row class="ma-0 align-center pl-6 pt-2">
      <h4>Product features</h4>
      <v-col class="text-right pr-8">
        <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
      </v-col>
    </v-row>
    <data-tables
      style="width: 100%"
      v-if="!loading"
      :data="features"
      :page-size="10"
      :filters="tableFilters"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
      filter-multiple
      @filter-change="filters => handleFilterChange('tableFilters', filters)"
    >
      <el-table-column
        width="10"
      />
      <el-table-column
        v-for="(col) in columns"
        :key="col"
        :prop="col"
        :label="col"
        :column-key="col"
        sortable="custom"
        :filters="getColumnFilterValues('features',  col)"
      >
        <template slot-scope="scope">
          <span class="d-flex justify-space-between">
            <ChipsDropdown v-if="col === 'ML missing sample'" :items="scope.row[col]" />
            <v-checkbox
              v-else-if="typeof scope.row[col] === 'boolean'"
              v-model="scope.row[col]"
              disabled
              dense
            >
            </v-checkbox>
            <span v-else>
              {{formatValue(scope.row[col])}}
            </span>
          </span>
        </template>
      </el-table-column>
    </data-tables>
  </v-card>
</template>

<script>
import TableSearch from '../components/TableSearch'
import { loadFeatureStores } from '../api/features'
import columnFilters from '@/mixins/columnFilters'
import ChipsDropdown from '@/components/ChipsDropdown'

export default {
  mixins: [columnFilters],
  name: 'SettingsDataFeatures',
  components: { ChipsDropdown, TableSearch },
  data () {
    return {
      features: {},
      columns: [],
      tableFilters: [
        {
          value: ''
        }
      ]
    }
  },
  computed: {
    table_data () {
      return this.features.table_data
    },
    loading () {
      return !this.features || !this.features.length
    }
  },
  methods: {
    getInfo (row) {
      let dataString = ''
      const data = row['Active not in schema']
      for (const key of data) {
        dataString +=
          '<b>' + key + '<br>'
      }
      return dataString
    },
    // getColumnFilterValues (listProp, col) {
    //     let values = _.flatten(this.features.table_data).map(x => x[col])
    //     return _.uniq(values).filter((item) => item !== null).map(v => {
    //         return ({
    //             text: v,
    //             value: v,
    //         })
    //     })
    // },
    formatValue (value) {
      if (typeof value === 'number') {
        const splitted = value.toFixed(2).split('.')
        return splitted[1] === '00' ? splitted[0] : splitted.join('.')
      }
      return value
    },
    handleSearchChanged (val) {
      this.tableFilters[0].value = val
      this.tableKey++
    },
  },
  created () {
    loadFeatureStores().then((data) => {
      this.features = data.data
      this.columns = data.columns
    })
  }
}
</script>

<style scoped>

</style>
