<template>
  <div class="business-rule">
    <div class="row rule-data py-3">
      <v-col class="pl-8">
        <div class="d-flex align-center justify-space-between flex-grow-0 rule-name-color">
          <div class="d-flex align-center ">
            <v-tooltip top max-width="400px">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >info</v-icon>
              </template>
              <span>{{ruleInformation(rule.id).description}}</span>
            </v-tooltip>
            <div class="ml-4 rule-name">{{ ruleInformation(rule.id).name }}</div>
          </div>
          <div class="flex-shrink-0 rounded rule-color ml-6" :style="{
            backgroundColor: ruleInformation(rule.id).color
          }"></div>
        </div>
      </v-col>

      <v-col class="ranges pl-12">
        <form v-for="(item, idx) in ruleInformation(rule.id).variables" :key="idx">
          <!-- Check if we need a slider, range slider or switch -->
          <div v-if="ruleInformation(rule.id).types[idx] === 'range-slider'">
            <div class="d-inline-flex align-center slider">
              <div class="slider-value-from mr-2">{{ ruleInformation(rule.id).minmax[idx][0] }}</div>
              <v-range-slider
                v-model="rule[item]"
                hide-details
                thumb-label
                :min="ruleInformation(rule.id).minmax[idx][0]"
                :max="ruleInformation(rule.id).minmax[idx][1]"
                @change="inputChanged"
              />
              <div class="slider-value-to ml-2">{{ ruleInformation(rule.id).minmax[idx][1] }}</div>
            </div>
          </div>
          <div v-if="ruleInformation(rule.id).types[idx] === 'slider'">
            <div class="d-inline-flex align-center slider">
              <div class="slider-value-from mr-2">{{ ruleInformation(rule.id).minmax[idx][0] }}</div>
              <v-slider
                v-model="rule[item]"
                hide-details
                thumb-label
                :min="ruleInformation(rule.id).minmax[idx][0]"
                :max="ruleInformation(rule.id).minmax[idx][1]"
                @change="inputChanged"
              />
              <div class="slider-value-to ml-2">{{ ruleInformation(rule.id).minmax[idx][1] }}</div>
            </div>
          </div>
          <div v-else-if="ruleInformation(rule.id).types[idx] === 'bool'">
            <v-switch
              v-model="rule[item]"
              hide-details
              class="mt-0 pt-0"
              @change="inputChanged"
            >
            </v-switch>
          </div>
        </form>
      </v-col>

      <!-- Impact bars -->
      <v-col class="impact-bars mt-1 pr-12">
        <StackedProgressBar
          v-if="barsSegments"
          :segments="barsSegments[index + 1]"
          class="stacked-bar"
        />
      </v-col>
    </div>
  </div>
</template>

<script>
import StackedProgressBar from '@/components/StackedProgressBar.vue'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

export default {
  setup () {
    return {
      proposalStore: useStockProposalStore()}
  },
  props: ['setting', 'rule', 'index'],
  data () {
    return {
      barValues: [
        {
          color: '#007aff',
          width: '25%',
          caption: this.proposalStore.impactBarsShare
        },
        {
          color: '#ffd202',
          width: '55%',
          caption: '222'
        },
      ]
    }
  },
  computed: {
    barsSegments () {
      const { impactBarsShare, impactBarsValues } = this.proposalStore

      return impactBarsShare?.map((x, i) => ([
        {
          color: '#007aff',
          width: `${x[0]}%`,
          caption: impactBarsValues[i][0],
        },
        {
          color: '#ffd202',
          width: `${x[1]}%`,
          caption: impactBarsValues[i][1],
        },
      ]))
    },
  },
  components: {
    StackedProgressBar
  },
  methods: {
    ruleInformation (id) {
      // Function is used to return information on business rule
      const rules = this.proposalStore.getRules
      const defaultRule = JSON.parse(JSON.stringify(rules.find(x => x.id === id)))
      const checkFirst = this.setting.rules.find(x => x.id === id)
      const idx = this.setting.rules.indexOf(checkFirst)

      defaultRule['first_rule'] = (parseInt(idx) === 0)
      defaultRule['variables'] = []
      defaultRule['types'] = []
      defaultRule['minmax'] = []
      try {
        defaultRule['args_key'] = Object.values(defaultRule['args_key'])
      } catch (error) {
        defaultRule['args_key'] = []
      }
      for (const def in defaultRule.args_default) {
        defaultRule['variables'].push(def)
        if (Object.keys(defaultRule).includes('args_slider') && Object.keys(defaultRule['args_slider']).includes(def)) {
          if (Array.isArray(defaultRule['args_default'][def])) {
            defaultRule['types'].push('range-slider')
            defaultRule['minmax'].push(defaultRule['args_slider'][def])
          } else {
            defaultRule['types'].push('slider')
            defaultRule['minmax'].push(defaultRule['args_slider'][def])
          }
        } else if (Array.isArray(defaultRule.args_default[def])) {
          defaultRule['types'].push('int array')
          defaultRule['minmax'].push([])
        } else if (typeof defaultRule.args_default[def] === 'boolean') {
          defaultRule['types'].push('bool')
          defaultRule['minmax'].push([])
        } else if (defaultRule.args_default[def] === Object(defaultRule.args_default[def])) {
          defaultRule['types'].push('obj')
          defaultRule['minmax'].push([])
        } else {
          defaultRule['types'].push('int-float')
          defaultRule['minmax'].push([])
        }
      }

      return defaultRule
    },
    inputChanged () {
      // Emit changed when someone changes boolean or (range) slider
      this.$emit('changed')
    }
  },
}
</script>

<style lang="scss">
.business-rule {
    width: 100%;
    .slider {
        width: 200px;
        .slider-value-from,
        .slider-value-to {
            color: #666;
            font-size: 14px;
        }
    }
    .rule-data {
        .rule-name-color {
            width: 260px;
        }
        .rule-name {
            font-size: 13px;
            margin: 0;
            font-weight: 500;
        }
        .rule-color {
            width: 28px;
            height: 28px;
        }
        &:last-child {
            border-bottom: 0;
        }
    }
    .stacked-bar {
        height: 15px;
        min-width: 100%;
    }
}
</style>
