// It is loaded using script
window.google = window.google || undefined
const CLIENT_ID = '728864365640-hgcpb38tib1m9ejg5fdnuaiboebica5p.apps.googleusercontent.com'
export const initializeGoogleOneTap = (authCallback) => {
  // Busy wait for Google to load, then initialize the Google One Tap and render the button
  const interval = setInterval(() => {
    if (window.google) {
      const googleOneTap = window.google
      clearInterval(interval)
      googleOneTap.accounts.id.initialize({
        client_id: CLIENT_ID,
        callback: authCallback,
        cancel_on_tap_outside: true,
      })
      googleOneTap.accounts.id.renderButton(
        document.getElementById('google-btns-div'),
        { theme: 'outline', size: 'large', logo_alignment: 'center', width: 350, height: 40 } // customization attributes
      )
    }
  }, 100)
}
