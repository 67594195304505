<template>
  <div class="shop-budget-progress-legend">
    <div v-for="(item, i) in items" :key="i" class="legend-item">
      <div
        class="mark"
        :style="{ backgroundColor: item.color }"
      ></div>
      <div class="caption">
        {{ item.caption }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.shop-budget-progress-legend {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .legend-item {
        display: flex;
        align-items: center;
        margin-right: 32px;
        .mark {
            width: 30px;
            height: 13px;
            border-radius: 2px;;
            margin-right: 8px;
        }
        .caption {
            font-weight: 500;
        }
    }
}
</style>
