<template>
  <div class="buy-shopping-home-page pb-12">
    <shop-item-full-page
      :suppliers="suppliers"
      :schedules="schedules"
      :kpiFilters="kpiFilters"
      :chart-index="valueChartIndex"
      @filters-changed="$emit('filters-changed', $event)"
      @chart-index-changed="$emit('chart-index-changed', $event)"
      :data="kpiData"
      @modal-closed="dialogView = false; activeTab = 0; $emit('modal-closed')"
      :shopParameters="shopParameters"
      v-if="dialogView"></shop-item-full-page>
    <v-card v-show="!dialogView" v-if="shopParameters.budget_key && shopParameters.scenario_key"
      :key="reloadedKey"
      class="mt-6 p-relative">

      <v-overlay absolute color="white" :value="loading">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <v-tabs v-model="activeTab">
        <v-tabs-slider />
        <v-tab class="px-8" style="width: 260px" key="ShopItems">Shop Items</v-tab>
        <v-tab class="px-8" style="width: 260px" key="Grid">Grid</v-tab>
        <v-tab class="px-8" style="width: 260px" key="Drops">Drops</v-tab>
        <v-tab class="px-8" style="width: 260px" key="Overview" @click.stop="dialogView = true; $emit('dialog-view')">Overview
          <v-icon
            class="mx-2 action-btn-primary"

          >fullscreen
          </v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab" class="flex xs12">
        <v-tab-item :transition="false" :reverse-transition="false" key="ShopItems" class="flex py-4 px-0">
          <v-card
            flat
            v-if="shopParameters.budget_key && shopParameters.scenario_key"
          >
            <v-overlay absolute color="white" :value="shopItemsLoading">
              <v-progress-circular color="primary" indeterminate size="64" width="2" />
            </v-overlay>
            <ShopItems
              @dialog-view="dialogView = true; $emit('dialog-view')"
              @modal-closed="dialogView = false; $emit('modal-closed')"
              :items="shopItemsForDrop"
              :budget-key="shopParameters.budget_key"
              :scenario-key="shopParameters.scenario_key"
              :store-sets="storeSets"
              :payments="payments"
              :suppliers="suppliers"
              :currencies="currencies"
              :schedules="schedules"
              @shop-items-update="handleShopItemsUpdate($event); emitUpdate()"
              @shop-item-selected="handleShopItemSelectionChanged"
              @shop-items-added="handleShopItemsAdded($event); emitUpdate()"
              @shop-item-deleted="handleShopItemDeleted($event); emitUpdate()"
              @margin-calculator-open="handleMarginCalculatorOpen"
            >

              <h4 class="pt-4 px-4 ml-5" slot="title">
                Shop items<template v-if="selectedDrop"> for {{ selectedDrop.drop_name }}</template>
              </h4>
            </ShopItems>
            <div class="p-relative">
              <v-overlay absolute color="white" :value="ordersLoading">
                <v-progress-circular color="primary" indeterminate size="64" width="2" />
              </v-overlay>
              <h4 class="pt-4 px-4">
                Orders<template v-if="selectedItems.length && selectedItems.length < shopItemsForDrop.length"> for
                  <span :key="item.shop_item_key" v-for="(item, index) of selectedItems">
                    {{item.name.split('/')[0]}}
                    <span v-if="index !== selectedItems.length -1">,</span>
                  </span>
                </template>
              </h4>

              <ShopOrders
                :orders="shopOrdersForItem"
                :shop-items="shopItems"
                :schedules="schedules"
                :orderProperties="orderProperties"
                :orderPropertiesAdditional="orderPropertiesAdditional"
                :extra-status-options="extraStatusOptions"
                :status-options="statusOptions"
                :currencies="currencies"
                :suppliers="suppliers"
                :payments="payments"
                @order-saved="handleOrdersChanged($event); emitUpdate()"
                @order-updated="handleOrdersUpdated(); emitUpdate()"
                @need-reload="loadShopItems(); reloadedKey++"
                @order-deleted="handleOrdersChanged($event); emitUpdate()"
              />
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false" key="Grid" class="flex py-4 px-0">
          <ShopGrid
            :kpiFilters="kpiFilters"
            :currencies="currencies"
            :chart-index="valueChartIndex"
            @filters-changed="$emit('filters-changed', $event)"
            @chart-index-changed="$emit('chart-index-changed', $event)">
          </ShopGrid>
        </v-tab-item>

        <v-tab-item :transition="false" :reverse-transition="false" key="Drops" class="flex py-4 px-0">
          <ShopDrops
            v-if="shopParameters.budget_key && activeTab ===2"
          />
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false" key="Overview" class="flex py-4 px-0">
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- Margin tool dialog -->
    <v-dialog
      :value="marginCalculatorDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-12"
      width="90%"
      max-width="90%">
      <v-card class="p-relative">
        <v-card-title v-if="marginCalculatorItem" class="text-body-1 font-weight-medium">
          Margin calculator
          <v-icon class="mx-2">arrow_forward</v-icon>
          {{ marginCalculatorItem.name }}
        </v-card-title>
        <v-divider/>
        <MarginCalculator
          v-if="marginCalculatorItem"
          ref="marginCalculator"
          :item="marginCalculatorItem"
          :shop-item-key="marginCalculatorItem.shop_item_key"
          :supplier-name="marginCalculatorItem.supplier_name"
          @updatedShopItem="loadShopItems"
        />
        <v-divider/>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="marginCalculatorDialogVisible = false"
              >Close</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as shopApi from '@/api/shop'
import * as storeSetsApi from '@/api/storeSets'
import ShopDrops from '@/components/Shop/ShopDrops'
import ShopItems from '@/components/Shop/ShopItems'
import ShopOrders from '@/components/Shop/ShopOrders'
import MarginCalculator from '@/components/Shop/MarginCalculator.vue'

import ShopItemFullPage from './ShopItemFullPage'
import ShopGrid from '@/views/ShopGrid'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  mixins: [],
  components: {
    ShopGrid,
    ShopItemFullPage,
    ShopDrops,
    ShopItems,
    ShopOrders,
    MarginCalculator,
  },
  props: {
    kpiData: {
      type: Object,
      default: () => ({ })
    },
    valueChartIndex: {
      type: Number,
      default () {
        return 0
      }
    },
    scenariosValue: {
      type: Object,
      default () {
        return {}
      }
    },
    kpiFilters: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      loading: false,
      itemInfoDialog: false,
      dialogView: false,
      selectedTask: {},
      orderProperties: [],
      orderPropertiesAdditional: [],
      activeTab: 0,
      reloadedKey: 0,
      shopItemsLoading: false,
      ordersLoading: false,
      marginCalculatorDialogVisible: false,
      marginCalculatorItem: null,
      storeSets: [],
      statusOptions: [{
        text: 'In progress',
        value: 'in progress',
      }, {
        text: 'Ordered',
        value: 'ordered',
      }, {
        text: 'Delivered',
        value: 'delivered',
      }, {
        text: 'To order',
        value: 'to_order',
        disabled: true,
      }],
      shopItems: [],
      shopOrders: [],
      shopParameters: {},
      selectedDrop: null,
      selectedItems: [],
      budgetProgressLoading: false
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    ...mapState(useGeneralStore, {
      orderPoints: (state) => state.buySettings?.order_points || [],
    }),
    currencies () {
      return this.appConfig?.currencies || []
    },
    payments () {
      const payments = this.appConfig?.payments || []

      return payments.map(p => {
        if (!p.schedule?.map) {
          return p
        }
        return {
          ...p,
          schedule: p.schedule.map(s => {
            return {
              ...s,
              perc: s.perc * 100,
            }
          })
        }
      })
    },
    schedules () {
      return this.appConfig?.schedules || []
    },
    extraStatusOptions () {
      return this.orderPoints.map(p => ({
        text: p,
        value: this.$options.filters.formatString(p),
      }))
    },
    suppliers () {
      return (this.appConfig?.suppliers || [])
    },
    activeTabName () {
      return this.tabNames[this.activeTab]
    },
    tabNames () {
      return ['shop_items', 'grid', 'drops']
    },
    shopItemsForDrop () {
      return this.selectedDrop
        ? this.shopItems.filter(item => item.drop_name === this.selectedDrop.drop_name).map(item => ({ ...item, ...item.order_settings }))
        : this.shopItems.map(item => ({
          ...item,
          ...item.order_settings,
          sellthrough: item?.order_settings?.sellthrough === undefined ? 100 : item?.order_settings?.sellthrough,
          week_min: item.week_min || '',
          week_max: item.week_max || ''
        }))
    },
    shopOrdersForItem () {
      return this.selectedItems.length
        ? this.shopOrders.filter(order => this.selectedItems.map(item => item.shop_item_key).indexOf(order.shop_item_key) !== -1)
        : this.shopOrders
    }
  },
  watch: {
    'filtersStore.filtersChanged': {
      handler (newVal) {
        if (newVal) {
          this.handleFilterChanged()
        }
      },
    },
    activeTab: {
      handler () {
        if (this.$route.query.active_tab !== this.activeTabName) {
          this.$router.replace({ name: this.$route.name, query: { ...this.$route.query, active_tab: this.activeTabName } })
        }
      },
    },
    '$route.query.active_tab': {
      handler (newValue) {
        if (newValue && newValue !== this.activeTabName) {
          this.activeTab = this.tabNames.indexOf(newValue)
        }
      },
      immediate: true
    },
    'kpiFilters.week_min': {
      handler () {
        this.loadShopOrders()
      }
    },
    'kpiFilters.week_max': {
      handler () {
        this.loadShopOrders()
      }
    },
  },
  methods: {
    handleShopItemsUpdate () {
      setTimeout(() => this.loadShopOrders(), 2000)
    },
    handleOrdersUpdated () {
      setTimeout(() => this.loadShopItems(), 2000)
    },
    emitUpdate () {
      this.$emit('items-updated')
    },
    watchFiltersDataLoaded () {
      this.$watch(
        'filtersStore.filtersDataLoaded',
        function (newVal) {
          if (newVal) {
            this.loadPage()
          }
        },
        { immediate: true }
      )
    },
    loadStoreSets () {
      const storesLabel = useGeneralStore().currentStoresSetsLabel
      return storeSetsApi
        .getStoreSets(`?label=${storesLabel}`)
        .then(response => {
          this.storeSets = response.stores_sets
        })
    },
    loadShopItems () {
      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()
      const payload = {
        budget_key: this.shopParameters.budget_key,
        scenario_key: this.shopParameters.scenario_key,
        payment_keys: pageFiltersValues.payments || [],
        schedule_keys: pageFiltersValues.schedules || [],
        supplier_keys: pageFiltersValues.suppliers || [],
        dummies: this.filtersStore.selectedFiltersOptions['dummies'],
        shop_items: this.filtersStore.selectedFiltersOptions['shopItems'],
      }

      this.shopItemsLoading = true

      return shopApi
        .getShopItems(payload)
        .then(shopItems => {
          this.shopItems = shopItems
          this.shopItemsLoading = false
        })
    },
    loadShopOrders () {
      const { budget_key, scenario_key } = this.shopParameters
      const { week_min, week_max } = this.kpiFilters
      this.ordersLoading = true
      return shopApi
        .getShopOrders(budget_key, scenario_key, week_min, week_max)
        .then(({ orders, properties, order_properties }) => {
          this.shopOrders = orders
          this.orderProperties = properties
          this.orderPropertiesAdditional = order_properties
          this.ordersLoading = false
        })
    },
    handleFilterChanged () {
      this.loading = true
      this
        .loadShopItems()
        .then(() => {
          this.loading = false
        })
    },
    handleParamsChange (item) {
      localStorage.setItem('shop_items_params', JSON.stringify({ scenario_key: item.scenario_key, budget_key: item.budget_key }))

      const query = { ...this.$route.query }
      if (this.scenariosValue.scenario_key) {
        query.scenario_key = this.scenariosValue.scenario_key
      }
      if (this.scenariosValue.budget_key) {
        query.budget_key = this.scenariosValue.budget_key
      }
      if (item.budget_key && item.scenario_key) {
        this.loading = true
        this.shopParameters.budget_key = item.budget_key
        this.shopParameters.scenario_key = item.scenario_key
        Promise
          .all([
            this.loadShopItems(),
            this.loadShopOrders(),
          ])
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleShopItemSelectionChanged (items) {
      this.selectedItems = items
      this.shopItems.forEach(item=> {
        if(items.findIndex((newItem)=> newItem.shop_item_key === item.shop_item_key) !== -1){
          this.$set(item, 'selected', true)
        }
      })
    },
    handleShopItemsAdded () {
      this.loading = true
      this
        .loadShopItems()
        .finally(() => {
          this.loading = false
        })
    },
    handleShopItemDeleted () {
      this.loading = true
      this
        .loadShopItems()
        .finally(() => {
          this.loading = false
        })
    },
    handleMarginCalculatorOpen (item) {
      this.marginCalculatorItem = { ...item }
      this.marginCalculatorDialogVisible = true
      this.$nextTick(() => {
        this.$refs['marginCalculator'].loadData(item)
      })
    },
    handleOrdersChanged () {
      this.loading = true
      setTimeout(() => {
        this.loadShopOrders().finally(() => {
          this.loading = false
        })
      }, 1000)
    },
    loadPage () {
      this.loading = true
      const promises = [
        this.loadStoreSets(),
      ]
      const paramsFromStorage = JSON.parse(localStorage.getItem('shop_items_params')) || {}

      this.shopParameters.scenario_key = parseFloat(this.$route.query.scenario_key || paramsFromStorage?.scenario_key)
      this.shopParameters.budget_key = parseFloat(this.$route.query.budget_key || paramsFromStorage?.budget_key)

      if (!this.$route.query.budget_key && this.shopParameters.budget_key) {
        this.$router.replace({ ...this.$route, query: { ...this.shopParameters } })
      }

      if (!this.shopParameters.scenario_key) {
        this.shopParameters.scenario_key = 1
      }
      this.shopParameters = { ...this.shopParameters }

      Promise
        .all(promises)
        .finally(() => {
          this.loading = false
        })

      this.loadShopItems()
      this.loadShopOrders()
    },
  },
  created () {
    // if page has filters, wait till filters data is loaded
    if (this.filtersStore.currentPageFilters.length) {
      this.loading = true
      this.watchFiltersDataLoaded()
    } else {
      this.loadPage()
    }
  },
}
</script>

<style lang="scss">
.buy-shopping-home-page {
}
</style>
