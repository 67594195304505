import {defineStore} from 'pinia'
import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'

interface AlertData {
  page: 'option' | 'reorder'
  option_key: number
  option_name: string
  inbound_key?: number
  task_key?: number
  task_type?: string
}

interface AlertPayload {
  payload: {
    name: string
    comment: string
    page: string
    page_payload: {
      option_key?: number
      inbound_key?: number
      dummy_key?: number
    }
  }
  time_start?: string
  user_id?: string | number
}


export const useAlertStore = defineStore('alert', {
  state: ()  => ({
    optionAlertData: null as AlertData | null,
  }),
  actions: {
    setOptionAlertData (payload: AlertData) {
      this.optionAlertData = payload
    },
    createAlert (payload: AlertPayload) {
      return axios.post(baseURLs.manageAPI + '/create_alert', payload)
    }
  }
})
