<template>
  <resizable
    class="strategy-anomalies-table p-relative"
    @resize="updateTotalTableWidth"
  >
    <v-row>
      <v-col class="text-right pr-6">
        <TableSearch
          class="mx-2"
          @searchChanged="handleSearchChanged"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <data-tables
          style="width: 100%"
          ref="anomaliesTable"
          :key="tableKey"
          :data="anomaliesList"
          :page-size="10"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 mb-4 mr-2',
          }"
          :table-props="{
            'row-key': 'dimension',
            'expand-icon': 'add',
          }"
          :filters="tableFilters"
        >
          <data-tables
            slot="append"
            :index="null"
            class="total-table"
            :data="totals"
            :pagination-props="{class: 'd-none'}"
            v-if="anomaliesList.length"
          >
            <el-table-column label-class-name="d-none" class-name="total-row_column" width="45" />
            <el-table-column
              class-name="total-row_column"
              label-class-name="d-none"
              width="151"
            >
              <template>
                <div>Total</div>
              </template>
            </el-table-column>
            <el-table-column
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.actual | formatThousands }}</div>
              </template>
            </el-table-column>
            <el-table-column
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.budget | formatThousands }}</div>
              </template>
            </el-table-column>
            <el-table-column
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.delta | formatThousands }}</div>
              </template>
            </el-table-column>
            <el-table-column label-class-name="d-none" class-name="total-row_column" />
            <el-table-column label-class-name="d-none" class-name="total-row_column" />
            <el-table-column label-class-name="d-none" class-name="total-row_column" />
          </data-tables>
          <el-table-column width="45" class="expand-column">
            <template slot-scope="scope">
              <v-icon @click="handleExpandClick(scope.row)" class="my-2">
                <template v-if="!expandedRows.includes(scope.row)">
                  chevron_right
                </template>
                <template v-else>
                  expand_more
                </template>
              </v-icon>
            </template>
          </el-table-column>
          <el-table-column type="expand" width="1">
            <template slot-scope="scope">
              <StrategyAnomaliesTableChildRow
                :dimension="scope.row.dimension.replaceAll(' ', '-')"
                :chart-data="scope.row.graph"
                :weeks="weeks"
              />
            </template>
          </el-table-column>
          <el-table-column prop="dimension" label="Dimension" width="150">
            <template slot-scope="scope">
              {{ scope.row.dimension }}
            </template>
          </el-table-column>
          <el-table-column prop="actual" label="Actual" sortable="custom">
            <template slot-scope="scope">
              {{ applyFormatting(scope.row.actual) }}
            </template>
          </el-table-column>
          <el-table-column prop="budget" label="Budget" sortable="custom">
            <template slot-scope="scope">
              {{ applyFormatting(scope.row.budget) }}
            </template>
          </el-table-column>
          <el-table-column prop="delta" label="Delta" sortable="custom">
            <template slot-scope="scope">
              {{ applyFormatting(scope.row.delta) }}
            </template>
          </el-table-column>
          <el-table-column prop="delta" label="Delta %" sortable="custom">
            <template slot-scope="scope">
              <div
                :style="{
                  'background-color': getCellColor(scope.row.delta_perc),
                  padding: '13px 10px 13px 25px',
                }"
              >
                {{ scope.row.delta_perc | twoDecimals }}%
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="consistency" label="Consistency" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.consistency | twoDecimals }}
            </template>
          </el-table-column>
          <el-table-column prop="trend" label="Trend" sortable="custom">
            <template slot-scope="scope">
              <span :class="getTrendClass(scope.row.trend)">
                {{ scope.row.trend | twoDecimals }}
              </span>
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>
  </resizable>
</template>

<script>
import TableSearch from '@/components/TableSearch.vue'
import StrategyAnomaliesTableChildRow from '@/components/Strategy/StrategyAnomaliesTableChildRow'
import interpolate from 'color-interpolate'
import Resizable from '@/components/Utility/Resizable.vue'

const redToWhite = interpolate(['#EE37664D', '#E85B7F4D', '#E88BA54D', '#FFF4F44D'])
const whiteToGreen = interpolate(['#FFF4F44D', '#aaccc54D', '#7ec1b84D', '#4FB4AB4D'])

export default {
  name: 'StrategyAnomaliesTable',
  components: { Resizable, TableSearch, StrategyAnomaliesTableChildRow },
  props: {
    anomalies: {
      type: Array,
      default: () => [],
    },
    weeks: {
      type: Array,
      default: () => [],
    },
    metricFormat: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      expandedRows: [],
      tableKey: 0,
      tableFilters: [
        {
          value: '',
        },
      ],
    }
  },
  computed: {
    anomaliesList () {
      return this.anomalies.map(a => ({
        ...a,
        delta_perc: ((a.actual - a.budget) / a.budget) * 100
      }))
    },
    totals () {
      const total = {
        actual: 0,
        budget: 0,
        delta: 0,
      }
      this.anomaliesList.forEach(md => {
        Object.keys(total).forEach(key => {
          total[key] += md[key]
        })
      })

      return [total]
    },
  },
  watch: {
    totals: {
      handler () {
        this.updateTotalTableWidth()
      }
    },
  },
  methods: {
    async handleExpandClick (row) {
      const shouldExpand = !this.expandedRows.includes(row)
      this.$refs.anomaliesTable.$refs.elTable.toggleRowExpansion(row, shouldExpand)
      if (shouldExpand) {
        this.expandedRows.push(row)
      } else {
        this.expandedRows = this.expandedRows.filter(r => r !== row)
      }
    },
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = this.$el.querySelector('.el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 100)
    },
    handleSearchChanged (val) {
      this.tableFilters[0].value = val
      this.tableKey++
      this.updateTotalTableWidth()
    },
    applyFormatting (val) {
      return this.$options.filters[this.metricFormat](val)
    },
    getCellColor (value) {
      if (!value && value !== 0) {
        return 'transparent'
      }
      if (value < 100) {
        return redToWhite(value / 100)
      }

      if (value === 100) {
        return 'white'
      }

      if (value > 100 && value < 200) {
        return whiteToGreen((value - 100) / 100)
      }

      if (value > 200) {
        return whiteToGreen(1)
      }
    },
    getTrendClass (trend) {
      switch (trend) {
      case 'improving':
        return 'success--text'
      case 'worsening':
        return 'error--text'
      case 'stable':
        return 'warning--text'
      }
    },
  },
}
</script>

<style lang="scss">
.strategy-anomalies-table {
    .el-table td {
        padding: 0;
    }
    .total-row_column {
        background-color: #f5f7fa;
    }
    .el-table__expand-icon {
        display: none;
    }
    .el-table__expanded-cell[class*=cell] {
        padding: 16px;
        background: #f9f9f9;
        &:hover {
            background: #f9f9f9 !important;
        }
    }
}
</style>
