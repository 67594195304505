<template>
  <div class="consolidate-build p-relative">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <!-- Filters -->
    <v-row class="mt-0 pb-2">
      <v-col>
        <h4 class="px-4 py-1">Filters</h4>
      </v-col>
    </v-row>
    <v-row class="ma-0 px-3">
      <v-col class="filter-wrapper pt-6 pb-0">
        <StoresSelectByProperties
          class="stores-select"
          show-stores-select
          :selected-stores="formData.stores"
          :custom-stores="appConfig?.stores_open"
          @search-results-changed="handleStoresSearchResultsChanged"
          @update:selectedStores="handleSelectedStoresChangedDebounce"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0 pb-2">
      <v-col>
        <h4 class="px-4 py-1">Dimensions</h4>
      </v-col>
    </v-row>
    <v-row class="filter-wrapper ma-0 align-center flex-nowrap">
      <v-col cols="6" class="py-0 pl-6 pr-0">
        <v-autocomplete
          :items="propertiesList"
          v-model="dimension1"
          :filter="headersFilters"
          label="Dimension 1"
        />
      </v-col>
      <v-col cols="6" class="py-0 pr-0 pl-6">
        <v-autocomplete
          :items="propertiesList"
          v-model="dimension2"
          :filter="headersFilters"
          label="Dimension 2"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="dimension2ChartData ? 6 : 12" v-if="dimension1ChartData">
        <DataSetsBarChart
          ref="dimension1Chart"
          style="max-width: 100%"
          title="data_sets_bar_chart"
          index="1"
          :chartData="dimension1ChartData"
          height="100%"
        />
      </v-col>
      <v-col cols="6" v-if="dimension2ChartData">
        <DataSetsBarChart
          ref="dimension2Chart"
          style="max-width: 100%"
          title="data_sets_bar_chart"
          index="2"
          :chartData="dimension2ChartData"
          height="100%"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0 pb-2">
      <v-col>
        <h4 class="px-4 py-1">Needed</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn-toggle
          v-model="neededMode"
          mandatory
          dense
          active-class="bg-gray"
          light
          @change="handleNeededModeChange"
        >
          <v-btn
            value="dimension1"
            small
            color="white"
          >
            <span class="text-capitalize">Dimension 1</span>
          </v-btn>
          <v-btn
            value="dimension2"
            :disabled="dimension2 === null"
            small
            color="white"
          >
            <span class="text-capitalize">Dimension 2</span>
          </v-btn>
          <v-btn
            value="combo"
            :disabled="dimension2 === null"
            small
            color="white"
          >
            <span class="text-capitalize">Combo</span>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row id="neededTableRow">
      <v-col>
        <data-sets-tables
          v-if="neededTableData"
          class="fill-height elevation-0"
          :view="neededTableData"
          @row:click="handleNeededTableRowClick"
        ></data-sets-tables>
      </v-col>
    </v-row>
    <v-row class="mt-0 pb-2">
      <v-col>
        <h4 class="px-4 py-1">Needed matrix</h4>
      </v-col>
    </v-row>
    <v-row class="mt-0 pt-2 ml-0">
      <v-col cols="6" md="3" class="px-4 py-0">
        <collapsible-select
          item-text="name"
          hide-details
          v-if="dimension2 && neededMatrixData"
          label="Columns"
          v-model="selectedMatrixColumns"
          @change="updateColumnsList"
          :items="neededMatrixView.measures"
        ></collapsible-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <data-sets-matrix-tables
          class="fill-height"
          v-if="dimension2 && neededMatrixData"
          :view="neededMatrixData"
        >
        </data-sets-matrix-tables>
        <div v-else class="text-center pb-4">Select Dimension 2</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash'
import Vue from 'vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'
import { loadFilterProperties, loadViewData, performViewData } from '@/api/analytics'
import StoresSelectByProperties from '@/components/StoresSelectByProperties.vue'
import DataSetsBarChart from '@/components/AutomateDatasets/DataSetsBarChart'
import DataSetsTables from '@/components/AutomateDatasets/DataSetsTables'
import DataSetsMatrixTables from '@/components/AutomateDatasets/DataSetsMatrixTables'
import {
  dimensionsChart1,
  dimensionsChart2,
  neededTableView,
  neededMatrixView,
  neededTableOptionsLoad,
} from '@/staticConfigs/consolidateOverviewTab.js'
import { headersFilters } from '@/utils'
import { formatString } from '@/variables'
import { useFiltersStore } from '@/store/pinia/filtersStore'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'

export default Vue.extend({
  setup () {
    return {
      filtersStore: useFiltersStore(),
      stockTransfersStore: useStockTransfersStore(),
    }
  },
  name: 'ConsolidateOverview',
  components: {CollapsibleSelect, StoresSelectByProperties, DataSetsBarChart, DataSetsTables, DataSetsMatrixTables },
  props: {
    selectedStoresInput: {
      type: Array,
      default: () => [],
    }
  },
  data () {
    this.handleSelectedStoresChangedDebounce = debounce(this.handleSelectedStoresChanged, 2000)

    const dim1 = localStorage.getItem('consolidateOverviewDimension1')
    const dim2 = localStorage.getItem('consolidateOverviewDimension2')

    return {
      loading: false,
      neededMatrixView,
      selectedMatrixColumns:JSON.parse(localStorage.getItem('neededMatrixColumns')) || ['Needed', 'Needed%'],
      performanceProps: [],
      dimension1: dim1 || 'properties/article/group',
      dimension2: dim2 || null,
      dimension1ChartData: null,
      dimension2ChartData: null,
      neededDimension1: null,
      neededDimension2: null,
      neededMode: 'dimension1',
      loadNeededMatrixDataDebounce: debounce(this.loadNeededMatrixData, 1000),
      neededTableData: null,
      neededMatrixData: null,
      formData: {
        stores: [],
      },
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    storesMap () {
      return (this.appConfig?.stores || []).reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    propertiesList () {
      return this.getPropertiesList()
    },
  },
  watch:{
    selectedStoresInput: {
      handler (val) {
        this.formData.stores = val.slice()
      },
      immediate: true,
    },
    dimension1: {
      handler () {
        this.loadDimension1ChartsData()
        this.loadNeededTableData()

        if (this.dimension2) {
          this.loadNeededMatrixData()
        }

        localStorage.setItem('consolidateOverviewDimension1', this.dimension1)
      },
      immediate: true,
    },
    dimension2: {
      handler () {
        this.loadDimension2ChartsData()
        this.loadNeededTableData()
        this.loadNeededMatrixData()

        localStorage.setItem('consolidateOverviewDimension2', this.dimension2)
      },
    },
    neededMode: {
      handler () {
        this.loadNeededTableData()
      },
      immediate: true,
    }
  },
  methods: {
    headersFilters,
    formatString,
    updateColumnsList (){
      localStorage.setItem('neededMatrixColumns', JSON.stringify(this.selectedMatrixColumns))
      this.loadNeededMatrixDataDebounce()
    },
    handleStoresSearchResultsChanged (val) {
      this.formData.stores = Array.from(val)
      this.$emit('selectedStores:changed')
    },
    handleSelectedStoresChanged (val) {
      this.loadDimension1ChartsData()
      this.loadNeededTableData()

      if (this.dimension2) {
        this.loadDimension2ChartsData()
        this.loadNeededMatrixData()
      }

      this.$emit('update:selectedStores', val)
    },
    handleNeededModeChange () {

      this.loadNeededTableData()
      this.loadNeededMatrixData()
    },
    getPropertiesList () {
      const array = []
      const headers = {}
      for (const key of this.performanceProps.sort()) {
        const title = key.split('/')
        if (headers[title[1]] === undefined) {
          headers[title[1]] = { header: formatString(title[1] || ''), text: [] }
          array.push(headers[title[1]])
        }
        headers[title[1]].text.push(formatString(title[2] || ''), formatString(title[1] || ''))
        array.push({
          value: key,
          headerTitle: formatString(title[1] || ''),
          text: formatString(title[2] || '')
        })
      }
      if (!array.length) {
        return []
      }
      return array
    },
    async loadDimension1ChartsData () {
      const payload = cloneDeep(dimensionsChart1)
      payload.dimensions = [this.dimension1]
      payload.filters['properties/store/name'] = this.formData.stores.map(storeKey => this.storesMap[storeKey])

      this.loading = true
      const data = await loadViewData(payload, payload)
      this.loading = false

      this.dimension1ChartData = {
        view: {
          ...payload,
          measuresProps: payload.measures.reduce((prev, cur) => {
            prev[cur.name] = cur
            return prev
          }, {})
        },
        data,
      }

      this.$nextTick(() => {
        this.$refs['dimension1Chart'].drawGraph()
      })
    },
    async loadDimension2ChartsData () {
      const payload = cloneDeep(dimensionsChart2)
      payload.dimensions = [this.dimension2]
      payload.filters['properties/store/name'] = this.formData.stores.map(storeKey => this.storesMap[storeKey])
      payload.filters['properties/article/option_key'] = [...this.filtersStore.selectedFiltersOptions.options]

      this.loading = true
      const data = await loadViewData(payload, payload)
      this.loading = false

      this.dimension2ChartData = {
        view: {
          ...payload,
          measuresProps: payload.measures.reduce((prev, cur) => {
            prev[cur.name] = cur
            return prev
          }, {})
        },
        data,
      }

      this.$nextTick(() => {
        this.$refs['dimension2Chart'].drawGraph()
      })
    },
    async loadNeededTableData () {
      const payload = cloneDeep(neededTableView)
      payload.filters['properties/store/name'] = this.formData.stores.map(storeKey => this.storesMap[storeKey])
      payload.filters['properties/article/option_key'] = [...this.filtersStore.selectedFiltersOptions.options]

      if (this.neededMode === 'dimension1') {
        payload.dimensions = [this.dimension1]
      }

      if (this.neededMode === 'dimension2') {
        payload.dimensions = [this.dimension2]
      }

      if (this.neededMode === 'combo') {
        payload.dimensions = [this.dimension1, this.dimension2]
      }

      this.loading = true
      const data = await loadViewData(payload, payload)
      const viewData = await performViewData(payload)
      this.loading = false

      this.neededTableData = {
        view: {
          ...payload,
          measuresProps: payload.measures.reduce((prev, cur) => {
            prev[cur.name] = cur
            return prev
          }, {})
        },
        data,
        total_row: viewData.total_row,
        total_column: viewData.total_column,
        totals_sum: viewData.totals_sum,
      }
    },
    async loadNeededMatrixData () {
      const payload = cloneDeep(neededMatrixView)
      payload.filters['properties/store/name'] = this.formData.stores.map(storeKey => this.storesMap[storeKey])
      payload.filters['properties/article/option_key'] = [...this.filtersStore.selectedFiltersOptions.options]
      payload.dimensions = [this.dimension1]
      payload.properties.matrix = this.dimension2
      payload.measures = payload.measures.filter(item=> this.selectedMatrixColumns.includes(item.name))
      this.loading = true
      this.neededMatrixData = await performViewData(payload)
      this.loading = false

    },
    async handleNeededTableRowClick (row) {
      const payload = cloneDeep(neededTableOptionsLoad)
      if (this.neededMode === 'combo' && this.dimension2) {
        payload.filters[this.dimension2] = [row[this.dimension2]]
        payload.filters[this.dimension1] = [row[this.dimension1]]
      }else if (this.neededMode === 'dimension1') {
        payload.filters[this.dimension1] = [row[this.dimension1]]
      }else if (this.neededMode === 'dimension2') {
        payload.filters[this.dimension2] = [row[this.dimension2]]
      }
      payload.filters['properties/article/option_key'] = [...this.filtersStore.selectedFiltersOptions.options]

      this.loading = true
      const data = await loadViewData(payload, payload)
      const options = data.map(x => x['properties/article/option_key'])
      this.loading = false

      this.$emit('neededTable:rowClick', options)
    },
  },
  async created () {
    const props = await loadFilterProperties('stock_performance')
    this.performanceProps = Object.keys(props)
  }
})
</script>

<style lang="scss">
.consolidate-overview {
  .filter-wrapper {
    max-width: 400px;
  }
}
</style>
