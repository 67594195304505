<template>
  <div id="strategy-otb-intake" class="mx-n6">
    <h6 class="text-subtitle-1 font-weight-medium mx-6">OTB & Intake</h6>
    <v-carousel
      height="auto"
      class="pb-15"
      delimiter-icon="circle"
      :perPage="1"
      light
      hide-delimiter-background
      :show-arrows="false"
    >
      <v-carousel-item eager>
        <div
          class="chart-container mt-4 mx-4"
          style="position: relative; height: 350px;"
        >
          <canvas id="otbChart"></canvas>
        </div>
      </v-carousel-item>
      <v-carousel-item eager>
        <div
          class="chart-container mt-4 mx-4"
          style="position: relative; height: 350px;"
        >
          <canvas id="intakeChart"></canvas>
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { Chart } from 'chart.js/auto'
import { formatLargeNumber } from '@/utils'

export default {
  name: 'StrategyOtbIntake',
  props: {
    chartsData: {
      type: Object || null,
      default: null,
    },
    chartsOptions: {
      type: Object || null,
      default: null,
    },
  },
  data () {
    this.chartOptions = {
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      hover: {
        mode: 'index',
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          grid: {
            display: false
          },
          ticks: {
            font: {
              size: 14,
            }
          },
          offset: true,
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
          }
        },
        tooltip: {
          callbacks: {
            label: context => {
              return this.$options.filters.formatThousands(context.raw)
            },
          }
        },
      },
    }
    this.otbChart = null
    this.intakeChart = null

    return {
    }
  },
  methods: {
    drawCharts () {
      ['otbChart', 'intakeChart'].forEach(chart => {
        if (this[chart]) {
          this[chart].destroy()
        }
      })

      // OTB chart
      let ctx = document.getElementById('otbChart')
      let options = cloneDeep(this.chartOptions)

      options.scales.y1 = {
        position: 'left',
        title: {
          display: true,
          text: 'Intake',
          font: {
            size: 14,
          },
        },
        grid: {
          drawTicks: false,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          callback: (value)=> formatLargeNumber(value),
          padding: 16,
          font: {
            size: 14,
          }
        },
      }

      this.otbChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.chartsData.otb.weekNumbers,
          datasets: this.chartsOptions.otb.measures.map(o => {
            return {
              label: o.name,
              data: this.chartsData.otb[o.name],
              yAxisID: o.axis,
              borderColor: o.color_code,
              borderWidth: 2,
              backgroundColor: '#FFFFFF',
              pointRadius: null,
              borderDash: o.type === 'dotted' ? [5, 5] : [],
              tension: 0.4,
            }
          })
        },
        options,
      })

      // Intake chart
      ctx = document.getElementById('intakeChart')
      options = cloneDeep(this.chartOptions)

      options.scales.y1 = {
        position: 'left',
        title: {
          display: true,
          text: 'Euros',
          font: {
            size: 14,
          },
        },
        grid: {
          drawTicks: false,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          callback: (value)=> formatLargeNumber(value),
          padding: 16,
          font: {
            size: 14,
          }
        },
      }
      options.scales.y2 = {
        position: 'right',
        title: {
          display: true,
          text: 'Pieces',
          font: {
            size: 14,
          }
        },
        grid: {
          display: false,
          drawTicks: false,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          callback: (value)=> formatLargeNumber(value),
          padding: 16,
          font: {
            size: 14,
          }
        },
      }
      options.plugins.legend.labels.generateLabels = chart => {
        const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)

        for (const index in labels) {
          if (this.chartsOptions.intake.measures[index].type === 'dotted') {
            labels[index].lineDash = [5, 5]
          }
        }

        return labels
      }

      this.intakeChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.chartsData.intake.weekNumbers,
          datasets: this.chartsOptions.intake.measures.map(o => {
            return {
              label: o.name,
              data: this.chartsData.intake[o.name],
              yAxisID: o.axis,
              borderColor: o.color_code,
              borderWidth: 2,
              backgroundColor: '#FFFFFF',
              pointRadius: null,
              borderDash: o.type === 'dotted' ? [5, 5] : [],
              tension: 0.4,
            }
          })
        },
        options,
      })
    },
    updateCharts () {
      this.drawCharts()
    },
  },
}
</script>

<style lang="scss">
#strategy-otb-intake {
}
</style>
