<template>
  <resizable
    class="stock-picktickets-page p-relative mb-12"
    id="stock-picktickets-page"
  >
    <div class="my-6">
      <h3 class="card-title text-left mb-0">Picktickets</h3>
    </div>
    <v-card min-height="50vh">
      <v-tabs v-model="activeTab">
        <v-tabs-slider />
        <v-tab class="px-8" style="width: 260px" key="Allocation">Allocation</v-tab>
        <v-tab class="px-8" style="width: 260px" key="Replenishment">Replenishment</v-tab>
        <v-tab class="px-8" style="width: 260px" key="Custom">Custom</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab" class="flex xs12">
        <v-tab-item
          :transition="false"
          :reverse-transition="false"
          eager
          key="Allocation"
          class="flex py-4 px-0"
        >
          <StockAllocation></StockAllocation>
        </v-tab-item>

        <v-tab-item
          :transition="false"
          :reverse-transition="false"
          eager
          key="Replenishment"
          class="flex py-4 px-0"
        >
          <StockReplenishment
          ></StockReplenishment>
        </v-tab-item>
        <v-tab-item
          :transition="false"
          :reverse-transition="false"
          key="Custom"
          class="flex py-4 px-0"
        >
          <div class="px-6">Custom</div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </resizable>
</template>

<script>
import Resizable from '@/components/Utility/Resizable.vue'
import StockReplenishment from '@/views/StockReplenishment.vue'
import StockAllocation from '@/views/StockAllocation.vue'
export default {
  name: 'StockPickticketsPage',
  components: {
    StockAllocation,
    StockReplenishment, Resizable},
  data () {
    return {
      activeTab: 1,
    }
  },

}
</script>

<style lang="scss">
.stock-picktickets-page {
  .settings-menu {
    .input-parameter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      padding: 0 16px;
      .parameter-name {
        width: 200px;
      }
      .parameter-value {
        width: 250px;
        padding: 8px 0;
      }
      .slider {
        padding-top: 4px;
        .slider-value-from,
        .slider-value-to {
          color: #666;
          font-size: 14px;
        }
      }
    }
  }
  .store-name-cell {
    white-space: nowrap;
    overflow: hidden;
  }
  .el-table__expand-icon {
    display: none;
  }
  .el-table__expanded-cell[class*=cell] {
    padding: 16px 0;
    background: #f9f9f9;
    &:hover {
      background: #f9f9f9 !important;
    }
  }
  .el-table__append-wrapper {
    position: sticky;
    bottom: 0;
  }
  .table .el-table__body-wrapper {
    max-height: 50vh;
    overflow-y: auto;
  }
  .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
    width: 4px;
  }
  .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }
  .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
  .total-row_column {
    background-color: #f5f7fa;
  }
}
</style>
