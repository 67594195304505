<script lang="ts">
import {defineComponent} from 'vue'
import {mapState} from 'pinia'
import {useGeneralStore} from '@/store/pinia/generalStore'
import {useStockProposalStore} from '@/store/pinia/stockProposalStore'
import columnFilters from '@/mixins/columnFilters'
import interpolate from 'color-interpolate'
import {round} from 'lodash'
import TableSearch from '@/components/TableSearch.vue'
import DataExport from '@/components/DataExport.vue'
import {downloadData} from '@/utils'

const redToWhite = interpolate(['#EE37664D'])
const whiteToGreen = interpolate(['#4FB4AB4D'])

export default defineComponent({
  name: 'GoalTable',
  components: {DataExport, TableSearch},
  data (){
    return {
      proposalStore: useStockProposalStore(),
      filters: [],
    }
  },
  mixins: [columnFilters],
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    goalData () {
      const stores = this.appConfig?.stores || []
      const skuNames = this.proposalStore?.sku_names || []
      return this.proposalStore.storeKeys.map((store_key, index) => {

        const store = stores.find(x => x.store_key === store_key)
        const children = this.proposalStore.proposal[index]?.children?.map((item)=> ({...item, isChild: true, store_name: item.Store, store_key: store_key}))
        const skuData = {}
        let goal = 0
        let change = 0
        skuNames.forEach((name, sku_index) =>{
          const matrices = this.proposalStore.proposalMatrices
          skuData[`${name}_extra`] = matrices?.stock_extra[index][sku_index] || 0
          skuData[name] = matrices?.stock[index][sku_index] + skuData[`${name}_extra`]
          goal += skuData[name]
          change += skuData[`${name}_extra`]
        })
        return {
          ...skuData,
          goal,
          change,
          children,
          storeKey: store_key,
          store_name: store ? store.store_name : store_key,
        }
      })
    }
  },
  methods: {
    handleDownloadClick (sep: string) {
      downloadData(sep, this.goalData, this.$route.query.option_key + ' Goal')
    },
    getSummary (params) {
      const sums = []
      const { columns } = params

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total'
          return
        }
        sums[index] = round(this.goalData.reduce((acc, cur) => acc + cur[column.property], 0), 2)
      })

      return sums
    },
    getColorCoding (value: number, isChild:boolean){

      if (value === 0 || isChild) {
        return 'transparent'
      }
      if (value < -5) {
        return redToWhite(0)
      }
      if (value <= 0) {
        return redToWhite(value / -5)
      }
      if (value <= 5) {
        return whiteToGreen(value / 5)
      }
      if (value > 5) {
        return whiteToGreen(1)
      }
    }
  }
})
</script>

<template>
  <v-col class="pa-0">
    <v-row class="my-0">
      <v-col class="text-right pr-8">
        <TableSearch class="mx-2"
          @searchChanged="val => handleSearchChanged('filters', [...proposalStore?.sku_names, 'goal', 'change', 'store_name'], val)" />
        <DataExport
          @item-click="handleDownloadClick"
        >
        </DataExport>
      </v-col>
    </v-row>
    <data-tables
      v-if="goalData && goalData.length"
      :data="goalData"
      class="goal-table"
      @filter-change="handleFilterChange('filters', f)"
      :filters="filters"
      :page-size="15"
      filter-multiple
      :table-props="{

        'tree-props': {
          children: 'children',
        },
        'summary-method': getSummary,
        'show-summary': true,
        'row-key' : (row)=> row['store_name'] + row['store_key'],
        'cell-class-name': 'colored-td',
      }"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 pb-4 mr-2'
      }">

      <el-table-column
        column-key="store_name"
        prop="store_name"
        label="Store"
        key="store_name"
        align="left"
        width="200px"
        sortable="custom"
        label-class-name="pa-0"
        class-name="px-6 p-relative store-name-td"
      >
        <template v-slot="scope">
          <div class="store-name-cell" >
            {{scope.row.store_name}}
          </div>
          <v-icon
            v-if="!scope.row.isChild"
            :size="21"
            class="store-info-icon ml-2"
            @click="$emit('store-clicked', scope.row)"
          >info</v-icon>
        </template>
      </el-table-column>
      <el-table-column
        :column-key="col"
        label-class-name="text-center"
        v-for="col of proposalStore?.sku_names" :prop="col" :label="col" :key="col"
        sortable="custom">
        <template v-slot="scope">
          <div
            class="d-flex align-center justify-center colored-area"
            :style="{
              'background-color': getColorCoding(scope.row[`${col}_extra`], scope.row.isChild),
              padding: '12px',
              'font-size': '14px',
            }">
            {{scope.row[col]}}

            <span class="ml-1" v-if="scope.row[`${col}_extra`]"> (<span>{{ scope.row[`${col}_extra`] > 0 ? '+' : '' }}</span>{{scope.row[`${col}_extra`]}})</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        column-key="goal"
        sortable="custom"
        prop="goal"
        label="Goal"
        class-name="text-center"
        key="goal"
      >
        <template v-slot="scope">
          {{scope.row.goal}}
        </template>
      </el-table-column>
      <el-table-column
        column-key="change"
        sortable="custom"
        prop="change"
        label="Change"
        class-name="text-center"
        key="change"
      >
        <template v-slot="scope">
          {{scope.row.change}}
        </template>
      </el-table-column>
    </data-tables>
  </v-col>
</template>

<style lang="scss">

.goal-table {
  .store-info-icon {
    position: absolute;
    top: 12px;
    right: 24px;
    cursor: pointer;
  }
  .store-name-cell {
    max-width: 110px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .store-name-td {
    .cell {
      display: inline-flex;
    }
  }
  .el-table__row--level-1 {
    background-color: #fafafa !important;
  }
  .el-table td {
    padding: 0;
    height: 45px;
    border-bottom: none;
    .cell {
      padding: 0;
    }
  }
}
</style>
