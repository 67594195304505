
<template>
  <v-row>
    <v-col>
      <div class="chart-container">
        <div class="container">
          <div class="container-body">
            <canvas id="chart"></canvas>
          </div>
        </div>

      </div>
    </v-col>
  </v-row>
</template>

<script>
import ChartClass from '../../chart/ChartClass.js'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

export default {
  setup () {
    return {
      proposalStore: useStockProposalStore()}
  },
  data () {
    this.chart = {}
    // It is used to access chart data download functionality @downloadChartData
    this.chatData = {}
    return {
      legendItems: []
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
  },
  methods: {
    drawGraph () {
      const ctx = document.getElementById('chart').getContext('2d')

      const sortList = []
      const deltas_named = this.proposalStore.deltas_named
      if (!deltas_named) {
        return
      }
      for (let i = 0; i < deltas_named[0]?.deltas?.length || 0; i++) {
        let sum = 0
        for (const item of deltas_named) {
          sum += item.deltas[i]
        }
        sortList.push(sum)
      }
      // Determine the sorting order using the fairshare list and apply this sorting on the deltas and storeList
      const indices = [...sortList.keys()]
      indices.sort((a, b) => sortList[b] - sortList[a])
      const storeList = [...indices.map(i => this.proposalStore.fullStoreList[i])]
      const storeNameList = []
      const storeKeyList = []
      for (let i = 0; i < storeList.length; i++) {
        const store = (this.appConfig?.stores || []).find(x => x.store_key === storeList[i])
        if (store !== undefined) {
          storeNameList.push(store.store_name.substring(0, 14))
          storeKeyList.push(store.store_key)
        }
      }
      // Fill the returnData with a dataset for every business rule
      const returnData = deltas_named.map((item) => ({
        // ...item,
        label: item.name,
        data: [...indices.map(i => item?.deltas[i])],
        yAxisID: 'A',
        type: 'bar',
        stack: 'Stack 0',
        borderColor: item?.color,
        backgroundColor: item?.color,
        options: {
          maintainAspectRatio: false,
        }
      }))
      const total = []
      for (const store in returnData[0]?.data) {
        total[store] = 0
        for (const item of returnData) {
          total[store] += item.data[store]
        }
      }
      const totalFiltered = []
      for (const idx in total) {
        if (total[idx] > 0) {
          totalFiltered.push(total[idx])
        }
      }
      let max = 10
      if (totalFiltered.length > 0) {
        max = Math.ceil((2 * (totalFiltered.reduce((a, b) => a + b) / totalFiltered.length)) / 10) * 10
      }

      // Get all chart information
      const labels = storeNameList
      const data = returnData
      const scales = {
        'yAxes': [{
          stacked: true,
          title: {
            text: 'Amount',
          },
          id: 'A',
          position: 'left',
          max: max
        }],
        'xAxes': {
          stacked: true,
          title: {
            text: '',
          },
        },
      }
      const type = 'bar'

      // Check if chart has already been drawn, if so delete
      // eslint-disable-next-line
      if (Object.keys(this.chart).length > 0) {
        this.chart.delete()
      }
      // Check labels length and set width of the chart
      const minBarWidth = 8
      const barWidth = (window.innerWidth * 0.9) / labels.length
      if (barWidth < minBarWidth) {
        document.querySelector('.container-body').style.width = `${labels.length * minBarWidth}px`
      }
      this.chart = new ChartClass(ctx, labels, data, scales, type,  false, '#E35500', 0, false, { })
      this.chartData = {
        store_names: storeNameList,
        store_keys: storeKeyList,
        [returnData[0]?.label]: returnData[0]?.data,
        [returnData[1]?.label]: returnData[1]?.data,
        [returnData[2]?.label]: returnData[2]?.data,
      }
      this.legendItems = [
        { label: returnData[0]?.label, color: returnData[0]?.backgroundColor },
        { label: returnData[1]?.label, color: returnData[1]?.backgroundColor },
        { label: returnData[2]?.label, color: returnData[2]?.backgroundColor },
      ]
    }
  },
  mounted () {
    this.drawGraph()
  }
}
</script>

<style lang="scss">
.chart-container {
  .container {
    overflow-x: auto;
    max-width: 100%;
    .container-body {
      height: 350px;
    }
  }
  .chart-legend-label {
    font-size: 13px;
    color: #606266;
  }
}
</style>
