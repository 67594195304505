<template>
  <v-card class="mt-6 " v-if="failedLoad">
    <v-card-title>
      No option found for this key
    </v-card-title>
  </v-card>
  <div class="stockOptionPage" v-else-if="loaded">
    <!-- Header with back button -->
    <div v-if="prevRouteName" class="mb-6 mt-6 title-div">
      <h3>
        <span class="mr-1 text-capitalize route-name" @click="$router.push({ path: prevRoute.path })">
          {{ prevRouteName }}
        </span>
        /
        <span class="ml-1">Option page</span>
      </h3>
    </div>

    <!-- Header without back button -->
    <div class="title-div" v-else>
      <h3 class="mb-6 mt-6">Option page</h3>
    </div>

    <div class="mt-n4 article-info">
      <OptionInfo
        @changed="applyProposalRules"
        @orderChanged="handleOrderChanged"
        @clusterClick="handleClusterClick"
      />
    </div>

    <div class="mt-n4 carousel">
      <v-card class="carousel-card" min-height="300px" :class="{'px-0': (chartIndex === 3 || chartIndex === 4)}">
        <v-row v-if="chartIndex !== 5 && chartIndex !== 4" class="justify-space-between px-4 pt-3 pb-6 align-center">
          <div></div>
          <h4>{{chartNames[chartIndex]}}</h4>
          <DataExport
            v-if="chartIndex < 3"
            @item-click="(sep)=> downloadChartData(sep, $refs[chartRefs[chartIndex]], `${chartNames[chartIndex]}_${$route.query.option_key}`)"
          />
          <div style="width: 40px; height: 25px" v-else></div>
        </v-row>
        <v-overlay absolute color="white" :value="!allChartsLoaded">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <v-carousel
          height="auto"
          class="pb-14"
          delimiter-icon="circle"
          :perPage="1"
          light
          hide-delimiter-background
          :hide-delimiters="!allChartsLoaded"
          :show-arrows="false"
          @change="(value)=> {chartIndex = value}"
        >
          <v-carousel-item :eager="true" v-if="!predictionIsEmpty">
            <ChartElement
              v-if="Object.keys(predictionParams).length && proposalData"
              ref="predictionChart"
              title="prediction"
              forced-height="370px"
              :chart-data="proposalData"
              :key="predictionKey"
              endpoint="proposal"
              :params="predictionParams"
              @loaded="isEmpty => {predictionIsEmpty = isEmpty; chartLoaded['chart1'] = true}"
            />
          </v-carousel-item>
          <v-carousel-item>
            <strategy-period-option-story-chart
              ref="storyChart"
              :story="optionStory"
              height="370px"
            />
          </v-carousel-item>
          <v-carousel-item :eager="true" v-if="!topFlopIsEmpty">
            <TopFlopChart
              ref="topflop"
              height="370px"
              :top-stores="topStores"
            />
          </v-carousel-item>
          <v-carousel-item :eager="true">
            <div style="min-height: 370px">
              <excluded-stores
                :top-stores="topStores"
                @add-click="addClickStoresExcluded"
              />
            </div>
          </v-carousel-item>
          <v-carousel-item :eager="true">
            <div style="min-height: 370px">
              <included-stores
                :top-stores="topStores"
                @add-click="addClickStoresIncluded"
              />
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div style="height: 407px">
              <StockGaugeChart
                ref="stockGauge"
              />
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </div>

    <!-- Stock proposal -->
    <div class="proposal mt-2">
      <StockProposal
        ref="proposal"
        :baseOptionKey="baseOptionKey"
        @loaded-failed="failedLoad = true"
        @loaded="loadOptionStory"
        @capacityUpdated="handleProposalUpdated"
      >
        <GoalTable slot="goal" @store-clicked="handleStoreClicked" ></GoalTable>
        <DistributionTable
          @store-clicked="handleStoreClicked"
          slot="distribution"></DistributionTable>

        <option-transfers-table
          slot="transfers"
          v-if="transfers.data && optionInfoLoaded"
          :skuNames="transfers.total.sku_names"
          :data="transfers.data"
          :option_name="article.option_name"
          :option_key="article.option_key"
        />
      </StockProposal>
    </div>
  </div>
</template>

<script>
import OptionInfo from '@/components/Options/OptionInfo.vue'
import StockProposal from '@/components/Proposal/StockProposal.vue'
import TopFlopChart from '../components/Options/TopFlopChart.vue'
import axios from '@/services/axios.js'
import { downloadChartData } from '@/variables'
import DataExport from '../components/DataExport'
import OptionTransfersTable from '@/components/Proposal/OptionTransfersTable'
import ChartElement from '@/chart/ChartElement'
import StrategyPeriodOptionStoryChart from '@/components/Strategy/StrategyPeriodOptionStoryChart.vue'
import { getOptionStory } from '@/api/strategy'
import StockGaugeChart from '@/views/StockGaugeChart'
import baseURLs from '@/api/APIBaseURLs'
import ExcludedStores from '@/views/ExcludedStores'
import IncludedStores from '@/views/IncludedStores'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

import { useArticleStore } from '@/store/pinia/stockArticlesStore'
import { getOptionTransfersFree } from '@/api/stockTransfersAPI'
import * as optionsApi from '@/api/options'
import GoalTable from '@/components/Proposal/GoalTable.vue'
import DistributionTable from '@/components/Proposal/DistributionTable.vue'

export default {
  setup () {
    return {
      articlesStore: useArticleStore(),
      proposalStore: useStockProposalStore()}
  },
  name: 'StockOptionPage',
  components: {
    DistributionTable,
    GoalTable,
    ExcludedStores,
    IncludedStores,
    StockGaugeChart,
    StrategyPeriodOptionStoryChart,
    ChartElement,
    OptionTransfersTable,
    DataExport,
    OptionInfo,
    StockProposal,
    TopFlopChart,
  },
  data () {
    return {
      transfers: {total: {}},
      optionInfoLoaded: true,
      failedLoad: false,
      chartNames: ['Prediction', 'History', 'Store performance', 'Excluded stores', 'Included stores'],
      chartRefs: ['predictionChart', 'storyChart', 'topflop'],
      url: import.meta.env.VITE_URL,
      prevRoute: null,
      predictionIsEmpty: false,
      optionStory: null,
      chartLoaded: {
        chart1: false,
        chart2: true,
      },
      popularityEmpty: false,
      predictionKey: 0,
      popularityIsEmpty: false,
      chartIndex: 0,
      topFlopIsEmpty: false,
      loaded: false,
    }
  },
  computed: {
    topStores () {
      return this.proposalStore.top_stores
    },
    article () {
      return this.articlesStore.article || {}
    },
    articleSettings () {
      return this.articlesStore.articleSettings
    },
    baseOptionKey () {
      return this.articleSettings?.replacement_option_keys?.[0] || null
    },
    proposalData () {
      return this.proposalStore.projection
    },
    prevRouteName () {
      if (this.prevRoute !== null && this.prevRoute.name) {
        const prevRouteParts = this.prevRoute.name.split('-')

        return prevRouteParts.slice(1).join(' ')
      } else {
        return null
      }
    },
    predictionParams () {
      const proposalParams = this.proposalStore.proposalParams
      return { ...proposalParams, order_parameters: { ...proposalParams.order_parameters, leadtime: 0 } }
    },
    allChartsLoaded () {
      return this.chartLoaded.chart1 && this.chartLoaded.chart2
    },
  },
  methods: {
    handleStoreClicked (props){
      this.$refs.proposal.handleStoreClicked(props)
    },
    loadTransfers (){
      const payload = {'child_filters':{'option_key':[this.$route.query.option_key]}, 'view':'matrix', 'min_profit':null, 'send_min':null, 'send_max':null, 'pieces_max':null, 'records':false, 'filters':{}}
      getOptionTransfersFree(payload).then((data)=> {
        const transfersData = data.data
        transfersData.forEach((item)=> {
          item.sku_names.forEach((sku_name, index)=> {
            item[`${sku_name}_value`] = item.goals[index]
            item[sku_name] = {delta: item.deltas[index], goal: item.goals[index]}
          })

          data.total.sku_names.forEach(sku=> {
            item[`${sku}_value`] = item[`${sku}_value`] || 0
          })
        })
        this.transfers = data
        // this.loadOptionStory()
      }).catch(console.error)
    },
    addClickStoresExcluded (rows){
      for(const row of rows) {
        let stores = this.articlesStore.articleSettings.stores_filtered
        stores = stores.filter(item => item !== row.Store)
        this.articlesStore.articleSettings.stores_filtered = [...stores]
        if (!row['In clusters']) {
          const stores = new Set(this.articlesStore.articleSettings.stores_included)
          stores.add(row.Store)
          this.articlesStore.articleSettings.stores_included = [...stores]
        }
      }
      this.saveOptionSettings()
    },
    addClickStoresIncluded (rows){
      for(const row of rows) {
        const stores =  new Set(this.articlesStore.articleSettings.stores_filtered)
        stores.add(row.Store)
        this.articlesStore.articleSettings.stores_filtered = [...stores]
      }
      this.saveOptionSettings()


    },

    saveOptionSettings () {
      optionsApi
        .saveOptionSettings(this.articleSettings, this.$route.query)
        .then(data => {
          this.applyProposalRules(data.option_setting)
        })
    },
    downloadChartData,
    async applyProposalRules (data) {
      this.optionInfoLoaded = false
      await this.articlesStore.setArticleSettings( data)
      this.proposalStore.setProposal({})
      this.$refs.proposal.loadPage()
      await this.loadOptionData()
      this.logOptionView()
      this.optionInfoLoaded = true
    },
    handleOrderChanged () {
      this.articlesStore.loadArticleSettings(this.$route.query)
        .then(this.redrawCharts).catch(()=> {
          this.failedLoad = true
        })
    },
    handleProposalUpdated () {
      this.articlesStore.loadArticleSettings( this.$route.query)
        .then(this.redrawCharts).catch(()=> {
          this.failedLoad = true
        })
    },
    handleClusterClick (storeSetName) {
      this.$refs.proposal.handleClusterStoreSetClick(storeSetName)
    },
    redrawCharts () {
      if (!this.predictionIsEmpty && this.$refs.predictionChart?.redrawGraph) {
        this.$refs.predictionChart.redrawGraph(this.$route.query.option_key)
      }

      if (!this.popularityIsEmpty && this.$refs.popularityChart?.redrawGraph) {
        this.$refs.popularityChart?.redrawGraph(this.$route.query.option_key)
      }

      if (!this.topFlopIsEmpty && this.$refs.topflop?.plotGraph) {
        this.$refs.topflop.plotGraph(this.$route.query.option_key)
      }
      if (!!this.optionStory && this.$refs.storyChart) {
        this.$refs.storyChart.drawOptionChart()
      }
    },
    logOptionView () {
      axios
        .post(
          baseURLs.manageAPI + '/option_view',
          this.$route.query)
        .catch((error) => {
          console.log(error)
        })
    },
    async loadOptionStory () {
      const optionKey = this.$route.query.option_key
      try {

        this.optionStory = await getOptionStory({
          option_keys: this.articleSettings.replacement_option_keys || [optionKey]
        })
      } catch (e) {
        console.error(e)
      }
    },
    async loadOptionData () {
      await this.loadArticleSettings(this.$route.query.option_key)
      if (this.baseOptionKey && this.baseOptionKey !== this.$route.query.option_key) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            option_key: this.baseOptionKey
          }
        })
      }

    },
    async loadArticleSettings (option_key){
      try {
        await this.articlesStore.loadArticleSettings({option_key})
      }catch (e) {
        this.failedLoad = true
      }
    }
  },
  watch: {
    predictionParams () {
      this.predictionKey++
    },
    proposalData () {
      this.predictionKey++
    },
    async '$route.query.option_key' () {
      await this.loadOptionData()
      this.loadTransfers()
      this.logOptionView()
      this.redrawCharts()
    },
  },
  async created () {
    this.proposalStore.setProposal({})
    await this.loadOptionData()
    await this.logOptionView()
    this.loaded = true
    this.loadTransfers()
  }
}
</script>

<style lang="scss">
.stockOptionPage {
    display: grid;
    grid-template: auto / 1fr 1fr 1fr;
    grid-gap: 16px;
    .title-div {
        display: flex;
        align-items: center;
        grid-row: 1/2;
        grid-column: 1/4;
        .route-name {
            cursor: pointer;
            color: var(--text-sub-black);
        }
    }
    .article-info {
        grid-column: 1/2;
        grid-row: 2/3;
    }
    .carousel {
        grid-column: 2/4;
        grid-row: 2/3;
        .VueCarousel-pagination {
            .VueCarousel-dot-container {
                margin: 0 !important;
                .VueCarousel-dot {
                    margin-top: 10px !important;
                }
            }
        }
        .carousel-card {
            padding: 16px;
            box-sizing: border-box;
            height: 100% !important;
            .chart-title {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 26px;
            }
        }
    }
    .proposal {
        grid-column: 1/4;
        grid-row: 3/4;
    }
}
</style>
