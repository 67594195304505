<template>
  <el-card class="orders-table">
    <div class="row text-right justify-content-end">
      <div class="col pt-4 px-7">
        <TableSearch @searchChanged="handleSearchChanged" />
        <DataExport
          @item-click="downloadData"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <data-tables
          id="orders_table"
          :key="tasksListKey"
          :data="tableData"
          :filters="filters"
          :page-size="10"
          :table-props="{
            'summary-method': this.getSummary,
            'show-summary': true,}"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 pb-4 mr-2'
          }">
          <el-table-column label="Date" prop="Date" class-name="px-2">
            <template slot-scope="scope">
              <div>{{scope.row['Date']}}</div>
            </template>
          </el-table-column>

          <el-table-column label="Type" prop="Type">
            <template slot-scope="scope">
              <p  class="item mb-0" :class="{'ordered': scope.row['Type'] === 'Ordered'}">{{scope.row['Type']}}</p>
            </template>
          </el-table-column>

          <el-table-column v-for="prop of tableKeys" :prop="prop" :label="prop" :key="prop">
            <template slot-scope="scope">
              <div>{{scope.row[prop]}}</div>
            </template>
          </el-table-column>
        </data-tables>
      </div>
    </div>
  </el-card>
</template>

<script>
import TableSearch from '../TableSearch'
import DataExport from '../DataExport'
import { downloadData } from '@/utils'
import { round } from 'lodash'

export default {
  components: { DataExport, TableSearch },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    tableKeys: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      filters: [
        {
          value: '',
        },
      ],
      tasksListKey: 0,
      items: [
        { title: 'CSV (comma)', value: ',' },
        { title: 'CSV (semicolon)', value: ';' },
        { title: 'CSV (semicolon, decimal comma)', value: 'dutch' }
      ]
    }
  },

  methods: {
    getSummary (params) {
      const sums = []
      const { columns } = params

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total'
          return
        }
        //  stores and SKUs column don't have summary
        if (index === 1) {
          return
        }
        sums[index] = round(this.tableData.reduce((acc, cur) => acc + cur[column.property], 0), 2)
        sums[index] = round(this.tableData.reduce((acc, cur) => acc + cur[column.property], 0), 2)
      })

      return sums
    },
    handleSearchChanged (val) {
      this.filters[0].value = val
      this.tasksListKey = 0
    },
    downloadData (sep) {
      const data = this.tableData.map(
        row => ['Date', 'Type'].concat(this.tableKeys).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: row[cur] }),
          {}
        )
      )

      downloadData(sep, data, this.$route.query.option_key)
    },
  },

}
</script>

<style lang="scss">
#orders_table .cell {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.orders-table {
  .item {
      color: var(--warning);
  }

  .el-card__body {
    padding: 0;
  }
  .ordered {
      color: var(--success)
  }
}
</style>
