<template>
  <div class="shop-item-beliefs">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-row>
      <v-col cols="auto" class="mx-4">
        <v-autocomplete
          :menu-props="{
            left: true,
          }"
          hide-details
          multiple
          label="Predictions"
          v-model="item.prediction_mode"
          @change="saveShopItemDebounce(beliefsList)"
          :items="predictionItems"
        >
        </v-autocomplete>
      </v-col>
      <v-col class="text-right align-center d-flex px-8 justify-end">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('tableFilters', beliefFields, val)"
        />
        <v-icon
          class="mx-2 rotate270 action-btn-primary"
          :disabled="moveUpDisabled"
          @click="handleMoveUpClick"
        >last_page</v-icon>
        <v-icon
          class="mx-2 rotate90 action-btn-primary"
          :disabled="moveDownDisabled"
          @click="handleMoveDownClick"
        >last_page</v-icon>
        <v-icon class="mx-2 action-btn-primary" :disabled="selectedRows.length !== 1" @click="handleEditBeliefClick">edit</v-icon>
        <v-icon class="mx-2 action-btn-primary" @click="handleAddBeliefClick">add</v-icon>
        <v-icon class="mx-2 action-btn-danger" :disabled="!selectedRows.length" @click="handleDeleteBeliefClick">delete</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="p-relative">
        <data-tables
          style="width: 100%"
          class="mb-4"
          :data="beliefsList"
          :page-size="beliefsList.length"
          :pagination-props="{ pageSizes: [beliefsList.length], hideOnSinglePage: true }"
          :table-props="{ 'row-key': 'name' }"
          :filters="tableFilters"
          filter-multiple
          @filter-change="filters => handleFilterChange('tableFilters', filters)"
          @row-click="handleRowClick">
          <el-table-column prop="timeline_key" width="55">
            <template v-slot="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-4 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column prop="store_keys" label="Stores" sortable="custom">
            <template v-slot="scope">
              <ChipsDropdown :items="scope.row.store_keys.map(s => storesNames[s])" />
            </template>
          </el-table-column>
          <el-table-column prop="week_min" label="From" sortable="custom">
            <template v-slot="scope">
              <template v-if="scope.row.week_min">
                {{ scope.row.week_min | formatWeekNumber }}
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="week_max" label="To" sortable="custom">
            <template v-slot="scope">
              <template v-if="scope.row.week_max">
                {{ scope.row.week_max | formatWeekNumber }}
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="sku_names" label="SKUs" sortable="custom">
            <template v-slot="scope">
              <ChipsDropdown :items="scope.row.sku_names" />
            </template>
          </el-table-column>
          <el-table-column prop="factor" label="Factor" sortable="custom">
            <template v-slot="scope">
              {{ scope.row.factor }}
            </template>
          </el-table-column>
          <el-table-column prop="absolute" label="Absolute" sortable="custom">
            <template v-slot="scope">
              {{ scope.row.absolute }}
            </template>
          </el-table-column>
          <el-table-column prop="comment" label="Note" sortable="custom">
            <template v-slot="scope">
              {{ scope.row.comment }}
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>

    <!-- Form dialog -->
    <v-dialog
      :value="formDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">{{ isEditing ? 'Edit' : 'Add' }} belief</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 text--primary">
          <template v-if="editedBelief">
            <div class="container px-12">
              <v-row>
                <v-col>
                  <collapsible-select
                    v-model="editedBelief.store_keys"
                    :items="storesList"
                    item-text="text"
                    item-value="value"
                    autocomplete
                    show-select-all
                    class="mt-1 mb-4 text-body-2"
                    hide-details
                    label="Stores"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <collapsible-select
                    :items="storeSets"
                    v-model="selectedStoreSets"
                    clearable
                    item-text="set_name"
                    item-value="stores_set_key"
                    label="Store Sets"
                    return-object
                    show-select-all
                    class="mt-1 mb-4 text-body-2"
                    autocomplete
                    hide-details="auto"
                  ></collapsible-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <collapsible-select
                    v-model="editedBelief.sku_names"
                    :items="item.sku_names.map(name => ({
                      text: name,
                      value: name
                    }))"
                    autocomplete
                    show-select-all
                    class="mt-1 mb-4 text-body-2"
                    hide-details
                    label="SKUs"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <WeekSelector
                    v-model="editedBelief.week_min"
                    only-future
                    :error-messages="getWeekMinError()"
                    clearable
                    label="From"
                    hide-details="auto"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu'
                    }"
                  />
                </v-col>
                <v-col>
                  <WeekSelector
                    v-model="editedBelief.week_max"
                    only-future
                    :error-messages="getWeekMaxError()"
                    clearable
                    label="To"
                    hide-details="auto"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu'
                    }"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model.number="editedBelief.factor"
                    :error-messages="getFactorError()"
                    label="Factor"
                    type="number"
                    hide-details="auto"
                    @input="editedBelief.absolute = null" />
                </v-col>
                <v-col>
                  <v-text-field
                    type="number"
                    v-model.number="editedBelief.absolute"
                    :error-messages="getAbsoluteError()"
                    label="Absolute"
                    hide-details="auto"
                    @input="editedBelief.factor = null" />
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-8">
                <v-col class="py-0">
                  <v-textarea
                    label="Note"
                    v-model="editedBelief.comment"
                  />
                </v-col>
              </v-row>
            </div>
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleSaveCancel"
              >Cancel</v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                @click="handleSaveConfirm"
                class="mx-4"
              >Save</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog -->
    <v-dialog
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium">Delete beliefs</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete this beliefs</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteConfirm"
                class="red--text mx-4"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :value="deleteBeliefsDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">

        <v-card-title class="text-body-1 font-weight-medium">Delete beliefs</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete this beliefs</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteBeliefsDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteBeliefsConfirm"
                class="red--text mx-4"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import * as shopApi from '@/api/shop'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import ChipsDropdown from '@/components/ChipsDropdown.vue'
import { addWeeks, format, getWeek } from 'date-fns'
import WeekSelector from '../WeekSelector'

export default {
  components: {
    WeekSelector,
    TableSearch,
    CollapsibleSelect,
    ChipsDropdown,
  },
  mixins: [columnFilters],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    stores: {
      type: Array,
      default: () => [],
    },
    storeSets: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      loading: false,
      saveShopItemDebounce:_.debounce(this.saveShopItem, 1000),
      dialogLoading: false,
      formDialogVisible: false,
      deleteDialogVisible: false,
      deleteBeliefsDialogVisible: false,
      isEditing: false,
      tableFilters: [],
      beliefsList: [],
      editedBelief: null,
      factorOrAbsoluteError: null,
      factorError: null,
      weekMinError: null,
      weekMaxError: null,
      beliefFields: ['store_keys', 'sku_names', 'week_min', 'week_max', 'absolute', 'factor', 'comment'],
      selectedIndex: null,
      selectedStoreSets: [],
    }
  },
  computed: {
    storesNames () {
      return this.stores.reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    predictionItems (){
      return [
        {
          text: 'Potential',
          value: 'potential',
        },
        {
          text: 'Sales Ly + Missed',
          value: 'sales_ly_missed',
        },
        {
          text: 'Sales Ly + LLy + Missed',
          value: 'sales_ly_lly_missed',
        },
      ]
    },
    storesList () {
      return this.item.store_keys.map(s => ({
        text: this.storesNames[s],
        value: s,
      }))
    },
    selectedRows () {
      return this.beliefsList.filter(r => r.selected)
    },
    moveUpDisabled () {
      return this.selectedRows.length !== 1 || !this.selectedRows[0] ||
        this.beliefsList.indexOf(this.selectedRows[0]) === 0
    },
    moveDownDisabled () {
      return this.selectedRows.length !== 1 || !this.selectedRows[0] ||
        this.beliefsList.indexOf(this.selectedRows[0]) === this.beliefsList.length - 1
    },
  },
  watch: {
    item: {
      handler (val) {
        if (val) {
          this.beliefsList = val.beliefs.map((b, i) => ({
            store_keys: b.store_keys || [],
            ...b,
            selected: (this.selectedIndex !== null && i === this.selectedIndex)
          }))
        }
      },
      immediate: true,
    },
  },
  methods: {
    getWeekMinError () {
      if (this.editedBelief.absolute) {
        if (!this.editedBelief.week_min) {
          return 'Week from is required'
        }
      }
      return []
    },
    getWeekMaxError () {
      if (this.editedBelief.absolute) {
        if (!this.editedBelief.week_max) {
          return 'Week to is required'
        }
      }
      return []
    },
    getFactorError () {
      if (this.editedBelief.factor && this.editedBelief.factor < 0) {
        return 'Factor must be greater or equal to 0'
      }
      if (typeof this.editedBelief.factor !== 'number' && typeof this.editedBelief.absolute !== 'number') {
        return 'Factor or absolute is required'
      }
      return []
    },
    getAbsoluteError () {
      if (typeof this.editedBelief.factor !== 'number' && typeof this.editedBelief.absolute !== 'number') {
        return 'Factor or absolute is required'
      }
      return []
    },
    saveShopItem (beliefs) {
      if(this.item.prediction_mode?.lengt){
        this.item.prediction_mode = ['potential']
      }
      const payload = {
        ...this.item,
        beliefs: beliefs
          .map(b => this.beliefFields.reduce((acc, cur) => ({
            ...acc,
            [cur]: b[cur]
          }), {}))
          .map(b => ({
            ...b,
            week_min: b.week_min ? parseInt(b.week_min) : null,
            week_max: b.week_max ? parseInt(b.week_max) : null,
            factor: typeof b.factor === 'number' ? parseFloat(b.factor) : null,
            absolute: typeof b.absolute === 'number' ? parseFloat(b.absolute) : null,
          })),
      }

      this.loading = true

      return shopApi
        .saveShopItem(payload).then((shop_item) => {
          this.$emit('shop-item-saved', shop_item)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSelectRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleMoveUpClick () {
      const selectedIndex = this.beliefsList.indexOf(this.selectedRows[0])
      const tmp = this.beliefsList[selectedIndex - 1]

      if (selectedIndex - 1 >= 0) {
        this.$set(this.beliefsList, selectedIndex - 1, this.selectedRows[0])
        this.$set(this.beliefsList, selectedIndex, tmp)
        this.selectedIndex = selectedIndex - 1

        this.loading = true
        this
          .saveShopItem(this.beliefsList)
          .then(() => {
            this.editedBelief = {}
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleMoveDownClick () {
      const selectedIndex = this.beliefsList.indexOf(this.selectedRows[0])
      const tmp = this.beliefsList[selectedIndex + 1]

      this.$set(this.beliefsList, selectedIndex + 1, this.selectedRows[0])
      this.$set(this.beliefsList, selectedIndex, tmp)
      this.selectedIndex = selectedIndex + 1

      this.loading = true
      this
        .saveShopItem(this.beliefsList)
        .finally(() => {
          this.loading = false
        })
    },
    handleDeleteBeliefClick () {
      this.deleteDialogVisible = true
    },
    handleDeleteConfirm () {
      this.beliefsList = this.beliefsList.filter(item => !item.selected)
      this.dialogLoading = true
      this.saveShopItem(this.beliefsList)
        .finally(() => {
          this.dialogLoading = false
          this.deleteDialogVisible = false
        })
    },
    handleEditBeliefClick () {
      this.formDialogVisible = true
      this.isEditing = true
      this.editedBelief = {
        ...this.selectedRows[0],
        week_min: this.selectedRows[0].week_min
          ? this.selectedRows[0].week_min?.toString()
          : null,
        week_max: this.selectedRows[0].week_max
          ? this.selectedRows[0].week_max?.toString()
          : null,
      }
    },
    handleAddBeliefClick () {
      this.formDialogVisible = true
      this.isEditing = false

      const sessionItem = sessionStorage.getItem('commitFilters')
      if (sessionItem) {
        this.editedBelief = JSON.parse(sessionItem)
        this.editedBelief.isNew = true
      } else {
        this.editedBelief = {
          isNew: true,
          store_keys: [ ...this.item.store_keys ],
          sku_names: [ ...this.item.sku_names ],
          week_min: this.item.week_min?.toString(),
          week_max: this.item.week_max?.toString(),
        }
      }
      if (!this.editedBelief.week_min) {
        const newDate = new Date()
        this.editedBelief.week_min = newDate.getFullYear() + '' + getWeek(newDate)
      } else {
        if (this.editedBelief.week_min < format((new Date()), 'yyyyww')) {
          this.editedBelief.week_min = format(new Date(), 'yyyyww')
        } else {
          this.editedBelief.week_min = this.editedBelief.week_min?.toString()
        }
      }
      if (!this.editedBelief.week_max) {
        const week_min = this.editedBelief.week_min.toString()
        const year = week_min.substring(0, 4)
        const week = week_min.substring(4)
        const weekMinDate = new Date(`${year}-01-01`)
        weekMinDate.setDate(week * 7)
        const week_max = addWeeks(weekMinDate, 52)
        this.editedBelief.week_max = format(week_max, 'yyyyww')
      } else {
        if (this.editedBelief.week_max < format((new Date()), 'yyyyww')) {
          const date = new Date()
          addWeeks(date, 52)
          this.editedBelief.week_max = format(date, 'yyyyww')
        } else {
          this.editedBelief.week_max = this.editedBelief.week_max.toString()
        }
      }
    },
    handleSaveCancel () {
      this.formDialogVisible = false
      this.editedBelief = null
      this.selectedStoreSets = []
    },
    handleSaveConfirm () {
      if (this.validateBelief(this.editedBelief)) {
        const storeKeysIncludingSets = _.concat(
          this.editedBelief.store_keys,
          _.flatMap(this.selectedStoreSets, 'store_keys')
        )
        const index = this.beliefsList.indexOf(this.selectedRows[0])

        this.editedBelief.store_keys = _.uniq(storeKeysIncludingSets)

        if (index !== -1 && !this.editedBelief.isNew) {
          this.beliefsList[index] = { ...this.editedBelief }
        } else {
          const payload = { ...this.editedBelief }
          delete payload.isNew
          this.beliefsList.push(payload)
        }

        this.dialogLoading = true
        this
          .saveShopItem(this.beliefsList)
          .finally(() => {
            this.dialogLoading = false
            this.formDialogVisible = false
            this.selectedStoreSets = []
          })
      }
    },
    validateBelief (belief) {
      let isValid = true
      this.factorOrAbsoluteError = null
      this.factorError = null
      this.weekMinError = null
      this.weekMaxError = null
      if (belief.factor && belief.factor < 0) {
        this.factorError = 'Factor must be greater or equal to 0'
        return false
      }
      if (typeof belief.factor !== 'number' && typeof belief.absolute !== 'number') {
        this.factorOrAbsoluteError = 'Factor or absolute is required'
        isValid = false
      } else {
        if (belief.absolute) {
          if (!belief.week_min) {
            this.weekMinError = 'Week from is required'
            isValid = false
          }

          if (!belief.week_max) {
            this.weekMaxError = 'Week to is required'
            isValid = false
          }
        }
      }

      return isValid
    },
    createBeliefFromChartItem (itemData, filters) {
      this.formDialogVisible = !itemData.absolute
      const storeKeys = itemData.type === 'store_keys_data' ? [ itemData.value ] : [ ...filters.store_keys ]
      const skus = itemData.type === 'sku_names_data' ? [ itemData.value ] : [ ...filters.sku_names ]
      let weekMin = itemData.type === 'week_number_data' ? itemData.value?.toString() : filters.week_min
      let weekMax = itemData.type === 'week_number_data' ? itemData.value?.toString() : filters.week_max
      if (!weekMin) {
        weekMin = format(new Date(), 'yyyyww')
      }

      if (!weekMax) {
        const year = weekMin.substring(0, 4)
        const week = weekMin.substring(4)
        const weekMinDate = new Date(`${year}-01-01`)
        weekMinDate.setDate(week * 7)
        const week_max = addWeeks(weekMinDate, 52)
        weekMax = format(week_max, 'yyyyww')
      }
      const editedBelief = {
        store_keys: storeKeys,
        sku_names: skus,
        week_min: weekMin,
        week_max: weekMax,
      }

      this.editedBelief = { ...editedBelief }
      if (itemData.absolute) {
        editedBelief.absolute = itemData.absolute
        this.beliefsList.push(editedBelief)

        this
          .saveShopItem(this.beliefsList)
          .finally(() => {
            this.dialogLoading = false
            this.formDialogVisible = false
            this.selectedStoreSets = []
          })
      }
    },
    createBeliefFromTableItem (itemData, filters) {
      const storeKeys = itemData.type === 'store_keys_data' ? [ itemData.value ] : [ ...filters.store_keys ]
      const skus = itemData.type === 'sku_names_data' ? [ itemData.value ] : [ ...filters.sku_names ]
      let weekMin = itemData.type === 'week_number_data' ? itemData.value?.toString() : filters.week_min
      let weekMax = itemData.type === 'week_number_data' ? itemData.value?.toString() : filters.week_max
      if (!weekMin) {
        weekMin = format(new Date(), 'yyyyww')
      }

      if (!weekMax) {
        const year = weekMin.substring(0, 4)
        const week = weekMin.substring(4)
        const weekMinDate = new Date(`${year}-01-01`)
        weekMinDate.setDate(week * 7)
        const week_max = addWeeks(weekMinDate, 52)
        weekMax = format(week_max, 'yyyyww')
      }
      const editedBelief = {
        store_keys: storeKeys,
        sku_names: skus,
        week_min: weekMin,
        week_max: weekMax,
      }

      this.editedBelief = { ...editedBelief }
      if (itemData.absolute) {
        editedBelief.absolute = itemData.absolute
        this.beliefsList.push(editedBelief)

        const payload = {
          ...this.item,
          beliefs: this.beliefsList
            .map(b => this.beliefFields.reduce((acc, cur) => ({
              ...acc,
              [cur]: b[cur]
            }), {}))
            .map(b => ({
              ...b,
              week_min: b.week_min ? parseInt(b.week_min) : null,
              week_max: b.week_max ? parseInt(b.week_max) : null,
              factor: typeof b.factor === 'number' ? parseFloat(b.factor) : null,
              absolute: typeof b.absolute === 'number' ? parseFloat(b.absolute) : null,
            })),
        }
        shopApi
          .saveShopItem(payload).then(() => this.$emit('belief-saved'))
      }
    },
    getBeliefsToDelete (payload) {
      switch (payload.config.xAxisKey) {
      case 'week_number':
        return this.beliefsList.filter(item => {
          return !payload.rows.includes(item.week_min) && !payload.rows.includes(item.week_max)
        })
      case 'store_keys':
        return this.beliefsList.filter(item => {
          let contains = false
          payload.rows.forEach(store => {
            if (item.store_keys.includes(store)) {
              contains = true
            }
          })
          return !contains
        })
      case 'sku_names':
        return this.beliefsList.filter(item => {
          let contains = false
          payload.rows.forEach(sku => {
            if (item.sku_names.includes(sku)) {
              contains = true
            }
          })
          return !contains
        })
      default :
        return []
      }
    },
    handleDeleteBeliefs (payload) {
      this.deletePayload = payload
      this.deleteBeliefsDialogVisible = true
    },
    handleDeleteBeliefsConfirm () {
      const payload = this.deletePayload
      const beliefsToDelete = this.getBeliefsToDelete(payload)
      this
        .saveShopItem(beliefsToDelete)
        .finally(() => {
          this.dialogLoading = false
          this.deleteDialogVisible = false
          this.deletePayload = null
        })
    }
  },
}
</script>

<style lang="scss">
.shop-item-beliefs {
}
</style>
