import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'


export function getPropertiesImpactAndMissing (payload) {
  const url = baseURLs.logicAPI + '/studio_shap'

  return axios.post(url, payload)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function getPricesChartData (payload) {
  const url = baseURLs.logicAPI + '/studio_price_shap'

  return axios.post(url, payload)
    .then(response => response.data)
    .catch(error => console.log(error))
}
