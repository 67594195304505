// suppress typescript errors for now since we are in process of migrating parts to typescript
// @ts-nocheck
import Vue from 'vue'
import App from '@/App.vue'
import pinia from '@/store/pinia.js'
import router from '@/router'
import '@/filters'
import Vuelidate from 'vuelidate'
import locale from 'element-ui/lib/locale/lang/en'
import './assets/css/element-ui.css'
import './assets/css/style.scss'
import AuthPlugin from '@/plugins/auth'
import ElementUI from 'element-ui'
import {DataTables} from '@/components/Tables/index'
import VueCarousel from 'vue-carousel'
import vuetify from './plugins/vuetify'
import VueKinesis from 'vue-kinesis'
import VAnimateCss from 'v-animate-css'
import './plugins/elTableSortFixer.js'
import globalInputMaxLength from '@/mixins/globalInputMaxLength'
import {initSentry} from '@/sentry'

// workaround for pagination design
locale.el.pagination.pagesize = ''
locale.el.pagination.total = 'Total: {total}'
Vue.use(ElementUI, { locale })
Vue.use(AuthPlugin)
Vue.use(Vuelidate)
Vue.use(DataTables)
Vue.use(VueCarousel)
Vue.use(VueKinesis)
Vue.use(VAnimateCss)

// Registers global mixing to set maxlength on all inputs and textareas
Vue.mixin(globalInputMaxLength)
Vue.config.productionTip = false

initSentry(Vue, router)

const app = new Vue({
  pinia,
  router,
  vuetify,
  render: h => h(App)
})
app.$mount('#app')
