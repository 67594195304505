<template>
  <v-menu
    bottom
    offset-y
    content-class="linked-pages-menu"
    min-width="450"
    max-width="450"
    nudge-bottom="10"
    :open-delay="200"
    :close-on-content-click="false"
    v-model="menuOpened"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="ml-1 mr-3 icon-highlight-on-focus"
        :class="{
          active: menuOpened,
          'warning--text': isBaseOptionKey,
        }"
        v-bind="attrs"
        v-on="on"
      >insert_link</v-icon>
    </template>
    <div class="pb-3">
      <h4 class="ml-4 mb-6 pt-6">
        External keys
      </h4>
      <v-row class="align-center mx-1">
        <v-col cols="6">
          Variation
        </v-col>
        <v-col>
          {{ variationKey }}
        </v-col>
      </v-row>
      <v-row class="align-center mx-1">
        <v-col cols="6">
          Option
        </v-col>
        <v-col>
          {{ optionKey }}
        </v-col>
      </v-row>
      <v-row v-if="isBaseOptionKey" class="mx-1">
        <v-col cols="6">
          Replacements
        </v-col>
        <v-col cols="6">
          {{ replacementOptionKeys.join(', ') }}
        </v-col>
      </v-row>
      <h4 class="ml-4 mb-6 mt-8">
        Linked pages
      </h4>
      <v-row class="align-center px-1 linked-pages-list">
        <v-col cols="4" class="pt-4 pb-4" :class="{ active: buyReorderActive }">
          <router-link
            v-if="!buyReorderActive"
            class="linked-page-link"
            :to="{
              name: 'buy-reorder-page',
              query: {
                'option_key': optionKey
              }
            }">
            Buy<br/>reorder
          </router-link>
          <span v-else>Buy<br/>reorder</span>
        </v-col>
        <v-col cols="4" class="pt-4 pb-4" :class="{ active: stockOptionActive }">
          <router-link
            v-if="!stockOptionActive"
            class="linked-page-link"
            :to="{
              name: 'stock-option',
              query: {
                'option_key': optionKey
              }
            }">
            Stock<br/>option
          </router-link>
          <span v-else>Stock<br/>option</span>
        </v-col>
        <v-col cols="4" class="pt-4 pb-4" :class="{ active: pricingMarkdownActive }">
          <router-link
            v-if="!pricingMarkdownActive"
            class="linked-page-link"
            :to="{
              name: 'pricing-markdown-page',
              query: {
                'option_key': optionKey
              }
            }">
            Pricing<br/>markdown
          </router-link>
          <span v-else>Pricing<br/>markdown</span>
        </v-col>
      </v-row>
    </div>
  </v-menu>
</template>

<script>

export default {
  name: 'LinkedPagesMenu',
  props: {
    variationKey: {
      type: String,
      default: null,
    },
    optionKey: {
      type: String,
      default: null,
    },
    replacementOptionKeys: {
      type: Array || null,
      default: null,
    },
  },
  data () {
    return {
      menuOpened: false,
    }
  },
  computed: {
    isBaseOptionKey () {
      return this.replacementOptionKeys && this.replacementOptionKeys.length && this.replacementOptionKeys[0] === this.optionKey
    },
    buyReorderActive () {
      return this.$route.name === 'buy-reorder-page'
    },
    stockOptionActive () {
      return this.$route.name === 'stock-option'
    },
    pricingMarkdownActive () {
      return this.$route.name === 'pricing-markdown-page'
    },
  },
}
</script>

<style lang="scss" scoped>
    .linked-pages-menu {
        background-color: #fff;
        .linked-pages-list {
            border-top: 1px solid #e3e3e3;
            .col {
                text-align: center;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                position: relative;
                &.active {
                    background-color: #f5f7fa;
                }
                &:not(:last-child) {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        border-left: 1px solid #e3e3e3;
                    }
                }
            }
            .linked-page-link {
                text-decoration: none;
                color: #000;
            }
        }
    }
</style>
