<template>
  <div>
    <v-card class="pt-5 px-2 my-5" :class="{'py-6':!scatterData}">
      <v-overlay absolute color="white" :value="!scatterData">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <h4 class="text-left ml-4">Stock vs Required</h4>
      <data-sets-scatter-chart
        class="pl-3 mt-3"
        :flat="true"
        :view="scatterData || {view: {}}"
        :hide="true"
        :index="null"
        height="430"
      />
    </v-card>
    <v-card>
      <v-overlay absolute color="white" :value="!reorderGrouped">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <data-sets-tables
        v-if="reorderGrouped"
        :key="groupedKey"
        :view="reorderGrouped"
        :index="0"
        :showDownload="true"
        :has-title="false"
        :child-views="reorderGrouped.view.childViews"
        @expand-change="(...data) => loadChildViews(data)"
      >
        <v-col cols="10" style="margin-right: -100px; padding-right: 100px">
          <h4 class="px-3 py-1">Reorder grouped</h4>
          <v-col cols="3" class="py-0">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              :items="dimensions"
              :filter="headersFilters"
              :value="chosenDimension"
              @input="onDimensionsChange"
            />
          </v-col>
        </v-col>
      </data-sets-tables>
    </v-card>
    <v-card class="mt-5">
      <v-overlay absolute color="white" :value="!reorderArticles">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <data-sets-tables
        v-if="reorderArticles"
        :key="articlesKey"
        :view="reorderArticles"
        :index="0"
        :showDownload="true"
        :has-title="false"
        :child-views="reorderArticles.view.childViews"
        @expand-change="(...data) => loadChildViews(data)"
      >
        <v-col cols="10" style="margin-right: -100px; padding-right: 100px">
          <h4 class="px-3 py-2">Reorder articles</h4>
        </v-col>
      </data-sets-tables>
    </v-card>
  </div>
</template>

<script>
import DataSetsScatterChart from '@/components/AutomateDatasets/DataSetsScatterChart'
import { getDatasets, performViewData } from '@/api/analytics'
import DataSetsTables from '@/components/AutomateDatasets/DataSetsTables'
import { formatString } from '@/variables'
import { headersFilters } from '@/utils'
import { useAnalyticsFiltersStore } from '@/store/pinia/analyticsFiltersStore'

export default {
  name: 'ReorderHome',
  components: { DataSetsTables, DataSetsScatterChart },
  setup () {
    return {
      analyticsFiltersStore: useAnalyticsFiltersStore()
    }
  },
  data () {
    return {
      scatterKey: 0,
      groupedKey: 0,
      articlesKey: 0,
      datasets: [],
      chosenDimension: 'properties/article/group',
      scatterData: null,
      reorderGrouped: null,
      reorderArticles: null,
      views: [
        {
          'dataset': 'buy_reorder',
          'view_name': 'Stock vs Required',
          'chart_type': 'scatter_chart',
          'dimensions': ['properties/article/group'],
          'measures': [{
            'name': 'Stock',
            'calculation': 'stock'
          }, {
            'name': 'Require max',
            'calculation': 'require_max'
          }, {
            'name': 'To order N6M',
            'calculation': 'to_order_n6m'
          }],
          'filters': {},
          'properties': {
            'axis': {
              'x': 'Stock',
              'y1': 'Require max',
              'grading_max': 10000,
              'grading_min': 0,
              'color_grading': 'To order N6M'
            },
            'limit': null
          },
          'creation_date': '2023-01-02 09:42:06'
        },
        {
          'dataset': 'buy_reorder',
          'view_name': 'Reorder grouped',
          'chart_type': 'table',
          'dimensions': ['properties/article/group'],
          'measures': [
            { 'name': 'Stock', 'decimals': 0, 'calculation': 'stock' },
            { 'name': 'Require min (Avg)', 'decimals': 0, 'calculation': 'require_min' },
            { 'name': 'Require max (Avg)', 'decimals': 0, 'calculation': 'require_max', 'grading_max': 10000, 'color_grading': false },
            { 'name': 'Stock - Max', 'calculation': ['stock', '-', 'require_max'] },
            { 'name': 'To order N5W', 'decimals': 0, 'calculation': 'to_order_n5w', 'color_grading': true },
            { 'name': 'Ordered N6M', 'decimals': 0, 'calculation': 'ordered_n6m' }
          ],
          'filters': {},
          'properties': { 'rows': 10, 'sort': 'To order N5W', 'total_row': true, 'child_rows': 'properties/article/key' },
          'creation_date': '2023-01-02 09:34:02',
          'limited': false,
          'filters_applied': [] },
        {
          'dataset': 'buy_reorder',
          'view_name': 'Reorder articles',
          'chart_type': 'table',
          'dimensions': ['properties/article/key'],
          'measures': [{
            'name': 'Stock',
            'decimals': 0,
            'calculation': 'stock'
          }, {
            'name': 'Require min',
            'decimals': 0,
            'calculation': 'require_min'
          }, {
            'name': 'Require max',
            'decimals': 0,
            'calculation': 'require_max'
          }, {
            'name': 'Stock - Max',
            'decimals': 0,
            'calculation': ['stock', '-', 'require_max']
          }, {
            'name': 'To order N5W',
            'decimals': 0,
            'calculation': 'to_order_n5w',
            'color_grading': true
          }, {
            'name': 'Ordered N6M',
            'decimals': 0,
            'calculation': 'ordered_n6m'
          }],
          'filters': {},
          'properties': {
            'rows': 10,
            'sort': 'To order N5W',
            'total_row': true,
            'limit': null
          },
          'creation_date': '2023-01-02 09:34:02',
          'format': 'doa'
        }
      ],
    }
  },
  created () {
    this.loadData(true)
    if (this.analyticsFiltersStore.datasetsList.length) {
      this.datasets = this.analyticsFiltersStore.datasetsList
    } else {
      getDatasets().then((data) => {
        this.datasets = data
        this.analyticsFiltersStore.setDatasetsList(data)
      })
    }
  },
  computed: {
    dimensions () {
      const dimensions = {}
      if (!this.datasets['buy_reorder']) {
        return []
      }
      const dimensionsList = this.datasets['buy_reorder'].dimensions
      dimensionsList.forEach((item) => {
        const data = item.split('/')
        const header = { header: formatString(data[1] || ''), text: [] }
        if (!dimensions[data[1]]) {
          dimensions[data[1]] = [header]
        }
        const text = data[2] === 'name' ? `Name (${formatString(data[1])})` : formatString(data[2] || '')
        dimensions[data[1]].push({ text, value: item, headerTitle: header.header })
        dimensions[data[1]][0].text.push(formatString(data[2] || ''), formatString(data[1] || ''))
      })
      const data = []
      for (const key in dimensions) {
        data.push(...dimensions[key])
      }
      return data
    },
  },
  methods: {
    headersFilters,
    loadData (initial = false) {
      this.views.forEach((view, index) => {
        if (index !== 2) {
          view.dimensions[0] = this.chosenDimension
        }
        performViewData(view).then(r => {
          switch (index) {
          case 0:
            this.scatterData = r
            this.scatterKey += !initial ? 1 : 0
            break
          case 1:
            this.reorderGrouped = r
            this.groupedKey += !initial ? 1 : 0
            break
          case 2:
            this.reorderArticles = r
            this.articlesKey += !initial ? 1 : 0
            break
          }
        })
      })
    },
    loadChildViews (view) {
      performViewData(view, true).then(() => {
        this.reorderGrouped = { ...this.reorderGrouped }
      })
        .catch(console.log)
    },

    onDimensionsChange (input) {
      this.chosenDimension = input
      this.loadData()
    },
  }
}
</script>

<style scoped>

</style>
