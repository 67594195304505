import axios from '../services/axios'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

const logicApiVersion = localStorage.getItem('logicApiVersion')

export const loadFeatureStores = () => {
  const url = baseURLs.logicAPI + '/feature_score?version=' + logicApiVersion

  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
    .then(({ data }) => {
      return data
    }).catch(error => {
      console.log(error)
      return []
    })
}
