<template>
  <v-dialog
    v-model="isOpen"
    scrollable
    persistent
    content-class="align-self-start mt-14"
    width="600px"
    max-width="90%"
  >
    <v-card>
      <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">Add SKU Range</v-card-title>
      <v-divider/>
      <v-card-text class="px-0 text--primary">
        <div class="container px-12">
          <v-text-field
            color="primary"
            v-model="sku.sku_range_name"
            label="Name"
            hide-details="auto"
            required
            class="my-4"
          />
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2">
          <v-col>
            <v-btn
              color="primary"
              depressed
              text
              @click="handleCancel"
            >Cancel</v-btn>
            <v-btn
              color="primary"
              depressed
              :disabled="!isLocalPropertyValid"
              @click="handleSave"
              class="mx-4"
            >Save</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SKUModal',
  data () {
    return {
      sku: {
        sku_range_name: '',
        sku_names: [],
      }
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isLocalPropertyValid () {
      return this.sku.sku_range_name.length > 0
    },
  },
  methods: {
    handleCancel () {
      this.$emit('close')
    },
    handleSave () {
      this.$emit('save', this.sku)
    },
  },
}
</script>

<style scoped>

</style>
