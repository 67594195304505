<template>
  <div class="shop-lookalike-comparison">
    <v-overlay absolute color="white" :value="!lookalikeData || searchLoading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-row class="justify-space-between align-center mb-2 px-4 my-1">
      <v-col>
        <h4 class="px-4">Lookalikes</h4>
      </v-col>
      <v-col class="text-right">
        <TableSearch @searchChanged="handleLookalikesSearchChanged" />
        <v-menu :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="action-btn-primary mx-2" v-bind="attrs" v-on="on">tune</v-icon>
          </template>

          <v-card>
            <h4 class="ml-4 mb-6 pt-6 edit-parameters-title">
              Edit parameters
            </h4>
            <div class="parameter">
              <v-col cols="3" class="pa-0">Max weeks</v-col>
              <v-col cols="7" class="pa-0">
                <v-row class="ma-0 align-center">
                  <v-slider
                    v-model="weekSlider"
                    max="52"
                    min="0"
                    dense
                    hide-details
                    thumb-label
                    thumb-size="27"
                    @change="()=> storesChangedDelayed()"
                  >
                    <template v-slot:prepend>
                      <span class="pt-1 pr-4 grey--text">0</span>
                    </template>

                    <template v-slot:append>
                      <span class="pt-1 pl-4 grey--text">52</span>
                    </template>
                  </v-slider>
                </v-row>
              </v-col>
            </div>
            <div class="parameter">
              <v-col cols="3" class="pa-0">Stores</v-col>
              <v-col cols="7" class="pa-0">
                <collapsible-select
                  v-model="storesFilter"
                  :items="storesList"
                  item-text="text"
                  item-value="value"
                  autocomplete
                  :show-select-all="storesList.length > 0"
                  class="mt-1 mb-4 text-body-2"
                  hide-details="auto"
                  :error-messages="!(storesFilter || []).length ? 'Select a store set with stores' : ''"
                  @change="storesChangedDelayed"
                />
              </v-col>
            </div>
          </v-card>
        </v-menu>

      </v-col>
    </v-row>
    <data-tables
      id="lookalikes-table"
      ref="lookalikeTable"
      style="width: 100%"
      :key="lookalikesListKey"
      :data="lookalikes"
      :page-size="10"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
      :table-props="{ 'row-key': 'option_key', 'row-class-name': isSpecial }"
      :filters="lookalikesTableFilters"
      :expand-row-keys="expandedPanels.map(d => d.option_key)"
      :row-key="d => d.option_key"
      @row-click="toggleExpansion"
      @expand-change="expansionChanged"
    >
      <el-table-column type="expand" v-if="lookalikes?.length" >
        <template #default="props">
          <v-row>
            <v-col cols="12">
              <v-carousel
                height="100%"
                class="pb-15"
                delimiter-icon="circle"
                :perPage="1"
                light
                v-model="carouselStep"
                hide-delimiter-background
                :hide-delimiters="false"
                :show-arrows="false"
              >
                <v-carousel-item :eager="true">
                  <h4 class="chart-title pa-6 pt-2">
                    Potentials
                  </h4>
                  <ChartElement
                    title="shop_item_lookalike"
                    id="shop_item_lookalike"
                    :chartData="lookalikeGraph[props.row.option_key]"
                    :params="{}"
                    forcedHeight="300px"
                    :padding="true"
                    :fixedHeight="false"
                  />

                </v-carousel-item>
                <v-carousel-item>
                  <LookalikeCharts
                    title="lookalike_skus"
                    :chartData="lookalikeSkus[props.row.option_key]"
                    :params="{}"
                    height="300px"
                    :padding="true"
                    :fixedHeight="false"
                  />
                </v-carousel-item>
              </v-carousel>

            </v-col>
          </v-row>
        </template>
      </el-table-column>
      <el-table-column
        v-for="col in columns"
        sortable="custom"
        :key="col"
        :prop="col"
        :label="columnNames[col] || col"
        :label-class-name="col === 'option_key' ? 'ml-2' : ''"
        :width="col === 'option_key' ? '320' : null">
        <template slot-scope="scope">
          <div v-if="col === 'option_key' && scope.row.option_name" class="d-flex flex-nowrap justify-space-between align-center">
            <v-col cols="8" class="px-0">
              <div>
                <OptionDataCell
                  class="lookalikesComparisonDataCell"
                  :option="scope.row"
                  name-key="option_name"
                />
              </div>
            </v-col>
            <v-col cols="4">
              <v-menu
                :key="infoKey"
                top
                open-on-hover
                offset-x
                min-width="200"
                max-width="200"
                content-class="option-info-menu"
                :open-delay="200"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-0 icon-has-action disabled"
                    :style="{opacity: getProperties(scope.row) ? 1 : 0.5}"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    @click.stop="setProperties(scope.row)"
                    @mouseover="setProperties(scope.row)"
                  >
                    info
                  </v-icon>
                </template>
                <v-card v-if="getProperties(scope.row)"
                  class="py-2 px-4 p-relative"
                  v-html="getProperties(scope.row)"
                />
              </v-menu>
              <v-btn
                v-if="!isCurrentLookalike(scope.row)"
                class="icon-has-action"
                dense
                icon
                @click.stop="addOption(scope.row)"
              >
                <v-icon>add</v-icon>
              </v-btn>
              <v-btn
                v-if="isCurrentLookalike(scope.row)"
                class="icon-has-action"
                dense
                icon
                @click.stop="removeOption(scope.row)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-col>
          </div>
          <div v-else-if="['week_min', 'week_max'].includes(col)">{{ scope.row[col] | formatWeekNumber }}</div>
          <div v-else-if="col === 'store_keys'">
            <ChipsDropdown :items="getStoreNames(scope.row.store_keys)" />
          </div>
          <div v-else-if="col === 'sku_names'">
            <ChipsDropdown :items="scope.row.sku_names" />
          </div>
          <div v-else-if="col === 'RoS'">
            <span :style="{color: getColorCoding(scope.row)}">{{ scope.row[col] }}</span>
          </div>
          <div v-else-if="col === 'ST%'">
            <span>{{ scope.row[col] }}%</span>
          </div>
          <div v-else>{{ scope.row[col] }}</div>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { formatString } from '@/variables.js'
import TableSearch from '@/components/TableSearch.vue'
import OptionDataCell from '@/components/OptionDataCell.vue'
import ChipsDropdown from '@/components/ChipsDropdown.vue'
import * as shopApi from '@/api/shop'
import ChartElement from '@/chart/ChartElement'
import CollapsibleSelect from '@/components/CollapsibleSelect'
import _ from 'lodash'
import { loadOptionData } from '@/api/options'
import LookalikeCharts from './LookalikeCharts'

export default {
  components: {
    LookalikeCharts,
    CollapsibleSelect,
    ChartElement,
    OptionDataCell,
    ChipsDropdown,
    TableSearch,
  },
  data () {
    return {
      infoKey: 0,
      searchLoading: false,
      carouselStep: 0,
      articleProperties: {},
      articlePropertyLock: {},
      storesChangedDelayed: _.debounce(this.getLookalikeData, 500),
      storesFilter: [],
      weekSlider: 0,
      lookalikeAdditionalData: [],
      lookalikesListKey: 0,
      expandedPanels: [],
      lookalikeData: null,
      lookalikeGraph: null,
      lookalikeSkus: null,
      lookalikesTableFilters: [
        {
          value: ''
        }
      ],
      columnNames: {
        option_key: 'Option',
        store_keys: 'Stores',
        sku_names: 'SKUs',
        week_min: 'Start',
        Intake: 'Intake',
        'Sales (net)': 'Sales (net)',
        'ST%': 'ST%',
        Missed: 'Missed',
        RoS: 'RoS',
        'RoS belief': 'RoS belief',
        Price: 'Price/Markdown',
      },
      getLookalikeDebounced: null,
      lookalikesSearch: ''
    }
  },
  created () {
    this.getLookalikeDebounced = _.debounce(this.getLookalikeData, 500)
  },
  props: {
    dummy: {
      type: Object,
      default: () => ({}),
    },
    commitmentsPayload: {
      type: Object,
      default: () => ({}),
    },
    stores: {
      type: Array,
      default: () => [],
    },
    reorder_option_keys: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    storesList () {
      return ((this.commitmentsPayload ? this.commitmentsPayload.store_keys : []) || []).map(s => ({
        text: this.storesNames[s],
        value: s,
      }))
    },
    lookalikes () {
      if (!this.lookalikeData) {
        return []
      }
      return this.lookalikeData.data.map(la => ({
        ...this.lookalikeAdditionalData.find(lad => lad.option_key === la.option_key),
        ...la,
      }))
    },
    storesNames () {
      return this.stores.reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    columns () {
      if (!this.lookalikeData) {
        return []
      }

      return this.lookalikeData.columns
    },
  },
  watch: {
    commitmentsPayload: {
      async handler () {
        if (!this.commitmentsPayload) {
          return
        }
        if (!this.commitmentsPayload.week_max) {
          this.weekSlider = 52
        }
        this.setDefaultWeekSlider()
        this.storesFilter = this.commitmentsPayload.store_keys
        this.storesChangedDelayed()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getColorCoding (value) {
      if (value['RoS'] >= 1.2 * value['RoS belief']) {
        return 'green'
      } else if (value['RoS'] <= 0.8 * value['RoS belief']) {
        return 'orange'
      } else {
        return ''
      }
    },
    expansionChanged (row, expanded_rows) {
      this.expandedPanels = expanded_rows
      for (let i = 0; i < expanded_rows.length - 1; i++) {
        this.$refs.lookalikeTable.$refs.elTable.toggleRowExpansion(expanded_rows[i], false)
      }
    },
    toggleExpansion (row) {
      this.$refs.lookalikeTable.$refs.elTable.toggleRowExpansion(row, !this.expandedPanels.includes(row))
    },
    getLookalikeData (value) {
      let week_max = this.commitmentsPayload.week_max

      if (this.commitmentsPayload.week_min && value !== undefined) {
        week_max = parseInt(this.commitmentsPayload.week_min.toString().substring(4)) + this.weekSlider
        if (week_max / 52 > 1 && week_max % 52) {
          week_max = this.commitmentsPayload.week_min + this.weekSlider + 48
        } else {
          week_max = this.commitmentsPayload.week_min + this.weekSlider
        }
      }
      shopApi.getShopLookalikesData({
        ...this.commitmentsPayload,
        store_keys: this.storesFilter || [],
        weeks_slider: this.weekSlider,
        week_max,
        reorder_option_keys: this.reorder_option_keys,
        properties: this.dummy.properties,
        lookalike_option_keys: this.dummy.lookalike_option_keys,
      }, this.lookalikesSearch).then(data => {
        this.lookalikeData = data.lookalike_data || { data: [] }
        this.lookalikeGraph = data.lookalike_graphs
        this.lookalikeSkus = data.lookalike_skus
        this.searchLoading = false
      })
    },
    setDefaultWeekSlider () {
      if (this.commitmentsPayload.week_min && this.commitmentsPayload.week_max) {
        const year_min = parseInt(this.commitmentsPayload.week_min.toString().substring(0, 4))
        const year_max = parseInt(this.commitmentsPayload.week_max.toString().substring(0, 4))
        const week_min = parseInt(this.commitmentsPayload.week_min.toString().substring(4))
        const week_max = parseInt(this.commitmentsPayload.week_max.toString().substring(4))
        if (year_min === year_max) {
          this.weekSlider = week_max - week_min
        } else {
          this.weekSlider = Math.max(Math.min((year_max - year_min) * 52 + week_max - week_min, 52), 1)
        }
      }
    },
    getStoreNames (storeKeys) {
      return storeKeys.map(key => this.storesNames[key])
    },
    // Lazy load properties for each lookalike
    async setProperties (article) {
      // If properties are already loaded, do nothing
      if (this.articleProperties[article.option_key]) {
        return
      }
      // If properties are being loaded, wait for them to finish
      if (this.articlePropertyLock[article.option_key]) {
        return
      }
      try {
        // Lock properties loading
        this.articlePropertyLock[article.option_key] = true
        const { data: { data } } = await loadOptionData({ option_key: article.option_key })
        this.articleProperties[article.option_key] = data.properties || { 'props': 'ss' }
        this.infoKey++
      } catch (e) {
        console.error(e)
      } finally {
        // Unlock properties loading
        this.articlePropertyLock[article.option_key] = false
      }
    },
    getProperties (article) {
      let dataString = ''
      const data = this.articleProperties[article.option_key]
      if (!data) {
        return ''
      }
      for (const key in data) {
        const val =
          '<b>' + formatString(key) + '</b>: ' + data[key].toLocaleString()
        dataString += val + '<br>'
      }
      return dataString
    },
    handleLookalikesSearchChanged (val) {
      this.searchLoading = true
      this.lookalikesSearch = val
      this.storesChangedDelayed()
    },
    isSpecial ({ row }) {
      const isSpecial = this.dummy.lookalike_option_keys.includes(row.option_key.toString())
      return isSpecial ? 'bg-special cursor-pointer' : 'cursor-pointer'
    },
    addOption (row) {
      if (this.dummy.lookalike_option_keys.includes(row.option_key.toString())) {
        return
      }
      this.dummy.lookalike_option_keys.push(row.option_key.toString())
      this.$emit('dummyLookalikeKeysChanged')
    },
    removeOption (row) {
      this.dummy.lookalike_option_keys = this.dummy.lookalike_option_keys.filter(key => key !== row.option_key.toString())
      this.$emit('dummyLookalikeKeysChanged')
    },
    isCurrentLookalike (row) {
      return this.dummy.lookalike_option_keys.includes(row.option_key.toString())
    },
  },
}
</script>

<style lang="scss">
.bg-special {
  background-color: #f5f7fa !important;
}
.shop-lookalike-comparison {
  .cursor-pointer {
    cursor: pointer;
  }
}
</style>

<style>
  .lookalikesComparisonDataCell .option-title {
    max-width: 140px;
    overflow-x: hidden;
    text-overflow: ellipsis
  }
</style>
