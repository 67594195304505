<template>
  <div>
    <label for="email">
      Email
    </label>
    <v-text-field
      id="email"
      v-model="form.email"
      :error-messages="errors.email"
      @change="validateEmail"
      required
      type="email"
      @keydown.enter="submit"
      outlined
      autofocus
    />
    <div class="d-flex justify-lg-space-between">
      <label for="password">
        Password
      </label>
      <v-spacer/>
      <router-link :to="'/forgot-password?email=' + form.email" class="text-right text-decoration-none">
        Forgot password?
      </router-link>
    </div>
    <v-text-field
      id="password"
      v-model="form.password"
      :error-messages="errors.password"
      type="password"
      @change="validatePassword"
      @keydown.enter="submit"
      required
      outlined
    />
    <v-btn
      class="action mt-1"
      outlined
      @click="submit"
      v-animate-css.click="'tada'"
      v-animate-css.hover="'pulse'"
      :disabled="submitLock"
    >
      Login
    </v-btn>
    <v-row align="center" class="my-6 divider-row">
      <v-divider/><span class="px-2 text--lighten-4">OR</span><v-divider/>
    </v-row>
    <v-row justify="space-between" class="mt-2 px-3">
      <microsoft-button
        @success="onAuthSuccess"
        @error="setError"
      />
      <google-button
        class="pt-6"
        @success="onAuthSuccess"
        @error="setError"
      />
    </v-row>
  </div>
</template>

<script>
import { getModuleFromPath } from '@/utils'
import GoogleButton from '@/components/Users/GoogleButton.vue'
import { useGeneralStore } from '@/store/pinia/generalStore'

const MicrosoftButton = () => import('@/components/Users/MicrosoftButton.vue')

export default {
  name: 'LoginForm',
  components: { GoogleButton, MicrosoftButton },
  data () {
    return {
      errors: { email: '', password: '' },
      form: { email: '', password: '' },
      submitLock: false,
    }
  },
  props: {
    errorMessage: {
      type: String,
      default: null
    },
    successMessage: {
      type: String,
      default: null
    }
  },
  methods: {
    isValidEmail (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    },
    validateEmail () {
      if (!this.form.email) {
        this.errors.email = 'Email is required!'
      } else if (!this.isValidEmail(this.form.email)) {
        this.errors.email = 'Email is not valid!'
      } else {
        this.errors.email = ''
      }
    },
    validatePassword () {
      if (!this.form.password) {
        this.errors.password = 'Password is required!'
      } else {
        this.errors.password = ''
      }
    },
    validateForm () {
      // Email validation
      this.validateEmail()
      // Password validation
      this.validatePassword()
      return !this.errors.email && !this.errors.password
    },
    setError (error) {
      this.$emit('update:errorMessage', error)
    },
    async onAuthSuccess () {
      try {
        await this.$auth.refreshAppConfig()
        let lastVisitedModule = localStorage.getItem('lastVisitedModule') || 'stock'
        if (this.$route.query['redirect']) {
          lastVisitedModule = getModuleFromPath(this.$route.query['redirect']) || lastVisitedModule
          this.$router.push({ path: this.$route.query['redirect']?.toString() })
        } else {
          this.$router.push({ path: `/${lastVisitedModule}/home` })
        }

        const defaultCurrency = useGeneralStore().buySettings?.default_currency
        localStorage.setItem('defaultCurrency', defaultCurrency || null)
        localStorage.setItem('lastVisitedModule', lastVisitedModule)
      } catch (e) {
        console.error(e)
      }
    },
    async submit () {
      // To avoid double submit
      if (this.submitLock) {
        return
      }
      this.setError(null)
      if (!this.validateForm()) {
        return
      }

      this.submitLock = true
      try {
        // All validations passed, so try to log in
        const response = await this.$auth.login(this.form.email.toLowerCase(), this.form.password)
        if (response.status === 200) {
          await this.onAuthSuccess()
        } else {
          this.setError('Either the password or the email address is incorrect.')
        }
      } catch (error) {
        this.setError('Something went wrong, please try again later.')
      }
      this.submitLock = false
    },
  },

}
</script>
