<template>
  <div class="option-sets-search-filters">
    <h6 class="text-subtitle-1 font-weight-medium mb-4">{{ title }}</h6>
    <div class="text-body-1 font-weight-medium pt-2 px-6">Filter</div>
    <v-container fluid>
      <div style="width: 550px">
        <template v-for="(filter, index) in selectedFilters">
          <v-row
            v-if="!(filter.type === 'option_keys_include' && !staticSet && !filter.values.length)"
            :key="index"
            class="align-baseline"
          >
            <v-col
              cols="3"
              class="px-6 text-body-2"
            >
              {{ filterOptions.find(o => o.value === filter.type)?.label }}
            </v-col>
            <v-col
              :cols="7"
              class="px-6"
            >
              <template v-if="filter.type === 'option_keys_include'">
                <div class="p-relative">
                  <v-overlay absolute color="white" :value="optionsKeysLoading">
                    <v-progress-circular color="primary" indeterminate size="32" width="2" />
                  </v-overlay>

                  <template v-if="filter.values.length > 1000">
                    {{ filter.values.length }} options included
                  </template>
                  <template v-else>
                    <CollapsibleSelect
                      class="mt-4"
                      ref="optionsSelect"
                      v-model="filter.values"
                      placeholder="Search"
                      :items="stockManageStore.setsTab.optionsList"
                      :return-object="false"
                      :error-messages="filter.errors[0]"
                      item-value="option_key"
                      item-text="name"
                      multiple
                      autocomplete
                      chips
                      click-first-filters-selected
                      show-selected-first
                      clearable
                      small-chips
                      deletable-chips
                      hide-details
                      :menu-props="{
                        left: true,
                        offsetY: true,
                        contentClass: 'select-dropdown-menu',
                      }"
                      @blur="$emit('filtersChanged')"
                      @focus="handleOptionsSelectFocus"
                    />
                  </template>
                </div>
              </template>
              <template v-if="['stock_active', 'stock_frozen', 'stock_allow_transfers', 'stock_optimize_margin', 'stock_force_minimum', 'stock_exclude_minimum', 'stock_safe_guard', 'stock_infinite_supply', 'stock_exclude_deficit'].includes(filter.type)">
                <v-autocomplete
                  v-model="filter.values[0]"
                  :items="[{
                    text: 'Yes',
                    value: true,
                  }, {
                    text: 'No',
                    value: false,
                  }]"
                  hide-details="auto"
                  clearable
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                  @change="$emit('filtersChanged')"
                />
              </template>
              <template v-if="filter.type === 'discount'">
                <div class="d-flex align-center">
                  <div class="slider-value-from mr-2">0</div>
                  <v-range-slider
                    v-model="filter.values"
                    hide-details="auto"
                    thumb-label
                    track-color="#ccc"
                    track-fill-color="primary"
                    :min="0"
                    :max="100"
                    @change="$emit('filtersChanged')"
                  />
                  <div class="slider-value-to ml-2">100</div>
                </div>
              </template>
              <template v-if="['osp', 'asp', 'stock', 'dc_stock', 'inbounds', 'skus', 'stores_included', 'days_until_inbound'].includes(filter.type)">
                <div class="d-flex align-center">
                  <v-text-field
                    type="number"
                    v-model.number="filter.values[0]"
                    dense
                    :error-messages="filter.errors[0]"
                    hide-details
                    clearable
                    class="mr-2"
                    @change="$emit('filtersChanged')"
                  ></v-text-field>
                  <div class="values-separator">To incl.</div>
                  <v-text-field
                    type="number"
                    v-model.number="filter.values[1]"
                    dense
                    :error-messages="filter.errors[1]"
                    hide-details
                    clearable
                    class="ml-2"
                    @change="$emit('filtersChanged')"
                  ></v-text-field>
                </div>
              </template>
              <template v-if="['date_min_days', 'date_max_days'].includes(filter.type)">
                <div class="d-flex align-center">
                  <v-text-field
                    type="number"
                    label="Days before"
                    v-model.number="filter.values[0]"
                    dense
                    :error-messages="filter.errors[0]"
                    hide-details
                    clearable
                    class="mr-2"
                    @change="$emit('filtersChanged')"
                  ></v-text-field>
                  <v-text-field
                    type="number"
                    label="Days after"
                    v-model.number="filter.values[1]"
                    dense
                    :error-messages="filter.errors[1]"
                    hide-details
                    clearable
                    class="mr-2"
                    @change="$emit('filtersChanged')"
                  ></v-text-field>
                </div>
              </template>
              <template v-if="['store_inclusions', 'store_exclusions', 'store_not_inclusions', 'store_not_exclusions'].includes(filter.type)">
                <v-autocomplete
                  v-model="filter.values[0]"
                  :items="appConfig?.stores_open || []"
                  item-text="store_name"
                  item-value="store_key"
                  class="pt-0"
                  hide-details="auto"
                  clearable
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                  @change="$emit('filtersChanged')"
                />
              </template>
            </v-col>
            <v-col cols="2">
              <v-icon class="mx-2 action-btn-danger" @click="handleDeleteFilterClick(filter)">delete</v-icon>
            </v-col>
          </v-row>
        </template>
        <v-row v-if="!staticSet">
          <v-col cols="10" class="pt-0 px-6 pb-3">
            <v-autocomplete
              :key="newFilterKey"
              v-model="newFilter"
              placeholder="Add filter"
              :items="filterOptionsAvailable"
              item-text="label"
              hide-details="auto"
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
              @change="handleNewFilterChanged"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { getOptionsFilterValuesByType } from '@/utils'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'

import { useStockManageStore } from '@/store/pinia/stockManageStore'

export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  props: ['title', 'selectedFilters', 'staticSet', 'optionsKeysLoading'],
  data () {
    return {
      newFilter: null,
      newFilterKey: 0,
      filterOptions: [
        {
          label: 'Active',
          value: 'stock_active',
        },
        {
          label: 'Price',
          value: 'asp',
        },
        {
          label: 'Price (original)',
          value: 'osp',
        },
        {
          label: 'Stock',
          value: 'stock',
        },
        {
          label: 'DC Stock',
          value: 'dc_stock',
        },
        {
          label: 'Inbound',
          value: 'inbounds',
        },
        {
          label: 'Days til inbound',
          value: 'days_until_inbound',
        },
        {
          label: 'Discount',
          value: 'discount',
        },
        {
          label: 'Options include',
          value: 'option_keys_include',
        },
        {
          label: 'SKUs',
          value: 'skus',
        },
        {
          label: 'Store count',
          value: 'stores_included',
        },
        {
          label: 'Frozen',
          value: 'stock_frozen',
        },
        {
          label: 'Allow transfers',
          value: 'stock_allow_transfers',
        },
        {
          label: 'Store included',
          value: 'store_inclusions',
        },
        {
          label: 'Store excluded',
          value: 'store_exclusions',
        },
        {
          label: 'Store not included',
          value: 'store_not_inclusions',
        },
        {
          label: 'Store not excluded',
          value: 'store_not_exclusions',
        },
        {
          label: 'Optimize margin',
          value: 'stock_optimize_margin',
        },
        {
          label: 'Force minimum',
          value: 'stock_force_minimum',
        },
        {
          label: 'Exclude minimum',
          value: 'stock_exclude_minimum',
        },
        {
          label: 'Safe guard',
          value: 'stock_safe_guard',
        },
        {
          label: 'Infinite supply',
          value: 'stock_infinite_supply',
        },
        {
          label: 'Exclude deficit',
          value: 'stock_exclude_deficit',
        },
        {
          label: 'Start date',
          value: 'date_min_days',
        },
        {
          label: 'End date',
          value: 'date_max_days',
        },
      ],
    }
  },
  components: {
    CollapsibleSelect,
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    filterOptionsAvailable () {
      return this.filterOptions.filter(
        o => !this.selectedFilters.map(
          f => f.type
        ).includes(o.value)
      ).filter(
        // option_keys_include filter is added automatically if needed
        o => o.value !== 'option_keys_include'
      )
    },
  },
  methods: {
    handleNewFilterChanged () {
      const defaultValues = this.newFilter === 'stock_active' ? [true] : []
      const values = getOptionsFilterValuesByType(this.newFilter, defaultValues)

      this.$emit('filterAdded', {
        type: this.newFilter,
        values,
        errors: [],
      })
      this.newFilter = null
    },
    handleDeleteFilterClick (filter) {
      this.$emit('filterRemoved', filter)
      this.newFilter = null
      // for some reason new filter autocomplete is filled with removed filter name, so force its re-rendering
      this.newFilterKey++
    },
    handleOptionsSelectFocus () {
      const el = this.$refs.optionsSelect[0]

      el.sortItems(el.orderedItems)
    },
    validateFilters () {
      this.selectedFilters.forEach(filter => {
        this.$set(filter, 'errors', [])

        if (['original_price', 'price', 'stock', 'inbounds'].includes(filter.type)) {
          const val1 = parseInt(filter.values[0])
          const val2 = parseInt(filter.values[1])

          if (val1 <= 0) {
            this.$set(filter.errors, 0, 'Should be > 0')
          } else if (val2 <= 0) {
            this.$set(filter.errors, 1, 'Should be > 0')
          } else if (val1 > val2) {
            this.$set(filter.errors, 1, 'Min should be <= max')
          }
        } else if (['days_util_inbound'].includes(filter.type)) {
          const val1 = parseInt(filter.values[0])
          const val2 = parseInt(filter.values[1])

          if (val1 < 0) {
            this.$set(filter.errors, 0, 'Should be >= 0')
          } else if (val2 < 0) {
            this.$set(filter.errors, 1, 'Should be >= 0')
          } else if (val1 > val2) {
            this.$set(filter.errors, 1, 'Min should be <= max')
          }
        }

        if (filter.type === 'option_keys_include' && this.staticSet) {
          if (!filter.values.length) {
            this.$set(filter.errors, 0, 'Set should contain options')
          }
        }
      })

      return !this.selectedFilters.filter(f => f.errors.length).length
    },
  },
}
</script>

<style lang="scss">
.option-sets-search-filters {
    .values-separator {
        flex: 0 0 70px;
        color: #666;
        font-size: 14px;
        text-align: center;
    }
    .slider-value-from,
    .slider-value-to {
        color: #666;
        font-size: 14px;
    }
}
</style>
