<template>
  <div>
    <h4 class="pl-6">
      <template v-if="title">
        {{ title }}
      </template>
      <template v-else>
        Proposal{{option_key? "s": " overview" }}
      </template>
    </h4>
    <v-row v-if="showOptimisationType" justify="center">
      <v-btn-toggle
        v-model="optimisationType"
        dense
        active-class="bg-gray"
        light
      >
        <v-btn
          v-for="t in ['value', 'pieces', 'all']"
          :value="t"
          :key="t"
          small
          color="white"
        >
          <span class="text-capitalize">{{ t }}</span>
        </v-btn>
      </v-btn-toggle>
    </v-row>
    <v-overlay absolute color="white" :value="!tableData">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-row class="mb-2 px-4">
      <v-col class="text-right">
        <TableSearch @searchChanged="handleLookalikesSearchChanged" />
        <v-icon class="mx-2 action-btn-primary" @click="handleAddClick">add</v-icon>
        <data-export @item-click="downloadTableData" />
      </v-col>
    </v-row>
    <data-tables
      v-if="computedTableData"
      id="proposal-overview-table"
      ref="proposalOverviewTable"
      style="width: 100%"
      :key="listKey"
      :data="computedTableData"
      :page-size="10"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
      :table-props="{ 'row-key': 'markdown_proposal_key' }"
      :filters="tableFilters"
      @filter-change="onFilterChange"
      :expand-row-keys="expandedRows"
    >
      <el-table-column v-if="!option_key" type="expand">
        <template #default="props">
          <MDProjectionGraph
            :isolated-load="true"
            :option_key="props.row.option_key"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-if="!option_key"
        key="option_name"
        prop="option_name"
        label="Name"
        class="pl-6"
        column-key="option_name"
        filter-multiple
        :filtered-value="filters.value ? [filters.value] : []"
        :filters="getColumnFilterValues('computedTableData','option_name')"
        width="220"
      >
        <template slot-scope="scope">
          <OptionDataCell
            :option="getOption(scope.row)"
            :url="getMarkdownPageURL(scope.row.option_key)"
            name-key="option_name"
          />
        </template>
      </el-table-column>
      <el-table-column v-if="option_key" width="20"></el-table-column>
      <el-table-column
        sortable="custom"
        key="markdown_moment_name"
        prop="markdown_moment_name"
        column-key="markdown_moment_name"
        label="MD moment"
        :filters="getColumnFilterValues('computedTableData','markdown_moment_name')"
        filter-multiple
      >
        <template slot-scope="scope">
          <div style="white-space: nowrap">{{scope.row.markdown_moment_name }}</div>
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        key="week"
        prop="week"
        column-key="week"
        label="Week"
        :filters="getColumnFilterValues('computedTableData','week', getWeekName)"
      >
        <template slot-scope="scope">
          {{ getWeekName(scope.row.week) }}
        </template>
      </el-table-column>
      <el-table-column
        key="store_keys"
        prop="store_keys"
        column-key="store_keys"
        label="Stores"
        :filters="getColumnFilterValues('computedTableData','store_keys', getStoreName)"
        width="215"
      >
        <template slot-scope="scope">
          <ChipsDropdown :items="getStoreNames(scope.row.store_keys)" />
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        key="price"
        prop="price"
        label="Price"
      >
        <template slot-scope="scope">
          <v-text-field
            v-model="scope.row.price"
            :prefix="currency"
            class="pt-2"
            dense
            @change="handlePriceChange(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        key="discount"
        prop="discount"
        label="Discount"
      >
        <template slot-scope="scope">
          <v-text-field
            v-model="scope.row.discount"
            class="pt-2"
            suffix="%"
            dense
            @change="handleDiscountChange(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        key="projection"
        prop="projection"
        label="Projection"
      >
        <template slot-scope="scope">
          {{ scope.row.projection }}
        </template>
      </el-table-column>

      <el-table-column
        sortable="custom"
        key="markdown_value"
        prop="markdown_value"
        label="MD value"
      >
        <template slot-scope="scope">
          {{ priceFormatter.format(scope.row.markdown_value) }}
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        key="status"
        prop="status"
        label="Status"
        width="150"
        class="pr-0"
      >
        <template slot-scope="scope">
          <v-autocomplete
            v-model="scope.row.status"
            :items="scope.row.optimization_type === 'pieces' ? piecesStatusList : valueStatusList"
            item-value="value"
            dense
            @change="handleStatusChange(scope.row)"
            class="pt-2"
            :class="scope.row.status"
            style="font-size: 13px"
          >
            <template v-slot:item="{item: status}">
              <span class="text-capitalize" :style="{color: getStatusColor(status.value)}">{{status.value}}</span>
            </template>
          </v-autocomplete>
        </template>
      </el-table-column>
      <el-table-column width="50">
        <template slot-scope="scope">
          <v-icon @click="handleDelete(scope.row)">delete</v-icon>
        </template>
      </el-table-column>
    </data-tables>
    <div ref="bottom"></div>
    <v-dialog
      v-model="customMarkdownDialogVisible"
      persistent
      scrollable
      width="600px">
      <CustomMarkdownDialog @close="onCustomMarkdownAddClose"/>
    </v-dialog>
  </div>
</template>

<script>
import { deleteMarkdownProposal, getMarkdownProposalsForOption, saveMarkdownProposal } from '@/api/markdown_proposals'
import { downloadData } from '@/utils'
import ChipsDropdown from '@/components/ChipsDropdown'
import TableSearch from '@/components/TableSearch'
import columnFilters from '@/mixins/columnFilters'
import OptionDataCell from '@/components/OptionDataCell'
import DataExport from '@/components/DataExport'
import MDProjectionGraph from '@/components/Pricing/MDProjectionGraph'
import CustomMarkdownDialog from '@/components/Pricing/CustomMarkdownDialog'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'PricingProposalOverview',
  mixins: [columnFilters],
  components: {
    CustomMarkdownDialog,
    MDProjectionGraph,
    DataExport,
    OptionDataCell,
    // OptionDataCell,
    // ChartElement,
    ChipsDropdown,
    TableSearch,
  },
  props: {
    title: {
      type: String || null,
      default: null,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    option_key: {
      type: String,
      default: null
    },
    statuses: {
      type: Array,
      default: () => ['accepted', 'planned', 'proposed'],
    },
    showOptimisationType: {
      type: Boolean,
      default: true,
    }
  },
  data () {
    return {
      customMarkdownDialogVisible: false,
      optimisationType: 'value',
      tableData: null,
      tableFilters: [{ prop: '', value: '' }],
      listKey: 0,
      expandedRows: [],
      piecesStatusList: [{ text: 'Accepted', value: 'accepted' }, { text: 'Planned', value: 'planned' }, { text: 'Proposed (pcs)', value: 'proposed' }],
      valueStatusList: [{ text: 'Accepted', value: 'accepted' }, { text: 'Planned', value: 'planned' }, { text: 'Proposed (val)', value: 'proposed' }]
    }
  },
  created () {
    this.loadOverview()
  },
  watch: {
    filters: {
      handler () {
        if (!this.filters.value) {
          this.tableFilters = []
        } else {
          this.optimisationType = 'all'
          this.tableFilters = [{ prop: 'option_name', value: this.filters.value }]
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    stores () {
      return (this.appConfig?.stores || [])
    },
    computedTableData () {
      if (!this.tableData) {
        return []
      }
      if (this.optimisationType === 'all') {
        return this.tableData
      } else if (this.optimisationType === 'value') {
        return this.tableData.filter(row => row.status !== 'proposed' || row.optimization_type === 'value')
      } else if (this.optimisationType === 'pieces') {
        return this.tableData.filter(row => row.status !== 'proposed' || row.optimization_type === 'pieces')
      }
      return this.tableData
    },
    storesNames () {
      return this.stores.reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    priceFormatter () {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.$auth.getUserCurrency().currency,
        minimumFractionDigits: 0,
      })
    },
    currency () {
      return this.$auth.getUserCurrency().symbol
    }
  },
  methods: {
    onCustomMarkdownAddClose (ls) {
      this.customMarkdownDialogVisible = false
      if (!ls) {
        return
      }
      this.loadOverview()
    },
    onFilterChange (f) {
      if (f.option_name && !f.option_name.length) {
        this.$emit('filters-removed')
      }
      this.handleFilterChange('tableFilters', f)
    },
    getMarkdownPageURL (option_key) {
      return {
        name: 'pricing-markdown-page',
        query: {
          option_key
        }
      }
    },
    getOption (row) {
      return {
        option_key: row.option_key,
        option_name: row.option_name,
        image_url: row.image_url,
      }
    },
    handleReplace (newProposal, index) {
      this.$set(this.tableData, index, {
        ...this.tableData[index],
        ...newProposal
      })
    },
    async handleStatusChange (row) {
      const changed = await saveMarkdownProposal(row)
      if (!changed) {
        await this.loadOverview()
        return
      }
      this.handleReplace(changed, this.tableData.indexOf(row))
    },
    async handleDelete (row) {
      try {
        await this.$confirm('Are you sure you want to delete this proposal?', 'Delete proposal', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
        await deleteMarkdownProposal(row)
        this.tableData.splice(this.tableData.indexOf(row), 1)
      } catch (e) {
        console.error(e)
      }
    },
    getStatusColor (status) {
      switch (status) {
      case 'accepted':
        return 'green'
      case 'planned':
        return 'rgb(0, 122, 255)'
      case 'proposed':
        return '#FF9800'
      }
    },
    async handlePriceChange (row) {
      const changed = await saveMarkdownProposal(row)
      if (!changed) {
        await this.loadOverview()
        return
      }
      this.handleReplace(changed, this.tableData.indexOf(row))
    },
    async handleDiscountChange (row) {
      const changed = await saveMarkdownProposal(row)
      if (!changed) {
        await this.loadOverview()
        return
      }
      this.handleReplace(changed, this.tableData.indexOf(row))
    },
    getColumnFilters () {
      return this.getColumnFilterValues('computedTableData', 'option_name')
    },
    getStoreNames (storeKeys) {
      return storeKeys.map(key => this.storesNames[key])
    },
    getStoreName (storeKey) {
      return this.storesNames[storeKey] || ''
    },
    getWeekName (week) {
      week = week.toString()
      // 202251 -> 2022-W51
      if (!week || (week && week.length !== 6)) {
        return week
      }
      const year = week.slice(0, 4)
      const weekNumber = week.slice(4)
      return `${year}-W${weekNumber}`
    },
    async loadOverview () {
      this.tableData = await getMarkdownProposalsForOption(this.option_key || 'all', this.statuses)
      this.$emit('proposals-loaded', this.tableData)
    },
    handleLookalikesSearchChanged (val) {
      this.handleSearchChanged('tableFilters', ['option_name'], val)
    },
    downloadTableData (sep) {
      const data = this.computedTableData.map(row => {
        return {
          ...row,
        }
      })
      downloadData(sep, data, 'proposal_overview')
    },
    handleAddClick () {
      this.customMarkdownDialogVisible = true
    },
  }
}
</script>

<style>

.accepted .v-select__slot > input {
    color: green;
    text-transform: capitalize;
}
.proposed .v-select__slot > input {
    color: #FF9800;
    text-transform: capitalize;
}
.planned .v-select__slot > input {
    color: rgb(0, 122, 255);
    text-transform: capitalize;
}

#proposal-overview-table .v-text-field__slot {
  font-size: 13px!important;
}

</style>
