<template>
  <div class="consolidate-build p-relative">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <!-- Filters -->
    <v-row class="mt-0 pb-2">
      <v-col>
        <h4 class="px-4 py-1">Filters</h4>
      </v-col>
    </v-row>
    <v-col>
      <v-col class="filter-wrapper pb-0">
        <StoresSelectByProperties
          class="stores-select"
          show-stores-select
          :selected-stores="formData.stores"
          :custom-stores="appConfig?.stores_open"
          @search-results-changed="handleStoresSearchResultsChanged"
        />
      </v-col>
      <v-row class="filter-wrapper ma-0 align-center">
        <v-col class="py-0">
          <v-select
            :value="optionsList"
            :items="[optionsList.length ? optionsList[0] : []]"
            label="Options"
            multiple
            chips
            readonly
            small-chips
          >
            <v-icon slot="append" @click="handleOptionsEditClick">edit</v-icon>
            <template v-if="optionsList.length" v-slot:selection>
              <v-chip small outlined>
                <span>{{ optionsList[0] }}</span>
              </v-chip>
              <span v-if="optionsList.length > 1" class="grey--text caption ml-1">
                +{{ optionsList.length - 1 }}
              </span>
            </template>
          </v-select>
        </v-col>
        Or
        <v-col class="py-0">
          <v-autocomplete
            label="Options set"
            v-model="optionSetOperationData"
            :items="existingOptionSets"
            item-text="set_name"
            clearable
            item-value="options_set_key"
            @input="filtersStore.selectedFiltersOptions.options = []"
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
          />
        </v-col>
      </v-row>
      <v-col class="py-0 filter-wrapper">
        <label for="margin-value" class="v-label slider-label">Margin</label>
        <div class="d-flex align-center">
          <div class="v-label mr-3">0</div>
          <v-slider
            v-model="formData.margin"
            id="margin-value"
            thumb-label
            hide-details="auto"
            track-color="#ccc"
            track-fill-color="primary"
            :thumb-size="24"
            :min="0"
            :max="100"
            :step="1"
          />
          <div class="v-label ml-3">100</div>
        </div>
      </v-col>
      <v-row class="ma-0 filter-wrapper">
        <v-col class="py-0 filter-wrapper">
          <div class="d-flex align-center">
            <v-text-field
              v-model.number="formData.keep_min"
              clearable
              type="number"
              label="Keep min"
              hide-details="auto"
              track-color="#ccc"
              :error-messages="!validatesField(formData.keep_min) ?'Must be greater or equal to 0':null"
            />
          </div>
        </v-col>
        <v-col class="py-0 filter-wrapper">
          <div class="d-flex align-center">
            <v-text-field
              type="number"

              v-model.number="formData.keep_max"
              clearable
              label="Keep max"
              hide-details="auto"
              track-color="#ccc"
              :error-messages="!validatesField(formData.keep_max) ?'Must be greater or equal to 0':null"
            />
          </div>
        </v-col>
        <v-col  class="py-0 filter-wrapper">
          <div class="d-flex align-center">
            <v-text-field
              type="number"
              v-model.number="formData.exclude_max"
              clearable
              label="Exclude max"
              hide-details="auto"
              track-color="#ccc"
              :error-messages="!validatesField(formData.exclude_max) ?'Must be greater or equal to 0':null"
            />
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-row class="mb-0">
      <v-col class="text-right pt-8 pb-4">
        <v-btn color="primary" depressed outlined class="mx-4" @click="handleConsolidateClick">Consolidate</v-btn>
      </v-col>
    </v-row>

    <template v-if="consolidateData">
      <!-- Sections -->
      <v-row class="mt-4">
        <v-col class="text-center">
          <v-btn-toggle
            v-model="currentSection"
            mandatory
            dense
            active-class="bg-gray"
            light
          >
            <v-btn
              v-for="(section, index) in sections"
              :key="index"
              :value="section.value"
              small
              color="white"
            >
              <span class="text-capitalize">{{ section.text }}</span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row v-show="currentSection === 'kpis'">
        <v-col>
          <h4 class="px-4 py-1">KPIs</h4>
          <ConsolidateKPITiles :kpi-items="kpis" class="my-2" />
        </v-col>
      </v-row>
      <v-row v-show="currentSection === 'stores'">
        <v-col>
          <h4 class="px-4 py-1">Stores</h4>
          <ConsolidateStores
            :stores-data="consolidateData.stores"
            @selected-row-changed="val => selectedStore = val"
          />
        </v-col>
      </v-row>
      <v-row v-show="currentSection === 'options'">
        <v-col>
          <h4 class="px-4 py-1">Options</h4>
          <ConsolidateOptions
            :options-data="consolidateData.options"
            @selected-row-changed="val => selectedOption = val"
          />
        </v-col>
      </v-row>

      <!-- Grid -->
      <v-row class="mt-6">
        <v-col>
          <h4 class="px-4 py-1">{{ gridsTitle }}</h4>
          <ConsolidateGridsChart
            ref="gridsChart"
            :consolidate-data="consolidateData"
            :selected-option="selectedOption"
            :selected-store="selectedStore"
            @transferRemaining:changed="val => remainderStoreKey = val"
            @userExclusions:updated="handleUserExclusionsUpdated"
            @userInclusions:updated="handleUserInclusionsUpdated"
            @insert-exclusions-click="handleInsertExclusionsClick"
            @build-transfer-click="handleBuildTransferClick"
          />
        </v-col>
      </v-row>
      <v-row v-if="consolidateTransfersData" class="mt-0">
        <v-col>
          <h4 class="px-4 py-1">Consolidate transfers</h4>
          <ConsolidateTransfersTable
            ref="transfersTable"
            :building-transfers="buildingTransfers"
            :transfers-data="consolidateTransfersData"
            :store-keys="formData.stores"
            :exclusions="exclusions"
            :remainder-store-key="remainderStoreKey"
            @type-changed="handleTransfersTypeChanged"
            @accept-transfers-click="handleAcceptTransfersClick"
            @transfers:accepted="handleTransferAccepted"
          />
        </v-col>
      </v-row>
    </template>

    <!-- Insert exclusions confirm dialog -->
    <v-dialog
      :value="exclusionsDialogVisible"
      persistent
      content-class="align-self-start mt-10"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Insert exclusions
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to insert {{ exclusionsNumber }} exclusions?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="exclusionsDialogVisible = false"
              >Cancel
              </v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                @click="handleExclusionsConfirm"
                class="mx-4"
              >Ok
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Accept transfers confirm dialog -->
    <v-dialog
      :value="transfersDialogVisible"
      persistent
      content-class="align-self-start mt-10"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Accept transfers
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to accept the shown transfers?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="transfersDialogVisible = false"
              >Cancel
              </v-btn>
              <v-btn
                color="primary"
                :loading="loading"
                outlined
                @click="handleTransfersConfirm"
                class="mx-4"
              >Ok
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep, round, uniq } from 'lodash'
import Vue from 'vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { excludeOptions, loadConsolidateData, loadConsolidateTransfers } from '@/api/consolidate'
import { getAvailabilityColorClass, getTransfersOverviewData } from '@/utils'
import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'
import ConsolidateTransfersTable from '@/components/Stock/Consolidate/ConsolidateTransfersTable'
import StoresSelectByProperties from '@/components/StoresSelectByProperties.vue'
import ConsolidateKPITiles from '@/components/Stock/Consolidate/ConsolidateKPITiles.vue'
import ConsolidateStores from '@/components/Stock/Consolidate/ConsolidateStores.vue'
import ConsolidateOptions from '@/components/Stock/Consolidate/ConsolidateOptions.vue'
import ConsolidateGridsChart from '@/components/Stock/Consolidate/ConsolidateGridsChart.vue'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default Vue.extend({
  setup () {
    return {
      filtersStore: useFiltersStore(),
      stockTransfersStore: useStockTransfersStore(),
    }
  },
  name: 'ConsolidateBuild',
  components: { StoresSelectByProperties, ConsolidateKPITiles, ConsolidateStores, ConsolidateOptions, ConsolidateGridsChart, ConsolidateTransfersTable },
  data () {
    return {
      loading: false,
      optionSetOperationData: null,
      formData: {
        stores: [],
        margin: 0,
        keep_min: null,
        keep_max: null,
        exclude_max: null,
      },
      currentSection: 'kpis',
      sections: [{
        value: 'kpis',
        text: 'KPIs',
      }, {
        value: 'stores',
        text: 'Stores',
      }, {
        value: 'options',
        text: 'Options',
      }],
      consolidateData: null,
      consolidateTransfersData: null,
      buildingTransfers: false,
      selectedOption: null,
      selectedStore: null,
      userExclusions: null,
      userInclusions: null,
      remainderStoreKey: null,
      exclusionsDialogVisible: false,
      transfersDialogVisible: false,
      optionsListFromNeededTable: null,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    ...mapState(useStoreAndOptionSetsStore, ['optionSets']),
    existingOptionSets () {
      return this.optionSets
    },
    filtersOpened () {
      return this.filtersStore.filtersOpened
    },
    storesMap () {
      return (this.appConfig?.stores || []).reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    optionsList () {
      if (this.optionsListFromNeededTable) {
        return this.optionsListFromNeededTable
      }

      return [...this.filtersStore.selectedFiltersOptions.options]
    },
    margin () {
      return this.formData.margin / 100
    },
    kpis () {
      if (!this.consolidateData) {
        return []
      }

      return [{
        title: 'Combinations',
        value1: this.consolidateData.statistics.combinations_current,
        value2: this.consolidateData.statistics.combinations_reached,
        separator: 'arrow',
      }, {
        title: 'Exclusions | Success %',
        value1: this.consolidateData.statistics.exclusions,
        value2: round(this.consolidateData.statistics.success_rate * 100, 1) + '%',
        colorClass2: getAvailabilityColorClass(this.consolidateData.statistics.success_rate * 100),
        separator: 'pipe',

      }, {
        title: 'Fairstock',
        value: this.consolidateData.statistics.fairstock,
      }, {
        title: 'Missing | Surplus',
        value1: this.consolidateData.statistics.missing,
        value2: this.consolidateData.statistics.surplus,
        separator: 'pipe',
      }, {
        title: 'Availability',
        value1: round(this.consolidateData.statistics.availability_current * 100, 1) + '%',
        value2: round(this.consolidateData.statistics.availability_reached * 100, 1) + '%',
        colorClass1: getAvailabilityColorClass(this.consolidateData.statistics.availability_current * 100),
        colorClass2: getAvailabilityColorClass(this.consolidateData.statistics.availability_reached * 100),
        separator: 'arrow',
      }]
    },
    gridsTitle () {
      const titleMain = 'Grids'
      const titleSuffixParts = []
      let titleSuffix = ''

      if (this.selectedStore) {
        titleSuffixParts.push(this.storesMap[this.selectedStore.store_key])
      }

      if (this.selectedOption) {
        titleSuffixParts.push(this.selectedOption.name)
      }

      if (titleSuffixParts.length) {
        titleSuffix = ` (${titleSuffixParts.join(', ')})`
      }

      return titleMain + titleSuffix
    },
    proposedExclusions () {
      return this.consolidateData?.exclusions
    },
    exclusions () {
      if (!this.proposedExclusions && !this.userExclusions && !this.userInclusions) {
        return null
      }

      const result = cloneDeep(this.proposedExclusions)

      if (this.userExclusions) {
        // Adding to proposed exclusions options user excluded
        Object
          .keys(this.userExclusions)
          .forEach(key => {
            if (result[key]) {
              result[key] = uniq([
                ...result[key],
                ...this.userExclusions[key]
              ])
            } else {
              result[key] = [ ...this.userExclusions[key] ]
            }
          })
      }

      if (this.userInclusions) {
        // Removing from proposed exclusions options user included
        Object
          .keys(this.userInclusions)
          .forEach(key => {
            if (result[key]) {
              result[key] = result[key].filter(storeKey => !this.userInclusions[key].includes(storeKey))
            }
          })
      }

      return result
    },
    exclusionsNumber () {
      if (!this.exclusions) {
        return null
      }

      return Object
        .keys(this.exclusions)
        .reduce((acc, cur) => acc + this.exclusions[cur].length, 0)
    }
  },
  methods: {
    validatesField (field){
      if(typeof field !== 'number'){
        field = parseFloat(field)
      }
      return !field || field > 0
    },
    handleStoresSearchResultsChanged (val) {
      this.formData.stores = Array.from(val)
      this.$emit('selectedStores:changed')
    },
    handleOptionsEditClick () {
      this.filtersStore.setFiltersOpened(true)
      this.filtersStore.setOptionsSearchVisible(true)
    },
    prepareData (data) {
      data.options.data = data.options.data.map(x => ({
        ...x,
        selected: false,
        removed: x.stores_current - x.stores_reached
      }))

      data.stores.data = data.stores.data.map(x => ({
        ...x,
        selected: false,
        store_name: this.storesMap[x.store_key],
        removed: x.options_current - x.options_reached
      }))

      return data
    },
    async handleConsolidateClick () {
      const payload = {
        ...this.formData,
        store_keys: this.formData.stores,
        margin: this.margin,
        filters: {
          option_key: this.optionsList,
        },
      }
      if(this.optionSetOperationData){
        payload.options_set_key = this.optionSetOperationData
      }
      delete payload.stores
      for(const key in this.formData){
        if(!this.validatesField(this.formData[key])){
          return
        }
        if(this.formData[key] === null){
          delete payload[key]
        }
      }
      this.loading = true
      this.buildingTransfers = true
      this.selectedStore = null
      this.selectedOption = null
      this.consolidateTransfersData = null

      if (this.$refs.gridsChart) {
        this.$refs.gridsChart.transfersClicked = false
        this.$refs.gridsChart.showExclusionsWarning = false
        this.$refs.gridsChart.showGridWarning = false
        this.$refs.gridsChart.colsExcluded = []
        this.$refs.gridsChart.rowsExcluded = []
        this.$refs.gridsChart.colsIncluded = []
        this.$refs.gridsChart.rowsIncluded = []
      }

      try {
        const consolidateData = await loadConsolidateData(payload)
        this.consolidateData = Object.freeze(this.prepareData(consolidateData))

        if (this.$refs.transfersTable) {
          this.$refs.transfersTable.transfersBtnDisabled = false
        }

        if (this.$refs.gridsChart) {
          this.$refs.gridsChart.exclusionsBtnDisabled = false
        }
      } finally {
        this.loading = false
        this.buildingTransfers = false
      }

      this.$emit('data-loaded')
    },
    handleUserExclusionsUpdated (exclusions) {
      this.userExclusions = Object.freeze(exclusions)
    },
    handleUserInclusionsUpdated (inclusions) {
      this.userInclusions = Object.freeze(inclusions)
    },
    async handleBuildTransferClick () {
      const view = this.$refs.transfersTable?.lowerCaseType || 'combinations'

      if (view === 'overview') {
        this.buildTransfersOverview()
      } else {
        this.buildTransfers(view)
      }
    },
    async buildTransfers (view) {
      this.loading = true
      this.buildingTransfers = true

      const payload = {
        store_keys: this.formData.stores,
        margin: this.margin,
        exclusions: this.exclusions,
        view,
      }

      if (this.remainderStoreKey) {
        payload.remainder_store_key = this.remainderStoreKey
      }

      try {
        const transfersData = await loadConsolidateTransfers(payload)
        this.consolidateTransfersData = Object.freeze(transfersData)

        if (this.$refs.transfersTable) {
          this.$refs.gridsChart.exclusionsBtnDisabled = false
          this.$refs.transfersTable.transfersBtnDisabled = false
        }
      } finally {
        this.loading = false
        this.buildingTransfers = false
      }
    },
    async buildTransfersOverview () {
      this.loading = true
      this.buildingTransfers = true

      const payload = {
        store_keys: this.formData.stores,
        margin: this.margin,
        exclusions: this.exclusions,
      }

      if (this.remainderStoreKey) {
        payload.remainder_store_key = this.remainderStoreKey
      }

      try {
        const payloadSend = {
          ...payload,
          view: 'send'
        }
        const transfersDataSend = await loadConsolidateTransfers(payloadSend)

        const payloadReceive = {
          ...payload,
          view: 'receive'
        }
        const transfersDataReceive = await loadConsolidateTransfers(payloadReceive)
        this.consolidateTransfersData = Object.freeze(getTransfersOverviewData(transfersDataSend, transfersDataReceive))

        if (this.$refs.transfersTable) {
          this.$refs.gridsChart.exclusionsBtnDisabled = false
          this.$refs.transfersTable.transfersBtnDisabled = false
          this.$refs.transfersTable.updateTotalTableWidth()
        }
      } finally {
        this.loading = false
        this.buildingTransfers = false
      }
    },
    handleInsertExclusionsClick () {
      this.exclusionsDialogVisible = true
    },
    handleAcceptTransfersClick () {
      this.transfersDialogVisible = true
    },
    handleTransferAccepted () {
      this.$refs.gridsChart.showExclusionsWarning = true
      this.$emit('transfers:accepted')
    },
    async handleExclusionsConfirm () {
      this.loading = true

      const payload = {
        store_keys: this.formData.stores,
        margin: this.margin,
        exclusions: this.exclusions,
      }

      try {
        await excludeOptions(payload)
        this.exclusionsDialogVisible = false
        this.$refs.gridsChart.exclusionsBtnDisabled = true
      } finally {
        this.loading = false
      }
    },
    async handleTransfersConfirm () {
      this.loading = true

      const payload = {
        store_keys: this.formData.stores,
        margin: this.margin,
        exclusions: this.exclusions,
        accept: true,
      }

      if (this.remainderStoreKey) {
        payload.remainder_store_key = this.remainderStoreKey
      }

      try {
        await loadConsolidateTransfers(payload)
        this.transfersDialogVisible = false
        this.$refs.transfersTable.tableData.forEach(item => {
          this.$set(item, 'accepted', true)
        })
        this.$refs.gridsChart.showExclusionsWarning = true
        this.$refs.transfersTable.transfersBtnDisabled = true
        this.$emit('transfers:accepted')
      } finally {
        this.loading = false
      }
    },
    async handleTransfersTypeChanged () {
      const view = this.$refs.transfersTable?.lowerCaseType || 'combinations'
      if (view === 'overview') {
        this.buildTransfersOverview()
      } else {
        this.buildTransfers(view)
      }
    },
  },
  watch:{
    'filtersStore.selectedFiltersOptions.options': {
      handler (val){
        if (val?.length){
          this.optionSetOperationData = null
        }
      }
    },
    selectedStoresInput: {
      handler (val) {
        this.formData.stores = val.slice()
      }
    },
    /* filtersOpened (newValue) {
      if (!newValue) {
        this.handleConsolidateClick()
      }
    } */
  },
  mounted () {
    useStoreAndOptionSetsStore().loadOptionSets().catch(console.error)
  },
})
</script>

<style lang="scss">
.consolidate-build {
  .filter-wrapper {
    max-width: 400px;
  }
}
</style>
