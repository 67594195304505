<template>
  <div class="stockProposal" :key="proposalsKey">
    <v-card v-if="loaded" :key="proposalsKey">
      <v-tabs v-model="activeTab" grow>
        <v-tabs-slider />
        <v-tab>Timeline</v-tab>
        <v-tab>Clusters</v-tab>
        <v-tab>Capacity</v-tab>
        <v-tab>Factors</v-tab>
        <v-tab>Transfers</v-tab>
        <v-tab>Value</v-tab>
        <v-tab>Log</v-tab>
        <v-tab v-if="prepacks">Prepacks</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab" class="mt-2 pb-6">
        <v-tab-item :transition="false" :reverse-transition="false">
          <OptionTimeline
            :gettingProposal="gettingProposal"
            :key="proposalTableKey"
            @settingLoaded="applyRules" @settingUpdated="applyRules" @settingDeleted="applyRules" />
        </v-tab-item>
        <v-tab-item eager :transition="false" :reverse-transition="false">
          <Clusters ref="clusters" :option-key="optionKey" />
          <div class="mt-8 mx-5 text-right">
            <v-btn color="primary" depressed outlined
              @click="$router.push({ name: 'stock-manage', query: { tab: 'clusters' } })">All clusters</v-btn>
          </div>
        </v-tab-item>
        <v-tab-item eager :transition="false" :reverse-transition="false">
          <CapacityOptionDynamic :option-key="optionKey" :skus="skus" @capacityUpdated="handleCapacityUpdated" />
          <GroupTable :option-key="optionKey" :skus="skus" />
          <BlocksTable :option-key="optionKey" />
          <div class="mt-8 mx-5 text-right">
            <v-btn color="primary" depressed outlined
              @click="$router.push({ name: 'stock-manage', query: { tab: 'capacity' } })">All capacity</v-btn>
          </div>
        </v-tab-item>
        <v-tab-item eager :transition="false" :reverse-transition="false">
          <Factors :option-key="optionKey" />
          <div class="mt-8 mx-5 text-right">
            <v-btn color="primary" depressed outlined
              @click="$router.push({ name: 'stock-manage', query: { tab: 'factors' } })">All factors</v-btn>
          </div>
        </v-tab-item>
        <v-tab-item eager :transition="false" :reverse-transition="false">
          <StockManageTransfers
            :option-key="optionKey"
            :store-sets="storeSets"
          />
          <div class="mt-8 mx-5 text-right">
            <v-btn color="primary" depressed outlined
              @click="$router.push({ name: 'stock-manage', query: { tab: 'transfers' } })">All transfers</v-btn>
          </div>
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false">
          <OptionValue @changed="handleOptionValueChanged" />
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false">
          <StockOptionLog :option-key="optionKey" />
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false">
          <OptionPrepacks @changed="handlePrepacksChanged" :prepacks="prepacks" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card class="mt-6 pa-4" min-height="350px">
      <v-row class="justify-space-between px-4 pt-3 pb-6 align-center">
        <h4>Stores</h4>
        <v-btn-toggle
          v-model="storeChartIndex"
          dense
          active-class="bg-gray"
        >
          <v-btn
            small
            :key="0"
          >
            <span class="text-capitalize">Proposals</span>
          </v-btn>
          <v-btn
            small
            :key="1"
          >
            <span class="text-capitalize">Goal</span>
          </v-btn>
        </v-btn-toggle>

        <DataExport
          style="width: 50px"
          @item-click="
            (sep) => downloadChartData(sep, $refs[storeChartRefs[storeChartIndex]], `${storeChartNames[storeChartIndex]}_${$route.query.option_key}`)
          "
        />
      </v-row>
      <v-overlay absolute color="white" :value="!loaded">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <v-carousel
        hide-delimiters
        v-model="storeChartIndex"
        height="auto" delimiter-icon="circle" :perPage="1" light hide-delimiter-background
        :show-arrows="false"
      >
        <v-carousel-item eager>
          <StackedBar ref="stackedBar" v-if="loaded" />
        </v-carousel-item>
        <v-carousel-item eager>
          <proposal-graph v-if="loaded && stock_extra" ref="stores_proposal_graph" :params="{}"
            :chartData="stock_extra" :padding="false" :fixedHeight="false"></proposal-graph>
        </v-carousel-item>
      </v-carousel>

    </v-card>

    <v-card class="mt-6 py-4">
      <h4 class="mb-6 px-4">Distribution</h4>
      <v-overlay absolute color="white" :value="!loaded">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <OverviewTable v-if="loaded" :key="overviewTableKey" />
    </v-card>

    <v-card class="mt-8 mb-6 py-4" min-height="350px">
      <v-row class="align-center ma-0 justify-space-between">

        <h4 class=" px-4">Stores detailed</h4>
        <v-btn-toggle
          v-model="storeDetailedIndex"
          dense
          active-class="bg-gray"
        >
          <v-btn
            small
            :key="0"
          >
            <span class="text-capitalize">Proposals</span>
          </v-btn>
          <v-btn
            small
            :key="1"
          >
            <span class="text-capitalize">Goal</span>
          </v-btn>
          <v-btn
            small
            :key="2"
          >
            <span class="text-capitalize">Distribution</span>
          </v-btn>
          <v-btn
            small
            :key="3"
          >
            <span class="text-capitalize">Transfers</span>
          </v-btn>
        </v-btn-toggle>
        <div style="width: 147px"></div>
      </v-row>

      <v-overlay absolute color="white" :value="!loaded">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <v-carousel
        hide-delimiters
        v-model="storeDetailedIndex"
        height="auto" delimiter-icon="circle" :perPage="1" light hide-delimiter-background
        :show-arrows="false"
      >
        <v-carousel-item eager>
          <ProposalTable v-if="loaded" :key="proposalTableKey" @store-clicked="handleStoreClicked" />
        </v-carousel-item>
        <v-carousel-item eager>
          <slot name="goal"></slot>
        </v-carousel-item>
        <v-carousel-item eager>
          <slot name="distribution"></slot>

        </v-carousel-item>
        <v-carousel-item eager>
          <slot name="transfers"></slot>

        </v-carousel-item>
      </v-carousel>

    </v-card>

    <v-dialog :value="storePredictionsDialogVisible" persistent scrollable content-class="align-self-start mt-14"
      width="1200px" max-width="90%">
      <v-card v-if="storeToViewPredictions" class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Store predictions
          <v-icon class="mx-2">arrow_forward</v-icon>
          {{ storeToViewPredictions.Store }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text--primary">
          <div class="p-relative pt-4" style="min-height: 150px;">
            <v-overlay absolute color="white" :value="storePredictionsChartLoading">
              <v-progress-circular color="primary" indeterminate size="64" width="2" />
            </v-overlay>
            <h4 class="mb-4">Prediction</h4>
            <ChartElement v-if="storePredictionsChartData" ref="storePredictionChart" title="store_prediction"
              :params="{}" :chartData="storePredictionsChartData" :padding="false" :fixedHeight="false" />
          </div>
          <div class="p-relative pt-4" style="min-height: 150px;">
            <v-overlay absolute color="white" :value="storePredictionsTableLoading">
              <v-progress-circular color="primary" indeterminate size="64" width="2" />
            </v-overlay>
            <h4 class="mb-4">Feature impact (next 7 days)</h4>
            <ShapValueTable v-if="shapValueExplanation" :tableData="shapValueExplanation" />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn color="primary" depressed text @click="handlePredictionsDialogCloseClick">Close</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { serializeObjToQuery } from '@/utils'
import * as optionsApi from '@/api/options'
import ProposalTable from './ProposalTable.vue'
import OverviewTable from './ProposalOverviewTable.vue'
import StackedBar from './StackedBar.vue'
import OptionTimeline from './OptionTimeline.vue'
import OptionValue from './OptionValue.vue'
import ChartElement from '@/chart/ChartElement.vue'
import ShapValueTable from './ShapValueTable.vue'
import OptionPrepacks from './OptionPrepacks'
import ProposalGraph from '../Shop/ProposalGraph'
import Clusters from '@/components/Assortment/Clusters.vue'
import CapacityOptionDynamic from '@/components/Assortment/LowLevelCapacityOption.vue'
import Factors from '@/components/Assortment/Factors.vue'
import GroupTable from '@/components/Assortment/GroupTable.vue'
import BlocksTable from '@/components/Assortment/BlocksTable.vue'
import StockManageTransfers from '@/components/Transfers/StockManageTransfers.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'
import { downloadChartData } from '@/variables'
import DataExport from '@/components/DataExport.vue'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

import { useArticleStore } from '@/store/pinia/stockArticlesStore'
import StockOptionLog from '@/components/Proposal/StockOptionLog.vue'

import { useStockManageStore } from '@/store/pinia/stockManageStore'
import { getSKUNames } from '@/api/skusAPI'

export default {
  setup () {
    return {
      articlesStore: useArticleStore(),
      stockManageStore: useStockManageStore(),
      proposalStore: useStockProposalStore()
    }
  },
  props: {
    baseOptionKey: String,
  },
  data () {
    return {
      storePredictionsChartAbortController: null,
      storePredictionsTableAbortController: null,
      activeTab: 0,
      tabs: ['timelines', 'clusters', 'capacity', 'factors', 'transfers', 'value', 'log', 'prepacks'],
      skus: [],
      proposalsKey: 0,
      loaded: false,
      gettingProposal: false,
      proposalLoaded: false,
      storeToViewPredictions: null,
      storePredictionsDialogVisible: false,
      storePredictionsChartLoading: false,
      storePredictionsTableLoading: false,
      storePredictionsChartData: null,
      shapValueExplanation: null,

      // to force color updates in tables
      overviewTableKey: 0,
      proposalTableKey: 0,

      // keep shift feature only in the code for now
      daysToShift: 0,
      shiftingDates: false,

      // Store charts
      storeChartIndex: 0,
      storeDetailedIndex: 0,
      storeChartRefs: ['stackedBar', 'stores_proposal_graph'],
      storeChartNames: ['store_bar_1', 'store_bar_2'],
    }
  },
  components: {
    StockOptionLog,
    DataExport,
    StockManageTransfers,
    BlocksTable,
    GroupTable,
    Factors,
    CapacityOptionDynamic,
    Clusters,
    ProposalGraph,
    OptionPrepacks,
    ProposalTable,
    StackedBar,
    OverviewTable,
    OptionTimeline,
    OptionValue,
    ChartElement,
    ShapValueTable,
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    ...mapState(useStoreAndOptionSetsStore, ['storeSets']),
    stores () {
      return (this.appConfig?.stores.slice() || [])
    },
    optionKey () {
      return this.$route.query.option_key.toString()
    },
    prepacks () {
      return this.proposalStore.prepacks
    },
    stock_extra () {
      const stock_extra = this.proposalStore.stock_extra
      if (!stock_extra) {
        return null
      }
      const storeList = stock_extra.store_key
      const storeNameList = []
      for (let i = 0; i < storeList?.length || 0; i++) {
        const store = this.stores.find(x => x.store_key === storeList[i])
        if (store !== undefined) {
          storeNameList.push(store.store_name.substring(0, 13))
        } else {
          storeNameList.push(storeList[i])
        }
      }
      return { ...stock_extra, store_names: storeNameList }
    }
  },
  watch: {
    '$route.query' (newQuery, oldQuery) {
      if(!this.baseOptionKey || this.baseOptionKey === newQuery.option_key){
        this.reloadOptionProposal(newQuery, oldQuery)
      }
    },
    activeTab (val) {
      if (this.$route.query.tab === this.tabs[val]) {
        return
      }
      const newRoute = { ...this.$route, query: { ...this.$route.query, tab: this.tabs[val] } }
      this.$router.replace(newRoute)
    },
  },
  methods: {
    downloadChartData,
    reloadOptionProposal (newQuery, oldQuery){
      this.queryParams = {...this.$route.query}
      const onlyTabChanged = JSON.stringify(newQuery) === JSON.stringify({ ...oldQuery, tab: newQuery.tab })
      if (onlyTabChanged) {
        return
      }
      if (this.loaded) {
        this.loaded = false
        this.proposalStore.loadProposalSettingsGetLatest( {
          route: serializeObjToQuery(this.$route.query),
        })
          .then((settings) => {

            setTimeout(()=> {
              this.proposalStore.loadProposal( {
                route: this.queryParams,
                settings,
              })
                .then(() => {
                  this.proposalsKey++
                  this.loaded = true
                }).catch(()=> {
                  this.$emit('loaded-failed')
                })
            }, 100)
          })
          .catch(console.log)
      }
    },
    handleClusterStoreSetClick (storeSetName) {
      this.$refs.clusters.handleClusterStoreSetClick(storeSetName)
    },
    loadPage () {
      const loadRules = this.proposalStore.setRules()
      const loadDefaults = this.proposalStore.setDefaults()
      const promiseList = [
        useStoreAndOptionSetsStore().loadStoreSets().catch(console.error),
        useStoreAndOptionSetsStore().loadOptionSets(),
      ]

      if (!this.proposalStore.rulesLoaded) {
        promiseList.push(loadRules)
      }

      if (!this.proposalStore.defaultsLoaded) {
        promiseList.push(loadDefaults)
      }

      Promise
        .all(promiseList)
        .then(() => {
          this.stockManageStore.getEntitiesSets()
          this.proposalStore.loadProposalSettingsGetLatest( {
            route: serializeObjToQuery(this.queryParams),
          })
            .then((settings) => {

              setTimeout(()=> {
                this.proposalStore.loadProposal( {
                  route: this.queryParams,
                  settings,
                })
                  .then(async () => {
                    this.loaded = true
                    this.proposalsKey++
                    await this.loadSKUNames()
                    this.$emit('loaded')
                    // this.$refs.stackedBar.drawGraph()
                  })
              }, 100)
            })
        })
        .catch(console.error)
    },
    loadClusters () {
      this.$refs.clusters.loadClusters()
    },
    async loadSKUNames () {
      this.skus = getSKUNames()
    },
    handlePrepacksChanged () {
      setTimeout(()=> {
        this.proposalStore.loadProposal( {
          route: this.queryParams
        })
          .then(() => {
            this.$refs.stackedBar.drawGraph()
            this.gettingProposal = false
            this.proposalLoaded = true
            this.overviewTableKey++
            this.proposalTableKey++
            this.loaded = true
          }).catch(console.error)
      }, 100)
    },
    applyRules () {
      try {
        this.gettingProposal = true
        this.proposalStore.loadProposal({
          route: this.queryParams,
        })
          .then(() => {
            this.$refs.stackedBar.drawGraph()
            this.proposalLoaded = true
            this.overviewTableKey++
            this.proposalTableKey++
            this.proposalStore.loadProposalSettingsGetLatest({
              route: serializeObjToQuery(this.queryParams),
            })
            this.gettingProposal = false
            this.proposalTableKey++

            setTimeout(() => {
              this.proposalLoaded = false
            }, 1500)
          })
      }catch (e) {
        console.log(e)

        this.gettingProposal = false
        this.proposalTableKey++
        this.proposalLoaded = false
      }
    },
    handleCapacityUpdated () {
      this.applyRules()
      this.$emit('capacityUpdated')
    },
    handleOptionValueChanged () {
      this.articlesStore.setSavingOptionSettings(true)
      optionsApi
        .saveOptionSettings(this.articlesStore.articleSettings, this.$route.query)
        .then(() => {
          this.proposalStore.loadProposal( {
            route: this.queryParams,
          }).then(()=> this.$refs.stackedBar?.drawGraph())
        })
        .finally(() => {
          this.articlesStore.setSavingOptionSettings(false)
        })
    },
    async handleStoreClicked (store) {
      const version = localStorage.getItem('logicApiVersion')

      this.storeToViewPredictions = store
      this.storePredictionsDialogVisible = true
      this.storePredictionsChartLoading = true
      this.storePredictionsTableLoading = true

      if (this.storePredictionsChartAbortController) {
        this.storePredictionsChartAbortController.abort()
      }

      if (this.storePredictionsTableAbortController) {
        this.storePredictionsTableAbortController.abort()
      }

      const projectionDataPayload = {
        store_keys: [store.storeKey],
        option_key: this.optionKey,
        include_dc_stock: false,
        version,
        format: 'doa',
      }

      const explainDataPayload = {
        store_keys: [store.storeKey],
        option_keys: [this.optionKey],
        version,
        format: 'doa',
      }

      this.storePredictionsChartAbortController = new AbortController()
      this.storePredictionsTableAbortController = new AbortController()

      optionsApi
        .getStoreProjectionChartData(projectionDataPayload, this.storePredictionsChartAbortController.signal)
        .then(response => {
          this.storePredictionsChartData = response
          this.storePredictionsChartLoading = false
        })

      optionsApi
        .getExplain(explainDataPayload, this.storePredictionsTableAbortController.signal)
        .then(response => {
          this.shapValueExplanation = response
          this.storePredictionsTableLoading = false
        })
    },
    handlePredictionsDialogCloseClick () {
      this.storePredictionsDialogVisible = null
      this.storePredictionsChartData = null
      this.shapValueExplanation = null
    },
  },
  mounted () {
    this.queryParams = {...this.$route.query}
    if (this.$route.query.tab) {
      this.activeTab = this.tabs.indexOf(this.$route.query.tab)
    }
  },
  created () {
    this.loadPage()
  }
}
</script>

<style lang="scss">
.stockProposal {}
</style>
