<template>
  <div class="dummy-skus">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <template>
      <v-row>
        <v-col class="pb-0">
          <h4 class="mt-2 pa-4 pb-0">Selected SKUs</h4>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="3">
          <v-autocomplete
            :menu-props="{
              left: true,
            }"
            color="primary"
            @change="handleSkusRangeChange"
            item-text="sku_range_name"
            item-value="sku_range_key"
            label="Import SKU range"
            hide-details="auto"
            :value="currentSkuRange"
            :items="skuRanges"
            class="mt-2 ml-4"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right px-8">
          <v-icon
            class="mx-2 rotate270 action-btn-primary"
            :disabled="moveUpDisabled"
            @click="handleSkuMoveUpClick"
          >last_page</v-icon>
          <v-icon
            class="mx-2 rotate90 action-btn-primary"
            :disabled="moveDownDisabled"
            @click="handleSkuMoveDownClick"
          >last_page</v-icon>
          <v-icon class="mx-2 action-btn-primary" @click="handleAddSkuClick">add</v-icon>
          <v-icon class="mx-2 action-btn-danger" :disabled="!selectedSkuRows[0]" @click="handleDeleteSkuClick">delete</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="p-relative">
          <data-tables
            style="width: 100%"
            v-if="dummySkus"
            class="mb-4"
            :data="dummySkus"
            :page-size="dummySkus.length"
            :pagination-props="{ pageSizes: [dummySkus.length], hideOnSinglePage: true }"
            :table-props="{ 'row-key': 'name' }"
            @row-click="handleRowClick">
            <el-table-column prop="timeline_key" width="55">
              <template slot-scope="scope">
                <v-simple-checkbox
                  color="primary"
                  dense
                  hide-details
                  class="mt-0 ml-4 pt-0"
                  :value="scope.row.selected"
                  :ripple="false"
                  @input="handleSelectRowChange($event, scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column label="Rank" label-class-name="ml-4" width="120px">
              <template slot-scope="scope">
                <div class="ml-4">
                  {{ scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="SKU name">
              <template slot-scope="scope">
                <div class="sku-name-select-wrapper">
                  <v-autocomplete
                    v-model="scope.row.name"
                    :items="getSkusOptionsAvailable(scope.row.name)"
                    :error-messages="scope.row.name === null ? 'Required' : null"
                    dense
                    class="pt-0 my-1 mr-4 text-body-2"
                    hide-details
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                    @change="handleSkuChange"
                  />
                </div>
              </template>
            </el-table-column>
          </data-tables>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import * as dummiesApi from '@/api/dummies'
import { getSkuRanges } from '@/api/skusAPI'

export default {
  components: {},
  props: {
    dummy: {
      type: Object,
      default: () => ({}),
    },
    skus: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      loading: false,
      similarOptions: [],
      lookalikeOptions: [],
      similarOptionSelected: null,
      lookalikeOptionSelected: null,
      dummySkus: [],
      skuRanges: [],
    }
  },
  async beforeMount () {
    this.skuRanges = getSkuRanges()
  },
  computed: {
    currentSkuRange () {
      return this.getSkuRangeByKey(this.dummy.sku_range_key) || {}
    },
    selectedSkuRows () {
      return this.dummySkus.filter(r => r.selected)
    },
    moveUpDisabled () {
      return !this.selectedSkuRows[0] ||
        !this.allSkusValid ||
        this.dummySkus.indexOf(this.selectedSkuRows[0]) === 0
    },
    moveDownDisabled () {
      return !this.selectedSkuRows[0] ||
        !this.allSkusValid ||
        this.dummySkus.indexOf(this.selectedSkuRows[0]) === this.dummySkus.length - 1
    },
    usedSkus () {
      return this.dummySkus.map(ds => ds.name)
    },
    skusOptionsAvailable () {
      return this.skus?.filter(s => !this.usedSkus.includes(s))
        ?.map(s => ({
          text: s,
          value: s,
        })) || []
    },
    allSkusValid () {
      return this.dummySkus.map(s => s.name).every(n => n !== null)
    }
  },
  watch: {
    dummy: {
      handler (val) {
        if (!val) {
          return
        }

        this.loading = true
        this
          .getSimilarAndLookalikeOptions()
          .finally(() => {
            this.loading = false
          })

        this.dummySkus = this.dummy.sku_names.map((sn) => ({
          name: sn,
        }))
      },
      immediate: true,
    }
  },
  methods: {
    handleSkusRangeChange (val) {
      const range = this.getSkuRangeByKey(val)
      if (!range) {
        return
      }
      this.dummySkus = range.sku_names.map(s => ({
        name: s,
      }))
      this.dummy.sku_range_key = range.sku_range_key

      this.loading = true
      this
        .saveDummy()
        .then(updatedDummy => {
          this.$emit('dummy-saved', updatedDummy)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getSkuRangeByKey (key) {
      return this.skuRanges.find(r => r.sku_range_key === key)
    },
    getSimilarAndLookalikeOptions () {
      const payload = {
        filters: this.dummy.properties,
        lookalikes: this.dummy.lookalike_option_keys,
      }

      return dummiesApi
        .getSimilarOptions(payload)
        .then(data => {
          this.similarOptions = data.similar_products
          this.lookalikeOptions = data.lookalikes
        })
    },
    getSkusOptionsAvailable (selectedSkuName) {
      if (!selectedSkuName) {
        return this.skusOptionsAvailable
      }

      return [
        ...this.skusOptionsAvailable,
        {
          text: selectedSkuName,
          value: selectedSkuName,
        }
      ]
    },
    saveDummy () {
      let payload = {
        ...this.dummy,
        sku_names: this.dummySkus.map(s => s.name || s).filter(s => s)
      }

      payload = {
        ...payload,
      }

      this.loading = true

      return dummiesApi
        .saveDummy(payload)
        .finally(() => {
          this.loading = false
        })
    },
    handleSelectRowChange (val, row) {
      this.selectedSkuRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
    },
    handleRowClick (row, col, event) {
      if(col.property === 'name'){
        return
      }
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSkuMoveUpClick () {
      const selectedIndex = this.dummySkus.indexOf(this.selectedSkuRows[0])
      const tmp = this.dummySkus[selectedIndex - 1]

      if (selectedIndex - 1 >= 0) {
        this.$set(this.dummySkus, selectedIndex - 1, this.selectedSkuRows[0])
        this.$set(this.dummySkus, selectedIndex, tmp)

        this.loading = true
        this
          .saveDummy()
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleSkuMoveDownClick () {
      const selectedIndex = this.dummySkus.indexOf(this.selectedSkuRows[0])
      const tmp = this.dummySkus[selectedIndex + 1]

      this.$set(this.dummySkus, selectedIndex + 1, this.selectedSkuRows[0])
      this.$set(this.dummySkus, selectedIndex, tmp)

      this.loading = true
      this
        .saveDummy()
        .finally(() => {
          this.loading = false
        })
    },
    handleDeleteSkuClick () {
      const existingRowDeleted = this.selectedSkuRows[0].name !== null
      const indexToDelete = this.dummySkus.indexOf(this.selectedSkuRows[0])

      this.dummySkus.splice(indexToDelete, 1)

      if (existingRowDeleted) {
        this.loading = true
        this
          .saveDummy()
          .then(updatedDummy => {
            this.$emit('dummy-saved', updatedDummy)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleAddSkuClick () {
      this.dummySkus.push({
        name: null,
      })
    },
    handleSkuChange (value) {
      if(!value){
        return
      }
      this.loading = true
      this
        .saveDummy()
        .then(updatedDummy => {
          this.$emit('dummy-saved', updatedDummy)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
}
</script>

<style lang="scss">
.dummy-skus {
    .sku-name-select-wrapper {
        width: 220px;
    }
}
</style>
