<template>
  <div class="chart-container" style="position: relative; height: 400px;">
    <canvas ref="chartEl" class=""></canvas>
  </div>
</template>

<script>
import { Chart } from 'chart.js/auto'

export default {
  props: {
    chartData: {
      type: Object,
      default: () => ({}),
    },
    ros: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    this.chart = null

    return {
    }
  },
  computed: {
  },
  methods: {
    plotGraph () {
      if (this.chart) {
        this.chart.destroy()
      }

      const impactBackgroundColors = this.chartData.bucket_shap_value.map(v => v < 0 ? 'rgba(255, 87, 33, 0.2)' : 'rgba(76, 175, 80, 0.2)')
      const impactBorderColors = this.chartData.bucket_shap_value.map(v => v < 0 ? '#FF5721' : 'rgb(76, 175, 80)')

      const datasets = [{
        label: 'RoS impact',
        data: this.chartData.bucket_shap_value,
        backgroundColor: impactBackgroundColors,
        borderColor: impactBorderColors,
        borderWidth: 2,
        xAxisID: 'x',
        yAxisID: 'y1',
      }, {
        label: 'Options#',
        type: 'line',
        showLine: false,
        data: this.chartData.bucket_options,
        backgroundColor: '#FF9800',
        borderColor: '#FF9800',
        borderWidth: 3,
        xAxisID: 'x',
        yAxisID: 'y2',
      }]

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            display: true,
            labels: {
              usePointStyle: true,
            }
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Price',
              font: {
                size: 14,
              },
            },
            offset: true,
          },
          y1: {
            position: 'left',
            min: -0.2,
            max: 0.2,
            stepSize: 0.1,
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            title: {
              display: true,
              text: 'RoS impact',
              font: {
                size: 14,
              },
            },
          },
          y2: {
            position: 'right',
            min: -20,
            max: 20,
            stepSize: 10,
            title: {
              display: true,
              text: 'Options#',
              font: {
                size: 14,
              },
            },
            border: {
              display: false,
            },
          },
        },
      }

      this.chart = new Chart(this.$refs.chartEl, {
        type: 'bar',
        data: {
          datasets,
          labels: this.chartData.bucket_price,
        },
        options,
      })

      this.chart.update()
    },
  },
  watch: {
    chartData: function () {
      this.plotGraph()
    }
  }
}
</script>
<style>
</style>
