import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'

export function requestPicktickets (payload) {
  const url = baseURLs.tsqlAPI + '/logistics_picktickets'

  return axios
    .post(url, payload)
    .then(response => response.data)
    .catch(error => {
      console.log(error)
    })
}
