<template>
  <div id="google-btns-div"></div>
</template>

<script>
import authService from '@/auth/Auth'
import { initializeGoogleOneTap } from '@/auth/googleAuth'

export default {
  name: 'GoogleButton',
  methods: {
    async onLoginSuccess (response) {
      try {
        await this.sendTokenToServer(response.credential)
        this.$emit('success')
      } catch (e) {
        this.$emit('error', 'Failed to login', e)
        console.error('Failed to login', e)
      }
    },
    async sendTokenToServer (token) {
      return authService.googleLogin(token)
    }
  },
  async created () {
    initializeGoogleOneTap(r => this.onLoginSuccess(r))
  }
}
</script>

<style lang="scss">
  div#google-btns-div > div > div > div {
    //text-transform: uppercase!important;
    font-size: 15px!important;
    font-weight: 500!important;
    letter-spacing: 0.0892857143em!important;
    height: 40px!important;
  }
  #google-btns-div {
    svg {
      width: 21px;
      height: 21px;
    }
  }
</style>
