<template>
  <div class="stock-picktickets-criteria">
    <h6 class="mb-6 text-subtitle-1 font-weight-medium">Filters</h6>

    <!-- stores -->
    <v-row>
      <v-col cols="2" class="pt-0">
        <collapsible-select
          :items="appConfig.stores"
          v-model="fromStores"
          label="From Stores"
          item-text="store_name"
          item-value="store_key"
          show-select-all
          autocomplete
          hide-details
          @blur="handleStoresSelected"
        />
      </v-col>
      <v-col cols="2" class="pt-0">
        <v-autocomplete
          :menu-props="{
            left: true,
          }"
          :items="storeSets"
          label="Select store set to prefill stores list"
          item-text="set_name"
          item-value="stores_set_key"
          class="pt-0"
          return-object
          hide-details
          @change="handleFromStoreSetChanged"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="pt-4">
        <collapsible-select
          :items="appConfig.stores"
          v-model="toStores"
          item-text="store_name"
          label="To Stores"
          autocomplete
          show-select-all
          hide-details
          item-value="store_key"
          @blur="handleStoresSelected"
        />
      </v-col>
      <v-col cols="2" class="pt-4">
        <v-autocomplete
          :menu-props="{
            left: true,
          }"
          :items="storeSets"
          label="Select store set to prefill stores list"
          item-text="set_name"
          item-value="stores_set_key"
          class="pt-0"
          return-object
          hide-details
          @change="handleToStoreSetChanged"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <v-select
          :value="optionsList"
          :items="[optionsList.length ? optionsList[0] : []]"
          label="Options"
          multiple
          chips
          readonly
          small-chips
        >
          <v-icon slot="append" @click="handleOptionsEditClick">edit</v-icon>
          <template v-if="optionsList.length" v-slot:selection>
            <v-chip small outlined>
              <span>{{ optionsList[0] }}</span>
            </v-chip>
            <span v-if="optionsList.length > 1" class="grey--text caption ml-1">
              +{{ optionsList.length - 1 }}
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <!-- rules -->
    <div class="mt-8 mx-n6 rules-wrapper">
      <h6 class="pt-6 px-6 text-subtitle-1 font-weight-medium">Rules</h6>
      <v-container fluid class="mt-4">
        <v-row class="bordered-list-header">
          <v-col cols="3" class="px-6">Option</v-col>
          <v-col cols="5" offset="2">Settings</v-col>
        </v-row>
        <v-row
          v-for="(rule, index) of rules"
          :key="index"
          class="align-center bordered-list-row"
        >
          <v-col cols="3" class="px-6 py-4">
            {{ rule.rule | formatString }}
          </v-col>
          <v-col cols="5" offset="2">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model.number="rule.value"
                  label="Value"
                  class="pt-0"
                  hide-details
                  @blur="handleRuleChanged"
                />
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  :menu-props="{
                    left: true,
                  }"
                  :items="storeSets"
                  label="Store set"
                  item-text="set_name"
                  item-value="stores_set_key"
                  class="pt-0"
                  return-object
                  hide-details
                  @change="val => handleRuleStoreSetChanged(val, rule)"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-icon class="mx-2 action-btn-danger" @click="removeRule(index)">delete</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="px-6">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              :items="rulesList"
              :key="rules.length"
              item-value="type"
              item-text="name"
              v-model="newRule"
              label="Select rule"
              class="pt-0"
              hide-details
              @change="handleNewRuleChanged"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { formatString } from '@/variables'
import { headersFilters } from '@/utils'
import CollapsibleSelect from '@/components/CollapsibleSelect'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  name: 'StockPickticketsCriteria',
  components: { CollapsibleSelect },
  props: {
    storeSets: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      fromStores: [],
      toStores: [],
      filters: {},
      rulesList: [{
        type: 'maximum_pieces_per_store',
        name: 'Maximum pieces per store'
      }, {
        type: 'maximum_pieces',
        name: 'Maximum pieces'
      }],
      rules: [],
      newRule: null,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    criteria () {
      return {
        fromStores: this.fromStores,
        toStores: this.toStores,
        filters: this.filters,
        rules: this.rules,
      }
    },
    optionsList () {
      return [ ...this.filtersStore.selectedFiltersOptions.options ]
    },
  },
  methods: {
    headersFilters,
    formatString,
    setCriteria (val) {
      this.fromStores = val.fromStores || []
      this.toStores = val.toStores || []
      this.filters = val.filters || {}
      this.rules = val.rules || []
    },
    handleStoresSelected () {
      this.$emit('changed', this.criteria)
    },
    removeFilter (index) {
      delete this.filters[index]
      this.filters = { ...this.filters }

      this.$emit('changed', this.criteria)
    },
    handleRuleChanged () {
      this.$emit('changed', this.criteria)
    },
    handleNewRuleChanged () {
      this.rules.push({
        rule: this.newRule,
        value: '',
        store_keys: []
      })
      this.newRule = null
    },
    removeRule (index) {
      this.rules.splice(index, 1)
      this.rules = [ ...this.rules ]

      this.$emit('changed', this.criteria)
    },
    handleFromStoreSetChanged (value) {
      this.fromStores = value.store_keys.slice()
      this.$emit('changed', this.criteria)
    },
    handleToStoreSetChanged (value) {
      this.toStores = value.store_keys.slice()
      this.$emit('changed', this.criteria)
    },
    handleRuleStoreSetChanged (value, rule) {
      rule.store_keys = value.store_keys.slice()
      this.$emit('changed', this.criteria)
    },
    handleOptionsEditClick () {
      this.filtersStore.setFiltersOpened(true)
      this.filtersStore.setOptionsSearchVisible(true)
    },
  },
}
</script>

<style lang="scss">
.stock-picktickets-criteria {
  .rules-wrapper {
  }
}
</style>
