import axios from '@/services/axios'
import baseURLs from '@/api/APIBaseURLs'
import {getHeadersWithAccessToken} from '@/variables'
import * as models from '@/models/StockManage'
import {RuleBlock} from '@/models/Proposal'
import {useGeneralStore} from '@/store/pinia/generalStore'
import authService from '@/auth/Auth'

export function getStoreSetsFilterOptionValues (key: string): string[]|number[] {
  const generalStore = useGeneralStore()
  const values = generalStore.appConfig?.properties.stores[key]
  return values || []
}

export async function saveOptionSet (payload: models.OptionSetPayload): Promise<void> {
  const url = `${baseURLs.manageAPI}/options_sets${payload.options_set_key ? `?options_set_key=${payload.options_set_key}` : ''}`
  await axios.post(url, payload)
}

export async function saveStoreSet (payload: models.StoreSetPayload): Promise<void> {
  const url = `${baseURLs.manageAPI}/stores_sets${payload.stores_set_key ? `?stores_set_key=${payload.stores_set_key}` : ''}`
  await axios.post(url, payload)
}

export async function getOptionSet (key: number): Promise<models.OptionSet> {
  const response = await axios.get(`${baseURLs.manageAPI}/options_sets?options_set_key=${key}`)
  return response.data.options_sets[0]
}

export async function getStoreSet (key: number): Promise<models.StoreSet> {
  const response = await axios.get(`${baseURLs.manageAPI}/stores_sets?stores_set_key=${key}`)
  return response.data.stores_sets[0]
}

export async function deleteOptionSet (key: number[]): Promise<void> {
  await axios.delete(`${baseURLs.manageAPI}/options_sets`, {
    headers: getHeadersWithAccessToken(),
    data: { options_set_keys: key },
  })
}

export async function deleteStoreSet (keys: number[]): Promise<void> {
  await axios.delete(`${baseURLs.manageAPI}/stores_sets`, {
    headers: getHeadersWithAccessToken(),
    data: { stores_set_keys: keys },
  })
}

// TODO merge with stockOperateAPI.ts
export async function getOptionsPreviewByCriteria (payload: models.PreviewCriteriaPayload): Promise<{ options: models.Option[], refreshTime: string }> {
  const response = await axios.post(`${baseURLs.tsqlAPI}/options_finder`, {
    search_text: payload.searchText,
    properties_include: payload.propertiesInclude,
    properties_exclude: payload.propertiesExclude,
    format: 'lists',
    ...payload.filters,
    ...payload.options_set,
  }, { headers: getHeadersWithAccessToken() })

  return {
    options: createOptionsFromColumnsAndData(response.data.columns, response.data.data),
    refreshTime: response.data.refresh_time,
  }
}

export async function getStoresPreviewByCriteria (payload: models.PreviewCriteriaPayload): Promise<unknown> {
  const response = await axios.post(`${baseURLs.manageAPI}/search_stores`, payload)
  return response.data
}

export async function loadClusters (optionKey?: number): Promise<models.Cluster[]> {
  const query = optionKey ? `?option_key=${optionKey}` : ''
  const response = await axios.get(`${baseURLs.manageAPI}/clusters${query}`)
  return response.data.clusters
}

export async function getCluster (key: number): Promise<models.Cluster> {
  const response = await axios.get(`${baseURLs.manageAPI}/clusters?cluster_key=${key}`)
  return response.data.clusters[0]
}

export async function saveCluster (payload: models.Cluster): Promise<void> {
  await axios.post(`${baseURLs.manageAPI}/clusters`, payload)
}

export async function deleteCluster (key: number): Promise<void> {
  await axios.delete(`${baseURLs.manageAPI}/clusters?cluster_key=${key}`)
}

export async function loadFactors (optionKey?: number): Promise<models.Factor[]> {
  const query = optionKey ? `?option_key=${optionKey}` : ''
  const response = await axios.get(`${baseURLs.manageAPI}/factor${query}`)
  return response.data.factor
}

export async function getFactor (key: number): Promise<models.Factor> {
  const response = await axios.get(`${baseURLs.manageAPI}/factor?factor_key=${key}`)
  return response.data.factor[0]
}

export async function saveFactor (payload: models.Factor): Promise<void> {
  const method = payload.factor_key ? 'put' : 'post'
  await axios[method](`${baseURLs.manageAPI}/factor`, payload)
}

export async function deleteFactor (key: number): Promise<void> {
  await axios.delete(`${baseURLs.manageAPI}/factor`, {
    headers: getHeadersWithAccessToken(),
    data: { factor_key: key },
  })
}

export async function loadDoubleFactors (): Promise<models.DoubleFactor[]> {
  const response = await axios.get(`${baseURLs.manageAPI}/data/stock/manage/double_factors`)
  return response.data.data
}

export function getRuleblocks (): RuleBlock[] {
  const generalStore = useGeneralStore()
  return generalStore.appConfig?.rule_blocks || []
}

export function loadTimelines (): models.Timeline[] {
  const generalStore = useGeneralStore()
  return generalStore.appConfig?.timelines || []
}

export async function saveRuleblock (payload: models.RuleBlockPayload): Promise<void> {
  await axios.put(`${baseURLs.manageAPI}/rule_blocks`, payload)
  authService.refreshAppConfig().catch(console.error)

}

export async function saveTimeline (payload: models.TimelinePayload): Promise<void> {
  const method = payload.timeline_key ? 'put' : 'post'
  await axios[method](`${baseURLs.manageAPI}/timelines`, payload)
  authService.refreshAppConfig().catch(console.error)
}

export async function deleteTimeline (key: number): Promise<void> {
  await axios.delete(`${baseURLs.manageAPI}/timelines?timeline_key=${key}`)
  authService.refreshAppConfig().catch(console.error)
}

export async function deleteRuleblock (key: number): Promise<void> {
  await axios.delete(`${baseURLs.manageAPI}/rule_blocks?block_key=${key}`)
  authService.refreshAppConfig().catch(console.error)
}

export async function getTimelineMappings (): Promise<models.TimelineMapping[]> {
  const response = await axios.get(`${baseURLs.manageAPI}/timelines_mapping`)
  const mappings = [...response.data]
  if (!mappings.length) {
    mappings.push({
      map_rank: 999,
      options_set_key: null,
      timeline_key: null,
    })
  }
  return mappings
}

export async function saveTimelinesMapping (payload: models.TimelinesMappingPayload): Promise<void> {
  await axios.post(`${baseURLs.manageAPI}/timelines_mapping`, payload)
}

export async function loadOptionCapacity (optionKey: number): Promise<{ capacity: models.Capacity[] }> {
  const response = await axios.get(`${baseURLs.manageAPI}/capacity?option_key=${optionKey}`)
  return {
    capacity: response.data.capacity,
  }
}
export async function loadCapacity (): Promise<{ capacity: models.Capacity[] }> {
  const response = await axios.get(`${baseURLs.manageAPI}/capacity`)
  return {
    capacity: response.data.capacity
  }
}

export async function getCapacityOption (key: number): Promise<models.Capacity> {
  const response = await axios.get(`${baseURLs.manageAPI}/capacity?capacity_key=${key}`)
  return response.data.capacity[0]
}

export async function deleteCapacityOption (key: number): Promise<void> {
  await axios.delete(`${baseURLs.manageAPI}/capacity?capacity_key=${key}`, {
    headers: getHeadersWithAccessToken(),
    data: { capacity_key: key },
  })
}

export async function saveCapacityOption (payload: models.SaveCapacityOptionPayload): Promise<void> {
  const method = payload.capacity_key ? 'put' : 'post'
  await axios[method](`${baseURLs.manageAPI}/capacity`, payload)
}

// TODO merge with stockOperateAPI definition
function createOptionsFromColumnsAndData (columns: string[], data: unknown[][]): models.Option[] {
  return data.map(row => {
    const option: Partial<models.Option> = {}
    columns.forEach((column, index) => {
      option[column as keyof models.Option] = row[index] as never
    })
    return option as models.Option
  })
}
