<template>
  <div class="option-search-component">
    <OptionAlertModal
      :dialogVisible="alertDialogVisible"
      @alert-create="alertDialogVisible = false"
      @close-click="alertDialogVisible = false"
    />

    <div v-if="searchActive" class="search-input-wrapper">
      <v-combobox
        ref="searchInput"
        :search-input.sync="searchTerm"
        :items="searchResults"
        :loading="loading"
        item-value="option_key"
        item-text="name"
        :menu-props="{
          left: true,
          offsetY: true,
          contentClass: 'options-search-results',
          closeOnContentClick: false,
          maxHeight: 520,
        }"
        placeholder="Search"
        hide-details
        class="d-inline-block"
        @update:search-input="selectedResult = searchResults[selectedIndex]"
        @update:list-index="index => selectedIndex = index"
        @keyup.enter="handleSearchClick()"
      >
        <v-icon slot="append" @click="handleSearchClick">search</v-icon>
        <template v-slot:item="{ item }">
          <router-link
            :to="getRoute(item)"
            class="mt-2 search-result-item"
            @click.stop="handleSearchResultClick($event, item)"
          >
            <v-row class="pb-2 align-center">
              <v-col
                :cols="7"
                class="d-flex"
              >
                <img
                  alt=""
                  :src="item?.image_url || '@/assets/img/ts_placeholder.png'"
                  height="90px"
                  width="60px"
                  @error="loadPlaceholder"
                >
                <div v-if="item.name?.split(' / ')?.length === 2" class="ml-6 pt-2 option-name-wrapper">
                  <div class="text-body-2 font-weight-medium option-name">{{ item.name.split(" / ")[0] }}</div>
                  <p class="text-body-2 text--secondary">{{ item.name.split(" / ")[1] }}</p>
                  <p class="mt-2 text-body-2">
                    {{ item.osp | currency }}
                    <span v-if="item.osp !== item.asp"> / {{ item.asp | currency }}</span>
                  </p>
                </div>
                <div v-else class="pt-2 option-name-wrapper">
                  <div class="text-body-2 font-weight-medium option-name">{{ item.name }}
                  </div>
                </div>
              </v-col>
              <v-col :cols="3" class="text-body-2">
                <v-icon class="mr-2">
                  local_shipping
                </v-icon>
                {{ item.stock }}
              </v-col>
              <v-col :cols="2" class="text-right">
                <v-icon
                  class="mx-2 alert-icon"
                  @click.prevent="changeAlert(item)"
                >
                  add_alert
                </v-icon>
              </v-col>
            </v-row>
          </router-link>
        </template>
        <template v-slot:no-data>
          <div v-if="noResults" class="px-4">No options found</div>
        </template>
      </v-combobox>
      <v-icon class="d-inline-block ml-2" @click="closeSearch">close</v-icon>
    </div>
    <v-icon v-else class="action-btn-primary" @click="handleSearchIconClick">search</v-icon>
  </div>
</template>

<script>
import { findOptionsByFiltersTSQL } from '@/api/options'
import OptionAlertModal from '@/components/Alerts/OptionAlertModal.vue'
import ts_placeholder from '@/assets/img/ts_placeholder.png'
import { useAlertStore } from '@/store/pinia/alertStore'

export default {
  setup () {
    return { alertStore: useAlertStore() }
  },
  data () {
    return {
      searchActive: false,
      alertDialogVisible: false,
      loading: false,
      searchTerm: '',
      searchResults: [],
      noResults: false,
      selectedIndex: -1,
      selectedResult: null,
    }
  },
  methods: {
    getRoute (item) {
      return {
        name: this.redirectLink,
        query: {
          option_key: item?.option_key,
        },
      }
    },
    changeAlert (option) {
      const payload = {
        page: 'option',
        option_key: option?.option_key,
        option_name: option?.name
      }

      this.alertDialogVisible = true
      this.alertStore?.setOptionAlertData(payload)
    },
    querySearchAsync (queryString) {
      const payload = {
        search_text: queryString,
        discount_min: null,
        discount_max: null,
        stock_active: null,
        osp_min: null,
        osp_max: null,
        asp_min: null,
        asp_max: null,
        stock_min: null,
        stock_max: null,
        inbounds_min: null,
        inbounds_max: null,
        option_keys_include: [],
        option_keys_exclude: [],
        properties_include: {},
        properties_exclude: {},
        filters: [],
      }
      this.selectedResult = null
      return findOptionsByFiltersTSQL(payload)
    },
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    handleSearchClick () {
      if (this.selectedResult) {
        this.$emit('search-closed')
        this.searchActive = false

        return this.$router.push(this.getRoute(this.selectedResult))
      }

      this.loading = true
      this.noResults = false
      this
        .querySearchAsync(this.searchTerm)
        .then(results => {
          if (!results) {
            return
          }
          this.searchResults = results.options

          if (!results.options.length) {
            this.noResults = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSearchIconClick () {
      this.searchActive = true
      this.$nextTick(() => {
        this.$refs['searchInput']?.focus()
      })
      this.$emit('search-opened')
    },
    closeSearch () {
      this.searchActive = false
      this.searchTerm = ''
      this.searchResults = []
      this.selectedResult = null
      this.$emit('search-closed')
    },
    handleSearchResultClick () {
      this.$emit('search-closed')
      this.searchActive = false
    },
  },
  components: {
    OptionAlertModal,
  },
  computed: {
    redirectLink () {
      return this.$route.name.startsWith('pricing-')
        ? 'pricing-markdown-page'
        : 'stock-option'
    },
  },
  watch: {
    $route () {
      this.handleSearchResultClick()
    }
  }
}
</script>

<style lang="scss">
.option-search-component {
  .search-input-wrapper {
    display: flex;
    width: 550px;
  }
}
.options-search-results {
  .search-result-item {
    text-decoration: none;
    color: inherit;
    width: 100%;
    p {
      margin: 0;
    }
    &:hover {
      .option-name {
        color: var(--primary);
      }
    }
  }
  .v-list-item {
    border-bottom: 1px solid var(--border-color);
  }
  .option-name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    .option-name {
      color: unset;
      text-decoration: none;
    }
  }
}
</style>
