import {defineStore} from 'pinia'
import {getStoreSets} from '@/api/storeSets'
import {getOptionSets} from '@/api/options'
import {OptionSet, StoreSet} from '@/models/StoreAndOptionSets'
import {useGeneralStore} from '@/store/pinia/generalStore'

export const useStoreAndOptionSetsStore = defineStore('storesAndOptionSets', {
  state: () => ({
    optionSets: [] as OptionSet[],
    loadingOptionSets: false,
    loadingStoreSetsLabel: 'all',
    loadingOptionSetsLabel: 'all',
    storeSets: [] as StoreSet[],
    loadingStoreSets: false,
  }),
  actions: {
    async loadStoreSets () {
      try {
        const storesLabel = useGeneralStore().currentStoresSetsLabel
        if(storesLabel === this.loadingStoreSetsLabel && this.loadingStoreSets){
          return
        }
        this.loadingStoreSets = true
        this.loadingStoreSetsLabel = storesLabel || 'all'
        const label = storesLabel === 'all' ? '' : `?label=${storesLabel}`
        const response = await getStoreSets(label)
        this.storeSets = response.stores_sets
      } finally {
        this.loadingStoreSets = false
      }
    },
    async loadOptionSets () {
      try {
        const optionsLabel = useGeneralStore().currentStoresSetsLabel
        if(optionsLabel === this.loadingOptionSetsLabel && this.loadingOptionSets){
          return
        }
        this.loadingOptionSetsLabel = optionsLabel || 'all'
        this.loadingOptionSets = true
        const label = optionsLabel === 'all' ? '' : `?label=${optionsLabel}`
        const response = await getOptionSets(label)
        this.optionSets = response.options_sets
      } finally {
        this.loadingOptionSets = false
      }
    }
  }
})
