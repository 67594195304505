<template>
  <div class="option-proposal-value p-relative">
    <v-overlay absolute color="white" :value="articlesStore.savingOptionSettings">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <h6 class="text-subtitle-1 font-weight-medium mt-2 px-4">Margin calculation</h6>
    <v-row class="mx-1 mt-4">
      <v-col :cols="4" class="py-0">
        <v-select
          :items="proposalValueMethod.choices.map(x => ({
            text: $options.filters.capitalize(x),
            value: x,
          }))"
          class="pt-0"
          v-model="optionValueForm.valueMethod"
          label="Value method"
          hide-details
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"
          @change="handleOptionValueChange"
        >
          <template v-slot:append-outer>
            <v-tooltip top :max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
              </template>
              <span>{{ proposalValueMethod.description }}</span>
            </v-tooltip>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row class="mx-1 mt-4">
      <v-col :cols="4" class="py-0 pr-12">
        <v-row>
          <v-col>
            <v-menu
              v-model="datePickerStartActive"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="optionValueForm.dateMin"
                  :error-messages="dateMinErrors"
                  clearable
                  label="Start"
                  hide-details="auto"
                  v-on="on"
                  @click:clear="handleDateMinClearClick"
                ></v-text-field>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                :locale-first-day-of-year="4"
                :show-week="true"
                no-title
                v-model="optionValueForm.dateMin"
                @click="datePickerStartActive = false"
                @change="handleOptionValueChange"
              />
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              v-model="datePickerEndActive"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="optionValueForm.dateMax"
                  :error-messages="dateMaxErrors"
                  :class="{ 'past-date': dateMaxPast }"
                  clearable
                  label="End"
                  hide-details="auto"
                  v-on="on"
                  @click:clear="handleDateMaxClearClick"
                ></v-text-field>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                :locale-first-day-of-year="4"
                :show-week="true"
                no-title
                v-model="optionValueForm.dateMax"
                @click="datePickerEndActive = false"
                @change="handleOptionValueChange"
              />
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="methodRequiresDates" class="mx-1 mt-8 mb-4">
      <v-col :cols="4" class="py-0">
        <div class="d-flex align-center">
          <div class="slider-label mr-8">End value</div>
          <div class="slider-value-from mr-2">{{ proposalValueEndValue.slider[0] }}</div>
          <v-slider
            v-model="optionValueForm.endValue"
            :min="10"
            :max="100"
            hide-details
            thumb-label
            track-color="#ccc"
            track-fill-color="primary"
            @change="handleOptionValueChange"
          />
          <div class="slider-value-to ml-2 mr-2">{{ proposalValueEndValue.slider[1] }}</div>
          <v-tooltip top :max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
            </template>
            <span>{{ proposalValueEndValue.description }}</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <v-row class="mx-1 mt-8 mb-4">
      <v-col>
        <OptionValueChart
          class="d-flex flex"
          ref="option_value_graph"
          title="option_value_graph"
          :chartData="chartData"
          :dateEnd="optionValueForm.dateMax"
          :fixedHeight="false" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { isBefore, startOfDay } from 'date-fns'
import OptionValueChart from './OptionValueChart'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

import { useArticleStore } from '@/store/pinia/stockArticlesStore'

export default {
  setup () {
    return {
      articlesStore: useArticleStore(),
      proposalStore: useStockProposalStore()}
  },
  components: {
    OptionValueChart,
  },
  data () {
    return {
      optionValueForm: {
        valueMethod: null,
        dateMin: null,
        dateMax: null,
        endValue: null,
      },
      datePickerStartActive: false,
      datePickerEndActive: false,
    }
  },
  computed: {
    articleSettings () {
      return this.articlesStore.articleSettings
    },
    proposalValueMethod () {
      return this.proposalStore.value.find(x => x.name === 'method')
    },
    proposalValueEndValue () {
      return this.proposalStore.value.find(x => x.name === 'end_value')
    },
    chartData () {
      const { valueDates, valuePercentage, valuePrice } = this.proposalStore

      return {
        valueDates,
        valuePercentage,
        valuePrice,
        price: this.proposalStore.price,
        originalPrice: this.proposalStore.originalPrice,
        currency: this.$auth.getUserCurrency(),
      }
    },
    methodRequiresDates () {
      return ['linear', 'exponential'].includes(this.optionValueForm.valueMethod)
    },
    dateMinErrors () {
      return (this.methodRequiresDates && !this.optionValueForm.dateMin)
        ? 'Required'
        : null
    },
    dateMaxErrors () {
      return (this.methodRequiresDates && !this.optionValueForm.dateMax)
        ? 'Required'
        : null
    },
    dateMaxPast () {
      return isBefore(new Date(this.optionValueForm.dateMax), startOfDay(new Date()))
    },
  },
  methods: {
    handleDateMinClearClick () {
      this.optionValueForm.dateMin = null
      this.handleOptionValueChange()
    },
    handleDateMaxClearClick () {
      this.optionValueForm.dateMax = null
      this.handleOptionValueChange()
    },
    handleOptionValueChange () {
      const valueData = {
        method: this.optionValueForm.valueMethod,
      }

      if (this.methodRequiresDates) {
        valueData.end_value = this.optionValueForm.endValue
      }

      this.articlesStore.updateArticleSetting('value_parameters', valueData)

      this.articlesStore.updateArticleSetting('date_min', this.optionValueForm.dateMin)

      this.articlesStore.updateArticleSetting('date_max', this.optionValueForm.dateMax)

      if (!this.dateMinErrors && !this.dateMaxErrors) {
        this.$emit('changed')
      }
    },
  },
  created () {
    this.optionValueForm.valueMethod = this.articleSettings.value_parameters.method || this.proposalValueMethod.default
    this.optionValueForm.dateMin = this.articleSettings.date_min || null
    this.optionValueForm.dateMax = this.articleSettings.date_max || null
    this.optionValueForm.endValue = this.articleSettings.value_parameters.end_value || this.proposalValueEndValue.default
  }
}
</script>

<style lang="scss">
.option-proposal-value {
    .slider-value-from,
    .slider-value-to {
        font-size: 14px;
    }
    .past-date {
        input {
            color: var(--danger);
        }
    }
}
</style>
