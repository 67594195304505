import axios from '@/services/axios'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

export function loadStoresProperties () {
  const url = baseURLs.manageAPI + '/stores_batch'

  return axios.get(url, {
    headers: getHeadersWithAccessToken(),
    params: {
      format: 'd',
    }
  }).then(({ data }) => data)
    .catch(error => console.log(error))
}

export function setStoresProperties (payload) {
  const url = baseURLs.manageAPI + '/stores_batch'
  const h = getHeadersWithAccessToken()

  return axios.post(url, payload, { headers: h })
    .then(({ data }) => data)
    .catch(error => console.log(error))
}
