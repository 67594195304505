<template>
  <v-menu
    bottom
    offset-y
    nudge-right="12"
    nudge-top="56"
    min-width="400"
    max-width="400"
    content-class="option-variations-menu"
    :disabled="!variations.length"
    :open-delay="200"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-row
        v-bind="attrs"
        class="option-variations-menu flex-nowrap"
        v-on="on">
        <v-col cols="auto" class="pr-0">
          <p class="product-title text-no-wrap">
            {{ option["option_name"].split(" / ")[0] }}
          </p>
          <p class="product-subtitle">
            {{ option["option_name"].split(" / ")[1] }}
          </p>
          <p class="product-price">{{ renderPrice(option["Price"]) }}</p>
        </v-col>
        <v-col cols="auto" class="py-2" v-if="variations.length">
          <v-icon class="mr-3">arrow_drop_down</v-icon>
        </v-col>
      </v-row>
    </template>
    <v-card class="p-relative overflow-auto" max-height="620" >
      <v-col
        v-for="item of variations"
        :key="item.option_key"
        class="d-flex c-pointer option-info-variant px-6"
        @click="handleVariationClick(item)"
      >
        <img
          alt=""
          :src="item['image_url'] || '@/assets/img/ts_placeholder.png'"
          height="90px"
          width="60px"
          @error="loadPlaceholder"
        >
        <div v-if="item['option_name'].split(' / ').length === 2" class="ml-6 pt-2 option-name-wrapper">
          <div class="text-body-2 font-weight-medium option-name">{{ item['option_name'].split(" / ")[0] }}
          </div>
          <p class="text-body-2 text--secondary">{{ item['option_name'].split(" / ")[1] }}</p>
          <p class="mt-2 text-body-2">{{ item["Price"] }}</p>
        </div>
        <div v-else class="pt-2 option-name-wrapper">
          <div class="text-body-2 font-weight-medium option-name">{{ item['option_name'] }}
          </div>
        </div>
      </v-col>
    </v-card>
  </v-menu>
</template>

<script>
import ts_placeholder from '@/assets/img/ts_placeholder.png'

export default {
  name: 'OptionVariationsMenu',
  props: {
    option: {
      type: Object,
      default: null,
    },
    variations: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
    }
  },
  methods: {
    renderPrice (price) {
      const prices = price.split('/')
      if (prices.length === 1) {
        return this.priceFormatter(parseFloat(prices[0]))
      }
      return [this.priceFormatter(parseFloat(prices[0])), this.priceFormatter(parseFloat(prices[1]))].join(' / ')
    },
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    handleVariationClick (item) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          option_key: item.option_key
        }
      })
      this.$router.go(0)
    }
  },
  computed: {

    priceFormatter () {
      const currency = this.$auth.getUserCurrency().currency || 'EUR'
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return formatter.format
    },
  }
}
</script>

<style lang="scss" scoped>
    .option-variations-menu {

      .product-price {
        color: var(--text-black);
        font-size: 14px;
        font-weight: 500;
        margin: 12px 0;
      }
    }
</style>
