<template>
  <div class="buy-manage-budgets mb-6">
    <buy-manage-budgets-tile
      class="mb-2"
      :properties="properties"
      @budget-change="setBudgetKey"
    />
    <buy-manage-budgets-beliefs
      class="mb-2"
      v-if="beliefs"
      :beliefs="beliefs"
      :sku_names="sku_names"
      :budget="budget"
      :properties="properties"
      :store-sets="storeSets"
      @saved="loadBeliefs"
    />
    <buy-manage-budgets-scenarios
      class="mb-2"
      ref="scenarios"
      v-if="scenarios"
      :scenarios="scenarios"
      :sku_names="sku_names"
      :budget="budget"
      :properties="properties"
      @saved="loadScenarios"
      @merged="refreshHistory"
    />
    <buy-manage-budgets-merge-history
      v-if="budget && budget.budget_key"
      :budget="budget"
      :open-merge-modal="openMergeModal"
    />
  </div>
</template>

<script>
import BuyManageBudgetsTile from '@/components/BuyManageBudgets/BuyManageBudgetsTile'
import BuyManageBudgetsBeliefs from '@/components/BuyManageBudgets/BuyManageBudgetsBeliefs'
import BuyManageBudgetsScenarios from '@/components/BuyManageBudgets/BuyManageBudgetsScenarios'
import { loadBeliefs, loadScenarios } from '@/api/budgetAPI'
import BuyManageBudgetsMergeHistory from '@/components/BuyManageBudgets/BuyManageBudgetsMergeHistory'
import { usePropertiesStore } from '@/store/pinia/propertiesStore'

export default {
  name: 'BuyManageBudgets',
  components: { BuyManageBudgetsMergeHistory, BuyManageBudgetsBeliefs, BuyManageBudgetsTile, BuyManageBudgetsScenarios },
  props: ['properties', 'sku_names', 'storeSets'],
  data () {
    return {
      budget: {},
      beliefs: null,
      scenarios: null,
      budget_key: null,
    }
  },
  methods: {
    refreshHistory () {
      this.budget = { ...this.budget }
      this.loadScenarios()
      this.$refs.scenarios.updateMergeScenarios()
    },
    setBudgetKey (newValue) {
      if (newValue) {
        this.loadBeliefs(newValue)
        this.loadScenarios(newValue)
      } else {
        this.beliefs = null
        this.scenarios = null
      }
      this.budget = newValue
    },
    loadBeliefs (newValue) {
      this.budget_key = newValue.budget_key || this.budget_key
      loadBeliefs(this.budget_key).then(r => {
        this.beliefs = r
      })
    },
    loadScenarios (newValue) {
      if (newValue) {
        this.budget_key = newValue.budget_key
      }
      loadScenarios(this.budget_key).then(r => {
        this.scenarios = r
      })
    },
    openMergeModal () {
      this.$refs.scenarios.openMergeModal()
    }
  },
  created () {
    usePropertiesStore().loadSKUOptionsProperties().catch(console.error)
  }
}
</script>

<style scoped>
  .buy-manage-budgets {
    display: flex;
    flex-direction: column;
  }
</style>
