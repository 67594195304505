<template>
  <div class="mb-10 form-div">
    <div class="mb-2 title-wrapper">
      <h4 class="mb-1">
        {{ filterData.label }}
      </h4>
      <v-icon @click="$emit('revert-click', filterName)">replay</v-icon>
    </div>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="selectedFilterOptions.week_from"
          :items="weeksOptions"
          clearable
          label="Week from"
          hide-details
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"
          @click:clear="selectedFilterOptions.weeks_left_from = null"
          @change="handleFilterChanged"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="selectedFilterOptions.week_to"
          :items="weeksOptions"
          clearable
          label="Week to"
          hide-details
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"
          @click:clear="selectedFilterOptions.weeks_left_from = null"
          @change="handleFilterChanged"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          type="number"
          v-model.number="selectedFilterOptions.cost_price_from"
          clearable
          hide-details
          label="Cost price from"
          @click:clear="selectedFilterOptions.cost_price_from = null"
          @change="handleFilterChanged"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          type="number"
          v-model.number="selectedFilterOptions.cost_price_to"
          clearable
          hide-details
          label="Cost price to"
          @click:clear="selectedFilterOptions.cost_price_to = null"
          @change="handleFilterChanged"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="selectedFilterOptions.weeks_left_from"
          clearable
          hide-details
          label="Weeks left from"
          @click:clear="selectedFilterOptions.weeks_left_from = null"
          @change="handleFilterChanged"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="selectedFilterOptions.weeks_left_to"
          clearable
          hide-details
          label="Weeks left to"
          @click:clear="selectedFilterOptions.weeks_left_to = null"
          @change="handleFilterChanged"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="selectedFilterOptions.currency"
          :items="currencies"
          :item-text="(item)=> `${item.name} (${item.iso_code})`"
          item-value="iso_code"
          clearable
          label="Currency"
          @change="handleFilterChanged"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { filtersData } from '@/variables'
import { getWeeksSelectorOptions } from '@/utils'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  props: {
    filterName: {
      type: String,
      required: true,
    },
    filterData: {
      type: Object,
      default: () => ({}),
    },
    currencies: {
      type: Array,
      default: () => [],
    }
  },
  data () {
    return {
      filtersData,
    }
  },
  computed: {
    selectedFilterOptions () {
      return this.filtersStore.selectedFiltersOptions[this.filterName]
    },
    weeksOptions () {
      return getWeeksSelectorOptions()
    },
  },
  methods: {
    handleFilterChanged () {
      this.$emit('filter-changed', {
        filterName: this.filterName,
        value: this.selectedFilterOptions,
      })
    },
    handleClearClick (key) {
      this.selectedFilterOptions[key] = null
      this.handleFilterChanged()
    }
  },
}
</script>

<style scoped lang="scss">
.form-div {
    width: 100%;
    .title-wrapper {
        display: flex;
        justify-content: space-between;
    }
}
</style>
