import axios from 'axios'
import router from '@/router'
import { getHeadersWithAccessToken } from '@/variables.js'
import authService from '@/auth/Auth'
import { useGeneralStore } from '@/store/pinia/generalStore'
import baseURLs from '@/api/APIBaseURLs'

const service = axios.create({
  baseURL: '',
  headers: getHeadersWithAccessToken()
})

const isLoginRoute = () => router.app._route.path === '/login'
const isAuthRoute = () => router.app._route.path === '/login' || router.app._route.path === '/reset_password' || router.app._route.path === '/forgot-password'
function redirectToLogin () {
  router.push({ name: 'login', query: { redirect: router.app._route.path } }).then()
}

service.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config

  if (originalRequest?.url.includes('refresh') &&
      !originalRequest.url.includes(baseURLs.storepageAPI) &&
      (error.response.status === 422 || error.response.status === 401)
  ) {
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('access_token')
    localStorage.removeItem('email')
    localStorage.removeItem('superuser')

    localStorage.removeItem('expires_at')
    localStorage.removeItem('org')
    localStorage.removeItem('groups')
    localStorage.removeItem('Recommendations_Dimensions')

    if (!isLoginRoute()) {
      redirectToLogin()
    }
    return Promise.reject(error)
  }

  if (error.response &&
        (error.response.status === 422 || error.response.status === 401) &&
        !originalRequest._retry &&
        !originalRequest.url.includes(baseURLs.storepageAPI)
  ) {
    originalRequest._retry = true
    try {
      const { data:{ access_token } } = await authService.refreshTokens()
      originalRequest['headers'] = getHeadersWithAccessToken(access_token)
      return service.request(originalRequest) // retry the original request with interceptor and not the local catch
    } catch (error) {
      if (!isAuthRoute()) {
        redirectToLogin()
      }
      return Promise.reject(error)
    }
  }if (error.response &&
        (error.response.status === 400 || error.response.status === 500)
  ) {
    const message = error.response?.data?.message
    useGeneralStore().setSnack('warning', message)
  }

  if (error.response && error.response.status === 403) {
    useGeneralStore().setAccessDeniedDialogVisible(true)
  }

  return Promise.reject(error)
})

export const setAxiosAccessToken = (token) => {
  service.defaults.headers.common['Authorization'] = `Bearer ${token}`
  service.defaults.headers['Authorization'] = `Bearer ${token}`
}

export const revokeAxiosAccessToken = () => {
  delete service.defaults.headers.common['Authorization']
  delete service.defaults.headers['Authorization']
}

export default service
