<template>
  <div class="stock-home-page p-relative mb-12" v-animate-css="'fadeInUp'">

    <v-overlay absolute color="white" :value="!loaded">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <template v-if="loaded">
      <h3 class="mb-6 mt-6">Home</h3>
      <app-chart-carousel-home ref="chart-carousel"></app-chart-carousel-home>
      <h4 class="my-6">Bestsellers</h4>
      <app-bestsellers-carousel></app-bestsellers-carousel>
      <div
      >
        <v-overlay absolute color="white" :value="!taskLoaded">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <app-tasks-component/>
      </div>

      <app-recommendations></app-recommendations>
    </template>

    <v-row class="mt-4">
      <v-col class="text-right">
        <v-btn
          color="primary"
          depressed
          outlined
          @click="$router.push({ name: 'stock-overview' })"
        >Stock overview</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ChartCarouselHome from '../components/Home/ChartCarouselHome'
import BestsellersCarousel from '../components/Home/BestsellersCarousel'
import TasksComponent from '../components/Home/TasksComponent'
import Recommendations from '../components/Home/Recommendations'

import { useStockTasksStore } from '@/store/pinia/tasksStore'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore(),
      proposalStore: useStockProposalStore(),
      tasksStore: useStockTasksStore()}
  },
  data () {
    return {
      loaded: false,
      taskLoaded: false,
    }
  },
  watch: {
    'filtersStore.filtersChanged': {
      handler (newVal) {
        if (newVal) {
          this.handleFilterChanged()
        }
      },
    },
  },
  methods: {
    watchFiltersDataLoaded () {
      this.$watch(
        'filtersStore.filtersDataLoaded',
        function (newVal) {
          if (newVal) {
            this.loadPage()
          }
        },
      )
    },
    async handleFilterChanged () {
      this.loaded = false
      await this.tasksStore.getAllTasks()
      await this.tasksStore.getRecommendations()
      this.loaded = true
    },
    loadPage () {
      if(this.loading){
        return
      }
      this.loading = true
      this.loaded = false
      this.tasksStore.getAllTasks().then(() => {
        this.taskLoaded = true
        this.loaded = true
      })

      // Already load the rules and defaults, so that proposal is faster later on
      if (
        !(
          this.proposalStore.rulesLoaded &&
          this.proposalStore.defaultsLoaded
        )
      ) {
        const loadRules = this.proposalStore.setRules()
        const loadDefaults = this.proposalStore.setDefaults()
        Promise.all([loadRules, loadDefaults]).then(() => {
          this.loaded = true
          this.loading = false
        })
      }

      if (this.tasksStore.leftHomeFrom !== null) {
        document
          .getElementById(this.tasksStore.leftHomeFrom)
          .scrollIntoView()
      }
    },
  },
  mounted () {
    // if page has filters, wait till filters data is loaded
    if (!this.filtersStore.currentPageFilters.length) {
      this.watchFiltersDataLoaded()
    } else {
      this.loadPage()
    }

    if (this.filtersStore.filtersDataLoaded) {
      this.loadPage()
    }
  },
  components: {
    appTasksComponent: TasksComponent,
    appBestsellersCarousel: BestsellersCarousel,
    appRecommendations: Recommendations,
    appChartCarouselHome: ChartCarouselHome,
  },
}
</script>

<style lang="scss">
.container-fluid {
  margin: 0;
}

.homePage {
  .el-table td {
    padding: 8px 0;
  }
}
</style>
