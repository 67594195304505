<template>
  <v-dialog
    v-model="isOpen"
    scrollable
    persistent
    content-class="align-self-start mt-14"
    width="600px"
    max-width="90%"
  >
    <v-card>
      <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">
        {{isEdit? 'Edit' : 'Add'}} Value
      </v-card-title>
      <v-divider/>
      <v-card-text class="px-0 text--primary">
        <div class="container px-12">
          <v-autocomplete
            color="primary"
            v-model="localValue.property_name"
            item-text="property_name"
            item-value="property_name"
            label="Property Name"
            hide-details="auto"
            :disabled="propertyDisabled"
            :items="filteredProperties"
            class="my-4"
          />
          <v-text-field
            v-if="valueType !== 'boolean'"
            color="primary"
            v-model="localValue.value"
            :type="valueType"
            :error-messages="errors"
            hide-details="auto"
            label="Value"
            required
            class="mt-2 mb-4"
          />
          <v-row v-else class="justify-center pl-3 mt-3">
            <div class="col col-4 justify-start px-0">
              <span class="text--primary">Value</span>
            </div>
            <div class="col justify-start">
              <v-simple-checkbox
                color="primary"
                dense
                v-model="localValue.value"
                :ripple="false"
              />
            </div>
          </v-row>
          <v-text-field
            hide-details="auto"
            color="primary"
            v-model="localValue.value_alias"
            label="Alias"
            required
            class="mt-2 mb-4"
          />
          <v-text-field
            hide-details="auto"
            color="primary"
            v-model="localValue.value_map"
            label="Map"
            required
            class="mt-2 mb-4"
          />
          <v-row class="justify-center pl-3 mt-3">
            <div class="col col-4 justify-start px-0">
              <span class="text--primary">Active</span>
            </div>
            <div class="col justify-start">
              <v-simple-checkbox
                color="primary"
                dense
                v-model="localValue.active"
                :ripple="false"
              />
            </div>
          </v-row>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2">
          <v-col>
            <v-btn
              color="primary"
              depressed
              text
              @click="handleCancel"
            >Cancel</v-btn>
            <v-btn
              color="primary"
              depressed
              :disabled="!isLocalPropertyValid"
              @click="handleSave"
              class="mx-4"
            >Save</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getPropertyManagementObjects } from '@/api/dataProperties'

export default {
  name: 'ValueModal',
  data () {
    return {
      localValue: {
        value: '',
        value_alias: '',
        value_map: '',
        active: true
      },
      isEdit: false,
      errors: [],
      properties: [],
      localProperty: {}
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    objectName: {
      type: String,
      default: ''
    },
    property: {
      type: Object,
      required: true,
    },
  },
  created () {
    this.getProperties()
    this.localProperty = this.property
  },
  computed: {
    propertyDisabled () {
      return this.isEdit || !!(this.property && this.property.property_name)
    },
    isLocalPropertyValid () {
      return this.localValue.property_name && this.localValue.property_name.length > 0 &&
        (this.localValue.value || this.localValue.value === 0 || this.localValue.value === false)
    },
    filteredProperties () {
      return this.properties.filter(r => r.object_name.toLowerCase() === this.objectName.toLowerCase())
    },
    prop () {
      let property = this.property
      if ((!property || !property.property_name) && this.localProperty && this.localProperty.property_name) {
        property = this.localProperty
      }
      return property
    },
    valueType () {
      if (!this.prop || !this.prop.value_type) {
        return 'text'
      }
      if (this.prop.value_type === 'decimal' || this.prop.value_type === 'integer') {
        return 'number'
      }
      if (this.prop.value_type === 'boolean') {
        return 'boolean'
      }
      return 'text'
    },
  },
  watch: {
    'localValue.property_name' (val) {
      this.localProperty = this.properties.find(r => r.property_name === val)
      if (this.localProperty && this.localProperty.value_type === 'boolean') {
        this.localValue.value = this.localProperty.value_default
      }
    },
  },
  methods: {
    getProperties () {
      getPropertyManagementObjects().then(response => {
        // Capitalize first letter of each word
        this.properties = response.properties
      })
    },
    handleCancel () {
      this.$emit('close')
    },
    validate (value, value_type) {
      this.errors = []
      if (value_type === 'decimal' &&
        (!value.match(/^\d+$/) && !value.match(/^\d+\.\d+$/))) {
        this.errors.push('value must be a decimal')
        return false
      }
      if (value_type === 'integer' && !value.match(/^\d+$/)) {
        this.errors.push('value must be an integer')
        return false
      }
      return true
    },
    handleSave () {
      const value = this.localValue.value
      if (!this.validate(value, this.prop.value_type)) {
        return
      }
      this.$emit('close')
      this.$emit('save', this.localValue)
    },
    setLocalValue (value) {
      if (value) {
        this.localValue = Object.assign({}, value)
        this.isEdit = true
      } else {
        this.localValue = {
          property_name: this.property.property_name,
          value: this.property.value_default || '',
          value_alias: '',
          value_map: '',
          active: true
        }
        this.isEdit = false
      }
    },
  },

}
</script>
