<template>
  <div class="optionInfo">
    <OptionAlertModal
      :dialogVisible="alertDialogVisible"
      @alert-create="alertDialogVisible = false"
      @close-click="alertDialogVisible = false"
    />

    <div v-loading="!loaded">
      <v-card class="product-card" min-height="350px" :flat="!!queryParams">
        <div class="mb-4 product-title">
          <h4>Product</h4>
          <div class="d-flex">
            <v-tooltip top :max-width="250" v-if="!queryParams">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-switch
                    v-model="articleSettings.stock_active"
                    class="mt-0 pt-0 mr-1"
                    hide-details
                    @change="saveOptionSettings()"
                  />
                </div>
              </template>
              <span>Set option active/inactive to enable communication</span>
            </v-tooltip>

            <LinkedPagesMenu
              v-if="!queryParams"
              :variation-key="article?.variation_key"
              :option-key="query.option_key"
              :replacement-option-keys="articleSettings.replacement_option_keys"
            />

            <v-menu
              top
              open-on-hover
              offset-x
              min-width="200"
              max-width="200"
              content-class="option-info-menu"
              :open-delay="200"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-3"
                  v-bind="attrs"
                  v-on="on"
                >info</v-icon>
              </template>
              <v-card
                v-if="article"
                class="py-2 px-4 p-relative"
              >
                <div class="pt-2 font-weight-bold">Last change</div>
                <div><span class="font-weight-bold">User: </span>{{usersDetails.first_name || '-'}} {{usersDetails.last_name}}</div>
                <div class="pb-1 text-no-wrap"><span class="font-weight-bold text-no-wrap">Time: </span>{{formattedChangedDate}}</div>
                <v-divider></v-divider>
                <div class="pt-2 font-weight-bold">Properties</div>
                <div
                  v-html="getProperties()"></div>
              </v-card>

            </v-menu>

            <v-icon class="icon" @click="changeAlert(article)">add_alert</v-icon>
          </div>
        </div>

        <template v-if="loaded">
          <div class="image-row" :class="{' flex-column align-center mb-0': queryParams}">
            <img :height="queryParams && !$vuetify.breakpoint.mobile ? '240px': '160px'" :src="article.image_url || '@/assets/img/ts_placeholder.png'" @error="loadPlaceholder"  alt=""/>
            <div class="product-text">
              <OptionVariationsMenu
                :option="article"
                :variations="variations"
              />
              <div class="mt-4">
                <template v-if="articleSettings.frozen">
                  <v-chip
                    label
                    outlined
                    small
                    color="red"
                    class="py-4 mb-2 mr-2"
                  >Frozen</v-chip>
                </template>
                <template v-else-if="articleSettings.clusters && articleSettings.clusters.length > 0">
                  <v-menu offset-y nudge-bottom="5">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-if="articleSettings.clusters.length" v-on="on" class="c-pointer">
                        <v-chip
                          label
                          small
                          outlined
                          color="success"
                          v-bind="attrs"
                          @click.stop="$emit('item-click', articleSettings.clusters[0])"
                        >
                          {{ articleSettings.clusters[0] }}
                        </v-chip>
                        <span v-if="articleSettings.clusters.length > 1" class="grey--text caption ml-1">
                          +{{ articleSettings.clusters.length - 1 }}
                        </span>
                        <v-icon class="ml-1">arrow_drop_down</v-icon>
                      </div>
                    </template>
                    <v-card max-height="400px">
                      <v-card class="py-2 pl-2" flat>
                        <h5>Clusters</h5>
                        <div v-for="(i, index) in articleSettings.clusters" :key="index" class="pt-2">
                          <v-chip label small outlined @click="handleClusterClick(i)">
                            <span>{{ i }}</span>
                          </v-chip>
                        </div>
                        <h5 class="pt-2">Store clusters</h5>
                        <div v-for="(i, index) in articleSettings.stores_clusters" :key="index" class="pt-2">
                          <v-chip label small outlined>
                            <span>{{ getStoreName(i) }}</span>
                          </v-chip>
                        </div>
                      </v-card>
                    </v-card>
                  </v-menu>
                </template>
                <template v-else>
                  <v-chip
                    label
                    outlined
                    small
                    color="red"
                    class="py-4 mb-2 mr-2"
                  >No clusters</v-chip>
                </template>
              </div>
            </div>
          </div>

          <v-divider v-if="!queryParams" />
          <template v-if="loaded && !queryParams" >
            <div class="inclusions mt-4">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                v-model="storesIncluded"
                label="Inclusions"
                :items="appConfig.stores_open"
                item-text="store_name"
                item-value="store_key"
                autocomplete
                @blur="saveOptionSettingsDelayed()"
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @click="handleSelectAllClickInclusions"
                  >
                    <v-list-item-action
                      class="pa-0 mr-4">
                      <v-icon :color="allSelectedInclusions ? 'primary' : null">
                        {{ iconInclusions }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <slot name="selection" :item="item" :index="index">
                    <v-chip
                      v-if="index === 0"
                      small
                      close
                      outlined
                      @click:close="storesIncluded.splice(index, 1); storesIncluded = [...storesIncluded];saveOptionSettingsDelayed()"
                    >
                      <span>{{ item.store_name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption ml-1 plus-number">
                      +{{ storesIncluded.length - 1 }}
                    </span>
                  </slot>
                </template>
                <template v-slot:item="data">
                  <v-list-item
                    v-bind="data.attrs" v-on="data.on"
                    ripple
                  >
                    <v-list-item-action                      class="pa-0 mr-4"
                    >
                      <v-checkbox :input-value="storesIncluded.indexOf(data.item.store_key) !== -1">
                      </v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content
                      :style="{'color': getInclusionsColor(data.item)}"
                      :color="'red'">
                      <v-list-item-title>
                        {{data.item.store_name}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>

            </div>
            <div class="exclusions">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                v-model="storesExcluded"
                label="Exclusions"
                multiple
                :items="appConfig.stores_open"
                item-text="store_name"
                item-value="store_key"
                show-select-all
                autocomplete
                @blur="saveOptionSettingsDelayed()"
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @click="handleSelectAllClickExclusions"
                  >
                    <v-list-item-action
                      class="pa-0 mr-4">
                      <v-icon :color="allSelectedExclusions ? 'primary' : null">
                        {{ iconExclusions }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <slot name="selection" :item="item" :index="index">
                    <v-chip
                      v-if="index === 0"
                      small
                      close
                      outlined
                      @click:close="storesExcluded.splice(index, 1); storesExcluded = [...storesExcluded]; saveOptionSettingsDelayed()"
                    >
                      <span>{{ item.store_name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption ml-1 plus-number">
                      +{{ storesExcluded.length - 1 }}
                    </span>
                  </slot>
                </template>
                <template v-slot:item="data">
                  <v-list-item
                    v-bind="data.attrs" v-on="data.on"
                    ripple
                  >
                    <v-list-item-action class="pa-0 mr-4">
                      <v-checkbox :input-value="storesExcluded.indexOf(data.item.store_key) !== -1">
                      </v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content
                      :style="{'color': [...articleSettings.stores_clusters, ...articleSettings.stores_included].indexOf(data.item.store_key) !== -1 ? 'var(--success)': ''}"
                      :color="'red'">
                      <v-list-item-title>
                        {{data.item.store_name}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </div>
            <div class="order">
              <v-select
                v-model="order"
                label="Order"
                :items="[{
                  text: 'No order',
                  inbound_key: null,
                }, ...proposalStore.inbounds]"
                :item-text="item => item.inbound_key ? `Order: ${item.inbound_key} Pieces: ${item.Pieces} Date: ${item.Date}` : `No order`"
                item-value="inbound_key"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @change="$emit('orderChanged')"
              />
            </div>
          </template>
        </template>
      </v-card>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as optionsApi from '@/api/options'
import { formatString } from '@/variables'
import OptionAlertModal from '@/components/Alerts/OptionAlertModal.vue'
import LinkedPagesMenu from '@/components/LinkedPagesMenu'
import OptionVariationsMenu from '@/components/OptionVariationsMenu.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import ts_placeholder from '@/assets/img/ts_placeholder.png'

import { useAlertStore } from '@/store/pinia/alertStore'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

import { useArticleStore } from '@/store/pinia/stockArticlesStore'

export default {
  setup () {
    return {
      articlesStore: useArticleStore(),
      proposalStore: useStockProposalStore(),
      alertStore: useAlertStore() }
  },
  data () {
    return {
      saving: false,
      proposalSaved: null,
      variations: [],
      loaded: false,
      alertDialogVisible: false,
      saveOptionSettingsDelayed: null,
    }
  },
  props: ['queryParams'],
  components: {
    OptionAlertModal,
    LinkedPagesMenu,
    OptionVariationsMenu,
  },
  computed: {
    iconInclusions () {
      if (this.someSelectedInclusions) {
        return 'mdi-close-box'
      }

      if (this.allSelectedInclusions) {
        return 'mdi-checkbox-marked'
      }

      return 'mdi-checkbox-blank-outline'
    },

    allSelectedInclusions () {
      return this.appConfig.stores_open.length === this.storesIncluded.length
    },
    someSelectedInclusions () {
      return this.appConfig.stores_open.length !== this.storesIncluded.length && this.storesIncluded.length
    },
    iconExclusions () {
      if (this.someSelectedExclusions) {
        return 'mdi-close-box'
      }

      if (this.allSelectedExclusions) {
        return 'mdi-checkbox-marked'
      }

      return 'mdi-checkbox-blank-outline'
    },

    allSelectedExclusions () {
      return this.appConfig.stores_open.length === this.storesExcluded.length
    },
    someSelectedExclusions () {
      return this.appConfig.stores_open.length !== this.storesExcluded.length && this.storesExcluded.length
    },
    ...mapState(useGeneralStore, ['appConfig']),
    query () {
      return this.queryParams || this.$route.query
    },
    stores () {
      return (this.appConfig?.stores || [])
    },
    storesNames () {
      return this.stores.reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    formattedChangedDate () {
      if (!this.articleSettings.last_change) {
        return '-'
      }
      return this.articleSettings.last_change
    },
    usersDetails () {
      return (this.appConfig?.users || []).find((user) => user.id === this.articleSettings.last_user) || {}
    },
    proposalParams () {
      return this.proposalStore.proposalParams
    },
    article () {
      return this.articlesStore.article || null
    },
    articleSettings () {
      return this.articlesStore.articleSettings
    },
    storesIncluded: {
      get () {
        return this.articleSettings.stores_included
      },
      set (val) {
        this.articlesStore.updateArticleSetting( 'stores_included', val)
      }
    },
    storesExcluded: {
      get () {
        return this.articlesStore.articleSettings.stores_filtered
      },
      set (val) {
        this.articlesStore.updateArticleSetting( 'stores_filtered', val)
      }
    },
    order: {
      get () {
        return Object.keys(this.query).includes('inbound_key')
          ? this.query.inbound_key
          : null
      },
      set (val) {
        const newQuery = { ...this.query }

        if (val !== null) {
          newQuery['inbound_key'] = val
        } else {
          delete newQuery['inbound_key']
        }

        this.$router
          .push({ name: 'stock-option', query: newQuery })
          .catch(err => console.log(err))
      },
    },
  },
  watch: {
    article: {
      handler () {
        if (!this.article) {
          return
        }

        optionsApi
          .getOptionVariations(this.query.option_key)
          .then(variations => {
            this.variations = variations
          })
      },
    },
    $route () {
      this.load()
    },
  },
  created () {
    this.load()
    this.saveOptionSettingsDelayed = _.debounce(this.saveOptionSettings, 300)
  },
  methods: {

    handleSelectAllClickInclusions () {
      if (this.allSelectedInclusions) {
        this.storesIncluded = []
      }else {
        this.storesIncluded = this.appConfig.stores_open.map(item => {
          return item.store_key
        })
      }
      this.saveOptionSettingsDelayed()
    },
    handleSelectAllClickExclusions () {
      if (this.allSelectedExclusions) {
        this.storesExcluded = []
      }else {
        this.storesExcluded = this.appConfig.stores_open.map(item => {
          return item.store_key
        })
      }
      this.saveOptionSettingsDelayed()
    },
    getInclusionsColor (item){

      if(this.storesExcluded.indexOf(item.store_key) !== -1){
        return 'var(--error)'
      }
      return this.articleSettings.stores_clusters.indexOf(item.store_key) !== -1 ? 'var(--success)' : ''
    },
    saveOptionSettings () {
      optionsApi
        .saveOptionSettings(this.articleSettings, this.query)
        .then(data => {
          if (!data) {
            return
          }
          if(JSON.stringify(data?.option_setting) === this.oldOptionSettings){
            return
          }
          this.$emit('changed', data?.option_setting)
          this.oldOptionSettings = JSON.stringify(data?.option_setting)
        })
    },
    getStoreName (store_key){
      return this.storesNames[store_key]
    },
    getProperties () {
      if (!this.article?.properties) {
        return
      }
      let dataString = ''
      const data = this.article['properties']
      for (const key of Object.keys(data).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))) {
        const val =
          '<b>' + formatString(key) + '</b>: ' + data[key].toLocaleString()
        dataString += val + '<br>'
      }
      return dataString
    },
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    load () {
      optionsApi
        .loadOptionData(this.query)
        .then(response => {
          this.articlesStore.setArticle(response.data.data)
          this.loaded = true
        })
        .catch((error) => console.log(error))
    },
    changeAlert () {
      const payload = {
        page: 'option',
        option_name: this.article.option_name,
        option_key: this.query.option_key,
        inbound_key: this.query.inbound_key,
      }

      this.alertDialogVisible = true
      this.alertStore.setOptionAlertData(payload)
    },
    handleClusterClick (storeSetName) {
      this.$emit('clusterClick', storeSetName)
    },
  },
}
</script>

<style lang="scss">
.optionInfo {
    color: var(--text-black);
    height: 100%;
    .tooltip {
        width: 20px;
    }
    .product-card {
        padding: 16px;
        height: 100% !important;
        .product-title {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .icon:hover {
                color: var(--primary);
                cursor: pointer;
            }
        }
        .image-row {
            margin-bottom: 26px;
            display: flex;
            .product-text {
                font-size: 13px;
                margin-left: 16px;
                p {
                    margin: 0;
                }
                .product-title {
                    font-weight: 600;
                }
                .product-subtitle {
                    color: var(--text-sub-black);
                }
                .product-price {
                    margin-top: 6px;
                }
            }
        }
        .inclusions {
            .v-label {
                color: var(--success) !important;
            }
            .v-chip {
                border-color: var(--success);
                color: var(--success);
                background-color: rgba(76, 175, 80, 0.06) !important;
                &:hover {
                    background-color: rgba(76, 175, 80, 0.2) !important;
                }
            }
        }
        .exclusions {
            .v-label {
                color: var(--error) !important;
            }
            .v-chip {
                border-color: var(--error);
                color: var(--error);
                background-color: rgba(255, 87, 33, 0.06) !important;
                &:hover {
                    background-color: rgba(255, 87, 33, 0.2) !important;
                }
            }
        }
        .inclusions,
        .exclusions {
            .v-select__selections {
                min-height: 42px;
            }
            .v-input__append-inner {
                align-self: auto;
            }
            .v-chip {
                margin-left: 0;
                height: 30px;
                border-radius: 15px;
                &:before {
                    display: none;
                }
            }
        }
        .order {
            .v-label {
                color: var(--warning) !important;
            }
            .v-select__selections {
                color: var(--warning);
            }
        }
    }
}

.option-info-variant:hover {
  background-color: #f5f7fa

}
</style>
