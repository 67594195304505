<template>
  <div id="strategy-collection-analyze">
    <v-row class="my-4" justify="center">
      <v-btn-toggle
        v-model="currentVersion"
        mandatory
        dense
        active-class="bg-gray"
        light
        @change="onVersionChange"
      >
        <v-btn
          v-for="(version, index) in versions"
          :key="index"
          small
          color="white"
        >
          <span class="text-capitalize">{{ version }}</span>
        </v-btn>
      </v-btn-toggle>
    </v-row>
    <div
      v-for="(version, index) in versions"
      v-show="index === currentVersion"
      :key="index"
      class="mt-4 mx-4"
      style="position: relative;"
    >
      <StrategyCollectionKPICharts
        v-if="version === 'KPI'"
        class="py-4"
        :referenceYearsWithData="referenceYearsWithData"
        :kpis="kpis"
      />
      <div v-else style="height: 350px">
        <canvas :id="version + 'Chart'"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, maxBy, groupBy } from 'lodash'
import { Chart } from 'chart.js/auto'
import { formatLargeNumber } from '@/utils'
import { chartColors } from '@/variables'
import StrategyCollectionKPICharts from './StrategyCollectionKPICharts'

export default {
  name: 'StrategyCollectionAnalyze',
  components: { StrategyCollectionKPICharts },
  props: {
    chartsData: {
      type: Object || null,
      default: null,
    },
    periodsOptionsGrouped: {
      type: Object,
      default: () => ({}),
    },
    referenceYearsWithData: {
      type: Array,
      default: () => [],
    },
    kpis: {
      type: Object,
      default: () => ({}),
    },
    timeMachineWeeks: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    this.chartOptions = {
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      hover: {
        mode: 'index',
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
            font: {
              size: 14,
            },
          },
          grid: {
            display: false
          },
          ticks: {
            font: {
              size: 14,
            }
          },
          offset: true,
        },
        y: {
          position: 'left',
          title: {
            display: true,
            font: {
              size: 14,
            },
          },
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            callback: (value)=> formatLargeNumber(value),
            padding: 16,
            font: {
              size: 14,
            }
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: context => {
              return this.$options.filters.formatThousands(context.raw)
            },
          }
        },
      },
    }
    this.charts = {
      revenue: null,
      options: null,
      pareto: null,
      depth: null,
      scatter: null,
    }

    return {
      versions: ['KPI', 'revenue', 'options', 'pareto', 'depth', 'scatter'],
      currentVersion: 0,
    }
  },
  watch: {
    'chartsData.weeksChart': {
      handler: function () {
        this.drawWeeksChart()
        this.drawOptionsChart()
      },
    },
    'chartsData.salesChart': {
      handler: function () {
        this.drawSalesChart()
      },
      deep: true,
    },
    'chartsData.depthsChart': {
      handler: function () {
        this.drawDepthsChart()
      },
      deep: true,
    },
    'periodsOptionsGrouped': {
      handler: function () {
        this.drawPeriodsChart()
      },
    },
  },
  computed: {
  },
  methods: {
    onVersionChange () {
      // this.drawCharts()
    },
    drawCharts () {
      this.drawDepthsChart()
    },
    drawWeeksChart () {
      if (this.charts.revenue) {
        this.charts.revenue.destroy()
      }

      const ctx = document.getElementById('revenueChart')
      const chartOptions = cloneDeep(this.chartOptions)

      chartOptions.scales.y.title.text = 'Revenue'

      const revenueDatasets = this.chartsData.weeksChart.filter(d => d.metric === 'Revenue')
      const maxWeeksDataset = maxBy(revenueDatasets, o => o.data.length)

      this.charts.revenue = new Chart(ctx, {
        type: 'line',
        data: {
          labels: maxWeeksDataset.data.map((x, i) => this.timeMachineWeeks[i]),
          datasets: revenueDatasets.map((dataset, i) => {
            const color = chartColors[i % (chartColors.length - 1)]

            return {
              data: dataset.data,
              label: dataset.period,
              borderColor: color,
              borderWidth: 2,
              backgroundColor: color,
              pointRadius: null,
              tension: 0.4,
            }
          })
        },
        options: chartOptions,
      })
    },
    drawOptionsChart () {
      if (this.charts.options) {
        this.charts.options.destroy()
      }

      const ctx = document.getElementById('optionsChart')
      const chartOptions = cloneDeep(this.chartOptions)

      chartOptions.scales.y = {
        position: 'left',
        title: {
          display: true,
          text: 'Options',
          font: {
            size: 14,
          },
        },
        border: {
          display: false,
        },
        ticks: {
          callback: (value)=> formatLargeNumber(value),
          padding: 16,
          font: {
            size: 14,
          }
        }
      }

      const optionsDatasets = this.chartsData.weeksChart.filter(d => d.metric === 'Options')
      const maxWeeksDataset = maxBy(optionsDatasets, o => o.data.length)

      this.charts.options = new Chart(ctx, {
        type: 'line',
        data: {
          labels: maxWeeksDataset.data.map((x, i) => this.timeMachineWeeks[i]),
          datasets: optionsDatasets.map((dataset, i) => {
            const color = chartColors[i % (chartColors.length - 1)]

            return {
              data: dataset.data,
              label: dataset.period,
              borderColor: color,
              borderWidth: 2,
              backgroundColor: color,
              pointRadius: null,
              tension: 0.4,
            }
          })
        },
        options: chartOptions,
      })
    },
    drawSalesChart () {
      if (this.charts.pareto) {
        this.charts.pareto.destroy()
      }

      const ctx = document.getElementById('paretoChart')
      const metricName = 'revenue'

      if (!this.chartsData.salesChart[metricName]) {
        return
      }

      const periods = this.chartsData.salesChart[metricName]
      const chartOptions = cloneDeep(this.chartOptions)
      const labels = periods[Object.keys(periods)[0]].map((p, i) => i)

      chartOptions.scales.x.title.display = true
      chartOptions.scales.x.title.text = 'Options'
      chartOptions.scales.y.title.text = this.$options.filters.formatString(metricName)

      this.charts.pareto = new Chart(ctx, {
        type: 'line',
        data: {
          labels,
          datasets: Object.keys(periods).map((p, i) => {
            const color = chartColors[i % (chartColors.length - 1)]
            return {
              label: p,
              data: this.chartsData.salesChart[metricName][p],
              borderColor: color,
              borderWidth: 2,
              backgroundColor: color,
              pointRadius: null,
              tension: 0.4,
            }
          }),
        },
        options: chartOptions,
      })
    },
    drawDepthsChart () {
      if (this.charts.depth) {
        this.charts.depth.destroy()
      }

      if (!this.chartsData.depthsChart || Object.keys(this.chartsData.depthsChart).length === 0) {
        return
      }

      const ctx = document.getElementById('depthChart')
      const options = cloneDeep(this.chartOptions)

      const uniqueYears = [...new Set(this.chartsData.depthsChart.period)]
      const uniqueDepths = [...new Set(this.chartsData.depthsChart.depth_bucket)]
      const dataObjects = this.chartsData.depthsChart.period
        .map((year, index) => ({
          year,
          depth: this.chartsData.depthsChart.depth_bucket[index],
          options: this.chartsData.depthsChart.options[index],
          revenue: this.chartsData.depthsChart.revenue[index],
          intake_pieces: this.chartsData.depthsChart.pieces[index],
        }))
        .sort((a, b) => a.year - b.year)
      const colors = uniqueYears.reduce((acc, cur, index) => ({
        ...acc,
        [cur]: chartColors[index]
      }), {})

      const dataObjectsByYear = groupBy(dataObjects, 'year')
      const datasets = Object
        .keys(dataObjectsByYear)
        .map(year => ({
          label: year,
          data: dataObjectsByYear[year].map(obj => ({
            x: obj.depth,
            y: obj.intake_pieces,
          })),
          backgroundColor: colors[year],
        }))

      options.scales.y.title.text = 'Pieces'
      options.plugins.tooltip = {
        callbacks: {
          label: context => {
            const dataObject = dataObjectsByYear[context.dataset.label][context.dataIndex]
            const format = this.$options.filters.formatThousands

            return [
              `Pieces: ${format(dataObject.intake_pieces)}`,
              `Options: ${format(dataObject.options)}`,
              `Revenue: ${format(dataObject.revenue)}`,
            ]
          },
        }
      }
      options.barPercentage = 1

      this.charts.depth = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: uniqueDepths,
          datasets,
        },
        options,
      })
    },
    drawPeriodsChart () {
      if (this.charts.scatter) {
        this.charts.scatter.destroy()
      }

      const ctx = document.getElementById('scatterChart')
      const options = cloneDeep(this.chartOptions)

      options.plugins.tooltip.enabled = false
      options.plugins.tooltip.external = this.periodsChartTooltipHandler
      options.scales.x.title.display = true
      options.scales.x.title.text = 'Revenue'
      options.scales.y.title.text = 'Margin'
      options.scales.y.ticks.callback = (value) => {
        return value + '%'
      }

      this.charts.scatter = new Chart(ctx, {
        type: 'scatter',
        data: {
          datasets: Object
            .keys(this.periodsOptionsGrouped)
            .filter(key => key !== 'planned')
            .map((p, i) => ({
              label: p,
              data: this.periodsOptionsGrouped[p].map(o => ({
                x: o.revenue,
                y: o.margin,
              })),
              pointRadius: 5,
              borderColor: chartColors[i],
              borderWidth: 2,
              backgroundColor: `${chartColors[i]}55`,
            }))
        },
        options,
      })
    },
    getOrCreateTooltip (chart) {
      let tooltipEl = chart.canvas.parentNode.querySelector('div')

      if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)'
        tooltipEl.style.borderRadius = '3px'
        tooltipEl.style.color = 'white'
        tooltipEl.style.opacity = 1
        tooltipEl.style.pointerEvents = 'none'
        tooltipEl.style.position = 'absolute'
        tooltipEl.style.transform = 'translate(-50%, 0)'
        tooltipEl.style.transition = 'all .1s ease'
        tooltipEl.classList.add('tooltip')

        chart.canvas.parentNode.appendChild(tooltipEl)
      }

      return tooltipEl
    },
    periodsChartTooltipHandler (context) {
      const { chart, tooltip } = context
      const tooltipEl = this.getOrCreateTooltip(chart)

      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }

      if (tooltip.dataPoints.length) {
        while (tooltipEl.firstChild) {
          tooltipEl.firstChild.remove()
        }

        const dataPoint = tooltip.dataPoints[0]
        const hoveredOption = this.periodsOptionsGrouped[Object.keys(this.periodsOptionsGrouped)[dataPoint.datasetIndex]][dataPoint.dataIndex]
        const nameParts = hoveredOption.name.split(' / ')
        let nameHTML = `<div class="name-line-1">${nameParts[0]}</div>`

        if (nameParts.length === 2) {
          nameHTML += `<div class="name-line-2">${nameParts[1]}</div>`
        }

        tooltipEl.innerHTML += `
          <div class="tooltip-content">
            ${nameHTML}
            <img src="${hoveredOption.image_url}"><br/>
            <b>Year:</b> ${hoveredOption.period}<br/>
            <div class="kpis">
              <div class="kpi-item">
                <i aria-hidden="true" class="v-icon notranslate material-icons theme--light">savings</i>
                <div class="kpi-value">${hoveredOption.revenue}</div>
                </div>
                <div class="kpi-item">
                <i aria-hidden="true" class="v-icon notranslate material-icons theme--light">shopping_basket</i>
                <div class="kpi-value">${hoveredOption.sales_net}</div>
                </div>
                <div class="kpi-item">
                <i aria-hidden="true" class="v-icon notranslate material-icons theme--light">percent</i>
                <div class="kpi-value">${hoveredOption.margin}</div>
              </div>
            </div>
          </div>
        `
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

      tooltipEl.style.opacity = 1
      tooltipEl.style.left = positionX + tooltip.caretX + 'px'
      tooltipEl.style.top = positionY + tooltip.caretY + 'px'
      tooltipEl.style.font = tooltip.options.bodyFont.string
      tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
    },
  }
}
</script>

<style lang="scss">
#strategy-collection-analyze {
  .v-window,
  .v-carousel {
    overflow: visible;
    .v-responsive {
      overflow: visible;
    }
  }
  .tooltip {
    width: 300px;
  }
  .tooltip-content {
    text-align: center;
    img {
      max-width: 100px;
      max-height: 100px;
    }
    .name-line-1 {
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
    }
    .name-line-2 {
      font-size: 12px;
    }
    .kpis {
      display: flex;
      justify-content: space-between;
      .kpi-item {
        padding: 0 12px;
        width: 33.33%;
      }
      .v-icon {
        color: #fff;
      }
    }
  }
}
</style>
