<template>
  <div class="overviewTable">
    <v-row>
      <v-col v-if="overview.every(row=> Object.keys(row).length > 2)">
        <data-tables :data="overview"
          :pagination-show="false"
          :table-props="{
            'row-key': row=> row.Description,
            'tree-props': { children: 'children' }
          }"
        >
          <el-table-column
            prop="Description"
            label="Description"
            align="left"
            width="175px"
            class-name="pl-4"
          >
          </el-table-column>
          <el-table-column
            v-for="(col, idx) in headers"
            :key="String(col) + String(idx)"
            :prop="col"
            :label="col | formatString"
            align="center"
            :sortable="false"
          >
            <template slot-scope="scope">
              <div
                class="d-flex justify-center"
                v-if="scope.row['Description'] === 'Reservation' && col !== 'Prepacks'"
              >
                <v-text-field
                  class="input-row"
                  :class="{
                    'highlight-orange': shouldHighlight(col),
                    'ml-4': col === 'Total'
                  }"
                  :disabled="col === 'Total'"
                  :rules="[reservationRule]"
                  v-model="scope.row[col]"
                  @input="captureSKUs"
                  @change="saveValueDebounce"
                >
                </v-text-field>
                <v-btn
                  class="mt-4"
                  width="20px"
                  v-if="isAnySKUHighlighted && col === 'Total'"
                  @click="removeReservationOnDC"
                  icon
                >
                  <v-icon>
                    delete
                  </v-icon>
                </v-btn>
              </div>
              <div
                v-else
                :style="
                  scope.row['Description'] === 'Percentage in stores'
                    ? {
                      'background-color': color(scope.row[col]),
                      padding: '10px 10px 10px 25px',
                      marginLeft: '-15px',
                      'font-size': '14px',
                    }
                    : {
                      padding: '10px',
                      'font-size': '14px',
                    }
                "
              >
                <span>{{ scope.row[col] }}</span>
              </div>
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
      <v-col v-else class="mx-4 pb-8">
        <span v-if="!isSingleSku">Percentage in stores: {{overview[3][singleSkuProp]}}</span>
        <budget-progress-item
          :progress-data="progressData"
          :show-percentage-in-segment="isSingleSku"
          :exclude-total="isSingleSku"
          :show-legend="isSingleSku"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import BudgetProgressItem from '@/components/Shop/BudgetProgressItem'
import interpolate from 'color-interpolate'
import { useStockProposalStore } from '@/store/pinia/stockProposalStore'
import _ from 'lodash'
import { useArticleStore } from '@/store/pinia/stockArticlesStore'

const colormap = interpolate(['#80cbc4', '#ffe089', '#ff9a9a'])

export default {
  setup () {
    return {
      proposalStore: useStockProposalStore(),
      articlesStore: useArticleStore(),
    }
  },
  components: { BudgetProgressItem },
  data () {
    return {
      capturedSKUs: undefined,
      colormap,
      saveValueDebounce: _.debounce(this.saveValue, 500),
      hideActions: true,
      select: true,
      rowsPerPageItems: [10],
      pagination: {
        rowsPerPage: 10,
      }
    }
  },
  computed: {
    isSingleSku () {
      return this.proposalStore.singleSku
    },
    progressData () {
      if (this.isSingleSku) {
        return this.singleSkyProgressData
      }
      const colors = ['#007AFF', '#FF9800', '#4CAF50']
      return {
        segments: this.overview.slice(0, -1).map((item, index) => {
          return {
            ...item,
            value: parseFloat(item[this.singleSkuProp]),
            color: colors[index],
            caption: item['Description']
          }
        })
      }
    },
    singleSkyProgressData () {
      const segments = [
        {
          caption: 'Total',
          color:  '#000',
        },
        {
          caption: 'Free',
          color: '#4CAF50',
        },
        {
          caption: 'Reservation',
          color: 'grey',
        },
        {
          caption: 'One Stock',
          color: '#007AFF',
        },
        {
          caption: 'Stores',
          color: '#FF9800',
        },
      ]
      const data = [
        ...this.overview[0].children,
        this.overview[1],
        this.overview[2],
      ]
      return {
        segments: segments.map((seg) => {
          const item = data.find(item => item.Description === seg.caption)
          return {
            ...seg,
            value: parseFloat(item[this.singleSkuProp]),
          }
        })
      }
    },
    singleSkuProp () {
      return Object.keys(this.overview[0]).find(item => item !== 'Description')
    },
    overview () {
      // Get all data
      const dcEnd = structuredClone(this.proposalStore.dcStockEnd)
      const storeEnd = structuredClone(this.proposalStore.storeStockEnd)
      const replenishments = structuredClone(this.proposalStore.replenishments)
      const percentage = structuredClone(this.proposalStore.storeStockEnd)
      const oneStock = structuredClone(this.proposalStore.oneStock)
      let reservation = structuredClone(this.articlesStore.articleSettings.stock_parameters?.reservation_per_sku || {})
      const reservationDC = structuredClone(this.proposalStore.reservationOnDc)
      reservation = {...reservationDC, ...reservation}
      const freeStock = structuredClone(this.proposalStore.freeStock)
      const total = {}
      for(const key in dcEnd){
        total[key] = dcEnd[key] + storeEnd[key]
      }
      // Adjust formatting according to requirements
      for (const key in dcEnd) {
        if (storeEnd[key] + dcEnd[key] > 0) {
          percentage[key] = `${parseInt((storeEnd[key] / (storeEnd[key] + dcEnd[key])) * 100)}%`
        } else {
          percentage[key] = '0%'
        }
        storeEnd[key] = `${storeEnd[key]} (${replenishments[key] > 0 ? '+' : ''}${replenishments[key]})`
      }
      oneStock.Description = 'One Stock'
      reservation.Description = 'Reservation'
      // If there are any captured SKUs, update the reservation from the captured SKUs
      // This is needed to prevent user input from being overwritten
      if (this.capturedSKUs) {
        for (const key in reservation) {
          if (this.capturedSKUs[key]) {
            reservation[key] = this.capturedSKUs[key]
          }
        }
      }
      reservation.Total = this.calculateReservationTotal(reservation)
      freeStock.Description = 'Free'
      dcEnd.children = [oneStock, reservation, freeStock]
      // Add description column
      dcEnd['Description'] = 'Distribution center'
      storeEnd['Description'] = 'Stores'
      percentage['Description'] = 'Percentage in stores'
      total['Description'] = 'Total'

      return [dcEnd, storeEnd, total, percentage]
    },
    headers () {
      const h = this.proposalStore.proposalTableColumns
      // Remove columns that are not needed here
      const idx = h.indexOf('Store')
      if (idx !== -1) {
        h.splice(idx, 1)
      }
      const idxColumn = h.indexOf('Cover')
      if (idxColumn !== -1) {
        h.splice(idxColumn, 1)
      }
      return h
    },
    isAnySKUHighlighted () {
      return this.headers.some(this.shouldHighlight)
    },
  },
  methods: {
    // Capture reservation input values to avoid it being overwritten on save
    captureSKUs () {
      this.capturedSKUs = structuredClone(this.overview[0].children[1])
    },
    reservationRule: (value) => {
      if (!value) {
        return true
      }
      value = Number(value)
      if (Number.isNaN(value)) {
        return 'Enter a number'
      }
      if (value < 0) {
        return 'Enter a positive number'
      }
      return true
    },
    removeReservationOnDC () {
      const newSettings = {
        ...this.articlesStore.articleSettings,
        stock_parameters: {
          ...this.articlesStore.articleSettings.stock_parameters,
          reservation_per_sku: {},
        },
      }
      this.articlesStore.saveOptionSettings(newSettings, this.$route.query).then(() => {
        return this.proposalStore.loadProposal({
          route: {...this.$route.query}
        })
      }).catch(console.error)
    },
    shouldHighlight (col) {
      if (!this.articlesStore.articleSettings?.stock_parameters) {
        return false
      }
      if (col === 'Total') {
        return false
      }
      return Object.keys(this.articlesStore.articleSettings.stock_parameters?.reservation_per_sku || {}).includes(col)
    },
    setReservationTotal () {
      const total = this.calculateReservationTotal()
      this.$set(this.overview[0].children[1], 'Total', total)
    },
    isReservationInputValid () {
      for (const key in this.overview[0].children[1]) {
        const value = this.overview[0].children[1][key]
        if (key !== 'Description' && key !== 'Total') {
          if(this.reservationRule(value) !== true) {
            return false
          }
        }
      }
      return true
    },
    saveValue () {
      this.setReservationTotal()
      const updatedSKUKeys = Object.keys(this.overview[0].children[1]).filter(k => k !== 'Description' && k !== 'Total')
      // Validate
      if (!this.isReservationInputValid()) {
        return
      }
      const updatedSKUs = updatedSKUKeys.reduce((acc, key) => {
        let value = this.overview[0].children[1][key]
        if (!value) {
          value = 0
        }
        acc[key] = value
        return acc
      }, {})
      const newSettings = {
        ...this.articlesStore.articleSettings,
        stock_parameters: {
          ...this.articlesStore.articleSettings.stock_parameters,
          reservation_per_sku: {
            ...updatedSKUs,
          },
        },
      }
      this.articlesStore.saveOptionSettings(newSettings, this.$route.query).then(() => {
        return this.proposalStore.loadProposal({
          route: {...this.$route.query}
        })
      }).catch(console.error)
    },
    calculateReservationTotal (res) {
      const reservation = res || this.overview[0].children[1]
      let total = 0
      for (const k in reservation) {
        if (k !== 'Description' && k !== 'Total' && reservation[k]) {
          const numVal = Number(reservation[k])
          if (isNaN(numVal)) {
            continue
          }
          reservation[k] = numVal
          total += reservation[k]
        }
      }
      return total
    },
    color (val) {
      let result = 0
      if (val == null) {
        return '#fff'
      } else {
        val = parseInt(val.replace('%', ''))
        result = Math.min(1, Math.max(0, (val - 60) / 40))
      }
      try {
        return colormap(result)
      } catch (error) {
        return '#fff'
      }
    },
  },
}
</script>

<style lang="scss">

.overviewTable {
    .el-table--border::after, .el-table--group::after, .el-table::before {
        background-color: inherit !important;
    }
    .el-table td {
        height: 45px;
        padding: 0;
        border-bottom: none;
    }
    .el-table .cell {
      padding: 0 0 0 14px;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td {
        background-color: #fff;
    }

  .el-table__row.el-table__row--level-1 {
    background-color: #f5f7fa;
  }

  .input-row {
    &.highlight-orange {
      input {
        color: #ff9800 !important;
      }
    }
    max-width: 60%;
    input {
      text-align: center!important;
      color: #606266;
      font-size: 14px;
    }
  }
}
</style>
