<template>
  <div id="strategy-collection-page" class="p-relative pt-0 mb-12">
    <v-overlay absolute color="white" :value="loading || timeMachineDataLoading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="align-baseline mt-3 mb-3">
      <v-col cols="3" class="py-0">
        <h3>Collection</h3>
      </v-col>
      <v-spacer />
      <v-col md="2" cols="4" class="py-0">
        <StrategyCubeSelect
          ref="cubeSelect"
          @cube-inputs-loaded="handleCubeInputsLoaded"
          @cube-inputs-changed="handleCubeInputsChanged"
        />
      </v-col>
    </v-row>

    <v-card class="pt-4" min-height="100vh">
      <div class="px-6 pb-2">
        <template v-if="filtersDimensions.length">
          <v-row class="align-center">
            <v-col cols="11" class="pb-0">
              <StrategyCollectionFilters
                ref="filters"
                :dimensions="filtersDimensionsVisible.filter(d => !storeDimensions.includes(d.name))"
                @filters-loaded="handleFiltersLoaded"
                @filters-changed="handleFiltersChanged"
              />
            </v-col>
          </v-row>
        </template>

        <div class="d-flex align-center mt-6 mb-4">
          <h6 class="text-subtitle-1 font-weight-medium">Analyze</h6>
          <v-btn icon @click="toggleSectionVisibility('analyze')">
            <v-icon v-if="sectionsVisible.analyze">expand_more</v-icon>
            <v-icon v-else>chevron_right</v-icon>
          </v-btn>
        </div>
        <StrategyCollectionAnalyze
          ref="analyze"
          v-show="sectionsVisible.analyze"
          :chartsData="chartsData"
          :periodsOptionsGrouped="periodsOptionsGrouped"
          :kpis="kpis"
          :timeMachineWeeks="timeMachineWeeks"
          class="mt-4"
        />

        <template v-if="subDimensions.length">
          <div class="d-flex align-center mt-6 mb-4">
            <h6 class="text-subtitle-1 font-weight-medium">Overview</h6>
            <v-btn icon @click="toggleSectionVisibility('overview')">
              <v-icon v-if="sectionsVisible.overview">expand_more</v-icon>
              <v-icon v-else>chevron_right</v-icon>
            </v-btn>
          </div>
          <StrategyCollectionOverview
            ref="overview"
            v-show="sectionsVisible.overview"
            :cubeProperties="cubeProperties"
            :subDimensions="subDimensions"
            :referenceYears="referenceYears"
            :overviewData="overviewData"
            @input-dimensions:changed="handleOverviewInputDimensionsChanged"
            @selected-row:changed="handleOverviewSelectedRowChanged"
            @output-dimensions:changed="handleOverviewOutputDimensionsChanged"
            @selected-metrics:changed="handleOverviewSelectedMetricsChanged"
            @totals:changed="handleOverviewTotalsChanged"
            @generate-products:clicked="handleGenerateProductsClicked"
          />
        </template>

        <div class="d-flex align-center mt-6 mb-4">
          <h6 class="text-subtitle-1 font-weight-medium">Products</h6>
          <v-btn icon @click="toggleSectionVisibility('products')">
            <v-icon v-if="sectionsVisible.products">expand_more</v-icon>
            <v-icon v-else>chevron_right</v-icon>
          </v-btn>
        </div>
        <StrategyCollectionProducts
          v-show="sectionsVisible.products"
          :products="products"
          @product-selected="handleSelectItemRowChange ($event)"
          @products-selected="handleSelectItemsRowChange ($event)"
          @products-updated="$event.forEach(updateSingleProduct)"
          @updated-chunk="loadUpdatedProduct"
          :analyticsProperties="analyticsProperties"
          :options-properties="options.properties || []"
          @product-deleted="handleProductDeleted"
        />

        <div class="d-flex align-center mt-6 mb-4">
          <h6 class="text-subtitle-1 font-weight-medium">Matrix</h6>
          <v-btn icon @click="toggleSectionVisibility('matrix')">
            <v-icon v-if="sectionsVisible.matrix">expand_more</v-icon>
            <v-icon v-else>chevron_right</v-icon>
          </v-btn>
        </div>
        <StrategyCollectionMatrix
          v-show="sectionsVisible.matrix"
          ref="matrix"
          :options-properties="options.properties || []"
          :periods-data="periodsData"
          :periods-options-grouped="periodsOptionsGrouped"
          :analyticsProperties="analyticsProperties"
          @selected-period1:changed="handleMatrixPeriod1Changed"
          @selected-period2:changed="handleMatrixPeriod2Changed"
          @item:dropped="handleMatrixRowDropped"
          @product-deleted="handleProductDeleted"
        />
      </div>
    </v-card>
    <div id="for-tooltip"></div>
    <v-card @click="reloadPage" class="info-icon">
      <v-tooltip top left attach="#for-tooltip" :max-width="500"  min-width="150" v-if="!actionsToApply">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="success" size="48" >check_circle_outline</v-icon>
        </template>
        <span>No pending changes</span>
      </v-tooltip>
      <v-tooltip  top left attach="#for-tooltip" :max-width="500" min-width="150" v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="warning" size="48" >error_outline</v-icon>
        </template>
        <span>Products updated, click to refresh data</span>
      </v-tooltip>
    </v-card>
  </div>
</template>

<script>
import { cloneDeep, groupBy, last, uniq } from 'lodash'
import {
  generateProducts,
  getCollectionOverview,
  getStrategyCollectionProduct,
  getStrategyCollectionProducts,
  getTimeMachineDataTSQL,
} from '@/api/strategy'
import { getStatusColorClass } from '@/utils'
import { loadFilterProperties } from '@/api/analytics'
import StrategyCubeSelect from '@/components/Strategy/StrategyCubeSelect'
import StrategyCollectionFilters from '@/components/Strategy/StrategyCollectionFilters'
import StrategyCollectionOverview from '@/components/Strategy/StrategyCollectionOverview'
import StrategyCollectionAnalyze from '@/components/Strategy/StrategyCollectionAnalyze'
import StrategyCollectionMatrix from '@/components/Strategy/CollectionMatrix/StrategyCollectionMatrix'
import StrategyCollectionProducts from '@/components/Strategy/StrategyCollectionProducts'
import { useStrategyFiltersStore } from '@/store/pinia/strategyFiltersStore'
import {useGeneralStore} from '@/store/pinia/generalStore'

export default {
  setup () {
    return {
      strategyFiltersStore: useStrategyFiltersStore()
    }
  },
  name: 'StrategyCollectionPage',
  components: { StrategyCubeSelect, StrategyCollectionFilters, StrategyCollectionOverview, StrategyCollectionAnalyze, StrategyCollectionMatrix, StrategyCollectionProducts },
  data () {
    this.sectionsVisibleDefaults = {
      overview: true,
      analyze: true,
      matrix: true,
      products: true,
    }

    return {
      loading: false,
      actionsToApply: false,
      sectionsVisible: { ...this.sectionsVisibleDefaults },
      timeMachineDataLoading: false,
      filtersDimensions: [],
      subDimensions: [],
      storeDimensions: [],
      cubeProperties: [],
      referenceYears: [],
      filtersLoaded: false,
      analyticsProperties: {},
      filtersValues: {},
      chartsData: {
        weeksChart: null,
        salesChart: null,
        depthsChart: null,
        gridChart: null,
      },
      timeMachineWeeks: [],
      periodsData: [],
      kpis: null,
      options: [],
      periodsOptionsGrouped: null,
      products: [],
      overviewData: [],
      overviewOutputDimensions: {},
      selectedProduct: null,
    }
  },
  computed: {
    filtersOpened () {
      return this.strategyFiltersStore.filtersOpened
    },
    filtersDimensionsVisible () {
      return this.filtersDimensions.filter(d => d.name !== 'store_keys')
    },
  },
  watch: {
    async filtersOpened (newValue) {
      const state = this.strategyFiltersStore

      if (!newValue && state.filtersChanged) {
        if (state.period) {
          this.$refs.filters.setWeeksFromPeriod(state.period)
        }

        this.loading = true

        try {
          await Promise.all([
            this.loadTimeMachineData(),
            this.loadProducts(),
            this.loadCollectionOverview(),
          ])
        } finally {
          this.loading = false
        }
      }
    },
    options () {
      this.periodsOptionsGrouped = groupBy(this.buildOptionsObjects(this.options), 'period')
      this.addPlannedOptions()
    },
    'sectionsVisible.matrix' () {
      setTimeout(this.$refs['matrix'].alignTables, 100)
    },
  },
  methods: {
    async reloadPage (){
      if(!this.actionsToApply){
        return
      }

      this.loading = true

      try {
        await Promise.all([
          this.loadTimeMachineData(),
          this.loadCollectionOverview(),
        ])
      } finally {
        this.loading = false
        this.actionsToApply = false
      }
    },
    handleSelectItemRowChange ({value, key}){
      const index = this.products.findIndex((item)=> item.product_key === key)
      this.products[index].selected = value
      this.products = [...this.products]
    },
    handleSelectItemsRowChange ({value, keys}){
      keys.forEach((key)=> this.handleSelectItemRowChange({value, key}))
    },
    async handleCubeInputsLoaded ({ dimensions, sub_dimensions, store_dimensions, reference_years, properties }) {
      this.filtersDimensions = dimensions
      this.subDimensions = sub_dimensions
      this.storeDimensions = store_dimensions
      this.referenceYears = reference_years
      this.cubeProperties = properties
        .map(p => p.name)
        .filter(p => p.includes('/'))
    },
    async handleCubeInputsChanged () {
      this.loading = true

      try {
        await Promise.all([
          this.loadTimeMachineData(),
          this.loadCollectionOverview(),
        ])
      } finally {
        this.loading = false
      }
    },
    async handleFiltersLoaded (values) {
      this.filtersLoaded = true
      this.filtersValues = values
      this.loading = true

      try {
        await Promise.all([
          this.loadTimeMachineData(),
          this.loadProducts(),
        ])
      } finally {
        this.loading = false
      }
    },
    async handleFiltersChanged (values) {
      this.filtersValues = values
      this.overviewOutputDimensions = {}
      this.loading = true

      try {
        await Promise.all([
          this.loadTimeMachineData(),
          this.loadProducts(),
          this.loadCollectionOverview(),
        ])
      } finally {
        this.loading = false
      }
    },
    async handleOverviewInputDimensionsChanged () {
      this.$refs['overview'].loading = true

      try {
        await this.loadCollectionOverview()
      } finally {
        this.$refs['overview'].loading = false
      }
    },
    handleOverviewSelectedRowChanged (row) {
      this.selectedProduct = row
      this.updateKpis()
    },
    async handleOverviewOutputDimensionsChanged (dimensions) {
      this.overviewOutputDimensions = { ...dimensions }
      this.loading = true

      try {
        await Promise.all([
          this.loadTimeMachineData(),
          this.loadProducts(),
        ])
      } finally {
        this.loading = false
      }
    },
    async handleOverviewSelectedMetricsChanged () {
      this.updateKpis()
    },
    async handleOverviewTotalsChanged () {
      this.updateKpis()
    },
    async handleGenerateProductsClicked ({ rows, isTotal }) {
      const cube = localStorage.getItem('strategyCubeKey')
      const version = localStorage.getItem('strategyVersionKey')
      const fills = rows.map(row => {
        const result = {
          original_value: row.budget.original_value,
          cost_value: row.budget.cost_value,
          pieces: row.budget.pieces,
          width: row.budget.width,
        }

        this.subDimensions.forEach(sd => {
          result[`${sd}/value`] = row.budget[`${sd}/value`]
        })

        return result
      })

      const payload = {
        cube_key: cube,
        version_key: version,
        dimensions: this.subDimensions,
        week_min: this.filtersValues.week_min,
        week_max: this.filtersValues.week_max,
        fills,
      }

      this.loading = true

      try {
        await generateProducts(payload)

        if (isTotal) {
          await Promise.all([
            this.loadCollectionOverview(),
            this.loadProducts(),
          ])
        } else {
          const response = await this.loadCollectionOverview(['collection'])
          const newOverviewData = cloneDeep(this.overviewData)
          const respRow = response.overview.data.find(row => this.subDimensions.every(sd => row[`${sd}/value`] === rows[0][`${sd}/value`]))

          if (rows[0].budget.width !== 0 && !respRow) {
            console.error('No collection row found in response')
          }

          if (respRow) {
            newOverviewData[rows[0].rowIndex].collection = {
              ...newOverviewData[rows[0].rowIndex].collection,
              ...respRow.collection,
            }
          } else if (rows[0].budget.width === 0) {
            newOverviewData[rows[0].rowIndex].collection = {
              ...newOverviewData[rows[0].rowIndex].collection,
              ...{
                cost_value: 0,
                original_value: 0,
                original_value_ex_vat: 0,
                pieces: 0,
                width: 0,
              }
            }
          }

          this.overviewData = Object.freeze(newOverviewData)
          this.$nextTick(() => {
            this.$refs['overview'].setSelectedRowByIndex(rows[0].rowIndex)
          })
        }
      } finally {
        this.loading = false
      }
    },
    async handleMatrixPeriod1Changed () {
      this.loading = true

      try {
        await this.loadTimeMachineData()
      } finally {
        this.loading = false
      }
    },
    async handleMatrixPeriod2Changed () {
      this.loading = true

      try {
        await this.loadTimeMachineData()
      } finally {
        this.loading = false
      }
    },
    async handleMatrixRowDropped () {
      this.loading = true

      try {
        await this.loadProducts()
        this.addPlannedOptions()
      } finally {
        this.loading = false
      }

      this.actionsToApply = true
    },
    handleProductDeleted (product) {
      this.products = this.products.filter(p => p.product_key !== product.product_key)
      this.addPlannedOptions()
      this.actionsToApply = true
    },
    async loadTimeMachineData () {
      const refYears = [];
      ['selectedPeriod1', 'selectedPeriod2'].forEach(period => {
        if (this.$refs['matrix'][period] !== 'planned') {
          refYears.push(this.$refs['matrix'][period])
        }
      })

      const payload = {
        sales_min: 0,
        stock_min: 100,
        week_min: this.filtersValues.week_min,
        week_max: this.filtersValues.week_max,
        reference_years: refYears,
        filters: {},
      }
      const companyFilters = this.getCompanySpecificFiltersPayload()
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}
      const cube = localStorage.getItem('strategyCubeKey')
      const version = localStorage.getItem('strategyVersionKey')

      for (const key in companyFilters) {
        this.addPayloadFilter(payload, key, companyFilters[key])
      }

      for (const key in this.overviewOutputDimensions) {
        this.addPayloadFilter(payload, key, this.overviewOutputDimensions[key], true)
      }

      for (const key in additionalFilters) {
        if (payload.filters[key]) {
          payload.filters[key] = uniq([
            ...payload.filters[key],
            ...additionalFilters[key]
          ])
        } else {
          payload.filters[key] = additionalFilters[key]
        }
      }
      try {
        let timeMachineData = await getTimeMachineDataTSQL(payload, cube, version)

        console.log(timeMachineData)
        if (typeof timeMachineData === 'string') {
          // TODO: There is a bug in the API that returns an invalid JSON string, which included NaN values
          timeMachineData = timeMachineData.replace(/NaN/g, '0')
          try {
            timeMachineData = JSON.parse(timeMachineData)
          } catch (e) {
            console.error('Error parsing time machine data', e)
            return
          }
        }

        this.timeMachineWeeks = Object.freeze(timeMachineData.weeks.week)
        this.chartsData.weeksChart = Object.freeze(this.prepareWeeksChartData(timeMachineData.weeks))
        this.chartsData.salesChart = Object.freeze({
          sales_net: timeMachineData.pareto?.sales_net,
          revenue: timeMachineData.pareto?.revenue,
        })
        this.chartsData.depthsChart = Object.freeze(timeMachineData.depths)
        this.chartsData.gridChart = Object.freeze(timeMachineData.grid)
        this.periodsData = Object.freeze(timeMachineData.periods.data)
        this.options = Object.freeze(timeMachineData.options)
      }catch (e) {
        if(e.code === 'ERR_NETWORK'){
          useGeneralStore().setSnack('error', 'Dataset too large for return, please adjust your filters')
        }

      }
    },
    async loadCollectionOverview (sources = null) {
      const cube = localStorage.getItem('strategyCubeKey')
      const version = localStorage.getItem('strategyVersionKey')
      const payload = {
        week_min: this.filtersValues.week_min,
        week_max: this.filtersValues.week_max,
        cube_key: cube,
        version_key: version,
        dimensions: this.$refs['overview'].selectedDimensions,
        filters: {},
      }

      if (sources) {
        payload.sources = sources
      }

      const companyFilters = this.getCompanySpecificFiltersPayload()
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}

      for (const key in companyFilters) {
        this.addPayloadFilter(payload, key, companyFilters[key])
      }

      for (const key in additionalFilters) {
        if (payload.filters[key]) {
          payload.filters[key] = uniq([
            ...payload.filters[key],
            ...additionalFilters[key]
          ])
        } else {
          payload.filters[key] = additionalFilters[key]
        }
      }
      try {

        const response = await getCollectionOverview(payload)

        if (sources) {
          return response
        }

        this.overviewData = Object.freeze(response.overview.data)
      }catch (e) {
        if(e.code === 'ERR_NETWORK'){
          useGeneralStore().setSnack('error', 'Dataset too large for return, please adjust your filters')
        }
      }
    },
    async loadProducts () {
      const payload = this.getPayload()
      const products = await getStrategyCollectionProducts(payload)

      this.products = products.map((p, i) => {

        return {
          ...p,
          rowKey: `${p.product_key}-${i}`,
        }
      })
    },
    async loadUpdatedProduct (product_key){
      const { week_min, week_max } = this.getNonEmptyFiltersPayload()
      const [updatedProduct] = await getStrategyCollectionProduct({
        product_key,
        week_min,
        week_max,
      })
      this.updateSingleProduct({...updatedProduct})
    },
    updateSingleProduct (updatedProduct){
      const index = this.products.findIndex(item=> item.product_key === updatedProduct.product_key)
      this.$set(this.products, index, updatedProduct)
      this.actionsToApply = true
    },
    addPayloadFilter (payload, key, value, replaceExistingKeys = false) {
      const keyParts = key.split('/')

      if (keyParts.length === 1) {
        if (replaceExistingKeys || !payload.filters[keyParts[0]]) {
          payload.filters[keyParts[0]] = value
        } else {
          payload.filters[keyParts[0]] = uniq([
            ...payload.filters[keyParts[0]],
            ...value
          ])
        }
      } else {
        if (key.endsWith('/value')) {
          if (replaceExistingKeys || !payload.filters[keyParts[0]]) {
            payload.filters[keyParts[0]] = value
          } else {
            payload.filters[keyParts[0]] = uniq([
              ...payload.filters[keyParts[0]],
              ...value
            ])
          }
        } else {
          if (replaceExistingKeys || !payload.filters[keyParts[1]]) {
            payload.filters[keyParts[1]] = value
          } else {
            payload.filters[keyParts[1]] = uniq([
              ...payload.filters[keyParts[1]],
              ...value
            ])
          }
        }
      }
    },
    getPayload () {
      const { week_min, week_max, ...filters } = this.getNonEmptyFiltersPayload()
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}
      const payload = {
        filters: {},
        week_min,
        week_max,
      }

      for (const key in filters) {
        this.addPayloadFilter(payload, key, filters[key])
      }

      for (const key in additionalFilters) {
        this.addPayloadFilter(payload, key, additionalFilters[key])
      }

      for (const key in this.overviewOutputDimensions) {
        this.addPayloadFilter(payload, key, this.overviewOutputDimensions[key], true)
      }

      delete payload.filters.week_numbers

      return payload
    },
    updateKpis () {
      const metrics = this.$refs['overview'].selectedMetrics
      const data = this.selectedProduct ? this.selectedProduct : this.$refs['overview'].overviewTableTotals

      this.kpis = {
        metrics,
        periodsData: data,
      }
    },
    buildOptionsObjects (input) {
      const options = []

      input.columns.forEach(col => {
        input.data[col].forEach((val, index) => {
          if (options[index]) {
            options[index][col] = val
          } else {
            options.push({
              [col]: val
            })
          }
        })
      })

      return options
    },
    addPlannedOptions () {
      // adjust planned product object properties to match other periods product objects properties
      this.periodsOptionsGrouped = Object.freeze({
        ...this.periodsOptionsGrouped,
        planned: this.products.map(prod => ({
          ...prod,
          name: prod.product_name,
          intake_pieces: prod.pieces,
          revenue: prod.value,
          ...Object.keys(prod.properties).reduce((acc, cur) => ({
            ...acc,
            [`properties/article/${cur}`]: prod.properties[cur],
          }), {}),
          statusColorClass: getStatusColorClass(prod, '--text'),
        }))
      })
    },
    getNonEmptyFiltersPayload () {
      const filtersPayload = {}

      Object.keys(this.filtersValues).forEach(name => {
        if (this.filtersValues[name]) {
          if (!Array.isArray(this.filtersValues[name]) || this.filtersValues[name].length) {
            filtersPayload[name] = this.filtersValues[name]
          }
        }
      })

      return filtersPayload
    },
    getCompanySpecificFiltersPayload () {
      const { store_keys, week_numbers, week_min, week_max, ...payload } = this.getNonEmptyFiltersPayload()

      return payload
    },
    prepareWeeksChartData (input) {
      const datasets = []
      let revenueData = null
      let optionsData = null

      input.period.forEach((period, i) => {
        const lastDataset = last(datasets)

        if (!lastDataset || lastDataset.period !== period) {
          datasets.push({
            period,
            metric: 'Revenue',
            data: []
          })
          revenueData = last(datasets).data

          datasets.push({
            period,
            metric: 'Options',
            data: []
          })
          optionsData = last(datasets).data
        }

        revenueData.push(input.revenue[i])
        optionsData.push(input.options[i])
      })

      return datasets
    },
    toggleSectionVisibility (section) {
      this.sectionsVisible[section] = !this.sectionsVisible[section]
      localStorage.setItem('strategyCollectionSectionsVisible', JSON.stringify(this.sectionsVisible))
    },
  },
  async created () {
    this.analyticsProperties = await loadFilterProperties('skus')
    this.sectionsVisible = JSON.parse(localStorage.getItem('strategyCollectionSectionsVisible')) || this.sectionsVisibleDefaults
  },
}
</script>

<style lang="scss">
#strategy-collection-page {

  .info-icon {
    position: fixed;
    bottom: 36px;
    right: 36px;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
    z-index: 4;
  }
  #for-tooltip {
    position: fixed;
    bottom: 172px;
    right: 172px;
  }
  .settings-menu {
    .input-parameter {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      border-bottom: 1px solid var(--border-color);
      padding: 0 16px;
      .parameter-name {
        width: 200px;
      }
      .parameter-value {
        width: 250px;
        padding: 8px 0;
      }
      .slider {
        padding-top: 4px;
        .slider-value-from,
        .slider-value-to {
          color: #666;
          font-size: 14px;
        }
      }
      &:last-child {
        padding-bottom: 8px;
      }
    }
  }
}
</style>
