<template>
  <div class="transfer-build-form py-8 pr-4 pl-4 p-relative">
    <v-overlay absolute color="white" :value="stockTransfersStore.buildingTransfers || stockTransfersStore.acceptingAllTransfers">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-row>
      <v-col :cols="5">
        <v-row>
          <v-col class="py-0" cols="6">
            <StoresSelectByProperties
              class="stores-select"
              show-stores-select
              :selected-stores.sync="formData.fromStores"
              @search-results-changed="updateFormData"
            />
          </v-col>
          <v-col class="py-0"  cols="6">
            <StoresSelectByProperties
              label="To stores"
              class="stores-select"
              show-stores-select
              :selected-stores.sync="formData.toStores"
              @search-results-changed="updateFormData"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <!-- very customized select to display only amount of options -->
            <v-select
              :value="optionsList"
              :items="[optionsList.length ? optionsList[0] : []]"
              label="Options"
              multiple
              chips
              readonly
              small-chips
            >
              <template v-slot:append>
                <v-icon @click="handleOptionsEditClick">edit</v-icon>
              </template>
              <template v-if="optionsList.length" v-slot:selection>
                <v-chip small outlined>
                  <span>{{ optionsList[0] }}</span>
                </v-chip>
                <span v-if="optionsList.length > 1" class="grey--text caption ml-1">
                  +{{ optionsList.length - 1 }}
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="6" class="py-0">
            <v-select
              v-model="formData.method"
              label="Method"
              @change="updateFormData"
              :items="[{
                value: 'all',
                text: 'All',
              }, {
                value: 'proposal',
                text: 'Proposal',
              }, {
                value: 'fairshare',
                text: 'Fairshare',
              }]"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }" />
          </v-col>
          <v-col :cols="6" class="py-0">
            <v-text-field
              v-model.number="formData.delta"
              label="Delta"
              @input="formData.delta = $event || 0"
              type="number"
              hide-details
              @change="updateFormData"
            >
              <template v-slot:append-outer>
                <v-tooltip top :max-width="250">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                  </template>
                  <span>Amount of stock kept = chosen method + margin</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col :cols="6" class="py-0">
            <v-select
              v-model="formData.to_method"
              label="To method"
              @change="updateFormData"
              :items="[{
                value: 'all',
                text: 'All',
              }, {
                value: 'proposal',
                text: 'Proposal',
              }, {
                value: 'fairshare',
                text: 'Fairshare',
              }, {
                value: 'goal',
                text: 'Goal',
              }]"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }" />
          </v-col>
          <v-col :cols="6" class="py-0">
            <v-text-field
              v-model.number="formData.to_delta"
              @change="updateFormData"
              label="To delta"
              type="number"
              @input="formData.to_delta = $event || 0"

              hide-details
            >
              <template v-slot:append-outer>
                <v-tooltip top :max-width="250">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                  </template>
                  <span>Amount of stock received = chosen method + margin</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col class="py-0">
            <v-select
              @change="updateFormData"
              v-model="formData.split"
              label="Split"
              :items="[{
                value: 'even',
                text: 'Even',
              }, {
                value: 'fairshare',
                text: 'Fairshare',
              }]"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            >
              <template v-slot:append-outer>
                <v-tooltip top :max-width="250">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                  </template>
                  <span>How to split stock across receiving stores</span>
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right pt-8">
        <v-btn
          :color="isAccepted ? 'error': 'primary'"
          depressed
          outlined
          class="ml-4"
          :disabled="buildBtnDisabled"
          @click="handleBuildClick"
        >
          <span v-if="isAccepted">Reset Build</span>
          <span v-else>Build</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'

import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'
import StoresSelectByProperties from '@/components/StoresSelectByProperties.vue'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore(),
      stockTransfersStore: useStockTransfersStore(),
    }
  },
  name: 'TransferBuildForm',
  components: {
    StoresSelectByProperties,
  },
  props: {
    activeType: {
      type: String,
      default () {
        return 'Combinations'
      }
    }
  },
  data () {
    return {
      propertyListFrom: [],
      propertyListTo: [],
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    ...mapState(useStoreAndOptionSetsStore, ['storeSets']),
    stores () {
      return (this.appConfig?.stores || [])
    },
    formData: {
      get () {
        return this.stockTransfersStore.transfersBuildForm
      },
      set (val) {
        this.stockTransfersStore.setBuildForm(val)
        this.stockTransfersStore.transfersBuildForm = val
      },
    },
    optionsList () {
      return [ ...this.filtersStore.selectedFiltersOptions.options ]
    },
    isAccepted () {
      return this.stockTransfersStore.transferTablesData?.build?.data?.some(item => item.accepted)
    },
    buildBtnDisabled () {
      return !this.formData.fromStores.length || !this.formData.toStores.length
    },
  },
  methods: {
    updateFormData () {
      setTimeout(() => {
        this.stockTransfersStore.setBuildForm(this.formData)
      }, 100)
    },
    resetForm () {
      this.stockTransfersStore.resetBuildForm()
    },
    propertyValuesFrom (listItem) {
      if (listItem.selectedProperty === 'Store sets') {
        return this.storeSets.map(item => ({
          store_keys: item.store_keys, label: item.set_name, value: item.stores_set_key
        }))
      }
      const values = new Set()
      this.stores.forEach((item) => {
        if (item.properties[listItem.selectedProperty] !== undefined) {
          const value = item.properties[listItem.selectedProperty]
          values.add(value)
        }
      })

      return ([...values]).map(item => ({ value: item, text: item }))
    },
    onPropDeleteClickFrom (index) {
      this.propertyListFrom.splice(index, 1)
      this.handleValuesChangeFrom()
    },
    handleValuesChangeFrom () {
      const data = this.stores.filter(item => {
        let contains = this.propertyListFrom.filter(item => item.selectedValue.length > 0).length > 0
        this.propertyListFrom.forEach((listItem) => {
          if (listItem.selectedProperty === 'Store sets') {
            listItem.selectedValue.forEach((storesList) => {
              if (storesList.store_keys.indexOf(item.store_key) === -1) {
                contains = false
              }
            })
          } else if (!listItem.selectedValue.includes(item.properties[listItem.selectedProperty])) {
            contains = false
          }
        })
        return contains
      })
      const set = new Set([...data.map(item => item.store_key)])
      this.formData.fromStores = [...set]
    },
    propertyValuesTo (listItem) {
      if (listItem.selectedProperty === 'Store sets') {
        return this.storeSets.map(item => ({
          store_keys: item.store_keys, label: item.set_name, value: item.stores_set_key
        }))
      }
      const values = new Set()
      this.stores.forEach((item) => {
        if (item.properties[listItem.selectedProperty] !== undefined) {
          const value = item.properties[listItem.selectedProperty]
          values.add(value)
        }
      })

      return ([...values]).map(item => ({ value: item, text: item }))
    },
    onPropDeleteClickTo (index) {
      this.propertyListTo.splice(index, 1)
      this.handleValuesChangeTo()
    },

    handleValuesChangeTo () {
      const data = this.stores.filter(item => {
        let contains = this.propertyListTo.filter(item => item.selectedValue.length > 0).length > 0
        this.propertyListTo.forEach((listItem) => {
          if (listItem.selectedProperty === 'Store sets') {
            listItem.selectedValue.forEach((storesList) => {
              if (storesList.store_keys.indexOf(item.store_key) === -1) {
                contains = false
              }
            })
          } else if (!listItem.selectedValue.includes(item.properties[listItem.selectedProperty])) {
            contains = false
          }
        })
        return contains
      })
      const set = new Set([...data.map(item => item.store_key)])
      this.formData.toStores = [...set]
    },
    buildTransfers () {
      const params = {
        store_keys: this.formData.fromStores,
        to_store_keys: this.formData.toStores,
        method: this.formData.method,
        delta: this.formData.delta || 0,
        to_method: this.formData.to_method,
        to_delta: this.formData.to_delta || 0,
        split: this.formData.split || '',
        filters: {},
        view: this.activeType.toLowerCase()
      }
      const filters = {}
      const pageFilters = this.filtersStore.getPageFiltersNonDefaultValues()
      filters['option_key'] = pageFilters['options']

      params.filters = filters

      this.stockTransfersStore.buildTransfers(params)
        .then(() => {
          this.stockTransfersStore.setTransfersBuildVisible(true)
        })
    },
    handleBuildClick () {
      if (this.stockTransfersStore.detailsRowExpanded.build) {
        this.stockTransfersStore.setTransferDetailsData([])
        this.stockTransfersStore.setDetailsRowExpanded('build', false)
      }
      this.buildTransfers()
    },
    handleOptionsEditClick () {
      this.filtersStore.setFiltersOpened(true)
      this.filtersStore.setOptionsSearchVisible(true)
    },
  },
  created () {
    useStoreAndOptionSetsStore().loadStoreSets().catch(console.error)
  }
}
</script>

<style lang="scss">
</style>
