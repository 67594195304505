<template>
  <v-card class="strategy-action-modal p-relative">
    <v-overlay absolute color="white" :value="loading" >
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">
      Perform action
    </v-card-title>
    <v-divider/>
    <v-card-text>
      <v-row class="align-baseline mt-1">
        <div class="px-12 col-12 py-2">
          <v-autocomplete
            :menu-props="{
              left: true,
            }"
            v-model="currentAction"
            :items="actions"
            label="Pick an action"
            :error-messages="errors.action"
          />
        </div>
      </v-row>
      <v-row v-if="currentAction" class="align-baseline mt-1 mb-4">
        <div class="px-12 col-12 py-0">
          <template v-if="currentAction.action === 'new_version'">
            <v-text-field
              v-model="newVersionName"
              label="New version name"
              :error-messages="errors.newVersionName"
            />
          </template>

          <template v-if="currentAction.action === 'merge_version'">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              v-model="fromVersion"
              :items="currentCubeVersions"
              label="From version"
              :error-messages="errors.fromVersion"
            />
            <div v-if="timestamp">{{ timestamp }}</div>
          </template>

          <template v-if="currentAction.action === 'set_value'">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              v-model="currentMetric"
              :items="metricList"
              label="Pick a metric"
              :error-messages="errors.metric"
            />
            <v-row>
              <template v-if="setShareVisible && setShare">
                <v-col>
                  <template v-if="totals[currentMetric + ' (bdg)'] !== undefined">
                    <v-text-field
                      v-model="totals[currentMetric + ' (bdg)']"
                      label="Total"
                      readonly
                      disabled
                      :error-messages="errors.total"
                    />
                  </template>
                  <template v-else>
                    <v-text-field
                      v-model="currentReference"
                      label="Reference"
                      readonly
                      disabled
                      :error-messages="errors.reference"
                    />
                  </template>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model.number="currentShare"
                    label="Share"
                    suffix="%"
                    :readonly="totals[currentMetric + ' (bdg)'] === undefined"
                    :disabled="totals[currentMetric + ' (bdg)'] === undefined"
                    :error-messages="errors.share"
                    @input="handleShareInput"
                  />
                </v-col>
              </template>
              <template v-else>
                <v-col>
                  <v-text-field
                    v-model="currentReference"
                    label="Reference"
                    readonly
                    disabled
                    :error-messages="errors.reference"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="currentIndex"
                    label="Index"
                    :error-messages="errors.index"
                    @input="handleIndexInput"
                  />
                </v-col>
              </template>
            </v-row>
            <v-text-field
              v-model="currentValue"
              label="Value"
              hide-details="auto"
              :error-messages="errors.value"
              @input="calculateShare"
            />
            <div
              v-if="['promotion', 'markdown', 'discount', 'margin', 'intake_margin', 'original_price', 'retail_price', 'actual_price', 'cost_price'].includes(currentMetric)"
              class="d-flex align-center mt-4"
            >
              <label class="mr-2">Offset sales</label>
              <v-checkbox
                v-model="offsetSales"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </div>
            <div
              v-if="['original_price', 'retail_price', 'actual_price', 'cost_price'].includes(currentMetric)"
              class="d-flex align-center mt-6"
            >
              <label class="mr-2">Scale prices</label>
              <v-checkbox
                v-model="scalePrices"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </div>
            <div
              v-if="setShareVisible"
              class="d-flex align-center mt-8"
            >
              <label class="mr-2">Set share</label>
              <v-checkbox
                v-model="setShare"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </div>
          </template>

          <template v-if="currentAction.action === 'match_prices'">
            <div class="d-flex align-center mt-4">
              <label class="mr-2">Offset sales</label>
              <v-checkbox
                v-model="offsetSales"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </div>
          </template>

          <!-- current filters selection -->
          <template v-if="['set_value', 'merge_version', 'match_prices'].includes(currentAction.action)">
            <stores-select
              :keys.sync="filters.store_keys"
              :store-key-list="parentFilters.storeKeyList"
              restrict-list
              select-all
              class="mt-8"
            />
            <collapsible-select
              v-for="(dimension, i) in dimensionsToShow"
              :key="i"
              v-model="filters[dimension.name]"
              :label="dimension.name | formatString"
              :items="dimension.values"
              show-select-all
              multiple
              autocomplete
              hide-details="auto"
              class="mt-8"
            />
            <collapsible-select
              v-for="(values, name) in additionalFilters"
              :key="name"
              :value="values"
              :label="name"
              :items="values"
              show-select-all
              multiple
              autocomplete
              hide-details="auto"
              class="mt-8"
              @change="val => additionalFilters = { ...additionalFilters, [name]: val}"
            />
            <WeekSelector
              v-model.number="filters.week_min"
              label="Begin"
              clearable
              class="mt-8"
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            />
            <WeekSelector
              v-model.number="filters.week_max"
              label="End"
              clearable
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            />
          </template>

          <template v-if="currentAction.action === 'set_value'">
            <v-text-field
              v-model="note"
              label="Note"
            />
          </template>

          <template v-if="currentAction.action === 'add_dimension'">
            <v-row>
              <v-col>
                <h4>Add</h4>
              </v-col>
              <v-col>
                <h4>Look likes</h4>
              </v-col>
            </v-row>
            <v-row
              v-for="d in subDimensions"
              :key="d"
              class="align-center"
            >
              <v-col>
                <v-combobox
                  :menu-props="{
                    left: true,
                  }"
                  autocomplete
                  :items="dimensions.find(d1 => d1.name === d).values"
                  :label="d | formatString"
                  :error-messages="errors.dimensionsAdds[d]"
                  v-model="dimensionsAdds[d]"
                  hide-details="auto"
                  class="pt-0"
                />
              </v-col>
              <v-col>
                <v-select
                  autocomplete
                  :items="dimensions.find(d1 => d1.name === d).values"
                  :label="d | formatString"
                  :error-messages="errors.dimensionsLookslikes[d]"
                  v-model="dimensionsLookslikes[d]"
                  hide-details="auto"
                  class="pt-0"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="currentFactor"
              label="Factor"
              hide-details="auto"
              class="mt-4"
              :error-messages="errors.factor"
            />
          </template>

          <template v-if="currentAction.action === 'remove_dimensions'">
            <v-row>
              <v-col>
                <h4>Remove</h4>
              </v-col>
            </v-row>
            <v-row
              v-for="d in subDimensions"
              :key="d"
              class="align-center"
            >
              <v-col>
                <v-select
                  autocomplete
                  :items="dimensions.find(d1 => d1.name === d).values"
                  :label="d | formatString"
                  :error-messages="errors.dimensionsRemove[d]"
                  v-model="dimensionsRemove[d]"
                  hide-details="auto"
                  class="pt-0"
                />
              </v-col>
            </v-row>
          </template>

          <template v-if="currentAction.action === 'sync_cube'">
            <StrategyCubeSelect
              ref="cubeSelect"
              label="Source"
              :connect-to-page="false"
              :clearable="false"
            />
            <div
              v-if="errors.cube.length || errors.version.length"
              class="mt-2 v-messages theme--light error--text"
              role="alert"
            >
              <div class="v-messages__wrapper">
                <div v-if="errors.cube.length" class="v-messages__message">{{ errors.cube[0] }}</div>
                <div v-if="errors.version.length" class="v-messages__message">{{ errors.version[0] }}</div>
              </div>
            </div>
            <StrategyCubeSelect
              class="mt-6"
              label="Target"
              disabled
              :connect-to-page="false"
              :clearable="false"
            />
            <v-row>
              <v-col>
                <WeekSelector
                  v-model.number="syncCubeWeekMin"
                  :error-messages="errors.syncCubeWeekMin"
                  label="Week min"
                  clearable
                  class="mt-8"
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                />
              </v-col>
              <v-col>
                <WeekSelector
                  v-model.number="syncCubeWeekMax"
                  :error-messages="errors.syncCubeWeekMax"
                  label="Week max"
                  clearable
                  class="mt-8"
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                />
              </v-col>
            </v-row>
          </template>
        </div>
      </v-row>
    </v-card-text>
    <v-divider/>
    <v-card-actions class="pa-4">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        class="mr-2"
        @click="$emit('close')"
      >
        Cancel
      </v-btn>
      <v-btn
        v-if="currentAction?.action === 'set_value'"
        color="primary"
        depressed
        outlined
        class="mr-2"
        @click="handleApplyLaterClick"
      >
        Apply later
      </v-btn>
      <v-btn
        color="primary"
        depressed
        outlined
        @click="handleApplyClick"
      >
        Apply
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { cloneDeep, isEqual, round } from 'lodash'
import { PerformAction } from '@/api/cube'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import StoresSelect from '@/components/StoresSelect.vue'
import WeekSelector from '../WeekSelector'
import StrategyCubeSelect from '@/components/Strategy/StrategyCubeSelect'
import { useCubesStore } from '@/store/pinia/cubesStore'

export default {
  name: 'StrategyActionModal',
  components: {
    WeekSelector,
    CollapsibleSelect,
    StoresSelect,
    StrategyCubeSelect,
  },
  props: {
    actions: {
      type: Array,
      required: true
    },
    cubeKey: {
      type: String,
      required: true
    },
    versionKey: {
      type: String,
      required: true
    },
    dimensions: {
      type: Array,
      default: () => [],
    },
    subDimensions: {
      type: Array,
      default: () => [],
    },
    metricList: {
      type: Array,
      default: () => [],
    },
    parentFilters: {
      type: Object,
      default: () => ({})
    },
    totals: {
      type: Object || null,
      default: null,
    },
  },
  setup () {
    return {
      cubesStore: useCubesStore(),
    }
  },
  data () {
    return {
      loading: false,
      currentAction: null,
      currentMetric: null,
      currentReference: null,
      currentIndex: null,
      currentShare: 0,
      currentValue: null,
      currentFactor: 1.0,
      fromVersion: null,
      timestamp: null,
      note: '',
      newVersionName: '',
      errors: {
        action: [],
        metric: [],
        value: [],
        factor: [],
        share: [],
        cube: [],
        syncCubeWeekMin: [],
        syncCubeWeekMax: [],
        version: [],
        newVersionName: [],
        fromVersion: [],
        dimensionsAdds: {},
        dimensionsRemove: {},
        dimensionsLookslikes: {},
      },
      filters: {
        store_keys: [],
        week_min: null,
        week_max: null,
      },
      additionalFilters: {},
      offsetSales: true,
      scalePrices: true,
      setShare: false,
      dimensionsLookslikes: {},
      dimensionsAdds: {},
      dimensionsRemove: {},
      syncCubeWeekMin: null,
      syncCubeWeekMax: null,
    }
  },
  computed: {
    dimensionsToShow () {
      return this.dimensions.filter(d => !['store_keys', 'week_numbers'].includes(d.name))
    },
    filtersEqualParentFilters () {
      const basicFiltersEqual = this.dimensionsToShow.every(d => {
        let filtersValues = this.filters[d.name]
        let parentFiltersValues = this.parentFilters[d.name]

        if (filtersValues && !Array.isArray(filtersValues)) {
          filtersValues = [filtersValues]
        }

        if (parentFiltersValues && !Array.isArray(parentFiltersValues)) {
          parentFiltersValues = [parentFiltersValues]
        }

        return isEqual(
          filtersValues?.slice().sort(),
          parentFiltersValues?.slice().sort()
        )
      })

      const additionalFiltersEqual = Object.keys(this.additionalFilters).every(name => isEqual(
        this.additionalFilters[name]?.slice().sort(),
        this.parentFilters[name]?.slice().sort()
      ))

      return basicFiltersEqual && additionalFiltersEqual
    },
    setShareVisible () {
      return !this.filtersEqualParentFilters && ['revenue', 'sales_net'].includes(this.currentMetric)
    },
    hasErrors () {
      const hasAddsError = Object
        .keys(this.errors.dimensionsAdds)
        .some(key => this.errors.dimensionsAdds[key].length)

      const hasRemovedError = Object
        .keys(this.errors.dimensionsRemove)
        .some(key => this.errors.dimensionsRemove[key].length)

      const hasLookalikesError = Object
        .keys(this.errors.dimensionsLookslikes)
        .some(key => this.errors.dimensionsLookslikes[key].length)

      return hasAddsError || hasRemovedError || hasLookalikesError || Object.keys(this.errors).some(key => this.errors[key].length)
    },
    currentCubeVersions () {
      return this.cubesStore.cubesBrowser?.version_keys[this.cubeKey] ?? []
    },
  },
  methods: {
    validate () {
      this.errors = {
        action: [],
        metric: [],
        value: [],
        factor: [],
        share: [],
        newVersionName: [],
        fromVersion: [],
        cube: [],
        syncCubeWeekMin: [],
        syncCubeWeekMax: [],
        version: [],
        dimensionsAdds: {},
        dimensionsRemove: {},
        dimensionsLookslikes: {},
      }

      this.subDimensions.forEach(d => {
        this.$set(this.errors.dimensionsAdds, d, [])
        this.$set(this.errors.dimensionsRemove, d, [])
        this.$set(this.errors.dimensionsLookslikes, d, [])
      })

      if (!this.currentAction) {
        this.errors.action.push('Action is required')
        return
      }

      if (this.currentAction.action === 'match_prices') {
        // nothing to validate for this action
        return
      }

      if (this.currentAction.action === 'new_version') {
        if (!this.newVersionName) {
          this.errors.newVersionName.push('Name is required')
        }
        return
      }

      if (this.currentAction.action === 'merge_version') {
        if (!this.fromVersion) {
          this.errors.fromVersion.push('From version is required')
        }
        return
      }

      if (this.currentAction.action === 'add_dimension') {
        this.subDimensions.forEach(d => {
          if (!this.dimensionsAdds[d].length) {
            this.errors.dimensionsAdds[d].push('Dimension is required')
          }

          if (!this.dimensionsLookslikes[d]) {
            this.errors.dimensionsLookslikes[d].push('Dimension is required')
          }
        })

        if (!this.currentFactor) {
          this.errors.factor.push('Factor is required')
        }

        if (this.currentFactor) {
          if (Number(this.currentFactor) < 0) {
            this.errors.factor.push('Factor must be positive')
          } else {
            this.currentFactor = Number(this.currentFactor)
          }
        }

        return
      }

      if (this.currentAction.action === 'remove_dimensions') {
        this.subDimensions.forEach(d => {
          if (!this.dimensionsRemove[d].length) {
            this.errors.dimensionsRemove[d].push('Dimension is required')
          }
        })

        return
      }

      if (this.currentAction.action === 'sync_cube') {
        if (!this.$refs.cubeSelect?.currentCube) {
          this.errors.cube.push('Cube is required')
        }

        if (this.$refs.cubeSelect?.currentCube === this.$route.query.cube && this.$refs.cubeSelect?.currentVersion === this.$route.query.version) {
          this.errors.cube.push('Cube and version can not be the same')
        }

        if (!this.$refs.cubeSelect?.currentVersion) {
          this.errors.version.push('Version is required')
        }

        if (!this.syncCubeWeekMin) {
          this.errors.syncCubeWeekMin.push('Required')
        }

        if (!this.syncCubeWeekMax) {
          this.errors.syncCubeWeekMax.push('Required')
        }

        return
      }

      if (!this.currentMetric) {
        this.errors.metric.push('Metric is required')
      }

      if (!this.currentValue) {
        this.errors.value.push('Value is required')
      }

      if (this.currentValue) {
        if (this.currentAction.action === 'set_value') {
          if (Number(this.currentValue) < 0) {
            this.errors.value.push('Value must be positive')
          } else {
            this.currentValue = Number(this.currentValue)
          }
        }
      }

      if (this.setShareVisible && this.setShare) {
        this.validateShare()
      }
    },
    validateShare () {
      this.errors.share = []

      if (this.currentShare === '') {
        this.errors.share.push('Value is required')
      }

      if (this.currentShare) {
        if (this.currentShare < 0 || this.currentShare > 100) {
          this.errors.share.push('Value must be between 0 and 100')
        }
      }
    },
    handleShareInput () {
      this.validateShare()
      this.calculateValue()
    },
    handleIndexInput () {
      this.currentValue = round(this.currentReference * this.currentIndex / 100, 2)
    },
    calculateValue () {
      if (this.totals[this.currentMetric + ' (bdg)'] !== undefined) {
        this.currentValue = round(this.totals[this.currentMetric + ' (bdg)'] * this.currentShare / 100, 2)
      }
    },
    calculateShare () {
      const shareRef = this.totals[this.currentMetric + ' (bdg)'] || this.currentReference
      this.currentShare = round(this.currentValue / shareRef, 4) * 100
    },
    fillIndexBeliefFromExternal (ref, value, metric, additionalFilters) {
      this.currentAction = this.actions.find(a => a.value.action === 'set_value').value
      this.currentMetric = metric
      const settings = JSON.parse(localStorage.getItem('strategyBudgetCubesSettings'))
      this.offsetSales = settings.offsetSales
      this.scalePrices = settings.scalePrices
      this.setShare = settings.setShares
      this.currentReference = ref

      if (ref !== 0) {
        this.currentIndex = Math.floor(value * 100 / ref)
      }

      this.currentValue = value

      const shareRef = this.totals[this.currentMetric + ' (bdg)'] || ref
      this.currentShare = round(value / shareRef, 4) * 100

      additionalFilters?.forEach(f => {
        this.setAdditionalFilter(f.name, f.value)
      })
    },
    setAdditionalFilter (additionalFilterName, additionalFilterValue) {
      if (additionalFilterName) {
        if (additionalFilterName === 'week_numbers') {
          this.filters.week_min = additionalFilterValue
          this.filters.week_max = additionalFilterValue
        } else {
          const filtersNames = [
            ...this.dimensionsToShow.map(d => d.name),
            'store_keys',
          ]

          if (filtersNames.includes(additionalFilterName)) {
            this.filters[additionalFilterName] = [additionalFilterValue]
          } else {
            this.additionalFilters[additionalFilterName] = [additionalFilterValue]
          }
        }
      }
    },
    getActionPayload () {
      let payload = {
        cube_key: this.cubeKey,
        version_key: this.versionKey,
        action: this.currentAction.action,
      }

      if (['set_value', 'new_version'].includes(this.currentAction.action)) {
        const sidePanelFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}
        payload = {
          ...payload,
          config: {
            variable: this.currentMetric,
            value: parseFloat(this.currentValue),
            share: this.currentShare / 100,
          },
          parent_filters: {
            ...this.parentFilters,
            ...sidePanelFilters,
          },
          filters: {
            ...this.filters,
            ...this.additionalFilters,
            ...sidePanelFilters
          },
          note: this.note,
        }

        if (this.currentAction.action === 'set_value') {
          payload.config.offset_sales = this.offsetSales
          payload.config.set_share = this.setShare
          payload.config.scale_prices = this.scalePrices
        }

        if (this.currentAction.action === 'new_version') {
          payload.config.version_key = this.newVersionName
        }
      }

      if (this.currentAction.action === 'merge_version') {
        payload = {
          ...payload,
          config: {
            version_key: this.fromVersion,
            timestamp: this.timestamp,
          },
          filters: {
            ...this.filters,
            ...this.additionalFilters,
          },
        }
      }

      if (this.currentAction.action === 'match_prices') {
        payload = {
          ...payload,
          config: {
            offset_sales: this.offsetSales,
          },
          filters: {
            ...this.filters,
            ...this.additionalFilters,
          },
        }
      }

      if (this.currentAction.action === 'add_dimension') {
        const config = {
          adds: {},
          looks_like: {},
          factor: this.currentFactor,
        }

        Object
          .keys(this.dimensionsLookslikes)
          .forEach(d => {
            config['looks_like'][d] = this.dimensionsLookslikes[d]
            config['adds'][d] = this.dimensionsAdds[d]
          })

        payload = {
          ...payload,
          config,
        }
      }

      if (this.currentAction.action === 'remove_dimensions') {
        const config = {}

        Object
          .keys(this.dimensionsLookslikes)
          .forEach(d => {
            config[d] = this.dimensionsRemove[d]
          })

        payload = {
          ...payload,
          config,
        }
      }

      if (this.currentAction.action === 'sync_cube') {
        payload = {
          ...payload,
          config: {
            from_cube_key: this.$refs.cubeSelect?.currentCube,
            from_version_key: this.$refs.cubeSelect?.currentVersion,
          },
          filters: {
            week_min: this.syncCubeWeekMin,
            week_max: this.syncCubeWeekMax,
          },
        }
      }

      return payload
    },
    async handleApplyClick () {
      this.validate()

      if (this.hasErrors) {
        return
      }

      const payload = this.getActionPayload()
      this.loading = true

      try {
        await PerformAction(payload)
        this.$emit('apply')
        this.$emit('close')
      } catch (e) {
        console.error(e)
      }

      this.loading = false
    },
    handleApplyLaterClick () {
      this.validate()

      if (this.hasErrors) {
        return
      }

      const payload = this.getActionPayload()

      this.$emit('applyLater', payload)
      this.$emit('close')
    },
  },
  mounted () {
    this.filters = {
      store_keys: [...(this.parentFilters.store_keys || [])],
      week_min: this.parentFilters.week_min,
      week_max: this.parentFilters.week_max,
    }

    this.dimensions.forEach(d => {
      if (this.parentFilters[d.name]) {
        this.$set(this.filters, d.name, cloneDeep(this.parentFilters[d.name]))
      }
    })

    this.subDimensions.forEach(d => {
      this.$set(this.dimensionsAdds, d, [])
      this.$set(this.dimensionsRemove, d, [])
      this.$set(this.dimensionsLookslikes, d, null)
      this.$set(this.errors.dimensionsAdds, d, [])
      this.$set(this.errors.dimensionsRemove, d, [])
      this.$set(this.errors.dimensionsLookslikes, d, [])
    })

    this.additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}

    this.syncCubeWeekMin = this.filters.week_min
    this.syncCubeWeekMax = this.filters.week_max
  }
}
</script>

<style scoped lang="scss">
.strategy-action-modal {
}
</style>
