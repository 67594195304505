<template>
  <v-row class="pa-5 pt-0">
    <v-row justify="end">
      <div class="col text-right pr-6">
        <TableSearch
          class="mx-2"
          @searchChanged="v => this.searchQuery = v"
        />
        <v-icon class="mx-2 action-btn-primary" :disabled="selectedRows.length !== 1" @click="handleEditClick">edit</v-icon>
        <v-icon class="mx-2 action-btn-primary" @click="handleAddSingle">add</v-icon>
        <v-icon class="mx-2 action-btn-danger" :disabled="selectedRows.length < 1" @click="handleDeleteClick">delete</v-icon>
        <data-export @item-click="downloadTableData"/>
      </div>
    </v-row>
    <data-tables
      style="width: 100%"
      :data="filteredProperties"
      :page-size="10"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
      :table-props="{ 'row-key': 'properties_key' }"
      :filters="tableFilters"
      filter-multiple
      @filter-change="f => handleFilterChange('tableFilters', f)"
      @row-click="handleRowClick"
    >
      <el-table-column prop="properties_key" width="55">
        <template v-slot="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="mt-0 ml-2 pt-0"
            :value="scope.row.selected"
            :ripple="false"
            @input="handleSelectRowChange($event, scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="property_name"
        column-key="property_name"
        label="Name"
        sortable="custom"
        :filters="getColumnFilterValues('filteredProperties', 'property_name')"
      >
        <template v-slot="scope">
          <span class="font-weight-medium text--black size">{{ scope.row.property_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="property_alias"
        column-key="property_alias"
        label="Alias"
        sortable="custom"
        :filters="getColumnFilterValues('filteredProperties', 'property_alias')"
      >
        <template v-slot="scope">
          <span class="font-weight-medium text--black"> {{ scope.row.property_alias }} </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="force"
        column-key="force"
        label="Force"
        :sortable="true"
        :filters="getColumnFilterValues('filteredProperties', 'force')"
      >
        <template v-slot="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            disabled
            class="mt-0 pt-0"
            :value="scope.row.force"
            :ripple="false"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="value_type"
        column-key="value_type"
        label="Value Type"
        :sortable="true"
        :filters="getColumnFilterValues('filteredProperties', 'value_type')"
      >
        <template v-slot="scope">
          <span class="font-weight-medium text--black"> {{ scope.row.value_type }} </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="value_dropdown"
        column-key="value_dropdown"
        label="Value Dropdown"
        :sortable="true"
        :filters="getColumnFilterValues('filteredProperties', 'value_dropdown')"
      >
        <template v-slot="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            disabled
            class="mt-0 pt-0"
            :value="scope.row.value_dropdown"
            :ripple="false"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="value_default"
        column-key="value_default"
        label="Default Value"
        sortable="custom"
        :filters="getColumnFilterValues('filteredProperties', 'value_default')"
      >
        <template v-slot="scope">
          <span class="font-weight-medium text--black"> {{ scope.row.value_default }} </span>
        </template>
      </el-table-column>
    </data-tables>
    <property-modal :isOpen="editModalOpen" ref="propertyEditModal"
      @save="saveProperty" @close="editModalOpen = false"/>
    <delete-dialog
      content-text="Are you sure you want to delete those properties?"
      content-title="Delete Properties"
      :visible="deleteModalOpen"
      @confirm="handleDelete"
      @cancel="deleteModalOpen = false"
    />
  </v-row>
</template>

<script>
import DataExport from '@/components/DataExport'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'
import { getPropertyManagementObjects, removePropertyOnObject, savePropertyOnObject } from '@/api/dataProperties'
import { downloadData } from '@/utils'
import PropertyModal from '@/components/StockSettings/PropertyModal'
import DeleteDialog from '@/components/DeleteDialog'
export default {
  mixins: [columnFilters],
  name: 'PropertiesTable',
  components: {
    DeleteDialog,
    PropertyModal,
    TableSearch,
    DataExport
  },
  data () {
    return {
      tableFilters: [],
      searchQuery: '',
      editModalOpen: false,
      deleteModalOpen: false,
      properties: [],
    }
  },
  props: {
    objectName: {
      type: String,
      required: true
    },
  },
  created () {
    this.getProperties()
  },
  computed: {
    filteredProperties () {
      if (this.searchQuery === '') {
        return this.properties.filter(r => r.object_name.toLowerCase() === this.objectName.toLowerCase())
      }
      return this.properties.filter(r => {
        return r.property_name.toLowerCase().includes(this.searchQuery.toLowerCase()) && r.object_name.toLowerCase() === this.objectName.toLowerCase()
      })
    },
    selectedRows () {
      return this.filteredProperties.filter(r => r.selected)
    },
  },
  methods: {
    getProperties () {
      getPropertyManagementObjects().then(response => {
        // Capitalize first letter of each word
        this.properties = response.properties
      })
    },
    handleAddSingle () {
      this.$refs.propertyEditModal.setLocalProperty(null)
      this.editModalOpen = true
    },
    handleEditClick () {
      this.$refs.propertyEditModal.setLocalProperty(this.selectedRows[0])
      this.editModalOpen = true
    },
    async saveProperty (property) {
      property.object_name = this.objectName.toLowerCase()
      const res = await savePropertyOnObject(property)
      this.mergeWithUpdatedProperties(res.properties)
      this.unselectAll()
      this.$emit('refresh')
    },
    mergeWithUpdatedProperties (updatedProperties) {
      // Merge updated properties with current properties
      updatedProperties.forEach((updatedProperty) => {
        // If updated property has same property_name and value as current property, update current property
        let updated = false
        this.properties.forEach((current, i) => {
          if (current.property_name === updatedProperty.property_name && current.object_name === updatedProperty.object_name) {
            // Reactively update current property
            this.$set(this.properties[i], 'property_alias', updatedProperty.property_alias)
            this.$set(this.properties[i], 'value_default', updatedProperty.value_default)
            this.$set(this.properties[i], 'value_dropdown', updatedProperty.value_dropdown)
            this.$set(this.properties[i], 'value_type', updatedProperty.value_type)
            this.$set(this.properties[i], 'force', updatedProperty.force)
            updated = true
          }
        })
        if (!updated) {
          // If updated property is not in current properties, add it in front
          this.properties = [updatedProperty, ...this.properties]
        }
      })
    },
    handleDeleteClick () {
      this.deleteModalOpen = true
    },
    async handleDelete () {
      await removePropertyOnObject(this.objectName, this.selectedRows)
      this.getProperties()
      this.unselectAll()
      this.$emit('refresh')
      this.deleteModalOpen = false
    },
    downloadTableData (sep) {
      const data = this.filteredProperties.map(row => {
        return {
          object_name: row.object_name,
          property_name: row.property_name,
          property_alias: row.property_alias,
          force: row.force,
          value_type: row.value_type,
          value_dropdown: row.value_dropdown ? row.value_dropdown : false,
          value_default: row.value_default ? row.value_default : '',
        }
      })
      downloadData(sep, data, `${this.objectName}_properties`)
    },
    unselectAll () {
      this.properties.forEach(r => this.$set(r, 'selected', false))
    },
    handleSelectRowChange (val, row) {
      this.$set(row, 'selected', val)
      if (this.selectedRows.length === 1) {
        this.$emit('propertySelected', this.selectedRows[0])
      } else {
        this.$emit('propertySelected', null)
      }
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    }
  }
}
</script>

<style scoped>

</style>
