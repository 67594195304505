<template>
  <div class="dummy-variant-form p-relative">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-card
      ref="card"
      class="p-relative">
      <h4 class="pa-4 pb-0 ">Variation</h4>
      <v-col cols="6">
        <v-row class="mt-0 pt-0 pa-1 ">
          <v-col cols="5">
            <v-text-field
              v-model="inputForm.variantName.value"
              clearable
              label="Name"
              hide-details="auto"
              @change="saveVariant" />
          </v-col>
          <v-col cols="6"></v-col>
          <v-col cols="5">
            <span class="p-relative">
              <v-text-field
                :value="inputForm.variationKey.value"
                @input="inputForm.variationKey.value = $event ? $event : null"
                label="Variation key (external)"
                hide-details="auto"
                :disabled="isDisabled"
                @change="saveVariant"
              />
              <v-icon class="p-absolute lock-icon" @click="isDisabled= false" v-if="isDisabled">lock</v-icon>
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-tabs v-model="activeTab">
        <v-tabs-slider />
        <v-tab class="px-8" style="width: 260px" key="Dummies">Options</v-tab>
        <v-tab class="px-8" style="width: 260px" key="Properties">Properties</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab" class="flex xs12">
        <v-tab-item :transition="false" :reverse-transition="false" key="Dummies" class="flex py-4 px-0">
          <DummiesTable
            ref="variantDummiesTable"
            :variant="variant"
            :isSelected="true"
            :dummies="dummiesForVariant"
            :dummyProperties="dummyProperties"
            :selected-dummy-key="selectedDummyKey"
            :variantProperties="variantPropertiesForDummies"
            @saved="handleListChange"
            @selection-changed="(value)=> $emit('selection-changed', value)"
            @dummy-added="(value)=> $emit('dummy-added', value)"
            @dummy-duplicated="(value)=> $emit('dummy-duplicated', value)"
            @dummy-export-changed="(value)=> $emit('dummy-export-changed', value)"
            @batch-upload-done="(value)=> $emit('batch-upload-done', value)"
            @dummy-deleted="(value)=> $emit('dummy-deleted', value)"
          />
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false" key="Properties" class="pa-4">

          <v-row>
            <v-col :cols="6">
              <h4 class="mt-2">Managed</h4>
            </v-col>
          </v-row>
          <v-row
            class="flex-wrap justify-space-between"

          >
            <v-col
              :cols="6"
              v-for="(prop, index) in variantProperties"
              :key="index"
              class="pa-0"
            >
              <v-col :cols="12">
                <v-row class="align-center">
                  <v-col cols="5">
                    <v-autocomplete
                      :menu-props="{
                        left: true,
                      }"
                      :value="prop.property_name"
                      item-text="property_name"
                      item-value="property_name"
                      disabled
                      :error-messages="
                        prop.force && !variant.properties[prop.property_name] ? ['This is a required field'] :
                        (prop.errors ? prop.errors : [])
                      "
                      :items="variantObjectProperties"
                      label="Property"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="5">
                    <v-simple-checkbox
                      v-if="prop.value_type === 'boolean'"
                      :ref="prop.property_name"
                      @input="variant.properties[prop.property_name] = $event; handlePropValueChange(prop)"
                      :value="variant.properties[prop.property_name].toString().toLowerCase() === 'true'"
                      :error-messages="prop.errors"
                      color="primary"
                      dense
                      hide-details="auto"
                      class="mt-0 pt-0"
                      :ripple="false"
                    />
                    <v-autocomplete
                      v-else-if="prop.value_dropdown && prop.values"
                      :ref="prop.property_name"
                      v-model="variant.properties[prop.property_name]"
                      :items="prop.values"
                      label="Value"
                      hide-details="auto"
                      :error-messages="
                        prop.force && !variant.properties[prop.property_name] ? ['This is a required field'] :
                        (prop.errors ? prop.errors : [])
                      "
                      :menu-props="{
                        left: true,
                        offsetY: true,
                        contentClass: 'select-dropdown-menu',
                      }"
                      @change="handlePropValueChange(prop)" />
                    <v-text-field
                      v-else
                      :ref="prop.property_name"
                      v-model="variant.properties[prop.property_name]"
                      label="Value"
                      hide-details="auto"
                      :error-messages="
                        prop.force && !variant.properties[prop.property_name] ? ['This is a required field'] :
                        (prop.errors ? prop.errors : [])
                      "
                      :type="prop.value_type === 'text' ? 'text' : 'number'"
                      @keyup.enter="handlePropValueChange(prop)"
                      @blur="handlePropValueChange(prop)"
                    />
                  </v-col>

                  <v-col :class="{hidden: prop.force}" :cols="1" class="text-left">
                    <v-icon
                      class="mt-5 action-btn-danger"
                      @click="handlePropDeleteClick(prop)"
                    >delete</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
          <v-row class="mt-0" v-if="!addingProperty">
            <v-col cols="7">
              <v-icon class="plus-icon" @click="addingProperty = true">add</v-icon>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col :cols="6">
              <v-row class="align-center">
                <v-col cols="5">
                  <v-autocomplete
                    v-model="newProperty"
                    item-text="property_name"
                    return-object
                    :items="remainingVariantProperties"
                    label="Property"
                    @change="handleNewPropertyChanged"
                  />
                </v-col>
                <v-col :cols="5" v-if="newProperty.property_name">
                  <v-checkbox
                    v-if="newProperty.value_type === 'boolean'"
                    @input="newProperty.value = $event"
                    :value="newProperty.value.toString().toLowerCase() === 'true'"
                    color="primary"
                    dense
                    hide-details
                    class="mt-0 pt-0"
                    :ripple="false"
                  />
                  <v-autocomplete
                    v-else-if="newProperty.value_dropdown && newProperty.values"
                    v-model="newProperty.value"
                    :items="newProperty.values"
                    label="Value"
                    hide-details="auto"
                    :error-messages="propertyErrors ? propertyErrors : null"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                    @keyup.enter="saveProperty"
                    @blur="saveProperty"
                  />
                  <v-text-field
                    v-else
                    v-model="newProperty.value"
                    label="Value"
                    :error-messages="propertyErrors ? propertyErrors : null"
                    :type="newProperty.value_type === 'text' ? 'text' : 'number'"
                    @keyup.enter="saveProperty"
                    @blur="saveProperty"
                  />

                </v-col>
                <v-col cols="1" v-if="addingProperty" class="text-left">
                  <v-icon v-if="newProperty.property_name" class="plus-icon mr-2" @click="saveProperty">save</v-icon>
                </v-col>
                <v-col cols="1" v-if="addingProperty" class="text-left">
                  <v-icon class="plus-icon" @click="clearAddedProperty">close</v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div v-if="expandable" class="mt-4 expand-btn-wrapper">
            <template v-if="expanded">
              <v-icon class="panel-icon action-btn-primary" @click="setExpanded(false)">expand_less</v-icon>
            </template>
            <template v-else>
              <v-icon class="panel-icon action-btn-primary" @click="setExpanded(true)">expand_more</v-icon>
            </template>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { saveVariant } from '@/api/dummies'
import DummiesTable from '@/components/Dummies/DummiesTable'

export default {
  components: { DummiesTable },
  props: {
    variant: {
      type: Object,
      default () {
        return {}
      },
      required: true,
    },
    selectedDummyKey: {
      type: Number,
      default: null,
    },
    dummiesForVariant: {
      type: Array,
      default () {
        return []
      }
    },
    variantPropertiesForDummies: {
      type: Array,
      default () {
        return []
      }
    },

    dummyProperties: {
      type: Array,
      default () {
        return []
      }
    },
    variantObjectProperties: {
      type: Array,
      default: () => [],
    },
    expandable: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      loading: false,
      isDisabled: true,
      activeTab: 0,
      addingProperty: false,
      propertyErrors: [],
      expanded: true,
      inputForm: {
        variantName: {
          value: '',
          error: null,
        },
        variationKey: {
          value: '',
          error: null,
        },
      },
      newProperty: {
        property_name: '',
        value_type: 'text',
        value_dropdown: false,
        value: '',
        values: [],
      },
    }
  },
  computed: {
    variantProperties () {
      if (this.variantObjectProperties.length === 0) {
        return
      }
      return this.variantObjectProperties
        .filter(
          p => Object.keys(this.variant.properties).includes(p.property_name)
        )
        .sort((a, b) => a.force === b.force ? 0 : (a.force ? -1 : 1))
    },
    remainingVariantProperties () {
      return this.variantObjectProperties
        .filter(prop => {
          return !this.variant.properties[prop.property_name]
        })
        .sort((a, b) => a.force === b.force ? 0 : (a.force ? -1 : 1))
    },
  },
  watch: {
    variant: {
      handler () {
        if (this.variant) {
          this.inputForm.variantName.value = this.variant.variant_name
          this.inputForm.variationKey.value = this.variant.variation_key
        }
      },
      immediate: true,
    },
    activeTab () {
      this.$router.replace({ ...this.$route, query: { ...this.$route.query, formTab: this.activeTab } })
    },
    $route: {
      handler () {
        this.activeTab = parseInt(this.$route.query.formTab) || 0
      },
      immediate: true
    }
  },
  methods: {
    async handleListChange (payload) {
      //  await loadDummiesList(payload)
      // const routeDummyKey = parseInt(this.$route.query.dummy_key)
      // if (routeDummyKey) {
      this.$emit('update-dummy', payload)
      // }
    },
    saveVariant () {
      const payload = {
        ...this.variant,
        variant_name: this.inputForm.variantName.value,
        variation_key: this.inputForm.variationKey.value,
      }

      this.loading = true

      return saveVariant(payload)
        .finally(() => {
          this.loading = false
        })
    },
    validatePropValue (prop) {
      this.$set(prop, 'errors', [])
      const value = this.variant.properties[prop.property_name]
      if (prop.value_type === 'text') {
        if (prop.force && !value) {
          prop.errors.push('This is a required field')
          return false
        }
      }
      if (prop.value_type === 'decimal' &&
        (!value?.toString().match(/^\d+$/) && !value?.toString().match(/^\d+\.\d+$/))) {
        prop.errors.push('value must be a decimal')
        return false
      }
      if (prop.value_type === 'integer' && !value?.toString().match(/^\d+$/)) {
        prop.errors.push('value must be an integer')
        return false
      }
      return true
    },
    handlePropValueChange (prop) {
      const isValid = this.validatePropValue(prop)
      if (!isValid) {
        return
      }

      this.saveVariant()
        .then(updatedVariant => {
          this.$emit('properties-changed', updatedVariant)
        }).finally(() => {
          this.loading = false
        })
    },
    handlePropDeleteClick (prop) {
      delete this.variant.properties[prop.property_name]
      this.loading = true
      this.saveVariant()
        .then(updatedVariant => {
          this.$emit('properties-changed', updatedVariant)
        }).finally(() => {
          this.loading = false
        })
    },
    handleNewPropertyChanged () {
      if (this.newProperty.value_default) {
        if (this.newProperty.value_type === 'boolean') {
          this.newProperty.value = this.newProperty.value_default?.toString()?.toLowerCase() === 'true'
        } else {
          this.newProperty.value = this.newProperty.value_default
        }
      }
    },
    setExpanded (val) {
      this.expanded = val

      if (this.expanded) {
        this.$refs.card.$el.removeAttribute('style')
      } else {
        const dummiesTable = document.getElementById('variantDummiesTable')
        this.$refs.card.$el.setAttribute('style', `height: ${dummiesTable.clientHeight}px`)
      }
    },
    clearAddedProperty () {
      this.newProperty = {
        property_name: '',
        value: '',
        value_type: 'text'
      }
      this.addingProperty = false
    },
    saveProperty () {
      this.propertyErrors = []
      if (!this.newProperty.property_name || (!this.newProperty.value && this.newProperty.value !== 0 && this.newProperty.value !== false)) {
        this.propertyErrors.push('name and value required')
        return
      }
      if (this.newProperty.value_type === 'decimal' &&
        (!this.newProperty.value?.toString().match(/^\d+$/) || !this.newProperty.value?.toString().match(/^\d+\.\d+$/))) {
        this.propertyErrors.push('value must be a decimal')
        return
      }
      if (this.newProperty.value_type === 'integer' && !this.newProperty.value?.toString().match(/^\d+$/)) {
        this.propertyErrors.push('value must be an integer')
        return
      }
      this.variant.properties[this.newProperty.property_name] = this.newProperty.value
      this
        .saveVariant()
        .then(updatedVariant => {
          this.$emit('properties-changed', updatedVariant)
          this.addingProperty = false
          this.newProperty.value = ''
          this.newProperty.property_name = ''
          this.newProperty.value_type = ''
        })
    },
  }
}
</script>

<style lang="scss">
.dummy-variant-form {
    .v-card {
        overflow: hidden;
    }

    .lock-icon {
      top: 20px;
      position: absolute;
      right: 6px
    }

    .expand-btn-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        // background: #fff;
        background: linear-gradient(to top, #fff 70%, transparent);
        text-align: center;
        padding: 12px 0 8px 0;
    }

  .hidden {
    visibility: hidden;
  }
}
</style>
