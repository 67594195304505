<template>
  <div>
    <OptionAlertModal
      :dialogVisible="alertDialogVisible"
      @alert-create="alertDialogVisible = false"
      @close-click="alertDialogVisible = false"
    />
    <div
      class="task-table"
      v-if="tasksStore.tasksLoaded[taskType]"
    >
      <v-overlay absolute color="white" :value="loadingTasks">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>

      <v-row class="mt-4">
        <v-col class="text-subtitle-1 font-weight-medium mb-4 ml-6">{{ `${selectedRows.length} line${selectedRows.length !== 1 ? 's' : ''} selected` }}</v-col>
        <v-col class="text-right mr-6">
          <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
          <v-icon
            v-if="taskType !== 'alerts'"
            class="mx-2 alert-icon"
            :disabled="selectedRows.length !== 1"
            @click="changeAlert(selectedRows[0])"
          >
            add_alert
          </v-icon>
          <v-icon
            class="mx-2 action-btn-primary"
            :disabled="!selectedRows.length"
            @click="completeSelectedTasks"
          >
            done
          </v-icon>
          <data-export @item-click="downloadTableData"/>
        </v-col>
      </v-row>

      <data-tables
        :key="tasksListKey"
        :current-page.sync="currentPage"
        :filters="tasksTableFilters"
        :data="data"
        :table-props="{
          'row-key': 'option_key'
        }"
        :page-size="10"
        @filtered-data="filteredData = $event"
        :pagination-props="{
          pageSizes: [5, 10,15, 25, 50],
          class: 'el-pagination text-right mt-6 pb-4 mr-2',
        }"
        @row-click="handleRowClick"
      >
        <el-table-column prop="option_key" width="55">
          <template v-slot:header>
            <v-simple-checkbox
              color="primary"
              :value="allRowsSelected"
              :ripple="false"
              dense
              hide-details
              class="ml-3"
              @input="handleSelectAllChange"
            />
          </template>
          <template slot-scope="scope">
            <v-simple-checkbox
              color="primary"
              dense
              hide-details
              class="ml-3"
              :value="scope.row.selected"
              :ripple="false"
              @input="handleSelectRowChange($event, scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-if="taskType !== 'alerts'"
          label="Option"
          sortable="custom"
          align="left"
          width="300px"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.option !== null">
              <v-menu
                :key="scope.row.option_key"
                right
                bottom
                :open-on-hover="!infoMenuOpened[scope.row.option_key]"
                nudge-bottom="10"
                :nudge-left="200"
                :disabled="!scope.row.option_key"
                v-model="infoMenuOpened[scope.row.option_key]"
                @input="onInfoMenuChange(scope.row.option_key, $event)"
                offset-x
                offset-y
                :open-delay="3000"
                :close-delay="1000"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <OptionDataCell :option="scope.row" />
                  </div>
                </template>
                <info-menu
                  @close="infoMenuOpened[scope.row.option_key] = false"
                  v-if="infoMenuOpened[scope.row.option_key]"
                  :row="scope.row"></info-menu>
              </v-menu>
            </div>
          </template>
        </el-table-column>

        <!-- Auto generated columns -->
        <el-table-column
          v-for="title in headers"
          :prop="title"
          :label="formatString(title)"
          :key="title"
          sortable="custom"
        >
          <template slot-scope="scope">
            <div v-if="title === 'status'">
              <div class="status-column">
                <p class="status-text" :class="getStatusColor(scope.row.status)">
                  {{ getStatusString(scope.row.status) }}
                </p>
              </div>
            </div>
            <div v-else-if="taskType === 'alerts' && title === 'name'">
              <AlertNameCell :option="scope.row" />
            </div>
            <div v-else-if="taskType === 'markdowns_on_dc' && title === 'discount'">
              {{ scope.row[title] }}%
            </div>
            <div v-else>
              {{ scope.row[title] }}
            </div>
          </template>
        </el-table-column>
      </data-tables>
    </div>
    <div
      v-else
      v-loading="tasksStore.tasksLoaded[taskType] === false"
      style="height: 300px"
    ></div>
  </div>
</template>

<script>
import TableSearch from '@/components/TableSearch.vue'
import OptionDataCell from '@/components/OptionDataCell.vue'
import AlertNameCell from '@/components/AlertNameCell.vue'
import OptionAlertModal from '@/components/Alerts/OptionAlertModal.vue'
import { formatString, getHeadersWithAccessToken } from '@/variables'
import axios from '../../services/axios'
import InfoMenu from '@/components/Home/InfoMenu'
import DataExport from '@/components/DataExport'
import baseURLs from '@/api/APIBaseURLs'
import { downloadData } from '@/utils'
import { useAlertStore } from '@/store/pinia/alertStore'
import { useStockTasksStore } from '@/store/pinia/tasksStore'

export default {
  setup () {
    return {
      alertStore: useAlertStore(),
      tasksStore: useStockTasksStore(),
    }
  },
  props: ['taskType'],
  data () {
    return {
      loaded: false,
      infoMenuOpened: {},
      filteredData: [],
      checked: false,
      tasksListKey: 0,
      loadingTasks: false,
      alertDialogVisible: false,
      tasksTableFilters: [
        {
          value: '',
        },
      ],
    }
  },
  components: {
    DataExport,
    InfoMenu,
    TableSearch,
    OptionDataCell,
    AlertNameCell,
    OptionAlertModal,
  },
  computed: {
    headers () {
      let headers = this.tasksStore.tasks[this.taskType].headers
      const keys = ['handled']
      let idx = -1
      for (const item of headers) {
        if (item.includes('_key')) {
          keys.push(item)
        }
      }
      for (const key of keys) {
        idx = headers.indexOf(key)
        if (idx > -1) {
          headers.splice(idx, 1)
        }
      }

      if (this.taskType === 'alerts') {
        // Filter out some cols in the alerts tab.
        headers = headers.filter(
          (elem) => elem !== 'page' && elem !== 'page_payload'
        )
      }

      // We filter out what columns we dont want to auto generate here
      return headers.filter(
        (h) =>
          h !== 'image_url' &&
          h !== 'page_payload' &&
          h !== 'page' &&
          h !== 'option'
      )
    },
    data () {
      return this.tasksStore.tasks[this.taskType].data.filter(x => !x.handled)
    },
    selectedRows () {
      return this.data.filter(r => r.selected)
    },
    allRowsSelected () {
      return (this.filteredData.length > 0) && (this.selectedRows.length === this.filteredData.length)
    },
    currentPage: {
      set (val) {
        this.tasksStore.updateTasksPage(val)
      },
      get () {
        return this.tasksStore.tasksTablePageNumber
      },
    },
  },
  methods: {
    onInfoMenuChange (option_key, value) {
      if (value) {
        this.infoMenuOpened = { [option_key]: value }
      }
    },
    downloadTableData (sep) {
      downloadData(sep, this.data, 'Tasks')
    },
    getStatusString (status) {
      if (status === 0) {
        return 'Never viewed'
      } else if (status === 1) {
        return 'Viewed last 7 days'
      } else if (status === 2) {
        return 'Viewed last 14 days'
      } else if (status === 3) {
        return 'Viewed over 14 days'
      }
    },
    getStatusColor (status) {
      if (status === 0) {
        return 'never'
      } else if (status === 1) {
        return 'seven-days'
      } else if (status === 2) {
        return 'fourteen-days'
      } else if (status === 3) {
        return 'over-fourteen-days'
      }
    },

    formatString (str) {
      return formatString(str)
    },
    changeAlert (row) {
      const payload = {
        page: 'option',
        option_key: row.option_key,
        option_name: this.taskType !== 'alerts' ? row.option : row.name,
        inbound_key: row.inbound_key,
        task_key: row.task_key,
        task_type: this.taskType,
      }

      this.alertDialogVisible = true
      this.alertStore.setOptionAlertData(payload)
    },
    handleRowClick (row, col, event) {
      if (!event.target.classList.contains('v-simple-checkbox')) {
        // remove empty values from the payload
        const query = Object.fromEntries(
          Object.entries(row.page_payload).filter(([_, v]) => !!v)
        )
        this.$router.push({ path: row.page, query: query })
      }
    },
    handleSelectAllChange (val) {
      this.filteredData.forEach(r => this.$set(r, 'selected', val))
    },
    handleSelectRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
    handleSearchChanged (val) {
      this.tasksTableFilters[0].value = val
      this.tasksListKey++
    },
    completeSelectedTasks () {
      const tasksKeys = this.selectedRows.map(x => x.task_key)
      const url = baseURLs.manageAPI + '/tasks'
      const data = {
        task_keys: tasksKeys
      }
      this.loadingTasks = true
      axios
        .delete(url, {
          headers: getHeadersWithAccessToken(),
          data,
        })
        .then(() => {
          this.tasksStore.tasks[this.taskType].data.map(t => {
            if (tasksKeys.includes(t.task_key)) {
              t.handled = true
            }
          })
        })
        .finally(() => {
          this.loadingTasks = false
        })
    }
  },
}
</script>

<style lang="scss">
.task-table {
    .has-gutter {
        th:last-child {
            text-align: end;
        }
    }
    .status-column {
        display: flex;
        p {
            font-size: 14px;
            margin: 0;
            color: white;
        }
        .never {
            color: rgba(0, 0, 0, 0.5) !important;
        }
        .seven-days {
            color: var(--success) !important;
        }
        .fourteen-days {
            color: var(--warning) !important;
        }
        .over-fourteen-days {
            color: var(--danger) !important;
        }
    }
}
</style>
