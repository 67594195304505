<template>
  <v-row class="strategy-intake-kpi-tiles d-flex flex-wrap justify-space-around mx-n2">
    <v-col
      cols="5"
      md="2"
      sm="4"
      xs="5"
      v-for="(item, i) in itemsToShow"
      :key="i"
      class="text-center align-center rounded p-relative rounded elevation-2 kpi-tile mx-2 my-2"
    >
      <template v-if="item">
        <div class="mt-1 tile-title d-flex justify-center align-end">
          {{ item.title }}
          <template v-if="item.title === 'Requirement fut'">
            <v-tooltip top :max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
              </template>
              <span>Requirement future is the total amount of goods needed for the selected period, consisting of requirement end, sellthrough buffer, markdown open and outflow</span>
            </v-tooltip>
          </template>
          <template v-if="item.title === 'OTB'">
            <v-tooltip top :max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
              </template>
              <span v-html="otbKpisFormatted"></span>
            </v-tooltip>
          </template>
        </div>
        <div class="mt-2 tile-value">{{ formatMetric(item.value, item.title) }}</div>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { useCubesStore } from '@/store/pinia/cubesStore'

export default {
  name: 'StrategyIntakeKPITiles',
  components: {},
  props: {
    kpiItems: {
      type: Array,
      default: () => [],
    },
    otbKpis: {
      type: Object,
      default: () => ({}),
    },
    metric: {
      type: String,
      default: 'value',
    },
  },
  setup () {
    return {
      cubesStore: useCubesStore(),
    }
  },
  computed: {
    metricFormats () {
      return this.cubesStore.metricFormat
    },
    itemsToShow () {
      return this.kpiItems.length ? this.kpiItems : new Array(4)
    },
    otbKpisFormatted () {
      return Object
        .keys(this.otbKpis)
        .map(key => `<b>${this.$options.filters.formatString(key)}</b>: ${this.formatMetric(this.otbKpis[key], key)}`)
        .join('<br/>')
    }
  },
  methods: {
    formatMetric (value, metricName) {
      let format = this.metricFormats[metricName.toLowerCase().replace(' ', '_')] || 'integer'

      if (this.metric === 'pieces' && typeof value === 'number') {
        format = 'integer'
      }

      return this.$options.filters[format](value)
    },
  },
}
</script>

<style lang="scss">
  .strategy-intake-kpi-tiles {
    .kpi-tile {
      background-color: white;
      color: var(--text-sub-black);
      height: 160px;
      padding-top: 50px;
      flex-basis: 160px;
      flex-grow: 0;
      display: inline-block;
      .tile-title {
        font-size: 12px;
        font-weight: 500;
      }
      .tile-value {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  @media (min-width: 1600px) {
    .strategy-intake-kpi-tiles {
      .kpi-tile {
        flex-basis: 190px !important;
      }
    }
  }
  @media (min-width: 1900px) {
    .strategy-intake-kpi-tiles {
      .kpi-tile {
        flex-basis: 220px !important;
      }
    }
  }
</style>
