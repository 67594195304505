<template>
  <v-card class="buy-manage-costs-modal">
    <v-card-title class="text-body-1 font-weight-medium my-2 px-12 justify-space-between">
      {{ `${cost && cost.cost_key ? 'Edit cost' : 'Add cost'}` }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text--primary px-12">
      <h6 class="text-subtitle-1 font-weight-medium mt-4">General</h6>
      <v-col class="align-baseline mt-0 pt-0 mb-4 mx-0 px-0">
        <v-row class="mt-2 mb-4 mx-0">
          <v-text-field
            label="Name"
            clearable
            hide-details
            v-model="cost.cost_name"
            :error-messages="name_error"
          >
          </v-text-field>
        </v-row>
        <v-row class="mt-2 mb-4 mx-0">
          <v-autocomplete
            :menu-props="{
              left: true,
            }"
            :items="currencies"
            item-text="name"
            item-value="iso_code"
            hide-details
            label="Currency"
            :error-messages="currency_error"
            v-model="cost.currency"
          >
          </v-autocomplete>
        </v-row>
        <v-row class="mt-2 mb-4 mx-0">
          <v-autocomplete
            :menu-props="{
              left: true,
            }"
            :items="payments"
            item-text="payment_name"
            item-value="payment_key"
            hide-details
            clearable
            label="Payments"
            v-model="cost.payment_key"
            :error-messages="payments_error"
            @input="paymentKeyChange"
          >
          </v-autocomplete>
        </v-row>
        <h6 class="text-subtitle-1 font-weight-medium mt-4 ">Costs</h6>
        <v-row class="mt-2 mb-4 mx-0 flex xs6">
          <v-text-field
            type="number"
            step=".01"
            label="Per Piece"
            hide-details
            :value="cost.cost_per_piece || 0"
            @input="(value)=> cost.cost_per_piece = value || 0"
          ></v-text-field>
        </v-row>
        <v-row class="mt-2 mb-4 mx-0 flex xs6">
          <v-text-field
            type="number"
            step=".01"
            hide-details
            label="Per Order"
            :value="cost.cost_per_order || 0"
            @input="(value)=> cost.cost_per_order =  value || 0"
          ></v-text-field>
        </v-row>
        <v-row class="mt-2 mb-4 mx-0 flex xs6">
          <v-text-field
            type="number"
            step="1"
            hide-details
            label="Buy Price"
            suffix="%"
            :value="(cost.perc_of_cost_price * 100).toFixed(2) || 0"
            @input="(value)=> perc_of_cost_price =  value || 0 "
          ></v-text-field>
        </v-row>
        <v-row class="mt-2 mb-4 mx-0 flex xs6">
          <v-text-field
            type="number"
            step="1"
            label="Retail Price"
            suffix="%"
            hide-details
            :value="(cost.perc_of_retail_price * 100).toFixed(2) || 0"
            @input="(value)=> perc_of_retail_price =  value || 0 "
          ></v-text-field>
        </v-row>

      </v-col>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row class="dialog-footer text-right py-2 px-4">
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="$emit('close')"
              >Cancel</v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                class="mx-4"
                @click="saveCost"
              >Save</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-card-actions>
  </v-card>
</template>

<script>
import axios from '../services/axios'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import baseURLs from '@/api/APIBaseURLs'

export default {
  name: 'BuyManageCostsModal',
  data () {
    return {
      perc_of_retail_price: null,
      perc_of_cost_price: null,
      name_error: '',
      currency_error: '',
      payments_error: '',
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    currencies () {
      return this.appConfig?.currencies || []
    },
  },
  props: {
    cost: {
      type: Object,
      default () {
        return {}
      }
    },
    payments: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    saveCost () {
      const url = baseURLs.manageAPI
      this.name_error = ''
      this.currency_error = ''
      this.payments_error = ''
      if (this.perc_of_cost_price !== null) {
        this.cost.perc_of_cost_price = (this.perc_of_cost_price / 100 || 0)
      }
      if (this.perc_of_retail_price !== null) {
        this.cost.perc_of_retail_price = (this.perc_of_retail_price / 100 || 0)
      }
      if (!this.validates()) {
        return
      }
      axios.post(url + '/cost', this.cost).then(() => this.$emit('save'))
    },
    paymentKeyChange (value) {
      if (!value) {
        this.cost = { ...this.cost }
      }
    },
    validates () {
      if (!this.cost.cost_name) {
        this.name_error = 'Can not be empty'
      }
      if (!this.cost.currency) {
        this.currency_error = 'Can not be empty'
      }
      if (!this.cost.payment_key) {
        this.payments_error = 'Can not be empty'
      }
      return !this.name_error && !this.currency_error && !this.payments_error
    },
  },
}
</script>
