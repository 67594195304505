<template>
  <div class="assortment-search-properties p-relative">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <div class="d-flex">
      <div class="block-wrapper mr-7">
        <div class="text-body-1 font-weight-medium pt-6 pl-6">Properties include</div>
        <div class="container ml-6">
          <v-row class="align-baseline" v-for="(_, k) in propertiesInclude" :key="k">
            <v-col :cols="11">
              <v-row>
                <v-col :cols="4" class="pl-0 text-body-2">
                  <v-autocomplete
                    :items="getPropertiesIncludeList(k)"
                    class="pt-0"
                    :value="k"
                    @change="val => handlePropIncludeChanged(k, val)"
                    placeholder="Select property"
                    hide-details="auto"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                  />
                </v-col>
                <v-col :cols="8">
                  <collapsible-select
                    :value="propertiesInclude[k]"
                    :items="propertiesIncludeValuesList[k]"
                    autocomplete
                    show-select-all
                    hide-details="auto"
                    @change="val => $emit('propIncludeValuesChanged', {
                      prop: k,
                      value: val
                    })"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="1" class="text-right">
              <v-icon
                class="action-btn-danger"
                @click="$emit('propIncludeDeleted', k)"
              >
                delete
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pl-0 pb-6">
              <v-icon
                :disabled="addIncludeDisabled"
                class="action-btn-primary"
                @click="handlePropIncludeAddClick"
              >add</v-icon>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="block-wrapper ml-7">
        <div class="text-body-1 font-weight-medium pt-6 pl-6">Properties exclude</div>
        <div class="container ml-6">
          <v-row class="align-baseline" v-for="(_, k) in propertiesExclude" :key="k">
            <v-col :cols="11">
              <v-row>
                <v-col :cols="4" class="pl-0 text-body-2">
                  <v-autocomplete
                    :items="getPropertiesExcludeList(k)"
                    :value="k"
                    @change="val => handlePropExcludeChanged(k, val)"
                    placeholder="Select property"
                    class="pt-0"
                    hide-details="auto"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                  />
                </v-col>
                <v-col :cols="8">
                  <collapsible-select
                    :value="propertiesExclude[k]"
                    :items="propertiesExcludeValuesList[k]"
                    autocomplete
                    show-select-all
                    hide-details="auto"
                    @change="val => $emit('propExcludeValuesChanged', {
                      prop: k,
                      value: val
                    })"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="1" class="text-right">
              <v-icon
                class="action-btn-danger"
                @click="$emit('propExcludeDeleted', k)"
              >
                delete
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pl-0 pb-6">
              <v-icon
                :disabled="addExcludeDisabled"
                class="action-btn-primary"
                @click="handlePropExcludeAddClick"
              >add</v-icon>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import { usePropertiesStore } from '@/store/pinia/propertiesStore'

export default {
  props: ['propertiesInclude', 'propertiesExclude'],
  setup () {
    return {
      propertiesStore: usePropertiesStore(),
    }
  },
  data () {
    return {
      loading: false,
      propertiesIncludeValuesList: {},
      propertiesExcludeValuesList: {},
    }
  },
  components: {
    CollapsibleSelect,
  },
  computed: {
    allIncludePropsAdded () {
      return this.getPropertiesIncludeList().length === 1
    },
    allExcludePropsAdded () {
      return this.getPropertiesExcludeList().length === 1
    },
    allIncludePropsHaveValues () {
      return Object.keys(this.propertiesInclude).every(
        prop => this.propertiesInclude[prop].length
      )
    },
    allExcludePropsHaveValues () {
      return Object.keys(this.propertiesExclude).every(
        prop => this.propertiesExclude[prop].length
      )
    },
    addIncludeDisabled () {
      return this.allIncludePropsAdded || !this.allIncludePropsHaveValues
    },
    addExcludeDisabled () {
      return this.allExcludePropsAdded || !this.allExcludePropsHaveValues
    },
  },
  methods: {
    loadIncludeValues (keys) {
      this.loading = true
      try {
        const propertyValues = this.loadValues(keys)
        this.propertiesIncludeValuesList = Object.assign({}, this.propertiesIncludeValuesList, propertyValues)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    loadExcludeValues (keys) {
      this.loading = true
      try {
        const propertyValues = this.loadValues(keys)
        this.propertiesExcludeValuesList = Object.assign({}, this.propertiesExcludeValuesList, propertyValues)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    getPropertiesIncludeList (prop) {
      const includeKeys = Object.keys(this.propertiesInclude)
      return this.propertiesStore.storesPropertiesList
        .filter(p => p === prop || !includeKeys.includes(p))
        .map(x => ({
          text: this.$options.filters.formatString(x),
          value: x,
        }))
    },
    getPropertiesExcludeList (prop) {
      const excludeKeys = Object.keys(this.propertiesExclude)
      return this.propertiesStore.storesPropertiesList
        .filter(p => p === prop || !excludeKeys.includes(p))
        .map(x => ({
          text: this.$options.filters.formatString(x),
          value: x,
        }))
    },
    handlePropIncludeAddClick () {
      const newProp = this.getPropertiesIncludeList()[0]?.value
      this.$emit('propIncludeAdded', newProp)
      this.loadIncludeValues([newProp])
    },
    handlePropExcludeAddClick () {
      const newProp = this.getPropertiesExcludeList()[0]?.value

      this.$emit('propExcludeAdded', newProp)
      this.loadExcludeValues([newProp])
    },
    handlePropIncludeChanged (oldProp, newProp) {
      this.$emit('propIncludeChanged', { oldProp, newProp })
      this.loadIncludeValues([newProp])
    },
    handlePropExcludeChanged (oldProp, newProp) {
      this.$emit('propExcludeChanged', { oldProp, newProp })
      this.loadExcludeValues([newProp])
    },
    loadValues (keys) {
      const propertyValues = usePropertiesStore().getStoresPropertiesValues(keys)
      const res = {}
      for (const prop in propertyValues) {
        res[prop] = propertyValues[prop].map(x => ({
          text: this.$options.filters.formatString(x),
          value: x,
        }))
      }
      return res
    }
  },
  created () {
    const propsInclude = Object.keys(this.propertiesInclude)
    const propsExclude = Object.keys(this.propertiesExclude)

    if (propsInclude.length) {
      this.loadIncludeValues(propsInclude)
    }

    if (propsExclude.length) {
      this.loadExcludeValues(propsExclude)
    }
  },
}
</script>

<style lang="scss">
.assortment-search-properties {
    .el-select {
        width: 200px;
    }
    .block-wrapper {
        width: 460px;
    }
}
</style>
