/**
 * This is a workaround for a bug in Element UI table sorter.
 * Table header click does not work when said header contains a filter.
 */

// Get all table filter elements
function getFilterElements () {
  return document.querySelectorAll('.el-table th.el-table__cell > div.cell > span.el-table__column-filter-trigger')
}

// If the element contains a sorter, click it. This is a separate function, so it won't be added multiple times
function elementClick (e) {
  const sorterElement = e.target.querySelector('span.caret-wrapper')
  if (sorterElement) {
    sorterElement.click()
  }
}

function fixFilteredTableSorter () {
  const filterElements = getFilterElements()
  if (filterElements.length === 0) {
    return
  }
  // Find the parent element of the filter element, that one is the name cell of the column
  filterElements.forEach(filterEl => {
    const columnHeaderCell = filterEl.parentElement
    // Add click event listener to the column header cell
    columnHeaderCell.addEventListener('click', elementClick)
  })
}

// Call callback function count times with ms delay between calls
function busyLoop (callback, count, ms) {
  if (count <= 0) {
    return
  }
  setTimeout(() => {
    callback()
    busyLoop(callback, count - 1, ms)
  }, ms)
}
// Let's assume first 15 seconds are enough to load the table and column filters
busyLoop(fixFilteredTableSorter, 5, 3000)

// Call the function again every 10 seconds as long as the page is open
setInterval(fixFilteredTableSorter, 15000)
