<template>
  <div class="dummies-filter">
    <div class="mb-10 form-div">
      <div class="mb-2 title-wrapper">
        <h4 class="mb-1">
          {{ filterData.label }}
        </h4>
        <v-icon @click="$emit('revert-click', filterName)">replay</v-icon>
      </div>
      <v-row>
        <v-col>
          <v-text-field
            v-model="selectedFilterOptions.name_search"
            clearable
            hide-details
            label="Name search"
            @click:clear="selectedFilterOptions.name_search = null"
            @change="handleFilterChanged"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-menu
            v-model="datePickerFromActive"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="selectedFilterOptions.created_from"
                clearable
                label="Created from"
                hide-details="auto"
                v-on="on"
                @click:clear="handleClearClick('created_from')"
              ></v-text-field>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              :locale-first-day-of-year="4"
              :show-week="true"
              no-title
              v-model="selectedFilterOptions.created_from"
              @change="handleFilterChanged"
            />
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="datePickerToActive"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            left
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="selectedFilterOptions.created_to"
                clearable
                label="Created to"
                hide-details="auto"
                v-on="on"
                @click:clear="selectedFilterOptions.created_to = null"
              ></v-text-field>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              :locale-first-day-of-year="4"
              :show-week="true"
              no-title
              v-model="selectedFilterOptions.created_to"
              @change="handleFilterChanged"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            type="number"
            v-model.number="selectedFilterOptions.price_from"
            clearable
            hide-details
            label="Price from"
            @click:clear="selectedFilterOptions.price_from = null"
            @change="handleFilterChanged"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            type="number"
            v-model.number="selectedFilterOptions.price_to"
            clearable
            hide-details
            label="Price to"
            @click:clear="selectedFilterOptions.price_to = null"
            @change="handleFilterChanged"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row
        v-for="(prop, index) in selectedProperties"
        :key="index"
        class="align-center"
      >
        <v-col>
          <v-autocomplete
            v-model="prop.name"
            :items="getAvailableProperties(prop)"
            label="Property"
            hide-details
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @change="handlePropNameChange(index)"
          />
        </v-col>
        <v-col>
          <CollapsibleSelect
            v-if="prop.name"
            v-model="prop.values"
            :items="getPropertyValuesOptions(prop)"
            autocomplete
            class="mt-3"
            show-select-all
            label="Value"
            hide-details
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @change="handlePropValueChange(prop)"
          />
        </v-col>
        <v-col :cols="1">
          <v-icon
            class="action-btn-danger"
            @click="handlePropDeleteClick(index)"
          >delete</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-icon class="plus-icon" @click="handleAddPropClick">add</v-icon>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { filtersData } from '@/variables'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
// import { loadDummiesProperties } from '@/api/properties'

import { useFiltersStore } from '@/store/pinia/filtersStore'
import {getPropertiesAndValues} from '@/api/options'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  props: {
    filterName: {
      type: String,
      required: true,
    },
    filterData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { CollapsibleSelect },
  data () {
    return {
      filtersData,
      datePickerFromActive: false,
      datePickerToActive: false,
      selectedProperties: [{
        name: null,
        values: []
      }],
      studioPropertiesValues: {},
      studioPropertiesKeys: [],
    }
  },
  computed: {
    selectedFilterOptions () {
      return this.filtersStore.selectedFiltersOptions[this.filterName]
    },
  },
  watch: {
    selectedFilterOptions: {
      handler () {
        this.setPropertiesFilter()
      },
      deep: true,
    }
  },
  methods: {
    handleFilterChanged () {
      this.$emit('filter-changed', {
        filterName: this.filterName,
        value: this.selectedFilterOptions,
      })
    },
    handleClearClick (key) {
      this.selectedFilterOptions[key] = null
      this.handleFilterChanged()
    },
    getAvailableProperties (selectedProperty) {
      const available = []

      if (selectedProperty.name) {
        available.push({
          text: this.$options.filters.formatString(selectedProperty.name),
          value: selectedProperty.name,
        })
      }
      const props = this.studioPropertiesKeys
      return [
        ...available,
        ...props
          .filter(p => !this.selectedProperties.map(sp => sp.name).includes(p))
          .map(p => ({
            value: p,
            text: this.$options.filters.formatString(p),
          })),
      ]
    },
    getPropertyValuesOptions (prop) {
      return this.studioPropertiesValues[prop.name]
        .map(x => ({
          value: x,
          text: this.$options.filters.formatString(x),
          disabled: false,
        }))
    },
    emitProperties () {
      const properties = this.selectedProperties.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.name]: cur.values,
        }
      }, {})

      this.$emit('filter-changed', {
        filterName: this.filterName,
        value: {
          ...this.selectedFilterOptions,
          properties,
        },
      })
    },
    handlePropNameChange (index) {
      this.selectedProperties[index].values = []
      this.emitProperties()
    },
    handlePropValueChange () {
      this.$nextTick(() => {
        this.emitProperties()
      })
    },
    handleAddPropClick () {
      this.selectedProperties.push({
        name: null,
        values: [],
      })
    },
    handlePropDeleteClick (index) {
      this.selectedProperties.splice(index, 1)
      this.emitProperties()
    },
    setPropertiesFilter () {
      const keys = Object.keys(this.selectedFilterOptions.properties)

      if (keys.length) {
        this.selectedProperties = keys.map(key => ({
          name: key,
          values: this.selectedFilterOptions.properties[key]
        }))
      } else {
        this.selectedProperties = [{
          name: null,
          values: []
        }]
      }
    }
  },
  created () {
    this.setPropertiesFilter()
    return getPropertiesAndValues('dummies' )
      .then((data ) => {
        this.studioPropertiesValues = data.values
        this.studioPropertiesKeys = data.properties
      })
  },
}
</script>

<style lang="scss">
.dummies-filter {
    .v-chip__content > span {
        max-width: 50px;
        overflow: hidden;
    }
    .form-div {
        width: 100%;
        .title-wrapper {
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>
