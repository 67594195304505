<template>
  <div class="table-search-component">
    <template v-if="searchActive">
      <v-text-field
        ref="searchInput"
        class="d-inline-block mr-2"
        dense
        label="Search"
        v-model="searchTerm"
        hide-details="auto"
        @keyup.enter="$emit('searchChanged', searchTerm)"
        @change="$emit('searchChanged', searchTerm)"
      >
        <v-icon slot="append" @click="$emit('searchChanged', searchTerm)" class="action-btn-primary">search</v-icon>
      </v-text-field>
      <v-icon class="d-inline-block ml-2" @click="handleSearchCloseClick">close</v-icon>
    </template>
    <v-icon v-else class="action-btn-primary" @click="handleSearchIconClick">search</v-icon>
  </div>
</template>

<script>
export default {
  name: 'TableSearch',
  data () {
    return {
      searchActive: false,
      searchTerm: ''
    }
  },
  methods: {
    handleSearchCloseClick () {
      this.searchActive = false
      this.searchTerm = ''
      this.$emit('searchChanged', this.searchTerm)
    },
    handleSearchIconClick () {
      this.searchActive = true
      this.$nextTick(() => {
        if (this.$refs['searchInput']) {
          this.$refs['searchInput'].focus()
        }
      })
    },
  }
}
</script>

<style lang="scss">
.table-search-component {
    display: inline-block;
    position: relative;
    .v-text-field {
        margin-top: 2px;
        position: absolute;
        width: 200px;
        max-width: none;
        right: 32px;
    }
    .v-label {
        font-size: 14px;
    }
}
</style>
