<template>
  <div>
    <!-- TO:DO Elan ask about this <app-alert-modal id="tasks-table-alerts" :isTask="true"></app-alert-modal> -->
    <div class="row mt-50">
      <div class="col-12">
        <v-card min-height="350px">
          <v-overlay absolute color="white" :value="!activeTabName" >
            <v-progress-circular color="primary" indeterminate size="64" width="2" />
          </v-overlay>
          <h4 class="px-4 py-4">Tasks</h4>
          <v-tabs v-model="activeTab" grow show-arrows>
            <v-tabs-slider/>
            <v-tab
              v-for="item of tabs"
              :key="item"
              class="px-8"
              style="max-width: 220px"
            >
              <template
                v-if="item === '1_alerts' && tasksStore.tasks.alerts.data.filter(x => !x.handled).length">
                <v-badge color="red" dot>
                  {{ formatKey(item) }}
                </v-badge>
              </template>
              <template v-else>
                {{ formatKey(item) }}
              </template>
            </v-tab>
          </v-tabs>
          <ProductTable v-if="activeTabName" @rowClicked="setOptionKey" :task-type="activeTabName" ref="productTable" class="mb-50"></ProductTable>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import ProductTable from './ProductTable'
import { useOrderTasksStore } from '@/store/pinia/tasksStore'

export default {
  setup () {
    return {
      tasksStore: useOrderTasksStore()
    }
  },
  computed: {
    tabs () {
      const data = this.tasksStore.taskEndpoints
      return data.sort()
    },
    activeTabName () {
      return (this.tabs[this.activeTab] || '').substring(2)
    },
    activeTab: {
      set (val) {
        this.tasksStore.updateTasksTab(val)
      },
      get () {
        return this.tasksStore.tasksActiveTab
      },
    },
  },
  methods: {
    formatKey (key) {
      key = key.substr(2)
      key = key.replace('_', ' ')
      key = key.replace('_', ' ')
      return key.charAt(0).toUpperCase() + key.slice(1)
    },
    setOptionKey (row) {
      let path = row.module || 'stock'
      if (!row.module && row.page === 'reorder') {
        path = 'buy'
      }
      this.$router.push({ path: `/${path}/${row.page}`, query: row.page_payload })
    },
  },
  components: {
    ProductTable,
    // appOptionAlertModal: OptionAlertModal
  },
}
</script>

<style lang="scss">
</style>
