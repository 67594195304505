<template>
  <div class="dummy-info">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <ImageUploadDialog
      :visible="imageDialogVisible"
      @cancel="imageDialogVisible = false"
      @image-uploaded="handleImageUploaded"
    />

    <v-card class="pa-4 dummy-card" flat min-height="220">
      <div class="mb-2 dummy-title">
        <h4>Option</h4>
        <div v-if="dummy" class="d-flex">
          <v-tooltip v-if="dummy.in_use" top :max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="mr-3 error--text">warning</v-icon>
            </template>
            <span>Dummy in use</span>
          </v-tooltip>
          <v-icon class="mr-3 action-btn-primary" @click="handleEditImageClick">camera_alt</v-icon>
        </div>
      </div>

      <template v-if="dummy">
        <div class="status-row">
          <v-menu
            top
            open-on-hover
            offset-x
            min-width="300"
            max-width="300"
            content-class="option-info-menu"
            :open-delay="200"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <span :style="{ color: dummyStatusColorMap[statusToDisplay] }" v-bind="attrs"
                v-on="on">
                {{ statusToDisplay | capitalize }}
              </span>
            </template>

            <v-card
              class="py-2 px-4 p-relative"
            >
              <div v-for="(val, name) in statusItems" :key="name">
                <div class="d-inline-block mr-4">{{ name | formatString }}</div>
                <span v-if="val" class="success--text">Filled</span>
                <span v-else class="error--text">Missing</span>
              </div>
              <div>
                <div class="d-inline-block mr-4">Option name</div>
                <span v-if="dummy.option_name.length" class="success--text">Filled</span>
                <span v-else class="error--text">Missing</span>
              </div>
              <div>
                <div class="d-inline-block mr-4">Supplier</div>
                <span v-if="dummy.supplier_key" class="success--text">Filled</span>
                <span v-else class="error--text">Missing</span>
              </div>
              <div>
                <div class="d-inline-block mr-4">SKUs</div>
                <span v-if="dummy.sku_names.length" class="success--text">Filled</span>
                <span v-else class="error--text">Missing</span>
              </div>
              <div>
                <div class="d-inline-block mr-4">Price</div>
                <span v-if="dummy.prices && Object.keys(dummy.prices).length" class="success--text">Filled</span>
                <span v-else class="error--text">Missing</span>
              </div>
            </v-card>
          </v-menu>
        </div>

        <div class="image-row">
          <img
            height="184px"
            class="mt-2"
            :src="dummy.image_url || '@/assets/img/ts_placeholder.png'"
            @error="loadPlaceholder"
            alt=""/>
        </div>
      </template>
    </v-card>
  </div>
</template>

<script>
import * as dummiesApi from '@/api/dummies'
import { dummyStatusColorMap } from '@/variables.js'
import ImageUploadDialog from '@/components/ImageUploadDialog'
import ts_placeholder from '@/assets/img/ts_placeholder.png'

export default {
  components: { ImageUploadDialog },
  props: {
    dummy: {
      type: Object,
      default: () => ({}),
    },
    managedPropertiesValid: {
      type: Boolean,
      required: true,
    },
    variantPropertiesValid: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      dummyStatusColorMap,
      imageDialogVisible: false,
      loading: false,
    }
  },
  computed: {
    statusItems () {
      return {
        properties_option: this.managedPropertiesValid,
        properties_variation: this.variantPropertiesValid,
      }
    },
    statusToDisplay () {
      return this.dummy.status
    },
  },
  methods: {
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    handleEditImageClick () {
      this.imageDialogVisible = true
    },
    handleImageUploaded (imageUrl) {
      const payload = {
        ...this.dummy,
        image_url: imageUrl,
      }

      this.loading = true
      this.imageDialogVisible = false

      return dummiesApi
        .saveDummy(payload)
        .then(() => {
          this.dummy.image_url = imageUrl
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleExportChanged (val) {
      const payload = {
        ...this.dummy,
        export: val,
      }

      this.loading = true

      return dummiesApi
        .saveDummy(payload)
        .then(() => {
          this.$emit('export-changed', val)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.dummy-info {
    .dummy-card {
        .dummy-title {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .icon-has-action:hover {
                color: var(--primary);
                cursor: pointer;
            }
        }
        .status-row {
            font-size: 14px;
        }
        .image-row {
            display: flex;
            justify-content: center;
        }
    }
}
</style>
