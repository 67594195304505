<template>
  <v-card
    v-if="!isChangeView"
    :flat="flat || inModal"
    ref="dougnutCanvas"
    class="pa-4 pt-2 d-flex flex-column overflow-auto">
    <div class="d-flex align-center ma-0 justify-space-between pb-2" v-if="index !== null && !hide">
      <h4 class="text-no-wrap">{{positionTitle}}. {{ view.view.view_name }}</h4>
      <div class="d-flex show-on-hover flex-nowrap pt-1" v-if="!view.view.errored && !view.errored" >
        <v-icon class="show-on-hover mr-1 action-btn-primary" @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" v-if="!inModal">edit</v-icon>
        <v-icon
          class="mr-1 action-btn-primary"
          @click="$emit('full-screen',view,index)"
          v-if="!inModal"
        >fullscreen</v-icon>
        <data-sets-view-filter :view="view" @change="filterChanged" :index="index" ></data-sets-view-filter>
      </div>

      <template v-else-if="view.view.errored || view.errored" >
        <div class="d-flex show-on-hover flex-nowrap pt-1">
          <v-icon class="show-on-hover action-btn-primary " @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" v-if="!inModal">edit</v-icon>
        </div>
      </template>
    </div>
    <v-overlay absolute color="white" :value="(!view.data) && !(view.view.errored || view.errored)">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-row v-if="!view.view.errored && !view.errored" class="d-flex flex-column align-center justify-center text-center ma-0 pb-5">
      <h1 style="line-height: normal">{{mainTitle}}</h1>
      <h2 class="pt-10">{{targetTitle}}</h2>
    </v-row>

    <h4 v-else class="row justify-center py-8"> Could not retrieve data</h4>
  </v-card>

  <datasets-new-view
    v-else
    :dashboard-details="dashboardDetails"
    :viewsMap="viewsMap"
    @saved-positions="$emit('saved-positions', $event)"
    :changeView="{...view.view, position}"
    :positions="positions"
    :datasets="datasets"
    :dataSetForChoose="dataSetForChoose"
    @cancel="isChangeView = false; $emit('toggle-view', false)"
    @delete-view="$emit('delete-view',$event)"
    @save="changeView">
  </datasets-new-view>
</template>

<script>
const DatasetsNewView = () => import('@/components/AutomateDatasets/DatasetsNewView')
import DataSetsViewFilter from '@/components/AutomateDatasets/DataSetsViewFilter'

export default {
  name: 'DataSetsKPI',
  components: { DataSetsViewFilter, DatasetsNewView },
  props: ['view', 'index', 'positions', 'datasets', 'dataSetForChoose', 'shared', 'hide', 'flat',
          'height', 'inModal', 'secondaryIndex', 'viewsMap', 'dashboardDetails'],
  computed: {
    positionTitle () {
      if(this.dashboardDetails?.view_keys[this.index]?.filter(item=> item).length === 1){
        return this.index + 1
      }
      return this.position
    },
    position () {
      return `${this.index + 1}${['A', 'B', 'C', 'D'][this.secondaryIndex]}`
    },
    mainTitleValue () {
      const measure = this.view.view.measures[0].name

      const data = this.view.data[0][measure]
      const mainProperty = this.view.view.measuresProps[measure]
      if (mainProperty?.percentage) {
        if (typeof data !== 'number') {
          return data
        }
        return (data * 100).toFixed(
          mainProperty?.decimals > 2 ? mainProperty?.decimals - 2 : 0
        ) + '%'
      }

      const value = (data || 0).toFixed(
        mainProperty?.decimals)
      if (mainProperty?.decimals) {
        return value
      }
      return this.$options.filters.formatThousands(parseFloat(value))
    },

    mainTitle () {
      const value = this.mainTitleValue
      const targetMeasure = this.view.view.measures[1]?.name

      const measure = this.view.view.measures[0].name

      const data = this.view.data[0][targetMeasure]
      const mainProperty = this.view.view.measuresProps[measure]
      if (targetMeasure) {
        let calculateValue = value
        if(mainProperty?.percentage){
          calculateValue = value.substring(0, value.length - 1) / 100
        }
        const percentage = this.$options.filters.formatPercents(parseFloat(calculateValue.toString().replaceAll(',', '')) / data)
        return value +
          ` (${percentage}%)`
      }
      return value
    },
    targetTitle () {

      if(!this.view.view.measures[1] || !this.view.data){
        return ''
      }
      const measure = this.view.view.measures[1].name
      const data = this.view.data[0][measure]
      const mainProperty = this.view.view.measuresProps[measure]
      if (mainProperty?.percentage) {
        if (typeof data !== 'number') {
          return data
        }
        return (data * 100).toFixed(
          mainProperty?.decimals > 2 ? mainProperty?.decimals - 2 : 0
        ) + '%'
      }
      const value = (data || 0).toFixed(
        mainProperty?.decimals)
      if (mainProperty?.decimals) {
        return value
      }

      const targetValueForrmatted = this.$options.filters.formatThousands(parseFloat(value))
      return `${targetValueForrmatted} `
    },
    isTarget () {
      return this.view.view.measures[1]?.name
    },
    title () {
      return 'data_sets_doughnut_chart'
    }
  },
  data () {
    return {
      innerHeight: 370,
      isChangeView: false,
    }
  },
  methods: {
    filterChanged (value) {
      this.changeView({ view: value, position: this.index })
    },
    changeView (value) {
      this.isChangeView = false
      this.$emit('toggle-view', false)
      this.$emit('change-view', value)
    },
  }
}
</script>

<style scoped>

</style>
