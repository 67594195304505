<template>
  <div class="dummy-lookalikes">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <div v-show="false">
      <div class="pa-4">
        <v-row>
          <v-col :cols="6">
            <h4 class="mt-2 mb-6">Search</h4>
            <template v-if="properties">
              <v-row
                v-for="(prop, index) in selectedProperties"
                :key="index"
                class="py-0 my-0"
              >
                <v-col :cols="5">
                  <v-autocomplete
                    v-model="prop.name"
                    :items="getAvailableProperties(prop)"
                    label="Property"
                    hide-details
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"/>
                </v-col>
                <v-col :cols="5">
                  <v-autocomplete
                    v-if="prop.name && properties[prop.name]"
                    v-model="prop.value"
                    :items="getPropertyValuesOptions(prop)"
                    label="Value"
                    hide-details
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                    @change="handlePropValueChange" />
                  <v-text-field
                    v-else-if="prop.name"
                    class="disabled-text-field"
                    dense
                    hide-details
                    disabled
                    :value="prop.value"/>
                </v-col>
                <v-col v-if="prop.value" :cols="2">
                  <v-icon
                    class="mt-5 action-btn-danger"
                    @click="handlePropDeleteClick(prop)"
                  >delete</v-icon>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col>
                <v-icon class="plus-icon" @click="handleAddPropClick">add</v-icon>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <v-divider />
    </div>

    <div class="pa-4">
      <SimilarOptions
        :options="similarOptions"
        :selected="lookalikeOptions"
        :options-selected="lookalikeOptions"
        @options-added="handleOptionsAdded"
        @image-copied="handleImageCopied"
        @table-search="getSimilarAndLookalikeOptions"
        @options-deleted="handleOptionsDeleted"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as dummiesApi from '@/api/dummies'
import SimilarOptions from './SimilarOptions.vue'

export default {
  components: { SimilarOptions },
  props: {
    dummy: {
      type: Object,
      default: () => ({}),
    },
    properties: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      loading: false,
      selectedProperties: [],
      similarOptions: [],
      lookalikeOptions: [],
    }
  },
  watch: {
    dummy: {
      handler (val) {
        if (!val || this.optionsChanged) {
          this.optionsChanged = false
          return
        }

        this.fillPropertiesFromDummy()
        this.loading = true
        this
          .getSimilarAndLookalikeOptions()
          .finally(() => {
            this.loading = false
          })
      },
      immediate: true,
    },
  },
  methods: {
    fillPropertiesFromDummy () {
      this.selectedProperties = Object.keys(this.dummy.properties).reduce(
        (acc, cur) => {
          return [ ...acc, {
            name: cur,
            value: this.dummy.properties[cur]
          }]
        },
        []
      )
    },
    getAvailableProperties (selectedProperty) {
      const available = []

      if (selectedProperty.name) {
        available.push({
          text: this.$options.filters.formatString(selectedProperty.name),
          value: selectedProperty.name,
        })
      }

      return [
        ...available,
        ...Object.keys(this.properties)
          .filter(p => !this.selectedProperties.map(sp => sp.name).includes(p))
          .map(p => ({
            value: p,
            text: this.$options.filters.formatString(p)
          })),
      ]
    },
    getPropertyValuesOptions (prop) {
      const options = Object.keys(this.properties[prop.name])
        .map(x => ({
          value: x,
          text: this.$options.filters.formatString(x),
          disabled: false,
        }))

      // case for old property which is not present in current properties list
      if (prop.value && !Object.keys(this.properties[prop.name]).includes(prop.value)) {
        options.push({
          value: prop.value,
          text: this.$options.filters.formatString(prop.value),
          disabled: true,
        })
      }

      return options
    },
    getSimilarAndLookalikeOptions (search) {
      this.loading = true
      const payload = {
        filters: this.selectedProperties.reduce(
          (acc, cur) => ({ ...acc, [cur.name]: cur.value }),
          {}
        ),
        lookalikes: this.dummy.lookalike_option_keys,
      }
      if (search) {
        payload.search_text = search
      }

      return dummiesApi
        .getSimilarOptions(payload)
        .then(data => {
          this.similarOptions = data.similar_products
          this.lookalikeOptions = data.lookalikes
          this.loading = false
        })
    },
    handleAddPropClick () {
      this.selectedProperties.push({
        name: null,
        value: null,
      })
    },
    handlePropValueChange () {
      this.loading = true
      this
        .getSimilarAndLookalikeOptions()
        .finally(() => {
          this.loading = false
        })
    },
    handlePropDeleteClick (prop) {
      const index = this.selectedProperties.findIndex(p => p.name === prop.name)

      this.selectedProperties.splice(index, 1)
      this.loading = true
      this
        .getSimilarAndLookalikeOptions()
        .finally(() => {
          this.loading = false
        })
    },
    handleOptionsAdded (options) {
      const payload = {
        ...this.dummy,
        lookalike_option_keys: _.uniq([
          ...this.dummy.lookalike_option_keys,
          ...options.map(o => o.option_key)
        ]),
      }

      this.loading = true

      return dummiesApi
        .saveDummy(payload)
        .then(updatedDummy => {
          this.optionsChanged = true
          this.$emit('options-added', updatedDummy)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleOptionsDeleted (options) {
      const payload = {
        ...this.dummy,
        lookalike_option_keys: _.difference(this.dummy.lookalike_option_keys, options.map(o => o.option_key))
      }

      this.loading = true

      return dummiesApi
        .saveDummy(payload)
        .then(updatedDummy => {
          this.optionsChanged = true

          this.$emit('options-deleted', updatedDummy)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleImageCopied (imageUrl) {
      const payload = {
        ...this.dummy,
        image_url: imageUrl,
      }

      this.loading = true

      return dummiesApi
        .saveDummy(payload)
        .then(() => {
          this.dummy.image_url = imageUrl
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
.dummy-lookalikes {
  .disabled-text-field {
    padding-top:18px;
  }
}
</style>
