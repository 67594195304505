<template>
  <div>
    <div class="d-flex align-center mt-8 ml-6">
      <h6 class="text-subtitle-1 font-weight-medium">{{ title }}</h6>
    </div>
    <div class="text-right pr-6 pb-4">
      <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
      <DataExport class="mx-2" @item-click="downloadOptionsData" />
    </div>
    <div class="p-relative">
      <data-tables
        style="width: 100%"
        class="search-results"
        :key="listKey"
        :filters="tableFilters"
        filter-multiple
        @filter-change="filters => handleFilterChange('tableFilters', filters)"
        :data="options"
        :page-size="5"
        :pagination-props="{
          pageSizes: [5, 10,15, 25, 50],
          class: 'el-pagination text-right my-4 mr-2',
        }">
        <el-table-column
          v-for="col in ['option', 'stock', 'price', 'active', 'inbound']"
          sortable="custom"
          :key="col"
          :prop="col"
          :column-key="col"
          :label="col | formatString"
          :label-class-name="col === 'option' ? 'ml-4' : ''"
          :filters="col === 'option' ? undefined : getColumnFilterValues('options', col)"
          :width="col === 'option' ? '320' : null">
          <template slot-scope="scope">
            <template v-if="col === 'option' && scope.row['option'] !== null">
              <OptionDataCell
                :option="scope.row"
                open-in-new-tab
                class="ml-4"
              />
            </template>
            <template v-else-if="col === 'active'">
              {{ scope.row[col] ? 'Yes' : 'No' }}
            </template>
            <template v-else>{{ scope.row[col] }}</template>
          </template>
        </el-table-column>
      </data-tables>
    </div>
  </div>
</template>

<script>
import TableSearch from '@/components/TableSearch.vue'
import columnFilters from '@/mixins/columnFilters'
import OptionDataCell from '@/components/OptionDataCell.vue'
import DataExport from '@/components/DataExport'
import { downloadData } from '@/utils'

export default {
  name: 'TimelineMappingOptionsTable',
  // Mixins for column filters
  mixins: [columnFilters],
  components: { TableSearch, OptionDataCell, DataExport },
  props: {
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      listKey: 0,
      tableFilters: [
        {
          value: ''
        }
      ],
    }
  },
  methods: {
    handleSearchChanged (val) {
      this.tableFilters[0].value = val
      this.listKey++
    },
    downloadOptionsData (sep) {
      const data = this.options.map(
        row => ['option', 'stock', 'price', 'active', 'inbound'].reduce(
          (acc, cur) => Object.assign(acc, { [cur]: row[cur] }),
          {}
        )
      )

      downloadData(sep, data, 'assortment_timelines_mapping_options')
    },
  }
}
</script>
