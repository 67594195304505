import {defineStore} from 'pinia'
import * as api from '@/api/analytics'
import * as models from '@/models/AnalyticsFilters'

export const useAnalyticsFiltersStore = defineStore('buyFilters', {
  state: (): State => ({
    filtersDataLoaded: false,
    filtersOpened: false,
    singleFiltersOpened: null,
    changedFilters: new Set<string>(),
    filtersChanged: false,
    dashboardDetails: {} as models.DashboardDetails,
    viewsList: [],
    datasetsList: [],
    propertiesWithDataset: {},
    datasetsForProperties: new Set<string>(),
    properties: [],
    filtersApplied: false,
    selectedValues: {
      stores: {},
      toStores: {},
      storesDc: {},
      toStoresDc: {},
      groups: {},
      collections: {},
    },
  }),
  actions: {
    saveChangedFilters (dataset: string) {
      this.changedFilters.add(dataset)
      this.setChangedFilters(this.changedFilters)
    },
    setDashboardDetails (dashboard: models.DashboardDetails) {
      this.dashboardDetails = dashboard
      this.filtersApplied = !!Object.keys(dashboard.filters || {}).length
    },
    async loadProperties (dataset: models.Dataset) {
      if (!dataset) {
        return
      }
      const datasetKey = typeof dataset === 'string' ? dataset : 'none'
      const properties = this.propertiesWithDataset[datasetKey] || await api.loadFilterProperties(dataset)
      this.setFiltersProperties({ properties, datasetKey })
      return properties
    },
    toggleFilter () {
      this.filtersOpened = !this.filtersOpened
    },
    async setViewsFilters (payload: models.ViewsFilters[]) {
      for (const views of payload) {
        for (const view of views.filter(item => Object.keys(item || {}).length)) {
          if (!this.datasetsForProperties.has(view.dataset)) {
            this.datasetsForProperties.add(view.dataset)
            await this.loadProperties(view.dataset)
          }
        }
      }
      this.viewsList = payload
    },
    setFiltersOpened (value: boolean) {
      this.filtersOpened = value
    },
    setChangedFilters (value: Set<string>) {
      this.changedFilters = value
    },
    setDatasetsList (value: string[]) {
      this.datasetsList = value
    },
    setSingleFiltersOpened (value: string | null) {
      this.singleFiltersOpened = value
    },
    setFiltersProperties (payload: { datasetKey: string; properties: models.Property[] }) {
      this.propertiesWithDataset[payload.datasetKey] = payload.properties
      if (payload.datasetKey === 'none') {
        this.properties = payload.properties
      }
      this.propertiesWithDataset = { ...this.propertiesWithDataset }
    },
  },
})

interface State {
  filtersDataLoaded: boolean
  filtersOpened: boolean
  singleFiltersOpened: string | null
  changedFilters: Set<string>
  filtersChanged: boolean
  dashboardDetails: models.DashboardDetails
  viewsList: models.ViewsFilters[]
  datasetsList: models.Dataset[]
  propertiesWithDataset: Record<string, models.Property[]>
  datasetsForProperties: Set<string>
  properties: models.Property[]
  filtersApplied: boolean
  selectedValues: models.SelectedValues
}
