<template>
  <div class="dataTableTable">
    <v-row class="ma-0">
      <v-col class="pa-0">
        <v-overlay absolute color="white" :value="proposalStore.savingProposal">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <data-tables :data="dataTable"
          :page-size="5"
          @filter-change="filters => handleFilterChange('tableFilters', filters)"
          :filters="tableFilters"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
        >
          <el-table-column
            v-for="(col) in columns"
            :key="col"
            :prop="col"
            :fixed="col.toLowerCase() === 'total' || col.toLowerCase() === 'stock'? 'right' : false"
            :column-key="col"
            :width="col === 'Store' ? 200 : ''"
            :filters="col === 'In clusters' ? getColumnFilterValues('dataTable', col, (value)=> value? 'Yes': 'No'): undefined"
            :label="col | formatString"
            :align="col === 'Store' ? 'start': 'center'"
            sortable
          >
            <template slot-scope="scope">
              <div
                :style="{
                  padding: '10px 0',
                  'font-size': '14px',
                  whitespace: 'nowrap'
                }
                "
              >
                <span v-if="col.toLowerCase() === 'store'" style="white-space: nowrap">{{ getStoreName(scope.row[col]) }}</span>
                <span v-else-if="col === 'In clusters'">{{ scope.row[col] ? 'Yes' : 'No' }}</span>
                <span v-else>{{ scope.row[col] }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="Include"
            props="action"
          >
            <template slot-scope="scope">
              <v-icon @click="onActionClick(scope.row)" class="pa-0">add</v-icon>
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>
  </div>
</template>
<script>

import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import columnFilters from '@/mixins/columnFilters'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

import { useArticleStore } from '@/store/pinia/stockArticlesStore'
import _ from 'lodash'

export default {
  setup () {
    return {
      articlesStore: useArticleStore(),
      proposalStore: useStockProposalStore()}
  },
  name: 'ExcludedStores',
  data () {
    return {
      hideActions: true,
      select: true,
      addActionClickDebounce: _.debounce(this.addActionClick, 1000),
      tableFilters: [],
      rowsPerPageItems: [10],
      pagination: {
        rowsPerPage: 10,
      },
    }
  },

  mixins: [ columnFilters ],
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    stores () {
      return (this.appConfig?.stores || [])
    },
    storesNames () {
      return this.stores.reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    potential_excluded () {
      return this.proposalStore.potential_excluded || { table: [], columns: [] }
    },
    dataTable () {
      // Get all data
      return this.potential_excluded.table?.map(item=>({
        in_cluster: this.getStoresCluster(item.Store),
        ...item,
      }))
    },
    columns () {
      // Get all data
      return this.potential_excluded.columns
    },
  },
  methods: {
    onActionClick (row){
      if (!this.proposalStore.potential_excluded) {
        this.proposalStore.potential_excluded = { table: [], columns: [] }
      }
      this.proposalStore.potential_excluded.table = this.potential_excluded.table.filter(item=> item.Store !== row.Store)
      this.actionNeededRows = this.actionNeededRows || []
      this.actionNeededRows.push(row)
      this.addActionClickDebounce()
    },
    addActionClick (){
      this.$emit('add-click', this.actionNeededRows)
    },
    getStoresCluster (store_key){
      return this.articlesStore.articleSettings.stores_clusters?.indexOf(store_key) !== -1 ? 'Yes' : 'No'
    },
    getStoreName (store_key){
      return this.storesNames[store_key]
    }

  },
}
</script>

<style lang="scss">

.dataTableTable {
    .el-table--border::after, .el-table--group::after, .el-table::before {
        background-color: inherit !important;
    }
    .el-table td {
        height: 45px;
        padding: 0;
        border-bottom: none;
    }
    .el-table .cell {
        padding: 0;
      overflow: visible;
        padding-left: 14px;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td {
        background-color: #fff;
    }
}
</style>
