<template>
  <v-row>
    <v-col class="pa-0">
      <v-col cols="10" style="margin-right: -100px; padding-right: 100px" class="pt-0">
        <v-row class="ma-0 ">
          <v-col cols="3" class="py-0 pa-0">
            <slot></slot>
          </v-col>
          <v-col class="align-center justify-center pb-0 mt-4">
            <h4 class="text-center">Sales L5W</h4>
          </v-col>
        </v-row>
      </v-col>
      <data-sets-line-chart
        v-if="trendGraph"
        :view="trendGraph"
        height="320"
        ref="chart"
        index="1"
        :flat="true"
        :hide="true"
      ></data-sets-line-chart>
    </v-col>
  </v-row>
</template>

<script>
import DataSetsLineChart from '@/components/AutomateDatasets/DataSetsLineChart'
import { performViewData } from '@/api/analytics'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  name: 'StockSalesL5WGraph',
  components: { DataSetsLineChart },
  props: {
    chosenDimension: {
      type: String,
      default () {
        return 'properties/hierarchy/group'
      }
    }
  },
  data () {
    return {
      datasets: [],
      trendView: {
        'org_key': 0,
        'view_key': 804,
        'user_id': 51,
        'dataset': 'sales_statistics',
        'view_name': 'Top carrousel: 2. Sales L5W',
        'chart_type': 'bar_chart',
        'dimensions': ['properties/hierarchy/group'],
        'measures': [{
          'axis': 'y2',
          'name': 'Ratio',
          'type': 'point',
          'decimals': 1,
          'color_code': '#085D70',
          'calculation': ['sales', '/', 'potential_belief_stocked'],
          'fill_color_code': '',
          'border_color_code': '#FF9800'
        }, {
          'axis': 'y1',
          'name': 'Sales',
          'type': 'bar',
          'color_code': '#FF9800',
          'calculation': 'sales',
          'fill_color_code': '',
          'border_color_code': '#4CAF50'
        }, {
          'axis': 'y1',
          'name': 'Belief',
          'type': 'bar',
          'color_code': '#FF9800',
          'calculation': 'potential_belief_stocked',
          'fill_color_code': '#FF9800',
          'border_color_code': '#FF9800'
        }],
        'filters': { 'properties/calendar/relative_week': ['-1', '-2', '-3', '-4', '-5'] },
        'properties': {
          'axis': { 'y1': 'Pieces', 'y2': 'Ratio', 'y1_min': 0, 'y2_max': 2, 'y2_min': 0 },
          'sort': 'Belief',
          'bar_type': 'shadow',
          height:'300px',
          'sort_order': 'descending',
          'parent_limit': 100
        },
        'creation_date': '2023-01-19 13:28:17',
        'limited': false,
        'filters_applied': ['properties/calendar/relative_week']
      },
      trendGraph: null,
    }
  },
  methods: {
    redrawGraph () {
      const pageFilters = this.filtersStore.getPageFiltersNonDefaultValues()
      this.trendView.filters.collection_key = pageFilters.collections || []
      this.trendView.filters.group_key = pageFilters.groups || []
      this.trendView.filters.store_key = pageFilters.stores || []
      this.trendView.filters['properties/article/option_key'] = pageFilters.options || []
      this.trendView.dimensions[0] = this.chosenDimension
      performViewData(this.trendView).then(r => {

        this.trendGraph = r
        this.$emit('load')
      })
    }
  },
  watch: {
    chosenDimension () {
      this.redrawGraph()
    },
    'filtersStore': function () {
      this.redrawGraph()
    }
  },
  mounted () {
    this.redrawGraph()
  }
}
</script>

<style scoped>

</style>
