<template>
  <div class="storepage-menu">
    <router-view/>
  </div>
</template>

<script>
import { getHeadersWithAccessToken, headers } from '@/variables'
import axios from '@/services/axios'
import baseURLs from '@/api/APIBaseURLs'

export default {
  props: {
    isPrint: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    checkToken () {
      // Decide whether we use headers with authorisation or not.
      const h = (localStorage.getItem('access_token') ? getHeadersWithAccessToken() : headers)
      // Here token may be null, but the endpoint can handle it and we can respond to the error if necessary.
      const payload = { token: localStorage.getItem('storepage_token'), store_key: parseInt(this.$route.params.store_key) }
      axios.post(baseURLs.storepageAPI + '/check_token', payload, { headers: h })
        .catch(error => {
          if (error.response.status === 403) {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            localStorage.removeItem('email')
            localStorage.removeItem('expires_at')
            localStorage.removeItem('org')
            localStorage.removeItem('groups')
            this.$router.push({ name: 'storepage-resend-token', query: { token: this.$route.query.token, storeResendToken: true } })
          } else {
            this.$router.push({ name: 'login', params: { storeLogin: true } })
          }
        })
    }
  },
  created () {
    if (!this.isPrint) {
      this.checkToken()
    }
  }
}
</script>

<style lang="scss">
.storepage-menu {
    .bg-info {
        background-color: #7250ec !important;
    }
    .dropdown-item {
        color: #333333 !important;
        height: 3em;
        line-height: 3em;
        padding: 0 6em;
    }
    .dropdown-menu  {
        // margin: 0;
        // padding: 0;
        border: none;
        // border-radius: 8px !important;
        background-color: #f1c506;
    }
    .transfer-menu-tab {
        color: white !important;
        &:hover {
            color: #333333 !important;
            background-color: #ffe057;
        }
    }
    .dropdown-toggle::after {
        display:none;
    }
}
</style>
