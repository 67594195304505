import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'
import * as models from '@/models/Rules'

export async function getRules (): Promise<models.RulesResponse> {
  const response = await axios.post<models.RulesResponse>(baseURLs.logicAPI + '/rules', {})
  return {
    rules: Object.values(response.data.rules),
    value: response.data.value,
    parameters: response.data.parameters
  }
}
