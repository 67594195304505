<template>
  <div>
    <v-row class="ma-0 color-container pa-0">
      <div
        class="color-picker ma-0"
        v-for="color of chartColors"
        :key="color"
        :style="{backgroundColor: color}"
        @click="$emit('input', color)"
      />
      <slot></slot>
    </v-row>

    <v-col cols="12" class="pa-0">
      <v-text-field
        style="width: 100px"
        v-model="chosenColor"
        hide-details
        @change="$emit('input', $event)"
        label="Hex"
      ></v-text-field>
    </v-col>
  </div>
</template>

<script>
import { chartColors } from '@/variables'
export default {
  name: 'ColorPicker',
  props: {
    chosenColor: {
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      chartColors: [...chartColors.slice(0, chartColors.length - 1), '#FF5722', '#FFD202', '#FFFFFF'],
    }
  }
}
</script>

<style scoped>
.color-picker {
  width: 48px;
  max-height: 34px;
  min-height: 34px;
  cursor: pointer;
  border-radius: 4px;
  border: 0.5px solid rgb(235,235,235);
}

.color-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
</style>
