<template>
  <div class="buy-manage-payments mb-6">
    <v-row class="ma-0" justify="space-between">
      <h4 class="pa-6 pb-2 pt-8 ">Payments</h4>
    </v-row>
    <v-row class="align-center ml-7 pb-10">
      <v-col cols="3" class="pl-0">
        <v-autocomplete
          :items="innerPayments.map((item)=> {
            return {
              text: item.payment_name,
              value: item,
            }
          })"
          label="Payment Name"
          :value="selectedPayment"
          class="pa-0 ma-0 "
          hide-details
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"
          @input="changePayment"
        />
      </v-col>
      <v-col cols="2">
        <v-btn icon class="my-2 mb-0" @click="editPayment">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn icon class="mt-2 mb-0" @click="onAddPayment">
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn
          icon
          class="mt-2 mb-0"
          :disabled="!selectedPayment || !selectedPayment.payment_key"
          @click="deleteDialogVisible = true"
        >
          <v-icon >delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <NameDialog
      :is-open="nameDialogVisible"
      :isAdd="isAdd"
      title="Payment Name"
      text-field-label="Payment Name"
      :value="selectedPayment.payment_name ? selectedPayment.payment_name : ''"
      @close="nameDialogVisible = false"
      @save="changeName"
      @add="addPayment"
    />
    <div v-for="(key, index) in selectedPayment.schedule" :key="index" class="d-flex px-6 mb-6">
      <div class="ruleblock-mark p-relative d-flex align-center">
        <v-icon>radio_button_checked</v-icon>
      </div>
      <div class="pa-6 grow ruleblock-content">
        <v-row class="align-center">
          <v-col class="py-0" cols="3">
            <v-select
              :items="anchor_data"
              class="pt-0"
              v-model="key.point"
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
              @input="savePayment"
            />
          </v-col>
          <v-col cols="3" class="d-flex  py-0">
            <div class="ml-4 mr-2 pt-2">Days delta:</div>
            <v-text-field
              type="number"
              class="pt-0 mt-0"
              v-model="key.delta"
              @input="savePayment"
            />
          </v-col>
          <v-col cols="4" class="d-flex py-0">
            <div class="ml-4 mr-2 pt-2">Percentage:</div>
            <v-text-field
              :key="percChangeKey"
              type="number"
              class="pt-0  mt-0 percentage"
              :error-messages="notFixedPercentages"
              v-model="key.perc"
              @input="(value)=> percChange(value, key)"
            />
          </v-col>
          <v-row class="text-right justify-end">
            <v-btn icon class=" ma-0 mb-0 mx-4 action-btn-primary" @click="()=> onScheduleDelete(index)">
              <v-icon>delete</v-icon>
            </v-btn>
          </v-row>
        </v-row>
      </div>
    </div>
    <v-row class="mx-6 mb-6" v-if="selectedPayment.schedule">
      <v-icon class="plus-icon" >add</v-icon>
      <v-row class=" ruleblock-content ma-0 ml-4 mt-3 pa-2 pt-0">
        <v-col  class="py-0" cols="3">
          <v-select
            :items="anchor_data"
            v-model="newStepper"
            label="Select Anchor Point"
            @input="addStepper"
          />
        </v-col>
      </v-row>
    </v-row>
    <v-dialog
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="p-relative rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Delete Payment
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Do you want to delete this Payment?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="deletePayment"
                class="red--text mx-4"
              >Ok</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { deletePayment, savePayment } from '@/api/manage'
import NameDialog from '@/components/Dialogs/NameDialog'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'BuyManagePayments',
  components: { NameDialog },
  props: ['payments'],
  data () {
    return {
      isAdd: false,
      nameDialogVisible: false,
      selectedPayment: {},
      newStepper: '',
      percChangeKey: 0,
      notFixedPercentages: '',
      innerPayments: [],
      deleteDialogVisible: false,
      newName: '',
    }
  },
  computed: {
    ...mapState(useGeneralStore, {
      anchor_data: (state) => state.buySettings?.anchor_points || [],
    }),
  },
  methods: {
    editPayment () {
      this.isAdd = false
      this.nameDialogVisible = true
    },
    onAddPayment () {
      this.isAdd = true
      this.nameDialogVisible = true
    },
    addPayment (name) {
      this.isAdd = true
      const newPayment = {
        active: true,
        payment_name: name,
        schedule: [],
      }
      this.innerPayments = [newPayment, ...this.innerPayments]
      this.selectedPayment = newPayment
    },
    changePayment (value) {
      if (value !== null) {
        this.selectedPayment = value
      }
      this.checkPercentages()
    },
    changeName (value) {
      if (value === this.selectedPayment.payment_name || !this.selectedPayment) {
        return
      }
      if (!value) {
        value = ''
      }
      if (!this.saved) {
        this.newName = value
        setTimeout(() => {
          this.savePaymentWithName()
          this.saved = false
        }, 200)
      } else {
        this.saved = true
      }
    },
    savePaymentWithName () {
      if (this.newName === null) {
        this.newName = ''
        return
      }
      this.selectedPayment.payment_name = this.newName || ''
      this.savePayment()
    },
    savePayment () {
      if (!this.selectedPayment) {
        return Promise.resolve(false)
      }
      if (!this.checkPercentages()) {
        return Promise.resolve(false)
      }
      const selectedPayment = {
        ...this.selectedPayment,
        schedule: this.selectedPayment.schedule.map((item) => {
          return { ...item, perc: parseFloat((item.perc / 100).toFixed(2)) }
        })
      }
      return savePayment(selectedPayment)
        .then((payment) => {
          if (!this.selectedPayment.payment_key) {
            this.selectedPayment.payment_key = payment.payment_key
          }
          this.$emit('payments-update')
        })
    },
    deletePayment () {
      deletePayment(this.selectedPayment).then(() => {
        this.$emit('payments-update')
        this.selectedPayment = {}
        this.deleteDialogVisible = false
      })
    },
    addStepper (value) {
      this.selectedPayment.schedule.push({ point: value, delta: 0, perc: 0 })
      setTimeout(() => {
        this.newStepper = ''
      }, 50)
      this.savePayment()
    },
    onScheduleDelete (index) {
      this.selectedPayment.schedule.splice(index, 1)
      this.savePayment()
    },
    percChange (value, key) {
      const isDigits = value.split('.')[1]
      if (isDigits && isDigits.length > 2) {
        this.percChangeKey++
      }
      key.perc = parseFloat(isDigits ? parseFloat(value.toFixed(2)) : value)
      this.savePayment()
    },
    checkPercentages () {
      if (!this.selectedPayment.schedule) {
        return
      }

      let sum = this.selectedPayment.schedule.reduce(
        (previousValue, currentValue) => (previousValue.perc || previousValue) + currentValue.perc,
        0
      )

      if (this.selectedPayment.schedule.length === 1) {
        sum = this.selectedPayment.schedule[0].perc
      }

      if (sum !== 100) {
        this.notFixedPercentages = 'Percentages do not add up to 100%'
      } else {
        this.notFixedPercentages = ''
        return true
      }
    }

  },
  watch: {
    payments (newValue) {
      this.innerPayments = [...newValue]

      if (this.selectedPayment && this.selectedPayment.payment_key) {
        this.selectedPayment = this.innerPayments
          .find(
            s => s.payment_key === this.selectedPayment.payment_key
          )
      }
    },
    innerPayments () {
      if ((this.selectedPayment && this.selectedPayment.payment_key) || this.innerPayments.length === 0) {
        return
      }
      this.selectedPayment = this.innerPayments[0]
    }
  },
  mounted () {
    this.innerPayments = [ ...this.payments ]
  },
}
</script>

<style lang="scss">

.buy-manage-payments {
    .ruleblock-mark {
      width: 40px;

      .v-icon {
        cursor: pointer;
      }
    }

    .ruleblock-header {
      cursor: pointer;
    }

    .ruleblock-content {
      border: 1px solid var(--border-color);

      .block-name-select {
        width: 200px;
      }
    }
    .plus-icon {
      font-size: 24px;
    }

    .new-ruleblock {
      .ruleblock-mark {
        &:before,
        &:after {
          content: none;
        }
      }
    }

    .business-rule-row {
      border-bottom: 1px solid var(--border-color);

      &:last-child {
        border-bottom: 0;
      }
    }
}
</style>
