<template>
  <v-col class="mb-15" style="height: 500px">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <h6 class="text-subtitle-1 font-weight-medium mb-6 pt-0 ml-3">Module History</h6>
    <LineChart
      ref="userActivityGraph"
      :options="chartOptions"
      :labels="labels"
      :data="chartData"/>
  </v-col>
</template>

<script>
import LineChart from '@/charts/LineChart.vue'
import { GetAvatarColor } from '@/libs/colors'

export default {
  name: 'ModuleActivityGraph',
  components: { LineChart },
  props:{
    data: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    modules: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      chartOptions: {
        scales: {
          y: {
            title: {
              display: true,
              text: 'Events'
            },
            min: 0,
            // suggestedMax: 2,
            ticks: {
              //  Set maximum number of ticks, (ticks are the values on the y-axis)
              maxTicksLimit: 15,
            //   minTicksLimit: 2,
            },
            border: {
              display: false,
            },
          }
        }
      }
    }
  },
  computed: {
    labels () {
      const dates = new Set(this.data.map(item => item.date))
      return Array.from(dates).sort((a, b) => new Date(a) - new Date(b))
    },
    chartData () {
      return this.modules.map(module => {
        const capitalModule = module.charAt(0).toUpperCase() + module.slice(1)
        const color = GetAvatarColor(module, module)
        return {
          label: capitalModule,
          data: this.labels.map(date => {
            const moduleActivity = this.data.find(row => row.date === date && row.module === module)
            return moduleActivity ? moduleActivity.events : 0
          }),
          fill: false,
          borderColor: color,
          backgroundColor: '#fff',
        }
      })
    }
  }
}
</script>
