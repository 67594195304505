<template>
  <div id="buy-order-page">
    <div class="container-fluid mb-8" v-if="optionKey">
      <div class="row">
        <div class="col-4 fixed-height">
          <OptionInfo
            @reorderKeysChanged="reorderOptionKeysChanged"
            @paramChanged="parametersChanged"
            @storeKeyChanged="handleOptionInfoStoreKeyChanged"
            :optionKey="optionKey"
            :order-settings="orderSettings"
            :storeKeys="orderSettings.store_keys"
            :storeKeysSelected="orderSettings.storeKeysSelected"></OptionInfo>
        </div>
        <h4 v-if="!Object.keys(orderData).length && !loadingOrderData" class="pa-4">
          No predictions for this option. Check if reordering is active. Changes to settings can take up to one day.'</h4>
        <div class="col-8 fixed-height">
          <v-card
            style="height: 100%;"
            class="pa-4"
            v-loading="loadingOrderData">
            <v-row justify="space-between" class="pa-3 pb-4">
              <h4 class="mb-2 text-center">Potential</h4>
              <DataExport
                v-if="!chartIndex"
                @item-click="(sep)=> downloadChartData(sep, $refs['prediction_graph_weeks_order'], $route.query.option_key)"
              />
            </v-row>
            <v-carousel
              :hide-delimiters="loadingOrderData"
              delimiter-icon="circle"
              :perPage="1"
              light
              hide-delimiter-background
              height="550"
              @change="value => chartIndex = value"
              :show-arrows="false">
              <v-carousel-item>
                <h4 class="mb-2 text-center">Weeks</h4>
                <ChartElement
                  title="prediction_graph_weeks_order"
                  ref="prediction_graph_weeks_order"
                  :params="adjustedOrderSettings"
                  data-type="prediction_graph"
                  :chartData="orderData"
                  :padding="false"
                  :fixedHeight="false"
                  forcedHeight="100%"
                />
              </v-carousel-item>
              <v-carousel-item>
                <h4 class="mb-2 text-center">Sales</h4>
                <ChartElement
                  title="sales_graph"
                  ref="sales_graph"
                  :params="adjustedOrderSettings"
                  data-type="prediction_graph"
                  :chartData="orderData"
                  :padding="false"
                  :fixedHeight="false"
                  forcedHeight="100%"
                />
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </div>
      </div>
      <div class="row mt-50" >
        <div class="col-12">
          <v-card
            v-loading="loadingOrderData"
            class="pa-4 pb-10"
            min-height="350px"
          >
            <v-carousel
              :hide-delimiters="loadingOrderData"
              delimiter-icon="circle"
              :perPage="1"
              class="pb-4"
              light
              height="auto"
              hide-delimiter-background
              @change="value => secondChartIndex = value"
              :show-arrows="false">
              <v-carousel-item>
                <v-row justify="space-between" class="pa-3 pb-4">
                  <h4 class="mb-2 text-center">Projection</h4>
                  <DataExport
                    @item-click="(sep)=> downloadChartData(sep, $refs['stock_graph_order'], $route.query.option_key)"
                  />
                </v-row>
                <h4 class="mb-2 text-center">Weeks</h4>
                <ChartElement
                  title="stock_graph_order"
                  ref="stock_graph_order"
                  data-type="stock_graph"
                  class="pb-4"
                  :chartData="orderData"
                  :params="adjustedOrderSettings"
                  :padding="false"
                  :fixedHeight="false"
                />
              </v-carousel-item>
              <v-carousel-item>
                <v-row justify="space-between" class="pa-3">
                  <h4 class="mb-2 text-center">Scenario</h4>
                  <DataExport
                    @item-click="(sep)=> downloadChartData(sep, $refs['order_scenarios_graph'], $route.query.option_key)"
                  />
                </v-row>
                <h4 class="mb-2 text-center">Weeks</h4>
                <ChartElement
                  title="order_scenarios_graph"
                  ref="order_scenarios_graph"
                  data-type="stock_graph"
                  class="pb-4"
                  :chartData="orderData"
                  :params="adjustedOrderSettings"
                  :padding="false"
                  :fixedHeight="false"
                />
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </div>
      </div>
      <v-card class="mt-8" min-height="350px"
        v-loading="loadingOrderData">
        <h4 class="px-4 py-4">Data</h4>
        <v-tabs v-model="activeTab" grow show-arrows>
          <v-tabs-slider/>
          <v-tab
            v-for="item of tabs"
            :key="item"
            class="px-8"
          >
            <template>
              {{ item }}
            </template>
          </v-tab>
        </v-tabs>
        <SizeCurveTable
          v-if="![0, 1].includes(activeTab)"
          :key="activeTab"
          :activeTabName="this.activeTabName"
          :tableData="orderData[this.activeTabName]"
          :tableKeys="orderData[this.activeTab === 2 ? 'weeks_columns' : 'size_columns']"
        ></SizeCurveTable>
        <OrdersTable
          v-else
          :activeTabName="this.activeTabName"
          :tableData="orderData[this.activeTabName]"
          :tableKeys="orderData[this.activeTabName + '_columns']"
        ></OrdersTable>
      </v-card>
    </div>
  </div>
</template>

<script>
import ChartElement from '../chart/ChartElement'
import OptionInfo from '../components/Order/OptionInfo.vue'
import OrdersTable from '../components/Order/OrdersTable.vue'
import SizeCurveTable from '../components/Order/SizeCurveTable.vue'
import { downloadChartData } from '@/variables'
import axios from '@/services/axios.js'
import DataExport from '../components/DataExport'
import baseURLs from '@/api/APIBaseURLs'

export default {
  name: 'BuyOrderPage',
  data () {
    return {
      predictionChartType: 'weeks',
      optionKey: null,
      chartIndex: 0,
      secondChartIndex: 0,
      loadingOrderData: true,
      activeTab: 0,
      tabs: ['orders', 'orders blank', 'weeks', 'sizes', 'sizes %'],
      tabsNames: ['orders', 'orders_blank', 'weeks', 'size_totals', 'size_curves'],
      orderSettings: {
        option_key: null,
        order_parameters: null,
        storeKeysSelected: [],
        store_keys: [],
        version: null,
        planning_active: false,
      },
      orderData: {},
      warningMessage: 'There are no stores which are currently included for this option. Add clusters, inclusions or remove exclusions to fix this.',
    }
  },
  computed: {
    adjustedOrderSettings () {
      return {
        ...this.orderSettings,
        store_keys: this.orderSettings.storeKeysSelected || [],
      }
    },
    activeTabName () {
      return this.tabsNames[this.activeTab]
    }
  },
  components: {
    DataExport,
    OptionInfo,
    OrdersTable,
    ChartElement,
    SizeCurveTable,
  },
  methods: {
    handleOptionKeyChange () {
      const optionKey = this.$route.query.option_key
      if (optionKey) {
        this.optionKey = optionKey
        this
          .getOrderSettings()
          .then(this.getOrderData)
      }
    },
    downloadChartData,
    getOrderSettings () {
      return axios.get(baseURLs.manageAPI + `/option_settings?option_key=${String(this.optionKey)}&format=d&module=buy&page=reorder`)
        .then(response => {
          this.orderSettings = {
            ...response.data,
            option_key: this.optionKey,
            storeKeysSelected: response.data.store_keys,
          }
          /* this.orderSettings.option_key = this.optionKey
                    this.orderSettings.planning_active = response.data.planning_active
                    this.orderSettings.store_keys = response.data.store_keys
                    this.orderSettings.storeKeysSelected = response.data.store_keys
                    this.orderSettings.version = response.data.version
                    this.orderSettings.order_parameters = response.data.order_parameters
                    this.orderSettings.reorder_option_keys = response.data.reorder_option_keys */
        })
        .catch(error => {
          console.log(error)
        })
    },
    reorderOptionKeysChanged (reorderedOptionKeys) {
      this.orderSettings.reorder_option_keys = reorderedOptionKeys
      this.orderSettings = { ...this.orderSettings }
      this.saveOrderSettings().then(this.getOrderData)
    },
    async saveOrderSettings () {
      if (!this.orderSettings.storeKeysSelected.length) {
        return Promise.resolve()
      }
      return axios.post(baseURLs.manageAPI + '/option_settings', this.orderSettings).catch(console.error)
    },
    getOrderData () {
      if (!this.orderSettings.storeKeysSelected.length) {
        return Promise.resolve()
      }
      this.loadingOrderData = true

      return axios.post(baseURLs.logicAPI + '/order', this.adjustedOrderSettings)
        .then(response => {
          this.orderData = response.data

          // Remove hardcoded columns
          const ordersTables = ['orders_columns', 'orders_blank_columns']
          ordersTables.forEach(name => {
            let idx = this.orderData[name].indexOf('Date')
            this.orderData[name].splice(idx, 1)
            idx = this.orderData[name].indexOf('Type')
            this.orderData[name].splice(idx, 1)
          })

          this.loadingOrderData = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    parametersChanged (data) {
      this.orderSettings.order_parameters = JSON.parse(JSON.stringify(data))
      this.orderSettings.planning_active = data.planning_active
      delete this.orderSettings.order_parameters.planning_active
      this
        .getOrderData()
        .then(this.saveOrderSettings)
    },
    handleOptionInfoStoreKeyChanged (keys) {
      this.orderSettings.storeKeysSelected = keys
      this
        .getOrderData()
        .then(this.saveOrderSettings)
    },
  },
  watch: {
    $route () {
      this.handleOptionKeyChange()
    },
  },
  created () {
    this.handleOptionKeyChange()
  }
}
</script>
<style lang="scss">
    #buy-order-page {
        .v-carousel {
            overflow: visible;
            .v-carousel__controls {
                bottom: -30px !important;
            }
        }
    }
</style>
