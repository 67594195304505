export function zoomFix (){
  // const isEdge = navigator.userAgent.includes('Edg')
  // const isChrome = navigator.userAgent.includes('Chrome')
  // const isSafari = navigator.userAgent.includes('Safari')
  // if(!isEdge && !isChrome && !isSafari){
  //   return
  // }
  // const clientWidth = window.innerWidth
  // const BreakPointS = 800
  // const BreakPointM = 1000
  // const BreakPointL = 1600
  // const BreakPointXL = 3840
  //
  // const PercentageS = 0.7
  // const PercentageM = 0.9
  // const PercentageXl = 1
  let zoom = 1
  // if(clientWidth < BreakPointL){
  //   if(clientWidth <= BreakPointS){
  //     zoom = PercentageS
  //   } else if(clientWidth <= BreakPointM){
  //     zoom = PercentageS
  //   }else {
  //     zoom = PercentageS + ((PercentageM - PercentageS)
  //       * ((clientWidth - BreakPointM) / (BreakPointL - BreakPointM)))
  //   }
  // }else if(clientWidth <= BreakPointXL){
  //   zoom = PercentageM + ((PercentageXl - PercentageM)
  //     * ((clientWidth - BreakPointL) / (BreakPointXL - BreakPointL)))
  // }
  document.documentElement.style.setProperty('--zoom', zoom.toFixed(2))
}
