<template>
  <div class="shop-item-order-form-prepacks p-relative">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <!-- Prepacks table -->
    <!-- <div class="text-right pr-6 pb-2 d-flex align-center justify-end">
            <label class="v-label theme--light mr-2">Optimized</label>
            <v-switch
                :input-value="optimized"
                hide-details
                class="d-inline-block pt-0 mt-0 mr-2"
                @change="handleOptimizedChange"
            />
        </div> -->
    <v-row class="mx-6">
      <v-col cols="2">
        <v-text-field
          :value="dataForLoad.prepack_percentage"
          :error-messages="dataForLoad.prepack_percentage > 100 || dataForLoad.prepack_percentage < 0 ? ['Must be between 0 and 100']: null"
          @input="(value)=> {dataForLoad.prepack_percentage =  parseFloat(value || 0); $emit('data-load-changed', dataForLoad)}"
          hide-details="auto"
          label="Fairshare push %"
        ></v-text-field>
      </v-col>
      <v-col cols="3" class="px-6">
        <label for="factor-value" class="v-label v-label--active theme--light" style="font-size: 13px">Pieces (min/max)</label>
        <v-row class="ma-0 align-center">
          <div class="pr-1">4</div>
          <v-range-slider
            :value="[dataForLoad.prepack_minimum || 4, dataForLoad.prepack_maximum || 8]"
            @input="(value)=> {dataForLoad.prepack_maximum = value[1]; dataForLoad.prepack_minimum = value[0]; $emit('data-load-changed', dataForLoad)}"
            hide-details
            thumb-label
            track-color="#ccc"
            track-fill-color="primary"
            :max="26"
            :min="4"/>
          <div class="slider-value-to">26</div>
        </v-row>
      </v-col>
    </v-row>
    <data-tables
      style="width: 100%"
      class="mb-4"
      :data="prepacksRows"
      :page-size="prepacksRows.length"
      :pagination-props="{ pageSizes: [prepacksRows.length], hideOnSinglePage: true }">
      <el-table-column
        label="#"
        :width="200"
        class-name="pl-8"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        v-for="sku in skuCols"
        :key="sku"
        :prop="sku"
        :label="sku"
      >
        <template slot-scope="scope">
          <v-text-field
            class="mb-5"
            type="number"
            v-model.number="scope.row.values[sku]"
            :error-messages="scope.row.errors[sku]"
            hide-details
            @change="handleQtyChanged(scope.row)"
          ></v-text-field>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="Amount">
        <template slot-scope="scope">
          <v-text-field
            class="mb-5"
            type="number"
            v-model.number="scope.row.amount"
            :error-messages="scope.row.amountError"
            hide-details
            @change="handleAmountChanged(scope.row)"
          ></v-text-field>
        </template>
      </el-table-column>
      <el-table-column class-name="pr-8" width="80">
        <template slot-scope="scope">
          <v-icon
            class="mx-2 action-btn-danger"
            @click="handleDeleteClick(scope.row)"
          >delete</v-icon>
        </template>
      </el-table-column>
    </data-tables>
    <div class="px-8">
      <v-icon
        v-if="!addDisabled"
        :disabled="dataForLoad.prepack_percentage > 100 || dataForLoad.prepack_percentage< 0"
        @click="handleAddClick"
      >add</v-icon>
      <div v-if="addDisabled">No more prepacks can be added</div>
    </div>

    <!-- Summary table -->
    <data-tables
      style="width: 100%"
      class="mt-8 mb-4"
      :data="summaryRows"
      :page-size="summaryRows.length"
      :pagination-props="{ pageSizes: [summaryRows.length], hideOnSinglePage: true }">
      <el-table-column
        label="Name"
        :width="200"
        class-name="pl-8"
      >
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column
        v-for="sku in skuCols"
        :key="sku"
        :prop="sku"
        :label="sku"
      >
        <template slot-scope="scope">
          {{ scope.row[sku] }}
        </template>
      </el-table-column>
      <el-table-column prop="total" label="Total">
        <template slot-scope="scope">
          <div class="d-flex align-center">
            {{ scope.row.total }}
            <template v-if="scope.row.isPiecesRow">
              <v-icon
                v-if="piecesChartData && piecesChartData.length"

                class="my-1 ml-3 icon-has-action"
                @click="handlePiecesInfoClick"
              >info</v-icon>
            </template>
          </div>
        </template>
      </el-table-column>
      <!-- Add empty column for same column width as in prepacks table -->
      <el-table-column class-name="pr-8" width="80">
      </el-table-column>
    </data-tables>

    <v-dialog
      :value="piecesInfoDialogVisible"
      content-class="align-self-start mt-14"
      scrollable
      width="1200px"
      max-width="90%"
    >
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">Prepack pieces info</v-card-title>
        <v-card-text class="px-0 text--primary">
          <PrepackPiecesChart
            v-if="piecesChartData && piecesChartData.length"
            ref="piecesChart"
            :chartData="piecesChartData"
          />
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                outlined
                @click="piecesInfoDialogVisible = false"
                class="mx-4"
              >Close</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import * as shopApi from '@/api/shop'
import PrepackPiecesChart from './PrepackPiecesChart.vue'

export default {
  components: {
    PrepackPiecesChart
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    orderLine: {
      type: Object,
      required: true,
    },
    skuCols: {
      type: Array,
      required: true,
    },
    optimized: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      dataForLoad: {
        prepack_percentage: 0,
        prepack_minimum: 4,
        prepack_maximum: 8,
      },
      loading: false,
      prepacksRows: [],
      prepackVariants: 0,
      addDisabled: false,
      piecesInfoDialogVisible: false,
      saveOrderPrepacksDelayed: null,
    }
  },
  computed: {
    formatFairShare () {
      const formatter = new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return formatter.format
    },
    prepacksValid () {
      return this.prepacksRows.every(pp => {
        return !pp.amountError && this.skuCols.every(sku => !pp.errors[sku])
      })
    },
    summaryRows () {
      const prepacksPieces = this.skuCols.reduce(
        (pieces, sku) => ({
          ...pieces,
          [sku]: this.prepacksRows.reduce((sum, row) => sum + row.values[sku] * row.amount, 0),
        }),
        {}
      )

      const piecesToOrder = this.skuCols.reduce((acc, cur) => ({
        ...acc,
        [cur]: this.orderLine.pieces[cur] || 0,
      }), {})
      const piecesTotal = this.skuCols.reduce(
        (acc, cur) => acc + this.orderLine.pieces[cur],
        0
      )
      const prepacksPiecesTotal = this.skuCols.reduce(
        (acc, cur) => acc + prepacksPieces[cur],
        0
      )
      const prepacksMaxTotal = this.skuCols.reduce(
        (acc, cur) => acc + (this.orderLine.prepack_total || {})[cur] || 0,
        0
      )
      const skuPercentage = this.skuCols.reduce(
        (pieces, sku) => {
          const percentage = _.round((prepacksPieces[sku] / this.orderLine.pieces[sku]) * 100)
          return {
            ...pieces,
            [sku]: Number.isFinite(percentage) ? (percentage + '%') : '-',
          }
        },
        {}
      )
      const percentageTotal = _.round((prepacksPiecesTotal / piecesTotal) * 100)

      return [{
        name: 'Total pieces',
        isPiecesRow: false,
        ...piecesToOrder,
        total: piecesTotal,
      }, {
        name: 'Prepack pieces',
        isPiecesRow: true,
        ...prepacksPieces,
        total: prepacksPiecesTotal,
      }, {
        name: 'Prepack max',
        isPiecesRow: false,
        ...this.orderLine.prepack_total,
        total: prepacksMaxTotal,
      }, {
        name: 'Prepack %',
        isPiecesRow: false,
        ...skuPercentage,
        total: Number.isFinite(percentageTotal) ? (percentageTotal + '%') : '-',
      }]
    },
    piecesChartData () {
      return this.orderLine.prepack_graph_data
    },
  },
  watch: {
    orderLine () {
      this.setPrepackRows(this.orderLine.prepacks)
      this.prepackVariants = this.orderLine.prepack_variants

      if (this.prepackVariants > this.prepacksRows.length) {
        this.addDisabled = true
        this.prepackVariants = this.prepacksRows.length
      } else {
        this.addDisabled = false
      }
    }
  },
  methods: {
    setPrepackRows (prepacks) {
      this.prepacksRows = prepacks.map(pp => ({
        values: { ...pp.prepack },
        errors: this.skuCols.reduce(
          (acc, cur) => ({ ...acc, [cur]: null }),
          {}
        ),
        amount: pp.amount,
        amountError: null,
      }))
    },
    emitPrepacks () {
      this.$emit('prepacks-changed', {
        newPrepacks: this.prepacksRows.map(row => ({
          prepack: { ...row.values },
          amount: row.amount,
        })),
        dataForLoad: {
          ...this.dataForLoad,
          prepack_percentage: parseInt(this.dataForLoad.prepack_percentage)
        },
      })
    },
    emitVariants () {
      this.$emit('variants-changed', this.prepackVariants)
    },
    validatePrepacks () {
      this.prepacksRows.forEach(pp => {
        this.$set(pp, 'amountError', null)

        if (!Number.isInteger(pp.amount) || pp.amount < 0) {
          this.$set(pp, 'amountError', 'Should be >= 0')
        }

        this.skuCols.forEach(sku => {
          this.$set(pp.errors, sku, null)

          if (!Number.isInteger(pp.values[sku]) || pp.values[sku] < 0) {
            this.$set(pp.errors, sku, 'Should be >= 0')
          }
        })
      })
    },
    validateAndEmitPrepacks () {
      this.validatePrepacks()
      this.$emit('prepacks-validated', this.prepacksValid)
      this.emitPrepacks()
    },
    saveOrderPrepacks () {
      this.loading = true

      const orderLinesPayload = {
        ...this.$route.query,
        order_key: this.order.order_key,
        order_lines: [{
          ...this.orderLine,
          prepack_variants: this.prepackVariants,
          prepacks: this.prepacksRows.map(row => ({
            prepack: { ...row.values },
            amount: row.amount,
          }))
        }],
      }

      return shopApi
        .saveShopItemOrderLines(orderLinesPayload)
        .then(() => {
          this.loading = false
        })
    },
    handleAddClick () {
      this.prepackVariants++
      if (this.optimized) {
        this.emitVariants()
      } else {
        this.prepacksRows.push({
          values: this.skuCols.reduce(
            (pieces, sku) => ({
              ...pieces,
              [sku]: 0,
            }),
            {}
          ),
          amount: 0,
          errors: this.skuCols.reduce(
            (acc, cur) => ({ ...acc, [cur]: null }),
            {}
          ),
          amountError: null,
        })
        this.saveOrderPrepacksDelayed()
      }
    },
    handleQtyChanged () {
      if (this.optimized) {
        this.validateAndEmitPrepacks()
      } else {
        this.saveOrderPrepacksDelayed()
      }
    },
    handleAmountChanged () {
      if (this.optimized) {
        this.validateAndEmitPrepacks()
      } else {
        this.saveOrderPrepacksDelayed()
      }
    },
    handleDeleteClick (row) {
      this.prepackVariants--
      this.prepacksRows.splice(this.prepacksRows.indexOf(row), 1)

      if (this.optimized) {
        this.emitPrepacks()
        this.emitVariants()
      } else {
        this.saveOrderPrepacksDelayed()
      }
    },
    handlePiecesInfoClick () {
      this.piecesInfoDialogVisible = true

      this.$nextTick(() => {
        this.$refs.piecesChart.drawChart()
      })
    },
    handleOptimizedChange (val) {
      this.$emit('prepacks-optimization-changed', val)
    },
  },
  created () {
    this.saveOrderPrepacksDelayed = _.debounce(this.saveOrderPrepacks, 2000)
    this.setPrepackRows(this.orderLine.prepacks)
    this.prepackVariants = this.orderLine.prepack_variants
  },
}
</script>

<style lang="scss">
.shop-item-order-form-prepacks {
    .el-table::before {
        display: none;
    }
    .el-table__row:last-child {
        td {
            border-bottom: 0;
        }
    }
    .v-text-field {
        font-size: 13px;
    }
}
</style>
