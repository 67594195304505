<template>
  <div class="popover"
    @click="goToHomePage">
    <img
      slot="reference"
      alt="Thunderstock logo"
      class="thunder-logo"
      :src="thunderstockLogo"
      :width="width"
    />

  </div>
</template>

<script>
import thunderstockLogo from '@/assets/img/thunderstock-logo.svg'

export default {
  data () {
    return {
      thunderstockLogo: thunderstockLogo,
    }
  },
  props: {
    activeModule: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '30px',
    },
    to: {
      type: String,
      default: '',
    }
  },
  name: 'Logo',
  methods: {
    goToHomePage () {
      if (this.to) {
        this.$router.push({ path: this.to })
        return
      }
      const routes = {
        buy: 'buy-home-page',
        stock: 'stock-home-page',
        pricing: 'pricing-markdown-page'
      }
      const generalPage = this.$route.name.split('-')[0]
      if (generalPage === 'storepage') {
        this.$router.push({ name: 'stock-transfers' })
        return
      }
      this.$router.push({ name: routes[generalPage] })
    },
  }
}
</script>

<style lang="scss">

.popover {
  cursor: pointer;
}
.thunder-logo {
  margin-top: 6px !important;
  margin-left: 6px;
}
</style>
