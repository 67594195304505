<template>
  <div class="container-fluid px-1 px-md-2 px-lg-4 pt-8">
    <div class="row d-flex justify-space-between pa-3">
      <div>
        <h3>Home</h3>
      </div>
      <div>
        <h3>{{fromStoreName}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card fixed-height second-card-row">
          <div class="card-body text-center">
            <v-overlay absolute color="white" :value="!loaded">
              <v-progress-circular color="primary" indeterminate size="64" width="2" />
            </v-overlay>
            <v-card class="pb-4">

              <v-row justify="space-between">
                <h4 class="pa-4 text-center">Predictions</h4>
                <DataExport
                  @item-click="(sep)=> downloadChartData(sep, $refs['stores_trend_graph'])"
                />
              </v-row>
              <div class="d-flex xs12 flex">
                <ChartElement
                  class="d-flex flex"
                  ref="stores_trend_graph"
                  v-if="Object.keys(trendData).length"
                  title="stores_trend_graph"
                  :chartData="trendData"
                  :params="{}"
                  :padding="false"
                  :fixedHeight="false" />
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-50">
      <div class="col-12">
        <h3 class="text-left pb-6">Bestsellers</h3>
        <div class="bestseller-carousel">
          <carousel :perPageCustom="[[256,2], [768, 2], [1024, 3], [1200, 5]]" :navigationEnabled="false" v-if="this.bestsellersData.length > 0">
            <slide v-for="p in this.bestsellersData" :key="p.option_key">
              <div class="carousel-item-container">
                <div class="image-container justify-center d-flex">
                  <img class="product-image" height="200px;" :src="p['image_url'] || '@/assets/img/ts_placeholder.png'" @error="loadPlaceholder" alt="">
                </div>
                <div class="text-container">
                  <template v-if="p['option_name'].split(' / ').length === 2">
                    <p class="article-name">{{p['option_name'].split(" / ")[0]}}</p>
                    <p class="subtitle">{{p['option_name'].split(" / ")[1]}}</p>
                  </template>
                  <template v-else>
                    <p class="article-name">{{p['option_name'].split(" / ")}}</p>
                  </template>
                  <p class="price-tag">{{p['Price']}}</p>
                  <p class="sold cart">
                    <v-icon class="mr-1">shopping_basket</v-icon>
                    {{p['Net Sales L4W']}}</p>
                </div>
              </div>
            </slide>
          </carousel>
          <div v-else>
            <carousel :perPageCustom="[[256,1], [768, 3], [1024, 6], [1200, 7]]" :navigationEnabled=false>
              <slide v-for="i in 10" :key="i">
                <div class="carousel-item-container" v-loading="!loaded" style="padding: 10px; height: 381px; width: 250px;">
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartElement from '../chart/ChartElement'
import DataExport from '../components/DataExport'
import { downloadChartData } from '@/variables'
import ts_placeholder from '@/assets/img/ts_placeholder.png'
import { useStorepageStore } from '@/store/pinia/storePageStore'

export default {
  name: 'StorePageHome',
  setup () {
    return {
      storepageStore: useStorepageStore()
    }
  },
  data () {
    this.chart = null

    return {
      loaded: false
    }
  },
  components: { DataExport, ChartElement },
  computed: {
    trendData () {
      return this.storepageStore.trendData || {}
    },
    bestsellersData () {
      return this.storepageStore.bestsellersData
    },
    fromStoreName () {
      return this.storepageStore.transfersFromStoreName
    }
  },
  methods: {
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    downloadChartData,
    getData () {
      this.loaded = false
      const payload = {
        token: localStorage.getItem('storepage_token'),
        store_key: parseInt(this.$route.params.store_key),
        ignore_cache: true
      }
      const loadTrend = this.storepageStore.getTrendData(payload)
      const loadBestsellers = this.storepageStore.getBestsellersData(payload)
      const loadStore = this.storepageStore.getStoresInfo(payload)
      Promise.all([loadTrend, loadBestsellers, loadStore]).then(() => {
        this.loaded = true
      })
    },
  },
  created () {
    if (this.$auth.isAccessTokenValid()) {
      // User is logged in, remove token from LS if exists
      if (localStorage.getItem('storepage_token')) {
        localStorage.removeItem('storepage_token')
      }
    }

    // If the token is explicitly present in the route, then we save it.
    if (Object.keys(this.$route.query).includes('token')) {
      localStorage.setItem('storepage_token', this.$route.query.token)
    }
  },
  mounted () {

    this.getData()
  }
}
</script>

<style lang="scss" scoped>
    .bestseller-carousel {
        .VueCarousel-slide {
            margin-right: 25px;
        }
        p {
            margin-bottom: 0;
            font-size: 14px;
        }
        .article-name,.sold {
            white-space: nowrap;
            font-weight: 500;
        }
        .carousel-item-container {
            width: 100%;
            margin: 0 auto;
            background: #FFFFFF;
            box-shadow: 0 2px 10px 5px rgba(0,0,0,0.02);
            border-radius: 5px;
        }
        @media screen and (max-height: 500px) {
            .VueCarousel-slide {
                margin: 0;
            }

            img.product-image {
                max-height: 120px;
            }
        }
        .image-container {
            padding: 20px 0;
        }
        .text-container {
            padding: 10px 16px 20px;
            width: 100%;
        }
        p.subtitle {
            // color: #CCE0E0;
        }
        p.price-tag {
            //padding: 10px;
            width: 150px;
            margin: 10px 0;
        }
        .cart {
          padding-top: 4px;
          gap: 12px;
          border-top: 2px solid #f4f7fa;
          display: flex;
          align-items: center;
        }
        .VueCarousel-dot-container {
            margin-top: 0!important;
        }
    }
</style>
