<template>
  <v-row class="p-relative mx-0 my-1 py-1  color-range-picker" @mouseover="updateOnHover">
    <v-slider
      style="width: 100%"
      class="just-background mb-0 py-0"
      hide-details
      :style="`background: linear-gradient(to right, ${linearColors}); border-radius:4px`"
      hide-canvas
      hide-inputs
    ></v-slider>
    <v-slider
      v-for="(item, index) of value.filter(item=> item || item === 0)"
      :key="index"
      :readonly="index ===0 || value.filter(item=> item !== undefined).length -1 === index"
      :ref="`rangeSlider${index}`"
      :id="`rangeSlider${index}`"
      :thumb-size="2"
      style="width: 100%"
      class="p-absolute"
      :class="{'on-top': topComponent === index}"
      :value="item"
      @input="updateValueDebounce(index, $event)"
      :max="100"
      @start="isDragging = true"
      @end="isDragging = false"
      hide-canvas
      hide-inputs
    ></v-slider>
  </v-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ColorRangePicker',
  props: {
    value: {
      type: Array,
      default () {
        return [0, 20, 40, 60, 80]
      }
    },
    colors: {
      type: Array,
      default () {
        return ['#085D70', '#329193', '#BA448F', '#FFD202', '#FFAB00']
      }
    }
  },
  data () {
    return {
      updatedValue: [],
      updateValueDebounce: _.debounce(this.updateValue, 200),
      topComponent: 0,
      isDragging: false,
      sliderKey: 0
    }
  },
  computed: {
    linearColors () {
      let colors = ''
      this.colors.forEach((item, index) => {
        if (!this.value[index] && this.value[index] !== 0) {
          return
        }
        colors += ` ${item} ${this.value[index]}%,`
      })
      return colors.substr(0, colors.length - 1)
    }
  },
  methods: {
    updateValue (index, value) {
      if (!value) {
        return
      }
      let actualIndex = 0
      for (let i = 1; i < this.value.length; i++) {
        if (index === 0 || this.value[i] === undefined) {
          continue
        }
        index--
        if (index === 0) {
          actualIndex = i
        }
      }
      this.updatedValue[actualIndex] = value

      const newValue = this.value.map((item, index) => {
        if (this.updatedValue[index] === undefined) {
          return this.value[index]
        }
        return this.updatedValue[index]
      })
      this.$emit('input', newValue)
    },
    updateOnHover (event) {
      if (this.isDragging) {
        return
      }
      const isOnTop = event.target.classList.contains('v-slider__thumb')
      if (!isOnTop) {
        const component = `rangeSlider${this.topComponent}`
        this.$refs[component][0]?.onSliderMouseUp(event)
        this.topComponent = (this.topComponent + 1) % (this.value.filter(item => item !== undefined).length)
      }
    },
  }
}
</script>

<style lang="scss">

/*.color-picker-slider{*/
/*  background: linear-gradient(to right, #F00 0%, #FF0 16.66%, #0F0 33.33%, #0FF 50%, #00F 66.66%, #F0F 83.33%, #F00 100%);*/
/*}*/
.color-range-picker {
  .theme--light.v-slider .v-slider__track-background.primary.lighten-3,
  .theme--light.v-slider .v-slider__track-fill,
  .on-top .v-slider__track-container,
  .v-input:not(.just-background) .v-slider__track-container {
    background: transparent !important;
  }

  .v-input:not(.on-top) .v-slider__track-container {
    height: 20px;
    border-radius: 4px;
  }

  .v-input.just-background .v-slider__thumb {
    display: none;
  }

  .v-md-editor {
    box-shadow: none;
  }

  .v-slider__thumb {
    width: 14px;
    height: 14px;
    left: -8px;
    &:before {
      left: -10px;
      top: -10px;
    }
    &:after {
      content: '';
      width: 14px;
      height: 14px;
      left: 7px;
      top: 7px;
      position: absolute;
      border-radius: 8px;
      background-color: #fff !important;
    }
  }

  .v-slider--horizontal {
    min-height: 24px;
  }

    .on-top {
    z-index: 2 !important
  }
}
</style>
