<template>
  <v-app-bar class="top-bar" height="56px" fixed>
    <v-overlay
      absolute
      z-index="3"
      class="overlay"
      :value="menuOpened || filtersOpened || searchOpened || analyticsFilterOpened || pricingFiltersOpened || strategyFiltersOpened"
      @click.native="overlayClick"
    ></v-overlay>
    <v-icon class="d-md-none" @click="$emit('menu-clicked')">menu</v-icon>
    <v-divider class="d-md-none mt-3 mr-5 ml-4 py-3 divider" vertical></v-divider>
    <logo activeModule="stock" class="icon" />

    <v-divider class="mt-3 mr-5 ml-4 py-3 divider" vertical></v-divider>

    <h3>Thunderstock</h3>

    <v-menu
      v-model="menuOpened"
      class="menu"
      offset-y
      nudge-bottom="15"
      content-class="module-selector"
      :close-on-click="false"
    >
      <template v-slot:activator="{ on }">
        <div class="menu-activator" :class="{ 'active': menuOpened }">
          <v-icon v-on="on" class="ml-6" @click="expandClick">{{
            menuOpened ? 'expand_less' : 'expand_more'
          }}</v-icon>
        </div>
      </template>

      <v-list class="menu-list" two-line subheader min-width="280" elevation="0">
        <v-list-item :to="{ name: 'strategy-home-page' }" :class="{ 'active': currentModule === 'strategy' }" @click="itemClick">
          Strategy
        </v-list-item>
        <v-list-item :to="{ name: 'buy-home-page' }" :class="{ 'active': currentModule === 'buy' }" @click="itemClick">
          Buy
        </v-list-item>
        <v-list-item :to="{ name: 'stock-home-page' }" :class="{ 'active': currentModule === 'stock' }" @click="itemClick">
          Stock
        </v-list-item>
        <v-list-item :to="{ name: 'pricing-home-page' }" :class="{ 'active': currentModule === 'pricing' }" @click="itemClick">
          Pricing
        </v-list-item>
      </v-list>
    </v-menu>

    <v-spacer></v-spacer>
    <template v-if="$auth.isAccessTokenValid()">
      <OptionSearch
        v-if="!isBuyPage"
        v-show="!isStrategyAnalyticsPage"
        ref="optionsSearch"
        class="mr-2"
        @search-opened="searchOpened = true"
        @search-closed="searchOpened = false"
      />

      <BuyOptionSearch
        v-else
        v-show="!isBuyHomePage && !isBuyStudioPage && !isBuyShopItemPage && !isBuyAnalyticsPage && !isBuySettingsPage && !isBuyManagePage"
        ref="optionsSearch"
        class="mr-2"
        @search-opened="searchOpened = true"
        @search-closed="searchOpened = false"
      />
    </template>
    <template v-if="filtersStore.currentPageFilters.length">
      <v-btn
        icon
        :color="filtersStore.filtersApplied ? 'primary' : null"
        @click="handleFilterClick"
      >
        <v-icon>filter_list</v-icon>
      </v-btn>

      <FilterWidget />
    </template>

    <template v-if="routeName === 'buy-analytics' || routeName === 'stock-analytics' || routeName === 'pricing-analytics'|| routeName === 'strategy-analytics'">
      <v-btn
        icon
        class="analytics-filter-icon"
        :color="analyticsFiltersStore.filtersApplied ? 'primary' : null"
        @click="handleAnalyticsFilter"
      >
        <v-icon>filter_list</v-icon>
      </v-btn>

      <AnalyticsFilterWidget />
    </template>

    <template v-if="['pricing-home-page', 'pricing-markdown-builder'].includes(routeName)">
      <v-btn
        icon
        :color="pricingFiltersStore.filtersApplied ? 'primary' : null"
        @click="handlePricingFilter"
      >
        <v-icon>filter_list</v-icon>
      </v-btn>
      <PricingFilterWidget />
    </template>

    <template v-if="['strategy-home-page', 'strategy-cubes-page', 'strategy-intake-page', 'strategy-collection-page', 'strategy-wssi-page'].includes(routeName)">
      <v-btn
        icon
        :color="strategyFiltersStore.filtersApplied ? 'primary' : null"
        @click="handleStrategyFilter"
      >
        <v-icon>filter_list</v-icon>
      </v-btn>
      <StrategyFilterWidget />
    </template>
  </v-app-bar>
</template>

<script>
import Logo from '@/components/Logo'
import OptionSearch from '@/components/OptionSearch.vue'
import FilterWidget from '@/components/FilterWidget/FilterWidget.vue'
import BuyOptionSearch from '@/components/BuyOptionSearch'
import AnalyticsFilterWidget from '@/components/FilterWidget/AnalyticsFilterWidget'
import PricingFilterWidget from '@/components/FilterWidget/PricingFilterWidget'
import StrategyFilterWidget from '@/components/FilterWidget/StrategyFilterWidget'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { usePricingFiltersStore } from '@/store/pinia/pricingFiltersStore'
import { useStrategyFiltersStore } from '@/store/pinia/strategyFiltersStore'
import { useAnalyticsFiltersStore } from '@/store/pinia/analyticsFiltersStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore(),
      generalStore: useGeneralStore(),
      pricingFiltersStore: usePricingFiltersStore(),
      strategyFiltersStore: useStrategyFiltersStore(),
      analyticsFiltersStore: useAnalyticsFiltersStore(),
    }
  },
  name: 'TopNavbar',
  components: {
    PricingFilterWidget,
    AnalyticsFilterWidget,
    Logo,
    OptionSearch,
    BuyOptionSearch,
    FilterWidget,
    StrategyFilterWidget,
  },
  data: function () {
    return {
      menuOpened: false,
      searchOpened: false
    }
  },
  computed: {
    currentModule () {
      return this.generalStore.currentModule
    },
    routeName () {
      return this.$route?.name || ''
    },
    filtersOpened () {
      return this.filtersStore.filtersOpened
    },
    analyticsFilterOpened () {
      return this.analyticsFiltersStore.filtersOpened
    },
    pricingFiltersOpened () {
      return this.pricingFiltersStore.filtersOpened
    },
    strategyFiltersOpened () {
      return this.strategyFiltersStore.filtersOpened
    },
    isBuyHomePage () {
      return this.routeName === 'buy-home-page'
    },
    isBuyStudioPage () {
      return this.routeName.includes('buy-studio')
    },
    isBuyShopItemPage () {
      return this.routeName.includes('buy-shop-item')
    },
    isBuyAnalyticsPage () {
      return this.routeName.includes('buy-analytics')
    },
    isBuySettingsPage () {
      return this.routeName.startsWith('buy-settings')
    },
    isBuyManagePage () {
      return this.routeName === 'buy-manage-main'
    },
    isStrategyAnalyticsPage () {
      return this.routeName.includes('strategy-analytics')
    },
    isBuyPage () {
      return this.routeName.split('-')[0] === 'buy'
    }
  },
  methods: {
    overlayClick () {
      this.hideOverlay()
    },
    handleAnalyticsFilter () {
      this.analyticsFiltersStore.toggleFilter()
    },
    handlePricingFilter () {
      this.pricingFiltersStore.toggleFilter()
    },
    handleStrategyFilter () {
      this.strategyFiltersStore.toggleFilter()
    },
    itemClick () {
      this.hideOverlay()
    },
    hideOverlay () {
      this.menuOpened = false
      this.filtersStore.setFiltersOpened(false)
      this.analyticsFiltersStore.setFiltersOpened(false)
      this.pricingFiltersStore.setFiltersOpened(false)
      this.strategyFiltersStore.setFiltersOpened(false)

      if (this.$refs['optionsSearch']) {
        this.$refs['optionsSearch'].closeSearch()
      }
    },
    handleFilterClick () {
      this.filtersStore.setFiltersOpened(!this.filtersOpened)
    },
    expandClick () {
      this.menuOpened = !this.menuOpened
    },
  },
  created () {
    document.addEventListener('keydown',  (event) => {
      if (event.ctrlKey && event.key === 's') {
        if(this.$refs.optionsSearch){
          this.$refs.optionsSearch.handleSearchIconClick()
        }

        event.stopPropagation()
        event.preventDefault()
      }
    })
  }
}
</script>

<style lang="scss">
.top-bar {
    box-shadow: var(--box-shadow) !important;
    background-color: #fff !important;
    .overlay {
        position: absolute;
        height: calc(100vh / var(--zoom));
        margin-top: 56px;
    }
    .divider {
        min-height: 0 !important;
        max-height: 0 !important;
    }
}

.module-selector {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 0px 0px rgba(0, 0, 0, 0.2) !important;
    left: 0 !important;
    border-radius: 0 !important;
    .menu-list {
        padding-bottom: 0;
        box-shadow: inset 0 2px 2px #ccc !important;
        .v-list-item {
            display: flex;
            justify-content: center;
            color: var(--text-black);
            font-size: 20px;
            font-weight: 500;
            border-bottom: 1px solid var(--border-color);
            &:last-child {
                border-bottom: 0;
            }
            &:hover {
                &:before {
                    opacity: 0;
                }
            }
            &.active {
                color: var(--primary) !important;
                &:before {
                    opacity: 0;
                }
            }
        }
    }
}
</style>
