<template>
  <div class="acceptedTable">
    <data-tables
      ref="transfersTable"
      :data="tableData"
      :pagination-props="{ hideOnSinglePage: true }"
      :tableProps="tableProps"
      @row-click="rowClicked"
    >
      <el-table-column
        width="45">
        <template
          slot-scope="scope">
          <v-icon class="my-2">
            <template>
              {{expandedRow === scope.row.store ? 'expand_more': 'chevron_right'}}
            </template>
          </v-icon>
        </template>
      </el-table-column>
      <el-table-column label="Store" prop="store_key" min-width="160" >
        <template slot-scope="scope" class="my-2">
          <span >{{scope.row.store_name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Tasks" prop="created">
        <template slot-scope="scope">
          {{ scope.row.tasks }}
        </template>
      </el-table-column>
      <el-table-column label="Handled" prop="transfer">
        <template slot-scope="scope">
          {{ scope.row.handled }}
        </template>
      </el-table-column>
      <el-table-column label="Progress" prop="picked">
        <template slot-scope="scope">
          <StackedProgressBar
            style="height: 20px"
            :segments="getSegments(scope.row.progress)"
          />
        </template>
      </el-table-column>
      <el-table-column label="Done" prop="done" width="60"
      >
        <template slot-scope="scope">
          <div @click.stop>
            <v-checkbox
              :value="scope.row.done"
              class="mt-6"
              style="height: 60px"
              disabled
            ></v-checkbox>
          </div>
        </template>
      </el-table-column>
    </data-tables>

    <!-- Delete dialog -->
    <v-dialog
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="p-relative rounded-0">
        <v-overlay absolute color="white" :value="stockTransfersStore.deletingAcceptedTransfer">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <v-card-title v-if="rowToDelete" class="text-body-1 font-weight-medium">
          {{ findStore(rowToDelete.store_key).store_name }}
          <v-icon class="mx-2">arrow_forward</v-icon>
          {{ findStore(rowToDelete.to_store_key).store_name }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete this transfer?<br/>Note: deleting a transfer does not reset any exclusions.</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleDeleteCancel"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteConfirm"
                class="red--text mx-4"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Close confirm dialog -->
    <v-dialog
      :value="closeDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Close transfer
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">You will lose any changes made in this transfer. Continue?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleCloseCancel"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleCloseConfirm"
                class="red--text mx-4"
              >Ok</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import StackedProgressBar from '../components/StackedProgressBar'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'
import { useStorepageStore } from '@/store/pinia/storePageStore'

export default {
  setup () {
    return {
      stockTransfersStore: useStockTransfersStore(),
      storepageStore: useStorepageStore(),
    }
  },
  components: {
    StackedProgressBar,
  },
  props: {
    expandedRow: {
      type: Number,
      default: 0,
    },
    tableData: [],
  },
  data () {
    return {
      loaded: false,
      tableProps: {
        lazy: true,
      },
      loadingExportData: false,
      deleteDialogVisible: false,
      rowToDelete: null,
      rowExpandClicked: null,
      closeDialogVisible: false,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['findStoreWithKey']),
  },
  methods: {
    loadData () {
      this.stockTransfersStore.setDetailsRowExpanded('accepted', false)
    },
    findStore (storeKey) {
      return this.findStoreWithKey(storeKey)
    },
    handleSearchChanged (val) {
      this.transfersTableFilters[0].value = val
    },
    handleDeleteClick (row) {
      this.rowToDelete = row
      this.deleteDialogVisible = true
    },
    getSegments (progress) {
      const caption = progress * 100 + '%'
      return [
        {
          caption,
          color: '#007aff',
          width: caption
        },
        {
          color: '#ffd202',
          width: '0%'
        }
      ]
    },
    handleDeleteCancel () {
      this.rowToDelete = null
      this.deleteDialogVisible = false
    },
    handleDeleteConfirm () {
      this.stockTransfersStore.deleteAcceptedTransfer(this.rowToDelete)
        .then(() => {
          this.rowToDelete = null
          this.deleteDialogVisible = false
        })
    },
    rowClicked (row) {
      if (row.store === this.storepageStore.transfersToStoreKey) {
        this.$emit('storeClicked', null)
      } else {
        this.$emit('storeClicked', row.store, row.store_name)
      }
    },
    handleCloseCancel () {
      this.closeDialogVisible = false
      this.rowExpandClicked = null
    },
    handleCloseConfirm () {
      this.closeDialogVisible = false
      this.rowExpandClicked = null
    },
  },
  created () {
    this.loadData()
  },
}
</script>

<style lang="scss">
.acceptedTable {
  .el-table__expand-icon {
    display: none;
  }
  .el-table__expanded-cell[class*=cell] {
    padding: 16px;
    background: #f9f9f9;
    &:hover {
      background: #f9f9f9 !important;
    }
  }
}
</style>
