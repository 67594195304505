<template>
  <div class="shop-item-commit-filters">
    <h4 class="pa-4 pt-6 pb-0">Filters</h4>
    <div class="mt-2 mx-4">
      <v-row>
        <v-col>
          <collapsible-select
            v-model="filtersForm.store_keys"
            :items="storesList"
            item-text="text"
            item-value="value"
            @input="saveFilters"
            autocomplete
            :show-select-all="storesList.length > 0"
            class="mt-1 mb-4 text-body-2"
            hide-details="auto"
            :error-messages="!(filtersForm.store_keys || []).length ? 'Select a store set with stores' : ''"
            label="Stores"
            @change="formChangedDelayed"
          />
        </v-col>
        <v-col>
          <collapsible-select
            v-model="filtersForm.sku_names"
            :items="item.sku_names.map(name => ({
              text: name,
              value: name,
            }))"
            @input="saveFilters"

            autocomplete
            show-select-all
            class="mt-1 mb-4 text-body-2"
            hide-details="auto"
            label="SKUs"
            @change="formChangedDelayed"
          />
        </v-col>
        <v-col>
          <WeekSelector
            v-model="filtersForm.week_min"
            :lower-limit="item.week_min"
            :higher-limit="filtersForm.week_max"
            clearable
            only-future
            @input="saveFilters"
            label="Commit from"
            class="pt-0"
            hide-details="auto"
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @change="formChangedDelayed"
          />
        </v-col>
        <v-col>
          <WeekSelector
            v-model="filtersForm.week_max"
            clearable
            :lower-limit="filtersForm.week_min"
            :higher-limit="item.week_max"
            @input="saveFilters"
            only-future
            label="Commit to"
            class="pt-0"
            hide-details="auto"
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @change="formChangedDelayed"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { addWeeks, getWeek, format } from 'date-fns'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import WeekSelector from '../WeekSelector'

export default {
  components: {
    WeekSelector,
    CollapsibleSelect,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    stores: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      formChangedDelayed: _.debounce(this.formChanged, 2000),
      filtersForm: {
        store_keys: null,
        sku_names: null,
        week_min: null,
        week_max: null,
      },
    }
  },
  computed: {
    storesNames () {
      return this.stores.reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    storesList () {
      return (this.item.store_keys || []).map(s => ({
        text: this.storesNames[s],
        value: s,
      }))
    },
  },
  methods: {
    saveFilters () {
      sessionStorage.setItem('commitFilters', JSON.stringify(this.filtersForm))
    },
    setFiltersFromShopItem () {
      const sessionItem = sessionStorage.getItem('commitFilters')
      if (sessionItem) {
        this.filtersForm = JSON.parse(sessionItem)
        return
      }
      if (!this.item.week_min) {
        const newDate = new Date()
        this.filtersForm.week_min = newDate.getFullYear() + '' + getWeek(newDate)
      } else {
        if (this.item.week_min < format((new Date()), 'yyyyww')) {
          this.filtersForm.week_min = format(new Date(), 'yyyyww')
        } else {
          this.filtersForm.week_min = this.item.week_min?.toString()
        }
      }
      if (!this.item.week_max) {
        const week_min = this.filtersForm.week_min.toString()
        const year = week_min.substring(0, 4)
        const week = week_min.substring(4)
        const weekMinDate = new Date(`${year}-01-01`)
        weekMinDate.setDate(week * 7)
        const week_max = addWeeks(weekMinDate, 52)
        this.filtersForm.week_max = format(week_max, 'yyyyww')
      } else {
        if (this.item.week_max < format((new Date()), 'yyyyww')) {
          const date = new Date()
          addWeeks(date, 52)
          this.filtersForm.week_max = format(date, 'yyyyww')
        } else {
          this.filtersForm.week_max = this.item.week_max.toString()
        }
      }
      this.filtersForm.store_keys = this.item.store_keys
      this.filtersForm.sku_names = this.item.sku_names
      this.$emit('filters-changed', this.filtersForm)
    },
    formChanged () {
      this.$emit('filters-changed', this.filtersForm)
    },
  },
  watch: {
    'item.week_min': {
      handler () {
        this.setFiltersFromShopItem()
        this.formChangedDelayed()
      }
    },
    'item.week_max': {
      handler () {
        this.setFiltersFromShopItem()
        this.formChangedDelayed()
      }
    },
  },
}
</script>

<style lang="scss">
.shop-item-commit-filters {
}
</style>
