<template>
  <resizable
    class="pricing-markdown-builder-statistics p-relative"
    @resize="updateTotalTableWidth"
  >
    <v-row>
      <v-col class="text-right mr-6">
        <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
        <DataExport @item-click="downloadTableData" />
        <v-menu
          offset-y
          offset-x
          nudge-bottom="5"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on"
            >filter_list
            </v-icon>
          </template>
          <v-card max-height="400" class="overflow-auto">
            <h5 class="px-2 pt-2">Columns</h5>
            <el-checkbox-group
              v-model="selectedColumns"
              @input="handleSelectedColumnsChanged"
              style="font-size: 14px"
              class="d-flex flex-column pa-2">
              <el-checkbox
                v-for="item of columnsToSelect"
                :label="item"
                :key="item"
              />
            </el-checkbox-group>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <data-tables
          class="table"
          style="width: 100%"
          :key="tableKey"
          :filters="tableFilters"
          :data="statistics.table"
          :page-size="statistics.table.length > 200 ? 200 : statistics.table.length"
          :pagination-props="{
            pageSizes: [statistics.table.length > 200 ? 200 : statistics.table.length],
            class: statistics.table.length > 200 ? 'el-pagination text-right mt-6 mb-4 mr-2' : 'd-none',
          }"
          :table-props="{ 'row-key': 'option_key' }"
        >
          <data-tables
            slot="append"
            :index="null"
            class="total-table"
            :data="[statistics.total]"
            :pagination-props="{class: 'd-none'}"
          >
            <el-table-column
              v-for="column in primaryColumns"
              :key="column"
              :prop="column"
              :label="column | formatString"
              class-name="total-row_column"
              label-class-name="d-none"
              :width="column === 'dimension' ? '170px' : undefined"
            >
              <template slot-scope="scope">
                <template v-if="column === 'dimension'">
                  <div class="pl-3">Total</div>
                </template>
                <template v-if="['markdown_perc_n5w', 'margin_perc_n5w'].includes(column)">
                  {{ scope.row[column] }}%
                </template>
                <template v-else>
                  {{ scope.row[column] | formatThousands }}
                </template>
                <template v-if="hasChangeColumn(column)">
                  <div
                    class="delta-value"
                    :class="getChangeClassName(scope.row, column + '_change')"
                  >({{ scope.row[column + '_change'] | formatThousands | addSign }})</div>
                </template>
              </template>
            </el-table-column>
          </data-tables>
          <el-table-column
            v-for="column in primaryColumns"
            :key="column"
            :prop="column"
            :label="column | formatString"
            :width="column === 'dimension' ? '170px' : undefined"
            :label-class-name="column === 'dimension' ? 'pl-3' : ''"
            :class-name="column === 'dimension' ? 'pl-3' : ''"
            sortable
          >
            <template slot-scope="scope">
              <template v-if="['markdown_perc_n5w', 'margin_perc_n5w'].includes(column)">
                {{ scope.row[column] }}%
              </template>
              <template v-else>
                {{ scope.row[column] | formatThousands }}
              </template>
              <template v-if="hasChangeColumn(column)">
                <div
                  class="delta-value"
                  :class="getChangeClassName(scope.row, column + '_change')"
                >({{ scope.row[column + '_change'] | formatThousands | addSign }})</div>
              </template>
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>
  </resizable>
</template>

<script>
import { downloadData } from '@/utils'
import TableSearch from '@/components/TableSearch'
import DataExport from '@/components/DataExport'
import Resizable from '@/components/Utility/Resizable.vue'

const columnsToSelect = ['Week number', 'Discount', 'Price', 'Intake', 'Stock', 'Sellthrough', 'Weeks on hand', 'Weeks left', 'Residual', 'Value', 'Markdown perm', 'Sales n5w', 'Markdown perc n5w', 'Margin perc n5w', 'Sales lxw', 'Sellthrough lxw']
const defaultColumns = ['Week number', 'Discount', 'Price', 'Intake', 'Stock', 'Sellthrough', 'Weeks on hand', 'Weeks left', 'Residual', 'Value', 'Markdown perm']

export default {
  name: 'MarkdownBuilderStatistics',
  components: { Resizable, TableSearch, DataExport },
  props: {
    statistics: {
      type: Object || null,
      default: null,
    },
  },
  data () {
    return {
      tableFilters: [{ value: '' }],
      tableKey: 0,
      columnsToSelect,
      selectedColumns: JSON.parse(localStorage.getItem('markdownBuilderSelectedColumns')) || defaultColumns,
    }
  },
  computed: {
    selectedColumnsProps () {
      return this.selectedColumns.map(c => c.replaceAll(' ', '_').toLowerCase())
    },
    primaryColumns () {
      return this.statistics.columns
        .filter(c =>
          (c === 'dimension') ||
          (!c.endsWith('_change') && this.selectedColumnsProps.includes(c))
        )
    },

  },
  watch: {
  },
  methods: {
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = document.querySelector('.pricing-markdown-builder-statistics .el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 100)
    },
    handleSearchChanged (val) {
      this.tableFilters[0].value = val
      this.tableKey++
      this.updateTotalTableWidth()
    },
    downloadTableData (sep) {
      downloadData(sep, this.statistics.table, 'markdown_builder_statistics')
    },
    hasChangeColumn (col) {
      return this.statistics.columns.includes(col + '_change')
    },
    getChangeClassName (row, colName) {
      if (colName === 'residual_change') {
        return row[colName] >= 0 ? 'error--text' : 'success--text'
      } else {
        return row[colName] < 0 ? 'error--text' : 'success--text'
      }
    },
    handleSelectedColumnsChanged (value) {
      localStorage.setItem('markdownBuilderSelectedColumns', JSON.stringify(value))
      this.updateTotalTableWidth()
    },
  },
}
</script>
<style lang="scss">
.pricing-markdown-builder-statistics {
    .el-table__append-wrapper {
        position: sticky;
        bottom: 0;
    }
    .table .el-table__body-wrapper {
        max-height: 50vh;
        overflow-y: auto;
    }
    .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
        width: 4px;
    }
    .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    }
    .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 3px;
    }
    .total-row_column {
        background-color: #f5f7fa;
    }
    .delta-value {
        font-size: 11px;
    }
}
</style>
