<template>
  <div>
    <v-overlay absolute color="white" :value="!loaded || !chartData">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <div v-show="loaded" class="chart-container" style="position: relative;"
      :style="{height: '300px'}">
      <canvas id="lookalike_skus"></canvas>
    </div>
  </div>
</template>

<script>
import { Chart, BarController } from 'chart.js/auto'

class DashedBorderBar extends BarController {
  draw () {
    super.draw(arguments)
    const ctx = this.chart.ctx
    ctx.save()
    const dataset = this.chart.data.datasets[1]
    ctx.strokeStyle = dataset.dashedBorderColor
    ctx.lineWidth = dataset.dashedBorderWidth
    ctx.setLineDash(dataset.borderDash)
    this.getMeta().data.forEach(d => {
      ctx.strokeRect(d.x - d.width / 2 + 1, d.y + 2, d.width - 2, d.height + ctx.lineWidth - 2)
    })
    ctx.restore()
  }
}
DashedBorderBar.id = 'dashedBorderBar'
DashedBorderBar.defaults = BarController
Chart.register(DashedBorderBar)

export default {
  props: ['chartData', 'height'],
  data () {
    this.chart = null

    return {
      loaded: false,
    }
  },
  watch: {
    chartData () {
      this.drawGraph()
    }
  },
  methods: {
    drawGraph () {
      try {
        this.loaded = false
        const ctx = document.getElementById('lookalike_skus').getContext('2d')
        const chartData = {
          labels: this.chartData.sku_names,
          datasets: [
            {
              label: 'Sales',
              data: this.chartData['Sales'],
              yAxisID: 'y',
              pointRadius: 6,
              borderColor: '#4CAF50',
              backgroundColor: 'transparent',
              borderWidth: 2,
              fill: false,
              showLine: false,
              type: 'bar',
            },
            {
              label: 'Sales + Missed',
              data: this.chartData['Sales + Missed'],
              yAxisID: 'y',
              pointRadius: 6,
              dashedBorderColor: '#4CAF50',
              backgroundColor: 'transparent',
              dashedBorderWidth: 2,
              borderDash: [10, 5],
              fill: false,
              showLine: false,
              type: 'dashedBorderBar',
            },
            {
              label: 'Belief',
              data: this.chartData['Belief'],
              yAxisID: 'y',
              pointRadius: 6,
              borderColor: '#FF9800',
              backgroundColor: '#FF9800',
              borderWidth: 2,
              fill: true,
              showLine: false,
              type: 'bubble',
            }
          ]
        }

        const options = {
          responsive: true,
          maintainAspectRatio: false,
          // clip: false,
          plugins: {
            legend: {
              position: 'bottom',
              align: 'center',
              labels: {
                usePointStyle: true,
                generateLabels: (chart) => {
                  const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)
                  labels[1].lineDash = [5, 5]
                  labels[1].lineWidth = 2
                  labels[1].strokeStyle = '#4CAF50'
                  return labels
                },
              }
            },
            tooltip: {
              callbacks: {
                label: tooltipItem => {
                  return chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex].toLocaleString()
                }
              }
            },
          },
          scales: {
            y: {
              stacked: false,
              gridLines: {
                display: true
              },
              title: {
                display: true,
                text: 'Sales',
                font: {
                  size: 14,
                },
              },
              min: 0,
              ticks: {
                font: {
                  size: 14,
                },
                padding: 16,
                callback: function (value) {
                  const ranges = [
                    { divider: 1e6, suffix: 'M' },
                    { divider: 1e3, suffix: 'k' }
                  ]

                  function formatNumber (n) {
                    for (let i = 0; i < ranges.length; i++) {
                      if (n >= ranges[i].divider) {
                        return (n / ranges[i].divider).toString() + ranges[i].suffix
                      }
                    }
                    return n
                  }
                  return formatNumber(value)
                },
              }
            },
            x: {
              grid: {
                display: true,
              },
              stacked: true,
            }
          },
        }

        if (this.chart) {
          this.chart.destroy()
        }

        this.chart = new Chart(ctx, {
          type: 'bar',
          data: chartData,
          options,
        })

        this.$emit('loaded')
        this.loaded = true
      } catch (e) {
        this.$emit('loaded')
        console.log(e)
        this.loaded = true
      }
    },
  },
  mounted () {
    this.drawGraph()
  }
}
</script>
