<template>
  <div class="my-6 mb-12 buy_analytics">
    <div class="my-6">
      <h3 class="card-title text-left mb-0">Analytics</h3>
    </div>
    <v-card class="pa-6 pb-0 mb-3">
      <v-row class="ma-0">
        <h4 class="pa-0 pb-2">
          Dashboard
        </h4>
        <v-row class="ma-0 my-0 justify-end">
          <v-icon @click="manageModal = true" class="analytics-manage_icon mr-4">tune</v-icon>
          <v-icon @click="openPositionsModal = true" class="analytics-manage_icon">mdi-grid</v-icon>

        </v-row>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="6" md="3" class="pt-0 px-0">
          <v-row class="ma-0 align-center">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              class="dashboard-select mr-3"
              :items="dashboardForChoose"
              label="Name"
              v-model="chosenDashboard"
              :loading="loading"
              @change="views = []; loadViews($event, true)"
            ></v-autocomplete>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <v-card-actions class="justify-end pb-4 close-dialog-view"
      v-if="dialogView">
      <v-icon
        depressed
        outlined
        size="24"
        class="my-2"
        @click="closeViewModal"
      >close</v-icon>
    </v-card-actions>
    <v-row class="my-0 justify-space-between mt-0" v-for="(viewsList,index) of viewsToShow.filter(item=> item && item.length)" :key="index + ''">
      <v-col
        cols="12"
        :md="calculateViewsColumn(viewsList, index === toggledView.firstIndex, view.view)"
        class="view-column"
        :style="`height: ${!view.view.properties?.no_inline_scroll && calculateViewsHeight(view, index === toggledView.firstIndex)}`"
        v-for="(view, secondaryIndex) in viewsList.filter(item=> Object.keys(item || {}).length)" :key="view.view.view_key"
        :class="{isDialogView: dialogView && dialogView.view.view_key === view.view.view_key}"
      >
        <template v-if="view.loading">
          <v-card
            class="mt-10 py-4 pa-0 ma-0"
            style="min-height: 100%"
          >
            <h4 class="pa-6 pr-4">{{index +1}}{{['A','B', 'C'][secondaryIndex]}}. {{ view.view.view_name }}</h4>
            <v-overlay absolute color="white" :value="true">
              <v-progress-circular color="primary" indeterminate size="64" width="2"/>
            </v-overlay>
          </v-card>
        </template>
        <template v-else-if="view && view.view
          && ((toggledView.firstIndex !== index || toggledView.secondaryIndex === secondaryIndex) || $vuetify.breakpoint.mobile)
          && (!dialogView || dialogView.view.view_key === view.view.view_key)">
          <data-sets-matrix-tables
            class="fill-height"
            v-if="view.view.properties && view.view.properties.matrix && view.view.chart_type === 'table'"
            :positions="positions"
            :secondaryIndex="secondaryIndex"
            @saved-positions="savePositions"
            :datasets="datasets"
            :shared="!dashboardDetails.personal"
            :dataSetForChoose="dataSetForChoose"
            @full-screen="setDialogView"
            :view="view"
            :dashboardDetails="dashboardDetails"
            :views-map="viewsNamesMap"
            :in-modal="(dialogView && dialogView.view.view_key === view.view.view_key)"
            :child-views="view.view.childViews"
            :index="index"
            @toggle-view="setToggleView($event, index, secondaryIndex)"
            @change-view="saveView"
            @delete-view="deleteView"
          ></data-sets-matrix-tables>
          <data-sets-tables
            class="fill-height"
            v-else-if="view.view.chart_type === 'table'"
            :positions="positions"
            :views-map="viewsNamesMap"
            @saved-positions="savePositions"
            @toggle-view="setToggleView($event, index, secondaryIndex)"
            :secondaryIndex="secondaryIndex"
            :datasets="datasets"
            :shared="!dashboardDetails.personal"
            :dataSetForChoose="dataSetForChoose"
            @full-screen="setDialogView"
            :view="view"
            :dashboardDetails="dashboardDetails"
            :in-modal="(dialogView && dialogView.view.view_key === view.view.view_key)"
            :child-views="view.view.childViews"
            :index="index"
            @change-view="saveView"
            @delete-view="deleteView"
          ></data-sets-tables>
          <data-sets-scatter-chart
            :view="view"
            class="fill-height"
            :secondaryIndex="secondaryIndex"
            :index="index"
            :views-map="viewsNamesMap"
            @saved-positions="savePositions"
            :dashboardDetails="dashboardDetails"
            :shared="!dashboardDetails.personal"
            :positions="positions"
            :in-modal="(dialogView && dialogView.view.view_key === view.view.view_key)"
            :datasets="datasets"
            @full-screen="setDialogView"
            :dataSetForChoose="dataSetForChoose"
            @change-view="saveView"
            @toggle-view="setToggleView($event, index, secondaryIndex)"
            @delete-view="deleteView"
            v-else-if="view.view.chart_type === 'scatter_chart'"
          ></data-sets-scatter-chart>
          <data-sets-doughnut-chart
            :view="view"
            :views-map="viewsNamesMap"
            @saved-positions="savePositions"
            :dashboardDetails="dashboardDetails"
            class="fill-height"
            :secondaryIndex="secondaryIndex"
            :index="index"
            :positions="positions"
            :in-modal="(dialogView && dialogView.view.view_key === view.view.view_key)"
            :shared="!dashboardDetails.personal"
            :datasets="datasets"
            @toggle-view="setToggleView($event, index, secondaryIndex)"
            @full-screen="setDialogView"
            :dataSetForChoose="dataSetForChoose"
            @change-view="saveView"
            @delete-view="deleteView"
            v-else-if="view.view.chart_type === 'doughnut_chart'"
          ></data-sets-doughnut-chart>
          <data-sets-gauge-chart
            :view="view"
            :views-map="viewsNamesMap"
            @saved-positions="savePositions"
            :dashboardDetails="dashboardDetails"
            class="fill-height"
            :secondaryIndex="secondaryIndex"
            :index="index"
            :positions="positions"
            :in-modal="(dialogView && dialogView.view.view_key === view.view.view_key)"
            :shared="!dashboardDetails.personal"
            :datasets="datasets"
            @toggle-view="setToggleView($event, index, secondaryIndex)"
            @full-screen="setDialogView"
            :dataSetForChoose="dataSetForChoose"
            @change-view="saveView"
            @delete-view="deleteView"
            v-else-if="view.view.chart_type === 'gauge_chart'"
          ></data-sets-gauge-chart>
          <DataSetsKPI
            :view="view"
            class="fill-height"
            :secondaryIndex="secondaryIndex"
            :index="index"
            :views-map="viewsNamesMap"
            @saved-positions="savePositions"
            :dashboardDetails="dashboardDetails"
            :positions="positions"
            :in-modal="(dialogView && dialogView.view.view_key === view.view.view_key)"
            :shared="!dashboardDetails.personal"
            :datasets="datasets"
            @toggle-view="setToggleView($event, index, secondaryIndex)"
            @full-screen="setDialogView"
            :dataSetForChoose="dataSetForChoose"
            @change-view="saveView"
            @delete-view="deleteView"
            v-else-if="view.view.chart_type === 'kpi_tile' || view.view.chart_type === 'kpi_chart' "
          ></DataSetsKPI>
          <DataSetsText
            :view="view"
            class="fill-height"
            :views-map="viewsNamesMap"
            @saved-positions="savePositions"
            :dashboardDetails="dashboardDetails"
            :secondaryIndex="secondaryIndex"
            :index="index"
            :positions="positions"
            :in-modal="(dialogView && dialogView.view.view_key === view.view.view_key)"
            :shared="!dashboardDetails.personal"
            :datasets="datasets"
            @toggle-view="setToggleView($event, index, secondaryIndex)"
            @full-screen="setDialogView"
            :dataSetForChoose="dataSetForChoose"
            @change-view="saveView"
            @delete-view="deleteView"
            v-else-if="view.view.chart_type === 'text_tile'"
          ></DataSetsText>
          <DataSetsImage
            :view="view"
            class="fill-height"
            :views-map="viewsNamesMap"
            @saved-positions="savePositions"
            :dashboardDetails="dashboardDetails"
            :secondaryIndex="secondaryIndex"
            :index="index"
            :positions="positions"
            :in-modal="(dialogView && dialogView.view.view_key === view.view.view_key)"
            :shared="!dashboardDetails.personal"
            :datasets="datasets"
            @toggle-view="setToggleView($event, index, secondaryIndex)"
            @full-screen="setDialogView"
            :dataSetForChoose="dataSetForChoose"
            @change-view="saveView"
            @delete-view="deleteView"
            v-else-if="view.view.chart_type === 'image_tile'"
          ></DataSetsImage>
          <DatasetsFilters
            :view="view"
            class="fill-height"
            :views-map="viewsNamesMap"
            @saved-positions="savePositions"
            :dashboardDetails="dashboardDetails"
            :secondaryIndex="secondaryIndex"
            :index="index"
            :positions="positions"
            :in-modal="(dialogView && dialogView.view.view_key === view.view.view_key)"
            :shared="!dashboardDetails.personal"
            :datasets="datasets"
            @toggle-view="setToggleView($event, index, secondaryIndex)"
            @full-screen="setDialogView"
            :dataSetForChoose="dataSetForChoose"
            @change-view="saveView"
            @delete-view="deleteView"
            @filters-updated="applyFilters(false)"
            v-else-if="view.view.chart_type === 'filters_tile'"
          ></DatasetsFilters>
          <data-sets-waterfall-chart
            :view="view"
            :views-map="viewsNamesMap"
            @saved-positions="savePositions"
            :dashboardDetails="dashboardDetails"
            class="fill-height"
            :secondaryIndex="secondaryIndex"
            :index="index"
            :positions="positions"
            :in-modal="(dialogView && dialogView.view.view_key === view.view.view_key)"
            :shared="!dashboardDetails.personal"
            :datasets="datasets"
            @toggle-view="setToggleView($event, index, secondaryIndex)"
            @full-screen="setDialogView"
            :dataSetForChoose="dataSetForChoose"
            @change-view="saveView"
            @delete-view="deleteView"
            v-else-if="view.view.chart_type === 'waterfall_chart'"
          ></data-sets-waterfall-chart>
          <data-sets-line-chart
            :view="view"
            class="fill-height"
            :height="!view.view.properties?.no_inline_scroll && calculateViewsHeight(view, index === toggledView.firstIndex)"
            :views-map="viewsNamesMap"
            @saved-positions="savePositions"
            :dashboardDetails="dashboardDetails"
            :secondaryIndex="secondaryIndex"
            :index="index"
            @toggle-view="setToggleView($event, index, secondaryIndex)"
            :positions="positions"
            :in-modal="(dialogView && dialogView.view.view_key === view.view.view_key)"
            :shared="!dashboardDetails.personal"
            :datasets="datasets"
            @full-screen="setDialogView"
            :dataSetForChoose="dataSetForChoose"
            @change-view="saveView"
            @delete-view="deleteView"
            v-else
          ></data-sets-line-chart>
        </template>
        <v-card v-else-if="!view?.view && Object.keys(view?.view || {}).length"
          class="mt-10 py-4 pa-0 ma-0 is-expanded-table data-sets-tables"
        >
          <h4 class="pa-6 pr-4">{{index +1}}. {{ view.view.view_name }}</h4>
          <h4  class="row justify-center py-8"> Could not retrieve data</h4>
        </v-card>
      </v-col>
    </v-row>
    <v-menu offset-y :close-on-content-click="false" nudge-bottom="5" v-model="addMenu" @input="loadViewsForCopy" v-if="!showNewView && !dialogView">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on"

          class="mt-10"
          :disabled="!dashboardDetails.personal">add</v-icon>
      </template>
      <v-list dense>
        <h4 class="pa-4">Add View</h4>
        <v-list-item
          class="c-pointer font-weight-medium"
          @click="showNewView = true; addMenu =false"
        >
          <v-label
            class="c-pointer"><span class="c-pointer">New template</span></v-label>
        </v-list-item>
        <v-list-item
          class="c-pointer"
        >
          <v-list-item-title>
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              :key="newViewSelectorsKey"
              label="Copy personal"
              :items="copyViewsPersonal.map((item)=> {return {...item, group: item.dashboard_type}})"
              item-text="view_name"
              item-value="view_key"
              @input="loadView"
              clearable
            ></v-autocomplete></v-list-item-title>
        </v-list-item>
        <v-list-item
          class="c-pointer"
        >
          <v-list-item-title>
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              :key="newViewSelectorsKey"
              label="Copy shared"
              :items="copyViewsShared.map((item)=> {return {...item, group: item.dashboard_type}})"
              item-text="view_name"
              item-value="view_key"
              @input="loadView"
              clearable
            ></v-autocomplete>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <datasets-new-view
      class="mt-10"
      v-if="showNewView"
      :positions="positions"
      :datasets="datasets"
      :dataSetForChoose="dataSetForChoose"
      :dashboard-details="dashboardDetails"
      @save="saveView($event)"
      @cancel="showNewView = false"
    />
    <v-dialog
      v-model="manageModal"
      v-if="manageModal"
      scrollable
      content-class="align-self-start mt-10"
      width="1200px"
      max-width="90%">
      <ManageDashboardsModal
        @close="()=> manageModal =false"
        @open-dashboard="closeAndGo"
      ></ManageDashboardsModal>
    </v-dialog>
    <v-dialog

      v-model="openPositionsModal"
      scrollable
      content-class="align-self-start mt-14"
      width="90%"
      max-width="90%">
      <PositionsModal
        v-if="openPositionsModal"
        :dashboard-details="dashboardDetails"
        :views-map="viewsNamesMap"
        @close="openPositionsModal = false"
        @saved="savePositions"
      ></PositionsModal>
    </v-dialog>
  </div>
</template>

<script>
import {formatString} from '@/variables'
import DataSetsTables from '@/components/AutomateDatasets/DataSetsTables'
import {
  getDashboardDetails,
  getDashboards,
  getDatasets,
  getSharedDashboards,
  getViewDetails,
  getViewsCopy,
  performViewData,
  saveDashboard,
  saveView,
} from '@/api/analytics'
import DataSetsLineChart from '@/components/AutomateDatasets/DataSetsLineChart'
import DataSetsScatterChart from '@/components/AutomateDatasets/DataSetsScatterChart'
import ManageDashboardsModal from '@/components/AutomateDatasets/ManageDashboardsModal'
import DataSetsMatrixTables from '@/components/AutomateDatasets/DataSetsMatrixTables'
import DataSetsDoughnutChart from '@/components/AutomateDatasets/DataSetsDoughnutChart'
import DataSetsWaterfallChart from '@/components/AutomateDatasets/DataSetsWaterfallChart'
import DataSetsKPI from '@/components/AutomateDatasets/DataSetsKPI'
import DataSetsImage from '@/components/AutomateDatasets/DataSetsImage'
import DataSetsGaugeChart from '@/components/AutomateDatasets/DataSetsGaugeChart'
import DatasetsFilters from '@/components/AutomateDatasets/DataSetsFilters'
import {useAnalyticsFiltersStore} from '@/store/pinia/analyticsFiltersStore'
import PositionsModal from '@/components/AutomateDatasets/PositionsModal.vue'

const DatasetsNewView = () => import('@/components/AutomateDatasets/DatasetsNewView')
// Dynamic import since md editor is localized in DataSetsText
const DataSetsText = () => import('@/components/AutomateDatasets/DataSetsText')

export default {
  name: 'DataSetsMainPage',
  components: {PositionsModal, DatasetsFilters, DataSetsGaugeChart, DataSetsImage, DataSetsText, DataSetsKPI, DataSetsWaterfallChart, DataSetsDoughnutChart, DataSetsMatrixTables, ManageDashboardsModal, DataSetsScatterChart, DataSetsLineChart, DatasetsNewView, DataSetsTables },
  setup () {
    return {
      analyticsFiltersStore: useAnalyticsFiltersStore()
    }
  },
  data () {
    return {
      showViews: [],
      dialogView: null,
      dialogIndex: null,
      openPositionsModal: false,
      viewModalOpen: false,
      dashboards: [],
      chosenDashboard: null,
      views: [[]],
      erroredViews: [],
      minMaxValues: {},
      toggledView: {},
      dashboardForChoose: [],
      datasets: {},
      newViewSelectorsKey: 0,
      addMenu: false,
      manageModal: false,
      newName: '',
      copyViewsPersonal: [],
      copyViewsShared: [],
      tempViews: {},
      loading: false,
      showNewView: false,
    }
  },
  computed: {
    dataSetForChoose () {
      return Object.keys(this.datasets || {}).map((item) => {
        return { value: item, text: formatString(item) }
      })
    },
    viewsToShow (){
      const viewKeys = this.showViews.map(item=> item.filter(key=> key))
      return viewKeys.map((item, index) => {
        return item.map((view, secondaryIndex) => {
          if (!this.views[index]) {
            return {view, data: [], loading: true}
          }
          return this.views[index][secondaryIndex] || {view, data: [], loading: true}
        })
      })
      // return this.views.map((viewsList, listIndex)=> {
      //   const view_keys = this.dashboardDetails.view_keys[listIndex]
      //   return viewsList?.filter(item=> view_keys?.indexOf(item.view.view_key) > -1) || null
      // })
    },
    viewsNamesMap () {
      return this.views.filter(item => item).reduce((prevList, views) => {
        const newViews = !views ? {} : views?.reduce((prev, view) => {
          return { ...prev,
                   [view?.view?.view_key]: {
                     name: view?.view?.view_name,
                     style: view?.view?.chart_type,
                     properties: view.view.properties
                   } }
        }, {})
        return { ...prevList, ...newViews }
      }, {})
    },
    positions () {
      return Array.from({ length: (this.dashboardDetails.view_keys || []).length + 1 }, (v, i) => i + 1)
    },
    dashboardDetails () {
      return this.dashboards.find((item) => item.dashboard_key === this.chosenDashboard) || { view_keys: [] }
    }
  },
  watch: {
    dashboardDetails (newValue) {
      this.analyticsFiltersStore.setDashboardDetails(newValue)
    },
    'analyticsFiltersStore.filtersOpened': function (newValue) {
      this.applyFilters(newValue)
    },
  },
  methods: {
    loadViewsForCopy (value){
      if(!value){
        return
      }
      getViewsCopy().then((data) => {
        let dashboardName = ''
        const tempDataPersonal = []
        const tempDataShared = []
        data.forEach((item) => {
          if (dashboardName !== item.dashboard_name) {
            dashboardName = item.dashboard_name
            if (item.dashboard_type === 'personal') {
              tempDataPersonal.push({ header: item.dashboard_name })
            } else {
              tempDataShared.push({ header: item.dashboard_name })
            }
          }
          if (item.dashboard_type === 'personal') {
            tempDataPersonal.push(item)
          } else {
            tempDataShared.push(item)
          }
        })
        this.copyViewsPersonal = tempDataPersonal
        this.copyViewsShared = tempDataShared
      })

    },
    applyFilters (newValue){
      if (newValue) {
        return
      }
      const indexes = this.analyticsFiltersStore.changedFilters
      if (indexes.has('default')) {
        this.dashboards = [...this.dashboards]
        this.getDashboards()
      } else if (indexes.has('dashboard')) {
        this.loadViews()
      } else {
        indexes.forEach((position) => {
          if(!position) {
            return
          }
          const secondPositions = ['A', 'B', 'C', 'D']
          const positionLetter = position[position.length - 1]
          let firstPosition
          let secondPosition
          if(typeof position === 'number' || position.length === 1){
            firstPosition = position - 1
            secondPosition = 0
          }else {
            firstPosition = position.substring(0, position.length - 1)
            firstPosition--
            secondPosition = secondPositions.indexOf(positionLetter)
            if (secondPosition === -1) {
              secondPosition = 0
            }
          }
          const views = this.analyticsFiltersStore.viewsList
          this.loadViewData(views[firstPosition][secondPosition], false, [firstPosition, secondPosition])
        })
      }
      this.analyticsFiltersStore.setChangedFilters(new Set())
    },
    async savePositions (positions) {
      if(!this.views){
        return
      }
      const keys = new Set(positions.views)
      this.loading = true
      this.toggledView = {}
      this.dashboardDetails.view_keys = this.dashboardDetails.view_keys.filter(item => item.some(key => key))
      for (const viewsList of this.views) {
        for (const  item  of viewsList) {
          if(!item){
            continue
          }
          const view = item.view
          if (keys.has(view.view_key)) {
            view.properties.length = positions.map[view.view_key]?.properties.length
            view.properties.height = positions.map[view.view_key]?.properties.height
            await saveView({ ...view, dashboard_key: this.dashboardDetails.dashboard_key })
          }
        }
      }
      saveDashboard(this.dashboardDetails).then(this.loadViews)
    },
    calculateViewsHeight (view, toggledIndex){
      if(!view.data){
        return ''
      }
      if(!view.view?.properties?.height || toggledIndex){
        return ''
      }
      return `calc((calc(100vw / var(--zoom)) - 60px) / 12 * ${view.view.properties?.height}) `
    },
    calculateViewsColumn (list, toggledIndex, view) {
      if (toggledIndex) {
        return 12
      }

      if(this.dialogView?.view && this.dialogView?.view?.view_key ===  view?.view_key){
        return 12
      }
      if (view.properties?.length) {
        return view.properties.length
      }
      const viewsList = list.filter(item => Object.keys(item || {}).length)
      let count = 0
      let columnsCount = 0
      viewsList.forEach(({ view }) => {
        if (view.properties?.length) {
          columnsCount += view.properties.length
        } else {
          count++
        }
      })
      return parseInt((12 - columnsCount) / count)
    },
    setToggleView (value, index, secondaryIndex) {
      if (!value) {
        this.toggledView = {}
        return
      }
      this.toggledView = {
        firstIndex: index,
        secondaryIndex
      }
    },
    async closeAndGo (dashboard_key) {
      this.manageModal = false
      this.chosenDashboard = dashboard_key
      await this.$router.push({ ...this.$route, query: { dashboard_key } })
      await this.getDashboards()
      this.views = []
      this.loadViews(dashboard_key, true)
    },
    setDialogView (view, index) {
      this.dialogView = view
      this.dialogIndex = index
      this.viewModalOpen = true
      document.querySelector('.option-search-component').classList.add('d-none')
      document.querySelector('.analytics-filter-icon').classList.add('d-none')
    },
    closeViewModal () {
      this.viewModalOpen = false
      this.dialogView = null
      document.querySelector('.option-search-component').classList.remove('d-none')
      document.querySelector('.analytics-filter-icon').classList.remove('d-none')
    },
    loadViews (value, isFirstLoad = false) {
      this.toggledView = {}
      if (value !== undefined && this.$route.query?.dashboard_key?.toString() !== this.chosenDashboard.toString()) {
        this.$router.replace({ name: this.$route.name, query: { ...this.$route.query, dashboard_key: this.chosenDashboard } })
        localStorage.setItem('chosenDashboard', value)
      }
      this.loading = true
      this.views = [[]]
      this.erroredViews = []
      getDashboardDetails(this.chosenDashboard).then(async ({views, datasets}) => {
        this.showViews = views
        const chosenDashboard = this.chosenDashboard
        this.analyticsFiltersStore.setFiltersProperties({datasetKey: 'none', properties: null})
        await this.analyticsFiltersStore.loadProperties(datasets)
        await this.analyticsFiltersStore.setViewsFilters(views)
        if (views) {
          for (let firstPosition = 0; firstPosition < views.length; firstPosition++) {
            let viewsList = views[firstPosition]
            if (!Array.isArray(viewsList)) {
              viewsList = [views[firstPosition]]
            }
            for (let secondPosition = 0; secondPosition < viewsList.length; secondPosition++) {
              const view = viewsList[secondPosition]
              if (!Object.keys(view || {}).length) {
                continue
              }
              if (!this.views[firstPosition]) {
                this.views[firstPosition] = []
              }
              this.views[firstPosition][secondPosition] = { view: { view_name: view.view_name }, data: null }
              if (!view.dimensions) {
                view.dimensions = []
              }
              this.loadViewData(view, false, [firstPosition, secondPosition], false, chosenDashboard)
            }
          }
        }
        this.loading = false
      }).catch(error => {
        console.log(error)
        if (error.response?.data?.message?.includes('no dashboard found')) {
          const newRoute = this.$route
          delete newRoute.query.dashboard_key
          localStorage.removeItem('chosenDashboard')
          this.$router.replace({ ...newRoute })
          if (!isFirstLoad) {
            this.getDashboards(true)
          }
          this.loading = false
        }
      })
    },
    loadView (viewKey) {
      this.newViewSelectorsKey++
      this.addMenu = false
      getViewDetails(viewKey).then((view) => {
        delete view.view_key
        saveView({ ...view, dashboard_key: this.$route.query.dashboard_key }).then((view) => {
          if (view.response) {
            alert(view.response.data.message)
            this.$emit('cancel')
            return
          }
          this.saveView({ view, position: this.positions.length + 1 + 'A' })
        })
      })
    },
    async loadViewData (view, expanded = false, [firstPosition, secondPosition], replace, chosenDashboard) {
      if ((chosenDashboard && chosenDashboard !== this.chosenDashboard) || !Object.keys(view || {}).length) {
        return
      }
      try {
        const callingView = view[0] || view
        const pageFilters = {}
        const propertiesWithDataset = this.analyticsFiltersStore.propertiesWithDataset
        const dashboardFilters = this.dashboardDetails.filters
        for (const key in dashboardFilters) {
          if (propertiesWithDataset[callingView.dataset] && propertiesWithDataset[callingView.dataset][key]) {
            pageFilters[key] = dashboardFilters[key]
          }
        }
        const optionKey = 'properties/article/option_key'
        if (dashboardFilters[optionKey]) {
          pageFilters[optionKey] = dashboardFilters[optionKey]
        }
        if (this.$route.query.option_key) {
          pageFilters[optionKey] = this.$route.query.option_key
        }

        const data = (view.chart_type === 'text_tile' || view.chart_type === 'image_tile' || view.chart_type === 'filters_tile') ? { view, data: [] } : await performViewData(view, expanded, pageFilters)
        if (!this.views[firstPosition]) {
          this.views[firstPosition] = []
        }
        if (data === 'error' || data.errored) {
          if (expanded) {
            return
          }
          this.views[firstPosition][secondPosition] = { view: { ...view, errored: true }, data: [] }
          this.views = [ ...this.views ]
          return
        }
        if (expanded) {
          this.views = [...this.views]
        } else if (firstPosition !== undefined) {
          const firstPositionData = this.views[firstPosition]
          firstPositionData[secondPosition] = data
          this.$set(this.views, firstPosition, firstPositionData)
        } else {
          this.views = [...this.views, data]
        }
      } catch (e) {
        this.views[firstPosition][secondPosition] = { view: { ...view, errored: true }, data: [] }
        this.views = [...this.views]
        this.erroredViews = [...this.erroredViews, [{ ...view }]]
      }
    },
    deleteView (position) {
      const secondPositions = ['A', 'B', 'C', 'D']
      const positionLetter = position[position.length - 1]
      let firstPosition = position.substring(0, position.length - 1)
      firstPosition--
      let secondPosition = secondPositions.indexOf(positionLetter)
      if (secondPosition === -1) {
        secondPosition = 0
      }
      this.dashboardDetails.view_keys = this.dashboardDetails.view_keys.filter(item=> item.some(view=> typeof view === 'number'))
      this.dashboardDetails.view_keys[firstPosition].splice(secondPosition, 1)
      if (!this.dashboardDetails.view_keys[firstPosition].filter(item => typeof item === 'number').length) {
        this.dashboardDetails.view_keys.splice(firstPosition, 1)
      }
      saveDashboard(this.dashboardDetails).then(this.loadViews)
    },
    formatString,
    saveView ({ view, position }) {
      const secondPositions = ['A', 'B', 'C', 'D']
      const positionLetter = position[position.length - 1]
      let firstPosition = position.substring(0, position.length - 1)
      firstPosition--
      let secondPosition = secondPositions.indexOf(positionLetter)
      if (secondPosition === -1) {
        secondPosition = 0
      }
      this.showNewView = false
      if (!this.dashboardDetails.view_keys || !this.dashboardDetails.view_keys.length) {
        this.dashboardDetails.view_keys = [[]]
      }
      if (this.dashboardDetails.view_keys[firstPosition] && !Array.isArray(this.dashboardDetails.view_keys[firstPosition])) {
        this.dashboardDetails.view_keys[firstPosition] = [this.dashboardDetails.view_keys[firstPosition]]
      }
      if ((this.dashboardDetails.view_keys[firstPosition] || [])[secondPosition] === view.view_key) {
        this.loadViewData(view, false, [firstPosition, secondPosition], true).then(() => {
          this.analyticsFiltersStore.setViewsFilters(this.views.map((viewList) => viewList.map(({ view }) => view)))
        })
        return
      }
      if (!this.dashboardDetails.view_keys[firstPosition]) {
        this.dashboardDetails.view_keys[firstPosition] = []
      }
      if (this.dashboardDetails.view_keys[firstPosition][secondPosition]) {
        let [movingFirstPosition, movingSecondPosition] = [firstPosition, secondPosition]
        while (this.dashboardDetails.view_keys[movingFirstPosition][movingSecondPosition]) {
          if (movingSecondPosition >= 3) {
            movingFirstPosition++
            movingSecondPosition = 0
          } else {
            movingSecondPosition++
          }
          if (movingFirstPosition >= this.dashboardDetails.view_keys.length) {
            break
          }
        }
        this.dashboardDetails.view_keys[movingFirstPosition][movingSecondPosition] = this.dashboardDetails.view_keys[firstPosition][secondPosition]
      }
      this.dashboardDetails.view_keys.forEach((viewsList, index) => {
        this.dashboardDetails.view_keys[index] = viewsList.filter((item) => item !== view.view_key)
      })
      this.dashboardDetails.view_keys[firstPosition][secondPosition] = view.view_key
      this.dashboardDetails.view_keys = this.dashboardDetails.view_keys.filter(item => item.length)
      saveDashboard(this.dashboardDetails).then(this.loadViews)
    },
    async getDashboards (isDeleted = false) {
      this.dashboards = []
      this.dashboardForChoose = []
      await getDashboards().then(dashboards => {
        this.dashboards = [...this.dashboards, ...dashboards.map((item) => {
          return {
            ...item,
            personal: true,
          }
        })]
        this.dashboardForChoose.push({ header: 'Personal' })
        this.dashboardForChoose = [...this.dashboardForChoose, ...dashboards.map(item => {
          return {
            text: item.dashboard_name,
            value: item.dashboard_key,
            group: 'Personal'
          }
        })]
      })
      await getSharedDashboards().then(dashboards => {
        this.dashboards = [...this.dashboards, ...dashboards]
        this.dashboardForChoose.push({ header: 'Shared' })
        this.dashboardForChoose = [...this.dashboardForChoose, ...dashboards.map(item => {
          return {
            text: item.dashboard_name,
            value: item.dashboard_key,
            group: 'Shared'
          }
        })]
        this.chosenDashboard = parseInt(this.$route.query.dashboard_key) || null
        if (this.chosenDashboard === null) {
          const id = localStorage.getItem('chosenDashboard')
          this.chosenDashboard = parseInt(id) || null
        }
        if (this.chosenDashboard !== null && !isDeleted) {
          this.loadViews(this.chosenDashboard, true)
        }
      })
    }
  },
  created () {
    this.getDashboards()
    if (this.analyticsFiltersStore.datasetsList.length) {
      this.datasets = this.analyticsFiltersStore.datasetsList
    } else {
      getDatasets().then((data) => {
        this.datasets = data
        this.analyticsFiltersStore.setDatasetsList(data)
      })
    }
  },
}
</script>

<style lang="scss">

.isDialogView {
  height: calc(100vh / var(--zoom));
  padding-right: 20px;
  padding-top: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw / var(--zoom));
  background-color: white;
}
.close-dialog-view {
  z-index: 23;
  position: fixed;
  top: 0;
  right: 22px;
}
.buy_analytics {
  .inModal {
    height: calc(100vh / var(--zoom));
  }
  .show-on-hover {
    visibility: hidden;
  }
  .view-column:hover .show-on-hover {
    visibility: visible;
  }
  .operation-option {
    height: 100px;
    display: inline-block;
    letter-spacing: 1.25px;
    cursor: pointer;

    &.active {
      position: relative;
      color: var(--primary);

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 4px solid var(--primary);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    &.active * {
      color: var(--primary);
    }

    .icon {
      position: absolute;
      right: 50px;
    }
  }
  .analytics-manage_icon:hover {
    color: var(--primary)
  }
}
@media (max-width: 960px){
  .view-column {
    height: unset !important;
  }
  .buy_analytics {
    .show-on-hover {
      visibility: visible;
    }
  }
}
</style>
