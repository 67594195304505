import axios from '@/services/axios'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

export async function GetStrategyCubes () {
  const url = baseURLs.manageAPI + '/strategy_cubes'

  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.data)
    .catch(console.error)
}

export async function GetCubesBrowser () {
  const url = baseURLs.logicAPI + '/cube_browser'

  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(console.error)
}

export async function GetCubeInputData (cube_key, version_key) {
  const url = baseURLs.logicAPI + '/cube_browser'

  return axios.post(url, {
    cube_key: cube_key,
    version_key: version_key
  }, {
    headers: getHeadersWithAccessToken(),
  }).then(response => response.data).catch(console.error)
}

export async function LoadComparison (payload, signal) {
  const url = baseURLs.logicAPI + '/cube_comparison'

  return axios
    .post(url, payload, {
      headers: getHeadersWithAccessToken(),
      signal,
    })
    .then(response => {
      return response.data
    })
}

export async function PerformAction (payload) {
  const url = baseURLs.logicAPI + '/cube_action'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data)
}

export async function GetCubeIntake (payload) {
  const url = baseURLs.logicAPI + '/cube_intake'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data)
}

export async function GetCubeCollection (payload) {
  const url = baseURLs.logicAPI + '/cube_collection'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data)
}

export async function GetCubeSimulation (payload) {
  const url = baseURLs.logicAPI + '/cube_simulation'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data)
}

export async function GetCubeWSSI (payload) {
  const url = baseURLs.logicAPI + '/cube_wssi_2'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data)
}

export async function GetCubeDownloadLink (payload) {
  const url = baseURLs.logicAPI + '/cube_download'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  }).then(response => response.data)
}

export async function UploadDataFile (cubeKey, versionKey, formData) {
  const url = baseURLs.logicAPI + `/cube_upload?cube_key=${cubeKey}&version_key=${versionKey}`

  return axios.post(url, formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
}
