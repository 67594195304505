<template>
  <div class="options-search-results">
    <v-virtual-scroll
      ref="virtualScroll"
      :items="searchResults"
      :item-height="110"
      height="calc(calc(100vh / var(--zoom)) - 235px)"
    >
      <template v-slot:default="{ item }">
        <router-link
          :to="getRoute(item)"
          class="mt-2 search-result-item"
        >
          <v-row class="mx-0 pb-2 align-center">
            <v-col
              :cols="8"
              class="d-flex"
            >
              <img
                alt=""
                :src="item.image_url || '@/assets/img/ts_placeholder.png'"
                height="90px"
                width="60px"
                @error="loadPlaceholder"
              >
              <div v-if="item.name.split(' / ').length === 2" class="ml-6 pt-2 option-name-wrapper">
                <div class="text-body-2 font-weight-medium option-name">{{ item.name.split(" / ")[0] }}</div>
                <p class="text-body-2 text--secondary">{{ item.name.split(" / ")[1] }}</p>
                <p class="mt-2 text-body-2">
                  {{ item.osp | currency }}
                  <span v-if="item.osp !== item.asp"> / {{ item.asp | currency }}</span>
                </p>
              </div>
              <div v-else class="pt-2 option-name-wrapper">
                <div class="text-body-2 font-weight-medium option-name">{{ item.name }}
                </div>
              </div>
            </v-col>
            <v-col :cols="4" class="text-body-2">
              <v-icon class="mr-2">
                local_shipping
              </v-icon>
              {{ item.stock }}
            </v-col>
          </v-row>
        </router-link>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
import ts_placeholder from '@/assets/img/ts_placeholder.png'

export default {
  name: 'OptionsSearchResults',
  props: {
    searchResults: {
      type: Array,
      default: () => [],
    }
  },
  data () {
    return {
      infoMenuOpened: {},
      columns: ['option', 'stock', 'price'],
      columnsLabels: ['Option', 'Stock', 'Price'],
      optionListKey: 0,
    }
  },
  computed: {
    redirectLink () {
      return this.$route.name.startsWith('pricing-')
        ? 'pricing-markdown-page'
        : 'stock-option'
    },
  },
  methods: {
    getRoute (item) {
      return {
        name: this.redirectLink,
        query: {
          option_key: item.option_key,
        },
      }
    },
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
  },
}
</script>
<style lang="scss">
.options-search-results {
  .search-result-item {
    text-decoration: none;
    color: inherit;
    width: 100%;
    /* .row {
      border-bottom: 1px solid var(--border-color);
    } */
    p {
      margin: 0;
    }
    &:hover {
      .option-name {
        color: var(--primary);
      }
    }
  }
  .option-name-wrapper {
    overflow: hidden;
    white-space: nowrap;
    .option-name {
      color: unset;
      text-decoration: none;
    }
  }
}
</style>
