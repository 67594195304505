<template>
  <div>
    <v-overlay absolute color="white" :value="!loaded">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <div v-show="loaded" class="chart-container" style="position: relative; height: 370px;">
      <canvas :id="title"></canvas>
    </div>
  </div>
</template>

<script>
import { Chart } from 'chart.js/auto'
import { format } from 'date-fns'
import axios from '../../services/axios'
import baseURLs from '@/api/APIBaseURLs'

export default {
  props: ['title', 'endpoint', 'params', 'logicBased'],
  data () {
    this.chart = null

    return {
      loaded: false,
    }
  },
  components: {
  },
  methods: {
    drawGraph () {
      this.loaded = false
      const ctx = document.getElementById(this.title).getContext('2d')
      const callUrl = this.logicBased ? baseURLs.logicAPI : baseURLs.manageAPI
      const url = callUrl + (this.logicBased ? '/' : '/data/') + this.endpoint

      axios.post(url, this.params)
        .then(response => {
          const data = response.data.data
          const labels = data.job_date
          const bar1 = data.errors
          const bar2 = data.succes

          const formatedLabels = labels.map(date => `${format(new Date(date), 'dd MMM')}`)
          const chartData = {
            labels: formatedLabels,
            datasets: [
              {
                label: 'Success',
                borderColor: 'rgb(76, 175, 80)',
                backgroundColor: 'rgba(76, 175, 80, 0.2)',
                borderWidth: 2,
                data: bar2,
                xAxisID: 'x',
                yAxisID: 'y'
              },
              {
                label: 'Errors',
                borderColor: '#FF5721',
                backgroundColor: 'rgba(255, 87, 33, 0.2)',
                borderWidth: 2,
                data: bar1,
                xAxisID: 'x',
                stack: 'background',
                yAxisID: 'y'
              },
            ]
          }
          const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'bottom',
                align: 'center',
              },
            },
            scales: {
              x: {
                stacked: true,
                // barThickness: 40,
                title: {
                  display: true,
                  text: '',
                  font: {
                    size: 14,
                  },
                },
                grid: {
                  display: false
                }
              },
              y: {
                stacked: false,
                grid: {
                  display: true
                },
                title: {
                  display: true,
                  text: '',
                  font: {
                    size: 14,
                  },
                },
                min: 0,
                ticks: {
                  font: {
                    size: 14,
                  },
                  padding: 16,
                  callback: function (value) {
                    const ranges = [
                      { divider: 1e6, suffix: 'M' },
                      { divider: 1e3, suffix: 'k' }
                    ]

                    function formatNumber (n) {
                      for (let i = 0; i < ranges.length; i++) {
                        if (n >= ranges[i].divider) {
                          return (n / ranges[i].divider).toString() + ranges[i].suffix
                        }
                      }
                      return n
                    }
                    return formatNumber(value)
                  },
                }
              },
            },
          }

          if (this.chart) {
            this.chart.destroy()
          }

          this.chart = new Chart(ctx, { type: 'bar', data: chartData, options })

          this.$emit('loaded')
          this.loaded = true
        })
        .catch(error => {
          // TODO temporary, i will remove later ?!
          this.loaded = true
          console.log(error)
        })
    }
  },
  mounted () {
    this.drawGraph()
  }
}
</script>
