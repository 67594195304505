<template>
  <div>
    <v-overlay absolute color="white" :value="loadingResults">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <SearchFilters
      :selected-filters="selectedFilters"
      ref="searchFilters"
      class="mr-4"
      @filterAdded="handleFilterAdded"
      @filtersChanged="handleFilterChanged"
      @filterRemoved="handleFilterRemoved"
    />
    <SearchProperties
      compact-layout
      :properties-include="propertiesInclude"
      :properties-exclude="propertiesExclude"
      @propIncludeAdded="handlePropIncludeAdded"
      @propExcludeAdded="handlePropExcludeAdded"
      @propIncludeChanged="handlePropIncludeChanged"
      @propExcludeChanged="handlePropExcludeChanged"
      @propIncludeDeleted="handlePropIncludeDeleted"
      @propExcludeDeleted="handlePropExcludeDeleted"
      @propIncludeValuesChanged="handlePropIncludeValuesChanged"
      @propExcludeValuesChanged="handlePropExcludeValuesChanged"
    />
    <SearchText
      :search-string="searchText"
      @change="handleSearchTextChanged"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { optionsFiltersToFormParameters } from '@/utils'
import { findOptionsByFiltersTSQL } from '@/api/options'
import SearchFilters from '@/components/Operate/SearchFilters.vue'
import SearchProperties from '@/components/OptionSets/SearchProperties.vue'
import SearchText from '@/components/OptionSets/SearchText.vue'

export default {
  name: 'OptionsSearch',
  emits: ['resultsUpdated', 'filtersUpdated'],
  components: {
    SearchFilters,
    SearchProperties,
    SearchText,
  },
  data () {
    return {
      selectedFilters: [],
      propertiesInclude: {},
      propertiesExclude: {},
      searchText: '',
      loadingResults: false,
      searchDebounce: _.debounce(this.searchByFilters, 2000),
    }
  },
  mounted () {
    try{
      this.getFiltersFromLocalStorage()
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    getFiltersFromLocalStorage () {
      const filtersItem = localStorage.getItem('optionsSearchFilters')
      if (!filtersItem) {
        return
      }
      const filters = JSON.parse(filtersItem)

      if (filters) {
        this.selectedFilters = filters.selectedFilters
        this.propertiesInclude = filters.propertiesInclude
        this.propertiesExclude = filters.propertiesExclude
        this.searchText = filters.searchText
      }
    },
    getNonEmptyProperties (properties) {
      const result = Object.assign({}, properties)

      Object.keys(result).forEach(prop => {
        if (!result[prop].length) {
          delete result[prop]
        }
      })

      return result
    },
    searchByFilters () {
      if (!this.$refs.searchFilters.validateFilters()) {
        return
      }

      const payload = {
        ...optionsFiltersToFormParameters(this.selectedFilters),
        properties_include: this.getNonEmptyProperties(this.propertiesInclude),
        properties_exclude: this.getNonEmptyProperties(this.propertiesExclude),
        search_text: this.searchText || null,
      }
      this.loadingResults = true

      return findOptionsByFiltersTSQL(payload)
        .then(results => {
          this.$emit('resultsUpdated', results.options)
          this.$emit('filtersUpdated', {
            selectedFilters: this.selectedFilters,
            propertiesInclude: this.propertiesInclude,
            propertiesExclude: this.propertiesExclude,
            searchText: this.searchText,
          })
        })
        .finally(() => {
          this.loadingResults = false
        })
    },
    handleFilterAdded (newFilter) {
      this.selectedFilters = this.selectedFilters.concat([newFilter])
      this.searchDebounce()
    },
    handleFilterChanged () {
      this.searchDebounce()
    },
    handleFilterRemoved (removedFilter) {
      this.selectedFilters = this.selectedFilters.filter(f => f.type !== removedFilter.type)
      this.searchDebounce()
    },
    handlePropIncludeAdded (prop) {
      this.propertiesInclude = Object.assign(
        {},
        this.propertiesInclude,
        { [prop]: [] },
      )
      this.searchDebounce()
    },
    handlePropExcludeAdded (prop) {
      this.propertiesExclude = Object.assign(
        {},
        this.propertiesExclude,
        { [prop]: [] },
      )
      this.searchDebounce()
    },
    handlePropIncludeChanged ({ oldProp, newProp }) {
      const newPropsInclude = Object.assign(
        {},
        this.propertiesInclude,
        { [newProp]: [] },
      )

      delete newPropsInclude[oldProp]

      this.propertiesInclude = newPropsInclude
      this.searchDebounce()
    },
    handlePropExcludeChanged ({ oldProp, newProp }) {
      const newPropsExclude = Object.assign(
        {},
        this.propertiesExclude,
        { [newProp]: [] },
      )

      delete newPropsExclude[oldProp]

      this.propertiesExclude = newPropsExclude
      this.searchDebounce()
    },
    handlePropIncludeDeleted (prop) {
      const newVal = Object.assign({}, this.propertiesInclude)

      delete newVal[prop]

      this.propertiesInclude = newVal
      this.searchDebounce()
    },
    handlePropExcludeDeleted (prop) {
      const newVal = Object.assign({}, this.propertiesExclude)

      delete newVal[prop]

      this.propertiesExclude = newVal
      this.searchDebounce()
    },
    handlePropIncludeValuesChanged ({ prop, value }) {
      this.propertiesInclude[prop] = value
      this.searchDebounce()
    },
    handlePropExcludeValuesChanged ({ prop, value }) {
      this.propertiesExclude[prop] = value
      this.searchDebounce()
    },
    handleSearchTextChanged (text) {
      this.searchText = text
      this.searchDebounce()
    },
  }
}
</script>
