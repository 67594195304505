<template>
  <div class="option-data-cell--general">
    <v-menu
      open-on-hover
      offset-x
      :disabled="!enlargeImageOnHover"
    >
      <template v-slot:activator="{ on }">
        <img
          class="mr-6"
          width="33px"
          v-on="on"
          :src="option.image_url || '@/assets/img/ts_placeholder.png'"
          @error="loadPlaceholder"
        />
      </template>
      <v-card class="pa-2">
        <img
          max-height="220"
          max-width="132"
          :src="option.image_url"
          @error="loadPlaceholder"
        />
        {{ option.name }}
      </v-card>
    </v-menu>

    <div class="option">
      <router-link
        class="option-name"
        :target="openInNewTab ? '_blank' : null"
        :to="getOptionUrl(option)"
      >
        <p class="option-title">{{ title }}</p>
        <p v-if="hasSubtitle" class="option-subtitle">
          {{ subtitle }}
        </p>
      </router-link>
    </div>
  </div>
</template>

<script>
import ts_placeholder from '@/assets/img/ts_placeholder.png'

export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    nameKey: {
      type: String,
      default: 'option',
    },
    url: {
      type: [Object, String],
      default: null,
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    useRoute: {
      type: Boolean,
      default: false,
    },
    enlargeImageOnHover: {
      type: Boolean,
      default: false,
    },
    useTsPlaceholderImage: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    nameParts () {
      return this.option[this.nameKey] ? this.option[this.nameKey].split(' / ') : null
    },
    hasSubtitle () {
      return this.nameParts?.length > 1
    },
    title () {
      if (!this.nameParts) {
        return ''
      }
      return this.nameParts[0]
    },
    subtitle () {
      if (!this.hasSubtitle) {
        return ''
      }
      return this.nameParts[1]
    },
  },
  methods: {
    getOptionUrl (row) {
      return this.url ? this.url : {
        name: 'stock-option',
        query: {
          'option_key': row.option_key,
          'inbound_key': row.inbound_key,
        }
      }
    },
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
  }
}
</script>

<style lang="scss">
    .option-data-cell--general {
        display: flex;
        flex-direction: row;
        font-size: 13px !important;
        align-items: center;
        letter-spacing: 0.2px;
        p {
            margin: 0;
        }
        .option-name {
            color: unset !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-decoration: none;
            .option-title {
                font-weight: 500;
                &:hover {
                    color: var(--primary);
                    cursor: pointer;
                }
            }
            .option-subtitle {
                color: var(--text-sub-black) !important;
                font-size: 12px;
            }
        }
    }
</style>
