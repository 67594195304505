<template>
  <resizable
    class="transferTable p-relative"
    @resize="updateTotalTableWidth"
  >
    <v-overlay absolute color="white"
      :value="stockTransfersStore.buildingTransfers || stockTransfersStore.acceptingAllTransfers || acceptingTransfer">
      <v-progress-circular color="primary" indeterminate size="64" width="2"/>
    </v-overlay>
    <v-row class="pt-5 pb-3 justify-space-between">
      <v-col cols="2" class="py-0 pa-4 pl-7">
        <v-select
          class="pt-0"
          hide-details
          v-model="activeType"
          @input="$emit('type-change', $event)"
          :items="['Combinations', 'Send', 'Receive']"
        ></v-select>
      </v-col>
      <v-col class="text-right pr-6">
        <TableSearch class="mx-2" @searchChanged="handleSearchChanged"/>
        <DataExport
          @item-click="downloadData"
        />
      </v-col>
    </v-row>
    <data-tables
      ref="transfersTable"
      :key="transfersListKey"
      :data="tableData"
      :page-size="5"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 mb-4 mr-2',
      }"
      @sort-change="expandedRow = null"

      :filters="transfersTableFilters"
      @expand-change="handleExpandChange"
    >
      <data-tables
        slot="append"
        :index="null"
        class="total-table"
        :data="[totalsData]"
        :pagination-props="{class: 'd-none'}"
        :key="totalsExcludeKey"
      >
        <el-table-column label-class-name="d-none" class-name="total-row_column" width="47"/>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
          width="260"
        >
          <template>
            <div>Total</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.pieces | formatThousands }}</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template>
            <v-checkbox
              hide-details
              class="pa-0 mt-0"
              :disabled="!!expandedRow" v-model="totalsExclude"></v-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
          width="150"
        >
          <template>
            <v-btn
              color="primary"
              depressed
              outlined
              :disabled="!!expandedRow || tableData?.some(item=> item.accepted)"
              width="130"
              @click="handleAcceptAllClick"
            >Accept all
            </v-btn>
          </template>
        </el-table-column>
      </data-tables>
      <el-table-column width="45" class="expand-column">
        <template slot-scope="scope">
          <v-icon @click="handleExpandClick(scope.row)" class="my-2">
            <template v-if="expandedRow !== scope.row">
              chevron_right
            </template>
            <template v-else>
              expand_more
            </template>
          </v-icon>
        </template>
      </el-table-column>
      <el-table-column type="expand" width="1">
        <template slot-scope="props">
          <!-- Using slot-scope here to prevent double render of TransfersBuildDetails component.
               Not sure why this happens -->
          <TransfersBuildDetails
            :type="activeType"
            :row="props.row" ref="transfersDetails" @error-added="detailsErrors = {...$event}" @transfers-changed="detailsChanged= true" />
        </template>
      </el-table-column>
      <el-table-column label="Store" sortable="custom" prop="store_key" width="130">
        <template slot-scope="scope">
          <div class="one-line" v-if="findStore(scope.row.store_key)">
            {{ findStore(scope.row.store_key).store_name }}
          </div>
          <span v-else>{{ scope.row.store_key }}</span>
        </template>
      </el-table-column>
      <el-table-column label="To Store" sortable="custom" prop="to_store_key" width="130">
        <template slot-scope="scope">
          <div class="one-line" v-if="findStore(scope.row.to_store_key)">
            {{ findStore(scope.row.to_store_key).store_name }}
          </div>
          <span v-else>{{ scope.row.to_store_key }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Stock" sortable="custom" prop="stock">
        <template slot-scope="scope">
          {{ scope.row.stock | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="To stock" sortable="custom" prop="to_stock">
        <template slot-scope="scope">
          {{ scope.row.to_stock | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="Fairshare" sortable="custom" prop="fairshare">
        <template slot-scope="scope">
          {{ scope.row.fairshare | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="To fairshare" sortable="custom" prop="to_fairshare">
        <template slot-scope="scope">
          {{ scope.row.to_fairshare | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="Transfer" sortable="custom" prop="pieces">
        <template slot-scope="scope">
          {{ scope.row.pieces | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="Exclude store" sortable="custom" prop="pieces">
        <template slot-scope="scope">
          <v-checkbox
            hide-details
            class="pa-0 mt-0"
            @change="totalsExcludeKey ++"
            :disabled="expandedRow === scope.row" v-model="scope.row.store_exclusion"></v-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="" prop="" width="150">
        <template slot-scope="scope">
          <v-btn
            color="primary"
            depressed
            :disabled="!scope.row.pieces
              || !!scope.row.accepted
              || (expandedRow && expandedRow !== scope.row)
              || !!Object.keys(detailsErrors).length"
            outlined
            width="130"
            @click="handleAcceptClick(scope.row)"
          >
            <span v-if="scope.row.accepted">Accepted</span>
            <span v-else>Accept</span></v-btn>
        </template>
      </el-table-column>
    </data-tables>

    <!-- Close confirm dialog -->
    <v-dialog
      :value="closeDialogVisible"
      persistent
      content-class="align-self-start mt-10"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Close transfer
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">You will lose any changes made in this transfer. Continue?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleCloseCancel"
              >Cancel
              </v-btn>
              <v-btn
                depressed
                outlined
                @click="handleCloseConfirm"
                class="red--text mx-4"
              >Ok
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </resizable>
</template>
<script>
import TableSearch from '@/components/TableSearch.vue'
import TransfersBuildDetails from '@/components/Transfers/TransfersBuildDetails.vue'
import { downloadData } from '@/utils'
import DataExport from '../DataExport'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'
import Resizable from '@/components/Utility/Resizable.vue'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore(),
      stockTransfersStore: useStockTransfersStore(),
    }
  },
  props: {
    activeType: {
      type: String,
      default (){
        return 'Combinations'
      }
    }
  },
  components: {
    Resizable,
    DataExport,
    TableSearch,
    TransfersBuildDetails,
  },
  data () {
    return {
      detailsErrors: {},
      loaded: false,
      detailsChanged: false,
      acceptingTransfer: false,
      transfersListKey: 0,
      totalsExcludeKey: 0,
      transfersTableFilters: [
        {
          value: ''
        }
      ],
      expandedRow: null,
      rowExpandClicked: null,
      closeDialogVisible: false,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['findStoreWithKey']),
    tableData () {
      return this.stockTransfersStore.transferTablesData.build.data
    },
    totalsExclude: {
      get () {
        return this.tableData?.every(item => item.store_exclusion)
      },
      set (value) {
        this.tableData.forEach(item => this.$set(item, 'store_exclusion', value))
      }
    },
    totalsData () {
      return this.stockTransfersStore.transferTablesData.build.total
    },
    buildFormData () {
      return this.stockTransfersStore.transfersBuildForm
    }
  },
  watch: {
    expandedRow () {
      this.totalsExcludeKey++
    },
    'stockTransfersStore.detailsRowExpanded.accepted' (newVal) {
      if (newVal) {
        // close row if there is opened one
        if (this.expandedRow) {
          this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
          this.expandedRow = null
        }
      }
    },
    'stockTransfersStore.buildingTransfers' (newVal) {
      if (newVal) {
        // close row if there is opened one
        if (this.expandedRow) {
          this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
          this.expandedRow = null
        }
      }
    },
  },
  methods: {
    async getTransfersForBuild (row) {
      if (this.expandedRow === row && this.$refs.transfersDetails?.currentPageRows) {
        return this.$refs.transfersDetails.getTransfersForAccept()
      }
      const params = {
        store_keys: this.buildFormData.fromStores,
        to_store_keys: this.buildFormData.toStores,
        method: this.buildFormData.method,
        to_method: this.buildFormData.to_method,
        to_delta: this.buildFormData.to_delta,
        delta: this.buildFormData.delta,
        split: this.buildFormData.split,
        child_store_key: row.store_key,
        child_to_store_key: row.to_store_key,
        filters: {},
        view: this.activeType.toLowerCase()
      }
      if (row.store_exclusion) {
        params.store_exclusion = true
      }
      const filters = {}
      const pageFilters = this.filtersStore.getPageFiltersNonDefaultValues()
      filters['option_key'] = pageFilters['options']
      params.filters = filters
      // load data for opened row
      return this.stockTransfersStore.getTransferDetailsTSQL(params)
    },
    downloadData (sep) {
      const data = this.tableData
      downloadData(sep, data, 'Transfers_Build')
    },
    async handleAcceptClick (row) {
      row.accepted = true
      const rows = await this.getTransfersForBuild(row)
      const payload = []
      rows.forEach(item => {
        if (item.pieces <= 0) {
          return
        }

        const newItem = {
          to_store_key: item.to_store_key,
          store_key: item.store_key,
          sku_key: item.sku_key,
          pieces: item.pieces,
          stock: item.stock,
          keep: (item.stock - item.pieces),
          type: 'build',
          profit: 0,
          store_exclusion: item.store_exclusion,
          option_key: item.option_key,
        }
        payload.push(newItem)
      })

      this.acceptingTransfer = true
      this.stockTransfersStore.acceptTransfer(payload)
        .then(() => {
          // this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
          this.expandedRow = null
          this.acceptingTransfer = false
        })
    },
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = document.querySelector('.el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 100)
    },
    findStore (storeKey) {
      return this.findStoreWithKey(storeKey)
    },
    handleExpandChange (row) {
      const params = {
        store_keys: this.buildFormData.fromStores,
        to_store_keys: this.buildFormData.toStores,
        method: this.buildFormData.method,

        to_method: this.buildFormData.to_method,
        to_delta: this.buildFormData.to_delta,
        delta: this.buildFormData.delta,
        split: this.buildFormData.split,
        child_store_key: row.store_key,
        child_to_store_key: row.to_store_key,
        filters: {},
      }
      const filters = {}
      const pageFilters = this.filtersStore.getPageFiltersNonDefaultValues()
      filters['option_key'] = pageFilters['options']

      params.filters = filters

      // close row if there is opened one
      if (this.expandedRow) {
        this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
        this.stockTransfersStore.setTransferBuildDetailsData([])
      }

      // save opended row in component and state
      this.expandedRow = this.expandedRow ? null : row
      this.stockTransfersStore.setDetailsRowExpanded('build', !!this.expandedRow)

      // load data for opened row
      if (this.expandedRow) {
        this.stockTransfersStore.getTransferDetailsTSQL(params)
      }
    },
    handleSearchChanged (val) {
      if (this.expandedRow) {
        this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
        this.expandedRow = null
      }
      this.transfersTableFilters[0].value = val
      this.transfersListKey++
      this.updateTotalTableWidth()
    },
    handleExpandClick (row) {
      this.detailsErrors = {}
      if (this.expandedRow) {
        this.rowExpandClicked = row

        if(this.detailsChanged) {
          this.closeDialogVisible = true
        }else {
          this.handleCloseConfirm()
        }
      } else {
        this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(row, row !== this.expandedRow)
      }
    },
    handleCloseCancel () {
      this.closeDialogVisible = false
      this.rowExpandClicked = null
    },
    handleCloseConfirm () {
      this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.rowExpandClicked, this.rowExpandClicked !== this.expandedRow)
      this.closeDialogVisible = false
      this.rowExpandClicked = null
      this.detailsChanged = false
    },
    handleAcceptAllClick () {
      this.tableData.forEach(item => {
        this.$set(item, 'accepted', true)
      })
      this.stockTransfersStore.acceptAllBuiltTransfers(true, this.totalsExclude)
    },
  },
  beforeDestroy () {
    // cleanup opened row, because table will be recreated
    if (!this.stockTransfersStore.detailsRowExpanded.accepted) {
      this.stockTransfersStore.setTransferDetailsData([])
      this.stockTransfersStore.setDetailsRowExpanded('build', false)
    }
  },
}
</script>

<style lang="scss">
.transferTable {
  .total-row_column {
    background-color: #f5f7fa;
  }

  .el-table__expand-icon {
    display: none;
  }
  .el-table td {
    padding: 0;
  }
  .el-table .cell {
    padding: 4px 10px ;
  }

  .el-table__expanded-cell[class*=cell] {
    padding: 16px;
    background: #f9f9f9;

    &:hover {
      background: #f9f9f9 !important;
    }
  }

  .one-line {
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
