<template>
  <div class="operate-options-file-search">
    <template v-if="!file">
      <h6 class="text-subtitle-1 font-weight-medium mb-4 ml-6">1. Import</h6>
      <div class="mx-6 overflow-hidden">
        <div class="p-relative file-drop-zone" @dragover="dragover" @dragleave="dragleave" @drop="drop">
          <input
            type="file"
            id="assetsFieldHandle"
            @change="onFileChange"
            ref="file"
            accept=".csv"
          />
          <label for="assetsFieldHandle">
            <span class="material-icons icon">system_update_alt</span>
            <span>Choose a file or drag it here</span>
          </label>
        </div>
      </div>
    </template>
    <template v-if="file">
      <h6 class="text-subtitle-1 font-weight-medium ml-6">1. File</h6>
      <div class="d-flex align-center ml-6 mt-2 mb-4">
        <span class="text-caption font-weight-medium grey--text text--darken-2">{{ file.name }}</span>
        <v-icon class="ml-2 action-btn-danger" @click="removeFile">delete</v-icon>
      </div>
      <div class="rounded elevation-2 mx-6">
        <data-tables
          style="width: 100%"
          :data="parsedResults.slice(0, rowsNum)"
          :pagination-props="{class: 'd-none'}"
        >
          <el-table-column v-for="(col, i) in columns.slice(0, 5)" :key="i" :prop="col" :label="col">
            <template v-slot:header="{ column }">
              <div class="ml-3">
                {{ formatColumns(column.label) }}
              </div>
            </template>
            <template slot-scope="scope">
              <div class="ml-3">
                {{ scope.row[col] }}
              </div>
            </template>
          </el-table-column>
        </data-tables>
        <div class="d-flex justify-space-between pb-6 pr-6 mt-3">
          <div class="ml-6 grey--text text--darken-2 text-caption font-weight-medium" :class="{ 'error--text': importError }">
            <template v-if="importError">
              {{ importError }}
            </template>
            <template v-else>
              No import error
            </template>
          </div>
          <div class="grey--text text--darken-2 text-caption font-weight-medium">
            <div>Showing first {{ rowsNum }} rows and columns</div>
            <div>Total rows: {{ parsedResults.length }}</div>
          </div>
        </div>
      </div>

    </template>
  </div>
</template>

<script>
import * as Papa from 'papaparse'
import { formatString } from '@/variables'

import { useStockOperateStore } from '@/store/pinia/stockOperateStore'

export default {
  setup () {
    return {
      operateStore: useStockOperateStore()
    }
  },
  data () {
    return {
      rowsNum: 5,
      colsNum: 5,
      file: null,
      importError: null,
      chosenOption: '',
      chosenColumn: '',
      applyMessage: '',
    }
  },
  computed: {
    parsedResults: {
      get () {
        return this.operateStore.fileTab.parsedResults
      },
      set (value) {
        this.operateStore.setFileTabParsedResults(value)
      }
    },
    columns () {
      return this.operateStore.getFileTabParsedResultsColumns
    }
  },
  methods: {
    dragover (event) {
      event.preventDefault()
      event.currentTarget.classList.add('hover')
    },
    formatColumns (item) {
      return formatString(item)
    },
    dragleave (event) {
      event.currentTarget.classList.remove('hover')
    },
    drop (event) {
      event.preventDefault()
      event.currentTarget.classList.remove('hover')

      this.$refs.file.files = event.dataTransfer.files
      this.onFileChange()
    },
    onFileChange () {
      this.file = this.$refs.file.files[0]
      this.importError = null
      this.parsedResults = []

      const ext = this.file.name.split('.').pop().toLowerCase()

      if (ext !== 'csv') {
        this.importError = 'Required format is CSV'
      } else {
        Papa.parse(this.file, {
          header: true,
          // remove empty lines
          skipEmptyLines: true,
          complete: results => {
            this.parsedResults = results.data

            if (!results.data.length) {
              this.importError = 'No rows found'
            } else {
              const keys = this.parsedResults
              this.operateStore.setSelectedOptionKeys(keys)
            }
          }
        })
      }
    },
    removeFile () {
      this.file = null
      this.parsedResults = []
      this.operateStore.setSelectedOptionKeys([])
      this.operateStore.setShowSearchResults('file', false)
    },
  }
}
</script>

<style lang="scss">
.operate-options-file-search {
    .file-drop-zone {
        input {
            position: absolute;
            opacity: 0;
        }
        label {
            border: 5px dashed #ccc;
            height: 250px;
            padding-top: 30px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .icon {
                font-size: 48px;
                margin-bottom: 20px;
            }
        }
        &:hover,
        &.hover {
            label {
                background: rgba(0, 122, 255, 0.06);
            }
        }
    }
}
</style>
