<template>
  <div>
    <div class="d-flex justify-space-between py-6">
      <h3>Overview</h3>
      <h3>{{storeName}}</h3>
    </div>
    <StorePageStoreTable
      :colored-rows="coloredRows"
      :cellWidths="cellWidths"
      @data-loaded="(name)=> storeName = name"
    ></StorePageStoreTable>
    <StorePageGroupsTable
      :colored-rows="coloredRows"
      :cellWidths="cellWidths"
    ></StorePageGroupsTable>
    <StorePageOptionsTable
      :colored-rows="coloredRows"
      :cellWidths="cellWidths"
    ></StorePageOptionsTable>
  </div>
</template>

<script>
import StorePageStoreTable from './StorePageStoreTable'
import StorePageGroupsTable from './StorePageGroupsTable'
import StorePageOptionsTable from './StorePageOptionsTable'
export default {
  name: 'StorePageOverview',
  components: { StorePageOptionsTable, StorePageGroupsTable, StorePageStoreTable },
  data () {
    return {
      storeName: '',
      coloredRows: ['cover', 'projection_cover'],
      cellWidths: ['120', '50', '80', '70', '75', '90', '90'],
    }
  },
  created () {
    if (this.$auth.isAccessTokenValid()) {
      // User is logged in, remove token from LS if exists
      if (localStorage.getItem('storepage_token')) {
        localStorage.removeItem('storepage_token')
      }
    }

    // If the token is explicitly present in the route, then we save it.
    if (Object.keys(this.$route.query).includes('token')) {
      localStorage.setItem('storepage_token', this.$route.query.token)
    }
  },
}
</script>
