<template>
  <div class="option-set-form">
    <template v-if="optionSetForm && editMode">
      <v-container fluid class="pl-10 pr-8">
        <div class="text-body-1 font-weight-medium pt-2">General</div>
        <div style="width: 475px">
          <v-row class="align-baseline mt-4 mb-0">
            <v-col cols="10" class="py-0 pr-9">
              <v-text-field
                v-model="optionSetForm.set_name"
                :error-messages="optionSetNameError"
                label="Set name"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-8 mb-0 ">
            <v-col cols="10" class="py-0 pr-9">
              <collapsible-select
                label="Labels"
                v-model="optionSetForm.label"
                :items="labelsWithModule"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline my-4">
            <v-col cols="3" class="py-0">
              <label>Static set</label>
            </v-col>
            <v-col class="py-0">
              <v-checkbox v-model="optionSetForm.static_set" @change="handleStaticSetChange" hide-details="auto" />
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col>
            <SearchFilters
              :selected-filters="optionSetFilters"
              :static-set="optionSetForm.static_set"
              :options-keys-loading="optionsKeysFilterLoading"
              ref="searchFilters"
              class="ml-n6"
              @filterAdded="handleFilterAdded"
              @filterRemoved="handleFilterRemoved"
              @filtersChanged="handleCriteriaChanged"
            />
          </v-col>
        </v-row>
        <template v-if="!optionSetForm.static_set">
          <v-row>
            <v-col>
              <SearchProperties
                ref="searchProperties"
                class="ml-n6"
                :properties-include="optionSetPropsInclude"
                :properties-exclude="optionSetPropsExclude"
                @propIncludeAdded="handlePropIncludeAdded"
                @propExcludeAdded="handlePropExcludeAdded"
                @propIncludeChanged="handlePropIncludeChanged"
                @propExcludeChanged="handlePropExcludeChanged"
                @propIncludeDeleted="handlePropIncludeDeleted"
                @propExcludeDeleted="handlePropExcludeDeleted"
                @propIncludeValuesChanged="handlePropIncludeValuesChanged"
                @propExcludeValuesChanged="handlePropExcludeValuesChanged"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <SearchText
                :search-string="optionSetSearchText"
                class="ml-n6"
                @change="handleSearchTextChanged"
              />
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col class="text-right">
            <v-btn
              color="primary"
              depressed
              outlined
              @click="handleSearchClick"
            >Search</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-divider v-if="editMode && showOptionsPreview" class="my-8" />
    <template v-if="showOptionsPreview">
      <div>
        <div class="px-10 py-3 d-flex justify-space-between">
          <h6 class="text-body-1 font-weight-bold mb-4">Results</h6>
          <TableSearch class="mx-2" @searchChanged="handleResultsSearchChanged" />
        </div>
        <div class="p-relative">
          <v-overlay absolute color="white" :value="stockManageStore.loadingOptionsResults">
            <v-progress-circular color="primary" indeterminate size="64" width="2" />
          </v-overlay>

          <data-tables
            style="width: 100%"
            class="search-results"
            :key="resultsListKey"
            :filters="resultsTableFilters"
            :data="optionsPreview"
            :page-size="5"
            :pagination-props="{
              pageSizes: [5, 10,15, 25, 50],
              class: 'el-pagination text-right mt-6 mb-4 mr-2',
            }">
            <el-table-column
              v-for="col in ['option', 'stock','dc_stock','inbounds', 'price', 'active']"
              sortable="custom"
              :key="col"
              :prop="col"
              :label="col | formatString"
              :label-class-name="col === 'option' ? 'ml-8' : ''"
              :width="col === 'option' ? '320' : null">
              <template slot-scope="scope">
                <template v-if="col === 'option' && scope.row['option'] !== null">
                  <OptionDataCell
                    :option="scope.row"
                    open-in-new-tab
                    name-key="name"
                    class="ml-8"
                  />
                </template>
                <template v-else-if="col === 'active'">
                  {{ scope.row[col] ? 'Yes' : 'No' }}
                </template>
                <template v-else>{{ scope.row[col] }}</template>
              </template>
            </el-table-column>
          </data-tables>
        </div>
      </div>
    </template>

    <KeepSelectionDialog
      :visible="keepSelectionDialogVisible"
      @discard-click="handleDiscardSelectionConfirm"
      @add-click="handleAddSelectionConfirm"
    />
  </div>
</template>

<script>
import { debounce, isNumber } from 'lodash'
import {
  optionsFiltersToFormParameters,
  optionSetParametersToFiltersDynamic,
  optionSetParametersToFiltersStatic,
} from '@/utils'
import OptionDataCell from '@/components/OptionDataCell.vue'
import SearchFilters from '@/components/OptionSets/SearchFilters.vue'
import SearchProperties from '@/components/OptionSets/SearchProperties.vue'
import SearchText from '@/components/OptionSets/SearchText.vue'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import KeepSelectionDialog from '@/components/Assortment/KeepSelectionDialog.vue'
import TableSearch from '@/components/TableSearch.vue'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { mapState } from 'pinia'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'

import { useStockManageStore } from '@/store/pinia/stockManageStore'
export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  components: {
    OptionDataCell,
    CollapsibleSelect,
    SearchFilters,
    SearchText,
    SearchProperties,
    KeepSelectionDialog,
    TableSearch,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: String,
      default: 'sets',
    },
    optionSet: {
      type: Object || null,
      default: null,
    },
  },
  data () {
    this.searchDebounce = debounce(this.searchOptions, 2000)

    return {
      optionSetForm: null,
      optionSetNameError: null,
      optionSetFilters: [],
      optionSetPropsInclude: {},
      optionSetPropsExclude: {},
      optionSetSearchText: '',
      keepSelectionDialogVisible: false,
      resultsTableFilters: [
        {
          value: ''
        }
      ],
      showOptionsPreview: false,
      optionsPreview: [],
      resultsListKey: 0,
      optionsKeysFilterLoading: false,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    ...mapState(useStoreAndOptionSetsStore, ['optionSets']),
    labelsWithModule (){
      return ['all', 'stock', 'pricing']
    },
  },
  watch: {
    optionSet (val) {
      if (val) {
        this.handleOptionSetChange()
      }
    },
  },
  methods: {
    validateOptionSet () {
      this.optionSetNameError = null

      const usedNames = this.optionSets
        .filter(x => x.options_set_key !== this.optionSetForm.options_set_key)
        .map(x => x.set_name)

      if (this.optionSetForm.set_name === '' || usedNames.includes(this.optionSetForm.set_name)) {
        this.optionSetNameError = 'Should be unique and not empty'
      }

      return !this.optionSetNameError
    },
    getSavePayload () {
      return {
        ...this.optionSetForm,
        label: this.optionSetForm.label.length ? this.optionSetForm.label : ['all'],
        ...this.mapFiltersForAPI(optionsFiltersToFormParameters(this.optionSetFilters)),
        ...{
          search_string: this.optionSetSearchText,
          properties_include: this.optionSetPropsInclude,
          properties_exclude: this.optionSetPropsExclude,
        },
      }
    },
    // temp workaround until API filters are consistent
    mapFiltersForAPI (filters) {
      return {
        ...filters,
        price_min: filters.asp_min,
        price_max: filters.asp_max,
        original_price_min: filters.osp_min,
        original_price_max: filters.osp_max,
        discount_min: isNumber(filters.discount_min) ? filters.discount_min * 100 : null,
        discount_max: isNumber(filters.discount_max) ? filters.discount_max * 100 : null,
      }
    },
    // temp workaround until API filters are consistent
    mapFiltersFromAPI (filters) {
      return filters.map(f => {
        if (f.type === 'price') {
          f.type = 'asp'
        }

        if (f.type === 'original_price') {
          f.type = 'osp'
        }

        return f
      })
    },
    searchOptions () {
      this.stockManageStore.setLoadingOptionsResults(true)
      this.stockManageStore.getOptionsPreviewByCriteria({
        filters: optionsFiltersToFormParameters(this.optionSetFilters),
        propertiesInclude: this.optionSetPropsInclude,
        propertiesExclude: this.optionSetPropsExclude,
        searchText: this.optionSetSearchText || null,
        // options_set: this.stockManageStore[`${this.tab}Tab`].editedOptionSet
      })
        .then(response => {
          this.optionsPreview = response.options.map(item => ({
            ...item,
            price: this.$auth.getUserCurrency().symbol + ((item.osp > item.asp) ? `${item.osp.toFixed(2)} / ${this.$auth.getUserCurrency().symbol + item.asp.toFixed(2)}` : item.osp.toFixed(2))
          }))
        })
        .finally(() => {
          this.stockManageStore.setLoadingOptionsResults(false)
        })

      this.showOptionsPreview = true
    },
    resetForm () {
      this.showOptionsPreview = false
      this.optionSetForm = null
      this.optionSetNameError = null
      this.optionSetFilters = []
      this.optionSetSearchText = ''
      this.optionSetPropsInclude = {}
      this.optionSetPropsExclude = {}
    },
    handleOptionSetChange () {
      const { options_set_key, set_name, static_set, label, ...criteria } = this.optionSet
      const { org_key, properties_include, properties_exclude, search_string, ...filtersParams } = criteria
      const filters = static_set ? optionSetParametersToFiltersStatic(filtersParams) : this.mapFiltersFromAPI(optionSetParametersToFiltersDynamic(filtersParams))

      this.optionSetFilters = []
      filters.forEach(filter => this.optionSetFilters.push(filter))

      this.optionSetForm = { options_set_key, set_name, static_set, label }
      this.optionSetSearchText = search_string
      this.optionSetPropsInclude = properties_include
      this.optionSetPropsExclude = properties_exclude
      this.searchOptions()
    },
    handleCriteriaChanged () {
      this.$refs.searchFilters.validateFilters()
      this.searchDebounce()
    },
    handleFilterAdded (filter) {
      this.optionSetFilters.push(filter)
      this.searchDebounce()
    },
    handleFilterRemoved (filter) {
      if (filter.type === 'option_keys_include') {
        filter.values = []
      } else {
        this.optionSetFilters.splice(this.optionSetFilters.indexOf(filter), 1)
      }

      this.handleCriteriaChanged()
    },
    handlePropIncludeAdded (prop) {
      this.$set(this.optionSetPropsInclude, prop, [])
    },
    handlePropExcludeAdded (prop) {
      this.$set(this.optionSetPropsExclude, prop, [])
    },
    handlePropIncludeChanged ({ oldProp, newProp }) {
      this.$delete(this.optionSetPropsInclude, oldProp)
      this.$set(this.optionSetPropsInclude, newProp, [])
    },
    handlePropExcludeChanged ({ oldProp, newProp }) {
      this.$delete(this.optionSetPropsExclude, oldProp)
      this.$set(this.optionSetPropsExclude, newProp, [])
    },
    handlePropIncludeDeleted (prop) {
      this.$delete(this.optionSetPropsInclude, prop)
      this.handleCriteriaChanged()
    },
    handlePropExcludeDeleted (prop) {
      this.$delete(this.optionSetPropsExclude, prop)
      this.handleCriteriaChanged()
    },
    handlePropIncludeValuesChanged ({ prop, value }) {
      this.$set(this.optionSetPropsInclude, prop, value)
      this.$refs.searchProperties.$forceUpdate()
      this.handleCriteriaChanged()
    },
    handlePropExcludeValuesChanged ({ prop, value }) {
      this.$set(this.optionSetPropsExclude, prop, value)
      this.$refs.searchProperties.$forceUpdate()
      this.handleCriteriaChanged()
    },
    handleSearchTextChanged (text) {
      this.optionSetSearchText = text
      this.handleCriteriaChanged()
    },
    handleAddSelectionConfirm () {
      // perform search to get fresh results
      this.optionSetFilters = [{
        type: 'option_keys_include',
        values: this.optionsPreview.map(x => x.option_key),
        errors: [],
      }]
      this.optionsKeysFilterLoading = true
      this.stockManageStore.setLoadingOptionsResults(true)

      this.stockManageStore.getOptionsPreviewByCriteria({
        filters: optionsFiltersToFormParameters(this.optionSetFilters),
        propertiesInclude: this.optionSetPropsInclude,
        propertiesExclude: this.optionSetPropsExclude,
        searchText: this.optionSetSearchText || null,
      })
        .then(response => {
          this.optionsPreview = response.options
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.optionsKeysFilterLoading = false
          this.showOptionsPreview = true
          this.stockManageStore.setLoadingOptionsResults(false)
        })

      this.keepSelectionDialogVisible = false
    },
    handleDiscardSelectionConfirm () {
      // this.optionSetFilters = []
      this.optionsPreview = []
      this.keepSelectionDialogVisible = false
      this.searchDebounce()
    },
    handleStaticSetChange () {
      // show add selection confirmation if going from dynamic to static set
      if (this.optionSetForm.static_set) {
        this.keepSelectionDialogVisible = true
        this.optionSetFilters = [{
          type: 'option_keys_include',
          values: [],
          errors: [],
        }]
      } else {
        this.optionSetFilters = []
      }

      this.optionSetPropsInclude = {}
      this.optionSetPropsExclude = {}
      this.optionSetSearchText = ''

      if (!this.keepSelectionDialogVisible) {
        this.searchDebounce()
      }
    },
    handleResultsSearchChanged (val) {
      this.resultsTableFilters[0].value = val
      this.resultsListKey++
    },
    handleSearchClick () {
      this.searchOptions()
    },
  },
}
</script>
