import {defineStore} from 'pinia'
import * as api from '@/api/rulesAPI'
import * as models from '@/models/Rules'

export const useRulesStore = defineStore('rules', {
  state: (): models.RulesState => ({
    rules: [],
    value: [],
    parameters: []
  }),
  actions: {
    async setRules () {
      try {
        const { rules, value, parameters } = await api.getRules()
        this.rules = rules
        this.value = value
        this.parameters = parameters
      } catch (error) {
        console.error('Error setting rules:', error)
      }
    }
  },
  getters: {
    getRules (): models.Rule[] {
      return structuredClone(this.rules)
    }
  }
})
