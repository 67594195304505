<template>
  <div class="acceptedTable">
    <v-row class="ma-0 pl-4 align-center flex-nowrap">
      <v-col cols="8" class="pl-0">
        <v-row class="flex-nowrap">
          <v-col cols="2">
            <v-select
              class="pt-0 mt-0"
              hide-details
              v-model="activeType"
              @input="loadData()"
              :items="['Combinations', 'Send', 'Receive', 'Option']"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-row class="pt-0 pb-0 mx-0 align-center px-4">
        <v-col class="text-right">
          <TableSearch class="mx-2" @searchChanged="handleTableSearchChanged" />
          <v-icon class="mx-2" @click="onMailClick">email</v-icon>
          <DataExport
            @item-click="downloadTableData"
          />
        </v-col>
      </v-row>
    </v-row>
    <data-tables
      ref="transfersTable"
      :key="transfersListKey"
      :data="tableData"
      :page-size="10"
      @filtered-data="filteredData = $event"
      @update:currentPage="expandedRow = null; rowExpandClicked = null"
      @update:pageSize="expandedRow = null; rowExpandClicked = null"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 mr-2',
      }"
      @sort-change="expandedRow = null; rowExpandClicked = null"
      :tableProps="tableProps"
      :filters="transfersTableFilters"
      @filter-change="filters => {expandedRow = null; rowExpandClicked = null; handleFilterChange('transfersTableFilters', filters)}"
      @expand-change="handleExpandChange"
    >
      <el-table-column width="45" class="expand-column">
        <template slot-scope="scope">
          <v-icon @click="handleExpandClick(scope.row)" class="my-2">
            <template v-if="expandedRow !== scope.row">
              chevron_right
            </template>
            <template v-else>
              expand_more
            </template>
          </v-icon>
        </template>
      </el-table-column>
      <el-table-column type="expand" width="1">
        <template slot-scope="props">
          <!-- Using slot-scope here to prevent double render of AcceptedTransferDetails component.
               Not sure why this happens -->
          <AcceptedTransferDetails
            @delete-detail="deleteRowFromList"
            :row="props.row" :type="activeType" @update-details="loadData(true)"/>
        </template>
      </el-table-column>
      <el-table-column
        column-key="store_name"
        v-if="activeType !== 'Option'"
        label="Store" sortable="custom" prop="store_name">
        <template slot-scope="scope">
          <div class="one-line" v-if="scope.row.store_name">
            {{ scope.row.store_name }}
          </div>
          <span v-else>{{scope.row.store_key}}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="activeType !== 'Option'"
        column-key="to_store_name"
        label="To Store" sortable="custom" prop="to_store_name">
        <template slot-scope="scope">
          <div class="one-line" v-if="scope.row.to_store_name">
            {{ scope.row.to_store_name }}
          </div>
          <span v-else>{{scope.row.to_store_key}}</span>
        </template>
      </el-table-column>

      <el-table-column
        v-if="activeType === 'Option'"
        label="Name" prop="name" width="250" column-key="name">
        <template slot-scope="scope">
          <OptionDataCell
            nameKey="name"
            :option="{...scope.row}"
            open-in-new-tab
          />
        </template>
      </el-table-column>
      <el-table-column label="Created" sortable="custom" prop="created" column-key="created"
        :filters="getColumnFilterValues('tableData', 'created', (value)=> $options.filters.formatDate(value, 'yyyy-MM-dd'), true)">
        <template slot-scope="scope">
          {{ scope.row.created | formatDate('yyyy-MM-dd')}}
        </template>
      </el-table-column>
      <el-table-column label="Transfer" sortable="custom" prop="transfer">
        <template slot-scope="scope">
          {{ scope.row.transfer | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="Picked" sortable="custom" prop="picked">
        <template slot-scope="scope">
          {{ scope.row.picked | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="Tasks" sortable="custom" prop="tasks">
        <template slot-scope="scope">
          {{ scope.row.tasks | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="Done" sortable="custom" prop="done">
        <template slot-scope="scope">
          {{ scope.row.done | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="Type" sortable="custom" prop="type" column-key="type"
        :filters="getColumnFilterValues('tableData', 'type')"
      >
        <template slot-scope="scope">
          {{ scope.row.type | formatString}}
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="200">
        <template slot-scope="scope">
          <div class="ml-n1 my-2 text-left">
            <v-icon class="mr-2 action-btn-danger" @click="handleDeleteClick(scope.row)">delete</v-icon>
            <v-icon
              :disabled="activeType !== 'Combinations'"
              @click="handlePrintClick(scope.row)"
              class="mx-2 action-btn-primary"
              v-if="activeType !== 'Option'"
            >logout</v-icon>
            <DataExport
              @item-click="(sep)=> handleDownloadClick(scope.row, sep)"
              :closeOnClick="true"
            >
              <v-overlay
                absolute
                color="white"
                :value="loadingExportData"
                slot="additional-list-item">
                <v-progress-circular color="primary" indeterminate size="64" width="2" />
              </v-overlay>
            </DataExport>
          </div>
        </template>
      </el-table-column>
    </data-tables>

    <!-- Delete dialog -->
    <v-dialog
      v-model="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="p-relative rounded-0">
        <v-overlay absolute color="white" :value="stockTransfersStore.deletingAcceptedTransfer">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <v-card-title v-if="rowToDelete && activeType !== 'Option'" class="text-body-1 font-weight-medium">
          {{ rowToDelete.store_name }}
          <v-icon class="mx-2">arrow_forward</v-icon>
          {{ rowToDelete.to_store_name }}
        </v-card-title>
        <v-card-title v-if="rowToDelete && activeType === 'Option'" class="text-body-1 font-weight-medium">
          {{rowToDelete.name}}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete this transfer?<br/>Note: deleting a transfer does not reset any exclusions.</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleDeleteCancel"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteConfirm"
                class="red--text mx-4"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="emailModalOpen"
      v-model="emailModalOpen"
      persistent
      scrollable
      content-class="align-self-start mt-14 acceptedTable"
      width="1200px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Send transfer mail
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">

          <v-overlay absolute color="white" :value="emailLoading">
            <v-progress-circular color="primary" indeterminate size="64" width="2" />
          </v-overlay>
          <v-textarea
            class="pa-4 pb-0"
            counter
            label="Message"
            :rules="[v => v.length <= 500 || 'Max 500 characters']"
            v-model="messageValue"
          ></v-textarea>

          <v-col class="px-4 py-0" cols="3">
            <v-combobox
              v-model="emailAddresses"
              label="Extra email addresses"
              class="pt-0"
              :items="emailAddressesList"
              multiple
              chips
              small-chips
              deletable-chips
              clearable
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" small close @click:close="emailAddresses.splice(0, 1)">
                  <span>{{ item }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption mx-1">
                  +{{ emailAddresses.length - 1 }}
                </span>
              </template>
            </v-combobox>
          </v-col>
          <v-col class="text-right pr-6">
            <TableSearch class="mx-2"
              @searchChanged="val => handleSearchChanged('transfersModalTableFilters', ['store_key', 'to_store_key', 'created', 'transfer'], val)"
            />
          </v-col>
          <h3 class="pa-4">{{selectedRows.length}} Rows are selected</h3>
          <data-tables
            ref="transfersModalTable"
            :key="transfersModalListKey"
            :data="modalTableData"
            @filtered-data="filteredDataMails = $event"
            @filter-change="filters => handleFilterChange('transfersModalTableFilters', filters)"
            :page-size="10"
            :pagination-props="{
              pageSizes: [5, 10,15, 25, 50],
              class: 'el-pagination text-right mt-6 mr-2',
            }"
            :filters="transfersModalTableFilters"
          >
            <el-table-column width="55" :key="selectedRowsKey">
              <template slot="header">
                <v-simple-checkbox
                  color="primary"
                  dense
                  hide-details
                  class="mt-0 ml-4 pt-0"
                  :value="selectedRows.length === filteredDataMails.length"
                  :ripple="false"
                  @input="handleSelectAllClick"
                />
              </template>
              <template slot-scope="scope">
                <v-simple-checkbox
                  color="primary"
                  dense
                  hide-details
                  class="mt-0 ml-4 pt-0"
                  :value="scope.row.selected"
                  :ripple="false"
                  @input="handleSelectRowChange($event, scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column
              :filters="getColumnFilterValues('modalTableData', 'store_name')"
              column-key="store_name"

              label="Store" sortable="custom" prop="store_name">
              <template slot-scope="scope">
                <div class="one-line" v-if="scope.row.store_name">
                  {{ scope.row.store_name }}
                </div>
                <span v-else>{{scope.row.store_key}}</span>
              </template>
            </el-table-column>
            <el-table-column
              :filters="getColumnFilterValues('modalTableData', 'to_store_name')"
              column-key="to_store_name"

              label="To Store" sortable="custom" prop="to_store_name">
              <template slot-scope="scope">
                <div class="one-line" v-if="scope.row.to_store_name">
                  {{ scope.row.to_store_name }}
                </div>
                <span v-else>{{scope.row.to_store_key}}</span>
              </template>
            </el-table-column>
            <el-table-column
              :filters="getColumnFilterValues('modalTableData', 'created')"
              column-key="created"

              label="Created" sortable="custom" prop="created">
              <template slot-scope="scope">
                {{ scope.row.created | formatDate('yyyy-MM-dd')}}
              </template>
            </el-table-column>
            <el-table-column label="Transfer" sortable="custom" prop="transfer"
              column-key="transfer"

              :filters="getColumnFilterValues('modalTableData', 'transfer')"
            >
              <template slot-scope="scope">
                {{ scope.row.transfer | formatThousands }}
              </template>
            </el-table-column>
            <el-table-column label="Type" sortable="custom" prop="type"
              column-key="type"

              :filters="getColumnFilterValues('modalTableData', 'type')"
            >
              <template slot-scope="scope">
                {{ scope.row.type | formatString }}
              </template>
            </el-table-column>
            <el-table-column label="Email Addresses" sortable="custom" prop="email_addresses"
              column-key="email_addresses"

              :filters="getColumnFilterValues('modalTableData', 'email_addresses')"
            >
              <template slot-scope="scope">
                <v-combobox
                  v-model="scope.row.email_addresses"
                  class="table-field pb-1"
                  :items="[...emailAddressesList, ...scope.row.email_addresses]"
                  multiple
                  chips
                  small-chips

                  deletable-chips
                  clearable
                  hide-details
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0" small close @click:close="emailAddresses.splice(0, 1)">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption mx-1">
                      +{{ scope.row.email_addresses.length - 1 }}
                    </span>
                  </template>
                </v-combobox>
              </template>
            </el-table-column>

          </data-tables>

        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    depressed
                    text
                    @click="emailModalOpen = false"
                  >Cancel</v-btn>
                  <v-btn
                    color="primary"
                    depressed
                    outlined
                    @click="sendMail"
                    class="mx-4"
                  >Send</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog

      :value="emailResultOpen"
      persistent
      width="600px"
      max-width="90%">
      <v-card>
        <v-card-title class="text-body-1 font-weight-medium">
          {{emailResult}}
        </v-card-title>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    depressed
                    text
                    @click="emailResultOpen = false"
                  >Close</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Close confirm dialog -->
  </div>
</template>

<script>

import axios from '@/services/axios.js'
import { getHeadersWithAccessToken } from '@/variables.js'
import { downloadData } from '@/utils'
import TableSearch from '@/components/TableSearch.vue'
import AcceptedTransferDetails from '@/components/Transfers/AcceptedTransferDetails.vue'
import DataExport from '../DataExport'
import columnFilters from '@/mixins/columnFilters'
import { sendMail } from '@/api/transfers'
import OptionDataCell from '../OptionDataCell'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import baseURLs from '@/api/APIBaseURLs'

import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'
import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore(),
      stockTransfersStore: useStockTransfersStore()
    }
  },
  components: {
    OptionDataCell,
    DataExport,
    AcceptedTransferDetails,
    TableSearch,
  },
  mixins: [columnFilters],
  props: {
    storesFilter: {
      type: Array || null,
      default: null
    },
  },
  data () {
    return {
      loaded: false,
      filteredData: [],
      filteredDataMails: [],
      emailLoading: false,
      emailResultOpen: false,
      emailResult: '',
      activeType: 'Combinations',
      emailAddresses: [],
      modalTableData: [],
      emailAddressesByRow: [],
      selectedRowsKey: 0,
      emailModalOpen: false,
      messageValue: '',
      tableProps: {
        lazy: true,
        'show-summary': true,
        'summary-method': this.getSummary,
      },
      transfersListKey: 0,
      transfersTableFilters: [
        {
          value: ''
        }
      ],
      transfersModalListKey: 0,
      transfersModalTableFilters: [
        {
          value: ''
        }
      ],
      loadingExportData: false,
      deleteDialogVisible: false,
      rowToDelete: null,
      expandedRow: null,
      rowExpandClicked: null,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig', 'findStoreWithKey']),
    storesList () {
      return this.appConfig?.stores || []
    },
    tableData () {
      return this.stockTransfersStore.transferTablesData['accepted'].map((item) => ({
        ...item,
        store_name: this.findStore(item.store_key)?.store_name,
        to_store_name: this.findStore(item.to_store_key)?.store_name
      }))
    },
    storeMails () {
      return this.storesList.reduce((prev, curr) => {
        prev[curr.store_key] = curr.storepage_settings
        return prev
      }, {})
    },
    emailAddressesList () {
      const emailAddresses = []
      this.modalTableData.forEach(item => {
        if (this.storeMails[item.store_key]?.mail) {
          emailAddresses.push(...this.storeMails[item.store_key].mail)
        }
      })
      const emailSet = new Set([...this.emailAddresses, ...emailAddresses])
      return [...emailSet].filter(item => item)
    },
    selectedRows () {
      return this.modalTableData.filter(row => row.selected)
    },
  },
  watch: {
    'stockTransfersStore.transferTablesData.mailingData': {
      handler (newValue) {
        this.modalTableData = newValue.map((item) => ({
          ...item,
          email_addresses: this.storeMails[item.store_key]?.mail || [],
          store_name: this.findStore(item.store_key)?.store_name,
          to_store_name: this.findStore(item.to_store_key)?.store_name
        })) || []
      },
      immediate: true
    },
    'stockTransfersStore.acceptingTransferDetailsFree': function (newValue) {
      if (!newValue) {
        setTimeout(()=> {
          this.loadData()
        }, 1000)
      }
    },
    selectedRows () {
      this.selectedRowsKey++
    },

    'stockTransfersStore.detailsRowExpanded.combinations': 'handleDetailsRowExpandedChange',
    'stockTransfersStore.detailsRowExpanded.send': 'handleDetailsRowExpandedChange',
    'stockTransfersStore.detailsRowExpanded.receive': 'handleDetailsRowExpandedChange',
    'stockTransfersStore.detailsRowExpanded.build': 'handleDetailsRowExpandedChange',
  },
  methods: {
    onMailClick () {
      this.emailModalOpen = true
      this.transfersModalListKey = this.transfersListKey + 10
    },
    downloadTableData (sep) {
      downloadData(sep, this.tableData, 'transfers_accepted')
    },
    deleteRowFromList (){
      this.expandedRow = null
      setTimeout(()=> {
        this.loadData(false)
      }, 1000)
    },
    getSummary (params) {
      const sums = []
      const { columns } = params
      columns.forEach((column, index) => {
        if (index === 2) {
          sums[index] = 'Total'
          return
        }
        const value = this.filteredData.reduce((acc, cur) => acc + cur[column.property], 0)

        if (typeof value !== 'number' || isNaN(value)) {
          sums[index] = ''
        }else {
          sums[index] = this.$options.filters.formatThousands(this.filteredData.reduce((acc, cur) => acc + cur[column.property], 0))
        }

      })
      return sums
    },
    handleSelectAllClick () {
      const selectAll = this.selectedRows.length !== this.filteredDataMails.length
      this.filteredDataMails.forEach(row => this.$set(row, 'selected', selectAll))
    },
    sendMail () {
      this.emailLoading = true
      const payload = {
        message: this.messageValue,
        extra_email_addresses: this.emailAddresses,
        stores: this.selectedRows
      }
      sendMail(payload).then((r) => {
        this.emailResultOpen = true
        this.emailResult = r.msg
        this.emailLoading = false
        this.emailModalOpen = false
      })
        .catch(console.log)
    },
    handleRowClick (row, col, event) {
      if (event.target.tagName === 'INPUT' || event.target.classList.contains('v-icon')) {
        return
      }
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
    loadData (noReload = false) {
      this.stockTransfersStore.setDetailsRowExpanded('accepted', false)
      this.expandedRow = null
      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()

      const storeKeyFilter = this.storesFilter ? this.storesFilter : (pageFiltersValues.stores_dc || [])
      const params = {
        option_view: false,
        send: this.activeType === 'Receive' ? 'multi' : 'all',
        receive: this.activeType === 'Send' ? 'multi' : 'all',
        filters: {
          group_key: pageFiltersValues.groups || [],
          collection_key: pageFiltersValues.collections || [],
          option_key: pageFiltersValues.options || [],
          store_key: storeKeyFilter,
          to_store_key: pageFiltersValues.to_stores_dc || [],
        }
      }

      if (this.activeType === 'Option') {
        params.option_view = true
      }

      this.stockTransfersStore.getTransfersList('accepted', params, false, noReload).then(data => {
        this.rowExpandClicked = this.expandedRow
        if (!this.expandedRow) {
          return
        }
        const oldItem = this.tableData.find(item => item.store_key === this.expandedRow.store_key && item.to_store_key === this.expandedRow.to_store_key)
        const newItem = data?.find(item => item.store_key === this.expandedRow.store_key && item.to_store_key === this.expandedRow.to_store_key)
        for (const key in newItem) {
          this.$set(oldItem, key, newItem[key])
        }
      })
    },
    findStore (storeKey) {
      return this.findStoreWithKey(storeKey)
    },
    handleExpandChange (row) {
      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()
      const params = {
        option_view: false,
        child_store_key: row.store_key === 'multi' ? 'multi' : row.store_key || null,
        child_to_store_key: row.to_store_key === 'multi' ? 'multi' : row.to_store_key || null,
        send: this.activeType === 'Receive' ? 'multi' : 'all',
        receive: this.activeType === 'Send' ? 'multi' : 'all',
        type: row.type,
        filters: {
          group_key: pageFiltersValues.groups || [],
          collection_key: pageFiltersValues.collections || [],
          option_key: pageFiltersValues.options || [],
          store_key: pageFiltersValues.stores_dc || [],
          to_store_key: pageFiltersValues.to_stores_dc || [],
        }
      }
      if (this.activeType === 'Option') {
        params.child_option_key = row.option_key
        params.option_view = true
      }
      // close row if there is opened one
      if (this.expandedRow) {
        this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
        this.stockTransfersStore.setTransferDetailsData([])
      }

      // save opended row in component and state
      this.expandedRow = this.expandedRow ? null : row
      this.stockTransfersStore.setDetailsRowExpanded('accepted', !!this.expandedRow)

      // load data for opened row
      if (this.expandedRow) {
        this.stockTransfersStore.getTransferDetails('accepted', params)
      }
    },
    handleDetailsRowExpandedChange (val) {
      if (val) {
        // close row if there is opened one
        if (this.expandedRow) {
          this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
          this.expandedRow = null
        }
      }
    },
    handleTableSearchChanged (val) {
      this.transfersTableFilters[0].value = val
      this.transfersListKey++
    },
    handleDeleteClick (row) {
      this.rowToDelete = row
      this.deleteDialogVisible = true
    },
    handlePrintClick (row) {
      // Open storepage transfers print in new tab
      const routeData = this.$router.resolve({
        name: 'storepage-transfers-print',
        params: { 'store_key': row.store_key },
        query: { 'to_store_key': row.to_store_key, type: row.type },
      })

      window.open(routeData.href, '_blank', 'noreferrer')
    },
    async handleDownloadClick (row, sep) {
      const url = baseURLs.manageAPI + '/data/stock/transfers/export_accepted'

      this.loadingExportData = true

      try {
        const response = await axios.post(url, {
          store_key: row.store_key || 'multi',
          to_store_key: row.to_store_key || 'multi',
          format: 'doa',
          option_key: this.activeType === 'Option' ? row.option_key : 'multi'
        }, {
          headers: getHeadersWithAccessToken(),
        })
        this.loadingExportData = false
        this.downloadTransfersData(response.data, sep, row)
      } catch (error) {
        this.loadingExportData = false
        console.log(error)
      }
    },
    downloadTransfersData (data, sep, row) {
      const columns = data.keys
      const resultData = []

      columns.forEach(col => {
        data.data[col].forEach((colValue, j) => {
          if (col === 'sku_properties') {
            colValue = JSON.stringify(colValue)
          }

          if (resultData[j]) {
            resultData[j][col] = colValue
          } else {
            resultData.push({
              [col]: colValue
            })
          }
        })
      })

      let filename = `transfers_accepted_from_${row.store_key}_to_${row.to_store_key}`
      if (this.activeType === 'Option') {
        filename = `transfers_accepted_option_${row.option_key}`
      }
      downloadData(sep, resultData, filename)
    },
    handleDeleteCancel () {
      this.rowToDelete = null
      this.deleteDialogVisible = false
    },
    handleDeleteConfirm () {
      this.stockTransfersStore.deleteAcceptedTransfer(this.rowToDelete)
        .then(() => {
          this.rowToDelete = null
          this.deleteDialogVisible = false

          setTimeout(()=> {
            this.loadData()
          }, 1000)
        })
    },
    handleExpandClick (row) {
      if (this.expandedRow) {
        this.rowExpandClicked = row
      }
      this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(row, row !== this.expandedRow)
    },
  },
  created () {
    this.loadData()
  },
}
</script>

<style lang="scss">
.acceptedTable {
  .el-table__expand-icon {
    display: none;
  }
  .el-table__expanded-cell[class*=cell] {
    padding: 16px;
    background: #f9f9f9;
    &:hover {
      background: #f9f9f9 !important;
    }
  }
  .one-line {
    white-space: nowrap;
    overflow: hidden;
  }

  .table-field {
    margin-top: -12px;
  }
}
</style>
