<template>
  <v-dialog
    :value="visible"
    persistent
    scrollable
    content-class="align-self-start mt-14"
    max-width="90%">
    <v-card class="rounded-0">
      <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">Add order</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-0 p-relative text--primary">
        <v-overlay absolute color="white" :value="loading || loadingShopItemOrderLineOptimized">
          <v-progress-circular color="primary" indeterminate size="64" width="2"/>
        </v-overlay>

        <div class="shop-item-order-form">
          <h4 class="mx-12 text-body-1 font-weight-medium mt-5 mb-8">Steps</h4>
          <Stepper
            :steps="steps"
            :current-step="currentStep"
            class="mx-12"
            @step-clicked="handleStepClicked"
          />
          <div class="mt-8">
            <Pieces
              v-if="currentStep === 0"
              :order="order"
              :item="item"
              :orders="orders"
              :order-line="orderLines[0]"
              :commitments="commitments || {}"
              :sku-cols="skuCols"
              @pieces-to-order-changed="handlePiecesChanged"
              @pieces-to-order-validated="handlePiecesValidated"
            />
            <Crossdocks
              v-if="currentStep === 1"
              :item="item"
              :order="order"
              :order-line="orderLines[0]"
              :sku-cols="skuCols"
              @crossdocks-changed="handleCrossdocksChanged"
              @crossdocks-validated="handleCrossdocksValidated"
            />
            <Prepacks
              v-if="currentStep === 2"
              :item="item"
              :order="order"
              :order-line="orderLines[0]"
              :sku-cols="skuCols"
              :optimized="prepacksRequireOptimization"
              @prepacks-optimization-changed="handlePrepacksOptimizationChanged"
              @prepacks-changed="handlePrepacksChanged"
              @data-load-changed="dataForLoad = $event"
              @variants-changed="handlePrepacksVariantsChanged"
              @prepacks-validated="handlePrepacksValidated"
            />
            <Schedule
              v-if="currentStep === 3"
              :item="item"
              :order="order"
              :order-schedule="orderSchedule"
              @schedule-changed="handleScheduleChanged"
            />
            <Costs
              v-if="currentStep === 4"
              :currencies="currencies"
              :item="item"
              :dummy="dummy"
              :pieces_total="order.pieces_total"
              :order="order"
              :order-costs="orderCosts"
              :order-line="orderLines[0]"
              :kpi-items="kpiItems"
              :sku-cols="skuCols"
              @new-cost-group-selected="handleNewCostGroupSelected"
              @costs-changed="handleCostsChanged"
            />
            <Summary
              v-if="currentStep === 5"
              ref="summary"
              :currencies="currencies"
              :item="item"
              :order="order"
              :order-line="orderLines[0]"
              :order-schedule="orderSchedule"
              :extra-status-options="extraStatusOptions"
              :status-options="statusOptions"
              :kpi-items="kpiItems"
              @summary-changed="handleSummaryChanged"
              @validation="handleSummaryValidation"
            />
          </div>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2 px-4">
          <v-col>
            <v-btn
              v-if="currentStep > 0"
              color="primary"
              depressed
              outlined
              @click="handlePreviousOrderStepClick"
              class="mx-4"
            >Previous
            </v-btn>
            <v-btn
              color="primary"
              depressed
              text
              :disabled="saveProgressDisabled"
              @click="handleSaveOrderProgressClick()"
            >Save progress
            </v-btn>
            <v-btn
              v-if="currentStep < 5"
              color="primary"
              depressed
              outlined
              :disabled="nextStepDisabled"
              @click="handleNextOrderStepClick()"
              class="mx-4"
            >Next
            </v-btn>
            <v-btn
              v-if="currentStep === 5"
              :disabled="orderDisabled"
              color="primary"
              depressed
              outlined
              @click="handleOrderClick"
              class="mx-4"
            >Order
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-dialog
        v-model="updateDialog"
        persistent
        content-class="align-self-center mt-0 mb-8"
        width="600px"
        max-width="90%">
        <v-card class="rounded-0">
          <v-card-text>
            <div class="text-body-1 pt-4">
              Pieces are changed, do you want to refresh order schedule and costs?
            </div>
          </v-card-text>
          <v-card-actions>
            <v-row class="dialog-footer text-right py-2">
              <v-col>
                <v-btn
                  color="primary"
                  depressed
                  text
                  @click="updateDialog = false; saveFunction(true)"
                >Cancel
                </v-btn>
                <v-btn
                  depressed
                  outlined
                  color="primary"
                  @click="updateDialog= false; onSaveClick()"
                  class="mx-4"
                >Refresh
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import * as shopApi from '@/api/shop'
import { updateOrderForm } from '@/api/shop'
import Stepper from '@/components/Shop/Stepper'
import Pieces from './ShopItemOrderFormPieces'
import Crossdocks from './ShopItemOrderFormCrossdocks'
import Prepacks from './ShopItemOrderFormPrepacks'
import Schedule from './ShopItemOrderFormSchedule'
import Costs from './ShopItemOrderFormCosts'
import Summary from './ShopItemOrderFormSummary'

export default {
  components: {
    Stepper,
    Pieces,
    Crossdocks,
    Prepacks,
    Schedule,
    Costs,
    Summary,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currencies: {
      type: Array,
      default: () => [],
    },
    dummy: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    orders: {
      type: Array,
      default: () => [],
    },
    orderLines: {
      type: Array,
      required: true,
    },
    orderSchedule: {
      type: Array,
      required: true,
    },
    orderCosts: {
      type: Object,
      required: true,
    },
    commitments: {
      type: Object,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    extraStatusOptions: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      vat: parseFloat(localStorage.getItem('identity_vat')) || 1.2,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      saveFunction: () => {},
      dataForLoad: {
        prepack_percentage: 0,
        prepack_minimum: 4,
        prepack_maximum: 8,
      },
      areSummaryPropertiesValid: false,
      steps: ['Pieces', 'Cross-docks', 'Prepacks', 'Schedule', 'Costs', 'Order'],
      defaultCurrency: localStorage.getItem('defaultCurrency'),
      currentStep: 0,
      loading: false,
      updateDialog: false,
      loadingShopItemOrderLineOptimized: false,
      newPieces: { ...this.orderLines[0].pieces },
      newCrossdocks: [...this.orderLines[0].cross_docks],
      newPrepacks: [...this.orderLines[0].prepacks],
      prepacksRequireOptimization: true,
      newPrepackVariants: this.orderLines[0].prepack_variants,
      newSchedule: [...this.orderSchedule],
      newCosts: { ...this.orderCosts },
      newOrder: { ...this.order },
      newPiecesValid: true,
      newCrossdocksValid: true,
      newPrepacksValid: true,
      getShopItemOrderLinesOptimizedDelayed: null,
    }
  },
  computed: {

    formatPercents () {
      const formatter = new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })
      return formatter.format
    },
    currencySymbols () {
      return this.currencies.reduce((acc, cur) => ({
        ...acc,
        [cur.iso_code]: cur.symbol,
      }), {})
    },
    defaultCurrencySymbol () {
      return this.currencySymbols[this.defaultCurrency]
    },
    currentStepValid () {
      return (this.currentStep === 0 && this.newPiecesValid) ||
        (this.currentStep === 1 && this.newCrossdocksValid) ||
        (this.currentStep === 2 && this.newPrepacksValid) ||
        (this.currentStep === 3) ||
        (this.currentStep === 4) ||
        (this.currentStep === 5)
    },
    orderDisabled () {
      return !this.areSummaryPropertiesValid
    },
    saveProgressDisabled () {
      return !this.currentStepValid
    },
    nextStepDisabled () {
      return !this.currentStepValid
    },
    skuCols () {
      const orderLinePieces = Object.keys(this.orderLines[0].pieces)

      return [
        ...this.dummy.sku_names,
        ..._.difference(orderLinePieces, this.dummy.sku_names)
      ]
    },
    kpiItems () {
      const piecesTotal = this.skuCols.reduce(
        (acc, cur) => acc + this.orderLines[0].pieces[cur],
        0
      )
      const turnover = this.dummy.prices[this.defaultCurrency] * piecesTotal
      const turnoverExVAT = _.round(turnover / this.vat, 2) // -20%
      const buyingPrice = this.orderCosts.costs
        .filter(c => c.cost_key === 0)
        .reduce(
          (acc, cur) => acc + cur.cost_default_currency,
          0
        )
      const otherCosts = this.orderCosts.costs
        .filter(c => c.cost_key !== 0)
        .reduce(
          (acc, cur) => acc + cur.cost_default_currency,
          0
        )
      const margin = turnoverExVAT - buyingPrice - otherCosts
      const vatHumanized = this.$options.filters.twoDecimals((this.vat - 1) * 100)
      return [{
        title: `Sales value (ex ${vatHumanized}% VAT)`,
        value: `${this.$options.filters.formatThousands(_.round(turnover))} (${this.defaultCurrencySymbol + this.$options.filters.formatThousands(_.round(turnoverExVAT))})`,
      }, {
        title: 'Buying costs',
        value: `${this.$options.filters.formatThousands(_.round(buyingPrice))} (${this.$options.filters.formatPercents(buyingPrice / turnoverExVAT)}%)`,
      }, {
        title: 'Other costs',
        value: `${this.$options.filters.formatThousands(_.round(otherCosts))} (${this.$options.filters.formatPercents(otherCosts / turnoverExVAT)}%)`,
      }, {
        title: 'Margin',
        value: `${this.$options.filters.formatThousands(_.round(margin))} (${this.formatPercents((margin / turnoverExVAT) * 100)}%)`,

      }, {
        title: 'Markup',
        value: `${this.order.markup?.toFixed(1) || ''}`,
      }]
    },
  },
  watch: {},
  methods: {
    onSaveClick () {
      const orderLinesPayload = {
        budget_key: this.order.budget_key,
        scenario_key: this.order.scenario_key,
        shop_item_key: this.order.shop_item_key,
        order_key: this.order.order_key,
        order_lines: [{
          ...this.orderLines[0],
          pieces: this.newPieces,
          cross_docks: this.newCrossdocks,
        }],
      }
      shopApi.saveShopItemOrderLines(orderLinesPayload).then(() => {
        updateOrderForm({ order_key: this.order.order_key, force: true }).then((data) => {
          data.order.pieces_total = this.pieces_total
          this.$emit('update-order', data)
        })
        this.piecesChanged = false
        this.saveFunction(true)
      })
    },
    saveOrderProgress () {
      const orderLinesPayload = {
        budget_key: this.order.budget_key,
        scenario_key: this.order.scenario_key,
        shop_item_key: this.order.shop_item_key,
        order_key: this.order.order_key,
        order_lines: [{
          ...this.orderLines[0],
          pieces: this.newPieces,
          cross_docks: this.newCrossdocks,
        }],
      }

      if (this.newOrder.status === 'to_order') {
        this.newOrder.status = 'in progress'
      }

      const promises = [
        shopApi.saveShopItemOrderInfo(this.newOrder),
        shopApi.saveShopItemOrderLines(orderLinesPayload),
      ]

      return Promise.all(promises)
    },
    saveCurrentStep () {
      this.getShopItemOrderLinesOptimizedDelayed.cancel()
      this.loading = true

      // in case if prepack optimized is false, prepacks are saved inside its component
      const stepsRequireOptimization = this.prepacksRequireOptimization
        ? [0, 1, 2]
        : [0, 1]

      if (stepsRequireOptimization.includes(this.currentStep)) {
        return this
          .getShopItemOrderLinesOptimized()
          .then(() => {
            return this
              .saveOrderProgress()
              .finally(() => {
                this.loading = false
              })
          })
      } else {
        const payload = {
          budget_key: this.order.budget_key,
          scenario_key: this.order.scenario_key,
          shop_item_key: this.order.shop_item_key,
          order_key: this.order.order_key,
          order_schedule: this.newSchedule,
        }

        return Promise
          .all([
            // saving only schedule cause prepacks (in case if optimized is false) and costs are saved in real time
            shopApi.saveShopItemOrderSchedule(payload),
            shopApi.saveShopItemOrderInfo(this.newOrder),
          ])
          .finally(() => {
            this.loading = false
          })
      }
    },
    handlePreviousOrderStepClick () {
      if (this.currentStep > 0) {
        this.currentStep--
      }
    },
    handleNextOrderStepClick (isNext) {
      if (this.currentStep === 0 && !isNext && this.piecesChanged) {
        this.saveFunction = this.handleNextOrderStepClick
        this.updateDialog = true
        return
      }
      if (this.currentStep < this.steps.length - 1) {
        this
          .saveCurrentStep()
          .then(() => {
            this.currentStep++
          })
      }
    },
    handleOrderClick () {
      this.loading = true

      shopApi
        .saveShopItemOrderInfo({
          ...this.newOrder,
          status: 'ordered',
        })
        .then(() => {
          this.loading = false
          this.$emit('order-progress-saved')
        })
    },
    handleSaveOrderProgressClick (isNext) {
      if (this.currentStep === 0 && !isNext && this.piecesChanged) {
        this.saveFunction = this.handleSaveOrderProgressClick
        this.updateDialog = true
        return
      }
      this
        .saveCurrentStep()
        .then(() => {
          this.$emit('order-progress-saved')
        })
    },
    handleStepClicked (step, isNext) {
      if (this.currentStep === 0 && !isNext && this.piecesChanged) {
        this.saveFunction = (isNext) => this.handleStepClicked(step, isNext)
        this.updateDialog = true
        return
      }
      this
        .saveCurrentStep()
        .then(() => {
          this.currentStep = step
        })
    },
    isEqual (object1, object2) {
      const keys1 = Object.keys(object1)
      const keys2 = Object.keys(object2)

      if (keys1.length !== keys2.length) {
        return false
      }

      for (const key of keys1) {
        if (object1[key] !== object2[key]) {
          return false
        }
      }

      return true
    },
    handlePiecesChanged (newPieces) {
      this.piecesChanged = !this.isEqual(newPieces, this.newPieces)
      this.newPieces = { ...newPieces }
      this.pieces_total = Object.keys(this.newPieces).reduce((prev, curr) => this.newPieces[curr] + prev, 0)
      this.$set(this.order, 'pieces_total', this.pieces_total)
      this.orderCosts = { ...this.orderCosts }
      if (this.currentStepValid) {
        this.getShopItemOrderLinesOptimizedDelayed()
      } else {
        this.getShopItemOrderLinesOptimizedDelayed.cancel()
      }
    },
    handlePiecesValidated (isValid) {
      this.newPiecesValid = isValid
    },
    handleCrossdocksChanged (newCrossdocks) {
      this.newCrossdocks = [...newCrossdocks]

      if (this.currentStepValid) {
        this.getShopItemOrderLinesOptimizedDelayed()
      } else {
        this.getShopItemOrderLinesOptimizedDelayed.cancel()
      }
    },
    handleCrossdocksValidated (isValid) {
      this.newCrossdocksValid = isValid
    },
    handlePrepacksOptimizationChanged (val) {
      this.prepacksRequireOptimization = val
    },
    handlePrepacksChanged ({ newPrepacks, dataForLoad }) {
      this.newPrepacks = [...newPrepacks]
      this.dataForLoad = dataForLoad
      if (this.currentStepValid) {
        this.getShopItemOrderLinesOptimizedDelayed()
      } else {
        this.getShopItemOrderLinesOptimizedDelayed.cancel()
      }
    },
    handlePrepacksVariantsChanged (newVariants) {
      this.newPrepackVariants = newVariants
      this.getShopItemOrderLinesOptimizedDelayed.cancel()
      this
        .getShopItemOrderLinesOptimized()
    },
    handlePrepacksValidated (isValid) {
      this.newPrepacksValid = isValid
    },
    handleScheduleChanged (newSchedule) {
      this.newSchedule = [...newSchedule]
    },
    handleNewCostGroupSelected (costGroup) {
      this.loading = true

      const payload = {
        budget_key: this.order.budget_key,
        scenario_key: this.order.scenario_key,
        shop_item_key: this.order.shop_item_key,
        order_key: this.order.order_key,
        method: 'append',
        cost_keys: [costGroup.value],
      }

      shopApi
        .saveShopItemOrderCosts(payload)
        .then(() => {
          this.$emit('costs-saved')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleCostsChanged (costs) {
      this.loading = true

      const payload = {
        budget_key: this.order.budget_key,
        scenario_key: this.order.scenario_key,
        shop_item_key: this.order.shop_item_key,
        order_key: this.order.order_key,
        method: 'update',
        costs,
      }

      shopApi
        .saveShopItemOrderCosts(payload)
        .then(() => {
          this.$emit('costs-saved')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSummaryChanged (newOrder) {
      this.newOrder = { ...newOrder }
    },
    handleSummaryValidation (v) {
      this.areSummaryPropertiesValid = v
    },
    getShopItemOrderLinesOptimized () {
      this.loadingShopItemOrderLineOptimized = true
      const payload = {
        ...this.orderLines[0],
        pieces: this.newPieces,
        pieces_loose: this.newPieces,
        ...this.dataForLoad,
        cross_docks: this.newCrossdocks,
        prepack_variants: this.newPrepackVariants,
        prepacks: this.newPrepacks,
      }

      return shopApi
        .getShopItemOrderLinesOptimized(payload)
        .then(orderLines => {
          if (!orderLines.length) {
            return
          }
          // TODO: check if there is a case when pieces return from logic api may differ from user input. In that pieces passed to pieces form should be overriden
          // this.newPieces = orderLines[0].pieces
          this.newCrossdocks = orderLines[0].cross_docks
          this.newPrepacks = orderLines[0].prepacks

          this.orderLines[0] = {
            ...orderLines[0],
            order_key: this.orderLines[0].order_key,
          }
        })
        .finally(() => {
          this.loadingShopItemOrderLineOptimized = false
        })
    },
  },
  created () {
    this.getShopItemOrderLinesOptimizedDelayed = _.debounce(this.getShopItemOrderLinesOptimized, 2000)
  }
}
</script>

<style lang="scss">
.shop-item-order-form {
}
</style>
