<template>
  <div>
    <template v-if="storeSetForm && editMode">
      <v-container fluid class="pl-10 pr-8">
        <div class="text-body-1 font-weight-medium pt-2">General</div>
        <div style="width: 475px">
          <v-row class="align-baseline mt-4 mb-0">
            <v-col cols="11" class="py-0 pr-9">
              <v-text-field
                v-model="storeSetForm.set_name"
                label="Set name"
                :error-messages="storeSetNameError"
                hide-details />
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-8 mb-0">
            <v-col cols="11" class="py-0 pr-9">
              <collapsible-select
                v-model="storeSetForm.label"
                :items="labelsWithModule"
                label="Labels"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-4 mb-8">
            <v-col cols="3" class="py-0">
              <label>Static set</label>
            </v-col>
            <v-col class="py-0">
              <v-checkbox v-model="storeSetForm.static_set" @change="handleStaticSetChange" hide-details="auto" />
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col>
            <SearchFiltersStatic
              v-if="storeSetForm.static_set"
              :selected-filters="storeSetFilters"
              ref="searchFilters"
              class="ml-n6"
              @filterRemoved="handleFilterRemoved"
              @filtersChanged="handleCriteriaChanged"
            >
              <v-icon @click="onUploadClick">upload</v-icon>
            </SearchFiltersStatic>
            <SearchFiltersDynamic
              v-else
              :selected-filters="storeSetFilters"
              :static-set="storeSetForm.static_set"
              :stores-keys-loading="storesKeysFilterLoading"
              ref="searchFilters"
              class="ml-n6"
              @filterAdded="handleFilterAdded"
              @filterRemoved="handleFilterRemoved"
              @filtersChanged="handleCriteriaChanged"
            >
            </SearchFiltersDynamic>
          </v-col>

          <div  v-if="importError" class="mt-6 ml-6 grey--text text--darken-2 text-caption font-weight-medium error--text">
            {{ importError }}
          </div>
          <input
            type="file"
            style="position: absolute;
              opacity: 0"
            id="assetsFieldHandle"
            @change="onFileChange"
            ref="file"
            accept=".csv"
          />
        </v-row>
        <v-row v-if="!storeSetForm.static_set">
          <v-col>
            <SearchProperties
              ref="searchProperties"
              class="ml-n6"
              :properties-include="storeSetPropsInclude"
              :properties-exclude="storeSetPropsExclude"
              @propIncludeAdded="handlePropIncludeAdded"
              @propExcludeAdded="handlePropExcludeAdded"
              @propIncludeChanged="handlePropIncludeChanged"
              @propExcludeChanged="handlePropExcludeChanged"
              @propIncludeDeleted="handlePropIncludeDeleted"
              @propExcludeDeleted="handlePropExcludeDeleted"
              @propIncludeValuesChanged="handlePropIncludeValuesChanged"
              @propExcludeValuesChanged="handlePropExcludeValuesChanged"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn
              color="primary"
              depressed
              outlined
              @click="handleSearchClick"
            >Search</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-divider v-if="editMode && showStoresPreview" class="my-8" />
    <template v-if="showStoresPreview">
      <div>
        <div class="px-10 py-3 d-flex justify-space-between">
          <h6 class="text-body-1 font-weight-bold mb-4">Results</h6>
          <TableSearch class="mx-2" @searchChanged="handleResultsSearchChanged" />
        </div>
        <div class="p-relative">
          <v-overlay absolute color="white" :value="stockManageStore.loadingStoresResults">
            <v-progress-circular color="primary" indeterminate size="64" width="2" />
          </v-overlay>

          <data-tables
            style="width: 100%"
            :key="resultsListKey"
            :filters="resultsTableFilters"
            :data="storesPreview"
            :page-size="5"
            :pagination-props="{
              pageSizes: [5, 10,15, 25, 50],
              class: 'el-pagination text-right mt-6 mb-4 mr-2',
            }">
            <el-table-column
              v-for="col in ['store_name', 'country', 'dc_key', 'one_stock']"
              sortable="custom"
              :key="col"
              :prop="col"
              :label="col | formatString"
              :label-class-name="col === 'store_name' ? 'ml-8' : ''">
              <template slot-scope="scope">
                <div v-if="col === 'one_stock'">
                  <div class="d-flex">
                    <v-simple-checkbox
                      disabled
                      dense
                      hide-details
                      class="shrink mt-0 pt-0"
                      :value="scope.row.one_stock"
                    />
                  </div>
                </div>
                <div v-else :class="{ 'ml-8': col === 'store_name' }">{{ scope.row[col] }}</div>
              </template>
            </el-table-column>
          </data-tables>
        </div>
      </div>
    </template>

    <KeepSelectionDialog
      :visible="keepSelectionDialogVisible"
      @discard-click="handleDiscardSelectionConfirm"
      @add-click="handleAddSelectionConfirm"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { getStoresSearchDefaultParams, storeSetParametersToFilters, storesFiltersToFormParameters } from '@/utils'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import SearchFiltersDynamic from '@/components/StoreSets/SearchFiltersDynamic.vue'
import SearchFiltersStatic from '@/components/StoreSets/SearchFiltersStatic.vue'
import SearchProperties from '@/components/StoreSets/SearchProperties.vue'
import KeepSelectionDialog from '@/components/Assortment/KeepSelectionDialog.vue'
import TableSearch from '@/components/TableSearch.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'
import * as Papa from 'papaparse'

import { useStockManageStore } from '@/store/pinia/stockManageStore'

export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  components: {
    CollapsibleSelect,
    SearchFiltersDynamic,
    SearchFiltersStatic,
    SearchProperties,
    KeepSelectionDialog,
    TableSearch,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    storeSet: {
      type: Object || null,
      default: null,
    },
  },
  data () {
    return {
      searchDebounce: _.debounce(this.searchStores, 2000),
      keepSelectionDialogVisible: false,
      oldStoreSetFilters: [],
      file: null,
      importError: null,
      importSuccess: null,
      uploading: false,
      parsedResults: [],
      resultsTableFilters: [
        {
          value: ''
        }
      ],
      storeSetForm: null,
      storeSetNameError: null,
      storeSetFilters: [],
      storeSetPropsInclude: {},
      storeSetPropsExclude: {},
      showStoresPreview: false,
      storesPreview: [],
      resultsListKey: 0,
      storesKeysFilterLoading: false,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    ...mapState(useStoreAndOptionSetsStore, ['storeSets']),
    storesList () {
      return this.appConfig?.stores?.map(item => item.store_key)
    },
    labelsWithModule (){
      return ['all', 'strategy', 'buy', 'stock', 'pricing']
    },
  },
  watch: {
    storeSet (val) {
      if (val) {
        this.handleStoreSetChange()
      }
    },
  },
  methods: {
    onUploadClick () {
      this.$refs.file.click()
    },
    onFileChange () {
      this.file = this.$refs.file.files[0]
      this.importError = null
      this.importSuccess = null
      this.parsedResults = []

      Papa.parse(this.file, {
        header: true,
        dynamicTyping: true,
        complete: ({ data: results }) => {
          const keys = Object.keys(results[0] || {})
          const key = keys.find(prop => prop.includes('store_key')) || keys[0]
          const parsedData = [...(new Set(results.map((item) => parseInt(item[key]))))]
          this.parsedResults = parsedData.filter(item => this.storesList.indexOf(item) !== -1)
          this.storeSetFilters[0].values = this.parsedResults
          if (!results.length) {
            this.importError = 'No rows found'
          }
        }
      })
    },

    handleCriteriaChanged () {
      this.$refs.searchFilters.validateFilters()
      this.storeSetFilters = [...this.storeSetFilters]
      this.searchDebounce()
    },
    searchStores () {
      this.stockManageStore.setLoadingStoresResults(true)

      if (this.storeSetForm.static_set) {
        this.stockManageStore.getStoresPreviewByCriteria({
          ...getStoresSearchDefaultParams(),
          static_set: true,
          ...this.storeSetFilters.reduce(
            (acc, cur) => ({ ...acc, [cur.type]: cur.values }),
            {}
          )
        })
          .then(response => {
            this.storesPreview = response.stores
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.stockManageStore.setLoadingStoresResults(false)
          })
      } else {
        this.stockManageStore.getStoresPreviewByCriteria({
          ...getStoresSearchDefaultParams(),
          ...this.storeSetForm,
          properties_include: this.storeSetPropsInclude,
          properties_exclude: this.storeSetPropsExclude,
          ...this.storeSetFilters.reduce(
            (acc, cur) => ({ ...acc, [cur.type]: cur.values }),
            {}
          ),
        })
          .then(response => {
            this.storesPreview = response.stores
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.stockManageStore.setLoadingStoresResults(false)
          })
      }

      this.showStoresPreview = true
    },
    handleFilterAdded (filter) {
      this.storeSetFilters.push(filter)
      this.searchDebounce()
    },
    handleFilterRemoved (filter) {
      if (filter.type === 'store_keys_include') {
        filter.values = []
      } else {
        this.storeSetFilters.splice(this.storeSetFilters.indexOf(filter), 1)
      }

      this.handleCriteriaChanged()
    },
    validateStoreSet () {
      this.storeSetNameError = null

      const usedNames = this.storeSets
        .filter(x => x.stores_set_key !== this.storeSetForm.stores_set_key)
        .map(x => x.set_name)

      if (this.storeSetForm.set_name === '' || usedNames.includes(this.storeSetForm.set_name)) {
        this.storeSetNameError = 'Should be unique and not empty'
      }

      return !this.storeSetNameError
    },
    getSavePayload () {
      return {
        ...this.storeSetForm,
        label: this.storeSetForm.label.length ? this.storeSetForm.label : ['all'],
        ...storesFiltersToFormParameters(this.storeSetFilters),
        properties_include: this.storeSetPropsInclude,
        properties_exclude: this.storeSetPropsExclude,
      }
    },
    resetForm () {
      this.showStoresPreview = false
      this.storeSetForm = null
      this.storeSetNameError = null
      this.storeSetFilters = []
      this.storeSetPropsInclude = {}
      this.storeSetPropsExclude = {}
    },
    handleStoreSetChange () {
      const { stores_set_key, set_name, static_set, label, ...criteria } = this.storeSet
      const { org_key, properties_include, properties_exclude, ...filtersParams } = criteria
      const filters = storeSetParametersToFilters(filtersParams)

      filters.forEach(filter => this.storeSetFilters.push(filter))

      this.storeSetForm = { stores_set_key, set_name, static_set, label }
      this.storeSetPropsInclude = properties_include
      this.storeSetPropsExclude = properties_exclude
      this.searchDebounce()
    },
    handlePropIncludeAdded (prop) {
      this.$set(this.storeSetPropsInclude, prop, [])
    },
    handlePropExcludeAdded (prop) {
      this.$set(this.storeSetPropsExclude, prop, [])
    },
    handlePropIncludeChanged ({ oldProp, newProp }) {
      this.$delete(this.storeSetPropsInclude, oldProp)
      this.$set(this.storeSetPropsInclude, newProp, [])
    },
    handlePropExcludeChanged ({ oldProp, newProp }) {
      this.$delete(this.storeSetPropsExclude, oldProp)
      this.$set(this.storeSetPropsExclude, newProp, [])
    },
    handlePropIncludeDeleted (prop) {
      this.$delete(this.storeSetPropsInclude, prop)
      this.handleCriteriaChanged()
    },
    handlePropExcludeDeleted (prop) {
      this.$delete(this.storeSetPropsExclude, prop)
      this.handleCriteriaChanged()
    },
    handlePropIncludeValuesChanged ({ prop, value }) {
      this.$set(this.storeSetPropsInclude, prop, value)
      this.$refs.searchProperties.$forceUpdate()
      this.handleCriteriaChanged()
    },
    handlePropExcludeValuesChanged ({ prop, value }) {
      this.$set(this.storeSetPropsExclude, prop, value)
      this.$refs.searchProperties.$forceUpdate()
      this.handleCriteriaChanged()
    },
    handleAddSelectionConfirm () {
      // perform search to get fresh results
      this.storesKeysFilterLoading = true
      this.stockManageStore.setLoadingStoresResults(true)

      this.stockManageStore.getStoresPreviewByCriteria({
        ...getStoresSearchDefaultParams(),
        ...this.storeSet,
        static_set: false,
        properties_include: this.storeSetPropsInclude,
        properties_exclude: this.storeSetPropsExclude,
        ...this.oldStoreSetFilters.reduce(
          (acc, cur) => ({ ...acc, [cur.type]: cur.values }),
          {}
        ),
      })
        .then(response => {
          this.storesPreview = response.stores
          this.storeSetFilters = [{
            type: 'store_keys_include',
            values: this.storesPreview.map(x => x.store_key),
          }]
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.storesKeysFilterLoading = false
          this.stockManageStore.setLoadingStoresResults(false)
        })

      this.keepSelectionDialogVisible = false
    },
    handleDiscardSelectionConfirm () {
      this.storeSetFilters = [{
        type: 'store_keys_include',
        values: [],
      }]

      this.keepSelectionDialogVisible = false
    },
    handleStaticSetChange () {
      // saving old filter for static filter search request
      this.oldStoreSetFilters = [...this.storeSetFilters]

      // show add selection confirmation if going from dynamic to static set
      if (this.storeSetForm.static_set) {
        this.keepSelectionDialogVisible = true
        this.storeSetFilters = [{
          type: 'store_keys_include',
          values: [],
        }]
      } else {
        this.storeSetFilters = []
      }

      this.storesPreview = []
      this.searchDebounce()
    },
    handleResultsSearchChanged (val) {
      this.resultsTableFilters[0].value = val
      this.resultsListKey++
    },
    handleSearchClick () {
      this.searchStores()
    },
  },
}
</script>
