<template>
  <v-card
    v-if="!isChangeView"
    :flat="flat || inModal"
    ref="dougnutCanvas"
    class="pa-4 pt-2 d-flex flex-column overflow-auto">
    <div class="d-flex align-center ma-0 justify-space-between pb-2" v-if="index !== null && !hide">
      <h4 class="text-no-wrap">{{positionTitle}}. {{ view.view.view_name }}</h4>
      <div class="d-flex show-on-hover flex-nowrap pt-1" v-if="!view.view.errored && !view.errored" >
        <v-icon class="show-on-hover mr-1 action-btn-primary" @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" v-if="!inModal">edit</v-icon>
        <v-icon
          class="mr-1 action-btn-primary"
          @click="$emit('full-screen',view,index)"
          v-if="!inModal"
        >fullscreen</v-icon>
        <data-sets-view-filter :view="view" @change="filterChanged" :index="index" ></data-sets-view-filter>
      </div>

      <template v-else-if="view.view.errored || view.errored" >
        <div class="d-flex show-on-hover flex-nowrap pt-1">
          <v-icon class="show-on-hover action-btn-primary " @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" v-if="!inModal">edit</v-icon>
        </div>
      </template>
    </div>
    <v-overlay absolute color="white" :value="(!view.data) && !(view.view.errored || view.errored)">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-row class="d-flex flex-column align-center justify-center ma-0">
      <img
        :src="view.view.properties.image_url || '@/assets/img/ts_placeholder.png'"
        alt=""
        :style="`max-width: 100%;
        min-height: 200px;
        max-height: ${view.view.properties.maxHeight || 400}px`"
      />
    </v-row>
  </v-card>

  <datasets-new-view
    v-else
    :dashboard-details="dashboardDetails"
    :viewsMap="viewsMap"
    @saved-positions="$emit('saved-positions', $event)"
    :changeView="{...view.view, position}"
    :positions="positions"
    :datasets="datasets"
    :dataSetForChoose="dataSetForChoose"
    @cancel="isChangeView = false; $emit('toggle-view', false)"
    @delete-view="$emit('delete-view',$event)"
    @save="changeView">
  </datasets-new-view>
</template>

<script>
const DatasetsNewView = () => import('@/components/AutomateDatasets/DatasetsNewView')
import DataSetsViewFilter from '@/components/AutomateDatasets/DataSetsViewFilter'

export default {
  name: 'DataSetsImage',
  components: { DataSetsViewFilter, DatasetsNewView },
  props: ['view', 'index', 'positions', 'datasets', 'dataSetForChoose', 'shared', 'hide', 'flat',
          'height', 'inModal', 'secondaryIndex', 'viewsMap', 'dashboardDetails'],
  computed: {
    positionTitle () {
      if(this.dashboardDetails?.view_keys[this.index]?.filter(item=> item).length === 1){
        return this.index + 1
      }
      return this.position
    },
    position () {
      return `${this.index + 1}${['A', 'B', 'C', 'D'][this.secondaryIndex]}`
    },
    mainTitle () {
      return this.view.view.properties.title
    },
    targetTitle () {
      return this.view.view.properties.sub_text
    },
    isTarget () {
      return this.view.view.measures[1]?.name
    },
    title () {
      return 'data_sets_doughnut_chart'
    }
  },
  data () {
    return {
      innerHeight: 370,
      isChangeView: false,
    }
  },
  methods: {
    filterChanged (value) {
      this.changeView({ view: value, position: this.index })
    },
    changeView (value) {
      this.isChangeView = false
      this.$emit('toggle-view', false)
      this.$emit('change-view', value)
    },
  }
}
</script>

<style scoped>

</style>
