import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'
import * as models from '@/models/Tasks'

export async function getTasks (params: models.GetTasksParams): Promise<models.GetTasksResponse> {
  return axios.post(baseURLs.manageAPI + '/tasks', params).then(response => response.data)
}

export async function getRecommendations (endpoint: string, params: models.GetRecommendationsParams) {
  return axios.post(baseURLs.manageAPI + '/data/stock/recommendations/' + endpoint, params).then(response => response.data)
}
