<template>
  <v-row class="strategy-kpi-tiles d-flex flex-wrap justify-space-around mx-n4 px-3">
    <v-col
      cols="5"
      md="2"
      sm="4"
      xs="5"
      v-for="(item, i) in itemsToShow"
      :key="i"
      @click="$emit('tile-clicked', item)"
      class="text-center align-center c-pointer rounded p-relative rounded elevation-2 kpi-tile mx-2 my-2"
    >
      <template v-if="item">
        <div class="mt-1 tile-title">{{ item.title }}</div>
        <div class="mt-2 tile-value">
          <v-text-field
            :key="updatedField"
            v-if="isChangeable"
            class="pt-0 pb-3 change-field"
            hide-details
            @click.stop
            :value="formatMetric(item[topValueKey], item.title, true)"
            @blur="focusedField = null"
            @focus="focusedField = item.title; oldValue = item[topValueKey]"
            @change="handleEnterClick(item, $event)"
          >
          </v-text-field>
          <span v-else>{{ formatMetric(item[topValueKey], item.title) }}</span>
        </div>
        <div class="tile-subtitle-lg" :class="{'mt-2': !isChangeable}">{{ formatMetric(item[bottomValueKey], item.title, false) }}</div>
        <div class="tile-subtitle" :class="{'increase': item.delta > 0, 'decrease': item.delta < 0}">
          ({{ item.delta > 0 ? '+': '' }}{{ item.delta }}%)
        </div>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import {useCubesStore} from '@/store/pinia/cubesStore'

export default {
  name: 'StrategyKPITiles',
  components: {},
  data (){
    return{
      updatedField: 0,
      focusedField: null,
      oldValue: null
    }
  },
  props: {
    kpiItems: {
      type: Array,
      default: () => [],
    },
    topValueKey: {
      type: String,
      default: 'bdg',
    },
    bottomValueKey: {
      type: String,
      default: 'ref',
    },
    // number of tiles to show while loading data
    itemsNumber: {
      type: Number,
      required: true,
    },
    isChangeable: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  setup () {
    return {
      cubesStore: useCubesStore(),
    }
  },
  computed: {
    metricFormats () {
      return this.cubesStore.metricFormat
    },
    itemsToShow () {
      return this.kpiItems.length ? this.kpiItems : new Array(this.itemsNumber)
    },
  },
  methods: {
    formatMetric (value, metricName, isInputField) {
      if(this.isChangeable && isInputField && this.focusedField === metricName){
        return parseFloat(value)
      }
      const format = this.metricFormats[metricName.toLowerCase().replace(' ', '_')]
      if(!format || !this.$options.filters[format]){
        return value
      }
      return this.$options.filters[format](value)
    },
    handleEnterClick (item, value){
      if(this.oldValue === parseFloat(value)){
        return
      }
      item[this.topValueKey] = parseFloat(value)
      this.updatedField ++
      this.$emit('tile-changed', item)
    }
  }
}
</script>

<style lang="scss">
  .strategy-kpi-tiles {

      .change-field .v-input__icon--append-outer {
        display: none;
      }
    .kpi-tile {
      color: var(--text-sub-black);
      background-color: white;
      height: 130px;
      flex-basis: 130px;
      display: inline-block;
      .tile-title {
        font-size: 12px;
        font-weight: 500;
      }
      .tile-value {
        font-size: 16px;
        font-weight: 500;
      }
      .tile-subtitle-lg {
        font-size: 14px;
        font-weight: 500;
      }
      .tile-subtitle {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.375rem;
      }
      .increase {
        color: #4caf50;
      }
      .decrease {
        color: #f44336;
      }
    }

    .change-field {
      color:rgb(96, 98, 102);
      font-size: 13px;
    }
    .change-field input{
      color:rgb(96, 98, 102);
      text-align: center;
    }

    .change-field .v-input__icon--append-outer {
      visibility: hidden;
    }
    .change-field:hover .v-input__icon--append-outer {
      visibility: visible;
    }
  }
  @media (min-width: 1200px) {
    .strategy-kpi-tiles {

      .change-field .v-input__icon--append-outer {
        display: flex;
      }
      .kpi-tile {
        flex-basis: 170px !important;
      }
    }
  }
  @media (min-width: 1600px) {
    .strategy-kpi-tiles {
      .kpi-tile {
        flex-basis: 190px !important;
      }
    }
  }
  @media (min-width: 1900px) {
    .strategy-kpi-tiles {
      .kpi-tile {
        flex-basis: 220px !important;
      }
    }
  }
</style>
