<template>
  <div
    class="strategy-collection-option-card"
    :class="size === 'large' ? 'mr-4' : 'mr-2 mb-2'"
  >
    <template v-if="size === 'large'">
      <div class="d-flex justify-center image-wrapper">
        <img
          :src="option.image_url || '@/assets/img/ts_placeholder.png'"
          draggable="false"
          max-height="110"
          max-width="64"
          @error="loadPlaceholder"
        />
      </div>
      <template v-if="!isPlanned">
        <router-link
          class="option-title-link"
          target="_blank"
          :to="getOptionUrl(option)"
        >
          <div class="option-title">{{ nameParts[0] }}</div>
        </router-link>
      </template>
      <template v-else>
        <div
          class="option-title"
          :class="getStatusColorClass(option, '--text')"
          @click="$emit('product-click')"
        >{{ nameParts[0] }}</div>
      </template>
      <div class="option-subtitle">{{ nameParts[1] }}</div>
      <div
        v-for="(metric, i) in visibleMetrics"
        :key="i"
      >
        <template v-if="['original_value', 'cost_value', 'revenue'].includes(metric.value)">
          <b class="metric-name">{{ getMetricsName(metric.text) }}</b>: {{ option[metricsDict[metric.value]] | currency_no_decimals }}
        </template>
        <template v-else-if="['original_price', 'cost_price'].includes(metric.value)">
          <b class="metric-name">{{ getMetricsName(metric.text) }}</b>: {{ option[metricsDict[metric.value]] | currency }}
        </template>
        <template v-else-if="['initial_margin', 'sellthrough_fp'].includes(metric.value)">
          <b class="metric-name">{{ getMetricsName(metric.text) }}</b>: {{ option[metricsDict[metric.value]] }}%
        </template>
        <template v-else>
          <b class="metric-name">{{ getMetricsName(metric.text) }}</b>: {{ option[metricsDict[metric.value]] ? round(option[metricsDict[metric.value]], 2) : '-' }}
        </template>
      </div>
      <!-- <v-btn
        v-if="isPlanned"
        icon
        @click="$emit('delete-click', option)"
        class="action-icon"
      >
        <v-icon>delete</v-icon>
      </v-btn>
      <v-btn
        v-if="!isPlanned"
        icon
        @click="$emit('add-click', option)"
        class="action-icon"
      >
        <v-icon>add</v-icon>
      </v-btn> -->
    </template>
    <div
      v-if="size === 'small'"
      class="small-card"
      :class="getStatusColorClass(option, '')"
      @click="isPlanned ? $emit('product-click') : openOptionPage(option)"
    >
      <template v-if="['original_value', 'cost_value', 'revenue'].includes(selectedOrder)">
        {{ option[selectedOrder] | currency_no_decimals }}
      </template>
      <template v-else-if="['original_price', 'cost_price'].includes(selectedOrder)">
        {{ option[selectedOrder] | currency }}
      </template>
      <template v-else-if="['initial_margin', 'sellthrough_fp'].includes(selectedOrder)">
        {{ option[selectedOrder] }}%
      </template>
      <template v-else>
        {{ option[selectedOrder] }}
      </template>
    </div>
  </div>
</template>

<script>
import { round } from 'lodash'
import { getStatusColorClass } from '@/utils'
import ts_placeholder from '@/assets/img/ts_placeholder.png'

export default {
  name: 'OptionCard',
  components: {},
  props: {
    option: {
      type: Object,
      required: true,
    },
    visibleMetrics: {
      type: Array,
      default: () => [],
    },
    metricsDict: {
      type: Object,
      default: () => ({}),
    },
    isPlanned: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'large',
    },
    selectedOrder: {
      type: String,
      default: 'pieces',
    },
  },
  data () {
    return {}
  },
  computed: {
    nameParts () {
      return this.option.name.split(' / ')
    }
  },
  methods: {
    round,
    getStatusColorClass,
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    getOptionUrl (row) {
      return {
        name: 'stock-option',
        query: {
          'option_key': row.option_key,
        }
      }
    },
    getMetricsName (input) {
      if (input === 'Original value') {
        return 'Orig. value'
      }

      if (input === 'Initial margin') {
        return 'Init. margin'
      }

      if (input === 'Original price') {
        return 'Orig. price'
      }

      if (input === 'Sellthrough FP') {
        return 'Sellt. FP'
      }

      if (input === 'Potential missed') {
        return 'Pot. missed'
      }

      return input
    },
    openOptionPage (option) {
      const routeData = this.$router.resolve(this.getOptionUrl(option))
      window.open(routeData.href, '_blank')
    },
  },
}
</script>

<style lang="scss">
.strategy-collection-option-card {
  position: relative;
  max-width: 170px;
  .option-title-link {
    color: unset !important;
    text-decoration: none;
  }
  .option-title {
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    &:hover {
      color: var(--primary);
      cursor: pointer;
    }
  }
  .image-wrapper {
    height: 96px;
  }
  .option-subtitle {
    color: var(--text-sub-black) !important;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
  }
  .metric-name {
    text-transform: capitalize;
  }
  .action-icon {
    position: absolute;
    top: 4px;
    right: 4px;
  }
  .small-card {
    width: 60px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    border: 1px solid;
    cursor: pointer;
  }
}
</style>