<template>
  <div class="shop-item-commit-charts">
    <v-row class="ma-0  p-relative justify-space-between align-center">
      <div class="d-flex p-absolute align-center">
        <h4 class="pl-3" >Data</h4>
        <v-tooltip top :max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
          </template>
          <span>
            Shown sales data is depicting the sum of all selected ‘Reorder options’. When this selection is left empty, it will show the average of all selected lookalikes.
          </span>
        </v-tooltip>
      </div>
      <v-col cols="12" class="align-center d-flex justify-center">
        <v-btn-toggle
          v-model="typeIndex"
          dense
          active-class="bg-gray"
        >
          <v-btn
            small
            :key="0"
          >
            <span class="text-capitalize">Graphs</span>
          </v-btn>
          <v-btn
            small
            :key="1"
          >
            <span class="text-capitalize">Table</span>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row v-if="chartsData" class="mt-0">
      <v-col class="pt-0">

        <v-col cols="12" class="align-center d-flex justify-center">
          <v-btn-toggle
            v-model="chartsIndex"
            @change="tableFilters = []"
            dense
            active-class="bg-gray"
          >
            <v-btn
              small
              :key="0"
            >
              <span class="text-capitalize">Weeks</span>
            </v-btn>
            <v-btn
              small
              :key="1"
            >
              <span class="text-capitalize">Stores</span>
            </v-btn>
            <v-btn
              small
              :key="2"
            >
              <span class="text-capitalize">Skus</span>
            </v-btn>
            <v-btn
              v-if="chartsData.week_number_ly_data?.data"
              small
              :key="3"
            >
              <span class="text-capitalize">Historic</span>
            </v-btn>
          </v-btn-toggle>

        </v-col>
        <v-carousel
          hide-delimiter-background
          light
          v-model="chartsIndex"
          height="auto"
          hide-delimiters
          class="pb-15"
          delimiter-icon="circle"
          :perPage="1"
          :show-arrows="false"
        >
          <template v-if="typeIndex === 0">
            <!-- Charts -->
            <v-carousel-item
              v-for="(_, key) in  chartsConfig"
              :key="key"
              eager
            >
              <v-row class="ma-0 pa-1 pr-2 justify-end">
                <data-export @item-click="downloadTableData"/>
                <v-menu offset-y offset-x nudge-bottom="5" :close-on-content-click="false" v-if="chartsIndex !== 3">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on"
                    >filter_list
                    </v-icon>
                  </template>
                  <v-card max-height="400" class="overflow-auto">
                    <h5 class="px-2 pt-2">Columns</h5>
                    <el-checkbox-group :value="selectedLines" label="name" @input="saveSelectedLines" style="font-size: 14px"
                      class="d-flex flex-column pa-2">
                      <el-checkbox v-for="item of columns" :label="item" :key="item">
                      </el-checkbox>
                    </el-checkbox-group>
                  </v-card>
                </v-menu>
              </v-row>
              <template v-if="key !== 'week_number_ly_data'">
                <div class="px-12" :style="{
                  position: 'relative',
                  width: '100%',
                  height: '380px'
                }">
                  <canvas :id="key" :key="key + typeIndex" />
                </div>
              </template>
              <template v-else>
                <ChartElement
                  title="shop_item_lookalike"
                  id="shop_item_lookalike"
                  :chartData="lookalikeChartData"
                  :params="{}"
                  forcedHeight="380px"
                  :padding="true"
                  :fixedHeight="false"
                />
              </template>
            </v-carousel-item>
          </template>
          <template v-else>
            <!-- Tables -->
            <v-carousel-item
              v-for="(config, key) in chartsConfig"
              :key="key"
            >
              <v-row class="p-relative align-center justify-end ma-0 pr-2 pb-2">

                <TableSearch
                  :key="chartsIndex"
                  class="mx-2"
                  @searchChanged="val => handleSearchChanged('tableFilters', filterColumns(key), val)"
                />
                <data-export @item-click="downloadTableData"/>

                <v-menu offset-y offset-x nudge-bottom="5" :close-on-content-click="false" v-if="chartsIndex !== 3">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on"
                    >filter_list
                    </v-icon>
                  </template>
                  <v-card max-height="400" class="overflow-auto">
                    <h5 class="px-2 pt-2">Columns</h5>
                    <el-checkbox-group :value="selectedColumns" label="name" @input="saveSelectedColumns" style="font-size: 14px"
                      class="d-flex flex-column pa-2">
                      <el-checkbox v-for="item of columns" :label="item" :key="item">
                      </el-checkbox>
                    </el-checkbox-group>
                  </v-card>
                </v-menu>
              </v-row>
              <data-tables
                v-if="tableData.length && chartsIndex !== 3 && chartsData[key]"
                :data="tableData"
                :key="tableKey"
                :page-size="10"
                @row-click="(row)=> handleRowClick(config, row, key)"
                class="beliefs-table"
                :table-props="{
                  'summary-method': getSummary,
                  'show-summary': true,
                }"
                :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
                :filters="tableFilters"
                filter-multiple
                @filter-change="filters => handleFilterChange('tableFilters', filters)"
              >
                <el-table-column
                  class-name="px-4"
                  :column-key="chartsData[key]?.columns[0]"
                  :prop="chartsData[key]?.columns[0]"
                  :label="chartsData[key]?.columns[0].split('-')[0] | formatString" sortable="custom">
                  <template slot-scope="scope">
                    <span v-if="chartsData[key]?.columns[0].includes('store')">
                      {{storesNames[scope.row[chartsData[key]?.columns[0]]]}}
                    </span>
                    <span v-else-if="chartsData[key]?.columns[0].includes('week')">
                      {{parseWeek(scope.row[chartsData[key]?.columns[0]])}}
                    </span>
                    <span v-else>
                      {{scope.row[chartsData[key]?.columns[0]]}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  class-name="px-4"
                  v-for="column of selectedColumns.filter(columnItem=> columnItem !== 'Share')"
                  :key="columnProps[column]"
                  :column-key="columnProps[column]"
                  :prop="columnProps[column]"
                  :label="column" sortable="custom">
                  <template slot-scope="scope" >
                    <span v-if="column === 'Factor'" :style="`color: ${interpolateColors((scope.row['belief'] / scope.row['potential']).toFixed(2))}`">

                      {{(scope.row['belief'] / scope.row['potential']).toFixed(2)}}
                    </span>
                    <span v-else>
                      {{scope.row[columnProps[column]]}}
                      <span v-if="isShareSelected && column !== 'Factor' && column !== 'Belief Depth'&& !column.toLowerCase().includes('index') ">
                        {{formatPercents(scope.row[columnProps[column]] /  (sumData[chartsIndex] || {})[columnProps[column]])}}
                      </span>
                    </span>
                  </template>
                </el-table-column>
              </data-tables>
              <data-tables
                v-else-if="tableData.length"
                :data="tableData"
                :key="tableKey"
                :page-size="10"
                class="beliefs-table"
                :table-props="{
                  'summary-method': getSummary,
                  'show-summary': true,
                }"
                :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
                :filters="tableFilters"
                filter-multiple
                @filter-change="filters => handleFilterChange('tableFilters', filters)"
              >
                <el-table-column
                  class-name="px-4"
                  v-for="column of chartsData[key]?.columns"
                  :key="column"
                  :column-key="column"
                  :prop="column"
                  :label="column | formatString" sortable="custom">
                  <template slot-scope="scope" >
                    <span v-if="column !=='sellthrough'">
                      {{scope.row[column]}}
                    </span>
                    <span v-else>
                      {{scope.row[column] | formatPercents}}%
                    </span>
                  </template>
                </el-table-column>
              </data-tables>
            </v-carousel-item>
          </template>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {Chart} from 'chart.js/auto'
import columnFilters from '../../mixins/columnFilters'
import {chartColors, parseWeek} from '@/variables'
import _, {round} from 'lodash'
import TableSearch from '@/components/TableSearch.vue'
import ChartElement from '../../chart/ChartElement'
import interpolate from 'color-interpolate'
import DataExport from '@/components/DataExport'
import {downloadData} from '@/utils'

export default {
  mixins: [columnFilters],
  components: { DataExport, ChartElement, TableSearch },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    dummy: {
      type: Object,
      default: () => ({}),
    },
    chartsData: {
      type: Object,
      default: () => ({}),
    },
    lookalikeData: {
      type: Object,
      default: () => ({}),
    },
    stores: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    this.chartsElements = {}

    return {
      chartsIndex: 0,
      typeIndex: 0,
      tableKey: 0,
      sumData: {},
      tableData: [],
      selectedColumns: JSON.parse(localStorage.getItem('commit-carousel-columns')) || [
        'Potential',
        'Belief',
        'Factor',
        'Sales LY',
        'Sales LY + Missed',
        'Index LY',
        'Committed (Gross)',
        'Share'
      ],
      selectedLines: JSON.parse(localStorage.getItem('commit-carousel-lines')) || [
        'Potential',
        'Belief',
        'Sales LY + Missed',
      ],
      tableFilters: [],
      onBeliefsDebounce: _.debounce(this.onBeliefsChange, 500),
      chartsConfig: {
        week_number_data: {
          name: 'Week',
          xAxisKey: 'week_number',
          xAxisTitle: 'Weeks',
        },
        store_keys_data: {
          name: 'Store',
          xAxisKey: 'store_keys',
          xAxisTitle: 'Stores',
        },
        sku_names_data: {
          name: 'Sku',
          xAxisKey: 'sku_names',
          xAxisTitle: 'SKUs',
        },
        week_number_ly_data: {
          name: 'Historic Compare',
          xAxisKey: 'week_number',
          xAxisTitle: 'Weeks',
        },
      },
    }
  },
  computed: {
    isShareSelected () {
      return this.selectedColumns.includes('Share')
    },
    columns () {
      const columns = [
        'Potential',
        'Belief',
        'Belief Depth',
        'Sales LY',
        'Sales LY + Missed',
        'Index LY',
        'Prices LY',
        'Sales LLY',
        'Sales LLY + Missed',
        'Index LLY',
        'Prices LLY',
        'Committed (Gross)',
        'Returns',
        'Committed',
      ]
      if (this.typeIndex === 1) {
        columns.splice(2, 0, 'Factor')
        columns.splice(columns.length - 1, 0, 'Share')
      }
      return columns
    },
    columnProps () {
      if (this.chartsIndex === 3) {
        return {}
      }
      return {
        'Potential': 'potential',
        'Belief': 'belief',
        'Factor': 'factor',
        'Belief Depth': 'belief_depth',
        'Sales LY': 'sales_ly',
        'Prices LY': 'prices_ly',
        'Sales LY + Missed': 'sales_missed_ly',
        'Sales LLY': 'sales_lly',
        'Sales LLY + Missed': 'sales_missed_lly',
        'Index LY': 'index_ly',
        'Index LLY': 'index_lly',
        'Prices LLY': 'prices_lly',
        'Committed (Gross)': 'committed_gross',
        'Returns': 'returns',
        'Committed': 'committed',
        'Share': 'share',
      }
    },
    lookalikeChartData () {
      const data = this.chartsData.week_number_ly_data?.data
      if (!data) {
        return []
      }
      data.weeks = data.week_numbers
      return data
    },
    storesNames () {
      return this.stores.reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    summaryData () {
      const key = Object.keys(this.chartsConfig)[this.chartsIndex]
      const columns = (key !== 'week_number_ly_data' ? ['week_number', ...this.columns.map(item => item.prop)] : this.chartsData[key].columns)

      const props = {}

      const sums = {}
      columns.forEach((column, index) => {
        if (!index) {
          sums[column] = 'Total'
          return
        }
        const sum = round(this.tableData.reduce((acc, cur) => acc + cur[column], 0), 2)
        sums[column] = sum
        props[column] = sum
        if (column === 'factor') {
          sums[column] = (props['belief'] / props['potential']).toFixed(2)
        }
      })
      return [sums]
    },
    selectedRows () {
      return this.tableData.filter(row => row.selected)
    },
  },
  watch: {
    chartsIndex: {
      handler () {
        this.setData()
        this.tableKey = 0
      },
      immediate: true,
    },
    chartsData: {
      handler () {
        this.$nextTick(() => {
          if (!this.typeIndex) {
            this.drawCharts()
          }
          this.setData()
        })
      },
      immediate: true
    },
    'item.reorder_option_keys': {
      handler () {
        this.$nextTick(() => {
          this.drawCharts()
          this.setData()
        })
      },
    },
    typeIndex () {
      if (this.typeIndex) {
        this.$nextTick(() => {
          this.$el.querySelector('.el-table__footer-wrapper')?.addEventListener('click', () => {
            this.$emit('item-click', {
              type: 'total',
              value: {},
            })
          })
        })
        return
      }
      this.$nextTick(() => {
        this.drawCharts()
      })
    },
  },
  methods: {
    filterColumns (key) {
      return [...this.chartsData[key].columns, 'storeName', 'parsedWeek', 'factor']
    },
    downloadTableData (sep) {
      const names = ['Weeks', 'Stores', 'Skus', 'Historic']
      downloadData(sep, this.tableData, `ShopCommit${names[this.chartsIndex]}`)
    },
    interpolateColors (value) {
      value = parseFloat(value)
      if (value < 1.1 && value > 0.9) {
        return 'rgb(96, 98, 102)'
      }
      if (value <= 0.9) {
        return interpolate(['#ff5721'])(1 - value)
      }
      if (value >= 1.1) {
        return interpolate(['#4caf50'])(value - 1)
      }
    },
    formatPercents (value) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })
      return `(${formatter.format(value * 100 || 0) + '%'})`
    },
    saveSelectedColumns (value) {
      this.selectedColumns = this.columns.filter(column => value.includes(column))
      localStorage.setItem('commit-carousel-columns', JSON.stringify(this.selectedColumns))
    },
    saveSelectedLines (value) {
      this.selectedLines = this.columns.filter(column => value.includes(column))
      localStorage.setItem('commit-carousel-lines', JSON.stringify(this.selectedLines))
      this.drawCharts()
    },
    setData () {
      if (!this.chartsData) {
        return
      }
      const key = Object.keys(this.chartsConfig)[this.chartsIndex]
      const chartData = this.chartsData[key]
      if (!chartData?.columns || !chartData?.data) {
        return []
      }
      const data = []
      for (let i = 0; i < (chartData?.data[chartData?.columns[0]] || []).length; i++) {
        const row = {}
        for (const column of chartData.columns) {
          if (!chartData.data[column]) {
            row[column] = ''
            continue
          }
          row[column] = chartData.data[column][i]
          if (chartData.columns[0].includes('store')) {
            row.storeName = this.storesNames[row[chartData.columns[0]]]
          } else if (chartData.columns[0].includes('week')) {
            row.parsedWeek = this.parseWeek(row[chartData.columns[0]])
          }
          row.selected = false
          if (this.chartsIndex !== 3) {
            row.factor = (chartData.data['belief'][i] / chartData.data['potential'][i] || 0)
          }
        }
        data.push(row)
      }

      this.tableData = data
      if (this.typeIndex) {
        setTimeout(() => {
          for (const element of this.$el.querySelectorAll('.el-table__footer-wrapper')) {
            element?.addEventListener('click', () => {
              this.$emit('item-click', {
                type: 'total',
                value: {},
              })
            })
          }
        }, 1000)
      }
    },
    parseWeek,
    handleSelectRowChange (value, row) {
      this.$set(row, 'selected', value)
    },
    handleDeleteClick (config) {
      this.$emit('delete-beliefs', { config, rows: this.selectedRows.map(item => item[config.xAxisKey]) })
    },
    onBeliefsChange (config, row, key, $event) {
      if ($event < 0) {
        return
      }
      let value
      if (row === 'total') {
        key = 'total'
        value = 'total'
      } else {
        value = row[config.xAxisKey]
      }
      this.$emit('item-change', {
        type: key,
        value,
        absolute: $event
      })
    },
    handleRowClick (config, row, key) {
      if (key === 'week_number_ly_data') {
        return
      }
      this.$emit('item-click', {
        type: key,
        value: row[config.xAxisKey],
        isNew: true
      })
    },
    getSummary (params) {
      const sums = []
      const props = {}
      const key = Object.keys(this.chartsConfig)[this.chartsIndex]
      const columnsList = this.chartsData[key]?.columns
      const { columns } = params
      let factorIndex = 0
      let salesLYIndex = 0
      let salesLLYIndex = 0
      if (columns.length < 2) {
        return []
      }
      columns.forEach((column, index) => {
        if (!index) {
          sums[index] = 'Total'
          return
        }
        const hiddenColumns = ['index', 'belief_depth', 'sellthrough', 'belief_index', 'stock']
        if (hiddenColumns.includes(column.property?.toLowerCase())) {
          sums[index] = ''
          return
        }
        sums[index] = round(this.tableData.reduce((acc, cur) => acc + (cur ? cur[column.property] : 0), 0), 2)
        if (column.property === 'factor') {
          factorIndex = index
        }
        if (column.property === 'index_ly') {
          salesLYIndex = index
        }
        if (column.property === 'index_lly') {
          salesLLYIndex = index
        }
      })
      columnsList.forEach((column)=>{
        props[column] = round(this.tableData.reduce((acc, cur) => acc + (cur ? cur[column] : 0), 0), 2)
      })
      if (factorIndex) {
        sums[factorIndex] = (props['belief'] / props['potential']).toFixed(2)
        props.factor = sums[factorIndex]
      }
      if(salesLYIndex) {
        sums[salesLYIndex] = (props['belief'] / props['sales_ly'] * 100).toFixed(0)
        props['index_ly'] = sums[salesLYIndex]
      }
      if(salesLLYIndex) {
        sums[salesLLYIndex] = (props['belief'] / props['sales_lly'] * 100).toFixed(0)
        props['index_lly'] = sums[salesLLYIndex]
      }
      if (this.chartsIndex !== 3 && Object.keys(props).length > 1) {
        this.sumData[this.chartsIndex] = { ...props }
        if (this.tableKey < 1) {
          this.tableKey++
        }
      }
      return sums
    },
    drawCharts () {
      if (!this.chartsData) {
        return
      }

      Object.keys(this.chartsConfig).forEach(name => {
        const ctx = document.getElementById(name)
        if (!ctx) {
          return
        }
        const datasets = [
        ]
        this.selectedLines.forEach((line, index) => {
          switch (line) {
          case 'Potential':
            datasets.push({
              label: 'Potential',
              borderDash: [10, 5],
              pointRadius: 0,
              data: this.chartsData[name].data.potential,
              borderColor: '#FFD202',
              borderWidth: 2,
              pointHitRadius: 20,
              pointHoverRadius: 4,
              backgroundColor: name === 'week_number_data' ? 'transparent' : '#ffd2021a',
            },
            )
            break
          case 'Belief':
            datasets.push({
              label: 'Belief',
              data: this.chartsData[name].data.belief,
              backgroundColor: 'transparent',
              borderColor: '#FF9800',
              borderWidth: 2,
              pointHoverRadius: 4,
              pointHitRadius: 20,
              pointRadius: 0,
            })
            break
          case 'Sales LY + Missed':
            datasets.push({
              label: 'Sales LY + Missed',
              type: 'line',
              borderDash: name === 'week_number_data' ? [10, 5] : undefined,
              data: this.chartsData[name].data.sales_missed_ly,
              borderColor: '#4CAF50',
              borderWidth: 2,
              showLine: name === 'week_number_data',
              pointRadius: name === 'week_number_data' ? 0 : 4,
              pointHitRadius: 20,
              pointHoverRadius: 4,
              backgroundColor: 'transparent',
            })
            break
          case 'Sales LY':
            datasets.push({
              label: 'Sales LY',
              borderDash: name === 'week_number_data' ? undefined : [10, 5],
              data: this.chartsData[name].data.sales_ly,
              borderColor: '#4CAF50',
              borderWidth: 2,
              pointRadius: name === 'week_number_data' ? 0 : 4,
              pointHoverRadius: 4,
              showLine: name === 'week_number_data',
              pointHitRadius: 20,
              backgroundColor: 'transparent',
            })
            break
          case 'Sales LLY':
            datasets.push({
              label: 'Sales LLY',
              data: this.chartsData[name].data.sales_lly,
              borderColor: '#69b2ab',
              borderWidth: 2,
              pointHitRadius: 20,
              pointRadius: 0,
              yAxisID: 'y',
              pointHoverRadius: 4,
              backgroundColor: 'transparent',
            })
            break
          case 'Belief Depth':
            datasets.push({
              label: 'Belief Depth',
              data: this.chartsData[name].data.belief_depth,
              borderColor: '#9BE48E',
              borderWidth: 2,
              pointRadius: 0,
              pointHitRadius: 20,
              yAxisID: 'y',
              pointHoverRadius: 4,
              backgroundColor: 'transparent',
            })
            break
          case 'Index LY':
            datasets.push({
              label: 'Index LY',
              data: this.chartsData[name].data.index_ly,
              borderColor: '#F989D0',
              borderWidth: 2,
              pointRadius: 0,
              pointHitRadius: 20,
              yAxisID: 'y',
              pointHoverRadius: 4,
              backgroundColor: 'transparent',
            })
            break
          case 'Sales LLY + Missed':
            datasets.push({
              label: 'Sales LLY + Missed',
              type: 'line',
              borderDash: name === 'week_number_data' ? [10, 5] : undefined,
              data: this.chartsData[name].data.sales_missed_lly,
              borderColor: '#69b2ab',
              borderWidth: 2,
              showLine: name === 'week_number_data',
              pointRadius: name === 'week_number_data' ? 0 : 4,
              pointHoverRadius: 4,
              pointHitRadius: 20,
              yAxisID: 'y',
              backgroundColor: 'transparent',
            })
            break
          case 'Index LLY':
            datasets.push({
              label: 'Index LY',
              type: 'line',
              borderDash: name === 'week_number_data' ? [10, 5] : undefined,
              borderWidth: 2,
              showLine: name === 'week_number_data',
              pointRadius: name === 'week_number_data' ? 0 : 4,
              pointHoverRadius: 4,
              pointHitRadius: 20,
              yAxisID: 'y',
              backgroundColor: 'transparent',
              data: this.chartsData[name].data.index_lly,
              borderColor: '#F989D0',
            })
            break
          case 'Committed (Gross)':
            datasets.push({
              label: 'Committed (Gross)',
              data: this.chartsData[name].data.committed_gross,
              borderColor: '#EE3766',
              borderWidth: 2,
              pointRadius: 0,
              pointHitRadius: 20,
              yAxisID: 'y',
              pointHoverRadius: 4,
              backgroundColor: 'transparent',
            })
            break
          case 'Returns':
            datasets.push({
              label: 'Returns',
              data: this.chartsData[name].data.returns,
              borderColor: '#765397',
              borderWidth: 2,
              pointRadius: 0,
              pointHitRadius: 20,
              yAxisID: 'y',
              pointHoverRadius: 4,
              backgroundColor: 'transparent',
            })
            break
          case 'Committed':
            datasets.push({
              label: 'Committed',
              data: this.chartsData[name].data.committed,
              borderColor: '#FF675B',
              borderWidth: 2,
              pointRadius: 0,
              pointHitRadius: 20,
              yAxisID: 'y',
              pointHoverRadius: 4,
              backgroundColor: 'transparent',
            })
            break
          default:
            datasets.push({
              label: line,
              data: this.chartsData[name].data[line.toLowerCase().replaceAll(' ', '_')],
              borderColor: chartColors[index],
              borderWidth: 2,
              pointRadius: 0,
              pointHitRadius: 20,
              yAxisID: 'y',
              pointHoverRadius: 4,
              backgroundColor: 'transparent',
            })
            break
          }
        })

        if (this.chartsElements[name]) {
          this.chartsElements[name].destroy()
        }
        const type = name === 'week_number_data' ? 'line' : 'bar'

        this.chartsElements[name] = new Chart(ctx, {
          type,
          data: {
            labels: this.chartsData[name].data[this.chartsConfig[name].xAxisKey],
            datasets
          },
          options: {
            maintainAspectRatio: false,
            animation: {
              duration: 0,
            },
            tension: 0.2,
            scales: {
              x: {
                stacked: true,
                title: {
                  display: true,
                  text: this.chartsConfig[name].xAxisTitle,
                  font: {
                    size: 14,
                  },
                  padding: {
                    top: 15,
                    bottom: 10,
                  },
                },
                grid: {
                  display: name !== 'week_number_data'
                },
                ticks: {
                  callback: (value, index) => {
                    const realValue = this.chartsData[name].data[this.chartsConfig[name].xAxisKey][index]

                    if (name === 'week_number_data') {
                      return this.$options.filters.formatWeekNumber(realValue)
                    }

                    if (name === 'store_keys_data') {
                      return this.storesNames[realValue] ? this.storesNames[realValue].substring(0, 10) : null
                    }

                    return realValue
                  }
                },
                offset: true,
              },
              y: {
                min: name === 'week_number_data' ? 0 : undefined,
                title: {
                  display: true,
                  font: {
                    size: 14,
                  },
                  text: 'Amount',
                },
                border: {
                  display: false,
                },
              },
            },
            onHover: (event, activeEls) => {
              event.native.target.style.cursor = activeEls.length ? 'pointer' : 'default'
            },
            onClick: (event, activeEls) => {
              if (activeEls.length) {
                this.$emit('item-click', {
                  type: name,
                  value: this.chartsData[name].data[this.chartsConfig[name].xAxisKey][activeEls[0].index],
                })
              }
            },
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  usePointStyle: true,
                  generateLabels: (chart) => {
                    const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)
                    labels.forEach((label)=> {
                      const dashedLabels = ['Potential']
                      if(name === 'week_number_data'){
                        dashedLabels.push('Sales LY + Missed')
                        dashedLabels.push('Sales LLY + Missed')
                        dashedLabels.push('Index LY')
                      }else {
                        dashedLabels.push('Sales LY')
                      }
                      if(dashedLabels.includes(label.text) ){
                        label.lineDash = [5, 5]
                      }
                    })

                    return labels
                  },
                },
              },
              tooltip: {
                callbacks: {
                  title: tooltipItem => {
                    if (name === 'week_number_data') {
                      return this.$options.filters.formatWeekNumber(tooltipItem[0].label)
                    }

                    if (name === 'store_keys_data') {
                      return this.storesNames[tooltipItem[0].label]
                    }

                    return tooltipItem[0].label
                  },
                  label: context => {
                    let label = context.dataset.label || ''

                    if (label) {
                      label += ': '
                    }

                    if (context.parsed.y !== null) {
                      label += new Intl.NumberFormat('en-US').format(context.parsed.y)
                    }

                    return label
                  }
                }
              },
            },
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
.shop-item-commit-charts {
  .beliefs-table {
    .el-table .cell {
      overflow: visible;
    }
  }
  .append-total-commit{
    .el-table--enable-row-hover .el-table__body tr>td {
      background-color: #f5f7fa;
    }
  }
}
</style>
