<template>
  <div class="buy-shop-items">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2"/>
    </v-overlay>
    <v-row>
      <slot name="title"></slot>
      <v-col class="text-right pr-6 pb-0">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('itemsTableFilters', columnsToSearch, val)"
        />
        <v-icon
          class="mx-2 action-btn-primary"
          :disabled="marginCalculatorDisabled"
          @click="handleMarginIconClick"
        >calculate
        </v-icon>
        <v-icon
          class="mx-2 action-btn-primary"
          :disabled="marginCalculatorDisabled"
          @click="handleEditClick"
        >edit
        </v-icon>
        <v-icon
          class="mx-2 action-btn-primary"
          @click="handleAddIconClick"
          :disabled="selectedItemsRows.length >1"
        >add
        </v-icon>
        <v-icon
          class="mx-2 action-btn-primary"
          :disabled="!selectedItemsRows.length"
          @click="handledeleteIconClick"
        >delete
        </v-icon>
        <v-menu offset-y offset-x left nudge-bottom="5" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on"
            >filter_list
            </v-icon>
          </template>
          <v-card max-height="400" class="overflow-auto">
            <h5 class="px-2 pt-2">Columns</h5>
            <el-checkbox-group v-model="selectedColumns" @input="saveSelectedColumns" style="font-size: 14px"
              class="d-flex flex-column pa-2">
              <el-checkbox v-for="item of columnsToSelect" :label="item" :key="item"></el-checkbox>
            </el-checkbox-group>
            <h5 class="px-2">Order parameters</h5>
            <el-checkbox-group v-model="orderColumns" @input="saveOrderColumns" style="font-size: 14px"
              class="d-flex flex-column pa-2">
              <el-checkbox v-for="item of sortedOrderColumns" :label="item" :key="item"></el-checkbox>
            </el-checkbox-group>
            <h5 class="px-2">Properties</h5>
            <el-checkbox-group v-model="additionalColumns" @input="saveProperties" style="font-size: 14px"
              class="d-flex flex-column pa-2">
              <el-checkbox v-for="item of sortedDummyProperties" :label="item" :key="item"></el-checkbox>
            </el-checkbox-group>
          </v-card>
        </v-menu>

      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h4 class="text-no-wrap px-6 mb-4">{{ selectedItemsRows.length }} line(s) selected
          <span v-if="selectedItemsRows.length > 1">(changes are applied to all selected rows)</span>
        </h4>
        <data-tables
          style="width: 100%"
          :data="items"
          :page-size="shopItemPageSize"
          @update:pageSize="onShopItemsPageSizeChange"
          :current-page="shopItemCurrPage"
          @update:currentPage="shopItemCurrPage =  $event"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 mb-4 mr-2'
          }"
          :table-props="{ 'row-key': 'shop_item_key', 'row-class-name': isSpecial }"
          :filters="itemsTableFilters"
          filter-multiple
          @filtered-data="filteredData = $event"
          @filter-change="handleFiltersChange">
          <data-tables-column prop="shop_item_key" width="55">
            <template v-slot:header>
              <v-row class="ma-0 flex-nowrap">
                <v-simple-checkbox
                  color="primary"
                  :value="allSelectedItems"
                  :ripple="false"
                  dense
                  hide-details
                  class="ml-3"
                  @input="handleSelectAllItemsChange"
                />
              </v-row>
            </template>
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-3 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectItemRowChange($event, scope.row)"
              />
            </template>
          </data-tables-column>
          <data-tables-column prop="name"
            class-name="name-column"
            label="Name" sortable column-key="name" :filter-method="equalsFilter"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'name')[0]?.value"
            :filters="getColumnFilterValues('items', 'name')"
          >
            <template slot-scope="scope">
              <OptionDataCell
                :title="scope.row.name"
                :option="scope.row"
                @image-click="$router.push(getShopPageUrl(scope.row.shop_item_key))"
                :url="getShopPageUrl(scope.row.shop_item_key)"
                name-key="name"
              />
            </template>
          </data-tables-column>
          <data-tables-column :prop="item" :label="formatString(item)" sortable="custom"
            :key="item"
            :column-key="item"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === item)[0]?.value"

            :filters="getColumnFilters(items, item)"

            v-for="item in additionalColumns">
            <template slot-scope="scope">
              {{ scope.row.properties[item] }}
            </template>
          </data-tables-column>

          <data-tables-column
            v-if="orderColumns.includes('Split orders')"
            prop="split_orders"
            sortable
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'split_orders')[0]?.value"
            column-key="split_orders"
            label="Split Orders"
            :filters="getColumnFilterValues('items', 'split_orders')"
          >
            <template slot-scope="scope">
              <v-switch
                :input-value="scope.row.order_settings.split_orders"
                @change="orderParamsChanged(scope.row, 'split_orders',$event)"
              ></v-switch>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="orderColumns.includes('Stock min')"
            :filters="getColumnFilterValues('items', 'stock_min')"
            prop="stock_min"
            sortable
            column-key="stock_min"
            label="Stock min"
          >
            <template slot-scope="scope">
              <v-text-field
                :value="scope.row.order_settings.stock_min"
                type="number"
                :error-messages="!validateOrderSettings(scope.row, 'stock_min') ? 'Must be integer greater or equal to 0' : ''"
                class="d-inline-block pt-0 mt-0 mr-2"
                @change="!validateOrderSettings(scope.row, 'stock_min') ? ()=> {} :orderParamsChanged(scope.row, 'stock_min',parseFloat($event))"
              />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="orderColumns.includes('MOQ')"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'moq')[0]?.value"
            :filters="getColumnFilterValues('items', 'moq')"
            prop="moq"
            sortable
            column-key="moq"
            label="MOQ"
          >
            <template slot-scope="scope">
              <v-text-field
                v-model.number="scope.row.order_settings.moq"
                type="number"
                :error-messages="!validateOrderSettings(scope.row, 'moq') ? 'Must be integer greater or equal to 0' : ''"
                class="d-inline-block pt-0 mt-0 mr-2"
                @change="!validateOrderSettings(scope.row, 'moq') ?()=>{} : orderParamsChanged(scope.row, 'moq',parseFloat($event))"
              />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="orderColumns.includes('Cover min')"
            :filters="getColumnFilterValues('items', 'cover_min')"
            prop="cover_min"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'cover_min')[0]?.value"
            sortable
            column-key="cover_min"
            label="Cover min"
          >
            <template slot-scope="scope">
              <v-text-field
                v-model.number="scope.row.order_settings.cover_min"
                type="number"
                :error-messages="!validateOrderSettings(scope.row, 'cover_min') ? 'Must be integer greater or equal to 0' : ''"
                class="d-inline-block pt-0 mt-0 mr-2"
                @change="!validateOrderSettings(scope.row, 'cover_min') ? ()=> {} : orderParamsChanged(scope.row, 'cover_min',parseFloat($event))"
              />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="orderColumns.includes('Cover max')"
            prop="cover_max"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'cover_max')[0]?.value"
            sortable
            :filters="getColumnFilterValues('items', 'cover_max')"
            column-key="cover_max"
            label="Cover max"
          >
            <template slot-scope="scope">
              <v-text-field
                v-model.number="scope.row.order_settings.cover_max"
                type="number"
                :error-messages="!validateOrderSettings(scope.row, 'cover_max') ? 'Must be integer greater or equal to 0' : ''"
                class="d-inline-block pt-0 mt-0 mr-2"
                @change="!validateOrderSettings(scope.row, 'cover_max')  ? ()=> {} : orderParamsChanged(scope.row, 'cover_max',parseFloat($event))"
              />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="orderColumns.includes('Box size')"
            prop="box_size"
            sortable
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'box_size')[0]?.value"
            :filters="getColumnFilterValues('items', 'box_size')"
            column-key="box_size"
            label="Box size"
          >
            <template slot-scope="scope">
              <v-text-field
                v-model.number="scope.row.order_settings.box_size"
                type="number"
                :error-messages="!validateOrderSettings(scope.row, 'box_size')  ? 'Must be integer greater or equal to 0' : ''"
                class="d-inline-block pt-0 mt-0 mr-2"
                @change="!validateOrderSettings(scope.row, 'box_size')  ? ()=> {} : orderParamsChanged(scope.row, 'box_size',parseFloat($event))"
              />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="orderColumns.includes('Sellthrough')"
            prop="sellthrough"
            :filters="getColumnFilterValues('items', 'sellthrough')"
            sortable="custom"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'sellthrough')[0]?.value"
            column-key="sellthrough"
            label="Sellthrough"
          >
            <template slot-scope="scope">
              <v-text-field
                :value="sellthroughValue(scope.row)"
                @input="scope.row.order_settings.sellthrough = parseFloat($event / 100)"
                type="number"
                :error-messages="validateSellthrough(scope.row) ? '' : 'Must be integer between 1 and 100'"
                class="d-inline-block pt-0 mt-0 mr-2"
                prefix="%"
                @change="validateSellthrough(scope.row) ? orderParamsChanged(scope.row, 'sellthrough',parseFloat($event / 100)) : ()=> {}"
              />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Start')"
            :filters="getColumnFilterValues('items', 'week_min_formatted')"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'week_min_formatted')[0]?.value"
            prop="week_min_formatted"
            column-key="week_min_formatted"
            label="Start"
            sortable="custom"
          >
            <template slot-scope="scope">
              <WeekSelector
                class="table-field"
                v-model="scope.row.week_min"
                :start-year="0"
                only-future
                hasNoWeekItem
                @input="propsChanged($event, 'week_min',scope.row, scope.$index)"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"/>
            </template>
          </data-tables-column>
          <data-tables-column prop="week_max_formatted" label="End" sortable="custom"
            column-key="week_max_formatted"
            :filters="getColumnFilterValues('items', 'week_max_formatted')"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'week_max_formatted')[0]?.value"
            v-if="selectedColumns.includes('End')">
            <template slot-scope="scope">
              <WeekSelector
                class="table-field"
                v-model="scope.row.week_max"
                :start-year="0"
                only-future
                hasNoWeekItem
                @input="propsChanged($event, 'week_max',scope.row, scope.$index)"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"/>
            </template>
          </data-tables-column>
          <data-tables-column prop="drop_key" label="Drop" sortable="custom" column-key="drop_key"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'drop_key')[0]?.value"
            :filters="getColumnFilterValues('items', 'drop_key', getDropName, true)"
            v-if="selectedColumns.includes('Drop')">
            <template slot-scope="scope">
              <v-autocomplete
                v-model="scope.row.drop_key"
                :items="dropsList"
                item-value="drop_key"
                class="table-field"
                @input="propsChanged($event, 'drop_key',scope.row, scope.$index)"
                item-text="drop_name"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"/>
            </template>
          </data-tables-column>
          <data-tables-column prop="stores_set_keys" label="Store sets" sortable="custom"
            column-key="stores_set_keys"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'stores_set_keys')[0]?.value"
            :filters="getColumnFilterValues('items', 'stores_set_keys', getStoresSetsName, true)"
            v-if="selectedColumns.includes('Store sets')">
            <template slot-scope="scope">
              <collapsible-select
                v-model="scope.row.stores_set_keys"
                placeholder="Search"
                autocomplete
                show-select-all
                class="table-field"
                not-clearable
                :items="getStoreSetsNames(scope.row.stores_set_keys)"
                item-value="stores_set_key"
                @input="propsChanged($event, 'stores_set_keys',scope.row, scope.$index)"
                item-text="set_name"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
              >
              </collapsible-select>
            </template>
          </data-tables-column>
          <data-tables-column prop="supplier_key" label="Supplier" sortable="custom"
            column-key="supplier_key"
            :filters="getColumnFilterValues('items', 'supplier_key', getSupplierName, true)"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'supplier_key')[0]?.value"
            v-if="selectedColumns.includes('Supplier')">
            <template slot-scope="scope">
              <v-autocomplete
                v-model="scope.row.supplier_key"
                :items="suppliers"
                class="table-field"
                item-value="supplier_key"
                item-text="supplier_name"
                @input="propsChanged($event, 'supplier_key',scope.row, scope.$index)"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"/>
            </template>
          </data-tables-column>
          <data-tables-column prop="schedule_key" label="Schedule" sortable="custom"
            column-key="schedule_key"
            :filters="getColumnFilterValues('items', 'schedule_key', getScheduleName, true)"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'schedule_key')[0]?.value"
            v-if="selectedColumns.includes('Schedule')">
            <template slot-scope="scope">
              <v-autocomplete

                v-model="scope.row.schedule_key"
                :items="schedules"
                item-value="schedule_key"
                class="table-field"
                item-text="schedule_name"
                @input="propsChanged($event, 'schedule_key',scope.row, scope.$index)"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
              />
            </template>
          </data-tables-column>
          <data-tables-column prop="payment_key" label="Payment" sortable="custom"
            column-key="payment_key"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'payment_key')[0]?.value"
            :filters="getColumnFilterValues('items', 'payment_key', getPaymentName, true)"
            v-if="selectedColumns.includes('Payment')">
            <template slot-scope="scope">
              <v-autocomplete

                v-model="scope.row.payment_key"
                :items="payments"
                class="table-field"
                item-value="payment_key"
                @input="propsChanged($event, 'payment_key', scope.row, scope.$index)"
                item-text="payment_name"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
              />
            </template>
          </data-tables-column>
          <data-tables-column prop="prediction_mode" label="Prediction" sortable="custom"
            column-key="prediction_mode"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'prediction_mode')[0]?.value"
            :filters="getColumnFilterValues('items', 'prediction_mode')"
            v-if="selectedColumns.includes('Prediction')">
            <template slot-scope="scope">
              <v-autocomplete

                v-model="scope.row.prediction_mode"
                :items="predictionItems"
                multiple
                class="table-field"
                @input="propsChanged($event, 'prediction_mode', scope.row, scope.$index)"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
              />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Retail price')"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'retail_price')[0]?.value"
            prop="retail_price"
            column-key="retail_price"
            :filters="getColumnFilterValues('items', 'retail_price')"
            label="Retail price"
            sortable="custom"
          >
            <template slot-scope="scope">
              <v-text-field
                class="table-field"
                :prefix="$auth.getUserCurrency().symbol"
                :value="scope.row.retail_price"
                @input="scope.row.retail_price = parseFloat($event.toString().replace(',', '.') || 0)"
                @change="propsChanged(parseFloat($event.toString().replace(',', '.') || 0), 'retail_price',scope.row, scope.$index)"
              >
              </v-text-field>
            </template>
          </data-tables-column>

          <data-tables-column
            v-if="selectedColumns.includes('Buy price')"
            prop="buy_price"
            :filters="getColumnFilterValues('items', 'buy_price')"
            column-key="buy_price"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'buy_price')[0]?.value"
            label="Buy price"
            sortable="custom"
          >
            <template slot-scope="scope">
              <v-row class="mt-0">
                <v-col cols="7" class="py-0">
                  <v-autocomplete
                    v-model="scope.row.currency"
                    :items="currencies"
                    class="table-field"
                    item-value="iso_code"
                    item-text="iso_code"
                    @input="propsChanged($event, 'currency', scope.row, scope.$index)"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu'
                    }"/>
                </v-col>
                <v-col cols="5" class="pa-0">
                  <v-text-field
                    :value="scope.row.buy_price"
                    @input="scope.row.buy_price = parseFloat($event.toString().replace(',', '.') || 0)"
                    @change="propsChanged(parseFloat($event.toString().replace(',', '.') || 0), 'buy_price',scope.row, scope.$index)"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </template>
          </data-tables-column>

          <data-tables-column
            v-if="selectedColumns.includes('Pieces')"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'pieces_total')[0]?.value"
            column-key="pieces_total"
            :filters="getColumnFilterValues('items', 'pieces_total')"
            prop="pieces_total" label="Pieces" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.pieces_total | formatThousands }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Cost price')"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'cost_price')[0]?.value"
            prop="cost_price"
            :filters="getColumnFilterValues('items', 'cost_price')"
            column-key="cost_price"
            label="Cost price"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{priceFormatter(scope.row.cost_price)}}
            </template>
          </data-tables-column>

          <data-tables-column
            v-if="selectedColumns.includes('Margin')"
            :filters="getColumnFilterValues('items', 'gross_margin')"
            prop="gross_margin"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'gross_margin')[0]?.value"
            column-key="gross_margin"
            label="Margin"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{(formatPercents(scope.row.gross_margin * 100) )|| 0}}%
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Markup')"
            :filters="getColumnFilterValues('items', 'markup')"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'markup')[0]?.value"
            prop="markup"
            column-key="markup"
            label="Markup"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{scope.row.markup | formatThousands}}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Option')"
            prop="option_key"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'option_key')[0]?.value"
            column-key="option_key"
            :filters="getColumnFilterValues('items', 'option_key')"
            label="Option"
            sortable="custom"
          >
            <template slot-scope="scope">
              <router-link
                class="deep-link"
                :to="{name: 'stock-option',query: { option_key: scope.row.option_key } }"
                target="_blank"
              >
                {{ scope.row.option_key }}
              </router-link>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Variation')"
            prop="variation_key"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'variation_key')[0]?.value"
            column-key="variation_key"
            :filters="getColumnFilterValues('items', 'variation_key')"
            label="Variation"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.variation_key }}
              </span>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Dummy')"
            prop="dummy_key"
            :filtered-value="itemsTableFilters.filter(filter=> filter.prop === 'dummy_key')[0]?.value"
            column-key="dummy_key"
            :filters="getColumnFilterValues('items', 'dummy_key')"
            label="Dummy"
            sortable="custom"
          >
            <template slot-scope="scope">
              <router-link
                class="deep-link"
                target="_blank"
                :to="{name: 'buy-studio-item-page',query: { dummy_key: scope.row.dummy_key } }">

                {{ scope.row.dummy_key }}
              </router-link>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedColumns.includes('Status')"
            prop="status"
            column-key="status"
            label="Status"
            sortable="custom"
            :filter-method="equalsFilter"
            :filtered-value="itemsTableFilters.filter(item=> item.prop === 'status')[0]?.value"
            :filters="getColumnFilterValues('items', 'status')"
          >
            <template slot-scope="scope">
              <span :style="{ color: shopItemStatusColorMap[scope.row.status] }">
                {{ scope.row.status | capitalize | formatString}}
              </span>
            </template>
          </data-tables-column>
        </data-tables>
      </v-col>
    </v-row>

    <!-- Dummies dialog -->
    <v-dialog
      :value="addDialogVisible"

      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="1200px"
      max-width="90%">
      <v-card class="rounded-0" v-if="addDialogVisible">
        <v-overlay absolute color="white" :value="dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2"/>
        </v-overlay>
        <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">Add shop item(s)</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 text--primary">
          <v-tabs v-model="activeTab" grow show-arrows class="p-relative">
            <v-tabs-slider/>
            <v-tab class="px-8">
              <template>
                Options (Studio)
              </template>
            </v-tab>
            <v-tab class="px-8" :disabled="secondTabLocked">
              <template>
                Options (Stock)
              </template>
            </v-tab>
            <v-icon class="p-absolute tab-lock align-self-center" v-if="activeTab !== 1"
              style="right: 48px;"
              @click="secondTabLocked = !secondTabLocked"
            >lock</v-icon>                    </v-tabs>
          <div v-if="activeTab === 0">
            <div class="px-12 py-3 d-flex col-4 justify-space-between">
              <v-autocomplete

                v-model="selectedDropKey"
                :items="dropsList"
                :error-messages="selectedDropKey ? [] : ['Drop is required']"
                item-text="drop_name"
                item-value="drop_key"
                label="Drop"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
              />
            </div>
            <div class="px-12 mt-6 mb-2 d-flex justify-space-between">
              <h6 class="text-body-1 font-weight-bold mb-4">Options</h6>
              <span>
                <TableSearch @searchChanged="handleDummiesSearchChanged"/>
                <v-menu offset-y offset-x left nudge-bottom="5" :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on"
                      :color="additionalColumns.length ? 'primary' : null"

                    >filter_list</v-icon>
                  </template>
                  <v-card>
                    <el-checkbox-group v-model="additionalColumns" @input="saveProperties"
                      style="font-size: 14px" class="d-flex flex-column pa-2">
                      <el-checkbox v-for="item of sortedDummyProperties" :label="item"
                        :key="item"></el-checkbox>
                    </el-checkbox-group>
                  </v-card>
                </v-menu>
              </span>
            </div>
            <h4 class="text-no-wrap px-11 mb-4">{{ selectedDummiesRows.length }} lines selected</h4>

            <div class="p-relative">
              <data-tables
                style="width: 100%"
                class="search-results"
                :key="dummiesListKey"
                :filters="dummiesTableFilters"
                :data="dummiesList"
                :page-size="dummyPageSize"
                @filtered-data="filteredDummies = $event"
                @update:pageSize="onDummyPageSizeChange"
                @filter-change="dummyFilterChange"
                :table-props="{
                  defaultSort: {prop: 'allowed', order: 'descending'}
                }"
                filter-multiple
                :pagination-props="{
                  pageSizes: [5, 10,15, 25, 50],
                  class: 'el-pagination text-right mt-6 mb-4 mr-2'
                }"
                @row-click="handleDummiesRowClick">
                <data-tables-column prop="option_key" width="72" >
                  <template v-slot:header>
                    <v-row class="ma-0 flex-nowrap">
                      <v-simple-checkbox
                        color="primary"
                        :value="allDummiesRowsSelected"
                        :ripple="false"
                        dense
                        hide-details
                        class="ml-3"
                        @input="handleSelectAllDummiesChange"
                      />
                    </v-row>
                  </template>
                  <template slot-scope="scope">
                    <v-simple-checkbox
                      v-if="scope.row.allowed"
                      color="primary"
                      dense
                      hide-details
                      class="mt-0 ml-3 pt-0"
                      :value="scope.row.selected"
                      :ripple="false"
                      @input="handleSelectDummyRowChange($event, scope.row, scope)"
                    />
                  </template>
                </data-tables-column>

                <data-tables-column  label="Name"
                  column-key="name"
                  prop="name"
                  :filters="getColumnFilterValues('dummiesList', 'name')"
                >
                  <template slot-scope="scope">
                    <OptionDataCell
                      :option="scope.row"
                      :url="getDummyPageUrl(scope.row.dummy_key)"
                      name-key="name"
                    />
                  </template>
                </data-tables-column>
                <data-tables-column prop="created_at" label="Creation date" sortable="custom"
                  column-key="created_ad"
                  :filters="getColumnFilterValues('dummiesList', 'created_at')"
                  :filtered-value="dummiesTableFilters.filter(filter=> filter.prop === 'created_at')[0]?.value"

                >
                  <template slot-scope="scope">
                    {{ scope.row.created_at | formatDate('yyyy-MM-dd') }}
                  </template>
                </data-tables-column>
                <data-tables-column :prop="item" :label="formatString(item)" sortable="custom"
                  :key="item"
                  :column-key="item"
                  :filtered-value="dummiesTableFilters.filter(filter=> filter.prop === item)[0]?.value"
                  @change-filter="handleFiltersChange($event)"
                  :filters="getColumnFilters(dummiesList, item)"
                  v-for="item in additionalColumns">
                  <template slot-scope="scope">
                    {{ scope.row.properties[item] }}
                  </template>
                </data-tables-column>
                <data-tables-column
                  prop="status"
                  column-key="status"
                  label="Status"
                  sortable="custom"
                  :filtered-value="dummiesTableFilters.filter(filter=> filter.prop === 'status')[0]?.value"
                  :filters="getColumnFilterValues('dummiesList', 'status')"
                >
                  <template slot-scope="scope">
                    <span :style="{ color: dummyStatusColorMap[scope.row.status] }">
                      {{ scope.row.status | capitalize }}
                    </span>
                  </template>
                </data-tables-column>
                <data-tables-column prop="allowed" label="Allowed" sortable="custom"
                  column-key="allowed"
                  :filtered-value="dummiesTableFilters.filter(filter=> filter.prop === 'allowed')[0]?.value"

                  :filters="getColumnFilterValues('dummiesList', 'allowed')"

                >
                  <template slot-scope="scope">
                    {{ scope.row.allowed ? 'Yes' : 'No' }}
                    <v-tooltip top :max-width="250" v-if="!scope.row.allowed" open-on-click>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon :size="21" class="mladd -2" v-bind="attrs" v-on="on">info</v-icon>
                      </template>
                      <span>{{ scope.row.reason }}</span>
                    </v-tooltip>
                  </template>
                </data-tables-column>
                <data-tables-column prop="already_in_budget" label="In budget" sortable="custom"
                  column-key="already_in_budget"
                  :filters="getColumnFilterValues('dummiesList', 'already_in_budget')"
                >
                  <template slot-scope="scope">
                    {{ scope.row.already_in_budget ? 'Yes' : 'No' }}
                  </template>
                </data-tables-column>
              </data-tables>
            </div>
          </div>
          <div v-if="activeTab === 1">
            <h4 class="px-12 pt-3" style="line-height: 24px">This process will create dummies and directly set them to ‘Completed’,<br> bypassing the property management and checks.</h4>

            <div class="px-12 pt-3 d-flex col-4 pb-0 justify-space-between">
              <v-autocomplete

                v-model="selectedDropKey"
                :items="dropsList"
                hide-details="auto"
                :error-messages="selectedDropKey ? [] : ['Drop is required']"
                item-text="drop_name"
                item-value="drop_key"
                label="Drop"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
              />
            </div>
            <div class="px-12 pb-3 d-flex col-4 pt-0 justify-space-between">
              <v-autocomplete
                :value="supplierKey"
                :items="suppliers"
                hide-details="auto"
                class="pt-0"
                :error-messages="supplierKey ? [] : ['Supplier is required']"
                item-value="supplier_key"
                item-text="supplier_name"
                return-object
                @input="(value) => supplierKey = value.supplier_key"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"/>
            </div>
            <div class="px-12 mt-6 mb-2 d-flex justify-space-between">
              <h6 class="text-body-1 font-weight-bold mb-4">Existing Options</h6>
              <span>
                <TableSearch @searchChanged="handleOptionsSearchChanged"/>
              </span>
            </div>
            <h4 class="text-no-wrap px-11 mb-4">{{ selectedOptionsRows.length }} lines selected</h4>
            <div class="p-relative">
              <data-tables
                style="width: 100%"
                class="search-results"
                :key="optionsListKey"
                :filters="optionsTableFilters"
                :data="optionsList"
                @filtered-data="filteredOptions = $event"
                :page-size="10"
                filter-multiple
                @filter-change="filters => handleFilterChange('optionsTableFilters', filters)"
                :pagination-props="{
                  pageSizes: [5, 10,15, 25, 50],
                  class: 'el-pagination text-right mt-6 mb-4 mr-2'
                }"
                @row-click="handleOptionsRowClick">
                <data-tables-column prop="option_key" width="72">
                  <template v-slot:header>
                    <v-row class="ma-0 flex-nowrap">
                      <v-simple-checkbox
                        color="primary"
                        :value="allOptionsRowsSelected"
                        :ripple="false"
                        dense
                        hide-details
                        class="ml-3"
                        @input="handleSelectAllOptionsChange"
                      />
                    </v-row>
                  </template>
                  <template slot-scope="scope">
                    <v-simple-checkbox
                      color="primary"
                      dense
                      hide-details
                      class="mt-0 ml-3 pt-0"
                      :value="scope.row.selected"
                      :ripple="false"
                      @input="handleSelectOptionsRowChange($event, scope.row, scope)"
                    />
                  </template>
                </data-tables-column>
                <data-tables-column  label="Name"
                  column-key="option"
                  prop="option"
                  :filters="getColumnFilterValues('optionsList', 'option')"
                >
                  <template slot-scope="scope">
                    <OptionDataCell
                      :option="scope.row"
                      :url="getOptionUrl(scope.row.option_key)"
                    />
                  </template>
                </data-tables-column>
                <data-tables-column :prop="item" :label="formatString(item)" sortable="custom"
                  :key="item"
                  :column-key="item"
                  :filters="getColumnFilters(optionsList, item)"
                  v-for="item in optionsAdditionalColumns">
                  <template slot-scope="scope">
                    {{ scope.row.properties[item] }}
                  </template>
                </data-tables-column>
                <data-tables-column
                  prop="stock"
                  column-key="stock"
                  label="Stock"
                  sortable="custom"
                  :filters="getColumnFilterValues('optionsList', 'stock')"
                >
                  <template slot-scope="scope">
                    {{ scope.row.stock }}
                  </template>
                </data-tables-column>
                <data-tables-column
                  prop="ordered"
                  column-key="ordered"
                  label="Ordered"
                  sortable="custom"
                  :filters="getColumnFilterValues('optionsList', 'ordered')"
                >
                  <template slot-scope="scope">
                    {{ scope.row.ordered }}
                  </template>
                </data-tables-column>
              </data-tables>
            </div>
          </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleAddCancel"
              >Cancel
              </v-btn>
              <v-btn
                :disabled="overlayAddDisabled"
                color="primary"
                depressed
                outlined
                @click="handleAddSubmit"
                class="mx-4"
              >Add
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog -->
    <v-dialog
      :title="selectedItemsRows[0] ? selectedItemsRows[0].option_name : null"
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2"/>
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium">Delete shop items</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete selected shop items</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel
              </v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteConfirm"
                class="red--text mx-4"
              >Delete
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { isNull } from 'lodash'
import { dummyStatusColorMap, formatString, shopItemStatusColorMap } from '@/variables'
import * as dummiesApi from '@/api/dummies'

import * as shopApi from '@/api/shop'
import { getDrops, onItemsChange } from '@/api/shop'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'
import OptionDataCell from '@/components/Dummies/OptionDataCell.vue'
import CollapsibleSelect from '../CollapsibleSelect'
import WeekSelector from '../WeekSelector'
import DataTablesColumn from '@/components/Tables/components/DataTablesColumn.vue'
import { useFiltersStore } from '@/store/pinia/filtersStore'

const columnsToSelect = ['Start', 'End', 'Drop', 'Store sets', 'Supplier', 'Schedule', 'Payment', 'Prediction', 'Retail price', 'Buy price', 'Pieces', 'Cost price', 'Margin', 'Markup', 'Option', 'Variation', 'Dummy', 'Status']
const defaultColumns = ['Start', 'End', 'Drop', 'Store sets', 'Supplier', 'Schedule', 'Payment', 'Buy price', 'Status']
export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  mixins: [columnFilters],
  components: {DataTablesColumn, WeekSelector, CollapsibleSelect, TableSearch, OptionDataCell },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    schedules: {
      type: Array,
      default: () => [],
    },
    budgetKey: {
      type: Number,
      required: true,
    },
    scenarioKey: {
      type: Number,
      required: true,
    },
    storeSets: {
      type: Array,
      default: () => [],
    },
    currencies: {
      type: Array,
      default: () => [],
    },
    suppliers: {
      type: Array,
      default: () => [],
    },
    payments: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      shopItemStatusColorMap,
      shopItemCurrPage: 1,
      onItemsChangeDelayed: null,
      filteredData: [],
      filteredOptions: [],
      filteredDummies: [],
      localstorage: localStorage,
      columnsToSelect,
      supplierKey: undefined,
      dummiesList: [],
      optionsList: [],
      secondTabLocked: true,
      dummyProperties: [],
      optionProperties: [],
      additionalColumns: JSON.parse(localStorage.getItem('dummiesProperties')) || [],
      orderColumns: JSON.parse(localStorage.getItem('orderColumns')) || [],
      optionsAdditionalColumns: JSON.parse(localStorage.getItem('optionsProperties')) || [],
      selectedColumns: JSON.parse(localStorage.getItem('shopItemSelectedColumns')) || defaultColumns,
      dropsList: [],
      selectedDropKey: null,
      dummyStatusColorMap,
      itemsTableFilters: JSON.parse(localStorage.getItem('shop-items-filters')) || [{ value: '' }],
      dummiesTableFilters: JSON.parse(localStorage.getItem('dummy-filters')) || [{ value: '' }],
      optionsTableFilters: [{ value: '' }],
      columnsToSearch: ['shop_item_key', 'name', 'week_min_formatted', 'stores_set_keys', 'drop_name', 'status'],
      loading: false,
      dialogLoading: false,
      addDialogVisible: false,
      deleteDialogVisible: false,
      dummiesListKey: 0,
      optionsListKey: 0,
      activeTab: 0,

    }
  },
  computed: {
    sortedDummyProperties () {
      const props = [...this.dummyProperties]
      return props.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    },
    predictionItems (){
      return [
        {
          text: 'Potential',
          value: 'potential',
        },
        {
          text: 'Sales Ly + Missed',
          value: 'sales_ly_missed',
        },
        {
          text: 'Sales Ly + LLy + Missed',
          value: 'sales_ly_lly_missed',
        },
      ]
    },

    formatPercents () {
      const formatter = new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return formatter.format
    },
    priceFormatter () {
      const currency = this.$auth.getUserCurrency().currency || 'EUR'
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return formatter.format
    },
    sortedOrderColumns () {
      return ['Split orders', 'Stock min', 'MOQ', 'Cover min', 'Cover max', 'Box size', 'Sellthrough']
    },
    shopItemPageSize: {
      get (){
        return parseFloat(localStorage.getItem('shop-item-page-size')) || 10
      },
      set (val){
        localStorage.setItem('shop-item-page-size', val)
      }
    },
    dummyPageSize () {
      return parseFloat(localStorage.getItem('dummy-page-size')) || 10
    },
    selectedItemsRows () {
      return this.filteredData.filter(r => r.selected)
    },
    selectedDummiesRows () {
      return this.filteredDummies.filter(r => r.selected)
    },
    allDummiesRowsSelected () {
      return (this.filteredDummies.length > 0) && (this.selectedDummiesRows.length === this.filteredDummies.filter((item) => item.allowed).length)
    },
    allSelectedItems () {
      return (this.filteredData.length > 0) && (this.selectedItemsRows.length === this.filteredData.length)
    },
    selectedOptionsRows () {
      return this.filteredOptions.filter(r => r.selected)
    },
    allOptionsRowsSelected () {
      return (this.filteredOptions.length > 0) && (this.selectedOptionsRows.length === this.filteredOptions.length)
    },
    marginCalculatorDisabled () {
      return this.selectedItemsRows.length !== 1
    },
    overlayAddDisabled () {
      return (this.activeTab === 1 && this.selectedOptionsRows.length < 1) || !this.selectedDropKey
    }
  },
  watch: {
    budgetKey: {
      handler () {
        getDrops({ budget_key: this.budgetKey })
          .then(drops => {
            this.dropsList = drops
          })
      },
      immediate: true
    },
    suppliers: {
      handler () {
        if (this.suppliers.length > 0) {
          this.supplierKey = this.suppliers[0].supplier_key
        }
      },
      immediate: true
    },
    items () {
      this.items.forEach(item => {
        this.$set(item, 'week_min_formatted', this.getItemWeeksColumnValue(item.week_min))
        this.$set(item, 'week_max_formatted', this.getItemWeeksColumnValue(item.week_max))
        this.$set(item, 'buy_price', this.getItemCostPriceColumnValue(item))
        for (const key in item.properties) {
          this.$set(item, key, item.properties[key])
        }
      })
    },
  },
  methods: {
    validateSellthrough (row) {
      if (isNull(row.order_settings?.sellthrough) || row.order_settings?.sellthrough === undefined) {
        return true
      }
      return Number.isInteger(parseFloat((row.order_settings?.sellthrough * 100 || 0).toFixed(2))) && row.order_settings?.sellthrough * 100 >= 1 && row.order_settings?.sellthrough * 100 <= 100
    },
    getDropName (key){
      if(!this.dropsMap){
        this.dropsMap = {}
      }
      if(!this.dropsMap[key]) {
        this.dropsMap[key] = this.dropsList.find(item => item.drop_key === key)?.drop_name
      }
      return this.dropsMap[key]
    },
    getSupplierName (key){
      if(!this.suppliersMap){
        this.suppliersMap = {}
      }
      if(!this.suppliersMap[key]) {
        this.suppliersMap[key] = this.suppliers.find(item => item.supplier_key === key)?.supplier_name
      }
      return this.suppliersMap[key]
    },
    getScheduleName (key){
      if(!this.schedulesMap){
        this.schedulesMap = {}
      }
      if(!this.schedulesMap[key]) {
        this.schedulesMap[key] = this.schedules.find(item => item.schedule_key === key)?.schedule_name
      }
      return this.schedulesMap[key]
    },
    getPaymentName (key){
      if(!this.paymentsMap){
        this.paymentsMap = {}
      }
      if(!this.paymentsMap[key]) {
        this.paymentsMap[key] = this.payments.find(item => item.payment_key === key)?.payment_name
      }
      return this.paymentsMap[key]
    },
    getStoresSetsName (key){
      if(!this.storeSetsMap){
        this.storeSetsMap = {}
      }
      if(!this.storeSetsMap[key]) {
        this.storeSetsMap[key] = this.storeSets.find(item => item.stores_set_key === key)?.set_name
      }
      return this.storeSetsMap[key]
    },
    validateOrderSettings (row, key) {
      if (row.order_settings[key] === undefined || isNull(row.order_settings[key])) {
        return true
      }
      return Number.isInteger(row.order_settings[key] || 0) && row.order_settings[key] >= 0
    },
    sellthroughValue (row) {
      if (row.order_settings?.sellthrough === undefined) {
        return 100
      }
      // Math.round(scope.row.order_settings?.sellthrough * 100 || 0),2 )
      return parseFloat((row.order_settings?.sellthrough * 100).toFixed(2))
    },
    orderParamsChanged (row, key, value) {
      if (typeof value === 'number' && isNaN(value)) {
        value = null
      }
      const orderSettings = { ...row.order_settings, [key]: value }
      this.$set(row, 'order_settings', orderSettings)
      this.selectedItemsRows.forEach((item) => {
        const orderSettings = { ...item.order_settings, [key]: value }
        this.$set(item, 'order_settings', orderSettings)
      })
      this.onItemsChangeDelayed({
        budget_key: this.budgetKey,
        scenario_key: this.scenarioKey,
        shop_item_keys: ([...new Set([...this.selectedItemsRows || [], row])]).map(item => item.shop_item_key),
        order_settings: { [key]: value }
      })
    },
    handleDummiesRowClick (row, col, event) {
      const checkbox = event.target.closest('.el-table__row').querySelector('.v-simple-checkbox')

      if (checkbox) {
        checkbox.click()
      }
    },
    onShopItemsPageSizeChange (value) {
      this.shopItemPageSize = value
      localStorage.setItem('shop-item-page-size', value)
    },
    onDummyPageSizeChange (value) {
      localStorage.setItem('dummy-page-size', value)
    },
    dummyFilterChange (filters) {
      this.handleFilterChange('dummiesTableFilters', filters)

      let filtersToSave = this.dummiesTableFilters.filter(item => {
        return typeof item.prop === 'string'
      })
      if (!filtersToSave.length) {
        filtersToSave = [{ value: '' }]
      }
      localStorage.setItem('dummy-filters', JSON.stringify(filtersToSave))
    },
    handleFiltersChange (filters) {
      this.handleFilterChange('itemsTableFilters', filters)

      let filtersToSave = this.itemsTableFilters.filter(item => {
        return typeof item.prop === 'string'
      })
      if (!filtersToSave.length) {
        filtersToSave = [{ value: '' }]
      }
      localStorage.setItem('shop-items-filters', JSON.stringify(filtersToSave))
    },
    propsChanged (value, key, row) {
      if ((typeof value === 'number' && isNaN(value)) || (isNull(value) && !key.toLowerCase().includes('week'))) {
        return
      }
      if (key.toLowerCase().includes('week') && !value) {
        value = null
      }
      this.selectedItemsRows.forEach((item) => {
        this.$set(item, key, value)
      })

      const selectedRows = new Set([...this.selectedItemsRows.map(item => item.shop_item_key), row.shop_item_key])
      const shop_item_keys = [...selectedRows]
      this.onItemsChangeDelayed({
        budget_key: this.budgetKey,
        scenario_key: this.scenarioKey,
        shop_item_keys,
        [key]: value,
      })
    },
    handleSelectAllItemsChange () {
      const selected = !this.allSelectedItems
      this.filteredData.forEach((item) => {
        this.$set(item, 'selected', selected)
      })
      this.$emit('shop-item-selected', this.selectedItemsRows || null)
    },
    getWeek (date) {
      const currentDate = new Date(date)
      const oneJan = new Date(currentDate.getFullYear(), 0, 1)
      const numberOfDays = Math.floor((currentDate - oneJan) / (24 * 60 * 60 * 1000))
      const weekNum = '0' + Math.floor((currentDate.getDay() + 1 + numberOfDays) / 7)
      return currentDate.getFullYear() + '' + weekNum.substr(-2)
    },
    handleOptionsRowClick (row, col, event) {
      const checkbox = event.target.closest('.el-table__row').querySelector('.v-simple-checkbox')

      if (checkbox) {
        checkbox.click()
      }
    },
    isSpecial ({ row }) {
      const isSpecial = row.selected
      return isSpecial ? 'bg-special cursor-pointer' : 'cursor-pointer'
    },
    formatString,
    getColumnFilters (list, col) {
      // flatten to handle columns with array of values properly
      const values = _.flatten(list.map(x => x.properties[col]))
      return _.uniq(values).filter((item) => item !== null)
        .sort((a, b) => {
          return (a < b) ? -1 : 1
        }).map(v => {
          return ({

            text: typeof v === 'string' ? this.$options.filters.formatString(v) : v,
            value: v,
          })
        })
    },
    handleSelectItemRowChange (val, row) {
      this.$set(row, 'selected', val)

      this.$emit('shop-item-selected', this.selectedItemsRows || null)
    },
    handleSelectDummyRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
    handleSelectOptionsRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
    handleSelectAllDummiesChange (val) {
      this.dummiesList.forEach(r => {
        if (r.allowed) {
          this.$set(r, 'selected', false)
        }
      })
      this.filteredDummies.forEach(r => {
        if (r.allowed) {
          this.$set(r, 'selected', val)
        }
      })
    },
    handleSelectAllOptionsChange (val) {
      this.optionsList.forEach(r => {
        this.$set(r, 'selected', false)
      })
      this.filteredOptions.forEach(r => {
        this.$set(r, 'selected', val)
      })
    },
    handleDummiesSearchChanged (val) {
      this.dummiesTableFilters[0].value = val
      this.dummiesListKey++
    },
    handleOptionsSearchChanged (val) {
      this.optionsTableFilters[0].value = val
      this.optionsListKey++
    },
    handleAddIconClick () {
      this.loadOptions()
      this.addDialogVisible = true
      this.dialogLoading = true
      this.supplierKey = null
      this.selectedDropKey = null

      shopApi
        .getDrops({ budget_key: this.budgetKey })
        .then(drops => {
          this.dropsList = drops
        })
        .finally(() => {
          this.dialogLoading = false
        })
    },
    handledeleteIconClick () {
      this.deleteDialogVisible = true
    },
    handleEditClick () {
      this.redirectToShopPage(this.selectedItemsRows[0].shop_item_key)
    },
    handleAddCancel () {
      this.addDialogVisible = false
    },
    handleAddSubmit () {
      if (this.activeTab === 1) {
        this.handleOptionsSubmit()
        return
      }
      const payload = {
        budget_key: this.budgetKey,
        scenario_key: this.scenarioKey,
        dummy_keys: this.selectedDummiesRows.map(r => r.dummy_key),
        drop_key: this.selectedDropKey,
      }
      this.dialogLoading = true

      shopApi
        .saveShopItems(payload)
        .then(() => {
          this.addDialogVisible = false
          this.$emit('shop-items-added')
        })
        .finally(() => {
          this.dialogLoading = false
        })
    },
    async handleOptionsSubmit () {
      const payload = {
        budget_key: this.budgetKey,
        scenario_key: this.scenarioKey,
        drop_key: this.selectedDropKey,
        supplier_key: this.supplierKey,
        option_keys: this.selectedOptionsRows.map(r => r.option_key),
        variation_keys: this.selectedOptionsRows.map(r => r.variation_key),
      }

      this.dialogLoading = true
      await shopApi.saveShopItems(payload)
      this.addDialogVisible = false
      this.$emit('shop-items-added')
      this.dialogLoading = false
    },
    handleDeleteConfirm () {
      this.dialogLoading = true

      const payload = {
        budget_key: this.budgetKey,
        scenario_key: this.scenarioKey,
        shop_item_keys: this.selectedItemsRows.map(item => item.shop_item_key),
      }

      shopApi
        .deleteShopItem(payload)
        .then(() => {
          this.deleteDialogVisible = false
          this.$emit('shop-item-deleted')
        })
        .finally(() => {
          this.dialogLoading = false
        })
    },
    loadOptionsWithDrops () {
      this.dialogLoading = true
      const payload = {
        scenario_key: this.scenarioKey,
        budget_key: this.budgetKey,
      }
      this.loadDummyProperties(payload)
    },
    onItemsChange (payload) {
      return onItemsChange(payload).then(({ shop_items }) => {
        if (!shop_items) {
          return
        }
        this.$emit('shop-items-update')
        if (!payload.supplier_key) {
          return
        }
        const selectedRows = this.items.filter(item => payload.shop_item_keys.includes(item.shop_item_key))
        selectedRows.forEach((item, index) => {
          const newItem = shop_items.find((shopItem) => shopItem.shop_item_key === item.shop_item_key)
          this.$set(selectedRows[index], 'schedule_key', newItem.schedule_key)
          this.$set(selectedRows[index], 'payment_key', newItem.payment_key)
        })
      })
    },
    loadDummyProperties (payload) {
      dummiesApi
        .loadDummies(payload)
        .then(response => {
          this.dummiesList = response.dummies?.map(d => {
            return {
              ...d,
              selected: false,
              ...d.properties,
            }
          }) || []
          this.dummyProperties = response.properties || []
        })
        .finally(() => {
          this.dialogLoading = false
        })
    },
    async loadOptions () {
      this.dialogLoading = true
      shopApi
        .getAllowedOptions(this.budgetKey)
        .then(response => {
          this.optionsList = response.map(o => {
            return {
              ...o,
              selected: false,
              ...o.properties
            }
          })
          this.optionProperties = response.map(x => x.properties).reduce((acc, x) => {
            const props = Object.keys(x || {})
            props.forEach(p => {
              if (!acc.includes(p)) {
                acc.push(p)
              }
            })
            return acc
          }, [])
        })
        .finally(() => {
          this.dialogLoading = false
        })
    },
    saveProperties (value) {
      localStorage.setItem('dummiesProperties', JSON.stringify(value))
    },
    saveOrderColumns (value) {
      localStorage.setItem('orderColumns', JSON.stringify(value))
    },
    saveSelectedColumns (value) {
      localStorage.setItem('shopItemSelectedColumns', JSON.stringify(value))
    },
    handleMarginIconClick () {
      this.$emit('margin-calculator-open', this.selectedItemsRows[0])
    },
    getStoreSetsNames (keys) {
      return this.storeSets.filter(item => keys.indexOf(item.store_key))
    },
    getItemWeeksColumnValue (week) {
      if (!week) {
        return ''
      }
      return `${this.$options.filters.formatWeekNumber(week)}`
    },
    getItemCostPriceColumnValue (row) {
      return _.round(row.buy_price, 2)
    },
    redirectToShopPage (dummyKey) {
      this.$router.push(this.getShopPageUrl(dummyKey))
    },
    getShopPageUrl (shopKey) {
      return {
        name: 'buy-shop-item-page',
        query: {
          shop_item_key: shopKey,
          budget_key: this.budgetKey,
          scenario_key: this.scenarioKey,
        },
      }
    },
    getDummyPageUrl (dummyKey) {
      return {
        name: 'buy-studio-item-page',
        query: { dummy_key: dummyKey }
      }
    },
    getOptionUrl (option_key) {
      return {
        name: 'stock-option',
        query: {
          'option_key': option_key,
        }
      }
    },
  },
  mounted () {
    const payload = {
      dummies: this.filtersStore.selectedFiltersOptions['dummies'],
    }
    this.onItemsChangeDelayed = _.debounce(this.onItemsChange, 500)
    this.loadOptionsWithDrops(payload)
    getDrops({ budget_key: this.budgetKey })
      .then(drops => {
        this.dropsList = drops
      })
  },
}
</script>

<style lang="scss">
.buy-shop-items {
  .v-select__selections {
    flex-wrap: nowrap;
  }

  .grey--text.caption {
    white-space: nowrap;
  }

  .v-text-field {
    font-size: 13px;
    font-weight: 500;
    padding-top: 0;
    margin-top: 0;

    input {
      color: rgb(96, 98, 102) !important;
    }
  }

  .table-field {
    margin-bottom: -12px;
  }

  .deep-link {
    text-decoration: none;
    color: rgb(96, 98, 102);
  }

  .deep-link:hover{
    color: #007aff;
  }

  .el-table__column-filter-trigger {
    line-height: normal;
    transform: none;
    vertical-align: middle;
    .el-icon-arrow-down {
      &::before {
        display: none;
      }
    }
  }
}
</style>
