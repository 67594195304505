<template>
  <v-card
    ref="dataTables"
    class="data-sets-matrix-tables py-4 pt-1 overflow-auto"
    v-if="!isChangeView"
  >
    <v-row class="align-center mx-1 my-0" v-if="index !== null">
      <v-overlay absolute color="white" :value="!view.data && !(view.view.errored || view.errored)">
        <v-progress-circular color="primary" indeterminate size="64" width="2"/>
      </v-overlay>
      <v-row class="ma-0">
        <h4 class="ma-0 pl-3 pr-2 pb-4 align-self-start text-no-wrap" v-if="hasTitle">{{positionTitle}}. {{ view.view.view_name }}</h4>
        <v-tooltip v-if="view.view.limited" top :max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-3 error--text">warning</v-icon>
          </template>
          <span>Only top rows of data are showed because of size. Total row data is still correct</span>
        </v-tooltip>
        <slot v-else></slot>
      </v-row>
      <div class="d-flex pa-3 pb-4 mr-3 show-on-hover "
        v-if="!view.view.errored && !view.errored && view.view.dimensions && view.view.dimensions.length">
        <TableSearch
          @searchChanged="val => handleSearchChanged('tableFilters',[...view.view.dimensions, ...Object.keys(sortedData[0])], val)"
        />
        <v-icon class="show-on-hover ml-2  action-btn-primary" v-if="hasTitle && !inModal"  @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" >edit</v-icon>
        <v-overlay absolute color="white" :value="downloadingLoaded">
          <v-progress-circular color="primary" indeterminate size="64" width="2"/>
        </v-overlay>
        <div class="d-inline">
          <v-menu offset-y :left="true" :close-on-content-click="true" nudge-bottom="5">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="mx-2 action-btn-primary">download</v-icon>
            </template>
            <v-list dense>
              <v-list-item
                v-if="view.view.properties.child_rows"
              >
                <v-list-item-title class="font-weight-bold">Parent table</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="c-pointer"
                v-for="(item, index) in downloadItems"
                :key="index"
                @click.native="downloadData(item.value)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
              <template v-if="view.view.properties.child_rows">
                <v-list-item
                >
                  <v-list-item-title class="font-weight-bold">Child table</v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="c-pointer"
                  v-for="(item, index) in downloadItems"
                  :key="index"
                  @click.native="downloadData(item.value, true)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </div>
        <v-icon
          class="mr-1 action-btn-primary"
          @click="$emit('full-screen',view,index)"
          v-if="!inModal && hasTitle"

        >fullscreen
        </v-icon>
        <data-sets-view-filter
          v-if="hasTitle"
          :view="view" @change="filterChanged" :index="index"></data-sets-view-filter>
      </div>
      <template v-else-if="view.view.errored || view.errored" >
        <div class="d-flex pa-3 pb-4 mr-3 show-on-hover ">
          <v-icon class="show-on-hover action-btn-primary" v-if="hasTitle && !inModal"  @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" >edit</v-icon>
        </div>
      </template>
    </v-row>

    <v-row class="ma-0 pl-4">
      <v-col v-if="inModal" cols="2">
        <v-text-field
          label="Column Width"
          v-model="columnWidth"
        ></v-text-field>
      </v-col>
    </v-row>
    <data-tables
      style="width: 100%"
      :two-rows-column="true"
      :data="sortedData"
      :key="dataTableKey"
      filter-multiple
      show-select
      v-if="view.data && !view.view.errored && !view.errored && view.view.dimensions && view.view.dimensions.length"
      :filters="tableFilters"
      :page-size="view.view.properties.rows || 5"
      @filter-change="filters => handleFilterChange('tableFilters', filters)"
      @sort-change="onSortChange"
      @row-click="row => $emit('row:click', row)"
      :table-props="{
        'summary-method': getSummary,
        'show-summary': view.view.properties.total_row,
        load: load,
        defaultSort: {prop: sortProperty, order: view.view.properties.sort_order || 'descending'},
        lazy: true,
        'max-height': view.view?.properties?.no_inline_scroll ? undefined : 700,
        'row-key': tableProps,
        'tree-props': { children: 'children', hasChildren: 'hasChildren' }
      }"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
    >
      <data-tables-column v-for="column in view.view.dimensions"
        :column-key="column"
        :prop="column"
        :key="column"
        :min-width="columnWidth || columnProperties[column]?.width || (firstColumnWider ? 150 : 100)"
        :max-width="columnWidth || columnProperties[column]?.width"
        :fixed="!isRecommendations"
        sortable="custom"
        class-name="pl-6"
        :label="`${column.split('/')[2]} (${column.split('/')[1]})`">
        <template slot-scope="scope">
          <v-row class="justify-start mr-0 my-0" :style="scope.row?.isChild ? 'margin-left: -32px' : 'margin-left: 0'"
            v-if="((view.view.dimensions[0]===column && scope.row?.isChild && view?.view?.properties?.child_rows.includes('/key')) || (!scope.row?.isChild && column.includes('/key'))) &&
              !(columnProperties || {} )[column] && 'image_url' in scope.row">
            <v-menu
              :key="scope.row['properties/article/key']+ ' ' "
              right
              bottom
              :open-on-hover="!infoMenuOpened[scope.row['properties/article/key']]"
              nudge-bottom="10"
              :disabled="!isRecommendations || !scope.row['properties/article/key']"
              :nudge-left="200"
              v-model="infoMenuOpened[scope.row['properties/article/key']]"
              @input="onInfoMenuChange(scope.row['properties/article/key'], $event)"
              offset-x
              offset-y
              :open-delay="3000"
              :close-delay="1000"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">

                <div
                  v-bind="attrs" v-on="on"
                >
                  <router-link
                    style="color: inherit; text-decoration: none"
                    :to="scope.row.route"
                    target="_blank"
                    class="d-flex special-aggregation">
                    <v-img
                      :src="scope.row['image_url'] || '@/assets/img/ts_placeholder.png'"
                      class="mr-6"
                      lazy-src="/img/placeholder-image.83cd44f1.png"
                      height="48"
                      width="33"
                    />
                    <div class="lines d-none d-md-block">
                      <div class="one-line  text-no-wrap">
                        <span class="option-name  text-no-wrap">{{ scope.row.name.split(' / ')[0] }}</span>
                      </div>
                      <div class="one-line">
                        <span>{{ scope.row.name.split(' / ')[1] }}</span>
                      </div>
                    </div>
                    <span class="d-md-none d-block">
                      {{ scope.row.name.split(' / ')[0] }}
                      {{ scope.row.name.split(' / ')[1] }}
                    </span>
                  </router-link>
                </div>
              </template>
              <info-menu
                @close="infoMenuOpened[scope.row['properties/article/key']] = false"
                v-if="infoMenuOpened[scope.row['properties/article/key']]"
                :row="{option_key: scope.row['properties/article/key']}"></info-menu>
            </v-menu>

          </v-row>
          <span v-else>
            {{ scope.row[column] }}
          </span>
        </template>
      </data-tables-column>
      <data-tables-column
        sortable="custom"
        :filters="columnProperties[column]?.value_filtering ? getColumnFilterValues('sortedData', column) : null"
        v-for="(column) in columns"
        :min-width="columnWidth || columnProperties[column]?.width || 100"
        :max-width="columnWidth || columnProperties[column]?.width"
        :width="columnWidth || columnProperties[column]?.width"
        :key="column"
        :label="column"
        label-class-name="text-center"
        :column-key="column"
        :prop="column">
        <template slot-scope="scope">
          <div
            :style="getCellStyle(scope.row[column], view, column)"
          >
            {{ formatter(scope.row, {property: column }) }}
          </div>
        </template>

      </data-tables-column>
    </data-tables>
    <h4 v-else-if="view.view.errored || view.errored" class="row justify-center pb-8">Could not retrieve data</h4>

  </v-card>

  <datasets-new-view
    v-else-if="isChangeView"
    :dashboard-details="dashboardDetails"
    :viewsMap="viewsMap"
    @saved-positions="$emit('saved-positions', $event)"
    :changeView="{...view.view, position}"
    :positions="positions"
    :datasets="datasets"
    :dataSetForChoose="dataSetForChoose"
    @cancel="isChangeView = false; $emit('toggle-view', false)"
    @delete-view="$emit('delete-view',$event)"
    @save="changeView">
  </datasets-new-view>
</template>

<script>
import '../../filters'
import columnFilters from '@/mixins/columnFilters'
import { downloadAnalytics, performViewData } from '@/api/analytics'

import TableSearch from '@/components/TableSearch'
import DataSetsViewFilter from './DataSetsViewFilter'
import interpolate from 'color-interpolate'
import InfoMenu from '@/components/Home/InfoMenu'
import { useAnalyticsFiltersStore } from '@/store/pinia/analyticsFiltersStore'
import DataTablesColumn from '@/components/Tables/components/DataTablesColumn.vue'

const DatasetsNewView = () => import('@/components/AutomateDatasets/DatasetsNewView')

export default {
  name: 'DataSetsTables',
  mixins: [columnFilters],
  components: {DataTablesColumn, InfoMenu, DatasetsNewView, DataSetsViewFilter, TableSearch },
  setup () {
    return {
      analyticsFiltersStore: useAnalyticsFiltersStore()
    }
  },
  data () {
    return {
      infoMenuOpened: {},
      downloadItems: [
        { title: 'CSV (comma)', value: ',' },
        { title: 'CSV (semicolon)', value: ';' },
        { title: 'CSV (semicolon, decimal comma)', value: 'dutch' }
      ],
      sortedColumn: '',
      sortedOrder: '',
      loadedChildren: {},
      columnWidth: null,
      isChangeView: false,
      totalKey: 0,
      expandedRowsKey: 0,
      dataTableKey: 0,
      minMaxValues: {},
      tableFilters: [
        {
          value: '',
        },
      ],
      downloadingLoaded: false,
    }
  },
  watch: {
    view () {
      this.totalKey++
      this.setColors()
    },
    isChangeView () {
      this.setColors()
    }
  },
  computed: {
    positionTitle () {
      if(this.dashboardDetails?.view_keys[this.index]?.filter(item=> item).length === 1){
        return this.index + 1
      }
      return this.position
    },
    position () {
      return `${this.index + 1}${['A', 'B', 'C', 'D'][this.secondaryIndex]}`
    },
    sortProperty () {
      return this.view.view.properties.sort
    },
    getHeight () {
      if (this.sortedData.length >= 5) {
        return '50vh'
      }
      return this.sortedData.length * '80px'
    },
    sortedData () {
      return this.view.data
    },
    columnProperties () {
      return this.view.view.measuresProps
    },
    columns () {
      const keys = Object.keys((this.view.data || {})[0] || {})
      return keys.filter(key => {
        const hiddenColumns = ['name', 'route', 'image_url', 'Sort', 'hasChildren', ...this.view.view.dimensions, this.view.view.properties.matrix]
        return !hiddenColumns.includes(key)
      })
    }
  },
  methods: {
    onSortChange (props){
      if(!props.order) {
        this.sortedColumn = null
        this.sortedOrder = null
        return
      }
      this.sortedColumn = props.prop
      this.sortedOrder = props.order
    },
    sortChildren (a, b){
      return (a[this.sortedColumn] - b[this.sortedColumn]) * (this.sortedOrder === 'ascending' ? 1 : -1)
    },
    setColors () {
      this.$nextTick(() => {

        if(typeof this.$el?.querySelectorAll !== 'function'){
          return
        }
        const elements = this.$el?.querySelectorAll?.('.el-table__footer-wrapper tbody td, .el-table__fixed-footer-wrapper tbody td') || []
        elements.forEach(element => {
          element.classList?.add((this.view.view.properties.total_row_color || '#f5f7fa')?.replace('#', 'a'))
        })
      })
    },
    onInfoMenuChange (option_key, value) {
      if (value) {
        this.infoMenuOpened = { [option_key]: value }
      }
    },
    formatter (row, column) {
      if (typeof (row && row[column.property]) === 'string') {
        return row[column.property]
      }
      const dataColumn = column.property
      if ((this.columnProperties || {})[dataColumn]?.percentage) {
        if (typeof ((row && row[column.property]) || 0) !== 'number') {
          return ((row && row[column.property]) || 0)
        }
        return (((row && row[column.property]) || 0) * 100).toFixed(
          this.columnProperties[dataColumn]?.decimals > 2 ? this.columnProperties[dataColumn]?.decimals - 2 : 0
        ) + '%'
      }

      if (row && typeof row[dataColumn] === 'string') {
        return row[column.property]
      }

      if (typeof ((row && row[column.property]) || 0) !== 'number') {
        return ((row && row[column.property]) || 0)
      }
      const value = ((row && row[column.property]) || 0).toFixed(
        this.columnProperties[dataColumn]?.decimals)
      if (this.columnProperties[dataColumn]?.decimals) {
        return value
      }
      return this.$options.filters.formatThousands(parseFloat(value))
    },
    filterChanged (value) {
      this.$emit('change-view', { view: value, position: this.index })
    },
    downloadData (sep, child = false) {
      this.downloadingLoaded = true
      const downloadPayload = {
        ...this.view.view,
        csv: true,
        csv_separator: sep,
        csv_decimal: '.'
      }
      if (sep === 'dutch') {
        downloadPayload.csv_separator = ';'
        downloadPayload.csv_decimal = ','
      }

      const pageFilters = {}
      const propertiesWithDataset = this.analyticsFiltersStore.propertiesWithDataset
      const dashboardFilters = this.dashboardDetails.filters || {}
      for (const key in dashboardFilters) {
        if (propertiesWithDataset[this.view.view.dataset] && propertiesWithDataset[this.view.view.dataset][key]) {
          pageFilters[key] = dashboardFilters[key]
        }
      }
      const optionKey = 'properties/article/option_key'
      if (dashboardFilters[optionKey]) {
        pageFilters[optionKey] = dashboardFilters[optionKey]
      }
      if (this.$route.query.option_key) {
        pageFilters[optionKey] = this.$route.query.option_key
      }
      downloadPayload.filters = { ...pageFilters, ...downloadPayload.filters }
      downloadAnalytics(downloadPayload, child)
        .then(({ url }) => {
          this.downloadingLoaded = false
          const link = document.createElement('a')
          link.setAttribute('href', url)
          const today = new Date()
          const dd = today.getDate()
          const mm = today.getMonth() + 1
          const yyyy = today.getFullYear()
          link.setAttribute('download', this.view.view.view_name + '_' + String(dd) + '_' + String(mm) + '_' + String(yyyy) + '.csv')
          link.click()
        })
    },

    changeView (value) {
      this.isChangeView = false
      this.$emit('toggle-view', false)
      this.dataTableKey++
      this.$emit('change-view', value)
    },
    load (tree, treeNode, resolve) {
      const rowKey = this.view.view.dimensions.reduce((prev, cur) => {
        return prev + tree[cur]
      }, '')
      if (this.loadedChildren[rowKey]) {

        if(this.sortedColumn){
          resolve(this.loadedChildren[rowKey].sort(this.sortChildren))
          return
        }
        resolve(this.loadedChildren[rowKey])
        return
      }
      this.loadViewData([this.view.view, tree]).then((data) => {
        const resolveData = data.map((item) => {
          const child = { ...item, isChild: true }
          child[this.view.view.dimensions[0]] = child[this.view.view.properties.child_rows]
          return child
        })
        if(this.sortedColumn){
          resolveData.sort(this.sortChildren)
        }
        this.loadedChildren[rowKey] = resolveData
        resolve([...resolveData])
      })
    },
    getCellStyle (row, view, column) {
      const columnProp = this.columnProperties[column]
      if (typeof row === 'string') {
        return {
          justifyContent: 'start',
          display: 'flex'
        }
      }
      if (!columnProp.color_grading || row === undefined) {
        return {
          justifyContent: 'center',
          display: 'flex'
        }
      }
      return {
        background: this.getCellGradeColor(row, view, column),
        borderRadius: '4px',
        color: '#fff',
        padding: '13px 0',
        justifyContent: 'center',
        display: 'flex'
      }
    },
    getCellGradeColor (row, view, column) {
      const columnProp = this.columnProperties[column]
      if (typeof row !== 'number') {
        return 'full-screen'
      }
      const min = parseFloat(columnProp.min == null ? this.getMinMax(view, column).min * (columnProp.percentage ? 100 : 1) : columnProp.min)
      const max = parseFloat(columnProp.max == null ? this.getMinMax(view, column).max * (columnProp.percentage ? 100 : 1) : columnProp.max)
      if (columnProp.percentage) {
        row *= 100
      }
      const middles = {
        first: min + ((max - min) * ((columnProp.first || 25) / 100)),
        second: min + ((max - min) * ((columnProp.second || 50) / 100)),
        third: min + ((max - min) * ((columnProp.third || 75) / 100))
      }
      const keys = ['first', 'second', 'third']
      if (row <= min) {
        return columnProp.min_color_code || '#085D70'
      }
      if (row >= max) {
        return columnProp.max_color_code || '#FFAB00'
      }
      if (!columnProp.first && !columnProp.second && !columnProp.third) {
        return interpolate([columnProp.min_color_code || '#085D70', columnProp.max_color_code || '#FFAB00'])((row - min) / (max - min))
      }
      let workingKey = ''
      let minWorkingKey = ''
      for (const key of keys) {
        if (columnProp[key]) {
          if (row <= middles[key]) {
            workingKey = key
          } else {
            minWorkingKey = key
          }
        }
      }
      const maxValue = workingKey ? middles[workingKey] : max
      const minValue = minWorkingKey ? middles[minWorkingKey] : min
      const maxColor = workingKey ? columnProp[`${workingKey}_color`] : columnProp.max_color_code || '#FFAB00'
      const minColor = minWorkingKey ? columnProp[`${minWorkingKey}_color`] : columnProp.min_color_code || '#085D70'
      return interpolate([minColor, maxColor])((row - minValue) / (maxValue - minValue))

    },

    getMinMax (view, column) {
      if (!(this.minMaxValues[view.view.view_key] && this.minMaxValues[view.view.view_key][column])) {
        const minMax = {
          min: Math.min(...view.data.map((item) => item[column])),
          max: Math.max(...view.data.map((item) => item[column]))
        }
        this.minMaxValues[view.view.view_key] = { [column]: minMax }
      }
      return this.minMaxValues[view.view.view_key][column]
    },
    async loadViewData ([view, tree]) {
      try {
        const pageFilters = {}
        const propertiesWithDataset = this.analyticsFiltersStore.propertiesWithDataset
        const dashboardFilters = this.dashboardDetails?.filters || {}
        for (const key in dashboardFilters) {
          if (propertiesWithDataset[view.dataset] && propertiesWithDataset[view.dataset][key]) {
            pageFilters[key] = dashboardFilters[key]
          }
        }
        const optionKey = 'properties/article/option_key'
        if (dashboardFilters[optionKey]) {
          pageFilters[optionKey] = dashboardFilters[optionKey]
        }
        return (await performViewData([view, tree], true, pageFilters)).childViews.data
      } catch (e) {
        console.log(e)
      }
    },

    tableProps (row) {
      // return row[this.view.view.dimensions]
      const dimensions = this.view.view.dimensions.filter(item => item !== this.view.view.properties.matrix)
      let props = ''
      dimensions.forEach(item => {
        props += row[item] || ''
      })
      return props
    },
    getSummary (params) {
      if(!this.view.total_row[0]){
        return []
      }
      const sums = []
      const { columns } = params
      columns.forEach((column, index) => {
        if (this.view.view.dimensions.includes(column.property)) {
          sums[index] = ''
          if (index === 0) {
            sums[index] = 'Total'
          }
          return
        }
        const dataColumn = column.property
        const data = this.view.total_row[0]
        let value = data[dataColumn]
        if ((this.columnProperties || {})[dataColumn]?.percentage) {
          value = ((value || 0) * 100).toFixed(
            this.columnProperties[dataColumn]?.decimals > 2 ? this.columnProperties[dataColumn]?.decimals - 2 : 0
          ) + '%'
        } else {
          value = (value || 0).toFixed(this.columnProperties[dataColumn]?.decimals)
          if (!this.columnProperties[dataColumn]?.decimals) {
            value = this.$options.filters.formatThousands(parseFloat(value)) || '0'
          }
        }
        sums[index] = value
      })
      return sums
    },
  },
  props: {
    dashboardDetails: {
      type: Object,
      default () {
        return {}
      }
    },
    viewsMap: {
      type: Object,
      default () {
        return {}
      }
    },
    shared: {
      type: Boolean,
      default () {
        return true
      }
    },
    firstColumnWider: {
      type: Boolean,
      default () {
        return false
      }
    },
    isRecommendations: {
      type: Boolean,
      default () {
        return false
      }
    },
    hasTitle: {
      type: Boolean,
      default () {
        return true
      }
    },
    inModal: {
      type: Boolean,
      default () {
        return false
      }
    },

    index: {
      type: Number,
      default () {
        return null
      }
    },
    secondaryIndex: {
      type: Number,
      default () {
        return null
      }
    },
    positions: [],
    datasets: [],
    dataSetForChoose: [],
    view: {
      type: Object,
      default () {
        return {
          view: {},
          data: []
        }
      }
    }
  },
}
</script>

<style lang="scss">
.data-sets-matrix-tables {
  tbody {
    max-height: 60vh;
  }

  .el-table td {
    padding: 0;
  }

  .el-table--border td:first-child .cell, .el-table--border th:first-child .cell {
    padding: 0;
  }

  .el-table td .cell {
    padding: 0;
    display: flex;
    align-items: center;
  }

  .el-table .cell > *:not(.el-table__expand-icon):not(.el-table__indent):not(.el-table__placeholder){
   flex-grow: 1;
  }

  .el-table .el-table__footer td .cell{
    display: block;
  }

  .el-table td .cell .row.justify-start{
    width: 0;
  }
  .el-table__body td, .el-table__fixed-footer-wrapper td, .el-table__footer-wrapper td {
    &.a00000099 {
      background-color: #00000099 !important;
    }
    &.a007AFF {
      background-color: #007AFF !important;
    }
    &.a4CAF50 {
      background-color: #4CAF50 !important;
    }
    &.aFF9800 {
      background-color: #FF9800 !important;
    }
    &.aFF9A9A {
      background-color: #FF9A9A !important;
    }
    &.a085D70 {
      background-color: #085D70 !important;
    }
    &.a9BE48E {
      background-color: #9BE48E !important;
    }
    &.a4FB4AB {
      background-color: #4FB4AB !important;
    }
    &.aF989D0 {
      background-color: #F989D0 !important;
    }
    &.aEE3766 {
      background-color: #EE3766 !important;
    }
    &.a765397 {
      background-color: #765397 !important;
    }
    &.aFF675B {
      background-color: #FF675B !important;
    }
    &.a73A1C7 {
      background-color: #73A1C7 !important;
    }
    &.aF5907B {
      background-color: #F5907B !important;
    }
    &.a00000033 {
      background-color: #00000033 !important;
    }
    &.a00000099 {
      background-color: #00000099 !important;
    }
    &.aFF5722 {
      background-color: #FF5722 !important;
    }
    &.aFFD202 {
      background-color: #FFD202 !important;
    }
    &.aFFFFFF {
      background-color: #FFFFFF !important;
    }
    &.af5f7fa {
      background-color: #f5f7fa !important;
    }
  }

  .el-table .el-table__row--level-1{
    background-color: #fafafa;
  }
}
</style>

<style scoped>
</style>
