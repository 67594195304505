<template>
  <v-card>
    <v-card-title class="text-body-1 font-weight-medium">Custom Markdown</v-card-title>
    <v-divider/>
    <v-card-text>
      <div class="container px-12">
        <v-combobox
          v-model="inputForm.optionKeys.value"
          :items="optionsListToShow"
          :error-messages="inputForm.optionKeys.errors"
          class="mb-3"
          label="Options"
          placeholder="Search"
          :return-object="false"
          item-value="option_key"
          item-text="name"
          multiple
          chips
          clearable
          small-chips
          deletable-chips
          hide-details="auto"
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"
          @blur="onOptionsBlur"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              small
              close
              outlined
              @click="handleFirstOptionClick"
              @click:close="inputForm.optionKeys.value.splice(0, 1)"
            >
              <span>{{ keyToOptionMap[item] }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption mx-1">
              +{{ inputForm.optionKeys.value.length - 1 }}
            </span>
          </template>
        </v-combobox>
        <v-combobox
          :menu-props="{
            left: true,
          }"
          v-model="inputForm.markdown_moment.value"
          :error-messages="inputForm.markdown_moment.errors"
          :items="markdownMoments"
          class="mb-3"
          label="Markdown moment"
          placeholder="Search"
          hide-details="auto"
          :multiple="false"
          :return-object="false"
          item-value="markdown_moment_key"
          item-text="markdown_moment_name"
        >
          <template v-slot:selection="{ item }">
            <span>{{ keyToMarkdownMap[item] }}</span>
          </template>
        </v-combobox>
        <WeekSelector
          v-model="inputForm.week.value"
          :error-messages="inputForm.week.errors"
          :end-year="1"
          :start-year="-1"
          class="mb-3"
          label="Week"
          hide-details="auto"
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"/>
        <collapsible-select
          v-model="inputForm.storeKeys.value"
          :items="storesListToShow"
          :error-messages="inputForm.storeKeys.errors"
          class="mb-3"
          label="Stores"
          placeholder="Search"
          item-value="store_key"
          item-text="store_name"
          autocomplete
          multiple
          chips
          clearable
          small-chips
          deletable-chips
          show-select-all
          hide-details="auto"
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"
          @blur="onStoresBlur"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              small
              close
              outlined
              @click="handleFirstStoreClick"
              @click:close="inputForm.storeKeys.value.splice(0, 1)"
            >
              <span>{{ item.store_name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption mx-1">
              +{{ inputForm.storeKeys.value.length - 1 }}
            </span>
          </template>
        </collapsible-select>
        <v-text-field
          v-model="inputForm.price.value"
          :error-messages="inputForm.price.errors"
          hide-details="auto"
          label="Price"
          class="mb-2"
          @input="onPriceChange"
        />
        <v-select
          v-model="inputForm.status.value"
          :error-messages="inputForm.status.errors"
          :items="statusOptions"
          hide-details="auto"
          label="Status"
          class="mb-2"
        />
      </div>
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-row class="dialog-footer text-right py-2">
        <v-col>
          <v-btn
            color="primary"
            depressed
            text
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
          <v-btn
            depressed
            @click="addCustomMarkdown"
            class="mx-4 primary"
          >
            Add
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { findOptionsByFiltersTSQL } from '@/api/options'
import { getMarkdownMoments } from '@/api/markdownMoments'
import CollapsibleSelect from '@/components/CollapsibleSelect'
import { saveMarkdownProposals } from '@/api/markdown_proposals'
import WeekSelector from '../WeekSelector'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'CustomMarkdownDialog',
  components: { WeekSelector, CollapsibleSelect },
  data () {
    return {
      addLock: false,
      showOnlyCurrentOptions: false,
      showOnlyCurrentStores: false,
      optionsList: [],
      markdownMoments: [],
      inputForm: {
        optionKeys: {
          value: [],
          errors: [],
        },
        storeKeys: {
          value: [],
          errors: [],
        },
        markdown_moment: {
          value: null,
          errors: [],
        },
        week: {
          value: null,
          errors: [],
        },
        price: {
          value: null,
          errors: [],
        },
        status: {
          value: 'accepted',
          errors: [],
        }
      }
    }
  },
  created () {
    this.loadOptions()
    this.getMarkdownMoments()
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    stores () {
      return (this.appConfig?.stores_open || [])
    },
    statusOptions () {
      return ['accepted', 'planned']
    },
    optionsListToShow () {
      return this.showOnlyCurrentOptions
        ? this.optionsList.filter(
          o => this.inputForm.optionKeys.value.includes(o.option_key)
        ) : this.optionsList
    },
    storesListToShow () {
      const chosenKeys = this.inputForm.storeKeys.value
      return this.showOnlyCurrentStores
        ? this.stores.filter(
          o => chosenKeys.includes(o.store_key)
        ) : this.stores
    },
    keyToOptionMap () {
      return this.optionsList.reduce((acc, o) => {
        acc[o.option_key] = o.name
        return acc
      }, {})
    },
    keyToMarkdownMap () {
      return this.markdownMoments.reduce((acc, o) => {
        acc[o.markdown_moment_key] = o.markdown_moment_name
        return acc
      }, {})
    },
  },
  methods: {
    async getMarkdownMoments () {
      this.markdownMoments = await getMarkdownMoments()
    },
    async loadOptions () {
      const payload = {
        search_text: null,
        discount_min: null,
        discount_max: null,
        stock_active: null,
        osp_min: null,
        osp_max: null,
        asp_min: null,
        asp_max: null,
        stock_min: null,
        stock_max: null,
        inbounds_min: null,
        inbounds_max: null,
        option_keys_include: [],
        option_keys_exclude: [],
        properties_include: {},
        properties_exclude: {},
        filters: [],
      }

      const response = await findOptionsByFiltersTSQL(payload)

      this.optionsList = response.options
    },
    async addCustomMarkdown () {
      if (this.addLock) {
        return
      }
      if (!this.formValidates()) {
        return
      }
      this.addLock = true
      try {
        const ObjTemplate = {
          option_key: undefined,
          markdown_moment_key: this.inputForm.markdown_moment.value,
          week: parseInt(this.inputForm.week.value),
          store_keys: [...this.inputForm.storeKeys.value],
          price: parseFloat(this.inputForm.price.value),
          status: this.inputForm.status.value,
          optimization_type: 'custom',
        }
        const payload = []
        for (const optionKey of this.inputForm.optionKeys.value) {
          payload.push({
            ...ObjTemplate,
            option_key: optionKey,
          })
        }
        const proposals = await saveMarkdownProposals(payload)
        this.$emit('close', proposals)
      } catch (e) {
        console.error(e)
      }
      this.addLock = false
    },
    formValidates () {
      let valid = true
      // reset all errors
      for (const key in this.inputForm) {
        this.inputForm[key].errors = []
      }
      if (!this.inputForm.optionKeys.value.length) {
        this.inputForm.optionKeys.errors = ['Please select at least one option']
        valid = false
      }
      if (!this.inputForm.storeKeys.value.length) {
        this.inputForm.storeKeys.errors = ['Please select at least one store']
        valid = false
      }
      if (!this.inputForm.markdown_moment.value) {
        this.inputForm.markdown_moment.errors = ['Please select a markdown moment']
        valid = false
      }
      if (!this.inputForm.week.value) {
        this.inputForm.week.errors = ['Please select a week']
        valid = false
      }
      if (!this.inputForm.price.value) {
        this.inputForm.price.errors = ['Please enter a price']
        valid = false
      }
      // check if price is a number
      if (this.inputForm.price.value) {
        this.onPriceChange()
        if (this.inputForm.price.errors.length) {
          valid = false
        }
      }
      return valid
    },
    onPriceChange () {
      this.inputForm.price.value = this.inputForm.price.value.replaceAll(',', '.')
      // check if price contains only numbers and one dot
      const regex = /^[0-9]+(\.[0-9]+)?$/
      if (!regex.test(this.inputForm.price.value)) {
        this.inputForm.price.errors = ['Please enter a valid price']
        return
      }
      const value = parseFloat(this.inputForm.price.value)
      if (isNaN(value)) {
        this.inputForm.price.errors = ['Please enter a valid price']
        return
      }
      this.inputForm.price.errors = []
    },
    onOptionsBlur () {
      setTimeout(() => {
        this.showOnlyCurrentOptions = false
      }, 100)
    },
    onStoresBlur () {
      setTimeout(() => {
        this.showOnlyCurrentStores = false
      }, 100)
    },
    handleFirstOptionClick () {
      this.showOnlyCurrentOptions = true
    },
    handleFirstStoreClick () {
      this.showOnlyCurrentStores = true
    },
  }
}
</script>
