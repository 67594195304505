<template>
  <div class="stock-assortment-page mb-12">
    <h3 class="mb-6 mt-6">Manage</h3>

    <v-card>
      <v-tabs v-model="activeTab" grow show-arrows>
        <v-tabs-slider />
        <v-tab class="px-8">Sets</v-tab>
        <v-tab class="px-8">Moments</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item :transition="false" :reverse-transition="false">
          <OptionSets/>
          <v-divider class="mt-2" />
          <storeSets />
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false">
          <MarkdownMoments/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import OptionSets from '@/components/Assortment/OptionSets/OptionSets'
import storeSets from '@/components/Assortment/StoreSets/StoreSets'
import { loadManageData } from '@/store/manageDataLoader'
import MarkdownMoments from '@/components/Assortment/MarkdownMoments'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'

export default {
  name: 'PricingManagePage',
  components: { MarkdownMoments, storeSets, OptionSets },
  data () {
    return {
      activeTab: 0,
    }
  },
  watch: {
    activeTab (val){
      this.setStoresSetsLabel (val === 0 ? 'sets' : 'pricing')
    }
  },
  methods: {
    setStoresSetsLabel (label){
      const generalStore = useGeneralStore()
      if(label === 'sets'){
        generalStore.setCurrentStoresSetsLabel('all')
        useStoreAndOptionSetsStore().loadStoreSets()
        useStoreAndOptionSetsStore().loadOptionSets()
      }else{
        generalStore.setCurrentStoresSetsLabel('pricing')
        useStoreAndOptionSetsStore().loadStoreSets()
        useStoreAndOptionSetsStore().loadOptionSets()
      }
    },
  },
  created () {
    const generalStore = useGeneralStore()
    generalStore.setCurrentStoresSetsLabel('all')
    loadManageData()
  },
}
</script>

<style scoped>

</style>
