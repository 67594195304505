<template>

  <v-menu open-on-hover offset-y nudge-bottom="5" :max-width="250"
    :key="view.view.view_key + view.view.filters_applied?.toString()"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        :color="hasFilters ? '#007AFF' :''"
        class=" action-btn-primary"
        @click.stop="openFilters(true)"
        v-bind="attrs" v-on="on"
      >
        filter_list
      </v-icon>
    </template>
    <v-card class="pa-2">
      <h5>Filters Applied</h5>
      <v-card-text class="pa-1">
        <p class="my-2" v-for="item of view.view.filters_applied" :key="item">
          {{item.split('/')[1] | formatString}}
          ({{item.split('/')[2] | formatString}})
        </p>
      </v-card-text>
      <h5>Last Updated</h5>
      <v-card-text class="pa-1">
        <p class="my-1">
          {{view.view.refresh_time_ago}}
        </p>
      </v-card-text>
    </v-card>
  </v-menu>

</template>
<script>
import { useAnalyticsFiltersStore } from '@/store/pinia/analyticsFiltersStore'

export default {
  name: 'DataSetsViewFilter',
  props: ['index', 'view'],
  setup () {
    return {
      analyticsFiltersStore: useAnalyticsFiltersStore(),
    }
  },
  data () {
    return {
      menuOpened: false,
      properties: null,
      propKey: 0,
      newProperties: {},
      values: [],
      chosenProperty: '',
    }
  },
  computed: {
    hasFilters () {
      return !!this.view.view.filters_applied?.length
    }
  },
  methods: {
    async openFilters () {
      const viewsList = []
      this.analyticsFiltersStore.viewsList.forEach(views => viewsList.push(...(views.filter(view => Object.keys(view || {}).length))))
      const index = viewsList.findIndex((view) => view.view_key === this.view.view.view_key)
      this.analyticsFiltersStore.setSingleFiltersOpened(index)
      this.analyticsFiltersStore.toggleFilter()
    },
  },
}
</script>

<style scoped>
</style>
