<template>
  <v-dialog v-model="isOpen" persistent scrollable content-class="align-self-start mt-14" width="600px" max-width="90%">
    <v-card class="rounded-0 add-edit-static-dialog">
      <v-overlay absolute color="white" :value="stockManageStore.capacityTab.dialogLoading">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>

      <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">
        {{ `${currentCapacityOption && isNewOption ? 'Add low level capacity' : 'Edit low level capacity'}` }}
      </v-card-title>
      <v-divider />
      <v-card-text class="px-0 text--primary">
        <div class="container px-12">
          <template v-if="currentCapacityOption">

            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0">
                <v-autocomplete
                  label="Option set"
                  v-model="currentCapacityOption.options_set_key"
                  @input="$emit('capacity-update', currentCapacityOption)"
                  :items="stockManageStore.capacityTab.capacityOptionSetsList"
                  :error-messages="currentCapacityOptionErrors.options" item-text="set_name"
                  item-value="options_set_key" autocomplete show-select-all hide-details />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col v-if="!fixedOptionKey" class="py-0">
                <collapsible-select
                  class="pt-3"
                  autocomplete
                  :menu-props="{
                    left: true,
                  }"
                  v-model="currentCapacityOption.option_keys"
                  item-value="option_key"
                  item-text="name"
                  :error-messages="currentCapacityOptionErrors.options" label="Options" :items="reorderOptionsList"
                  hide-no-data hide-details>
                </collapsible-select>
              </v-col>
              <v-col v-else class="py-0">
                <label class="v-label theme--light mr-4">Option</label>
                <v-chip small class="my-2">
                  {{ fixedOptionKey }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0">
                <v-autocomplete
                  label="Store set"
                  class="pt-3"
                  v-model="currentCapacityOption.stores_set_key"
                  @input="$emit('capacity-update', currentCapacityOption)"
                  :items="stockManageStore.capacityTab.capacityStoreSetsList"
                  :error-messages="currentCapacityOptionErrors.stores" item-text="set_name"
                  item-value="stores_set_key" autocomplete show-select-all hide-details />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0">
                <collapsible-select
                  class="pt-3"
                  label="Stores"
                  v-model="currentCapacityOption.store_keys"
                  :items="appConfig.stores_open"
                  :error-messages="currentCapacityOptionErrors.stores"
                  item-text="store_name" item-value="store_key" autocomplete show-select-all hide-details />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0">
                <v-select
                  class="mt-0"
                  label="Type"
                  v-model="currentCapacityOption.capacity_type"
                  :items="capacityTypes"
                  :error-messages="currentCapacityOptionErrors.capacityType"
                  hide-details />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0">
                <collapsible-select
                  class="pt-3"
                  :items="skus.map((item) => { return { value: item, text: item } })"
                  v-model="currentCapacityOption.sku_names" clearable autocomplete show-select-all label="SKUs"
                  hide-details="auto" />
                <v-expansion-panels
                  class="mt-4"
                  flat
                >
                  <v-expansion-panel
                    flat
                  >
                    <v-expansion-panel-header class="pa-0" style="min-height: 0" hide-actions>
                      <div style="color: rgb(144, 147, 153); font-weight: 500">Combinations <v-icon>keyboard_arrow_down</v-icon>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="capacity-info-table px-0"
                    >

                      <data-tables
                        class="capacity-info-table"
                        :data="infoData"
                        :pagination-show="false"
                      >
                        <el-table-column
                          label="Type"
                          width="120"

                        >
                          <template slot-scope="scope">
                            {{scope.row.type}}
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="SKUs"
                          width="70"

                        >
                          <template slot-scope="scope">
                            {{scope.row.skus}}
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Impact"
                        >
                          <template slot-scope="scope">
                            {{scope.row.impact}}
                          </template>
                        </el-table-column>
                      </data-tables>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0 pr-8">
                <v-menu ref="dateMinPicker" v-model="dateMinPickerActive" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on }">
                    <v-text-field :value="currentCapacityOption.date_min" clearable hide-details label="Start" v-on="on"
                      @click:clear="currentCapacityOption.date_min = null" />
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :locale-first-day-of-year="4" :show-week="true" no-title v-model="currentCapacityOption.date_min"
                    @click="dateMinPickerActive = false" />
                </v-menu>
              </v-col>
              <v-col class="py-0 pl-8">
                <v-menu ref="dateMaxPicker" v-model="dateMaxPickerActive" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on }">
                    <v-text-field :value="currentCapacityOption.date_max" clearable hide-details label="End" v-on="on"
                      @click:clear="currentCapacityOption.date_max = null" />
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :locale-first-day-of-year="4" :show-week="true" no-title v-model="currentCapacityOption.date_max"
                    @input="dateMaxPickerActive = false" />
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-4 mb-4">
              <v-col class="py-0 pr-8">
                <v-text-field type="number" v-model.number="currentCapacityOption.must_have"
                  :error-messages="currentCapacityOptionErrors.mustHave" label="Must have" hide-details />
              </v-col>
              <v-col class="py-0 px-8">
                <v-text-field type="number" v-model.number="currentCapacityOption.nice_have"
                  :error-messages="currentCapacityOptionErrors.niceToHave" label="Nice to have" hide-details />
              </v-col>
              <v-col class="py-0 pl-8">
                <v-text-field type="number" v-model.number="currentCapacityOption.max_have"
                  :error-messages="currentCapacityOptionErrors.maxHave" label="Maximum" hide-details />
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 mb-0 pt-2 " v-if="!isCapacityGroups">
              <v-col cols="auto" class="py-0">
                <label class="v-label theme--light text-no-wrap">Exclude
                  <v-tooltip top :max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                    </template>
                    <span>
                      When this is set to true and ‘Exclude minimum’ in timeline settings is activated, stores will be excluded based on the ‘Must have’ value.
                    </span>
                  </v-tooltip>
                </label>
              </v-col>
              <v-col class="py-0 pl-0">
                <v-checkbox v-model="currentCapacityOption.exclude" hide-details="auto" class="mt-0 pt-0 " />
              </v-col>
            </v-row>
            <v-row class="align-baseline mt-0 mb-4">
              <v-col class="py-0">
                <v-textarea rows="4" v-model="currentCapacityOption.note"
                  :error-messages="currentCapacityOptionErrors.note" label="Note" hide-details />
              </v-col>
            </v-row>
          </template>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2 px-4">
          <v-col>
            <v-row>
              <v-col>
                <v-btn color="primary" depressed text @click="$emit('cancel')">Cancel</v-btn>
                <v-btn color="primary" depressed outlined @click="$emit('save', currentCapacityOption)" class="mx-4">Save</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CollapsibleSelect from '@/components/CollapsibleSelect'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { findOptionsByFiltersTSQL } from '@/api/options'

import { useStockManageStore } from '@/store/pinia/stockManageStore'

export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  name: 'AddEditDialog',
  components: { CollapsibleSelect },
  props: {
    skus: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    currentCapacityOption: {
      type: Object,
      required: true,
    },
    isNewOption: {
      type: Boolean,
      default: false,
    },
    currentCapacityOptionErrors: {
      type: Object,
      required: true,
    },
    fixedOptionKey: {
      type: String,
      default: '',
    },
    isCapacityGroups: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      dateMinPickerActive: false,
      dateMaxPickerActive: false,
      reorderOptionsList: [],
      capacityTypes: [
        { text: 'Pieces per SKU', value: 'pieces_per_sku' },
        { text: 'Pieces per option', value: 'pieces_per_option' },
        { text: 'Unique SKUs', value: 'unique_skus' },
        { text: 'Serial SKUs', value: 'serial_skus' },
      ],
      infoData: [
        {
          type: 'Pieces per SKU',
          skus: 'Empty',
          impact: 'Set pieces per SKU for all SKUs',
        },
        {
          type: 'Pieces per SKU',
          skus: 'Filled',
          impact: 'Set pieces per SKU for selected SKUs',
        },
        {
          type: 'Pieces per Option',
          skus: 'Empty',
          impact: 'Set pieces per Option within all SKUs',
        },
        {
          type: 'Pieces per Option',
          skus: 'Filled',
          impact: 'Set pieces per Option within selected SKUs',
        },
        {
          type: 'Unique SKUs',
          skus: 'Empty',
          impact: 'Set amount of unique SKUs within all SKUs',
        },
        {
          type: 'Unique SKUs',
          skus: 'Filled',
          impact: 'Set amount of unique SKUs within selected SKUs',
        },
        {
          type: 'Serial SKUs',
          skus: 'Empty',
          impact: 'Set amount of serial SKUs within all SKUs',
        },
        {
          type: 'Serial SKUs',
          skus: 'Filled',
          impact: 'Set amount of serial SKUs within selected SKUs',
        },
      ]
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
  },
  methods:{
    async loadReorderOptions () {
      const payload = {
        search_text: null,
        discount_min: null,
        discount_max: null,
        stock_active: null,
        osp_min: null,
        osp_max: null,
        asp_min: null,
        asp_max: null,
        stock_min: null,
        stock_max: null,
        inbounds_min: null,
        inbounds_max: null,
        option_keys_include: [],
        option_keys_exclude: [],
        properties_include: {},
        properties_exclude: {},
        filters: [],
      }
      return findOptionsByFiltersTSQL(payload, ['option_key', 'name'])
        .then(response => {
          this.reorderOptionsList = response.options
        })
    },

  },
  mounted () {
    this.loadReorderOptions()
    if(this.fixedOptionKey){
      this.currentCapacityOption.option_keys = [this.fixedOptionKey]
    }
  }
}
</script>
<style lang="scss">
.add-edit-static-dialog {
  .v-expansion-panel-content__wrap {
    padding: 0
  }
}
</style>
