<template>
  <div
    v-if="filtersStore.filtersDataLoaded"
    class="stock-filter-widget"
  >
    <v-card v-show="filtersOpened" class="pt-2 mb-12 px-4 filter-widget-card" id="filterForm">
      <div
        v-show="!optionsSearchVisible"
        class="filter-options"
      >
        <div class="pt-4 px-8">
          <template v-for="(filterName, index) in pageFilters">
            <template v-if="filtersData[filterName]">
              <ArrayOfValuesWithPropertiesFilter
                :key="index"
                v-if="filtersData[filterName].type === 'arrayOfValuesWithProperties'"
                :filterName="filterName"
                :filterData="filtersData[filterName]"
                :filterOptionsList="filtersOptionsList[filtersData[filterName].filterOptionsListProp]"
                :selectedProperties="selectedProperties"
                @add-property-click="handleAddPropertyClick"
                @property-change="handlePropertyChanged"
                @property-value-change="handlePropertyValueChanged"
                @property-delete="handlePropertyDeleteClick"
                @filter-changed="handleFilterChanged"
                @revert-click="revertFilter"
              />
              <ArrayOfValuesFilter
                :key="index"
                v-if="filtersData[filterName].type === 'arrayOfValues'"
                :filterName="filterName"
                :filterData="filtersData[filterName]"
                :filterOptionsList="filtersOptionsList[filtersData[filterName].filterOptionsListProp]"
                @filter-changed="handleFilterChanged"
                @revert-click="revertFilter"
              />
              <OptionsFilter
                :key="index"
                v-if="filtersData[filterName].type === 'options'"
                :filterName="filterName"
                :filterData="filtersData[filterName]"
                :optionsList="optionsList"
                @edit-click="handleOptionsEditClick"
                @revert-click="revertFilter"
              />
              <DummiesFilter
                :key="index"
                v-if="filtersData[filterName].type === 'dummies'"
                :filterName="filterName"
                :filterData="filtersData[filterName]"
                @filter-changed="handleFilterChanged"
                @revert-click="revertFilter"
              />
              <ShopItemsFilter
                :key="index"
                v-if="filtersData[filterName].type === 'shopItems'"
                :filterName="filterName"
                :filterData="filtersData[filterName]"
                :currencies="currencies"
                @filter-changed="handleFilterChanged"
                @revert-click="revertFilter"
              />
            </template>
          </template>
        </div>
      </div>
      <div
        v-show="optionsSearchVisible && !optionsSearchResultsVisible"
        class="options-search"
      >
        <div class="font-weight-medium my-3 px-8 text-body-1">Options</div>
        <OptionsSearch
          ref="optionsSearch"
          class="mx-2"
          @resultsUpdated="handleOptionsSearchResultsUpdated"
          @filtersUpdated="handleOptionsFilterUpdated"
        />
      </div>
      <div
        v-if="optionsSearchVisible && optionsSearchResultsVisible"
        class="options-search-results"
      >
        <div class="font-weight-medium my-3 px-8 text-body-1">Results</div>
        <OptionsSearchResults
          ref="optionsSearchResults"
          class="mx-2"
          :search-results="optionsSearchResults"
        />
      </div>
      <div class="controls">
        <v-divider />
        <template v-if="optionsSearchVisible">
          <v-row class="mx-0 mt-3 pb-6 align-center">
            <v-col class="pl-12">
              <template v-if="!optionsSearchResultsVisible">
                <v-btn text color="primary" class="mr-2" @click="optionsSearchResultsVisible = true">SHOW {{ optionsSearchResults.length }} RESULTS</v-btn>
              </template>
              <template v-else>
                <v-btn text color="primary" class="mr-2" @click="optionsSearchResultsVisible = false">HIDE {{ optionsSearchResults.length }} RESULTS</v-btn>
              </template>
            </v-col>
            <v-col class="pr-6 text-right">
              <v-btn text color="primary" class="mr-2" @click="handleOptionsSearchCancelClick">Cancel</v-btn>
              <v-btn outlined color="primary" @click="handleOptionsSearchUpdateClick">Update</v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <div class="mt-6 pb-6 pr-6 text-right">
            <v-btn text color="primary" class="mr-2" @click="handleResetAllClick">Reset All</v-btn>
            <v-btn outlined color="primary" @click="handleApplyClick">Apply</v-btn>
          </div>
        </template>
      </div>
    </v-card>
  </div>
</template>

<script>
import { filtersData } from '@/variables'
import ArrayOfValuesWithPropertiesFilter from './ArrayOfValuesWithPropertiesFilter'
import ArrayOfValuesFilter from './ArrayOfValuesFilter'
import OptionsFilter from './OptionsFilter'
import DummiesFilter from './DummiesFilter'
import ShopItemsFilter from './ShopItemsFilter'
import OptionsSearch from '@/components/Options/OptionsSearch.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import OptionsSearchResults from '@/components/Options/OptionsSearchResults.vue'
import { usePropertiesStore } from '@/store/pinia/propertiesStore'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  name: 'FilterWidget',
  setup () {
    return {
      filtersStore: useFiltersStore(),
      setsStore: useStoreAndOptionSetsStore(),
    }
  },
  data () {
    return {
      filtersChanged: false,
      filtersData,
      optionsSearchResultsVisible: false,
      optionsSearchResults: [],
      currentOptionsFilters: null,
    }
  },
  components: {
    ArrayOfValuesWithPropertiesFilter,
    ArrayOfValuesFilter,
    OptionsFilter,
    DummiesFilter,
    ShopItemsFilter,
    OptionsSearch,
    OptionsSearchResults,
  },
  directives: {
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    currencies () {
      return this.appConfig?.currencies || []
    },
    pageFilters () {
      return this.filtersStore.currentPageFilters
    },
    filtersOpened () {
      return this.filtersStore.filtersOpened
    },
    optionsSearchVisible () {
      return this.filtersStore.optionsSearchVisible
    },
    selectedProperties () {
      return this.filtersStore.selectedProperties
    },
    loaded () {
      return this.filtersStore.loaded
    },
    optionsList () {
      return this.filtersStore.selectedFiltersOptions.options
    },
    selectedFiltersOptions () {
      return this.filtersStore.selectedFiltersOptions
    },
    filtersOptionsList () {
      const filters = ['stores', 'groups', 'collections', 'storesDc', 'suppliers', 'payments', 'schedules']

      return filters.reduce((acc, cur) => ({
        ...acc,
        [cur]: this.filtersStore.loaded[cur]
          ? this.filtersStore.filtersData[cur]
          : { data: [] },
      }), {})
    }
  },
  methods: {
    revertFilter (filterName) {
      if (filterName === 'options') {
        this.filtersStore.revertOptionsFilter()
      } else if (filterName === 'shopItems') {
        this.filtersStore.revertShopItemsFilter()
      } else if (filterName === 'dummies') {
        this.filtersStore.revertDummiesFilter()
      } else {
        this.filtersStore.revertFilter(filterName)
      }

      this.filtersChanged = true
    },
    handleResetAllClick () {
      this.pageFilters.forEach(this.revertFilter)
      this.filtersChanged = true
    },
    handleApplyClick () {
      this.filtersStore.setFiltersOpened(false)
      this.closeForm()
    },
    closeForm () {
      const filters = ['stores', 'toStores', 'storesDc', 'toStoresDc', 'groups', 'collections', 'suppliers', 'payments', 'schedules', 'options', 'dummies', 'shopItems']

      try {
        filters.forEach(filter => {
          this.filtersStore.setLocalStorage(filter)
        })

        if (this.filtersChanged === true) {
          this.filtersStore.setFiltersChanged(true)

          this.$nextTick(() => {
            this.filtersStore.setFiltersChanged(false)
            this.filtersChanged = false
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    handlePropertyChanged ({ val, filterName }) {
      this.filtersStore.getPropertyValues({
        filterName,
        val,
      })
    },
    handlePropertyDeleteClick ({ filterName, propName }) {
      this.filtersStore.deleteProperty({
        filterName,
        propName,
      })
      this.filtersChanged = true
    },
    handlePropertyValueChanged (filterName) {
      this.$nextTick(() => {
        this.filtersStore.setSelectedPropertyValues({ filterName })
        this.filtersChanged = true
      })
    },
    handleFilterChanged ({ filterName, value }) {
      this.filtersStore.setSelectedFilterOptions({ filterName, value })
      this.filtersChanged = true
    },
    handleAddPropertyClick (filterName) {
      this.filtersStore.setSelectedProperties({
        filterName,
        value: [...this.selectedProperties[filterName], '']
      })
    },
    async handleOptionsEditClick () {
      this.filtersStore.setOptionsSearchVisible(true)

      this.$refs.optionsSearch.searchDebounce.cancel()
      await this.$refs.optionsSearch.searchByFilters()
    },
    handleOptionsSearchCancelClick () {
      this.filtersStore.setOptionsSearchVisible(false)
    },
    handleOptionsSearchResultsUpdated (results) {
      this.optionsSearchResults = Object.freeze(results)
    },
    handleOptionsFilterUpdated (filters) {
      this.currentOptionsFilters = filters
    },
    async handleOptionsSearchUpdateClick () {
      this.$refs.optionsSearch.searchDebounce.cancel()
      await this.$refs.optionsSearch.searchByFilters()

      const optKeys = this.optionsSearchResults.map(r => r.option_key)
      localStorage.setItem('optionsList', JSON.stringify(optKeys))
      if (this.currentOptionsFilters) {
        localStorage.setItem('optionsSearchFilters', JSON.stringify(this.currentOptionsFilters))
      }

      this.filtersStore.setOptionsFilter()
      this.filtersChanged = true
      this.filtersStore.setOptionsSearchVisible(false)
    },
    checkFiltersDefault () {
      let allDefault = true

      this.pageFilters.forEach(filterName => {
        let selectedOptionsLength = 0
        let allOptionsLength = 0
        // Case for not supported filter
        if (!this.filtersData[filterName]) {
          return
        }

        if (['arrayOfValues', 'arrayOfValuesWithProperties'].includes(this.filtersData[filterName].type)) {
          selectedOptionsLength = this.selectedFiltersOptions[filterName].length
          allOptionsLength = this.filtersOptionsList[this.filtersData[filterName].filterOptionsListProp].length
        }

        if (this.filtersData[filterName].type === 'dummies') {
          const { properties, ...nullable } = this.selectedFiltersOptions.dummies

          if (
            (Object.keys(properties).length !== 0) ||
            (!Object.keys(nullable).every(key => this.selectedFiltersOptions.dummies[key] === null))
          ) {
            allDefault = false
          }
        }

        if (this.filtersData[filterName].type === 'shopItems') {
          const keys = Object.keys(this.selectedFiltersOptions.shopItems)

          if (!keys.every(key => this.selectedFiltersOptions.shopItems[key] === null)) {
            allDefault = false
          }
        }

        if (this.filtersData[filterName].type === 'options') {
          selectedOptionsLength = this.selectedFiltersOptions.options.length
          allDefault = allDefault && !selectedOptionsLength
          return
        }

        if (selectedOptionsLength !== allOptionsLength) {
          allDefault = false
        }
      })
      return allDefault
    },
  },
  watch: {
    $route () {
      this.filtersStore.setFiltersApplied(!this.checkFiltersDefault())
    },
    filtersOpened (val) {
      if (!val) {
        this.closeForm()
        this.filtersStore.setFiltersApplied(!this.checkFiltersDefault())
      }
    },
  },
  created () {
    this.setsStore.loadStoreSets().catch(console.error)
    this.filtersStore.getFilterDataSuppliers()
    this.filtersStore.getFilterDataPayments()
    this.filtersStore.getFilterDataSchedules()
    this.filtersStore.getFilterDataCollections()
    this.filtersStore.getFilterDataGroups()
    this.filtersStore.getFilterDataStores()
    this.filtersStore.getFilterDataStoresDc()
    usePropertiesStore().loadSKUOptionsProperties().catch(console.error)

    const dataLoad = [
      this.filtersStore.getFilterDataShopItems(),
      this.filtersStore.getFilterDataDummies(),
      this.filtersStore.setOptionsFilter()
    ]

    Promise
      .all(dataLoad)
      .then(() => {
        for (const item in this.filtersData) {
          const storageKey = item === 'options' ? 'optionsList' : item
          if (localStorage.getItem(item) === null && localStorage.getItem(storageKey) === null) {
            this.revertFilter(item)
          }
        }
        this.filtersStore.setFiltersApplied(!this.checkFiltersDefault())
        this.filtersStore.setFiltersDataLoaded(true)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
</script>

<style lang="scss">
.stock-filter-widget {
  position: fixed;
  top: 56px;
  right: 0;
  z-index: 6;
  .v-input {
    font-size: 14px;
    padding-top: 12px;
    margin-top: 4px;
  }
  .v-text-field.v-input--dense {
    padding-top: 0;
  }
  .v-select__selection--comma {
    margin-bottom: 2px;
  }
  .v-text-field {
    .v-label {
      font-size: 14px;
    }
    .v-label--active {
      font-size: 12px;
      transform: translateY(-20px);
    }
  }
  .values-select {
    .v-chip .v-chip__content {
      & > span {
        width: 40px;
        overflow: hidden;
      }
    }
  }
  .v-select.v-select--chips .v-select__selections {
    min-height: 32px;
  }
  .operate-search-properties {
    .property-values {
      padding-top: 12px !important;
    }
    .properties-container {
      margin-left: 0 !important;
      padding-left: 36px;
    }
  }
  .filter-widget-card {
    box-shadow: inset 0 2px 2px #ccc !important;
    width: 500px;
    height: calc(calc(100vh / var(--zoom)) - 56px);
    overflow: hidden;
    z-index: 1000;
    border-radius: 0 !important;
    justify-content: center;
    align-items: start;
  }
  h4 {
    font-size: 16px;
  }
  .filter-options {
    height: calc(100% - 85px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
  }
  .options-search-results {
    height: calc(100% - 85px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
