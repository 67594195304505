import axios from '@/services/axios'
import {getHeadersWithAccessToken} from '@/variables'
import baseURLs from '@/api/APIBaseURLs'
import * as models from '@/models/Article'

export async function getArticleSettings (params?: Record<string, unknown>): Promise<models.ArticleSettings> {
  const url = baseURLs.manageAPI + '/option_settings'
  const response = await axios.get(url, {
    headers: getHeadersWithAccessToken(),
    params: {
      format: 'd',
      module: 'stock',
      page: 'option',
      ...params,
    }
  })
  return response.data
}
