<template>
  <div class="shop-item-order-form-summary">
    <div class="d-flex align-center px-12 mb-4">
      <div class="step-mark mr-5">
        1
      </div>
      <div class="py-5 px-6 grow step-content">
        <v-row class="align-center">
          <v-col :cols="2">
            Pieces
          </v-col>
          <v-col :cols="2">
            {{ totalPieces }}
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="d-flex align-center px-12 mb-4">
      <div class="step-mark mr-5">
        2
      </div>
      <div class="py-5 px-6 grow step-content">
        <v-row class="align-center">
          <v-col :cols="2">
            Cross-docks
          </v-col>
          <v-col :cols="2">
            {{ orderLine.cross_docks.length }}
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="d-flex align-center px-12 mb-4">
      <div class="step-mark mr-5">
        3
      </div>
      <div class="py-5 px-6 grow step-content">
        <v-row class="align-center">
          <v-col :cols="2">
            Prepacks
          </v-col>
          <v-col :cols="2">
            {{ orderLine.prepacks.length }}
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="d-flex align-center px-12 mb-4">
      <div class="step-mark mr-5">
        4
      </div>
      <div class="py-5 px-6 grow step-content">
        <v-row class="align-center">
          <v-col :cols="2">
            Ordered
          </v-col>
          <v-col :cols="2">
            {{
              `${order.order_date_key} (W${format((new Date(order.order_date_key)), 'ww')})`
            }}

          </v-col>
          <v-col :cols="2">
            In shop
          </v-col>
          <v-col :cols="2">
            {{
              `${order.shop_date_key} (W${format((new Date(order.shop_date_key)), 'ww')})`
            }}
          </v-col>
          <v-col :cols="2">
            Tasks
          </v-col>
          <v-col :cols="2">
            {{ totalTasks }}
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="d-flex align-center px-12 mb-4">
      <div class="step-mark mr-5">
        5
      </div>
      <div class="py-5 px-6 grow step-content">
        <v-row class="align-center">
          <v-col :cols="2">
            Buying costs
          </v-col>
          <v-col :cols="2">
            {{ defaultCurrencySymbol }}{{ kpiItems[1].value }}
          </v-col>
          <v-col :cols="2">
            Costs
          </v-col>
          <v-col :cols="2">
            {{ defaultCurrencySymbol }}{{ kpiItems[2].value }}
          </v-col>
          <v-col :cols="2">
            Margin
          </v-col>
          <v-col :cols="2">
            {{ defaultCurrencySymbol }}{{ kpiItems[3].value }}
          </v-col>
        </v-row>
      </div>
    </div>
    <v-row class="mx-9">
      <v-flex class="col-7">
        <modal-property-management
          class="mt-5"
          ref="propertyManagement"
          :obj="order"
          objectName="orders"
          @validation="handlePropertyManagementValidation"
        />
      </v-flex>
    </v-row>

  </div>
</template>

<script>
import _ from 'lodash'
import ModalPropertyManagement from '@/components/GeneralSettings/ModalPropertyManagement'
import { format } from 'date-fns'

export default {
  components: {
    ModalPropertyManagement,
  },
  props: {
    currencies: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    orderLine: {
      type: Object,
      required: true,
    },
    orderSchedule: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    extraStatusOptions: {
      type: Array,
      required: true,
    },
    kpiItems: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      arePropertiesValid: false,
      orderData: null,
      defaultCurrency: localStorage.getItem('defaultCurrency'),
    }
  },
  computed: {
    currencySymbols () {
      return this.currencies.reduce((acc, cur) => ({
        ...acc,
        [cur.iso_code]: cur.symbol,
      }), {})
    },
    defaultCurrencySymbol () {
      return this.currencySymbols[this.defaultCurrency]
    },
    totalPieces () {
      return _.sum(_.values(this.orderLine.pieces))
    },
    totalTasks () {
      return this.orderSchedule.filter(s => s.task).length
    },
    isValid () {
      return this.$refs.propertyManagement.areAllRequiredPropertiesFilled
    }
  },
  watch: {
    order () {
      this.orderData = { ...this.order }
    },
  },
  methods: {
    handleOrderChanged () {
      this.$emit('summary-changed', this.orderData)
    },
    handlePropertyManagementValidation (arePropertiesValid) {
      this.arePropertiesValid = arePropertiesValid
      this.$emit('validation', arePropertiesValid)
    },
    format,
  },
  created () {
    this.orderData = { ...this.order }
  },
}
</script>

<style lang="scss">
.shop-item-order-form-summary {
    .step-mark {
        background: rgba(0,0,0,0.38);
        color: #fff;
        font-size: 8px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        border-radius: 15px;
    }
    .step-content {
        border: 1px solid var(--border-color);
        font-size: 16px;
    }
}
</style>
