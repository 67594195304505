<template>
  <div class="sidebar">
    <side-navigation-drawer v-bind:items="items" ref="drawer" />
    <div class="sidebar-wrapper d-none d-md-block ">
      <div class="sidebar-items">
        <router-link
          :to="{ name: item.route }"
          v-for="(item, idx) in items"
          :key="idx"
          style="text-decoration: none; color: inherit;">
          <div
            class="sidebar-item"
            :class="{ 'item-active': itemActive(item) }"
          >
            <v-icon class="sidebar-icon"> {{ item.icon }} </v-icon>
          </div>
        </router-link>

        <div class="sidebar-item drawer-open-btn" @click="clickDrawer">
          <v-icon>
            chevron_right
          </v-icon>
        </div>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
import SideNavigationDrawer from '@/components/SideNavigationDrawer.vue'

export default {
  name: 'StrategySidebar',
  components: { SideNavigationDrawer },
  data () {
    const superuser = localStorage.getItem('superuser') === 'true'
    const items = [{
      name: 'Home',
      icon: 'mdi-home',
      route: 'strategy-home-page',
    }, {
      name: 'Budget cubes',
      icon: 'mdi-cube-outline',
      route: 'strategy-cubes-page',
    }, {
      name: 'Intake',
      icon: 'ssid_chart',
      route: 'strategy-intake-page',
    }, {
      name: 'Collection',
      icon: 'mdi-cube-unfolded',
      route: 'strategy-collection-page',
    }]

    if (superuser) {
      items.push({
        name: 'WSSI',
        icon: 'mdi-calendar-week-begin',
        route: 'strategy-wssi-page',
      })
    }

    items.push({
      name: 'Analytics',
      icon: 'mdi-chart-box',
      route: 'strategy-analytics'
    }, {
      name: 'Settings',
      icon: 'mdi-cog',
      route: 'strategy-settings-main',
    })

    return {
      items,
    }
  },
  methods: {
    itemActive (item) {
      if (item.name === 'Settings') {
        if (this.$route.path.split('/')[2] === 'settings') {
          return true
        }
      }
      return this.$route.name === item.route
    },
    clickDrawer () {
      this.$refs.drawer.clickDrawer()
    }

  },
}
</script>
