<template>
  <v-dialog
    :value="isOpen"
    persistent
    scrollable
    content-class="align-self-start mt-14"
    width="1200px"
    max-width="90%">
    <v-card class="rounded-0">
      <v-overlay absolute color="white" :value="stockManageStore.capacityTab.dialogLoading">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <v-card-title
        v-if="currentOptionSet"
        class="text-body-1 font-weight-medium mx-4 my-2"
      >
        <template v-if="currentOptionSetEditMode">
          {{ `${currentOptionSet.options_set_keys ? 'Edit' : 'Add'} option set` }}
        </template>
        <template v-else>
          Option set
          <v-icon class="mx-2">arrow_forward</v-icon>
          {{ currentOptionSet.set_name }}
          <v-btn
            color="primary"
            small
            depressed
            outlined
            @click="currentOptionSetEditClick"
            class="ml-4"
          >Edit</v-btn>
        </template>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-0 text--primary">
        <OptionSetForm
          ref="optionSetForm"
          :option-set="currentOptionSet"
          :edit-mode="currentOptionSetEditMode"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2 px-4">
          <v-col>
            <template v-if="currentOptionSetEditMode">
              <v-btn
                color="primary"
                depressed
                text
                @click="handleOptionSetSaveCancel"
              >Cancel</v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                @click="handleOptionSetSaveConfirm"
                class="mx-4"
              >Save</v-btn>
            </template>
            <template v-else>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleOptionSetSaveCancel"
              >Close</v-btn>
            </template>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OptionSetForm from '@/components/Assortment/OptionSets/OptionSetForm'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'

import { useStockManageStore } from '@/store/pinia/stockManageStore'
export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  name: 'OptionSetFormDialog',
  components: { OptionSetForm },
  data () {
    return {
      currentOptionSetEditMode: false,
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    currentOptionSet: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    optionSetFormRef () {
      return this.$refs.optionSetForm
    },
    handleOptionSetSaveConfirm () {
      if (!this.optionSetFormRef().validateOptionSet() || !this.optionSetFormRef().$refs.searchFilters.validateFilters()) {
        return
      }

      const payload = this.optionSetFormRef().getSavePayload()

      this.stockManageStore.setDialogLoading({
        tab: 'capacity',
        value: true,
      })
      this.stockManageStore.saveOptionSet(payload)
        .then(() => {
          this.$emit('close')
          this.optionSetFormRef().resetForm()
          this.currentOptionSetEditMode = false

          Promise
            .all([
              useStoreAndOptionSetsStore().loadOptionSets(),
              this.stockManageStore.loadLowLevelCapacityList()
            ])
            .finally(() => {
              this.stockManageStore.setDialogLoading({
                tab: 'capacity',
                value: false,
              })
            })
        })
      this.$emit('close')
    },
    handleOptionSetSaveCancel () {
      this.optionSetFormRef().resetForm()
      this.currentOptionSetEditMode = false
      this.$emit('close')
    },
    currentOptionSetEditClick () {
      this.currentOptionSetEditMode = true
    },
  }
}
</script>

<style scoped>

</style>
