import axios from '@/services/axios'
import baseURLs from '@/api/APIBaseURLs'

export type Log = {
  operation: 'initial' | 'insert' | 'update' | 'delete' | 'transfers';
  actions: string[];
  action: string;
  timestamp: Date|string;
  delta: string; // 5 h ago, 10 days ago, etc. minimum minutes, maximum months
  first_name: string;
  last_name: string;
  log_text: string; // "{first_name} {last_name} has changed {action, action, ...}"
  current: Record<string, unknown>;
  difference: Record<string, unknown>;
}

export async function getHistoricLogs (option_key: number|string): Promise<Log[]> {
  const res = await axios.get<Log[]>(baseURLs.manageAPI + '/option_log?option_key=' + option_key)
  // Parse the date strings into Date objects
  for (const log of res.data) {
    log.timestamp = new Date(log.timestamp)
    if (!log.delta) {
      log.delta = getDelta(log.timestamp) + ' ago'
    } else {
      // Check if delta contains the word ago

    }
    log.action = log.actions.join(', ')
    if (log.operation.toLowerCase() === 'transfers') {
      log.log_text = `${log.first_name} ${log.last_name} has accepted ${log.current['pieces'] ?? 0} pieces in Transfers ${log.current['type'] ?? ''}`
    } else {
      log.log_text = `${log.first_name} ${log.last_name} has changed ${log.action}`
    }
  }
  return res.data
}

function getDelta (date: Date): string {
  const now = new Date()
  const delta = now.getTime() - date.getTime()
  const deltaMinutes = Math.floor(delta / 60000)
  if (deltaMinutes <= 1) {
    return 'a minute'
  } else if (deltaMinutes < 60) {
    return `${deltaMinutes} minutes`
  }
  const deltaHours = Math.floor(deltaMinutes / 60)
  if (deltaHours === 1) {
    return 'an hour'
  } else if (deltaHours < 24) {
    return `${deltaHours} hours`
  }
  const deltaDays = Math.floor(deltaHours / 24)
  if (deltaDays === 1) {
    return 'a day'
  } else if (deltaDays < 366) {
    return `${deltaDays} days`
  }
  const deltaMonths = Math.floor(deltaDays / 30)
  if (deltaMonths === 1) {
    return 'a month'
  }
  return `${deltaMonths} months`
}
