<template>
  <v-col col="12">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-row class="ma-0" justify="space-between">
      <h4 class="pa-6 pb-2 pt-8">Fixed currency rates</h4>
    </v-row>
    <v-row class="ma-0 mt-2 justify-end">
      <div class="text-right mb-4 px-4">
        <!--          <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />-->

        <table-search class="mx-2"
          @searchChanged="filters => {currenciesTableFilters[0].value = filters; filterKey ++}"
        />
        <v-icon
          :disabled="selectedRow === null"
          class="mx-2 action-btn-primary"
          @click="formDialogVisible = true; edit=true"
        >
          edit
        </v-icon>
        <v-icon
          class="mx-2 action-btn-primary"
          @click="formDialogVisible = true; edit=false"
        >
          add
        </v-icon>
        <v-icon
          class="mx-2 action-btn-primary"
          @click="deleteDialogVisible = true"
        >
          delete
        </v-icon>
      </div>

    </v-row>
    <data-tables
      :data="parsedCurrencies"
      :key="filterKey"
      :hidden="loading"
      :page-size="5"
      :filters="currenciesTableFilters"
      @row-click="onRowClick"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
    >
      <el-table-column
        align="left"
        v-for="item in columns"
        :key="item"
        :prop="item"
        :label="item === 'key' ? '' : item"
        :width="item === 'key' ? '60' : ''"
      >
        <template slot-scope="scope">
          <v-simple-checkbox
            v-if="item === 'key'"
            color="primary"
            dense
            hide-details
            class="mt-0 ml-4 pt-0 ma-0"
            :value="scope.row.key === selectedRow"
            :ripple="false"
            :input-value="scope.row.key === selectedRow"
            @click.stop="onRowClick(scope.row)"
          />
          <span
            v-else-if="item === 'Budgets' || item === 'Scenarios'"
          >
            <span >{{scope.row[item] || `All ${item}` }}</span>
          </span>
          <span v-else>
            {{scope.row[item]===null ? '-' : scope.row[item]}}
          </span>
        </template>
      </el-table-column>
    </data-tables>
    <!-- Delete dialog -->
    <v-dialog
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="stockManageStore.capacityTab.dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium">Delete currency</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete this fixed currency</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                class="red--text mx-4"
                @click="deleteConfirmation"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Form dialog -->
    <v-dialog
      v-model="formDialogVisible"
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <settings-currency-forms
        :budgets="budgets"
        v-if="formDialogVisible"
        :currency="currencies[selectedRow]"
        :edit="edit"
        @close="formDialogVisible = false"
        @confirmation="onCurrencyConfirm"
      ></settings-currency-forms>
    </v-dialog>
  </v-col>
</template>

<script>
import { round } from 'lodash'
import { getHeadersWithAccessToken } from '@/variables'
import axios from '../services/axios'
import TableSearch from '../components/TableSearch'
import columnFilters from '@/mixins/columnFilters'
import SettingsCurrencyForms from './SettingsCurrencyForms'
import baseURLs from '@/api/APIBaseURLs'

import { useStockManageStore } from '@/store/pinia/stockManageStore'
export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  name: 'BuySettingsCurrencies',
  components: { SettingsCurrencyForms, TableSearch },
  mixins: [columnFilters],
  data () {
    return {
      currencies: [{}],
      newAnchor: '',
      deleteDialogVisible: false,
      edit: false,
      budgets: [],
      filterKey: 0,
      formDialogVisible: false,
      selectedRow: null,
      currenciesTableFilters: [
        {
          value: ''
        }
      ],
      loading: true,
    }
  },
  methods: {
    onRowClick (row) {
      this.selectedRow = this.selectedRow === row.key ? null : row.key
    },
    deleteConfirmation () {
      this.loading = true
      const url = baseURLs.manageAPI + '/currency'
      axios.delete(url, {
        headers: getHeadersWithAccessToken(),
        data: this.currencies[this.selectedRow]
      }).finally(() => {
        this.deleteDialogVisible = false
        this.loadData()
      })
    },
    getBudget (key) {
      return this.budgets.filter((item) => item.budget_key === key)[0] && this.budgets.filter((item) => item.budget_key === key)[0]['budget_name']
    },
    loadData () {
      const url = baseURLs.manageAPI + '/currencies'
      axios.get(url).then(({ data: { currencies } }) => {
        this.loading = false
        this.currencies = currencies
      })
    },
    onCurrencyConfirm (values) {
      this.loading = true
      this.selectedRow = null

      const url = baseURLs.manageAPI + '/currency'

      if (this.edit) {
        this.editCurrency(url, values)
      } else {
        this.addCurrency(url, values)
      }
    },
    editCurrency (url, values) {
      axios.put(url, values).then(this.loadData)
    },
    addCurrency (url, values) {
      axios.post(url, values).then(this.loadData)
    }
  },
  computed: {
    parsedCurrencies () {
      return this.currencies.map((item, index) => {
        return {
          key: index,
          Budgets: this.getBudget(item.budget_key),
          Scenarios: item.scenario_key,
          'Date from': item.date_min,
          'Date to': item.date_max,
          'Currency (=1)': item.currency,
          'Rate': round(item.rate, 2).toFixed(2),
          'To Currency (=1 * rate)': item.to_currency,
        }
      })
    },
    columns () {
      if (this.parsedCurrencies.length > 0) {
        return Object.keys(this.parsedCurrencies[0])
      }
      return ['key', 'Budgets', 'Scenarios', 'Date from', 'Date to', 'Currency (=1)', 'Rate', 'To Currency (=1 * rate)']
    }
  },
  created () {
    const url = baseURLs.manageAPI
    axios.get(url + '/budgets').then(({ data: { budgets } }) => {
      this.budgets = [{ budget_name: 'All budgets', budget_key: null }, ...(budgets || [])]
    })
    this.loadData()
  }
}
</script>

<style scoped>

</style>
