<template>
  <div class="stock-consolidate-page p-relative mb-12" id="stock-consolidate-page">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <h3 class="mb-6 mt-6">Consolidate</h3>
    <h5 class="error--text mb-4" style="margin-top: -16px;" v-if="activeTab === 0">
      This functionality is in beta
    </h5>
    <v-card>
      <v-tabs v-model="activeTab" show-arrows>
        <v-tabs-slider />
        <v-tab class="px-8">Overview</v-tab>
        <v-tab class="px-8">Build</v-tab>
        <v-tab class="px-8">Exclusions</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item :transition="false" :reverse-transition="false">
          <ConsolidateOverview
            ref="overviewTab"
            :selectedStoresInput="selectedStores"
            @update:selectedStores="val => selectedStores = val"
            @neededTable:rowClick="handleNeededTableRowClick"
          />
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false">
          <ConsolidateBuild
            ref="buildTab"
            :selectedStoresInput="selectedStores"
            @data-loaded="saveSettings"
            @transfers:accepted="handleTransfersAccepted"
            @selectedStores:changed="handleSelectedStoresChangedDebounce"
            @update:selectedStores="val => selectedStores = val"
          />
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false" eager>
          <ConsolidateExclusions
            :exclusions="exclusions"
            :key="eclusionsKey"
            class="mt-2"
            @transfers:accepted="handleTransfersAccepted"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card class="mt-6 mb-9 pb-4 p-relative">
      <v-overlay absolute color="white" :value="stockTransfersStore.loadingTransfersList['accepted']">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <div class="d-flex align-center mb-4 pt-3 pl-4">
        <h6 class="text-subtitle-1 font-weight-medium">Accepted transfers</h6>
        <v-btn icon @click="toggleAcceptedTableVisibility">
          <v-icon v-if="acceptedTransfersTableVisible">expand_more</v-icon>
          <v-icon v-else>chevron_right</v-icon>
        </v-btn>
      </div>

      <AcceptedTransfersTable
        v-if="filtersDataLoaded"
        ref="acceptedTransfersTable"
        :storesFilter="storesFilter"
        v-show="acceptedTransfersTableVisible"
      />
    </v-card>

  </div>
</template>

<script>
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'
import { getExclusions } from '@/api/action'
import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'
import { useFiltersStore } from '@/store/pinia/filtersStore'
import ConsolidateOverview from '@/components/Stock/Consolidate/ConsolidateOverview.vue'
import ConsolidateBuild from '@/components/Stock/Consolidate/ConsolidateBuild.vue'
import ConsolidateExclusions from '@/components/Stock/Consolidate/ConsolidateExclusions.vue'
import { default as AcceptedTransfersTable } from '@/components/Transfers/AcceptedTable'
import { debounce } from 'lodash'

export default {
  name: 'StockConsolidatePage',
  components: { ConsolidateOverview, ConsolidateBuild, ConsolidateExclusions, AcceptedTransfersTable },
  setup () {
    return {
      filtersStore: useFiltersStore(),
      stockTransfersStore: useStockTransfersStore(),
    }
  },
  data () {
    this.handleSelectedStoresChangedDebounce = debounce(this.handleSelectedStoresChanged, 100)

    return {
      loading: false,
      activeTab: 1,
      selectedStores: [],
      exclusions: [],
      eclusionsKey: 0,
      acceptedTransfersTableVisible: false,
    }
  },
  computed: {
    filtersDataLoaded () {
      return this.filtersStore.filtersDataLoaded
    },
    storesFilter () {
      return this.$refs['buildTab']?.formData.stores || []
    },
  },
  watch: {
    activeTab: {
      async handler (val) {
        // if exclusions tab opened
        if (val === 2) {
          this.loading = true

          try {
            const exclusions = await getExclusions()

            this.eclusionsKey++
            this.exclusions = Object.freeze(exclusions)
          } finally {
            this.loading = false
          }
        }
      }
    },
  },
  methods: {
    saveSettings () {
      localStorage.setItem('stockConsolidate', JSON.stringify({
        stores: this.$refs.buildTab.formData.stores,
        margin: this.$refs.buildTab.formData.margin,
      }))
    },
    loadSettings () {
      const settings = JSON.parse(localStorage.getItem('stockConsolidate'))

      if (settings?.stores) {
        this.selectedStores = settings.stores
      }

      if (settings?.margin) {
        this.$refs.buildTab.formData.margin = settings.margin
      }
    },
    toggleAcceptedTableVisibility () {
      this.acceptedTransfersTableVisible = !this.acceptedTransfersTableVisible
      localStorage.setItem('acceptedTransfersTableVisible', this.acceptedTransfersTableVisible)
    },
    handleTransfersAccepted () {
      this.$refs.acceptedTransfersTable.loadData()
    },
    handleSelectedStoresChanged () {
      this.$refs['acceptedTransfersTable']?.loadData()
    },
    handleNeededTableRowClick (options) {
      this.$refs['buildTab'].optionsListFromNeededTable = options.slice()
      this.$refs['buildTab'].handleConsolidateClick()
      this.activeTab = 1
    }
  },
  created () {
    useStoreAndOptionSetsStore().loadStoreSets().catch(console.error)
    this.acceptedTransfersTableVisible = localStorage.getItem('acceptedTransfersTableVisible') || false
  },
  async mounted () {
    this.loadSettings()
  },
}
</script>

<style lang="scss">
.stock-consolidate-page {
  .v-tab {
    width: 267px;
  }
}
</style>
