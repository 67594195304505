<template>
  <div>
    <v-overlay absolute color="white" :value="!loaded">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <div v-show="loaded" class="chart-container" style="position: relative; height: 400px;">
      <canvas :id="title"></canvas>
    </div>
  </div>
</template>

<script>
import { Chart } from 'chart.js/auto'
import { hexToRgbA } from '@/utils'
import { chartColors } from '@/variables'

export default {
  props: ['title', 'chartData', 'params'],
  data () {
    this.chart = null

    return {
      loaded: false,
    }
  },
  watch: {
    chartData () {
      this.drawGraph()
    }
  },
  methods: {
    drawGraph () {
      this.loaded = false
      const ctx = document.getElementById(this.title).getContext('2d')
      const data = this.prepareChartData(this.chartData)
      const chartData = data[0]
      const options = data[1]
      if (this.chart) {
        this.chart.destroy()
      }
      this.chart = new Chart(ctx, { type: 'bar', data: chartData, options: options })
      this.$emit('loaded')
      this.loaded = true
    },
    prepareChartData (data) {
      const keys = Object.keys(data.stores)
      const points = []
      const length = data.stores[keys[0]].length
      for (let i = 0; i < length; i++) {
        const point = {}
        keys.forEach((item) => {
          point[item] = data.stores[item][i]
        })
        points.push(point)
      }
      const datasets = keys.map((item, index) => {
        return {
          label: item,
          type: 'bar',
          borderWidth: 1,
          data: points.map(x => x[item]),
          /* xAxisID: 'bar-x-axis2',
          yAxisID: 'y-axis1', */
          backgroundColor: hexToRgbA(chartColors[index], 1),
          borderColor: chartColors[index]
        }
      })
      const chartData = {
        labels: data.store_names,
        datasets,
      }
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            position: 'bottom',
            // barThickness: 40,
            title: {
              display: false,
              font: {
                size: 14
              }
            },
            grid: {
              display: false,
            },
          },
          xAxis2: {
            display: false,
            stacked: true,
            position: 'bottom',
            title: {
              text: 'Test',
            },
            // barThickness: 40,
            // these are needed because the bar controller defaults set only the first x axis properties
            type: 'category',
            // categoryPercentage: 0.8,
            // barPercentage: 0.9,
            grid: {
              display: false,
            },
            offset: true
          },
          y: {
            stacked: true,
            position: 'left',
            grid: {
              display: true,
            },
            title: {
              display: true,
              text: 'Pieces',
              font: {
                size: 14
              }
            },
            ticks: {
              font: {
                size: 14
              },
              padding: 16,
              min: 0,
              callback: function (value) {
                const ranges = [
                  { divider: 1e6, suffix: 'M' },
                  { divider: 1e3, suffix: 'k' }
                ]

                function formatNumber (n) {
                  for (let i = 0; i < ranges.length; i++) {
                    if (n >= ranges[i].divider) {
                      return (n / ranges[i].divider).toString() + ranges[i].suffix
                    }
                  }
                  return n
                }
                return formatNumber(value)
              },
            }
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
          },
          tooltip: {
            callbacks: {
              // this callback is used to create the tooltip label
              label: tooltipItem => {
                return datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex].toLocaleString()
              }
            }
          },
        },
      }

      return [chartData, options]
    },
  },
  mounted () {
    this.drawGraph()
  }
}
</script>
