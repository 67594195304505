<template>
  <div class="batch-store-settings p-relative pb-8">
    <v-overlay absolute color="white" :value="false">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-row class="mt-0 pb-2">
      <v-col :cols="6">
        <h6 class="text-subtitle-1 font-weight-medium ml-6">Store batch operations</h6>
      </v-col>
    </v-row>
    <v-row class="my-0 py-0">
      <v-col :cols="4" class="ml-6 py-0 my-0">
        <collapsible-select
          label="Stores"
          autocomplete
          :items="stores"
          v-model="selectedStores"
          show-select-all
          item-text="store_name"
          item-value="store_key"
        ></collapsible-select>

        <v-row
          class="ma-0"
          v-for="(item, index) in propertyList"
          :key="index"
        >
          <v-col class="py-0 pl-0">
            <v-autocomplete
              label="Properties"
              :item-disabled="(listItem) => propertyList.some(prop=> prop.selectedProperty === listItem.value)"
              class="pt-3 mt-1"
              :disabled="propertyList.length -1 !== index"
              v-model="item.selectedProperty"
              :items="Object.keys(stores[0].properties).map(prop=> ({
                value:prop,
                text: prop,
              }))"
            ></v-autocomplete>
          </v-col>
          <v-col class="py-0 ">
            <CollapsibleSelect
              v-if="item.selectedProperty"
              label="Values"
              show-select-all
              class="pt-3 mt-0"
              autocomplete
              :disabled="propertyList.length -1 !== index"
              v-model="item.selectedValue"
              @input="handleValuesChange($event, item)"
              :items="propertyValues(item)"
              :item-text="'text'"
            />
          </v-col>

          <v-col :cols="1" class="d-flex align-center justify-end py-0 pr-0">
            <v-icon
              v-if="item.selectedProperty"
              class="ml-2 action-btn-danger"
              @click="onPropDeleteClick(index)"
            >delete</v-icon>
          </v-col>
        </v-row>
        <v-icon @click="propertyList = [...propertyList, {selectedValue: [], selectedProperty: ''}]">add</v-icon>
      </v-col>
    </v-row>
    <v-row class="my-0 py-0">
      <v-col :cols="4" class="ml-6  py-0 my-0" >
        <v-autocomplete
          v-if="properties.length"
          label="Operation"
          :items="properties"
          v-model="selectedProperties"
          :item-text="(item )=> formatString(item)"
          :item-value="(item)=> item"
          @input="editedStore = {}; logicParametersErrors = {}"
        ></v-autocomplete>
        <v-row class="align-baseline mt-0 mb-4" v-if="selectedProperties.variables && selectedProperties.variables.indexOf('store_name') !== -1">
          <v-col class="py-0">
            <v-text-field
              v-model="editedStore.store_name"
              label="Store name"
              hide-details="auto"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-baseline mt-0 mb-4" v-if="selectedProperties.variables && selectedProperties.variables.indexOf('proposal_key') !== -1">
          <v-col class="py-0">
            <v-autocomplete
              v-model="editedStore.proposal_key"
              label="Proposal key"
              :items="stores"
              item-text="store_name"
              item-value="store_key"
              hide-details="auto"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="align-baseline mt-0 mb-4" v-if="selectedProperties.variables && selectedProperties.variables.indexOf('dc_key') !== -1">
          <v-col class="py-0">
            <v-autocomplete
              v-model="editedStore.dc_key"
              label="DC key"
              :items="allStores"
              item-text="store_name"
              item-value="store_key"
              hide-details="auto"
              clearable
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            />
          </v-col>
        </v-row>
        <v-row class="align-baseline mt-0 mb-4" v-if="selectedProperties.variables && selectedProperties.variables.indexOf('stock_key') !== -1">
          <v-col class="py-0">
            <v-autocomplete
              v-model="editedStore.stock_key"
              label="Stock key"
              :items="allStores"
              item-text="store_name"
              item-value="store_key"
              hide-details="auto"
              clearable
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            />
          </v-col>
        </v-row>
        <v-row class="align-baseline mt-2 mb-4" v-if="selectedProperties.variables && selectedProperties.variables.indexOf('store_type') !== -1">
          <v-col class="py-0">
            <v-select
              v-model="editedStore.store_type"
              :items="typeOptions"
              dense
              label="Store type"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            />

          </v-col>
        </v-row>
        <v-row class="align-baseline mt-0 mb-4" v-if="selectedProperties.variables && selectedProperties.variables.indexOf('country') !== -1">
          <v-col class="py-0">
            <v-text-field
              v-model="editedStore.country"
              label="Country"
              hide-details="auto"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-baseline mt-0 mb-4" v-if="selectedProperties.variables && selectedProperties.variables.indexOf('looks_like_store_key') !== -1">
          <v-col class="py-0">
            <v-autocomplete
              v-model="editedStore.looks_like_store_key"
              label="Looks like store"
              :items="stores"
              item-text="store_name"
              item-value="store_key"
              class="store-status-dropdown pt-0 my-1 mr-4 text-body-2"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            />
          </v-col>
          <v-col class="py-0" v-if="selectedProperties.variables && selectedProperties.variables.indexOf('looks_like_percentage') !== -1">
            <v-text-field
              type="number"
              step="1"
              v-model.number="editedStore.looks_like_percentage"
              label="Looks like percentage"
              hide-details="auto"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-center mt-0 mb-4"  v-if="selectedProperties.variables && selectedProperties.variables.indexOf('export_proposals') !== -1">
          <v-col cols="4" class="py-0">
            <label>Export proposals</label>
          </v-col>
          <v-col class="py-0">
            <v-simple-checkbox
              v-model="editedStore.export_proposals"
              color="primary"
              dense
              hide-details
              class="d-inline-block mt-0 pt-0"
              :ripple="false"
            />
          </v-col>
        </v-row>
        <v-row class="align-center mt-0 mb-4"  v-if="selectedProperties.variables && selectedProperties.variables.indexOf('store_status') !== -1">

          <v-col class="py-0">
            <v-select
              v-model="editedStore.store_status"
              :items="statusOptions"
              label="Store status"
              class="my-1 text-body-2"
              hide-details
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
            />
          </v-col>
        </v-row>
        <v-row class="align-center mt-0 mb-4"  v-if="selectedProperties.variables && selectedProperties.variables.indexOf('date_min') !== -1">

          <v-col class="py-0">
            <v-menu
              ref="datePicker"
              v-model="openFromDatePickerActive"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="editedStore.date_min"
                  clearable
                  label="Open from"
                  class="mb-0"
                  hide-details="auto"
                  v-on="on"
                  @click:clear="editedStore.date_min = null"
                ></v-text-field>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                :locale-first-day-of-year="4"
                :show-week="true"
                no-title
                v-model="editedStore.date_min"
                :max="editedStore.date_max"
                @click="openFromDatePickerActive = false"
              />
            </v-menu>
          </v-col>
          <v-col class="py-0">
            <v-menu
              ref="datePicker"
              v-model="openToDatePickerActive"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="editedStore.date_max"
                  clearable
                  label="Open to"
                  hide-details="auto"
                  v-on="on"
                  class="mb-0"
                  @click:clear="editedStore.date_max = null"
                ></v-text-field>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                :locale-first-day-of-year="4"
                :show-week="true"
                no-title
                v-model="editedStore.date_max"
                :min="editedStore.date_min"
                @click="openToDatePickerActive = false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="align-center mt-0 mb-4"  v-if="selectedProperties.variables && selectedProperties.variables.indexOf('sunday') !== -1">

          <v-col class="py-0 mt-1">
            <collapsible-select
              v-model="editedStore.sunday"
              :items="sundayOptions"
              label="Sunday openings"
              item-text="text"
              show-select-all
              class="mt-1 mb-4 text-body-2"
              hide-details
            />
          </v-col>
        </v-row>
        <v-col class="align-center mt-0 mb-6 mx-0 py-0"  v-if="selectedProperties.variables && selectedProperties.variables.indexOf('logic_parameters') !== -1">
          <v-row class="align-baseline mt-0 mb-0 px-0">
            <v-col class="pa-0">
              <v-text-field
                type="number"
                v-model.number="editedStore.logic_parameters.delivery_days"
                label="Delivery days"
                hide-details="auto"
                :error-messages="logicParametersErrors.delivery_days"
                @input="(value)=> logicParametersErrors.delivery_days = value < 0 ? 'Should be >= 0' : ''"
                clearable
              >
                <template v-slot:append-outer>
                  <v-tooltip top :max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                    </template>
                    <span>Estimated return costs per piece</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-5 mb-0">
            <v-col class="pa-0" cols="12">
              <v-text-field
                type="number"
                v-model.number="editedStore.logic_parameters.redelivery_days"
                :error-messages="logicParametersErrors.redelivery_days"
                @input="(value)=> logicParametersErrors.redelivery_days = value < 0 ? 'Should be >= 0' : ''"
                label="Redelivery days"
                hide-details="auto"
                clearable
              >
                <template v-slot:append-outer>
                  <v-tooltip top :max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                    </template>
                    <span>Estimate amount of days between sending and product being sellable</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-5 mb-0">
            <v-col class="pa-0">
              <v-text-field
                type="number"
                v-model.number="editedStore.logic_parameters.return_costs"
                :error-messages="logicParametersErrors.return_costs"
                @input="(value)=> logicParametersErrors.return_costs = value < 0 ? 'Should be >= 0' : ''"
                label="Return costs"
                hide-details="auto"
                clearable
              >
                <template v-slot:append-outer>
                  <v-tooltip top :max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :size="21" class="ml-2 " v-bind="attrs" v-on="on">info</v-icon>
                    </template>
                    <span>Constant costs per piece sold</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-5 mb-0">
            <v-col class="pa-0">
              <v-text-field
                type="number"
                v-model.number="editedStore.logic_parameters.constant_costs"
                :error-messages="logicParametersErrors.constant_costs"
                @input="(value)=> logicParametersErrors.constant_costs = value < 0 ? 'Should be >= 0' : ''"
                label="Constant costs"
                hide-details="auto"
                clearable
              >
                <template v-slot:append-outer>
                  <v-tooltip top :max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                    </template>
                    <span>Percentage (0.01=1%) of variable costs on turnover</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-5 mb-0">
            <v-col class="pa-0">
              <v-text-field
                type="number"
                v-model.number="editedStore.logic_parameters.variable_costs"
                :error-messages="logicParametersErrors.variable_costs"
                @input="(value)=> logicParametersErrors.variable_costs = value < 0 ? 'Should be >= 0' : ''"
                label="Variable costs"
                hide-details="auto"
                clearable
              >
                <template v-slot:append-outer>
                  <v-tooltip top :max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                      </span>
                    </template>
                    <span>Average amount of time between deliveries</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

        </v-col>
      </v-col>
    </v-row>
    <v-row class="mx-6 mt-0 pt-0 pb-3">
      <span class='text-subtitle-1 font-weight-medium'
        v-if="selectedProperties.operation === 'set_store_opening' || selectedProperties.operation === 'set_logic_parameters' ">
        If field is left empty, it will not be updated
      </span>
    </v-row>
    <v-btn
      color="primary"
      depressed
      outlined
      class="mx-6 my-6"
      :loading="loading"
      :disabled="!validates"
      @click="showWarning = true"
    >Apply</v-btn>
    <div v-if="showWarning">
      <div class="mx-6">
        <span class='text-subtitle-1 font-weight-medium'>
          Are you sure you want to {{ formatString(selectedProperties) }} for {{this.selectedStores.length}} stores?
        </span>
      </div>
      <div class="my-6 mx-6">
        <v-btn
          color="primary"
          depressed
          text
          @click="showWarning = false"
        >No</v-btn>
        <v-btn
          color="primary"
          depressed
          outlined
          @click="handleApplyClick"
          class="ml-4"
        >Yes</v-btn>
      </div>
    </div>
    <div v-if="!showWarning && operationApplied === true" class="text-subtitle-1 font-weight-medium my-6 mx-6">
      Operation successfully applied
    </div>
    <div v-if="!showWarning && operationApplied === false" class="text-subtitle-1 font-weight-medium my-6 mx-6">
      Operation not successfully applied
    </div>
  </div>
</template>

<script>
import CollapsibleSelect from '../CollapsibleSelect'
import { loadStoresProperties, setStoresProperties } from '@/api/stores_batch'
import { formatString } from '@/variables'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'BatchStoreSettings',
  components: { CollapsibleSelect },
  data () {
    return {
      propertyList: [],
      typeOptions: [{
        text: 'Store',
        value: 'store',
      }, {
        text: 'DC',
        value: 'DC',
      }, {
        text: 'Outlet',
        value: 'outlet',
      }, {
        text: 'Webshop',
        value: 'webshop',
      }],
      selectedStores: [],
      properties: [],
      selectedProperties: {},
      openToDatePickerActive: false,
      operationApplied: null,
      loading: false,
      showWarning: false,
      openFromDatePickerActive: false,
      editedStore: {},
      storesKey: 0,
      statusOptions: [{
        text: 'Open',
        value: 'open_store',
      }, {
        text: 'Closed',
        value: 'closed_store',
      }, {
        text: 'DC',
        value: 'DC',
      }],
      sundayOptions: [{
        text: 'First',
        value: 1,
      }, {
        text: 'Second',
        value: 2,
      }, {
        text: 'Third',
        value: 3,
      }, {
        text: 'Fourth',
        value: 4,
      }, {
        text: 'Last',
        value: -1,
      }],
      logicParametersErrors: {},
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    stores () {
      return (this.appConfig?.stores_open || [])
    },
    allStores () {
      return (this.appConfig?.stores || [])
    },
    validates () {
      if (!Object.keys(this.editedStore).length || !this.selectedStores.length) {
        return false
      }
      if (this.selectedProperties.operation === 'set_logic_parameters') {
        if (!Object.keys(this.editedStore.logic_parameters).length) {
          return false
        }
        for (const key in this.logicParametersErrors) {
          if (this.logicParametersErrors[key]) {
            return false
          }
        }
      }
      let validates = true
      if (this.selectedProperties.operation !== 'set_store_opening' && this.selectedProperties.operation !== 'set_logic_parameters') {
        this.selectedProperties.variables.forEach((item) => {
          if (item !== 'store_keys' && this.editedStore[item] === null) {
            validates = false
          }
        })
      } else {
        const checkValue = this.selectedProperties.operation === 'set_store_opening' ? this.editedStore : this.editedStore.logic_parameters
        let nonMandatorFieldsValidates = false
        for (const key in checkValue) {
          if (checkValue[key] || checkValue[key] === 0) {
            if (typeof checkValue[key] === 'object' && checkValue[key].length) {
              nonMandatorFieldsValidates = true
            } else {
              nonMandatorFieldsValidates = true
            }
          }
        }
        validates = nonMandatorFieldsValidates
      }
      return validates
    }
  },
  watch: {
    selectedProperties (newValue) {
      if (newValue.variables && newValue.variables.indexOf('export_proposals') !== -1) {
        this.editedStore.export_proposals = false
        this.editedStore = { ...this.editedStore }
      } else if (newValue.variables && newValue.variables.indexOf('logic_parameters') !== -1) {
        this.editedStore.logic_parameters = {}
        this.editedStore = { ...this.editedStore }
      }
    }
  },
  methods: {
    log: console.log,
    formatString (item) {
      return item.operation ? formatString(item.operation) : ''
    },
    onPropDeleteClick (index) {
      this.propertyList.splice(index, 1)
      this.handleValuesChange()
    },
    propertyValues (listItem) {
      if (listItem.selectedProperty === 'Store sets') {
        return this.stores.map(item => ({
          store_keys: item.store_keys, label: item.set_name, value: item.stores_set_key
        }))
      }
      const values = new Set()
      this.stores.forEach((item) => {
        if (item.properties[listItem.selectedProperty] !== undefined) {
          const value = item.properties[listItem.selectedProperty]
          values.add(value)
        }
      })

      return ([...values]).map(item => ({ value: item, text: item }))
    },
    handleValuesChange () {
      const data = this.stores.filter(item => {
        let contains = this.propertyList.filter(item => item.selectedValue.length > 0).length > 0
        this.propertyList.forEach(listItem => {
          if (!listItem.selectedValue.includes(item.properties[listItem.selectedProperty])) {
            contains = false
          }
        })
        return contains
      })
      const set = new Set([...data.map(item => item.store_key)])
      this.selectedStores = [...set]
    },
    handleApplyClick () {
      this.showWarning = false
      this.loading = true
      for (const key in this.editedStore) {
        if (!this.editedStore[key] && this.editedStore[key] !== 0) {
          delete this.editedStore[key]
        }
      }
      const payload = { ...this.editedStore }
      payload.store_keys = this.selectedStores
      payload.operation = this.selectedProperties.operation
      setStoresProperties(payload)
        .then(() => {
          this.loading = false
          this.operationApplied = true
          this.$auth.refreshAppConfig()
          setTimeout(() => {
            this.operationApplied = null
          }, 3000)
        })
        .catch(() => {
          this.operationApplied = false
        })
    }
  },
  created () {
    loadStoresProperties().then(r => {
      this.properties = r
    })
  }
}
</script>

<style scoped>

</style>
