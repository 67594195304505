<template>
  <v-dialog
    :value="dialogVisible"
    content-class="align-self-start access-denied-dialog-content"
    width="600px"
    max-width="90%"
    @click:outside="dialogVisible = false"
  >
    <v-card class="p-relative rounded-0">
      <v-card-title class="text-body-1 mx-6 pt-6 error--text font-weight-medium">
        Access denied
      </v-card-title>
      <v-card-text class="px-12">
        <div class="text-body-2 pt-4 font-weight-medium">You do not have sufficient rights to access this part of Thunderstock.<br/>Contact your admin user to gain more rights.</div>
      </v-card-text>
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2">
          <v-col>
            <v-btn
              depressed
              outlined
              class="error--text mx-4"
              @click="dialogVisible = false"
            >Close</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useGeneralStore } from '@/store/pinia/generalStore'
import { mapState } from 'pinia'

export default {
  computed: {
    ...mapState(useGeneralStore, ['accessDeniedDialogVisible']),
    dialogVisible: {
      get () {
        return this.accessDeniedDialogVisible
      },
      set (val) {
        useGeneralStore().setAccessDeniedDialogVisible(val)
      }
    }
  }
}
</script>

<style lang="scss">
.access-denied-dialog-content {
    margin-top: 80px;
}
</style>
