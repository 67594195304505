<template>
  <div class="buy-shop-item-page p-relative pb-12">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <div class="mb-6 mt-6 title-div">
      <h3>
        <span class="mr-1 text-capitalize route-name" @click="$router.push(shoppingRoute)">
          Shopping
        </span>
        /
        <span class="ml-1">Shop item</span>
      </h3>
    </div>

    <v-card  v-if="currentShopItem" class="my-4">
      <v-row class="my-0">
        <v-col :cols="5">
          <ShopItemInfo
            :dummy="currentDummy"
            :item="currentShopItem"
          />
        </v-col>
        <v-col :cols="7">
          <ShopItemInputForm
            :item="currentShopItem"
            :store-sets="storeSets"
            :suppliers="suppliers"
            :payments="payments"
            :currencies="currencies"
            :schedules="schedules"
            @shop-item-saved="handleInputFormSaved"
            @weeks-changed="handleShopItemWeeksChanged"
            @reorder-options-changed="handleShopItemReorderOptionsChanged"
            @margin-calculator-open="handleMarginCalculatorOpen"
            @orders-update="ordersUpdate"
          />
        </v-col>
      </v-row>
      <v-tabs v-model="activeTab">
        <v-tabs-slider />
        <v-tab key="Grid" class="px-8"
          style="width: 180px">Grid</v-tab>
        <v-tab key="Lookalikes" class="px-8"
          style="width: 180px">Lookalikes</v-tab>
        <v-tab key="Commit" class="px-8"
          style="width: 180px">Commit</v-tab>
        <v-tab key="Orders" class="px-8"
          style="width: 180px">Orders</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <!-- Beliefs -->
        <v-tab-item key="Grid" :transition="false" :reverse-transition="false"
        >
          <ShopGrid
            :kpiFilters="kpiFilters"
            :currencies="currencies"
            :chart-index="budgetProgressIndex"
            @filters-changed="saveKPIFilters"
            @chart-index-changed="saveBudgetProgressIndex">
          </ShopGrid>
        </v-tab-item>
        <v-tab-item key="Lookalikes" :transition="false" :reverse-transition="false"
        >
          <LookalikeComparison
            ref="lookalikes"
            v-if="currentDummy"
            :dummy="currentDummy"
            :stores="stores"
            :reorder_option_keys="currentShopItem.reorder_option_keys"
            :commitmentsPayload="commitmentsPayload"
            @dummyLookalikeKeysChanged="dummyChanged"
          />
        </v-tab-item>

        <!-- Create commit -->
        <v-tab-item key="Commit" :transition="false" :reverse-transition="false" eager
        >
          <ShopItemCommitForm
            ref="commitForm"
            :key="commitKey"
            v-if="currentShopItem && currentDummy"
            :item="currentShopItem"
            :stores="stores"
            :dummy="currentDummy"
            @filters-changed="handleFiltersChanged"
            @chart-item-click="handleBeliefChartItemClick"
            @table-row-change="handleBeliefChartItemChange"
            @commitments-saved="handleCommitmentsSaved"
            @delete-beliefs="handleDeleteBeliefs"
          >

            <v-row class="mb-2 pt-6">
              <v-col>
                <h4 class="px-4">Beliefs</h4>
              </v-col>
            </v-row>
            <ShopItemBeliefs
              ref="beliefs"
              :key="beliefsKey"
              :item="currentShopItem"
              :stores="stores"
              :store-sets="storeSets"
              @belief-saved="handleBeliefsSaved"
              @shop-item-saved="handleBeliefsUpdated"
            />
          </ShopItemCommitForm>

          <v-row class="mb-2 pt-6">
            <v-col>
              <v-row class="align-center ma-0">
                <h4 class="px-4">Commitments</h4>
                <v-menu right :max-width="350" close-on-click :close-on-content-click="false" v-if="commitments.commitment_outside_selection">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="mr-3 error--text">warning</v-icon>
                  </template>
                  <v-card>
                    <v-card-text>
                      There are commitments outside your current selection of stores and weeks.
                    </v-card-text>
                    <v-card-text>
                      <v-row class="ma-0 align-center flex-nowrap pb-4" v-if="differenceStores?.length">
                        <label class="v-label theme--light mr-2 dev-mode">Stores</label>
                        <chips-dropdown
                          :items="differenceStores"
                        ></chips-dropdown>
                      </v-row>
                      <v-row class="ma-0 align-center flex-nowrap pb-4" v-if="differenceSkus?.length">
                        <label class="v-label theme--light mr-2 dev-mode">Skus</label>
                        <chips-dropdown
                          :items="differenceSkus"
                        ></chips-dropdown>
                      </v-row>
                      <v-row class="ma-0 align-center flex-nowrap pb-4" v-if="differenceWeeks?.length">
                        <label class="v-label theme--light mr-2 dev-mode">Weeks</label>
                        <chips-dropdown
                          :items="differenceWeeks"
                        ></chips-dropdown>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-row>
            </v-col>
          </v-row>
          <ShopItemCommitments
            :item="currentShopItem"
            :commitments="commitments.table_data"
            :stores="stores"
            @delete="deleteCommitments"
          />
        </v-tab-item>

        <v-tab-item key="Orders" :transition="false" :reverse-transition="false"
        >
          <div class="p-relative">
            <v-overlay absolute color="white" :value="ordersLoading">
              <v-progress-circular color="primary" indeterminate size="64" width="2" />
            </v-overlay>
            <ShopItemProjection
              :reorder-data="reorderData"
              :ordersLoading="ordersLoading"
              :projection_table="projection_table"
              :sku_table="sku_table"
              :carry-over-visible="!!currentShopItem?.reorder_option_keys?.length"
              :sku_table_perc="sku_table_perc"
            >
              <ShopOrders
                slot="orders"
                :orders="orders"
                :shop-items="[{...currentShopItem, name: currentDummy?.name}]"
                :schedules="schedules"
                :orderProperties="orderProperties"
                :extra-status-options="extraStatusOptions"
                :status-options="statusOptions"
                :currencies="currencies"
                :suppliers="suppliers"
                :payments="payments"
                @order-status-saved="redrawGraphs()"
                @need-reload="reloadShopItem()"
                @params-changed="handleParamsChange"
                @edit-order-click="handleEditOrderClick"
                @order-saved="handleOrderSaved"
                @order-deleted="handleOrderDeleted"
              >
                <template slot="actions">
                  <v-tooltip
                    top :max-width="250" :disabled="!!optionKey">
                    <template v-slot:activator="{ on, attrs }">
                      <span                        v-bind="attrs" v-on="on"
                      >
                        <v-icon
                          class="mx-2 action-btn-primary"
                          :color="copyExistingData?.length ? '#FF9800' : ''"
                          :disabled="!optionKey"
                          @click="openExistingInbounds"
                        >
                          mdi-package-variant-closed-plus
                        </v-icon>
                      </span>
                    </template>
                    <span>No inbounds found for this option key</span>
                  </v-tooltip>
                  <v-menu :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="action-btn-primary mx-2" v-bind="attrs" v-on="on">tune</v-icon>
                    </template>

                    <v-card>
                      <h4 class="ml-4 mb-6 pt-6 edit-parameters-title">
                        Edit parameters
                      </h4>
                      <div class="parameter">
                        <v-col cols="5" class="pa-0">Split orders</v-col>
                        <v-col cols="3" class="pa-0 d-flex justify-end">
                          <v-switch
                            v-model="currentShopItem.order_settings.split_orders"
                            hide-details
                            class="d-inline-block pt-0 mt-0"
                            @change="onParamsChange('split_orders')"
                          />
                        </v-col>
                      </div>
                      <div class="parameter" v-if="currentShopItem">
                        <v-col cols="5" class="pa-0">Stock min</v-col>
                        <v-col cols="3" class="pa-0">
                          <v-text-field
                            v-model.number="currentShopItem.order_settings.stock_min"
                            hide-details="auto"
                            type="number"
                            :error-messages="!Number.isInteger(currentShopItem.order_settings.stock_min || 0) || currentShopItem.order_settings.stock_min < 0 ? 'Must be integer greater or equal to 0' : ''"
                            class="d-inline-block pt-0 mt-0 mr-2"
                            @change="onParamsChange('stock_min')"
                          />
                        </v-col>
                      </div>
                      <div class="parameter">
                        <v-col cols="5" class="pa-0">MOQ</v-col>
                        <v-col cols="3" class="pa-0">
                          <v-text-field
                            v-model.number="currentShopItem.order_settings.moq"
                            hide-details="auto"
                            type="number"
                            :error-messages="!Number.isInteger(currentShopItem.order_settings.moq || 0) || currentShopItem.order_settings.moq < 0 ? 'Must be integer greater or equal to 0' : ''"
                            class="d-inline-block pt-0 mt-0 mr-2"
                            @change="onParamsChange('moq')"
                          />
                        </v-col>
                      </div>
                      <div class="parameter">
                        <v-col cols="8" class="pa-0">Cover </v-col>
                        <v-col cols="2" class="pa-0">
                          <v-text-field
                            v-model.number="currentShopItem.order_settings.cover_min"
                            hide-details
                            label="Min"
                            type="number"
                            :error-messages="!Number.isInteger(currentShopItem.order_settings.cover_min || 0) || currentShopItem.order_settings.cover_min < 0 || currentShopItem.order_settings.cover_min >= currentShopItem.order_settings.cover_max ? 'Must be integer greater or equal to 0' : ''"
                            class="d-inline-block pt-0 mt-0 mr-2"
                            @change="onParamsChange('cover_min')"
                          />
                        </v-col>
                        <v-col cols="2" class="pa-0">
                          <v-text-field
                            v-model.number="currentShopItem.order_settings.cover_max"
                            hide-details
                            label="Max"
                            type="number"
                            :error-messages="!Number.isInteger(currentShopItem.order_settings.cover_max || 0) || currentShopItem.order_settings.cover_max < 0 || currentShopItem.order_settings.cover_min >= currentShopItem.order_settings.cover_max ? 'Must be integer greater or equal to 0' : ''"
                            class="d-inline-block pt-0 mt-0 mr-2"
                            @change="onParamsChange('cover_max')"
                          />
                        </v-col>
                      </div>
                      <div class="parameter">
                        <v-col cols="5" class="pa-0">Box size</v-col>
                        <v-col cols="3" class="pa-0">
                          <v-text-field
                            v-model.number="currentShopItem.order_settings.box_size"
                            hide-details="auto"
                            type="number"
                            :error-messages="!Number.isInteger(currentShopItem.order_settings.box_size || 0) || currentShopItem.order_settings.box_size < 0 ? 'Must be integer greater or equal to 0' : ''"
                            class="d-inline-block pt-0 mt-0 mr-2"
                            @change="onParamsChange('box_size')"
                          />
                        </v-col>
                      </div>
                      <div class="parameter">
                        <v-col cols="5" class="pa-0">Sellthrough</v-col>
                        <v-col cols="5" class="pa-0">
                          <v-row class="ma-0 align-center">
                            <v-text-field
                              :value="sellthrough.toFixed(0)"
                              id="sellthrough"
                              type="number"
                              :error-messages="validateSellthrough(parseFloat(sellthrough)) ? '' : 'Must be integer between 1 and 100'"
                              class="d-inline-block pt-0 mt-0 mr-2"
                              prefix="%"
                              @input="currentShopItem.order_settings.sellthrough = $event / 100"
                              @change="validateSellthrough(parseFloat($event)) ? onSellthroughChange(parseFloat($event / 100)) : ()=> {}"
                            />
                          </v-row>
                        </v-col>
                      </div>
                    </v-card>
                  </v-menu>
                </template>

              </ShopOrders>
              <!-- Order form -->
              <ShopItemOrderForm
                slot="orders"
                v-if="currentShopItem && editedOrder && editedOrderLines.length"
                :currencies="currencies"
                :dummy="currentDummy"
                :item="currentShopItem"
                :order="editedOrder"
                :orders="orders"
                :order-lines="editedOrderLines"
                :order-schedule="editedOrderSchedule"
                :order-costs="editedOrderCosts"
                :commitments="commitments.skus_data"
                :extra-status-options="extraStatusOptions"
                :status-options="statusOptions"
                :visible="orderFormDialogVisible"
                @update-order="onUpdateOrder"
                @order-progress-saved="handleOrderProgressSaved"
                @costs-saved="handleOrderCostsSaved"
              />
              <shop-item-carry-over
                v-if="reorderOptions"
                :reorderOptions="reorderOptions"
                :carry-over="currentShopItem.carry_over"
                slot="carry over"
                @carry-over-changed="onCarryOverChange"
              ></shop-item-carry-over>
            </ShopItemProjection>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- Margin tool dialog -->
    <v-dialog
      :value="marginCalculatorDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-12"
      width="90%"
      max-width="90%">
      <v-card class="p-relative">
        <v-card-title v-if="currentDummy" class="text-body-1 font-weight-medium ma-5">
          Margin calculator
          <v-icon class="mx-2">arrow_forward</v-icon>
          {{ currentDummy.name }}
        </v-card-title>
        <v-divider/>
        <MarginCalculator
          v-if="currentShopItem && currentSupplier"
          ref="marginCalculator"
          :item="currentShopItem"
          :shop-item-key="currentShopItem.shop_item_key"
          :supplier-name="currentSupplier.supplier_name"
          @updatedShopItem="reloadShopItem"
        />
        <v-divider></v-divider>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="marginCalculatorDialogVisible = false"
              >Close</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :value="copyDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <copy-existing-inbound
        v-if="copyDialogVisible"
        :data="copyExistingData"
        :option-key="optionKey"
        :shop-item="currentShopItem"
        @close="copyDialogVisible = false"
        @saved="copyExistingSaved"
      />
    </v-dialog>
  </div>
</template>

<script>
import * as shopApi from '@/api/shop'
import {deleteCommitments, getInboundsOption, optionViewCallOrders} from '@/api/shop'
import * as dummiesApi from '@/api/dummies'
import { saveDummy } from '@/api/dummies'
import * as storeSetsApi from '@/api/storeSets'
import ShopItemInfo from '@/components/Shop/ShopItemInfo.vue'
import ShopItemInputForm from '@/components/Shop/ShopItemInputForm.vue'
import ShopItemBeliefs from '@/components/Shop/ShopItemBeliefs.vue'
import ShopItemCommitForm from '@/components/Shop/ShopItemCommitForm.vue'
import ShopItemOrderForm from '@/components/Shop/ItemOrderForm/ShopItemOrderForm.vue'
import MarginCalculator from '@/components/Shop/MarginCalculator.vue'
import ShopItemCommitments from '@/components/Shop/ShopItemCommitments'
import LookalikeComparison from '@/components/Shop/LookalikeComparison'
import ShopItemProjection from '@/components/Shop/ShopItemProjection'
import ShopItemCarryOver from '../components/Shop/ShopItemCarryOver'
import ShopOrders from '../components/Shop/ShopOrders'
import CopyExistingInbound from '../components/Shop/CopyExistingInbound'
import ShopGrid from '@/views/ShopGrid'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import {isNull} from 'lodash'
import ChipsDropdown from '@/components/ChipsDropdown'
import {parseWeek} from '@/variables'

const tabs = ['Grid', 'Lookalikes', 'Commit', 'Orders']
export default {
  mixins: [],
  components: {
    ChipsDropdown,
    ShopGrid,
    CopyExistingInbound,
    ShopOrders,
    ShopItemCarryOver,
    ShopItemProjection,
    LookalikeComparison,
    ShopItemCommitments,
    ShopItemInfo,
    ShopItemInputForm,
    ShopItemBeliefs,
    ShopItemCommitForm,
    ShopItemOrderForm,
    MarginCalculator,
  },
  watch: {
    '$route.query': {
      handler (newValue) {
        this.setup()
        if (newValue.tab) {
          const activeTab = tabs.indexOf(newValue.tab)
          if (activeTab !== this.activeTab) {
            this.activeTab = activeTab
          }
        }
      },
      deep: true,
      immediate: true
    },
    '$route.query.shop_item_key': {
      handler (newValue) {
        this.alreadySet = false
        this.setup()
        if (newValue.tab) {
          const activeTab = tabs.indexOf(newValue.tab)
          if (activeTab !== this.activeTab) {
            this.activeTab = activeTab
          }
        }
      },
      deep: true,
    },
    activeTab: {
      async handler (newValue) {
        await this.loadTabData(newValue)
      },
      immediate: true
    },
    currentShopItem: {
      async handler () {
        await this.loadTabData(this.activeTab)
        if (this.currentShopItem && typeof this.currentShopItem?.order_settings?.cover_min !== 'number' &&
          typeof this.currentShopItem?.order_settings?.cover_min !== 'string'
        ) {
          this.currentShopItem.order_settings.cover_min = 0
        }
        if (this.currentShopItem && typeof this.currentShopItem?.order_settings.cover_max !== 'number' &&
          typeof this.currentShopItem?.order_settings.cover_max !== 'string'
        ) {
          this.currentShopItem.order_settings.cover_max = 6
        }
      },
      immediate: true
    },
  },
  data () {
    return {
      copyDialogVisible: false,
      copyExistingData: [],
      projection_table: null,
      sku_table_perc: null,
      orderProperties: [],
      sku_table: null,
      prevRoute: null,
      reorderOptions: null,
      activeTab: 0,
      commitKey: 0,
      beliefsKey: 0,
      commitmentsPayload: null,
      progressData: null,
      loading: false,
      commitmentsLoading: false,
      ordersLoading: false,
      currentShopItem: null,
      budgetProgressIndex: parseInt(localStorage.getItem('buy-chart-carousel') || 0),
      budgetProgressLegendItems: [{
        caption: 'Ordered',
        color: '#4CAF50',
      }, {
        caption: 'Committed',
        color: '#FF9800',
      }, {
        caption: 'Potential',
        color: '#FFD202',
      }, {
        caption: 'Open',
        color: '#E3E3E3',
      }],
      currentDummy: null,
      storeSets: [],
      commitments: [],
      orders: [],
      toOrder: [],
      orderFormDialogVisible: false,
      editedOrder: null,
      editedOrderLines: [],
      editedOrderSchedule: [],
      editedOrderCosts: [],
      splitOrder: false,
      commitFilters: {
        store_keys: null,
        sku_names: null,
        week_min: null,
        week_max: null,
      },
      kpiFilters: JSON.parse(localStorage.getItem('kpiFilters')) || {
        week_min: null,
        week_max: null,
      },
      statusOptions: [{
                        text: 'In progress',
                        value: 'in progress',
                      }, {
                        text: 'Ordered',
                        value: 'ordered',
                      }, {
                        text: 'Delivered',
                        value: 'delivered',
                      },
                      {
                        text: 'To order',
                        value: 'to_order',
                        disabled: true,
                      }],
      marginCalculatorDialogVisible: false,
      reorderData: {},
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    ...mapState(useGeneralStore, {
      orderPoints: (state) => state.buySettings?.order_points || [],
      differenceStores () {
        return (this.commitments.commitment_difference?.stores || []).map((item)=> {
          return this.stores.find(store=> store.store_key === item)?.store_name
        })
      },
      differenceWeeks () {
        return (this.commitments.commitment_difference?.weeks || []).map(parseWeek)
      },
      differenceSkus () {
        return (this.commitments.commitment_difference?.skus || [])
      },
    }),
    stores () {
      return this.appConfig?.stores || []
    },
    currencies () {
      return this.appConfig?.currencies || []
    },
    suppliers () {
      return this.appConfig?.suppliers || []
    },
    extraStatusOptions () {
      return this.orderPoints.map(p => ({
        text: p,
        value: this.$options.filters.formatString(p),
      }))
    },
    schedules () {
      return this.appConfig?.schedules || []
    },
    payments () {
      return this.appConfig?.payments || []
    },
    sellthrough () {
      const value = this.currentShopItem.order_settings.sellthrough
      return (!value && value !== 0 ? 1 : value) * 100
    },
    optionKey () {
      return this.currentDummy?.option_key || ''
    },
    shoppingRoute () {
      return this.prevRoute && this.prevRoute.name
        ? { path: this.prevRoute.fullPath }
        : { path: '/buy/home' }
    },
    currentSupplier () {
      return this.suppliers.find(s => this.currentShopItem.supplier_key === s.supplier_key)
    },
  },
  methods: {
    redrawGraphs (){
      this.reloadShopItem(true).then(this.loadShopItemReorderData)
    },
    async loadCopyExistingData (option_key){
      this.copyExistingData = await getInboundsOption(option_key).catch(console.error)
    },
    ordersUpdate () {
      this.loadShopItemOrders()
    },

    validateSellthrough (value) {
      if (isNull(value) || value === undefined) {
        return true
      }
      return Number.isInteger(parseFloat((value || 0).toFixed(2))) && value >= 1 && value <= 100
    },
    onSellthroughChange (event) {
      let value = event
      if (!event) {
        value = null
      }
      this.currentShopItem.order_settings.sellthrough = value
      this.onParamsChange()
    },
    saveBudgetProgressIndex (index) {
      this.budgetProgressIndex = index
      localStorage.setItem('buy-chart-carousel', index)
    },
    saveKPIFilters (filters) {
      this.kpiFilters = filters
      localStorage.setItem('kpiFilters', JSON.stringify(this.kpiFilters))
    },
    onUpdateOrder (data) {
      this.editedOrder = data.order
      this.editedOrderSchedule = data.order_schedules
      this.editedOrderCosts = { costs: data.costs, costs_grouped: data.costs_grouped }
    },
    onParamsChange () {
      if(!Number.isInteger(this.currentShopItem.order_settings.cover_max || 0) || this.currentShopItem.order_settings.cover_max < 0 ){
        return
      }
      if(!Number.isInteger(this.currentShopItem.order_settings.cover_min || 0) || this.currentShopItem.order_settings.cover_min < 0 ){
        return
      }
      if(this.currentShopItem.order_settings.cover_min >= this.currentShopItem.order_settings.cover_max){
        return
      }
      this.handleParamsChange()
    },
    async loadTabData (newValue) {
      if (!this.currentShopItem || !this.currentDummy) {
        return
      }
      const tab = tabs[newValue]
      if (tab !== this.$route.query.tab) {
        this.$router.replace({
          name: this.$route.name,
          query: { ...this.$route.query, tab }
        })
      }
      if ((newValue === 2 || newValue === 3) && !this.commitmentsCalled) {
        this.commitmentsCalled = true
      }
      if (newValue === 3 && !this.ordersCalled) {
        this.ordersCalled = true
        this.loadShopItemReorderData()
          .then(() => {
            this.loadShopItemOrders()
          })
      }
    },
    onShopItemCurrencyPriceChanged () {
      this.reloadShopItem()
    },
    handleBeliefsSaved () {
      this.$refs.commitForm.loadCommitData()
      this.loadShopItemOrders()
    },
    handleDeleteBeliefs (payload) {
      this.$refs.beliefs.handleDeleteBeliefs(payload)
    },
    async dummyChanged () {
      this.loading = true
      await saveDummy(this.currentDummy).then(this.loadDummy)
      this.loadShopItemReorderData()
        .then(() => {

          this.loadShopItemOrders()
          this.$refs.lookalikes?.getLookalikeData()
        })
        .finally(() => {
          this.loading = false
        })
    },
    getCommitmentsPayload () {
      return {
        dummy_key: this.currentShopItem.dummy_key,
        shop_item_key: this.currentShopItem.shop_item_key,
        scenario_key: this.currentShopItem.scenario_key,
        budget_key: this.currentShopItem.budget_key,
        option_key: (this.currentDummy || {}).option_key,
        sku_names: this.currentShopItem.sku_names,
        reorder_option_keys: this.currentShopItem.reorder_option_keys,
        store_keys: this.currentShopItem.store_keys,
        beliefs: this.currentShopItem.beliefs,
        week_min: this.currentShopItem.week_min,
        week_max: this.currentShopItem.week_max,
        lookalike_option_keys: this.currentDummy.lookalike_option_keys
      }
    },
    loadStoreSets () {
      const storesLabel = useGeneralStore().currentStoresSetsLabel
      return storeSetsApi
        .getStoreSets(`?label=${storesLabel}`)
        .then(response => {
          this.storeSets = response.stores_sets
        })
    },
    loadShopItem () {
      return shopApi
        .getShopItem(this.$route.query)
        .then(item => {
          delete item.option_key
          this.currentShopItem = item
          if(typeof this.currentShopItem.prediction_mode === 'string'){
            this.currentShopItem.prediction_mode = [this.currentShopItem.prediction_mode]
          }
          this.beliefsKey++
        })
    },
    deleteCommitments () {
      deleteCommitments(this.getCommitmentsPayload()).then(this.refreshCommitments)
    },
    refreshCommitments (commitments) {
      this.commitmentsLoading = true
      this.commitments = commitments || {}

      const { potential, committed, residual } = commitments.skus_data
      const shopItemPayload = {
        ...this.currentShopItem,
        potential,
        committed,
        residual,
      }

      shopApi.saveShopItem(shopItemPayload)
        .then((data) => {
          this.ordersUpdate()
          this.currentShopItem = data
          this.$auth.refreshAppConfig()
          this.loadPage()
          this.commitmentsLoading = false
          this.loadShopItemOrders()
        })
    },
    async loadShopItemCommitments (payload, needToSave = false) {
      payload = {...payload, ...this.commitFilters}
      payload.week_min = parseInt(payload.week_min)
      payload.week_max = parseInt(payload.week_max)
      this.commitmentsLoading = true
      return shopApi
        .getCommitments(payload)
        .then(response => {
          this.commitments = response || {}
          if (needToSave) {
            const { potential, committed, residual } = response.skus_data
            const shopItemPayload = {
              ...this.currentShopItem,
              potential,
              committed,
              residual,
            }
            return shopApi.saveShopItem(shopItemPayload).then(item => {
              this.currentShopItem = item
              this.$auth.refreshAppConfig()
              this.ordersUpdate()
              this.loadPage()
            })
          }
        })
        .finally(() => {
          this.commitmentsLoading = false
        })
    },
    handleBeliefsUpdated (data) {
      this.currentShopItem = JSON.parse(JSON.stringify(data))
      setTimeout(() => {
        this.$refs.lookalikes?.getLookalikeData()
        this.$refs.commitForm.loadCommitData()
        this.beliefsKey++
        this.loadShopItemOrders()
      }, 100)
    },
    handleCommitmentsSaved () {
      this.commitmentsLoading = true
      this.ordersLoading = true
      // this.commitmentsPayload = this.getCommitmentsPayload()
      return this.loadShopItemCommitments(this.getCommitmentsPayload(), true).then(() => {
        return Promise
          .all([
            this.$refs.commitForm.loadCommitData(),
            this.loadShopItemOrders(),
            this.loadShopItemReorderData(),
          ])
          .finally(() => {
            this.commitmentsLoading = false
            this.ordersLoading = false
          })
      })
    },
    loadShopItemOrders () {
      this.ordersLoading = true

      const { budget_key, scenario_key, shop_item_key } = this.currentShopItem

      return shopApi
        .getShopItemOrders(budget_key, scenario_key, shop_item_key)
        .then(({ orders, properties }) => {
          this.orders = orders
          this.orderProperties = properties || {}
        })
        .finally(() => {
          this.ordersLoading = false
        })
    },
    onCarryOverChange (carry_over) {
      this.loadShopItemReorderData(carry_over)
      this.currentShopItem.carry_over = carry_over
      shopApi.saveShopItem(this.currentShopItem).then((data) => {
        this.loadShopItemOrders()
        this.currentShopItem = data
      })
    },
    clearOrderSettings () {
      const settings = this.currentShopItem.order_settings
      for (const key in settings) {
        if (settings[key] === '') {
          delete settings[key]
        }
      }
    },
    loadShopItemReorderData (carry_over = undefined) {
      this.clearOrderSettings()
      const payload = {
        shop_item_key: this.currentShopItem.shop_item_key,
        budget_key: this.currentShopItem.budget_key,
        scenario_key: this.currentShopItem.scenario_key,
        ...this.currentShopItem.order_settings,
        leadtime: this.currentShopItem.leadtime,
        reorder_option_keys: this.currentShopItem.reorder_option_keys,
        option_key: (this.currentDummy || {}).option_key,
        week_min: this.currentShopItem.week_min,
        week_max: this.currentShopItem.week_max,
      }
      carry_over = carry_over || this.currentShopItem.carry_over
      if (carry_over) {
        payload.carry_over = carry_over
      }
      this.ordersLoading = true
      return shopApi
        .getShopItemToOrder(payload)
        .then(data => {
          this.reorderData = { ...data.projection, leadtime: this.currentShopItem.leadtime } || null
          this.projection_table = data.projection_table || {}
          this.projection_table.columns = data.projection_table?.columns?.filter(column => column !== 'Sales LY')
          this.progressData = data.order_progress
          this.progressData = data.order_progress
          this.setReorderOption(data.reorder_options_stock)
          this.sku_table = data.sku_table
          this.sku_table_perc = data.sku_table_perc
          this.ordersLoading = false
        })
    },
    setReorderOption (data) {
      for (const key in data) {
        if (!data[key]) {
          data[key] = 0
        }
      }
      this.reorderOptions = data
    },
    loadDummy () {
      return dummiesApi
        .loadDummy(this.currentShopItem.dummy_key)
        .then(response => {
          this.currentDummy = { ...response.dummy, name: response.name }
          this.loadTabData(this.activeTab)
          this.loadCopyExistingData(this.currentDummy.option_key)
        })
    },
    reloadShopItem (justUpdate) {
      this.loading = true
      return this
        .loadShopItem()
        .finally(async () => {
          if (!justUpdate){
            await this.loadShopItemOrders()
          }
          this.loading = false
        })
    },
    handleFiltersChanged (filters) {
      this.commitFilters = filters

      this.commitmentsCalled = true
      const commitmentsPayload = this.getCommitmentsPayload()
      this.loadShopItemCommitments(commitmentsPayload)
    },
    handleBeliefChartItemClick (itemData) {
      this.$refs.beliefs.createBeliefFromChartItem(itemData, this.commitFilters)
    },
    handleBeliefChartItemChange (itemData) {
      this.$refs.beliefs.createBeliefFromTableItem(itemData, this.commitFilters)
    },
    loadPage () {
      return Promise
        .all([
          this.loadStoreSets(),
          this.loadDummy(),
        ])
        .then(() => {
          this.commitmentsPayload = this.getCommitmentsPayload()
          return optionViewCallOrders(this.currentDummy?.option_key, this.currentShopItem.shop_item_key)
            .then(() => {
              this.loading = false
            })
        })
        .catch(console.log)
    },
    handleInputFormSaved (shopItem) {
      this.currentShopItem = shopItem
      this.$refs.commitForm.setFiltersFromShopItem()
    },
    handleShopItemWeeksChanged (shopItem) {
      this.currentShopItem = shopItem
      this.loadPage()
    },
    handleShopItemReorderOptionsChanged () {
      this.reloadShopItem()
        .then(this.loadShopItemReorderData)
    },
    handleMarginCalculatorOpen () {
      this.marginCalculatorDialogVisible = true
      this.$nextTick(() => {
        this.$refs['marginCalculator'].loadData(this.currentShopItem)
      })
    },
    async handleSplitOrderChanged (val) {
      this.splitOrder = val

      this.loading = true
      await this.saveSplitOrders()
      return this.loadShopItemReorderData()
        .then(() => {

          this.loadShopItemOrders()
        })
        .finally(() => {
          this.loading = false
        })
    },
    async handleParamsChange () {
      this.loading = true
      this.clearOrderSettings()
      const payload = { ...this.currentShopItem }

      this.loading = true

      await shopApi
        .saveShopItem(payload).finally(() => {
          this.loadShopItemOrders()
          this.loading = false
        })
      return this.loadShopItemReorderData()
        .then(() => {
          this.loadShopItemOrders()
          this.$refs.lookalikes?.getLookalikeData()
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleEditOrderClick ([orderInfo, orderLines, orderSchedule, orderCosts]) {
      this.orderFormDialogVisible = true
      this.editedOrder = orderInfo
      this.editedOrderLines = orderLines.map(ol => ({
        ...ol,
        order_key: orderInfo.order_key,
      }))
      this.editedOrderSchedule = orderSchedule
      this.editedOrderCosts = orderCosts
    },
    async handleOrderSaved () {
      this.loading = true
      try {
        setTimeout(async ()=> {
          await this.loadShopItemReorderData()
          await this.loadShopItemOrders()
          this.loading = false
        }, 500)
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    async copyExistingSaved () {
      await this.handleOrderSaved()
      await this.loadCopyExistingData(this.currentDummy.option_key)
    },
    openExistingInbounds () {
      this.copyDialogVisible = true
      this.loadCopyExistingData(this.currentDummy.option_key)

    },
    handleOrderDeleted () {
      this.loading = true

      return this.loadShopItemReorderData()
        .then(() => {
          return Promise.all([
            this.loadShopItemOrders(),
            this.reloadShopItem(),
          ])
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleOrderProgressSaved () {
      this.orderFormDialogVisible = false
      this.editedOrder = null
      this.editedOrderLines = []
      this.loading = true

      return this.loadShopItemReorderData()
        .then(() => {
          this.loadShopItemOrders()

          this.reloadShopItem()
          this.loadPage()
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleOrderCostsSaved () {
      this.loading = true

      const payload = {
        ...this.$route.query,
        order_key: this.editedOrder.order_key,
      }

      shopApi
        .getShopItemOrderCosts(payload)
        .then(orderCosts => {
          this.editedOrderCosts = orderCosts
        })
        .finally(() => {
          this.loading = false
        })
    },
    async setup () {
      if (this.alreadySet || this.setting) {
        return
      }
      this.loading = true
      this.setting = true
      try {
        await this.loadShopItem()
        sessionStorage.removeItem('commitFilters')
        await this.loadPage()
        this.$refs.commitForm.setFiltersFromShopItem()
        this.alreadySet = true
        this.setting = false
        // }
      } catch (e) {
        console.error(e)
      }
      this.loading = false
      this.setting = false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
}
</script>

<style lang="scss">
.buy-shop-item-page {
  .title-div {
    display: flex;
    align-items: center;
    grid-row: 1/2;
    grid-column: 1/4;
    .route-name {
      cursor: pointer;
      color: var(--text-sub-black);
    }
  }
}
</style>
