<template>
  <div class="settings-contamination-list p-relative">
    <v-overlay absolute color="white" :value="loadingContamination">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="mt-0 pt-2">
      <v-col :cols="6">
        <h6 class="text-subtitle-1 font-weight-medium ml-6">Contamination overview</h6>
      </v-col>
      <v-col :cols="3" :offset="3" class="text-right pr-6 d-flex align-center justify-end">
        <label class="v-label theme--light mr-2">Historic</label>
        <v-switch
          v-model="historic"
          hide-details
          class="d-inline-block pt-0 mt-0 mr-2"
          @change="handleHistoricChange"
        />
      </v-col>
    </v-row>
    <v-row>
      <div class="col text-right pr-6">
        <table-search
          class="mx-2"
          @searchChanged="filters => handleSearchChanged('resultsTableFilters',columnsToSearch, filters)"
        />
        <v-icon
          class="mx-2 action-btn-primary"
          @click="handleAddClick"
        >add</v-icon>
        <v-icon
          class="mx-2 my-3 action-btn-danger"
          @click="handleDeleteClick"
        >delete</v-icon>
      </div>
    </v-row>
    <v-row>
      <div class="col">
        <data-tables
          style="width: 100%"
          :data="contaminationList"
          :page-size="pageSize"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :table-props="{ 'row-key': 'rn' }"
          filter-multiple
          :filters="resultsTableFilters"
          @update:currentPage="value => {currentPage = value; this.selectedIndex = null}"
          @update:pageSize="value => { pageSize = value; this.selectedIndex = null}"
          @filter-change="parseStoreFilterChange"
          @row-click="handleRowClick"
        >
          <el-table-column
            width="55"
            prop="store">
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-4 pt-0"
                :value="selectedIndex === scope.$index"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.$index)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="stores"
            column-key="stores"
            label="Stores"
            sortable="custom"
            class-name="pl-4"
            :filters="getColumnFilterValues('contaminationsStoresFilters', 'stores')"
          >
            <template slot-scope="scope">
              <chips-dropdown
                :items="getFormattedStoresList(scope.row.stores).map(item=> item.store_name)"
              ></chips-dropdown>
            </template>
          </el-table-column>
          <el-table-column
            prop="date_min"
            label="From date"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ scope.row.date_min }}
            </template>
          </el-table-column>
          <el-table-column prop="date_max" label="To date" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.date_max }}
            </template>
          </el-table-column>
          <el-table-column prop="note" label="Notes" sortable="custom">
            <template slot-scope="scope">
              {{scope.row.note}}
            </template>

          </el-table-column>
        </data-tables>
      </div>
    </v-row>

    <!-- Form dialog -->
    <v-dialog
      :value="formDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0" :loading="addingInProgress">
        <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">New Contamination</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 text--primary">
          <div class="container px-12">
            <template>
              <v-row class="align-baseline mt-0 mb-4">
                <v-col class="py-0">
                  <collapsible-select
                    v-model="newContaminationForm.stores"
                    label="Select stores"
                    :items="appConfig.stores"
                    :error-messages="contaminationFormErrors.stores"
                    item-text="store_name"
                    item-value="store_key"
                    show-select-all
                    autocomplete
                    hide-details="auto"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu'
                    }"
                  />
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-0 mb-4">
                <v-col class="py-0">
                  <v-menu
                    v-model="fromDatePickerActive"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="newContaminationForm.date_min"
                        :error-messages="contaminationFormErrors.date_min"
                        clearable
                        label="From date"
                        hide-details="auto"
                        v-on="on"
                        @click:clear="newContaminationForm.date_min = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      :locale-first-day-of-year="4"
                      :show-week="true"
                      no-title
                      :max="newContaminationForm.date_max"
                      v-model="newContaminationForm.date_min"
                      @click="fromDatePickerActive = false"
                    />
                  </v-menu>
                </v-col>
                <v-col class="py-0">
                  <v-menu
                    v-model="toDatePickerActive"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="newContaminationForm.date_max"
                        :error-messages="contaminationFormErrors.date_max"
                        clearable
                        label="To date"
                        hide-details="auto"
                        v-on="on"
                        @click:clear="newContaminationForm.date_max = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      :locale-first-day-of-year="4"
                      :show-week="true"
                      no-title
                      v-model="newContaminationForm.date_max"
                      :min="newContaminationForm.date_max"
                      @click="toDatePickerActive = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="align-center mt-8 mb-0 px-3">
                <v-text-field
                  v-model="newContaminationForm.note"
                  :error-messages="contaminationFormErrors.note"
                  label="Note"
                ></v-text-field>
              </v-row>
            </template>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    depressed
                    text
                    @click="handleSaveFormCancelClick"
                  >Cancel</v-btn>
                  <v-btn
                    color="primary"
                    depressed
                    outlined
                    @click="handleSaveFormClick"
                    class="mx-4"
                  >Add</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :value="deleteDialogVisible"
      persistent
      width="600px"
      max-width="90%">
      <v-card>
        <v-card-title class="text-body-1 font-weight-medium">Delete contamination</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete this contamination?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteConfirm"
                class="red--text mx-4"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import { getHeadersWithAccessToken } from '@/variables'
import axios from '../../services/axios'
import ChipsDropdown from '../ChipsDropdown'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import baseURLs from '@/api/APIBaseURLs'

export default {
  mixins: [columnFilters],
  data () {
    return {
      historic: false,
      loadingContamination: true,
      originalContaminationList: [],
      contaminationList: [],
      selectedIndex: null,
      formDialogVisible: false,
      fromDatePickerActive: false,
      toDatePickerActive: false,
      addingInProgress: false,
      deleteDialogVisible: false,
      resultsTableFilters: [],
      tableDatepickerFromActive: {},
      tableDatepickerToActive: [],
      currentPage: 1,
      pageSize: 10,
      tableDateFromErrors: [],
      tableDateToErrors: [],
      newContaminationForm: {
        stores: []
      },
      contaminationFormErrors: {
        stores: null,
        date_min: null,
        date_max: null,
        note: null
      },
      contaminationTableFilters: [],
      columnsToSearch: ['stores', 'date_min', 'date_max', 'note'],
    }
  },
  components: {
    ChipsDropdown,
    TableSearch,
    CollapsibleSelect,
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    tableHasErrors () {
      return this.tableDateFromErrors.length > 0 || this.tableDateToErrors.length > 0
    },
    contaminationsStoresList () {
      const lists = []
      this.contaminationList.forEach((item) => lists.push(...this.getFormattedStoresList(item.stores)))
      return lists
    },
    contaminationsStoresFilters () {
      return this.contaminationsStoresList.map((item) => {
        return { stores: item.store_name }
      })
    }
  },
  methods: {
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (event, index) {
      this.selectedIndex = event ? index : null
    },
    getFormattedStoresList (stores) {
      return (this.appConfig?.stores || []).filter((item) => {
        return stores.includes(item.store_key)
      })
    },
    parseStoreFilterChange (value) {
      const keys = this.contaminationsStoresList.filter((item) => value.stores.includes(item.store_name))
        .map((item) => item.store_key)
      this.resultsTableFilters = [{ prop: 'stores', value: [...new Set(keys)] }]
    },
    handleAddClick () {
      this.formDialogVisible = true
    },
    handleHistoricChange () {
      this.loadData()
    },
    handleDeleteClick () {
      this.deleteDialogVisible = true
    },
    handleDeleteConfirm () {
      const url = baseURLs.manageAPI + '/stores_contaminations'
      const index = this.selectedIndex + ((this.currentPage - 1) * this.pageSize)
      axios.delete(
        url,
        { headers: getHeadersWithAccessToken(), data: { ...this.contaminationList[index] }
        }).then(() => {
        this.loadData()
        this.selectedIndex = null
        this.deleteDialogVisible = false
      })
    },
    handleSaveFormCancelClick () {
      this.formDialogVisible = false
      this.resetForm()
    },
    handleSaveFormClick () {
      if (!this.validateClosure()) {
        return
      }
      this.addingInProgress = true
      const url = baseURLs.manageAPI + '/stores_contaminations'
      axios.post(url, this.newContaminationForm).then(() => {
        this.selectedIndex = null
        this.addingInProgress = false
        this.formDialogVisible = false
        this.loadData()
      }).catch((e) => console.log(e.response))
    },
    handleTableDateFromSelected (scope) {
      this.tableDatepickerFromActive[scope.$index] = false
      this.handleTableInlineChange(scope.row)
    },
    handleTableDateToSelected (scope) {
      this.tableDatepickerToActive[scope.$index] = false
      this.handleTableInlineChange(scope.row)
    },
    validateClosure () {
      this.resetFormErrors()

      if (!this.newContaminationForm.stores) {
        this.contaminationFormErrors.stores = 'Can not be empty'
      }

      if (this.newContaminationForm.note && this.newContaminationForm.note.length > 150) {
        this.contaminationFormErrors.note = 'Note should not be more then 150 characters'
      }

      return !this.formHasErrors()
    },
    resetForm () {
      this.newContaminationForm = { block_proposals: false }
      this.resetFormErrors()
    },
    formHasErrors () {
      return !Object.values(this.contaminationFormErrors).every(val => val === null)
    },
    resetFormErrors () {
      const errors = this.contaminationFormErrors

      Object.keys(errors).forEach(key => {
        errors[key] = null
      })
    },
    loadData () {
      this.loadingContamination = true
      axios.get(baseURLs.manageAPI + `/stores_contaminations?historic=${this.historic}`)
        .then(r => {
          this.contaminationList = r.data.contaminations
          this.loadingContamination = false
        })
    },
  },
  created () {
    this.loadData()
  },
}
</script>

<style lang="scss">
.settings-contamination-list {
  .inline-date-input {
    font-size: 12px;
    width: 150px;
  }
}
</style>
