import {defineStore} from 'pinia'
import * as api from '@/api/stockOperateAPI'
import * as models from '@/models/StockOperate'

export const useStockOperateStore = defineStore('stockOperate', {
  state: (): models.State => ({
    operationsList: [],
    operationsImportList: [],
    filtersTab: {
      selectedFilters: [],
      propertiesIncludeValuesList: {},
      propertiesExcludeValuesList: {},
      propertiesInclude: {},
      propertiesExclude: {},
      searchText: '',
      searchResults: [],
      searchResultsRefreshTime: null,
      loadingResults: false,
      showSearchResults: false,
      applyingOperation: false,
      operationApplied: null,
    },
    fileTab: {
      selectedOptionsKey: [],
      loadingResults: false,
      searchResults: [],
      searchResultsRefreshTime: null,
      showSearchResults: false,
      applyingOperation: false,
      operationApplied: null,
      parsedResults: [],
    },
    optionSetsTab: {
      selectedOptionSetsKey: [],
      loadingResults: false,
      searchResults: [],
      searchResultsRefreshTime: null,
      showSearchResults: false,
      applyingOperation: false,
      operationApplied: null,
    },
    searchResultsSelected: [],
  }),
  actions: {
    validateImportDataColumns (validationColumns: string[]): string[] {
      const existingColumns = this.getFileTabParsedResultsColumns
      const errors = []
      // Check columns one by one and match them with the existing columns and ordering
      for (let i = 0; i < validationColumns.length; i++) {
        if (existingColumns.length <= i) {
          errors.push(`Column (${i + 1}) '${validationColumns[i]}' missing`)
        }else if (validationColumns[i] !== existingColumns[i]) {
          errors.push(`Column (${i + 1}) '${existingColumns[i]}' should be "${validationColumns[i]}"`)
        }
      }
      return errors
    },
    async findOptionsByFilters (payload: models.FindOptionsByFiltersPayload) {
      this.setLoadingResults('filters', true)
      this.setShowSearchResults('filters', true)

      try {
        const data = await api.findOptionsByFilters(payload)
        this.setSearchResults('filters', data.options)
        this.setSearchResultsRefreshTime('filters', data.refreshTime)
      } catch (error) {
        console.error('Failed to find options by filters:', error)
      } finally {
        this.setLoadingResults('filters', false)
      }
    },
    async findOptionsByOptionsKey (payload: models.FindOptionsByOptionsKeyPayload) {
      this.setLoadingResults('file', true)
      this.setShowSearchResults('file', true)

      try {
        const response = await api.findOptionsBySearchKey(payload)
        this.setSearchResults('file', response.data)
        this.setSearchResultsRefreshTime('file', response.refreshTime)
      } catch (error) {
        console.error('Failed to find options by options key:', error)
      } finally {
        this.setLoadingResults('file', false)
      }
    },
    async findOptionsByOptionSetKey (payload: number) {
      this.setLoadingResults('optionSets', true)
      this.setShowSearchResults('optionSets', true)

      try {
        const response = await api.findOptionsSetByOptionSetKey(payload)
        const optionsSet = response.options_sets[0]
        const previewResponse = await api.getOptionsPreviewByCriteria({ options_set: optionsSet })
        // TODO stockManage.getOptionsPreviewByCriteria
        this.setSearchResults('optionSets', previewResponse.options)
        this.setSearchResultsRefreshTime('optionSets', previewResponse.refreshTime)
      } catch (error) {
        console.error('Failed to find options by option set key:', error)
      } finally {
        this.setLoadingResults('optionSets', false)
      }
    },
    async loadOperations () {
      try {
        this.operationsList = await api.getOperations()
      } catch (error) {
        console.error('Failed to load operations:', error)
      }
    },
    async loadImportOperations () {
      try {
        this.operationsImportList = (await api.getOperations(true)).filter(o => o.operation.startsWith('import_'))
      } catch (error) {
        console.error('Failed to load operations:', error)
      }
    },
    async applyBatchOperation (payload: models.ApplyBatchOperationPayload) {
      try {
        await api.applyBatchOperation(payload.operationData)
        this.setOperationApplied(payload.tab, true)
      } catch (error) {
        console.error('Failed to apply batch operation:', error)
        this.setOperationApplied(payload.tab, false)
      } finally {
        this.setApplyingOperation(payload.tab, false)
      }
    },
    async applyOptionSetOperation (payload: models.ApplyOptionSetOperationPayload) {
      try {
        await api.applyOptionSetOperation(payload.operationData)
        this.setOperationApplied(payload.tab, true)
      } catch (error) {
        console.error('Failed to apply option set operation:', error)
        this.setOperationApplied(payload.tab, false)
      } finally {
        this.setApplyingOperation(payload.tab, false)
      }
    },
    addSelectedFilter (filter: models.Filter) {
      this.filtersTab.selectedFilters.push(filter)
    },
    removeSelectedFilter (filter: models.Filter) {
      this.filtersTab.selectedFilters = this.filtersTab.selectedFilters.filter(f => f.type !== filter.type)
    },
    setSearchText (searchText: string) {
      this.filtersTab.searchText = searchText
    },
    addPropertyInclude (property: string) {
      this.filtersTab.propertiesInclude = {
        ...this.filtersTab.propertiesInclude,
        [property]: [],
      }
    },
    addPropertyExclude (property: string) {
      this.filtersTab.propertiesExclude = {
        ...this.filtersTab.propertiesExclude,
        [property]: [],
      }
    },
    changePropertyInclude (oldProperty: string, newProperty: string) {
      this.filtersTab.propertiesInclude[newProperty] = this.filtersTab.propertiesInclude[oldProperty] || []
      delete this.filtersTab.propertiesInclude[oldProperty]
      // trigger change
      this.filtersTab.propertiesInclude = {...this.filtersTab.propertiesInclude}
    },
    changePropertyExclude (oldProperty: string, newProperty: string) {
      this.filtersTab.propertiesExclude[newProperty] = this.filtersTab.propertiesExclude[oldProperty] || []
      delete this.filtersTab.propertiesExclude[oldProperty]
      // trigger change
      this.filtersTab.propertiesExclude = {...this.filtersTab.propertiesExclude}
    },
    deletePropertyInclude (property: string) {
      delete this.filtersTab.propertiesInclude[property]
      // trigger change
      this.filtersTab.propertiesInclude = {...this.filtersTab.propertiesInclude}
    },
    deletePropertyExclude (property: string) {
      delete this.filtersTab.propertiesExclude[property]
      // trigger change
      this.filtersTab.propertiesExclude = {...this.filtersTab.propertiesExclude}
    },
    setPropertyIncludeValue (property: string, value: string[]) {
      this.filtersTab.propertiesInclude[property] = value
    },
    setPropertyExcludeValue (property: string, value: string[]) {
      this.filtersTab.propertiesExclude[property] = value
    },
    setSearchResults (tab: models.Tab, results: models.SearchResult[]) {
      this[`${tab}Tab`].searchResults = results
    },
    setSearchResultsRefreshTime (tab: models.Tab, refreshTime: string) {
      this[`${tab}Tab`].searchResultsRefreshTime = refreshTime
    },
    setShowSearchResults (tab: models.Tab, show: boolean) {
      this[`${tab}Tab`].showSearchResults = show
    },
    addSearchResultsSelected (tab: models.Tab, selected: models.SearchResult[]) {
      const currentSelected = this.searchResultsSelected
      const newSelected = selected.filter(item => !currentSelected.some(s => s.option_key === item.option_key))
      this.searchResultsSelected.push(...newSelected)
    },
    removeSearchResultsSelected (tab: models.Tab, selected: models.SearchResult[]) {
      const keysToRemove = new Set(selected.map(item => item.option_key))
      this.searchResultsSelected = this.searchResultsSelected.filter(
        item => !keysToRemove.has(item.option_key)
      )
    },
    setLoadingResults (tab: models.Tab, loading: boolean) {
      this[`${tab}Tab`].loadingResults = loading
    },
    setSelectedOptionKeys (optionsKey: number[]) {
      this.fileTab.selectedOptionsKey = optionsKey
    },
    setApplyingOperation (tab: models.Tab, applying: boolean) {
      this[`${tab}Tab`].applyingOperation = applying
    },
    setOperationApplied (tab: models.Tab, applied: boolean) {
      this[`${tab}Tab`].operationApplied = applied
    },
    setSelectedOptionSetsKey (optionSetsKey: number[]) {
      this.optionSetsTab.selectedOptionSetsKey = optionSetsKey
    },
    setFileTabParsedResults (parsedResults: Record<string, string>[]) {
      this.fileTab.parsedResults = parsedResults
    }
  },
  getters: {
    getFileTabParsedResultsColumns () : string[] {
      return this.fileTab.parsedResults[0] ? Object.keys(this.fileTab.parsedResults[0]) : []
    }
  }
})
