<template>
  <div class="buy-manage-schedules mb-8">
    <v-row class="ma-0" justify="space-between">
      <h4 class="pa-6 pb-2 pl-7 pt-8">Schedules</h4>
    </v-row>
    <v-row class="align-center ml-7 pb-8">
      <v-col cols="3" class="pl-0">
        <v-autocomplete
          :items="innerSchedules.map((item)=> {
            return {
              text: item.schedule_name,
              value: item,
            }
          })"
          label="Schedule name"
          :value="selectedSchedule"
          class=" pa-0 ma-0 "
          hide-details
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"
          @input="checkSelectedSchedule"
        />
      </v-col>
      <v-col cols="2">
        <v-btn icon class="mt-2 mb-0" @click="onEdit">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn icon class=" mt-2 mb-0" @click="onAdd">
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn
          icon
          class=" mt-2 mb-0"
          :disabled="!selectedSchedule || !selectedSchedule.schedule_key"
          @click="deleteDialogVisible = true"
        >
          <v-icon >delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <NameDialog
      :is-open="nameDialogVisible"
      :isAdd="isAdd"
      title="Schedule Name"
      text-field-label="Schedule Name"
      :value="selectedSchedule.schedule_name ? selectedSchedule.schedule_name : ''"
      @close="nameDialogVisible = false"
      @save="changeName"
      @add="addSchedule"
    />
    <v-row class="align-center ml-7 pb-8">
      <v-col cols="3" class="pl-0">
        <collapsible-select
          class="pa-0 ma-0 pt-2"
          v-if="selectedSchedule.schedule_key"
          v-model="selectedSchedule.cost_keys"
          :items="costs"
          item-text="cost_name"
          item-value="cost_key"
          autocomplete
          clearable
          label="Costs"
          @change="saveSchedule"
        />
      </v-col>
    </v-row>
    <div v-for="(key) in sortedSchedule" :key="key.point" class="d-flex px-6 mb-4">
      <div class="ruleblock-mark p-relative d-flex align-center">
        <v-icon>radio_button_checked</v-icon>
      </div>
      <div class="pa-6 grow ruleblock-content">
        <v-row class="align-center">
          <v-col class="py-0" cols="3">
            <div>{{ key.point }}</div>
          </v-col>
          <v-col cols="3" class="d-flex align-center py-0">
            <div class="ml-4 mr-2">Days delta:</div>
            <v-text-field
              type="number"
              class="pt-0 mt-1"
              hide-details="auto"
              :disabled="key.point === 'in shop'"
              :value="key.delta"
              :error-messages="key.delta > 0 ? 'Days delta must be negative': ''"
              @change="(value)=> changeDelta(key, value)"
            />
          </v-col>
          <v-col cols="4" class="d-flex align-center py-0">
            <div class="ml-4 mr-2">Task:
            </div>

            <v-switch v-model="key.task" @change="saveSchedule" dense></v-switch>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-dialog
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="p-relative rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Delete schedule
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Do you want to delete this schedule?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="deleteSchedule"
                class="red--text mx-4"
              >Ok</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { deleteSchedule, saveSchedule } from '@/api/manage'
import CollapsibleSelect from '../components/CollapsibleSelect'
import NameDialog from '@/components/Dialogs/NameDialog'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'BuyManageSchedules',
  components: { NameDialog, CollapsibleSelect },
  props: ['schedules', 'costs'],
  data () {
    return {
      nameDialogVisible: false,
      isAdd: false,
      selectedSchedule: {},
      newStepper: '',
      innerSchedules: [],
      deleteDialogVisible: false,
      newName: null,
    }
  },
  computed: {
    ...mapState(useGeneralStore, {
      anchorPoints: (state) => state.buySettings?.anchor_points || [],
    }),
    sortedSchedule () {
      if (!this.selectedSchedule.schedule || !this.selectedSchedule.schedule.length) {
        return []
      }
      return (this.selectedSchedule.schedule || []).sort((a, b) => b.delta - a.delta)
    },
  },
  methods: {
    onAdd () {
      this.isAdd = true
      this.nameDialogVisible = true
    },
    onEdit () {
      this.isAdd = false
      this.nameDialogVisible = true
    },
    addSchedule (name) {
      this.isAdd = false
      const newSchedule = {
        active: true,
        schedule_name: name,
        schedule: this.anchorPoints.map(item => ({ point: item, delta: 0, tasks: false }))
      }

      this.innerSchedules = [newSchedule, ...this.innerSchedules]
      this.selectedSchedule = newSchedule

      this.saveSchedule()
    },
    checkSelectedSchedule (value) {
      if (value !== null) {
        this.selectedSchedule = value
      }
    },
    changeName (value) {
      if (value === this.selectedSchedule.schedule_name || !this.selectedSchedule) {
        return
      }
      if (!value) {
        value = ''
      }
      this.newName = value
      if (!this.saved) {
        setTimeout(() => {
          this.saveScheduleWithName()
          this.saved = false
        }, 300)
      } else {
        this.saved = true
      }
    },
    saveScheduleWithName () {
      this.selectedSchedule.schedule_name = this.newName || ''
      this.saveSchedule()
    },
    saveSchedule () {
      if (!this.selectedSchedule.schedule) {
        return
      }
      const errorsLength = this.selectedSchedule.schedule
        .filter((item) => (!item.delta && item.delta !== 0) || (item.delta > 0)).length
      if (errorsLength) {
        this.selectedSchedule = { ...this.selectedSchedule }
        return
      }

      return saveSchedule(this.selectedSchedule)
        .then((schedule) => {
          if (!this.selectedSchedule.schedule_key) {
            this.selectedSchedule.schedule_key = schedule.schedule_key
          }
          this.$emit('schedule-added')
        })
    },
    changeDelta (key, value) {
      key.delta = parseFloat(value)
      this.saveSchedule()
    },
    deleteSchedule () {
      const payload = { schedule_key: this.selectedSchedule.schedule_key }

      deleteSchedule(payload)
        .then(() => {
          this.$emit('schedule-deleted')
          this.selectedSchedule = {}
          this.deleteDialogVisible = false
        })
    },
    addStepper (value) {
      this.selectedSchedule.schedule.push({ point: value, delta: 0, task: false })
      setTimeout(() => {
        this.newStepper = ''
      }, 50)
      this.saveSchedule()
    },
  },
  watch: {
    schedules (newValue) {
      this.innerSchedules = [...newValue]

      if (this.selectedSchedule && this.selectedSchedule.schedule_key) {
        this.selectedSchedule = this.innerSchedules
          .find(
            s => s.schedule_key === this.selectedSchedule.schedule_key
          )
      }
    },
    innerSchedules () {
      if ((this.selectedSchedule && this.selectedSchedule.schedule_key) || this.innerSchedules.length === 0) {
        return
      }
      this.selectedSchedule = this.innerSchedules[0]
    },
  },
  mounted () {
    this.innerSchedules = [ ...this.schedules ]
  },
}
</script>

<style lang="scss">

.buy-manage-schedules {
    .ruleblock-mark {
      width: 40px;

      .v-icon {
        cursor: pointer;
      }
    }

    .ruleblock-header {
      cursor: pointer;
    }

    .ruleblock-content {
      border: 1px solid var(--border-color);

      .block-name-select {
        width: 200px;
      }
    }
    .plus-icon {
      font-size: 24px;
    }

    .new-ruleblock {
      .ruleblock-mark {
        &:before,
        &:after {
          content: none;
        }
      }
    }

    .business-rule-row {
      border-bottom: 1px solid var(--border-color);

      &:last-child {
        border-bottom: 0;
      }
    }
}
</style>
