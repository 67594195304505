<template>
  <v-card flat>
    <div class="flex xs12">
      <v-row class="mt-4 mb-2 pl-1" justify="space-between">
        <v-col>
          <h4 class="pl-6 pt-1">File overview</h4>
        </v-col>
        <v-col class="text-right pr-8">
          <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
          <v-btn
            :disabled="selectedRows.length !== 1"
            icon
            @click="download"
          >
            <v-icon class="mx-2 action-btn-primary">download</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div>
        <data-tables
          :data="files"
          :key="tableKey"
          :filters="filters"
          :page-size="10"
          :table-props="{
            'row-key': 'file_name'
          }"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 pb-4 mr-2',
          }"
          :sort-method="sortMethod"

          @filter-change="value => handleFilterChange('filters', value)"
          @row-click="handleRowClick"
        >
          <el-table-column width="55">
            <template v-slot="{ row }">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-4 pt-0"
                :value="row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="file_name"
            :filters="getColumnFilterValues('files', 'file_name', (file_name)=> getFolderPart(file_name),true)"
            label="Folder"
            key="file_name"
          >
            <template v-slot="{ row }">
              {{getFolderPart(row.file_name)}}
            </template>
          </el-table-column>
          <el-table-column
            :filters="getColumnFilterValues('files', 'file_name', (file_name)=> getFilenamePart(file_name),true)"
            sortable="custom"
            prop="file_name"
            label="File name"
            key="file_name"
            column-key="file_name"
          >
            <template v-slot="{ row }">
              {{getFilenamePart(row.file_name)}}
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            :filters="getColumnFilterValues('files', 'last_modified')"
            prop="last_modified"
            label="Last modified"
            key="last_modified"
            column-key="last_modified"
          >
            <template v-slot="{ row }">
              {{row.last_modified}}
            </template>
          </el-table-column>
        </data-tables>
      </div>
    </div>
  </v-card>
</template>

<script>
import axios from '../services/axios'
import TableSearch from '@/components/TableSearch.vue'
import baseURLs from '@/api/APIBaseURLs'
import columnFilters from '@/mixins/columnFilters'

export default {
  name: 'SettingsDataExports',
  components: { TableSearch },
  mixins: [columnFilters],
  data () {
    return {
      files: [],
      tableKey: 0,
      filters: [
        {
          value: ''
        }
      ],
      sortMethod:{
        last_modified: (a, b)=> {
          let [mm, dd, yyyy] = a.split('/')
          const firstDate = new Date(`${dd}/${mm}/${yyyy}`);
          [mm, dd, yyyy] = b.split('/')
          const secondDate = new Date(`${dd}/${mm}/${yyyy}`)
          if (firstDate < secondDate) {
            return -1
          } else if (firstDate === secondDate) {
            return 0
          } else {
            return 1
          }
        },
        file_name: (a, b)=> {
          const first = this.getFilenamePart(a)
          const second = this.getFilenamePart(b)
          if (first < second) {
            return -1
          } else if (first === second) {
            return 0
          } else {
            return 1
          }
        }
      },
    }
  },
  computed: {
    selectedRows () {
      return this.files.filter((file) => file.selected)
    },

  },
  methods: {
    getFolderPart (path) {
      const parts = path.split('/')
      return parts.slice(0, parts.length - 1).join('/')
    },
    getFilenamePart (path) {
      const parts = path.split('/')
      return parts[parts.length - 1]
    },
    handleSearchChanged (val) {
      this.filters[0].value = val
      this.tableKey++
    },
    getManagerUrl () {
      return baseURLs.manageAPI
    },
    async download () {
      const filename = this.selectedRows[0].file_name
      const downloadURL = await this.getDownloadLink(filename)
      if (!downloadURL) {
        return
      }

      const link = document.createElement('a')
      link.setAttribute('href', downloadURL)
      link.setAttribute('download', filename)
      link.click()
    },
    async loadFileList () {
      const listURL = this.getManagerUrl() + '/files'
      try {
        const { data } = await axios.get(listURL)
        this.files = data.files
      } catch (e) {
        console.error(e)
      }
    },
    async getDownloadLink (filename) {
      const downloadURL = this.getManagerUrl() + '/files'
      try {
        const { data } = await axios.post(downloadURL, { filename })
        return data.url
      } catch (e) {
        console.error(e)
        return ''
      }
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
    }
  },
  created () {
    this.loadFileList()
  }
}
</script>

<style scoped>

</style>
