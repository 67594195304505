<template>
  <div class="shop-item-input-form">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-card class="pa-4 pt-0" flat>
      <v-row>
        <v-col class="py-1">
          <v-autocomplete
            v-model="inputForm.dropKey.value"
            :error-messages="inputForm.dropKey.error"
            :items="dropsList"
            label="Drop"
            item-value="drop_key"
            item-text="drop_name"
            hide-details
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @change="handleInputFormChange" />
        </v-col>
        <v-col class="py-1">
          <v-autocomplete
            :value="inputForm.supplierKey.value"
            :error-messages="inputForm.supplierKey.error"
            :items="suppliers"
            item-value="supplier_key"
            item-text="supplier_name"
            label="Supplier"
            hide-details
            return-object
            @input="(value)=> inputForm.supplierKey.value = value.supplier_key"
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @change="handleSupplierChange" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <v-row>
            <v-col>
              <WeekSelector
                v-model="inputForm.weekMin.value"
                only-future
                label="Start"
                :higherLimit="inputForm.weekMax.value"
                clearable
                hide-details
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @change="handleWeeksChange" />
            </v-col>
            <v-col>
              <WeekSelector
                only-future
                v-model="inputForm.weekMax.value"
                label="End"
                hide-details
                :lower-limit="inputForm.weekMin.value"
                clearable
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @change="handleWeeksChange" />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="py-1">
          <v-autocomplete
            v-model="inputForm.scheduleKey.value"
            :error-messages="inputForm.scheduleKey.error"
            :items="schedules"
            item-value="schedule_key"
            item-text="schedule_name"
            label="Schedule"
            hide-details
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @change="handleInputFormChange" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <v-combobox
            v-model="inputForm.storeSets.value"
            label="Store sets"
            placeholder="Search"
            :items="storeSetsListToShow"
            :error-messages="inputForm.storeSets.error"
            item-value="stores_set_key"
            item-text="set_name"
            multiple
            chips
            clearable
            small-chips
            deletable-chips
            hide-details
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @blur="handleStoreSetsBlur"
            @change="handleInputFormChange"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip
                v-if="index === 0"
                small
                close
                outlined
                @click="handleFirstStoreSetsClick"
                @click:close="inputForm.storeSets.value.splice(0, 1)"
              >
                <span>{{ item.set_name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption mx-1">
                +{{ inputForm.storeSets.value.length - 1 }}
              </span>
            </template>
          </v-combobox>
        </v-col>
        <v-col class="py-1">
          <v-autocomplete
            v-model="inputForm.paymentKey.value"
            :error-messages="inputForm.paymentKey.error"
            :items="payments"
            item-value="payment_key"
            item-text="payment_name"
            label="Payment"
            hide-details
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @change="handleInputFormChange" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="py-1">
          <collapsible-select
            class="mt-4"
            v-model="inputForm.reorderOptionKeys.value"
            label="Reorder options"
            placeholder="Search"
            :items="reorderOptionsListToShow"
            :return-object="false"
            item-value="option_key"
            item-text="name"
            multiple
            autocomplete
            chips
            click-first-filters-selected
            show-selected-first
            clearable
            small-chips
            deletable-chips
            hide-details
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @click="handleReorderOptionsClick"
            @change="handleReorderOptionsBlur"
          >
            <template v-slot:append>
              <v-menu
                top
                open-on-hover
                offset-x
                min-width="300"
                max-width="500"
                content-class="option-info-menu"
                :open-delay="200"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-2 icon-has-action c-pointer"
                    v-bind="attrs"
                    v-on="on"
                  >info</v-icon>
                </template>
                <v-card
                  class="py-2 px-4 p-relative">
                  <b>Reorder options:</b><br> Selects one or multiple options, used for calculating potential and current stock positions.
                </v-card>
              </v-menu>
            </template>
          </collapsible-select>
        </v-col>

        <v-col cols="6" class="py-1">
          <v-row>
            <v-col>
              <v-text-field
                v-model="inputForm.buyPrice.value"

                :value="inputForm.buyPrice.value"
                @input="inputForm.buyPrice.value = parseFloat($event.toString().replace(',', '.') || 0)"
                :error-messages="inputForm.buyPrice.error"
                label="Buy price"
                hide-details="auto"
                @change="handleInputFormChange"
              >
                <template v-slot:append>
                  <v-icon @click="handleMarginIconClick">
                    calculate
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>

            <v-col>
              <v-autocomplete
                v-model="inputForm.currency.value"
                :error-messages="inputForm.currency.error"
                :items="currencies"
                item-value="iso_code"
                item-text="iso_code"
                label="Currency"
                hide-details
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @change="handleInputFormChange" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import * as _ from 'lodash'
import * as shopApi from '@/api/shop'
import { findOptionsByFiltersTSQL } from '@/api/options'
import { getDrops } from '@/api/shop'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import WeekSelector from '../WeekSelector'

export default {
  components: { WeekSelector, CollapsibleSelect },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    storeSets: {
      type: Array,
      default: () => [],
    },
    suppliers: {
      type: Array,
      default: () => [],
    },
    currencies: {
      type: Array,
      default: () => [],
    },
    payments: {
      type: Array,
      default: () => [],
    },
    schedules: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      dropsList: [],
      saveShopItemDelayed: null,
      loading: false,
      reorderOptionsLoaded: false,
      reorderOptionsList: [],
      showOnlyCurrentOptions: false,
      showOnlyCurrentStores: false,
      inputForm: {
        dropKey: {
          value: '',
          error: null,
        },
        weekMin: {
          value: '',
          error: null,
        },
        weekMax: {
          value: '',
          error: null,
        },
        reorderOptionKeys: {
          value: [],
          error: null,
        },
        supplierKey: {
          value: '',
          error: null,
        },
        currency: {
          value: '',
          error: null,
        },
        paymentKey: {
          value: '',
          error: null,
        },
        scheduleKey: {
          value: '',
          error: null,
        },
        buyPrice: {
          value: '',
          error: null,
        },
        storeSets: {
          value: [],
          error: null,
        },
      },
    }
  },
  computed: {
    reorderOptionsListToShow () {
      return this.showOnlyCurrentOptions
        ? this.reorderOptionsList.filter(
          o => this.item.reorder_option_keys.includes(o.option_key)
        ) : this.reorderOptionsList
    },
    filteredStoreSets () {
      return this.storeSets.filter(ss => ss.label.includes('all'))
    },
    storeSetsListToShow () {
      return this.showOnlyCurrentStores
        ? this.filteredStoreSets.filter(ss => this.item.stores_set_keys.includes(ss.stores_set_key))
        : this.filteredStoreSets
    },
    storeKeyToObjectMap () {
      return this.storeSetsListToShow.reduce((acc, ss) => {
        acc[ss.stores_set_key] = ss
        return acc
      }, {})
    },
  },
  watch: {
    item: {
      handler (val) {
        this.inputForm.weekMin.value = val.week_min ? val.week_min.toString() : null
        this.inputForm.weekMax.value = val.week_max ? val.week_max.toString() : null
        this.inputForm.reorderOptionKeys.value = this.item.reorder_option_keys
        this.inputForm.supplierKey.value = this.item.supplier_key
        this.inputForm.currency.value = this.item.currency
        this.inputForm.paymentKey.value = this.item.payment_key
        this.inputForm.scheduleKey.value = this.item.schedule_key
        this.inputForm.buyPrice.value = _.round(this.item.buy_price, 2)
        this.inputForm.storeSets.value = this.item.stores_set_keys.map(this.getStoreSet)
        this.inputForm.dropKey.value = this.item.drop_key
        this.validateShopItem()
      },
      immediate: true
    },
    storeKeyToObjectMap: {
      handler () {
        this.inputForm.storeSets.value = this.item.stores_set_keys.map(this.getStoreSet)
        this.inputForm = { ...this.inputForm }
      },
      deep: true,
    },
  },
  methods: {
    getStoreSet (key) {
      const obj = this.storeKeyToObjectMap[key]
      return obj || { stores_set_key: key, set_name: key }
    },
    getWeek (date) {
      const currentDate = new Date(date)
      const oneJan = new Date(currentDate.getFullYear(), 0, 1)
      const numberOfDays = Math.floor((currentDate - oneJan) / (24 * 60 * 60 * 1000))
      const weekNum = '0' + Math.floor((currentDate.getDay() + 1 + numberOfDays) / 7)
      return currentDate.getFullYear() + '' + weekNum.substr(-2)
    },
    handleSupplierChange (value) {
      this.handleInputFormChange()
      this.newMoq = value.moq
    },
    saveShopItem (eventName) {
      const payload = {
        ...this.item,
        reorder_option_keys: this.inputForm.reorderOptionKeys.value,
        supplier_key: this.inputForm.supplierKey.value,
        currency: this.inputForm.currency.value,
        payment_key: this.inputForm.paymentKey.value,
        schedule_key: this.inputForm.scheduleKey.value,
        stores_set_keys: this.inputForm.storeSets.value.map(ss => ss.stores_set_key),
        drop_key: this.inputForm.dropKey.value,
        buy_price: _.round(this.inputForm.buyPrice.value, 2),
        week_min: this.inputForm.weekMin.value,
        week_max: this.inputForm.weekMax.value,
      }
      if (this.newMoq !== undefined) {
        payload.moq = this.newMoq.moq
      }

      this.loading = true

      return shopApi
        .saveShopItem(payload)
        .then((shop_item) => {
          this.$emit(eventName, shop_item)
          this.$emit('orders-update', shop_item)
        })
        .finally(() => {
          this.loading = false
        })
    },
    clearInputFormErrors () {
      for (const key in this.inputForm) {
        this.inputForm[key].error = null
      }
    },
    inputFormHasErrors () {
      let hasErrors = false

      for (const key in this.inputForm) {
        if (this.inputForm[key].error) {
          hasErrors = true
        }
      }

      return hasErrors
    },
    validateShopItem () {
      const keys = ['supplierKey', 'buyPrice']

      this.clearInputFormErrors()

      keys.forEach(key => {
        if (this.inputForm[key].value === '' || this.inputForm[key].value === null) {
          this.inputForm[key].error = 'Required'
        }
      })

      if (!this.inputForm.storeSets.value.length) {
        this.inputForm.storeSets.error = 'At least one required'
      }

      if (this.inputForm.dropKey.value === '') {
        this.inputForm.dropKey.error = 'Required'
      }

      return !this.inputFormHasErrors()
    },
    async loadReorderOptions () {
      const payload = {
        search_text: null,
        discount_min: null,
        discount_max: null,
        stock_active: null,
        osp_min: null,
        osp_max: null,
        asp_min: null,
        asp_max: null,
        stock_min: null,
        stock_max: null,
        inbounds_min: null,
        inbounds_max: null,
        option_keys_include: [],
        option_keys_exclude: [],
        properties_include: {},
        properties_exclude: {},
        filters: [],
      }
      return findOptionsByFiltersTSQL(payload, ['option_key', 'name'])
        .then(response => {
          this.reorderOptionsList = response.options
          this.reorderOptionsLoaded = true
        })
    },
    handleInputFormChange () {
      if (this.validateShopItem()) {
        this.saveShopItemDelayed('shop-item-saved')
      }
    },
    handleReorderOptionsClick () {
      if (!this.reorderOptionsLoaded) {
        this
          .loadReorderOptions()
          .then(() => {
            this.reorderOptionsLoaded = true
          })
      }
    },
    handleFirstReorderOptionClick () {
      this.handleReorderOptionsClick()
      this.showOnlyCurrentOptions = true
    },
    handleReorderOptionsBlur () {
      if (this.validateShopItem()) {
        this.saveShopItemDelayed('reorder-options-changed')
      }

      setTimeout(() => {
        this.showOnlyCurrentOptions = false
      }, 100)
    },
    handleFirstStoreSetsClick () {
      this.showOnlyCurrentStores = true
    },
    handleStoreSetsBlur () {
      if (this.validateShopItem()) {
        this.saveShopItemDelayed('shop-item-saved')
      }

      setTimeout(() => {
        this.showOnlyCurrentStores = false
      }, 100)
    },

    handleWeeksChange () {
      if (this.validateShopItem()) {
        this.saveShopItemDelayed('weeks-changed')
      }
    },
    async handleMarginIconClick () {
      await this.saveShopItem('shop-item-saved')
      this.$emit('margin-calculator-open')
    },
    loadDropList () {
      const budget_key = this.$route.query.budget_key
      getDrops({ budget_key }).then(drops => {
        this.dropsList = drops
      })
    },
  },
  created () {
    this.loadDropList()
    this.saveShopItemDelayed = _.debounce(this.saveShopItem, 2000)
    setTimeout(this.loadReorderOptions, 3000)
  }
}
</script>
