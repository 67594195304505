import axios from '@/services/axios.js'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

let logicApiVersion = localStorage.getItem('logicApiVersion')

export function getScenarios (budget_key) {
  const url = baseURLs.manageAPI + `/scenarios?budget_key=${budget_key}`

  return axios.get(url)
    .then(response => response.data.scenarios)
    .catch(error => console.log(error))
}

export function updateOrderForm (payload) {
  const url = baseURLs.manageAPI + '/shop_orders_update'
  return axios.post(url, payload)
    .then(response => response.data.data)
    .catch(error => console.log(error))
}

export function saveSupplier (supplier) {
  const url = baseURLs.manageAPI + '/supplier'
  return axios.post(url, supplier)
    .then(response => response.data.suppliers)
    .catch(error => console.log(error))
}

export function getDrops (payload) {
  const url = baseURLs.manageAPI + '/drops'

  return axios.post(url, payload)
    .then(response => response.data.drops)
    .catch(error => console.log(error))
}

export function updateDrop (payload) {
  const url = baseURLs.manageAPI + '/drop'

  return axios.put(url, payload)
    .then(response => response.data.drops)
    .catch(error => console.log(error))
}

export function addDrop (payload) {
  const url = baseURLs.manageAPI + '/drop'

  return axios.post(url, payload)
    .then(response => response.data.drops)
    .catch(error => console.log(error))
}
export function deleteDrop (data) {
  const url = baseURLs.manageAPI + '/drop'

  return axios.delete(url, { headers: getHeadersWithAccessToken(), data })
    .then(response => response.data.drops)
    .catch(error => console.log(error))
}

export function getShopItems (payload) {
  const url = baseURLs.manageAPI + '/shop_items'

  return axios.post(
    url,
    payload,
  )
    .then(response => response.data.shop_items)
    .catch(error => console.log(error))
}

export function getShopItem ({ shop_item_key, budget_key, scenario_key }) {
  const url = baseURLs.manageAPI + `/shop_item?budget_key=${budget_key}&scenario_key=${scenario_key}&shop_item_key=${shop_item_key}`

  return axios.get(url)
    .then(response => response.data.shop_item)
    .catch(error => console.log(error))
}

export function saveShopItems (payload) {
  const url = baseURLs.manageAPI + '/shop_item'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function onItemsChange (payload) {
  const url = baseURLs.manageAPI + '/shop_items'

  return axios.put(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}
export function onOrdersChange (payload) {
  const url = baseURLs.manageAPI + '/shop_orders'

  return axios.put(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function deleteShopItem (payload) {
  const url = baseURLs.manageAPI + '/shop_item'

  return axios.delete(url, {
    data: payload,
    headers: getHeadersWithAccessToken(),
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function saveShopItem (payload) {
  const url = baseURLs.manageAPI + '/shop_item'

  return axios.put(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.shop_item)
    .catch(error => console.log(error))
}

export function getBudgetsData (payload) {
  payload.ignore_cache = true
  const url = baseURLs.manageAPI + '/data/buy/shop/get_shop_items_in_budget_part'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function getFullPageData (payload) {
  payload.ignore_cache = true
  const url = baseURLs.manageAPI + '/data/buy/shop/get_shop_items_in_budget'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}
export function getShopGridData (payload) {
  let url = baseURLs.manageAPI + `/shop_item_grid?budget_key=${payload.budget_key}&scenario_key=${payload.scenario_key}`
  if (payload.week_min) {
    url += `&week_min=${payload.week_min}`
  }
  if (payload.week_max) {
    url += `&week_max=${payload.week_max}`
  }
  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function getInboundsOption (option_key = '') {
  const url = baseURLs.manageAPI + '/data/buy/shop/get_inbounds_option?option_key=' + option_key

  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.data)
    .catch(error => console.log(error))
}

export function getAllowedOptions (budget_key) {
  const url = baseURLs.manageAPI + '/data/buy/shop/get_options_allowed?budget_key=' + budget_key

  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.data)
    .catch(console.error)
}

export function copyExistingInbound (payload) {
  const url = baseURLs.manageAPI + '/existing_inbound_to_order'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function getBeliefsCommitData (payload) {
  const url = baseURLs.logicAPI + '/shop_beliefs'
  if (!logicApiVersion) {
    logicApiVersion = localStorage.getItem('logicApiVersion')
  }

  return axios.post(url, {
    ...payload,
    version: logicApiVersion,
    save: false,
  }, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function getShopLookalikesData (payload, search = '') {
  const url = baseURLs.logicAPI + '/shop_lookalikes?search_text=' + search
  if (!logicApiVersion) {
    logicApiVersion = localStorage.getItem('logicApiVersion')
  }
  return axios.post(url, {
    ...payload,
    version: logicApiVersion,
    save: false,
    search_text: search
  }, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function saveCommitments (payload) {
  const url = baseURLs.logicAPI + '/shop_beliefs'
  if (!logicApiVersion) {
    logicApiVersion = localStorage.getItem('logicApiVersion')
  }
  return axios.post(url, {
    ...payload,
    version: logicApiVersion,
    save: true,
  }, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.data)
    .catch(error => console.log(error))
}

export function getCommitments (payload) {
  const url = baseURLs.logicAPI + '/shop_commitments'
  if (!logicApiVersion) {
    logicApiVersion = localStorage.getItem('logicApiVersion')
  }
  return axios.post(url, {
    ...payload,
    version: logicApiVersion,
  }, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function deleteCommitments (payload) {
  const url = baseURLs.logicAPI + '/shop_commitments'
  if (!logicApiVersion) {
    logicApiVersion = localStorage.getItem('logicApiVersion')
  }
  return axios.delete(url, {
    data: {
      ...payload,
      version: logicApiVersion,
    },
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function getBudgetProgress (payload) {
  const url = baseURLs.manageAPI + '/budget_progress'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.progress[0])
    .catch(error => console.log(error))
}

export function getShopOrders (budget_key, scenario_key, week_min, week_max) {
  let url = baseURLs.manageAPI + `/shop_orders?budget_key=${budget_key}&scenario_key=${scenario_key}`

  if (week_min) {
    url += `&week_min=${week_min}`
  }

  if (week_max) {
    url += `&week_max=${week_max}`
  }

  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => {
      if (!response?.data) {
        console.error('No data in shop orders response')
        return {}
      }
      return {
        orders: response.data.orders,
        orders_total: response.data.orders_total,
        properties: response.data.properties,
        order_properties: response.data.order_properties
      }
    }).catch(error => console.log(error))
}

export function getShopItemOrders (budget_key, scenario_key, shop_item_key) {
  const url = baseURLs.manageAPI + `/shop_orders?budget_key=${budget_key}&scenario_key=${scenario_key}&shop_item_key=${shop_item_key}`

  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function getShopItemToOrder (payload) {
  const url = baseURLs.logicAPI + '/shop_orders'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function loadBudgetKPIs (payload) {
  const url = baseURLs.manageAPI + '/budget_progress'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function optionViewCallOrders (option_key, shop_item_key) {
  const url = baseURLs.manageAPI + '/option_view'
  return axios.post(url, { option_key, module: 'shop_item', shop_item_key })
    .then(response => {
      return response.data
    }).catch()
}

export function getShopItemOrderLinesOptimized (payload) {
  const url = baseURLs.logicAPI + '/shop_order_line'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.order_lines)
    .catch(error => console.log(error))
}

export function getShopItemOrderLines ({ scenario_key, budget_key, shop_item_key, order_key }) {
  const url = baseURLs.manageAPI + `/shop_order_line?scenario_key=${scenario_key}&budget_key=${budget_key}&shop_item_key=${shop_item_key}&order_key=${order_key}`

  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.order_lines)
    .catch(error => console.log(error))
}

export function saveShopItemOrderLines (payload) {
  const url = baseURLs.manageAPI + '/shop_order_line'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function getShopItemOrderInfo ({ scenario_key, budget_key, shop_item_key, order_key }) {
  const url = baseURLs.manageAPI + `/shop_order?scenario_key=${scenario_key}&budget_key=${budget_key}&shop_item_key=${shop_item_key}&order_key=${order_key}`

  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.order[0])
    .catch(error => console.log(error))
}

export function saveShopItemOrderInfo (payload) {
  const url = baseURLs.manageAPI + '/shop_order'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.order)
    .catch(error => console.log(error))
}

export function refreshShopItemOrder (payload) {
  const url = baseURLs.manageAPI + '/shop_orders_update'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function deleteShopItemOrder (payload) {
  const url = baseURLs.manageAPI + '/shop_orders'

  return axios.delete(url, {
    data: payload,
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.order)
    .catch(error => console.log(error))
}

export function getShopItemOrderSchedule ({ scenario_key, budget_key, shop_item_key, order_key }) {
  const url = baseURLs.manageAPI + `/shop_order_schedule?scenario_key=${scenario_key}&budget_key=${budget_key}&shop_item_key=${shop_item_key}&order_key=${order_key}`

  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.order_schedule)
    .catch(error => console.log(error))
}

export function saveShopItemOrderSchedule (payload) {
  const url = baseURLs.manageAPI + '/shop_order_schedule'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function getShopItemOrderCosts ({ scenario_key, budget_key, shop_item_key, order_key }) {
  const url = baseURLs.manageAPI + `/shop_order_cost?scenario_key=${scenario_key}&budget_key=${budget_key}&shop_item_key=${shop_item_key}&order_key=${order_key}`

  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data.data)
    .catch(error => console.log(error))
}

export function saveShopItemOrderCosts (payload) {
  const url = baseURLs.manageAPI + '/shop_order_cost'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => {
      const { costs, costs_grouped } = response.data.data

      return { costs, costs_grouped }
    })
    .catch(error => console.log(error))
}

export function getShopItemScenario ({ scenario_key, budget_key, shop_item_key }) {
  const url = baseURLs.manageAPI + `/shop_item_scenario?budget_key=${budget_key}&scenario_key=${scenario_key}&shop_item_key=${shop_item_key}`

  return axios.get(url, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function saveShopItemScenarios (payload) {
  const url = baseURLs.manageAPI + '/shop_item_scenario'

  return axios.post(url, payload, {
    headers: getHeadersWithAccessToken()
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}
