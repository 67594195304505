<template>
  <div class="shop-item-commitments">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-row>
      <v-col class="text-right px-8">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('tableFilters', columnsToSearch, val)"
        />
        <data-export @item-click="downloadTableData"/>

        <v-icon class="mx-2 action-btn-danger" @click="deleteDialogVisible = true">delete</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="p-relative">
        <data-tables
          style="width: 100%"
          class="mb-4"
          :key="tableKey"
          :data="commitmentsData"
          :page-size="10"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :table-props="tableProps"
          :filters="tableFilters"
          filter-multiple
          @filter-change="filters => handleFilterChange('tableFilters', filters)"
        >
          <el-table-column prop="week" label="Week" sortable="custom" class-name="pl-4">
            <template slot-scope="scope">
              <div :style="{ color: scope.row.week_completed ? '#4CAF50' : '#FF9800' }">
                {{ scope.row.week }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="stores_committed" label="Stores" sortable="custom">
            <template slot-scope="scope">
              <ChipsDropdown
                :items="scope.row.stores_committed"
                :color="scope.row.stores_completed ? '#4CAF50' : '#FF9800'"
              />
            </template>
          </el-table-column>
          <el-table-column prop="sku_names_committed" label="SKUs" sortable="custom">
            <template slot-scope="scope">
              <ChipsDropdown
                :items="scope.row.sku_names_committed"
                :color="scope.row.sku_names_completed ? '#4CAF50' : '#FF9800'"
              />
            </template>
          </el-table-column>
          <el-table-column prop="committed_gross" label="Committed (Gross)" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.committed_gross | formatThousands }}
            </template>
          </el-table-column>
          <el-table-column prop="returns" label="Returns" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.returns | formatThousands }}
            </template>
          </el-table-column>
          <el-table-column prop="committed" label="Committed" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.committed | formatThousands }}
            </template>
          </el-table-column>
          <el-table-column prop="residual" label="Residual" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.residual | formatThousands }}
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>
    <delete-dialog
      content-title="Delete Commitments"
      content-text="This will delete all commitments for this shop item, this action can not be reversed!"
      :visible="deleteDialogVisible"
      @cancel="deleteDialogVisible = false"
      @confirm="handleDelete"
    />
  </div>
</template>

<script>
import { round } from 'lodash'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch'
import ChipsDropdown from '@/components/ChipsDropdown.vue'
import DeleteDialog from '@/components/DeleteDialog'
import DataExport from '@/components/DataExport'
import { downloadData } from '@/utils'

export default {
  components: {
    DataExport,
    DeleteDialog,
    TableSearch,
    ChipsDropdown,
  },
  mixins: [columnFilters],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    commitments: {
      type: Array,
      default: () => [],
    },
    stores: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      deleteDialogVisible: false,
      loading: false,
      commitmentsKey: 0,
      tableKey: 0,
      tableProps: {
        'row-key': row => `${row.week}-${this.commitmentsKey}`,
        'summary-method': this.getSummary,
        'show-summary': true,
      },
      tableFilters: [],
      columnsToSearch: ['week', 'stores_committed', 'sku_names_committed', 'committed', 'residual'],
    }
  },
  computed: {
    storesNames () {
      return this.stores.reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    commitmentsData () {
      return this.commitments.map(c => ({
        ...c,
        week: this.$options.filters.formatWeekNumber(c.week),
        stores_committed: c.stores_committed.map(s => this.storesNames[s])
      }))
    },
    allWeeksCompleted () {
      return this.commitments.every(c => c.week_completed)
    },
  },
  watch: {
    commitments () {
      this.commitmentsKey++
    },
  },
  methods: {
    downloadTableData (sep) {
      downloadData(sep, this.commitmentsData, 'ShopItemCommitments')
    },
    handleDelete () {
      this.$emit('delete')
      this.deleteDialogVisible = false
    },
    /* handleSearchChanged (val) {
            this.tableFilters[0].value = val
            this.tableKey++
        }, */
    getSummary (params) {
      const { columns } = params
      const sums = []

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total'
          return
        }

        //  stores and SKUs column don't have summary
        if ([1, 2].includes(index)) {
          return
        }

        if (index === 3) {
          sums[index] = round(this.commitments.reduce((acc, cur) => acc + cur.committed_gross, 0), 2)
        }
        if (index === 4) {
          sums[index] = round(this.commitments.reduce((acc, cur) => acc + cur.returns, 0), 2)
        }

        if (index === 5) {
          sums[index] = round(this.commitments.reduce((acc, cur) => acc + cur.committed, 0), 2)
        }
        if (index === 6) {
          sums[index] = round(this.commitments.reduce((acc, cur) => acc + cur.residual, 0), 2)
        }
      })

      return sums
    }
  },
}
</script>

<style lang="scss">
.shop-item-commitments {
}
</style>
