<script>
import {defineComponent} from 'vue'
import ts_placeholder from '@/assets/img/ts_placeholder.png'
import ProductsSkus from '@/components/Strategy/ProductsSkus.vue'
import {useCubesStore} from '@/store/pinia/cubesStore'
import ProductsOrders from '@/components/Strategy/ProductsOrders.vue'
const PropKey = 'properties/article/'

export default defineComponent({
  name: 'ProductsEditModal',
  components: {ProductsOrders, ProductsSkus},
  props: {
    product: {
      type: Object,
      default: ()=> ({})
    },
    analyticsProperties: {
      type: Object,
      default: ()=> ({})
    },
    parsedAnalyticsProperties: {
      type: Array,
      default: ()=> []
    },
  },
  data (){
    return {
      activeTab: 0,
      PropKey,
      addOther: false,
      innerProduct: {properties: {}},
      newOther: {},
      cubesStore: useCubesStore(),

    }
  },
  mounted (){
    this.innerProduct = JSON.parse(JSON.stringify(this.product || {}))
  },
  computed: {
    isDisabled (){
      return this.innerProduct.status !== 'generated' && this.innerProduct.status !== 'placeholder'
    },
    productNameParts (){
      return this.innerProduct.product_name?.split('/') || []
    },
    selectedProperties () {
      const props =  JSON.parse(localStorage.getItem('strategyCollectionProductsPropertiesColumns')) || []
      return props.map(item=> item.split(PropKey)[1])
    },
    sortedProductProperties (){
      const subProperties = [
        ...this.selectedProperties,
        ...(this.cubesStore?.cubeInputData?.sub_dimensions || [])
      ]

      const keys = Object.keys(this.innerProduct.properties || {}).filter(key=> this.parsedAnalyticsProperties.map(item=> item.label).includes(key))
      return {
        minor: subProperties,
        major: keys.filter(item=> !subProperties.includes(item))
      }
    },
  },
  methods: {
    updateSku (product){
      this.innerProduct = {...product}
    },
    updateOrders (){
      this.innerProduct = {...product}
    },
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    saveOther (){
      this.innerProduct.properties[this.newOther.property_name.split(PropKey)[1]] = this.newOther.value
      this.addOther = false
      this.innerProduct = {...this.innerProduct}
      this.newOther = {}
    },
    getAvailableProps (){
      return this.parsedAnalyticsProperties.filter(item=> !this.sortedProductProperties.major.includes(item.label))
    },
  }
})
</script>

<template>
  <v-card class="pa-0" style="max-height: 90vh">
    <v-card-title class="pb-0">
      <h5>Product</h5>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-row class="ma-0 pa-3" >
        <v-col cols="6">
          <div class="status-row">
            <span :class="innerProduct.statusColorClass">
              {{innerProduct.status}}
            </span>
          </div>

          <div class="image-row">
            <img
              height="184px"
              class="mt-2"
              @error="loadPlaceholder"
              :src="innerProduct.image_url || '@/assets/img/ts_placeholder.png'"
              alt=""/>
          </div>
        </v-col>

        <v-col cols="6">
          <v-text-field label="Product Key" :value="innerProduct.product_key" disabled >

          </v-text-field>
          <v-text-field
            :disabled="isDisabled"
            label="Variation name"
            :value="productNameParts[0]"
            @input="innerProduct.product_name = `${$event}/${productNameParts[1]}`"
          >

          </v-text-field>
          <v-text-field
            @input="innerProduct.product_name = `${productNameParts[0]}/${$event}`"
            :disabled="isDisabled"
            label="Option name" :value="productNameParts[1]">

          </v-text-field>
        </v-col>
      </v-row>
      <v-tabs v-model="activeTab" grow>
        <v-tabs-slider />
        <v-tab key="properties" class="px-8"
          style="width: 100px">Properties</v-tab>
        <v-tab key="skus" class="px-8"
          style="width: 100px">Skus</v-tab>
        <v-tab key="orders" class="px-8"
          disabled
          style="width: 100px">Orders</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item :transition="false" :reverse-transition="false" key="properties" class="pa-1">
          <v-row class="ma-0">
            <v-col cols="6">
              <v-row
                class="ma-0 align-center pt-6 flex-nowrap"
                v-for="(key) of sortedProductProperties.minor"
                :key="key">
                <v-autocomplete
                  class="pr-4 pt-1"
                  :label="key | formatString"
                  :disabled="isDisabled"
                  v-model="innerProduct.properties[key]"
                  :menu-props="{
                    left: true
                  }"
                  clearable
                  hide-details
                  :items="analyticsProperties[`${PropKey}${key}`]"
                ></v-autocomplete>
                <v-icon
                  class="action-btn-danger"
                  :disabled="isDisabled"
                  @click="delete innerProduct.properties[key]; innerProduct = {...innerProduct}"
                >delete</v-icon>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row
                class="ma-0 align-center pt-6 flex-nowrap"
                v-for="(key) of sortedProductProperties.major"
                :key="key">
                <v-autocomplete
                  class="pr-4 pt-1"
                  :label="key | formatString"
                  :disabled="isDisabled"
                  v-model="innerProduct.properties[key]"
                  :menu-props="{
                    left: true
                  }"
                  clearable
                  hide-details
                  :items="analyticsProperties[`${PropKey}${key}`]"
                ></v-autocomplete>
                <v-icon
                  class="action-btn-danger"
                  :disabled="isDisabled"
                  @click="delete innerProduct.properties[key]; innerProduct = {...innerProduct}"
                >delete</v-icon>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="mt-0" v-if="!addOther">
                <v-col cols="7">
                  <v-icon
                    :disabled="isDisabled"
                    class="plus-icon" @click="addOther = true">add</v-icon>
                </v-col>
              </v-row>
              <v-row v-else class="pb-2 pr-10">
                <v-col :cols="6" class="py-0">
                  <v-autocomplete
                    v-model="newOther.property_name"
                    :disabled="isDisabled"
                    :items="getAvailableProps()"
                    item-text="label"
                    item-value="value"
                    label="Property"
                    hide-details="auto"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"/>
                </v-col>
                <v-col :cols="6" class="py-0">
                  <v-autocomplete
                    v-if="newOther.property_name"
                    v-model="newOther.value"
                    :items="analyticsProperties[newOther.property_name]"
                    :disabled="isDisabled"
                    label="Value"
                    hide-details
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                    @change="saveOther" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false" key="skus">
          <ProductsSkus
            :product="innerProduct"
            :is-disabled="isDisabled"
            :skus="innerProduct.sku_names"
            @update-product="updateSku"
          ></ProductsSkus>
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false" key="orders">
          <ProductsOrders
            :product="innerProduct"
            :is-disabled="isDisabled"
            @update-product="updateOrders"
            @chunk-created="$emit('chunk-created', $event)"
            @chunk-deleted="$emit('chunk-deleted', $event)"
          ></ProductsOrders>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions>
      <v-row class="ma-0 justify-end text-right py-2 px-4">
        <v-btn
          color="primary"
          depressed
          text
          @click="$emit('close')"
        >Cancel</v-btn>
        <v-btn
          color="primary"
          depressed
          outlined
          @click="$emit('save', innerProduct)"
          class="mx-4"
        >Save</v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss">

</style>
