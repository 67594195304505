<template>
  <div v-if="shopParameters.budget_key && shopParameters.scenario_key" class="isDialogView">
    <v-card-actions class="justify-end pb-4 close-dialog-view">
      <v-icon
        depressed
        outlined
        size="24"
        class="my-2"
        @click="closeViewModal"
      >close</v-icon>
    </v-card-actions>
    <v-row class="ma-2 mb-0 justify-center p-relative">
      <v-btn-toggle
        :value="chartIndex || 0"
        @change="$emit('chart-index-changed', $event)"
        dense
        active-class="bg-gray"
      >
        <v-btn
          v-for="(type, index) in types"
          :key="index"
          small
        >
          <span class="text-capitalize">{{ formatString(type.name) }}</span>
        </v-btn>
      </v-btn-toggle>
      <v-col class="d-flex pa-0 p-absolute right"  style="right: 0" cols="4">
        <WeekSelector
          v-model="kpiFilters.week_min"
          @input="$emit('filters-changed', {week_max: kpiFilters.week_max, week_min: $event}); loadItems()"
          :higher-limit="kpiFilters.week_max"
          class="mr-2 pt-0 mt-0 "
          label="Week min"
          hide-details
          clearable
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"                        >
        </WeekSelector>
        <WeekSelector
          v-model="kpiFilters.week_max"
          @input="$emit('filters-changed', {week_min: kpiFilters.week_min, week_max: $event}); loadItems()"
          clearable
          :lower-limit="kpiFilters.week_min"
          class="ml-2 pt-0 mt-0 "
          label="Week max"
          hide-details
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"                        >

        </WeekSelector>
      </v-col>
    </v-row>
    <BudgetProgressItem
      v-if="showBudgetProgress"
      :isPercentage="false"
      :budgetProgressType="types[chartIndex || 0].value"
      :progressData="budgetProgress"
      class="px-3"
      show-fractions
    >
      <template v-slot:total-caption>
        <div
          class="total mt-2"
        >
          <template v-if="budgetProgress.total !== undefined">
            Budget: {{!isPieces ? priceFormatter(budgetProgress.total) : budgetProgress.total }}
          </template>
          <span v-if="budgetProgress.over > 0">
            &nbsp;(Open: {{!isPieces ? priceFormatter(budgetProgress.over) : budgetProgress.over}})
          </span>
          <span v-else-if="budgetProgress.over < 0" class="exceed">
            &nbsp;(Over: {{!isPieces ? priceFormatter(-1*budgetProgress.over) : -1*budgetProgress.over}})
          </span>
        </div>
      </template>
    </BudgetProgressItem>
    <BudgetProgressLegend
      v-if="showBudgetProgress"
      :items="budgetProgressLegendItems"
    />
    <svg id="shopDropsChart" width="100%" class="pt-3" v-show="shopItemsForGant?.length"></svg>
    <v-dialog
      v-model="itemInfoDialog"
      v-if="itemInfoDialog"
      scrollable
      content-class="align-self-start mt-12"
      width="auto"
      max-width="90%">
      <v-card class="p-relative task-infos px-4 pt-6">
        <v-col>
          <v-row>
            <v-col class="py-0">
              <router-link :to="getShopPageUrl(selectedTask)" class="black--text text-decoration-none" target="_blank">
                <v-row class="mb-0 pb-3">
                  <v-col class=" pb-0 font-weight-bold">{{selectedTask.name.split(' / ')[0]}}</v-col>
                </v-row>
              </router-link>
              <v-row class="mx-0" style="font-size: 12px; color: rgba(0,0,0,0.6)">{{selectedTask.name.split(' / ')[1]}}
              </v-row>
            </v-col>
            <v-col class="py-0" cols="auto">
              <v-row class="ma-0">
                <v-menu
                  top
                  open-on-hover
                  offset-x
                  min-width="250"
                  max-width="250"
                  :open-delay="200"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-2 c-pointer" v-bind="attrs" v-on="on">info</v-icon>
                  </template>
                  <v-card class="py-1 px-4">
                    <div
                      v-for="(info, index) in dummyInfo.properties"
                      :key="index"
                      class="mb-1 d-flex align-center"
                    >
                      <b>{{ index | formatString }}: </b><br />
                      <span class="text-body-2 ml-1"> {{info}}</span>
                    </div>
                  </v-card>
                </v-menu>
                <v-icon class="mr-2" @click="onCalculatorClick">
                  calculate
                </v-icon>

                <router-link :to="getShopPageUrl(selectedTask)" class="black--text text-decoration-none" target="_blank">
                  <v-icon :to="getShopPageUrl(selectedTask)">
                    edit
                  </v-icon>
                </router-link>

              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-4 pt-4">
            <v-col cols="4" class="py-0">
              Supplier:
            </v-col>
            <v-col class="py-0">
              {{getSupplier(selectedTask.supplier_key)}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-0">
              Schedule:
            </v-col>
            <v-col class="py-0">
              {{getSchedule(selectedTask.schedule_key)}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-0">
              Payment:
            </v-col>
            <v-col class="py-0">
              {{getPayment(selectedTask.payment_key)}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-0">
              Retail Price:
            </v-col>
            <v-col class="py-0">
              {{formatPrice(selectedTask.retail_price)}}
            </v-col>
          </v-row>
          <v-row>
            <v-col  cols="4" class="py-0">
              Buy Price:
            </v-col>
            <v-col class="py-0">
              {{formatPrice(selectedTask.cost_price, true)}}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pr-8">
              <div>&ensp;</div>
              <div>Value:</div>
              <div>Pieces:</div>
              <div>Costs:</div>
            </v-col>
            <v-col class="pr-8">
              <span class="text-no-wrap">To order</span>
              <div>
                {{formatPrice(selectedTask.to_order_revenue)}}
              </div>
              <div>
                {{selectedTask.to_order_pieces}}
              </div>
              <div>
                {{formatPrice(selectedTask.to_order_cost)}}
              </div>

            </v-col>
            <v-col class="pr-8">
              <span class="text-no-wrap">In progress</span>
              <div>
                {{formatPrice(selectedTask.in_progress_revenue)}}
              </div>
              <div>
                {{selectedTask.in_progress_pieces}}
              </div>
              <div>
                {{formatPrice(selectedTask.in_progress_cost)}}
              </div>
            </v-col>
            <v-col>
              Ordered
              <div>
                {{formatPrice(selectedTask.ordered_revenue)}}
              </div>
              <div>
                {{selectedTask.ordered_pieces}}
              </div>
              <div>
                {{formatPrice(selectedTask.ordered_cost)}}
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="marginCalculatorDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-12"
      width="90%"
      max-width="90%">
      <v-card class="p-relative">
        <v-card-title v-if="selectedTask" class="text-body-1 font-weight-medium">
          Margin calculator
          <v-icon class="mx-2">arrow_forward</v-icon>
          {{ selectedTask.name }}
        </v-card-title>
        <v-divider/>
        <MarginCalculator
          v-if="selectedTask"
          ref="marginCalculator"
          :item="selectedTask"
          :shop-item-key="selectedTask.shop_item_key"
          :supplier-name="selectedTask.supplier_name"
        />
        <v-divider></v-divider>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="marginCalculatorDialogVisible = false"
              >Close</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>
import { formatString } from '@/variables'
import { getFullPageData, getShopItem, saveShopItem } from '@/api/shop'
import _ from 'lodash'
import Gantt from '../libs/gantt'
import { loadDummy } from '@/api/dummies'
import MarginCalculator from '../components/Shop/MarginCalculator'
import { format } from 'date-fns'
import BudgetProgressItem from '@/components/Shop/BudgetProgressItem'
import BudgetProgressLegend from '@/components/Shop/BudgetProgressLegend'
import WeekSelector from '@/components/WeekSelector'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'ShopItemFullPage',
  components: { WeekSelector, BudgetProgressLegend, BudgetProgressItem, MarginCalculator },
  props: ['shopParameters', 'suppliers', 'schedules', 'data', 'chartIndex', 'kpiFilters'],
  data () {
    this.chart = null

    return {
      shopItemsForGant: null,
      marginCalculatorDialogVisible: false,
      params: null,
      currency: localStorage.getItem('defaultCurrency'),
      selectedTask: null,
      dummyInfo: {},
      itemInfoDialog: false,

      budgetProgressLegendItems: [{
        caption: 'Ordered',
        color: '#4CAF50',
      }, {
        caption: 'In progress',
        color: '#007AFF',
      }, {
        caption: 'To order',
        color: '#FFD202',
      }, {
        caption: 'Open',
        color: '#E3E3E3',
      }],
      types: [{ value: 'revenue', name: 'value' }, { value: 'pieces', name: 'pieces' }, { value: 'cost', name: 'costs' }],
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    payments () {
      return (this.appConfig?.payments || [])
    },
    isPieces () {
      return this.types[this.chartIndex || 0]?.value === 'pieces'
    },
    priceFormatter () {
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.$auth.getUserCurrency().currency,
        maximumFractionDigits: 0,
      })
      return formatter.format
    },
    budgetProgress () {
      const data = this.data.kpi[0]
      if (!data) {
        return {}
      }
      const prop = this.types[this.chartIndex || 0].value
      const segments = []
      this.budgetProgressLegendItems.forEach((item) => {
        const key = item.caption.toLowerCase().replaceAll(' ', '_')
        let dataProp = data[`${key}_${prop}`]
        if (key === 'open' && dataProp < 0) {
          dataProp = 0
        }
        segments.push({
          value: dataProp,
          color: item.color,
          caption: item.caption,
        })
      })

      return {
        segments,
        total: parseFloat(data[`intake_${prop}`]),
        over: data[`open_${prop}`],
        Budget: parseFloat(data[`intake_${prop}`]),
      }
    },

    showBudgetProgress () {
      return !!this.budgetProgress?.Budget
    },
  },
  methods: {
    loadItems () {
      this.params = JSON.parse(localStorage.getItem('buy_home_params')) || {}
      getFullPageData(
        {
          budget_key: this.shopParameters.budget_key,
          scenario_key: this.shopParameters.scenario_key,
          week_min: parseInt(this.kpiFilters?.week_min) || null,
          week_max: parseInt(this.kpiFilters?.week_max) || null,
        }).then(({ data }) => {
        const progresses = ['ordered', 'in_progress', 'to_order']
        data = data.map(item => {
          const additional = {}
          progresses.forEach(progress => {
            this.types.forEach((type) => {
              const key = `${progress}_${type.value}`
              if (type.value === 'pieces') {
                additional[key + '_ocp'] = this.$options.filters.formatThousands(item[key])
                return
              }
              additional[key + '_ocp'] = (new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: this.currency,
                minimumFractionDigits: 0,
              })).format(item[key])
            })
          })
          return {
            ...item,
            ...additional
          }
        })
        this.shopItemsForGant = data
      })
    },
    onCalculatorClick () {
      this.marginCalculatorDialogVisible = true
      this.itemInfoDialog = false
      this.$nextTick(() => {
        this.$refs['marginCalculator'].loadData(this.selectedTask)
      })
    },
    getSupplier (supplier_key) {
      return this.suppliers.find(item => item.supplier_key === supplier_key)?.supplier_name
    },
    getSchedule (schedule_key) {
      return this.schedules.find(item => item.schedule_key === schedule_key)?.schedule_name
    },
    getPayment (payment_key) {
      return this.payments.find(item => item.payment_key === payment_key)?.payment_name
    },
    setupChart () {
      if (!this.shopItemsForGant || !this.shopParameters.budget_key ||
        !this.shopParameters.scenario_key || !this.shopItemsForGant?.length) {
        return
      }
      const options = {
        bar_height: 80,
        view_mode: 'Week',
        on_drop_click: this.handleDropClick,
        on_date_change: _.debounce(this.handleDropDateChanged, 200),
      }
      this.chart = new Gantt('#shopDropsChart', this.shopItemsForGant.map(this.parseItemForGraph), options)
    },
    handleDropDateChanged (item, start, end) {
      const week_min = parseInt(this.getWeek(start))
      const week_max = parseInt(this.getWeek(end))
      getShopItem(item).then((shop_item) => {
        shop_item.week_min = week_min
        shop_item.week_max = week_max
        saveShopItem(shop_item)
      })
    },
    getWeek (date) {
      const currentDate = new Date(date)
      const oneJan = new Date(currentDate.getFullYear(), 0, 1)
      const numberOfDays = Math.floor((currentDate - oneJan) / (24 * 60 * 60 * 1000))
      const weekNum = '0' + Math.floor((currentDate.getDay() + 1 + numberOfDays) / 7)
      return currentDate.getFullYear() + '' + weekNum.substr(-2)
    },
    formatPrice (price, task_currency = false) {
      return (new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: task_currency ? this.selectedTask.currency : this.currency,
        minimumFractionDigits: 2,
      })).format(price)
    },
    getShopPageUrl (shopItem) {
      return {
        name: 'buy-shop-item-page',
        query: {
          shop_item_key: shopItem.shop_item_key,
          budget_key: shopItem.budget_key,
          scenario_key: shopItem.scenario_key,
        },
      }
    },
    closeViewModal () {
      this.$emit('modal-closed')
    },
    formatString,
    handleDropClick (task) {
      this.selectedTask = task
      this.itemInfoDialog = true
      loadDummy(task.dummy_key).then(response => {
        this.dummyInfo = response.dummy
      })
    },
    parseWeekForGraph (week) {
      const days = (week + '').substr(4) * 7
      const years = parseInt((week + '').substr(0, 4)) || (new Date()).getFullYear()
      const date = new Date(years, 0, days)
      return date.toISOString().substr(0, 10)
    },
    parseItemForGraph (item, _index) {
      const cantChange = !item.week_min || !item.week_max
      if (!item.week_min) {
        const newDate = format(new Date(), 'yyyyww')
        item.week_min = parseInt(newDate) + 1
      }
      return {
        _index,
        id: item.shop_item_key + '',
        name: item.name,
        start: this.parseWeekForGraph(item.week_min),
        end: this.parseWeekForGraph(item.week_max || item.week_min + 52),
        progress: 100,
        type: this.types[this.chartIndex || 0].value,
        ...item,
        isShopItem: true,
        cantChange
      }
    }
  },
  watch: {
    shopItemsForGant: {
      handler () {
        setTimeout(() => {
          this.setupChart()
        }, 200)
      },
      immediate: true
    },
    chartIndex: {
      handler () {
        this.setupChart()
      },
    },
  },

  mounted () {
    if (this.shopItemsForGant) {
      return
    }
    this.loadItems()
  },
}
</script>

<style scoped>
.task-infos {
  font-size: 13px;
}
</style>
