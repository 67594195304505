<template>
  <v-card>
    <v-tabs v-model="activeTab">
      <v-tabs-slider />
      <v-tab>Metrics</v-tab>
      <v-tab>Settings</v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab" class="mt-2 pb-6 px-6">
      <v-tab-item :transition="false" :reverse-transition="false" eager>
        <v-checkbox
          v-for="item of metricsList"
          v-model="selectedMetrics"
          :key="item.value"
          :label="item.text"
          :value="item.value"
          :ripple="false"
          return-object
          hide-details
          class="mt-0 pt-0"
          @change="handleSelectedMetricsChange"
        />
      </v-tab-item>
      <v-tab-item>
        <h4 class="mt-5 mb-2">Order products by</h4>
        <v-select
          v-model="selectedOrder"
          :items="orderByOptions"
          class="text-body-2 pt-0 mt-n2"
          hide-details
          @change="handleSelectedOrderChange"
        />
        <h4 class="mt-6 mb-2">Total columns</h4>
        <v-checkbox
          v-for="item of sumOptions"
          v-model="selectedSum"
          :key="item.value"
          :label="item.text + (selectedSum.includes(item.value) ? ` (${selectedSum.indexOf(item.value) + 1})` : '')"
          :value="item.value"
          :ripple="false"
          return-object
          hide-details
          class="mt-0 pt-0"
          @change="handleSelectedTotalsChange"
        />

        <h4 class="mt-6 mb-2">Show total as</h4>
        <v-checkbox
          v-model="selectedShowTotalAs"
          label="Value"
          value="value"
          hide-details
          class="mt-0 pt-0"
          @change="$emit('selectedShowTotalAs:changed', selectedShowTotalAs)"
        />
        <v-checkbox
          v-model="selectedShowTotalAs"
          label="Share"
          value="share"
          hide-details
          class="mt-0 pt-0"
          @change="$emit('selectedShowTotalAs:changed', selectedShowTotalAs)"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
export default {
  name: 'Settings',
  components: {},
  props: {
    metricsListProp: {
      type: Array,
      default: () => [],
    },
    selectedMetricsProp: {
      type: Array,
      default: () => [],
    },
    selectedShowTotalAsProp: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    this.orderByOptions = [{
      text: 'Original value',
      value: 'original_value',
    }, {
      text: 'Cost value',
      value: 'cost_value',
    }, {
      text: 'Initial margin',
      value: 'initial_margin',
    }, {
      text: 'Original price',
      value: 'original_price',
    }, {
      text: 'Cost price',
      value: 'cost_price',
    }, {
      text: 'Pieces',
      value: 'pieces',
    }, {
      text: 'Stars',
      value: 'stars',
    }, {
      text: 'Stores',
      value: 'stores',
    }]

    this.sumOptions = [{
      text: 'Original value',
      value: 'original_value',
    }, {
      text: 'Cost value',
      value: 'cost_value',
    }, {
      text: 'Original price',
      value: 'original_price',
    }, {
      text: 'Cost price',
      value: 'cost_price',
    }, {
      text: 'Pieces',
      value: 'pieces',
    }, {
      text: 'Width',
      value: 'width',
    }, {
      text: 'Depth',
      value: 'depth',
    }]

    return {
      activeTab: 0,
      selectedMetrics: this.selectedMetricsProp.map(m => m.value),
      metricsList: this.metricsListProp,
      selectedOrder: 'pieces',
      selectedSum: ['width', 'pieces'],
      selectedShowTotalAs: this.selectedShowTotalAsProp,
    }
  },
  methods: {
    handleSelectedMetricsChange () {
      this.$nextTick(() => this.$emit('selectedMetrics:change', this.selectedMetrics))
    },
    handleSelectedOrderChange () {
      this.$nextTick(() => this.$emit('selectedOrder:changed', this.selectedOrder))
    },
    handleSelectedTotalsChange () {
      this.$nextTick(() => this.$emit('selectedTotals:changed', this.selectedSum))
    },
  }
}
</script>