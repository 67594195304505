<template>
  <v-card
    v-if="!isChangeView"
    @resize="resizeCanvas"
    :flat="flat || inModal"
    ref="dougnutCanvas"
    class="pa-4 pt-2 overflow-auto">
    <div class="d-flex align-center ma-0 justify-space-between pb-2" v-if="index !== null && !hide">
      <h4 class="text-no-wrap">{{positionTitle}}. {{ view.view.view_name }}</h4>
      <div class="d-flex show-on-hover flex-nowrap pt-1" v-if="!view.view.errored && !view.errored" >
        <v-icon class="show-on-hover action-btn-primary" @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" v-if="!inModal">edit</v-icon>
        <v-overlay absolute color="white" :value="downloadingLoaded">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <DataExport
          @item-click="downloadData"
        />
        <v-icon
          class="mr-1 action-btn-primary"
          @click="$emit('full-screen',view,index)"
          v-if="!inModal"
        >fullscreen</v-icon>
        <data-sets-view-filter :view="view" @change="filterChanged" :index="index" ></data-sets-view-filter>
      </div>
      <template v-else-if="view.view.errored || view.errored" >
        <div class="d-flex show-on-hover flex-nowrap pt-1">
          <v-icon class="show-on-hover action-btn-primary " @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" v-if="!inModal">edit</v-icon>
        </div>
      </template>
    </div>

    <v-overlay absolute color="white" :value="(!view.data) && !(view.view.errored || view.errored)">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="justify-center ma-0">
      <div  :class="title+index+ '_' + secondaryIndex" class="p-relative" style="width: 200px;" ></div>

      <v-menu v-model="legendMenuOpened" :attach="'.' + title+index+ '_' + secondaryIndex">
        <v-card class="pa-3">
          <v-row :key="item.text" class="ma-0 flex-nowrap text-no-wrap" v-for="item of legendItems">
            <div style="width: 48px; height: 16px; margin-right: 16px" :style="{backgroundColor: item.fillStyle}"></div>
            {{item.text}}
          </v-row>
        </v-card>
      </v-menu>
    </v-row>
    <div class="chart-container" style="position: relative;">
      <canvas :id="title+index+ '.' + secondaryIndex"></canvas>
    </div>
  </v-card>

  <datasets-new-view
    v-else
    :dashboard-details="dashboardDetails"
    :viewsMap="viewsMap"
    @saved-positions="$emit('saved-positions', $event)"
    :changeView="{...view.view, position}"
    :positions="positions"
    :datasets="datasets"
    :dataSetForChoose="dataSetForChoose"
    @cancel="isChangeView = false; $emit('toggle-view', false)"
    @delete-view="$emit('delete-view',$event)"
    @save="changeView">
  </datasets-new-view>
</template>

<script>
import { downloadAnalytics } from '@/api/analytics'
import DataExport from '@/components/DataExport'
import DataSetsViewFilter from '@/components/AutomateDatasets/DataSetsViewFilter'
import { dataConvertDict } from '@/chart/ChartData'
import { Chart } from 'chart.js/auto'
import { useAnalyticsFiltersStore } from '@/store/pinia/analyticsFiltersStore'

const DatasetsNewView = () => import('@/components/AutomateDatasets/DatasetsNewView')

export default {
  name: 'DataSetsDoughnutChart',
  components: { DataSetsViewFilter, DataExport, DatasetsNewView },
  props: ['view', 'index', 'positions', 'datasets', 'dataSetForChoose', 'shared', 'hide', 'flat',
          'height', 'inModal', 'secondaryIndex', 'viewsMap', 'dashboardDetails'],
  setup () {
    return {
      analyticsFiltersStore: useAnalyticsFiltersStore()
    }
  },
  computed: {
    positionTitle () {
      if(this.dashboardDetails?.view_keys[this.index]?.filter(item=> item).length === 1){
        return this.index + 1
      }
      return this.position
    },
    downlaodFilters () {
      return { ...this.view.view.filters, ...this.analyticsFiltersStore.dashboardDetails.filters }
    },
    position () {
      return `${this.index + 1}${['A', 'B', 'C', 'D'][this.secondaryIndex]}`
    },
    title () {
      return 'data_sets_doughnut_chart'
    }
  },
  data () {
    return {
      legendMenuOpened: false,
      legendItems: [],
      innerHeight: 370,
      isChangeView: false,
      downloadingLoaded: false,
    }
  },
  methods: {
    resizeCanvas () {
      this.innerHeight = (this.$refs.dougnutCanvas?.$el.offsetWidth - 32) / 1.5
    },
    filterChanged (value) {
      this.changeView({ view: value, position: this.index })
    },
    downloadData (sep) {
      this.downloadingLoaded = true
      const downloadPayload = { ...this.view.view, filters: this.downlaodFilters, csv: true, csv_separator: sep, csv_decimal: '.' }
      if (sep === 'dutch') {
        downloadPayload.csv_separator = ';'
        downloadPayload.csv_decimal = ','
      }
      downloadAnalytics(downloadPayload)
        .then(({ url }) => {
          this.downloadingLoaded = false
          const link = document.createElement('a')
          link.setAttribute('href', url)
          const today = new Date()
          const dd = today.getDate()
          const mm = today.getMonth() + 1
          const yyyy = today.getFullYear()
          link.setAttribute('download', this.view.view.view_name + '_' + String(dd) + '_' + String(mm) + '_' + String(yyyy) + '.csv')
          link.click()
        })
    },
    changeView (value) {
      this.isChangeView = false
      this.$emit('toggle-view', false)
      this.$emit('change-view', value)
    },
    drawChart () {
      this.innerHeight = (this.$refs.dougnutCanvas?.$el.offsetWidth - 32) / 1.5
      if (this.chart) {
        this.chart.destroy()
      }
      try {
        this.loaded = false
        const ctx = document.getElementById(this.title + this.index + '.' + this.secondaryIndex).getContext('2d')
        const data = dataConvertDict[this.title](this.view)
        const chartData = data[0]
        const options = data[1]
        const isHalf = this.view.view.properties.doughnut_degree === '180'
        const fill = this.view.view.measures[0].name
        const target = this.view.view.measures[1]?.name
        const rotationRadius = (isHalf ? 1 : 0.5) * Math.PI
        const plugins = [{
          afterDatasetDraw: () => {
            const centerY = (this.chart.chartArea.bottom / (isHalf ? 1 : 2))

            const width = this.chart.width
            const height = this.chart.chartArea.bottom - this.chart.legend.height
            const ctx = this.chart.ctx
            ctx.restore()
            ctx.font = '1.17em sans-serif'
            ctx.textBaseline = 'middle'
            ctx.fillStyle = 'rgba(0, 0, 0, 0.86)'
            const text = this.$options.filters.formatThousands(this.view.total_row[0][fill])
            const textX = Math.round((width - ctx.measureText(text).width) / 2)
            const textXFill = Math.round((width - ctx.measureText(fill).width) / 2)
            if (!this.view.view.properties.hide_total_value) {
              ctx.fillText(text, textX, centerY - (isHalf ? 48 : 0))
              ctx.fillText(fill, textXFill, centerY - (isHalf ? 48 : 0) + 20)
            }
            ctx.save()
            if (!target) {
              return
            }
            const centerX = this.chart.width / 2
            // if 180 / 1
            const radius = this.chart.outerRadius
            const radiusInner = this.chart.innerRadius
            // Convert myValue to a percentage of the total sum
            const targetValue = this.view.total_row[0][target]
            const total = Math.max(2 * targetValue, this.view.total_row[0][fill])
            const percentage = (targetValue / total) * 100
            // Calculate the angle for myValue
            //   (*2 if 360)
            const angle = (percentage / 100) * (isHalf ? 1 : 2) * Math.PI
            // Calculate line end point based on the angle
            const lineX = centerX + radius * Math.cos(angle - rotationRadius)
            const lineY = centerY + radius * Math.sin(angle - rotationRadius)
            const lineXInner = centerX + radiusInner * Math.cos(angle - rotationRadius)
            const lineYInner = centerY + radiusInner * Math.sin(angle - rotationRadius)
            // Draw a line indicating myValue
            let targetTextY = lineY
            let targetTextYTarget = lineY + 20
            if (lineY < 20) {
              targetTextY += 20
              targetTextYTarget += 20
            } else if (lineY > height - 60) {
              targetTextY -= 20
              targetTextYTarget -= 60
            }
            let targetTextX = lineX - 5
            let targetTextXTarget = lineX - 5
            if (lineX <= width / 2) {
              targetTextX -= ctx.measureText(targetValue).width + 5
              targetTextXTarget -= ctx.measureText(target).width + 5
            }
            if (!this.view.view.properties.hide_total_value) {
              ctx.fillText(this.$options.filters.formatThousands(targetValue), targetTextX, targetTextY)
              ctx.fillText(target, targetTextXTarget, targetTextYTarget)
            }
            ctx.beginPath()
            ctx.moveTo(lineXInner, lineYInner)
            ctx.lineTo(lineX, lineY)
            ctx.lineWidth = 2 // Set line width
            ctx.strokeStyle = 'black' // Set line color
            ctx.stroke()
          }
        }]
        options.plugins.legend.onHover = (chart, legend, {legendItems})=> {
          this.legendMenuOpened = true
          if(!this.legendItems?.length){
            this.legendItems = legendItems
          }
        }
        options.plugins.legend.onLeave = ()=> {
          this.legendMenuOpened = false
        }
        this.chart = new Chart(ctx, { type: 'doughnut', data: chartData, options, plugins })
        this.$emit('loaded')
        this.loaded = true
      } catch (e) {
        console.log(e)
        this.$emit('loaded')
        this.loaded = true
      }
    },
  },
  watch: {
    view: {
      handler (value) {
        if (Object.keys(value).length < 2) {
          return
        }
        setTimeout(() => {
          this.drawChart()
        }, 300)
      },
      immediate: true
    },
    isChangeView () {
      setTimeout(() => {
        if (!this.isChangeView) {
          this.innerHeight = (this.$refs.dougnutCanvas?.$el.offsetWidth - 32) / 1.5
          this.drawChart()
        }
      }, 200)
    }
  },
}
</script>

<style scoped>

</style>
