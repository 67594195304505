<template>
  <v-menu
    :key="option.option_key"
    right
    bottom
    :open-on-hover="!infoMenuOpened[option.option_key]"
    nudge-bottom="10"
    :nudge-left="200"
    :disabled="!option.option_key"
    v-model="infoMenuOpened[option.option_key]"
    @input="onInfoMenuChange(option.option_key, $event)"
    offset-x
    offset-y
    :open-delay="3000"
    :close-delay="1000"
    :close-on-content-click="false"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="option-data-cell--transfers">
        <v-img
          :src="option.image_url"
          :key="option.option_key"
          class="mr-6"
          :lazy-src="placeholder_image"
          max-width="80"
          max-height="120"
        />

        <div class="option">
          <router-link
            v-if="nameParts.length === 2"
            class="option-name"
            target="_blank"
            :to="getOptionUrl(option)"
          >
            <p class="option-title">{{ nameParts[0] }}</p>
            <p class="option-subtitle">
              {{ nameParts[1] }}
            </p>
          </router-link>
          <span v-else>{{ nameParts[1] }}</span>
          <div class="mt-2 price">
            <template v-if="option.price">
              {{ option.price | twoDecimals }}
            </template>
            <template v-else-if="option.osp > option.asp">
              {{ option.osp | twoDecimals }} / {{ option.asp | twoDecimals }}
            </template>
            <template v-else>
              {{ option.osp | twoDecimals }}
            </template>
          </div>
          <div class="mt-2">
            <v-menu
              top
              offset-x
              min-width="200"
              max-width="200"
              content-class="option-info-menu"
              :close-on-content-click="false"
              :value="optionInfoVisible"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="handleOptionInfoClicked"
                >info</v-icon>
              </template>

              <v-card class="py-2 px-4 p-relative">
                <v-overlay absolute color="white" :value="loadingOptionInfo">
                  <v-progress-circular color="primary" indeterminate size="64" width="2" />
                </v-overlay>
                <div v-for="(value, key) in optionInfo" :key="key" class="text-body-2 my-1">
                  <b>{{ key }}:</b> {{ value }}
                </div>
              </v-card>
            </v-menu>
          </div>
        </div>
      </div>

    </template>
    <info-menu
      @close="infoMenuOpened[option.option_key] = false"
      v-if="infoMenuOpened[option.option_key]"
      :row="option"></info-menu>
  </v-menu>
</template>

<script>
import axios from '@/services/axios.js'
import { getHeadersWithAccessToken } from '@/variables.js'
import InfoMenu from '@/components/Home/InfoMenu'
import baseURLs from '@/api/APIBaseURLs'
import placeholder_image from '@/assets/img/placeholder-image.png'

export default {
  components: { InfoMenu },
  props: {
    option: {
      type: Object,
      required: true,
    },
    nameKey: {
      type: String,
      default: 'name',
    },
  },
  data () {
    return {
      infoMenuOpened: {},
      optionInfoVisible: false,
      loadingOptionInfo: false,
      optionInfo: null,
      placeholder_image,
    }
  },
  computed: {
    nameParts () {
      return this.option[this.nameKey] ? this.option[this.nameKey].split(' / ') : []
    }
  },
  methods: {
    onInfoMenuChange (option_key, value) {
      if (value) {
        this.infoMenuOpened = { [option_key]: value }
      }
    },
    getOptionUrl (row) {
      return {
        name: 'stock-option',
        query: {
          'option_key': row.option_key
        }
      }
    },
    handleOptionInfoClicked () {
      const url = baseURLs.manageAPI + '/data/general/options/data?format=d&option_key=' + this.option.option_key

      this.loadingOptionInfo = true
      this.optionInfoVisible = true

      return axios.get(url, {
        headers: getHeadersWithAccessToken()
      })
        .then(response => {
          this.optionInfo = response.data.data.properties
          this.loadingOptionInfo = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
    .option-data-cell--transfers {
        display: flex;
        flex-direction: row;
        font-size: 13px !important;
        align-items: center;
        letter-spacing: 0.2px;
        p {
            margin: 0;
        }
        .option-name {
            color: unset !important;
            text-decoration: none;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .option-title {
                font-weight: 500;
                &:hover {
                    color: var(--primary);
                    cursor: pointer;
                }
            }
            .option-subtitle {
                color: var(--text-sub-black) !important;
                font-size: 12px;
            }
        }
        .price {
            color: var(--text-black);
        }
    }
    .option-info-menu {
        .v-card {
            min-height: 100px;
        }
    }
</style>
