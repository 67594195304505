<template>
  <div class="operate-option-sets-search">
    <h6 class="text-subtitle-1 font-weight-medium mb-4 ml-6">1. Overview</h6>
    <v-row>
      <v-col class="text-right pr-6">
        <TableSearch class="mx-2" @searchChanged="handleOptionSetsSearchChanged" />
      </v-col>
    </v-row>
    <data-tables
      style="width: 100%"
      :key="optionSetsKey"
      :data="optionSets"
      :page-size="5"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
      :table-props="{ 'row-key': 'options_set_key' }"
      :filters="optionSetsTableFilters"
      @row-click="handleRowClick">
      <el-table-column prop="options_set_key" width="55">
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            :value="scope.row.selected"
            :ripple="false"
            dense
            hide-details
            class="ml-3"
            @input="handleSelectRowChange($event, scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="set_name" label="Set name" sortable="custom">
        <template slot-scope="scope">
          {{ scope.row.set_name }}
        </template>
      </el-table-column>
      <el-table-column prop="label" label="Labels" sortable="custom">
        <template slot-scope="scope">
          <ChipsDropdown :items="scope.row.label" />
        </template>
      </el-table-column>
      <el-table-column prop="options" label="Options" sortable="custom">
        <template slot-scope="scope">
          {{ scope.row.options }}
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import TableSearch from '@/components/TableSearch.vue'
import ChipsDropdown from '@/components/ChipsDropdown.vue'
import { mapState } from 'pinia'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'

import { useStockOperateStore } from '@/store/pinia/stockOperateStore'

export default {
  setup () {
    return {
      operateStore: useStockOperateStore()
    }
  },
  data () {
    return {
      optionSetsKey: 0,
      optionSetsTableFilters: [
        {
          value: ''
        }
      ],
    }
  },
  components: {
    TableSearch,
    ChipsDropdown,
  },
  computed: {
    ...mapState(useStoreAndOptionSetsStore, ['optionSets']),
    selectedRows () {
      return this.optionSets.filter(r => r.selected)
    },
    allRowsSelected () {
      return (this.optionSets.length > 0) && (this.selectedRows.length === this.optionSets.length)
    },
  },
  methods: {
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.optionSets.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
      this.operateStore.setSelectedOptionSetsKey(this.selectedRows.map(x => x.options_set_key))
    },
    handleOptionSetsSearchChanged (val) {
      this.optionSetsTableFilters[0].value = val
      this.optionSetsKey++
    },
  }
}
</script>

<style lang="scss">
.operate-option-sets-search {
    .sc-table .pagination-wrap {
        text-align: right;
    }
}
</style>
