<template>
  <div class="shop-item-commit-form">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <ShopItemCommitFilters
      ref="commitFilters"
      :item="item"
      :stores="stores"
      @filters-changed="handleFiltersChanged"
    />
    <slot></slot>
    <ShopItemCommitCarousel
      :item="item"
      :dummy="dummy"
      class="my-8"
      :stores="stores"
      :chartsData="chartsData"
      :lookalikeData="lookalikeData"
      @item-click="itemData => $emit('chart-item-click', itemData)"
      @item-change="itemData => $emit('table-row-change', itemData)"
      @delete-beliefs="itemData => $emit('delete-beliefs', itemData)"
    />
    <div class="d-flex align-center">
      <h4 class="pl-4">KPI</h4>
      <v-tooltip top :max-width="250">
        <template v-slot:activator="{ on, attrs }">
          <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
        </template>
        <span>
          Shown sales data is depicting the sum of all selected ‘Reorder options’. When this selection is left empty, it will show the average of all selected lookalikes.
        </span>
      </v-tooltip>
    </div>
    <div class="d-flex flex-nowrap justify-space-between kpi-tiles mx-2 mx-md-16">
      <div
        v-for="(item, i) in kpiItems.slice(0,4)"
        :key="i"
        class="d-flex flex-column align-center rounded p-relative rounded elevation-2 kpi-tile my-4 mx-3 mx-md-16"
        :class="{'c-pointer': item.title === 'Returns'}"
        @click="openReturnsPerStore(item.title)"
      >
        <div class="mt-4 tile-title" :class="{'return-check': item.title === 'Returns'}">{{ item.title }}</div>
        <div class="mt-6 tile-value">{{ item.value }}</div>
      </div>
    </div>
    <div class="d-flex flex-nowrap justify-space-between kpi-tiles mx-2 mx-md-16">
      <div
        v-for="(item, i) in kpiItems.slice(4)"
        :key="i"
        class="d-flex flex-column align-center rounded p-relative rounded elevation-2 kpi-tile my-4 mx-3 mx-md-16"
        :class="{'c-pointer': item.title === 'Returns'}"
        @click="openReturnsPerStore(item.title)"
      >
        <div class="mt-4 tile-title" :class="{'return-check': item.title === 'Returns'}">{{ item.title }}</div>
        <div class="mt-6 tile-value">{{ item.value }}</div>
      </div>
    </div>

    <div class="mt-4 pb-6 mr-1 text-right">
      <v-btn
        color="primary"
        depressed
        outlined
        @click="commitDialogVisible = true"
        class="mx-4"
      >Commit</v-btn>
    </div>

    <!-- Commit confirm dialog -->
    <v-dialog
      :value="commitDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0 p-relative">
        <v-overlay absolute color="white" :value="commitDialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <v-card-text>
          <div class="text-body-1 pt-8">Inserting this commitment will update any existing and overlapping commitments. Continue?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="commitDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                @click="handleCommitConfirmClick"
                class="mx-4"
              >Commit</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <shop-returns-per-store-modal
      :is-open="returnsPerStoreModal"
      v-if="returnsPerStoreModal"
      :data="{...returns_data}"
      title="Returns per store"
      @close="returnsPerStoreModal = false"/>
    <shop-returns-per-store-modal
      :is-open="salesPerStoreModal"
      v-if="salesPerStoreModal"
      :data="{...zero_sales_data}"
      title="Zero sales Per Store"
      @close="salesPerStoreModal = false"/>
  </div>
</template>

<script>
import { round } from 'lodash'
import * as shopApi from '@/api/shop'
import ShopItemCommitFilters from '@/components/Shop/ShopItemCommitFilters.vue'
import ShopItemCommitCarousel from '@/components/Shop/ShopItemCommitCarousel.vue'
import ShopReturnsPerStoreModal from '@/components/Shop/ShopReturnsPerStoreModal'

export default {
  components: {
    ShopReturnsPerStoreModal,
    ShopItemCommitFilters,
    ShopItemCommitCarousel,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    dummy: {
      type: Object,
      default: () => ({}),
    },
    stores: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      loading: false,
      commitDialogVisible: false,
      commitDialogLoading: false,
      returnsPerStoreModal: false,
      salesPerStoreModal: false,
      filters: {
        store_keys: null,
        sku_names: null,
        week_min: null,
        week_max: null,
      },
      chartsData: null,
      kpiData: null,
      lookalikeData: null,
      returns_data: {
        columns: [],
        data: [],
      },
      zero_sales_data: {
        columns: [],
        data: [],
      },
    }
  },
  computed: {
    kpiItems () {
      if (!this.kpiData) {
        return []
      }

      return [
        {
          title: 'Sales LY + Missed',
          value: `${this.kpiData.sales_missed_ly} (${round(this.kpiData['sales_missed_ly%'] * 100, 2)}%)`,
        },
        {
          title: 'Sales LY',
          value: `${this.kpiData.sales_ly} (${round(this.kpiData['sales_ly%'] * 100, 2)}%)`,
        },
        {
          title: 'Returns LY',
          value: `${this.kpiData.sales_ly_net - this.kpiData.sales_ly} (${round((this.kpiData['sales_ly_net%'] - this.kpiData['sales_ly%']) * 100, 2)}%)`,
        }, {
          title: 'Sales LY (Net)',
          value: `${this.kpiData.sales_ly_net} (${round(this.kpiData['sales_ly_net%'] * 100, 2)}%)`,

        },
        {
          title: 'Potential',
          value: `${this.kpiData.potential} (${round(this.kpiData['potential%'] * 100, 2)}%)`,

        }, {
          title: 'Belief',
          value: `${this.kpiData.belief} (${round(this.kpiData['belief%'] * 100, 2)}%)`,
        }, {
          title: 'Returns',
          value: `-${this.kpiData.returns} (-${round(this.kpiData['return%'] * 100, 2)}%)`,
        }, {
          title: 'To commit',
          value: `${this.kpiData.to_commit} = ${round(this.kpiData['to_commit%'] * 100, 2)}%`,
        },
      ]
    }
  },
  methods: {
    openReturnsPerStore (title) {
      if (title === 'Returns') {
        this.returnsPerStoreModal = true
      }
    },
    getCommitmentsPayload () {
      return {
        prediction_mode: this.item.prediction_mode,
        dummy_key: this.item.dummy_key,
        shop_item_key: this.item.shop_item_key,
        store_keys: this.filters.store_keys,
        option_key: this.dummy.option_key,
        scenario_key: this.item.scenario_key,
        budget_key: this.item.budget_key,
        sku_names: this.filters.sku_names,
        week_min: parseInt(this.filters.week_min),
        week_max: parseInt(this.filters.week_max),
        beliefs: this.item.beliefs,
        reorder_option_keys: this.item.reorder_option_keys,
        lookalike_option_keys: this.dummy.lookalike_option_keys,
      }
    },
    setFiltersFromShopItem () {
      this.filters.store_keys = this.item.store_keys
      this.filters.sku_names = this.item.sku_names
      this.filters.week_min = this.item.week_min?.toString() || null
      this.filters.week_max = this.item.week_max?.toString() || null

      this.$refs.commitFilters.setFiltersFromShopItem()
    },
    async loadCommitData () {
      this.loading = true
      const payload = this.getCommitmentsPayload()
      return shopApi
        .getBeliefsCommitData(payload)
        .then(data => {
          if (!data) {
            return
          }
          this.chartsData = data.graph_data
          this.kpiData = data.kpi_data
          this.lookalikeData = data.lookalike_data
          this.returns_data = data.returns_data
          this.zero_sales_data = data.zero_sales_data
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleFiltersChanged (filters) {
      this.filters = filters
      this.loadCommitData()
      this.$emit('filters-changed', this.filters)
    },
    handleCommitConfirmClick () {
      this.commitDialogLoading = true

      const payload = this.getCommitmentsPayload()
      shopApi
        .saveCommitments(payload)
        .then(() => {
          this.$emit('commitments-saved')
          this.commitDialogVisible = false
        })
        .finally(() => {
          this.commitDialogLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
.shop-item-commit-form {
    .kpi-tiles {
        .kpi-tile {
            height: 130px;
            flex-basis: 220px;
            display: inline-block;
            .tile-title {
                font-size: 12px;
                font-weight: 500;
            }
            .tile-value {
                font-weight: 500;
            }
        }
    }
  .kpi-tile:hover {
    .return-check {
      color: var(--primary);
    }
  }
}
</style>
