<template>
  <collapsible-select
    :value="keys"
    :items="storesListToShow"
    :error-messages="errors"
    class="mb-3"
    label="Stores"
    placeholder="Search"
    item-value="store_key"
    item-text="store_name"
    autocomplete
    multiple
    chips
    clearable
    small-chips
    deletable-chips
    :show-select-all="selectAll"
    hide-details
    :menu-props="{
      left: true,
      offsetY: true,
      contentClass: 'select-dropdown-menu',
    }"
    @change="onStoresChange"
    @blur="onStoresBlur"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip
        v-if="index === 0"
        small
        close
        outlined
        @click="handleFirstStoreClick"
        @click:close="deleteFirstStore"
      >
        <span>{{ item.store_name }}</span>
      </v-chip>
      <span v-if="index === 1" class="grey--text caption mx-1">
        +{{ keys.length - 1 }}
      </span>
    </template>
  </collapsible-select>
</template>

<script>
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { mapState } from 'pinia'

export default {
  name: 'StoresSelect',
  components: { CollapsibleSelect },
  emits: ['update:keys'],
  props: {
    restrictList: {
      type: Boolean,
      default: false
    },
    storeKeyList: {
      type: Array,
      default: () => []
    },
    keys: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      storeList: [],
      showOnlyCurrentStores: false
    }
  },
  mounted () {
    this.loadStores()
  },
  watch: {
    restrictList () {
      this.loadStores()
    },
    storeKeyList () {
      this.loadStores()
    },
    stores () {
      this.loadStores()
    }
  },
  computed: {
    errors () {
      if (this.required && !this.keys.length) {
        return ['This field is required']
      }
      return []
    },
    ...mapState(useGeneralStore, ['appConfig']),
    stores () {
      return this.appConfig?.stores || []
    },
    storesListToShow () {
      return this.showOnlyCurrentStores
        ? this.stores.filter(s => this.keys.includes(s.store_key))
        : this.storeList
    },
  },
  methods: {
    loadStores () {
      if (!this.stores.length) {
        return
      }
      if (!this.restrictList || !this.storeKeyList.length) {
        this.storeList = this.stores.map(s => ({
          store_key: s.store_key,
          store_name: s.store_name
        }))
        return
      }
      this.storeList = this.storeKeyList.map(key => {
        const storeData = this.stores.find(s => s.store_key === key)
        return {
          store_key: key,
          store_name: storeData ? storeData.store_name : key
        }
      })
    },
    onStoresChange (value) {
      this.$emit('update:keys', value)
    },
    onStoresBlur () {
      setTimeout(() => {
        this.showOnlyCurrentStores = false
      }, 100)
    },
    handleFirstStoreClick () {
      this.showOnlyCurrentStores = true
    },
    deleteFirstStore () {
      const stores = [...this.keys]
      stores.splice(0, 1)
      this.$emit('update:keys', stores)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-chip {
  padding-right: 0;
}
.v-chip__content {
  > span {
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 30px);
  }
}
</style>
