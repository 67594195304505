<template>
  <div>
    <label for="password">
      Password
    </label>
    <v-text-field
      id="password"
      v-model="password"
      :error-messages="passwordErrors"
      @change="validatePassword"
      required
      type="password"
      outlined
      autofocus
    />
    <label for="repeatPassword">
      Repeat Password
    </label>
    <v-text-field
      id="repeatPassword"
      v-model="repeatPassword"
      :error-messages="repeatPasswordErrors"
      @change="validateRepeatPassword"
      required
      type="password"
      outlined
    />
    <v-btn
      class="action mt-1"
      outlined
      @click="submit"
      v-animate-css.click="'tada'"
      v-animate-css.hover="'pulse'"
      :disabled="submitLock"
    >
      Reset Password
    </v-btn>
  </div>
</template>

<script>
export default {
  data () {
    return {
      password: '',
      passwordErrors: [],
      repeatPassword: '',
      repeatPasswordErrors: [],
      submitLock: false,
    }
  },
  props: {
    errorMessage: {
      type: String,
      default: null
    },
    successMessage: {
      type: String,
      default: null
    }
  },
  methods: {
    validatePassword () {
      this.passwordErrors = []
      this.repeatPasswordErrors = []
      if (!this.password) {
        this.passwordErrors.push('Password is required')
      } else if (this.password.length < 6) {
        this.passwordErrors.push('Password must be at least 6 characters')
      }
      return this.passwordErrors.length === 0
    },
    validateRepeatPassword () {
      this.repeatPasswordErrors = []
      if (!this.repeatPassword) {
        this.repeatPasswordErrors.push('Repeat password is required')
      } else if (this.repeatPassword !== this.password) {
        this.repeatPasswordErrors.push('Passwords do not match')
      }
      return this.repeatPasswordErrors.length === 0
    },
    setError (error) {
      this.$emit('update:errorMessage', error)
    },
    async submit () {
      if (this.submitLock) {
        return
      }
      this.setError(null)
      if (!this.validatePassword() || !this.validateRepeatPassword()) {
        return
      }
      this.submitLock = true
      // All validations passed, so send invite request to token service
      try {
        await this.$auth.resetPassword(this.$route.query.id, this.$route.query.token, this.password)
        this.$router.push({ path: '/login' }).catch()
        this.$emit('update:successMessage', 'Password reset successfully')
      } catch (error) {
        this.setError('Something went wrong')
        console.error(error)
      }
      this.submitLock = false
    }
  }
}
</script>
