<template>
  <v-row>
    <v-col :cols="5" :class="{'py-0 my-3': !prop.value_dropdown && prop.value !== 'boolean'}">
      <v-autocomplete
        :value="prop.property_name"
        item-text="property_name"
        item-value="property_name"
        disabled
        :items="properties"
        label="Property"
        hide-details
      />
    </v-col>
    <v-col :cols="5"
      class="d-flex align-center"
      :class="{'py-0 my-1': !prop.value_dropdown && prop.value !== 'boolean'}">
      <v-autocomplete
        v-if="prop.value_dropdown && prop.values"
        :ref="prop.property_name"
        v-model="store.properties[prop.property_name]"
        :items="prop.values"
        label="Value"
        hide-details="auto"
        :error-messages="
          prop.force && !store.properties[prop.property_name] ? ['This is a required field'] :
          (prop.errors ? prop.errors : [])
        "
        :menu-props="{
          left: true,
          offsetY: true,
          contentClass: 'select-dropdown-menu',
        }"
        @change="handlePropertyChanged"
        @input="handlePropertyChanged"
      />
      <v-text-field
        v-else-if="prop.value_type !== 'boolean'"
        :ref="prop.property_name"
        v-model="store.properties[prop.property_name]"
        label="Value"
        hide-details="auto"
        :error-messages="
          prop.force && !store.properties[prop.property_name] ? ['This is a required field'] :
          (prop.errors ? prop.errors : [])
        "
        :type="prop.value_type === 'text' ? 'text' : 'number'"
        @change="handlePropertyChanged"
        @input="handlePropertyChanged"
      />
      <v-simple-checkbox
        v-else
        v-model="storeProperties[prop.property_name]"
        :ref="prop.property_name"
        color="primary"
        hide-details="auto"
        :ripple="false"
        @input="v => handleBooleanPropertyChanged(prop.property_name, v)"
      />
    </v-col>
    <v-col v-if="!prop.force" :cols="1">
      <v-icon
        class="mt-5 action-btn-danger"
        @click="handlePropDeleteClick(prop)"
      >delete</v-icon>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'StoreEditPropertyEntry',
  data () {
    return {
      storeProperties: {}
    }
  },
  props: {
    prop: {
      type: Object,
      required: true,
    },
    properties: {
      type: Array,
      required: true,
    },
    store: {
      type: Object,
      required: true,
    },
    handlePropDeleteClick: {
      type: Function,
      required: true,
    },
  },
  mounted () {
    this.storeProperties = this.store.properties || {}
  },
  watch: {
    store: {
      handler () {
        this.storeProperties = { ...this.store.properties }
      },
      deep: true,
    },
  },
  methods: {
    handleBooleanPropertyChanged (propName, value) {
      this.store.properties[propName] = value
      this.$emit('propertyChanged', propName, value)
    },
    handlePropertyChanged (propName, value) {
      this.$emit('propertyChanged', propName, value)
    }
  },
}
</script>

<style scoped>

</style>
