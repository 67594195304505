<template>
  <div class="options-search-text">
    <div class="pl-6 block-wrapper">
      <div class="text-body-1 font-weight-medium pt-6">Text search</div>
      <v-text-field
        v-model="searchText"
        label="Text"
        clearable
        hide-details
        @input="$emit('change', $event)"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  props: ['searchString'],
  data () {
    return {
      searchText: this.searchString || '',
    }
  },
  watch: {
    searchString (o, n) {
      if(o !== n && this.searchString) {
        this.searchText = this.searchString
      }
    }
  },
}
</script>

<style lang="scss">
.options-search-text {
  .block-wrapper {
    width: 410px;
  }
}
</style>
