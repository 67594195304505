import DataSetsMainPage from '@/views/DataSetsMainPage.vue'
import OperatePage from '@/views/OperatePage.vue'
import BuySettingsConfigurationPage from '@/views/BuySettingsConfigurationPage.vue'
import StockSettingsConfigurationPage from '@/views/StockSettingsConfigurationPage.vue'
import SettingsConfiguration from '@/views/SettingsConfiguration.vue'
import StockSettingsStoresPage from '@/views/StockSettingsStoresPage.vue'
import StockSettingsUsersPage from '@/views/StockSettingsUsersPage.vue'
import StockSettingsDataPage from '@/views/StockSettingsDataPage.vue'
import SettingsMainPage from '@/views/SettingsMainPage.vue'
import PricingManagePage from '@/views/PricingManagePage.vue'
import PricingMarkdownBuilderPage from '@/views/PricingMarkdownBuilderPage.vue'
import PricingMarkdownPage from '@/views/PricingMarkdownPage.vue'
import PricingHomePage from '@/views/PricingHomePage.vue'
import PricingLayout from '@/views/modules/PricingLayout.vue'

export const pricingRoutes = {
  path: '/pricing',
  component: PricingLayout,
  children: [
    {
      path: 'home',
      name: 'pricing-home-page',
      component: PricingHomePage,
    },
    {
      path: 'markdown',
      name: 'pricing-markdown-page',
      component: PricingMarkdownPage,
    },
    {
      path: 'builder',
      name: 'pricing-markdown-builder',
      component: PricingMarkdownBuilderPage,
    },
    {
      path: 'manage',
      name: 'pricing-manage',
      component: PricingManagePage,
    },
    {
      path: 'settings',
      name: 'pricing-settings-main',
      component: SettingsMainPage
    },
    {
      path: 'settings/data',
      name: 'pricing-settings-data',
      component: StockSettingsDataPage
    },
    {
      path: 'settings/users',
      name: 'pricing-settings-users',
      component: StockSettingsUsersPage
    },
    {
      path: 'settings/stores',
      name: 'pricing-settings-stores',
      component: StockSettingsStoresPage
    },
    {
      path: 'settings/configuration',
      name: 'pricing-settings-configuration',
      component: SettingsConfiguration
    },
    {
      path: 'settings/configuration/stock',
      name: 'pricing-settings-configuration-stock',
      component: StockSettingsConfigurationPage
    },
    {
      path: 'settings/configuration/buy',
      name: 'pricing-settings-configuration-buy',
      component: BuySettingsConfigurationPage,
    },
    {
      path: 'operate',
      name: 'pricing-operate',
      component: OperatePage,
    },
    {
      path: 'analytics',
      name: 'pricing-analytics',
      component: DataSetsMainPage,
    }
  ]
}
