<template>
  <v-card>
    <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">{{ isEdit ? 'Edit ' : 'Add ' }} rule</v-card-title>
    <v-divider/>
    <v-card-text class="px-0 text--primary">
      <div class="container px-12 pt-0">
        <template v-if="editedItem">
          <v-row class="align-baseline mt-2">
            <v-col class="py-1">
              <v-text-field
                :error-messages="requiredField(editedItem.name)"
                v-model="editedItem.name"
                label="Name"
                required
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-4">
            <v-col class="py-1">
              <v-select
                v-model="editedItem.type"
                :items="types"
                label="Type"
                :error-messages="requiredField(editedItem.type)"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline">
            <v-col class="py-1">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                v-model="editedItem.options_set_key"
                :items="optionSets"
                :error-messages="requiredField(editedItem.options_set_key)"
                item-text="set_name"
                item-value="options_set_key"
                class="text-body-2"
                label="Option set"
                :multiple="false"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline">
            <v-col class="py-1">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                label="Store set"
                v-model="editedItem.stores_set_key"
                :error-messages="editedItem.stores_set_key || editedItem.store_keys?.length ? '' : 'This field is required'"
                :items="storeSets"
                item-text="set_name"
                item-value="stores_set_key"
                :multiple="false"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline">
            <v-col class="py-1">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                label="To store set"
                v-model="editedItem.to_stores_set_key"
                :error-messages="editedItem.to_stores_set_key || editedItem.to_store_keys?.length ? '' : 'This field is required'"
                :items="storeSets"
                item-text="set_name"
                item-value="stores_set_key"
                :multiple="false"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline">
            <v-col class="my-3">
              <StoresSelectByProperties
                class="stores-select"
                :error-messages="editedItem.stores_set_key || editedItem.store_keys?.length ? '' : 'This field is required'"
                label="Stores"
                show-stores-select
                :selected-stores="editedItem.store_keys"
                @search-results-changed="v => editedItem.store_keys = Array.from(v)"
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline">
            <v-col class="mb-2">
              <StoresSelectByProperties
                class="stores-select"
                label="To stores"
                :error-messages="editedItem.to_stores_set_key || editedItem.to_store_keys?.length ? '' : 'This field is required'"
                show-stores-select
                :selected-stores="editedItem.to_store_keys"
                @search-results-changed="v => editedItem.to_store_keys = Array.from(v)"
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-4">
            <v-col class="py-1">
              <v-text-field
                :error-messages="doubleDecimal(editedItem.costs) || requiredField(editedItem.costs)"
                v-model.number="editedItem.costs"
                label="Costs"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-4">
            <v-col class="py-1">
              <v-text-field
                :error-messages="integerRule(editedItem.days) || requiredField(editedItem.days)"
                v-model.number="editedItem.days"
                label="Days"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-4 mb-4">
            <v-col class="py-0 pr-8">
              <v-menu
                v-model="minDatePickerActive"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="editedItem.date_min"
                    clearable
                    hide-details
                    label="Start"
                    @click:clear="editedItem.date_min = null"
                  />
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  :locale-first-day-of-year="4"
                  :show-week="true"
                  no-title
                  v-model="editedItem.date_min"
                  @click="minDatePickerActive = false"
                />
              </v-menu>
            </v-col>
            <v-col class="py-0 pr-8">
              <v-menu
                v-model="maxDatePickerActive"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="editedItem.date_max"
                    clearable
                    hide-details
                    label="Start"
                    @click:clear="editedItem.date_max = null"
                  />
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  :locale-first-day-of-year="4"
                  :show-week="true"
                  no-title
                  v-model="editedItem.date_max"
                  @click="maxDatePickerActive = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-col class="align-baseline mt-0 mt-4 my-0 py-0 mx-0" cols="6">
            <v-row class="py-0" align="center" justify="space-between">
              <label>Allowed</label>
              <v-checkbox
                v-model="editedItem.allowed"
              />
            </v-row>
          </v-col>
          <v-row class="align-baseline mt-2">
            <v-col class="py-1">
              <div class="py-3 label">Route</div>
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                ref="storeAutocomplete"
                class="pb-3"
                :items="stores"
                label="Add a store to route"
                item-text="store_name"
                return-object
                @change="addStoreToRoute"
                hide-details="auto"
                :error-messages="routeStoreErrors"
              />
              <order-list
                show-arrows
                :items="routeOrder"
                @update:items="updateRouteOrder"
                @delete="deleteRoute"
              />
            </v-col>
          </v-row>
        </template>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-row class="dialog-footer text-right py-2 px-4">
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="$emit('close')"
              >Cancel</v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                :disabled="!isValid"
                @click="$emit('save')"
                class="mx-4"
              >Save</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>

import OrderList from '@/components/OrderList.vue'
import { useGeneralStore } from '@/store/pinia/generalStore'
import StoresSelectByProperties from '@/components/StoresSelectByProperties.vue'

export default {
  name: 'TransferRulesFormDialog',
  components: { StoresSelectByProperties, OrderList },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    editedItem: {
      type: Object,
      required: true
    },
    storeSets: {
      type: Array,
      default: () => []
    },
    optionSets: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    return {
      generalStore: useGeneralStore(),
    }
  },
  data () {
    return {
      minDatePickerActive: false,
      maxDatePickerActive: false,
      types: [
        {
          text: 'All',
          value: 'all'
        },
        {
          text: 'Free',
          value: 'free'
        },
        {
          text: 'Box',
          value: 'box'
        },
        {
          text: 'Build',
          value: 'build'
        },
        {
          text: 'Consolidate',
          value: 'consolidate'
        }
      ],
      routeOrder: [],
      routeStoreErrors: ''
    }
  },
  computed: {
    stores () {
      return this.generalStore.appConfig?.stores || []
    },
    storesMap () {
      const m = new Map()
      this.stores.forEach(store => {
        m.set(store.store_key, store)
      })
      return m
    },
    isValid () {
      const { name, type, options_set_key, stores_set_key, store_keys, to_stores_set_key, to_store_keys, costs, days } = this.editedItem
      if (!name || !type || !options_set_key || (!stores_set_key && !store_keys?.length) || (!to_stores_set_key && !to_store_keys?.length)) {
        return false
      }
      if (this.integerRule(days)) {
        return false
      }
      return !this.doubleDecimal(costs)
    },
  },
  watch: {
    editedItem: {
      handler () {
        this.setRouteOrder()
      },
      immediate: true
    }
  },
  methods: {
    updateRouteOrder (items) {
      this.editedItem.route = items.map(item => item.value)
      this.setRouteOrder()
    },
    setRouteOrder () {
      this.routeOrder = this.editedItem.route?.map(storeKey => {
        const s = this.storesMap.get(storeKey)
        return {
          text: s.store_name,
          value: s.store_key
        }
      }) || []
    },
    addStoreToRoute (store) {
      this.routeStoreErrors = ''
      if (!this.editedItem.route) {
        this.editedItem.route = []
      }
      // Check if store is already in route
      if (this.editedItem.route.includes(store.store_key)) {
        this.routeStoreErrors = 'Store already in route'
        return
      }
      this.$refs.storeAutocomplete.internalValue = null
      this.editedItem.route = [...this.editedItem.route, store.store_key]
      this.setRouteOrder()
    },
    deleteRoute (index) {
      this.editedItem.route.splice(index, 1)
      this.setRouteOrder()
    },
    doubleDecimal (value) {
      // Maximum of 2 decimal places and number
      const regex = /^\d+(\.\d{1,2})?$/
      if (!regex.test(value.toString())) {
        return 'Please enter a valid number (max 2 decimal places)'
      }
      return null
    },
    integerRule (value) {
      if (!Number.isInteger(Number(value))) {
        return 'Please enter an integer'
      }
      if (value < 0) {
        return 'Please enter a positive integer'
      }
      return null
    },
    requiredField (value) {
      if (value === null || value === undefined || value === '') {
        return 'This field is required'
      }
      if (value.length === 0) {
        return 'This field is required'
      }
      return null
    },
  }
}

</script>
