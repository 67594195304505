<template>
  <v-card class="rounded-0">
    <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">
      {{edit ? 'Edit currency configuration': 'Add currency configuration'}}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="px-0 text--primary">
      <div class="container px-12">
        <template >
          <v-row class="align-baseline mt-4 mb-4">
            <v-col class="py-0">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                :items="budgets"
                item-text="budget_name"
                item-value="budget_key"
                clearable
                label="Budget"
                hide-details
                v-model="values.budget_key"
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-4 mb-4">
            <v-col class="py-0">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                :items="scenarios"
                item-text="scenario_name"
                clearable
                item-value="scenario_key"
                label="Scenario"
                hide-details
                v-model="values.scenario_key"
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-4 mb-4">
            <v-col class="py-0 pr-8">
              <v-menu
                ref="dateMinPicker"
                v-model="dateMinPickerActive"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    clearable
                    hide-details
                    :value="values.date_min || '-'"
                    label="From Date"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  :locale-first-day-of-year="4"
                  :show-week="true"
                  :min="new Date()"
                  no-title
                  v-model="values.date_min"
                />
              </v-menu>
            </v-col>
            <v-col class="py-0 pl-8">
              <v-menu
                ref="dateMaxPicker"
                v-model="dateMaxPickerActive"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    clearable
                    hide-details
                    :value="values.date_max || '-'"
                    label="To Date"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  :locale-first-day-of-year="4"
                  :show-week="true"
                  no-title
                  :min="new Date(values.date_min)"
                  v-model="values.date_max"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-4 mb-4">
            <v-col class="py-0">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                :items="currencies"
                clearable
                item-text="name"
                item-value="iso_code"
                label="From Currency (=1)"
                v-model="values.currency"
                :error-messages="errors.currency"
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-4 mb-4">
            <v-col class="py-0">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                :items="currencies"
                item-text="name"
                item-value="iso_code"
                clearable
                label="To Currency"
                v-model="values.to_currency"
                :error-messages="errors.to_currency"
              />
            </v-col>
          </v-row>
          <v-row class="align-baseline mt-4 mb-4">
            <v-col class="py-0" cols="6">
              <v-text-field
                label="Rate"
                clearable
                type="number"
                v-model="values.rate"
                :error-messages="errors.rate"
              />
            </v-col>
          </v-row>
        </template>
      </div>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-row class="dialog-footer text-right py-2 px-4">
        <v-col>
          <v-btn
            color="primary"
            depressed
            text
            @click="$emit('close')"
          >Cancel</v-btn>
          <v-btn
            color="primary"
            depressed
            outlined
            class="mx-4"
            @click="onSaveClick"
          >{{this.edit ? 'Save' : 'Add'}}</v-btn>
        </v-col>

      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'SettingsCurrencyForms',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    budgets: [],
    currency: {
      budget_key: null,
      scenario_key: null,
      date_min: '',
      date_max: '',
      currency: '-',
      to_currency: '-',
      rate: '1',
    }
  },
  data () {
    return {
      scenarios: [{ scenario_key: null, scenario_name: 'All scenario' }],
      dateMaxPickerActive: false,
      dateMinPickerActive: false,
      values: {
        budget_key: null,
        scenario_key: null,
        date_min: '',
        date_max: '',
        currency: '',
        to_currency: '',
        rate: '',
      },
      errors: {
        currency: '',
        to_currency: '',
        rate: '',
      },
      errorsMessages: {
        currency: 'From currency should not be empty',
        to_currency: 'To currency should not be empty',
        rate: 'Rate should not be empty',
      },
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    currencies () {
      const currencies = this.appConfig?.currencies || []

      return currencies.map((item) => {
        return {
          name: `${item.name} (${item.iso_code})`,
          iso_code: item.iso_code,
        }
      })
    }
  },
  methods: {
    onSaveClick () {
      this.errors = {
        currency: '',
        to_currency: '',
        rate: '',
      }
      const validations = [
        'currency',
        'to_currency',
        'rate']
      let validates = true
      validations.forEach((field) => {
        if (!this.values[field]) {
          this.errors[field] = this.errorsMessages[field]
          validates = false
        }
      })
      if (validates) {
        this.$emit('confirmation', this.values)
        this.$emit('close')
      }
    }
  },
  mounted () {
    if (this.edit) {
      this.values = this.currency
    }
  },
}
</script>

<style scoped>

</style>
