<template>
  <div
    class="analytics-filter-widget"
    @click="addProperty"
  >
    <v-card v-show="filtersOpened" class="filter-widget-card" id="filterForm" :key="propKey">
      <div class="filter-options pa-4">
        <div class="pt-4 px-4">
          <h4 class="pb-4 justify-space-between align-center d-flex">Dashboard filters
            <v-icon @click="resetDashboardFilter" class="">replay</v-icon>
          </h4>
          <div class="py-4">
            <v-row v-for="(newProp, index) of dashboardFilters" :key="index" class="flex-nowrap dashboard-filters  ma-0">
              <v-autocomplete
                :items="index === 'properties/article/option_key' ? [{value: index, text: 'Option Key'}] : filtersList"
                class="pr-4 flex xs6"
                :value="index"
                :filter="headersFilters"
                @input="(value)=> {delete dashboardFilters[index]; dashboardFilters[value]= []; propKey++; saveDashboardFilter()}"
                :label="`Property `"
              ></v-autocomplete>
              <collapsible-select
                v-if="index !== 'properties/article/option_key'"
                :items="(properties[index] || []).map((item)=> { return { text: formatString(item.toString()), value: item}})"
                v-model="dashboardFilters[index]"
                @input="lastProperty = 'dashboard'"
                ref="values"
                @change="isChangedFilters= true"
                show-select-all
                autocomplete
                multiple
                class="flex xs6 "
                label="Values"
              ></collapsible-select>
              <v-autocomplete
                v-else
                readonly
                disabled
                :items="[dashboardFilters[index][0]]"
                v-model="dashboardFilters[index][0]"
                ref="values"
                show-select-all
                autocomplete
                multiple
                class="flex xs6 "
                label="Values"
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    small
                    close
                    outlined
                  >
                    <span>{{ item }}</span>
                  </v-chip>
                  <span class="grey--text caption ml-1 plus-number">
                    +{{ dashboardFilters[index]?.length - 1 }}
                  </span>
                </template>
              </v-autocomplete>
              <v-flex class="align-baseline justify-center d-flex">
                <v-icon @click="removeDashboardFilter(index)">delete</v-icon>
              </v-flex>
            </v-row>

            <v-row class="flex-nowrap ma-0 dashboard-filters">
              <v-autocomplete
                :items="filtersList"
                :filter="headersFilters"
                v-model="choosenDashboardProperty"
                class="pr-4 flex xs6"
                :label="`Property`"
              ></v-autocomplete>
              <collapsible-select
                v-if="choosenDashboardProperty"
                autocomplete
                :class="{hidden: !chosenProperty}"
                multiple
                show-select-all
                @input="lastProperty = 'dashboard'"
                :items="((properties || {})[choosenDashboardProperty] || []).map((item)=> { return { text: formatString(item.toString()), value: item}})"
                v-model="dashboardValues"
                class="flex xs6 pr-0 "
                label="Values"
              ></collapsible-select>
              <div style="width: 24px"></div>
            </v-row>
            <template v-if="!hideViewFilters">
              <h4 class="py-4">View Filters</h4>
              <v-divider></v-divider>
              <v-expansion-panels accordion flat v-model="openedPanel">
                <template v-for="(views, viewListIndex) of viewsList">
                  <v-expansion-panel
                    v-for="(view, viewIndex) of views.filter(item=> Object.keys(item|| {}).length)" :key="getViewIndex(viewListIndex, viewIndex)"
                    class="view-panel"
                    @change="onPanelToggle(view)"
                  >
                    <v-expansion-panel-header class="pa-0 d-flex justify-space-between">
                      <h5 class="py-4 flex xs12">
                        {{getViewIndex(viewListIndex, viewIndex)}}. {{view.view_name}}(
                        <span :style="{color: getViewTitleColor(view.filters)}">{{Object.keys(view.filters || {}).length}} view
                        </span>
                        / {{numberOfDashboardFilter(view)}} dashboard )</h5>

                      <v-icon @click="resetViewFilter(view, index)" class="ml-2 v-expansion-panel-header__icon"

                      >replay</v-icon>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0">
                      <v-row class="mx-0 mb-0 pb-6 view-dataset-title">Dataset: {{formatString(view.dataset)}}</v-row>
                      <v-row v-for="(newProp, index) of view.filters" :key="index" class="flex-nowrap  ma-0">
                        <v-autocomplete
                          :items="getPropertiesForView(view)"
                          :class="{'orange--text-input': dashboardFilters && dashboardFilters[index]}"
                          class="pr-4 pb-6 flex xs6"
                          :value="index"
                          :filter="headersFilters"
                          :color="dashboardFilters && dashboardFilters[index] ? 'orange': ''"
                          :messages="dashboardFilters && dashboardFilters[index] ? 'Overruling dashboard filter': ''"
                          @input="(value)=> clearProperty(value, view, index, getViewIndex(viewListIndex, viewIndex))"
                          hide-details="auto"
                          label="Property"
                        ></v-autocomplete>
                        <collapsible-select
                          :items="((propertiesWithDataset[view.dataset]||{})[index] || []).map((item)=> { return { text: formatString(item.toString()), value: item}})"
                          v-model="view.filters[index]"
                          ref="values"
                          @input="lastProperty = view; lastIndex = getViewIndex(viewListIndex, viewIndex)"
                          show-select-all
                          multiple
                          autocomplete
                          class="flex xs6 "
                          label="Values"
                        ></collapsible-select>
                        <v-flex class="align-baseline pt-1 justify-center d-flex">
                          <v-icon @click="removeFilter(view, index, getViewIndex(viewListIndex, viewIndex))">delete</v-icon>
                        </v-flex>
                      </v-row>

                      <v-row class="flex-nowrap ma-0">
                        <v-autocomplete
                          :filter="headersFilters"
                          :items="getPropertiesForView(view)"
                          :color="dashboardFilters && dashboardFilters[chosenProperty] ? 'orange': ''"
                          v-model="chosenProperty"
                          :class="{'orange--text-input': dashboardFilters && dashboardFilters[chosenProperty]}"
                          class="pr-7 flex xs6 pb-6"
                          :label="`Property`"
                        ></v-autocomplete>
                        <collapsible-select
                          v-if="chosenProperty"
                          autocomplete
                          show-select-all
                          :class="{hidden: !chosenProperty}"
                          @input="lastProperty = view; lastIndex = getViewIndex(viewListIndex, viewIndex)"
                          multiple
                          :items="((propertiesWithDataset[view.dataset] || {})[chosenProperty] || []).map((item)=> { return { text: formatString(item.toString()), value: item}})"
                          v-model="values"
                          class="flex xs6 "
                          label="Values"
                        ></collapsible-select>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
            </template>
          </div>
        </div>
      </div>
      <div class="controls" v-if="!hideViewFilters || isChangedFilters">
        <v-divider />
        <div class="mt-6 pb-6 pr-6 text-right ">
          <v-btn text color="primary" class="mr-2" @click="resetDefault"
            v-if=" !hideViewFilters && !dashboardDetails.personal">Reset default</v-btn>
          <v-btn text color="primary" v-if="!hideViewFilters" class="mr-2" @click="resetAll">Reset All</v-btn>
          <v-btn outlined color="primary"
            v-if="!hideViewFilters || isChangedFilters"
            @click="onApplyClick">
            Apply
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>

import { formatString } from '@/variables'
import CollapsibleSelect from '@/components/CollapsibleSelect'
import { saveDashboard, saveView } from '@/api/analytics'
import { headersFilters } from '@/utils'
import { useAnalyticsFiltersStore } from '@/store/pinia/analyticsFiltersStore'

export default {
  name: 'AnalyticsFilterWidget',
  components: { CollapsibleSelect },
  props: ['hideViewFilters'],
  setup () {
    return {
      analyticsFiltersStore: useAnalyticsFiltersStore()
    }
  },
  data () {
    return {
      filtersChanged: false,
      isChangedFilters: false,
      openedPanel: null,
      lastProperty: '',
      dashboardFilters: {},
      datasetsForProperties: new Set(),
      lastIndex: null,
      newProperties: [],
      propKey: 0,
      chosenProperty: '',
      choosenDashboardProperty: '',
      values: [],
      dashboardValues: []
    }
  },
  watch: {
    viewsList: {
      async handler () {
        for (const views of this.viewsList) {
          for (const view of views.filter(item => Object.keys(item || {}).length)) {
            if (!this.datasetsForProperties.has(view.dataset)) {
              this.datasetsForProperties.add(view.dataset)
            }
          }
        }
      },
      deep: true
    },
    dashboardDetails: {
      handler () {
        this.dashboardFilters = this.dashboardDetails.filters || {}
      },
      immediate: true,
    },
    panelStore (newValue) {
      this.openedPanel = newValue
    },
  },
  mounted () {
    this.openedPanel = this.analyticsFiltersStore.singleFiltersOpened
    if (this.$route.query.option_key) {
      setTimeout(() => {
        this.lastProperty = 'dashboard'
        this.choosenDashboardProperty = 'properties/article/option_key'
        this.dashboardValues = [this.$route.query.option_key]
        this.addProperty()
      }, 1000)
    }
  },
  computed: {
    panelStore () {
      return this.analyticsFiltersStore.singleFiltersOpened
    },
    dashboardDetails () {
      const dashboardDetails = this.analyticsFiltersStore.dashboardDetails
      if (!dashboardDetails.filters) {
        dashboardDetails.filters = {}
      }
      return dashboardDetails
    },
    filtersList () {
      return this.getFiltersList(this.properties)
    },
    propertiesWithDataset () {
      return this.analyticsFiltersStore.propertiesWithDataset
    },
    properties () {
      const properties = this.analyticsFiltersStore.properties
      return this.sortProperties(properties)
    },
    filtersOpened () {
      return this.analyticsFiltersStore.filtersOpened
    },
    viewsList () {
      return this.analyticsFiltersStore.viewsList
    },
  },
  methods: {
    getViewTitleColor (viewFilters){
      for(const key in viewFilters){
        if(this.dashboardFilters[key]){
          return 'orange'
        }
      }
    },
    resetDefault () {
      saveDashboard({ dashboard_key: this.$route.query.dashboard_key, reset_filters: true }).then(({ data: { dashboard } }) => {
        this.analyticsFiltersStore.setDashboardDetails(dashboard)
        this.analyticsFiltersStore.saveChangedFilters('default')
        this.onApplyClick()
      })
    },
    onApplyClick () {
      setTimeout(() => {
        this.analyticsFiltersStore.setFiltersOpened(false)
        this.$emit('filters-updated')
        this.isChangedFilters = false
      }, 100)
    },
    getViewIndex (viewListIndex, viewIndex) {
      if(this.dashboardDetails?.view_keys[viewListIndex]?.filter(item=> item).length === 1){
        return viewListIndex + 1
      }
      return `${viewListIndex + 1}${['A', 'B', 'C', 'D'][viewIndex]}`
    },
    headersFilters,
    getPropertiesForView (view) {
      return this.getFiltersList(this.sortProperties({ ...(this.propertiesWithDataset[view.dataset] || {}) }))
    },
    getFiltersList (properties) {
      const array = []
      const headers = {}
      for (const key of Object.keys(properties || {}).sort()) {
        const title = key.split('/')
        if (headers[title[1]] === undefined) {
          headers[title[1]] = { header: formatString(title[1] || ''), text: [] }
          array.push(headers[title[1]])
        }
        headers[title[1]].text.push(formatString(title[2] || ''), formatString(title[1] || ''))
        array.push({
          value: key,
          headerTitle: formatString(title[1] || ''),
          text: formatString(title[2] || '')
        })
      }
      if (!array.length) {
        return []
      }
      return array
    },
    sortProperties (properties) {
      properties = JSON.parse(JSON.stringify(properties || {}))
      for (const key in properties) {
        if (!isNaN(parseFloat(properties[key][0]))) {
          properties[key].sort((a, b) => {
            return parseFloat(b) - parseFloat(a)
          })
        } else {
          properties[key].sort()
        }
      }
      return properties
    },
    numberOfDashboardFilter (view) {
      let count = 0
      for (const key in this.dashboardFilters) {
        if (this.propertiesWithDataset[view.dataset] && this.propertiesWithDataset[view.dataset][key]) {
          count++
        }
      }
      return count
    },
    addProperty () {
      if (this.lastIndex || this.lastProperty) {
        this.analyticsFiltersStore.saveChangedFilters((this.lastIndex || this.lastProperty))
      }
      if (this.lastProperty === 'dashboard') {
        this.saveDashboardFilter()
        if (this.lastProperty) {
          saveDashboard({ ...this.dashboardDetails, dashboard_key: this.$route.query.dashboard_key })
          this.lastProperty = ''
        }
      } else {
        this.addViewFilters()
        if (this.lastProperty) {
          saveView({ ...this.lastProperty, dashboard_key: this.$route.query.dashboard_key })
          this.lastProperty = ''
        }
      }
    },
    clearProperty (value, view, index, viewIndex) {
      delete view.filters[index]
      view.filters[value] = []
      this.propKey++
      this.lastProperty = view
      this.lastIndex = viewIndex
      this.analyticsFiltersStore.saveChangedFilters(viewIndex)

      saveView({ ...view, dashboard_key: this.$route.query.dashboard_key }).then((view) => {
        if (view.response) {
          alert(view.response.data.message)
        }
      })
    },
    resetViewFilter (view, viewIndex) {
      view.filters = {}
      saveView({ ...view, dashboard_key: this.$route.query.dashboard_key }).then((view) => {
        if (view.response) {
          alert(view.response.data.message)
        }
      })

      this.analyticsFiltersStore.saveChangedFilters(viewIndex)
    },
    resetDashboardFilter () {
      this.dashboardFilters = {}
      this.dashboardDetails.filters = {}
      this.analyticsFiltersStore.saveChangedFilters('dashboard')
      saveDashboard({ dashboard_key: this.$route.query.dashboard_key, ...this.dashboardDetails })
    },
    removeDashboardFilter (index) {
      delete this.dashboardFilters[index]
      this.dashboardFilters = { ...this.dashboardFilters }
      this.dashboardDetails.filters = this.dashboardFilters
      this.isChangedFilters = true
      this.analyticsFiltersStore.saveChangedFilters('dashboard')
      saveDashboard({ dashboard_key: this.$route.query.dashboard_key, ...this.dashboardDetails })
    },
    removeFilter (view, index, viewIndex) {
      delete view.filters[index]
      view.filters = { ...view.filters }
      saveView({ ...view, dashboard_key: this.$route.query.dashboard_key }).then((view) => {
        if (view.response) {
          alert(view.response.data.message)
        }
      })
      this.analyticsFiltersStore.saveChangedFilters(viewIndex)
    },
    resetAll () {
      this.analyticsFiltersStore.setDashboardDetails({})
      this.dashboardDetails.filters = {}
      this.$set(this.dashboardDetails, 'filters', {})
      saveDashboard({ dashboard_key: this.$route.query.dashboard_key, ...this.dashboardDetails })
      this.viewsList.forEach((views) => {
        for (const view of views.filter(item => Object.keys(item || {}).length)) {
          view.filters = {}
          saveView({ ...view, dashboard_key: this.$route.query.dashboard_key }).then((view) => {
            if (view.response) {
              alert(view.response.data.message)
            }
          })
        }
      })
      this.viewsList = [...this.viewsList]
      this.analyticsFiltersStore.saveChangedFilters('dashboard')
      this.onApplyClick()
    },
    saveDashboardFilter () {
      if (!this.choosenDashboardProperty || !this.dashboardValues.length) {
        return
      }
      this.dashboardFilters[this.choosenDashboardProperty] = [...this.dashboardValues]
      this.dashboardFilters = { ...this.dashboardFilters }
      this.choosenDashboardProperty = ''
      this.dashboardDetails.filters = this.dashboardFilters
      this.isChangedFilters = true
      saveDashboard({ dashboard_key: this.$route.query.dashboard_key, ...this.dashboardDetails })
      this.dashboardValues = []
      this.lastProperty = ''
      this.propKey++
    },
    addViewFilters () {
      if (!this.chosenProperty || !this.values.length || !this.lastProperty) {
        return
      }
      this.lastProperty.filters[this.chosenProperty] = [...this.values]
      this.lastProperty.filters = { ...this.lastProperty.filters }
      saveView({ ...this.lastProperty, dashboard_key: this.$route.query.dashboard_key }).then((view) => {
        if (view.response) {
          alert(view.response.data.message)
          // return
        }
      })
      this.chosenProperty = ''
      this.values = []
      this.lastProperty = ''
    },
    onPanelToggle (view) {
      this.dataset = view.dataset
    },
    loadProperties (dataset) {
      return this.analyticsFiltersStore.loadProperties(dataset || this.dataset)
    },
    formatString,
  },
}
</script>

<style lang="scss">
.analytics-filter-widget {
  position: fixed;
  top: 56px;
  right: 0;
  z-index: 6;
  .v-input {
    font-size: 14px;
  }
  .view-dataset-title {
    font-size: 12px;
    color: rgba(0,0,0,0.6);
  }
  .v-expansion-panel-content__wrap{
    padding: 0;
  }

  .view-panel{
    border-bottom: 1px solid #DADCDF;
  }
  .v-select__selection--comma {
    margin-bottom: 2px;
  }

  .orange--text-input {
    color:#ff9800 !important;
  *{
    color:#ff9800 !important;
  }
  }
  .v-text-field {
    .v-label {
      font-size: 14px;
    }
    .v-label--active {
      font-size: 12px;
      transform: translateY(-20px);
    }
  }
  .values-select {
    .v-chip .v-chip__content {
      & > span {
        width: 20px;
        overflow: hidden;
      }
    }
  }
  .v-select.v-select--chips .v-select__selections {
    min-height: 32px;
  }
  .filter-widget-card {
    box-shadow: inset 0 2px 2px #ccc !important;
    width: 500px;
    height: calc(calc(100vh / var(--zoom)) - 56px);
    overflow: hidden;
    z-index: 1000;
    border-radius: 0 !important;
    justify-content: center;
    align-items: start;
  }
  h4 {
    font-size: 16px;
  }
  .filter-options {
    height: calc(100% - 85px);
    margin-right: 12px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
  }
}
</style>
