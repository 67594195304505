<template>
  <resizable
    class="transferTable p-relative"
    @resize="updateTotalTableWidth"
  >
    <v-overlay absolute color="white"
      :value="buildingTransfers || acceptingTransfer">
      <v-progress-circular color="primary" indeterminate size="64" width="2"/>
    </v-overlay>
    <v-row class="align-center">
      <v-col cols="2">
        <v-select
          v-model="activeType"
          class="ml-4"
          @input="$emit('type-changed')"
          :items="['Combinations', 'Send', 'Receive', 'Overview']"
        ></v-select>
      </v-col>
      <v-col class="text-right pr-6">
        <TableSearch class="mx-2" @searchChanged="handleSearchChanged"/>
        <DataExport
          @item-click="downloadData"
        />
      </v-col>
    </v-row>
    <data-tables
      ref="transfersTable"
      :key="transfersListKey"
      :data="tableData"
      :page-size="5"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 mb-4 mr-2',
      }"
      :filters="transfersTableFilters"
      @expand-change="handleExpandChange"
    >
      <data-tables
        slot="append"
        :index="null"
        class="total-table"
        :data="[totalsData]"
        :pagination-props="{class: 'd-none'}"
        :key="totalsExcludeKey"
      >
        <template v-if="lowerCaseType === 'overview'">
          <el-table-column label-class-name="d-none" class-name="total-row_column" width="47"/>
          <el-table-column
            class-name="total-row_column"
            label-class-name="d-none"
          >
            <template>
              <div>Total</div>
            </template>
          </el-table-column>
          <el-table-column
            class-name="total-row_column"
            label-class-name="d-none"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.stock | formatThousands }}</div>
            </template>
          </el-table-column>
          <el-table-column
            class-name="total-row_column"
            label-class-name="d-none"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.receive | formatThousands }}</div>
            </template>
          </el-table-column>
          <el-table-column
            class-name="total-row_column"
            label-class-name="d-none"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.pieces | formatThousands }}</div>
            </template>
          </el-table-column>
          <el-table-column
            class-name="total-row_column"
            label-class-name="d-none"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.goal | formatThousands }}</div>
            </template>
          </el-table-column>
          <el-table-column
            class-name="total-row_column"
            label-class-name="d-none"
            width="150"
          >
            <!-- If not attaching scope to the template, transfersBtnDisabled change is not reflected in this template. Not sure why this happens-->
            <!-- eslint-disable vue/no-unused-vars -->
            <template slot-scope="scope">
              <v-btn
                color="primary"
                depressed
                outlined
                :disabled="!!expandedRow || tableData?.some(item => item.accepted) || transfersBtnDisabled"
                width="130"
                @click="handleAcceptTransfersClick"
              >Accept all
              </v-btn>
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column label-class-name="d-none" class-name="total-row_column" width="47"/>
          <el-table-column
            class-name="total-row_column"
            label-class-name="d-none"
            width="260"
          >
            <template>
              <div>Total</div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="index in 4"
            :key="index"
            class-name="total-row_column"
            label-class-name="d-none"
          >
          </el-table-column>
          <el-table-column
            class-name="total-row_column"
            label-class-name="d-none"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.pieces | formatThousands }}</div>
            </template>
          </el-table-column>
          <el-table-column
            class-name="total-row_column"
            label-class-name="d-none"
            width="150"
          >
            <!-- If not attaching scope to the template, transfersBtnDisabled change is not reflected in this template. Not sure why this happens-->
            <!-- eslint-disable vue/no-unused-vars -->
            <template slot-scope="scope">
              <v-btn
                color="primary"
                depressed
                outlined
                :disabled="!!expandedRow || tableData?.some(item => item.accepted) || transfersBtnDisabled"
                width="130"
                @click="handleAcceptTransfersClick"
              >Accept all
              </v-btn>
            </template>
          </el-table-column>
        </template>
      </data-tables>

      <template v-if="lowerCaseType === 'overview'">
        <el-table-column width="45" class="expand-column">
          <template slot-scope="scope">
            <v-icon @click="handleExpandClick(scope.row)" class="my-2">
              <template v-if="expandedRow !== scope.row">
                chevron_right
              </template>
              <template v-else>
                expand_more
              </template>
            </v-icon>
          </template>
        </el-table-column>
        <el-table-column type="expand" width="1">
          <template slot-scope="props">
            <!-- Using slot-scope here to prevent double render of TransfersBuildDetails component. Not sure why this happens -->
            <TransfersBuildDetails
              ref="transfersDetails"
              :type="lowerCaseType"
              :row="props.row"
              :loading-transfer-build-details="loadingTransferBuildDetails"
              :transfer-build-details-data="transferBuildDetailsData"
              @error-added="detailsErrors = {...$event}"
              @pieces-changed="handleTransferPiecesChanged"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="Store"
          sortable="custom"
          prop="storeName"
        >
          <template slot-scope="scope">
            <div class="one-line" v-if="scope.row.storeName">
              {{ scope.row.storeName }}
            </div>
            <span v-else>{{ scope.row.store_key }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Stock" sortable="custom" prop="stock">
          <template slot-scope="scope">
            {{ scope.row.stock | formatThousands }}
          </template>
        </el-table-column>
        <el-table-column label="Receive" sortable="custom" prop="receive">
          <template slot-scope="scope">
            {{ scope.row.receive | formatThousands }}
          </template>
        </el-table-column>
        <el-table-column label="Transfer" sortable="custom" prop="pieces">
          <template slot-scope="scope">
            {{ scope.row.pieces | formatThousands }}
          </template>
        </el-table-column>
        <el-table-column label="Goal" sortable="custom" prop="goal">
          <template slot-scope="scope">
            {{ scope.row.goal | formatThousands }}
          </template>
        </el-table-column>
        <el-table-column label="" prop="" width="150">
          <template slot-scope="scope">
            <v-btn
              color="primary"
              depressed
              :disabled="!scope.row.pieces
                || !!scope.row.accepted
                || (expandedRow && expandedRow !== scope.row)
                || !!Object.keys(detailsErrors).length"
              outlined
              width="130"
              @click="handleAcceptClick(scope.row)"
            >
              <span v-if="scope.row.accepted">Accepted</span>
              <span v-else>Accept</span></v-btn>
          </template>
        </el-table-column>
      </template>
      <template v-else>
        <el-table-column width="45" class="expand-column">
          <template slot-scope="scope">
            <v-icon @click="handleExpandClick(scope.row)" class="my-2">
              <template v-if="expandedRow !== scope.row">
                chevron_right
              </template>
              <template v-else>
                expand_more
              </template>
            </v-icon>
          </template>
        </el-table-column>
        <el-table-column type="expand" width="1">
          <template slot-scope="props">
            <!-- Using slot-scope here to prevent double render of TransfersBuildDetails component. Not sure why this happens -->
            <TransfersBuildDetails
              ref="transfersDetails"
              :type="lowerCaseType"
              :row="props.row"
              :loading-transfer-build-details="loadingTransferBuildDetails"
              :transfer-build-details-data="transferBuildDetailsData"
              @error-added="detailsErrors = {...$event}"
              @pieces-changed="handleTransferPiecesChanged"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="Store"
          sortable="custom"
          prop="storeName"
          :width="130"
        >
          <template slot-scope="scope">
            <div class="one-line" v-if="scope.row.storeName">
              {{ scope.row.storeName }}
            </div>
            <span v-else>{{ scope.row.store_key }}</span>
          </template>
        </el-table-column>
        <el-table-column label="To Store" sortable="custom" prop="toStoreName" width="130">
          <template slot-scope="scope">
            <div class="one-line" v-if="scope.row.toStoreName">
              {{ scope.row.toStoreName }}
            </div>
            <span v-else>{{ scope.row.to_store_key }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Stock" sortable="custom" prop="stock">
          <template slot-scope="scope">
            {{ scope.row.stock | formatThousands }}
          </template>
        </el-table-column>
        <el-table-column label="To stock" sortable="custom" prop="to_stock">
          <template slot-scope="scope">
            {{ scope.row.to_stock | formatThousands }}
          </template>
        </el-table-column>
        <el-table-column label="Fairshare" sortable="custom" prop="fairshare">
          <template slot-scope="scope">
            {{ scope.row.fairshare | formatThousands }}
          </template>
        </el-table-column>
        <el-table-column label="To fairshare" sortable="custom" prop="to_fairshare">
          <template slot-scope="scope">
            {{ scope.row.to_fairshare | formatThousands }}
          </template>
        </el-table-column>
        <el-table-column label="Transfer" sortable="custom" prop="pieces">
          <template slot-scope="scope">
            {{ scope.row.pieces | formatThousands }}
          </template>
        </el-table-column>
        <el-table-column label="" prop="" width="150">
          <template slot-scope="scope">
            <v-btn
              color="primary"
              depressed
              :disabled="!scope.row.pieces
                || !!scope.row.accepted
                || (expandedRow && expandedRow !== scope.row)
                || !!Object.keys(detailsErrors).length"
              outlined
              width="130"
              @click="handleAcceptClick(scope.row)"
            >
              <span v-if="scope.row.accepted">Accepted</span>
              <span v-else>Accept</span></v-btn>
          </template>
        </el-table-column>
      </template>
    </data-tables>

    <!-- Close confirm dialog -->
    <v-dialog
      :value="closeDialogVisible"
      persistent
      content-class="align-self-start mt-10"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Close transfer
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">You will lose any changes made in this transfer. Continue?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleCloseCancel"
              >Cancel
              </v-btn>
              <v-btn
                depressed
                outlined
                @click="handleCloseConfirm"
                class="red--text mx-4"
              >Ok
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </resizable>
</template>
<script>
import { loadConsolidateTransfers } from '@/api/consolidate'
import { saveTransfers } from '@/api/stockTransfersAPI'
import TableSearch from '@/components/TableSearch.vue'
import TransfersBuildDetails from '@/components/Stock/TransfersBuildDetails.vue'
import { downloadData } from '@/utils'
import DataExport from '@/components/DataExport'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import Resizable from '@/components/Utility/Resizable.vue'
import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'

export default {
  setup () {
    return {
      stockTransfersStore: useStockTransfersStore(),
    }
  },
  props: {
    buildingTransfers: {
      type: Boolean,
      default: false,
    },
    transfersData: {
      type: Object,
      default: () => ({}),
    },
    storeKeys: {
      type: Array,
      default: () => [],
    },
    exclusions: {
      type: Object,
      default: () => ({}),
    },
    remainderStoreKey: {
      type: Number || null,
      default: null,
    },
  },
  name: 'ConsolidateTransfersTable',
  components: {
    Resizable,
    DataExport,
    TableSearch,
    TransfersBuildDetails,
  },
  data () {
    return {
      loadingTransferBuildDetails: false,
      acceptingTransfer: false,
      transferBuildDetailsData: [],
      activeType: 'Combinations',
      detailsErrors: {},
      loaded: false,
      transfersListKey: 0,
      totalsExcludeKey: 0,
      transfersTableFilters: [
        {
          value: ''
        }
      ],
      expandedRow: null,
      expandedRowHasChanges: false,
      rowExpandClicked: null,
      closeDialogVisible: false,
      transfersBtnDisabled: false,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['findStoreWithKey']),
    lowerCaseType () {
      return this.activeType.toLowerCase()
    },
    tableData () {
      return this.transfersData.parent_table.map(row => ({
        ...row,
        storeName: this.findStore(row.store_key)?.store_name,
        toStoreName: this.findStore(row.to_store_key)?.store_name,
      }))
    },
    totalsData () {
      return this.transfersData.total
    },
  },
  watch: {
    expandedRow () {
      this.totalsExcludeKey++
    },
    buildingTransfers (newVal) {
      if (newVal) {
        // close row if there is opened one
        if (this.expandedRow) {
          this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
          this.expandedRow = null
        }
      }
    },
  },
  methods: {
    downloadData (sep) {
      const data = this.tableData
      downloadData(sep, data, 'Transfers_Build')
    },
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = this.$el.querySelector('.el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 100)
    },
    findStore (storeKey) {
      return this.findStoreWithKey(storeKey)
    },
    async getTransfersForBuild (row) {
      if (this.expandedRow === row && this.$refs.transfersDetails?.currentPageRows) {
        return this.$refs.transfersDetails.getTransfersForAccept()
      }

      const payload = {
        store_keys: this.storeKeys,
        child_store_key: row.store_key,
        child_to_store_key: row.to_store_key,
        exclusions: this.exclusions,
      }

      if (this.remainderStoreKey) {
        payload.remainder_store_key = this.remainderStoreKey
      }

      const { child_table } = await loadConsolidateTransfers(payload)

      return child_table
    },
    async handleExpandChange (row) {
      const payload = {
        store_keys: this.storeKeys,
        child_store_key: row.store_key,
        child_to_store_key: row.to_store_key,
        exclusions: this.exclusions,
      }

      if (this.remainderStoreKey) {
        payload.remainder_store_key = this.remainderStoreKey
      }

      // close row if there is opened one
      if (this.expandedRow) {
        this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
        this.transferBuildDetailsData = []
      }

      // save opended row in component and state
      this.expandedRow = this.expandedRow ? null : row

      // load data for opened row
      if (this.expandedRow) {
        this.loadingTransferBuildDetails = true
        try {
          const result = await loadConsolidateTransfers(payload)
          this.transferBuildDetailsData = Object.freeze(result.child_table)
        } finally {
          this.loadingTransferBuildDetails = false
        }
      }
    },
    handleSearchChanged (val) {
      if (this.expandedRow) {
        this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
        this.expandedRow = null
      }
      this.transfersTableFilters[0].value = val
      this.transfersListKey++
      this.updateTotalTableWidth()
    },
    handleExpandClick (row) {
      this.detailsErrors = {}
      if (this.expandedRow && this.expandedRowHasChanges) {
        this.rowExpandClicked = row
        this.closeDialogVisible = true
      } else {
        this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(row, row !== this.expandedRow)
      }
    },
    handleCloseCancel () {
      this.closeDialogVisible = false
      this.rowExpandClicked = null
    },
    handleCloseConfirm () {
      this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.rowExpandClicked, this.rowExpandClicked !== this.expandedRow)
      this.closeDialogVisible = false
      this.rowExpandClicked = null
      this.expandedRowHasChanges = false
    },
    async handleAcceptClick (row) {
      this.$set(row, 'accepted', true)
      const rows = await this.getTransfersForBuild(row)
      const payload = []
      rows.forEach(item => {
        if (item.pieces <= 0) {
          return
        }

        const newItem = {
          to_store_key: item.to_store_key,
          // to_store_key: 104,
          store_key: item.store_key,
          // store_key: 108,
          sku_key: item.sku_key,
          pieces: item.pieces,
          stock: item.stock,
          keep: (item.stock - item.pieces),
          type: 'consolidate',
          profit: 0,
          store_exclusion: item.store_exclusion
        }
        payload.push(newItem)
      })

      this.acceptingTransfer = true

      saveTransfers(payload)
        .then(() => {
          this.$refs.transfersTable.$refs.elTable.toggleRowExpansion(this.expandedRow, false)
          this.expandedRow = null
          this.acceptingTransfer = false
          this.$emit('transfers:accepted')
        })
    },
    handleAcceptTransfersClick () {
      this.$emit('accept-transfers-click')
    },
    handleTransferPiecesChanged () {
      this.expandedRowHasChanges = true
    },
  },
}
</script>

<style lang="scss">
.transferTable {
  .total-row_column {
    background-color: #f5f7fa;
  }

  .el-table__expand-icon {
    display: none;
  }
  .el-table td {
    padding: 0;
  }
  .el-table .cell {
    padding: 4px 10px ;
  }

  .el-table__expanded-cell[class*=cell] {
    padding: 16px;
    background: #f9f9f9;

    &:hover {
      background: #f9f9f9 !important;
    }
  }

  .one-line {
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
