import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'

export function getOrderDefaultParameters () {
  const url = baseURLs.logicAPI + '/order'

  return axios
    .get(url)
    .then(response => response.data)
    .catch(error => {
      console.log(error)
    })
}
