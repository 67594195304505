// src/api/closure.ts
import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'
import {Closure, ClosureCreateRequest, ClosuresUpdateRequest} from '@/models/Closure'


export async function getClosures (past: boolean, stores: number[]) : Promise<Closure[]> {
  const url = baseURLs.manageAPI + '/closures'
  const response = await axios.get(url, { params: { past, stores } })
  return response.data.closures as Closure[]
}


export async function deleteClosure (payload: Closure) {
  const url = baseURLs.manageAPI + '/closures'
  await axios.delete(url, { data: [payload] })
}
export async function createClosures (payload: ClosureCreateRequest) {
  const url = baseURLs.manageAPI + '/closures'
  await axios.post(url, payload)
}

export async function updateClosures (payload: ClosuresUpdateRequest) {
  const url = baseURLs.manageAPI + '/closures'
  await axios.put(url, payload)
}
