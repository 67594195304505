<template>
  <div class="shop-orders">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-row>
      <v-col class="text-right px-8">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('tableFilters', columnsToSearch, val)"
        />
        <DataExport
          @item-click="downloadOrdersData"
        />
        <slot name="actions"></slot>
        <v-icon class="mx-2 action-btn-primary" :disabled="selectedRows.length !== 1" @click="handleEditOrderClick()">edit</v-icon>
        <v-icon
          class="mx-2 action-btn-danger"
          :disabled="deleteDisabled"
          @click="handleDeleteOrderClick"
        >delete</v-icon>
        <v-icon
          class="mx-2 action-btn-danger"
          :disabled="!selectedRows.length"
          @click="handleRefreshClick"
        >replay</v-icon>
        <v-menu offset-y offset-x left nudge-bottom="5" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on"
            >filter_list</v-icon>
          </template>
          <v-card max-height="400" class="overflow-auto">
            <h5 class="px-2 pt-2">Columns</h5>
            <el-checkbox-group v-model="selectedColumns" @input="saveSelectedColumns" style="font-size: 14px" class="d-flex flex-column pa-2">
              <el-checkbox v-for="item of columnsToSelect" :label="item" :key="item"></el-checkbox>
            </el-checkbox-group>
            <h5 class="px-2">Order Properties</h5>
            <el-checkbox-group v-model="additionalOrderColumns" @input="savePropertiesAdditional" style="font-size: 14px" class="d-flex flex-column pa-2">
              <el-checkbox v-for="item of sortedOrderPropertiesAdditional" :label="item" :key="item">
                {{ item | formatString }}
              </el-checkbox>
            </el-checkbox-group>
            <h5 class="px-2">Properties</h5>
            <el-checkbox-group v-model="additionalColumns" @input="saveProperties" style="font-size: 14px" class="d-flex flex-column pa-2">
              <el-checkbox v-for="item of sortedOrderProperties" :label="item" :key="item">
                {{ item | formatString }}
              </el-checkbox>
            </el-checkbox-group>
          </v-card>
        </v-menu>

      </v-col>
    </v-row>
    <v-row>
      <v-col class="p-relative">
        <h4 class="text-no-wrap px-6 mb-4">{{selectedRows.length}} line(s) selected
          <span v-if="selectedRows.length > 1">(changes are applied to all selected rows)</span>
        </h4>

        <data-tables
          id="orders_table"
          style="width: 100%"
          class="mb-4"
          :data="ordersList"
          @filtered-data="filteredData = $event"
          :page-size="ordersPageSize"
          @update:pageSize="onShopOrderPageSizeUpdate"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :table-props="{ 'row-key': 'order_key',
                          'summary-method': this.getSummary,
                          'show-summary': true, }"
          :filters="tableFilters"
          filter-multiple
          @filter-change="filters => handleFilterChange('tableFilters', filters)">
          <el-table-column prop="timeline_key" width="55">
            <template v-slot:header>
              <v-simple-checkbox
                color="primary"
                class="ml-4"
                :value="allRowsSelected"
                :ripple="false"
                dense
                hide-details
                @input="handleSelectAllChange"
              />
            </template>
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-4 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Order ID')"
            :filters="getColumnFilterValues('ordersList', 'order_key')"
            column-key="order_key"
            prop="order_key" label="Order ID" sortable="custom">
            <template slot-scope="scope">
              <div @click.stop="handleOrderIdClick(scope.row)" class="id-cell">
                {{ scope.row.order_key }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('External ID')"
            :filters="getColumnFilterValues('ordersList', 'external_order_id')"
            column-key="external_order_id"
            prop="external_order_id"
            label="External ID"
            sortable="custom"
          >
            <template slot-scope="scope">

              <v-text-field
                :disabled="scope.row.status === 'to_order'"
                @change="handleOrderChanged(scope.row, 'external_order_id',$event)"
                v-model="scope.row.external_order_id"
              >
              </v-text-field>            </template>
          </el-table-column>

          <el-table-column
            v-if="selectedColumns.includes('Name')"
            :filters="getColumnFilterValues('ordersList', 'shopItemName')"
            column-key="shopItemName"
            prop="shop_item_key" label="Name" sortable="custom">
            <template slot-scope="scope">
              <template v-if="typeof  scope.row.shopItemName === 'string'">
                <div class=" option-data-cell--general d-flex flex-column align-start">
                  <router-link
                    :title="scope.row.shopItemName"
                    class="option-name"
                    target="_blank"
                    :to="{
                      name: 'buy-shop-item-page',
                      query: {
                        shop_item_key: scope.row.shop_item_key,
                        scenario_key: $route.query?.scenario_key,
                        budget_key: $route.query?.budget_key
                      }
                    }">
                    <p class="option-title mb-0"
                    >{{ scope.row.shopItemName.split('/')[0] }}</p>
                  </router-link>
                  <p class="option-subtitle mb-0">
                    {{ scope.row.shopItemName.split('/')[1]}}
                  </p>
                </div>
              </template>
              <template v-else>
                <div class=" option-data-cell--general d-flex flex-column align-start">
                  <router-link
                    :title="scope.row.shopItemName"
                    class="option-name"
                    target="_blank"
                    :to="{
                      name: 'buy-shop-item-page',
                      query: {
                        shop_item_key: scope.row.shop_item_key,
                        scenario_key: $route.query?.scenario_key,
                        budget_key: $route.query?.budget_key
                      }
                    }">
                    <p class="option-title mb-0"
                    >{{ scope.row.shopItemName}}</p>
                  </router-link>
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column :prop="item" :label="item | formatString" sortable="custom"
            :key="item"
            :column-key="item"
            :filters="getColumnFilterValues('ordersList', item)"
            v-for="item in additionalOrderColumns">
            <template slot-scope="scope">
              {{ scope.row[item]}}
            </template>
          </el-table-column>
          <el-table-column :prop="item" :label="item | formatString" sortable="custom"
            :key="item"
            :column-key="item"
            :filters="getColumnFilterValues('ordersList', item)"
            v-for="item in additionalColumns">
            <template slot-scope="scope">
              {{ scope.row[item]}}
            </template>
          </el-table-column>
          <el-table-column
            :filters="getColumnFilterValues('ordersList', 'pieces_total')"
            column-key="pieces_total"
            v-if="selectedColumns.includes('Pieces')"
            prop="pieces_total" label="Pieces" sortable="custom">
            <template slot-scope="scope">
              <v-text-field
                :disabled="scope.row.status === 'to_order' || scope.row.cross_dock_pieces > 0 || scope.row.prepack_pieces > 0"
                @change="scope.row.pieces_total < 0 || !Number.isInteger(scope.row.pieces_total) ? ()=> {}: handleOrderChanged(scope.row, 'pieces_total', parseFloat($event.toString().replace(',', '.') || 0))"
                :value="scope.row.pieces_total"
                :error-messages="scope.row.pieces_total < 0 || !Number.isInteger(scope.row.pieces_total) ? 'Must be integer greater to 0' : ''"
                @input="scope.row.pieces_total = parseFloat($event.toString().replace(',', '.') || 0)"
              >
              </v-text-field>
            </template>
          </el-table-column>
          <el-table-column
            :filters="getColumnFilterValues('ordersList', 'prepack_pieces')"
            column-key="prepack_pieces"
            v-if="selectedColumns.includes('Prepack')"
            prop="prepack_pieces" label="Prepack" sortable="custom">
            <template slot-scope="scope">
              {{scope.row.prepack_number}}({{ scope.row.prepack_pieces | formatThousands }})
            </template>
          </el-table-column>
          <el-table-column
            :filters="getColumnFilterValues('ordersList', 'cross_dock_pieces')"
            column-key="cross_dock_pieces"
            v-if="selectedColumns.includes('Crossdock')"
            prop="cross_dock_pieces"
            label="Crossdock"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ scope.row.cross_dock_number }}({{scope.row.cross_dock_pieces | formatThousands}})
            </template>
          </el-table-column>
          <el-table-column
            :filters="getColumnFilterValues('ordersList', 'value',priceFormatterInt, true)"
            column-key="value"
            v-if="selectedColumns.includes('Value')"
            prop="value"
            label="Value"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{priceFormatterInt(scope.row.value)}}
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Retail price')"
            prop="retail_price"
            column-key="retail_price"
            :filters="getColumnFilterValues('ordersList', 'retail_price',priceFormatter, true)"
            label="Retail price"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{priceFormatter(scope.row.retail_price)}}
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Buy Costs')"
            prop="buy_costs"
            column-key="retail_price"
            :filters="getColumnFilterValues('ordersList', 'buy_costs',priceFormatterInt, true)"
            label="Buy Costs"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{priceFormatterInt(scope.row.buy_costs)}}
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Buy Price')"
            prop="buy_price"
            min-width="110"
            column-key="buy_price"
            :filters="getColumnFilterValues('ordersList', 'buy_price')"
            label="Buy Price"
            sortable="custom"
          >
            <template slot-scope="scope">
              <v-row class="mt-0 mr-0">
                <v-col cols="7" class="pt-0">
                  <v-autocomplete
                    v-model="scope.row.currency"
                    :items="currencies"
                    :disabled="scope.row.status === 'to_order'"
                    @input="handleOrderChanged(scope.row, 'currency', $event)"
                    class="table-field"
                    item-value="iso_code"
                    item-text="iso_code"
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu'
                    }"/>
                </v-col>
                <v-col cols="5" class="pa-0">
                  <v-text-field
                    :disabled="scope.row.status === 'to_order'"
                    @change="handleOrderChanged(scope.row, 'buy_price', parseFloat($event.toString().replace(',', '.') || 0))"
                    :value="scope.row.buy_price"
                    @input="scope.row.buy_price = parseFloat($event.toString().replace(',', '.') || 0)"

                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </template>
          </el-table-column>

          <el-table-column
            v-if="selectedColumns.includes('Costs')"
            prop="costs_total"
            column-key="costs_total"
            :filters="getColumnFilterValues('ordersList', 'costs_total', priceFormatterInt, true)"
            label="Costs"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{priceFormatterInt(scope.row.costs_total)}}

            </template>
          </el-table-column>

          <el-table-column
            v-if="selectedColumns.includes('Cost price')"
            prop="cost_price"
            column-key="cost_price"
            :filters="getColumnFilterValues('ordersList', 'cost_price', priceFormatter, true)"
            label="Cost price"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{priceFormatter(scope.row.cost_price)}}
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Margin')"
            prop="gross_margin"
            column-key="gross_margin"
            :filters="getColumnFilterValues('ordersList', 'gross_margin', (value)=>((formatPercents(value * 100) )|| 0) + '%', true)"
            label="Margin"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{(formatPercents(scope.row.gross_margin * 100) )|| 0}}%
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Markup')"
            prop="markup"
            column-key="markup"
            :filters="getColumnFilterValues('ordersList', 'markup')"
            label="Markup"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{scope.row.markup | formatThousands}}
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Order date')"
            prop="order_date_key"
            column-key="order_date_key"
            :filters="getColumnFilterValues('ordersList', 'order_date_key')"
            class-name="with-field"
            label="Order date" sortable="custom">
            <template slot-scope="scope">
              <v-menu
                v-if="scope.row.order_date_key"
                ref="datePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="pt-0 mt-0 mb-1"
                    hide-details="auto"
                    :disabled="scope.row.status === 'to_order'"
                    :value="formatDateWeek(scope.row.order_date_key)"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  :show-week="true"
                  :first-day-of-week="1"
                  :locale-first-day-of-year="4"                  no-title
                  v-model="scope.row.order_date_key"
                  @change="val => handleOrderChanged(scope.row, 'order_date_key', val)"
                />
              </v-menu>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Shop date')"
            column-key="shop_date_key"
            :filters="getColumnFilterValues('ordersList', 'shop_date_key', formatDateWeek, true)"
            prop="shop_date_key" label="Shop date" sortable="custom">
            <template slot-scope="scope">
              <v-menu
                v-if="scope.row.shop_date_key"
                ref="datePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="pt-0 mt-0 mb-1 date-input"
                    hide-details="auto"
                    :style="scope.row.status === 'ordered' && new Date(scope.row.shop_date_key) < new Date() ? 'color: #FF9800': ''"
                    :disabled="scope.row.status === 'to_order'"
                    :value="formatDateWeek(scope.row.shop_date_key)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :show-week="true"
                  no-title

                  :first-day-of-week="1"
                  :locale-first-day-of-year="4"                  v-model="scope.row.shop_date_key"
                  @change="val => handleOrderChanged(scope.row, 'shop_date_key', val)"
                />
              </v-menu>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Revised date')"
            prop="revised_shop_date_key"
            column-key="revised_shop_date_key"
            :filters="getColumnFilterValues('ordersList', 'revised_shop_date_key', formatDateWeek, true)"
            class-name="with-field"
            label="Revised date" sortable="custom">
            <template slot-scope="scope">
              <v-menu
                v-if="scope.row.revised_shop_date_key"
                ref="datePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="pt-0 mt-0 mb-1 date-input"
                    :disabled="scope.row.status === 'to_order'"
                    :style="scope.row.status === 'ordered' && new Date(scope.row.revised_shop_date_key) < new Date() ? 'color: #FF9800': ''"
                    :value="formatDateWeek(scope.row.revised_shop_date_key)"
                    hide-details="auto"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :show-week="true"
                  no-title
                  :first-day-of-week="1"
                  :locale-first-day-of-year="4"
                  v-model="scope.row.revised_shop_date_key"
                  @change="val => handleOrderChanged(scope.row, 'revised_shop_date_key', val)"
                />
              </v-menu>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Supplier')"
            prop="supplierName"
            column-key="supplierName"
            label="Supplier"
            sortable="custom"
            :filter-method="equalsFilter"
            :filters="getColumnFilterValues('ordersList', 'supplierName')"
          >
            <template slot-scope="scope">
              <v-autocomplete
                v-if="scope.row.status !== 'to_order'"
                :value="scope.row.supplier_key"
                :items="suppliers"
                :error-messages="scope.row.supplier_key ? [] : ['Supplier is required']"
                item-value="supplier_key"
                item-text="supplier_name"
                hide-details="auto"
                class="pt-0 mt-0 mb-1 table-field"
                return-object
                @input="scope.row.supplier_key = $event.supplier_key; handleOrderChanged(scope.row, 'supplier_key', $event.supplier_key)"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"/>
              <span v-else>{{scope.row.supplierName}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Schedule')"
            prop="schedule"
            column-key="schedule"
            :filters="getColumnFilterValues('ordersList', 'schedule', formatDateWeek, true)"
            label="Schedule"
            sortable="custom"
          >
            <template slot-scope="scope">
              <v-autocomplete
                v-if="scope.row.status !== 'to_order'"
                v-model="scope.row.schedule_key"
                :items="schedules"
                item-value="schedule_key"
                class="pt-0 mt-0 mb-1 table-field"
                hide-details="auto"
                item-text="schedule_name"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
                @input="handleOrderChanged(scope.row, 'schedule_key', $event)"
              />
              <span v-else>{{scope.row.schedule}}</span>
            </template>
          </el-table-column>

          <el-table-column
            v-if="selectedColumns.includes('Payment')"
            prop="paymentName"
            column-key="paymentName"
            label="Payment"
            sortable="custom"
            :filters="getColumnFilterValues('ordersList', 'paymentName')"
          >
            <template slot-scope="scope">
              <v-autocomplete
                v-if="scope.row.status !== 'to_order'"
                v-model="scope.row.payment_key"
                :items="payments"
                hide-details="auto"
                class="pt-0 mt-0 mb-1 table-field"
                item-value="payment_key"
                item-text="payment_name"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
                @input="handleOrderChanged(scope.row, 'payment_key', $event)"

              />
              <span v-else>{{scope.row.paymentName}}</span>

            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Status')"
            prop="status"
            column-key="status"
            label="Status"
            sortable="custom"
            :filter-method="equalsFilter"
            :filters="getColumnFilterValues('ordersList', 'status')"
          >
            <template slot-scope="scope">
              <v-select
                :items="statusOptions"
                v-model="scope.row.status"
                :key="statusKey"
                class="py-1"
                :class="'status-' + scope.row.status.replace(' ', '-')"
                hide-details
                dense
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
                @change="val => handleOrderChanged(scope.row, 'status', val)" />
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Extra')"
            prop="extra"
            column-key="extra"
            label="Extra"
            sortable="custom"
            class-name="pr-4"
            :filter-method="equalsFilter"
            :filters="getColumnFilterValues('ordersList', 'extra')"
          >
            <template slot-scope="scope" v-if="scope.row.extra">
              <v-select
                v-if="scope.row.status !== 'to order'"
                :items="[{value: scope.row.extra, text: formatString(scope.row.extra) }, ...extraStatusOptions]"
                :value="scope.row.extra"
                class="py-1"
                :disabled="scope.row.status === 'to_order'"
                hide-details
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
                @change="val => handleOrderChanged(scope.row, 'extra', val)" />
              <span v-else>{{ scope.row.extra | formatString }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Message')"
            prop="message"
            column-key="message"
            label="Message"
            sortable="custom"
            class-name="pr-4"
          >
            <template slot-scope="scope">
              <template>
                <v-menu offset-y offset-x :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on"
                      :color="scope.row.note ? 'primary' : null"
                    >message</v-icon>
                  </template>
                  <v-card min-width="240" min-height="80">
                    <v-row class="ma-2 pa-2 align-center justify-space-between flex-nowrap" v-if="editMessage">
                      <v-textarea v-model="scope.row.note"
                        :disabled="scope.row.status === 'to_order'"></v-textarea>
                      <v-icon @click="handleOrderChanged(scope.row, 'note', scope.row.note); editMessage = false">mdi-check</v-icon>
                    </v-row>
                    <v-row class="ma-0 justify-space-between align-center pa-2" v-else>
                      <h5>{{scope.row.note}}</h5>
                      <v-icon @click="editMessage = true">edit</v-icon>
                    </v-row>
                  </v-card>
                </v-menu>

              </template>
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>

    <ShopItemOrderForm
      v-if="editedOrder"
      :currencies="currencies"
      :dummy="editedOrderDummy"
      :item="editedOrderShopItem"
      :order="editedOrder"
      :orders="editedShopItemOrders"
      :editedShopItemOrdersTotals="editedShopItemOrdersTotals"
      @update-order="onUpdateOrder"

      :order-lines="editedOrderOrderLines"
      :order-schedule="editedOrderSchedule"
      :order-costs="editedOrderCosts"
      :commitments="editedOrderShopItemCommitments.skus_data"
      :extra-status-options="extraStatusOptions"
      :status-options="statusOptions"
      :visible="orderFormDialogVisible"
      @order-progress-saved="handleOrderProgressSaved"
      @costs-saved="handleOrderCostsSaved"
    />

    <!-- Delete dialog -->
    <v-dialog
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="deleteDialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium">Delete order</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete this order</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteOrderConfirm"
                class="red--text mx-4"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :value="refreshDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">

        <v-card-title class="text-body-1 font-weight-medium">Refresh orders</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-4">
            Refreshing selected orders will:
            <ul class="bullet-points">
              <li>Update to current Shop item settings</li>
              <li>Pieces and related topics are not changed</li>
              <li>Only ‘In progress’ orders will be changed</li>
              <li>This action can not be undone</li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="refreshDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                color="primary"
                @click="handleRefreshOrderConfirm"
                class="mx-4"
              >Refresh</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { downloadData } from '@/utils'
import * as shopApi from '@/api/shop'
import * as dummiesApi from '@/api/dummies'
import columnFilters from '@/mixins/columnFilters'
import DataExport from '@/components/DataExport'
import TableSearch from '@/components/TableSearch'
import ShopItemOrderForm from '@/components/Shop/ItemOrderForm/ShopItemOrderForm.vue'
import { formatString } from '@/variables'
import { format } from 'date-fns'
import {isNull} from 'lodash'

const columnsToSelect = [
  'Order ID',
  'External ID',
  'Name',
  'Pieces',
  'Prepack',
  'Crossdock',
  'Value',
  'Retail price',
  'Buy Costs',
  'Buy Price',
  'Costs',
  'Cost price',
  'Margin',
  'Markup',
  'Order date',
  'Shop date',
  'Revised date',
  'Supplier',
  'Schedule',
  'Payment',
  'External ID',
  'Status',
  'Extra',
  'Message' ]
const defaultColumns = [
  'Order ID',
  'Name',
  'Pieces',
  'Prepack',
  'Order date',
  'Shop date',
  'Revised date',
  'Supplier',
  'Payment',
  'Status',
  'Extra']

export default {
  mixins: [ columnFilters ],
  components: {
    DataExport,
    TableSearch,
    ShopItemOrderForm,
  },
  props: {
    shopItems: {
      type: Array,
      default: () => [],
    },
    orderProperties: {
      type: Array,
      default: () => [],
    },
    orderPropertiesAdditional: {
      type: Array,
      default: () => [],
    },
    orders: {
      type: Array,
      default: () => [],
    },
    schedules: {
      type: Array,
      default: () => [],
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    extraStatusOptions: {
      type: Array,
      required: true,
    },
    currencies: {
      type: Array,
      default: () => [],
    },
    suppliers: {
      type: Array,
      required: true,
    },
    payments: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      Intl,
      editMessage: false,
      refreshDialogVisible: false,
      editedOrderOrderLines: null,
      editedOrderSchedule: null,
      onOrderChangeDelay: null,
      additionalColumns: JSON.parse(localStorage.getItem('orderProperties')) || [],
      additionalOrderColumns: JSON.parse(localStorage.getItem('orderPropertiesAdditional')) || [],
      columnsToSelect,
      selectedColumns: JSON.parse(localStorage.getItem('ordersTableSelectedColumns')) || defaultColumns,
      loading: false,
      statusKey: 0,
      deleteDialogVisible: false,
      deleteDialogLoading: false,
      orderFormDialogVisible: false,
      editedOrder: null,
      editedOrderDummy: null,
      editedOrderShopItem: null,
      editedShopItemOrders: [],
      editedShopItemOrdersTotals: {},
      filteredData: [],
      editedOrderShopItemCommitments: [],
      editedOrderCosts: [],
      tableFilters: [],
      columnsToSearch: ['supplier', 'payment', 'status', 'extra', 'shopItemName'],
    }
  },
  created () {
    this.onOrderChangeDelay = _.debounce(this.onOrderChange, 300)
  },
  computed: {

    ordersPageSize () {
      return parseFloat(localStorage.getItem('orders-page-size')) || 5
    },
    ordersTotal () {
      const totals = this.filteredData.reduce((prev, curr) => {
        for (const key in curr) {
          if (typeof curr[key] === 'number' && key !== 'order_key' && key !== 'shop_item_key') {
            if (prev[key]) {
              prev[key] += curr[key]
            } else {
              prev[key] = curr[key]
            }
          }
        }
        return prev
      }, {})
      let vat = localStorage.getItem('identity_vat')
      if (vat === undefined) {
        vat = 1.2
      }
      totals.buy_price = totals.buy_costs / totals.pieces_total
      totals.cost_price = totals.costs_total / totals.pieces_total
      totals.gross_margin = 1 - (totals.costs_total / (totals.value / vat))
      totals.markup = totals.value / totals.costs_total
      return totals
    },
    allRowsSelected () {
      return this.selectedRows.length === this.ordersList.length
    },
    sortedOrderProperties () {
      const props = [...this.orderProperties]
      return props.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    },
    sortedOrderPropertiesAdditional () {
      const props = [...this.orderPropertiesAdditional]
      return props.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    },
    priceFormatter () {
      const currency = this.$auth.getUserCurrency().currency || 'EUR'
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return formatter.format
    },
    priceFormatterInt () {
      const currency = this.$auth.getUserCurrency().currency || 'EUR'
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      return formatter.format
    },
    formatPercents () {
      const formatter = new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return formatter.format
    },
    ordersList () {
      return this.orders.map(o => {
        for (const key of [...this.sortedOrderPropertiesAdditional, ...this.sortedOrderProperties]) {
          o[key] = o.properties[key] || ' '
        }
        o.supplierName = this.suppliersMap[o.supplier_key] || '-'
        o.paymentName = this.paymentsMap[o.payment_key] || '-'
        o.shopItemName = this.shopItemsMap[o.shop_item_key]
          ? this.shopItemsMap[o.shop_item_key].name
          : o.shop_item_key
        o.schedule = this.schedulesMap[o.schedule_key]
        return o
      })
    },
    selectedRows () {
      return this.ordersList.filter(r => r.selected)
    },
    deleteDisabled () {
      return !this.selectedRows.length
    },
    shopItemsMap () {
      return this.shopItems.reduce((acc, cur) => ({
        ...acc,
        [cur.shop_item_key]: cur,
      }), {})
    },
    suppliersMap () {
      return this.suppliers.reduce((acc, cur) => ({
        ...acc,
        [cur.supplier_key]: cur.supplier_name
      }), {})
    },
    paymentsMap () {
      return this.payments.reduce((acc, cur) => ({
        ...acc,
        [cur.payment_key]: cur.payment_name
      }), {})
    },
    schedulesMap () {
      return this.schedules.reduce((acc, cur) => ({
        ...acc,
        [cur.schedule_key]: cur.schedule_name
      }), {})
    },
  },
  watch: {
    ordersList: {
      handler () {
        this.columnsToSearch = Object.keys(this.ordersList[0] || {})
      },
      immediate: true
    }
  },
  methods: {
    formatString,
    handleOrderIdClick (row) {
      this.handleEditOrderClick(row)
    },

    validateSellthrough (row) {
      if (isNull(row.order_settings?.sellthrough) || row.order_settings?.sellthrough === undefined) {
        return true
      }
      return Number.isInteger(parseFloat((row.order_settings?.sellthrough * 100 || 0).toFixed(2))) && row.order_settings?.sellthrough * 100 >= 1 && row.order_settings?.sellthrough * 100 <= 100
    },
    onShopOrderPageSizeUpdate (value){
      localStorage.setItem('orders-page-size', value)
    },
    isWrongPieces (row) {
      return !Number.isInteger(row.pieces_total) || row.pieces_total < row.cross_dock_pieces + row.prepack_pieces
    },
    onUpdateOrder (data) {
      this.editedOrder = data.order
      this.editedOrderSchedule = data.order_schedules
      this.editedOrderCosts = { costs: data.costs, costs_grouped: data.costs_grouped }
    },
    formatDateWeek (date) {
      return `${date} (W${format((new Date(date)), 'ww')})`
    },
    handleRefreshClick () {
      this.refreshDialogVisible = true
    },
    handleSelectAllChange (val) {
      this.filteredData.forEach((item) => {
        this.$set(item, 'selected', val)
      })
    },
    saveProperties (value) {
      localStorage.setItem('orderProperties', JSON.stringify(value))
    },
    savePropertiesAdditional (value) {
      localStorage.setItem('orderPropertiesAdditional', JSON.stringify(value))
    },

    getColumnFilters (list, col) {
      // flatten to handle columns with array of values properly
      const values = _.flatten(list.map(x => x.properties[col]))
      return _.uniq(values).filter((item) => item !== null).map(v => {
        return ({
          text: typeof v === 'string' ? this.$options.filters.formatString(v) : v,
          value: v,
        })
      })
    },
    getSummary (params) {
      if (!this.ordersTotal) {
        return []
      }

      const sums = ['', 'Total', '']
      const { columns } = params
      columns.forEach((column, index) => {
        if (this.ordersTotal[column.property] !== undefined) {
          sums[index] = this.priceFormatter(this.ordersTotal[column.property])
          if (column.property === 'value' || column.property === 'costs_total' || column.property === 'buy_costs') {
            sums[index] = this.priceFormatterInt(this.ordersTotal[column.property])
          }
          if (column.property === 'markup' || column.property === 'pieces_total') {
            sums[index] = this.$options.filters.formatThousands(this.ordersTotal[column.property])
          } if (column.property === 'gross_margin') {
            sums[index] = this.formatPercents(this.ordersTotal[column.property] * 100 || 0) + '%'
          }
          if (column.property === 'prepack_pieces' || column.property === 'cross_dock_pieces') {
            sums[index] = `${this.ordersTotal[column.property.replace('_pieces', '_number')]} (${this.$options.filters.formatThousands(this.ordersTotal[column.property])})`
          }
        }
      })
      return sums
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    saveSelectedColumns (value) {
      localStorage.setItem('ordersTableSelectedColumns', JSON.stringify(value))
    },
    onOrderChange (payload) {
      return shopApi.onOrdersChange(payload).then(({ orders }) => {
        const selectedRows = this.orders.filter(item => payload.order_keys.includes(item.order_key))
        selectedRows.forEach((item, index) => {
          const newItem = orders.find((shopItem) => shopItem.order_key === item.order_key)
          this.$set(selectedRows[index], 'schedule_key', newItem.schedule_key)
          this.$set(selectedRows[index], 'payment_key', newItem.payment_key)
          this.$set(selectedRows[index], 'gross_margin', newItem.gross_margin)
          this.$set(selectedRows[index], 'markup', newItem.markup)
          this.$set(selectedRows[index], 'costs_total', newItem.costs_total)
          this.$set(selectedRows[index], 'costs', newItem.costs)
          this.$set(selectedRows[index], 'status', newItem.status)
          this.$set(selectedRows[index], 'buy_costs', newItem.buy_costs)
          this.$set(selectedRows[index], 'cost_price', newItem.cost_price)
        })
        setTimeout(()=> {
          // this.$emit('order-status-saved')
          this.$emit('order-updated')
          this.$emit('order-saved')
        }, 500)
      })
    },
    handleSelectRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
    handleEditOrderClick (row) {
      row = row || this.selectedRows[0]
      this.loading = true

      Promise
        .all([
          this.loadShopItem(row),
          this.loadShopItemOrders(row),
        ])
        .then(() => {
          const commitmentsPayload = this.getCommitmentsPayload()

          return Promise.all([
            this.loadShopItemCommitments(commitmentsPayload),
            this.loadEditedOrderData(row),
            shopApi.getShopItemOrderInfo(row),
          ])
        }).catch(console.log)
        .then(([_1, _2, orderInfo]) => {
          orderInfo.pieces_total = row.pieces_total
          this.editedOrder = orderInfo
          this.orderFormDialogVisible = true
        }).catch(console.log)
        .finally(() => {
          this.loading = false
        })
    },
    handleDeleteOrderClick () {
      this.deleteDialogVisible = true
    },
    handleDeleteOrderConfirm () {
      this.deleteDialogLoading = true

      const payload = {
        ...this.$route.query,
        shop_item_key: this.selectedRows[0].shop_item_key,
        order_keys: this.selectedRows.map(item => item.order_key),
      }

      shopApi
        .deleteShopItemOrder(payload)
        .then(() => {
          this.$emit('order-deleted')
        })
        .finally(() => {
          this.deleteDialogLoading = false
          this.deleteDialogVisible = false
        })
    },
    handleRefreshOrderConfirm () {
      this.refreshDialogVisible = true

      const payload = {
        order_keys: this.selectedRows.map(item => item.order_key),
      }

      shopApi
        .refreshShopItemOrder(payload)
        .then(() => {
          this.$emit('order-deleted')
        })
        .finally(() => {
          this.refreshDialogVisible = false
          this.refreshDialogVisible = false
        })
    },
    async handleOrderChanged (row, property, val, isWrong) {
      if (isWrong) {
        return
      }
      this.loading = true
      this.selectedRows.forEach((item) => {
        this.$set(item, property, val)
      })
      const order_keys = [...(new Set([...this.selectedRows, row]))].map(item => item.order_key)
      const payload = {
        scenario_key: row.scenario_key,
        budget_key: row.budget_key,
        order_keys,
        [property]: val,
      }
      try {
        await this.onOrderChangeDelay(payload)
      } catch (e) {
        console.error(e)
      }
      this.loading = false
      if (property === 'status') {
        this.statusKey++
      }
    },
    handleOrderProgressSaved () {
      this.orderFormDialogVisible = false
      this.editedOrder = null
      this.editedOrderDummy = null
      this.editedOrderShopItem = null
      this.editedShopItemOrders = []
      this.editedOrderShopItemCommitments = []
      this.editedOrderCosts = []

      this.$emit('order-saved')
    },
    handleOrderCostsSaved () {
      this.loading = true

      shopApi
        .getShopItemOrderCosts(this.editedOrder)
        .then(orderCosts => {
          this.editedOrderCosts = orderCosts
        })
        .finally(() => {
          this.loading = false
        })
    },
    getCommitmentsPayload () {
      return {
        dummy_key: this.editedOrderShopItem.dummy_key,
        shop_item_key: this.editedOrderShopItem.shop_item_key,
        scenario_key: this.editedOrderShopItem.scenario_key,
        budget_key: this.editedOrderShopItem.budget_key,
        sku_names: this.editedOrderShopItem.sku_names,
        store_keys: this.editedOrderShopItem.store_keys,
        beliefs: this.editedOrderShopItem.beliefs,
        reorder_option_keys: this.editedOrderShopItem.reorder_option_keys,
        week_min: this.editedOrderShopItem.week_min,
        week_max: this.editedOrderShopItem.week_max,
        lookalike_option_keys: this.editedOrderDummy.lookalike_option_keys
      }
    },
    loadShopItem (row) {
      row = row || this.selectedRows[0]
      return shopApi
        .getShopItem(row)
        .then(async (item) => {
          this.editedOrderShopItem = item
          await this.loadDummy()
        })
    },
    loadDummy () {
      return dummiesApi
        .loadDummy(this.editedOrderShopItem.dummy_key)
        .then(response => {
          this.editedOrderDummy = response.dummy
        })
    },
    loadShopItemOrders (row) {
      row = row || this.selectedRows[0]
      const { budget_key, scenario_key, shop_item_key } = row

      return shopApi
        .getShopItemOrders(budget_key, scenario_key, shop_item_key)
        .then(({ orders, orders_total }) => {
          this.editedShopItemOrders = orders
          this.editedShopItemOrdersTotals = orders_total
        })
    },
    loadShopItemCommitments (payload) {
      return shopApi
        .getCommitments(payload)
        .then(response => {
          this.editedOrderShopItemCommitments = response
        })
    },
    loadEditedOrderData (row) {
      const promises = [
        shopApi.getShopItemOrderLines(row),
        shopApi.getShopItemOrderSchedule(row),
        shopApi.getShopItemOrderCosts(row),
      ]

      return Promise
        .all(promises)
        .then(([orderLines, orderSchedule, orderCosts]) => {
          this.editedOrderOrderLines = orderLines
          this.editedOrderSchedule = orderSchedule
          this.editedOrderCosts = orderCosts
        })
    },
    downloadOrdersData (sep) {
      const columns = Object.keys(this.ordersList[0])

      const data = this.ordersList.map(
        row => columns.reduce(
          (colAcc, curCol) => {
            let curValue = row[curCol]

            if (curCol === 'pieces') {
              curValue = Object.keys(row[curCol])
                .reduce(
                  (piecesAcc, sku) => {
                    return piecesAcc + `${sku}: ${row[curCol][sku]}, `
                  },
                  ''
                )
            }

            return {
              ...colAcc,
              [curCol]: curValue,
            }
          },
          {}
        )
      )

      downloadData(sep, data, 'shop_orders')
    }
  },
}
</script>

<style lang="scss">
#orders_table .cell {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#orders_table .with-field .cell {
  padding-top: 4px;
  padding-bottom: 4px;

}
.shop-orders {

  .v-select__selections {
    flex-wrap: nowrap;
  }
    .status-in-progress {
        color: #007AFF;
    }
    .status-ordered {
        color: #4CAF50;
    }
    .status-delivered {
        color: #765397;
    }
    .status-committed {
        color: #FF9800;
    }
    .status-to_order {
        color: #FFD202;
    }
  .option-subtitle {
    color: var(--text-sub-black) !important;
    font-size: 12px;
  }
  .bullet-points {
    list-style-type: circle;
  }

  .option-title {
    font-weight: 500;
  }

  .id-cell:hover {
    color: var(--primary);
    cursor: pointer;
  }

    .v-input {
        font-size: 13px;
        &.status-in-progress {
             .v-select__selection {
                color: #007AFF;
            }
        }
        &.status-ordered {
          .v-select__selection {
                color: #4CAF50;
            }
        }
        &.status-delivered{
          .v-select__selection {
                color: #765397;
            }
        }
        &.status-committed {
             .v-select__selection {
                color: #FF9800;
            }
        }
        &.status-to_order {
             .v-select__selection {
                color: #FFD202;
            }
        }
    }
  .date-input {
     .v-select__selection {
      color: inherit
    }
  }
}
</style>
