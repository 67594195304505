import {defineStore} from 'pinia'
import baseURLs from '@/api/APIBaseURLs'
import {Config} from '@/models/Config'
import {Optional} from '@/models/TypeHelpers'

export const useGeneralStore = defineStore('general', {
  state: () => ({
    currentModule: null as Optional<string>,
    currentStoresSetsLabel: 'buy' as Optional<string>,
    appConfig: null as Optional<Config>,
    accessDeniedDialogVisible: false,
    devMode: localStorage.getItem('devMode') === 'true',
    orgName: localStorage.getItem('org'),
    snackOpened: false,
    snackText: '',
    snackType: '',
  }),
  actions: {
    setDevMode (isDev = true) {
      if (this.devMode === isDev) {
        return
      }
      this.devMode = isDev
      localStorage.setItem('devMode', String(isDev))
      baseURLs.setDevMode(isDev)
    },
    setSnack (type: 'warning'| 'success' | 'error', text = '' ){
      this.snackOpened = true
      this.snackText = text
      this.snackType = type
    },
    setAppConfig (payload: Config) {
      this.appConfig = payload
    },
    setCurrentModule (payload: string) {
      if(this.appConfig?.stores_sets_labels?.includes(payload)) {
        this.setCurrentStoresSetsLabel(payload)
      }
      this.currentModule = payload
    },
    setCurrentStoresSetsLabel (payload: Optional<string>){
      this.currentStoresSetsLabel = payload
    },
    setAccessDeniedDialogVisible (payload: boolean) {
      this.accessDeniedDialogVisible = payload
    },
  },
  getters: {
    buySettings: (state) => {
      if(!state.appConfig?.org_settings){
        return null
      }
      return state.appConfig?.org_settings[0]?.buy_settings || null
    },
    /**
     * this returns a list of stores with label and value properties
     */
    mappedConfigStores: (state) => {
      return (state.appConfig?.stores || []).map(s => ({ label: s.store_name, value: s.store_key }))
    },

    /**
     * Returns a function that takes a store key and returns the store object
     */
    findStoreWithKey: (state) => (storeKey: number) => {
      storeKey = Number(storeKey)
      return (state.appConfig?.stores || []).find(s => Number(s.store_key) === storeKey)
    }
  }
})
