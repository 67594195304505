<template>
  <v-container class="ma-0">
    <v-row class="px-9 mb-1">
      <v-col cols="4">
        <v-autocomplete
          v-model="scenarioFrom"
          :items="scenarios"
          item-value="scenario_key"
          item-text="scenario_name"
          label="From Scenario"
          hide-details
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"
          @change="FromScenarioChange"
        />
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="scenarioTo"
          :items="scenarios"
          item-value="scenario_key"
          item-text="scenario_name"
          label="To Scenario"
          hide-details
          :menu-props="{
            left: true,
            offsetY: true,
            contentClass: 'select-dropdown-menu',
          }"
          @change="ToScenarioChange"
        />

      </v-col>
    </v-row>
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="pa-0 pt-4" v-if="showTables">

      <div class="col text-left py-2 mx-9">
        <h6 class="text-subtitle-1 font-weight-medium pb-1">Shop Items</h6>

        <span class="font-weight-medium">
          {{ shopItemsSelected.length }} line selected
        </span>
      </div>
      <v-col class="text-right pb-0 pt-5">
        <TableSearch
          class="mx-2"
          @searchChanged="val => this.$refs.shopItemsTable.handleSearchChanged('itemsTableFilters', columnsToSearch, val)"
        />
        <v-icon
          class="mx-2 action-btn-primary"
          @click="handleAddIconClick"
          :disabled="shopItemsSelected.length === 0"
        >add</v-icon>
      </v-col>
    </v-row>
    <v-row v-if="showTables" class="px-0" >
      <v-col class="px-0">
        <shop-items-table
          ref="shopItemsTable"
          :items="shopItems"
          :scenario-key="scenarioTo"
          :budget-key="budgetKey"
          :store-sets="storeSets"
        />
      </v-col>
    </v-row>
    <v-divider class="my-6" v-if="showTables && selectedItems.length > 0" />
    <v-row class="pa-0 pt-4" v-if="showTables && selectedItems.length > 0">
      <div class="col text-left py-2 mx-9">
        <h6 class="text-subtitle-1 font-weight-medium pb-1">Selected Items</h6>
        <span class="font-weight-medium" v-if="selectedItemsSelected.length > 0">
          {{ selectedItemsSelected.length }} line selected
        </span>
      </div>
      <v-col class="text-right pb-0 pt-5">
        <TableSearch
          class="mx-2"
          @searchChanged="val => this.$refs.selectedItemsTable.handleSearchChanged('itemsTableFilters', columnsToSearch, val)"
        />
        <v-icon
          class="mx-2 action-btn-primary"
          @click="handleDeleteIconClick"
          :disabled="selectedItemsSelected.length === 0"
        >delete</v-icon>
      </v-col>
    </v-row>
    <v-row v-if="showTables" class="px-0" >
      <v-col class="px-0">
        <shop-items-table
          ref="selectedItemsTable"
          :items="selectedItems"
          :scenario-key="scenarioFrom"
          :budget-key="budgetKey"
          :store-sets="storeSets"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ColumnFilters from '@/mixins/columnFilters'
import _ from 'lodash'
import TableSearch from '@/components/TableSearch.vue'
import ShopItemsTable from '@/components/BuyManageBudgets/ShopItemsTable'

export default {
  mixins: [ColumnFilters],
  name: 'ScenarioMergeShopItems',
  data () {
    return {
      scenarioFrom: 0,
      scenarioTo: 0,
      columnsToSearch: ['shop_item_key', 'option_name', 'week_min_formatted', 'stores_set_keys', 'drop_name', 'tags', 'status'],
      selectedItems: [],
      loading: false,
    }
  },
  components: {
    ShopItemsTable,
    TableSearch,
  },
  props: {
    storeSets: {
      type: Array,
      default: () => [],
    },
    budgetKey: {
      type: Number,
      required: true
    },
    scenarios: {
      type: Array,
      required: true
    },
    shopItems: {
      type: Array,
      default: () => [],
    },
    initialFrom: {
      type: Number,
      default: 0
    },
    initialTo: {
      type: Number,
      default: 0
    },
    initialSelectedItems: {
      type: Array,
      default: () => [],
    },
  },
  mounted () {
    this.scenarioFrom = this.initialFrom
    this.scenarioTo = this.initialTo
    this.selectedItems = this.initialSelectedItems
  },
  computed: {
    showTables () {
      return this.shopItems && this.shopItems.length > 0
    },
    shopItemsSelected () {
      return this.shopItems.filter(r => r.selected)
    },
    selectedItemsSelected () {
      return this.selectedItems.filter(r => r.selected)
    }
  },
  watch: {
    shopItems () {
      this.shopItems.forEach(item => {
        this.$set(item, 'week_min_formatted', this.$options.filters.formatWeekNumber(item.week_min))
        this.$set(item, 'cost_price', _.round(item.cost_price, 2))
      })
    },
    selectedItems: {
      handler () {
        this.selectedItemsUpdated()
      },
      deep: true
    },
    scenarios: {
      handler () {
        this.scenarioFrom = 0
        this.scenarioTo = 0
      },
      deep: true
    },
  },
  methods: {
    FromScenarioChange (value) {
      if (value && this.scenarioTo && value !== this.scenarioTo) {
        this.loadShopItems()
      }
    },
    ToScenarioChange (value) {
      if (value && this.scenarioFrom && value !== this.scenarioFrom) {
        this.loadShopItems()
      }
    },
    handleAddIconClick () {
      const items = this.shopItemsSelected.filter(
        item => !this.selectedItems.some(selectedItem => selectedItem.shop_item_key === item.shop_item_key)
      ).map(i => {
        return { ...i, selected: false }
      })
      this.selectedItems = this.selectedItems.concat(items)
    },
    handleDeleteIconClick () {
      this.selectedItems = this.selectedItems.filter(item => !item.selected)
    },
    async loadShopItems () {
      const from = this.scenarios.find(s => s.scenario_key === this.scenarioFrom)
      const to = this.scenarios.find(s => s.scenario_key === this.scenarioTo)
      this.fromToChanged(from, to)
    },
    fromToChanged (from, to) {
      this.selectedItems = []
      this.$emit('scenarioFromToChanged', from, to)
    },
    setLoading (value) {
      this.loading = value
    },
    selectedItemsUpdated () {
      this.$emit('selectedItemsUpdated', this.selectedItems)
    },
  },
}
</script>

<style scoped>

</style>
