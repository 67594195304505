<template>
  <v-card-text class="pl-3">
    <div class="buy-margin-calculator">
      <v-overlay absolute color="white" :value="loading">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <v-row class="pt-5 pl-8" justify="space-between">
        <v-col>
          <span class="text-subtitle-1 font-weight-medium black--text">Scenario overview</span>
        </v-col>
        <v-col class="text-right pr-6">
          <v-icon
            class="mx-2 action-btn-primary"
            :disabled="copyDisabled"
            @click="handleCopyIconClick"
          >file_copy</v-icon>
          <v-icon
            class="mx-2 action-btn-primary"
            @click="handleAddIconClick"
          >add</v-icon>
          <v-icon
            class="mx-2 action-btn-primary"
            :disabled="!selectedItemsRows.length"
            @click="handleDeleteIconClick"
          >delete</v-icon>
          <v-icon
            class="mx-2 action-btn-primary"
            @click="handleSaveIconClick"
            :disabled="selectedItemsRows.length !== 1"
          >mdi-check</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pl-0">
          <data-tables
            class="margin-table"
            style="width: 100%"
            :data="scenarios"
            :page-size="scenarios.length"
            :pagination-props="{
              pageSizes: [scenarios.length],
              hideOnSinglePage: true,
            }"
          >
            <el-table-column width="55" prop="selected" >
              <template v-slot:header>
                <v-row class="ma-0 flex-nowrap">
                  <v-simple-checkbox
                    color="primary"
                    :value="allSelectedItems"
                    :ripple="false"
                    dense
                    hide-details
                    class="ml-3"
                    @input="handleSelectAllItemsChange"
                  />
                </v-row>
              </template>
              <template slot-scope="scope">
                <v-simple-checkbox
                  color="primary"
                  dense
                  hide-details
                  class="mt-0 ml-3 pt-0"
                  v-model="scope.row.selected"
                  :ripple="false"
                />
              </template>
            </el-table-column>
            <el-table-column prop="supplier_name" label="Supplier">
              <template slot-scope="scope">
                <v-autocomplete
                  v-model="scope.row.supplier_key"
                  :items="suppliers"
                  item-value="supplier_key"
                  class="table-field"
                  item-text="supplier_name"
                  @input="saveScenariosDebounce"
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                />
              </template>
            </el-table-column>
            <el-table-column prop="schedule_name" label="Schedule">
              <template slot-scope="scope">
                <v-autocomplete
                  v-model="scope.row.schedule_key"
                  :items="schedules"
                  item-value="schedule_key"
                  class="table-field"
                  item-text="schedule_name"
                  @input="saveScenariosDebounce"
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                />
              </template>
            </el-table-column>
            <el-table-column prop="pieces" label="Pieces">
              <template slot-scope="scope">
                <v-text-field
                  v-model.number="scope.row.pieces"
                  class="mb-0"
                  @keyup="saveScenariosDebounce"
                />
              </template>
            </el-table-column>
            <el-table-column prop="retail_price" label="Retail price">
              <template slot-scope="scope">
                <v-text-field
                  :value="scope.row.retail_price"
                  class="mb-0"
                  @input="scope.row.retail_price = parseFloat($event.toString().replace(',', '.') || 0)"
                  @keyup="saveScenariosDebounce"
                />
              </template>
            </el-table-column>
            <el-table-column prop="revenue" label="Intake revenue">
              <template slot-scope="scope">
                <span>{{zeroDecimalPriceFormatter(scope.row.revenue)}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="buy_price" label="Buy price (conversion)" min-width="150px">
              <template slot-scope="scope">
                <v-row class="mt-0 mb-0">
                  <v-col cols="5" class="py-0">
                    <v-autocomplete
                      v-model="scope.row.currency"
                      :items="currencies"
                      class="table-field"
                      item-value="iso_code"
                      item-text="iso_code"
                      @input="saveScenariosDebounce"
                      :menu-props="{
                        left: true,
                        offsetY: true,
                        contentClass: 'select-dropdown-menu',
                      }" />
                  </v-col>
                  <v-col cols="4" class="pa-0">
                    <v-text-field
                      v-model.number="scope.row.buy_price"
                      @input="scope.row.buy_price = parseFloat($event.toString().replace(',', '.') || 0)"

                      @change="saveScenariosDebounce"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="3" class="pa-0 py-5">
                    ({{scope.row.conversion_rate?.toFixed(2)}})
                  </v-col>
                </v-row>
              </template>
            </el-table-column>
            <el-table-column prop="additional_cost_keys" label="Additional costs">
              <template slot-scope="scope">
                <collapsible-select
                  v-model="scope.row.additional_cost_keys"
                  :items="costs"
                  item-value="cost_key"
                  class="table-field"
                  item-text="cost_name"
                  multiple
                  show-select-all
                  not-clearable
                  @input="saveScenariosDebounce"
                />
              </template>
            </el-table-column>
            <el-table-column prop="costs" label="Costs">
              <template slot-scope="scope">
                <div class="d-flex align-center justify-space-between">
                  {{ zeroDecimalPriceFormatter(scope.row.costs) }}
                  <v-menu
                    top
                    open-on-hover
                    offset-x
                    min-width="250"
                    max-width="250"
                    content-class="option-info-menu"
                    :open-delay="200"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="icon-has-action"
                        v-bind="attrs"
                        v-on="on"
                      >info</v-icon>
                    </template>
                    <v-card class="py-2 px-4 p-relative">
                      <b>Costs</b>
                      <div
                        v-for="(cn, i) in scope.row.cost_names"
                        :key="i"
                        class="text-body-2"
                      >
                        <b>{{cn.cost_name}}</b>: {{ priceFormatter(cn.costs || 0) }}
                      </div>
                    </v-card>
                  </v-menu>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="cost_price" label="Cost price">
              <template slot-scope="scope">
                <span>{{priceFormatter(scope.row.cost_price)}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="cost_price" label="Intake margin (Markup)">
              <template slot-scope="scope">
                <span>{{(scope.row.gross_margin*100)?.toFixed(1)}}% ({{scope.row.markup?.toFixed(1)}})</span>
              </template>
            </el-table-column>
            <el-table-column width="40">
              <template slot-scope="scope">
                <v-menu offset-y offset-x :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="action-btn-primary" v-bind="attrs" v-on="on"
                      :color="scope.row.note ? 'primary' : null"
                    >message</v-icon>
                  </template>
                  <v-card min-width="240" min-height="80">
                    <v-row class="ma-2 pa-2 align-center justify-space-between flex-nowrap" v-if="editMessage">
                      <v-textarea v-model="scope.row.note"></v-textarea>
                      <v-icon @click="saveScenariosDebounce(); editMessage = false">mdi-check</v-icon>
                    </v-row>
                    <v-row class="ma-0 justify-space-between align-center pa-2" v-else>
                      <span>{{scope.row.note}}</span>
                      <v-icon @click="editMessage = true">edit</v-icon>
                    </v-row>
                  </v-card>
                </v-menu>
              </template>
            </el-table-column>
          </data-tables>
        </v-col>
      </v-row>
      <v-col cols="12" md="10" lg="6" class="pl-0">
        <v-expansion-panels flat>
          <v-expansion-panel
          >
            <v-expansion-panel-header class="pa-1">
              <span class="text-subtitle-1 font-weight-medium black--text pl-8">Currency rates</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="pb-5 pl-8 justify-end my-0">
                <TableSearch
                  class="mr-7"
                  @searchChanged="val => handleSearchChanged('currencyFilters', ['currency'], val)"
                />
              </v-row>
              <data-tables
                class="margin-table"
                :filters="currencyFilters"
                :data="rates"
                :page-size="5"
                :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
              >
                <el-table-column width="55"/>
                <el-table-column prop="org_currency" label="From">
                  <template slot-scope="scope">
                    <span>{{scope.row.org_currency}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="currency" label="To" sortable="custom">
                  <template slot-scope="scope">
                    <span>{{scope.row.currency}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="rate_fixed" label="Fixed">
                  <template slot-scope="scope">
                    <span>{{scope.row.rate_fixed}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="rate_currency" label="Current">
                  <template slot-scope="scope">
                    <span>{{scope.row.rate_currency}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="index" label="Index">
                  <template slot-scope="scope">
                    <span>{{scope.row.index.toFixed(2)}}</span>
                  </template>
                </el-table-column>
              </data-tables>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </div>
    <v-dialog
      :value="confirmDialog"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">Update confirmation</v-card-title>
        <v-divider/>
        <v-card-text>
          <div class="text-body-1 pt-8">
            Are you sure you want to update the settings for this shop item with the input of this row?<br><br>
            <span class="font-weight-bold">Be careful: pieces, additional costs and retail price are not updated.</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="confirmDialog = false"
              >Cancel</v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                @click="updateShopItem"
                class="mx-4"
              >Save</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>

<script>
import _ from 'lodash'
import * as shopApi from '@/api/shop'
import TableSearch from '@/components/TableSearch.vue'
import columnFilters from '@/mixins/columnFilters'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  components: { CollapsibleSelect, TableSearch },
  mixins: [columnFilters],
  props: {
    shopItemKey: {
      type: Number,
      required: true,
    },
    supplierName: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      editMessage: false,
      confirmDialog: false,
      currencyFilters: [{ value: '' }],
      loading: false,
      margin: [],
      shop_item_currency_price: 0,
      shop_item_buy_price: 0,
      shop_item_conversion_rate: 0,
      saveCurrencyPriceLock: false,
      scenarios: [],
      default_scenario: undefined,
      rates: [],
      saveScenariosDebounce: undefined,
    }
  },
  created () {
    this.saveScenariosDebounce = _.debounce(this.saveScenarios, 1000)
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    currencies () {
      return this.appConfig?.currencies || []
    },
    defaultCurrency () {
      return this.$auth.getUserCurrency().currency || 'EUR'
    },
    suppliers () {
      return (this.appConfig?.suppliers || [])
    },
    schedules () {
      return (this.appConfig?.schedules || [])
    },
    costs () {
      return (this.appConfig?.costs || [])
    },
    priceFormatter () {
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.defaultCurrency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return formatter.format
    },
    zeroDecimalPriceFormatter () {
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.defaultCurrency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      return formatter.format
    },
    marginRows () {
      return this.margin.map((x, i) => ({
        isCurrentRow: i === 0,
        ...x,
      }))
    },
    current () {
      return this.marginRows.filter(x => x.isCurrentRow)[0] || {
        buy_price: 0,
        conversion_rate: 0,
        shop_item_currency: ''
      }
    },
    allSelectedItems () {
      return this.scenarios.length === this.selectedItemsRows.length && this.scenarios.length > 0
    },
    selectedItemsRows () {
      return this.scenarios.filter(x => x.selected)
    },
    copyDisabled () {
      return this.selectedItemsRows.length !== 1
    },
  },
  watch: {
    current: {
      handler (val) {
        this.shop_item_buy_price = val.buy_price
        this.shop_item_conversion_rate = val.conversion_rate
        this.shop_item_currency_price = (val.buy_price * val.conversion_rate).toFixed(2)
      },
      deep: true,
    },
  },
  methods: {
    async loadData (payload) {
      this.loading = true
      try {
        const { rate, scenarios, default_scenario } = await shopApi.getShopItemScenario({
          ...payload,
          shop_item_key: this.shopItemKey,
        })
        this.scenarios = scenarios
        this.rates = rate
        this.default_scenario = default_scenario[0]
      } catch (e) {
        this.loading = false
        console.error(e)
      }
      this.loading = false
    },
    handleScenarioChanged (index, property, event) {
      const row = this.scenarios[index]
      const value = event.target.value
      this.$set(row, property, value)

      // Debounce post to scenarios
      this.saveScenariosDebounce()
    },
    handleSelectAllItemsChange () {
      if (this.allSelectedItems) {
        this.scenarios.forEach(scenario => {
          scenario.selected = false
        })
      } else {
        this.scenarios.forEach(scenario => {
          scenario.selected = true
        })
      }
      this.scenarios = [...this.scenarios]
    },
    handleSelectItemRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
    handleCopyIconClick () {
      if (this.selectedItemsRows.length !== 1) {
        return
      }
      const selectedScenario = this.selectedItemsRows[0]
      const newScenario = {
        ...selectedScenario,
        selected: false
      }
      this.scenarios = [...this.scenarios, newScenario]
      this.saveScenariosDebounce()
    },
    handleAddIconClick () {
      const newScenario = {
        ...this.default_scenario,
        selected: false,
      }
      this.scenarios = [...this.scenarios, newScenario]
      this.saveScenariosDebounce()
    },
    handleDeleteIconClick () {
      if (this.selectedItemsRows.length === 0) {
        return
      }
      this.scenarios = this.scenarios.filter(scenario => !scenario.selected)
      this.saveScenariosDebounce()
    },
    handleSaveIconClick () {
      this.confirmDialog = true
    },
    async saveScenarios () {
      const payload = {
        budget_key: this.item.budget_key,
        scenario_key: this.item.scenario_key,
        shop_item_key: this.shopItemKey,
        scenarios: this.scenarios,
      }
      try {
        const data = await shopApi.saveShopItemScenarios(payload)
        this.scenarios = data.scenarios
      } catch (e) {
        console.error(e)
      }
    },
    async updateShopItem () {
      if (this.selectedItemsRows.length !== 1) {
        return
      }
      const currentScenario = this.selectedItemsRows[0]
      const payload = {
        ...this.item,
        supplier_key: currentScenario.supplier_key,
        schedule_key: currentScenario.schedule_key,
        currency: currentScenario.currency,
        buy_price: currentScenario.buy_price,
      }
      try {
        await shopApi.saveShopItem(payload)
        this.$emit('updatedShopItem')
      } catch (e) {
        console.error(e)
      }
      this.confirmDialog = false
    },
  },
}
</script>

<style lang="scss">
.buy-margin-calculator {
    .margin-table {
        .v-text-field {
            font-size: 13px;
        }
        div.cell {
          word-break: break-word;
        }
    }
  .v-expansion-panel-header .v-expansion-panel-header__icon {
    margin-left: 0;
  }
  .v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
    flex: unset;
  }

}
</style>
