<template>
  <resizable
    id="strategy-collection-products"
    class="mx-n6 p-relative"
    @resize="updateTotalTableWidth"
  >
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row>
      <div class="col text-right pr-6">
        <TableSearch class="mx-2"
          @searchChanged="val => handleSearchChanged('tableFilters', selectedBasicColumns, val)" />

        <v-icon
          class="mx-2 action-btn-primary"
          :disabled="selectedProducts.length !== 1"
          @click="editModalOpened = true; selectedProduct = selectedProducts[0]"
        >edit
        </v-icon>
        <DataExport
          class="text-left"
          @item-click="downloadProductsData"
        />
        <v-menu left offset-y offset-x attach="#strategy-collection-products" nudge-bottom="5" :close-on-content-click="false" content-class="column-selection-menu">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on"
            >filter_list
            </v-icon>
          </template>
          <v-card max-height="400" class="overflow-auto pr-2">
            <h5 class="mt-2 mb-2 px-2">Columns</h5>
            <v-checkbox
              v-for="item of allBasicColumns"
              v-model="selectedBasicColumns"
              :key="item"
              :label="item | formatString"
              :value="item"
              hide-details
              class="mt-0 pt-0 ml-1 column-checkbox"
              @change="handleColumnsSelectionChanged"
            />
            <h5 class="mt-4 mb-2 px-2">Properties</h5>
            <v-checkbox
              v-for="item of parsedAnalyticsProperties"
              v-model="selectedPropertiesColumns"
              :key="item.value"
              :label="item.label | formatString"
              :value="item.value"
              hide-details
              class="mt-0 pt-0 ml-1 column-checkbox"
              @change="handleColumnsSelectionChanged"
            />
          </v-card>
        </v-menu>
      </div>
    </v-row>

    <h4 class="text-no-wrap px-6 mb-4">{{selectedProducts.length}} line(s) selected
      <span v-if="selectedProducts.length > 1">(changes are applied to all selected rows)</span>
    </h4>
    <v-row>
      <v-col>
        <data-tables
          style="width: 100%"
          ref="productsTable"
          :data="tableData"
          :page-size="10"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 mb-4 mr-2',
          }"
          :table-props="{
            'row-key': 'rowKey',
            lazy: true,
            load: loadChildData,
          }"
          :filters="tableFilters"
          @size-change="handleSizeChange"
          @expand-change="handleExpandChange"
          @filter-change="filters => handleFilterChange('tableFilters', filters)"
        >
          <data-tables
            slot="append"
            :index="null"
            class="total-table"
            :data="tableTotals"
            :pagination-props="{class: 'd-none'}"
            v-if="products.length"
          >
            <el-table-column
              v-if="selectedBasicColumns.includes('product_name')"
              label-class-name="d-none"
              class-name="total-row_column"
              width="50"
            />
            <el-table-column
              v-if="selectedBasicColumns.includes('product_name')"
              class-name="total-row_column"
              label-class-name="d-none"
              width="220"
            >
              <template>
                <div>Total</div>
              </template>
            </el-table-column>

            <el-table-column
              v-for="column in selectedPropertiesColumns"
              :key="column"
              width="120"
              label-class-name="d-none"
              class-name="total-row_column"
            ></el-table-column>
            <el-table-column
              v-if="selectedBasicColumns.includes('stores')"
              label-class-name="d-none"
              class-name="total-row_column"
              width="80"
            />
            <el-table-column
              v-if="selectedBasicColumns.includes('stars')"
              label-class-name="d-none"
              class-name="total-row_column"
              width="80"
            />
            <el-table-column
              v-if="selectedBasicColumns.includes('sku_names')"
              label-class-name="d-none"
              class-name="total-row_column"
              width="150"
            />
            <el-table-column
              v-if="selectedBasicColumns.includes('week_number')"
              label-class-name="d-none"
              class-name="total-row_column"
              width="120"
            />
            <el-table-column
              v-if="selectedBasicColumns.includes('original_value')"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ priceFormatterNoDecimal.format(scope.row.original_value) }}</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedBasicColumns.includes('cost_value')"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ priceFormatterNoDecimal.format(scope.row.cost_value) }}</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedBasicColumns.includes('margin')"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.initial_margin }} </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedBasicColumns.includes('original_price')"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ priceFormatterForPrice.format(scope.row.original_price) }}</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedBasicColumns.includes('cost_price')"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ priceFormatterForPrice.format(scope.row.cost_price) }}</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedBasicColumns.includes('pieces')"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.pieces | formatThousands }}</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectedBasicColumns.includes('status')"
              label-class-name="d-none"
              class-name="total-row_column"
            />
            <el-table-column label-class-name="d-none" class-name="total-row_column" width="50" />
          </data-tables>
          <el-table-column prop='selected' label="" width="50" class-name="overflow-visible">

            <template v-slot:header>
              <v-checkbox
                color="primary"
                class="ma-0 align-center"
                :value="allRowsSelected"
                hide-details
                @change="selectAllProducts"
              />
            </template>
            <template slot-scope="scope">
              <v-checkbox
                class="ma-0 align-center"
                v-if="!scope.row.isChild && (scope.row.status === 'generated' || scope.row.status === 'placeholder')"
                ripple
                v-model="scope.row.selected"
                style="height: 50px"
                @change="setSelectedProduct($event, scope.row)"
                hide-details></v-checkbox>
            </template>
          </el-table-column>
          <data-tables-column
            v-if="selectedBasicColumns.includes('product_name')"
            prop="product_name"
            column-key="product_name"
            label="Name"
            width="220"
            label-class-name="ml-4"
            class-name="pa-0"
            sortable="custom"
            :filter-method="equalsFilter"
            :filtered-value="tableFilters.filter(item => item.prop === 'product_name')[0]?.value"
            :filters="getColumnFilterValues('tableData', 'product_name')"
          >
            <template slot-scope="scope">
              <OptionDataCell
                v-if="!scope.row.isChild"
                :option="scope.row"
                isTwoRows
                :disableFields="selectedProducts.length > 1"
                :placeholder="ts_placeholder"
                :changeable="scope.row.isProductEditable"
                use-ts-placeholder-image
                @image-click="selectedProduct = scope.row; editModalOpened = true"
                open-in-new-tab
                @input="scope.row.product_name = $event; scope.row.isNewChunk ? null : handleProductChangedDelayed(scope.row, 'product_name')"
                name-key="product_name"
              />
              <div v-else class="ml-8 mb-1">-</div>
            </template>
          </data-tables-column>

          <data-tables-column
            v-for="column in selectedPropertiesColumns"
            :key="formatProp(column)"
            :prop="formatProp(column)"
            width="120"
            :column-key="formatProp(column)"
            :label="formatProp(column) | formatString"
            sortable
          >
            <template slot-scope="scope">
              <div v-if="!scope.row.isChild">
                <v-autocomplete
                  v-if="analyticsProperties && scope.row.isProductEditable"
                  :menu-props="{
                    left: true
                  }"
                  hide-details
                  class="mb-1"
                  v-model="scope.row.properties[formatProp(column)]"
                  @input="scope.row.isNewChunk ? null : updateChunkDelayed(scope.row,formatProp(column), true)"
                  :items="analyticsProperties[column]"
                ></v-autocomplete>
                <div
                  v-else-if="analyticsProperties"
                >
                  {{scope.row[formatProp(column)]}}
                </div>
              </div>
            </template>
          </data-tables-column>

          <data-tables-column
            v-if="selectedBasicColumns.includes('stores')"
            prop="stores"
            column-key="stores"
            label="Stores"
            width="80"
            sortable="custom"
            :filter-method="equalsFilter"
            :filtered-value="tableFilters.filter(item => item.prop === 'stores')[0]?.value"
            :filters="getColumnFilterValues('tableData', 'stores')"
          >
            <template slot-scope="scope">

              <v-text-field
                v-if="!scope.row.isNewChunk && (scope.row.isProductEditable || (scope.row.isChild && scope.row.isChildEditable))"
                :value="scope.row.stores"
                @keydown.enter="moveToNextField('stores', scope.$index, $event)"
                hide-details="auto"
                :ref="`field-stores-${scope.$index}`"
                class="mb-1"
                :error-messages="getStoresError(scope.row.stores)"
                @input="handleStoresChange($event, scope.row)"
              />

              <div v-else-if="!scope.row.isChild">{{ scope.row.stores }}</div>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedBasicColumns.includes('stars')"
            prop="stars"
            column-key="stars"
            label="Stars"
            sortable="custom"
            width="80"
            :filter-method="equalsFilter"
            :filtered-value="tableFilters.filter(item => item.prop === 'stars')[0]?.value"
            :filters="getColumnFilterValues('tableData', 'stars')"
          >
            <template slot-scope="scope">
              <v-text-field
                v-if="!scope.row.isNewChunk && (scope.row.isProductEditable || (scope.row.isChild && scope.row.isChildEditable))"
                :value="scope.row.stars"
                @keydown.enter="moveToNextField('stars',scope.$index, $event)"
                hide-details="auto"
                :ref="`field-stars-${scope.$index}`"
                class="mb-1"
                :error-messages="getStarsError(scope.row.stars)"
                @input="handleStarsChange($event, scope.row)"
              />
              <div v-else-if="!scope.row.isChild">{{ scope.row.stars }}</div>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedBasicColumns.includes('sku_names')"
            prop="sku_names"
            column-key="sku_names"
            label="SKU names"
            sortable="custom"
            width="150"
            :filter-method="equalsFilter"
            :filtered-value="tableFilters.filter(item => item.prop === 'sku_names')[0]?.value"
            :filters="getColumnFilterValues('tableData', 'sku_names')"
          >
            <template slot-scope="scope">
              <ChipsDropdown :items="scope.row.sku_names || []" />
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedBasicColumns.includes('week_number')"
            prop="week_number"
            column-key="week_number"
            label="Week number"
            sortable="custom"
            width="120"
            :filter-method="equalsFilter"
            :filtered-value="tableFilters.filter(item => item.prop === 'week_number')[0]?.value"
            :filters="getColumnFilterValues('tableData', 'week_number')"
          >
            <template slot-scope="scope">
              <WeekSelector
                v-if="scope.row.isProductEditable || (scope.row.isChild && scope.row.isChildEditable)"
                v-model="scope.row.week_number"
                :start-year="0"
                only-future
                hasNoWeekItem
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                hide-details="auto"
                class="mb-1"
                :error-messages="scope.row.errors?.week_number"
                @input="scope.row.isNewChunk ? null : updateChunkDelayed(scope.row, 'week_number')"
              />
              <div v-else-if="scope.row.week_number">{{ scope.row.week_number | formatWeekNumber }}</div>
              <div v-else>{{ scope.row.week_number }}</div>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedBasicColumns.includes('original_value')"
            prop="original_value"
            column-key="original_value"
            label="Original value"
            sortable="custom"
            :filter-method="equalsFilter"
            :filtered-value="tableFilters.filter(item => item.prop === 'original_value')[0]?.value"
            :filters="getColumnFilterValues('tableData', 'original_value')"
          >
            <template slot-scope="scope">
              {{ scope.row.original_value !== null ? priceFormatterNoDecimal.format(scope.row.original_value) : '-' }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedBasicColumns.includes('cost_value')"
            prop="cost_value"
            column-key="cost_value"
            label="Cost value"
            sortable="custom"
            :filter-method="equalsFilter"
            :filtered-value="tableFilters.filter(item => item.prop === 'cost_value')[0]?.value"
            :filters="getColumnFilterValues('tableData', 'cost_value')"
          >
            <template slot-scope="scope">
              {{ scope.row.cost_value !== null ? priceFormatterNoDecimal.format(scope.row.cost_value) : '-' }}
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedBasicColumns.includes('margin')"
            prop="initial_margin"
            column-key="initial_margin"
            label="Init. Margin"
            sortable="custom"
            :filter-method="equalsFilter"
            :filtered-value="tableFilters.filter(item => item.prop === 'initial_margin')[0]?.value"
            :filters="getColumnFilterValues('tableData', 'initial_margin')"
          >
            <template slot-scope="scope">
              {{ scope.row.initial_margin }}%
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedBasicColumns.includes('original_price')"
            prop="original_price"
            column-key="original_price"
            label="Original price"
            sortable="custom"
            :filter-method="equalsFilter"
            :filtered-value="tableFilters.filter(item => item.prop === 'original_price')[0]?.value"
            :filters="getColumnFilterValues('tableData', 'original_price')"
          >
            <template slot-scope="scope">
              <v-text-field
                v-if="scope.row.isProductEditable || (scope.row.isChild && scope.row.isChildEditable)"
                :value="scope.row.original_price"
                @keydown.enter="moveToNextField('original_price', scope.$index,$event)"
                :ref="`field-original_price-${scope.$index}`"
                hide-details="auto"
                class="mb-1"
                :error-messages="scope.row.errors?.original_price"
                @input="handleOriginalPriceChanged($event, scope.row)"
              />
              <div v-else>{{ priceFormatterForPrice.format(scope.row.original_price) }}</div>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedBasicColumns.includes('cost_price')"
            prop="cost_price"
            column-key="cost_price"
            label="Cost price"
            sortable="custom"
            :filter-method="equalsFilter"
            :filtered-value="tableFilters.filter(item => item.prop === 'cost_price')[0]?.value"
            :filters="getColumnFilterValues('tableData', 'cost_price')"
          >
            <template slot-scope="scope">
              <v-text-field
                v-if="scope.row.isProductEditable || (scope.row.isChild && scope.row.isChildEditable)"
                :ref="`field-cost_price-${scope.$index}`"
                @keydown.enter="moveToNextField('cost_price', scope.$index, $event)"
                :value="scope.row.cost_price"
                hide-details="auto"
                class="mb-1"
                :error-messages="scope.row.errors?.cost_price"
                @input="handleCostPriceChanged($event, scope.row)"
              />
              <div v-else>{{ priceFormatterForPrice.format(scope.row.cost_price) }}</div>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedBasicColumns.includes('pieces')"
            prop="pieces"
            column-key="pieces"
            label="Pieces"
            sortable="custom"
            :filter-method="equalsFilter"
            :filtered-value="tableFilters.filter(item => item.prop === 'pieces')[0]?.value"
            :filters="getColumnFilterValues('tableData', 'pieces')"
          >
            <template slot-scope="scope">
              <v-text-field
                v-if="scope.row.isProductEditable || (scope.row.isChild && scope.row.isChildEditable)"
                :value="scope.row.pieces"
                :ref="`field-pieces-${scope.$index}`"
                @keydown.enter="moveToNextField('pieces', scope.$index, $event)"
                hide-details="auto"
                class="mb-1"
                :error-messages="scope.row.errors?.pieces"
                @input="handlePiecesChanged($event, scope.row)"
              />
              <div v-else>{{ scope.row.pieces }}</div>
            </template>
          </data-tables-column>
          <data-tables-column
            v-if="selectedBasicColumns.includes('status')"
            prop="status"
            column-key="status"
            label="Status"
            sortable="custom"
            :filter-method="equalsFilter"
            :filtered-value="tableFilters.filter(item => item.prop === 'status')[0]?.value"
            :filters="getColumnFilterValues('tableData', 'status')"
          >
            <template slot-scope="scope">
              <div v-if="!scope.row.isChild" class="text-capitalize" :class="scope.row.statusColorClass">
                {{ scope.row.status }}
              </div>
              <div v-else class="mb-1 text-capitalize" :class="scope.row.statusColorClass">{{ scope.row.type || '-' }}</div>

            </template>
          </data-tables-column>
          <el-table-column width="50">
            <template slot-scope="scope">
              <v-icon
                v-if="scope.row.isNewChunk"
                class="my-2"
                @click="handleAddChunkClick(scope.row)"
              >add</v-icon>
              <v-icon
                v-else
                class="my-2 action-btn-danger"
                :disabled="!(scope.row.isProductDeletable || (scope.row.isChild && scope.row.isChildDeletable))"
                @click="scope.row.isChild ? handleDeleteChunkClick(scope.row) : handleDeleteProductClick(scope.row)"
              >delete</v-icon>
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
      <DeleteDialog
        :visible="isDeleteProductModalOpen"
        content-title="Delete product"
        content-text="Are you sure you want to delete selected product?"
        @cancel="handleDeleteProductCancel"
        @confirm="handleDeleteProductConfirm"
      />
      <DeleteDialog
        :visible="isDeleteChunkModalOpen"
        content-title="Delete chunk"
        content-text="Are you sure you want to delete selected chunk?"
        @cancel="handleDeleteChunkCancel"
        @confirm="handleDeleteChunkConfirm"
      />
    </v-row>
    <v-dialog
      v-model="editModalOpened"
      width="600px"
      v-if="editModalOpened"
      scrollable
      max-width="90%"
    >
      <ProductsEditModal
        :analyticsProperties="analyticsProperties"
        :parsedAnalyticsProperties="parsedAnalyticsProperties"
        :product="selectedProduct"
        @close="editModalOpened = false"
        @chunk-deleted="onChunkDeleted"
        @chunk-created="handleAddChunkClick"
        @save="handleProductChanged($event); editModalOpened = false"
      ></ProductsEditModal>
    </v-dialog>
  </resizable>
</template>

<script>
import { debounce, round } from 'lodash'
import columnFilters from '@/mixins/columnFilters'
import { downloadData, getStatusColorClass } from '@/utils'
import {
  createStrategyCollectionChunk,
  updateStrategyCollectionChunk,
  deleteStrategyCollectionChunk,
  updateStrategyCollectionProduct,
  deleteStrategyCollectionProduct,
} from '@/api/strategy'
import TableSearch from '@/components/TableSearch.vue'
import OptionDataCell from '@/components/Dummies/OptionDataCell.vue'
import WeekSelector from '@/components/WeekSelector'
import ChipsDropdown from '@/components/ChipsDropdown'
import Resizable from '@/components/Utility/Resizable.vue'
import DeleteDialog from '@/components/DeleteDialog'
import DataTablesColumn from '@/components/Tables/components/DataTablesColumn.vue'
import DataExport from '@/components/DataExport'
import ProductsEditModal from '@/components/Strategy/ProductsEditModal.vue'
import ts_placeholder from '@/assets/img/ts_placeholder.png'
const PropKey = 'properties/article/'

export default {
  name: 'StrategyCollectionProducts',
  components: {
    ProductsEditModal, Resizable, OptionDataCell, WeekSelector, ChipsDropdown, DeleteDialog, TableSearch, DataTablesColumn, DataExport },
  mixins: [ columnFilters ],
  props: {
    products: {
      type: Array,
      default: () => []
    },
    analyticsProperties: {
      type: Object,
      default: () => ({})
    },
    optionsProperties: {
      type: Array,
      default: () => ([]),
    },
  },
  data () {
    const allBasicColumns = ['product_name', 'stores', 'stars', 'sku_names', 'week_number', 'original_value', 'cost_value', 'margin', 'original_price', 'cost_price', 'pieces', 'status']
    const defaultBasicColumns = ['product_name', 'week_number', 'original_value', 'cost_value', 'margin', 'original_price', 'cost_price', 'pieces', 'status']

    return {
      ts_placeholder,
      pageSize: 10,
      loading: false,
      selectedProduct: null,
      selectedBasicColumns: JSON.parse(localStorage.getItem('strategyCollectionProductsBasicColumns')) || defaultBasicColumns,
      selectedPropertiesColumns: JSON.parse(localStorage.getItem('strategyCollectionProductsPropertiesColumns')) || [],
      allBasicColumns,
      tableFilters: [{ value: '' }],
      expandedRows: [],
      editModalOpened: false,
      isDeleteProductModalOpen: false,
      isDeleteChunkModalOpen: false,
      productToDelete: null,
      chunkToDelete: null,
      updateChunkDelayed: null,
      updateBulkProductDelayed: null,
      handleProductChangedDelayed: null,
      tableTotals: [],
    }
  },
  computed: {
    selectedProducts (){
      return this.tableData.filter(item=> item.selected)
    },
    selectableProducts (){
      return this.tableData.filter(item=> item.isProductEditable)
    },
    allRowsSelected (){
      return this.selectedProducts.length === this.selectableProducts.length
    },
    priceFormatter () {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.$auth.getUserCurrency().currency,
        minimumFractionDigits: 2,
        maximumFractionDigits:2,
      })
    },
    analyticsPropertiesList (){
      return Object.keys(this.analyticsProperties).sort()
    },
    parsedAnalyticsProperties (){
      const list = this.analyticsPropertiesList.filter(item=> item.startsWith(PropKey))
      return list.map(item=> ({
        label: item.split(PropKey)[1],
        value: item
      }))
    },
    priceFormatterNoDecimal () {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.$auth.getUserCurrency().currency,
        maximumFractionDigits: 0,
      })
    },
    priceFormatterForPrice () {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.$auth.getUserCurrency().currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    },
    propertiesColumns () {
      return this.optionsProperties.map(op => {
        const parts = op.split('/')

        return {
          value: parts.slice(-1)[0],
          text: this.$options.filters.formatString(parts.slice(-1)[0])
        }
      })
    },
    tableData () {
      return this.products.map(p => ({
        ...p,
        ...p.properties,
        rowKey: `product-key-${p.product_key}`,
        statusColorClass: getStatusColorClass(p, '--text'),
        hasChildren: (p.chunks.length > 1) || !['generated', 'placeholder'].includes(p.status),
        isProductEditable: (p.chunks.length === 1) && ['generated', 'placeholder'].includes(p.status),
        isProductDeletable: ['generated', 'placeholder'].includes(p.status),
      }))
    },
  },
  watch: {
    tableData: {
      handler () {
        this.tableTotals = this.calculateTotals()
      },
    },
  },
  methods: {
    handleSizeChange (size){
      this.pageSize = size
    },
    moveToPrevField (prop, id){

      let prev
      while(id > 0 && !prev){
        id--
        prev = this.$refs[`field-${prop}-${id}`]
      }
      prev?.focus()
    },
    moveToNextField (prop, id, event){
      if(event.shiftKey){
        this.moveToPrevField(prop, id)
        return
      }
      let next
      while(id < this.pageSize && !next){
        id++
        next = this.$refs[`field-${prop}-${id}`]
      }
      next?.focus()
    },
    selectAllProducts (value){
      this.$emit('products-selected', {value, keys:this.selectableProducts.map(item=> item.product_key)})
    },
    getStoresError (stores){
      if(stores === null) {
        return null
      }
      return stores <= 0 ? 'Must be greater than 0' : null
    },
    getStarsError (stars){
      if(stars === null) {
        return null
      }

      return stars <= 0 || stars > 5 ? 'Must be between 1 and 5' : null
    },
    onChunkDeleted (row){
      this.chunkToDelete = row
      this.handleDeleteChunkConfirm()
    },
    setSelectedProduct (value, product){
      this.$emit('product-selected', {value, key:product.product_key})
    },
    formatProp (key){
      return key.split(PropKey)[1]
    },
    updateTotalTableWidth () {
      const appendTable = document.querySelector('#strategy-collection-products .el-table__append-wrapper')
      if (appendTable) {
        appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
      }
    },
    calculateTotals () {
      const totals = {
        chunks: 0,
        pieces: 0,
        original_value: 0,
        cost_value: 0,
        original_price: 0,
        cost_price: 0,
      }
      this.products.forEach(product => {
        Object.keys(totals).forEach(key => {
          totals[key] += product[key]
        })
      })

      totals.original_price = round(totals.original_value / totals.pieces, 2)
      totals.cost_price = round(totals.cost_value / totals.pieces, 2)

      return [totals]
    },

    rowClassName ({ row }) {
      return row.isChild ? `chunk-row-${row.chunk_key}` : `product-row-${row.product_key}`
    },
    handleColumnsSelectionChanged () {
      localStorage.setItem('strategyCollectionProductsBasicColumns', JSON.stringify(this.selectedBasicColumns))
      localStorage.setItem('strategyCollectionProductsPropertiesColumns', JSON.stringify(this.selectedPropertiesColumns))
    },
    loadChildData (parentRow, treeNode, resolve) {
      let productsChunks = []

      productsChunks = parentRow.chunks
        .map(c => ({
          ...c,
          original_value: round(c.original_price * c.pieces, 2),
          cost_value: round(c.cost_price * c.pieces, 2),
          product: parentRow,
          isChild: true,
          rowKey: `chunk-row-${c.chunk_key}`,
          product_key: parentRow.product_key,
          isChildEditable: c.type === 'placeholder',
          isChildDeletable: c.type === 'placeholder',
        }))
      productsChunks.push({
        original_value: null,
        cost_value: null,
        original_price: parentRow.original_price,
        cost_price: parentRow.cost_price,
        product: parentRow,
        product_key: parentRow.product_key,
        isChild: true,
        isNewChunk: true,
        isChildEditable: true,
        type: 'placeholder',
        rowKey: `new-chunk-row-${parentRow.product_key}`,
      })

      resolve(productsChunks)
    },
    validateChunk (chunk) {
      this.$set(chunk, 'errors', {})
      const fields = ['type', 'week_number', 'pieces', 'original_price', 'cost_price']

      fields.forEach(field => {
        if (chunk[field] === undefined || chunk[field] === '') {
          this.$set(chunk.errors, field, ['Required'])
        }
      })
      return Object.keys(chunk.errors).length === 0
    },
    validateProduct (product) {
      this.$set(product, 'errors', {})
      const fields = ['stores', 'stars', 'status']

      fields.forEach(field => {
        if (product[field] === undefined || product[field] === '') {
          this.$set(product.errors, field, ['Required'])
        }
      })

      return Object.keys(product.errors).length === 0
    },
    calculateProductPieces (product) {
      const expandedData = this.$refs.productsTable.$refs.elTable.store.states.lazyTreeNodeMap[`product-key-${product.product_key}`]

      product.pieces = expandedData.reduce((acc, chunk) => acc + (chunk.pieces ? chunk.pieces : 0), 0)
    },
    calculateProductOriginalValue (product) {
      const expandedData = this.$refs.productsTable.$refs.elTable.store.states.lazyTreeNodeMap[`product-key-${product.product_key}`]

      product.original_value = expandedData.reduce((acc, chunk) => acc + (chunk.original_value ? chunk.original_value : 0), 0)
    },
    calculateProductOriginalPrice (product) {
      product.original_price = round(product.original_value / product.pieces, 2)
    },
    calculateProductCostValue (product) {
      const expandedData = this.$refs.productsTable.$refs.elTable.store.states.lazyTreeNodeMap[`product-key-${product.product_key}`]

      product.cost_value = expandedData.reduce((acc, chunk) => acc + (chunk.cost_value ? chunk.cost_value : 0), 0)
    },
    calculateProductCostPrice (product) {
      product.cost_price = round(product.cost_value / product.pieces, 2)
    },
    calculateProductMargin (product) {
      product.margin = round((1 - product.cost_value / product.original_value_ex_vat) * 100, 1)
    },
    calculateChunkOriginalValue (chunk) {
      chunk.original_value = Math.round(chunk.pieces * chunk.original_price)
    },
    calculateChunkCostValue (chunk) {
      chunk.cost_value = Math.round(chunk.pieces * chunk.cost_price)
    },
    handleExpandChange (row, expandedRows) {
      this.expandedRows = expandedRows
    },
    handleDeleteProductClick (product) {
      this.isDeleteProductModalOpen = true
      this.productToDelete = product
    },
    async handleAddChunkClick (row) {
      if (!this.validateChunk(row)) {
        return false
      }

      this.loading = true

      try {
        const payload = {
          ...row,
          product_key: row.product.product_key,
        }
        const newRow = await createStrategyCollectionChunk(payload)
        const expandedData = this.$refs.productsTable.$refs.elTable.store.states.lazyTreeNodeMap[`product-key-${row.product.product_key}`]
        if(!expandedData){
          this.$set(row.product, 'chunks', [...row.product.chunks, newRow])
          this.$emit('products-updated', {...row.product})
          return
        }
        // remove filled new chunk row
        expandedData.splice(-1)

        // push created chunk
        expandedData.push({
          ...newRow,
          product: row.product,
          isChild: true,
          isChildDeletable: true,
          rowKey: `chunk-row-${newRow.chunk_key}`
        })

        // add empty new chunk row
        expandedData.push({
          original_value: null,
          cost_value: null,
          original_price: row.original_price,
          cost_price: row.cost_price,
          product: row.product,
          product_key: row.product_key,
          isChild: true,
          isNewChunk: true,
          isChildEditable: true,
          type: 'placeholder',
          rowKey: `new-chunk-row-${row.product.product_key}`,
        })
      } finally {
        this.loading = false
      }
      this.$emit('products-updated', [{...row.product}])
    },
    handleDeleteChunkClick (chunk) {
      this.isDeleteChunkModalOpen = true
      this.chunkToDelete = chunk
    },
    handleDeleteProductCancel () {
      this.isDeleteProductModalOpen = false
      this.productToDelete = null
    },
    handleDeleteChunkCancel () {
      this.isDeleteChunkModalOpen = false
      this.chunkToDelete = null
    },
    async handlePiecesChanged (event, row) {
      row.pieces = parseFloat(event.toString().replace(',', '.') || 0)

      if (row.isChild) {
        this.calculateChunkOriginalValue(row)
        this.calculateChunkCostValue(row)
        this.calculateProductPieces(row.product)
        this.calculateProductOriginalValue(row.product)
        this.calculateProductCostValue(row.product)
        this.tableTotals = this.calculateTotals()
      }else {
        this.updateBulkProductDelayed(row, 'pieces')
        return
      }

      this.updateChunkDelayed.cancel()

      if (!row.isNewChunk) {
        if (this.validateChunk(row.isChild ? row : row.chunks[0])) {
          await this.updateChunkDelayed(row)
        }
      }
    },
    handleStoresChange (event, row){

      if(!event){
        event = '0'
      }
      const value = parseInt(event)
      if(!row.errors) {
        row.errors = {}
      }
      if(value <= 0 ){
        this.$set(row.errors, 'stores', 'Must be greater than 0')

        return
      }
      this.$set(row.errors, 'stores', null)
      row.stores = value
      this.updateBulkProductDelayed(row, 'stores')
    },
    handleStarsChange (event, row){
      if(!event){
        event = '0'
      }
      const value = parseInt(event)
      if(!row.errors) {
        row.errors = {}
      }
      if(value <= 0 || value > 5 ){
        this.$set(row.errors, 'stars', 'Must be between 1 and 5')
        return
      }
      this.$set(row.errors, 'stars', null)
      row.stars = value
      this.updateBulkProductDelayed(row, 'stars')
    },
    async handleOriginalPriceChanged (event, row) {
      row.original_price = parseFloat(event.toString().replace(',', '.') || 0)

      if (row.isChild) {
        this.calculateChunkOriginalValue(row)
        this.calculateChunkCostValue(row)
        this.calculateProductOriginalValue(row.product)
        this.calculateProductCostValue(row.product)
        this.calculateProductOriginalPrice(row.product)
        // this.calculateProductMargin(row.product)
        this.tableTotals = this.calculateTotals()
      }else {
        this.updateBulkProductDelayed(row, 'original_price')
        return
      }

      this.updateChunkDelayed.cancel()

      if (!row.isNewChunk) {
        if (this.validateChunk(row.isChild ? row : row.chunks[0])) {
          await this.updateChunkDelayed(row)
        }
      }
    },
    async handleCostPriceChanged (event, row) {
      row.cost_price = parseFloat(event.toString().replace(',', '.') || 0)

      if (row.isChild) {
        this.calculateChunkOriginalValue(row)
        this.calculateChunkCostValue(row)
        this.calculateProductOriginalValue(row.product)
        this.calculateProductCostValue(row.product)
        this.calculateProductCostPrice(row.product)
        // this.calculateProductMargin(row.product)
        this.tableTotals = this.calculateTotals()
      }else {
        this.updateBulkProductDelayed(row, 'cost_price')
        return
      }

      this.updateChunkDelayed.cancel()

      if (!row.isNewChunk) {
        if (this.validateChunk(row.isChild ? row : row.chunks[0])) {
          await this.updateChunkDelayed(row)
        }
      }
    },
    async handleProductChanged (row) {
      if (this.validateProduct(row)) {
        await this.updateProduct(row)

      }
    },
    async handleDeleteProductConfirm () {
      this.loading = true

      try {
        await deleteStrategyCollectionProduct(this.productToDelete.product_key)
      } finally {
        this.loading = false
        this.isDeleteProductModalOpen = false
      }

      this.$emit('product-deleted', this.productToDelete)
    },
    async handleDeleteChunkConfirm () {
      this.loading = true

      try {
        await deleteStrategyCollectionChunk(this.chunkToDelete.chunk_key)

        const expandedData = this.$refs.productsTable.$refs.elTable.store.states.lazyTreeNodeMap[`product-key-${this.chunkToDelete.product.product_key}`]

        if(!expandedData){
          this.$set(this.chunkToDelete.product, 'chunks', this.chunkToDelete.product.chunks.filter(item=> item.chunk_key !== this.chunkToDelete.chunk_key))
        }else {
          this.$refs.productsTable.$refs.elTable.store.states.lazyTreeNodeMap[`product-key-${this.chunkToDelete.product.product_key}`] = expandedData.filter(chunk => chunk.chunk_key !== this.chunkToDelete.chunk_key)
        }
        this.calculateProductPieces(this.chunkToDelete.product)
        this.calculateProductOriginalValue(this.chunkToDelete.product)
        this.calculateProductCostValue(this.chunkToDelete.product)
        this.$emit('products-updated', [{...this.chunkToDelete.product}])

        this.chunkToDelete = null
      } finally {
        this.loading = false
        this.isDeleteChunkModalOpen = false
      }

      this.$emit('chunk-deleted', this.chunkToDelete)
    },
    async updateBulkProduct (product, propName, isProperty){
      const payload = {}
      if(isProperty){
        payload.properties = {[propName]: product.properties[propName]}
      }else {
        payload[propName] = product[propName]
      }
      const keys = [product, ...this.selectedProducts].map(item=> item.product_key)
      payload.product_keys = [...(new Set(keys))]
      const response = await updateStrategyCollectionProduct(payload)

      this.$emit('products-updated', response.products.map(item=> ({...item, selected: true})))
    },
    async updateChunk (chunkOrProduct, propName, isProperty) {
      if(!chunkOrProduct.isChild){
        this.updateBulkProductDelayed(chunkOrProduct, propName, isProperty)
        return
      }
      let payload = { ...chunkOrProduct }
      const keysToUpdate = ['week_number', 'original_price', 'cost_price', 'pieces']

      if (chunkOrProduct.chunks) {
        payload = {
          ...chunkOrProduct.chunks[0],
          product_key: chunkOrProduct.product_key,
        }
        keysToUpdate.forEach(key => {
          payload[key] = chunkOrProduct[key]
        })
      }

      this.loading = true

      try {
        await updateStrategyCollectionChunk(payload)
        this.$emit('updated-chunk', chunkOrProduct.product_key)
      } finally {
        this.loading = false
      }
    },
    async updateProduct (product) {
      this.loading = true

      try {
        await updateStrategyCollectionProduct(product)
      } finally {
        this.$emit('updated-chunk', product.product_key)
        this.loading = false
      }
    },
    downloadProductsData (sep) {
      const data = this.tableData

      downloadData(sep, data, 'strategy_collection_products')
    },
  },
  created () {
    this.updateChunkDelayed = debounce(this.updateChunk, 1000)
    this.updateBulkProductDelayed = debounce(this.updateBulkProduct, 1000)
    this.handleProductChangedDelayed = debounce(this.handleProductChanged, 1000)
  }
}
</script>

<style lang="scss">
#strategy-collection-products {
  .total-row_column {
    background-color: #f5f7fa;
  }
  .v-image__image--preload{
    filter: none
  }
  .el-table__row {
    .option-data-cell--general {
      padding-left: 16px;
    }
    &.el-table__row--level-0 {
      .option-data-cell--general {
        padding-left: 0;
      }
    }
  }
  .el-table__row.el-table__row--level-1 {
    background: #fafafa;
    td {
      border-bottom: 0;
    }
    .el-table__indent,
    .el-table__placeholder {
        display: none;
    }
  }
  .el-table__expand-icon {
    float: left;
  }
  .v-text-field {
    font-size: 13px;
    font-weight: 500;
    padding-top: 0;
    margin-top: 0;

    input {
      color: rgb(96, 98, 102) !important;
    }
  }
  .el-table__column-filter-trigger {
    line-height: normal;
    transform: none;
    vertical-align: middle;
    .el-icon-arrow-down {
      &::before {
        display: none;
      }
    }
  }
  .overflow-visible {
    .cell {
      overflow: visible;
    }
  }
}

.column-selection-menu {
  .column-checkbox {
    .v-label {
      font-size: 14px;
      font-weight: 500;
    }
    &.v-input--is-label-active {
      .v-label {
        color: var(--primary);
      }
    }
  }
}
</style>
