<template>
  <div class="settings-stores-list p-relative">
    <v-overlay absolute color="white" :value="false">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <h6 class="text-subtitle-1 font-weight-medium mt-4 ml-6">Stores overview</h6>
    <v-row>
      <div class="col text-right pr-6">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('storesTableFilters', columnsToSearch, val)"
        />
        <v-icon :disabled="!selectedRows[0]" class="mx-2 action-btn-primary" @click="handleEditClick">edit</v-icon>
        <v-icon class="mx-2 action-btn-primary" @click="handleAddClick">add</v-icon>
        <v-icon class="mx-2 action-btn-danger" :disabled="!selectedRows[0] || selectedRows[0].in_use" @click="handleDeleteClick">delete</v-icon>
        <v-menu offset-y offset-x nudge-bottom="5" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on"
            >filter_list
            </v-icon>
          </template>
          <v-card max-height="400" class="overflow-auto">
            <h5 class="px-2 pt-2">Columns</h5>
            <el-checkbox-group v-model="selectedColumns" @input="saveSelectedColumns" style="font-size: 14px"
              class="d-flex flex-column pa-2">
              <el-checkbox v-for="item of columnsToSelect" :label="item" :key="item"></el-checkbox>
            </el-checkbox-group>
          </v-card>
        </v-menu>
      </div>
    </v-row>
    <v-row>
      <div class="col">
        <data-tables
          style="width: 100%"
          :data="storesList"
          :page-size="10"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :table-props="{ 'row-key': 'store_key' }"
          :filters="storesTableFilters"
          filter-multiple
          @filter-change="filters => handleFilterChange('storesTableFilters', filters)"
        >
          <el-table-column prop="store_key" width="55">
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-4 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="store_name"
            column-key="store_name"
            label="Store"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'store_name')"
          >
            <template slot-scope="scope">
              <v-text-field
                v-model="scope.row.store_name"
                :error-messages="scope.row.errors?.store_name"
                placeholder="Store name"
                hide-details="auto"
                clearable
                class="pt-0 my-1 mr-4 text-body-2"
                @input="handleTableInlineChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Country')"
            prop="country"
            column-key="country"
            label="Country"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'country')"
          >
            <template slot-scope="scope">
              <v-text-field
                v-model="scope.row.country"
                placeholder="Country"
                hide-details="auto"
                clearable
                class="pt-0 my-1 mr-4 text-body-2"
                @input="handleTableInlineChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Type')"
            prop="store_type"
            column-key="store_type"
            label="Type"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'store_type')"
          >
            <template slot-scope="scope">
              <v-select
                v-model="scope.row.store_type"
                :items="typeOptions"
                :error-messages="scope.row.errors?.store_type"
                dense
                class="store-type-dropdown pt-0 my-1 mr-4 text-body-2"
                hide-details
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @input="handleTableInlineChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Open')"
            prop="date_min"
            column-key="date_min"
            label="Open"
            :filters="getColumnFilterValues('storesList', 'date_min')"
            sortable="custom"
            min-width="90"
          >
            <template slot-scope="scope">
              <v-menu
                ref="datePicker"
                v-model="tableDatepickerOpenActive[scope.row.store_key]"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="scope.row.date_min"
                    dense
                    hide-details
                    class="pt-0 my-1 mr-4 text-body-2"
                    v-on="on"
                    @change="handleTableInlineChange(scope.row)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  :locale-first-day-of-year="4"
                  :show-week="true"
                  no-title
                  v-model="scope.row.date_min"
                  @change="handleTableDateOpenSelected(scope.row)"
                />
              </v-menu>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Close')"
            prop="date_max"
            column-key="date_max"
            label="Close"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'date_max')"

          >
            <template slot-scope="scope">
              <v-menu
                ref="datePicker"
                v-model="tableDatepickerCloseActive[scope.row.store_key]"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="scope.row.date_max"
                    dense
                    hide-details
                    class="pt-0 my-1 mr-4 text-body-2"
                    v-on="on"
                    @change="handleTableInlineChange(scope.row)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  :locale-first-day-of-year="4"
                  :show-week="true"
                  no-title
                  v-model="scope.row.date_max"
                  @change="handleTableDateCloseSelected(scope.row)"
                />
              </v-menu>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Export proposals')"
            prop="export_proposals"
            column-key="export_proposals"
            label="Export proposals"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'export_proposals')"
          >
            <template slot-scope="scope">
              <v-simple-checkbox
                v-model="scope.row.export_proposals"
                color="primary"
                dense
                hide-details
                class="d-inline-block mt-0 pt-0"
                :ripple="false"
                @input="handleTableInlineChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Proposal key')"
            prop="proposal_key"
            column-key="proposal_key"
            label="Proposal key"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'proposal_key')"
          >
            <template slot-scope="scope">
              <v-autocomplete
                v-model="scope.row.proposal_key"
                placeholder="Proposal key"
                :items="(appConfig?.stores_open || [])"
                item-text="store_name"
                item-value="store_key"
                hide-details="auto"
                clearable
                class="pt-0 my-1 mr-4 text-body-2"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @input="handleTableInlineChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Stock key')"
            prop="stock_key"
            column-key="stock_key"
            label="Stock key"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'stock_key')"
          >
            <template slot-scope="scope">
              <v-autocomplete
                v-model="scope.row.stock_key"
                placeholder="Stock key"
                :items="[...(appConfig?.stores_open || []), ...getAdditionalStoresStock(scope.row)]"
                item-text="store_name"
                item-value="store_key"
                :item-color="scope.row.stock_key === getAdditionalStoresStock(scope.row)[0]?.store_key ? 'red' : undefined"
                hide-details="auto"
                clearable
                class="pt-0 my-1 mr-4 text-body-2"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @input="handleTableInlineChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('DC key')"
            prop="dc_key"
            column-key="dc_key"
            label="DC key"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'dc_key')"
          >
            <template slot-scope="scope">
              <v-autocomplete
                v-model="scope.row.dc_key"
                :items="[...(appConfig?.stores_open || []), ...getAdditionalStoresDc(scope.row)]"
                item-text="store_name"
                item-value="store_key"
                :item-color="scope.row.dc_key === getAdditionalStoresDc(scope.row)[0]?.store_key ? 'red' : undefined"
                hide-details="auto"
                clearable
                class="pt-0 my-1 mr-4 text-body-2"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @input="handleTableInlineChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Looks like store')"
            prop="looks_like_store_key"
            column-key="looks_like_store_key"
            label="Looks like store"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'looks_like_store_key')"
          >
            <template slot-scope="scope">
              <v-autocomplete
                v-model="scope.row.looks_like_store_key"
                placeholder="Looks like store"
                :items="(appConfig?.stores || [])"
                item-text="store_name"
                item-value="store_key"
                hide-details="auto"
                clearable
                class="pt-0 my-1 mr-4 text-body-2"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @input="handleTableInlineChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Looks like percentage')"
            prop="looks_like_percentage"
            column-key="looks_like_percentage"
            label="Looks like percentage"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'looks_like_percentage')"
          >
            <template slot-scope="scope">
              <v-text-field
                v-model.number="scope.row.looks_like_percentage"
                :error-messages="scope.row.errors?.looks_like_percentage"
                placeholder="Looks like percentage"
                hide-details="auto"
                clearable
                class="pt-0 my-1 mr-4 text-body-2"
                @input="handleTableInlineChange(scope.row)"
              ></v-text-field>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('One Stock')"
            prop="one_stock"
            column-key="one_stock"
            label="One Stock"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'one_stock')"
          >
            <template slot-scope="scope">
              <v-simple-checkbox
                v-model="scope.row.one_stock"
                color="primary"
                dense
                hide-details
                class="d-inline-block mt-0 pt-0"
                :ripple="false"
                @input="handleTableInlineChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Allow prepacks')"
            prop="prepacks_allowed"
            column-key="prepacks_allowed"
            label="Allow prepacks"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'prepacks_allowed')"
          >
            <template slot-scope="scope">
              <v-simple-checkbox
                v-model="scope.row.prepacks_allowed"
                color="primary"
                dense
                hide-details
                class="d-inline-block mt-0 pt-0"
                :ripple="false"
                @input="handleTableInlineChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Delivery days')"
            prop="delivery_days"
            column-key="delivery_days"
            label="Delivery days"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'logic_parameters.delivery_days')"
          >
            <template slot-scope="scope">
              <v-text-field
                v-model.number="scope.row.logic_parameters.delivery_days"
                :error-messages="scope.row.errors?.delivery_days"
                placeholder="Delivery days"
                hide-details="auto"
                clearable
                class="pt-0 my-1 mr-4 text-body-2"
                @input="handleTableInlineChange(scope.row)"
              >
                <template v-slot:append-outer>
                  <v-tooltip top :max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                    </template>
                    <span>Estimate amount of days between sending and product being sellable</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Redelivery days')"
            prop="redelivery_days"
            column-key="redelivery_days"
            label="Redelivery days"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'logic_parameters.redelivery_days')"
          >
            <template slot-scope="scope">
              <v-text-field
                v-model.number="scope.row.logic_parameters.redelivery_days"
                :error-messages="scope.row.errors?.redelivery_days"
                placeholder="Redelivery days"
                hide-details="auto"
                clearable
                class="pt-0 my-1 mr-4 text-body-2"
                @input="handleTableInlineChange(scope.row)"
              >
                <template v-slot:append-outer>
                  <v-tooltip top :max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                    </template>
                    <span>Average amount of time between deliveries</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Return costs')"
            prop="return_costs"
            column-key="return_costs"
            label="Return costs"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'logic_parameters.return_costs')"
          >
            <template slot-scope="scope">
              <v-text-field
                v-model.number="scope.row.logic_parameters.return_costs"
                :error-messages="scope.row.errors?.return_costs"
                placeholder="Return costs"
                hide-details="auto"
                clearable
                class="pt-0 my-1 mr-4 text-body-2"
                @input="handleTableInlineChange(scope.row)"
              >
                <template v-slot:append-outer>
                  <v-tooltip top :max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                    </template>
                    <span>Estimated return costs per piece</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Constant costs')"
            prop="constant_costs"
            column-key="constant_costs"
            label="Constant costs"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'logic_parameters.constant_costs')"
          >
            <template slot-scope="scope">
              <v-text-field
                v-model.number="scope.row.logic_parameters.constant_costs"
                :error-messages="scope.row.errors?.constant_costs"
                placeholder="Constant costs"
                hide-details="auto"
                clearable
                class="pt-0 my-1 mr-4 text-body-2"
                @input="handleTableInlineChange(scope.row)"
              >
                <template v-slot:append-outer>
                  <v-tooltip top :max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                    </template>
                    <span>Constant costs per piece sold</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Variable costs')"
            prop="variable_costs"
            column-key="variable_costs"
            label="Variable costs"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'logic_parameters.variable_costs')"
          >
            <template slot-scope="scope">
              <v-text-field
                v-model.number="scope.row.logic_parameters.variable_costs"
                :error-messages="scope.row.errors?.variable_costs"
                placeholder="Variable costs"
                hide-details="auto"
                clearable
                class="pt-0 my-1 mr-4 text-body-2"
                @input="handleTableInlineChange(scope.row)"
              >
                <template v-slot:append-outer>
                  <v-tooltip top :max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                    </template>
                    <span>Percentage (0.01=1%) of variable costs on turnover</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Changed at')"
            prop="changed_at"
            column-key="changed_at"
            label="Changed at"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'changed_at')"
          >
            <template slot-scope="scope">
              {{ formatChangedAt(scope.row.changed_at) }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="selectedColumns.includes('Changed by')"
            prop="changed_by"
            column-key="changed_by"
            label="Changed by"
            sortable="custom"
            min-width="90"
            :filters="getColumnFilterValues('storesList', 'changed_by')"
          >
            <template slot-scope="scope">
              {{ scope.row.changed_by }}
            </template>
          </el-table-column>
        </data-tables>
      </div>
    </v-row>

    <!-- Form dialog -->
    <store-edit-modal
      ref="storeEditModal"
      :is-open="formDialogVisible"
      :saving-store-settings="savingStoreSettings"
      :type-options="typeOptions"
      @save="handleSaveClick"
      @close="handleEditClose" />

    <!-- Delete dialog -->
    <v-dialog
      :title="selectedRows[0] ? selectedRows[0].name : null"
      :value="deleteDialogVisible"
      persistent
      width="600px"
      max-width="90%">
      <v-card>
        <v-overlay absolute color="white" :value="storesStore.deletingStore">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium">Delete store</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete this store?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteConfirm"
                class="red--text mx-4"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from '@/services/axios.js'
import { getHeadersWithAccessToken, storeSettingsLogicDefaults } from '@/variables.js'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'
import StoreEditModal from '@/components/GeneralSettings/StoreEditModal'
import { useGeneralStore } from '@/store/pinia/generalStore'
import baseURLs from '@/api/APIBaseURLs'
import { useStoresStore } from '@/store/pinia/storesStore'

const columnsToSelect = [
  'Country', 'Type', 'Open', 'Close', 'Export proposals', 'Proposal key', 'Stock key', 'DC key', 'Looks like store',
  'Looks like percentage', 'One Stock', 'Allow prepacks', 'Delivery days', 'Redelivery days', 'Return costs',
  'Constant costs', 'Variable costs', 'Changed at', 'Changed by']
const defaultColumns = ['Country', 'Type', 'Open', 'Close', 'Export proposals']

export default {
  name: 'StoresList',
  mixins: [columnFilters],
  setup () {
    return {
      generalStore: useGeneralStore(),
      storesStore: useStoresStore(),
    }
  },
  data () {
    this.updateStoresDebounced = _.debounce(this.updateStores, 2000)

    return {
      storesList: [],
      selectedColumns: JSON.parse(localStorage.getItem('settingsStoresSelectedColumns')) || defaultColumns,
      columnsToSelect,
      deleteDialogVisible: false,
      formDialogVisible: false,
      selectedStoreGenerate: null,
      copied: false,
      loadingStoreSettings: false,
      savingStoreSettings: false,
      lifetime: 0,
      tokenUrl: '',
      editedStoreSettings: null,
      newEmails: [],
      storesTableFilters: [],
      tableDatepickerOpenActive: [],
      tableDatepickerCloseActive: [],
      typeOptions: [{
        text: 'Store',
        value: 'store',
      }, {
        text: 'DC',
        value: 'DC',
      }, {
        text: 'Outlet',
        value: 'outlet',
      }, {
        text: 'Webshop',
        value: 'webshop',
      }],
      columnsToSearch: ['store_name', 'country'],
      changedRows: [],
    }
  },
  components: {
    StoreEditModal,
    TableSearch,
  },
  computed: {
    appConfig () {
      return this.generalStore.appConfig
    },
    selectedRows () {
      return this.storesList.filter(r => r.selected)
    },
  },
  watch: {
    'appConfig.stores': {
      handler (newValue) {
        if (!newValue || !newValue.length) {
          return
        }
        const stores = this.appConfig.stores
          .map((item) => ({ ...item, date_min: item.date_min || '', date_max: item.date_max || '' })) || []
        this.storesList = structuredClone(stores)
      },
      immediate: true,

    },
  },
  methods: {
    formatChangedAt (date) {
      if (!date) {
        return ''
      }
      const d = new Date(date)
      return d.toISOString().substring(0, 19) // Time portion down to seconds
    },
    getAdditionalStoresStock (editedStore) {
      if (!editedStore.stock_key) {
        return []
      }
      const storesOpened = this.appConfig?.stores_open || []
      const additionalStoreIndex = storesOpened.findIndex(store => store.store_key === editedStore.stock_key)
      if (additionalStoreIndex !== -1) {
        return []
      }
      const additionalStore = {
        color: 'red',
        ...this.storesList.find(store => store.store_key === editedStore.stock_key)
      }
      return [additionalStore]
    },
    getAdditionalStoresDc (editedStore) {
      if (!editedStore.dc_key) {
        return []
      }
      const storesOpened = this.appConfig?.stores_open || []
      const additionalStoreIndex = storesOpened.findIndex(store => store.store_key === editedStore.dc_key)
      if (additionalStoreIndex !== -1) {
        return []
      }
      const additionalStore = {
        color: 'red',
        ...this.storesList.find(store => store.store_key === editedStore.dc_key)
      }
      return [additionalStore]
    },
    copyUrl () {
      const copyText = document.createElement('input')
      copyText.value = this.tokenUrl
      copyText.select()
      copyText.setSelectionRange(0, 99999)
      navigator.clipboard.writeText(copyText.value)
      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 2000)
    },
    loadStoreUrl () {
      const token_expiry = new Date()
      const dates = token_expiry.getDate() + parseInt(this.lifetime)
      token_expiry.setDate(dates)
      axios.post(
        baseURLs.storepageAPI + '/create_token',
        {
          store_key: this.selectedStoreGenerate,
          token_expiry,
          'tabs': ['home', 'overview', 'transfers'],
        }, {
          headers: getHeadersWithAccessToken()
        })
        .then(({ data: { token } }) => {
          this.tokenUrl = `https://app.thunderstock.nl/store/${this.selectedStoreGenerate}/home?token=` + token
        })
    },
    resetStoreErrors (store) {
      this.$set(store, 'errors', {})
    },
    validateStore (store) {
      this.resetStoreErrors(store)

      if (!store.store_type) {
        this.$set(store.errors, 'store_type', 'Can not be empty')
      }

      if (!store.store_name || store.store_name === '') {
        this.$set(store.errors, 'store_name', 'Can not be empty')
      }

      if (!store.dc_key) {
        this.$set(store.errors, 'dc_key', 'Can not be empty')
      }

      if (!store.country || store.country === '') {
        this.$set(store.errors, 'country', 'Can not be empty')
      }

      if (store.looks_like_percentage < 0 || store.looks_like_percentage > 100) {
        this.$set(store.errors, 'looks_like_percentage', 'Should be >= 0 and <= 100')
      }

      ['delivery_days', 'redelivery_days', 'return_costs', 'constant_costs', 'variable_costs'].forEach(key => {
        if (!store.logic_parameters[key] && !_.isNumber(store.logic_parameters[key])) {
          this.$set(store.errors, key, 'Can not be empty')
        } else {
          if (['delivery_days', 'redelivery_days'].includes(key)) {
            if (store.logic_parameters[key] < 1) {
              this.$set(store.errors, key, 'Should be >= 1')
            }
          }
          if (['return_costs', 'constant_costs', 'variable_costs'].includes(key)) {
            if (store.logic_parameters[key] < 0) {
              this.$set(store.errors, key, 'Should be >= 0')
            }
          }
        }
      })

      return !Object.keys(store.errors).length
    },
    handleSelectRowChange (val, row) {
      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
    },
    handleEditClick () {
      this.$refs.storeEditModal.setEditedStore({ ...this.selectedRows[0] })
      this.formDialogVisible = true
    },
    handleAddClick () {
      this.$refs.storeEditModal.setEditedStore({
        one_stock: false,
        export_proposals: false,
        prepacks_allowed: true,
        logic_parameters: { ...storeSettingsLogicDefaults },
      })
      this.formDialogVisible = true
    },
    handleDeleteClick () {
      this.deleteDialogVisible = true
    },
    handleDeleteConfirm () {
      this.storesStore.deleteStore(this.selectedRows[0])
        .then(() => {
          this.$auth.refreshAppConfig()
          this.deleteDialogVisible = false
        })
    },
    handleTableInlineChange (row) {
      row.date_min = row.date_min || null
      row.date_max = row.date_max || null

      if (!this.changedRows.includes(row)) {
        this.changedRows.push(row)
      }
      this.updateStoresDebounced()
    },
    async handleSaveClick (store) {
      await this.storesStore.saveStore({
        ...store,
        looks_like_percentage: _.round(store.looks_like_percentage),
        logic_parameters: {
          ...store.logic_parameters,
          delivery_days: _.round(store.logic_parameters.delivery_days),
          redelivery_days: _.round(store.logic_parameters.redelivery_days),
          return_costs: _.round(store.logic_parameters.return_costs, 2),
          constant_costs: _.round(store.logic_parameters.constant_costs, 2),
          variable_costs: _.round(store.logic_parameters.variable_costs, 2),
        }
      })
      this.handleEditClose()
      await this.$auth.refreshAppConfig()
    },
    handleEditClose () {
      this.formDialogVisible = false
      this.resetForm()
    },
    handleTableDateOpenSelected (row) {
      this.tableDatepickerOpenActive[row.store_key] = false
      this.handleTableInlineChange(row)
    },
    handleTableDateCloseSelected (row) {
      this.tableDatepickerCloseActive[row.store_key] = false
      this.handleTableInlineChange(row)
    },
    async updateStores () {
      let allValid = true

      this.changedRows.forEach(row => {
        if (!this.validateStore(row)) {
          allValid = false
        }
      })

      if (allValid) {
        await this.storesStore.updateStores(this.changedRows)
          .then(() => {
            this.$auth.refreshAppConfig()
          })

        this.changedRows = []
      }
    },
    resetForm () {
      this.$refs.storeEditModal.resetForm()
    },
    saveSelectedColumns (value) {
      localStorage.setItem('settingsStoresSelectedColumns', JSON.stringify(value))
    },
  },
}
</script>

<style lang="scss">
.settings-stores-list {
  .store-type-dropdown {
    width: 170px;
  }
}
</style>
