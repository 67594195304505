<template>
  <v-col class="ma-0 pa-0 p-relative">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <drops-graph
      v-if="dropsForGraph.length"
      :items="dropsForGraph"
      :drops="drops"
      :budgets="budgets"
      :shouldLoad="shouldLoad"
      :stores="stores"
      @update-drop="updateDrop"
      @load-data="loadData"
    ></drops-graph>
    <drops-table
      :drops="drops"
      :budgets="budgets"
      :stores="stores"
      @load-data="loadData"
      @drop-selected="val => $emit('drop-selected', val)"
    ></drops-table>
  </v-col>
</template>

<script>
import { getDrops, updateDrop } from '@/api/shop'
import { getBudgets } from '@/api/budgetAPI'
import { getStoreSets } from '@/api/storeSets'
import DropsTable from './DropsTable'
import DropsGraph from './DropsGraph'
import { addWeeks } from 'date-fns'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'ShopDrops',
  components: { DropsTable, DropsGraph },
  data () {
    return {
      loading: false,
      drops: [],
      budgets: [],
      shouldLoad: false,
      stores: [],
      dropsParameters: {},
      dropsForGraph: [],
    }
  },
  watch: {
    '$route.query': function () {
      this.loadFromRoute(true)
    }
  },
  methods: {
    parseWeekForGraph (week) {
      const days = (week + '').substr(4) * 7
      const years = parseInt((week + '').substr(0, 4))
      const date = new Date(years, 0, days)
      return date.toISOString().substr(0, 10)
    },
    parseItemForGraph (item, _index) {
      return {
        _index,
        id: item.drop_key + '',
        name: item.drop_name,
        start: item.week_min ? this.parseWeekForGraph(item.week_min) : new Date(),
        end: item.week_max ? this.parseWeekForGraph(item.week_max) : addWeeks(new Date(), 52),
        progress: 100,
        ...item,
      }
    },
    loadData (payload, graphsLoaded) {
      this.shouldLoad = !graphsLoaded

      if (payload) {
        this.dropsParameters = payload
      } else {
        payload = this.dropsParameters
      }

      return getDrops(payload)
        .then(r => {
          this.drops = r
          this.dropsForGraph = (this.drops || []).map(this.parseItemForGraph)
        })
    },
    updateDrop (item, start, end) {
      const drop = this.drops.filter((drop) => drop.drop_key === parseInt(item.id))[0]
      drop.week_min = start
      drop.week_max = end
      updateDrop(drop).then((payload) => this.loadData(payload, true))
    },
    loadFromRoute () {
      const paramsFromStorage = localStorage.getItem('shop_items_params')
      const payload = {
        ...JSON.parse(paramsFromStorage),
        ...this.$route.query,
      }

      localStorage.setItem('shop_items_params', JSON.stringify(payload))

      for (const param in payload) {
        payload[param] = parseInt(payload[param])
      }
      this.loading = true
      this
        .loadData(payload)
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.budgets = getBudgets()
    const storesLabel = useGeneralStore().currentStoresSetsLabel
    getStoreSets(`?label=${storesLabel}`).then(({ stores_sets }) => {
      this.stores = stores_sets
    })
  },
  mounted () {
    this.loadFromRoute()
  }
}
</script>

<style scoped>

</style>
