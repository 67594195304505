import axios from '@/services/axios'
import baseURLs from '@/api/APIBaseURLs'

export function loadBudgetsScenarios () {
  const url = baseURLs.manageAPI + '/data/buy/manage/budgets_scenarios'
  return axios.get(url)
    .then(({ data: { data } }) => {
      return data
    }
    ).catch(console.log)
}
