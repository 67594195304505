<template>
  <div class="product-table">
    <v-overlay absolute color="white" :value="loading" >
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-row class="mt-4">
      <v-col class="text-subtitle-1 font-weight-medium mb-4 ml-6">{{ `${selectedRows.length} line${selectedRows.length !== 1 ? 's' : ''} selected` }}</v-col>
      <v-col class="text-right mr-6">
        <!--          <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />-->

        <TableSearch class="mx-2" @searchChanged="handleSearchChanged" />
        <v-icon
          class="mx-2 action-btn-primary"
          :disabled="!selectedRows.length"
          @click="completeSelectedTasks"
        >
          done
        </v-icon>
      </v-col>

    </v-row>
    <data-tables
      v-if="!loading"
      :key="tasksListKey"
      :filters="filters"
      :data="tableData"
      @filtered-data="filteredData = $event"
      :page-size="10"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 pb-4 mr-2',
      }"
      @row-click="emitRowClicked">
      <el-table-column prop="option_key" :width="tableData.length ? 55: 'unset'">
        <template v-slot:header>
          <v-simple-checkbox
            color="primary"
            :value="allRowsSelected"
            :ripple="false"
            dense
            hide-details
            class="ml-3"
            @input="handleSelectAllChange"
          />
        </template>
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="ml-3"
            :value="scope.row.selected"
            :ripple="false"
            @input="handleSelectRowChange($event, scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-for="prop of tableKeys" :prop="prop" :label="getLabel(prop)" :key="prop" sortable>
        <template slot-scope="scope"
          v-if="prop !== 'image_url'">
          <div
            :title="scope.row[prop]"
            class="d-flex"
            v-if="(prop === 'option' || prop === 'name') && scope.row[prop].split(' / ').length === 2"
            :key="prop">
            <img
              v-if="scope.row['image_url']"
              :src="scope.row['image_url'] || '@/assets/img/ts_placeholder.png'"
              @error="loadPlaceholder"
              height="55"
              width="33"
              alt=""/>
            <div class="lines" :class="{' pl-6': scope.row.image_url}">
              <div class="one-line">
                <span class="option-name">{{scope.row[prop].split(' / ')[0]}}</span>
                <br>
              </div>
              <div class="one-line">
                <span>{{scope.row[prop].split(' / ')[1]}}</span>
              </div>
            </div>
          </div>
          <div v-else-if="prop === 'option'"><span>{{scope.row.option}}</span></div>
          <div v-else-if="prop === 'status'" class="status-column">
            <p :class="labelColorByStatus(scope.row.status)">{{statusMessage(scope.row.status)}}</p>
          </div>
          <div v-else-if="prop=== 'date'">{{formatDateWeek(scope.row.date)}}</div>
          <div v-else>{{scope.row[prop]}}</div>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import axios from '../../services/axios'
import { formatString, getHeadersWithAccessToken } from '@/variables'
import TableSearch from '../TableSearch'
import { format } from 'date-fns'
import baseURLs from '@/api/APIBaseURLs'
import ts_placeholder from '@/assets/img/ts_placeholder.png'
import { useOrderTasksStore } from '@/store/pinia/tasksStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  components: { TableSearch },
  setup () {
    return {
      filtersStore: useFiltersStore(),
      tasksStore: useOrderTasksStore()
    }
  },
  data () {
    return {
      filters: [
        {
          value: '',
        },
      ],
      filteredData: [],
      loading: false,
      tasksListKey: 0,
    }
  },
  props: {
    taskType: {
      type: String,
      default: 'reorder'
    }
  },
  methods: {
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    handleSearchChanged (val) {
      this.filters[0].value = val
      this.tasksListKey = 0
    },
    formatDateWeek (date) {
      return `${date} (W${format((new Date(date)), 'ww')})`
    },
    loadTasks () {
      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()
      this.params = JSON.parse(localStorage.getItem('buy_home_params')) || {}

      this.tasksStore.getTasksOfType({
        taskType: 'buy',
        groups: pageFiltersValues.groups || [],
        collections: pageFiltersValues.collections || [],
        ...this.params
      })
      this.loading = false
    },
    emitRowClicked (row) {
      this.$emit('rowClicked', row)
    },
    completeSelectedTasks () {
      const tasksKeys = this.selectedRows.map(x => x.task_key)
      const url = baseURLs.manageAPI + '/tasks'
      const data = {
        task_keys: tasksKeys
      }
      this.loading = true

      axios
        .delete(url, {
          headers: getHeadersWithAccessToken(),
          data,
        })
        .then(() => {
          this.tableData.forEach(t => {
            if (tasksKeys.includes(t.task_key)) {
              t.handled = true
            }
          })
          this.loadTasks()
        }).catch((e) => {
          this.loading = false
          console.warn(e)
        })
    },
    handleSelectAllChange (val) {
      this.filteredData.forEach(r => this.$set(r, 'selected', val))
    },
    handleSelectRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
    getLabel (key) {
      return formatString(key)
    },
    statusMessage (status) {
      if (status === 0) {
        return 'Never viewed'
      } else if (status === 1) {
        return 'Viewed last 7 days'
      } else if (status === 2) {
        return 'Viewed last 14 days'
      } else if (status === 3) {
        return 'Viewed over 14 days ago'
      }
    },
    labelColorByStatus (status) {
      if (status === 0) {
        return 'never'
      } else if (status === 1) {
        return 'seven-days'
      } else if (status === 2) {
        return 'fourteen-days'
      } else if (status === 3) {
        return 'over-fourteen-days'
      }
    }
  },
  computed: {
    tableData () {
      const data = (this.tasksStore.tasks[this.taskType] || {}).data
      return data.filter((item) => !item.handled)
    },
    selectedRows () {
      return this.filteredData.filter(r => r.selected)
    },
    allRowsSelected () {
      return (this.filteredData.length > 0) && (this.filteredData.length === this.selectedRows.length)
    },
    tableKeys () {
      const tableKeys = [...(this.tasksStore.tasks[this.taskType] || {}).headers || []]
      tableKeys.splice(tableKeys.indexOf('option_key'), 1)
      tableKeys.splice(tableKeys.indexOf('image_url'), 1)
      tableKeys.splice(tableKeys.indexOf('page'), 1)
      tableKeys.splice(tableKeys.indexOf('page_payload'), 1)
      tableKeys.splice(tableKeys.indexOf('task_key'), 1)
      tableKeys.splice(tableKeys.indexOf('handled'), 1)
      return tableKeys
    }
  }
}
</script>

<style lang="scss">
.product-table {
  .status-column {
      p {
        margin-top: 11px;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
      }

      .never {
        color: rgba(0, 0, 0, 0.6);
      }

      .seven-days {
        color: var(--success);
      }

      .fourteen-days {
        color: var(--warning);
      }

      .over-fourteen-days {
        color: var(--danger);
      }
    }
}
</style>
