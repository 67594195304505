<template>
  <div class="p-relative" style="height: 90%">
    <div
      class="not-fixed"
      :id="'chart-container-' + title"
      :style="{
        'position': 'relative',
        'padding-left': '0px',
        'padding-right': '0px',
        'height': '100%',
        'width': '100%'
      }">
      <canvas :id="`${title}`"></canvas>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { Chart } from 'chart.js/auto'
import { hexToRgbA } from '@/utils'

export default {
  props: ['chartData', 'title', 'dateEnd'],
  data () {
    this.chart = null

    return {
    }
  },
  watch: {
    chartData () {
      this.drawGraph()
    },
  },
  methods: {
    drawGraph () {
      const ctx = document.getElementById(`${this.$props.title}`).getContext('2d')
      const formatedLabels = this.chartData.valueDates.map(date => `${format(new Date(date), 'dd MMM yy')}`)
      const todayPriceIndex = this.chartData.valueDates.indexOf(format(new Date(), 'yyyy-MM-dd'))
      const fakePrices = [...new Array(this.chartData.valuePrice.length).fill(Infinity)] // to prevent points to be visible
      const pointsRadius = [...new Array(this.chartData.valuePrice.length).fill(0)]
      const lineTodayLabel = format(new Date(), 'dd MMM yy')
      const lineEndLabel = format(new Date(this.dateEnd), 'dd MMM yy')
      const annotation = {
        drawTime: 'afterDatasetsDraw',
        annotations: {
          line1: {
            type: 'line',
            mode: 'vertical',
            scaleID: 'X',
            value: lineTodayLabel,
            borderColor: '#FF9800',
            borderWidth: 2,
            borderDash: [5, 10],
            label: {
              display: false
            }
          },
          line2: {
            type: 'line',
            mode: 'vertical',
            scaleID: 'X',
            value: lineEndLabel,
            borderColor: '#E35500',
            borderWidth: 2,
            borderDash: [5, 10],
            label: {
              display: false
            }
          }
        },
      }

      fakePrices[todayPriceIndex] = this.chartData.price
      pointsRadius[todayPriceIndex] = 5

      const chartConfig = {
        type: 'line',
        data: {
          datasets: [
            {
              yAxisID: 'y1',
              label: 'Value',
              data: this.chartData.valuePrice,
              fill: true,
              backgroundColor: hexToRgbA('#FFF', 0.3),
              borderColor: hexToRgbA('#007aff', 1),
              borderWidth: 2,
              lineTension: 0.4,
              radius: 0,
              borderDash: [0, 0],
              pointRadius: null,
            }, {
              yAxisID: 'y1',
              label: 'Actual price',
              data: fakePrices,
              fill: true,
              backgroundColor: hexToRgbA('#FFF', 0.3),
              borderColor: hexToRgbA('#FF9800', 1),
              borderWidth: 2,
              lineTension: 0.4,
              radius: 0,
              borderDash: [0, 0],
              showLine: false,
              pointRadius: pointsRadius,
            }, {
              yAxisID: 'y1',
              label: 'End date',
              data: fakePrices,
              fill: true,
              backgroundColor: hexToRgbA('#FFF', 0.3),
              borderColor: hexToRgbA('#E35500', 1),
              borderWidth: 2,
              lineTension: 0.4,
              radius: 0,
              borderDash: [0, 0],
              showLine: false,
              pointRadius: pointsRadius,
            },
          ],
          labels: formatedLabels,
        },
        options: {
          plugins: {
            datalabels: {
              display: false
            },
            legend: {
              position: 'bottom',
              align: 'center',
              display: true,
              labels: {
                usePointStyle: true,
              }
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          hover: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            y: {
              position: 'left',
              title: {
                display: true,
                text: 'Value',
                font: {
                  size: 14,
                },
              },
              grid: {
                display: true,
                drawTicks: false,
              },
              border: {
                display: false,
              },
              min: 0,
              max: 1,
              ticks: {
                maxTicksLimit: undefined,
                font: {
                  size: 14,
                },
                padding: 16,
                callback: label => {
                  return label * 100 + '%'
                },
              }
            },
            y1: {
              position: 'right',
              title: {
                display: true,
                text: 'Price',
                font: {
                  size: 14,
                },
              },
              grid: {
                display: false,
                drawTicks: false,
              },
              border: {
                display: false,
              },
              min: 0,
              max: this.chartData.originalPrice,
              ticks: {
                maxTicksLimit: undefined,
                font: {
                  size: 14,
                },
                padding: 16,
                callback: label => {
                  return this.chartData.currency.symbol + label
                },
              }
            },
            x: {
              title: {
                display: true,
                text: '',
                font: {
                  size: 14,
                },
              },
              grid: {
                display: false,
              },
              ticks: {
                padding: 10,
                font: {
                  size: 14,
                },
                beginAtZero: true,
                userCallback: function (item, index) {
                  if (!(index % 0)) {
                    return item
                  }
                },
                display: true,
              },
              offset: true,
            }
          },
          clip: false,
          annotation,
        },
      }

      if (this.chart) {
        this.chart.destroy()
      }

      this.$nextTick(() => {
        this.chart = new Chart(ctx, chartConfig)
      })

      const container = document.getElementById('chart-container-' + this.$props.title)

      container.style.height = '350px'
    },
  },
  mounted () {
    this.drawGraph()
  },
}
</script>

<style scoped>
.not-fixed {
    height: 400px !important;
}
</style>
