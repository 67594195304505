<template>
  <div class="dummy-input-form">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-card class="pa-4 pt-3" flat>
      <v-row>
        <v-col>
          <v-text-field
            v-model="inputForm.optionName.value"
            :error-messages="inputForm.optionName.error"
            clearable
            label="Name"
            hide-details="auto"
            @change="handleInputFormChange" />
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="inputForm.supplierKey.value"
            :error-messages="!inputForm.supplierKey.value ? 'This is a required field' : null"
            :items="suppliers"
            item-value="supplier_key"
            item-text="supplier_name"
            label="Supplier"
            hide-details
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @change="handleInputFormChange" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="p-relative">
            <v-text-field
              v-model="inputForm.optionKey.value"
              :error-messages="inputForm.optionKey.error"
              :disabled="isDisabled"
              label="Option key (external)"
              hide-details="auto"
              @change="handleInputFormChange" />
            <v-icon class="p-absolute lock-icon" @click="isDisabled= false" v-if="isDisabled">lock</v-icon>
          </span>
        </v-col>
        <v-col>
          <v-combobox
            v-model="inputForm.tags.value"
            :error-messages="inputForm.tags.error"
            label="Tags"
            :items="currentTags.concat(newTags).filter(t => t !== null)"
            multiple
            chips
            small-chips
            deletable-chips
            clearable
            hide-details
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
            @input="handleTagsChanged"
            @change="handleInputFormChange"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip
                v-if="index === 0"
                small
                close
                outlined
                @click:close="handleTagRemove"
              >
                <span>{{ item }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption mx-1">
                +{{ inputForm.tags.value.length - 1 }}
              </span>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import * as dummiesApi from '@/api/dummies'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  props: {
    dummy: {
      type: Object,
      default: () => ({}),
    },
    variants: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      loading: false,
      isDisabled: true,
      inputForm: {
        optionName: {
          value: '',
          error: null,
        },
        optionKey: {
          value: '',
          error: null,
        },
        supplierKey: {
          value: '',
          error: null,
        },
        tags: {
          value: '',
          error: null,
        },
      },
      newTags: [],
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    currentTags () {
      return (this.appConfig?.dummy_tags || []).slice().map(t => t.tags)
    },
    suppliers () {
      return (this.appConfig?.suppliers || [])
    },
  },
  watch: {
    dummy: {
      handler () {
        this.inputForm.optionName.value = this.dummy.option_name
        this.inputForm.optionKey.value = this.dummy.option_key
        this.inputForm.supplierKey.value = this.dummy.supplier_key
        this.inputForm.tags.value = this.dummy.tags
      },
      immediate: true,
    },
  },
  methods: {
    saveDummy () {
      const prices = {
        ...this.dummy.prices,
      }

      const payload = {
        ...this.dummy,
        option_name: this.inputForm.optionName.value,
        prices,
        option_key: this.inputForm.optionKey.value,
        supplier_key: this.inputForm.supplierKey.value,
        tags: this.inputForm.tags.value,
      }
      this.loading = true
      return dummiesApi
        .saveDummy(payload)
        .finally(() => {
          this.loading = false
        })
    },
    handleInputFormChange () {
      this
        .saveDummy()
        .then(updatedDummy => {
          this.$emit('dummy-changed', updatedDummy)
        })
    },
    handleTagsChanged (val) {
      val.forEach(tag => {
        if (!this.currentTags.includes(tag)) {
          this.newTags.push(tag)
        }
      })
    },
    handleTagRemove () {
      this.inputForm.tags.value.splice(0, 1)
      this.saveDummy()
    },
  },
}
</script>

<style scoped>

.lock-icon {
  top: 20px;
  position: absolute;
  right: 6px
}
</style>
