export const kpisViewConfig = {
  'org_key': 0,
  'view_key': 1514,
  'user_id': 3,
  'dataset': 'strategy_offload',
  'view_name': '1a - KPI indicators',
  'chart_type': 'table',
  'dimensions': [
    'properties/cube/cube_key'
  ],
  'measures': [
    {
      'axis': 'y1',
      'name': 'Revenue (act)',
      'type': 'bar',
      'calculation': 'revenue_actuals'
    },
    {
      'axis': 'y1',
      'name': 'Revenue (bdg)',
      'type': 'bar',
      'calculation': 'revenue',
      'fill_color_code': '',
      'border_color_code': '#FF9800'
    },
    {
      'axis': 'y1',
      'name': 'Sales net (act)',
      'type': 'bar',
      'calculation': [
        'sales_actuals',
        '-',
        'returns_actuals'
      ]
    },
    {
      'axis': 'y1',
      'name': 'Sales net (bdg)',
      'type': 'bar',
      'calculation': [
        'sales',
        '-',
        'returns'
      ]
    },
    {
      'axis': 'y1',
      'name': 'Original price (act)',
      'type': 'bar',
      'calculation': 'original_price_actuals'
    },
    {
      'axis': 'y1',
      'name': 'Original price (bdg)',
      'type': 'bar',
      'calculation': 'original_price'
    },
    {
      'axis': 'y1',
      'name': 'Discount (act)',
      'type': 'bar',
      'decimals': 4,
      'percentage': true,
      'calculation': [
        'discount_value_actuals',
        '/',
        [
          'revenue_actuals',
          '+',
          'discount_value_actuals'
        ]
      ]
    },
    {
      'axis': 'y1',
      'name': 'Discount (bdg)',
      'type': 'bar',
      'decimals': 4,
      'percentage': true,
      'calculation': [
        'discount_value',
        '/',
        [
          'revenue',
          '+',
          'discount_value'
        ]
      ]
    },
    {
      'axis': 'y1',
      'name': 'Actual price (act)',
      'type': 'bar',
      'calculation': 'actual_price_actuals'
    },
    {
      'axis': 'y1',
      'name': 'Actual price (bdg)',
      'type': 'bar',
      'calculation': 'actual_price'
    },
    {
      'axis': 'y1',
      'name': 'Margin (act)',
      'type': 'bar',
      'calculation': 'margin_actuals'
    },
    {
      'axis': 'y1',
      'name': 'Margin (bdg)',
      'type': 'bar',
      'calculation': 'margin'
    },
    {
      'axis': 'y1',
      'name': 'Margin value (act)',
      'type': 'bar',
      'calculation': 'margin_value_actuals'
    },
    {
      'axis': 'y1',
      'name': 'Margin value (bdg)',
      'type': 'bar',
      'calculation': 'margin_value'
    }
  ],
  'filters': {
    'properties/calendar/YTD': [
      'True'
    ]
  },
  'properties': {
    'sort': null,
    'bar_type': 'stacked',
    'total_row': false,
    'limit': null
  },
  'format': 'doa'
}

export const revenueChartsViewsConfig = {
  revenue: {
    'org_key': 0,
    'view_key': 1507,
    'user_id': 3,
    'dataset': 'strategy_offload',
    'view_name': '1b - Revenue',
    'chart_type': 'line_chart',
    'dimensions': [
      'properties/calendar/week'
    ],
    'measures': [
      {
        'axis': 'y1',
        'name': 'Revenue (bgd)',
        'type': 'line',
        'color_code': '#FF9800',
        'calculation': 'revenue_rv'
      },
      {
        'axis': 'y1',
        'name': 'Revenue (act)',
        'type': 'line',
        'color_code': '#4CAF50',
        'calculation': 'revenue_rv_actuals'
      },
      {
        'axis': 'y2',
        'name': 'Stock',
        'type': 'line',
        'color_code': '#007AFF',
        'calculation': 'stock'
      },
      {
        'axis': 'y2',
        'name': 'Projection',
        'type': 'dotted',
        'color_code': '#007AFF',
        'calculation': 'projection'
      }
    ],
    'filters': {},
    'properties': {
      'axis': {
        'y1': 'Revenue',
        'y2': 'Stock'
      },
      'sort': null,
      'limit': null
    },
  },
  sales: {
    'org_key': 0,
    'view_key': 1499,
    'user_id': 3,
    'dataset': 'strategy_offload',
    'view_name': '1b - Sales & Returns',
    'chart_type': 'line_chart',
    'dimensions': [
      'properties/calendar/week'
    ],
    'measures': [
      {
        'axis': 'y1',
        'name': 'Sales (bdg)',
        'type': 'dotted',
        'color_code': '#4CAF50',
        'calculation': 'sales'
      },
      {
        'axis': 'y1',
        'name': 'Sales (act)',
        'type': 'line',
        'color_code': '#4CAF50',
        'calculation': 'sales_actuals'
      },
      {
        'axis': 'y1',
        'name': 'Returns (bdg)',
        'type': 'dotted',
        'color_code': '#EE3766',
        'calculation': 'returns'
      },
      {
        'axis': 'y1',
        'name': 'Returns (act)',
        'type': 'line',
        'color_code': '#EE3766',
        'calculation': 'returns_actuals'
      }
    ],
    'filters': {},
    'properties': {
      'axis': {
        'y1': 'Sales',
        'y2': 'Cumulative'
      },
      'sort': null,
      'limit': null
    },
  },
  prices: {
    'org_key': 0,
    'view_key': 1491,
    'user_id': 3,
    'dataset': 'strategy_offload',
    'view_name': '1b - Prices',
    'chart_type': 'line_chart',
    'dimensions': [
      'properties/calendar/week'
    ],
    'measures': [
      {
        'axis': 'y1',
        'name': 'Original price (bdg)',
        'type': 'dotted',
        'color_code': '#085D70',
        'calculation': 'original_price'
      },
      {
        'axis': 'y1',
        'name': 'Original price (act)',
        'type': 'line',
        'color_code': '#085D70',
        'calculation': 'original_price_actuals'
      },
      {
        'axis': 'y1',
        'name': 'Retail price (bdg)',
        'type': 'dotted',
        'color_code': '#007AFF',
        'calculation': 'retail_price'
      },
      {
        'axis': 'y1',
        'name': 'Retail price (act)',
        'type': 'line',
        'color_code': '#007AFF',
        'calculation': 'retail_price_actuals'
      },
      {
        'axis': 'y1',
        'name': 'Actual price (bdg)',
        'type': 'dotted',
        'color_code': '#4CAF50',
        'calculation': 'actual_price'
      },
      {
        'axis': 'y1',
        'name': 'Actual price (act)',
        'type': 'line',
        'color_code': '#4CAF50',
        'calculation': 'actual_price_actuals'
      },
      {
        'axis': 'y1',
        'name': 'Cost price (bdg)',
        'type': 'dotted',
        'color_code': '#FF9800',
        'calculation': 'cost_price'
      },
      {
        'axis': 'y1',
        'name': 'Cost price (act)',
        'type': 'line',
        'color_code': '#FF9800',
        'calculation': 'cost_price_actuals'
      }
    ],
    'filters': {},
    'properties': {
      'axis': {
        'y1': 'Price'
      },
      'sort': null,
      'limit': null
    },
  }
}

export const marginChartsViewsConfig = {
  percentages: {
    'org_key': 0,
    'view_key': 1512,
    'user_id': 3,
    'dataset': 'strategy_offload',
    'view_name': '2a - Percentages',
    'chart_type': 'line_chart',
    'dimensions': [
      'properties/calendar/week'
    ],
    'measures': [
      {
        'axis': 'y1',
        'name': 'Margin (bdg)',
        'type': 'dotted',
        'color_code': '#FF9800',
        'calculation': [
          'margin_value',
          '/',
          [
            'revenue',
            '/',
            1.21
          ]
        ]
      },
      {
        'axis': 'y1',
        'name': 'Margin (act)',
        'type': 'line',
        'color_code': '#FF9800',
        'calculation': [
          'margin_value_actuals',
          '/',
          [
            'revenue_actuals',
            '/',
            1.21
          ]
        ]
      },
      {
        'axis': 'y1',
        'name': 'Markdown (bdg)',
        'type': 'dotted',
        'color_code': '#4CAF50',
        'calculation': [
          'markdown_value',
          '/',
          [
            'revenue',
            '+',
            'discount_value'
          ]
        ]
      },
      {
        'axis': 'y1',
        'name': 'Markdown (act)',
        'type': 'line',
        'color_code': '#4CAF50',
        'calculation': [
          'markdown_value_actuals',
          '/',
          [
            'revenue_actuals',
            '+',
            'discount_value'
          ]
        ]
      },
      {
        'axis': 'y1',
        'name': 'Promotion (bdg)',
        'type': 'dotted',
        'color_code': '#73A1C7',
        'calculation': [
          'promotion_value',
          '/',
          [
            'revenue',
            '+',
            'discount_value'
          ]
        ]
      },
      {
        'axis': 'y1',
        'name': 'Promotion (act)',
        'type': 'line',
        'color_code': '#73A1C7',
        'calculation': [
          'promotion_value_actuals',
          '/',
          [
            'revenue_actuals',
            '+',
            'discount_value'
          ]
        ]
      }
    ],
    'filters': {},
    'properties': {
      'axis': {
        'y1': 'Percentage'
      },
      'sort': null,
      'limit': null
    },
  },
  margin: {
    'org_key': 0,
    'view_key': 1513,
    'user_id': 3,
    'dataset': 'strategy_offload',
    'view_name': '2a - Margin',
    'chart_type': 'bar_chart',
    'dimensions': [
      'properties/calendar/week'
    ],
    'measures': [
      {
        'axis': 'y2',
        'name': 'Revenue ov (bdg)',
        'type': 'point',
        'color_code': '#00000033',
        'calculation': [
          'revenue',
          '+',
          'discount_value'
        ],
        'fill_color_code': '',
        'border_color_code': '#FF9800'
      },
      {
        'axis': 'y1',
        'name': 'Cost value (bdg)',
        'type': 'bar',
        'color_code': '#FF9800',
        'calculation': 'cost_value',
        'fill_color_code': '#765397',
        'border_color_code': '#765397'
      },
      {
        'axis': 'y1',
        'name': 'Margin value (bdg)',
        'type': 'bar',
        'color_code': '#FF9800',
        'calculation': 'margin_value',
        'fill_color_code': '#FF9800',
        'border_color_code': '#FF9800'
      },
      {
        'axis': 'y1',
        'name': 'VAT (bdg)',
        'type': 'bar',
        'color_code': '#FF9800',
        'calculation': [
          'revenue',
          '-',
          'revenue',
          '/',
          1.21
        ],
        'fill_color_code': '#73A1C7',
        'border_color_code': '#73A1C7'
      },
      {
        'axis': 'y1',
        'name': 'Discount value (bdg)',
        'type': 'bar',
        'color_code': '#FF9800',
        'calculation': 'discount_value',
        'fill_color_code': '#EE3766',
        'border_color_code': '#EE3766'
      }
    ],
    'filters': {},
    'properties': {
      'axis': {
        'y1': 'Euros',
        'y2': 'Percentage'
      },
      'sort': null,
      'bar_type': 'stacked',
      'limit': null
    },
  },
  waterfall: {
    'org_key': 0,
    'view_key': 1516,
    'user_id': 3,
    'dataset': 'strategy_offload',
    'view_name': '2b - Margin waterfall',
    'chart_type': 'table',
    'dimensions': [
      'properties/cube/cube_key'
    ],
    'measures': [
      {
        'axis': 'y1',
        'name': 'Margin value (bdg)',
        'type': 'bar',
        'calculation': 'margin_value'
      },
      {
        'axis': 'y1',
        'name': 'Revenue ov delta',
        'type': 'bar',
        'decimals': 0,
        'calculation': [
          'revenue_actuals',
          '+',
          'discount_value_actuals',
          '-',
          'revenue',
          '-',
          'discount_value'
        ]
      },
      {
        'axis': 'y1',
        'name': 'Discount delta',
        'type': 'bar',
        'decimals': 0,
        'calculation': [
          'discount_value',
          '-',
          'discount_value_actuals'
        ]
      },
      {
        'axis': 'y1',
        'name': 'Cost value delta',
        'type': 'bar',
        'decimals': 0,
        'calculation': [
          'cost_value',
          '-',
          'cost_value_actuals'
        ]
      },
      {
        'axis': 'y1',
        'name': 'VAT delta',
        'type': 'bar',
        'decimals': 0,
        'calculation': [
          [
            'revenue',
            '-',
            'revenue_actuals'
          ],
          '*',
          0.17355
        ]
      },
      {
        'axis': 'y1',
        'name': 'Margin value (act)',
        'type': 'bar',
        'calculation': 'margin_value_actuals'
      }
    ],
    'filters': {
      'properties/calendar/YTD': [
        'True'
      ]
    },
    'properties': {
      'sort': null,
      'bar_type': 'stacked',
      'total_row': false,
      'limit': null
    },
    'format': 'doa'
  }
}

export const otbIntakeChartsViewsConfig = {
  otb: {
    'org_key': 0,
    'view_key': 1496,
    'user_id': 3,
    'dataset': 'strategy_offload',
    'view_name': '3a - OTB',
    'chart_type': 'line_chart',
    'dimensions': [
      'properties/calendar/week'
    ],
    'measures': [
      {
        'axis': 'y1',
        'name': 'Intake',
        'type': 'line',
        'color_code': '#FF9A9A',
        'calculation': 'intake'
      },
      {
        'axis': 'y1',
        'name': 'Projection',
        'type': 'line',
        'color_code': '#73A1C7',
        'calculation': 'projection'
      },
      {
        'axis': 'y1',
        'name': 'Otb',
        'type': 'line',
        'color_code': '#FF9800',
        'calculation': 'otb'
      },
      {
        'axis': 'y1',
        'name': 'Requirement',
        'type': 'line',
        'color_code': '#007AFF',
        'calculation': 'requirement'
      }
    ],
    'filters': {
      'properties/calendar/future': [
        'True'
      ]
    },
    'properties': {
      'axis': {
        'y1': 'Intake'
      },
      'sort': null,
      'limit': null
    },
  },
  intake: {
    'org_key': 0,
    'view_key': 1508,
    'user_id': 3,
    'dataset': 'strategy_offload',
    'view_name': '3a - Intake',
    'chart_type': 'line_chart',
    'dimensions': [
      'properties/calendar/week'
    ],
    'measures': [
      {
        'axis': 'y1',
        'name': 'Intake',
        'type': 'line',
        'color_code': '#FF9A9A',
        'calculation': 'intake'
      },
      {
        'axis': 'y1',
        'name': 'Intake cost value',
        'type': 'dotted',
        'color_code': '#FF9A9A',
        'calculation': 'intake_cv'
      }
    ],
    'filters': {},
    'properties': {
      'axis': {
        'y1': 'Euros',
        'y2': 'Pieces'
      },
      'sort': null,
      'limit': null
    },
  }
}

export const otbOpportunitiesViewConfig = {
  'org_key': 0,
  'view_key': 1518,
  'user_id': 3,
  'dataset': 'strategy_offload',
  'view_name': '3b - OTB per dimension',
  'chart_type': 'table',
  'dimensions': [],
  'measures': [
    /* {
      'axis': 'y1',
      'name': 'Requirement fut',
      'type': 'line',
      'calculation': 'requirement_fut'
    },
    {
      'axis': 'y1',
      'name': 'Stock',
      'type': 'bar',
      'calculation': [
        'requirement_fut',
        '-',
        'intake_cum',
        '-',
        'markdown_open',
        '-',
        'flow',
        '-',
        'otb'
      ]
    }, */
    {
      'axis': 'y2',
      'name': 'Intake cum',
      'type': 'line',
      'calculation': 'intake_cum'
    },
    {
      'axis': 'y1',
      'name': 'Markdown open',
      'type': 'bar',
      'calculation': 'markdown_open'
    },
    {
      'axis': 'y2',
      'name': 'Flow',
      'type': 'dotted',
      'calculation': 'flow'
    },
    {
      'axis': 'y1',
      'name': 'OTB',
      'type': 'bar',
      'calculation': 'otb',
      'color_grading': true
    }
  ],
  'filters': {},
  'properties': {
    'rows': 25,
    'sort': null,
    'total_row': true,
    'parent_limit': '',
    'limit': null
  },
  'format': 'doa'
}

export const warningsViewsConfig = {
  revenue: {
    'org_key': 0,
    'view_key': 1511,
    'user_id': 3,
    'dataset': 'strategy_offload',
    'view_name': '4a - Revenue deltas (L3M)',
    'caption': 'Revenue deltas',
    'chart_type': 'table',
    'dimensions': [],
    'measures': [
      {
        'axis': 'y1',
        'name': 'Revenue (act)',
        'type': 'bar',
        'decimals': 0,
        'calculation': 'revenue_actuals'
      },
      {
        'axis': 'y1',
        'name': 'Revenue (bdg)',
        'type': 'bar',
        'decimals': 0,
        'calculation': 'revenue'
      },
      {
        'axis': 'y1',
        'name': 'Revenue (d)',
        'type': 'bar',
        'decimals': 0,
        'calculation': [
          'revenue_actuals',
          '-',
          'revenue'
        ],
        'color_grading': true
      },
      {
        'axis': 'y1',
        'name': 'Revenue (idx)',
        'type': 'bar',
        'decimals': 0,
        'percentage': false,
        'calculation': [
          'revenue_actuals',
          '*',
          100,
          '/',
          'revenue'
        ],
        'grading_max': -1200,
        'grading_min': 50,
        'color_grading': false
      },
      {
        'axis': 'y1',
        'name': 'Sales net (act)',
        'type': 'bar',
        'decimals': 0,
        'calculation': [
          'sales_actuals',
          '-',
          'returns_actuals'
        ]
      },
      {
        'axis': 'y1',
        'name': 'Sales net (bdg)',
        'type': 'bar',
        'decimals': 0,
        'calculation': [
          'sales',
          '-',
          'returns'
        ]
      },
      {
        'axis': 'y1',
        'name': 'Sales net (idx)',
        'type': 'bar',
        'decimals': 0,
        'calculation': [
          [
            'sales_actuals',
            '-',
            'returns_actuals'
          ],
          '*',
          100,
          '/',
          [
            'sales',
            '-',
            'returns'
          ]
        ],
        'grading_max': -1200,
        'grading_min': 50,
        'color_grading': false
      }
    ],
    'filters': {
      'properties/calendar/future': [
        'False'
      ]
    },
    'properties': {
      'rows': 5,
      'sort': 'Revenue (d)',
      'total_row': true,
      'sort_order': 'ascending',
      'limit': null
    },
    'format': 'doa'
  },
  margin: {
    'org_key': 0,
    'view_key': 1520,
    'user_id': 3,
    'dataset': 'strategy_offload',
    'view_name': '4a - Margin waterfall (L3M)',
    'caption': 'Margin waterfall',
    'chart_type': 'table',
    'dimensions': [],
    'measures': [
      {
        'axis': 'y1',
        'name': 'Margin value (bdg)',
        'type': 'bar',
        'calculation': 'margin_value'
      },
      {
        'axis': 'y1',
        'name': 'Revenue ov delta',
        'type': 'bar',
        'decimals': 0,
        'calculation': [
          'revenue_actuals',
          '+',
          'discount_value_actuals',
          '-',
          'revenue',
          '-',
          'discount_value'
        ]
      },
      {
        'axis': 'y1',
        'name': 'Discount delta',
        'type': 'bar',
        'decimals': 0,
        'calculation': [
          'discount_value',
          '-',
          'discount_value_actuals'
        ]
      },
      {
        'axis': 'y1',
        'name': 'Cost value delta',
        'type': 'bar',
        'decimals': 0,
        'calculation': [
          'cost_value',
          '-',
          'cost_value_actuals'
        ]
      },
      {
        'axis': 'y1',
        'name': 'VAT delta',
        'type': 'bar',
        'decimals': 0,
        'calculation': [
          [
            'revenue',
            '-',
            'revenue_actuals'
          ],
          '*',
          0.17355
        ]
      },
      {
        'axis': 'y1',
        'name': 'Margin value (act)',
        'type': 'bar',
        'calculation': 'margin_value_actuals'
      },
      {
        'axis': 'y1',
        'name': 'Margin value (d)',
        'type': 'bar',
        'decimals': 0,
        'calculation': [
          'margin_value_actuals',
          '-',
          'margin_value'
        ]
      }
    ],
    'filters': {
      'properties/calendar/YTD': [
        'True'
      ]
    },
    'properties': {
      'sort': 'Margin value (d)',
      'bar_type': 'stacked',
      'total_row': true,
      'sort_order': 'ascending',
      'limit': null
    },
    'format': 'doa'
  },
  marginPerc: {
    'org_key': 0,
    'view_key': 1522,
    'user_id': 3,
    'dataset': 'strategy_offload',
    'view_name': '4a - Margin% waterfall (L3M)',
    'caption': 'Margin% waterfall',
    'chart_type': 'table',
    'dimensions': [],
    'measures': [
      {
        'axis': 'y1',
        'name': 'Intake Margin% (bdg)',
        'type': 'bar',
        'decimals': 4,
        'percentage': true,
        'calculation': [
          'intake_margin_value',
          '/',
          'revenue'
        ]
      },
      {
        'axis': 'y1',
        'name': 'Intake Margin% (act)',
        'type': 'bar',
        'decimals': 4,
        'percentage': true,
        'calculation': [
          'intake_margin_value_actuals',
          '/',
          'revenue_actuals'
        ]
      },
      {
        'axis': 'y1',
        'name': 'Margin% (bdg)',
        'type': 'bar',
        'decimals': 4,
        'percentage': true,
        'calculation': [
          'margin_value',
          '/',
          'revenue'
        ]
      },
      {
        'axis': 'y1',
        'name': 'Margin% (act)',
        'type': 'bar',
        'decimals': 4,
        'percentage': true,
        'calculation': [
          'margin_value_actuals',
          '/',
          'revenue_actuals'
        ]
      },
      {
        'axis': 'y1',
        'name': 'Cost% (bdg)',
        'type': 'bar',
        'decimals': 4,
        'percentage': true,
        'calculation': [
          'cost_value',
          '/',
          'revenue'
        ]
      },
      {
        'axis': 'y1',
        'name': 'Cost% (act)',
        'type': 'bar',
        'decimals': 4,
        'percentage': true,
        'calculation': [
          'cost_value_actuals',
          '/',
          'revenue_actuals'
        ]
      }
    ],
    'filters': {
      'properties/calendar/YTD': [
        'True'
      ]
    },
    'properties': {
      'sort': null,
      'bar_type': 'stacked',
      'total_row': true,
      'limit': null
    },
    'format': 'doa'
  },
  markdown: {
    'org_key': 0,
    'view_key': 1519,
    'user_id': 3,
    'dataset': 'strategy_offload',
    'view_name': '4a - Markdown open (n5w)',
    'caption': 'Markdown open (n5w)',
    'chart_type': 'table',
    'dimensions': [],
    'measures': [
      {
        'axis': 'y1',
        'name': 'Projection',
        'type': 'bar',
        'calculation': 'projection'
      },
      {
        'axis': 'y1',
        'name': 'Markdown open',
        'type': 'bar',
        'calculation': 'markdown_open'
      },
      {
        'axis': 'y1',
        'name': 'Markdown open%',
        'type': 'bar',
        'decimals': 4,
        'percentage': true,
        'calculation': [
          'markdown_open',
          '/',
          'projection'
        ]
      }
    ],
    'filters': {
      'properties/calendar/future': [
        'True'
      ]
    },
    'properties': {
      'sort': null,
      'total_row': true,
      'sort_order': 'ascending',
      'limit': null
    },
    'format': 'doa'
  },
  price: {
    'org_key': 0,
    'view_key': 1523,
    'user_id': 3,
    'dataset': 'strategy_offload',
    'view_name': '4a - Price deltas (L3M)',
    'caption': 'Price deltas (L3M)',
    'chart_type': 'table',
    'dimensions': [],
    'measures': [
      {
        'axis': 'y1',
        'name': 'Original price (bdg)',
        'type': 'bar',
        'decimals': 2,
        'calculation': 'original_price'
      },
      {
        'axis': 'y1',
        'name': 'Original price (act)',
        'type': 'bar',
        'decimals': 2,
        'calculation': 'original_price_actuals'
      },
      {
        'axis': 'y1',
        'name': 'OSP (d)',
        'type': 'bar',
        'decimals': 2,
        'calculation': [
          'original_price_actuals',
          '-',
          'original_price'
        ],
        'color_grading': true
      },
      {
        'axis': 'y1',
        'name': 'Retail price (bdg)',
        'type': 'bar',
        'decimals': 2,
        'calculation': 'retail_price'
      },
      {
        'axis': 'y1',
        'name': 'Retail price (act)',
        'type': 'bar',
        'decimals': 2,
        'calculation': 'retail_price_actuals'
      },
      {
        'axis': 'y1',
        'name': 'Retail price (d)',
        'type': 'bar',
        'decimals': 2,
        'calculation': [
          'retail_price_actuals',
          '-',
          'retail_price'
        ],
        'color_grading': true
      },
      {
        'axis': 'y1',
        'name': 'Actual price (bdg)',
        'type': 'bar',
        'decimals': 2,
        'calculation': 'actual_price'
      },
      {
        'axis': 'y1',
        'name': 'Actual price (act)',
        'type': 'bar',
        'decimals': 2,
        'calculation': 'actual_price_actuals',
        'color_grading': false
      },
      {
        'axis': 'y1',
        'name': 'Actual price (d)',
        'type': 'bar',
        'decimals': 2,
        'calculation': [
          'actual_price_actuals',
          '-',
          'actual_price'
        ],
        'color_grading': true
      },
      {
        'axis': 'y1',
        'name': 'Cost price (bdg)',
        'type': 'bar',
        'decimals': 2,
        'calculation': 'cost_price'
      },
      {
        'axis': 'y1',
        'name': 'Cost price (act)',
        'type': 'bar',
        'decimals': 2,
        'calculation': 'cost_price_actuals'
      },
      {
        'axis': 'y1',
        'name': 'Cost price (d)',
        'type': 'bar',
        'decimals': 2,
        'calculation': [
          'cost_price_actuals',
          '-',
          'cost_price'
        ],
        'color_grading': true
      }
    ],
    'filters': {
      'properties/calendar/future': [
        'False'
      ]
    },
    'properties': {
      'rows': 5,
      'sort': 'OSP (d)',
      'total_row': true,
      'sort_order': 'ascending',
      'limit': null
    },
    'format': 'doa'
  }
}

export const markdownChartsViewsConfig = {
  markdown1: {
    'view_key': 2102,
    'dataset': 'strategy_offload',
    'chart_type': 'line_chart',
    'dimensions': [
      'properties/calendar/week'
    ],
    'measures': [
      {
        'axis': 'y1',
        'name': 'Stock pieces',
        'type': 'line',
        'color_code': '#FF9800',
        'calculation': 'stock_pieces'
      },
      {
        'axis': 'y1',
        'name': 'Requirement pieces',
        'type': 'line',
        'color_code': '#007AFF',
        'calculation': 'requirement_pieces'
      },
      {
        'axis': 'y1',
        'name': 'Surplus pieces',
        'type': 'line',
        'color_code': '#4CAF50',
        'calculation': 'surplus_pieces'
      }
    ],
    'filters': {
      'properties/article/age/value': [
        'current'
      ],
      'properties/calendar/future': [
        'True'
      ]
    },
    'properties': {
      'axis': {
        'y1': 'Intake',
        'y1_min': 0
      },
      'sort': null,
      'limit': null
    },
    'view_name': 'Markdown - 1',
    'user_id': 3
  }
}

export const markdownTableViewConfig = {
  'view_key': 2103,
  'dataset': 'strategy_offload',
  'chart_type': 'table',
  'dimensions': [
  ],
  'measures': [
    {
      'axis': 'y1',
      'name': 'Revenue',
      'type': 'bar',
      'calculation': 'revenue'
    },
    {
      'axis': 'y1',
      'name': 'Stock (end)',
      'type': 'line',
      'decimals': 0,
      'calculation': 'stock (end)'
    },
    {
      'axis': 'y1',
      'name': 'Requirement pieces',
      'type': 'line',
      'decimals': 0,
      'calculation': 'requirement (end)'
    },
    {
      'axis': 'y1',
      'name': 'Surplus',
      'type': 'line',
      'decimals': 0,
      'calculation': 'surplus'
    },
    {
      'axis': 'y1',
      'name': 'Markdown value',
      'type': 'bar',
      'calculation': 'markdown_value'
    }
  ],
  'filters': {
    'properties/article/age/value': [
      'current'
    ],
    'properties/calendar/future': [
      'True'
    ]
  },
  'properties': {
    'rows': 10,
    'sort': 'Surplus',
    'total_row': true,
    'sort_order': 'descending',
    'limit': null
  },
  'view_name': 'Markdown - 1',
  'creation_date': '2024-02-23T10:31:32.715678',
  'user_id': 3,
  'format': 'doa'
}
