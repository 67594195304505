<template>
  <resizable
    id="strategy-intake"
    class="p-relative pt-0 mb-12"
    @resize="updateTotalTableWidth"
  >
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="align-baseline mt-3 mb-3">
      <v-col cols="3" class="py-0">
        <h3>Intake</h3>
      </v-col>
      <v-spacer />
      <v-col md="2" cols="4" class="py-0">
        <StrategyCubeSelect
          ref="cubeSelect"
          @cube-inputs-loaded="handleCubeInputsLoaded"
        />
      </v-col>
    </v-row>

    <v-card class="pt-4" min-height="calc(100vh / var(--zoom));">
      <div class="px-6">
        <v-row
          v-if="filtersDimensions.length"
          class="align-center"
        >
          <v-col cols="11" class="pb-0">
            <StrategyFilters
              ref="filters"
              :dimensions="filtersDimensions.filter(d => !storeDimensions.includes(d.name))"
              week-min-only-future
              @filters-loaded="handleFiltersLoaded"
              @filters-changed="handleFiltersChanged"
            />
          </v-col>
          <v-col cols="1" class="pb-0 pt-0 text-right">
            <v-menu
              :close-on-content-click="false"
              attach="#strategy-intake"
              offset-y
              left
              min-width="450"
              max-width="450"
              nudge-bottom="20"
              content-class="settings-menu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on">tune</v-icon>
              </template>

              <v-card>
                <h4 class="ml-4 pt-6">
                  Settings
                </h4>
                <div class="input-parameter">
                  <div class="parameter-name">Dimension</div>
                  <div class="parameter-value">
                    <v-select
                      :multiple="false"
                      v-model="selectedAnalyticsProperty"
                      :items="analyticsPropertiesGrouped"
                      class="text-body-2"
                      hide-details
                      @change="handleAnalyticsPropertyChanged"
                    />
                  </div>
                </div>
                <div
                  v-for="(_, name) in sliders"
                  :key="name"
                  class="input-parameter"
                >
                  <div class="parameter-name">{{ name | formatString }}</div>
                  <div class="parameter-value">
                    <div class="d-flex align-center">
                      <div class="slider-value-from mr-2">{{ sliders[name].min }}</div>
                      <v-slider
                        v-model="sliders[name].value"
                        thumb-label
                        hide-details="auto"
                        :min="sliders[name].min"
                        :max="sliders[name].max"
                        :step="sliders[name].step || 1"
                        @change="handleSliderChange"
                      />
                      <div class="slider-value-to ml-2">{{ sliders[name].max }}</div>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="mt-8 align-center">
          <v-col>
            <h6 class="text-subtitle-1 font-weight-medium">KPIs</h6>
          </v-col>
          <v-col class="text-center">
            <v-btn-toggle
              v-model="currentMetric"
              mandatory
              dense
              active-class="bg-gray"
              light
              @change="handleMetricChange"
            >
              <v-btn
                v-for="(metric, index) in metrics"
                :key="index"
                :value="metric.value"
                small
                color="white"
              >
                <span class="text-capitalize">{{ metric.text }}</span>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col></v-col>
        </v-row>
        <StrategyIntakeKPITiles
          :kpi-items="kpiItems"
          :otb-kpis="intakeData?.OTB_kpis"
          :metric="currentMetric"
        />
        <StrategyIntakeCharts
          v-if="filtersDimensions.length"
          ref="charts"
          class="mt-10"
          :metric-name="currentMetricSuffix"
          :intake-data="intakeData"
          :simulation-data="simulationData"
          @chart-click="handleChartClick"
        />
      </div>
      <template v-if="filtersDimensions.length">
        <v-row class="text-right pr-6 pb-4" justify="end">
          <TableSearch class="mx-2" @searchChanged="handleTableSearchChanged" />
          <DataExport class="mx-2" @item-click="downloadTableData" />
        </v-row>
        <data-tables
          ref="dimensionTable"
          style="width: 100%"
          class="table"
          :filters="tableFilters"
          :key="tableKey"
          :data="tableData"
          :page-size="tableData.length"
          :pagination-props="{
            pageSizes: [tableData.length],
            class: 'd-none',
          }"
          @filtered-data="filteredData = $event"
          @filter-change="filters => handleFilterChange('tableFilters', filters)"
        >
          <el-table-column
            :class-name="getHeaderClasses(c)"
            align="left"
            v-for="c in tableColumns"
            :key="c"
            :prop="c"
            :label="c | formatString"
            sortable
            :column-key="c"
            :filters="filterableColumns.includes(c) ? getColumnFilterValues('tableData', c) : null"
          >
            <template slot-scope="scope">
              <div :style="getCellStyle(scope.row, c)">
                {{ formatMetric(scope.row[c], c) }}
              </div>
            </template>
          </el-table-column>
          <template v-slot:append>
            <data-tables
              :data="[tableTotal]"
              class="total-table"
              :key="tableKey"
              :pagination-props="{class: 'd-none'}"
            >
              <el-table-column
                class-name="total-row_column pl-1"
                label-class-name="d-none"
                align="left"
                v-for="item in tableColumns"
                :prop="item"
                :key="item"
              >
                <template slot-scope="scope">
                  <div class="text-center">
                    <template v-if="!['cover', 'sellthrough_target'].includes(item)">
                      {{ formatMetric(scope.row[item], item) }}
                    </template>
                  </div>
                </template>
              </el-table-column>
            </data-tables>
          </template>
        </data-tables>
      </template>
    </v-card>

    <v-dialog
      v-model="productsModalVisible"
      content-class="align-self-start mt-14"
      scrollable
      max-width="1200px"
    >
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium mx-4 my-2">
          {{ productsModalTitle }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 text--primary">
          <StrategyIntakeProductsModal
            :products-data="zoomProducts"
            class="mt-4 "
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </resizable>
</template>

<script>
import columnFilters from '@/mixins/columnFilters'
import { GetCubeIntake, GetCubeSimulation } from '@/api/cube'
import { loadFilterProperties } from '@/api/analytics'
import { colormapOTB } from '@/variables'
import { downloadData, groupAnalyticsProperties } from '@/utils'
import TableSearch from '@/components/TableSearch.vue'
import DataExport from '@/components/DataExport.vue'
import StrategyCubeSelect from '@/components/Strategy/StrategyCubeSelect'
import StrategyFilters from '@/components/Strategy/StrategyFilters'
import StrategyIntakeKPITiles from '@/components/Strategy/StrategyIntakeKPITiles'
import StrategyIntakeCharts from '@/components/Strategy/StrategyIntakeCharts'
import StrategyIntakeProductsModal from '@/components/Strategy/StrategyIntakeProductsModal'
import Resizable from '@/components/Utility/Resizable.vue'
import { useCubesStore } from '@/store/pinia/cubesStore'
import { useStrategyFiltersStore } from '@/store/pinia/strategyFiltersStore'

export default {
  name: 'StrategyIntakePage',
  components: { Resizable, TableSearch, DataExport, StrategyCubeSelect, StrategyFilters, StrategyIntakeKPITiles, StrategyIntakeCharts, StrategyIntakeProductsModal },
  mixins: [ columnFilters ],
  setup () {
    return {
      cubesStore: useCubesStore(),
      strategyFiltersStore: useStrategyFiltersStore()
    }
  },
  data () {
    return {
      loading: true,
      metrics: [{
        value: 'value',
        text: 'Retail value',
      }, {
        value: 'pieces',
        text: 'Pieces',
      }, {
        value: 'costs',
        text: 'Cost value',
      }],
      currentMetric: 'value',
      productsModalVisible: false,
      collectionsOptions: [],
      filtersDimensions: [],
      storeDimensions: [],
      filtersLoaded: false,
      filtersValues: {},
      zoomWeek: null,
      zoomProducts: [],
      intakeData: null,
      simulationData: null,
      sliders: {
        cover: {
          min: 0,
          max: 52,
          value: 16,
        },
        variability: {
          min: 0,
          max: 1,
          value: 0.2,
          step: 0.1,
        },
        leadtime: {
          min: 1,
          max: 30,
          value: 20,
        },
      },
      filteredData: [],
      tableFilters: [{ value: '' }],
      tableKey: 0,
      analyticsProperties: [],
      selectedAnalyticsProperty: 'properties/article/season',
    }
  },
  computed: {
    analyticsPropertiesGrouped () {
      return groupAnalyticsProperties(this.analyticsProperties)
    },
    filtersOpened () {
      return this.strategyFiltersStore.filtersOpened
    },
    metricFormats () {
      return this.cubesStore.metricFormat
    },
    currentMetricSuffix () {
      if (this.currentMetric === 'value') {
        return ''
      }

      if (this.currentMetric === 'costs') {
        return '_cv'
      }

      return `_${this.currentMetric}`
    },
    tableData () {
      return this.intakeData?.['dimension_table' + this.currentMetricSuffix].data || []
    },
    tableColumns () {
      return this.intakeData?.['dimension_table' + this.currentMetricSuffix].columns || []
    },
    tableTotal () {
      // return this.intakeData?.['dimension_table' + this.currentMetricSuffix].total || []

      return this.tableColumns.reduce((acc, cur) => {
        this.filteredData.forEach(row => {
          if (!['cover_target', 'sellthrough_target'].includes(cur) && typeof row[cur] === 'number') {
            acc[cur] = acc[cur] === undefined ? row[cur] : acc[cur] + row[cur]
          }
        })

        return acc
      }, {})
    },
    /* filteredData () {
      return this.filterData(this.tableData, this.tableFilters)
    }, */
    filterableColumns () {
      return this.filtersDimensions.map(d => d.name)
    },
    otbMinMax () {
      return {
        min: Math.min(...this.tableData.map(r => r['OTB' + this.currentMetricSuffix])),
        max: Math.max(...this.tableData.map(r => r['OTB' + this.currentMetricSuffix])),
      }
    },
    kpiItems () {
      if (!this.intakeData) {
        return []
      }

      const kpis = [{
        title: 'Requirement fut',
        value: this.intakeData.kpis['requirement_fut' + this.currentMetricSuffix],
      }, {
        title: 'Stock',
        value: this.intakeData.kpis['stock' + this.currentMetricSuffix],
      }, {
        title: 'Intake',
        value: this.intakeData.kpis['intake' + this.currentMetricSuffix],
      }]

      if (this.currentMetric == 'value') {
        kpis.push({
          title: 'Markdown open',
          value: this.intakeData.kpis['markdown_open' + this.currentMetricSuffix],
        })
      }

      kpis.push({
        title: 'OTB',
        value: this.intakeData.kpis['OTB' + this.currentMetricSuffix],
      })

      return kpis
    },
    productsModalTitle () {
      return `Products for week ${this.zoomWeek}`
    },
  },
  watch: {
    filtersOpened (newValue) {
      const state = this.strategyFiltersStore

      if (!newValue && state.filtersChanged) {
        if (state.period) {
          this.$refs.filters.setWeeksFromPeriod(state.period)
        }

        this.loadCubeIntake()
      }
    },
  },
  methods: {
    formatMetric (value, metricName) {
      let format = this.metricFormats[metricName.replace(this.currentMetricSuffix, '')]

      if (this.currentMetric === 'pieces' && typeof value === 'number') {
        format = 'integer'
      }

      return format ? this.$options.filters[format](value) : value
    },
    getHeaderClasses (property) {
      let classes = 'text-capitalize pl-1'

      if (this.tableData) {
        if (typeof this.tableData[0][property] === 'number') {
          classes += ' text-center'
        }
      }

      return classes
    },
    getCellStyle (row, property) {
      const style = typeof row[property] === 'number' ? {
        textAlign: 'center'
      } : {}

      if (property.startsWith('OTB')) {
        Object.assign(style, {
          background: this.getCellGradeColor(row[property]),
          borderRadius: '4px',
          color: '#fff',
          padding: '13px 0',
        })
      }

      return style
    },
    getCellGradeColor (value) {
      const min = this.otbMinMax.min
      const max = this.otbMinMax.max
      const result = min === max ? 0.5 : ((value - min) / (max - min))

      return colormapOTB(result)
    },
    handleCubeInputsLoaded ({ dimensions, store_dimensions }) {
      this.filtersDimensions = dimensions
      this.storeDimensions = store_dimensions

      if (this.filtersLoaded) {
        this.loadCubeIntake()
      }
    },
    async handleFiltersLoaded (values) {
      this.filtersLoaded = true
      this.filtersValues = values

      if (this.filtersDimensions.length) {
        await this.loadCubeIntake()
      }
    },
    handleFiltersChanged (values) {
      this.filtersValues = values
      this.tableFilters = []
      this.loadCubeIntake()
    },
    handleSliderChange () {
      this.loadCubeIntake()
    },
    handleTableSearchChanged (val) {
      this.handleSearchChanged('tableFilters', this.tableColumns, val)
      // this.tableKey++
      // this.updateTotalTableWidth()
    },
    handleMetricChange () {
      this.$nextTick(() => {
        this.$refs.charts?.drawIntakeCharts()
        this.$refs.charts?.drawProjectionCharts()
        this.$refs.charts?.drawCoverCharts()
      })
    },
    async handleAnalyticsPropertyChanged () {
      if (this.filtersLoaded) {
        await this.loadCubeIntake()
      }
    },
    async handleChartClick (weekNumber) {
      /* const payload = {
        ...this.getCommonPayload(),
        week_zoom: weekNumber
      } */

      this.loading = true
      try {
        // const { zoom_products } = await GetCubeSimulation(payload)

        this.zoomWeek = weekNumber
        // this.zoomProducts = zoom_products
        this.productsModalVisible = true
      } finally {
        this.loading = false
      }
    },
    getSlidersValues () {
      return Object
        .keys(this.sliders)
        .reduce((acc, cur) => ({
          ...acc,
          [cur]: this.sliders[cur].value
        }), {})
    },
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = document.querySelector('#strategy-intake .el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 100)
    },
    async loadCubeIntake () {
      const payload = this.getCommonPayload()
      this.loading = true

      GetCubeIntake(payload)
        .then(intakeData => {
          this.loading = false
          this.intakeData = Object.freeze(intakeData)
          this.tableKey++
          this.updateTotalTableWidth()
          this.$nextTick(() => {
            this.$refs.charts?.drawIntakeCharts()
            this.$refs.charts?.drawProjectionCharts()
            this.$refs.charts?.drawCoverCharts()
          })

          /* this
            .loadSimulationData(payload)
            .then(() => {
              if (this.intakeData.proj_age_chart) {
                this.$nextTick(this.$refs.charts?.drawProjPerDimChart)
              }
            }) */

          if (this.intakeData.proj_age_chart) {
            this.$nextTick(this.$refs.charts?.drawProjPerDimChart)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadSimulationData (payload) {
      return GetCubeSimulation(payload)
        .then(simulationData => {
          this.simulationData = Object.freeze(simulationData)
        })
    },
    getCommonPayload () {
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}
      return {
        cube_key: this.$refs.cubeSelect.currentCube,
        version_key: this.$refs.cubeSelect.currentVersion,
        dimension: this.selectedAnalyticsProperty,
        filters: {
          ...this.filtersValues,
          ...additionalFilters,
        },
        ...this.getSlidersValues(),
      }
    },
    downloadTableData (sep) {
      downloadData(sep, this.tableData, 'intake_dimensions')
    },
  },
  async created () {
    const properties = await loadFilterProperties('skus')
    this.analyticsProperties = Object.keys(properties)
  },
}
</script>

<style lang="scss">
#strategy-intake {
    .el-table td {
        padding: 0;
    }
    .el-table__append-wrapper {
        position: sticky;
        bottom: 0;
    }
    .table .el-table__body-wrapper {
        max-height: 50vh;
        overflow-y: auto;
    }
    .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
        width: 4px;
    }
    .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    }
    .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 3px;
    }
    .total-row_column {
        background-color: #f5f7fa;
    }
    .settings-menu {
        .input-parameter {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            border-bottom: 1px solid var(--border-color);
            padding: 0 16px;
            .parameter-name {
                width: 200px;
            }
            .parameter-value {
                width: 250px;
                padding: 8px 0;
            }
            .slider {
                padding-top: 4px;
                .slider-value-from,
                .slider-value-to {
                    color: #666;
                    font-size: 14px;
                }
            }
            &:last-child {
                padding-bottom: 8px;
            }
        }
    }
}
</style>
