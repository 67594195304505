import {defineStore} from 'pinia'
import {startOfDay} from 'date-fns'
import * as api from '@/api/proposalsAPI'
import * as models from '@/models/Proposal'
import {useArticleStore} from '@/store/pinia/stockArticlesStore'

export const useStockProposalStore = defineStore('stockProposal', {
  state: (): models.ProposalState => ({
    rules: [],
    value: [],
    rulesLoaded: false,
    defaultsLoaded: false,
    defaults: [],
    proposalSettings: [],
    proposalSetting: {} as models.ProposalSetting,
    proposal: [],
    proposalLoaded: false,
    impactBarsShare: [],
    impactBarsValues: [],
    predictionChartWeekly: false,
    skuValues: [],
    deltas: [],
    deltas_named: [],
    fullStoreList: [],
    potential_excluded: {table: [], columns: []},
    potential_included: {table: [], columns: []},
    singleSku: false,
    valueDates: [],
    valuePercentage: [],
    transfersTable: [],
    transfersColumns: [],
    valuePrice: [],
    colors: [],
    proposalMatrices: null,
    sku_names: [],
    price: null,
    projection: null,
    originalPrice: null,
    inbounds: [],
    proposalTableColumns: [],
    storeStockInit: {
      Total: 0
    },
    storeStockEnd:{
      Total: 0
    },
    oneStock: [],
    freeStock: [],
    dcStockInit: {},
    dcStockEnd: {},
    replenishments: {
      Total: 0
    },
    reservationOnDc: [],
    parameters: [],
    parametersLoaded: false,
    savingProposal: false,
    proposalParams: {} as models.ProposalParams,
    gaugeChart: { prediction_score: 0, distribution_score: 0 },
    stock_extra: [],
    prepacks: [],
    top_stores: {},
    storeKeys: [],
    totalRowCover: []
  }),
  actions: {
    async setDefaults () {
      try {
        this.defaults = await api.getDefaults()
        this.defaultsLoaded = true
      } catch(e) {
        console.error(e)
      }
    },
    async setRules () {
      try {
        const { rules, value, parameters } = await api.getRules()
        this.rules = rules
        this.value = value
        this.parameters = parameters || []
        this.rulesLoaded = true
        this.parametersLoaded = true
      }catch (e) {
        console.error(e)
      }
    },
    async loadProposalSettingsGetLatest (payload: { route: string }) {
      try {
        const settingsResponse = await api.getProposalSettings(payload.route)
        this.proposalSettings = settingsResponse.map(setting => ({
          ...setting,
          type: this.defaults.find(d => d.block_key === setting.block_key)?.block_name
        }))

        let futureSetting = this.proposalSettings.findLast(
          setting => startOfDay(new Date(setting.date_min)) <= startOfDay(new Date())
        )

        if (!futureSetting) {
          futureSetting = this.proposalSettings[this.proposalSettings.length - 1]
        }
        this.setProposalSetting(futureSetting)
        return futureSetting
      } catch (e) {
        console.log(e)
      }
    },
    async setNewProposalSetting (payload: { route: string }) {
      try {
        const [setting] = await api.getProposalSetting(payload.route)
        this.setProposalSetting(setting)
      } catch (e) {
        console.error(e)
      }
    },
    async addProposalSetting (payload: { route: string }) {
      try {
        const setting = await api.addProposalSetting(payload.route, this.defaults[1].block_key)
        this.proposalSettings.push({
          ...setting,
          type: this.defaults.find(d => d.block_key === this.defaults[1].block_key)?.block_name,
        })
      } catch (e) {
        console.error(e)
      }
    },
    async deleteProposalSetting (payload: { route: string, id: number }) {
      try {
        await api.deleteProposalSetting(payload.route, payload.id)
        this.proposalSettings = this.proposalSettings.filter(s => s.id !== payload.id)
      } catch (e) {
        console.error(e)
      }
    },
    async loadProposal (payload: { route: models.ProposalRoute, settings?: models.ProposalSetting }) {
      this.savingProposal = true
      let currentProposalSetting: models.ProposalSetting | null = null
      if (payload.settings) {
        currentProposalSetting = structuredClone(payload.settings)
      } else if (this.proposalSetting) {
        currentProposalSetting = structuredClone(this.proposalSetting)
      }
      const stockParameters = currentProposalSetting?.stock_parameters || useArticleStore().articleSettings.stock_parameters
      const articleStore = useArticleStore()
      const params: models.ProposalParams = {
        ...articleStore.articleSettings,
        option_key: payload.route.option_key,
        rules: currentProposalSetting?.rules || [],
        params: currentProposalSetting?.parameters || {
          prepacks_keep: {},
        },
        stock_parameters: stockParameters,
        inbound_key: payload.route.inbound_key,
      }
      if(params.stock_parameters?.prepacks_keep) {
        for (const prepackKey in params.stock_parameters.prepacks_keep) {
          params.stock_parameters.prepacks_keep[prepackKey] = Number(params.stock_parameters.prepacks_keep[prepackKey])
        }
      }
      this.proposalParams = params
      if (currentProposalSetting === null || Object.entries(currentProposalSetting).length === 0) {
        return
      }
      try {
        const proposalData = await api.getProposal(params)
        this.setProposalData(proposalData)
      } catch (error) {
        console.error(error)
        this.setProposal({})
        const storeKeys = articleStore.articleSettings.store_keys
        if (!storeKeys || !storeKeys.length) {
          return
        }
        if (window.confirm('Oops, something went wrong. Do you want to try again?')) {
          window.location.reload()
        }
      } finally {
        this.savingProposal = false
      }
    },
    async saveRuleblock (payload: { query: string, setting: models.ProposalSetting }) {
      try {
        await api.saveRuleblock(payload.query, payload.setting)
      } catch (error) {
        console.error(error)
      }
    },
    setProposalSetting (setting: models.ProposalSetting) {
      // First we convert all variable values to floats
      for (const rule of setting.rules) {
        convertObjectPropsToFloat(rule)
      }
      this.proposalSetting = setting
    },
    setProposalData (data: models.ProposalData) {
      this.proposalLoaded = true
      this.inbounds = data.inbounds
      this.storeKeys = data.store_keys
      this.proposal = data.table
      this.colors = data.colors
      this.potential_excluded = data.potential_excluded || { table: [], columns: [] }
      this.potential_included = data.potential_included || { table: [], columns: [] }
      this.impactBarsShare = data.impact_bars_share
      this.impactBarsValues = data.impact_bars_values
      this.deltas = data.deltas || []
      this.deltas_named = data.deltas_named
      this.fullStoreList = data.store_keys || []
      this.totalRowCover = data.table_cover_ttl
      this.proposalTableColumns = data.table_columns
      this.proposalMatrices = data.matrices
      this.sku_names = data.sku_names
      this.storeStockInit = data.store_stock_init || {}
      this.top_stores = data.top_stores || {}
      this.storeStockEnd = data.store_stock_end || {}
      this.oneStock = data.one_stock_on_dc || []
      this.freeStock = data.free_on_dc || []
      this.dcStockInit = data.dc_stock_init || {}
      this.gaugeChart = { prediction_score: data.prediction_score, distribution_score: data.distribution_score }
      this.dcStockEnd = data.dc_stock_end || {}
      this.replenishments = data.replenishments || {}
      this.reservationOnDc = data.reservation_on_dc
      this.singleSku = data.single_sku
      this.valueDates = data.value_dates
      this.valuePercentage = data.value_percentage
      this.valuePrice = data.value_price
      this.price = data.price
      this.projection = data.projection
      this.originalPrice = data.original_price
      this.transfersTable = data.transfers_table
      this.transfersColumns = data.transfers_columns
      this.prepacks = data.prepacks
      this.stock_extra = data.stock_extra
    },
    setProposal (data: Partial<models.ProposalData>) {
      this.proposalLoaded = false
      this.proposal = data.table || []
      this.colors = data.colors || []
      this.potential_excluded = data.potential_excluded || { table: [], columns: [] }
      this.potential_included = data.potential_included || { table: [], columns: [] }
      this.impactBarsShare = data.impact_bars_share || []
      this.impactBarsValues = data.impact_bars_values || []
      this.deltas = data.deltas || []
      this.deltas_named = data.deltas_named || []
      this.fullStoreList = data.store_keys || []
      this.totalRowCover = data.table_cover_ttl || []
      this.proposalTableColumns = data.table_columns || []
      this.storeStockInit = data.store_stock_init || {}
      this.top_stores = data.top_stores || {}
      this.storeStockEnd = data.store_stock_end || {}
      this.oneStock = data.one_stock_on_dc || []
      this.freeStock = data.free_on_dc || []
      this.dcStockInit = data.dc_stock_init || {}
      this.dcStockEnd = data.dc_stock_end || {}
      if (data.prediction_score && data.distribution_score) {
        this.gaugeChart = { prediction_score: data.prediction_score, distribution_score: data.distribution_score }
      } else {
        this.gaugeChart = { prediction_score: 0, distribution_score: 0 }
      }
      this.replenishments = data.replenishments || {Total: 0}
      this.reservationOnDc = data.reservation_on_dc || []
      this.singleSku = data.single_sku || false
      this.valueDates = data.value_dates || []
      this.valuePercentage = data.value_percentage || []
      this.valuePrice = data.value_price || []
      this.price = data.price || null
      this.projection = data.projection || null
      this.originalPrice = data.original_price || null
      this.transfersTable = data.transfers_table || []
      this.transfersColumns = data.transfers_columns || []
      this.prepacks = data.prepacks || []
      this.stock_extra = data.stock_extra || []
    }
  },
  getters: {
    getProposalSettings: (state) => structuredClone(state.proposalSettings),
    getRules: (state) => structuredClone(state.rules),
    getParams: (state) => structuredClone(state.parameters),
    getDefaults: (state) => structuredClone(state.defaults),
    getCurrentSetting: (state) => state.proposalSetting,
    getProposalHeaders: (state) => structuredClone(state.proposalTableColumns),
    getProposal: (state) => structuredClone(state.proposal),
    getProposalColors: (state) => structuredClone(state.colors)
  },
})

function convertObjectPropsToFloat (obj: Record<string, unknown>) {
  for (const key in obj) {
    if (key === 'id') {
      continue
    }
    if (typeof obj[key] === 'number'){
      continue
    }
    if (typeof obj[key] === 'object') {
      convertObjectPropsToFloat(obj[key] as Record<string, unknown>)
    } else if (Array.isArray(obj[key])) {
      obj[key] = (obj[key] as string[]).map(parseFloat)
    } else {
      obj[key] = parseFloat(obj[key] as string)
    }
  }
}
