<template>

  <div class="stockHomeCarousel">
    <v-row class="justify-center p-relative py-4 ma-0 mx-4">
      <v-btn-toggle
        v-model="chartIndex"
        @change="saveChartIndex"
        dense
        active-class="bg-gray"
        light
      >
        <v-btn
          v-for="(type, index) in types"
          :key="index"
          small
          color="white"
        >
          <span class="text-capitalize">{{ type.name | formatString}}</span>
        </v-btn>
      </v-btn-toggle>
      <div class="p-absolute" style="right: 0">
        <v-switch
          v-model="isCumulative"
          hide-details
          @change="$emit('cumulative-change', $event)"
          label="Cumulative"
          class="pt-0 mt-0 mr-2"
        ></v-switch>
      </div>
    </v-row>
    <v-overlay absolute color="white" :value="Object.keys(data).length <3 ">
      <v-progress-circular color="primary" indeterminate size="64" width="2"/>
    </v-overlay>

    <v-carousel
      height="auto"
      class="pb-6"
      :perPage="1"
      hide-delimiters
      :show-arrows="false"
      v-model="chartIndex">
      <v-carousel-item v-for="(type, index) in types" :key="index">
        <div>
          <ChartElement
            v-if="chartIndex === index"
            title="buy_chart_carousel"
            ref="buy_chart_carousel"
            :params="{}"
            :chartData="chartData[type.name]"
            :padding="false"
            :fixedHeight="false"
          />
          <v-divider
            v-if="showBudgetProgress"
          />
          <BudgetProgressItem
            v-if="showBudgetProgress"
            :isPercentage="false"
            :budgetProgressType="types[chartIndex || 0].value"
            :progressData="budgetProgress"
            class="px-3"
            show-fractions
          >
            <template v-slot:total-caption>
              <div
                class="total mt-2"
              >
                <template v-if="budgetProgress.total !== undefined">
                  Budget: {{!isPieces ? priceFormatter(budgetProgress.total) : budgetProgress.total }}
                </template>
                <span v-if="budgetProgress.over > 0">
                  &nbsp;(Open: {{!isPieces ? priceFormatter(budgetProgress.over) : budgetProgress.over}})
                </span>
                <span v-else-if="budgetProgress.over < 0" class="exceed">
                  &nbsp;(Over: {{!isPieces ? priceFormatter(-1*budgetProgress.over) : -1*budgetProgress.over}})
                </span>
              </div>
            </template>
          </BudgetProgressItem>
          <BudgetProgressLegend
            v-if="showBudgetProgress"
            :items="budgetProgressLegendItems"
          />
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import ChartElement from '../chart/ChartElement'
import BudgetProgressItem from '../components/Shop/BudgetProgressItem'
import BudgetProgressLegend from '../components/Shop/BudgetProgressLegend'
export default {
  name: 'BuyChartCarousel',
  components: { BudgetProgressLegend, BudgetProgressItem, ChartElement },
  data () {
    return {
      isCumulative: false,

      budgetProgressLegendItems: [{
        caption: 'Ordered',
        color: '#4CAF50',
      }, {
        caption: 'In progress',
        color: '#007AFF',
      }, {
        caption: 'To order',
        color: '#FFD202',
      }, {
        caption: 'Open',
        color: '#E3E3E3',
      }],
      types: [{ value: 'revenue', name: 'value' }, { value: 'pieces', name: 'pieces' }, { value: 'cost', name: 'costs' }],
    }
  },
  watch: {
    chartIndex (newValue, oldValue) {
      if (newValue === undefined) {
        this.chartIndex = oldValue
      }
    },
  },
  methods: {

    saveChartIndex (value) {
      this.$emit('chart-index-changed', value)

      localStorage.setItem('buy-chart-carousel', value)
    },
  },
  computed: {

    isPieces () {
      return this.types[this.chartIndex || 0]?.value === 'pieces'
    },
    priceFormatter () {
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.$auth.getUserCurrency().currency,
        maximumFractionDigits: 0,
      })
      return formatter.format
    },
    showBudgetProgress () {
      return !!this.budgetProgress?.Budget
    },
    chartData () {
      return this.data?.table?.data
    },
    budgetProgress () {
      const data = this.data.kpi[0]
      if (!data) {
        return {}
      }
      const prop = this.types[this.chartIndex || 0].value
      const segments = []
      this.budgetProgressLegendItems.forEach((item) => {
        const key = item.caption.toLowerCase().replaceAll(' ', '_')
        let dataProp = data[`${key}_${prop}`]
        if (key === 'open' && dataProp < 0) {
          dataProp = 0
        }
        segments.push({
          value: dataProp,
          color: item.color,
          caption: item.caption,
        })
      })

      return {
        segments,
        total: parseFloat(data[`intake_${prop}`]),
        over: data[`open_${prop}`],
        Budget: parseFloat(data[`intake_${prop}`]),
      }
    },
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    chartIndex: {
      type: Number,
      default: () => 0
    },
  }
}
</script>

<style scoped>

</style>
