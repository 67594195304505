<template>
  <div class="buy-manage-budgets-main mt-2">
    <v-row class="ma-6 mb-3" justify="space-between">
      <h4>Budgets</h4>
    </v-row>
    <v-row class="ma-4  mt-0 justify-end" >
      <div>
        <table-search
          @searchChanged="filters => handleSearchChanged('budgetsFilters',Object.keys(budgets[0]), filters)"></table-search>
        <v-icon class="mx-2" @click="budgetModalOpen = true; isEdit=true" :disabled="!selectedBudget">edit</v-icon>
        <v-icon class="mr-2" @click="budgetModalOpen = true; isEdit=false">add</v-icon>
        <v-icon :disabled="!selectedBudget" @click="deleteDialogVisible =true">delete</v-icon>
      </div>
    </v-row>

    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <data-tables
      v-if="!loading"
      :data="budgets"
      :filters="budgetsFilters"
      :table-props="{'row-key': 'budget_key'}"
      :page-size="5"
      @row-click="(row)=> selectBudget(row)"
      @filter-change="filters => handleFilterChange('budgetsFilters', filters)"
      :pagination-props="{ pageSizes: [5, 10, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
    >
      <el-table-column prop="option_key" width="55">
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="ml-3"
            :value="selectedBudget && selectedBudget === scope.row.budget_key"
            :ripple="false"
            :input-value="selectedBudget && selectedBudget === scope.row.budget_key"
            @change="()=> selectBudget(scope.row)"
            @click.stop="()=> selectBudget(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="budget_name"
        sortable
        label="Budget Name"
      >
        <template slot-scope="scope">
          {{scope.row.budget_name}}
        </template>
      </el-table-column>
      <el-table-column
        prop="creation_date"
        sortable
        label="Created">
        <template slot-scope="scope">
          {{scope.row.creation_date && scope.row.creation_date.substr(0, 10)}}
        </template>
      </el-table-column>
      <el-table-column
        prop="active"
        sortable
        column-key="active"
        label="Active"

        :filters="getColumnFilterValues('budgets', 'active')">
        <template slot-scope="scope">
          <v-checkbox :input-value="scope.row.active" hide-details disabled class="ma-0"></v-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        prop="budgets_external_key"
        sortable
        label="External Key"
      >
        <template slot-scope="scope">
          {{scope.row.budgets_external_key}}
        </template>
      </el-table-column>
      <el-table-column
        prop="source"
        sortable
        label="Source">
        <template slot-scope="scope">
          {{scope.row.source | formatString}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="properties"
        label="Properties">
        <template slot-scope="scope">
          <chips-dropdown
            :items="scope.row.properties_show">
          </chips-dropdown>
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="dimensions"
        label="Dimensions">
        <template slot-scope="scope">
          <chips-dropdown
            v-if="scope.row.dimensions && scope.row.dimensions.length && scope.row.dimensions[0]"
            :items="scope.row.dimensions">
          </chips-dropdown>
          <span v-else>-</span>
        </template>
      </el-table-column>
    </data-tables>
    <v-divider/>
    <v-dialog
      v-model="budgetModalOpen"
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <buy-manage-budget-modal
        v-if="budgetModalOpen"
        :properties="properties"
        :isEdit="isEdit"
        :budget_key="selectedBudget"
        :budgets="budgets.map((item)=> item.budget_name)"
        :externalBudgets="externalBudgets"
        @close="budgetModalOpen = false"
        @save="budgetModalOpen = false; loadData()"
      ></buy-manage-budget-modal>
    </v-dialog>
    <v-dialog
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="p-relative rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Set budget inactive
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Do you want to make this budget inactive?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="onDeleteClick"
                class="red--text mx-4"
              >Yes</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ChipsDropdown from '../ChipsDropdown'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '../TableSearch'
import BuyManageBudgetModal from '../../views/BuyManageBudgetModal'
import { deleteBudgets, getExternalBudgets } from '@/api/budgetAPI'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'BuyManageBudgetsMain',
  mixins: [columnFilters],
  components: { BuyManageBudgetModal, ChipsDropdown, TableSearch },
  props: ['properties'],
  setup () {
    return {
      generalStore: useGeneralStore(),
    }
  },
  data () {
    return {
      externalBudgets: [],
      selectedBudget: null,
      budgetsFilters: [],
      budgetModalOpen: false,
      deleteDialogVisible: false,
      isEdit: false,
      loading: true,
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    budgets () {
      return this.generalStore.appConfig?.budgets || []
    }
  },
  methods: {
    selectBudget (budget) {
      if (this.selectedBudget === budget.budget_key) {
        this.selectedBudget = null
        budget = null
      } else {
        this.selectedBudget = budget.budget_key
      }
      this.$emit('budget-change', budget)
    },
    loadData () {
      this.loading = true
      getExternalBudgets().then(externalBudgets => {
        this.externalBudgets = externalBudgets
      }).finally(() => {
        this.loading = false
      })
    },
    onDeleteClick () {
      deleteBudgets({ budget_key: this.selectedBudget })
        .then(() => {
          this.deleteDialogVisible = false
          this.selectedBudget = null
          this.$emit('budget-change', null)
          this.loadData()
        })
    }
  }
}
</script>

<style scoped>
.buy-manage-budgets-main {
}
</style>
