<template>
  <div
    class="strategy-filter-widget"
  >
    <v-card v-show="filtersOpened" class="filter-widget-card" id="filterForm">
      <div class="filter-options pa-4">
        <div class="pt-4 px-4">
          <h4 class="justify-space-between align-center d-flex">Filters
            <v-icon @click="resetFilters" class="">replay</v-icon>
          </h4>
          <div class="py-4">
            <v-row v-for="(values, prop) in strategyPageFilters" :key="prop" class="flex-nowrap ma-0">
              <v-autocomplete
                :items="cubePropertiesGrouped"
                :filter="headersFilters"
                class="pr-4 flex xs6"
                :value="prop"
                @input="value => handleFilterPropertyChanged(prop,value)"
                label="Property"
              ></v-autocomplete>
              <collapsible-select
                :items="propertiesValues[prop]"
                v-model="strategyPageFilters[prop]"
                autocomplete
                multiple
                show-select-all
                class="flex xs6"
                label="Values"
                @change="handlePropertyValueChanged"
              ></collapsible-select>
              <v-flex class="align-baseline justify-center d-flex">
                <v-icon @click="removeStrategyFilter(prop)">delete</v-icon>
              </v-flex>
            </v-row>

            <v-row class="flex-nowrap ma-0">
              <v-autocomplete
                v-model="newProperty"
                :filter="headersFilters"
                label="Property"
                clearable
                hide-details="auto"
                :items="cubePropertiesGrouped"
                class="pr-4 flex xs6"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
              />
              <collapsible-select
                v-if="newProperty"
                autocomplete
                multiple
                show-select-all
                :items="propertiesValues[newProperty]"
                v-model="newPropertyValues"
                class="flex xs6"
                label="Values"
                @blur="handleNewPropertyValueChanged"
              ></collapsible-select>
            </v-row>
          </div>

          <template v-if="$route.name !== 'strategy-home-page'">
            <h4 class="pt-8 justify-space-between align-center d-flex">Periods
              <v-icon @click="resetPeriods" class="">replay</v-icon>
            </h4>
            <div class="py-4">
              <v-row class="flex-nowrap ma-0">
                <v-autocomplete
                  v-model="period"
                  :items="periodsList"
                  class="pr-4 flex xs6"
                  item-text="period_name"
                  return-object
                  label="Period"
                  @blur="handlePeriodChanged"
                ></v-autocomplete>
              </v-row>
            </div>
          </template>
        </div>
      </div>
      <div class="controls">
        <v-divider />
        <div class="mt-6 pb-6 pr-6 text-right ">
          <v-btn text color="primary" class="mr-2" @click="resetAll"
          >Reset All</v-btn>
          <v-btn outlined color="primary" @click="handleApplyClicked">
            Apply
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>

import { getStrategyPeriods } from '@/api/strategy'
import { formatString } from '@/variables'
import CollapsibleSelect from '@/components/CollapsibleSelect'
import { headersFilters } from '@/utils'
import { useCubesStore } from '@/store/pinia/cubesStore'
import { useStrategyFiltersStore } from '@/store/pinia/strategyFiltersStore'
import { debounce } from 'lodash'

export default {
  name: 'StrategyFilterWidget',
  components: { CollapsibleSelect },
  setup () {
    return {
      cubesStore: useCubesStore(),
      strategyFiltersStore: useStrategyFiltersStore()
    }
  },
  data () {
    return {
      filtersChanged: false,
      strategyPageFilters: {},
      newProperty: null,
      newPropertyValues: [],
      cubeProperties: [],
      periodsList: [],
      period: null,
      saveStrategyFilter: debounce(this.saveStrategyFilterRaw, 200),
    }
  },
  watch: {
    $route () {
      this.strategyFiltersStore.setFiltersApplied(this.hasStrategyFilters())
    },
    filtersOpened (val) {
      if (val) {
        this.strategyPageFilters = JSON.parse(localStorage.getItem('strategySideFilters') || '{}')
        this.period = JSON.parse(localStorage.getItem(this.periodStorageKey))
        this.strategyFiltersStore.setPeriod(this.period ? { ...this.period } : null)
      } else {
        this.$nextTick(() => {
          this.strategyFiltersStore.setFiltersChanged(false)
        })
      }

      this.strategyFiltersStore.setFiltersApplied(this.hasStrategyFilters())
    },
    'cubesStore.cubeInputData': {
      handler (val) {
        if (!val) {
          return
        }

        const { properties } = val

        this.cubeProperties = properties
          .map(p => p.name)
          .filter(p => p.includes('/'))
      },
      immediate: true,
    },
  },
  async mounted () {
    const periods = await getStrategyPeriods()
    this.periodsList = Object.freeze(periods)
    this.period = JSON.parse(localStorage.getItem(this.periodStorageKey))

    this.strategyFiltersStore.setPeriod(this.period ? { ...this.period } : null)
    this.strategyFiltersStore.setFiltersApplied(this.hasStrategyFilters())
  },
  computed: {
    isWSSIPage () {
      return this.$route.name === 'strategy-wssi-page'
    },
    periodStorageKey () {
      return this.isWSSIPage ? 'strategySideFilterWSSIPeriod' : 'strategySideFilterPeriod'
    },
    cubePropertiesGrouped () {
      const result = []
      const propertiesSorted = this.cubeProperties.slice().sort()
      let lastHeader = null

      for (const prop of propertiesSorted) {
        const parts = prop.split('/')

        if (!lastHeader || parts[0] !== lastHeader) {
          result.push({
            header: formatString(parts[0]),
          })

          lastHeader = parts[0]
        }

        result.push({
          value: prop,
          text: formatString(parts[1]),
        })
      }

      return result
    },
    propertiesValues () {
      return this.cubesStore.cubeInputData.properties.reduce((acc, cur) => ({
        ...acc,
        [cur.name]: cur.values,
      }), {})
    },
    filtersOpened () {
      return this.strategyFiltersStore.filtersOpened
    },
  },
  methods: {
    headersFilters,
    hasStrategyFilters () {
      const filters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}
      let period = null

      if (this.$route.name !== 'strategy-home-page') {
        period = JSON.parse(localStorage.getItem(this.periodStorageKey))
      }

      return Object.keys(filters).length || period
    },
    handleFilterPropertyChanged (oldProp, prop) {
      delete this.strategyPageFilters[oldProp]
      this.$set(this.strategyPageFilters, prop, [])
      this.saveStrategyFilter()
    },
    handlePropertyValueChanged () {
      this.strategyFiltersStore.setFiltersChanged(true)
      this.saveStrategyFilter()
    },
    handleNewPropertyValueChanged () {
      setTimeout(() => {
        this.$set(this.strategyPageFilters, this.newProperty, this.newPropertyValues.slice())
        this.newProperty = null
        this.newPropertyValues = []
        this.strategyFiltersStore.setFiltersChanged(true)
        this.saveStrategyFilter()
      }, 120)
    },
    handlePeriodChanged () {
      this.strategyFiltersStore.setPeriod(this.period ? { ...this.period } : null)
      this.strategyFiltersStore.setFiltersChanged(true)
      this.savePeriod()
    },
    handleApplyClicked () {
      this.saveStrategyFilter()
      this.strategyFiltersStore.setFiltersOpened(false)
      this.$nextTick(() => {
        this.strategyFiltersStore.setFiltersChanged(false)
      })
    },
    resetFilters () {
      this.$set(this, 'strategyPageFilters', {})
      this.strategyFiltersStore.setFiltersChanged(true)
      this.saveStrategyFilter()
    },
    resetPeriods () {
      this.period = null
      this.strategyFiltersStore.setPeriod(null)
      this.strategyFiltersStore.setFiltersChanged(true)
      this.savePeriod()
    },
    removeStrategyFilter (prop) {
      delete this.strategyPageFilters[prop]
      this.strategyPageFilters = { ...this.strategyPageFilters }
      this.saveStrategyFilter()
      this.strategyFiltersStore.setFiltersChanged(true)
    },
    resetAll () {
      this.resetFilters()
      this.resetPeriods()
    },
    saveStrategyFilterRaw () {
      localStorage.setItem('strategySideFilters', JSON.stringify(this.strategyPageFilters))
    },
    savePeriod () {
      localStorage.setItem(this.periodStorageKey, JSON.stringify(this.period))
    },
    formatString,
  },
}
</script>

<style lang="scss">
.strategy-filter-widget {
  position: fixed;
  top: 56px;
  right: 0;
  z-index: 6;
  .v-input {
    font-size: 14px;
  }
  .view-dataset-title {
    font-size: 12px;
    color: rgba(0,0,0,0.6);
  }
  .v-expansion-panel-content__wrap{
    padding: 0;
  }

  .view-panel{
    border-bottom: 1px solid #DADCDF;
  }
  .v-select__selection--comma {
    margin-bottom: 2px;
  }

  .orange--text-input {
    color:#ff9800 !important;
  *{
    color:#ff9800 !important;
  }
  }
  .v-text-field {
    .v-label {
      font-size: 14px;
    }
    .v-label--active {
      font-size: 12px;
      transform: translateY(-20px);
    }
  }
  .values-select {
    .v-chip .v-chip__content {
      & > span {
        width: 20px;
        overflow: hidden;
      }
    }
  }
  .v-select.v-select--chips .v-select__selections {
    min-height: 32px;
  }
  .filter-widget-card {
    box-shadow: inset 0 2px 2px #ccc !important;
    width: 500px;
    height: calc(calc(100vh / var(--zoom)) - 56px);
    overflow: hidden;
    z-index: 1000;
    border-radius: 0 !important;
    justify-content: center;
    align-items: start;
  }
  h4 {
    font-size: 16px;
  }
  .filter-options {
    height: calc(100% - 85px);
    margin-right: 12px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
  }
}
</style>
