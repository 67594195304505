<template>
  <div class="stock-transfers-filter pa-6">
    <v-overlay absolute color="white" :value="!appConfig.transfers_slicers" >
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <h4 class="pa-0 card-title text-left py-4">Box optimization settings</h4>
    <div class="col-5 px-0" v-for="(_, key) in appConfig.transfers_slicers" :key=key>
      <div class="slider-container align-center">
        <span class="titles">{{formatString(key)}}</span>
        <div class="d-flex align-center">
          <span>{{appConfig.transfers_slicers[key][0]}}</span>
          <v-slider
            class="px-4"
            v-model="appConfig.transfer_settings[0].transfers_settings[key]"
            id="factor-value"
            thumb-label
            track-color="#ccc"
            track-fill-color="primary"
            :thumb-size="24"
            hide-details
            :min="appConfig.transfers_slicers[key][0]"
            :max="appConfig.transfers_slicers[key][2]"
            :step="appConfig.transfers_slicers[key][1]"
            @change="saveSettings"
          />
          <span>{{appConfig.transfers_slicers[key][2]}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'

export default {
  setup () {
    return {
      stockTransfersStore: useStockTransfersStore(),
    }
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
  },
  methods: {
    formatString (str) {
      const words = str.split('_')
      let formattedString = ''
      words.forEach((word, index) => {
        if (index) {
          formattedString += word + ' '
        }
      })
      if (words[0] === 'minimum') {
        formattedString += '(min)'
      } else {
        formattedString += '(max)'
      }
      return formattedString.charAt(0).toUpperCase() + formattedString.slice(1)
    },
    async saveSettings () {
      await this.stockTransfersStore.saveSettings()
      await this.$auth.refreshAppConfig(null, ['transfer_settings'])
    }

  },
}
</script>

<style lang="scss">
.stock-transfers-filter {
  .slider-container{
    color: rgba(0,0,0,0.6);
    font-size: 14px;
    display: grid;
    grid-template-columns: 2fr 3fr;
  }
    .select-property-values {
        margin-left: 20px;
    }
    .el-input__inner {
        min-width: 200px !important;
    }
    .group-header {
        text-align: left;
    }
    .el-form-item__content {
        text-align: left;
    }
    .el-form-item:last-of-type {
        margin-bottom: 0;
    }
    .el-slider {
        max-width: 200px;
    }
}

</style>
