<template>
  <div class="stockCarousel">
    <v-card class="card p-relative" >
      <v-row justify="space-between" class="align-start mb-5">
        <h4 class="pa-4 text-center">Statistics</h4>
        <v-col cols="auto" class="flex-column d-flex align-center">
          <v-btn-toggle
            v-model="carouselIndex"
            dense
            active-class="bg-gray"
            class="pb-3 pr-3"
            light
          >
            <v-btn
              v-for="(type, index) in types"
              :key="index"
              small
              color="white"
            >
              <span class="text-capitalize">{{ type.name }}</span>
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle
            v-model="chartIndex"
            dense
            active-class="bg-gray"
            light
          >
            <v-btn
              v-for="(type, index) in chartTypes"
              :key="index"
              small
              color="white"
            >
              <span class="text-capitalize">{{ type.name }}</span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <DataExport
          class="pa-4 pr-0"
          @item-click="(sep)=> downloadChartData(sep, $refs['transferChart'])"
        />
      </v-row>
      <v-carousel
        hide-delimiters
        height="410px"
        light
        hide-delimiter-background
        :show-arrows="false"
        @change="(index)=> chartIndex = index">
        <v-carousel-item eager>

          <v-col justify="center" class="pa-0" v-if="allChartsLoaded"  style=" position: relative; min-height: 350px; max-height: 350px; width: 100%">
            <h4 class="text-center mb-2">{{chartIndex === 1? 'Profit' : 'Pieces'}}</h4>
            <LineChart
              ref="transferChart"
              :options="lineChartOptions"
              :labels="lineChartData.labels"
              :data="chartData"/>
          </v-col>
        </v-carousel-item>

        <v-carousel-item eager>

          <v-col justify="center" class="pa-0" v-if="allChartsLoaded"  style=" position: relative; min-height: 350px; max-height: 350px; width: 100%">
            <h4 class="text-center mb-2">{{chartIndex === 1? 'Profit' : 'Pieces'}}</h4>
            <LineChart
              ref="transferChart"
              :options="lineChartOptions"
              :labels="lineChartData.labels"
              :data="chartData"/>
          </v-col>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script>
import DataExport from '../DataExport'
import LineChart from '@/charts/LineChart.vue'
import { getStockTransfersChart } from '@/api/transfers'
import { downloadData } from '@/utils'
import { format } from 'date-fns'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  name: 'ChartsCarousel',
  data () {
    return {
      carouselIndex: 0,
      chartIndex: 0,
      componentsLoaded: { chart1: false, chart2: false },
      types: [
        { value: 'total', name: 'total' },
        { value: 'box', name: 'box' },
        { value: 'free', name: 'free' },
        { value: 'build', name: 'build' },
      ],
      chartTypes: [
        { value: '0', name: 'Pieces' },
        { value: '1', name: 'Profit' },
      ],
      lineChartData: {
        labels: [],
        datasets: [],
      }
    }
  },
  watch: {
    'filtersStore.filtersChanged': {
      handler (newVal) {
        if (newVal) {
          this.getLineChartData()
        }
      },
    },
  },
  computed: {
    currentWeek () {
      return 'W' + format(new Date(), 'ww')
    },
    params () {
      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()
      return {
        groups: pageFiltersValues.groups || [],
        collections: pageFiltersValues.collections || [],
        options: pageFiltersValues.options || [],
        stores_dc: pageFiltersValues.stores_dc || [],
        to_stores_dc: pageFiltersValues.to_stores_dc || [],
        stores: pageFiltersValues.stores || [],
        to_stores: pageFiltersValues.to_stores || [],
        ignore_cache: true,
        type: 'all',
        send: 'all',
        receive: 'all',
      }
    },
    lineChartOptions () {
      return {
        scales: {
          y: {
            title: {
              display: true,
              text: this.chartIndex ? 'Profit' :  'Pieces',
            },
            min: 0,
            ticks: {
              // Set maximum number of ticks, (ticks are the values on the y-axis)
              maxTicksLimit: 5,
              minTicksLimit: 5,
              callback: (v) => (v >= 500) ? v / 1000 + 'K' : v,
            },
            position: 'left',
            border: {
              display: false,
            },
          },
        }
      }
    },
    allChartsLoaded () {
      return this.lineChartData?.datasets?.length > 0
    },
    chartData () {
      const { proposedProp, acceptedProp } = this.getPropNames(this.chartIndex, this.carouselIndex)
      return [
        {
          label: 'Accepted',
          data: this.lineChartData.datasets.map(item => item[acceptedProp]),
          fill: false,
          borderDash: [10, 5],
          borderColor: '#4CAF50',
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'Proposed',
          data: this.lineChartData.datasets.map(item => item[proposedProp]),
          fill: true,
          borderColor: '#007AFF',
          pointBackgroundColor: '#FFF',
          backgroundColor: (context) => {
            if (!context.chart?.chartArea) {
              return
            }
            const { ctx, chartArea: { top, bottom } } = context.chart
            const proposalGradient = ctx.createLinearGradient(0, top, 0, bottom)
            proposalGradient.addColorStop(1, 'RGBA(0,122,255,0)')
            proposalGradient.addColorStop(0.5, 'RGBA(0,122,255,0.05)')
            proposalGradient.addColorStop(0, 'RGBA(0,122,255,0.2)')
            return proposalGradient
          },
        },
      ]
    },
  },
  methods: {
    downloadChartData (sep) {
      const chartData = this.lineChartData.datasets
      downloadData(sep, chartData, this.chartIndex ? 'profitChart' : 'piecesChart')
    },
    async getLineChartData () {
      const { data } = await getStockTransfersChart(this.params)
      this.lineChartData.labels = data.map(item => item.x_label)
      this.lineChartData.datasets = data
    },
    getPropNames (chartIndex, carouselIndex) {
      const chartPartList = ['pieces', 'profit']
      const carouselPartList = ['total', 'box', 'free', 'build']
      const proposedProp = `proposed_${chartPartList[chartIndex]}_${carouselPartList[carouselIndex]}`
      const acceptedProp = `accepted_${chartPartList[chartIndex]}_${carouselPartList[carouselIndex]}`
      return { proposedProp, acceptedProp }
    }
  },
  created () {
    this.getLineChartData()
  },
  components: {
    LineChart,
    DataExport,
  }
}
</script>

<style lang="scss">
.stockCarousel {
    .card {
        width: 100%;
        padding: 16px;

        .chart-title {
            text-align: center;
            margin-bottom: 6px;
        }
    }
    .VueCarousel-pagination {
        .VueCarousel-dot-container {
            margin: 0 !important;
            .VueCarousel-dot {
                margin-top: 10px !important;
            }
        }
    }
}
</style>
