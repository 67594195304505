<template>
  <v-card
    ref="matrixTable"
    class="data-sets-matrix-tables py-4 pt-1 overflow-auto"
    v-if="!isChangeView"
  >

    <v-row class="align-center mx-1 my-0" v-if="index !== null">
      <v-overlay absolute color="white" :value="!view.data && !(view.view.errored || view.errored)">
        <v-progress-circular color="primary" indeterminate size="64" width="2"/>
      </v-overlay>
      <v-row class="ma-0">
        <h4 class="ma-0 pl-3 pr-2 pb-4 align-self-start text-no-wrap" v-if="hasTitle">{{positionTitle}}. {{ view.view.view_name }}</h4>
        <v-tooltip v-if="view.view.limited" top :max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-3 error--text action-btn-primary">warning</v-icon>
          </template>
          <span>Only top rows of data are showed because of size. Total row data is still correct</span>
        </v-tooltip>
        <slot v-else></slot>
      </v-row>
      <div class="d-flex pa-3 pb-4 mr-3 show-on-hover "
        v-if="!view.view.errored && !view.errored && view.view.dimensions && view.view.dimensions.length">
        <TableSearch
          @searchChanged="val => handleSearchChanged('tableFilters',[...view.view.dimensions, ...Object.keys(sortedData[0])], val)"
        />
        <v-icon class="show-on-hover ml-2 action-btn-primary" v-if="hasTitle && !inModal"  @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" >edit</v-icon>
        <v-overlay absolute color="white" :value="downloadingLoaded">
          <v-progress-circular color="primary" indeterminate size="64" width="2"/>
        </v-overlay>
        <div class="d-inline">
          <v-menu offset-y :close-on-content-click="true" nudge-bottom="5">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="mx-2 action-btn-primary">download</v-icon>
            </template>
            <v-list dense>
              <v-list-item
                v-if="view.view.properties.child_rows"
              >
                <v-list-item-title class="font-weight-bold">Parent table</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="c-pointer"
                v-for="(item, index) in downloadItems"
                :key="index"
                @click.native="downloadData(item.value)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
              <template v-if="view.view.properties.child_rows">
                <v-list-item
                >
                  <v-list-item-title class="font-weight-bold">Child table</v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="c-pointer"
                  v-for="(item, index) in downloadItems"
                  :key="index"
                  @click.native="downloadData(item.value, true)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </div>
        <v-icon
          class="mr-1 action-btn-primary"
          @click="$emit('full-screen',view,index)"
          v-if="!inModal && hasTitle"

        >fullscreen
        </v-icon>
        <data-sets-view-filter
          v-if="hasTitle"
          :view="view" @change="filterChanged" :index="index"></data-sets-view-filter>
      </div>
      <template v-else-if="view.view.errored || view.errored" >
        <div class="d-flex pa-3 pb-4 mr-3 show-on-hover ">
          <v-icon class="show-on-hover  action-btn-primary" v-if="hasTitle && !inModal"  @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" >edit</v-icon>
        </div>
      </template>
    </v-row>
    <v-row class="ma-0 pl-4">
      <v-col v-if="inModal" cols="2">
        <v-text-field
          label="Column Width"
          v-model="columnWidth"
        ></v-text-field>
      </v-col>
    </v-row>
    <data-tables
      two-rows-column
      style="width: 100%"
      :data="sortedData"
      filter-multiple
      :key="dataTableKey"
      show-select
      v-if="view.data && !view.view.errored && !view.errored && view.view.dimensions && view.view.dimensions.length"
      :filters="tableFilters"
      @sort-change="onSortChange"
      @filter-change="filters => handleFilterChange('tableFilters', filters)"
      :page-size="view.view.properties.rows || 5"
      :table-props="{
        'summary-method': getSummary,
        'show-summary': view.view.properties.total_row,
        load: load,
        defaultSort: {prop: sortProperty, order:view.view.properties.sort_order || 'descending'},
        lazy: true,
        'max-height': view.view?.properties?.no_inline_scroll ? undefined : 700,
        'row-key': tableProps,
        'tree-props': { children: 'children', hasChildren: 'hasChildren' }
      }"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
    >
      <el-table-column v-for="column in view.view.dimensions"
        :column-key="column"
        :prop="column"
        :key="column"
        :min-width="columnWidth || columnProperties[column]?.width || 150"
        :max-width="columnWidth || columnProperties[column]?.width"
        fixed
        sortable
        class-name="pl-4"
        class="data-tables-column"
        label-class-name="data-tables-column"
        :label="`${column?.split('/')[2]} (${column?.split('/')[1]})`">
        <template v-slot:header="header">
          <TableColumnHeader
            :column-key="column"
            :label="header.column.label"
          ></TableColumnHeader>
        </template>
        <template slot-scope="scope">
          <v-row class="justify-start ma-0"
            v-if="
              ((view.view.dimensions[0]===column && scope.row?.isChild && view?.view?.properties?.child_rows.includes('/key')) || (!scope.row.isChild && column.includes('/key'))) &&
                !(columnProperties || {} )[column] && 'image_url' in scope.row">
            <router-link
              style="color: inherit; text-decoration: none"
              :to="scope.row.route"
              class="d-flex special-aggregation">
              <v-img
                :src="scope.row['image_url']"
                class="mr-6"
                lazy-src="/img/placeholder-image.83cd44f1.png"
                height="48"
                width="33"
              />
              <div class="lines d-none d-md-block">
                <div class="one-line  text-no-wrap">
                  <span class="option-name  text-no-wrap">{{ scope.row.name?.split(' / ')[0] }}</span>
                </div>
                <div class="one-line">
                  <span>{{ scope.row.name?.split(' / ')[1] }}</span>
                </div>
              </div>
              <span class="d-md-none d-block">
                {{ scope.row.name?.split(' / ')[0] }}
                {{ scope.row.name?.split(' / ')[1] }}
              </span>
            </router-link>
          </v-row>
          <span v-else>
            {{ scope.row[column] }}
          </span>
        </template>
      </el-table-column>
      <el-table-column v-for="matrixColumn in matrixColumns" :key="matrixColumn" :label="matrixColumn"
        label-class-name="text-center" :prop="matrixColumn">
        <el-table-column
          :filters="columnProperties[column]?.value_filtering ? getColumnFilterValues('sortedData', `${matrixColumn}/${column}`) : null"
          sortable="custom"
          v-for="column in columns.filter((item)=> !columnProperties[item].total_only)"
          :min-width="columnWidth || columnProperties[column]?.width || 150"
          :max-width="columnWidth || columnProperties[column]?.width"
          :width="columnWidth || columnProperties[column]?.width" :key="`${matrixColumn}/${column}`"
          :label="column"
          class="data-tables-column"
          label-class-name="text-center data-tables-column"
          :column-key="`${matrixColumn}/${column}`"
          :prop="`${matrixColumn}/${column}`">
          <template v-slot:header="header">
            <TableColumnHeader
              @filter-change="filters => handleFilterChange('tableFilters', filters)"
              :column-key="`${matrixColumn}/${column}`"
              :label="header.column.label"
              :filters="columnProperties[column]?.value_filtering ? getColumnFilterValues('sortedData', `${matrixColumn}/${column}`) : null"
            ></TableColumnHeader>
          </template>

          <template slot-scope="scope">
            <div
              :style="getCellStyle(scope.row[`${matrixColumn}/${column}`], view, column)"
            >

              {{ formatter(scope.row, {property: `${matrixColumn}/${column}`}) }}
            </div>
          </template>

        </el-table-column>
      </el-table-column>
      <el-table-column
        v-if="view.view.properties.total_column"
        label="Total"
        :key="totalKey"
        prop="total"
        label-class-name="text-center"
        :min-width="columnWidth || totalColumnWidth "
        :max-width="columnWidth || totalColumnWidth"
        fixed="right">
        <el-table-column
          v-for="column in columns"
          :key="`Total/${column}`"
          sortable
          :label="column"
          label-class-name="text-center"
          :class-name="`text-center ${(view.view.properties.total_column_color || '#f5f7fa')?.replace('#', 'a')}`"
          :min-width="(columnWidth || columnProperties[column]?.width || 150)"
          :max-width="(columnWidth || columnProperties[column]?.width || 150)"
          :width="(columnWidth || columnProperties[column]?.width || 150)"
          :prop="`Total/${column}` "
          :column-key="`Total/${column}`">
          <template slot-scope="scope">
            <div
              :style="getCellStyle(scope.row[`Total/${column}`], view, column)"
            >
              {{ formatter(scope.row, {property: `Total/${column}`}) }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </data-tables>
    <h4 v-else-if="view.view.errored || view.errored" class="row justify-center pb-8">Could not retrieve data</h4>

  </v-card>

  <datasets-new-view
    v-else-if="isChangeView"
    :changeView="{...view.view, position}"
    :positions="positions"
    :datasets="datasets"
    :views-map="viewsMap"
    :dashboard-details="dashboardDetails"
    @saved-positions="$emit('saved-positions', $event)"
    :dataSetForChoose="dataSetForChoose"
    @cancel="isChangeView = false; $emit('toggle-view', false)"
    @delete-view="$emit('delete-view',$event)"
    @save="changeView">
  </datasets-new-view>
</template>

<script>
import '../../filters'
import columnFilters from '@/mixins/columnFilters'
import { downloadAnalytics, performViewData } from '@/api/analytics'
import TableSearch from '@/components/TableSearch'
import DataSetsViewFilter from './DataSetsViewFilter'
import interpolate from 'color-interpolate'
import { useAnalyticsFiltersStore } from '@/store/pinia/analyticsFiltersStore'
import TableColumnHeader from '@/components/Tables/components/TableColumnHeader.vue'

const DatasetsNewView = () => import('@/components/AutomateDatasets/DatasetsNewView')

export default {
  name: 'DataSetsMatrixTables',
  mixins: [columnFilters],
  components: {TableColumnHeader, DatasetsNewView, DataSetsViewFilter, TableSearch },
  setup () {
    return {
      analyticsFiltersStore: useAnalyticsFiltersStore()
    }
  },
  data () {
    return {
      downloadItems: [
        { title: 'CSV (comma)', value: ',' },
        { title: 'CSV (semicolon)', value: ';' },
        { title: 'CSV (semicolon, decimal comma)', value: 'dutch' }
      ],
      loadedChildren: {},
      columnWidth: null,
      isChangeView: false,
      totalKey: 0,
      expandedRowsKey: 0,
      sortedColumn: '',
      sortedOrder: '',
      dataTableKey: 0,
      minMaxValues: {},
      tableFilters: [
        {
          value: '',
        },
      ],
      downloadingLoaded: false,
    }
  },
  watch: {
    view () {
      this.totalKey++
      this.dataTableKey++
      this.setColors()
    },
    isChangeView () {
      this.setColors()
    }
  },
  computed: {
    positionTitle () {
      if(this.dashboardDetails?.view_keys[this.index]?.filter(item=> item).length === 1){
        return this.index + 1
      }
      return this.position
    },
    position () {
      return `${this.index + 1}${['A', 'B', 'C', 'D'][this.secondaryIndex]}`
    },
    totalColumnWidth () {

      return this.columns.reduce((prev, column) => prev + (this.columnProperties[column]?.width || 150), 0)
    },
    sortProperty () {

      const sort = this.view.view.properties.sort
      if (this.view.view.properties.total_column) {
        return `Total/${sort}`
      }
      if (!this.matrixColumns) {
        return ''
      }
      return this.matrixColumns[0] + '/' + sort
    },
    matrixColumns () {
      const matrixColumns = new Set()
      this.view.data?.forEach((item) => {
        matrixColumns.add(item[this.view.view.properties.matrix])
      })
      return [...matrixColumns]
    },
    getHeight () {
      if (this.sortedData.length >= 5) {
        return '50vh'
      }
      return this.sortedData.length * '80px'
    },
    sortedData () {
      return this.sortingMethods(this.view.data, this.view.view.properties.child_rows, false, this.view.total_column)
    },
    columnProperties () {
      return this.view.view.measuresProps
    },
    columns () {
      const keys = Object.keys((this.view.data || {})[0] || {})
      const hiddenColumns = ['name', 'route', 'image_url', 'hasChildren', ...this.view.view.dimensions, this.view.view.properties.matrix]
      return keys.filter(key => {
        return !hiddenColumns.includes(key)
      })
    }
  },
  methods: {
    onSortChange (props){
      if(!props.order) {
        this.sortedColumn = null
        this.sortedOrder = null
        return
      }
      this.sortedColumn = props.prop
      this.sortedOrder = props.order
    },


    sortChildren (a, b){
      return (a[this.sortedColumn] - b[this.sortedColumn]) * (this.sortedOrder === 'ascending' ? 1 : -1)
    },
    setColors () {
      this.$nextTick(() => {
        if(typeof this.$el?.querySelectorAll !== 'function'){
          return
        }
        const elements = this.$el?.querySelectorAll('.el-table__footer-wrapper tbody td, .el-table__fixed-footer-wrapper tbody td') || []
        elements.forEach(element => {
          element.classList?.add((this.view.view.properties.total_row_color || '#f5f7fa')?.replace('#', 'a'))
        })
      })
    },
    formatter (row, column) {
      if (typeof (row && row[column.property]) === 'string') {
        return row[column.property]
      }
      const splittedColumn = column.property?.split('/')
      const dataColumn = splittedColumn[1]
      if ((this.columnProperties || {})[dataColumn]?.percentage) {
        return (((row && row[column.property]) || 0) * 100).toFixed(
          this.columnProperties[dataColumn]?.decimals > 2 ? this.columnProperties[dataColumn]?.decimals - 2 : 0
        ) + '%'
      }
      if (row && typeof row[column.property] !== 'number') {
        return row[column.property]
      }
      const value = ((row && row[column.property]) || 0).toFixed(
        this.columnProperties[dataColumn]?.decimals)
      if (this.columnProperties[dataColumn]?.decimals) {
        return value
      }
      return this.$options.filters.formatThousands(parseFloat(value))
    },
    filterChanged (value) {
      this.$emit('change-view', { view: value, position: this.index })
    },
    downloadData (sep, child = false) {
      this.downloadingLoaded = true
      const downloadPayload = {
        ...this.view.view,
        csv: true,
        csv_separator: sep,
        csv_decimal: '.'
      }
      if (sep === 'dutch') {
        downloadPayload.csv_separator = ';'
        downloadPayload.csv_decimal = ','
      }
      const pageFilters = {}
      const propertiesWithDataset = this.analyticsFiltersStore.propertiesWithDataset
      const dashboardFilters = this.dashboardDetails.filters || {}
      for (const key in dashboardFilters) {
        if (propertiesWithDataset[this.view.view.dataset] && propertiesWithDataset[this.view.view.dataset][key]) {
          pageFilters[key] = dashboardFilters[key]
        }
      }
      const optionKey = 'properties/article/option_key'
      if (dashboardFilters[optionKey]) {
        pageFilters[optionKey] = dashboardFilters[optionKey]
      }
      if (this.$route.query.option_key) {
        pageFilters[optionKey] = this.$route.query.option_key
      }
      downloadPayload.filters = { ...pageFilters, ...downloadPayload.filters }
      downloadAnalytics(downloadPayload, child)
        .then(({ url }) => {
          this.downloadingLoaded = false
          const link = document.createElement('a')
          link.setAttribute('href', url)
          const today = new Date()
          const dd = today.getDate()
          const mm = today.getMonth() + 1
          const yyyy = today.getFullYear()
          link.setAttribute('download', this.view.view.view_name + '_' + String(dd) + '_' + String(mm) + '_' + String(yyyy) + '.csv')
          link.click()
        })
    },

    changeView (value) {
      this.isChangeView = false
      this.$emit('toggle-view', false)
      this.dataTableKey++
      this.$emit('change-view', value)
    },
    sortingMethods (viewData, hasChildren, forChild = false, total_column) {
      const data = {}
      const mainKeys = this.view.view.dimensions.filter((item) => item !== this.view.view.properties.matrix)
      if (forChild) {
        mainKeys.push(this.view.view.properties.child_rows)
      }
      viewData?.forEach((item) => {
        const dataItem = structuredClone(item)
        const matrixKey = dataItem[this.view.view.properties.matrix]
        const mainItem = {}
        for (const key in dataItem) {
          if(mainKeys.includes(key) || key === this.view.view.properties.matrix) {
            continue
          }
          mainItem[`${matrixKey}/${key}`] = dataItem[key]
        }
        let dataKey = ''
        mainKeys.forEach((key) => {
          dataKey += '_' + dataItem[key]
        })
        if (!data[dataKey]) {
          data[dataKey] = {}
        }
        data[dataKey] = { ...data[dataKey], ...mainItem }
        mainKeys.forEach((mainKey) => {
          data[dataKey][mainKey] = dataItem[mainKey]
          data[dataKey].route = dataItem.route
          data[dataKey].name = dataItem.name
          data[dataKey].image_url = dataItem.image_url
        })
      })
      if (this.view.view.properties.total_column) {
        total_column.forEach((item) => {
          const dataItem = structuredClone(item)

          let dataKey = ''
          mainKeys.forEach((key) => {
            dataKey += '_' + dataItem[key]
          })
          const mainItem = {}
          for (const key in dataItem) {
            mainItem[`Total/${key}`] = dataItem[key]
          }
          data[dataKey] = { ...data[dataKey], ...mainItem }
        })
      }
      return Object.keys(data).reduce((prev, curr) => {
        return [...prev, { ...data[curr], hasChildren }]
      }, [])
    },
    load (tree, treeNode, resolve) {
      this.loadViewData([this.view.view, tree]).then(({ data, total_column }) => {
        const rowKey = this.view.view.dimensions.reduce((prev, cur) => {
          return prev + tree[cur]
        }, '')
        if (this.loadedChildren[rowKey]) {
          if(this.sortedColumn){
            resolve(this.loadedChildren[rowKey].sort(this.sortChildren))
            return
          }
          resolve(this.loadedChildren[rowKey])
          return
        }
        const resolveData = this.sortingMethods(data, false, true, total_column).map((item) => {
          const child = { ...item, isChild: true }
          child[this.view.view.dimensions[0]] = child[this.view.view.properties.child_rows]
          return child
        })
        if(this.sortedColumn){
          resolveData.sort(this.sortChildren)
        }
        this.loadedChildren[rowKey] = resolveData
        resolve([...resolveData])
      })
    },
    getCellStyle (row, view, column) {
      const columnProp = this.columnProperties[column]
      if (typeof row === 'string') {
        return {
          justifyContent: 'start',
          display: 'flex'
        }
      }
      if (!columnProp.color_grading || row === undefined) {
        return {
          justifyContent: 'center',
          display: 'flex'
        }
      }
      return {
        background: this.getCellGradeColor(row, view, column),
        borderRadius: '4px',
        color: '#fff',
        padding: '13px 0',
        justifyContent: 'center',
        display: 'flex'
      }
    },
    getCellGradeColor (row, view, column) {
      const columnProp = this.columnProperties[column]
      if (typeof row !== 'number') {
        return 'full-screen'
      }
      const min = parseFloat(columnProp.min == null ? this.getMinMax(view, column).min * (columnProp.percentage ? 100 : 1) : columnProp.min)
      const max = parseFloat(columnProp.max == null ? this.getMinMax(view, column).max * (columnProp.percentage ? 100 : 1) : columnProp.max)
      if (columnProp.percentage) {
        row *= 100
      }
      const middles = {
        first: min + ((max - min) * ((columnProp.first || 25) / 100)),
        second: min + ((max - min) * ((columnProp.second || 50) / 100)),
        third: min + ((max - min) * ((columnProp.third || 75) / 100))
      }
      const keys = ['first', 'second', 'third']
      if (row <= min) {
        return columnProp.min_color_code || '#085D70'
      }
      if (row >= max) {
        return columnProp.max_color_code || '#FFAB00'
      }
      if (!columnProp.first && !columnProp.second && !columnProp.third) {
        return interpolate([columnProp.min_color_code || '#085D70', columnProp.max_color_code || '#FFAB00'])((row - min) / (max - min))
      }
      let workingKey = ''
      let minWorkingKey = ''
      for (const key of keys) {
        if (columnProp[key]) {
          if (row <= middles[key]) {
            workingKey = key
          } else {
            minWorkingKey = key
          }
        }
      }
      const maxValue = workingKey ? middles[workingKey] : max
      const minValue = minWorkingKey ? middles[minWorkingKey] : min
      const maxColor = workingKey ? columnProp[`${workingKey}_color`] : columnProp.max_color_code || '#FFAB00'
      const minColor = minWorkingKey ? columnProp[`${minWorkingKey}_color`] : columnProp.min_color_code || '#085D70'
      return interpolate([minColor, maxColor])((row - minValue) / (maxValue - minValue))

    },


    getMinMax (view, column) {
      if (!(this.minMaxValues[view.view.view_key] && this.minMaxValues[view.view.view_key][column])) {
        const minMax = {
          min: Math.min(...view.data.map((item) => item[column])),
          max: Math.max(...view.data.map((item) => item[column]))
        }
        this.minMaxValues[view.view.view_key] = { [column]: minMax }
      }
      return this.minMaxValues[view.view.view_key][column]
    },
    async loadViewData ([view, tree]) {
      try {
        const pageFilters = {}
        const propertiesWithDataset = this.analyticsFiltersStore.propertiesWithDataset
        const dashboardFilters = this.dashboardDetails.filters
        for (const key in dashboardFilters) {
          if (propertiesWithDataset[view.dataset] && propertiesWithDataset[view.dataset][key]) {
            pageFilters[key] = dashboardFilters[key]
          }
        }
        const optionKey = 'properties/article/option_key'
        if (dashboardFilters[optionKey]) {
          pageFilters[optionKey] = dashboardFilters[optionKey]
        }
        return (await performViewData([view, tree], true, pageFilters)).childViews
      } catch (e) {
        console.log(e)
        // this.views = [...this.views, { view: { ...view, errored: true }, data: [] }]
        //
        // this.erroredViews = [...this.erroredViews, { ...view }]
      }
    },

    tableProps (row) {
      // return row[this.view.view.dimensions]
      const dimensions = this.view.view.dimensions.filter(item => item !== this.view.view.properties.matrix)
      let props = ''
      dimensions.forEach(item => {
        props += row[item] || ''
      })
      return props
    },
    getSummary (params) {
      try {
        const sums = []
        const {columns} = params
        columns.forEach((column, index) => {
          if (this.view.view.dimensions.includes(column.property)) {
            sums[index] = ''
            if (index === 0) {
              sums[index] = 'Total'
            }
            return
          }

          if (column.property.split('/').length === 1) {
            let sum = 0
            this.view.total_column.forEach(item => {
              if (typeof item[column.property] === 'number') {
                sum += item[column.property]
              }
            })
            sums[index] = this.$options.filters.formatThousands(sum) || '0'
            return
          }
          const splittedColumn = column.property.split('/')
          const matrixColumn = splittedColumn.slice(0, splittedColumn.length - 1).join('/')
          const dataColumn = splittedColumn.slice(splittedColumn.length - 1).join('/')
          const data = this.view.total_row?.find(item => item[this.view.view.properties.matrix] === matrixColumn) || {}

          let value = data[dataColumn]
          if (matrixColumn === 'Total') {
            value = this.view.totals_sum[0][dataColumn]
          }
          if ((this.columnProperties || {})[dataColumn]?.percentage) {
            value = ((value || 0) * 100).toFixed(
              this.columnProperties[dataColumn]?.decimals > 2 ? this.columnProperties[dataColumn]?.decimals - 2 : 0
            ) + '%'
          } else {
            value = (value || 0).toFixed(
              this.columnProperties[dataColumn]?.decimals)
            if (!this.columnProperties[dataColumn]?.decimals) {
              value = this.$options.filters.formatThousands(parseFloat(value)) || '0'
            }
          }
          sums[index] = value
        })
        return sums
      } catch (e){
        console.log(e)
        return []
      }
    },
  },
  props: {
    dashboardDetails: {
      type: Object,
      default () {
        return {}
      }
    },
    viewsMap: {
      type: Object,
      default () {
        return {}
      }
    },
    shared: {
      type: Boolean,
      default () {
        return true
      }
    },
    hasTitle: {
      type: Boolean,
      default () {
        return true
      }
    },
    inModal: {
      type: Boolean,
      default () {
        return false
      }
    },

    index: {
      type: Number,
      default () {
        return null
      }
    },
    secondaryIndex: {
      type: Number,
      default () {
        return null
      }
    },
    positions: [],
    datasets: [],
    dataSetForChoose: [],
    view: {
      type: Object,
      default () {
        return {
          view: {},
          data: []
        }
      }
    }
  },
}
</script>

<style lang="scss">
.data-sets-matrix-tables {
  tbody {
    max-height: 60vh;
  }
  .el-table__body td, .el-table__fixed-footer-wrapper td, .el-table__footer-wrapper td {
    &.a00000099 {
      background-color: #00000099 !important;
    }
    &.a007AFF {
      background-color: #007AFF !important;
    }
    &.a4CAF50 {
      background-color: #4CAF50 !important;
    }
    &.aFF9800 {
      background-color: #FF9800 !important;
    }
    &.aFF9A9A {
      background-color: #FF9A9A !important;
    }
    &.a085D70 {
      background-color: #085D70 !important;
    }
    &.a9BE48E {
      background-color: #9BE48E !important;
    }
    &.a4FB4AB {
      background-color: #4FB4AB !important;
    }
    &.aF989D0 {
      background-color: #F989D0 !important;
    }
    &.aEE3766 {
      background-color: #EE3766 !important;
    }
    &.a765397 {
      background-color: #765397 !important;
    }
    &.aFF675B {
      background-color: #FF675B !important;
    }
    &.a73A1C7 {
      background-color: #73A1C7 !important;
    }
    &.aF5907B {
      background-color: #F5907B !important;
    }
    &.a00000033 {
      background-color: #00000033 !important;
    }
    &.a00000099 {
      background-color: #00000099 !important;
    }
    &.aFF5722 {
      background-color: #FF5722 !important;
    }
    &.aFFD202 {
      background-color: #FFD202 !important;
    }
    &.aFFFFFF {
      background-color: #FFFFFF !important;
    }
    &.af5f7fa {
      background-color: #f5f7fa !important;
    }
  }

  .el-table td {
    padding: 0;
  }

  .el-table--border td:first-child .cell, .el-table--border th:first-child .cell {
    padding: 0;
  }

  .el-table .cell {
    padding: 0;
  }
}
</style>

<style scoped>
</style>
