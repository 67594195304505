<template>
  <div class="buy-manage-suppliers-schedules mb-6">
    <buy-manage-schedules
      :costs="costs"
      :schedules="schedules"
      @schedule-added="$emit('schedule-added')"
      @schedule-deleted="$emit('schedule-deleted')"
    >
    </buy-manage-schedules>
    <buy-manage-suppliers
      :costs="costs"
      :schedules="schedules"
      :payments="payments"
    ></buy-manage-suppliers>
  </div>
</template>

<script>
import BuyManageSuppliers from './BuyManageSuppliers'
import BuyManageSchedules from './BuyManageSchedules'
export default {
  name: 'BuyManageSuppliersPayments',
  components: { BuyManageSchedules, BuyManageSuppliers },
  props: ['costs', 'schedules', 'payments']
}
</script>

<style scoped>
.buy-manage-suppliers-schedules {
  display: flex;
  flex-direction: column;
}
</style>
