<template>

  <v-card class="metrics-calculation ma-0 ma-0 pa-0">
    <v-card-title class="text-body-1 font-weight-medium py-6 justify-space-between px-12">
      Edit measure
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text--primary px-12 py-6" >
      <v-card class="px-4 py-6 " height="260" outlined :class="{'error-validation': !validates}">
        <v-row class="mx-0">
          <v-col
            cols="1"
            class="mr-2 mb-2 pa-0 number"
            :class="item.type+ (activeIndex === index ? ' active': '')"
            @click="activeIndex = index"
            v-for="(item, index) in result"
            :key="index"
          >{{item.value}}</v-col>
        </v-row>
      </v-card>
      <h3 class="py-6">Input</h3>
      <v-row class="ma-0">
        <v-col cols="3">
          <v-row class="pa-0" >
            <v-col cols="1" class="mr-2 mb-2 pa-0 number"
              v-for="(item, index) in new Array(9)"
              :key="index"
              @click="addToMetrics(index+1, 'number')"
            >{{index+1}}</v-col>
            <v-col cols="2" class="mr-2 mb-2 pa-0 number zero" @click="addToMetrics(0, 'number')">0</v-col>
            <v-col
              cols="1"
              class="mr-2 mb-2 pa-0 number"
              @click="addToMetrics('.', 'number')"
              :class="{disabled: dotDisabled}"
            >.</v-col>
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-row class="pa-0">
            <v-col
              cols="1"
              v-for="item in actions"
              :key="item"
              class="mr-2 mb-2 pa-0 number action"
              @click="addToMetrics(item, 'action')"
              :class="{disabled: lastChoice === 'action' ||
                !result.length
                || result[result.length-1].value === '('}"
            >{{ item }}</v-col>
          </v-row>
        </v-col>
        <v-col cols="1">
          <v-row class="pa-0">
            <v-col cols="1"
              class="mr-2 mb-2 pa-0 number brackets"
              @click="addToMetrics('(', 'brackets')"
              :class="{disabled: bracketsCount <0}"

            >(</v-col>
            <v-col cols="2" class="mr-2 mb-2 pa-0 number brackets"
              @click="addToMetrics(')', 'brackets')"
              :class="{disabled: bracketsCount <=0}"
            >)</v-col>
          </v-row>
        </v-col>
        <v-col cols="3" class="pa-0 d-flex align-baseline">
          <v-autocomplete
            outlined
            ref="autocomplete"
            class="metric"
            :disabled="lastChoice === 'metric_type'"
            :class="{disabled: lastChoice === 'metric_type'}"
            hide-details
            label="Metric"
            v-model="lastChoice"
            color="#FF9800"
            :items="metrics"
            @change="addToMetrics"
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row class="dialog-footer text-right py-2 px-4">
        <v-col>
          <v-btn
            color="primary"
            depressed
            text
            @click="$emit('close')"
          >Cancel</v-btn>
          <v-btn
            color="primary"
            depressed
            @click="checkValidation"
          >Save</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'EditMeasure',
  data () {
    return {
      result: [],
      lastChoice: '',
      bracketsCount: 0,
      validates: true,
      activeIndex: -1,
      actions: ['-', '+', '/', '*', '^']
    }
  },
  props: {
    metrics: [],
    value: [],
  },
  computed: {
    dotDisabled () {
      return this.checkDots(this.result)
    },
  },
  methods: {
    checkDots (result) {
      if (!result.length ||
        result[result.length - 1].value === '.' ||
        result[result.length - 1].type !== 'number') {
        return true
      }
      for (let i = result.length - 1; i >= 0; i--) {
        if (result[i].value === '.') {
          return true
        }
        if (result[i].type !== 'number') {
          return false
        }
      }
      return false
    },
    checkDotValidations () {
      for (let i = this.result.length; i > 0; i--) {
        if (this.checkDots(this.result.slice(0, i - 1)) && this.result[i - 1].value === '.') {
          return false
        }
      }
      return true
    },
    checkActions () {
      if (this.result.length === 1) {
        return this.result[0].type === 'metric_type'
      }
      if (this.result[this.result.length - 1].type === 'action') {
        return false
      }
      const actions = ['action', 'metric_type']
      for (let i = this.result.length - 2; i >= 0; i--) {
        const currentType = this.result[i].type
        if (actions.includes(currentType) && currentType === this.result[i + 1].type) {
          return false
        }
      }
      return true
    },
    checkValidation () {
      this.validates = this.bracketsCount === 0 && this.checkDotValidations() && this.checkActions()
      if (this.validates) {
        this.saveParsedData()
      }
    },
    saveParsedData () {
      const resultList = [...this.result]
      for (let i = 0; i < this.result.length; i++) {
        if (this.result[i].type === 'number') {
          let result = ''
          while (i < this.result.length && this.result[i].type === 'number') {
            result += this.result[i].value
            this.result[i].needToDelete = true
            i++
          }
          this.result[i - 1].value = Number(result)
          delete this.result[i - 1].needToDelete
        }
      }
      this.result = this.result.filter(item => !item.needToDelete)
      let resolved = JSON.stringify(this.result.map(item => item.value))
      resolved = resolved.replaceAll('"(",', '[')
      resolved = resolved.replaceAll(',")"', ']')
      resolved = resolved.replaceAll('"("', '[')
      resolved = resolved.replaceAll('")"', ']')
      this.result = resultList
      this.$emit('value-set', JSON.parse(resolved))
      this.$emit('close')
    },
    parseResult (result) {
      if (result.length <= 1) {
        return result
      }
      const resultWithValues = result.map((item) => item.value)
      const firstIndex = resultWithValues.indexOf('(')
      const lastIndex = resultWithValues.lastIndexOf(')')
      return result.slice(firstIndex, lastIndex + 1)
    },
    addToMetrics (value, type) {
      if (value === '(') {
        this.bracketsCount++
      } else if (value === ')') {
        this.bracketsCount--
      }
      if (!type) {
        type = 'metric_type'
      }
      this.lastChoice = type
      this.result.splice(this.activeIndex + 1, 0, { value, type })
      this.result = [...this.result]
      this.activeIndex++
    },
    removeFromMetrics () {
      const [metric] = this.result.splice(this.activeIndex, 1)
      if (!metric.value) {
        return
      }
      if (metric.value === '(') {
        this.bracketsCount--
      } else if (metric.value === ')') {
        this.bracketsCount++
      }
      this.result = [...this.result]
      this.lastChoice = (this.result[this.result.length - 1] || {}).type || ''
      this.activeIndex--
    },
    onKeyDown (event) {
      if (this.actions.includes(event.key)) {
        this.addToMetrics(event.key, 'action')
        return
      }
      if (event.key === '(' || event.key === ')') {
        this.addToMetrics(event.key, 'brackets')
      }
      if (event.code.includes('Digit') && event.key <= '9' && event.key >= '0') {
        this.addToMetrics(event.key, 'number')
      }
      if (event.code === 'ArrowLeft') {
        this.activeIndex--
      }
      if (event.code === 'ArrowRight') {
        this.activeIndex++
      }
      if (event.code === 'Backspace') {
        this.removeFromMetrics()
      }
    },
    parseValue (value) {
      value.forEach((item) => {
        if (this.actions.indexOf(item) !== -1) {
          this.result.push({ type: 'action', value: item })
        } else if (typeof item === 'number' || item === '.') {
          const str = item.toString()
          for (let i = 0; i < str.length; i++) {
            this.result.push({ type: 'number', value: str[i] })
          }
        } else if (typeof item === 'string') {
          this.result.push({ type: 'metric_type', value: item })
        } else {
          this.parseValue(item)
        }
      })

    }
  },
  mounted () {
    document.addEventListener('keydown', this.onKeyDown)
    if(this.value) {
      this.parseValue(this.value)
    }
    this.activeIndex = this.result.length - 1
  }
}
</script>

<style scoped>
.number {
  font-size: 16px;
  min-width: 28px;
  max-width: 28px;
  min-height: 28px;
  max-height: 28px;
  border-radius: 8px;
  border: 1px solid #979797;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 500;
  cursor: pointer;
}

.zero {
  max-width: 64px;
  min-width: 64px;
}

.action {
  border: 1px solid #979797;
  border-radius: 8px;
  background-color: #D8D8D8;
}

.brackets {
  border: 1px solid #4CAF50;
  color: #4CAF50;
}

.metric {
  color: #FF9800;
  border-radius: 8px;
  font-weight: 500;
}

.active {
  background-color: rgba(76, 195, 80, 0.4);
}

.metric_type {
  max-width: unset;
  min-width: unset;
  padding: 0 12px !important;
  border: 1px solid #FF9800;
  color: #FF9800;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

</style>

<style lang="scss">
.metrics-calculation {
  .error-validation.theme--light.v-sheet--outlined {
    border: thin solid red;
  }
}
.metric {
  .v-input__control {
    .theme--light.v-icon, input, fieldset, label {
      color: #FF9800;
    }
    fieldset {
      border-color: #FF9800;
    }
  }
}
</style>
