<template>
  <div class="stockHomeCarousel">
    <v-card class="card pa-4 p-relative" min-height="350px">
      <v-row justify="space-between" class="pa-3 pb-7">
        <h4 class="mb-2 text-center">Predictions</h4>
        <DataExport
          v-show="!chartIndex"
          @item-click="downloadChartData"
        />
      </v-row>

      <v-overlay absolute color="white" :value="!allChartsLoaded">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>

      <v-carousel
        :hide-delimiters="!allChartsLoaded"
        height="420px"
        delimiter-icon="circle"
        :perPage="1"
        light
        class="pb-5"
        hide-delimiter-background
        :show-arrows="false"
        @change="(value)=> chartIndex = value">
        <v-carousel-item eager>
          <h4 class="mb-2 text-center">Trend</h4>
          <StockTrendGraph ref="graph0" @load="componentsLoaded.chart1 = true"></StockTrendGraph>
        </v-carousel-item>
        <v-carousel-item eager>
          <StockSalesL5WGraph
            ref="graph1"
            :chosen-dimension="chosenDimension"
            @load="componentsLoaded.chart2 = true">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              class="mt-0 "
              :filter="headersFilters"
              :items="dimensions"
              v-model="chosenDimension"
              hide-details
            />
          </StockSalesL5WGraph>
        </v-carousel-item>
        <v-carousel-item eager>
          <StockSalesN3WGraph
            ref="graph2"
            :chosen-dimension="chosenDimension"
            @load="componentsLoaded.chart3 = true">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              class="mt-0 "
              :filter="headersFilters"
              :items="dimensions"
              v-model="chosenDimension"
              hide-details
            />
          </StockSalesN3WGraph>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script>
import { headersFilters } from '@/utils'
import DataExport from '../DataExport'
import { formatString } from '@/variables'
import StockTrendGraph from '@/components/Home/StockTrendGraph'
import StockSalesL5WGraph from './StockSalesL5WGraph'
import StockSalesN3WGraph from './StockSalesN3WGraph'
import { getDatasets } from '@/api/analytics'
import { useAnalyticsFiltersStore } from '@/store/pinia/analyticsFiltersStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore(),
      analyticsFiltersStore: useAnalyticsFiltersStore()
    }
  },
  data () {
    return {
      chartIndex: 0,
      chosenDimension: 'properties/article/group',
      componentsLoaded: {
        chart1: false,
        chart2: false,
        chart3: false,
      },
    }
  },
  computed: {
    datasets () {
      return this.analyticsFiltersStore.datasetsList
    },
    dimensions () {
      const dimensions = {}
      if (!this.datasets['sales_statistics']) {
        return []
      }
      const dimensionsList = this.datasets['sales_statistics'].dimensions
      dimensionsList.forEach((item) => {
        const data = item.split('/')
        const header = { header: formatString(data[1] || ''), text: [] }
        if (!dimensions[data[1]]) {
          dimensions[data[1]] = [header]
        }
        const text = data[2] === 'name' ? `Name (${formatString(data[1])})` : formatString(data[2] || '')
        dimensions[data[1]].push({ text, value: item, headerTitle: header.header })
        dimensions[data[1]][0].text.push(formatString(data[2] || ''), formatString(data[1] || ''))
      })
      const data = []
      for (const key in dimensions) {
        data.push(...dimensions[key])
      }
      return data
    },
    params () {
      const pageFiltersValues = this.filtersStore.getPageFiltersNonDefaultValues()
      return {
        collections: pageFiltersValues.collections || [],
        groups: pageFiltersValues.groups || [],
        stores: pageFiltersValues.stores || [],
        format: 'doa',
      }
    },
    allChartsLoaded () {
      return this.componentsLoaded.chart1 && this.componentsLoaded.chart2 && this.componentsLoaded.chart3
    }
  },
  watch: {
    'filtersStore': function (newValue) {
      const filters = JSON.stringify(newValue)
      if (localStorage.getItem('stockFilters') === filters) {
        return
      }
      localStorage.setItem('stockFilters', filters)
    }
  },
  methods: {
    headersFilters,
    downloadChartData (sep) {
      this.$refs[`graph${this.chartIndex}`].$refs.chart.downloadData(sep)
    },
  },
  components: {
    StockSalesL5WGraph,
    StockSalesN3WGraph,
    StockTrendGraph,
    DataExport,
  },

  created () {
    if (Object.keys(this.analyticsFiltersStore.datasetsList).length) {
      getDatasets().then((data) => {
        this.analyticsFiltersStore.setDatasetsList(data)
      })
    }
  },
}
</script>

<style lang="scss">
.stockHomeCarousel {
  .VueCarousel-pagination {
    .VueCarousel-dot-container {
      margin: 0 !important;
      .VueCarousel-dot {
        margin-top: 10px !important;
      }
    }
  }
}
</style>
