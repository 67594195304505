<template>
  <v-card class="jobs-settings pb-0 mb-12" flat min-height="400px">
    <v-divider></v-divider>
    <h4 class="pa-0 card-title text-left pa-6">Combinations</h4>
    <v-row>
      <v-col class="text-subtitle-1 font-weight-medium mb-4 ml-6">{{ `${selectedRows.length} line${selectedRows.length !== 1 ? 's' : ''} selected` }}</v-col>
      <div class="col text-right pr-6">
        <table-search
          class="mx-2"
          @searchChanged="filters => handleSearchChanged('tableFilters',Object.keys(combinations[0]), filters)"
        />
        <v-icon
          class="mx-2 action-btn-primary"
          :disabled="selectedRows.length !== 1"
          @click="combinationsModalOpen= true; isEdit =true"
        >edit</v-icon>
        <v-icon
          class="mx-2 action-btn-primary"
          @click="combinationsModalOpen= true; isEdit =false"
        >add</v-icon>
        <v-menu offset-y nudge-bottom="5" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"
            >
              <v-icon v-bind="attrs" v-on="on" class="mx-0">download</v-icon>
            </v-btn>
          </template>
          <v-list dense class="p-relative">
            <v-list-item-group>
              <v-list-item @click.native="loadDownloadData(',')">
                <v-list-item-title>CSV (comma)</v-list-item-title>
              </v-list-item>
              <v-list-item @click.native="loadDownloadData(';')">
                <v-list-item-title>CSV (semicolon)</v-list-item-title>
              </v-list-item>
              <v-list-item @click.native="loadDownloadData('dutch')">
                <v-list-item-title>CSV (semicolon, decimal comma)</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-icon
          class="mx-2 my-3 action-btn-danger"
          :disabled="!selectedRows.length"
          @click="deleteCombinationsModal = true"
        >delete</v-icon>
      </div>
    </v-row>
    <data-tables
      style="width: 100%"
      v-if="combinations.length"
      :data="combinations"
      :page-size="10"
      :table-props="{
        'row-key': (row)=> row.store_key + row.to_stores.join('')
      }"
      :key="tableKey"
      @filtered-data="filteredData = $event"
      @row-click="(row)=>handleSelectRowChange(!row.selected, row)"
      :filters="tableFilters"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
      filter-multiple
      @filter-change="filters => handleFilterChange('tableFilters', filters)"
    >
      <el-table-column prop="option_key" width="55">
        <template v-slot:header>
          <v-simple-checkbox
            color="primary"
            :value="selectedRows.length === filteredData.length"
            :ripple="false"
            :indeterminate="!!(selectedRows.length && selectedRows.length < filteredData.length)"
            dense
            hide-details
            class="ml-3"
            @input="handleSelectAllChange"
          />
        </template>
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="ml-3"
            :value="scope.row.selected"
            :ripple="false"
            @input="handleSelectRowChange($event, scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-for="(col) in Object.keys(combinations[0]).filter((item)=> item !== 'selected')"
        :key="col"
        :prop="col"
        :width="col=== 'to_stores' ? 248 : 'auto'"
        :label="formatString(col)"
        :sortable="true"
        :column-key="col"
        :filters="getColumnFilterValues('combinations', col)"
      >
        <template slot-scope="scope">
          <span class="d-flex justify-space-between">
            <v-checkbox
              v-if="typeof scope.row[col] === 'boolean'"
              v-model="scope.row[col]"
              disabled
              dense
            >
            </v-checkbox>
            <ChipsDropdown
              v-else-if="typeof scope.row[col] === 'object'"
              :items="scope.row[col].map(sk => getStoreName(sk))" />
            <span v-else-if="col === 'store_key'">
              {{getStoreName(scope.row[col])}}
            </span>
            <span v-else>
              {{scope.row[col]}}
            </span>
          </span>
        </template>
      </el-table-column>
    </data-tables>
    <v-dialog
      v-model="combinationsModalOpen"
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <transfers-combinations-modal
        v-if="combinationsModalOpen"
        @close="combinationsModalOpen = false"
        @save="loadData"
        :outer-values="isEdit ? {...selectedRows[0], from_stores: [selectedRows[0].store_key]} : null"
      ></transfers-combinations-modal>
    </v-dialog>
    <v-dialog
      :value="deleteCombinationsModal"
      persistent
      content-class="align-self-start mt-12"
      width="600px"
      max-width="90%">
      <v-card class="p-relative rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Delete Combinations
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">
            Are you sure you want to delete
            {{selectedRows.length}} Combination{{selectedRows.length!== 1 ? 's': ''}}?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteCombinationsModal = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                class="red--text mx-4"
                :loading="deletingInProgress"
                @click="deleteCombinations"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <import-combinations v-if="displayImport"/>
  </v-card>
</template>

<script>
import TableSearch from '../components/TableSearch'
import { formatString } from '@/variables'
import ChipsDropdown from '../components/ChipsDropdown'
import columnFilters from '@/mixins/columnFilters'
import { deleteCombinations, getCombinations, getCombinationsUpload } from '@/api/combinations'
import _ from 'lodash'
import TransfersCombinationsModal from '../components/TransfersCombinationsModal'
import { downloadData } from '@/utils'
import ImportCombinations from '../ImportCombinations'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'TransfersCombinations',
  props: {
    displayImport: {
      type: Boolean,
      default: true
    }
  },
  mixins: [columnFilters],
  components: { ImportCombinations, TransfersCombinationsModal, ChipsDropdown, TableSearch },
  data () {
    return {
      combinations: [],
      filteredData: [],
      tableFilters: [
        {
          value: ''
        }
      ],
      tableKey: 0,
      combinationsModalOpen: false,
      deleteCombinationsModal: false,
      isEdit: false,
      deletingInProgress: false,
    }
  },
  methods: {
    formatString (col) {
      if (col === 'store_key') {
        return 'Store'
      }
      return formatString(col)
    },
    handleSelectAllChange (value) {
      this.filteredData.forEach((item) => {
        this.$set(item, 'selected', value)
      })
    },
    async loadDownloadData (sep) {
      if (!this.combinationsForUpload) {
        this.combinationsForUpload = await getCombinationsUpload()
      }
      downloadData(sep, this.combinationsForUpload, 'combinations')
    },
    handleSelectRowChange (event, row) {
      this.$set(row, 'selected', event)
    },
    async deleteCombinations () {
      this.deletingInProgress = true
      for (const combination of this.selectedRows) {
        await deleteCombinations(combination)
      }
      this.deletingInProgress = false
      this.deleteCombinationsModal = false
      this.loadData()
    },
    getColumnFilterValues (listProp, col) {
      let values = _.flatten(this.combinations).map(x => x[col])
      const childValues = []
      values.forEach((item) => {
        if (item.length) {
          childValues.push(...item)
        }
      })
      values = childValues.length ? childValues : values
      return _.uniq(values).filter((item) => item !== null).map(v => {
        return ({
          text: v,
          value: v,
        })
      })
    },
    getStoreName (key) {
      const store = this.findStoreWithKey(key)

      return store ? store.store_name : null
    },
    loadData () {
      getCombinations(this.optionKey).then((r) => {
        this.combinations = r
      })
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['findStoreWithKey']),
    selectedRows () {
      return this.combinations.filter((item) => item.selected)
    }
  },
  created () {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
