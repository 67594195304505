<template>
  <div id="strategy-anomalies" class="p-relative pt-0 mb-12">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="align-baseline mt-3 mb-3">
      <v-col cols="3" class="py-0">
        <h3>Anomalies</h3>
      </v-col>
      <v-spacer />
      <v-col md="2" cols="4" class="py-0">
        <StrategyCubeSelect
          ref="cubeSelect"
          @cube-inputs-loaded="handleCubeInputsLoaded"
        />
      </v-col>
    </v-row>

    <v-card class="pt-4" min-height="100vh">
      <v-card-text class="pa-0">
        <div class="px-6">
          <template v-if="filtersDimensions.length">
            <v-row class="align-center">
              <v-col cols="11" class="pb-0">
                <StrategyFilters
                  :dimensions="filtersDimensions"
                  weeks-in-past
                  @filters-loaded="handleFiltersLoaded"
                  @filters-changed="handleFiltersChanged"
                />
              </v-col>
              <v-col cols="1" class="pb-0 pt-0 text-right">
                <v-menu
                  :close-on-content-click="false"
                  attach="#strategy-anomalies"
                  offset-y
                  left
                  min-width="450"
                  max-width="450"
                  nudge-bottom="20"
                  content-class="settings-menu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on">tune</v-icon>
                  </template>
                  <v-card>
                    <h4 class="ml-4 pt-6">
                      Settings
                    </h4>
                    <div class="input-parameter">
                      <div class="parameter-name">Metric</div>
                      <div class="parameter-value">
                        <v-select
                          :multiple="false"
                          v-model="selectedMetric"
                          :items="metrics"
                          class="text-body-2"
                          hide-details
                          @change="handleMetricChange"
                        />
                      </div>
                    </div>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
          </template>
          <v-row class="mt-5 mb-8">
            <v-col>
              <h4 class="my-2 mb-6">KPIs - R2 scores</h4>
              <StrategyR2KPITiles :kpi-items="kpiItems" />
            </v-col>
          </v-row>
        </div>
        <template v-if="r2Scores && anomalyData">
          <v-tabs
            v-model="activeTab"
            grow
            show-arrows
          >
            <v-tabs-slider />
            <v-tab
              v-for="dimension in Object.keys(r2Scores)"
              :key="'tab-' + dimension"
            >{{ getTabTitle(dimension) }} [{{ getTabR2Score(dimension) }}]</v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="activeTab"
          >
            <v-tab-item
              v-for="dimension in Object.keys(r2Scores)" :key="'tab-item-' + dimension"
              :transition="false" :reverse-transition="false"
            >
              <StrategyAnomaliesTable
                :anomalies="anomalyData[dimension].table"
                :weeks="anomalyData[dimension].week_numbers"
                :metricFormat="metricFormat"
                class="mt-4"
              />
            </v-tab-item>
          </v-tabs-items>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { formatString } from '@/variables'
import { getCubeHistoricAnomaly } from '@/api/strategy'
import StrategyCubeSelect from '@/components/Strategy/StrategyCubeSelect'
import StrategyFilters from '@/components/Strategy/StrategyFilters'
import StrategyAnomaliesTable from '@/components/Strategy/StrategyAnomaliesTable'
import StrategyR2KPITiles from '@/components/Strategy/StrategyR2KPITiles'
import { useCubesStore } from '@/store/pinia/cubesStore'

export default {
  name: 'StrategyAnomaliesPage',
  components: { StrategyCubeSelect, StrategyFilters, StrategyAnomaliesTable, StrategyR2KPITiles },
  setup () {
    return {
      cubesStore: useCubesStore(),
    }
  },
  data () {
    return {
      loading: true,
      filtersDimensions: [],
      filtersLoaded: false,
      metrics: [],
      metricFormat: null,
      selectedDimension: 'store_keys',
      selectedMetric: 'revenue',
      filtersValues: {},
      anomalyData: null,
      r2Scores: null,
      activeTab: 0,
      kpis: null,
    }
  },
  computed: {
    kpiItems () {
      if (!this.kpis) {
        return []
      }

      return Object
        .keys(this.kpis)
        .map(key => {
          return {
            ...this.kpis[key],
            title: formatString(key),
          }
        })
    },
  },
  methods: {
    async handleCubeInputsLoaded ({ dimensions }) {
      this.filtersDimensions = dimensions
      this.loading = false

      const { metrics, metric_format } = this.cubesStore.cubesBrowser
      this.metrics = metrics
      this.metricFormat = metric_format[this.selectedMetric]

      if (this.filtersLoaded) {
        this.loading = true

        try {
          await this.loadAnomaly()
        } finally {
          this.loading = false
        }
      }
    },
    async handleMetricChange () {
      this.loading = true
      try {
        await this.loadAnomaly()
      } finally {
        this.loading = false
      }
    },
    async handleDimensionChange () {
      this.loading = true
      try {
        await this.loadAnomaly()
      } finally {
        this.loading = false
      }
    },
    async handleFiltersLoaded (values) {
      this.filtersLoaded = true
      this.filtersValues = values

      if (this.filtersDimensions.length) {
        this.loading = true
        try {
          await this.loadAnomaly()
        } finally {
          this.loading = false
        }
      }
    },
    async handleFiltersChanged (values) {
      this.filtersValues = values

      if (this.metricFormat) {
        this.loading = true
        try {
          await this.loadAnomaly()
        } finally {
          this.loading = false
        }
      }
    },
    async loadAnomaly () {
      const payload = {
        cube_key: this.$refs.cubeSelect.currentCube,
        version_key: this.$refs.cubeSelect.currentVersion,
        metric: this.selectedMetric,
        filters: this.filtersValues,
      }
      const { anomaly_data, kpis, r2_scores } = await getCubeHistoricAnomaly(payload)

      this.anomalyData = Object.freeze(anomaly_data)
      this.r2Scores = Object.freeze(r2_scores)
      this.kpis = Object.freeze(kpis)
    },
    getTabTitle (val) {
      const parts = val.split('/')

      return parts.pop()
    },
    getTabR2Score (val) {
      return this.r2Scores[val]
    },
  }
}
</script>

<style lang="scss">
#strategy-anomalies {
    .settings-menu {
        .input-parameter {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            border-bottom: 1px solid var(--border-color);
            padding: 0 16px;
            .parameter-name {
                width: 200px;
            }
            .parameter-value {
                width: 250px;
                padding: 8px 0;
            }
            .slider {
                padding-top: 4px;
                .slider-value-from,
                .slider-value-to {
                    color: #666;
                    font-size: 14px;
                }
            }
            &:last-child {
                padding-bottom: 8px;
            }
        }
    }
}
</style>
