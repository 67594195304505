<template>
  <v-row>
    <anchor-points/>
    <order-points/>
  </v-row>
</template>

<script>
import AnchorPoints from './AnchorPoints'
import OrderPoints from './OrderPoints'

export default {
  name: 'BuySettingsGeneral',
  components: { OrderPoints, AnchorPoints },
}
</script>

<style lang="scss">
.buy-settings-general {
    .v-text-field {
        .v-label {
            font-size: 13px;
        }
    }
    .plus-icon {
        font-size: 20px;
    }
}
</style>
