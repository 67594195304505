// src/stores/store.ts
import {defineStore} from 'pinia'
import {createStore, deleteStore, updateStores} from '@/api/storeAPI'
import {Store} from '@/models/Store'

export const useStoresStore = defineStore('stores', {
  state: () => ({
    savingStore: false,
    deletingStore: false
  }),
  actions: {
    async saveStore (store: Store) {
      this.savingStore = true
      try {
        if (store.store_key) {
          await updateStores(store)
        } else {
          store.store_key = store.newStoreKey
          delete store.newStoreKey
          await createStore(store)
        }
      } finally {
        this.savingStore = false
      }
    },
    async updateStores (stores: Store[]) {
      this.savingStore = true
      try {
        await updateStores(stores)
      } finally {
        this.savingStore = false
      }
    },
    async deleteStore (store: Store){
      this.deletingStore = true
      try {
        await deleteStore(store)
      } finally {
        this.deletingStore = false
      }
    }
  }
})
