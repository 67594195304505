<template>
  <v-dialog
    v-model="isOpen"
    scrollable
    persistent
    content-class="align-self-start mt-14"
    width="1200px"
    max-width="90%"
  >
    <v-card>
      <v-card-title class="text-body-1 font-weight-medium mx-6 my-2"> Create Merge </v-card-title>
      <v-divider/>
      <v-card-text class="text--primary pa-0">
        <div class="text-body-1 font-weight-medium ma-6 pl-6">Steps</div>
        <Stepper
          :steps="['Shop Items', 'Impact', 'Merge']"
          :current-step="step"
          class="mx-0 my-3"
        />
        <div>
          <div v-if="step === 0">
            <scenario-merge-shop-items
              ref="shopItems"
              :budget-key="localBudget.budget_key"
              :scenarios="scenarios"
              :store-sets="storeSets"
              :shopItems="shopItems"
              :initialFrom="scenarioFrom.scenario_key"
              :initialTo="scenarioTo.scenario_key"
              :initialSelectedItems="selectedItems"
              @selectedItemsUpdated="setSelectedItems"
              @scenarioFromToChanged="setScenarioFromTo"
            />
          </div>
          <div v-else-if="step === 1">
            <scenario-merge-impact
              :items="mergeItems"
              :store-sets="storeSets"
              :budget-key="localBudget.budget_key"
              :scenario-key="scenarioFrom.scenario_key"
            />
          </div>
          <div v-else-if="step === 2">
            <scenario-merge-confirm
              :budget="localBudget"
              :items="mergeItems"
              :from-scenario="scenarioFrom"
              :to-scenario="scenarioTo"
            />
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2">
          <v-col>
            <v-btn
              v-if="step > 0"
              color="primary"
              depressed
              outlined
              @click="handlePrevious"
              class="mx-2"
            >Previous</v-btn>
            <v-btn
              class="mx-2"
              color="primary"
              depressed
              text
              @click="handleCancel"
            >Cancel</v-btn>
            <v-btn
              color="primary"
              depressed
              outlined
              @click="handleNext"
              class="mx-2"
              :disabled="!canGoNext"
              :loading="loading"
            >{{step === 2 ? 'Merge' : 'Next'}}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import ScenarioMergeShopItems from '@/components/BuyManageBudgets/ScenarioMergeShopItems'
import ScenarioMergeImpact from '@/components/BuyManageBudgets/ScenarioMergeImpact'
import ScenarioMergeConfirm from '@/components/BuyManageBudgets/ScenarioMergeConfirm'
import { loadScenarios, mergeScenarios } from '@/api/budgetAPI'
import { getStoreSets } from '@/api/storeSets'
import { getShopItems } from '@/api/shop'
import Stepper from '@/components/Shop/Stepper'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'ScenarioMerge',
  components: { Stepper, ScenarioMergeShopItems, ScenarioMergeImpact, ScenarioMergeConfirm },
  data () {
    return {
      loading: false,
      step: 0,
      localBudget: { budget_key: 0 },
      scenarios: [],
      scenarioFrom: { scenario_key: 0 },
      scenarioTo: { scenario_key: 0 },
      shopItems: [],
      selectedItems: [],
      mergeItems: [],
      storeSets: [],
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    budget: {
      type: Object,
      default: () => ({})
    }
  },
  created () {
    this.localBudget = this.budget
    const storesLabel = useGeneralStore().currentStoresSetsLabel
    getStoreSets(`?label=${storesLabel}`).then(response => {
      this.storeSets = response.stores_sets
    })
    this.loadScenarios().then(() => {
      this.step = 0
    })
  },
  computed: {
    canGoNext () {
      switch (this.step) {
      case 0:
        return this.selectedItems.length > 0 && this.scenarioFrom.scenario_key !== this.scenarioTo.scenario_key &&
          this.scenarioFrom.scenario_key !== 0 && this.scenarioTo.scenario_key !== 0
      case 1:
        return true
      case 2:
        return true
      default:
        return false
      }
    }
  },
  watch: {
    budget: {
      handler (budget) {
        if (budget.budget_key !== this.localBudget.budget_key) {
          this.localBudget = budget
          this.loadScenarios()
          this.step = 0
        }
      },
      deep: true
    },
  },
  methods: {
    async mergeScenarios (dryRun = true) {
      this.loading = true
      const items = {}
      const merges = await mergeScenarios({
        budget_key: this.localBudget.budget_key,
        from_scenario_key: this.scenarioFrom.scenario_key,
        to_scenario_key: this.scenarioTo.scenario_key,
        shop_item_keys: this.selectedItems.map(item => item.shop_item_key)
      }, dryRun)
      // Get all items key value pairs
      this.shopItems.forEach(item => {
        items[item.shop_item_key] = item
      })
      // Merge items
      this.mergeItems = (merges || []).map(item => {
        return { ...items[item.shop_item_key], ...item }
      })
      this.loading = false
    },
    handleCancel () {
      this.step = 0
      if (this.$refs.shopItems) {
        this.$refs.shopItems.scenarioTo = 0
        this.$refs.shopItems.scenarioFrom = 0
      }
      this.scenarioFrom = { scenario_key: 0 }
      this.scenarioTo = { scenario_key: 0 }
      this.setScenarioFromTo(0, 0)
      this.$emit('close')
    },
    async handleNext () {
      switch (this.step) {
      case 0:
        await this.mergeScenarios()
        break
      case 2:
        await this.mergeScenarios(false)
        this.$emit('merged')
        this.$emit('close')
        this.step = 0
        return
      }
      this.step++
    },
    handlePrevious () {
      this.step--
    },
    async budgetChanged (budget) {
      this.$emit('budget-changed', budget.budget_key)
      this.localBudget = budget
      await this.loadScenarios()
    },
    async loadScenarios () {
      this.scenarios = await loadScenarios(this.localBudget.budget_key)
    },
    setSelectedItems (selectedItems) {
      this.selectedItems = selectedItems
    },
    async setScenarioFromTo (scenarioFrom, scenarioTo) {
      this.selectedItems = []
      if (!scenarioFrom || !scenarioTo) {
        this.shopItems = []
        return
      }
      this.scenarioFrom = scenarioFrom
      this.scenarioTo = scenarioTo
      this.$refs.shopItems.setLoading(true)
      const first = await getShopItems({
        budget_key: this.localBudget.budget_key,
        scenario_key: this.scenarioFrom.scenario_key,
      })
      const second = await getShopItems({
        budget_key: this.localBudget.budget_key,
        scenario_key: this.scenarioTo.scenario_key,
      })
      const items = {}
      first.forEach(item => {
        items[item.shop_item_key] = item
      })
      second.forEach(item => {
        items[item.shop_item_key] = item
      })
      this.shopItems = Object.values(items)
      this.$refs.shopItems.setLoading(false)
    },
  }
}
</script>

<style scoped>
</style>
