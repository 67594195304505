<template>
  <v-col v-if="anchorPoints" class="anchor-points" cols="12">
    <v-row class="ma-0 flex-column">
      <h4 class="pa-6 pb-2 pt-8">Anchor Points</h4>
      <span style="color: #fb8c00" class="pl-6 pb-8">
        Changing anchor points will possibly break combinations between schedules and payments
      </span>
    </v-row>
    <el-table
      :data="[...anchorPoints, '']"
    >
      <el-table-column
        width="60"
        align="center"
        label="Nr"
      >
        <template slot-scope="scope" >
          <span v-if="scope.$index !== anchorPoints.length">
            {{scope.$index +1}}
          </span>
          <v-icon class="plus-icon" :disabled="anchorPoints.indexOf(newAnchor) !== -1" v-else @click="addAnchor">add</v-icon>
        </template>
      </el-table-column>
      <el-table-column
        label="Name">
        <template slot-scope="scope">
          <span v-if="scope.$index !== anchorPoints.length ">
            {{scope.row}}
          </span>
          <v-text-field
            class="flex xs2"
            v-model="newAnchor"
            v-else
            :error-messages="newAnchorErrors"
            label="Anchor Point"
            @keydown.enter="addAnchor"
          ></v-text-field>
        </template>
      </el-table-column>
      <el-table-column
        width="80"
      >
        <template slot-scope="scope">
          <v-btn icon v-if="
            scope.$index !== anchorPoints.length &&
              scope.row !== 'ordered' &&
              scope.row !== 'in shop'"
            @click="removeItem(scope.$index)"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </template>
      </el-table-column>
    </el-table>
  </v-col>
</template>

<script>
import * as usersApi from '@/api/userAPI'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'AnchorPoints',
  setup () {
    return {
      generalStore: useGeneralStore(),
    }
  },
  data () {
    return {
      url: '',
      newAnchor: '',
    }
  },
  computed: {
    anchorPoints () {
      return this.generalStore.buySettings?.anchor_points || []
    },
    newAnchorErrors () {
      if (
        !this.newAnchor ||
        this.checkString(this.newAnchor)
      ) {
        return ''
      }
      return 'Unexpected Symbol'
    }
  },
  methods: {
    checkString (str) {
      return !!str.match('^[a-zA-Z0-9 _-]+$')
    },
    checkChar (char, range) {
      return !(char < range[0] || char > range[1])
    },
    addAnchor () {
      if (!this.newAnchor || !this.anchorPoints || this.newAnchorErrors || this.anchorPoints.indexOf(this.newAnchor) !== -1) {
        return
      }
      this.anchorPoints.push(this.newAnchor)
      this.newAnchor = ''

      usersApi
        .saveBuySettings({
          buy_settings: {
            anchor_points: this.anchorPoints,
          }
        })
        .then(() => {
          this.$auth.refreshAppConfig()
        })
    },
    removeItem (index) {
      this.anchorPoints.splice(index, 1)

      usersApi
        .saveBuySettings({
          buy_settings: {
            anchor_points: this.anchorPoints,
          }
        })
        .then(() => {
          this.$auth.refreshAppConfig()
        })
    }
  },
}
</script>

<style lang="scss">
.anchor-points {
  .v-text-field {
    .v-label {
      font-size: 13px;
    }
  }
  .plus-icon {
    font-size: 20px;
  }
}
</style>
