import axios from '@/services/axios.js'
import { getHeadersWithAccessToken, headers } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

export function uploadImage (payload) {
  const url = baseURLs.manageAPI + '/image'
  const h = localStorage.getItem('access_token')
    ? getHeadersWithAccessToken()
    : headers
  const options = {
    headers: {
      ...h,
      'Content-Type': 'multipart/form-data',
    }
  }

  return axios.post(url, payload, options)
    .then(response => response.data.data.file_location)
    .catch(error => console.log(error))
}

export function saveImage (payload) {
  const url = baseURLs.manageAPI + '/image'
  return axios.post(url, payload)
    .then(response => response.data.data.file_location)
    .catch(error => console.log(error))
}
