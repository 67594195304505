<script setup lang="ts">
import {computed} from 'vue'
import draggable from 'vuedraggable'

interface Item {
  value: string;
  text: string;
}

const props = defineProps<{
  items?: Item[];
  showArrows?: boolean;
}>()

const emit = defineEmits<{
  (event: 'delete', index: number): void;
  (event: 'update:items', value: Item[]): void;
}>()

const localItems = computed({
  get: () => props.items || [],
  set: (value) => emit('update:items', value),
})

function deleteItem (index: number) {
  emit('delete', index)
}
function moveItem (index: number, offset: number) {
  const newIndex = index + offset
  if (newIndex >= 0 && newIndex < localItems.value.length) {
    const items = [...localItems.value]
    const item = items.splice(index, 1)[0]
    items.splice(newIndex, 0, item)
    localItems.value = items
  }
}
</script>
<template>
  <div v-if="items" class="order-list">
    <draggable v-model="localItems" handle=".handle" :animation="200">
      <transition-group>
        <v-row v-for="(item, index) in localItems" :key="item.value" class="list-item justify-space-between">
          <v-col cols="9">
            <span class="handle">☰ {{index+1}})</span>
            {{ item.text }}
          </v-col>
          <v-col v-if="showArrows" cols="2" class="text-right">
            <span
              class="icon"
              :class="{ hidden: index === 0 }"
              @click="moveItem(index, -1)">
              <v-icon>mdi-arrow-up</v-icon>
            </span>
            <span
              class="icon"
              :class="{ hidden: index === localItems.length - 1 }"
              @click="moveItem(index, 1)">
              <v-icon>mdi-arrow-down</v-icon>
            </span>
          </v-col>
          <v-col cols="1">
            <span class="icon" @click="deleteItem(index)">
              <v-icon>mdi-delete</v-icon>
            </span>
          </v-col>
        </v-row>
      </transition-group>
    </draggable>
  </div>
  <div v-else>
    <p>-</p>
  </div>
</template>


<style lang="scss" scoped>
.order-list {
  margin-top: 16px;
  margin-inline: 10px;
  .row.list-item {
    .col {
      padding: 0;
    }
  }
}
.list-item {
  display: flex;
  align-items: center;
  background: #f0f0f0;
  padding: 8px;
  margin-bottom: 4px;
}

.handle {
  cursor: move;
  margin-right: 8px;
}

.icon {
  margin-inline: 4px;
  cursor: pointer;
}

.hidden {
  visibility: hidden !important;
}

.icon > .v-icon:hover {
  color: #000!important;
}
</style>
