<template>
  <div style="min-height: 450px">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2"/>
    </v-overlay>
    <data-sets-tables

      is-recommendations
      style="margin-top: 0 !important;"
      v-if="tableData"
      :key="tableKey"
      :view="tableData"
      :filters="{column: 'main', value: chosenFilters, list: chosenFiltersList}"
      @filters-updated="$emit('filters-updated', $event)"
      :index="0"
      :showDownload="true"
      :has-title="false"
      :child-views="tableData.view.childViews"
      @expand-change="(...data) => loadChildViews(data)"
    >
    </data-sets-tables>
  </div>
</template>

<script>
import { performViewData } from '@/api/analytics'
import DataSetsTables from '@/components/AutomateDatasets/DataSetsTables'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  name: 'RecommendationsTableLowCover',
  components: { DataSetsTables },
  props: ['chosenDimension', 'chosenFilters', 'chosenFiltersList'],
  data () {
    return {
      loading: true,
      tableKey: 0,
      tableData: null,
      dataView: {
        'org_key': 0,
        'view_key': 737,
        'user_id': 51,
        'dataset': 'stock_performance',
        'view_name': 'Recommendations Low stock (any dimension -> option)',
        'chart_type': 'table',
        'dimensions': ['properties/article/group'],
        'measures': [
          {
            'name': 'Stock stores',
            'calculation': 'stock_stores'
          },
          {
            'name': 'Proposal',
            'calculation': 'proposal'
          },
          {
            'axis': 'y1',
            'name': 'Potential N5W',
            'type': 'bar',
            'calculation': 'potential_stocked_n5w',
            'decimals': 0
          },
          {
            'axis': 'y1',
            'name': 'Cover',
            'type': 'bar',
            'decimals': 1,
            'calculation': 'store_cover'
          },
          {
            'name': 'Sort',
            'hidden': true,
            'decimals': 2,
            'calculation': [
              'proposal',
              '/',
              [
                'stock_stores',
                '+',
                1.1
              ]
            ]
          }
        ],
        'filters': {},
        'properties': {
          'rows': 10,
          'sort': 'Sort',
          'total_row': true,
          'child_rows': 'properties/article/key',
          'child_limit': 1000
        },
        'creation_date': '2023-01-05 13:18:52',
        'limited': false,
        'filters_applied': []
      }
    }
  },
  created () {
    this.loadData(true)
  },
  watch: {
    chosenDimension () {
      this.loadData()
    }
  },
  methods: {
    loadData (initial = false) {
      this.loading = true
      const pageFilters = this.filtersStore.getPageFiltersNonDefaultValues()
      this.dataView.filters.collection_key = pageFilters.collections || []
      this.dataView.filters.group_key = pageFilters.groups || []
      this.dataView.filters.store_key = pageFilters.stores || []
      this.dataView.dimensions = this.chosenDimension
      performViewData(this.dataView).then(r => {
        this.tableData = r
        this.tableKey += !initial ? 1 : 0
        this.loading = false
      })
    },
    loadChildViews (view) {
      performViewData(view, true).then(() => {
        //        this.tableData.view.childViews.data = this.tableData.view.childViews.data.splice(0, 9)
        this.tableData = JSON.parse(JSON.stringify(this.tableData))
      })
        .catch(console.log)
    },
    onDimensionsChange () {
      this.loadData()
    },
  }
}
</script>

<style scoped>

</style>
