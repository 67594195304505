<template>
  <data-tables
    class="shop_items_table"
    v-if="items && items.length > 0"
    key="shop_item_key"
    :data="items"
    :page-size="5"
    :pagination-props="{
      pageSizes: [5, 10,15, 25, 50],
      class: 'el-pagination text-right mt-6 mb-4 mr-2',
    }"
    :table-props="{ 'row-key': 'shop_item_key' }"
    :filters="itemsTableFilters"
    filter-multiple
    @filter-change="filters => handleFilterChange('itemsTableFilters', filters)"
    @row-click="selectable ? handleItemsRowClick: ()=>{}">
    <el-table-column prop="shop_item_key" width="55" v-if="selectable">
      <template v-slot:header>
        <v-simple-checkbox
          color="primary"
          :value="allRowsSelected"
          :ripple="false"
          dense
          hide-details
          @input="handleSelectAllChange"
        />
      </template>
      <template slot-scope="scope">
        <v-simple-checkbox
          color="primary"
          dense
          hide-details
          class="mt-0 pt-0"
          :value="scope.row.selected"
          :ripple="false"
          @input="handleSelectItemRowChange($event, scope.row)"
        />
      </template>
    </el-table-column>
    <el-table-column prop="option_name" width="250">
      <template v-slot:header>
        <div :class="{ 'mx-10': !selectable }">Option</div>
      </template>
      <template slot-scope="scope">
        <OptionDataCell
          :class="{ 'mx-10': !selectable }"
          :option="scope.row"
          :url="getShopPageUrl(scope.row.shop_item_key)"
          name-key="option_name"
        />
      </template>
    </el-table-column>
    <el-table-column
      prop="week_min_formatted"
      column-key="week_min_formatted"
      label="Start"
      sortable="custom"
      :filters="getColumnFilterValues('items', 'week_min_formatted')"
    >
      <template slot-scope="scope">
        {{ scope.row.week_min_formatted }}
      </template>
    </el-table-column>
    <el-table-column prop="stores_set_keys" label="Store sets" sortable="custom" width="200">
      <template slot-scope="scope">
        <ChipsDropdown :items="getStoreSetsNames(scope.row.stores_set_keys)" />
      </template>
    </el-table-column>
    <el-table-column
      prop="drop_name"
      column-key="drop_name"
      label="Drop"
      sortable="custom"
      :filters="getColumnFilterValues('items', 'drop_name')"
    >
      <template slot-scope="scope">
        {{ scope.row.drop_name }}
      </template>
    </el-table-column>
    <el-table-column
      prop="tags"
      column-key="tags"
      label="Tags"
      sortable="custom"
      :filters="getColumnFilterValues('items', 'tags')"
    >
      <template slot-scope="scope">
        <ChipsDropdown v-if="scope.row.tags" :items="scope.row.tags" />
      </template>
    </el-table-column>
    <el-table-column
      v-if="!impact"
      prop="status"
      column-key="status"
      label="Status"
      sortable="custom"
      :filters="getColumnFilterValues('items', 'status')"
    >
      <template slot-scope="scope">
        <span :style="{ color: shopItemStatusColorMap[scope.row.status] }">
          {{ scope.row.status | capitalize }}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      v-else
      prop="merge_effect"
      column-key="merge_effect"
      label="Impact"
      sortable="custom"
      :filters="getColumnFilterValues('items', 'merge_effect')"
    >
      <template slot-scope="scope">
        <span :style="{ color: shopItemStatusColorMap[scope.row.merge_effect.toLowerCase()] }">
          {{ scope.row.merge_effect | capitalize }}
        </span>
      </template>
    </el-table-column>
  </data-tables>
</template>

<script>

import ChipsDropdown from '@/components/ChipsDropdown.vue'
import OptionDataCell from '@/components/Dummies/OptionDataCell.vue'
import ColumnFilters from '@/mixins/columnFilters'
import { shopItemStatusColorMap } from '@/variables'

export default {
  mixins: [ColumnFilters],
  name: 'ShopItemsTable',
  components: {
    ChipsDropdown,
    OptionDataCell,
  },
  data () {
    return {
      shopItemStatusColorMap,
      itemsTableFilters: [],
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    budgetKey: {
      type: Number,
      required: true,
    },
    scenarioKey: {
      type: Number,
      required: true,
    },
    storeSets: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    impact: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    storeSetsMap () {
      return this.storeSets.reduce((acc, cur) => ({
        ...acc,
        [cur.stores_set_key]: cur.set_name,
      }), {})
    },
    filteredData () {
      return this.filterData(this.items, this.itemsTableFilters)
    },
    selectedRows () {
      return this.items.filter(item => item.selected)
    },
    allRowsSelected () {
      return this.filteredData.length > 0 && this.filteredData.length === this.selectedRows.length
    },
  },
  watch: {
    itemsTableFilters: {
      handler () {
        this.filteredData.forEach(r => this.$set(r, 'selected', false))
        this.items.forEach(item => {
          item.selected = false
        })
      },
      deep: true,
    },
  },
  methods: {
    handleItemsRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    getShopPageUrl (shopKey) {
      return {
        name: 'buy-shop-item-page',
        query: {
          shop_item_key: shopKey,
          budget_key: this.budgetKey,
          scenario_key: this.scenarioKey,
        },
      }
    },
    handleSelectItemRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
    getStoreSetsNames (keys) {
      if (!keys) {
        return []
      }
      return keys.map(key => this.storeSetsMap[key])
    },
    handleSelectAllChange (val) {
      this.filteredData.forEach(r => this.$set(r, 'selected', val))
    },
  }
}
</script>

<style scoped>

</style>
