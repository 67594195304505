import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'
import { getHeadersWithAccessToken } from '@/variables'
import { createOptionsFromColumnsAndData } from '@/api/stockOperateAPI'

export async function getOptionSets (query = '') {
  const url = baseURLs.manageAPI + '/options_sets' + query
  const response = await axios.get(url)
  return response.data
}
export async function getPropertiesAndValues (tableName, format = 'doa') {
  const url = baseURLs.tsqlAPI + '/options_finder'
  const params = { format: format, table_name: tableName || '' }

  const response = await axios.get(url, { headers: getHeadersWithAccessToken(), params })
  // For intelisense
  return {
    properties: response.data.properties,
    values: response.data.values,
    ...response.data,
  }
}

export function optionViewCall (option_key) {
  const url = baseURLs.manageAPI + '/option_view'
  return axios.post(url, { option_key, module: 'pricing' })
    .then(response => {
      return response.data
    }).catch()
}

export function saveOptionSettings (data, params) {
  const url = baseURLs.manageAPI + '/option_settings'

  return axios
    .post(
      url,
      data,
      { headers: getHeadersWithAccessToken(), params }
    )
    .then(r => r.data)
    .catch(console.error)
}

export function loadOptionData (params) {
  const url = baseURLs.manageAPI + '/data/general/options/data?format=d'

  return axios
    .get(url, { headers: getHeadersWithAccessToken(), params })
    .catch((error) => console.log(error))
}

export function getStoreProjectionChartData (payload, signal) {
  const url = baseURLs.logicAPI + '/projection'

  return axios
    .post(
      url,
      payload,
      {
        headers: getHeadersWithAccessToken(),
        signal,
      }
    )
    .then(response => response.data)
    .catch((error) => console.log(error))
}

export function getExplain (payload, signal) {
  const url = baseURLs.logicAPI + '/explain'

  return axios
    .post(
      url,
      payload,
      {
        headers: getHeadersWithAccessToken(),
        signal,
      },
    )
    .then(response => response.data)
    .catch((error) => console.log(error))
}

export function searchOptions (searchTerm) {
  const url = baseURLs.manageAPI + '/data/general/options/search'
  const input = '%' + searchTerm + '%'

  return axios.get(url, {
    headers: getHeadersWithAccessToken(),
    params: { 'query': input, 'limit': 100 },
  })
    .then(response => response.data.data)
    .catch((error) => console.log(error))
}

export function findOptionsByFiltersTSQL (filters, columns = [
  'option_key',
  'image_url',
  'name',
  'route',
  'variation_key',
  'osp',
  'asp',
  'stock',
  'dc_stock',
  'inbounds',
  'active',
  'stock_active',
  'planning_active',
  'skus',
  'stores_included',
  'discount'
]) {
  const url = baseURLs.tsqlAPI + '/options_finder'

  return axios
    .post(url, {
      ...filters,
      format: 'lists',
      columns,
    })
    .then(response => ({
      options: createOptionsFromColumnsAndData(response.data.columns, response.data.data),
      refreshTime: response.data.refresh_time,
    }))
    .catch(error => {
      console.error(error)
      return null
    })
}

export function getOptionVariations (optionKey) {
  const url = baseURLs.manageAPI + '/data/general/options/variation'

  return axios
    .get(url, {
      headers: getHeadersWithAccessToken(),
      params: {
        format: 'aod',
        option_key: optionKey,
      }
    })
    .then(response => response.data.data)
    .catch(error => {
      console.log(error)
    })
}
