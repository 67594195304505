import {defineStore} from 'pinia'

export const useStrategyFiltersStore = defineStore('strategyFilters', {
  state: () => ({
    filtersOpened: false,
    filtersApplied: false,
    filtersChanged: false,
    period: null as unknown,
  }),

  actions: {
    toggleFilter () {
      this.filtersOpened = !this.filtersOpened
    },
    setFiltersOpened (value: boolean) {
      this.filtersOpened = value
    },
    setFiltersApplied (value: boolean) {
      this.filtersApplied = value
    },
    setFiltersChanged (value: boolean) {
      this.filtersChanged = value
    },
    setPeriod (value: unknown) {
      this.period = value
    },
  },
})
