import axios from '@/services/axios.js'
import {getHeadersWithAccessToken} from '@/variables'
import baseURLs from '@/api/APIBaseURLs'
import {useGeneralStore} from '@/store/pinia/generalStore'
import {SKURange} from '@/models/Config'
import authService from '@/auth/Auth'


export function getSKUProperties (): Record<string, string[]> {
  const generalStore = useGeneralStore()
  return generalStore.appConfig?.properties.skus || {}
}

export function getSKUNames (): string[] {
  const generalStore = useGeneralStore()
  return generalStore.appConfig?.sku_names || []
}
export function getSkuRanges (): SKURange[] {
  const generalStore = useGeneralStore()
  const skuRanges = generalStore.appConfig?.sku_ranges
  return skuRanges || []
}

export async function upsertSkuRange (payload: unknown) {
  const url = baseURLs.manageAPI + '/sku_ranges'
  try {
    const response = await axios.post(url, payload)
    authService.refreshAppConfig().catch(console.error)
    return response.data
  } catch (e) {
    return console.error(e)
  }
}

// delete sku range
export async function deleteSkuRange (payload: unknown) {
  const url = baseURLs.manageAPI + '/sku_ranges'
  try {
    const response = await axios.delete(url, {data: payload, headers: getHeadersWithAccessToken()})
    authService.refreshAppConfig().catch(console.error)
    return response.data
  } catch (e) {
    return console.error(e)
  }
}
