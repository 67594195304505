<template>
  <v-row class="px-3">
    <v-col cols="12">
      <div class="pl-2">New</div>
      <div style="overflow-y: scroll; overflow-x: hidden" :style="{
        height: logHeight + 'px',
      }">
        <v-timeline
          dense
        >
          <v-timeline-item
            v-for="(log, i) in logs"
            :key="i"
            small
            fill-dot
            :color="getOperationColor(log.operation)"
          >
            <v-row justify="space-between">
              <v-col cols="12" sm="6">
                {{ log.log_text }}
              </v-col>
              <v-col cols="4" sm="2">
                <v-chip
                  style="width: 110px"
                  label
                  :color="getOperationColor(log.operation)"
                  class="mx-4 px-3 text-center justify-center"
                >
                  {{ log.operation }}
                </v-chip>
              </v-col>
              <v-col cols="4" sm="2" class="pr-0">
                <v-chip
                  style="width: 110px"
                  label
                  :color="'rgba(160,160,160,0.46)'"
                  class="mx-4 px-3 text-center justify-center"
                >
                  {{ log.delta }}
                </v-chip>
              </v-col>

              <v-col cols="1" class="pr-5 text-center">
                <v-tooltip left :max-width="500">
                  <template v-slot:activator="{ on, attrs }">

                    <v-row class="ma-0 flex-nowrap" v-bind="attrs" v-on="on">
                      <div  class="text-no-wrap">Change</div>
                      <v-icon
                        @click="copyToClipboard(pretty(log.difference))" :size="21" class="ml-2 c-pointer">info</v-icon>
                    </v-row>
                  </template>
                  <pre>{{ pretty(log.difference) }}</pre>
                </v-tooltip>
              </v-col>
              <v-col cols="1" class="pr-5 text-center">
                <v-tooltip left :max-width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-row class="ma-0 flex-nowrap" v-bind="attrs" v-on="on">
                      <div class="text-no-wrap">New</div>
                      <v-icon
                        @click="copyToClipboard(pretty(log.current))" :size="21" class="ml-2 c-pointer" >info</v-icon>
                    </v-row>
                  </template>
                  <pre>{{ pretty(log.current) }}</pre>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </div>
      <div class="pl-2">Old</div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {getHistoricLogs, Log} from '@/api/logs'
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'StockOptionLog',
  props: {
    optionKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      logs: [] as Log[]
    }
  },
  computed: {
    logHeight () {
      return this.logs.length >= 5 ? 300 : this.logs.length * 60
    },
  },
  methods: {
    getOperationColor (operation: string) {
      switch (operation.toLowerCase()) {
      case 'transfers':
        return '#eb922b'
      default:
        return 'rgba(0,122,255,0.46)'
      }
    },
    async getLogs () {
      try {
        this.logs = await getHistoricLogs(this.optionKey)
      } catch (error) {
        console.error(error)
      }
    },
    pretty (log: Record<string, unknown>) {
      return JSON.stringify(log, null, 2)
    },
    copyToClipboard (text) {
      navigator.clipboard.writeText(text)
    },
  },
  async created () {
    await this.getLogs()
  }
})
</script>
<style scoped lang="scss">

</style>
