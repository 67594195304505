<template>
  <div
    id="consolidate-stores"
    class="p-relative"
  >
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row>
      <v-col>
        <v-row class="text-right pr-6 pb-4" justify="end">
          <TableSearch class="mx-2" @searchChanged="val => handleSearchChanged('tableFilters', [...storesData.columns, 'store_name'], val)" />
          <DataExport
            @item-click="downloadStoresData"
          />
        </v-row>
        <data-tables
          style="width: 100%"
          class="stores-table"
          :filters="tableFilters"
          :key="tableKey"
          :data="tableData"
          :page-size="5"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 mb-4 mr-2',
          }"
          :table-props="{
            'row-key': 'period',
            'expand-icon': 'add',
          }"
          @filtered-data="filteredData = $event"
          @filter-change="filters => handleFilterChange('tableFilters', filters)"
          @row-click="handleRowClick"
        >
          <el-table-column prop="store_key" width="55">
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-3 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row, storesData.data.indexOf(scope.row))"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="store_name"
            label="Store"
            width="110"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.store_name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="fairshare"
            label="Fairshare"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.fairshare | formatThousands }}
            </template>
          </el-table-column>
          <el-table-column
            prop="fairstock"
            label="Fairstock"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.fairstock | formatThousands }}
            </template>
          </el-table-column>
          <el-table-column
            prop="options_included"
            label="Inclusions"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.options_included | formatThousands }}
            </template>
          </el-table-column>
          <el-table-column
            prop="options_current"
            label="Current"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.options_current }}
            </template>
          </el-table-column>
          <el-table-column
            prop="options_reached"
            label="Reached"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.options_reached | formatThousands }}
            </template>
          </el-table-column>
          <el-table-column
            prop="optionsRemovedCount"
            label="Exclusions"
            width="130"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              <div class="text-center">
                <div class="d-inline-flex align-center justify-center" style="width: 60px">
                  {{ scope.row.optionsRemovedCount | formatThousands }}

                  <template v-if="scope.row.options_removed.filter(x => !!x).length">
                    <v-menu
                      top
                      min-width="350"
                      max-width="350"
                      max-height="500"
                      content-class="options-search-results"
                      :open-delay="200"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="my-1 ml-2"
                          v-bind="attrs"
                          v-on="on"
                          @click="handleInfoIconClick(scope.row)"
                        >info</v-icon>
                      </template>
                      <v-card
                        class="py-2 px-4 p-relative elevation-0 rounded-0"
                        min-height="100"
                      >
                        <v-overlay absolute color="white" :value="optionsMenuLoading">
                          <v-progress-circular color="primary" indeterminate size="64" width="2" />
                        </v-overlay>
                        <v-row
                          v-for="(item, i) in optionsMenuData"
                          :key="i"
                          class="align-center"
                        >
                          <v-col class="d-flex pb-0">
                            <img
                              :alt="item.name"
                              :src="item.image_url || '@/assets/img/ts_placeholder.png'"
                              height="90px"
                              width="60px"
                              @error="loadPlaceholder"
                            >
                            <div v-if="item.name.split(' / ').length === 2" class="ml-6 pt-2 option-name-wrapper">
                              <div class="text-body-2 mb-0font-weight-medium option-name">{{ item.name.split(" / ")[0] }}</div>
                              <p class="text-body-2 mb-0 text--secondary">{{ item.name.split(" / ")[1] }}</p>
                              <p class="mt-2 mb-0 text-body-2">
                                {{ item.osp | currency }}
                                <span v-if="item.osp !== item.asp"> / {{ item.asp | currency }}</span>
                              </p>
                            </div>
                            <div v-else class="pt-2 option-name-wrapper">
                              <div class="text-body-2 font-weight-medium option-name">{{ item.name }}
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-menu>
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="availability_current"
            label="Availability"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              <div class="d-flex justify-center align-center">
                <span :class="getAvailabilityColorClass(scope.row.availability_current * 100)">
                  {{ scope.row.availability_current * 100 | roundToStep(1) }}%
                </span>
                <v-icon small class="mx-2">arrow_forward</v-icon>
                <span :class="getAvailabilityColorClass(scope.row.availability_reached * 100)">
                  {{ scope.row.availability_reached * 100 | roundToStep(1) }}%
                </span>
              </div>
            </template>
          </el-table-column>
          <template v-slot:append>
            <data-tables
              :data="[tableTotal]"
              class="total-table"
              :pagination-props="{class: 'd-none'}"
            >
              <el-table-column label-class-name="d-none" class-name="total-row_column" width="55" />
              <el-table-column label-class-name="d-none" class-name="total-row_column" width="110" />
              <el-table-column
                class-name="total-row_column pl-1"
                label-class-name="d-none"
                v-for="(value, col) in tableTotal"
                align="center"
                :width="col === 'optionsRemovedCount' ? '130' : null"
                :prop="col"
                :key="col"
              >
                <template>
                  <div class="text-center">
                    {{ value | formatThousands }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label-class-name="d-none" class-name="total-row_column" />
            </data-tables>
          </template>
        </data-tables>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { findOptionsByFiltersTSQL } from '@/api/options'
import columnFilters from '@/mixins/columnFilters'
import { getAvailabilityColorClass, downloadData } from '@/utils'
import TableSearch from '@/components/TableSearch.vue'
import DataExport from '@/components/DataExport'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import ts_placeholder from '@/assets/img/ts_placeholder.png'

export default {
  name: 'ConsolidateStores',
  components: { TableSearch, DataExport },
  props: {
    storesData: {
      type: Object,
      default: () => ({})
    },
  },
  mixins: [ columnFilters ],
  data () {
    return {
      loading: false,
      optionsMenuLoading: false,
      optionsMenuData: [],
      filteredData: [],
      tableFilters: [{ value: '' }],
      tableKey: 0,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    storesMap () {
      return (this.appConfig?.stores || []).reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    selectedRows () {
      return this.storesData.data.filter(r => r.selected)
    },
    tableData () {
      return this.storesData.data.map(row => ({
        ...row,
        optionsRemovedCount: row.options_included - row.options_reached,
      }))
    },
    tableTotal () {
      return ['fairshare', 'fairstock', 'options_included', 'options_current', 'options_reached', 'optionsRemovedCount'].reduce((acc, cur) => {
        this.filteredData.forEach(row => {
          if (typeof row[cur] === 'number') {
            acc[cur] = acc[cur] === undefined ? row[cur] : acc[cur] + row[cur]
          }
        })

        return acc
      }, {})
    },
  },
  watch: {
  },
  methods: {
    getAvailabilityColorClass,
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
      this.$emit('selected-row-changed', val ? row : null)
    },
    async handleInfoIconClick (row) {
      this.optionsMenuLoading = true
      this.optionsMenuData = []

      try {
        const response = await findOptionsByFiltersTSQL({
          option_keys_include: row.options_removed
        })

        this.optionsMenuData = Object.freeze(response.options)
      } finally {
        this.optionsMenuLoading = false
      }
    },
    downloadStoresData (sep) {
      const data = this.tableData.map(
        row => ['store_name', 'fairshare', 'fairstock', 'options_included', 'options_current', 'options_reached', 'optionsRemovedCount', 'availability_current', 'availability_reached'].reduce(
          (acc, cur) => Object.assign(acc, { [cur]: row[cur] }),
          {}
        )
      )

      downloadData(sep, data, 'consolidate_stores')
    },
  },
}
</script>

<style lang="scss">
#consolidate-stores {
  .el-table__expand-icon {
    display: none;
  }
  .el-table__append-wrapper {
    position: sticky;
    bottom: 0;
  }
  .stores-table .el-table__body-wrapper {
    max-height: 45vh;
    overflow-y: auto;
  }
  .stores-table::-webkit-scrollbar,
  .stores-table .el-table__body-wrapper::-webkit-scrollbar {
    width: 4px;
  }
  .stores-table::-webkit-scrollbar-track,
  .stores-table .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }
  .stores-table::-webkit-scrollbar-thumb,
  .stores-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
  .total-row_column {
    background-color: #f5f7fa;
  }
}
</style>
