<template>
  <v-dialog
    :value="isOpen"
    persistent
    scrollable
    content-class="align-self-start mt-14"
    width="1200px"
    max-width="90%">
    <v-card class="rounded-0">
      <v-overlay absolute color="white" :value="stockManageStore.capacityTab.dialogLoading">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <v-card-title
        v-if="currentStoreSet"
        class="text-body-1 font-weight-medium mx-4 my-2"
      >
        <template v-if="currentStoreSetEditMode">
          {{ `${currentStoreSet.stores_set_keys ? 'Edit' : 'Add'} store set` }}
        </template>
        <template v-else>
          Store set
          <v-icon class="mx-2">arrow_forward</v-icon>
          {{ currentStoreSet.set_name }}
          <v-btn
            color="primary"
            small
            depressed
            outlined
            @click="currentStoreSetEditClick"
            class="ml-4"
          >Edit</v-btn>
        </template>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-0 text--primary">
        <StoreSetForm
          ref="storeSetForm"
          :store-set="currentStoreSet"
          :edit-mode="currentStoreSetEditMode"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2 px-4">
          <v-col>
            <template v-if="currentStoreSetEditMode">
              <v-btn
                color="primary"
                depressed
                text
                @click="handleStoreSetSaveCancel"
              >Cancel</v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                @click="handleStoreSetSaveConfirm"
                class="mx-4"
              >Save</v-btn>
            </template>
            <template v-else>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleStoreSetSaveCancel"
              >Close</v-btn>
            </template>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StoreSetForm from '@/components/Assortment/StoreSets/StoreSetForm'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'

import { useStockManageStore } from '@/store/pinia/stockManageStore'

export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  name: 'StoreSetFormDialog',
  components: { StoreSetForm },
  data () {
    return {
      currentStoreSetEditMode: false
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    currentStoreSet: {
      type: Object,
      default: null,
    },
  },
  methods: {
    handleStoreSetSaveConfirm () {
      if (!this.$refs.storeSetForm.validateStoreSet()) {
        return
      }
      const payload = this.$refs.storeSetForm.getSavePayload()

      this.stockManageStore.setDialogLoading({
        tab: 'capacity',
        value: true,
      })
      this.stockManageStore.saveStoreSet(payload)
        .then(() => {
          this.$emit('close')
          this.$refs.storeSetForm.resetForm()
          this.currentStoreSetEditMode = false

          Promise
            .all([
              useStoreAndOptionSetsStore().loadStoreSets(),
              this.stockManageStore.loadLowLevelCapacityList()
            ])
            .catch(console.error)
            .finally(() => {
              this.stockManageStore.setDialogLoading({
                tab: 'capacity',
                value: false,
              })
            })
        })
    },
    handleStoreSetSaveCancel () {
      this.$refs.storeSetForm.resetForm()
      this.currentStoreSetEditMode = false
      this.$emit('close')
    },
    currentStoreSetEditClick () {
      this.currentStoreSetEditMode = true
    },
  }
}
</script>

<style scoped>

</style>
