<template>
  <div class="stock-overview-graphs">
    <v-row>
      <v-col class="p-relative">
        <v-overlay absolute color="white" :value="stockOverviewStore.dataLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <h6 class="text-subtitle-1 font-weight-medium ml-6">Graph</h6>
        <v-row>
          <v-col :cols="6" :offset="3" class="d-flex justify-center">
            <h6 class="text-subtitle-1 font-weight-medium" v-if="chartAxis.length">
              {{ chartAxis[0] | formatString }} and {{ chartAxis[1] | formatString }} per {{ (dimension1Value ? selectedDimension2.split('/').pop() : selectedDimension1.split('/').pop()) | formatString }} (colour = {{ chartAxis[2] | formatString }})
            </h6>
          </v-col>
          <v-col :cols="3" class="text-right pr-6 d-flex align-center justify-end">
            <label class="v-label theme--light mr-2">Outliers</label>
            <v-switch
              v-model="outliers"
              hide-details
              class="d-inline-block pt-0 mt-0 mr-2"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <div class="mb-0 text-center text-body-2" v-if="dimension1Value">
              {{ selectedDimension1.split('/').pop() | formatString }} = {{ dimension1Value }}
            </div>
          </v-col>
        </v-row>
        <div class="col-12 mt-20">
          <scatter-chart
            :dimension="this.dimension1Value ? this.selectedDimension2 : this.selectedDimension1"
            :outliers="outliers"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ScatterChart from '@/components/Overview/ScatterChart.vue'
import { useStockOverviewStore } from '@/store/pinia/stockOverviewStore'

export default {
  setup () {
    return {
      stockOverviewStore: useStockOverviewStore(),
    }
  },
  data () {
    return {
      outliers: true
    }
  },
  components: {
    ScatterChart
  },
  computed: {
    selectedDimension1 () {
      return this.stockOverviewStore.selectedDimensions[0]
    },
    selectedDimension2 () {
      return this.stockOverviewStore.selectedDimensions[1]
    },
    dimension1Value () {
      return this.stockOverviewStore.dimension1Value
    },
    chartAxis () {
      return this.stockOverviewStore.chartAxis
    },
  },
}
</script>

<style lang="scss">
.stock-overview-graphs {
}
</style>
