<template>
  <div class="option-data-cell--dummy">
    <div class="mr-6 image-wrapper">
      <img
        @error="getError"
        style="max-height: 55px; max-width: 33px"
        :src="option.image_url || placeholder_image"
        @click="$emit('image-click')"
        alt=""/>
    </div>
    <div class="option">
      <component
        :title="option[nameKey]"
        :is="url ? 'router-link' : 'div'"
        class="option-name"
        :target="openInNewTab ? '_blank' : null"
        :to="url"
      >
        <template v-if="nameParts.length === 2 && !changeable">
          <p class="option-title">
            <span v-if="!changeable">{{ nameParts[0] }}</span>
            <v-text-field
              v-else
              class="align-center"
              :disabled="disableFields"
              :value="option[nameKey]"
              @input="$emit('input', $event.replaceAll('/', ''))"
            ></v-text-field>
          </p>
          <p class="option-subtitle">
            {{ nameParts[1] }}
          </p>
        </template>
        <template v-else>
          <span v-if="!changeable">{{ nameParts[0] }}</span>
          <v-text-field :value="option[nameKey]"
            v-else-if="!isTwoRows"
            class="align-center"
            :disabled="disableFields"
            @input="$emit('input', $event.replaceAll('/', ''))"
          ></v-text-field>
          <template v-else>
            <v-text-field
              :value="nameParts[0]"
              class="align-center"
              hide-details
              :disabled="disableFields"
              dense
              @input="$emit('input', `${$event.replaceAll('/', ' ')}/${nameParts[1]}`)"
            ></v-text-field>
            <v-text-field
              :value="nameParts[1]"
              class="align-center pb-1"
              dense
              :disabled="disableFields"
              hide-details
              @input="$emit('input', `${nameParts[0]}/${$event.replaceAll('/', ' ')}`)"
            ></v-text-field>
          </template>
        </template>
      </component>
    </div>
  </div>
</template>

<script>
import placeholder_image from '@/assets/img/placeholder-image.png'

export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    isTwoRows: {
      type: Boolean
    },
    disableFields: {
      type: Boolean
    },
    changeable: {
      type: Boolean,
      default: false
    },
    nameKey: {
      type: String,
      default: 'option',
    },
    url: {
      type: Object,
      default: null,
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    placeholder:{
      type: String,
    }
  },
  computed: {
    placeholder_image (){
      return this.placeholder || placeholder_image

    },
    name () {
      return this.option[this.nameKey] || ''
    },
    nameParts () {
      return this.option[this.nameKey] ? this.option[this.nameKey].split('/') : []
    }
  },
  methods:{
    getError ($event){
      $event.target.src = this.placeholder_image
    }

  }
}
</script>

<style lang="scss">
    .option-data-cell--dummy {
        display: flex;
        flex-direction: row;
        font-size: 13px !important;
        align-items: center;
        letter-spacing: 0.2px;
        .image-wrapper {
            flex-shrink: 0;
            flex-basis: 33px;
        }
        p {
            margin: 0;
        }
        .option-name {
            color: unset !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-decoration: none;
            .option-title {
                font-weight: 500;
                &:hover {
                    color: var(--primary);
                    cursor: pointer;
                }
            }
            .option-subtitle {
                color: var(--text-sub-black) !important;
                font-size: 12px;
            }
        }
    }
</style>
