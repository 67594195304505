<template>
  <v-card class="buy-manage-beliefs-modal">
    <v-card-title class="text-body-1 font-weight-medium my-2 justify-space-between px-12">
      {{ `${isEdit ? 'Edit drop' : 'Add drop'}` }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text--primary px-12">
      <v-row class="align-baseline mt-2 mb-2">
        <v-col class="py-0 pr-8">
          <v-text-field
            v-model="innerDrop.drop_name"
            clearable
            class="pb-0 mb-0"
            label="Drop name"
            hide-details="auto"
            :error-messages="dropsErrors.drop_name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="align-baseline mt-2 mb-4">
        <v-col class="pt-0 pb-2 pr-8" v-if="!isEdit">
          <collapsible-select
            :items="budgets"
            v-model="innerDrop.budget_keys"
            clearable
            show-select-all
            item-text="budget_name"
            item-value="budget_key"
            label="Budget"
            hide-details="auto"
            :error-messages="dropsErrors.budget_key"
          ></collapsible-select>
        </v-col>
      </v-row>
      <v-row class="align-baseline  mt-2 mb-4 pr-8 ">
        <v-col class="pt-0 pb-2 ma-0">
          <WeekSelector
            class="pt-0 mt-0"
            v-model="innerDrop.week_min"
            label="Start Week"
            clearable
            hide-details="auto"
            :menu-props="{
              left: true,
              offsetY: true,
              contentClass: 'select-dropdown-menu',
            }"
          ></WeekSelector>
        </v-col>
        <v-col class="py-0 pr-0">
          <WeekSelector
            class="pt-0 mt-0"
            v-model="innerDrop.week_max"
            clearable
            hide-details="auto"
            :error-messages="saveIsDisabled ? 'End week must be more then start week' : ''"
            label="End Week"
          ></WeekSelector>
        </v-col>
      </v-row>
      <v-row class="align-baseline mt-0 mb-2">
        <v-col class="py-0 pr-8">
          <collapsible-select
            :items="stores"
            v-model="innerDrop.stores_set_keys"
            clearable
            item-text="set_name"
            class="mt-0 pt-0"
            item-value="stores_set_key"
            :error-messages="dropsErrors.stores_set_keys"
            label="Store Sets"
            show-select-all
            autocomplete
            hide-details="auto"
          ></collapsible-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row class="dialog-footer text-right py-2 px-4">
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="$emit('close')"
              >Cancel</v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                class="mx-4"
                :disabled="saveIsDisabled"
                @click="updateDrop"
              >Save</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-card-actions>
  </v-card>
</template>

<script>
import CollapsibleSelect from '../CollapsibleSelect'
import { addDrop, updateDrop } from '@/api/shop'
import { getStoreSets } from '@/api/storeSets'
import WeekSelector from '../WeekSelector'
import {useGeneralStore} from '@/store/pinia/generalStore'
export default {
  name: 'DropTableModal',
  components: { WeekSelector, CollapsibleSelect },
  props: ['drop', 'budgets', 'isEdit'],
  data () {
    return {
      innerDrop: {
        week_min: null,
        week_max: null,
        budget_keys: [],
        drop_name: '',
        stores_set_keys: [],
      },
      dropsErrors: {
        budget_keys: '',
        drop_name: '',
        stores_set_keys: '',
      },
      stores: [],
    }
  },
  computed: {
    saveIsDisabled () {
      return this.innerDrop.week_max && this.innerDrop.week_max < this.innerDrop.week_min
    },
  },
  watch: {
    drop: {
      handler () {
        this.setInnerDrop()
      },
      immediate: true,
      deep: true,
    },
  },
  mounted () {
    if (this.isEdit) {
      this.setInnerDrop()
    } else {
      this.innerDrop.budget_keys = [parseInt(this.$route.query.budget_key)]
    }
    const storesLabel = useGeneralStore().currentStoresSetsLabel
    getStoreSets(`?label=${storesLabel}`).then(({ stores_sets }) => {
      this.stores = stores_sets
    })
  },
  methods: {
    setInnerDrop () {
      this.innerDrop = this.drop
      this.innerDrop.week_min = this.innerDrop.week_min?.toString() || null
      this.innerDrop.week_max = this.innerDrop.week_max?.toString() || null
    },
    validates () {
      let validation = true
      for (const item in this.dropsErrors) {
        if (item === 'budget_keys') {
          continue
        }
        if (!this.innerDrop[item]) {
          this.dropsErrors[item] = 'Field Cannot be Empty'
          validation = false
        } else {
          this.dropsErrors[item] = ''
        }
      }
      if (this.innerDrop.week_max && this.innerDrop.week_max < this.innerDrop.week_min) {
        this.dropsErrors.week_max = 'End week must be after start week'
        validation = false
      } else if (this.innerDrop.week_max) {
        this.dropsErrors.week_max = ''
      }

      if (!this.isEdit && this.innerDrop.budget_keys && !this.innerDrop.budget_keys.length) {
        this.dropsErrors.budget_keys = 'Field Cannot be Empty'
        validation = false
      } else {
        this.dropsErrors.budget_keys = ''
      }

      if (!this.innerDrop.stores_set_keys.length) {
        this.dropsErrors.stores_set_keys = 'Field Cannot be Empty'
        validation = false
      } else {
        this.dropsErrors.stores_set_keys = ''
      }
      return validation
    },
    updateDrop () {
      if (!this.validates()) {
        return
      }
      const update = this.innerDrop.drop_key ? updateDrop : addDrop
      update(this.innerDrop).then((item) => {
        this.$emit('save', item)
      })
    }
  }
}
</script>

<style scoped>

</style>
