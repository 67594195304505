<template>
  <div class="product-skus">
    <template>
      <v-row>
        <v-col class="pb-0">
          <h4 class="mt-2 pa-4 pb-0">Selected SKUs</h4>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="6">
          <v-autocomplete
            :menu-props="{
              left: true,
            }"
            :disabled="isDisabled"
            color="primary"
            @change="handleSkusRangeChange"
            item-text="sku_range_name"
            item-value="sku_range_key"
            label="Import SKU range"
            hide-details="auto"
            :value="null"
            :items="skuRanges"
            class="mt-2 ml-4"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right px-8">
          <v-icon
            class="mx-2 rotate270 action-btn-primary"
            :disabled="isDisabled || moveUpDisabled"
            @click="handleSkuMoveUpClick"
          >last_page</v-icon>
          <v-icon
            class="mx-2 rotate90 action-btn-primary"
            :disabled="isDisabled || moveDownDisabled"
            @click="handleSkuMoveDownClick"
          >last_page</v-icon>
          <v-icon class="mx-2 action-btn-primary"
            :disabled="isDisabled"
            @click="handleAddSkuClick()">add</v-icon>
          <v-icon class="mx-2 action-btn-danger" :disabled="isDisabled || selectedIndex === null" @click="handleDeleteSkuClick">delete</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="p-relative">
          <data-tables
            style="width: 100%"
            v-if="product.sku_names"
            class="mb-4"
            :data="product.sku_names.map(item=> ({name: item}))"
            :page-size="product.sku_names.length"
            :table-props="{'row-key': 'name'}"
            :pagination-props="{ pageSizes: [product.sku_names.length], hideOnSinglePage: true }"
            @row-click="handleRowClick">
            <el-table-column prop="timeline_key" width="55">
              <template slot-scope="scope">
                <v-simple-checkbox
                  color="primary"
                  dense
                  hide-details
                  :disabled="isDisabled"
                  class="mt-0 ml-4 pt-0"
                  :value="scope.$index === selectedIndex"
                  :ripple="false"
                  @input="handleSelectRowChange($event, scope.$index)"
                />
              </template>
            </el-table-column>
            <el-table-column label="Rank" label-class-name="ml-4" width="120px">
              <template slot-scope="scope">
                <div class="ml-4">
                  {{ scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="SKU name">
              <template slot-scope="scope">
                <div class="sku-name-select-wrapper">
                  <v-autocomplete
                    v-model="product.sku_names[scope.$index]"
                    @input="$emit('update-product',product)"
                    :disabled="isDisabled"
                    :items="getSkusOptionsAvailable(scope.row.name)"
                    :error-messages="scope.row.name === null ? 'Required' : null"
                    dense
                    class="pt-0 my-1 mr-4 text-body-2"
                    hide-details
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                  />
                </div>
              </template>
            </el-table-column>
          </data-tables>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { getSKUNames, getSkuRanges } from '@/api/skusAPI'

export default {
  components: {},
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      skus: [],
      similarOptions: [],
      selectedIndex: null,
      lookalikeOptions: [],
      skuRanges: [],
    }
  },
  async beforeMount () {
    this.skus = getSKUNames()
    this.skuRanges = getSkuRanges()
  },
  computed: {
    selectedSkuRows () {
      if(this.selectedIndex === null) {
        return null
      }
      return this.product.sku_names[this.selectedIndex]
    },
    moveUpDisabled () {
      return this.selectedIndex === null ||
        !this.allSkusValid ||
        this.selectedIndex === 0
    },
    moveDownDisabled () {
      return this.selectedIndex === null ||
        !this.allSkusValid ||
        this.selectedIndex === this.product.sku_names.length - 1
    },
    usedSkus () {
      return this.product.sku_names
    },
    skusOptionsAvailable () {
      return this.skus?.filter(s => !this.usedSkus.includes(s))
        ?.map(s => ({
          text: s,
          value: s,
        })) || []
    },
    allSkusValid () {
      return this.product.sku_names.map(s => s.name).every(n => n !== null)
    }
  },

  methods: {
    handleSkusRangeChange (val) {
      const range = this.getSkuRangeByKey(val)
      if (!range) {
        return
      }
      this.product.sku_names = range.sku_names
      this.$emit('update-product', this.product)
    },
    getSkuRangeByKey (key) {
      return this.skuRanges.find(r => r.sku_range_key === key)
    },
    getSkusOptionsAvailable (selectedSkuName) {
      if (!selectedSkuName) {
        return this.skusOptionsAvailable
      }

      return [
        ...this.skusOptionsAvailable,
        {
          text: selectedSkuName,
          value: selectedSkuName,
        }
      ]
    },
    handleSelectRowChange (val, index) {
      this.selectedIndex = val ? index : null
    },
    handleRowClick (row, col, event) {
      if(col.property === 'name'){
        return
      }
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSkuMoveUpClick () {
      const tmp = this.product.sku_names[this.selectedIndex - 1]

      if (this.selectedIndex - 1 >= 0) {
        this.$set(this.product.sku_names, this.selectedIndex - 1, this.selectedSkuRows)
        this.$set(this.product.sku_names, this.selectedIndex, tmp)
        this.selectedIndex --

      }
    },
    handleSkuMoveDownClick () {
      const tmp = this.product.sku_names[this.selectedIndex + 1]

      this.$set(this.product.sku_names, this.selectedIndex + 1, this.selectedSkuRows)
      this.$set(this.product.sku_names, this.selectedIndex, tmp)
      this.selectedIndex++
    },
    handleDeleteSkuClick () {
      this.product.sku_names.splice(this.selectedIndex, 1)
      this.$emit('update-product', this.product)
      this.selectedIndex = null
    },
    handleAddSkuClick () {
      this.product.sku_names.push('')
      this.$emit('update-product', this.product)
    },
  },
}
</script>

<style lang="scss">
.product-skus {
    .sku-name-select-wrapper {
        width: 220px;
    }
}
</style>
