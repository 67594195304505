<template>
  <div id="strategy-intake-charts" class="mx-n6">
    <h6 class="text-subtitle-1 font-weight-medium mx-6">Requirement & OTB</h6>
    <v-carousel
      height="auto"
      class="pb-15"
      delimiter-icon="circle"
      :perPage="1"
      light
      hide-delimiter-background
      :show-arrows="false"
    >
      <v-carousel-item eager>
        <div
          class="chart-container mt-4 mx-4"
          style="position: relative; height: 350px;"
        >
          <canvas id="intakeRequirementChart"></canvas>
        </div>
      </v-carousel-item>
      <v-carousel-item eager>
        <div
          class="chart-container mt-4 mx-4"
          style="position: relative; height: 350px;"
        >
          <canvas id="intakeRevenueChart"></canvas>
        </div>
      </v-carousel-item>
    </v-carousel>
    <div v-if="intakeData?.proj_age_chart" class="pb-8">
      <h6 class="text-subtitle-1 font-weight-medium mt-8 mx-6">Projection (age)</h6>
      <v-carousel
        height="auto"
        class="pb-15"
        delimiter-icon="circle"
        :perPage="1"
        light
        hide-delimiter-background
        :show-arrows="false"
      >
        <v-carousel-item eager>
          <v-row class="my-4" justify="center">
            <v-btn-toggle
              v-model="currentProjection"
              mandatory
              dense
              active-class="bg-gray"
              light
              @change="onProjectionChange"
            >
              <v-btn small color="white">
                <span class="text-capitalize">Projection</span>
              </v-btn>
              <v-btn small color="white">
                <span class="text-capitalize">Projection corrected</span>
              </v-btn>
            </v-btn-toggle>
          </v-row>
          <div
            class="chart-container mt-4 mx-4"
            style="position: relative; height: 350px;"
          >
            <canvas id="projAgeChart"></canvas>
          </div>
        </v-carousel-item>
        <v-carousel-item eager>
          <v-row class="my-4" justify="center">
            <v-btn-toggle
              v-model="currentCover"
              mandatory
              dense
              active-class="bg-gray"
              light
              @change="onCoverChange"
            >
              <v-btn small color="white">
                <span class="text-capitalize">Cover</span>
              </v-btn>
              <v-btn small color="white">
                <span class="text-capitalize">Cover corrected</span>
              </v-btn>
            </v-btn-toggle>
          </v-row>
          <div
            class="chart-container mt-4 mx-4"
            style="position: relative; height: 350px;"
          >
            <canvas id="coverChart"></canvas>
          </div>
        </v-carousel-item>
        <v-carousel-item eager>
          <div
            class="chart-container mt-4 mx-4"
            style="position: relative; height: 350px;"
          >
            <canvas id="selloutPercentageChart"></canvas>
          </div>
        </v-carousel-item>
        <v-carousel-item eager>
          <div
            class="chart-container mt-4 mx-4"
            style="position: relative; height: 350px;"
          >
            <canvas id="projPerDimChart"></canvas>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { Chart } from 'chart.js/auto'
import { getRelativePosition } from 'chart.js/helpers'
import { formatLargeNumber } from '@/utils'
import { chartColors } from '@/variables'

export default {
  name: 'StrategyIntakeCharts',
  props: {
    intakeData: {
      type: Object || null,
      default: null,
    },
    simulationData: {
      type: Object || null,
      default: null,
    },
    metricName: {
      type: String,
      default: '',
    },
  },
  data () {
    this.chartOptions = {
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      hover: {
        mode: 'index',
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          grid: {
            display: false
          },
          ticks: {
            font: {
              size: 14,
            }
          },
          offset: true,
        },
        y: {
          position: 'left',
          title: {
            display: false,
          },
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          beginAtZero: true,
          ticks: {
            callback: (value)=> formatLargeNumber(value),
            padding: 16,
            font: {
              size: 14,
            }
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: context => {
              return this.$options.filters.formatThousands(context.raw)
            },
          }
        },
      },
    }
    this.charts = {
      intakeRevenueChart: null,
      intakeRequirementChart: null,
      projAgeChart: null,
      coverChart: null,
      selloutPercentageChart: null,
      projPerDimChart: null,
    }

    return {
      currentProjection: 0,
      currentCover: 0,
    }
  },
  methods: {
    drawIntakeCharts () {
      ['intakeRevenueChart', 'intakeRequirementChart'].forEach(chart => {
        if (this.charts[chart]) {
          this.charts[chart].destroy()
        }
      })

      let ctx = document.getElementById('intakeRevenueChart')

      this.charts.intakeRevenueChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.intakeData.intake_chart.week_numbers.map(this.$options.filters.formatWeekNumber),
          datasets: [{
            label: 'Revenue ov',
            data: this.intakeData.intake_chart.revenue_ov,
            borderColor: '#007AFF55',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            pointRadius: null,
            tension: 0.4,
          }, {
            label: 'Revenue rv',
            data: this.intakeData.intake_chart.revenue_rv,
            borderColor: '#007AFF',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            pointRadius: null,
            tension: 0.4,
          }],
        },
        options: this.chartOptions,
      })

      ctx = document.getElementById('intakeRequirementChart')
      const options = cloneDeep(this.chartOptions)
      options.scales.y.grid.color = context => {
        return context.tick.value === 0 ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.1)'
      }

      this.charts.intakeRequirementChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.intakeData.intake_chart.week_numbers.map(this.$options.filters.formatWeekNumber),
          datasets: [{
            label: 'Projection',
            data: this.intakeData.intake_chart['projection' + this.metricName],
            borderColor: '#007AFF55',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            pointRadius: null,
            tension: 0.4,
          }, {
            label: 'Requirement',
            data: this.intakeData.intake_chart['requirement' + this.metricName],
            borderColor: '#007AFF',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            pointRadius: null,
            tension: 0.4,
          }, {
            label: 'OTB',
            data: this.intakeData.intake_chart['otb' + this.metricName],
            borderColor: '#FF9800',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            pointRadius: null,
            tension: 0.4,
          }, {
            label: 'Intake',
            data: this.intakeData.intake_chart['intake' + this.metricName],
            borderColor: '#FF9A9A',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            pointRadius: null,
            tension: 0.4,
          }],
        },
        options,
      })
    },
    drawProjectionCharts () {
      ['projAgeChart', 'selloutPercentageChart'].forEach(chart => {
        if (this.charts[chart]) {
          this.charts[chart].destroy()
        }
      })

      if (this.intakeData.proj_age_chart) {
        let ctx = document.getElementById('projAgeChart')
        const currentProjection = this.currentProjection === 0 ? 'projection' : 'projection_fut'
        let options = cloneDeep(this.chartOptions)
        options.clip = false
        options.scales.y.stacked = true
        // options.scales.x.offset = false
        options.onClick = event => {
          const chart = this.charts.projAgeChart
          const canvasPosition = getRelativePosition(event, chart)
          const weekIndex = chart.scales.x.getValueForPixel(canvasPosition.x)
          const weekNumber = this.intakeData.proj_age_chart.week_numbers[weekIndex]

          this.$emit('chart-click', weekNumber)
        }

        this.charts.projAgeChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: this.intakeData.proj_age_chart.week_numbers.map(this.$options.filters.formatWeekNumber),
            datasets: [{
              label: 'Current',
              data: this.intakeData.proj_age_chart[currentProjection + this.metricName].current,
              borderColor: '#007AFF',
              borderWidth: 2,
              backgroundColor: '#007AFF',
              pointRadius: null,
              fill: true,
              tension: 0.4,
            }, {
              label: 'Old',
              data: this.intakeData.proj_age_chart[currentProjection + this.metricName].old,
              borderColor: '#FF9800',
              borderWidth: 2,
              backgroundColor: '#FF9800',
              pointRadius: null,
              fill: true,
              tension: 0.4,
            }],
          },
          options,
        })

        ctx = document.getElementById('selloutPercentageChart')
        options = cloneDeep(this.chartOptions)
        options.scales.y.title.display = true
        options.scales.y.title.text = 'Sellthrough %'

        this.charts.selloutPercentageChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: this.intakeData.proj_age_chart.week_numbers.map(this.$options.filters.formatWeekNumber),
            datasets: [{
              label: 'Current',
              data: this.intakeData.proj_age_chart['sellthrough'].current,
              borderColor: '#007AFF',
              borderWidth: 2,
              backgroundColor: '#007AFF',
              pointRadius: null,
              tension: 0.4,
            }, {
              label: 'Old',
              data: this.intakeData.proj_age_chart['sellthrough'].old,
              borderColor: '#FF9800',
              borderWidth: 2,
              backgroundColor: '#FF9800',
              pointRadius: null,
              tension: 0.4,
            }],
          },
          options,
        })
      }
    },
    drawCoverCharts () {
      if (this.charts.coverChart) {
        this.charts.coverChart.destroy()
      }

      if (this.intakeData.proj_age_chart) {
        const ctx = document.getElementById('coverChart')
        const currentCover = this.currentCover === 0 ? 'cover' : 'cover_fut'

        this.charts.coverChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: this.intakeData.proj_age_chart.week_numbers.map(this.$options.filters.formatWeekNumber),
            datasets: [{
              label: 'Current',
              data: this.intakeData.proj_age_chart[currentCover].current,
              borderColor: '#007AFF',
              borderWidth: 2,
              backgroundColor: '#007AFF',
              pointRadius: null,
              tension: 0.4,
            }, {
              label: 'Old',
              data: this.intakeData.proj_age_chart[currentCover].old,
              borderColor: '#FF9800',
              borderWidth: 2,
              backgroundColor: '#FF9800',
              pointRadius: null,
              tension: 0.4,
            }],
          },
          options: this.chartOptions,
        })
      }
    },
    drawProjPerDimChart () {
      if (this.charts.projPerDimChart) {
        this.charts.projPerDimChart.destroy()
      }

      if (this.simulationData?.simulation_grouped) {
        const ctx = document.getElementById('projPerDimChart')
        const options = cloneDeep(this.chartOptions)
        options.scales.y.stacked = true

        this.charts.projPerDimChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: this.simulationData.week_numbers.map(this.$options.filters.formatWeekNumber),
            datasets: this.simulationData.simulation_grouped.dimension_values.map((d, i) => {
              const color = chartColors[i % (chartColors.length - 1)]

              return {
                label: d,
                data: this.simulationData.simulation_grouped.projection[i],
                borderColor: color,
                borderWidth: 2,
                backgroundColor: color,
                pointRadius: null,
                fill: true,
                tension: 0.4,
              }
            }),
          },
          options,
        })
      }
    },
    updateCharts () {
      this.drawIntakeCharts()
      this.drawProjectionCharts()
      this.drawCoverCharts()
      this.drawProjPerDimChart()
    },
    onProjectionChange () {
      this.drawProjectionCharts()
    },
    onCoverChange () {
      this.drawCoverCharts()
    },
  },
}
</script>

<style lang="scss">
#strategy-intake-charts {
}
</style>
