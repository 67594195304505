<template>
  <div class="assortment-timelines">
    <div class="p-relative">
      <v-overlay absolute color="white" :value="stockManageStore.timelinesTab.loadingRuleblocks">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>

      <h6 class="text-subtitle-1 font-weight-medium mt-8 mb-4 ml-6">Ruleblocks</h6>
      <v-row>
        <v-col cols="3" class="text-right pr-6 ml-6">
          <div class="d-flex align-center">
            <v-autocomplete
              v-if="!currentRuleblock"
              :items="ruleBlocks"
              class="pt-0"
              v-model="currentRuleblock"
              item-value="block_key"
              item-text="block_name"
              placeholder="Select rule block"
              hide-details
              return-object
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }" />
            <v-combobox
              v-else
              :items="ruleBlocks"
              :value="currentRuleblock"
              :error-messages="currentRuleblockNameError"
              class="pt-0"
              item-value="block_key"
              item-text="block_name"
              placeholder="Select rule block"
              hide-details
              return-object
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
              @keyup.enter="event => handleCurrentRuleblockNameChange(event.target.value)"
              @change="handleCurrentRuleblockNameChange" />
            <v-icon class="mx-2 action-btn-primary" @click="handleAddRuleblockClick">add</v-icon>
            <v-icon class="mx-2 action-btn-danger" :disabled="!canDeleteRuleblock" @click="handleDeleteRuleblockClick">delete</v-icon>
          </div>
        </v-col>
      </v-row>
      <template v-if="currentRuleblock">
        <v-row>
          <v-col>
            <div class="d-flex px-6">
              <div class="ruleblock-mark d-flex align-start pt-4">
                <v-icon>radio_button_checked</v-icon>
              </div>
              <div class="pl-5 grow ruleblock-content">
                <div class="block-base" :class="{ collapsed: !rulesVisible }">
                  <v-row class="mb-2">
                    <v-col>
                      <div class="text-body-1 font-weight-medium pt-6 px-6">Rules</div>
                    </v-col>
                    <v-col cols="2" class="text-right">
                      <div class="pt-6 px-6">
                        <v-icon class="panel-icon action-btn-primary" @click="rulesVisible = !rulesVisible">expand_less</v-icon>
                      </div>
                    </v-col>
                  </v-row>
                  <v-container fluid v-if="rulesVisible">
                    <v-row class="bordered-list-header">
                      <v-col cols="3" class="px-6">Option</v-col>
                      <v-col cols="5" offset="2">Settings</v-col>
                    </v-row>
                    <RuleDataRow
                      v-for="(rule, index) of currentRuleblock.rules"
                      :key="index"
                      :index="index"
                      :rule="rule"
                      @change="handleRuleDataChanged"
                      @delete="handleRuleDeleted" />
                    <v-row>
                      <v-col cols="3" class="px-6">
                        <v-autocomplete
                          :items="rules"
                          :item-disabled="item => currentRuleblock.rules.find(x => x.id === item.id)"
                          class="pt-0"
                          v-model="newRule"
                          item-value="id"
                          item-text="name"
                          placeholder="Select rule"
                          hide-details
                          return-object
                          :menu-props="{
                            left: true,
                            offsetY: true,
                            contentClass: 'select-dropdown-menu',
                          }"
                          @change="handleNewRuleChanged" />
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex px-6 mb-4">
              <div class="ruleblock-mark d-flex align-start pt-4">
                <v-icon>radio_button_checked</v-icon>
              </div>
              <div class="pl-5 grow ruleblock-content">
                <div class="block-base" :class="{ collapsed: !parametersVisible }">
                  <v-row class="mb-2">
                    <v-col>
                      <div class="text-body-1 font-weight-medium pt-6 px-6">Parameters</div>
                    </v-col>
                    <v-col cols="auto" class="text-right">

                      <div class="pt-6 px-6 d-flex align-center">

                        <div class="d-flex align-center" v-if="superuser">
                          <label class="v-label theme--light mr-4">Show all Parameters</label>
                          <v-switch
                            v-model="showAll"
                            hide-details
                            class="pt-0 mt-0"
                          />
                        </div>
                        <v-icon class="panel-icon action-btn-primary" @click="parametersVisible = !parametersVisible">expand_less</v-icon>
                      </div>
                    </v-col>
                  </v-row>
                  <v-container fluid v-if="parametersVisible">
                    <v-row class="bordered-list-header mt-2">
                      <v-col cols="3" class="px-6">Option</v-col>
                      <v-col cols="5" offset="2">Settings</v-col>
                    </v-row>
                    <v-row class="bordered-list-row" v-for="(parameter, key) in parameters.filter(item=> showAll || !item.hide)" :key="key">
                      <v-col cols="3" class="px-6 py-4">
                        <v-tooltip top :max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon :size="21" class="mr-2" v-bind="attrs" v-on="on">info</v-icon>
                          </template>
                          <span>{{ parameter.description }}</span>
                        </v-tooltip>
                        {{ parameter.name | formatString}}
                      </v-col>
                      <v-col cols="4" offset="2" class="py-4 pr-6">
                        <div class="d-flex align-center" v-if="parameter.slider">
                          <div class="slider-value-from mr-2">0</div>
                          <v-slider
                            v-model="currentRuleblock.parameters[parameter.name]"
                            thumb-label
                            hide-details="auto"
                            :min="0"
                            :max="100"
                            @change="handleCurrentRuleblockChange"
                          />
                          <div class="slider-value-to ml-2">100</div>
                        </div>
                        <v-switch
                          v-else
                          v-model="currentRuleblock.parameters[parameter.name]"
                          hide-details
                          class="pt-0 mt-0"
                          @change="handleCurrentRuleblockChange"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
    </div>

    <v-divider class="mt-6" />
    <div class="p-relative">
      <v-overlay absolute color="white" :value="stockManageStore.timelinesTab.loadingTimelines">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>

      <h6 class="text-subtitle-1 font-weight-medium mt-8 mb-4 ml-6">Timelines</h6>
      <v-row>
        <v-col cols="3" class="text-right pr-6 ml-6">
          <div class="d-flex align-center">
            <v-autocomplete
              v-if="!currentTimeline"
              :items="timelines"
              class="pt-0"
              v-model="currentTimeline"
              item-value="timeline_key"
              item-text="timeline_name"
              placeholder="Select timeline"
              hide-details
              return-object
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }" />
            <v-combobox
              v-else
              :items="timelines"
              :value="currentTimeline"
              :error-messages="currentTimelineNameError"
              class="pt-0"
              item-value="timeline_key"
              item-text="timeline_name"
              placeholder="Select timeline"
              hide-details
              return-object
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
              @keyup.enter="event => handleCurrentTimelineNameChange(event.target.value)"
              @change="handleCurrentTimelineNameChange" />
            <v-icon class="mx-2 action-btn-primary" @click="handleAddTimelineClick">add</v-icon>
            <v-icon class="mx-2 action-btn-danger" :disabled="!canDeleteTimeline" @click="handleDeleteTimelineClick">delete</v-icon>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="currentTimeline" class="mt-6 timeline-ruleblocks">
        <v-col>
          <div v-for="(_, index) in currentTimeline.block_keys" :key="index" class="d-flex px-6 mb-4">
            <div class="ruleblock-mark p-relative d-flex align-center">
              <v-icon>radio_button_checked</v-icon>
            </div>
            <div class="px-5 py-4 grow ruleblock-content">
              <v-row class="align-center">
                <v-col :cols="3">
                  <v-autocomplete
                    :items="ruleBlocks"
                    v-model="currentTimeline.block_keys[index]"
                    item-value="block_key"
                    item-text="block_name"
                    class="pt-0"
                    hide-details
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                    @change="handleCurrentTimelineRuleblockChange" />
                </v-col>
                <template v-if="index < currentTimeline.block_keys.length - 1">
                  <v-col :cols="2" class="d-flex align-center">
                    <div class="ml-4 mr-2">Days:</div>
                    <v-text-field
                      v-model.number="currentTimeline.days[index]"
                      class="pt-0"
                      hide-details
                      :error-messages="currentTimelineDaysErrors[index]"
                      @change="handleCurrentTimelineDaysChange"
                    />
                  </v-col>
                </template>
                <v-col
                  :cols="1"
                  :offset="index < currentTimeline.block_keys.length - 1 ? 6 : 8"
                  class="text-right"
                >
                  <v-icon
                    v-if="currentTimeline.block_keys.length -1 !== index"
                    class="mx-2 action-btn-primary" @click="handleCurrentTimelineDeleteRuleblockClick(index)">delete</v-icon>
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- new rule block -->
          <div class="d-flex px-6 mb-4 new-ruleblock">
            <div class="ruleblock-mark p-relative d-flex align-center">
              <v-icon>add</v-icon>
            </div>
            <div class="px-5 py-4 grow ruleblock-content">
              <v-row class="align-center">
                <v-col cols="3">
                  <v-autocomplete
                    :items="ruleBlocks"
                    v-model="currentTimelineNewRuleBlockKey"
                    item-value="block_key"
                    item-text="block_name"
                    class="pt-0"
                    placeholder="Select rule block"
                    hide-details
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                    @change="handleCurrentTimelineNewRuleblockChange" />
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-divider class="mt-6" />
    <h6 class="text-subtitle-1 font-weight-medium mt-8 ml-6">Timelines mapping</h6>
    <v-row>
      <v-col class="text-right pr-6">
        <template v-if="selectedTimelinesMappingRows.length">
          <v-icon
            key="action1"
            class="mx-2 rotate270 action-btn-primary"
            :disabled="hasInvalidMappings || (selectedTimelinesMappingRows[0].map_rank === 1) || (selectedTimelinesMappingRows[0].map_rank === maxMapRank)"
            @click="handleTimelinesMappingMoveUpClick"
          >last_page</v-icon>
          <v-icon
            key="action2"
            class="mx-2 rotate90 action-btn-primary"
            :disabled="hasInvalidMappings || (selectedTimelinesMappingRows[0].map_rank === maxRealMapRank) || (selectedTimelinesMappingRows[0].map_rank === maxMapRank)"
            @click="handleTimelinesMappingMoveDownClick"
          >last_page</v-icon>
          <v-icon key="action4" class="mx-2 action-btn-danger" :disabled="!selectedTimelinesMappingRows[0] || (selectedTimelinesMappingRows[0].map_rank === maxMapRank)" @click="handleDeleteTimelinesMappingClick">delete</v-icon>
        </template>
        <template v-else>
          <v-icon key="action4" class="mx-2 action-btn-primary" @click="handleAddTimelinesMappingClick">add</v-icon>

          <DataExport
            @item-click="downloadTimelinesMappingData"
          />
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="p-relative">
        <v-overlay absolute color="white" :value="stockManageStore.timelinesTab.loadingTimelinesMapping">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <data-tables
          style="width: 100%"
          class="timelines-mapping-table mb-4"
          :key="timelinesMappingListKey"
          :data="timelinesMappingList"
          :page-size="maxMapRank"
          :pagination-props="{ pageSizes: [maxMapRank], hideOnSinglePage: true }"
          :table-props="{ 'row-key': 'map_rank' }"
          @row-click="handleRowClick"
        >
          <el-table-column prop="timeline_key" width="55">
            <template v-slot="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-4 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column prop="map_rank" label="Rank">
            <template v-slot="scope" >
              {{ scope.row.map_rank }}
            </template>
          </el-table-column>
          <el-table-column prop="options_set_key" label="Option set">
            <template v-slot="scope">
              <v-autocomplete
                v-if="scope.row.map_rank !== maxMapRank"
                :items="stockManageStore.timelinesTab.timelinesOptionSetsList"
                :error-messages="scope.row.optionSetError"
                v-model="scope.row.options_set_key"
                placeholder="Select option set"
                item-text="set_name"
                item-value="options_set_key"
                dense
                class="pt-0 my-1 mr-4 text-body-2"
                hide-details
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @click.native.stop
                @change="handleTimelinesMappingChange" />
            </template>
          </el-table-column>
          <el-table-column prop="timeline_key" label="Timeline">
            <template v-slot="scope">
              <v-autocomplete
                :items="timelinesForSelect"
                v-model="scope.row.timeline_key"
                :error-messages="scope.row.timelineError"
                placeholder="Select timeline"
                dense
                class="pt-0 my-1 mr-4 text-body-2"
                hide-details
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @click.native.stop
                @change="handleTimelinesMappingChange" />
            </template>
          </el-table-column>
          <el-table-column prop="options_included" label="Options included">
            <template v-slot="scope">
              {{ scope.row.options_included }}
            </template>
          </el-table-column>
          <el-table-column prop="options_blocked" label="Options blocked">
            <template v-slot="scope">
              {{ scope.row.options_blocked }}
            </template>
          </el-table-column>
          <el-table-column prop="options_applied" label="Timeline applied">
            <template v-slot="scope">
              {{ scope.row.options_applied }}
            </template>
          </el-table-column>
          <el-table-column>
            <template v-slot="scope">
              <div class="text-right">
                <v-icon class="my-2 mx-2 action-btn-primary" @click="handleTimelinesMappingSearchClick(scope.row)">info</v-icon>
              </div>
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>

    <!-- Timeline mappings options search dialog -->
    <v-dialog
      :value="optionsSearchDialogVisible"
      content-class="align-self-start mt-14"
      scrollable
      width="1200px"
      max-width="90%"
      @input="val => optionsSearchDialogVisible = val"
    >
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="optionsSearchDialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium">Included and blocked options</v-card-title>
        <v-card-text class="px-0 text--primary">
          <v-divider />
          <TimelineMappingOptionsTable
            :title="`Options included: ${selectedTimelinesMappingOptionsIncluded.length}`"
            :options="selectedTimelinesMappingOptionsIncluded"
          />
          <v-divider />

          <div class="mt-4">
            <TimelineMappingOptionsTable
              :title="`Options blocked: ${selectedTimelinesMappingOptionsBlocked.length}`"
              :options="selectedTimelinesMappingOptionsBlocked"
            />
          </div>
          <v-divider />

          <div class="mt-4">
            <TimelineMappingOptionsTable
              :title="`Timeline applied: ${selectedTimelinesMappingOptionsApplied.length}`"
              :options="selectedTimelinesMappingOptionsApplied"
            />
          </div>
          <v-divider />

          <div class="mt-4">
            <TimelineMappingOptionsTable
              :title="`Timeline not applied: ${selectedTimelinesMappingOptionsNotApplied.length}`"
              :options="selectedTimelinesMappingOptionsNotApplied"
            />
          </div>
          <v-divider />
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                outlined
                @click="optionsSearchDialogVisible = false"
                class="mx-4"
              >Close</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ruleblock delete confirm -->
    <DeleteDialog
      :visible="ruleblockDeleteConfirmVisible"
      :loading="stockManageStore.timelinesTab.loadingRuleblocks"
      content-title="Delete ruleblock"
      content-text="Are you sure you want to delete this ruleblock?"
      @confirm="handleDeleteRuleblockConfirm"
      @cancel="ruleblockDeleteConfirmVisible = false"
    />

    <!-- timeline delete confirm -->
    <DeleteDialog
      :visible="timelineDeleteConfirmVisible"
      :loading="stockManageStore.timelinesTab.loadingTimelines"
      content-title="Delete timeline"
      content-text="Are you sure you want to delete this timeline?"
      @confirm="handleDeleteTimelineConfirm"
      @cancel="timelineDeleteConfirmVisible = false"
    />

    <!-- timeline mapping delete confirm -->
    <DeleteDialog
      :visible="timelineMappingDeleteConfirmVisible"
      :loading="stockManageStore.timelinesTab.loadingTimelines"
      content-title="Delete timeline mapping"
      content-text="Are you sure you want to delete this timeline mapping?"
      @confirm="handleDeleteTimelineMappingConfirm"
      @cancel="timelineMappingDeleteConfirmVisible = false"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import axios from '@/services/axios.js'
import { getHeadersWithAccessToken } from '@/variables'
import { downloadData } from '@/utils'
import DeleteDialog from '@/components/DeleteDialog.vue'
import RuleDataRow from '@/components/Assortment/RuleDataRow.vue'
import DataExport from '../DataExport'
import TimelineMappingOptionsTable from '@/components/Assortment/TimelineMappingOptionsTable'
import baseURLs from '@/api/APIBaseURLs'
import { useRulesStore } from '@/store/pinia/rulesStore'

import { useStockManageStore } from '@/store/pinia/stockManageStore'

export default {
  components: {
    DataExport,
    DeleteDialog,
    RuleDataRow,
    TimelineMappingOptionsTable,
  },
  setup () {
    return {
      rulesStore: useRulesStore(),
      stockManageStore: useStockManageStore()
    }
  },
  data () {
    return {
      currentRuleblock: null,
      currentTimeline: null,
      currentTimelineNewRuleBlockKey: null,
      rulesVisible: true,
      newRule: null,
      showAll: false,
      parametersVisible: true,
      currentTimelineDaysErrors: [],
      timelinesMappingListKey: 0,
      maxMapRank: 999,
      ruleblockDeleteConfirmVisible: false,
      timelineDeleteConfirmVisible: false,
      timelineMappingDeleteConfirmVisible: false,
      optionsSearchDialogVisible: false,
      optionsSearchDialogLoading: false,
      currentRuleblockNameError: null,
      currentTimelineNameError: null,
      selectedTimelinesMappingOptions: [],
    }
  },
  computed: {
    rules () {
      return this.rulesStore.rules
    },
    superuser () {
      return (localStorage.getItem('superuser') === 'true')
    },
    parameters () {
      return this.rulesStore.parameters
    },
    timelinesForSelect () {
      return this.timelines.map(item => ({
        text: item.timeline_name,
        value: item.timeline_key,
        disabled: !item.block_keys.length
      }))
    },
    ruleBlocks () {
      return this.stockManageStore.timelinesTab.ruleBlocksList.filter(rb => rb.active)
        .sort((a, b) => a.block_key - b.block_key)
    },
    timelines () {
      return this.stockManageStore.timelinesTab.timelinesList
    },
    timelinesMappingList () {
      return this.stockManageStore.timelinesTab.timelinesMappingList
    },
    selectedTimelinesMappingRows () {
      return this.timelinesMappingList.filter(r => r.selected)
    },
    canDeleteRuleblock () {
      const keysInUse = this.timelines.reduce((acc, cur) => acc.concat(cur.block_keys), [])

      return this.currentRuleblock !== null && !keysInUse.includes(this.currentRuleblock.block_key)
    },
    canDeleteTimeline () {
      const keysInUse = this.timelinesMappingList.map(x => x.timeline_key)

      return this.currentTimeline !== null && !keysInUse.includes(this.currentTimeline.timeline_key)
    },
    maxRealMapRank () {
      return Math.max(
        ...this.timelinesMappingList
          .filter(el => el.map_rank !== this.maxMapRank)
          .map(el => el.map_rank)
      )
    },
    hasInvalidMappings () {
      return this.timelinesMappingList.some(x => x.optionSetError || x.timelineError)
    },
    selectedTimelinesMappingOptionsIncluded () {
      return this.selectedTimelinesMappingOptions.filter(o => o.included === 1)
    },
    selectedTimelinesMappingOptionsBlocked () {
      return this.selectedTimelinesMappingOptions.filter(o => o.included === 0)
    },
    selectedTimelinesMappingOptionsApplied () {
      return this.selectedTimelinesMappingOptions.filter(o => o.applied === 1)
    },
    selectedTimelinesMappingOptionsNotApplied () {
      return this.selectedTimelinesMappingOptions.filter(o => o.applied === 0 && o.included === 1)
    },
  },
  methods: {
    selectLastRuleblock () {

      this.currentRuleblock = this.ruleBlocks.length ? this.ruleBlocks[this.ruleBlocks.length - 1] : null
    },
    selectLastTimeline () {
      this.currentTimeline = this.timelines.length ? this.timelines[this.timelines.length - 1] : null
    },
    handleAddRuleblockClick () {
      this.stockManageStore.setLoadingRuleblocks(true)
      this.stockManageStore.saveRuleblock({})
        .then(() => {
          this.stockManageStore.loadRuleblocks()
            .then(() => {
              this.stockManageStore.setLoadingRuleblocks(false)
              this.currentRuleblockNameError = null
              this.selectLastRuleblock()
            })
        })
    },
    handleAddTimelineClick () {
      this.stockManageStore.setLoadingTimelines(true)
      this.stockManageStore.saveTimeline({})
        .then(() => {
          this.stockManageStore.loadTimelines()
            .then(() => {
              this.stockManageStore.setLoadingTimelines(false)
              this.currentTimelineNameError = null
              this.selectLastTimeline()
            })
        })
    },
    handleDeleteRuleblockClick () {
      this.ruleblockDeleteConfirmVisible = true
    },
    handleDeleteRuleblockConfirm () {
      this.stockManageStore.setLoadingRuleblocks(true)
      this.stockManageStore.deleteRuleblock(this.currentRuleblock.block_key)
        .then(() => {
          this.stockManageStore.loadRuleblocks()
            .then(() => {
              this.stockManageStore.setLoadingRuleblocks(false)
              this.ruleblockDeleteConfirmVisible = false
              this.currentRuleblockNameError = null
              this.selectLastRuleblock()
            })
        })
    },
    handleDeleteTimelineClick () {
      this.timelineDeleteConfirmVisible = true
    },
    handleDeleteTimelineConfirm () {
      this.stockManageStore.setLoadingTimelines(true)
      this.stockManageStore.deleteTimeline(this.currentTimeline.timeline_key)
        .then(() => {
          this.stockManageStore.loadTimelines()
            .then(() => {
              this.stockManageStore.setLoadingTimelines(false)
              this.timelineDeleteConfirmVisible = false
              this.currentTimelineNameError = null
              this.selectLastTimeline()
            })
        })
    },
    handleCurrentTimelineNewRuleblockChange () {
      this.currentTimeline.block_keys.push(this.currentTimelineNewRuleBlockKey)
      this.currentTimeline.days.push(1)
      this.$nextTick(() => {
        this.currentTimelineNewRuleBlockKey = null
      })

      this.stockManageStore.saveTimeline(this.currentTimeline)
    },
    handleCurrentTimelineRuleblockChange () {
      if (this.validateCurrentTimelineRuleblockDays()) {
        this.stockManageStore.saveTimeline(this.currentTimeline)
      }
    },
    handleCurrentTimelineDaysChange () {
      if (this.validateCurrentTimelineRuleblockDays()) {
        this.stockManageStore.saveTimeline(this.currentTimeline)
      }
    },
    handleCurrentTimelineDeleteRuleblockClick (index) {
      const isLastBlock = index === this.currentTimeline.block_keys.length - 1

      this.$delete(this.currentTimeline.block_keys, index)
      this.$delete(this.currentTimeline.days, index)

      if (isLastBlock && this.currentTimeline.days.length) {
        this.currentTimeline.days.splice(-1, 1, 1)
        this.$set(this.currentTimelineDaysErrors, index, null)
      }

      if (this.validateCurrentTimelineRuleblockDays()) {
        this.stockManageStore.saveTimeline(this.currentTimeline)
      }
    },
    handleCurrentRuleblockNameChange (val) {
      // this event may be triggered when ruleblock is selected or when ruleblock name is changed
      if (typeof val === 'object') {
        this.currentRuleblock = val
      } else {
        this.currentRuleblock.block_name = val

        if (this.validateRuleblock()) {
          this.stockManageStore.saveRuleblock(this.currentRuleblock)
        }
      }
    },
    handleCurrentTimelineNameChange (val) {
      // this event may be triggered when timeline is selected or when timeline name is changed
      if (typeof val === 'object') {
        this.currentTimeline = val
      } else {
        this.currentTimeline.timeline_name = val

        if (this.validateTimeline()) {
          this.stockManageStore.saveTimeline(this.currentTimeline)
        }
      }
    },
    handleNewRuleChanged () {
      this.currentRuleblock.rules.push({ ...this.newRule })
      this.$nextTick(() => {
        this.newRule = null
      })

      this.stockManageStore.saveRuleblock(this.currentRuleblock)
    },
    handleRuleDataChanged () {
      this.stockManageStore.saveRuleblock(this.currentRuleblock)
    },
    handleCurrentRuleblockChange () {
      this.stockManageStore.saveRuleblock(this.currentRuleblock)
    },
    handleRuleDeleted (index) {
      this.$delete(this.currentRuleblock.rules, index)
      this.stockManageStore.saveRuleblock(this.currentRuleblock)
    },
    validateCurrentTimelineRuleblockDays () {
      let isValid = true

      this.currentTimeline.days.forEach((d, index) => {
        this.$set(this.currentTimelineDaysErrors, index, null)

        if (isNaN(d) || d <= 0) {
          this.$set(this.currentTimelineDaysErrors, index, 'Should be > 0')
          isValid = false
        }
      })

      return isValid
    },
    validateRuleblock () {
      this.currentRuleblockNameError = null

      const usedNames = this.ruleBlocks
        .filter(x => x.active)
        .filter(x => x.block_key !== this.currentRuleblock.block_key)
        .map(x => x.block_name)

      if (this.currentRuleblock.block_name === '' || usedNames.includes(this.currentRuleblock.block_name)) {
        this.currentRuleblockNameError = 'Should be unique and not empty'
      }

      return !this.currentRuleblockNameError
    },
    validateTimeline () {
      this.currentTimelineNameError = null

      const usedNames = this.timelines
        .filter(x => x.timeline_key !== this.currentTimeline.timeline_key)
        .map(x => x.timeline_name)

      if (this.currentTimeline.timeline_name === '' || usedNames.includes(this.currentTimeline.timeline_name)) {
        this.currentTimelineNameError = 'Should be unique and not empty'
      }

      return !this.currentTimelineNameError
    },
    handleSelectRowChange (val, row) {
      this.selectedTimelinesMappingRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
    },
    handleRowClick (row, col, event) {
      if (row.map_rank !== this.maxMapRank) {
        event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
      }
    },
    handleAddTimelinesMappingClick () {
      const newMapping = {
        map_rank: this.maxRealMapRank + 1,
        options_set_key: null,
        timeline_key: null,
      }
      const payload = this.timelinesMappingList.concat([newMapping])

      this.validateTimelineMapping(payload)
      this.stockManageStore.setTimelinesMapping(_.sortBy(payload, 'mapRank'))
    },
    handleTimelinesMappingSearchClick (row) {
      const url = baseURLs.manageAPI + '/data/stock/assortment/get_timelines_mapping_options'

      this.optionsSearchDialogVisible = true
      this.optionsSearchDialogLoading = true
      this.selectedTimelinesMappingOptions = []

      axios.post(url, {
        map_rank: row.map_rank,
      }, {
        headers: getHeadersWithAccessToken()
      })
        .then(response => {
          this.selectedTimelinesMappingOptions = response.data.data
        })
        .catch(error => {
          console.log(error)
          throw error
        })
        .finally(() => {
          this.optionsSearchDialogLoading = false
        })
    },
    handleDeleteTimelinesMappingClick () {
      this.timelineMappingDeleteConfirmVisible = true
    },
    async handleDeleteTimelineMappingConfirm () {
      const selectedMapping = this.selectedTimelinesMappingRows.length && this.selectedTimelinesMappingRows[0]
      const newItems = this.timelinesMappingList
        .filter(x => (x.map_rank !== selectedMapping.map_rank) || (x.map_rank === this.maxMapRank))
      const payload = []

      // recalculate new ranks
      newItems.forEach((el, i) => {
        payload.push({
          ...el,
          map_rank: (el.map_rank !== this.maxMapRank ? i + 1 : this.maxMapRank),
        })
      })

      this.validateTimelineMapping(payload)
      await this.saveTimelinesMapping(payload)

      this.timelineMappingDeleteConfirmVisible = false
    },
    validateTimelineMapping (items) {
      items
        .filter(item => item.map_rank !== this.maxMapRank)
        .forEach(item => {
          item.optionSetError = null
          item.timelineError = null

          if (!item.options_set_key) {
            item.optionSetError = 'Required'
          }

          if (!item.timeline_key) {
            item.timelineError = 'Required'
          }
        })
    },
    async saveTimelinesMapping (payload) {
      const validMappings = payload.filter(x => !x.optionSetError && !x.timelineError)
      const invalidMappings = payload.filter(x => x.optionSetError || x.timelineError)

      this.stockManageStore.setLoadingTimelinesMapping(true)

      await this.stockManageStore.saveTimelinesMapping(validMappings)
      await this.stockManageStore.getTimelineMappings()

      const newMappings = this.timelinesMappingList.concat(invalidMappings)
      this.stockManageStore.setTimelinesMapping(_.sortBy(newMappings, 'mapRank'))
      this.stockManageStore.setLoadingTimelinesMapping(false)
    },
    handleTimelinesMappingChange () {
      this.validateTimelineMapping(this.timelinesMappingList)
      this.saveTimelinesMapping(this.timelinesMappingList)
    },
    handleTimelinesMappingMoveUpClick () {
      const selectedMapping = this.selectedTimelinesMappingRows.length && this.selectedTimelinesMappingRows[0]
      const payload = []

      if (selectedMapping.map_rank === 1) {
        return
      }

      this.timelinesMappingList.forEach((el, i) => {
        if (el.map_rank === selectedMapping.map_rank - 1) {
          payload[i + 1] = {
            ...el,
            map_rank: el.map_rank + 1
          }
        } else if (el.map_rank === selectedMapping.map_rank) {
          payload[i - 1] = {
            ...el,
            map_rank: el.map_rank - 1
          }
        } else {
          payload[i] = el
        }
      })

      this.validateTimelineMapping(payload)
      this.saveTimelinesMapping(payload)
    },
    handleTimelinesMappingMoveDownClick () {
      const selectedMapping = this.selectedTimelinesMappingRows.length && this.selectedTimelinesMappingRows[0]
      const payload = []

      if (selectedMapping.map_rank === this.maxRealMapRank) {
        return
      }

      this.timelinesMappingList.forEach((el, i) => {
        if (el.map_rank === selectedMapping.map_rank) {
          payload[i + 1] = {
            ...el,
            map_rank: el.map_rank + 1
          }
        } else if (el.map_rank === selectedMapping.map_rank + 1) {
          payload[i - 1] = {
            ...el,
            map_rank: el.map_rank - 1
          }
        } else {
          payload[i] = el
        }
      })

      this.validateTimelineMapping(payload)
      this.saveTimelinesMapping(payload)
    },
    downloadTimelinesMappingData (sep) {
      const columns = ['map_rank', 'options_set_key', 'timeline_key', 'options_included', 'options_blocked']
      const data = this.timelinesMappingList.map(
        row => {
          const optSet = this.stockManageStore.timelinesTab.timelinesOptionSetsList.find(x => x.options_set_key === row.options_set_key)
          const timeline = this.timelines.find(x => x.timeline_key === row.timeline_key)

          row.options_set_key = optSet ? optSet.set_name : row.options_set_key
          row.timeline_key = timeline ? timeline.timeline_name : row.timeline_key

          return columns.reduce(
            (acc, cur) => Object.assign(acc, { [cur]: row[cur] }),
            {}
          )
        }
      )

      downloadData(sep, data, 'assortment_timelines_mapping')
    },
  },

}
</script>

<style lang="scss">
.assortment-timelines {
    .timeline-ruleblocks {
        .ruleblock-mark {
            width: 40px;
            &:before {
                content: '';
                position: absolute;
                border-left: 1px solid var(--border-color);
                height: 50px;
                left: 11px;
                top: 50px;
            }
            &:after {
                position: absolute;
                content: '▾';
                font-size: 20px;
                color: var(--border-color);
                left: 3px;
                top: 86px;
            }
        }
        .ruleblock-content {
            border: 1px solid var(--border-color);
        }
        .new-ruleblock {
            .ruleblock-mark {
                &:before,
                &:after {
                    content: none;
                }
            }
        }
    }
    .slider-value-from,
    .slider-value-to {
        color: #666;
        font-size: 14px;
    }
    .collapsed {
        .panel-icon {
            transform: rotate(-180deg);
        }
    }
    .timelines-mapping-table {
        tr:last-child {
            td {
                border-bottom: 0;
            }
        }
    }
}
</style>
