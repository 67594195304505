<template>
  <div class="transfers-build p-relative">
    <v-overlay absolute color="white" :value="loadingTransferBuildDetails">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="my-0">
      <v-col class="text-right px-8">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('tableFilters', Object.keys(currentPageRows[0]), val)"
        />
        <DataExport
          @item-click="downloadData"
        />
      </v-col>
    </v-row>
    <data-tables
      ref="transfersDetailsTable"
      class="table"
      :data="currentPageRows"
      @filtered-data="filteredData = $event"
      @update:currentPage="currentPage = $event"
      @update:pageSize="pageSize = $event"
      :filters="tableFilters"
      :table-props="{'span-method': objectSpanMethod, 'row-index': 'option_key'}"
      :page-size="parseInt(pageSize)"
      :pagination-props="{
        pageSizes: pageSizes,
        class: 'el-pagination text-right mt-6 mb-4 mr-2' + (currentPageRows.length <= 200 ? ' d-none' : ''),
      }"
    >
      <el-table-column label="Name" prop="option_name" width="250">
        <template slot-scope="scope">
          <OptionDataCell
            :option="{...scope.row, price: scope.row.osp === scope.row.asp ? scope.row.asp : `${scope.row.osp} / ${scope.row.asp}`}"
            open-in-new-tab
          />
        </template>
      </el-table-column>
      <el-table-column v-if="type === 'receive'" label="Store" prop="store_key">
        <template slot-scope="scope">
          <div class="one-line" v-if="findStore(scope.row.store_key)">
            {{ findStore(scope.row.store_key).store_name }}
          </div>
          <span v-else>{{scope.row.store_key}}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="['send', 'overview'].includes(type)" label="To store" prop="to_store_key">
        <template slot-scope="scope">
          <div class="one-line" v-if="findStore(scope.row.to_store_key)">
            {{ findStore(scope.row.to_store_key).store_name }}
          </div>
          <span v-else>{{scope.row.to_store_key}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Sku name" prop="sku_name">
        <template slot-scope="scope">
          {{ scope.row.sku_name }}
        </template>
      </el-table-column>
      <el-table-column label="Stock" prop="stock">
        <template slot-scope="scope">
          {{ scope.row.stock }}
        </template>
      </el-table-column>
      <el-table-column label="To stock" prop="to_stock">
        <template slot-scope="scope">
          {{ scope.row.to_stock | formatThousands }}
        </template>
      </el-table-column>
      <el-table-column label="Fairshare" prop="fairshare">
        <template slot-scope="scope">
          {{ scope.row.fairshare }}
        </template>
      </el-table-column>
      <el-table-column label="To fairshare" prop="to_fairshare">
        <template slot-scope="scope">
          {{ scope.row.to_fairshare }}
        </template>
      </el-table-column>
      <el-table-column label="Transfer" prop="pieces">
        <template slot-scope="scope">
          <v-text-field
            hide-details="auto"
            class="pa-0 mt-0 mb-2"
            :value="getValue(scope)"
            @input="changeTransfer(scope, parseFloat($event || 0))"
            @change="handlePiecesChange(scope.row.option_key)"
            :error-messages="getErrors(scope)"
          ></v-text-field>
        </template>
      </el-table-column>
      <el-table-column label="Action">
        <template slot-scope="scope">
          <div class="text-center">
            <v-btn
              depressed
              class="text-capitalize white--text"
              :class="{
                'success': currentTransferModes[scope.row.option_key] === 'optimal',
                'warning': currentTransferModes[scope.row.option_key] === 'none',
                'grey lighten-2': currentTransferModes[scope.row.option_key] === 'custom',
              }"
              @click="handleTransferModeClick(scope.row.option_key)"
            >{{ currentTransferModes[scope.row.option_key] }}</v-btn>
            <div class="mt-2">Send: {{ totalDeltasByOption[scope.row.option_key].send }}</div>
          </div>
        </template>
      </el-table-column>
    </data-tables>
    <div class="pt-4 transfers-summary">
      <div class="d-flex justify-end align-center pr-1">
        <div>
          <div>Selected pieces: {{ totalPieces | formatThousands }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { downloadData } from '@/utils'
import OptionDataCell from '@/components/Transfers/OptionDataCell.vue'
import DataExport from '../DataExport'
import columnFilters from '../../mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'

export default {
  mixins: [ columnFilters ],
  name: 'TransfersBuildDetails',
  props: {
    type: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    loadingTransferBuildDetails: {
      type: Boolean,
      default: false,
    },
    transferBuildDetailsData: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 10,
      transfersChangedData: {},
      currentTransferModes: {},
      filteredData: [],
      pageSizes: [5, 10, 15, 25, 50],
      tableFilters: [],
    }
  },
  components: {
    DataExport,
    OptionDataCell,
    TableSearch
  },
  watch: {
    currentPageRows: {
      handler (val) {
        const rowsLength = this.currentPageRows.length
        if (!rowsLength || this.alreadyChanged) {
          return
        }
        if (val.length) {
          _
            .uniqBy(val, 'option_key')
            .forEach(item => {
              this.$set(this.currentTransferModes, item.option_key, 'optimal')
            })
        }
        this.setTransfersPieces()
        this.alreadyChanged = true
        if (rowsLength <= 200) {
          this.pageSizes = [this.currentPageRows.length]
          this.pageSize = rowsLength
        } else {
          this.pageSizes = [200, ...this.pageSizes]
          this.pageSize = 200
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['findStoreWithKey']),
    currentPageRows () {
      return this.transferBuildDetailsData
    },
    totalDeltasByOption () {
      const result = {}

      this.currentPageRows.forEach((row, index) => {
        if (!result[row.option_key]) {
          result[row.option_key] = {
            send: 0,
            keep: 0,
          }
        }

        result[row.option_key].send += this.getValue({ row, $index: index })
        result[row.option_key].keep += row.stock - this.getValue({ row, $index: index })
      })

      return result
    },
    totalPieces () {
      let count = 0
      this.currentPageRows.forEach((item, index) => {
        count += this.getValue({ row: item, $index: index })
      }, 0)
      return count
    },
    // needed for rowspan
    optionsRowsCount () {
      const counts = {}

      this.filteredData.forEach((row, i) => {
        if (!counts[row.option_key]) {
          counts[row.option_key] = {
            count: 1,
            startIndex: i,
          }
        } else {
          counts[row.option_key].count++
        }
      })

      return counts
    },
  },
  methods: {
    findStore (storeKey) {
      return this.findStoreWithKey(storeKey)
    },
    getValue (scope, isRowIndex = true) {
      const rowIndex = isRowIndex ? this.pageSize * (this.currentPage - 1) + scope.$index : scope.$index
      switch (this.currentTransferModes[scope.row.option_key]) {
      case 'optimal':
        return scope.row.pieces
      case 'custom':
        return this.transfersChangedData[rowIndex] === undefined ? scope.row.pieces : this.transfersChangedData[rowIndex]
      default :
        return 0
      }
    },
    changeTransfer (scope, value) {
      const rowIndex = this.pageSize * (this.currentPage - 1) + scope.$index

      this.$set(this.transfersChangedData, rowIndex, value)
    },
    handleTransferModeClick (optionKey) {
      const currentMode = this.currentTransferModes[optionKey]
      this.currentTransferModes[optionKey] = currentMode === 'optimal' ? 'none' : 'optimal'
      this.currentPageRows.forEach((row, index) => {
        if(row.option_key !== optionKey){
          return
        }
        let qty = row.pieces

        if (this.currentTransferModes[optionKey] === 'none') {
          qty = 0
        }

        this.$set(this.transfersChangedData, index, qty)
      })

      this.$emit('pieces-changed')
    },
    getTransfersForAccept () {
      const table = this.currentPageRows
      return table.map((item, index) => ({ ...item, pieces: this.getValue({ row: item, $index: index }, false) }))
    },
    setTransfersPieces () {
      this.transfersPieces = this.currentPageRows.reduce((prev, cur, index) => {
        const toStoreLength = 0
        const oldValue = toStoreLength === 1 ? cur.stock : cur.pieces
        return { ...prev, [index]: oldValue }
      }, {})
    },
    getErrors (scope) {
      if (!this.transfersPieces) {
        return
      }
      const index = this.pageSize * (this.currentPage - 1) + scope.$index

      const value = this.getValue(scope)
      this.errors = this.errors || {}
      if (!Number.isInteger(value)) {
        this.errors[index] = true
        this.$emit('error-added', this.errors)
        return 'Must be integer'
      }
      if (value < 0) {
        this.errors[index] = true
        this.$emit('error-added', this.errors)
        return 'Higher or equal to 0'
      }
      if (value > this.transfersPieces[index]) {
        this.errors[index] = true
        this.$emit('error-added', this.errors)
        return 'Lower or equal to ' + this.transfersPieces[index]
      }

      delete this.errors[index]
      this.$emit('error-added', this.errors)
      return null
    },
    objectSpanMethod ({ row, rowIndex, columnIndex }) {
      const pageIndex = this.pageSize * (this.currentPage - 1)
      const { count, startIndex } = this.optionsRowsCount[row.option_key]
      const actionColumns = this.type === 'combinations' ? 7 : 8
      if (columnIndex === 0 || columnIndex === actionColumns) {
        if (startIndex === (rowIndex + pageIndex)) { // This means it is the first row of a new product
          return {
            rowspan: count, // Set rowspan equal to number of rows coming with this product
            colspan: 1
          }
        } else if (!rowIndex) {
          return {
            rowspan: (startIndex + count > (pageIndex + this.pageSize)) ? count : (startIndex + count) % pageIndex,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      return {
        rowspan: 1,
        colspan: 1,
      }
    },
    downloadData (sep) {
      downloadData(sep, this.currentPageRows, 'Transfers_build_details')
    },
    handlePiecesChange (option_key) {
      this.currentTransferModes[option_key] = 'custom'
      this.$emit('pieces-changed')
    }
  },
}
</script>

<style lang="scss">
  .transfers-build {
    .el-table .cell {
      padding: 4px 10px;
      overflow: hidden;
    }
  }

  .transfers-build {
    thead {
      th {
        padding: 12px 0;
      }
    }

    .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
      width: 4px;
    }
    .table .el-table__body-wrapper {
      max-height: 60vh;
      overflow-y: auto;
    }

    .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    }

    .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 3px;
    }
    .pagination-wrap {
      background: transparent;
    }
    .el-pagination * {
      background: transparent !important;
    }
    .v-select__selection {
      font-size: 14px;
    }
  }

</style>
