<template>
  <v-dialog
    v-model="isOpen"
    persistent
    scrollable
    content-class="align-self-start mt-14"
    width="600px"
    max-width="90%">
    <v-card class="rounded-0">
      <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">
        {{ `${isEdit ? 'Edit markdown moment' : 'Add markdown moment'}` }}
      </v-card-title>
      <v-divider/>
      <v-card-text class="px-0 text--primary">
        <div class="container px-12">
          <template v-if="currentMoment">
            <v-row class="align-baseline mt-4">
              <v-col class="py-1">
                <v-text-field
                  :rules="[requiredField]"
                  v-model="currentMoment.markdown_moment_name"
                  required
                  label="Name"
                />
              </v-col>
            </v-row>
            <v-row class="align-baseline">
              <v-col class="py-1">
                <collapsible-select
                  v-model="currentMoment.weeks"
                  :rules="[requiredField, maxItemsSelectedField]"
                  :items="weekOptions"
                  label="Weeks"
                  multiple
                  autocomplete
                  show-select-all
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }" />
              </v-col>
            </v-row>
            <v-row class="align-baseline">
              <v-col class="py-1">
                <v-combobox
                  v-model="currentMoment.discounts"
                  label="Discounts"
                  multiple
                  small-chips
                  hint="Enter discount value and press enter"
                  :rules="[numberRule, requiredField]"
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                />
              </v-col>
            </v-row>
            <v-row class="align-baseline">
              <v-col class="py-1">
                <collapsible-select
                  v-model="currentMoment.options_set_keys"
                  :items="optionSets.filter(ss => ss.label.includes('all'))"
                  :rules="[requiredField, maxItemsSelectedField]"
                  item-text="set_name"
                  item-value="options_set_key"
                  autocomplete
                  show-select-all
                  :notClearable="true"
                  class="text-body-2"
                  label="Option sets"
                />
              </v-col>
            </v-row>
            <v-row class="align-baseline">
              <v-col class="py-1">
                <collapsible-select
                  v-model="currentMoment.stores_set_keys"
                  :items="storeSets.filter(ss => ss.label.includes('all'))"
                  :rules="[requiredField, maxItemsSelectedField]"
                  item-text="set_name"
                  item-value="stores_set_key"
                  autocomplete
                  show-select-all
                  :notClearable="true"
                  class="text-body-2"
                  label="Store sets"
                />
              </v-col>
            </v-row>
            <v-row class="align-baseline">
              <v-col class="py-1">
                <v-select
                  :multiple="false"
                  v-model="currentMoment.price_logic"
                  :items="['decimal', 'points', 'free']"
                  :notClearable="true"
                  class="text-body-2"
                  label="Price logic"
                />
              </v-col>
            </v-row>
            <v-row class="align-baseline " v-if="currentMoment.price_logic === 'points'">
              <v-col class="py-1">
                <v-combobox
                  v-model="currentMoment.price_points"
                  label="Points"
                  multiple
                  small-chips
                  hint="Enter point value and press enter"
                  :rules="[requiredField]"
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                />
              </v-col>
            </v-row>
            <v-row class="align-baseline" v-else-if="currentMoment.price_logic === 'decimal'">
              <v-col class="py-1">
                <v-select
                  :multiple="false"
                  v-model="currentMoment.price_decimal"
                  :items="[99, 95, '00']"
                  :notClearable="true"
                  class="text-body-2"
                  label="Decimal"
                />
              </v-col>
            </v-row>
          </template>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2 px-4">
          <v-col>
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  depressed
                  text
                  @click="$emit('close')"
                >Cancel</v-btn>
                <v-btn
                  color="primary"
                  depressed
                  outlined
                  :disabled="!isValid || maxSelectionExceeded"
                  @click="$emit('save')"
                  class="mx-4"
                >Save</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CollapsibleSelect from '@/components/CollapsibleSelect'
import { getWeeksSelectorOptions } from '@/utils'
export default {
  name: 'MarkdownAddEditModal',
  components: { CollapsibleSelect },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    currentMoment: {
      type: Object,
      default: () => ({}),
    },
    storeSets: {
      type: Array,
      default: () => [],
    },
    optionSets: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    weekOptions () {
      return getWeeksSelectorOptions(-1, 2, true).map(week => ({
        text: week.text,
        value: Number(week.value),
      }))
    },
    isValid () {
      return !!(this.currentMoment.markdown_moment_name &&
        this.currentMoment.weeks && this.currentMoment.weeks.length &&
        this.currentMoment.discounts && this.currentMoment.discounts.length &&
        this.currentMoment.options_set_keys && this.currentMoment.options_set_keys.length &&
        this.currentMoment.stores_set_keys && this.currentMoment.stores_set_keys.length &&
        (this.currentMoment.price_logic !== 'points' ||
          (this.currentMoment.price_points && this.currentMoment.price_points.length)))
    },
    maxSelectionExceeded () {
      return (this.currentMoment.weeks.length > 10 || this.currentMoment.options_set_keys.length > 10 || this.currentMoment.stores_set_keys.length > 10)
    },
  },
  methods: {
    numberRule (value) {
      const allIntegers = value.every(item => Number.isInteger(Number(item)))
      if (!allIntegers) {
        return 'Please enter only integers'
      }
      const betweenZeroAndOneHundred = value.every(item => item >= 0 && item <= 100)
      if (!betweenZeroAndOneHundred) {
        return 'Please enter an integer between 1 and 100'
      }
      return true
    },
    requiredField (value) {
      if (value === null || value === undefined || value === '') {
        return 'This field is required'
      }
      if (value.length === 0) {
        return 'This field is required'
      }
      return true
    },
    maxItemsSelectedField (value) {
      if (value.length > 10) {
        return 'Please select 10 items or less'
      }
      return true
    },
  },
}
</script>

<style scoped>

</style>
