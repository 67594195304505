<template>
  <div id="strategy-otb-opportunities">
    <h6 class="text-subtitle-1 font-weight-medium">OTB Opportunities</h6>

    <div class="mx-n6">
      <data-tables
        style="width: 100%"
        class="table"
        :data="tableData"
        :page-size="tableData.length"
        :pagination-props="{
          pageSizes: [tableData.length],
          class: 'd-none'
        }"
      >
        <el-table-column
          v-for="(c, index) in (tableData.length ? Object.keys(tableData[0]) : [])"
          :key="c"
          :prop="c"
          :label="c | formatString"
          :label-class-name="index === 0 ? 'text-left': 'text-center'"
          sortable
          :column-key="c"
        >
          <template slot-scope="scope">
            <div :style="getCellStyle(scope.row, c)">
              {{ formatMetric(scope.row[c], c) }}
            </div>
          </template>
        </el-table-column>
        <template v-slot:append>
          <data-tables
            :data="[tableTotal]"
            class="total-table"
            :pagination-props="{class: 'd-none'}"
          >
            <el-table-column
              class-name="total-row_column pl-1"
              label-class-name="d-none"
              align="left"
              v-for="c in (tableData.length ? Object.keys(tableData[0]) : [])"
              :prop="c"
              :key="c"
            >
              <template slot-scope="scope">
                <div class="text-center">
                  {{ formatMetric(scope.row[c], c) }}
                </div>
              </template>
            </el-table-column>
          </data-tables>
        </template>
      </data-tables>
    </div>
  </div>
</template>

<script>
import { colormapOTB } from '@/variables'
import { useCubesStore } from '@/store/pinia/cubesStore'

export default {
  name: 'StrategyOtbOpportunities',
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  setup () {
    return {
      cubesStore: useCubesStore(),
    }
  },
  computed: {
    metricFormats () {
      return this.cubesStore.metricFormat
    },
    otbMinMax () {
      return {
        min: Math.min.apply(Math, this.tableData.map(r => r.OTB)),
        max: Math.max.apply(Math, this.tableData.map(r => r.OTB)),
      }
    },
    tableTotal () {
      return []
    },
  },
  methods: {
    formatMetric (value, metricName) {
      let format = this.metricFormats[metricName.toLowerCase().replace(' ', '_')]

      if (this.currentMetric === 'pieces' && typeof value === 'number') {
        format = 'integer'
      }

      return format ? this.$options.filters[format](value) : value
    },
    getCellStyle (row, property) {
      const style = typeof row[property] === 'number' ? {
        textAlign: 'center'
      } : {}

      if (property === 'OTB') {
        Object.assign(style, {
          background: this.getCellGradeColor(row[property]),
          borderRadius: '4px',
          color: '#fff',
          padding: '13px 0',
        })
      }

      return style
    },
    getCellGradeColor (value) {
      const min = this.otbMinMax.min
      const max = this.otbMinMax.max
      const result = min === max ? 0.5 : ((value - min) / (max - min))

      return colormapOTB(result)
    },
  },
}
</script>

<style lang="scss">
#strategy-otb-opportunities {
    .el-table td {
        padding: 0;
    }
    .el-table__append-wrapper {
        position: sticky;
        bottom: 0;
    }
    .table .el-table__body-wrapper {
        max-height: 50vh;
        overflow-y: auto;
    }
    .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
        width: 4px;
    }
    .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    }
    .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 3px;
    }
    .total-row_column {
        background-color: #f5f7fa;
    }
}
</style>
