<template>
  <v-container>
    <v-row class="pa-0 pt-4">
      <div class="col text-left py-0">
        <h6 class="text-subtitle-1 font-weight-medium pb-1 px-7">Selected Items</h6>
      </div>
      <v-col class="text-right pb-0 pt-5">
        <TableSearch
          class="mx-2"
          @searchChanged="val => this.$refs.selectedItemsTable.handleSearchChanged('itemsTableFilters', columnsToSearch, val)"
        />
      </v-col>
    </v-row>
    <v-row class="px-0" >
      <v-col class="px-0">
        <shop-items-table
          ref="selectedItemsTable"
          :items="items"
          :scenario-key="scenarioKey"
          :budget-key="budgetKey"
          :store-sets="storeSets"
          :impact="true"
          :selectable="false"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopItemsTable from '@/components/BuyManageBudgets/ShopItemsTable'
import TableSearch from '@/components/TableSearch.vue'

export default {
  name: 'ScenarioMergeImpact',
  components: { ShopItemsTable, TableSearch },
  data () {
    return {
      columnsToSearch: ['shop_item_key', 'option_name', 'week_min_formatted', 'stores_set_keys', 'drop_name', 'tags', 'status'],
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    storeSets: {
      type: Array,
      default: () => [],
    },
    budgetKey: {
      type: Number,
      required: true
    },
    scenarioKey: {
      type: Number,
      default: 0
    },

  },
  methods: {
    //
  }
}
</script>

<style scoped>

</style>
