<template>
  <div id="strategy-chunks-charts" class="mx-n6">
    <v-carousel
      height="auto"
      class="pb-15"
      delimiter-icon="circle"
      :perPage="1"
      light
      hide-delimiter-background
      :show-arrows="false"
    >
      <v-carousel-item eager>
        <h4 class="text-center">Intake</h4>
        <div
          class="chart-container mt-4 mx-4"
          style="position: relative; height: 350px;"
        >
          <canvas id="intakeChart"></canvas>
        </div>
      </v-carousel-item>
      <v-carousel-item eager>
        <h4 class="text-center">Options</h4>
        <div
          class="chart-container mt-4 mx-4"
          style="position: relative; height: 350px;"
        >
          <canvas id="gridChart"></canvas>
        </div>
      </v-carousel-item>
      <v-carousel-item eager>
        <h4 class="text-center">Stock</h4>
        <div
          class="chart-container mt-4 mx-4"
          style="position: relative; height: 350px;"
        >
          <canvas id="stockChart"></canvas>
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { Chart } from 'chart.js/auto'
import { formatLargeNumber } from '@/utils'
import { chartColors } from '@/variables'

export default {
  name: 'StrategyChunksCharts',
  props: {
    chartsData: {
      type: Object || null,
      default: null,
    }
  },
  data () {
    this.chartOptions = {
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      hover: {
        mode: 'index',
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          grid: {
            display: false
          },
          ticks: {
            font: {
              size: 14,
            }
          },
          offset: true,
        },
        y: {
          position: 'left',
          title: {
            display: false,
          },
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            callback: (value)=> formatLargeNumber(value),
            padding: 16,
            font: {
              size: 14,
            }
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: context => {
              return this.$options.filters.formatThousands(context.raw)
            },
          }
        },
      },
    }
    this.charts = {
      intakeChart: null,
      gridChart: null,
      stockChart: null,
    }

    return {
    }
  },
  watch: {
    'chartsData.intakeChart': {
      handler: function () {
        this.drawIntakeChart()
      },
    },
    'chartsData.gridChart': {
      handler: function () {
        this.drawGridChart()
        this.drawStockChart()
      },
    },
  },
  methods: {
    drawCharts () {
      this.drawIntakeChart()

      if (this.chartsData.gridChart) {
        this.drawGridChart()
      }
    },
    drawIntakeChart () {
      if (this.charts.intakeChart) {
        this.charts.intakeChart.destroy()
      }

      const ctx = document.getElementById('intakeChart')
      this.charts.intakeChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.chartsData.intakeChart.week_numbers.map(this.$options.filters.formatWeekNumber),
          datasets: [{
            label: 'Intake',
            data: this.chartsData.intakeChart.intake,
            borderColor: '#007AFF55',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            pointRadius: null,
            tension: 0.4,
          }, {
            label: 'Intake cur',
            data: this.chartsData.intakeChart.intake_cur,
            borderColor: '#007AFF',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            pointRadius: null,
            tension: 0.4,
          }, {
            label: 'OTB',
            data: this.chartsData.intakeChart.otb,
            borderColor: '#FF9800',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            pointRadius: null,
            tension: 0.4,
          }],
        },
        options: this.chartOptions,
      })
    },
    drawGridChart () {
      if (this.charts.gridChart) {
        this.charts.gridChart.destroy()
      }

      const ctx = document.getElementById('gridChart')
      const options = cloneDeep(this.chartOptions)

      options.scales.y.stacked = true

      this.charts.gridChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.chartsData.gridChart.week_numbers.map(this.$options.filters.formatWeekNumber),
          datasets: this.chartsData.gridChart.dimensions.map((d, i) => {
            const color = chartColors[i % (chartColors.length - 1)]

            return {
              label: d,
              data: this.chartsData.gridChart.options[i],
              borderColor: color,
              borderWidth: 2,
              backgroundColor: color,
              pointRadius: null,
              fill: true,
              tension: 0.4,
            }
          }),
        },
        options,
      })
    },
    drawStockChart () {
      if (this.charts.stockChart) {
        this.charts.stockChart.destroy()
      }

      const ctx = document.getElementById('stockChart')
      const options = cloneDeep(this.chartOptions)

      options.scales.y.stacked = true

      this.charts.stockChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.chartsData.gridChart.week_numbers.map(this.$options.filters.formatWeekNumber),
          datasets: this.chartsData.gridChart.dimensions.map((d, i) => {
            const color = chartColors[i % (chartColors.length - 1)]

            return {
              label: d,
              data: this.chartsData.gridChart.stock[i],
              borderColor: color,
              borderWidth: 2,
              backgroundColor: color,
              pointRadius: null,
              fill: true,
              tension: 0.4,
            }
          }),
        },
        options,
      })
    },
    updateCharts () {
      this.drawCharts()
    }
  },
}
</script>

<style lang="scss">
#strategy-chunks-charts {
}
</style>
