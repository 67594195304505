import {defineStore} from 'pinia'
import * as api from '@/api/tasks'
import * as models from '@/models/Tasks'
import {TaskData} from '@/models/Tasks'
import {useFiltersStore} from '@/store/pinia/filtersStore'

export const useOrderTasksStore = defineStore('orderTasks', {
  state: (): models.TasksState => ({
    taskEndpoints: [],
    tasks: {},
    tasksLoaded: {},
    tasksActiveTab: null,
    tasksTablePageNumber: 1
  }),
  actions: {
    async getTasksOfType (payload: models.GetTasksOfTypePayload) {
      const params: models.GetTasksParams = {
        task_type: payload.taskType,
        groups: payload.groups,
        collections: payload.collections,
        budget_keys: payload.budget_key ? [payload.budget_key] : [],
        scenario_keys: payload.scenario_key ? [payload.scenario_key] : [],
      }
      try {
        const response = await api.getTasks(params)
        this.setTasks(response.tasks)
        this.setTaskEndpoints(response.task_list)
      } catch (error) {
        console.error(error)
      }
    },
    setTasks (payload: Record<string, models.TaskData[]>) {
      this.tasks = setTasks(this.tasks, payload)
      for (const type of Object.keys(payload)) {
        this.tasksLoaded[type] = true
      }
      this.tasksLoaded = {...this.tasksLoaded}
    },
    setTaskEndpoints (payload: string[]) {
      this.taskEndpoints = payload
    },
    updateTasksPage (payload: number) {
      this.tasksTablePageNumber = payload
    },
    updateTasksTab (payload: unknown) {
      this.tasksActiveTab = payload
    }
  }
})

export const useStockTasksStore = defineStore('stockTasks', {
  state: (): models.StockTasksState => ({
    taskEndpoints: [],
    tasks: {},
    tasksLoaded: {},
    tasksActiveTab: null,
    tasksTablePageNumber: 1,
    recommendations: {},
    recommendationQueries: [],
    recommendationsTablePageNumber: 1,
    recommendationsActiveTab: null,
    leftHomeFrom: null
  }),
  actions: {
    async getAllTasks () {
      const pageFiltersValues = getStockPageFilters()
      const params: models.GetTasksParams = {
        groups: pageFiltersValues.groups || [],
        stores: pageFiltersValues.stores || [],
        collections: pageFiltersValues.collections || [],
        format: 'aod',
        task_type: 'stock',
      }
      try {
        const response = await api.getTasks(params)
        this.setTasks(response.tasks)
        this.taskEndpoints = response.task_list
      } catch (error) {
        console.error(error)
      }
    },
    async getRecommendations () {
      const pageFiltersValues = getStockPageFilters()
      const params: models.GetRecommendationsParams = {
        groups: pageFiltersValues.groups || [],
        stores: pageFiltersValues.stores || [],
        collections: pageFiltersValues.collections || [],
        format: 'aod',
      }
      const promises = this.recommendationQueries
        .filter(query => !query.includes('_options'))
        .map(async (endpoint) => {
          try {
            const response = await api.getRecommendations(endpoint, params)
            this.setRecommendations(endpoint, response.data)
          } catch (error) {
            console.error(error)
          }
        })
      await Promise.all(promises)
    },
    setTasks (tasks: Record<string, models.TaskData[]>) {
      this.tasks = setTasks(this.tasks, tasks)

      for (const type of Object.keys(tasks)) {
        this.tasksLoaded[type] = true
      }
      this.tasksLoaded = {...this.tasksLoaded}
    },
    updateTasksTab (tab: string | null) {
      this.tasksActiveTab = tab
    },
    updateTasksPage (payload: number) {
      this.tasksTablePageNumber = payload
    },
    setRecommendations (key: string, data: unknown) {
      this.recommendations[key] = data
    },
    updateRecommendationsPage (page: number) {
      this.recommendationsTablePageNumber = page
    },
    updateLeftRoute (route: string | null) {
      this.leftHomeFrom = route
    }
  }
})


// Helpers
function getStockPageFilters () {
  const stockFiltersStore = useFiltersStore()
  return stockFiltersStore.getPageFiltersNonDefaultValues()
}


function setTasks (tasks: Record<string, models.Task>, payload: Record<string, TaskData[]>){
  for (const type of Object.keys(payload)) {
    tasks[type] = { data: payload[type], headers: [], urls: [] }
    if (payload[type].length > 0) {
      tasks[type].headers = Object.keys(payload[type][0] as object)
    }
  }

  return tasks
}
