import Vue from 'vue'
import Router from 'vue-router'
import { getModuleFromPath } from '@/utils'
import NotFoundPage from '@/views/NotFoundPage.vue'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { stockRoutes } from '@/routes/stock'
import { buyRoutes } from '@/routes/buy'
import { strategyRoutes } from '@/routes/strategy'
import { pricingRoutes } from '@/routes/pricing'
import { authRoutes } from '@/routes/auth'
import { storepageRoutes, storeRoutes } from '@/routes/store'
import ResetMe from '@/components/ResetMe.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/thunderstock',
      name: 'thunderstock',
      redirect: () => {
        window.location.href = 'http://thunderstock.nl'
      }
    },
    authRoutes,
    stockRoutes,
    buyRoutes,
    storeRoutes,
    storepageRoutes,
    strategyRoutes,
    pricingRoutes,
    {
      path: '/reset-me',
      name: 'reset-me',
      component: ResetMe,
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFoundPage
    },
  ]
})

function handleStorepageRoute (query) {
  if (query.token) {
    localStorage.setItem('storepage_token', query.token)
  }
}

router.beforeEach(async (to, from, next) => {
  let currentModule = getModuleFromPath(to.path)
  const isStorePage = currentModule === 'store'
  const accessStock = localStorage.getItem('user_access_stock')
  const accessBuy = localStorage.getItem('user_access_buy')

  if (currentModule) {
    localStorage.setItem('lastVisitedModule', currentModule)
  } else {
    currentModule = localStorage.getItem('lastVisitedModule') || 'stock'
  }
  // Avoids issues where route guard triggers before pinia initialization, Vue 2 issue
  setTimeout(() => useGeneralStore().setCurrentModule(currentModule), 100)

  if (isStorePage) {
    handleStorepageRoute(to.query)
  }

  // if user opens application root url
  if (to.path === '/') {
    if (['read', 'write'].includes(accessBuy) && !['read', 'write'].includes(accessStock)) {
      return next({ path: '/home' })
    } else {
      return next({ path: `/${currentModule}/home` })
    }
  }

  if (
    isStorePage ||
        ['/login', '/reset_password', '/forgot-password'].includes(to.path) ||
        localStorage.getItem('refresh_token')
  ) {
    next()
  } else {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }
})

export default router
