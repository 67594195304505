<template>
  <v-row class="ma-0 mx-4 justify-space-between pt-6">

    <v-overlay
      v-if="!Object.keys(data||{}).length"
      absolute color="white" :value="!Object.keys(data||{}).length ">
      <v-progress-circular color="primary" indeterminate size="64" width="2"/>
    </v-overlay>
    <template v-else>
      <v-card cols="4" class=" col col-1_5">
        <v-col class="text-center py-0">
          <div class="kpi-title" style="color: transparent"> Title</div>
          <template>
            <div class="kpi-title bold pb-4">
              Budget
            </div>
            <div class="kpi-title bold pb-4">
              Current
            </div>
            <div class="kpi-title percent pb-1" style="color: #4CAF50;">
              Ordered
            </div>
            <div class="percent kpi-title pb-1" style="color: #007AFF">
              In Progress
            </div>
            <div class="percent kpi-title pb-1" style="color: #FFD202">
              To Order
            </div>
          </template>
        </v-col>
      </v-card>
      <v-card  cols="4" class=" col col-1_5" v-for="type in types" :key="type.value">
        <v-col class="text-center py-0">
          <div class="kpi-title">{{type.name | formatString}}</div>
          <template v-if="type.value !== 'margin'">
            <div class="kpi-title bold pb-4">
              <span v-if="type.value !=='pieces'">{{$auth.getUserCurrency().symbol}}</span> {{formatNumber(data[`intake_${type.value}`], type.value)}}
            </div>
            <div class="kpi-title bold pb-4">
              <span v-if="type.value !=='pieces'">{{$auth.getUserCurrency().symbol}}</span> {{formatNumber(data[`total_${type.value}`], type.value)}}
              ({{formatNumber((data[`total_${type.value}_index`] * 100).toFixed(0))}}%)
            </div>
            <div class="kpi-title percent pb-1" style="color: #4CAF50;">
              <span v-if="type.value !=='pieces'">{{$auth.getUserCurrency().symbol}}</span> {{formatNumber(data[`ordered_${type.value}`], type.value )}}
              ({{formatNumber((data[`ordered_${type.value}_index`] * 100).toFixed(0))}}%)</div>
            <div class="percent kpi-title pb-1" style="color: #007AFF">

              <span v-if="type.value !=='pieces'">{{$auth.getUserCurrency().symbol}}</span> {{formatNumber(data[`in_progress_${type.value}`], type.value )}}
              ({{formatNumber((data[`in_progress_${type.value}_index`] * 100).toFixed(0))}}%)                </div>
            <div class="percent kpi-title pb-1" style="color: #FFD202">

              <span v-if="type.value !=='pieces'">{{$auth.getUserCurrency().symbol}}</span> {{formatNumber(data[`to_order_${type.value}`], type.value )}}
              ({{formatNumber((data[`to_order_${type.value}_index`] * 100).toFixed(0))}}%)
            </div>
          </template>
          <template v-else>
            <div class="kpi-title bold pb-4">
              {{(data[`intake_${type.value}`] * 100).toFixed(1)}}%
            </div>
            <div class="kpi-title bold pb-4">
              {{(data[`total_${type.value}`] * 100).toFixed(1)}}%
              ({{formatNumber((data[`total_${type.value}_index`] * 100).toFixed(0))}}%)
            </div>
            <div class="kpi-title percent pb-1" style="color: #4CAF50;">
              {{(data[`ordered_${type.value}`] * 100).toFixed(1)}}%
              ({{formatNumber((data[`ordered_${type.value}_index`] * 100).toFixed(0))}}%)</div>
            <div class="percent kpi-title pb-1" style="color: #007AFF">

              {{(data[`in_progress_${type.value}`] * 100).toFixed(1)}}%
              ({{formatNumber((data[`in_progress_${type.value}_index`] * 100).toFixed(0))}}%)                </div>
            <div class="percent kpi-title pb-1" style="color: #FFD202">

              {{(data[`to_order_${type.value}`] * 100).toFixed(1)}}%
              ({{formatNumber((data[`to_order_${type.value}_index`] * 100).toFixed(0))}}%)
            </div>
          </template>
        </v-col>
      </v-card>
    </template>
  </v-row>
</template>

<script>
import { formatLargeNumber } from '@/utils'
export default {
  name: 'BuyChartKPI',
  data () {
    return {
      types: [
        { value: 'revenue', name: 'value' },
        { value: 'pieces', name: 'pieces' },
        { value: 'cost', name: 'costs' },
        { value: 'margin', name: 'margin' },
        { value: 'price', name: 'price' },
      ],
    }
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    formatLargeNumber,
    formatNumber (value, type) {
      const ranges = [
        { divider: 1e6, suffix: 'M' },
        { divider: 1e3, suffix: 'k' }
      ]
      function formatNumber (n) {
        for (let i = 0; i < ranges.length; i++) {
          if (n >= ranges[i].divider) {
            return (n / ranges[i].divider).toFixed(type === 'price' ? 2 : 1) + ranges[i].suffix
          }
        }
        return type === 'price' ? n.toFixed(2) : n
      }
      return formatNumber(value)
    },
  },
  computed: {
    data () {
      const kpi = this.chartData?.kpi
      return kpi && kpi[0]
    }
  }
}
</script>

<style scoped>
  .kpi-title {
    font-size: 13px;
    color:rgba(0,0,0,0.87)
  }
  .kpi-title.bold {
    font-size: 15px;
    font-weight: bold;
  }
  .col-1_5 {
    flex: 14%;
    max-width: 14%;
  }
</style>
