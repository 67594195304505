<template>
  <v-card
    v-if="!isChangeView"
    :flat="flat || inModal"
    class="pa-4 pt-2 overflow-auto">
    <div class="d-flex align-center ma-0 justify-space-between pb-2" v-if="index !== null && !hide">
      <h4 class="text-no-wrap">{{positionTitle}}. {{ view.view.view_name }}</h4>
      <div class="d-flex show-on-hover flex-nowrap pt-1" v-if="!view.view.errored && !view.errored" >
        <v-icon class=" action-btn-primary" @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" v-if="!inModal">edit</v-icon>
        <v-overlay absolute color="white" :value="downloadingLoaded">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <DataExport
          @item-click="downloadData"
        />
        <v-icon
          class="mr-1 action-btn-primary"
          @click="$emit('full-screen',view,index)"
          v-if="!inModal"
        >fullscreen</v-icon>
        <data-sets-view-filter :view="view" @change="filterChanged" :index="index" ></data-sets-view-filter>
      </div>

      <template v-else-if="view.view.errored || view.errored" >
        <div class="d-flex show-on-hover flex-nowrap pt-1">
          <v-icon class="show-on-hover action-btn-primary " @click="isChangeView = true; $emit('toggle-view', true)" :disabled="shared" v-if="!inModal">edit</v-icon>
        </div>
      </template>
    </div>

    <v-overlay absolute color="white" :value="(!view.data) && !(view.view.errored || view.errored)">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <div class="chart-container" style="position: relative;"
      :style="{height: height? height+'px' : '370px'}">
      <canvas :id="title+index +'.' + secondaryIndex"></canvas>
    </div>
  </v-card>

  <datasets-new-view
    v-else
    :dashboard-details="dashboardDetails"
    :viewsMap="viewsMap"
    @saved-positions="$emit('saved-positions', $event)"
    :changeView="{...view.view, position}"
    :positions="positions"
    :datasets="datasets"
    :dataSetForChoose="dataSetForChoose"
    @cancel="isChangeView = false; $emit('toggle-view', false)"
    @delete-view="$emit('delete-view',$event)"
    @save="changeView">
  </datasets-new-view>
</template>

<script>
import { downloadAnalytics } from '@/api/analytics'
import DataExport from '@/components/DataExport'
import DataSetsViewFilter from '@/components/AutomateDatasets/DataSetsViewFilter'
import { dataConvertDict } from '@/chart/ChartData'
import { useAnalyticsFiltersStore } from '@/store/pinia/analyticsFiltersStore'
import { Chart } from 'chart.js/auto'

const DatasetsNewView = () => import('@/components/AutomateDatasets/DatasetsNewView')

export default {
  name: 'DataSetsWaterfallChart',
  components: { DataSetsViewFilter, DataExport, DatasetsNewView },
  props: ['view', 'index', 'positions', 'datasets', 'dataSetForChoose', 'shared', 'hide', 'flat',
          'height', 'inModal', 'secondaryIndex', 'viewsMap', 'dashboardDetails'],
  setup () {
    return {
      analyticsFiltersStore: useAnalyticsFiltersStore()
    }
  },
  computed: {
    positionTitle () {
      if(this.dashboardDetails?.view_keys[this.index]?.filter(item=> item).length === 1){
        return this.index + 1
      }
      return this.position
    },
    downlaodFilters () {
      return { ...this.view.view.filters, ...this.analyticsFiltersStore.dashboardDetails.filters }
    },
    position () {
      return `${this.index + 1}${['A', 'B', 'C', 'D'][this.secondaryIndex]}`
    },
    title () {
      return 'data_sets_waterfall_chart'
    }
  },
  data () {
    return {
      isChangeView: false,
      downloadingLoaded: false,
    }
  },
  methods: {
    filterChanged (value) {
      this.changeView({ view: value, position: this.index })
    },
    downloadData (sep) {
      this.downloadingLoaded = true
      const downloadPayload = { ...this.view.view, filters: this.downlaodFilters, csv: true, csv_separator: sep, csv_decimal: '.' }
      if (sep === 'dutch') {
        downloadPayload.csv_separator = ';'
        downloadPayload.csv_decimal = ','
      }
      downloadAnalytics(downloadPayload)
        .then(({ url }) => {
          this.downloadingLoaded = false
          const link = document.createElement('a')
          link.setAttribute('href', url)
          const today = new Date()
          const dd = today.getDate()
          const mm = today.getMonth() + 1
          const yyyy = today.getFullYear()
          link.setAttribute('download', this.view.view.view_name + '_' + String(dd) + '_' + String(mm) + '_' + String(yyyy) + '.csv')
          link.click()
        })
    },
    changeView (value) {
      this.isChangeView = false
      this.$emit('toggle-view', false)
      this.$emit('change-view', value)
    },
    drawChart () {
      try {
        this.loaded = false
        const ctx = document.getElementById(this.title + this.index + '.' + this.secondaryIndex).getContext('2d')
        const data = dataConvertDict[this.title](this.view)
        const chartData = data[0]
        const options = data[1]

        options.tooltips.callbacks = {
          label: (context) => {
            const values = (JSON.parse(context.value))
            return this.$options.filters.formatThousands(values[1] - values[0])
          },
        }
        if (this.chart) {
          this.chart.destroy()
        }
        this.chart = new Chart(ctx, { type: 'bar', data: chartData, options })
        this.$emit('loaded')
        this.loaded = true
      } catch (e) {
        this.$emit('loaded')
        this.loaded = true
      }
    },
  },
  watch: {
    view: {
      handler (value) {
        if (Object.keys(value).length < 2) {
          return
        }
        setTimeout(() => {
          this.drawChart()
        }, 300)
      },
      immediate: true
    },
    isChangeView () {
      setTimeout(() => {
        if (!this.isChangeView) {
          this.drawChart()
        }
      }, 200)
    }
  },
}
</script>

<style scoped>

</style>
