<template>
  <div
    class="chart-container mx-4"
    style="position: relative; height: 350px;"
    :style="height && `height: ${height};`"
  >
    <LineChart
      :key="chartKey"
      :data="chartDataC?.datasets||[]"
      :options="chartOptions"
      :labels="chartDataC?.labels||[]"
    />
    <!--        <canvas ref="chartCanvas"/>-->
  </div>
</template>

<script>
import { formatLargeNumber } from '@/utils'
import { chartColors } from '@/variables'
import LineChart from '@/charts/LineChart.vue'

export default {
  name: 'StrategyPeriodOptionStoryChart',
  components: { LineChart },
  props: {
    story: {
      type: Object || null,
      default: null,
    },
    height: {
      type: String,
      default: '',
    },
  },
  data () {
    const chartOptions = {
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      hover: {
        mode: 'index',
        intersect: false,
      },
      clip: false,
      scales: {
        x: {
          title: {
            display: false,
          },
          grid: {
            display: false
          },
          ticks: {
            font: {
              size: 14,
            }
          },
          // offset: true,
        },
        y1: {
          position: 'left',
          title: {
            display: true,
            text: 'Stock',
            font: {
              size: 14,
            },
          },
          beginAtZero: true,
          min: 0,
          grid: {
            drawTicks: false,
            drawOnChartArea: false,
          },
          border: {
            display: false,
          },
          ticks: {
            callback: (value)=> formatLargeNumber(value),
            padding: 16,
            font: {
              size: 14,
            }
          },
        },
        y2: {
          position: 'right',
          title: {
            display: true,
            text: 'Sales',
            font: {
              size: 14,
            },
          },
          beginAtZero: true,
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            callback: (value)=> formatLargeNumber(value),
            padding: 16,
            font: {
              size: 14,
            }
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
          },
        },
        tooltip: {
          callbacks: {
            label: context => {
              return this.$options.filters.formatThousands(context.raw)
            },
          }
        },
      },
    }
    this.optionChart = null

    return {
      chartDataC: null,
      chartOptions,
      chartKey: 0,
    }
  },
  computed: {
    chartData () {
      return this.story?.performance.data
    }
  },
  watch: {
    'chartData': {
      handler: function () {
        if (this.chartData) {
          this.$nextTick(this.drawOptionChart)
        }
      },
      immediate: true,
    },
  },
  methods: {
    drawOptionChart () {
      this.chartDataC = {
        labels: this.chartData.map(x => x.week_number).map(this.$options.filters.formatWeekNumber),
        datasets: [{
                     label: 'Sales',
                     data: this.chartData.map(x => x.sales),
                     yAxisID: 'y2',
                     borderColor: chartColors[1],
                     borderWidth: 2,
                     backgroundColor: '#FFFFFF',
                     pointRadius: null,
                     fill: false,
                     tension: 0.4,
                   }, {
                     label: 'Sales + missed',
                     data: this.chartData.map(x => x['sales + missed']),
                     yAxisID: 'y2',
                     borderColor: chartColors[1],
                     borderWidth: 2,
                     backgroundColor: '#FFFFFF',
                     borderDash: [10, 5],
                     pointRadius: null,
                     tension: 0.4,
                   }, {
                     label: 'Potential',
                     data: this.chartData.map(x => x.potential),
                     yAxisID: 'y2',
                     borderColor: chartColors[2],
                     borderWidth: 2,
                     backgroundColor: '#FFFFFF',
                     pointRadius: null,
                     tension: 0.4,
                   },
                   {
                     label: 'Stock',
                     data: this.chartData.map(x => x.stock),
                     yAxisID: 'y1',
                     borderWidth: 2,
                     pointRadius: null,
                     tension: 0.4,
                     fill: true,
                     borderColor: '#007AFF',
                     pointBackgroundColor: '#FFF',
                     backgroundColor: (context) => {
                       if (!context.chart?.chartArea) {
                         return
                       }
                       const { ctx, chartArea: { top, bottom } } = context.chart
                       const proposalGradient = ctx.createLinearGradient(0, top, 0, bottom)
                       proposalGradient.addColorStop(1, 'RGBA(0,122,255,0)')
                       proposalGradient.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
                       proposalGradient.addColorStop(0, 'RGBA(0,122,255,0.15)')
                       return proposalGradient
                     },
                   }
        ],
      }
      this.chartKey++
    },
  },
}
</script>

<style scoped>

</style>
