<template>
  <div class="dummy-properties">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <div class="pa-4">
      <v-row>
        <v-col>
          <h4 class="mt-2 mb-6">Included</h4>
        </v-col>
        <v-col>
          <h4 class="mt-2">Expected RoS (without price)</h4>
          <div v-if="ros" class="mb-6 text-body-2 font-weight-bold ros-value">base = {{ ros.base }}</div>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col :cols="10">
          <v-row v-for="(prop, index) in selectedProperties" :key="index">
            <v-col :cols="3">
              <v-autocomplete
                v-model="prop.name"
                :items="getAvailableProperties(prop)"
                label="Property"
                :disabled="!!prop.name"
                hide-details
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"/>
            </v-col>
            <v-col :cols="3">
              <v-autocomplete
                v-if="prop.name"
                v-model="prop.value"
                :items="getPropertyValuesOptions(prop)"
                label="Value"
                hide-details
                :error-messages="Object.keys(properties[prop.name]).includes(prop.value) ? [] : 'Value not in list'"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu',
                }"
                @change="handlePropValueChange(index)" />
            </v-col>
            <v-col v-if="prop.name||prop.value" :cols="1">
              <v-icon
                class="mt-5 action-btn-danger"
                @click="handlePropDeleteClick(index)"
              >delete</v-icon>
            </v-col>
            <v-col :cols="3">
              <div v-if="ros" class="pt-4 text-body-2 text-center ros-value">
                {{ propertyAndValueExists(prop) ? ros.properties[prop.name] : '-' }}
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="7">
              <v-icon class="plus-icon" @click="handleAddPropClick">add</v-icon>
            </v-col>
            <v-col cols="3" class="pt-0">
              <div v-if="ros" class="pt-4 text-body-2 font-weight-bold text-center ros-value total-value">
                Total: {{ ros.total }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div class="pa-4 pb-12">
      <h4 class="mt-2 mb-6">Missing</h4>
      <v-row>
        <v-col :cols="5">
          <MissingProperties
            :items="missingProperties"
            @property-selected="handleMissingPropertySelected"
          />
        </v-col>
        <v-col :cols="7">
          <MissingPropertyValues
            :property-name="selectedMissingProperty"
            :property-values="selectedMissingPropertyValues"
            @property-value-add="handleMissingPropertyValueAdded"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import * as dummiesApi from '@/api/dummies'
import * as propertiesApi from '@/api/properties'
import MissingProperties from './MissingProperties'
import MissingPropertyValues from './MissingPropertyValues'

export default {
  components: { MissingProperties, MissingPropertyValues },
  props: {
    dummy: {
      type: Object,
      default: () => ({}),
    },
    properties: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      loading: false,
      selectedProperties: [],
      missingProperties: null,
      selectedMissingProperty: null,
      selectedMissingPropertyValues: null,
      ros: null,
    }
  },
  watch: {
    dummy: {
      handler (val) {
        if (!val) {
          return
        }

        this.selectedProperties = Object.keys(this.dummy.properties).reduce(
          (acc, cur) => {
            return [ ...acc, {
              name: cur,
              value: this.dummy.properties[cur]
            }]
          },
          []
        ).filter(prop => !!this.properties[prop.name])

        this.loading = true
        this
          .getPropertiesImpactAndMissing()
          .then(data => {
            this.ros = {
              base: data.base_value,
              properties: data.selected_property_value,
              total: data.selected_total_value,
            }
            this.missingProperties = data.properties
            this.selectedMissingPropertyValues = data.property_value
            this.$emit('ros-changed', this.ros)
          })
          .finally(() => {
            this.loading = false
          })
      },
      immediate: true,
    },
  },
  methods: {
    getAvailableProperties (selectedProperty) {
      const available = []

      if (selectedProperty.name) {
        available.push({
          text: this.$options.filters.formatString(selectedProperty.name),
          value: selectedProperty.name,
        })
      }

      return [
        ...available,
        ...Object.keys(this.properties)
          .filter(p => !this.selectedProperties.map(sp => sp.name).includes(p))
          .map(p => ({
            value: p,
            text: this.$options.filters.formatString(p),
          })),
      ]
    },
    getPropertyValuesOptions (prop) {
      const options = Object.keys(this.properties[prop.name])
        .map(x => ({
          value: x,
          text: this.$options.filters.formatString(x),
          disabled: false,
        }))

      if (prop.value && !Object.keys(this.properties[prop.name]).includes(prop.value)) {
        options.push({
          value: prop.value,
          text: this.$options.filters.formatString(prop.value),
          disabled: true,
        })
      }

      return options
    },
    getPropertiesImpactAndMissing () {
      const filters = this.selectedProperties
        .filter(p => {
          if (!this.properties) {
            return p
          }

          return this.propertyAndValueExists(p)
        })
        .reduce(
          (acc, cur) => ({ ...acc, [cur.name]: cur.value }),
          {}
        )

      return propertiesApi
        .getPropertiesImpactAndMissing({
          filters,
          property: this.selectedMissingProperty,
        })
    },
    propertyAndValueExists (prop) {
      return Object.keys(this.properties).includes(prop.name) &&
        Object.keys(this.properties[prop.name]).includes(prop.value)
    },
    saveDummy () {
      const nonEmptyProperties = this.selectedProperties.filter(p => p.name !== null && p.value !== null)
      for (const prop of nonEmptyProperties) {
        this.dummy.properties[prop.name] = prop.value
      }

      const payload = {
        ...this.dummy,
      }

      return dummiesApi.saveDummy(payload)
    },
    handleAddPropClick () {
      this.selectedProperties.push({
        name: null,
        value: null,
      })
    },
    handlePropValueChange () {
      this.loading = true
      this
        .saveDummy()
        .then(updatedDummy => {
          this
            .getPropertiesImpactAndMissing()
            .then(data => {
              this.ros = {
                base: data.base_value,
                properties: data.selected_property_value,
                total: data.selected_total_value,
              }
              this.missingProperties = data.properties
              this.$emit('properties-changed', updatedDummy)
              this.$emit('ros-changed', this.ros)
            })
            .finally(() => {
              this.loading = false
            })
        })
    },
    handlePropDeleteClick (index) {
      delete this.dummy.properties[this.selectedProperties[index].name]
      this.selectedProperties.splice(index, 1)
      this.loading = true
      this
        .saveDummy()
        .then(updatedDummy => {
          this
            .getPropertiesImpactAndMissing()
            .then(data => {
              this.ros = {
                base: data.base_value,
                properties: data.selected_property_value,
                total: data.selected_total_value,
              }
              this.missingProperties = data.properties
              this.$emit('properties-changed', updatedDummy)
              this.$emit('ros-changed', this.ros)
            })
            .finally(() => {
              this.loading = false
            })
        })
    },
    handleMissingPropertySelected (property) {
      this.selectedMissingProperty = property.name
      this.loading = true
      this
        .getPropertiesImpactAndMissing()
        .then(data => {
          this.selectedMissingPropertyValues = data.property_value
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleMissingPropertyValueAdded (value) {
      this.selectedProperties.push({
        name: this.selectedMissingProperty,
        value: value.name,
      })

      this.loading = true
      this
        .saveDummy()
        .then(updatedDummy => {
          this.selectedMissingProperty = null
          this.selectedMissingPropertyValues = null
          this.$emit('properties-changed', updatedDummy)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
.dummy-properties {
    .disabled-text-field {
      padding-top:18px;
    }
    .ros-value {
        line-height: 32px;
    }
    .total-value {
        border-top: 1px solid rgba(0, 0, 0, 0.42);
    }
}
</style>
