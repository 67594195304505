<template>
  <canvas ref="lineChart" width="100%"/>
</template>

<script>
import {
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js/auto'

export default {
  name: 'LineChart',
  data () {
    this.chart = null

    return {
    }
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '40'
    },
  },
  mounted () {
    this.renderChart()
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.destroy()
    }
  },
  computed: {
    computedData () {
      // return this.data
      return {
        labels: this.labels,
        datasets: this.data
      }
    },
    computedOptions () {
      return {
        ...this.options,
        height: this.height,
        elements: {
          ...(this.options.elements || {}),
          line: {
            borderJoinStyle: 'round',
            tension: 0.6,
            ...(this.options.elements?.line || {}),
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          ...(this.options.plugins || {}),
          annotation: {
            ...(this.options.plugins?.annotation || {}),
          },
          legend: {
            padding: 50,
            position: 'bottom',
            align: 'center',
            title: { display: true, padding: 3 }, // or whatever number
            ...(this.options.plugins?.legend || {}),
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              lineWidth: '6px',
              generateLabels: chart => {
                const datasets = chart.data.datasets
                const {
                  labels: {
                    usePointStyle,
                    pointStyle,
                    textAlign,
                    color
                  }
                } = chart.legend.options
                return chart._getSortedDatasetMetas().map((meta) => {
                  const rowData = datasets[meta.index]
                  const inheritedProps = Object.keys(rowData).reduce((obj, key) => {
                    const value = rowData[key]
                    if (key.startsWith('border')) {
                      // replace border with line
                      obj[`line${key.slice(6)}`] = value
                    }
                    return obj
                  }, {})
                  const style = meta.controller.getStyle(usePointStyle ? 0 : undefined)

                  return {
                    text: rowData.label,
                    fillStyle: style.backgroundColor,
                    fontColor: color,
                    hidden: !meta.visible,
                    lineCap: style.borderCapStyle,
                    lineDash: style.borderDash,
                    lineDashOffset: style.borderDashOffset,
                    lineJoin: style.borderJoinStyle,
                    lineWidth: 1,
                    strokeStyle: style.borderColor,
                    pointStyle: pointStyle || style.pointStyle,
                    rotation: style.rotation,
                    textAlign: textAlign || style.textAlign,
                    borderRadius: 0,

                    // Below is extra data used for toggling the datasets
                    datasetIndex: meta.index,
                    ...inheritedProps
                  }
                })
              },
              ...(this.options.plugins?.legend?.labels || {}),
            }
          }
        }
      }
    }
  },
  watch: {
    data: {
      handler () {
        this.renderChart()
      },
      deep: true
    },
    labels: {
      handler () {
        this.renderChart()
      },
      deep: true
    },
    options: {
      handler () {
        this.renderChart()
      },
      deep: true
    }
  },
  methods: {
    renderChart () {
      const ctx = this.$refs['lineChart'].getContext('2d')
      if (this.chart) {
        this.chart.destroy()
      }
      this.chart = new Chart(ctx, {
        plugins: [Title, PointElement, LineElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Filler],
        type: 'line',
        data: this.computedData,
        options: this.computedOptions
      })
    }
  }
}
</script>
<style scoped lang="scss">

</style>
