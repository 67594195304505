<template>
  <v-dialog
    v-model="isOpen"
    scrollable
    persistent
    content-class="align-self-start mt-14"
    width="600px"
    max-width="90%">
    <v-card>
      <v-card-title class="text-body-1 font-weight-medium mx-4 my-2">Batch Upload</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="text--primary">
        <div class="container px-4 mt-4">
          <div>
            <h3 class="font-weight-medium">Info</h3>
            <div class="text-subtitle-1 grey--text text--darken-2 font-weight-regular pt-3 pb-9">
              Upload set of combinations. Download combinations table for required upload file format. Uploading will overwrite existing combination. Rows which are not in the upload, will not be deleted.
            </div>
          </div>
          <drag-and-drop @error="setError" @results="setResults" ref="dragndrop"/>
          <div class="mt-6 grey--text text--darken-2 text-caption font-weight-medium" :class="{ 'error--text': importError }">
            <template v-if="importError">
              {{ importError }}
            </template>
            <template v-else-if="importSuccess">
              {{ importSuccess }}
            </template>
            <template v-else>
              No import error
            </template>
          </div>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="justify-end">
        <div class="mx-5 my-6">
          <v-btn
            color="primary"
            depressed
            text
            @click="$emit('close')"
          >Cancel</v-btn>
        </div>
        <div class="mx-5 my-6">
          <v-btn
            color="primary"
            depressed
            outlined
            :disabled="!!importError || results.length === 0"
            @click="handleUpload"
            :loading="uploading"
          >Upload</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const DragAndDrop = () => import('@/components/DragAndDrop')
export default {
  name: 'BatchUploadModal',
  components: { DragAndDrop },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      results: [],
      importError: null,
      importSuccess: null,
      uploading: false,
    }
  },
  methods: {
    handleUpload () {
      this.uploading = true
      this.$emit('upload', this.results)
    },

    setError (error) {
      this.importError = error
      this.uploading = false
      if (!this.uploading && !this.importError) {
        this.$refs.dragndrop.Reset()
      }
    },

    setResults (results) {
      this.importError = null
      this.results = results
    },
  },
}
</script>

<style lang="scss">
  .file-drop-zone {
    input {
      position: absolute;
      opacity: 0;
    }
    label {
      border: 5px dashed #ccc;
      height: 250px;
      padding-top: 30px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .icon {
        font-size: 48px;
        margin-bottom: 20px;
      }
    }
    &:hover,
    &.hover {
      label {
        background: rgba(0, 122, 255, 0.06);
      }
    }
  }

</style>
