<template>
  <div class="bestseller-carousel">
    <OptionAlertModal
      :dialogVisible="alertDialogVisible"
      @alert-create="alertDialogVisible = false"
      @close-click="alertDialogVisible = false"
    />

    <carousel :perPageCustom="[[256,2], [500, 3], [1024, 7], [1200, 7]]" :navigationEnabled=false
      v-if="products && products.length > 0">
      <slide v-for="product in products" :key="product['properties/article/key']">
        <v-card class="vcard">
          <!-- Card Icon -->
          <v-row class="card-icon">
            <v-icon class="alert-icon" @click="changeAlert(product)">add_alert</v-icon>
          </v-row>

          <!-- Card Image -->
          <v-menu
            :key="product['properties/article/key']"
            right
            bottom
            :open-on-hover="!infoMenuOpened[product['properties/article/key']]"
            nudge-bottom="10"
            :disabled="!product['properties/article/key']"
            :nudge-left="200"
            v-model="infoMenuOpened[product['properties/article/key']]"
            @input="onInfoMenuChange(product['properties/article/key'], $event)"
            offset-x
            offset-y
            :open-delay="3000"
            :close-delay="1000"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-row class="mt-0 mb-4 image-row" >
                  <img height="160px" :src="product['image_url'] || '@/assets/img/ts_placeholder.png'" @error="loadPlaceholder" alt=""/>
                </v-row>
                <!-- Card Title -->
                <div class="option-name-wrapper">
                  <router-link class="card-title"
                    :to="product.route">
                    {{ product.title}}
                  </router-link>
                  <p v-if="product.subtitle"  class="card-subtitle">{{product.subtitle}}</p>
                </div>
              </div>
            </template>
            <info-menu
              @close="infoMenuOpened[product['properties/article/key']] = false"
              v-if="infoMenuOpened[product['properties/article/key']]"
              :row="{option_key: product['properties/article/key']}"></info-menu>
          </v-menu>
          <!-- Price subtitle -->
          <p class="price">
            {{ priceFormatter(product['OSP']) }}
            <span v-if="product.OSP !== product.ASP"> / {{priceFormatter(product.ASP)}}</span>
          </p>

          <!-- Stock -->
          <v-divider class="mx-0"></v-divider>
          <v-row class="stock-row py-0 mt-0">
            <v-col class="stock-col pb-2">
              <v-icon class="stock-row-icon">shopping_basket</v-icon>
              {{ product['Sales L5W'] }}
            </v-col>

            <v-col class="stock-col">
              <v-icon class="stock-row-icon">local_shipping</v-icon>
              {{ product['Stock DC'] }}
            </v-col>
          </v-row>
        </v-card>
      </slide>
    </carousel>

    <div v-else>
      <carousel :perPageCustom="[[256,1], [300, 3], [1024, 7], [1200, 7]]" :navigationEnabled=false>
        <slide v-for="i in 10" :key="i">
          <div class="carousel-item-container" v-loading="loading" style="padding: 10px; height: 381px; width: 250px;">
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel'
import OptionAlertModal from '@/components/Alerts/OptionAlertModal.vue'
import { performViewData } from '@/api/analytics'
import InfoMenu from '@/components/Home/InfoMenu'
import ts_placeholder from '@/assets/img/ts_placeholder.png'
import { useAlertStore } from '@/store/pinia/alertStore'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore(),
      alertStore: useAlertStore()
    }
  },
  data () {
    return {
      infoMenuOpened: {},
      loading: true,
      statusClass: 'never',
      alertDialogVisible: false,
      trendGraph: null,
      dataView: {
        'org_key': 0,
        'view_key': 730,
        'user_id': 51,
        'dataset': 'stock_performance',
        'view_name': 'Bestsellers',
        'chart_type': 'table',
        'dimensions': ['properties/article/key'],
        'measures': [{ 'name': 'Stock stores', 'calculation': 'stock_stores' }, {
                       'name': 'Stock DC',
                       'calculation': 'stock_dc'
                     }, { 'name': 'Sales L5W', 'decimals': 0, 'calculation': 'sales_l5w' }, {
                       'name': 'Potential L5W',
                       'decimals': 0,
                       'calculation': 'potential_stocked_l5w'
                     }, {
                       'name': 'Sort Calculation',
                       'decimals': 2,
                       'calculation': ['asp', '*', 'sales_l5w'],
                       'grading_max': 20,
                       'color_grading': false
                     },
                     {
                       'name': 'OSP',
                       'decimals': 2,
                       'calculation': 'osp',
                       'grading_max': 20,
                       'color_grading': false
                     }, { 'name': 'ASP', 'calculation': 'asp' }],
        'filters': {},
        'properties': { 'rows': 10, 'sort': 'Sort Calculation', 'parent_limit': 100 },
        'creation_date': '2023-01-05 13:13:05',
        'limited': false,
        'filters_applied': []
      }
    }
  },
  watch: {
    'filtersStore': function () {
      this.reCallData()
    }
  },
  methods: {

    onInfoMenuChange (option_key, value) {
      if (value) {
        this.infoMenuOpened = { [option_key]: value }
      }
    },
    reCallData () {
      const pageFilters = this.filtersStore.getPageFiltersNonDefaultValues()
      this.dataView.filters.collection_key = pageFilters.collections || []
      this.dataView.filters.group_key = pageFilters.groups || []
      this.dataView.filters.store_key = pageFilters.stores || []
      this.dataView.filters['properties/article/option_key'] = pageFilters.options || []
      performViewData(this.dataView).then(r => {
        this.trendGraph = r.data
        this.loading = false
      })
    },
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    changeAlert (option) {
      const payload = {
        page: 'option',
        option_key: option['properties/article/key'],
        option_name: option.name
      }

      this.alertDialogVisible = true
      this.alertStore.setOptionAlertData(payload)
    },
    statusMessage (status) {
      if (status === 0) {
        this.labelColorByStatus('never')
        return 'Never viewed'
      } else if (status === 1) {
        this.labelColorByStatus('seven-days')
        return 'Viewed last 7 days'
      } else if (status === 2) {
        this.labelColorByStatus('fourteen-days')
        return 'Viewed last 14 days'
      } else if (status === 3) {
        this.labelColorByStatus('over-fourteen-days')
        return 'Viewed over 14 days ago'
      }
    },
    labelColorByStatus (status) {
      if (status === 0) {
        return 'never'
      } else if (status === 1) {
        return 'seven-days'
      } else if (status === 2) {
        return 'fourteen-days'
      } else if (status === 3) {
        return 'over-fourteen-days'
      }
    }
  },
  computed: {
    products () {
      return (this.trendGraph || []).map(item => {
        const nameParts = item.name.split(' / ')
        const title = nameParts[0]
        const subtitle = nameParts.length === 2 ? nameParts[1] : ''
        return {
          ...item,
          title,
          subtitle,
        }
      })
    },

    priceFormatter () {
      const currency = this.$auth.getUserCurrency().currency || 'EUR'
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return formatter.format
    },
  },
  components: {
    InfoMenu,
    Carousel,
    Slide,
    OptionAlertModal,
  },
  mounted () {
    this.reCallData()
  }

}
</script>
