<template>
  <v-card class="buy-manage-drops-main" flat>
    <v-row class="ma-4 mr-7  mt-0 justify-end" >
      <div>
        <table-search
          @searchChanged="filters => handleSearchChanged('dropsFilters',Object.keys(drops[0]), filters)">
        </table-search>
        <v-icon class="mx-2" @click="dropModalOpen = true; isEdit=true" :disabled="!selectedDrop">edit</v-icon>
        <v-icon class="mr-2" @click="dropModalOpen = true; isEdit=false">add</v-icon>
        <v-icon :disabled="!selectedDrop || selectedDrop.shop_items > 0" @click="deleteDialogVisible = true">delete</v-icon>
      </div>
    </v-row>

    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <data-tables
      v-if="!loading"
      :data="drops"
      :filters="dropsFilters"
      :table-props="{'row-key': 'drop_key'}"
      :page-size="5"
      @row-click="(row) => selectDrop(row)"
      @filter-change="filters => handleFilterChange('dropsFilters', filters)"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
    >
      <el-table-column
        prop="option_key"
        width="55"
      >
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="mt-0 ml-4 pt-0"
            :value="selectedDrop && selectedDrop.drop_key === scope.row.drop_key"
            :ripple="false"
            :input-value="selectedDrop && selectedDrop.drop_key === scope.row.drop_key"
            @change="() => selectDrop(scope.row)"
            @click.stop="() => selectDrop(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="drop_name"
        column-key="drop_name"
        :filters="drops ? getColumnFilterValues('drops', 'drop_name') : []"
        sortable
        label="Name"
      >
        <template slot-scope="scope">
          {{scope.row.drop_name}}
        </template>
      </el-table-column>
      <el-table-column
        prop="stores_set_keys"
        sortable
        :filters="drops ? getColumnFilterValues('drops', 'stores_set_keys') : []"
        column-key="stores_set_keys"
        label="Stores sets">
        <template slot-scope="scope">
          <chips-dropdown
            v-if="getStores(scope.row.stores_set_keys).length"
            :items="getStores(scope.row.stores_set_keys)">
          </chips-dropdown>
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="week_min"
        :filters="drops ? getColumnFilterValues('drops', 'week_min') : []"
        column-key="week_min"
        label="Start">
        <template slot-scope="scope">
          {{parseWeek(scope.row.week_min)}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="week_max"
        :filters="drops ? getColumnFilterValues('drops', 'week_max') : []"
        column-key="week_max"
        label="End">
        <template slot-scope="scope">
          {{parseWeek(scope.row.week_max)}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="shop_items"
        :filters="drops ? getColumnFilterValues('drops', 'shop_items') : []"
        column-key="shop_items"
        label="Shop items">
        <template slot-scope="scope">
          {{scope.row.shop_items}}
        </template>
      </el-table-column>
    </data-tables>
    <v-dialog
      v-model="dropModalOpen"
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <drop-table-modal
        v-if="dropModalOpen"
        @close="dropModalOpen = false"
        :isEdit="isEdit"
        :drop="{...selectedDrop}"
        :budgets="budgets"
        :stores="stores"
        @save="itemSaved"
      ></drop-table-modal>
    </v-dialog>
    <v-dialog
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-12"
      width="600px"
      max-width="90%">
      <v-card class="p-relative">
        <v-card-title class="text-body-1 font-weight-medium">
          Delete drop
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete this drop?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="onDeleteClick"
                class="red--text mx-4"
              >Yes</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ChipsDropdown from '../ChipsDropdown'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '../TableSearch'
import { parseWeek } from '../../variables'
import DropTableModal from './DropTableModal'
import { deleteDrop } from '../../api/shop'

export default {
  name: 'DropsTable',
  mixins: [columnFilters],
  components: { DropTableModal, ChipsDropdown, TableSearch },
  props: ['drops', 'budgets', 'stores'],
  data () {
    return {
      selectedDrop: null,
      dropsFilters: [],
      dropModalOpen: false,
      deleteDialogVisible: false,
      isEdit: false,
      loading: false,
    }
  },
  watch: {
    drops (newValue) {
      if (
        this.selectedDrop &&
        !newValue.filter((item) => item.drop_key === this.selectedDrop.drop_key).length
      ) {
        this.selectedDrop = null
      }
    }
  },
  methods: {
    itemSaved (item) {
      this.dropModalOpen = false
      this.selectedDrop = { ...item }
      this.loadData()
    },
    selectDrop (drop) {
      if (this.selectedDrop && this.selectedDrop.drop_key === drop.drop_key) {
        this.selectedDrop = null
        drop = null
      } else {
        this.selectedDrop = drop
      }
      this.$emit('drop-selected', this.selectedDrop)
    },
    parseWeek,
    getStores (keys) {
      const filteredStores = this.stores.filter((item) => keys.indexOf(item.stores_set_key) !== -1) || []
      return filteredStores.map((item) => item.set_name)
    },
    loadData () {
      this.$emit('load-data')
    },
    onDeleteClick () {
      deleteDrop({ drop_key: this.selectedDrop.drop_key }).then(() => {
        this.deleteDialogVisible = false
        this.loadData()
      })
    }
  }
}
</script>

<style scoped>
.buy-manage-drops-main {
}
</style>
