<template>
  <div class="assortment-factors">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <template v-if="!optionKey">
      <TransfersSettings/>
      <TransfersCombinations :display-import="false"/>
      <v-divider/>
    </template>
    <h6 v-if="!optionKey" class="text-subtitle-1 font-weight-medium mt-8 ml-6">Rules</h6>
    <h6 v-else class="text-subtitle-1 font-weight-medium mt-2 ml-4">Rules</h6>
    <v-row>
      <v-col class="text-right pr-6">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('rulesTableFilters', columnsToSearch, val)"
        />
        <v-icon class="mx-2 action-btn-primary" :disabled="selectedRows.length !== 1" @click="handleEditClick">edit</v-icon>
        <v-icon class="mx-2 action-btn-primary" @click="handleAddClick">add</v-icon>
        <v-icon class="mx-2 action-btn-danger" :disabled="selectedRows.length === 0" @click="handleDeleteClick">delete</v-icon>
        <DataExport
          @item-click="downloadData"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <data-tables
          :key="tableKey"
          style="width: 100%"
          :data="rules"
          :page-size="5"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :table-props="{ 'row-key': 'transfer_rule_key' }"
          :filters="rulesTableFilters"
          @row-click="(row)=>handleSelectRowChange(!row.selected, row)"
          filter-multiple
          @filter-change="filters => handleFilterChange('rulesTableFilters', filters)">
          <el-table-column prop="transfer_rule_key" width="55">
            <template v-slot:header>
              <v-simple-checkbox
                color="primary"
                :value="!!(selectedRows.length && selectedRows.length === rules.length)"
                :ripple="false"
                :indeterminate="!!(selectedRows.length && selectedRows.length < rules.length)"
                dense
                hide-details
                class="ml-3"
                @input="handleSelectAllChange"
              />
            </template>
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="ml-3"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="Name"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="type"
            label="Type"
            column-key="type"
            sortable="custom"
            :filters="getColumnFilterValues('rules', 'type')"
          >
            <template slot-scope="scope">
              {{ scope.row.type }}
            </template>
          </el-table-column>
          <el-table-column
            prop="stores_set_key"
            label="Store set"
            sortable="custom"
            column-key="stores_set_key"
            :filters="getColumnFilterValues('rules', 'stores_set_key', getStoreSetName)"
          >
            <template slot-scope="scope">
              {{ getStoreSetName(scope.row.stores_set_key) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="to_stores_set_key"
            label="To store set"
            sortable="custom"
            column-key="to_stores_set_key"
            :filters="getColumnFilterValues('rules', 'to_stores_set_key', getStoreSetName)"
          >
            <template slot-scope="scope">
              {{ getStoreSetName(scope.row.to_stores_set_key) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="store_keys"
            label="Stores"
            sortabl="custom"
            column-key="store_keys"
            :filters="getColumnFilterValues('rules', 'store_keys', getStoreName)"
          >
            <template slot-scope="scope">
              <ChipsDropdown
                :items="scope.row.store_keys?.map(sk => getStoreName(sk))||[]" />
            </template>
          </el-table-column>
          <el-table-column
            prop="to_store_keys"
            label="To stores"
            sortable="custom"
            column-key="to_store_keys"
            :filters="getColumnFilterValues('rules', 'to_store_keys', getStoreName)"
          >
            <template slot-scope="scope">
              <ChipsDropdown
                :items="scope.row.to_store_keys?.map(sk => getStoreName(sk))||[]" />
            </template>
          </el-table-column>
          <el-table-column
            prop="options_set_key"
            column-key="options_set_key"
            label="Option set"
            sortable="custom"
            :filters="getColumnFilterValues('rules', 'options_set_key', getOptionSetName)"
          >
            <template slot-scope="scope">
              <div class="ml-4">
                {{ getOptionSetName(scope.row.options_set_key) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="route" label="Route" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.route }}
            </template>
          </el-table-column>
          <el-table-column prop="date_min" label="Start" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.date_min }}
            </template>
          </el-table-column>
          <el-table-column prop="date_max" label="End" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.date_max }}
            </template>
          </el-table-column>
          <el-table-column prop="allowed" label="Allowed" sortable="custom">
            <template slot-scope="scope">
              <v-checkbox
                v-model="scope.row.allowed"
                disabled
                dense
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="costs"
            label="Costs"
            sortable="custom"
            column-key="costs"
            :filters="getColumnFilterValues('rules', 'costs')"
          >
            <template slot-scope="scope">
              {{ scope.row.costs }}
            </template>
          </el-table-column>
          <el-table-column
            prop="days"
            label="Days"
            sortable="custom"
            column-key="days"
            :filters="getColumnFilterValues('rules', 'days')"
          >
            <template slot-scope="scope">
              {{ scope.row.days }}
            </template>
          </el-table-column>
          <el-table-column prop="changed_by" label="Modified by" sortable="custom">
            <template slot-scope="scope">
              {{ getUserName(scope.row.changed_by) }} at {{ scope.row.changed_at }}
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>
    <v-dialog
      persistent
      :value="addEditDialog"
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <TransferRulesFormDialog
        @close="addEditDialog = false"
        @save="handleSave"
        :edited-item="editedRule"
        :is-edit="!!editedRule.transfer_rule_key"
        :option-sets="optionSets"
        :store-sets="storeSets"
      />
    </v-dialog>
  </div>
</template>
<script>
import TableSearch from '@/components/TableSearch.vue'
import TransfersSettings from '@/views/TransfersSettings.vue'
import TransfersCombinations from '@/views/TransfersCombinations.vue'
import DataExport from '@/components/DataExport.vue'
import { downloadData } from '@/utils'
import { deleteTransfersRules, getTransfersRules, saveTransfersRules } from '@/api/transfers'
import columnFilters from '@/mixins/columnFilters'
import TransferRulesFormDialog from '@/components/Transfers/TransferRulesFormDialog.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'
import ChipsDropdown from '@/components/ChipsDropdown.vue'

export default {
  name: 'StockManageTransfers',
  mixins: [columnFilters],
  components: {
    ChipsDropdown,
    TransferRulesFormDialog,
    DataExport,
    TableSearch,
    TransfersSettings,
    TransfersCombinations
  },
  props: {
    optionKey: {
      default: null,
    },
    storeSets: {
      type: Array,
      default: () => [],
    },
  },
  setup () {
    return {
      generalStore: useGeneralStore(),
    }
  },
  data () {
    return {
      tableKey: 0,
      loading: false,
      rulesTableFilters: [
        {
          value: ''
        }
      ],
      rules: [],
      addEditDialog: false,
      editedRule: {},
    }
  },
  computed: {
    ...mapState(useStoreAndOptionSetsStore, ['optionSets']),
    usersList () {
      return (this.generalStore.appConfig?.users || [])
    },
    usersMap () {
      const usersMap = new Map()
      this.usersList.forEach(user => {
        usersMap.set(user.id, user.first_name + ' ' + user.last_name)
      })
      return usersMap
    },
    storeSetMap () {
      const storesMap = new Map()
      this.storeSets.forEach(store => {
        storesMap.set(store.stores_set_key, store.set_name)
      })
      return storesMap
    },
    optionSetMap () {
      const optionsMap = new Map()
      this.optionSets.forEach(option => {
        optionsMap.set(option.options_set_key, option.set_name)
      })
      return optionsMap
    },
    columnsToSearch () {
      return ['name', 'type']
    },
    selectedRows () {
      return this.rules?.filter(row => row.selected) || []
    }
  },
  methods: {
    async handleSave () {
      await saveTransfersRules(this.editedRule)
      this.addEditDialog = false
      await this.loadData()
      this.tableKey++
    },
    downloadData (sep) {
      const data = this.rules.map(rule => {
        return {
          'Name': rule.name,
          'Type': rule.type,
          'Stores': this.getStoreSetName(rule.stores_set_key),
          'To Stores': this.getStoreSetName(rule.to_stores_set_key),
          'Options': this.getOptionSetName(rule.options_set_key),
          'Route': rule.route || '',
          'Start': rule.date_min || '',
          'End': rule.date_max || '',
          'Costs': rule.costs,
          'Days': rule.days,
          'Modified by': this.getUserName(rule.changed_by) + ' at ' + rule.changed_at
        }
      })
      downloadData(sep, data, 'Transfer Rules')
    },
    async loadData () {
      this.loading = true
      const { rules } = await getTransfersRules(this.optionKey)
      this.rules = rules || []
      this.loading = false
    },
    handleAddClick () {
      this.editedRule = {
        name: '',
        type: 'all',
        allowed: true,
        stores_set_key: undefined,
        to_stores_set_key: undefined,
        options_set_key: undefined,
        store_keys: [],
        to_store_keys: [],
        costs: 0,
        days: 0
      }
      this.addEditDialog = true
    },
    handleEditClick () {
      this.editedRule = structuredClone(this.selectedRows[0])
      if (!this.editedRule.store_keys) {
        this.editedRule.store_keys = []
      }
      if (!this.editedRule.to_store_keys) {
        this.editedRule.to_store_keys = []
      }
      this.addEditDialog = true
    },
    async handleDeleteClick () {
      const ids = this.selectedRows.map(row => row.transfer_rule_key)
      await deleteTransfersRules(ids)
      await this.loadData()
    },
    handleSelectRowChange (event, row) {
      this.$set(row, 'selected', event)
    },
    handleSelectAllChange (value) {
      this.rules.forEach((item) => {
        this.$set(item, 'selected', value)
      })
    },
    getStoreSetName (key) {
      return this.storeSetMap.get(key) || key
    },
    getStoreName (key) {
      const store = this.generalStore.findStoreWithKey(key)
      return store ? store.store_name : key
    },
    getOptionSetName (key) {
      return this.optionSetMap.get(key) || key
    },
    getUserName (id) {
      return this.usersMap.get(id) || id
    },
    redrawTable () {
      setTimeout(() => {
        this.tableKey += 1
      }, 300)
    }
  },
  created () {
    this.loadData()
    this.redrawTable()
  }
}
</script>
