<template>
  <div>
    <el-table
      ref="elTable"
      :class="{'two-rows-column': twoRowsColumn}"
      @sort-change="handleSort"
      :filters="filters"
      :data="curTableData"
      v-bind="{...$attrs, ...tableProps}"
      style='width: 100%'
    >
      <slot></slot>
      <slot name="empty" slot="empty"></slot>
      <slot name="append" slot="append"></slot>
    </el-table>
    <div class="pagination-bar"  v-if="paginationShow"

      :class="paginationProps.class || ''"
    >
      <div class="pagination-wrap d-flex justify-end"
        @mousedown="updateOffsetValue"
      >
        <data-pagination
          ref="elPagination"
          v-if="!paginationProps.hideOnSinglePage || Math.ceil(total / innerPageSize) > 1"
          :total-pages="Math.ceil(total / innerPageSize)"
          :total="total"
          :page-sizes="paginationProps.pageSizes"
          :per-page="innerPageSize"
          :current-page="innerCurrentPage"
          @pagechanged="innerCurrentPage = $event;$emit('current-change', $event)"
          @size-change="handleSizeChange"
          @prev-click="$emit('prev-click', $event)"
          @next-click="$emit('next-click', $event)"
          @current-change="$emit('current-change', $event)"
        >
          <slot name="pagination"></slot>
        </data-pagination>
      </div>
    </div>
  </div>
</template>
<style lang='scss'>
@import "../style/index.scss";
</style>

<script>
import { stringPropFilterFn } from '../tools'
import ShareMixin from '../mixins/ShareMixin.js'
import { isArray, isString } from 'lodash'
import DataPagination from '@/components/Tables/components/DataPagination.vue'

const defaultSortFn = (a = 0, b = 0) => a > b ? 1 : a < b ? -1 : 0

export default {
  name: 'DataTables',
  components: {DataPagination},
  mixins: [ShareMixin],
  props: {
    twoRowsColumn: {
      type: Boolean,
    },
    sortMethod: {
      type: Object,
      default () {
        return {}
      }
    },
    filterProps: Array,
  },
  data () {
    return {
      innerfilterProps: []
    }
  },
  created () {
    this.setInnerFilterProps(this.filterProps)
    this._filterFnCache = Object.create(null)
    this._sortFnCache = Object.create(null)
  },
  computed: {
    sortedData () {
      if (this.sortData.order) {
        const sortedData = this.data.slice()

        const { order, prop } = this.sortData
        const isDescending = order === 'descending'

        const _sortMethod = this.createSortFn(prop, isDescending)
        sortedData.sort(_sortMethod)
        return sortedData
      }

      return this.data
    },
    tableData () {
      let filteredData = this.sortedData.slice()

      this.filters.forEach(filter => {
        const value = filter.value
        if ((isArray(value) && value.length === 0) ||
          (value === undefined || value === '')) {
          return true
        }

        const filterFn = filter.filterFn ||
          this.createFilterFn(filter.prop)

        filteredData = filteredData.filter(el => {
          return filterFn(el, filter)
        })
      })
      this.$emit('filtered-data', filteredData)
      return filteredData
    },
    curTableData () {
      if (this.paginationShow) {
        const from = this.innerPageSize * (this.innerCurrentPage - 1)
        const to = from + this.innerPageSize
        return this.tableData.slice(from, to)
      } else {
        return this.tableData
      }
    },
    total () {
      return this.tableData.length
    },
  },
  methods: {
    updateOffsetValue (){
      setTimeout(()=> {
        document.querySelectorAll('.test-class').forEach((el) => {
          if (el.style.display === 'none'){
            el.style.opacity = '0'
            return
          }
          if ( el.style.top.includes('calc') || el.style.left.includes('calc')) {
            return
          }
          el.style.top = `calc((${el.style.top} / var(--zoom)))`
          el.style.left = `calc((${el.style.left} + 24px)`
          el.style.opacity = '1'
        })
      }, 250)
    },
    sort (prop, order) {
      this.$refs.elTable?.sort(prop, order)
    },
    handleSort (obj) {
      this.$emit('sort-change', obj)
      this.sortData = obj
    },
    handleSizeChange (size) {
      this.innerPageSize = size
      this.$emit('size-change', size)
    },
    // cache filter function
    createFilterFn (prop) {
      let key
      const props = prop || this.innerfilterProps
      if (isArray(props)) {
        key = props.join('')
      } else if (isString(prop)) {
        key = props
      } else {
        console.error('prop must be string or array')
        return () => false
      }

      const hit = this._filterFnCache[key]
      if (hit) {
        return hit
      }

      /**
       * el: the row in table
       * filter: the filter Object.
       *    {
       *      prop: string | array
       *      value: any
       *    }
       */
      this._filterFnCache[key] = function (el, filter) {
        return isArray(props)
          ? props.some(prop => stringPropFilterFn(prop, el, filter, true))
          : stringPropFilterFn(props, el, filter)
      }

      return this._filterFnCache[key]
    },
    createSortFn (prop, isDescending) {
      const key = prop + isDescending

      const hit = this._sortFnCache[key]
      if (hit) {
        return hit
      }
      this._sortFnCache[key] = (a, b) =>{
        return (this.sortMethod[prop] || defaultSortFn)(a[prop], b[prop]) * (isDescending ? -1 : 1)
      }
      return this._sortFnCache[key]
    },
    setInnerFilterProps (val) {
      this.innerfilterProps = val || Object.keys(((this.data && this.data[0]) || {}))
    }
  },
  watch: {
    filterProps (val) {
      this.setInnerFilterProps(val)
    },
    filters () {
      this.innerCurrentPage = 1
    },
    sortMethod () {
      this._sortFnCache = []
    }
  },
}
</script>
