<template>
  <div id="strategy-filters">
    <v-row class="flex-nowrap">
      <template v-for="(dimension, i) in dimensions">
        <v-col
          v-if="dimension.name === 'store_keys'"
          :key="i"
          class="pb-0"
        >
          <StoresSelect
            :keys="filtersForm.store_keys"
            :store-key-list="storeKeyList"
            restrict-list
            select-all
            class="pt-0"
            @update:keys="onStoreKeysChange"
          />
        </v-col>
        <template v-else-if="dimension.name === 'week_numbers'">
          <v-col
            :key="i + 'week_min'"
            class="pb-0"
          >
            <WeekSelector
              v-model.number="filtersForm.week_min"
              label="Week min"
              clearable
              hide-details="auto"
              class="pt-0"
              :only-future="weekMinOnlyFuture"
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
              @input="onFilterChangeDelayed"
            ></WeekSelector>
          </v-col>
          <v-col
            :key="i + 'max'"
            class="pb-0"
          >
            <WeekSelector
              v-model.number="filtersForm.week_max"
              label="Week max"
              clearable
              :lower-limit="filtersForm.week_min"
              class="pt-0"
              :menu-props="{
                left: true,
                offsetY: true,
                contentClass: 'select-dropdown-menu',
              }"
              @input="onFilterChangeDelayed"
            />
          </v-col>
        </template>
        <v-col
          v-else
          :key="i + dimension.name"
          class="pb-0"
        >
          <collapsible-select
            show-select-all
            autocomplete
            :items="dimension.values"
            :label="dimension.name | formatString"
            v-model="filtersForm[dimension.name]"
            class="pt-0"
            @input="onFilterChangeDelayed"
          />
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import { debounce, xor } from 'lodash'
import { addYears, format } from 'date-fns'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import StoresSelect from '@/components/StoresSelect.vue'
import WeekSelector from '../WeekSelector'

export default {
  name: 'StrategyFilters',
  components: { WeekSelector, CollapsibleSelect, StoresSelect },
  props: {
    dimensions: {
      type: Array,
      default: () => [],
    },
    storeDimensions: {
      type: Array,
      default: () => [],
    },
    weeksInPast: {
      type: Boolean,
      default: false,
    },
    weekMinOnlyFuture: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    let weekMinDefault
    let weekMaxDefault

    if (this.weeksInPast) {
      weekMinDefault = Number(format(addYears(new Date(), -1), 'yyyyww'))
      weekMaxDefault = Number(format(new Date(), 'yyyyww'))
    } else {
      weekMinDefault = Number(format(new Date(), 'yyyyww'))
      weekMaxDefault = Number(format(addYears(new Date(), 1), 'yyyyww'))
    }

    return {
      weekMinDefault,
      weekMaxDefault,
      filtersForm: {
        store_keys: [],
        week_min: weekMinDefault,
        week_max: weekMaxDefault,
      },
      onFilterChangeDelayed: debounce(this.onFilterChange, 2000),
    }
  },
  computed: {
    isWSSIPage () {
      return this.$route.name === 'strategy-wssi-page'
    },
    storeKeyList () {
      return this.dimensions.find(d => d.name === 'store_keys').values
    },
  },
  watch: {
    dimensions: {
      handler (newVal, oldVal) {
        newVal.forEach(dimension => {
          if (!this.filtersForm[dimension.name]) {
            this.$set(this.filtersForm, dimension.name, [])
          }
        })

        if (oldVal && newVal) {
          const oldKeys = oldVal.map(x => x.name)
          const newKeys = newVal.map(x => x.name)
          const diff = xor(oldKeys, newKeys)

          if (JSON.stringify(oldKeys) !== JSON.stringify(newKeys)) {
            // don't reset weeks when the only changed dimension is storeDimension (it mean noStores was changed in parent component, we have to keep weeks selection in this case)

            const resetWeeks = (diff.length !== 1) || (diff[0] !== this.storeDimensions[0])
            this.resetFilters(resetWeeks)
          }
        }
      },
      immediate: true,
    },
  },
  mounted () {
    const savedFilters = JSON.parse(localStorage.getItem('strategyFilters'))
    const savedWSSIFilters = JSON.parse(localStorage.getItem('strategyWSSIFilters'))
    const period = JSON.parse(localStorage.getItem('strategySideFilterPeriod'))
    const WSSIperiod = JSON.parse(localStorage.getItem('strategySideFilterWSSIPeriod'))

    if (savedFilters) {
      Object.keys(savedFilters).forEach(key => {
        this.filtersForm[key] = savedFilters[key]
      })
    }

    // WSSI filter week_min stored separately
    if (this.isWSSIPage && savedWSSIFilters) {
      this.filtersForm.week_min = savedWSSIFilters.week_min
    }

    if (this.isWSSIPage && WSSIperiod) {
      this.setWeeksFromPeriod(WSSIperiod)
    }

    if (!this.isWSSIPage && period) {
      this.setWeeksFromPeriod(period)
    }

    this.$emit('filters-loaded', this.filtersForm)
  },
  methods: {
    resetFilters (resetWeeks) {
      localStorage.removeItem('strategyFilters')

      if (this.isWSSIPage) {
        localStorage.removeItem('strategyWSSIFilters')
      }

      this.filtersForm.store_keys = []

      if (resetWeeks) {
        this.filtersForm.week_min = this.weekMinDefault
        this.filtersForm.week_max = this.weekMaxDefault
      }

      this.$emit('filters-changed', this.filtersForm)
    },
    setWeeksFromPeriod (period) {
      this.filtersForm.week_min = period.week_min
      this.filtersForm.week_max = period.week_max
    },
    onStoreKeysChange (val) {
      this.filtersForm.store_keys = val
      this.onFilterChangeDelayed()
    },
    onFilterChange () {
      if (this.filtersForm.week_min >= this.filtersForm.week_max) {
        this.filtersForm.week_max = null
      }

      localStorage.setItem('strategyFilters', JSON.stringify(this.filtersForm))

      // WSSI filter week_min stored separately
      if (this.isWSSIPage) {
        localStorage.setItem('strategyWSSIFilters', JSON.stringify({
          week_min: this.filtersForm.week_min,
        }))
      }

      this.$emit('filters-changed', this.filtersForm)
    },
  },
}
</script>

<style lang="scss">
#strategy-filters {
}
</style>
