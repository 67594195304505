export const pricingHomeViews = [
  {
    'dataset': 'pricing_markdowns',
    'view_name': 'Stock vs Residual',
    'chart_type': 'scatter_chart',
    'dimensions': [
      'properties/article/group'
    ],
    'measures': [{
      'name': 'Residual',
      'decimals': 0,
      'calculation': 'residual_pieces'
    }, {
      'name': 'Stock',
      'decimals': 0,
      'calculation': 'stock'
    }, {
      'name': 'Residual ST',
      'decimals': 2,
      'calculation': 'residual_st'
    }],
    'filters': {},
    'properties': {
      'axis': {
        'x': 'Residual',
        'y1': 'Stock',
        'x_min': 0,
        'y1_min': 0,
        'grading_max': 0,
        'grading_min': 1,
        'color_grading': 'Residual ST'
      },
      'limit': null,
    },
    'creation_date': '2022-07-25 14:47:46'
  },
  {
    'dataset': 'pricing_markdowns',
    'view_name': 'High residual',
    'chart_type': 'table',
    'dimensions': [
      'properties/article/key'
    ],
    'measures': [
      { 'name': 'Proposals', 'decimals': 0, 'calculation': 'proposals_count' },
      {
        'name': 'Stock',
        'calculation': 'stock'
      },
      {
        'name': 'Residual',
        'decimals': 0,
        'calculation': 'residual_current'
      },
      {
        'name': 'Stock value',
        'decimals': 0,
        'calculation': 'stock_value'
      },
      {
        'name': 'Residual value',
        'decimals': 0,
        'calculation': [
          'price',
          '*',
          'residual_current'
        ],
        'color_grading': true
      },
      {
        'name': 'Weeks on hand',
        'calculation': 'weeks_on_hand'
      },
      {
        'name': 'Weeks left',
        'calculation': 'weeks_left'
      },
      {
        'name': 'OSP',
        'decimals': 2,
        'calculation': 'original_price'
      },
      {
        'name': 'ASP',
        'decimals': 2,
        'calculation': 'price'
      },
      {
        'name': 'MSP',
        'decimals': 2,
        'calculation': 'price'
      }
    ],
    'filters': {},
    'properties': {
      'rows': 10,
      'sort': 'Residual value',
      'total_row': false
    },
    'creation_date': '2022-07-25 14:56:35'
  },
  {
    'dataset': 'pricing_markdowns',
    'view_name': 'Markdowns proposals',
    'chart_type': 'table',
    'dimensions': [
      'properties/article/group',
    ],
    'measures': [
      {
        'name': 'Intake',
        'calculation': 'intake'
      },
      {
        'name': 'Stock',
        'calculation': 'stock'
      },
      {
        'name': 'Stock ST%',
        'decimals': 2,
        'percentage': true,
        'calculation': [
          1,
          '-',
          'stock',
          '/',
          'intake'
        ],
        'color_grading': true
      },
      {
        'name': 'On hand (w)',
        'decimals': 1,
        'calculation': 'weeks_on_hand'
      },
      {
        'name': 'Left (w)',
        'decimals': 1,
        'calculation': 'weeks_left'
      },
      {
        'name': 'Sales L5W',
        'decimals': 0,
        'calculation': 'sales_l5w'
      },
      {
        'name': 'Residual',
        'decimals': 0,
        'calculation': 'residual_current'
      },
      {
        'name': 'Residual ST%',
        'decimals': 2,
        'percentage': true,
        'calculation': [1, '-', 'residual_current', '/', 'intake'],
        'color_grading': true },
      {
        'name': 'Uplift',
        'decimals': 0,
        'percentage': false,
        'calculation': 'value_uplift',
      },
      {
        'name': 'Proposals',
        'decimals': 0,
        'calculation': 'proposals_count'
      }
    ],
    'filters': {},
    'properties': {
      'rows': 10,
      'sort': 'Uplift',
      'child_rows': 'properties/article/key',
      'total_row': true
    },
    'creation_date': '2022-07-25 14:57:04'
  }
]
