<template>
  <div class="reset-me">
    Refreshing... Your page will reload in a moment. In case it doesn't, <button @click="manuallyGoHome" class="click-me"> click here</button>.
  </div>
</template>
<script>
export default {
  name: 'ResetMe',
  mounted () {
    this.$auth.ResetCurrentOrg()
      .catch(e => {
        console.error('Failed to reset current org config', e)
        this.manuallyGoHome()
      })
  },
  methods: {
    manuallyGoHome () {
      this.$router.push({ path: '/' })
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
.reset-me {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
}
.click-me {
  margin-left: 0.5rem;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
</style>
