<template>
  <v-card class="pb-6" min-height="400px" flat>
    <v-row class="justify-space-between pa-4 ma-0 align-center">
      <div></div>
      <h4 >Predictions</h4>
      <DataExport
        @item-click="(sep)=> downloadChartData(sep, $refs['prediction_graph_optimization'])"
      />
    </v-row>
    <chart-component
      class="mr-6"
      ref="prediction_graph_optimization"
      title="prediction_graph_optimization"
      endpoint="settings/optimization/graph_predictions"
      :params="{format: 'doa'}"
    ></chart-component>

  </v-card>
</template>

<script>
import ChartComponent from '../chart/ChartComponent'
import DataExport from '../components/DataExport'
import { downloadChartData } from '../variables'

export default {
  name: 'SettingsDataOptimization',
  components: { DataExport, ChartComponent },
  methods: {
    downloadChartData,
  }
}
</script>

<style scoped>

</style>
