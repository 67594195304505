<template>
  <v-dialog
    :value="isOpen"
    persistent
    content-class="align-self-start mt-14"
    width="600px"
    max-width="90%">
    <v-card class="p-relative rounded-0">
      <v-card-title v-if="title" class="text-body-1 font-weight-medium">
        {{ title }}
      </v-card-title>
      <v-divider v-if="title"></v-divider>
      <v-card-text>
        <div class="text-body-1 pt-8">{{text}} </div>
      </v-card-text>
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2">
          <v-col>
            <v-btn
              text
              depressed
              @click="$emit('cancel')"
              class="mx-4 red--text"> Cancel </v-btn>
            <v-btn
              color="primary"
              depressed
              outlined
              @click="$emit('confirm')"> Confirm </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  emits: ['confirm', 'cancel'],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    }
  },
}
</script>

<style scoped>

</style>
