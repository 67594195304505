<template>
  <BaseAlertModal
    ref="alertModal"
    :dialog-visible="dialogVisible"
    :option-name="alertData.optionName"
    :option-key="alertData.dummyKey"
    @close-click="$emit('close-click')"
    @alert-create="$emit('alert-create')"
    @form-submit="handleFormSubmit"
  />
</template>

<script>
import BaseAlertModal from '@/components/Alerts/BaseAlertModal'
import { useAlertStore } from '@/store/pinia/alertStore'

export default {
  setup () {
    return { alertStore: useAlertStore() }
  },
  components: { BaseAlertModal },
  props: ['dialogVisible'],
  computed: {
    alertData () {
      const ad = this.alertStore.optionAlertData
      if (!ad) {
        return {}
      }
      return {
        page: ad.page,
        optionKey: ad.option_key,
        optionName: ad.option_name,
        inboundKey: ad.inbound_key,
      }
    },
  },
  methods: {
    getAlertPayload () {
      return {
        payload: {
          page: this.alertData.page,
          page_payload: {
            option_key: parseInt(this.alertData.optionKey),
            inbound_key: parseInt(this.alertData.inboundKey),
          },
        },
      }
    },
    handleFormSubmit () {
      const payload = this.getAlertPayload()
      this.$refs['alertModal'].createAlert(payload)
    },
  },
}
</script>

<style scoped lang="scss">
.option-alert-modal {
}
</style>
