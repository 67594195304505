import {defineStore} from 'pinia'
import {getStoresPropertiesValues, getStoresPropertyKeys} from '@/api/storeSets'
import {getPropertiesAndValues} from '@/api/options'
import {Optional} from '@/models/TypeHelpers'


const CACHING_TIME = 5 * 60 * 1000 // 5 minutes
const WithinCachingTime = (date: Date) => {
  const now = new Date()
  return now.getTime() - date.getTime() < CACHING_TIME
}

type PropertyValues = { [key: string]: string[] }

export const usePropertiesStore = defineStore('properties', {
  state: () => ({
    optionsPropertiesList: [] as string[],
    optionsValuesList: {} as PropertyValues,
    optionPropertiesUpdatedAt: null as Optional<Date>,

    storesPropertiesList: [] as string[],
    storesPropertiesUpdatedAt: null as Optional<Date>,
  }),
  actions: {
    async loadSKUOptionsProperties (force = false) {
      // if option properties were updated less than CACHING_TIME minutes ago, don't update
      if (!force && this.optionPropertiesUpdatedAt && WithinCachingTime(this.optionPropertiesUpdatedAt)) {
        return
      }
      const result = await getPropertiesAndValues('skus')
      this.optionsPropertiesList = result.properties
      this.optionsValuesList = result.values
      this.optionPropertiesUpdatedAt = new Date()
    },
    loadStoresProperties (force = false) {
      // if store properties were updated less than CACHING_TIME minutes ago, don't update
      if (!force && this.storesPropertiesUpdatedAt && WithinCachingTime(this.storesPropertiesUpdatedAt)) {
        return
      }
      this.storesPropertiesList = getStoresPropertyKeys()
      this.storesPropertiesUpdatedAt = new Date()
    },
    getStoresPropertiesValues (keys: string[]): Record<string, string[]> {
      const result = getStoresPropertiesValues(keys) as Record<string, string[]>
      if (!result) {
        return {}
      }
      return result
    }
  }
})
