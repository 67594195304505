<template>
  <div
    class="strategy-anomalies-table-child-row p-relative"
  >
    <v-row>
      <v-col>
        <h4 class="mt-5">Zoom</h4>
        <div
          class="chart-container mt-4 mx-4"
          style="position: relative; height: 350px;"
        >
          <canvas :id="canvasID"></canvas>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Chart } from 'chart.js/auto'
import { formatLargeNumber } from '@/utils'

export default {
  name: 'StrategyAnomaliesTableChildRow',
  components: {},
  props: {
    dimension: {
      type: String,
      required: true,
    },
    chartData: {
      type: Object || null,
      default: null,
    },
    weeks: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    this.chartOptions = {
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      hover: {
        mode: 'index',
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          grid: {
            display: false
          },
          ticks: {
            font: {
              size: 14,
            }
          },
          offset: true,
        },
        y: {
          position: 'left',
          title: {
            display: false,
          },
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            callback: (value)=> formatLargeNumber(value),
            padding: 16,
            font: {
              size: 14,
            }
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: context => {
              return this.$options.filters.formatThousands(context.raw)
            },
          }
        },
      },
    }
    this.chart = null

    return {
    }
  },
  computed: {
    canvasID () {
      return this.dimension + '-zoomChart'
    },
  },
  watch: {
  },
  methods: {
    drawChart () {
      if (this.chart) {
        this.chart.destroy()
      }

      const ctx = document.getElementById(this.canvasID)

      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.weeks,
          datasets: [{
            label: 'Actual',
            data: this.chartData.actual,
            borderColor: '#007AFF55',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            pointRadius: null,
            tension: 0.4,
          }, {
            label: 'Budget',
            data: this.chartData.budget,
            borderColor: '#007AFF',
            borderWidth: 2,
            backgroundColor: '#FFFFFF',
            pointRadius: null,
            tension: 0.4,
          }],
        },
        options: this.chartOptions,
      })
    }
  },
  mounted () {
    this.drawChart()
  },
  destroyed () {
    if (this.chart) {
      this.chart.destroy()
    }
  },
}
</script>

<style lang="scss">
.strategy-anomalies-table-child-row {
}
</style>
