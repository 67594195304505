<template>
  <v-row class="mx-0">
    <v-row>
      <v-col cols="12" class="pa-0 ma-0 mt-2" v-if="chosenDataset">
        <h4 class="mx-6 py-4">{{dimensionsName}}</h4>
        <v-row
          v-for="(measure, index) in newView.measures"
          :key="index"
          :id="index"
          class="mx-3 flex-nowrap ma-0"
          style="background: white"
          draggable="true"
          @dragstart="drag"
          @drop="ondrop"
          @dragover="allowDrop"
        >
          <v-col class="pa-0 mx-3 my-0" cols="11">
            <v-row class="ma-0 flex-nowrap">
              <v-icon>drag_handle</v-icon>
              <span class="d-flex align-center mx-2">{{index+1}}</span>
              <v-row class="align-start flex-nowrap my-0">
                <v-col cols="5" md="2" class="py-0">
                  <v-text-field
                    clearable
                    label="Name"
                    @input="value=> onNameChange(measure, value)"
                    :value="measure.name"
                    :error-messages="measure.name  ? '' :  'Can not be empty' "
                  ></v-text-field>
                </v-col>
                <v-col cols="5" md="2" class="py-0" v-if="typeof measure.calculation === 'string'">
                  <v-autocomplete
                    :menu-props="{
                      left: true,
                    }"
                    clearable
                    v-if="chosenDataset"
                    :items="metrics"
                    :value="measure.calculation"
                    @input="calculationChange($event, measure)"
                    :error-messages="measure.calculation ? '' : 'Can not be empty' "
                    label="Metric"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="5" md="2" class="py-0" v-else
                  @click="openCalculationModal(measure)">
                  <v-text-field
                    readonly
                    outlined
                    style="pointer-events: none"
                    append-icon="calculate"
                    v-if="chosenDataset"
                    :error-messages="measure.calculation[0] ? '' : 'Can not be empty' "
                    label="Measure"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="auto" xl="1" class="d-flex" style="height: 100%;" v-if="newView.chart_type !== 'waterfall_chart'">
                  <v-menu :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="action-btn-primary align-self-center" v-bind="attrs" v-on="on">tune</v-icon>
                    </template>
                    <v-card class="measures-properties" width="400">
                      <h4 class="ml-3 mb-2 pt-6 edit-parameters-title"> Edit parameters </h4>
                      <v-col class="py-0" v-if="newView.chart_type !== 'table' && newView.chart_type !== 'doughnut_chart' && newView.chart_type !== 'waterfall_chart'">
                        <v-autocomplete
                          :menu-props="{
                            left: true,
                          }"
                          :items="types"
                          v-model="measure.type"
                          label="Type"
                          @input="(value)=> {
                            if(value === 'bar') measure.axis = 'y1'
                            else {
                              measure.color_code = measure.color_code || '#FF9800';
                            }
                          }"
                        ></v-autocomplete>
                      </v-col>
                      <v-col class="py-0" v-if="newView.chart_type !== 'table' && newView.chart_type !== 'doughnut_chart' && newView.chart_type !== 'waterfall_chart'">
                        <v-autocomplete
                          :menu-props="{
                            left: true,
                          }"
                          :items="axisSelects"
                          v-model="measure.axis"
                          :disabled="newView.chart_type === 'bar_chart' && measure.type && measure.type !== 'point'"
                          label="Axis"
                        ></v-autocomplete>
                      </v-col>
                      <v-col class="py-0 px-0 d-flex" v-if="newView.chart_type === 'bar_chart' && measure.type !== 'point' && !newView.dimensions[1]">
                        <v-menu
                          top
                          offset-x
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-row
                              class="ma-0 pa-0 mb-2 justify-center"
                              v-bind="attrs"
                              v-on="on">
                              <v-col class="py-0">
                                <span class="theme--light v-label text-no-wrap" style="font-size: 14px">Fill</span>
                                <v-card
                                  v-if="measure.fill_color_code === ''"
                                  class="color-picker no-color ma-0 pa-0">
                                  <div class="no-color-line"/>
                                </v-card>
                                <div
                                  v-else
                                  class="color-picker"
                                  :style="{backgroundColor: measure.fill_color_code}"
                                />
                              </v-col>
                            </v-row>
                          </template>

                          <v-card class="pa-0 pb-1">
                            <color-picker
                              :chosen-color="measure.fill_color_code"
                              @input="(value)=> setColor(value, measure, 'fill_color_code')">
                              <v-card
                                class="color-picker no-color ma-0 pa-0"
                                @click="()=> measure.fill_color_code =''">
                                <div class="no-color-line"/>
                              </v-card>
                            </color-picker>
                          </v-card>
                        </v-menu>
                      </v-col>
                      <v-col class="py-0 px-0 d-flex pb-2" v-if="newView.chart_type !== 'table' && !newView.dimensions[1] && newView.chart_type !== 'doughnut_chart' && newView.chart_type !== 'waterfall_chart'">
                        <v-menu
                          top
                          offset-y
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-row
                              class="ma-0 pa-0 justify-start">
                              <v-col class="py-0" cols="auto"
                                v-bind="attrs"
                                v-on="on">
                                <span class="theme--light v-label text-no-wrap" style="font-size: 14px">
                                  {{newView.chart_type === 'bar_chart' && measure.type !== 'point' ? 'Border': 'Color'}}
                                </span>
                                <v-card
                                  v-if="measure.border_color_code === ''"
                                  class="color-picker no-color ma-0 pa-0">
                                  <div class="no-color-line"/>
                                </v-card>
                                <div
                                  v-else
                                  class="color-picker ma-0 pa-0"
                                  :style="{
                                    backgroundColor:
                                      newView.chart_type !== 'bar_chart' || measure.type === 'point' ?
                                        measure.color_code :
                                        measure.border_color_code
                                  }"
                                />
                              </v-col>
                            </v-row>
                          </template>
                          <v-card class="pa-0  pb-1">
                            <color-picker
                              :chosen-color="measure.border_color_code"
                              @input="(value)=> setColor(value, measure, 'border_color_code')">
                              <v-card
                                v-if="changeView.chart_type === 'bar_chart' && measure.type !== 'point'"
                                class="color-picker no-color ma-0 pa-0"
                                @click="()=> measure.border_color_code =''">
                                <div class="no-color-line"/>
                              </v-card>
                            </color-picker>
                          </v-card>
                        </v-menu>
                      </v-col>
                      <v-col class="pa-0 align-center d-flex justify-end"
                        style="height: 100%" v-if="newView.chart_type === 'waterfall_chart' && index !== 0" cols="auto" xl="1">
                        <span
                          class="d-flex align-center justify-space-between flex xs12">
                          <span class="theme--light v-label text-no-wrap mr-2">Relative</span>
                          <v-checkbox
                            class="my-0 py-0 ml-2"
                            hide-details
                            v-model="measure.relative"
                          ></v-checkbox>
                        </span>
                      </v-col>
                      <v-col class="py-0">
                        <v-text-field
                          clearable
                          hide-details="auto"
                          type="number"
                          label="Decimals"
                          v-model.number="measure.decimals"
                        ></v-text-field>
                      </v-col>
                      <v-col class="py-0">
                        <v-row class="ma-0 flex-nowrap text-no-wrap">
                          <v-text-field
                            clearable
                            type="number"
                            thumb-label
                            label="Width (50-500)"
                            hide-details="auto"
                            :min="50"
                            :max="500"
                            :value="measure.width"
                            @input="$set(measure, 'width', parseInt($event))"
                            :error-messages="measure.width < 50 || measure.width > 500 ? 'Must be between 50 and 500' : ''"
                          />
                        </v-row>
                      </v-col>
                      <v-col class=" align-center d-flex pr-1"
                        v-if="newView.chart_type === 'table'"
                      >
                        <span class=" d-flex align-center justify-space-between flex xs12">
                          <span class="theme--light v-label text-no-wrap mr-2">Total only</span>
                          <v-checkbox
                            class="my-0 py-0 mx-2 "
                            hide-details
                            v-model="measure.total_only"
                          ></v-checkbox>
                        </span>
                      </v-col>
                      <v-col class=" align-center d-flex pr-1"
                        v-if="newView.chart_type === 'table' || newView.chart_type === 'bar_chart'"
                      >
                        <span class=" d-flex align-center justify-space-between flex xs12">
                          <span class="theme--light v-label text-no-wrap mr-2">Aggregate</span>
                          <v-autocomplete
                            :menu-props="{
                              left: true,
                            }"
                            class="my-0 py-0 mx-2 text-capitalize"
                            hide-details
                            :items="aggregateItems"
                            clearable
                            v-model="measure.aggregate"
                            @input="measure.percentage = measure.normalize || measure.percentage"
                          ></v-autocomplete>
                        </span>
                      </v-col>
                      <v-col class="py-0" v-if="measure.aggregate">
                        <v-row class="ma-0 flex-nowrap text-no-wrap pt-2">
                          <collapsible-select
                            hide-details
                            style="width: 100%"
                            v-model="measure.aggregate_by"
                            :items="dimensions"
                            label="Aggregate by"
                            clearable
                            autocomplete
                          ></collapsible-select>
                        </v-row>
                      </v-col>
                      <v-col class=" align-center d-flex pr-1"
                        v-if="newView.chart_type === 'table' || newView.chart_type === 'bar_chart' "
                      >
                        <span class=" d-flex align-center justify-space-between flex xs12">
                          <span class="theme--light v-label text-no-wrap mr-2">Percentage</span>
                          <v-checkbox
                            class="my-0  mx-2 "
                            hide-details
                            v-model="measure.percentage"
                          ></v-checkbox>
                        </span>
                      </v-col>
                      <v-col class=" align-center d-flex pr-1"
                        v-if="newView.chart_type === 'table'"
                      >
                        <span class=" d-flex align-center justify-space-between flex xs12">
                          <span class="theme--light v-label text-no-wrap mr-2">Value Filtering</span>
                          <v-checkbox
                            class="my-0  mx-2 "
                            hide-details
                            v-model="measure.value_filtering"
                          ></v-checkbox>
                        </span>
                      </v-col>
                      <v-col class=" align-center d-flex pr-1"
                        v-if="newView.chart_type === 'line_chart' || newView.chart_type === 'bar_chart'"
                      >
                        <span class=" d-flex align-center justify-space-between flex xs12">
                          <span class="theme--light v-label text-no-wrap mr-2">Hide</span>
                          <v-checkbox
                            class="my-0 py-0 mx-2 "
                            hide-details
                            v-model="measure.hidden"
                          ></v-checkbox>
                        </span>
                      </v-col>
                      <v-col class=" align-center d-flex justify-end color-grading" v-if="newView.chart_type === 'table'" >
                        <span class=" d-flex align-center justify-space-between flex xs12">
                          <span class="theme--light v-label text-no-wrap mr-2">Color Grade</span>
                          <v-checkbox
                            class="my-0 py-0 ml-2"
                            hide-details
                            v-model="measure.color_grading"
                          ></v-checkbox>
                        </span>
                      </v-col>
                      <template class="ma-0" v-if="measure.color_grading">
                        <v-col cols="12">
                          <ColorRangePicker
                            :value="colorScales(measure).value"
                            @input="updateColorsValues(measure, $event)"
                            :colors="colorScales(measure).colors"
                          ></ColorRangePicker>
                          <v-row class="justify-space-between ml-0 ">
                            <span class="theme--light v-label text-no-wrap mr-2">Min value</span>
                            <span class="theme--light v-label text-no-wrap mr-4">Max value</span>
                          </v-row>
                          <v-row class="justify-space-between mt-0">
                            <v-col class="pt-0">
                              <v-text-field
                                v-model="measure.min"
                                clearable
                                hide-details
                              ></v-text-field>
                              <v-menu
                                top
                                offset-x
                                :close-on-content-click="false"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-row
                                    class="ma-0 pa-0 justify-end align-center"
                                    v-bind="attrs"
                                    v-on="on">
                                    <div
                                      class="color-picker mt-2"
                                      style="min-height: 24px; max-height: 24px; width: 100%"
                                      :style="{backgroundColor: measure.min_color_code || '#085D70'}"
                                    />
                                  </v-row>
                                </template>
                                <v-card class="pa-0 pb-1">
                                  <color-picker
                                    :chosen-color="measure.min_color_code || '#085D70'"
                                    @input="(value)=> {$set(measure, 'min_color_code', value); $set(colors, 0, value)}">
                                  </color-picker>
                                </v-card>
                              </v-menu>
                            </v-col>
                            <v-col class="pt-0">
                              <v-text-field
                                prefix="%"
                                v-model="measure.first"
                                @input="$set(measure,'first_color', $event? colors[1] : '')"
                                hide-details
                              ></v-text-field>
                              <v-menu
                                top
                                offset-x
                                :close-on-content-click="false"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-row
                                    class="ma-0 pa-0 justify-end align-center"
                                    v-bind="attrs"
                                    v-on="on">
                                    <div
                                      class="color-picker mt-2"
                                      style="min-height: 24px; max-height: 24px; width: 100%"
                                      :style="{backgroundColor: measure.first_color}"
                                    />
                                  </v-row>
                                </template>
                                <v-card class="pa-0 pb-1">
                                  <color-picker
                                    :chosen-color="measure.first_color"
                                    @input="(value)=> {$set(measure, 'first_color', value); $set(colors, 1, value); $set(measure, 'first', 25) }">
                                  </color-picker>
                                </v-card>
                              </v-menu>

                            </v-col>
                            <v-col class="pt-0">
                              <v-text-field
                                prefix="%"
                                v-model="measure.second"
                                @input="$set(measure,'second_color', $event? colors[2] : '')"
                                hide-details
                              ></v-text-field>
                              <v-menu
                                top
                                offset-x
                                :close-on-content-click="false"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-row
                                    class="ma-0 pa-0 justify-end align-center"
                                    v-bind="attrs"
                                    v-on="on">
                                    <div
                                      class="color-picker mt-2"
                                      style="min-height: 24px; max-height: 24px; width: 100%"
                                      :style="{backgroundColor: measure.second_color}"
                                    />
                                  </v-row>
                                </template>
                                <v-card class="pa-0 pb-1">
                                  <color-picker
                                    :chosen-color="measure.second_color"
                                    @input="(value)=> {$set(measure, 'second_color', value); $set(colors, 2, value);$set(measure, 'second', 50) }">
                                  </color-picker>
                                </v-card>
                              </v-menu>
                            </v-col>
                            <v-col class="pt-0">
                              <v-text-field
                                prefix="%"
                                v-model="measure.third"
                                @input="$set(measure,'third_color', $event? colors[3] : '')"
                                hide-details
                              ></v-text-field>
                              <v-menu
                                top
                                offset-x
                                :close-on-content-click="false"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-row
                                    class="ma-0 pa-0 justify-end align-center"
                                    v-bind="attrs"
                                    v-on="on">
                                    <div
                                      class="color-picker mt-2"
                                      style="min-height: 24px; max-height: 24px; width: 100%"
                                      :style="{backgroundColor: measure.third_color}"
                                    />
                                  </v-row>
                                </template>
                                <v-card class="pa-0 pb-1">
                                  <color-picker
                                    :chosen-color="measure.third_color"
                                    @input="(value)=> {$set(measure, 'third_color', value); $set(colors, 3, value); $set(measure, 'third', 75)}">

                                  </color-picker>
                                </v-card>
                              </v-menu>

                            </v-col>
                            <v-col class="pt-0">
                              <v-text-field
                                v-model="measure.max"
                                hide-details
                                clearable
                              ></v-text-field>
                              <v-menu
                                top
                                offset-x
                                :close-on-content-click="false"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-row
                                    class="ma-0 pa-0 justify-end align-center"
                                    v-bind="attrs"
                                    v-on="on">
                                    <div
                                      class="color-picker mt-2"
                                      style="min-height: 24px; max-height: 24px; width: 100%"
                                      :style="{backgroundColor: measure.max_color_code || '#FFAB00'}"
                                    />
                                  </v-row>
                                </template>
                                <v-card class="pa-0 pb-1">
                                  <color-picker
                                    :chosen-color=" measure.max_color_code || '#FFAB00'"
                                    @input="(value)=> {$set(measure, 'max_color_code', value); $set(colors, 4, value)}">
                                  </color-picker>
                                </v-card>
                              </v-menu>

                            </v-col>
                          </v-row>
                        </v-col>
                      </template>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
          <v-col cols="1" class="pa-0 ma-0 pr-10 d-flex align-baseline align-self-center justify-end">
            <v-icon @click="()=> {newView.measures.splice(index,1)}">delete</v-icon>
          </v-col>
        </v-row>
        <v-row
          v-if="newView.chart_type === 'waterfall_chart' && newView.measures.length"
          class="mx-3 flex-nowrap ma-0"
          style="background: white"
        >
          <v-col class="pa-0 mx-3 my-0" cols="11">
            <v-row class="ma-0 flex-nowrap">
              <v-icon class="hidden">drag_handle</v-icon>
              <span class="d-flex align-center mx-2">{{newView.measures.length}}</span>
              <v-row class="align-start flex-nowrap my-0">
                <v-col cols="5" md="2" class="py-0">
                  <v-text-field
                    clearable
                    label="End name"
                    v-model="newView.properties.endName"
                    :error-messages="newView.properties.endName  ? '' :  'Can not be empty' "
                  ></v-text-field>
                </v-col></v-row>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-col cols="12" class="pt-6 px-0" v-if="chosenDataset">
      <v-col cols="2" class="pt-6">
        <v-row class="flex-nowrap pl-2">
          <v-btn
            color="rgba(0, 0, 0, 0.67)"
            outlined
            class="mr-4"
            :disabled="!canAddNew"
            label="Metric / Measure"
            @click="addNewMeasure('Metric')"
          >
            <v-icon class="pr-2">add</v-icon>
            Metric
          </v-btn>
          <v-btn
            color="rgba(0, 0, 0, 0.67)"
            outlined
            :disabled="!canAddNew"
            label="Metric / Measure"
            @click="addNewMeasure('Measure')"
          >
            <v-icon class="pr-2">add</v-icon>

            Measure
          </v-btn>

        </v-row>
      </v-col>
    </v-col>
    <v-col cols="12" class="pb-8 px-0" v-if="chosenDataset && (newView.chart_type === 'table' || newView.chart_type === 'bar_chart')">
      <v-col cols="6" md="2">
        <h4>Format</h4>
        <v-select
          v-if="newView.chart_type === 'table'"
          class="pt-6"
          hide-details
          :items="[5, 10,15, 25, 50]"
          v-model="newView.properties.rows"
          label="Default Rows"
        ></v-select>
        <v-autocomplete
          :menu-props="{
            left: true,
          }"
          v-if="newView.chart_type === 'table' || newView.chart_type === 'bar_chart'"
          label="Sort By"
          hide-details
          class="pt-6"
          :items="newView.measures.map(item=> item.name)"
          v-model="newView.properties.sort"
        ></v-autocomplete>
        <v-select
          v-if="newView.chart_type === 'table' || newView.chart_type === 'bar_chart'"
          label="Sort Order"
          hide-details

          class="pt-6"
          :items="[{value:'ascending',text:'Ascending',}, {text:'Descending', value:'descending'}]"
          v-model="newView.properties.sort_order"
        ></v-select>
        <v-text-field
          class="pt-6"
          type="number"
          hide-details
          v-model.number="newView.properties.parent_limit"
          label="Parent Limit"
        ></v-text-field>
        <v-text-field
          v-if="newView.chart_type === 'table'"
          class="pt-6"
          type="number"
          hide-details

          v-model.number="newView.properties.child_limit"
          label="Child Limit"
        ></v-text-field>
        <v-checkbox
          v-if="newView.chart_type.includes('chart')"
          class="pt-6"
          type="number"
          hide-details

          v-model.number="newView.properties.hide_legend"
          label="Hide description"
        ></v-checkbox>

        <span class=" d-flex align-center py-4"
          v-if="newView.chart_type === 'table'"
        >
          <span class="theme--light v-label text-no-wrap mr-2">Inline scroll</span>
          <v-checkbox
            class="my-0 py-0"
            hide-details
            :input-value="!newView.properties.no_inline_scroll"
            @change="newView.properties.no_inline_scroll = !$event"
          ></v-checkbox>
        </span>
        <span class=" d-flex align-center py-4"
          v-if="newView.chart_type === 'table'"
        >
          <span class="theme--light v-label text-no-wrap mr-2">Total row</span>
          <v-checkbox
            class="my-0 py-0"
            hide-details
            v-model="newView.properties.total_row"
          ></v-checkbox>
          <v-menu
            v-if="newView.properties.total_row"
            top
            offset-x
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row
                class="ma-0 pa-0 justify-center"
                v-bind="attrs"
                v-on="on">
                <v-col class="py-0">
                  <v-card
                    v-if="!newView.properties.total_row_color"
                    class="color-picker ma-0 pa-0"
                    style="background-color: #f5f7fa"
                  >
                  </v-card>
                  <div
                    v-else
                    class="color-picker"
                    :style="{backgroundColor: newView.properties.total_row_color}"
                  />
                </v-col>
              </v-row>
            </template>

            <v-card class="pa-0 pb-1">
              <color-picker
                :chosen-color="newView.properties.total_row_color"
                @input="newView.properties.total_row_color = $event">
                <v-card
                  class="color-picker ma-0 pa-0"
                  style="background-color: #f5f7fa"
                  @click="()=> newView.properties.total_row_color ='#f5f7fa'">
                </v-card>
              </color-picker>
            </v-card>
          </v-menu>

        </span>
        <div class=" d-flex align-center"
          v-if="newView.chart_type === 'table' && newView.properties.matrix"
        >
          <span class="theme--light v-label text-no-wrap mr-2">Total Column</span>
          <v-checkbox
            class="my-0 py-0 "
            hide-details
            v-model="newView.properties.total_column"
          ></v-checkbox>
          <v-menu
            v-if="newView.properties.total_column"
            top
            offset-x
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row
                class="ma-0 pa-0 justify-center"
                v-bind="attrs"
                v-on="on">
                <v-col class="py-0">
                  <v-card
                    v-if="!newView.properties.total_column_color"
                    class="color-picker ma-0 pa-0"
                    style="background-color: #f5f7fa"
                  >
                  </v-card>
                  <div
                    v-else
                    class="color-picker"
                    :style="{backgroundColor: newView.properties.total_column_color}"
                  />
                </v-col>
              </v-row>
            </template>

            <v-card class="pa-0 pb-1">
              <color-picker
                :chosen-color="newView.properties.total_column_color"
                @input="newView.properties.total_column_color = $event">
                <v-card
                  class="color-picker ma-0 pa-0"
                  style="background-color: #f5f7fa"
                  @click="()=> newView.properties.total_column_color ='#f5f7fa'">
                </v-card>
              </color-picker>
            </v-card>
          </v-menu>

        </div>
      </v-col>
    </v-col>
    <v-col cols="12" class="pb-8 px-0" v-if="chosenDataset && (newView.chart_type === 'line_chart' || newView.chart_type === 'doughnut_chart')">
      <v-col cols="6" md="2">
        <h4>Format</h4>
        <v-checkbox
          class="pt-6"
          type="number"
          hide-details

          v-model.number="newView.properties.hide_legend"
          label="Hide description"
        ></v-checkbox>
      </v-col>
    </v-col>
    <v-dialog
      v-if="metrics"
      v-model="calculationModal"
      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%"
    >
      <EditMeasure
        v-if="calculationModal"
        :metrics="metrics"
        :value="calculationRow.calculation"
        @close="calculationModal= false"
        @value-set="(value)=> calculationRow.calculation =value "></EditMeasure>
    </v-dialog>
  </v-row>
</template>

<script>
import ColorPicker from '../ColorPicker'
import EditMeasure from './EditMeasure'
import vue from 'vue'
import { formatString } from '@/variables'
import CollapsibleSelect from '@/components/CollapsibleSelect'
import ColorRangePicker from '@/components/ColorRangePicker'

export default {
  name: 'DatasetsMeasureDimensions',
  components: { ColorRangePicker, CollapsibleSelect, EditMeasure, ColorPicker },
  props: ['changeView', 'chosenDataset', 'axis', 'canAddNew'],
  data () {
    return {
      newMeasureType: 0,
      calculationModal: false,
      calculationRow: null,
      colors: ['#085D70', '#329193', '#BA448F', '#FFD202', '#FFAB00'],
      newView: { sort: null },
      sort: null,
      relativeKey: 0,
    }
  },
  mounted () {
    this.setNewObject()
  },
  watch: {
    changeView () {
      this.setNewObject()
    }
  },
  computed: {
    aggregateItems () {
      return this.chosenDataset.aggregates.map(item => ({
        value: item,
        text: this.$options.filters.formatString(item)
      }))
    },
    dimensions () {
      const dimensions = {}
      const dimensionsList = this.newView.dimensions
      dimensionsList.forEach((item) => {
        const data = item.split('/')
        const header = { header: formatString(data[1] || ''), text: [] }
        if (!dimensions[data[1]]) {
          dimensions[data[1]] = [header]
        }
        const text = data[2] === 'name' ? `Name (${formatString(data[1])})` : formatString(data[2] || '')
        dimensions[data[1]].push({ text, value: item, headerTitle: header.header })
        header.text.push(formatString(data[2] || ''), formatString(data[1] || ''))
      })
      const data = []
      for (const key in dimensions) {
        data.push(...dimensions[key])
      }
      return data
    },
    isLarge () {
      return window.innerWidth > 1900
    },
    metrics () {
      if (!this.chosenDataset) {
        return null
      }
      return this.chosenDataset.metrics.map((item) => {
        return { value: item, text: formatString(item) }
      })
    },
    dimensionsName () {
      switch (this.newView.chart_type) {
      case 'table':
        return 'Columns'
      case 'line_chart':
        return 'Lines'
      case 'bar_chart':
        return 'Bars'
      default:
        return ''
      }
    },
    axisSelects () {
      const axises = []
      if (this.axis.y1) {
        axises.push({ value: 'y1', text: 'Y-axis 1' })
      }
      if (this.axis.y2) {
        axises.push({ value: 'y2', text: 'Y-axis 2' })
      }
      return axises
    },
    types () {
      if (this.newView.chart_type === 'line_chart') {
        return [{ value: 'line', text: 'Line' }, { value: 'point', text: 'Point' }, { value: 'dotted', text: 'Dotted' }]
      }
      return [{ value: 'bar', text: 'Bar' }, { value: 'point', text: 'Point' }]
    },
  },
  methods: {
    updateColorsValues (measure, value) {
      if (value[1] || value[1] === 0) {
        this.$set(measure, 'first', value[1])
      }
      if (value[2] || value[2] === 0) {
        this.$set(measure, 'second', value[2])
      }
      if (value[3] || value[3] === 0) {
        this.$set(measure, 'third', value[3])
      }
    },
    colorScales (measure) {
      const colors = this.colors.map((color, index) => {
        switch (index) {
        case 0:
          return measure.min_color_code || color
        case 1:
          return measure.first_color || color
        case 2:
          return measure.second_color || color
        case 3:
          return measure.third_color || color
        case 4:
          return measure.max_color_code || color
        }
        return color
      })
      return {
        value: [0, measure.first || undefined, measure.second || undefined, measure.third || undefined, 100],
        colors
      }
    },
    calculationChange (value, measure) {
      if (!value) {
        measure.calculation = ''
      } else {
        if ((measure.name === formatString(measure.calculation) && value) || !measure.name) {
          measure.name = formatString(value)
        }
        measure.calculation = value
      }
      this.$set(measure, 'name', measure.name)
      this.$set(measure, 'calculation', measure.calculation)
    },
    allowDrop (ev) {
      ev.preventDefault()
    },
    onNameChange (measure, value) {
      if (measure.name === this.newView.properties.sort) {
        vue.set(this.newView.properties, 'sort', value)
      }
      vue.set(measure, 'name', value)
    },
    openCalculationModal (row) {
      this.calculationRow = row
      this.calculationModal = true
    },
    drag (ev) {
      ev.dataTransfer.dropEffect = 'move'
      ev.dataTransfer.setData('text', ev.currentTarget.id)
    },
    ondrop (ev) {
      ev.preventDefault()
      const oldTarget = ev.dataTransfer.getData('text')
      const newTarget = ev.currentTarget.id
      const element = this.newView.measures[Number(oldTarget)]
      this.newView.measures.splice(Number(oldTarget), 1)
      this.newView.measures.splice(Number(newTarget), 0, element)
    },
    formatString,
    setColor (color, measure, type) {
      if (this.newView.chart_type === 'bar_chart' && measure.type !== 'point') {
        measure[type] = color
      } else {
        measure.color_code = color
      }
    },
    setNewObject () {
      this.newView = this.changeView
    },
    addNewMeasure (value) {
      const calculation = value === 'Measure' ? [] : ''
      const colorObject = this.newView.chart_type === 'table' ? {} : { color_code: '#FF9800' }
      if (this.newView.chart_type === 'bar_chart') {
        colorObject.fill_color_code = ''
        colorObject.border_color_code = '#FF9800'
      }
      const newMeasure = {
        calculation,
        name: '',
        ...colorObject,
        axis: 'y1',
        type: this.types[0].value
      }
      this.newView.measures = [...this.newView.measures, newMeasure]
      this.newMeasureType++
    },
  }
}
</script>

<style scoped>
.color-picker {
  width: 48px;
  min-height: 34px;
  max-height: 34px;
  border-radius: 4px;
}


.no-color-line {
  height: 7px;
  min-width: 56px;
  transform: rotate(145deg);
  border-radius: 3px;
  background-color: #FF5721;
  margin-left: -4px;
}
.no-color {
  display: flex;
  align-items: center;
}
</style>
<style>

.measures-properties > .col:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}
</style>
