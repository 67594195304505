<template>
  <div>
    <v-row class="ma-6 mb-3" justify="space-between">
      <h4>Merge history for {{budget.budget_name}}</h4>
    </v-row>
    <v-row class="ma-4  mt-0 justify-end">
      <v-icon class="ml-2" @click="openMergeModal">merge</v-icon>
    </v-row>
    <data-tables
      :data="mergeHistory"
      :page-size="5"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
      :table-props="{ 'row-key': 'scenario_key' }"
      :filters="tableFilters"
      filter-multiple
      @filter-change="filters => handleFilterChange('tableFilters', filters)"
    >
      <el-table-column
        prop="merge_timestamp"
        column-key="merge_timestamp"
        label="Merge Date"
        sortable="custom"
        :filters="mergeTimestamps"
      >
        <template v-slot:header>
          <span class="ml-3">Merge Date</span>
        </template>
        <template slot-scope="scope">
          <span class="font-weight-medium text--black size ml-3">{{ formatDate(scope.row.merge_timestamp) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="user_name"
        column-key="user_name"
        label="User"
        sortable="custom"
        :filters="getColumnFilterValues('mergeHistory', 'user_name')"
      >
        <template slot-scope="scope">
          <span class="font-weight-medium text--black size">{{ scope.row.user_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="from_scenario_key"
        column-key="from_scenario_key"
        label="From Scenario"
        sortable="custom"
        :filters="fromScenarioNames"
      >
        <template slot-scope="scope">
          <span class="font-weight-medium text--black size">{{ getScenarioName(scope.row.from_scenario_key) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="to_scenario_key"
        column-key="to_scenario_key"
        label="To Scenario"
        sortable="custom"
        :filters="toScenarioNames"
      >
        <template slot-scope="scope">
          <span class="font-weight-medium text--black size">{{ getScenarioName(scope.row.to_scenario_key) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="shop_items"
        column-key="shop_items"
        label="Shop Items"
        sortable="custom"
        :filters="getColumnFilterValues('mergeHistory', 'shop_items')"
      >
        <template slot-scope="scope">
          <span class="font-weight-medium text--black size">{{ scope.row.shop_items }}</span>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { getBudgets, getScenarioMergeHistory, loadScenarios } from '@/api/budgetAPI'
import { format } from 'date-fns'
import ColumnFilters from '@/mixins/columnFilters'

export default {
  mixins: [ColumnFilters],
  name: 'BuyManageBudgetsMergeHistory',
  data () {
    return {
      loading: false,
      mergeHistory: [],
      localBudgetKey: 0,
      budgets: [],
      scenarios: [],
      tableFilters: []
    }
  },
  props: {
    budget: {
      type: Object,
      required: true
    },
    openMergeModal: {
      type: Function,
      required: true
    }
  },
  created () {
    this.loadBudgets()
    this.localBudgetKey = this.budget.budget_key
    this.budgetChanged()
  },
  computed: {
    fromScenarioNames () {
      return this.getColumnFilterValues('mergeHistory', 'from_scenario_key').map(i => {
        return {
          text: this.getScenarioName(i.text),
          value: i.value
        }
      })
    },
    toScenarioNames () {
      return this.getColumnFilterValues('mergeHistory', 'to_scenario_key').map(i => {
        return {
          text: this.getScenarioName(i.text),
          value: i.value
        }
      })
    },
    mergeTimestamps () {
      return this.getColumnFilterValues('mergeHistory', 'merge_timestamp').map(i => {
        return {
          text: this.formatDate(i.text),
          value: i.value
        }
      })
    }
  },
  watch: {
    budget: {
      handler: function (newVal) {
        if (newVal) {
          this.localBudgetKey = newVal.budget_key
          this.budgetChanged()
        }
      },
      deep: true
    }
  },
  methods: {
    async budgetChanged () {
      await this.getScenarios()
      await this.getMergeHistory()
    },
    async getMergeHistory () {
      this.loading = true
      this.mergeHistory = await getScenarioMergeHistory(this.localBudgetKey)
      this.loading = false
    },
    async getScenarios () {
      this.scenarios = await loadScenarios(this.localBudgetKey)
    },
    async loadBudgets () {
      this.budgets = getBudgets().filter(b => b.active)
    },
    formatDate (date, pattern = 'yyyy-MM-dd') {
      if (date) {
        return format(new Date(date), pattern)
      }
      return ''
    },
    getScenarioName (scenario_key) {
      const scenario = this.scenarios.find(s => s.scenario_key === scenario_key)
      return scenario ? scenario.scenario_name : ''
    },
  }
}
</script>
