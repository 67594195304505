<template>
  <div class="dummies-table">
    <v-row class="justify-space-between mx-0">
      <v-col class="py-0 pl-1">
        <slot></slot>
      </v-col>
      <v-col class="text-right pr-6" :key="menuKey">
        <TableSearch
          class="mx-2"
          @searchChanged="onSearchChange"
        />
        <v-icon v-if="!isSelected" class="mx-2 action-btn-primary" :disabled="selectedRows.length !== 1"
          @click="handleEditClick">edit
        </v-icon>

        <v-icon v-if="isSelected" class="mx-2 action-btn-primary" :disabled="selectedRows.length !== 1"
          @click="handleDuplicateClick"
        >file_copy
        </v-icon>

        <v-menu offset-y nudge-bottom="5" v-else :disabled="selectedRows.length !==1">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-2 action-btn-primary" :disabled="!selectedRows[0] || selectedRows.length !==1"
              v-bind="attrs" v-on="on">file_copy
            </v-icon>
          </template>
          <v-list dense>
            <v-list-item class="c-pointer" @click="copyVariant">
              <v-list-item-title>Duplicate variation (all options)</v-list-item-title>
            </v-list-item>
            <v-list-item class="c-pointer" @click="copyDummyNewVariant">
              <v-list-item-title>Duplicate option (new variation)</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="copyDummyWithinVariant"
              class="c-pointer"
            >
              <v-list-item-title>Duplicate option (within variation)</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y left nudge-bottom="5">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on">add</v-icon>
          </template>
          <v-list dense>
            <v-list-item class="c-pointer" @click.native="handleAddClick">
              <v-list-item-title>New template</v-list-item-title>
            </v-list-item>
            <v-list-item
              class="c-pointer"
              @click.native="addDialogVisible = true; addDialogTitle = 'Copy existing option'"
            >
              <v-list-item-title>Copy existing option</v-list-item-title>
            </v-list-item>
            <v-list-item
              class="c-pointer"
              @click.native="addDialogVisible = true; addDialogTitle = 'Import existing option'"
            >
              <v-list-item-title>Import existing option</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-icon class="mx-2 action-btn-danger" :disabled="selectedRows.length === 0 || dummies.length <=1"
          @click="deleteDialogVisible = true">delete
        </v-icon>
        <v-menu offset-y offset-x nudge-bottom="5" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on"
            >filter_list
            </v-icon>
          </template>

          <v-card max-height="400" class="overflow-auto">
            <h5 class="px-2 pt-2">Columns</h5>
            <el-checkbox-group v-model="additionalColumns" @input="saveColumns" style="font-size: 14px"
              class="d-flex flex-column pa-2">
              <el-checkbox
                v-show="item !== 'Option' && item !== 'Variation'"
                v-for="item of sortedDummyColumns" :label="item" :key="item"></el-checkbox>
            </el-checkbox-group>
            <h5 class="px-2 pt-2">Variation properties</h5>
            <el-checkbox-group v-model="additionalColumnsVariants" @input="saveVariantProperties"
              style="font-size: 14px" class="d-flex flex-column pa-2">
              <el-checkbox v-for="item of sortedVariantProperties" :label="item" :key="item"></el-checkbox>
            </el-checkbox-group>
            <h5 class="px-2 pt-2">Option properties</h5>

            <el-checkbox-group v-model="additionalColumnsProperties" @input="saveProperties" style="font-size: 14px"
              class="d-flex flex-column pa-2">
              <el-checkbox v-for="item of sortedDummyProperties" :label="item" :key="item"></el-checkbox>
            </el-checkbox-group>
          </v-card>
        </v-menu>

      </v-col>
    </v-row>
    <v-row>
      <v-col >
        <v-overlay absolute color="white" :value="loading">
          <v-progress-circular color="primary" indeterminate size="64" width="2"/>
        </v-overlay>

        <h4 class="text-no-wrap px-6 mb-4">{{ selectedRows.length }} line(s) selected
          <span v-if="selectedRows.length > 1">(changes are applied to all selected rows)</span>
        </h4>
        <data-tables
          style="width: 100%"
          :key="dummiesKey"
          :data="dummiesForTable"
          @filtered-data="filteredData = $event"
          :page-size="parseInt(pageSize)"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 mb-4 mr-2'
          }"
          :table-props="{ 'row-key': 'dummy_key', 'span-method': objectSpanMethod }"
          :filters="tableFilters"
          filter-multiple
          @update:currentPage="currentPage = $event"
          @update:pageSize="pageSize = $event; savePageSize($event)"
          @filter-change="filters => handleFilterChange('tableFilters', filters)">
          <el-table-column prop="option_key" width="55">
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-3 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column prop="variant_name" label="Variation"
            column-key="variant_name"
            v-if="additionalColumns.includes('Variation')"
            :filter-method="equalsFilter"
            :filters="getColumnFilterValues('dummiesForTable', 'variant_name')">
            <template slot-scope="scope">
              <v-textarea
                v-model="scope.row.variant_name"
                rows="2"

                @input="handleFieldChangeDebounce('variant_name', $event, scope.row)"
              ></v-textarea>
            </template>
          </el-table-column>
          <el-table-column prop="variation_key" label="Variation Key"
            column-key="variation_key"
            v-if="additionalColumns.includes('Variation key')"

            :filters="getColumnFilterValues('dummiesForTable', 'variation_key')">
            <template slot-scope="scope">
              {{ scope.row.variation_key }}
            </template>
          </el-table-column>
          <el-table-column :prop="item" :label="formatString(item)"
            :key="item"
            :column-key="item"
            :filters="getColumnFilterValues('dummiesForTable', item)"
            v-for="item in additionalColumnsVariants">
            <template slot-scope="scope">
              <v-simple-checkbox
                v-if="(managedPropertiesVariants || {})[item]?.value_type === 'boolean'"
                :value="scope.row.properties_variant[item]?.toString()?.toLowerCase() === 'true'"
                color="primary"
                dense
                class="mt-0 pt-0"
                :ripple="false"
                @input="savePropertiesVariantDebounce(item, $event, scope.row)"
              />
              <v-autocomplete
                class="align-end "
                v-else-if="(managedPropertiesVariants || {})[item]?.value_dropdown && managedPropertiesVariants[item].values"
                :error-messages="validateValueForList((managedPropertiesVariants || {})[item], scope.row.properties_variant[item])"

                v-model="scope.row.properties_variant[item]"
                :items="(managedPropertiesVariants || {})[item].values"
                :menu-props="{

                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
                @input="savePropertiesVariantDebounce(item, $event, scope.row)"
              />
              <v-textarea
                rows="2"
                class="align-end "

                v-else-if="(managedPropertiesVariants || {})[item]?.value_type === 'text'"
                :error-messages="validateValueForList((managedPropertiesVariants || {})[item], scope.row.properties_variant[item])"
                v-model="scope.row.properties_variant[item]"
                type="text"
                @input="savePropertiesVariantDebounce(item, $event, scope.row)"
              />
              <v-text-field
                v-else
                class="align-end "

                :error-messages="validateValueForList((managedPropertiesVariants || {})[item], scope.row.properties_variant[item])"
                v-model.number="scope.row.properties_variant[item]"
                @input="savePropertiesVariantDebounce(item, $event, scope.row)"
                type="number"
              />
            </template>
          </el-table-column>

          <el-table-column prop="option_name" label="Option"
            :min-width="200"
            v-if="additionalColumns.includes('Option')"
            column-key="option_name"
            :filters="getColumnFilterValues('dummiesForTable', 'option_name')">
            <template slot-scope="scope">
              <DummyOptionDataCell
                :option="scope.row"
                :changeable="true"
                @image-click="$router.push(getDummyPageUrl(scope.row.dummy_key))"
                @input="handleFieldChangeDebounce('option_name', $event, scope.row)"
                name-key="option_name"
              />
            </template>
          </el-table-column>

          <el-table-column prop="option_key" label="Option key"
            column-key="option_key"
            v-if="additionalColumns.includes('Option key')"
            :filters="getColumnFilterValues('dummiesForTable', 'option_key')">
            <template slot-scope="scope">
              {{ scope.row.option_key }}
            </template>
          </el-table-column>
          <el-table-column :prop="item" :label="formatString(item)"
            :key="item"
            :filters="getColumnFilterValues('dummiesForTable', item)"
            :column-key="item"
            v-for="item in additionalColumnsProperties">
            <template slot-scope="scope">
              <v-simple-checkbox
                v-if="(managedPropertiesDummies || {})[item]?.value_type === 'boolean'"
                :value="scope.row.properties_dummy[item]"
                color="primary"
                dense
                class="mt-0 pt-0"
                :ripple="false"
                @input="savePropertiesDummyDebounce(item, $event,scope.row)"
              />
              <v-autocomplete
                v-else-if="(managedPropertiesDummies || {})[item]?.value_dropdown && managedPropertiesDummies[item].values"
                :error-messages="validateValueForList((managedPropertiesDummies || {})[item], scope.row.properties_dummy[item])"
                class="align-center"
                v-model="scope.row.properties_dummy[item]"
                :items="(managedPropertiesDummies || {})[item].values"
                :menu-props="{
                  left: true,
                  offsetY: true,
                  contentClass: 'select-dropdown-menu'
                }"
                @input="savePropertiesDummyDebounce(item, $event,scope.row)"
              />
              <v-text-field
                class="align-center"
                v-else-if="(managedPropertiesDummies || {})[item]?.value_type === 'text'"
                :error-messages="validateValueForList((managedPropertiesDummies || {})[item], scope.row.properties_dummy[item])"
                v-model="scope.row.properties_dummy[item]"
                type="text"

                @input="savePropertiesDummyDebounce(item, $event, scope.row)"
              />
              <v-text-field
                v-else
                class="align-center"

                :error-messages="validateValueForList((managedPropertiesDummies || {})[item], scope.row.properties_dummy[item])"
                v-model="scope.row.properties_dummy[item]"
                type="number"
                @input="savePropertiesDummyDebounce(item, $event, scope.row)"
              />
            </template>
          </el-table-column>

          <el-table-column prop="created_at"
            column-key="created_at" key="created_at" label="Created"
            v-if="additionalColumns.includes('Created')"

            :filters="getColumnFilterValues('dummiesForTable', 'created_at')"
          >
            <template slot-scope="scope">
              {{ scope.row.created_at | formatDate('yyyy-MM-dd') }}

            </template>
          </el-table-column>
          <el-table-column prop="changed_at"
            v-if="additionalColumns.includes('Changed')"

            column-key="changed_at" key="changed_at" label="Changed"

            :filters="getColumnFilterValues('dummiesForTable', 'changed_at')"
          >
            <template slot-scope="scope">
              {{ scope.row.changed_at | formatDate('yyyy-MM-dd') }}

            </template>
          </el-table-column>
          <el-table-column prop="price"
            column-key="priceValue" key="price" label="Price"
            v-if="additionalColumns.includes('Price')"

            :filters="getColumnFilterValues('dummiesForTable', 'priceValue')"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.prices && Object.keys(scope.row.prices).length"
              >
                <v-text-field v-for="(price, currency) in scope.row.prices" :key="price"
                  :prefix="currencySymbols[currency]"
                  class="align-center"
                  :value="scope.row.prices[currency]"
                  @input="handleFieldChangeDebounce('prices', parseFloat($event.toString().replace(',', '.') || 0), scope.row)"
                  @keyup.enter="handleFieldChangeDebounce('prices', parseFloat($event.currentTarget.value?.toString().replace(',', '.') || 0), scope.row)"
                ></v-text-field>
              </template>

              <v-text-field v-else
                class="align-center"
                :prefix="currencySymbols[defaultCurrency]"
                @input="handleFieldChangeDebounce('prices', parseFloat($event.toString().replace(',', '.') || 0), scope.row)"
                @keyup.enter="handleFieldChangeDebounce('prices', parseFloat($event.currentTarget.value?.toString().replace(',', '.') || 0), scope.row)"
              ></v-text-field>
            </template>
          </el-table-column>

          <el-table-column prop="supplier_key"
            v-if="additionalColumns.includes('Supplier')"

            column-key="supplier_key" key="supplier_key" label="Supplier"

            :filters="getColumnFilterValues('dummiesForTable', 'supplier_key')"
          >
            <template slot-scope="scope">
              <v-autocomplete
                :items="suppliers"
                class="align-center"
                item-text="supplier_name"
                item-value="supplier_key"
                v-model="scope.row.supplier_key"
                :error-messages="!scope.row.supplier_key ? [''] : null"
                @input="handleFieldChangeDebounce('supplier_key', $event, scope.row)"
              ></v-autocomplete>
            </template>
          </el-table-column>
          <el-table-column prop="sku_range_key"
            v-if="additionalColumns.includes('Sku Range')"

            column-key="sku_range_key" key="sku_range_key" label="Sku Range"

            :filters="getColumnFilterValues('dummiesForTable', 'sku_range_key')"
          >
            <template slot-scope="scope">
              <v-autocomplete
                :items="skuRanges"
                class="align-center"
                item-text="sku_range_name"
                item-value="sku_range_key"
                v-model="scope.row.sku_range_key"
                @input="handleFieldChangeDebounce('sku_range_key', $event, scope.row)"
              ></v-autocomplete>
            </template>
          </el-table-column>
          <el-table-column prop="status"
            v-if="additionalColumns.includes('Status')"

            column-key="status"
            label="Status"

            :filter-method="equalsFilter"
            :filters="getColumnFilterValues('dummiesForTable', 'status')"
          >
            <template slot-scope="scope">
              <span :style="{ color: dummyStatusColorMap[scope.row.status] }">
                {{ scope.row.status | capitalize }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="export"
            label="Export"
            column-key="export"
            v-if="additionalColumns.includes('Export')"

            :filters="getColumnFilterValues('dummiesForTable', 'export')"
          >
            <template slot-scope="scope">
              <v-switch
                v-model="scope.row.export"
                hide-details
                class="pt-0 align-center my-3"
                @click.stop
                @change="handleExportChange(scope.row, $event)"
              />
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>

    <!-- Copy option dialog -->
    <v-dialog
      :value="addDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="addDialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2"/>
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">{{ addDialogTitle }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 text--primary">
          <div class="px-12 py-3 d-flex justify-space-between">
            <v-text-field
              v-model="addOptionsSearchTerm"
              placeholder="Search"
              hide-details
              @keyup.enter="handleCopyOptionsSearchClick"
            >
              <v-icon slot="append" @click="handleCopyOptionsSearchClick">search</v-icon>
            </v-text-field>
          </div>
          <div class="px-12 mt-6 mb-2">
            <h6 class="text-body-1 font-weight-bold mb-4">Options</h6>
          </div>
          <div class="p-relative">
            <data-tables
              style="width: 100%"
              class="search-results"
              :data="addOptionsList"
              :page-size="5"
              :pagination-props="{
                pageSizes: [5, 10,15, 25, 50],
                class: 'el-pagination text-right mt-6 mb-4 mr-2'
              }"
              @row-click="handleCopyOptionsRowClick">
              <el-table-column prop="option_key" width="55">
                <template slot-scope="scope">
                  <v-simple-checkbox
                    color="primary"
                    class="mt-0 ml-3 pt-0"
                    :value="scope.row.selected"
                    @input="handleSelectCopyOptionRowChange($event, scope.row)"
                  />
                </template>
              </el-table-column>
              <el-table-column prop="option_name" label="Option">
                <template slot-scope="scope">
                  <OptionDataCell
                    :option="scope.row"
                    name-key="name"
                  />
                </template>
              </el-table-column>
            </data-tables>
          </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="addDialogVisible = false"
              >Cancel
              </v-btn>
              <v-btn
                :disabled="!selectedCopyOptionsRows.length"
                color="primary"
                depressed
                outlined
                @click="handleAddSubmit"
                class="mx-4"
              >Add
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <BatchUploadDummies
      ref="batchUploadModal"
      :isOpen="batchUploadModalOpen"
      @close="batchUploadModalOpen = false"
      @upload="handleBatchUpload"
      @setFilters="setBatchFilterOptions"
    />
    <v-dialog
      :title="selectedRows[0] ? selectedRows[0].name : null"
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">Delete dummy</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete selected dummies</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel
              </v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteClick"
                class="red--text mx-4"
              >Delete
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>
import * as dummiesApi from '@/api/dummies'
import { deleteDummy } from '@/api/dummies'
import { dummyStatusColorMap, formatString } from '@/variables.js'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'
import DummyOptionDataCell from '@/components/Dummies/OptionDataCell.vue'
import OptionDataCell from '@/components/OptionDataCell.vue'
import BatchUploadDummies from '@/components/Dummies/BatchUploadDummies'
import _ from 'lodash'
import { getObjectValues } from '@/api/dataProperties'
import { findOptionsByFiltersTSQL } from '@/api/options'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { getSkuRanges } from '@/api/skusAPI'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  mixins: [columnFilters],
  components: { OptionDataCell, DummyOptionDataCell, TableSearch, BatchUploadDummies },
  props: {
    dummies: {
      type: Array,
      default: () => [],
    },
    isSelected: {
      type: Boolean,
      default: () => false,
    },
    dummyProperties: {
      type: Array,
      default: () => [],
    },
    variantProperties: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      skuRanges: [],
      managedPropertiesDummies: {},
      managedPropertiesVariants: {},
      currentPage: 1,
      defaultCurrency: localStorage.getItem('defaultCurrency'),
      pageSize: localStorage.getItem('dummiesPageSize') || 10,
      filteredData: [],
      addDialogTitle: 'Copy existing option',
      dummyStatusColorMap,

      savePropertiesDummyDebounce: _.debounce(this.savePropertiesDummy, 300),
      savePropertiesVariantDebounce: _.debounce(this.savePropertiesVariant, 300),
      handleFieldChangeDebounce: _.debounce(this.handleFieldChange, 700),
      additionalColumns: JSON.parse(localStorage.getItem('dummiesColumns')) || [
        'Variation', 'Option', 'Created', 'Changed', 'Price', 'Status', 'Export'],
      additionalColumnsProperties: JSON.parse(localStorage.getItem('dummiesProperties')) || [],
      additionalColumnsVariants: JSON.parse(localStorage.getItem('additionalColumnsVariants')) || [],
      loading: false,
      deleteDialogVisible: false,
      dummiesKey: 0,
      tableFilters: [
        {
          value: '',
        },
      ],
      addDialogVisible: false,
      addDialogLoading: false,
      addOptionsList: [],
      addOptionsSearchTerm: '',
      batchUploadModalOpen: false,
      batchBudgetKey: 0,
      menuKey: 0,
      batchScenarioKey: 0,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    suppliers () {
      return (this.appConfig?.suppliers || [])
    },
    currencies () {
      return this.appConfig?.currencies || []
    },
    dummiesForTable () {
      return (this.dummies || [])?.map(item => {
        for (const curr in item.prices) {
          item.priceValue = item.prices[curr]
        }
        return ({ ...item, ...item.properties_variant, ...item.properties_dummy })
      }) || []
    },
    sortedDummyProperties () {
      const props = [...this.dummyProperties]
      return props.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    },
    sortedVariantProperties () {
      const props = [...this.variantProperties]
      return props.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    },
    sortedDummyColumns () {
      return [
        'Variation', 'Variation key', 'Option', 'Option key', 'Created', 'Changed', 'Price', 'Supplier', 'Sku Range', 'Status', 'Export']
    },

    currencySymbols () {
      return this.currencies.reduce((acc, cur) => ({
        ...acc,
        [cur.iso_code]: cur.symbol,
      }), {})
    },
    rowsCount () {
      const data = {}
      this.filteredData.forEach((item, index) => {
        if (!data[item.variant_key]) {
          data[item.variant_key] = { count: 1, startIndex: index }
        } else {
          data[item.variant_key].count++
        }
      })
      return data
    },
    selectedRows () {
      return this.dummiesForTable.filter(r => r.selected)
    },
    columnsToSearch () {
      return Object.keys(this.dummiesForTable[0])
    },
    selectedCopyOptionsRows () {
      return this.addOptionsList.filter(r => r.selected)
    },
  },
  created () {
    this.getDummyProperties()
    this.skuRanges = getSkuRanges()
  },
  watch: {
    $route: {
      handler () {
        const row = this.dummies.find(r => r.dummy_key === parseInt(this.$route.query.dummy_key || ''))
        if (!row) {
          return
        }
        this.$set(row, 'selected', true)
      },
      immediate: true
    },
    dummies: {
      handler () {
        if (this.alreadySet) {
          return
        }
        const row = this.dummies.find(r => r.dummy_key === parseInt(this.$route.query.dummy_key || ''))
        if (row) {
          this.$set(row, 'selected', true)
          this.alreadySet = true
        }
      },
      immediate: true
    }
  },
  methods: {
    onSearchChange (value) {
      const filters = ['option_key', 'variant_name', 'variation_key', 'option_name', 'option_key', 'created_at', 'changed_at', 'price', 'supplier_key', 'status', 'export'].filter((item) => {
        if (this.additionalColumns.includes(this.$options.filters.formatString(item))) {
          return true
        }
        if (item === 'variant_name' && this.additionalColumns.includes('Variation')) {
          return true
        }
        if (item === 'option_name' && this.additionalColumns.includes('Option')) {
          return true
        }
        if (item === 'created_at' && this.additionalColumns.includes('Created')) {
          return true
        }
        if (item === 'changed_at' && this.additionalColumns.includes('Changed')) {
          return true
        }
        return item === 'supplier_key' && this.additionalColumns.includes('Supplier')
      })
      this.handleSearchChanged('tableFilters', [...filters, ...this.additionalColumnsVariants, ...this.additionalColumnsProperties], value)
      this.dummiesKey++
    },
    isSpannedMethod (columnIndex) {
      if (columnIndex === 1 && this.additionalColumns.includes('Variation')) {
        return true
      }
      let additionalFieldsCount = 1
      if (this.additionalColumns.includes('Variation')) {
        additionalFieldsCount++
      }
      if (this.additionalColumns.includes('Variation key')) {
        additionalFieldsCount++
      }

      const startingPoint = this.additionalColumnsVariants.length + additionalFieldsCount

      return columnIndex < startingPoint
    },

    getSupplier (supplier_key) {
      return this.suppliers?.find(item => item.supplier_key === supplier_key)?.supplier_name
    },
    validateValueForList (prop, value) {
      if (!prop) {
        return []
      }
      if (prop.force && !value) {
        return ['']
      }
      if (!value) {
        return []
      }
      if (prop.value_type === 'decimal' &&
        (!value?.toString().match(/^\d+$/) && !value?.toString().match(/^\d+\.\d+$/))) {
        return ['']
      }
      if (prop.value_type === 'integer' && !value?.toString().match(/^\d+$/)) {
        return ['']
      }
      return []
    },
    validatePropValue (prop, value) {
      if (!prop) {
        return []
      }
      this.$set(prop, 'errors', [])
      if (prop.value_type === 'text') {
        if (prop.force && !value) {
          prop.errors.push('This is a required field')
          return false
        }
      }
      if (prop.value_type === 'decimal' &&
        (!value?.toString().match(/^\d+$/) && !value?.toString().match(/^\d+\.\d+$/))) {
        prop.errors.push('value must be a decimal')
        return false
      }
      if (prop.value_type === 'integer' && !value?.toString().match(/^\d+$/)) {
        prop.errors.push('value must be an integer')
        return false
      }
      return true
    },
    savePropertiesDummy (fieldName, value, row) {
      const value_type = (this.managedPropertiesDummies || {})[fieldName]?.value_type
      if (value_type !== 'text' && value_type !== 'boolean') {
        value = parseFloat(value)
      }
      const item = this.dummies.find(dummy => row.dummy_key === dummy.dummy_key)
      let newValue = value
      if (value === undefined || value === '') {
        newValue = null
      }
      this.$set(item, fieldName, newValue)
      this.$set(row, fieldName, newValue)

      if (!this.validatePropValue((this.managedPropertiesDummies || {})[fieldName], value)) {
        return
      }
      this.selectedRows.forEach(item => {
        this.$set(item.properties_dummy, fieldName, newValue)
      })
      const dummy_keys = [...this.selectedRows.map(item => item.dummy_key), row.dummy_key]
      const variant_keys = [...this.selectedRows.map(item => item.variant_key), row.variant_key]
      const payload = {
        dummies: this.filtersStore.selectedFiltersOptions['dummies'],
        dummy_keys,
        variant_keys,
        properties_dummy: { [fieldName]: newValue }
      }
      this.$emit('saved', payload)
    },
    savePropertiesVariant (fieldName, value, item) {
      const value_type = (this.managedPropertiesVariants || {})[fieldName]?.value_type
      if (value_type !== 'text' && value_type !== 'boolean') {
        value = parseFloat(value)
      }
      const row = this.dummies.find(dummy => item.dummy_key === dummy.dummy_key)
      this.$set(item, fieldName, value)
      this.$set(row, fieldName, value)
      if (!this.validatePropValue((this.managedPropertiesVariants || {})[fieldName], value, item)) {
        return
      }
      let newValue = value
      if (row.properties_variant[fieldName] === undefined || row.properties_variant[fieldName] === '') {
        newValue = null
      }
      row.properties_variant[fieldName] = newValue

      this.selectedRows.forEach(item => {
        this.$set(item.properties_variant, fieldName, newValue)
      })

      const dummy_keys = [...this.selectedRows.map(item => item.dummy_key), item.dummy_key]
      const variant_keys = [...this.selectedRows.map(item => item.variant_key), item.variant_key]
      const payload = {
        dummies: this.filtersStore.selectedFiltersOptions['dummies'],
        dummy_keys,
        variant_keys,
        properties_variant: { [fieldName]: newValue }
      }
      this.$emit('saved', payload)
    },
    handleFieldChange (fieldName, value, item) {
      if ((fieldName === 'supplier_key' && !value)) {
        return
      }

      const rowIndex = this.dummies.findIndex(dummy => item.dummy_key === dummy.dummy_key)
      const dummy_keys = [...this.selectedRows.map(item => item.dummy_key), item.dummy_key]
      const variant_keys = [...this.selectedRows.map(item => item.variant_key), item.variant_key]
      const payload = {
        dummies: this.filtersStore.selectedFiltersOptions['dummies'],
        dummy_keys,
        variant_keys,
      }
      if (fieldName === 'prices') {
        [item, ...(this.selectedRows || [])].forEach(item => {
          item.prices = { [this.defaultCurrency]: 0, ...item.prices }
          for (const key in item.prices) {
            this.$set(item.prices, key, value)
            payload.prices = { [key]: value }
          }
        })

        this.selectedRows.forEach(item => {
          const rowIndex = this.dummies.findIndex(dummy => item.dummy_key === dummy.dummy_key)
          this.$set(this.dummies[rowIndex], fieldName, item.prices)
        })
        this.$set(this.dummies[rowIndex], fieldName, item.prices)
        this.$emit('saved', payload)
        return
      }
      this.$set(this.dummies[rowIndex], fieldName, value)

      this.selectedRows.forEach(item => {
        const rowIndex = this.dummies.findIndex(dummy => item.dummy_key === dummy.dummy_key)
        this.$set(this.dummies[rowIndex], fieldName, value)
      })
      this.selectedRows.forEach(item => {
        this.$set(item, fieldName, value)
      })

      payload[fieldName] = value
      this.$emit('saved', payload)
    },
    objectSpanMethod ({ row, rowIndex, columnIndex }) {
      const pageIndex = this.pageSize * (this.currentPage - 1)
      const { count, startIndex } = this.rowsCount[row.variant_key]
      if (columnIndex > 0 && this.isSpannedMethod(columnIndex)) {
        if (startIndex === (rowIndex + pageIndex)) { // This means it is the first row of a new product
          return {
            rowspan: count, // Set rowspan equal to number of rows coming with this product
            colspan: 1
          }
        } else if (!rowIndex) {
          return {
            rowspan: (startIndex + count > (pageIndex + this.pageSize)) ? count : (startIndex + count) % pageIndex,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      return {
        rowspan: 1,
        colspan: 1,
      }
    },
    getDummyPageUrl (dummyKey) {
      return {
        name: 'buy-studio-item-page',
        query: { dummy_key: dummyKey }
      }
    },

    getColumnFilters (list, col) {
      // flatten to handle columns with array of values properly
      const values = _.flatten(list.map(x => x.properties[col]))
      return _.uniq(values).filter((item) => item !== null).map(v => {
        return ({
          text: typeof v === 'string' ? this.$options.filters.formatString(v) : v,
          value: v,
        })
      })
    },
    copyVariant () {
      dummiesApi.copyVariant({ variant_key: this.selectedRows[0].variant_key })
        .then(() => {
          this.menuKey++
          this.$emit('dummy-copy')
        })
    },
    copyDummyWithinVariant () {
      dummiesApi.copyDummyWithVariant({ dummy_key: this.selectedRows[0].dummy_key, copy_variant: false })
        .then(() => {
          this.menuKey++
          this.$emit('dummy-copy')
        })
    },
    copyDummyNewVariant () {
      dummiesApi.copyDummyWithVariant({ dummy_key: this.selectedRows[0].dummy_key, copy_variant: true })
        .then(() => {
          this.menuKey++
          this.$emit('dummy-copy')
        })
    },
    saveProperties (value) {
      localStorage.setItem('dummiesProperties', JSON.stringify(value))
    },
    saveColumns (value) {
      localStorage.setItem('dummiesColumns', JSON.stringify(value))
    },
    savePageSize (value) {
      localStorage.setItem('dummiesPageSize', JSON.stringify(value))
    },
    saveVariantProperties (value) {
      localStorage.setItem('additionalColumnsVariants', JSON.stringify(value))
    },
    formatString,
    setBatchFilterOptions (options) {
      this.batchBudgetKey = options.budget_key
      this.batchScenarioKey = options.scenario_key
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
      this.menuKey++
    },
    handleSelectRowChange (val, row) {
      const isItemPage = this.$route.name === 'buy-studio-item-page'
      const item = this.dummies.find(dummy => row.dummy_key === dummy.dummy_key)

      this.$set(row, 'selected', val)
      this.$set(item, 'selected', val)
      this.$emit('selection-changed', val || !isItemPage ? row : this.selectedRows[this.selectedRows.length - 1])
    },
    handleDuplicateClick () {
      this.loading = true

      dummiesApi
        .copyDummy(this.selectedRows[0].dummy_key)
        .then(response => {
          this.$emit('dummy-duplicated', response.new_dummy.dummy_key)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleAddClick () {
      this.loading = true

      dummiesApi
        .addDummy(parseInt(this.variant.variant_key))
        .then(response => {
          this.$emit('dummy-added', response.dummy.dummy_key)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleEditClick () {
      this.$emit('dummy-edit-clicked', this.selectedRows[0].dummy_key)
    },
    handleDeleteClick () {
      deleteDummy(this.selectedRows.map(item => item.dummy_key))
        .then(() => {
          this.$emit('dummy-deleted')
          this.deleteDialogVisible = false
        })
    },
    handleBatchUpload (rows) {
      this.loading = true

      dummiesApi
        .batchUploadDummies(rows, this.batchBudgetKey, this.batchScenarioKey)
        .then(() => {
          this.$emit('batch-upload-done')
          this.batchUploadModalOpen = false
          this.$refs.batchUploadModal.setError(null)
        })
        .catch(() => {
          this.$refs.batchUploadModal.setError('Upload Failed... Try Again')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleCopyOptionsRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectCopyOptionRowChange (val, row) {
      this.selectedCopyOptionsRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
    },
    handleCopyOptionsSearchClick () {
      this.addDialogLoading = true
      const payload = {
        search_text: this.addOptionsSearchTerm,
        discount_min: null,
        discount_max: null,
        stock_active: null,
        osp_min: null,
        osp_max: null,
        asp_min: null,
        asp_max: null,
        stock_min: null,
        stock_max: null,
        inbounds_min: null,
        inbounds_max: null,
        option_keys_include: [],
        option_keys_exclude: [],
        properties_include: {},
        properties_exclude: {},
        filters: [],
      }
      findOptionsByFiltersTSQL(payload)
        .then(results => {
          this.addOptionsList = results.options
        })
        .finally(() => {
          this.addDialogLoading = false
        })
    },
    handleAddSubmit () {
      this.addDialogLoading = true
      const payload = {
        option_key: this.selectedCopyOptionsRows[0].option_key,
        variant_key: parseInt(this.variant.variant_key)
      }
      if (this.addDialogTitle === 'Import existing option') {
        payload.variable_link = 'link'
        payload.variation_key = this.selectedCopyOptionsRows[0].variation_key
      }
      dummiesApi.copyOption(payload)
        .then(new_dummy => {
          this.$emit('dummy-duplicated', new_dummy.dummies[0].dummy_key)
        })
        .finally(() => {
          this.addDialogLoading = false
        })
    },
    getDummyProperties () {
      getObjectValues('dummies')
        .then(response => {
          // Capitalize first letter of each word
          this.managedPropertiesDummies = response.data.reduce((prev, curr) => {
            prev[curr.property_name] = curr
            return prev
          }, {})
        })
      getObjectValues('variants')
        .then(response => {
          // Capitalize first letter of each word
          this.managedPropertiesVariants = response.data.reduce((prev, curr) => {
            prev[curr.property_name] = curr
            return prev
          }, {})
        })
    },
    handleExportChange (row, event) {
      const item = { 'export': event, dummy_key: row.dummy_key, variant_key: row.variant_key }
      dummiesApi
        .dummyExportChange(item)
        .then(() => {
          // this.$emit('dummy-export-changed', row.dummy_key)
        })
    },
  },
}
</script>
<style lang="scss">
.dummies-table {
  .el-table {
    td {
      border-bottom: 1px solid #d3d3d3;
      max-height: 60px;
      overflow: hidden;
      .cell {
        //height: 100%;
        display: flex;
        max-height: 60px;
        align-items: center;

        .v-input {
          font-size: 13px;
          height: 90px;
          box-sizing: border-box;
          * {
            color: rgb(96, 98, 102);
          }
        }
      }
    }
  }
}
</style>
