<template>
  <v-row class="consolidate-kpi-tiles d-flex flex-wrap justify-space-around mx-n4 px-9">
    <v-col
      cols="5"
      md="2"
      sm="4"
      xs="5"
      v-for="(item, i) in itemsToShow"
      :key="i"
      class="text-center align-center rounded p-relative rounded elevation-2 kpi-tile mx-2 my-2"
    >
      <template v-if="item">
        <div class="mt-1 tile-title d-flex justify-center align-end">
          {{ item.title }}
        </div>
        <template v-if="'value' in item">
          <div class="mt-2 tile-value">{{ item.value }}</div>
        </template>
        <template v-else>
          <div class="mt-2 d-flex justify-center align-center tile-value">
            <!-- value1 -->
            <span :class="item.colorClass1">{{ item.value1 }}</span>

            <!-- separator -->
            <template v-if="item.separator === 'arrow'">
              <v-icon small class="mx-2">arrow_forward</v-icon>
            </template>
            <template v-if="item.separator === 'pipe'">
              <div class="d-inline-block mx-1 pipe-separator">|</div>
            </template>

            <!-- value2 -->
            <span :class="item.colorClass2">{{ item.value2 }}</span>
          </div>
        </template>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ConsolidateKPITiles',
  components: {},
  props: {
    kpiItems: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
    }
  },
  computed: {
    itemsToShow () {
      return this.kpiItems.length ? this.kpiItems : new Array(5)
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
  .consolidate-kpi-tiles {
    .kpi-tile {
      background-color: white;
      color: var(--text-sub-black);
      height: 85px;
      flex-basis: 130px;
      flex-grow: 1;
      display: inline-block;
      .tile-title {
        font-size: 12px;
        font-weight: 500;
      }
      .tile-value {
        font-size: 16px;
        font-weight: 500;
      }
      .pipe-separator {
        margin-top: -2px;
      }
    }
  }
  @media (min-width: 1600px) {
    .consolidate-kpi-tiles {
      .kpi-tile {
        flex-basis: 190px!important;
      }
    }
  }
  @media (min-width: 1900px) {
    .consolidate-kpi-tiles {
      .kpi-tile {
        flex-basis: 220px!important;
      }
    }
  }
</style>
