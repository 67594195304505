import axios from '@/services/axios'
import { getHeadersWithAccessToken } from '@/variables'
import baseURLs from '@/api/APIBaseURLs'

function getAPIBase () {
  return baseURLs.manageAPI
}

export const saveCapacityBlock = async (capacityBlock) => {
  try {
    const response = await axios.post(getAPIBase() + '/capacity_blocks', capacityBlock)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getCapacityBlocks = async (optionKey = undefined) => {
  const optionKeyQuery = optionKey ? '?option_key=' + optionKey : ''
  try {
    const response = await axios.get(getAPIBase() + '/capacity_blocks' + optionKeyQuery)
    return response.data
  } catch (e) {
    console.error(e)
    return []
  }
}

export const getCapacityGroups = async (optionKey = undefined) => {
  const optionKeyQuery = optionKey ? '?option_key=' + optionKey : ''
  try {
    const response = await axios.get(getAPIBase() + '/capacity_groups' + optionKeyQuery)
    return response.data.capacity_groups
  } catch (e) {
    console.log(e)
    return []
  }
}

export const saveCapacityGroup = async (capacityGroup) => {
  try {
    const response = await axios.post(getAPIBase() + '/capacity_groups', capacityGroup)
    return response.data.capacity_group
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const removeCapacityGroup = async (capacityGroup) => {
  try {
    const response = await axios.delete(getAPIBase() + '/capacity_groups', { data: capacityGroup, headers: getHeadersWithAccessToken() })
    return response.data.capacity_group
  } catch (e) {
    console.log(e)
    return null
  }
}
