<template>
  <v-dialog
    v-model="isOpen"
    persistent
    content-class="align-self-start mt-14"
    width="600px"
    max-width="90%">
    <v-card class="p-relative rounded-0">
      <v-card-title class="text-body-1 font-weight-medium">
        Delete {{ resource }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="text-body-1 pt-8">Do you want to delete {{ indicator }} {{ resource }}?</div>
      </v-card-text>
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2">
          <v-col>
            <v-btn
              color="primary"
              depressed
              text
              @click="$emit('close')"> Cancel </v-btn>
            <v-btn
              depressed
              outlined
              @click="$emit('delete')"
              class="red--text mx-4"> Delete </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String,
      default: '',
    },
    indicator: {
      type: String,
      default: 'this',
    }

  },
}
</script>

<style scoped>

</style>
