import * as Sentry from '@sentry/vue'
import axios, { revokeAxiosAccessToken, setAxiosAccessToken } from '@/services/axios.js'
import { currencyMap, getHeadersWithRefreshToken, headers } from '@/variables.js'
import { getConfig } from '@/api/config'
import { useGeneralStore } from '@/store/pinia/generalStore'
import baseURLs from '@/api/APIBaseURLs'
import { useCubesStore } from '@/store/pinia/cubesStore'

class Auth {
  /**
   * This is used to prevent multiple refresh token requests
   * @type {Promise<T>|null}
   * @private
   */
  _existingRefreshTokenRequest = null
  azureLogin (token) {
    return this.socialLogin(token, 'azure')
  }

  googleLogin (token) {
    return this.socialLogin(token, 'google')
  }

  socialLogin (token, provider) {
    return axios.post(`${baseURLs.manageAPI}/auth/${provider}`, { token, org_key: this.getOrgKey() }, { headers: headers })
      .then(this._loginCallback.bind(this))
  }

  _loginCallback (response) {
    this.setAccessToken(response.data.access_token, response.data.expiry)
    localStorage.setItem('refresh_token', response.data.refresh_token)
    this.setIdentityData(response.data.identity, response.data.organisations)
    setInterval(this.refreshTokens.bind(this), 30000)
    return response
  }

  isAccessTokenValid () {
    return !!(localStorage.getItem('access_token') && localStorage.getItem('expires_at') && Math.floor(Date.now() / 1000) < localStorage.getItem('expires_at'))
  }

  checkFrontendVersion (newVersion) {
    const currentVersion = localStorage.getItem('fe_version')
    if (currentVersion === newVersion || !currentVersion || !newVersion) {
      return
    }
    localStorage.setItem('fe_version', newVersion)
    this.logout().then(() => {
      window.location.reload()
    })
  }

  getOrganisations () {
    try {
      return JSON.parse(localStorage.getItem('organisations')) || []
    } catch (e) {
      console.error(e)
    }
    return []
  }

  setIdentityData (identity, organisations) {
    if (organisations) {
      localStorage.setItem('organisations', JSON.stringify(organisations))
    }
    this.checkFrontendVersion(identity.fe_version)
    localStorage.setItem('email', identity.email)
    localStorage.setItem('superuser', identity.superuser)
    localStorage.setItem('user_id', identity.id)
    localStorage.setItem('org', identity.organisation)
    localStorage.setItem('org_key', identity.org_key)
    localStorage.setItem('user_access_stock', identity.user_access.stock)
    localStorage.setItem('user_access_buy', identity.user_access.buy)
    localStorage.setItem('identity_vat', identity.vat)
    localStorage.setItem('fe_version', identity.fe_version)
    localStorage.setItem('logicApiVersion', identity.version)
    localStorage.setItem('defaultCurrency', identity.currency)
    localStorage.setItem('identity_vat', identity.vat)
    localStorage.setItem('defaultCurrency', identity.currency)
    localStorage.setItem('identity_vat', identity.vat)

    try {
      Sentry.setUser({
        email: identity.email,
        id: identity.id,
        org: identity.organisation,
        org_key: identity.org_key,
        superuser: identity.superuser,
      })
    } catch (e) {
      console.error('Sentry setting user failed', e)
    }
  }

  login (email, pass) {
    const credentials = {
      email: email,
      password: pass,
      org_key: this.getOrgKey()
    }
    return axios.post(baseURLs.manageAPI + '/login', credentials, { headers: headers })
      .then(this._loginCallback.bind(this))
      .catch(error => Promise.reject(error.response))
  }

  setAccessToken (token, expiry) {
    localStorage.setItem('access_token', token)
    localStorage.setItem('expires_at', expiry)
    setAxiosAccessToken(token)
  }

  refreshTokens () {
    // If there is already a refresh token request in progress, return that promise
    if (this._existingRefreshTokenRequest) {
      return this._existingRefreshTokenRequest
    }
    this._existingRefreshTokenRequest = axios.post(baseURLs.manageAPI + '/refresh', {},
      { headers: getHeadersWithRefreshToken() })
      .then(response => {
        this._existingRefreshTokenRequest = null
        if(response.status !== 200) {
          return Promise.reject(response)
        }
        this.setAccessToken(response.data.access_token, response.data.expiry)
        this.setIdentityData(response.data.identity)
        return response
      })
      .catch(error => {
        this._existingRefreshTokenRequest = null
        return error.response
      })
    return this._existingRefreshTokenRequest
  }

  async initAppConfig () {
    const appConfig = JSON.parse(localStorage.getItem('appConfig'))
    const userId = JSON.parse(localStorage.getItem('user_id'))

    if (!appConfig && userId) {
      await this.refreshAppConfig()
    } else {
      useGeneralStore().setAppConfig(appConfig)
    }
  }

  async checkAppFullConfigExpiration () {
    const now = new Date()
    let lastUpdate = localStorage.getItem('appFullConfigLastUpdate')

    lastUpdate = lastUpdate ? new Date(lastUpdate) : null

    // if last update was more than 1 hour ago
    if (!lastUpdate || (now - lastUpdate >= 1000 * 3600)) {
      return this.refreshAppConfig()
    } else {
      return Promise.resolve()
    }
  }

  async refreshAppConfig (ts = null, keys = []) {
    let config = {}
    // Separate try-catch blocks to avoid not loading the app if json in LS is corrupted
    try {
      config = JSON.parse(localStorage.getItem('appConfig') || '{}') || {}
    } catch (e) {
      console.error(e)
    }
    try {
      const newConfig = await getConfig(ts, keys)
      config = Object.assign(config, newConfig.data)
    } catch (e) {
      console.error(e)
    }

    useGeneralStore().setAppConfig(config)
    localStorage.setItem('appConfig', JSON.stringify(config))
    localStorage.setItem('appFullConfigLastUpdate', (new Date()).toString())
  }

  clearLocalStorage (logout = false) {
    const access_token = localStorage.getItem('access_token')
    const refresh_token = localStorage.getItem('refresh_token')
    const org_key = localStorage.getItem('org_key')
    const expires_at = localStorage.getItem('expires_at')
    const devMode = localStorage.getItem('devMode')
    const version = localStorage.getItem('fe_version')
    const lastVisitedModule = localStorage.getItem('lastVisitedModule')
    const currency = localStorage.getItem('defaultCurrency')
    // Clear everything in localStorage
    localStorage.clear()
    // Refresh everything in localStorage except tokens
    localStorage.setItem('devMode', devMode)
    localStorage.setItem('lastVisitedModule', lastVisitedModule)
    localStorage.setItem('fe_version', version)
    localStorage.setItem('defaultCurrency', currency)
    localStorage.setItem('org_key', org_key)
    if (!logout) {
      // if we are not logging out, we need to set the tokens back
      localStorage.setItem('access_token', access_token)
      localStorage.setItem('refresh_token', refresh_token)
      localStorage.setItem('expires_at', expires_at)
    }
  }

  logout () {
    this.clearLocalStorage(true)
    useCubesStore().$reset()
    const url = baseURLs.manageAPI + '/logout'

    return axios.get(url)
      .then(() => revokeAxiosAccessToken())
      .catch(console.error)
  }

  getUserCurrency () {
    const currency = localStorage.getItem('defaultCurrency')

    return {
      currency,
      symbol: currencyMap[currency],
    }
  }
  resetPassword (id, token, password) {
    return axios.put(baseURLs.manageAPI + '/change', { id, token, password }, { headers: headers })
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response) {
          return error.response
        }
      })
  }

  forgotPassword (email) {
    return axios.put(baseURLs.manageAPI + '/forgot', { email }, { headers })
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response) {
          return error.response
        }
      })
  }

  async setOrgKey (org_key) {
    localStorage.setItem('org_key', org_key)
    try {
      const response = await axios.post(baseURLs.manageAPI + '/switch', { org_key })
      this.clearLocalStorage(false)
      this._loginCallback(response)
      window.location.reload()
    } catch (e) {
      console.error(e)
    }
  }

  async ResetCurrentOrg () {
    const org_key = this.getOrgKey()
    const response = await axios.post(baseURLs.manageAPI + '/switch', { org_key })
    this.clearLocalStorage(false)
    this._loginCallback(response)
    await this.refreshAppConfig()
    setTimeout(() => {
      window.location = '/'
    }, 500)
  }

  getOrgKey () {
    return localStorage.getItem('org_key') || 0
  }
}

const authService = new Auth()
export default authService
