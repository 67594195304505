<template>
  <v-card style="width: calc(70vw / var(--zoom))">
    <v-row class="ma-0 justify-space-between">
      <v-col cols="3">
        <option-info
          ref="option_info"
          :query-params="{
            option_key: row.option_key,
            inbound_key: row.inbound_key
          }"
        ></option-info>
      </v-col>
      <v-col cols="9" :class="{'pt-6': chartIndex === 3}">
        <v-card class="carousel-card pt-2" flat min-height="350px" >
          <v-row class="justify-space-between px-4 pt-3 pb-6 align-center">
            <div></div>
            <h4>{{chartNames[chartIndex]}}</h4>
            <DataExport
              v-if="chartIndex !== 3"
              @item-click="(sep)=> downloadChartData(sep, $refs[chartIndex ? 'popularityChart' : 'predictionChart'], query.option_key)"
            />
            <div v-else></div>
          </v-row>
          <v-overlay absolute color="white" :value="!allChartsLoaded">
            <v-progress-circular color="primary" indeterminate size="64" width="2" />
          </v-overlay>
          <v-carousel
            height="380px"
            class="pb-10"
            delimiter-icon="circle"
            :perPage="1"
            light
            hide-delimiter-background
            :hide-delimiters="!allChartsLoaded"
            :show-arrows="false"
            @change="(value)=> {chartIndex = value; overviewTableKey++}"
          >
            <v-carousel-item :key="0" :eager="true">
              <ChartElement
                v-if="Object.keys(predictionParams).length && proposalData && loaded"
                :key="row.option_key"
                ref="predictionChart"
                title="prediction"
                forcedHeight="320px"
                :chart-data="proposalData"
                endpoint="proposal"
                :params="predictionParams"
                @loaded="chartLoaded['chart1'] = true"
              />
            </v-carousel-item>
            <v-carousel-item :key="1" :eager="true">
              <strategy-period-option-story-chart
                ref="storyChart"
                v-if="!!optionStory"
                :story="optionStory"
                height="320px"
              />
            </v-carousel-item>
            <v-carousel-item :key="2" :eager="true">
              <TopFlopChart
                v-if="!!stores.length"
                height="320px"
                ref="topflop"
                :top-stores="topStores"
              />
            </v-carousel-item>
            <v-carousel-item :key="3" :eager="true">
              <ProposalOverviewTable
                v-if="loaded"
                :inModal="true"
                :key="overviewTableKey"
              />
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import OptionInfo from '@/components/Options/OptionInfo'
import DataExport from '@/components/DataExport'
import { downloadChartData } from '@/variables'
import ChartElement from '@/chart/ChartElement'
import StrategyPeriodOptionStoryChart from '@/components/Strategy/StrategyPeriodOptionStoryChart'
import { getOptionStory } from '@/api/strategy'
import TopFlopChart from '@/components/Options/TopFlopChart'
import { serializeObjToQuery } from '@/utils'
import ProposalOverviewTable from '@/components/Proposal/ProposalOverviewTable'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

import { useArticleStore } from '@/store/pinia/stockArticlesStore'

export default {
  setup () {
    return {
      articlesStore: useArticleStore(),
      proposalStore: useStockProposalStore()}
  },
  name: 'InfoMenu',
  data () {
    return {
      chartNames: ['Prediction', 'History', 'Store performance', 'Distribution'],
      chartIndex: 0,
      chartLoaded: {
        chart1: false,
        chart2: true,
      },
      overviewTableKey: 0,
      loaded: false,
      optionStory: null,
    }
  },
  props: {
    row: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    downloadChartData,
    async loadOptionStory () {
      const optionKey = this.query.option_key
      try {
        this.optionStory = await getOptionStory({
          option_keys: [optionKey]
        })
      } catch (e) {
        console.error(e)
      }
    },
    loadProposal () {
      this.loaded = false
      // On creation we load the rules, set the defaults and get the proposal setting for this article and the proposal
      const loadRules = this.proposalStore.setRules()
      const loadDefaults = this.proposalStore.setDefaults()
      const promiseList = []

      if (!this.proposalStore.rulesLoaded) {
        promiseList.push(loadRules)
      }

      if (!this.proposalStore.defaultsLoaded) {
        promiseList.push(loadDefaults)
      }

      Promise
        .all(promiseList)
        .then(() => {
          setTimeout(()=> {
            this.proposalStore.loadProposalSettingsGetLatest( {
              route: serializeObjToQuery(this.query),
            })
              .then((settings) => {
                setTimeout(()=> {
                  this.proposalStore.loadProposal( {
                    route: this.query,
                    settings
                  })
                    .then(() => {
                      this.loaded = true
                      this.overviewTableKey++
                    })
                }, 100)
              })
          }, 100)
        })
    },
  },
  created () {
    this.articlesStore.loadArticleSettings( {...this.query, page: 'story'})
    this.loadOptionStory()
    this.loadProposal()
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    query () {
      return {
        option_key: this.row.option_key,
        inbound_key: this.row.inbound_key
      }
    },
    stores () {
      return (this.appConfig?.stores || [])
    },
    topStores () {
      return this.proposalStore.top_stores
    },
    proposalData () {
      return this.proposalStore.projection
    },
    predictionParams () {
      const proposalParams = this.proposalStore.proposalParams
      return { ...proposalParams, order_parameters: { ...proposalParams.order_parameters, leadtime: 0 } }
    },
    allChartsLoaded () {
      return this.chartLoaded.chart1 && this.chartLoaded.chart2
    },
  },
  watch: {
    stores: {
      handler () {
        if (!this.stores?.length) {
          return (this.appConfig?.stores || [])
        }
      },
      immediate: true
    }
  },
  components: { ProposalOverviewTable, TopFlopChart, StrategyPeriodOptionStoryChart, ChartElement, DataExport, OptionInfo }
}
</script>

<style scoped>

</style>
