const MaxInputLength = 5000
const MaxTextAreaLength = 10000

export default {
  mounted () {
    this.setMaxLengthOnInputs()
  },
  methods: {
    setMaxLengthOnInputs () {
      if (!this.$el?.querySelectorAll) {
        return
      }
      const inputs = this.$el.querySelectorAll('input')
      inputs.forEach(input => {
        if (input.getAttribute('maxlength')) {
          return
        }
        input.setAttribute('maxlength', String(MaxInputLength)) // Set your desired default maxLength
      })

      const textAreas = this.$el.querySelectorAll('textarea')
      textAreas.forEach(textarea => {
        if (textarea.getAttribute('maxlength')) {
          return
        }
        textarea.setAttribute('maxlength', String(MaxTextAreaLength)) // Set your desired default maxLength
      })
    }
  }
}
