import StrategyLayout from '@/views/modules/StrategyLayout.vue'
import BuySettingsConfigurationPage from '@/views/BuySettingsConfigurationPage.vue'
import StockSettingsConfigurationPage from '@/views/StockSettingsConfigurationPage.vue'
import StrategyAnomaliesPage from '@/views/StrategyAnomaliesPage.vue'
import SettingsConfiguration from '@/views/SettingsConfiguration.vue'
import StockSettingsDataPage from '@/views/StockSettingsDataPage.vue'
import StockSettingsStoresPage from '@/views/StockSettingsStoresPage.vue'
import StockSettingsUsersPage from '@/views/StockSettingsUsersPage.vue'
import SettingsMainPage from '@/views/SettingsMainPage.vue'
import DataSetsMainPage from '@/views/DataSetsMainPage.vue'
import StrategyWssiPage from '@/views/StrategyWssiPage.vue'
import StrategyCollectionPage from '@/views/StrategyCollectionPage.vue'
import StrategyChunksPage from '@/views/StrategyChunksPage.vue'
import StrategyIntakePage from '@/views/StrategyIntakePage.vue'
import StrategyBudgetCubesPage from '@/views/StrategyBudgetCubesPage.vue'
import StrategyHomePage from '@/views/StrategyHomePage.vue'

export const strategyRoutes = {
  path: '/strategy',
  component: StrategyLayout,
  children: [
    {
      path: 'home',
      name: 'strategy-home-page',
      component: StrategyHomePage,
    },
    {
      path: 'cubes',
      name: 'strategy-cubes-page',
      component: StrategyBudgetCubesPage,
    },
    {
      path: 'intake',
      name: 'strategy-intake-page',
      component: StrategyIntakePage,
    },
    {
      path: 'chunks',
      name: 'strategy-chunks-page',
      component: StrategyChunksPage,
    },
    {
      path: 'collection',
      name: 'strategy-collection-page',
      component: StrategyCollectionPage,
    },
    {
      path: 'wssi',
      name: 'strategy-wssi-page',
      component: StrategyWssiPage,
    },
    {
      path: 'analytics',
      name: 'strategy-analytics',
      component: DataSetsMainPage,
    },
    {
      path: 'settings',
      name: 'strategy-settings-main',
      component: SettingsMainPage
    },
    {
      path: 'settings/users',
      name: 'strategy-settings-users',
      component: StockSettingsUsersPage
    },
    {
      path: 'settings/stores',
      name: 'strategy-settings-stores',
      component: StockSettingsStoresPage
    },
    {
      path: 'settings/data',
      name: 'strategy-settings-data',
      component: StockSettingsDataPage
    },
    {
      path: 'settings/configuration',
      name: 'strategy-settings-configuration',
      component: SettingsConfiguration
    },
    {
      path: 'anomalies',
      name: 'strategy-anomalies-page',
      component: StrategyAnomaliesPage
    },
    {
      path: 'settings/configuration/stock',
      name: 'strategy-settings-configuration-stock',
      component: StockSettingsConfigurationPage
    },
    {
      path: 'settings/configuration/buy',
      name: 'strategy-settings-configuration-buy',
      component: BuySettingsConfigurationPage
    },
  ]
}
