<template>
  <div
    id="consolidate-options"
    class="p-relative"
  >
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row>
      <v-col>
        <v-row class="text-right pr-6 pb-4" justify="end">
          <TableSearch class="mx-2" @searchChanged="val => handleSearchChanged('tableFilters', optionsData.columns, val)" />
          <DataExport
            @item-click="downloadOptionsData"
          />
        </v-row>
        <data-tables
          style="width: 100%"
          class="options-table"
          :filters="tableFilters"
          :key="tableKey"
          :data="tableData"
          :page-size="5"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 mb-4 mr-2',
          }"
          :table-props="{
            'row-key': 'option_key',
            'expand-icon': 'add',
          }"
          @filtered-data="filteredData = $event"
          @filter-change="filters => handleFilterChange('tableFilters', filters)"
          @row-click="handleRowClick"
        >
          <el-table-column prop="option_key" width="55">
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-3 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row, optionsData.data.indexOf(scope.row))"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="Name"
            width="220"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              <v-menu
                :key="scope.row.option_key"
                right
                bottom
                :open-on-hover="!infoMenuOpened[scope.row.option_key]"
                nudge-bottom="10"
                :nudge-left="200"
                :disabled="!scope.row.option_key"
                v-model="infoMenuOpened[scope.row.option_key]"
                @input="onInfoMenuChange(scope.row.option_key, $event)"
                offset-x
                offset-y
                :open-delay="3000"
                :close-delay="1000"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <OptionDataCell
                      :option="scope.row"
                      :url="scope.row.route"
                      name-key="name"
                      open-in-new-tab
                    />
                  </div>
                </template>
                <info-menu
                  @close="infoMenuOpened[scope.row.option_key] = false"
                  v-if="infoMenuOpened[scope.row.option_key]"
                  :row="scope.row"></info-menu>
              </v-menu>
            </template>
          </el-table-column>
          <el-table-column
            prop="dc_stock"
            label="DC stock"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.dc_stock | formatThousands }}
            </template>
          </el-table-column>
          <el-table-column
            prop="fairstock"
            label="Fairstock"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.fairstock | formatThousands }}
            </template>
          </el-table-column>
          <el-table-column
            prop="stores_included"
            label="Inclusions"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.stores_included | formatThousands }}
            </template>
          </el-table-column>
          <el-table-column
            prop="stores_current"
            label="Current"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.stores_current | formatThousands }}
            </template>
          </el-table-column>
          <el-table-column
            prop="stores_reached"
            label="Reached"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.stores_reached | formatThousands }}
            </template>
          </el-table-column>
          <el-table-column
            prop="removed"
            label="Exclusions"
            width="130"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              <div>
                {{ (scope.row.stores_included - scope.row.stores_reached) | formatThousands }}
                <template v-if="scope.row.stores_removed.filter(x => !!x).length">
                  <v-menu
                    top
                    offset-x
                    min-width="200"
                    max-width="200"
                    :open-delay="200"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="my-1"
                        v-bind="attrs"
                        v-on="on"
                      >info</v-icon>
                    </template>
                    <v-card
                      class="py-2 px-4 p-relative"
                    >
                      <div v-for="(storeKey, i) in scope.row.stores_removed" :key="i">{{ storesMap[storeKey] }}</div>
                    </v-card>
                  </v-menu>
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="availability_current"
            label="Availability"
            align="center"
            class-name="text-capitalize"
            sortable
          >
            <template slot-scope="scope">
              <div class="d-flex justify-center align-center">
                <span :class="getAvailabilityColorClass(scope.row.availability_current * 100)">
                  {{ scope.row.availability_current * 100 | roundToStep(1) }}%
                </span>
                <v-icon small class="mx-2">arrow_forward</v-icon>
                <span :class="getAvailabilityColorClass(scope.row.availability_reached * 100)">
                  {{ scope.row.availability_reached * 100 | roundToStep(1) }}%
                </span>
              </div>
            </template>
          </el-table-column>
          <template v-slot:append>
            <data-tables
              :data="[tableTotal]"
              class="total-table"
              :pagination-props="{class: 'd-none'}"
            >
              <el-table-column label-class-name="d-none" class-name="total-row_column" width="55" />
              <el-table-column label-class-name="d-none" class-name="total-row_column" width="220" />
              <el-table-column
                class-name="total-row_column pl-1"
                label-class-name="d-none"
                v-for="(value, col) in tableTotal"
                align="center"
                :width="col === 'storesRemovedCount' ? '130' : null"
                :prop="col"
                :key="col"
              >
                <template>
                  <div class="text-center">
                    {{ value | formatThousands }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label-class-name="d-none" class-name="total-row_column" />
            </data-tables>
          </template>
        </data-tables>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OptionDataCell from '@/components/OptionDataCell.vue'
import { getAvailabilityColorClass, downloadData } from '@/utils'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '@/components/TableSearch.vue'
import DataExport from '@/components/DataExport'
import InfoMenu from '@/components/Home/InfoMenu'

export default {
  name: 'ConsolidateOptions',
  components: { OptionDataCell, TableSearch, DataExport, InfoMenu },
  props: {
    optionsData: {
      type: Object,
      default: () => ({})
    },
  },
  mixins: [ columnFilters ],
  data () {
    return {
      loading: false,
      filteredData: [],
      tableFilters: [{ value: '' }],
      tableKey: 0,
      infoMenuOpened: {},
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    storesMap () {
      return (this.appConfig?.stores || []).reduce((acc, cur) => ({
        ...acc,
        [cur.store_key]: cur.store_name
      }), {})
    },
    selectedRows () {
      return this.optionsData.data.filter(r => r.selected)
    },
    tableData () {
      return this.optionsData.data.map(row => ({
        ...row,
        storesRemovedCount: row.stores_included - row.stores_reached,
      }))
    },
    tableTotal () {
      return ['name', 'dc_stock', 'fairstock', 'stores_included', 'stores_current', 'stores_reached', 'storesRemovedCount'].reduce((acc, cur) => {
        this.filteredData.forEach(row => {
          if (typeof row[cur] === 'number') {
            acc[cur] = acc[cur] === undefined ? row[cur] : acc[cur] + row[cur]
          }
        })

        return acc
      }, {})
    },
  },
  watch: {
  },
  methods: {
    getAvailabilityColorClass,
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
      this.$emit('selected-row-changed', val ? row : null)
    },
    onInfoMenuChange (option_key, value) {
      if (value) {
        this.infoMenuOpened = { [option_key]: value }
      }
    },
    downloadOptionsData (sep) {
      const data = this.tableData.map(
        row => ['name', 'dc_stock', 'fairstock', 'stores_included', 'stores_current', 'stores_reached', 'removed', 'availability_current'].reduce(
          (acc, cur) => Object.assign(acc, { [cur]: row[cur] }),
          {}
        )
      )

      downloadData(sep, data, 'consolidate_options')
    },
  },
}
</script>

<style lang="scss">
#consolidate-options {
  .el-table__expand-icon {
    display: none;
  }
  .el-table__append-wrapper {
    position: sticky;
    bottom: 0;
  }
  .options-table .el-table__body-wrapper {
    max-height: 45vh;
    overflow-y: auto;
  }
  .options-table::-webkit-scrollbar,
  .options-table .el-table__body-wrapper::-webkit-scrollbar {
    width: 4px;
  }
  .options-table::-webkit-scrollbar-track,
  .options-table .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }
  .options-table::-webkit-scrollbar-thumb,
  .options-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
  .total-row_column {
    background-color: #f5f7fa;
  }
}
</style>
