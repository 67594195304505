<template >
  <div class="mb-10 form-div">
    <div class="mb-2 title-wrapper">
      <h4 class="mb-1">
        {{ filterData.label }} ({{ selectedFilterOptions.length }})
      </h4>
      <div>
        <!-- <v-icon @click="$emit('search-click', filterName)">search</v-icon> -->
        <v-icon @click="$emit('edit-click', filterName)" class="ml-4">edit</v-icon>
        <v-icon @click="$emit('revert-click', filterName)" class="ml-4">replay</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  props: {
    filterName: {
      type: String,
      required: true,
    },
    filterData: {
      type: Object,
      default: () => ({}),
    },
    optionsList: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
    }
  },
  computed: {
    selectedFilterOptions () {
      return this.filtersStore.selectedFiltersOptions[this.filterName]
    },
  }
}
</script>

<style scoped lang="scss">
.form-div {
    width: 100%;
    .title-wrapper {
        display: flex;
        justify-content: space-between;
    }
}
</style>
