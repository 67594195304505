<template>
  <div class="parameter">
    <div>{{ parameterName | formatString }}</div>

    <div v-if="parameterInformation(parameterName).type === 'slider'" class="mr-2 d-flex align-center slider">
      <div class="slider-value-from mr-2">{{ parameterInformation(parameterName).min }}</div>
      <v-slider
        v-model="setting.parameters[parameterName]"
        hide-details
        thumb-label
        :min="parameterInformation(parameterName).min"
        :max="parameterInformation(parameterName).max"
        @change="inputChanged"
      />
      <div class="slider-value-to ml-2">{{ parameterInformation(parameterName).max }}</div>
    </div>

    <div v-else-if="parameterInformation(parameterName).type === 'bool'">
      <v-switch
        v-model="setting.parameters[parameterName]"
        hide-details
        class="mt-0 pt-0"
        @change="inputChanged"
      >
      </v-switch>
    </div>
  </div>
</template>

<script>
import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

export default {
  setup () {
    return {
      proposalStore: useStockProposalStore()
    }
  },
  props: ['setting', 'parameterName', 'index'],
  methods: {
    inputChanged () {
      this.$emit('changed')
    },
    parameterInformation (p) {
      const params = this.proposalStore.getParams
      const defaultParam = structuredClone(params.find(x => x.name === p) || {})
      if (typeof defaultParam['default'] === 'boolean') {
        defaultParam['type'] = 'bool'
      } else if (Object.keys(defaultParam).includes('slider')) {
        defaultParam['type'] = 'slider'
        defaultParam['min'] = defaultParam['slider'][0]
        defaultParam['max'] = defaultParam['slider'][1]
      }
      return defaultParam
    }
  }
}
</script>

<style lang="scss">
    .parameter {
        width: 400px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--border-color);
        padding: 16px;
        .slider {
            width: 200px;
            .slider-value-from,
            .slider-value-to {
                color: #666;
                font-size: 14px;
            }
        }
    }
</style>
