<template>
  <div class="assortment-option-sets p-relative">
    <v-row class="d-flex align-center mt-8 ml-6">
      <h6 class="text-subtitle-1 font-weight-medium">Markdown moments</h6>
    </v-row>
    <v-row>
      <v-col class="text-right pr-6">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('momentsTableFilters', columnsToSearch, val)"
        />
        <v-icon class="mx-2 action-btn-primary" :disabled="selectedRows.length !== 1 || !selectedRows[0]"
          @click="handleEditClick">edit</v-icon>
        <v-icon class="mx-2 action-btn-primary" @click="handleAddClick">add</v-icon>
        <v-icon class="mx-2 action-btn-danger" :disabled="selectedRows.length === 0"
          @click="handleDeleteClick">delete</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <data-tables
          style="width: 100%"
          :data="moments"
          :page-size="5"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 mb-4 mr-2',
          }"
          :table-props="{ 'row-key': 'markdown_moment_key' }"
          :filters="momentsTableFilters"
          filter-multiple
          @filter-change="filters => handleFilterChange('momentsTableFilters', filters)"
          @row-click="handleRowClick">
          <el-table-column prop="markdown_moment_key" width="55">
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-4 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column prop="markdown_moment_name" label="Name" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.markdown_moment_name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="weeks"
            column-key="weeks"
            label="Weeks"
            sortable="custom"
            :filters="getColumnFilterValues('moments', 'weeks')"
          >
            <template slot-scope="scope">
              <ChipsDropdown :items="scope.row.weeks" />
            </template>
          </el-table-column>
          <el-table-column
            prop="discounts"
            column-key="discounts"
            label="Discounts"
            sortable="custom"
            :filters="getColumnFilterValues('moments', 'discounts')"
          >
            <template slot-scope="scope">
              <ChipsDropdown :items="scope.row.discounts" />
            </template>
          </el-table-column>
          <el-table-column
            prop="options_set_keys"
            column-key="options_set_keys"
            label="Option Sets"
            sortable="custom"
          >
            <template slot-scope="scope">
              <ChipsDropdown
                v-if="scope.row.options_set_keys && scope.row.options_set_keys.length"
                :items="getOptionSetsNames(scope.row.options_set_keys)" />
            </template>
          </el-table-column>
          <el-table-column
            prop="stores_set_keys"
            column-key="stores_set_keys"
            label="Store Sets"
            sortable="custom"
          >
            <template slot-scope="scope">
              <ChipsDropdown
                v-if="scope.row.stores_set_keys && scope.row.stores_set_keys.length"
                :items="getStoreSetsNames(scope.row.stores_set_keys)" />
            </template>
          </el-table-column>

          <el-table-column prop="price_logic"
            column-key="price_logic"
            label="Price logic"
            sortable="custom"
            :filters="getColumnFilterValues('moments', 'price_logic')"
          >
            <template slot-scope="scope">
              {{ scope.row.price_logic }}
            </template>
          </el-table-column>

          <el-table-column
            prop="price_points"
            column-key="price_points"
            label="Points"
            sortable="custom"
            :filters="getColumnFilterValues('moments', 'price_points')"
          >
            <template slot-scope="scope">
              <ChipsDropdown :items="scope.row.price_points || []" />
            </template>
          </el-table-column>
          <el-table-column
            prop="price_decimal"
            column-key="price_decimal"
            label="Decimals"
            sortable="custom"
            :filters="getColumnFilterValues('moments', 'price_decimal')"
          >
            <template slot-scope="scope">
              {{ scope.row.price_decimal }}
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>
    <MarkdownAddEditModal
      :is-open="isAddEditModalOpen"
      :current-moment="currentMoment"
      :is-edit="isEdit"
      :store-sets="storeSets"
      :option-sets="optionSets"
      @close="handleAddEditModalClose"
      @save="handleAddEditModalSave"
    />
    <DeleteDialog
      :visible="isDeleteModalOpen"
      content-title="Delete markdown moments"
      content-text="Are you sure you want to delete selected markdown moments?"
      @cancel="isDeleteModalOpen = false"
      @confirm="onDelete"
    />
  </div>
</template>

<script>
import { deleteMarkdownMoments, getMarkdownMoments, saveMarkdownMoment } from '@/api/markdownMoments'
import TableSearch from '@/components/TableSearch.vue'
import columnFilters from '@/mixins/columnFilters'
import ChipsDropdown from '@/components/ChipsDropdown'
import MarkdownAddEditModal from '@/components/Assortment/MarkdownAddEditModal'
import DeleteDialog from '@/components/DeleteDialog'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'
import { mapState } from 'pinia'

export default {
  mixins: [columnFilters],
  name: 'MarkdownMoments',
  components: {
    DeleteDialog,
    MarkdownAddEditModal,
    ChipsDropdown,
    TableSearch,
  },
  data () {
    return {
      moments: [],
      columnsToSearch: ['markdown_moment_name'],
      momentsTableFilters: [],
      isAddEditModalOpen: false,
      isDeleteModalOpen: false,
      currentMoment: {},
      isEdit: false,
    }
  },
  created () {
    this.loadMoments()
    this.loadSets()
  },
  computed: {
    ...mapState(useStoreAndOptionSetsStore, ['storeSets', 'optionSets']),
    selectedRows () {
      return this.moments.filter(row => row.selected)
    },
    // Creates a key-name map for store sets
    storeSetsMap () {
      return this.storeSets.reduce((acc, cur) => ({
        ...acc,
        [cur.stores_set_key]: cur.set_name,
      }), {})
    },
    // Creates a key-name map for option sets
    optionSetsMap () {
      return this.optionSets.reduce((acc, cur) => ({
        ...acc,
        [cur.options_set_key]: cur.set_name,
      }), {})
    },
  },
  methods: {
    getStoreSetsNames (keys) {
      if (!keys) {
        return []
      }
      return keys.map(key => this.storeSetsMap[key])
    },
    getOptionSetsNames (keys) {
      if (!keys) {
        return []
      }
      return keys.map(key => this.optionSetsMap[key])
    },
    async loadSets () {
      const setsStore = useStoreAndOptionSetsStore()
      await setsStore.loadOptionSets()
      await setsStore.loadStoreSets()
    },
    async loadMoments () {
      const moments = await getMarkdownMoments()
      if (this.selectedRows.length) {
        moments.forEach(moment => {
          moment.selected = this.selectedRows.some(row => row.markdown_moment_key === moment.markdown_moment_key)
        })
      }
      this.moments = moments.map(row => {
        row.selected = !!row.selected
        row.price_points = row.price_points ? row.price_points : []
        return row
      })
    },
    handleEditClick () {
      this.isAddEditModalOpen = true
      this.isEdit = true
      this.currentMoment = this.selectedRows[0]
    },
    handleAddClick () {
      this.isAddEditModalOpen = true
      this.isEdit = false
      this.currentMoment = {
        price_logic: 'decimal',
        price_decimal: 99,
      }
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleDeleteClick () {
      this.isDeleteModalOpen = true
    },
    async onDelete () {
      const keys = this.selectedRows.map(row => row.markdown_moment_key)
      await deleteMarkdownMoments(keys)
      await this.loadMoments()
      this.isDeleteModalOpen = false
    },
    handleSelectRowChange (val, row) {
      this.$set(row, 'selected', val)
      this.moments = [...this.moments]
    },
    handleAddEditModalClose () {
      this.isAddEditModalOpen = false
    },
    handleAddEditModalSave () {
      saveMarkdownMoment(this.currentMoment)
        .then(() => this.loadMoments())
      this.isAddEditModalOpen = false
    },

  },
}
</script>

<style scoped>

</style>
