<template>
  <v-card>
    <v-card-title class="headline">
      Debug info
      <v-spacer></v-spacer>
      <v-icon @click="copyToClipboard" class="c-pointer">content_copy</v-icon>
    </v-card-title>
    <v-card-text>
      <v-row class="my-3 pl-3">
        <ul>
          <li>FE Version: <b>{{ debugInfo.feVersion }}</b></li>
          <li>Build timestamp: <b>{{ debugInfo.buildTimestamp }}</b></li>
          <li>Build Commit hash: <b>{{ debugInfo.commitHash }}</b></li>
          <li>User ID: <b>{{ debugInfo.userID }}</b></li>
        </ul>
      </v-row>
      <v-row>
        <h3 class="pl-3">Troubleshooting:</h3>
      </v-row>
      <v-row class="pl-4 py-3">
        <ol>
          <li>Hold: <b>Ctrl + Shift + R</b> (On Mac: <b>Command + Shift + R</b>)</li>
          <li class="reset-me">
            <button class="click-me" @click="$router.push({path: '/reset-me'})">Click here</button>
            to reset cache or go to <a href="https://app.thunderstock.nl/reset-me">https://app.thunderstock.nl/reset-me</a></li>
          <li>Logout and Login</li>
          <li>If the problem continues - <a @click="copyToClipboard" class="c-pointer text-decoration-underline"> copy the debug info </a> and file a report</li>
        </ol>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="$emit('close')"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'DebugOverlay',
  emits: ['close'],
  props: {
    devMode: Boolean,
    superuser: Boolean
  },
  computed: {
    debugInfo () {
      return {
        commitHash: __COMMIT_HASH__,
        buildTimestamp: __BUILD_DATE__,
        feVersion: localStorage.getItem('fe_version'),
        userID: localStorage.getItem('user_id'),
        devMode: this.devMode,
        superuser: this.superuser,
        localstorage: this.localstorageObject
      }
    },
    localstorageObject () {
      const obj = { ...localStorage }
      // Remove token from localstorage object
      delete obj.access_token
      delete obj.refresh_token
      delete obj.appConfig
      delete obj.iconify0
      delete obj.siqlsdb
      return obj
    },
  },
  methods: {
    copyToClipboard () {
      const text = JSON.stringify(this.debugInfo, null, 2)
      navigator.clipboard.writeText(text)
    },
  }
}
</script>

<style scoped lang="scss">

.click-me {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
</style>
