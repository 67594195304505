<template>
  <div class="p-relative" style="height: 90%">
    <div
      v-show="!!topStores"
      class="chart-container px-0"
      style="position: relative; height: 430px; width: 100%"
      :style="height && `height: ${height}`"
    >
      <canvas ref="chartEl" class=""></canvas>
    </div>

    <v-overlay absolute color="white" :value="!topStores">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
  </div>
</template>

<script>
import { Chart } from 'chart.js/auto'
import { chartColors } from '@/variables.js'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  props: ['height', 'topStores'],
  data () {
    this.chart = null
    return {
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    chartData () {
      return this.topStores
    },
    stores () {
      return (this.appConfig?.stores || [])
    },
    storesMap () {
      const storesMap = new Map()
      this.stores.forEach(store => {
        storesMap.set(store.store_key, store.store_name)
      })
      return storesMap
    }
  },
  watch: {
    topStores: {
      handler: async function (v) {
        if (v && v.store_key) {
          this.plotGraph()
        }
      },
      immediate: true
    }
  },
  methods: {
    getStoreName (storeKey) {
      return this.storesMap.get(storeKey)
    },
    plotGraph () {
      if (this.chart) {
        this.chart.destroy()
      }
      if (!this.topStores || !this.topStores.sales_l5w) {
        return
      }
      const sales = this.topStores.sales_l5w
      const stock = this.topStores.economical_stock
      const technicalStock = this.topStores.technical_stock
      const stores = this.topStores.store_key?.map(storeKey => this.getStoreName(storeKey)) || []

      const chartData = {}
      for (let i = 0; i < sales.length; i++) {
        if (Object.prototype.hasOwnProperty.call(chartData, this.topStores['category'][i])) {
          chartData[this.topStores['category'][i]].push({
            'x': sales[i],
            'y': stock[i],
            'r': 5,
            'technicalStock': technicalStock[i],
            'label': '',
            'store': stores[i]
          })
        } else {
          chartData[this.topStores['category'][i]] = []
          chartData[this.topStores['category'][i]].push({
            'x': sales[i],
            'y': stock[i],
            'r': 5,
            'technicalStock': technicalStock[i],
            'label': '',
            'store': stores[i]
          })
        }
      }

      const chartDatasets = []
      let i = 0
      for (const c in chartData) {
        const obj = {}
        obj['label'] = c
        obj['backgroundColor'] = chartColors[i]
        obj['data'] = chartData[c]
        chartDatasets.push(obj)
        i++
      }

      this.$emit('loaded')
      this.chart = new Chart(this.$refs.chartEl, {
        type: 'bubble',
        data: {
          labels: '',
          datasets: chartDatasets
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          clip: false,
          plugins: {
            legend: {
              position: 'bottom',
              align: 'center',
              display: true,
              labels: {
                usePointStyle: true,
              }
            },
            tooltip: {
              footerFont: {
                size: 11,
                weight: 'normal',
              },
              callbacks: {
                label: tooltipItem => {
                  return chartDatasets[tooltipItem['datasetIndex']]['data'][tooltipItem['dataIndex']]['store']
                },
                footer: (tooltipItems) => {
                  const dataItem = chartDatasets[tooltipItems[0]['datasetIndex']]['data'][tooltipItems[0]['dataIndex']]

                  return [
                    `Sales = ${dataItem.x}`,
                    `Economical stock = ${dataItem.y}`,
                    `Technical stock = ${dataItem.technicalStock}`
                  ]
                }
              }
            },
          },
          hover: {
            mode: 'null'
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Sales L5W',
                font: {
                  size: 14,
                },
              },
              min: 0,
            },
            y: {
              id: 'Y',
              title: {
                display: true,
                text: 'Economical Stock',
                font: {
                  size: 14,
                },
              },
              min: 0,
            },
          },
        }
      })
    }
  }
}
</script>
<style>
.yMax {
    width: 250px;
    float: right;
    margin-right: 25px;
}
</style>
