<template>
  <div class="jobs-expanded-container">

    <v-overlay absolute color="white" :value="!dataTable.data">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <data-tables
      :data="dataTable.data"
      :page-size="5"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 pb-4 mr-2',
      }"
    >
      <el-table-column
        prop="run_time"
        sortable
        label="Run Time"
      >
        <template slot-scope="scope">
          {{scope.row.run_time}}
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        width="60"
        label="Status"
      >
        <template slot-scope="scope">
          <v-menu
            top
            offset-x
            min-width="500"
            max-width="500"
            :value="infoOpened === scope.$index"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="infoOpened = scope.$index"
              >info</v-icon>
            </template>

            <v-card class="py-2 px-4 p-relative align-center d-flex">
              {{scope.row.status}}
            </v-card>
          </v-menu>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
      >
        <template slot-scope="scope">
          <span style="white-space: nowrap">
            {{scope.row.status}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        sortable
        align="right"
        prop="succes"
        column-key="succes"
        label="Success"
        :filters="getColumnFilterValues('successFilters', 'succes')"
      >
        <template slot-scope="scope">
          <div class="d-flex justify-end pr-8">
            <v-checkbox
              disabled
              :input-value="!!scope.row.succes"></v-checkbox>
          </div>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import axios from '../../services/axios'

import columnFilters from '@/mixins/columnFilters'
import baseURLs from '@/api/APIBaseURLs'

export default {
  mixins: [columnFilters],
  name: 'JobExpandedTable',
  props: ['row'],
  data () {
    return {
      dataTable: [],
      infoOpened: null,
    }
  },
  computed: {
    successFilters () {
      return this.dataTable.data.map(item => {
        return { succes: item.succes }
      })
    }
  },
  mounted () {
    const url = baseURLs.manageAPI + '/data/settings/jobs/table_job'
    const params = {
      job_type: this.row.type,
      job_name: this.row.name,
    }
    axios.post(url, params).then(({ data }) => {
      this.dataTable = data
    })
  }
}
</script>

<style scoped>
  .jobs-expanded-container {
    background: #f9f9f9;
  }
</style>
