<template>
  <div class="pt-4">
    <div class="row justify-space-between pt-2">
      <v-col>
        <h3 class="pb-2">From: {{fromStoreName}} </h3>
        <h3>To: {{toStoreName}}</h3>
      </v-col>
    </div>
    <div class="row">
      <div class="col-12">
        <el-card>
          <v-overlay absolute color="white" :value="!loaded">
            <v-progress-circular color="primary" indeterminate size="64" width="2" />
          </v-overlay>
          <el-table
            v-if="loaded"
            :data="tableData"
            empty-text="Loading transfers"
            :span-method="spanMethod"
          >
            <el-table-column prop='Option' label="Option" min-width="180" >
              <template slot-scope="scope">
                <div class="d-flex">
                  <img
                    alt="Option"
                    class="image-modal-responsive pr-4 image"
                    :src="scope.row['image_url'] || '@/assets/img/ts_placeholder.png'"
                    @error="loadPlaceholder"
                    height=""
                    width='40%'
                    style="max-width: 120px"
                    @click="openImage(scope.row)">
                  <div
                    class="d-flex align-center">
                    <div class="d-flex flex-column align-start justify-start">
                      <div >{{scope.row['option_name'].split(' / ')[0]}}</div>
                      <div>{{scope.row['option_name'].split(' / ')[1]}}</div>
                      <div
                        class="font-weight-bold d-flex flex-column">
                        {{scope.row['price']}}

                        <v-menu
                          top
                          offset-x
                          min-width="200"
                          max-width="200"
                          content-class="option-info-menu"
                          :close-on-content-click="false"
                          v-model="optionInfoVisible[scope.row.option_key]"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-row class="ma-0">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="handleOptionInfoClicked(scope.row)"
                              >info</v-icon>
                            </v-row>
                          </template>

                          <v-card class="py-2 px-4 p-relative">
                            <v-overlay absolute color="white"
                              :value="loadingOptionInfo"
                              :key="scope.row.option_key">

                              <v-progress-circular color="primary" indeterminate size="64" width="2" />
                            </v-overlay>
                            <div v-for="(value, key) in optionInfo[scope.row.option_key]" :key="key" class="text-body-2 my-1">
                              <b>{{ key }}:</b> {{ value }}
                            </div>
                          </v-card>
                        </v-menu>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-for="col in tableHeaders"
              :key="col.key"
              :prop="col.key"
              :label="col.title | formatString"
              :formatter="formatter">
            </el-table-column>
            <el-table-column prop='done' label="Done" align="center">
              <template slot-scope="scope">
                <i v-if="scope.row.done" class="el-icon-circle-check done-icon"></i>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column prop='picked' label="Picked">
              <template slot-scope="scope">
                <span v-if="scope.row.picked>0">{{scope.row.picked}}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>
    <v-dialog v-model="modalImage.opened" max-width="500">
      <v-card class="d-flex flex-column px-8 py-3">
        <v-row class="d-flex justify-space-between font-weight-bold py-4 align-center">
          <h3>{{modalImage.title}}</h3>
          <v-btn icon>
            <v-icon @click="modalImage.opened = false">close</v-icon>
          </v-btn>
        </v-row>
        <v-row class="justify-center">
          <img alt="" class="image-modal-responsive" :src="modalImage.src">
        </v-row>
        <v-row class="py-6 justify-end">
          <v-btn @click="modalImage.opened = false">okay</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import axios from '@/services/axios'
import { getHeadersWithAccessToken } from '@/variables'
import { getAccepted } from '@/api/transfers.js'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import baseURLs from '@/api/APIBaseURLs'
import ts_placeholder from '@/assets/img/ts_placeholder.png'

export default {
  name: 'StorePageTransfersPrint',
  data () {
    return {
      tableData: [],
      modalImage: {
        opened: false,
      },
      optionInfoVisible: {},
      loadingOptionInfo: false,
      optionInfo: {},
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    stores () {
      return this.appConfig?.stores || []
    },
    fromStoreKey () {
      return parseInt(this.$route.params.store_key || 0)
    },
    fromStoreName () {
      const fromStore = this.stores.find(store => store.store_key === this.fromStoreKey)
      return fromStore ? fromStore.store_name : ''
    },
    toStoreKey () {
      return parseInt(this.$route.query.to_store_key || 0)
    },
    toStoreName () {
      const toStore = this.stores.find(store => store.store_key === this.toStoreKey)
      return toStore ? toStore.store_name : ''
    },
    loaded () {
      return this.tableData.length !== 0
    },
    tableHeaders () {
      return [
        {
          title: 'SKU',
          key: 'sku_name',
        },
        {
          title: 'Stock',
          key: 'stock',
        },
        {
          title: 'Keep',
          key: 'keep',
        },
        {
          title: 'Transfer',
          key: 'transfer',
        }
      ]
    }
  },
  methods: {
    spanMethod ({ row, columnIndex }) {
      if (columnIndex === 0) {
        if (row['count'] !== undefined) { // This means it is the first row of a new product
          return {
            rowspan: row['count'], // Set rowspan equal to number of rows coming with this product
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    openImage (row) {
      this.modalImage.src = row.image
      this.modalImage.opened = true
      this.modalImage.title = row.option
    },
    handleOptionInfoClicked (row) {
      const url = baseURLs.storepageAPI + '/option_info?option_key=' + row.option_key

      this.loadingOptionInfo = true
      this.optionInfoVisible[row.option_key] = true
      return axios.get(url, {
        headers: getHeadersWithAccessToken()
      })
        .then(response => {
          this.optionInfo[row.option_key] = response.data.data[0].properties
          this.loadingOptionInfo = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    formatter (row, column) {
      return row[column.property]
    },
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    async getTableData () {
      try {
        const { child_table: data } = await getAccepted(this.fromStoreKey, this.toStoreKey, this.$route.query.type)
        this.tableData = this.addCountColumn(data)
      } catch (e) {
        console.error(e)
      }
    },
    addCountColumn (data) {
      if (data.length > 0) {
        // For every first row in the table with a new product, set count equal to amount of rows coming with that product
        let cnt = 0
        for (let i = 0; i < data.length - 1; i++) {
          if (data[i]['option_name'] !== data[i + 1]['option_name']) {
            cnt += 1
            data[(i - (cnt - 1))]['count'] = cnt
            cnt = 0
          } else {
            cnt += 1
          }
        }
        data[data.length - (cnt + 1)]['count'] = cnt + 1
      }
      return data
    }
  },
  mounted () {
    // Get the actual data.
    this.getTableData()
  }
}
</script>

<style lang="scss">
    .done-icon{
        font-size: 2rem;
    }
</style>
