import {usePropertiesStore} from '@/store/pinia/propertiesStore'
import {useRulesStore} from '@/store/pinia/rulesStore'
import {useStockManageStore} from '@/store/pinia/stockManageStore'

export const loadManageData = async () => {
  usePropertiesStore().loadStoresProperties()
  usePropertiesStore().loadSKUOptionsProperties().catch(console.error)
  useRulesStore().setRules().catch(console.error)
  const manageStore = useStockManageStore()
  manageStore.getEntitiesSets()
  // stores sets
  manageStore.getStoreSetsFilterOptionValues('country').catch(console.error)
  manageStore.getStoreSetsFilterOptionValues('dc_key').catch(console.error)
  manageStore.getStoreSetsFilterOptionValues('proposal_key').catch(console.error)
  // factors
  manageStore.loadDoubleFactors().catch(console.error)
  // timelines
  manageStore.loadRuleblocks().catch(console.error)
  manageStore.loadTimelines().catch(console.error)
  manageStore.getTimelineMappings().catch(console.error)
}
