import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify, { options: { customProperties: true } })

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#007AFF',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#FF5721'
      }
    }
  }
})

export default vuetify
