import AuthLayout from '@/components/Users/AuthLayout.vue'
import Login from '@/components/Users/Login.vue'
import ForgotPassword from '@/components/Users/ForgotPassword.vue'
import ResetPassword from '@/components/Users/ResetPassword.vue'

export const authRoutes = {
  path: '/',
  component: AuthLayout,
  children: [
    {
      path: 'login',
      name: 'login',
      component: Login,
      meta: {
        title: 'Login'
      }
    },
    {
      path: 'forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: {
        title: 'Forgot Password'
      }
    },
    {
      path: 'reset_password',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        title: 'Reset Password'
      }
    },
  ],
}
