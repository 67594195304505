<template>
  <div>
    <div class="optionInfo">
      <OptionAlertModal
        :dialogVisible="alertDialogVisible"
        @alert-create="alertDialogVisible = false"
        @close-click="alertDialogVisible = false"
      />

      <div v-loading="loading">
        <v-card class="product-card" min-height="350px">
          <div class="mb-4 product-title">
            <h4>Product</h4>

            <div class="d-flex">
              <v-switch
                class="ma-0 pa-0"
                hide-details
                @change="paramsChanged"
                v-model="parameterValues.planning_active">
              </v-switch>
              <LinkedPagesMenu
                :variation-key="article.variation_key"
                :option-key="optionKey"
              />
              <v-menu
                top
                open-on-hover
                offset-x
                min-width="200"
                max-width="200"
                content-class="option-info-menu"
                :open-delay="200"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-3"
                    v-bind="attrs"
                    v-on="on"
                  >info</v-icon>
                </template>

                <v-card class="py-2 px-4 p-relative" v-html="getProperties()"></v-card>
              </v-menu>
              <v-icon class="icon" @click="changeAlert(article)">add_alert</v-icon>
            </div>
          </div>

          <template v-if="loaded">
            <div class="image-row">
              <img alt="" height="160px" :src="imgUrl || '@/assets/img/ts_placeholder.png'" @error="loadPlaceholder" />
              <div class="product-text">
                <OptionVariationsMenu
                  :option="article"
                  :variations="variations"
                />
              </div>
            </div>

            <v-divider />
            <template>
              <h4 class="mt-5 mb-4">Filters</h4>
              <div class="stores">
                <collapsible-select
                  v-model="storesIncluded"
                  :items="stores"
                  item-text="store_name"
                  item-value="store_key"
                  show-select-all
                  label="Stores"
                  autocomplete
                />
              </div>
              <div class="stores mb-6">
                <v-combobox
                  v-model="orderSettings.reorder_option_keys"
                  label="Reorder options"
                  placeholder="Search"
                  :items="reorderOptionsListToShow"
                  :return-object="false"
                  item-value="option_key"
                  item-text="name"
                  multiple
                  chips
                  clearable
                  small-chips
                  deletable-chips
                  hide-details
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"
                  @click="handleReorderOptionsClick"
                  @blur="handleReorderOptionsBlur"
                  @change="handleReorderOptionsChanged"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      v-if="index === 0"
                      small
                      close
                      outlined
                      @click="handleFirstReorderOptionClick"
                      @click:close="handleFirstReorderOptionDelete"
                    >
                      <span>{{ optionsMap.get(item) || item }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption mx-1">
                      +{{ orderSettings.reorder_option_keys.length - 1 }}
                    </span>
                  </template>
                </v-combobox>
              </div>
              <div class="sliders"
                v-for="p of parameters"
                :key="p.name">
                <div class="min-max d-flex align-center"><span>{{p.name | formatString}}</span></div>
                <v-slider
                  v-if="!isObject(parameterValues[p.name])"
                  @change="paramsChanged"
                  v-model="parameterValues[p.name]"
                  :min="p.slider[0]"
                  hide-details
                  :max="p.slider[1]"
                  :step="p.slider[2]"
                  thumb-label
                  :thumb-size="24"
                ></v-slider>
                <v-range-slider
                  v-else
                  :key="p.name"
                  @change="paramsChanged"
                  v-model="parameterValues[p.name]"
                  hide-details
                  :min="p.slider[0]"
                  :max="p.slider[1]"
                  :step="p.slider[2]"
                  thumb-label
                  :thumb-size="24"
                ></v-range-slider>
              </div>
            </template>
          </template>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import * as optionsApi from '@/api/options'
import { findOptionsByFiltersTSQL } from '@/api/options'
import * as orderApi from '@/api/order'
import OptionAlertModal from '../Alerts/OptionAlertModal'
import CollapsibleSelect from '../CollapsibleSelect'
import LinkedPagesMenu from '@/components/LinkedPagesMenu'
import OptionVariationsMenu from '@/components/OptionVariationsMenu.vue'
import { formatString } from '@/variables'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import ts_placeholder from '@/assets/img/ts_placeholder.png'

import { useAlertStore } from '@/store/pinia/alertStore'

export default {
  setup () {
    return { alertStore: useAlertStore() }
  },
  components: { OptionAlertModal, CollapsibleSelect, LinkedPagesMenu, OptionVariationsMenu },
  props: {
    'optionKey': {
      type: Number,
      default: null,
    },
    'storeKeys': {
      type: Array,
      default: () => [],
    },
    'storeKeysSelected': {
      type: Array,
      default: () => [],
    },
    orderSettings: {
      type: Object,
      default: () => {
        return {
          reorder_option_keys: [],
        }
      },
    },
  },
  data () {
    return {
      imgUrl: '',
      article: {},
      alertDialogVisible: false,
      loaded: false,
      loading: true,
      parameters: [],
      parameterValues: {},
      reorderOptionsList: [],
      showOnlyCurrentOptions: false,
      variations: [],
    }
  },
  watch: {
    optionKey () {
      this.load()
    },
  },
  methods: {
    isObject (val) {
      return typeof val === 'object'
    },
    changeAlert (article) {
      const payload = {
        page: 'reorder',
        option_key: this.optionKey,
        option_name: article.option_name,
      }
      this.alertDialogVisible = true
      this.alertStore.setOptionAlertData(payload)
    },
    getProperties () {
      let dataString = ''
      const data = this.article['properties']
      for (const key of Object.keys(data || {}).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))) {
        const val =
          '<b>' + formatString(key) + '</b>: ' + data[key].toLocaleString()
        dataString += val + '<br>'
      }
      return dataString
    },
    getDescription (name) {
      return name
    },
    getVariations () {
      return optionsApi
        .getOptionVariations(this.$route.query.option_key)
        .then(variations => {
          this.variations = variations
        })
    },
    load () {
      if (this.optionKey != null) {
        this.getSliderDefault().then(this.getSliders)
        this.getData()
        this.getVariations()
      }
    },
    getData () {
      optionsApi
        .loadOptionData({
          format: 'aod',
          option_key: this.optionKey
        })
        .then(response => {
          this.article = response.data.data
          this.imgUrl = response.data.data['image_url']
          delete response.data.data['image_url']
          const keys = Object.keys(response.data.data).slice(0, 5)
          this.article = {}
          for (const key of keys) {
            this.article[key] = response.data.data[key]
          }
          this.loaded = true
          this.loading = false
        })
        .catch(error => console.log(error))
    },
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    getSliders () {
      const data = this.orderSettings
      if (Object.keys(data['order_parameters']).length !== 0) {
        this.parameterValues = data['order_parameters']
        this.parameterValues.planning_active = data.planning_active
      } else {
        for (const p of this.parameters) {
          this.parameterValues[p.name] = p.default
        }
      }
    },
    getSliderDefault () {
      return orderApi
        .getOrderDefaultParameters()
        .then(response => {
          this.parameters = response.order_parameters
        })
        .catch(error => {
          console.log(error)
        })
    },
    paramsChanged () {
      this.$emit('paramChanged', this.parameterValues)
    },
    handleStoresSelectionChanged (selection) {
      let val = selection

      if (val.includes('all')) {
        val = this.storeKeys
      }

      if (val.includes('none')) {
        val = []
      }
      this.$emit('storeKeyChanged', val)
    },
    handleInputFormChange () {
      if (this.validateShopItem()) {
        this.saveShopItemDelayed('shop-item-saved')
      }
    },
    handleReorderOptionsClick () {
      if (!this.reorderOptionsLoaded) {
        this.loadReorderOptions()
      }
    },
    handleFirstReorderOptionClick () {
      this.handleReorderOptionsClick()
      this.showOnlyCurrentOptions = true
    },
    handleReorderOptionsBlur () {
      setTimeout(() => {
        this.showOnlyCurrentOptions = false
      }, 100)
    },

    loadReorderOptions () {
      const payload = {
        search_text: null,
        discount_min: null,
        discount_max: null,
        stock_active: null,
        osp_min: null,
        osp_max: null,
        asp_min: null,
        asp_max: null,
        stock_min: null,
        stock_max: null,
        inbounds_min: null,
        inbounds_max: null,
        option_keys_include: [],
        option_keys_exclude: [],
        properties_include: {},
        properties_exclude: {},
        filters: [],
      }

      this.loading = true

      return findOptionsByFiltersTSQL(payload, ['option_key', 'name'])
        .then(results => {
          this.reorderOptionsList = results.options
          this.reorderOptionsLoaded = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleFirstReorderOptionDelete () {
      this.orderSettings.reorder_option_keys.splice(0, 1)
      this.handleReorderOptionsChanged()
    },
    handleReorderOptionsChanged () {
      this.$emit('reorderKeysChanged', this.orderSettings.reorder_option_keys)
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    optionsMap () {
      const map = new Map()
      this.reorderOptionsList.forEach(o => map.set(o.option_key, o.name))
      return map
    },
    reorderOptionsListToShow () {
      return this.showOnlyCurrentOptions
        ? this.reorderOptionsList.filter(
          o => this.orderSettings.reorder_option_keys.includes(o.option_key)
        ) : this.reorderOptionsList
    },
    stores () {
      return (this.appConfig?.stores_open || [])
    },
    storesIncluded: {
      get () {
        return this.storeKeysSelected
      },
      set (val) {
        this.handleStoresSelectionChanged(val)
      }
    },
  },
  mounted () {
    this.load()
    this.loadReorderOptions()
  }
}
</script>

<style lang="scss">
.optionInfo {
    color: var(--text-black);
    height: 100%;
    .tooltip {
        width: 20px;
    }
    .product-card {
        padding: 16px;
        height: 100% !important;
        .product-title {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .icon:hover {
                color: var(--primary);
                cursor: pointer;
            }
        }
        .image-row {
            margin-bottom: 26px;
            display: flex;
            .product-text {
                font-size: 13px;
                margin-left: 16px;
                p {
                    margin: 0;
                }
                .product-title {
                    font-weight: 600;
                }
                .product-subtitle {
                    color: var(--text-sub-black);
                }
                .product-price {
                    margin-top: 6px;
                }
            }
        }
        .stores {
            .v-label {
                color: var(--success) !important;
            }
            .v-chip {
                border-color: var(--success);
                color: var(--success);
                height: 30px;
                background-color: rgba(76, 175, 80, 0.06) !important;
                &:hover {
                    background-color: rgba(76, 175, 80, 0.2) !important;
                }
            }
        }
    }
    .sliders {
        display: grid;
        grid-template-columns: 1fr 4fr;
        .min-max {
            color: #666;
            font-size: 14px;
        }
    }
}
</style>
