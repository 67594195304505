<template>
  <resizable @resize="updateTotalTableWidth">
    <div class="p-relative">
      <v-overlay absolute color="white" :value="loading">
        <v-progress-circular color="primary" indeterminate size="64" width="2" />
      </v-overlay>
      <data-tables
        class="strategy-collection-matrix-table"
        style="width: 100%"
        :data="tableData"
        :page-size="tableData.length"
        :pagination-props="{ pageSizes: [tableData.length], class: 'd-none' }"
        :table-props="{ 'row-key': 'option_key' }"
      >
        <el-table-column
          :prop="dimensionColumn"
          :label="dimensionColumnLabel"
          label-class-name="text-center"
          width="80"
          class-name="dark-bkg-column dimension-cell-td"
        >
          <template slot-scope="scope">
            <div class="text-center dimension-cell">
              <template v-if="['original_price', 'cost_price'].includes(dimensionColumn)">
                {{ scope.row[dimensionColumn] | currency }}
              </template>
              <template v-else>
                {{ scope.row[dimensionColumn] }}
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="options"
          label="Options"
          label-class-name="text-center"
          class-name="options-cell-td"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.options">
              <div
                class="d-flex options-cell"
                :class="{
                  'flex-nowrap': size === 'large',
                  'flex-wrap': size === 'small',
                }"
                @dragover="event => isPlanned ? handleCardDragover(event) : null"
                @drop="isPlanned ? handleCardDrop(scope.$index) : null"
              >
                <div
                  v-for="option, i in scope.row.options"
                  :key="i"
                  :style="size === 'large' ? {
                    width: '180px',
                    flex: '0 0 180px',
                  } : null"
                >
                  <v-menu
                    :disabled="size === 'large'"
                    v-model="optionsMenus[option.product_key]"
                    offset-x
                    min-width="300"
                    max-width="300"
                    open-on-hover
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                        v-on="on"
                        :draggable="isPlanned && ['generated', 'placeholder'].includes(option.status)"
                        @dragstart="isPlanned ? handleCardDragstart(option, scope.$index) : null"
                      >
                        <OptionCard
                          :option="option"
                          :visible-metrics="visibleMetrics"
                          :metrics-dict="metricsDict"
                          :is-planned="isPlanned"
                          :size="size"
                          :selectedOrder="selectedOrder"
                          @product-click="selectedProduct = option; editModalOpened = true"
                          @delete-click="handleOptionDeleteClick"
                        />
                      </div>
                    </template>
                    <div class="white pa-4">
                      <OptionCard
                        :option="option"
                        :visible-metrics="visibleMetrics"
                        :metrics-dict="metricsDict"
                        :is-planned="isPlanned"
                        :selectedOrder="selectedOrder"
                      />
                    </div>
                  </v-menu>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="placeholder"
                @dragover="event => isPlanned ? handleCardDragover(event) : null"
                @drop="isPlanned ? handleCardDrop(scope.$index) : null"
              >
                -
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-for="metric in sumMetrics"
          :key="metric"
          :prop="metric"
          :label="metricsNamesDict[metric]"
          label-class-name="text-center"
          width="100"
          class-name="dark-bkg-column metric-column"
        >
          <template slot-scope="scope">
            <!-- Value -->
            <template v-if="showValue">
              <template v-if="scope.row.options">
                <div class="text-center">
                  <template v-if="['original_value', 'cost_value', 'revenue'].includes(metric)">
                    {{ scope.row[`totalBy${upperFirst(metric)}`] | currency_no_decimals }}
                  </template>
                  <template v-else-if="['original_price', 'cost_price'].includes(metric)">
                    {{ scope.row[`totalBy${upperFirst(metric)}`] | currency }}
                  </template>
                  <template v-else>
                    {{ scope.row[`totalBy${upperFirst(metric)}`] }}
                  </template>
                </div>
              </template>
              <template v-else>
                <div class="text-center">
                  -
                </div>
              </template>
              <template v-if="showDeltas">
                <div
                  class="delta-value"
                  :class="{
                    'increase': scope.row[`totalBy${upperFirst(metric)}Delta`] > 0,
                    'decrease': scope.row[`totalBy${upperFirst(metric)}Delta`] < 0
                  }">
                  <template v-if="['original_value', 'cost_value', 'revenue'].includes(metric)">
                    {{ scope.row[`totalBy${upperFirst(metric)}Delta`] < 0 ? '' : '+' }}{{ scope.row[`totalBy${upperFirst(metric)}Delta`] | currency_no_decimals }}
                  </template>
                  <template v-else-if="['original_price', 'cost_price'].includes(metric)">
                    {{ scope.row[`totalBy${upperFirst(metric)}Delta`] < 0 ? '' : '+' }}{{ scope.row[`totalBy${upperFirst(metric)}Delta`] | currency }}
                  </template>
                  <template v-else>
                    {{ scope.row[`totalBy${upperFirst(metric)}Delta`] < 0 ? '' : '+' }}{{ scope.row[`totalBy${upperFirst(metric)}Delta`] }}
                  </template>
                </div>
              </template>
            </template>

            <!-- Share -->
            <template v-if="showShare">
              <div class="text-center">
                <div class="mt-4">
                  <template v-if="metric === 'depth'">
                    -
                  </template>
                  <template v-else>
                    {{ tableShares[scope['$index']][`shareBy${upperFirst(metric)}`] }}%
                  </template>
                </div>
                <div
                  v-if="showDeltas"
                  class="delta-value"
                  :class="{
                    'increase': tableShares[scope['$index']][`shareBy${upperFirst(metric)}Delta`] > 0,
                    'decrease': tableShares[scope['$index']][`shareBy${upperFirst(metric)}Delta`] < 0
                  }"
                >
                  <template v-if="metric === 'depth'">
                    -
                  </template>
                  <template v-else>
                    {{ tableShares[scope['$index']][`shareBy${upperFirst(metric)}Delta`] < 0 ? '' : '+' }}{{ tableShares[scope['$index']][`shareBy${upperFirst(metric)}Delta`] }}%
                  </template>
                </div>
              </div>
            </template>
          </template>
        </el-table-column>

        <!-- Totals -->
        <data-tables
          slot="append"
          class="total-table"
          :data="[tableTotals]"
          :pagination-props="{class: 'd-none'}"
        >
          <el-table-column
            class-name="total-row_column"
            label-class-name="d-none"
            width="80"
          >
            <template>
              <div class="text-center">
                Total
              </div>
            </template>
          </el-table-column>
          <el-table-column label-class-name="d-none" class-name="total-row_column options-cell-td" />
          <el-table-column
            v-for="metric in sumMetrics"
            :key="metric"
            :prop="metric"
            :label="metricsNamesDict[metric]"
            class-name="total-row_column"
            label-class-name="d-none"
            width="100"
          >
            <template slot-scope="scope">
              <div class="text-center">
                <template v-if="['original_value', 'cost_value', 'revenue'].includes(metric)">
                  {{ scope.row[`totalBy${upperFirst(metric)}`] | currency_no_decimals }}
                </template>
                <template v-else-if="['original_price', 'cost_price'].includes(metric)">
                  {{ scope.row[`totalBy${upperFirst(metric)}`] | currency }}
                </template>
                <template v-else>
                  {{ scope.row[`totalBy${upperFirst(metric)}`] }}
                </template>
                <template v-if="showDeltas">
                  <div
                    class="delta-value"
                    :class="{
                      'increase': scope.row[`totalBy${upperFirst(metric)}Delta`] > 0,
                      'decrease': scope.row[`totalBy${upperFirst(metric)}Delta`] < 0
                    }">
                    <template v-if="['original_value', 'cost_value', 'revenue'].includes(metric)">
                      {{ scope.row[`totalBy${upperFirst(metric)}Delta`] < 0 ? '' : '+' }}{{ scope.row[`totalBy${upperFirst(metric)}Delta`] | currency_no_decimals }}
                    </template>
                    <template v-else-if="['original_price', 'cost_price'].includes(metric)">
                      {{ scope.row[`totalBy${upperFirst(metric)}Delta`] < 0 ? '' : '+' }}{{ scope.row[`totalBy${upperFirst(metric)}Delta`] | currency }}
                    </template>
                    <template v-else>
                      {{ scope.row[`totalBy${upperFirst(metric)}Delta`] < 0 ? '' : '+' }}{{ scope.row[`totalBy${upperFirst(metric)}Delta`] }}
                    </template>
                  </div>
                </template>
              </div>
            </template>
          </el-table-column>
        </data-tables>
      </data-tables>
      <DeleteDialog
        :visible="isDeleteProductModalOpen"
        content-title="Delete product"
        content-text="Are you sure you want to delete selected product?"
        @cancel="handleDeleteProductCancel"
        @confirm="handleDeleteProductConfirm"
      />
      <v-dialog
        v-model="editModalOpened"
        width="600px"
        v-if="editModalOpened"
        scrollable
        max-width="90%"
      >
        <ProductsEditModal
          :analyticsProperties="analyticsProperties"
          :parsedAnalyticsProperties="parsedAnalyticsProperties"
          :product="selectedProduct"
          @close="editModalOpened = false"
          @chunk-deleted="onChunkDeleted"
          @click="handleAddChunkClick(scope.row)"
          @save="handleProductChanged($event); editModalOpened = false"
        ></ProductsEditModal>
      </v-dialog>
    </div>
  </resizable>
</template>

<script>
import { upperFirst, round } from 'lodash'
import { deleteStrategyCollectionProduct, updateStrategyCollectionProduct } from '@/api/strategy'
import OptionCard from '@/components/Strategy/CollectionMatrix/OptionCard.vue'
import DeleteDialog from '@/components/DeleteDialog'
import ProductsEditModal from '@/components/Strategy/ProductsEditModal.vue'
import Resizable from '@/components/Utility/Resizable.vue'
const PropKey = 'properties/article/'

export default {
  name: 'MatrixTable',
  components: { Resizable, OptionCard, DeleteDialog, ProductsEditModal },
  props: {
    tableData: {
      type: Array,
      default: () => ([])
    },
    tableShares: {
      type: Array,
      default: () => ([])
    },
    tableTotals: {
      type: Object,
      default: () => ({})
    },
    dimensionColumn: {
      type: String,
      required: true,
    },
    analyticsProperties: {
      type: Object,
      default: () => ({})
    },
    sumMetrics: {
      type: Array,
      required: true,
    },
    visibleMetrics: {
      type: Array,
      default: () => [],
    },
    showShare: {
      type: Boolean,
      default: false,
    },
    showValue: {
      type: Boolean,
      default: true,
    },
    showDeltas: {
      type: Boolean,
      default: false,
    },
    isPlanned: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'large',
    },
    selectedOrder: {
      type: String,
      default: 'pieces',
    },
  },
  data () {
    this.metricsDict = {
      original_value: 'original_value',
      cost_value: 'cost_value',
      initial_margin: 'initial_margin',
      original_price: 'original_price',
      cost_price: 'cost_price',
      pieces: 'pieces',
      stars: 'stars',
      stores: 'stores',
      revenue: 'revenue',
      sales: 'sales_net',
      margin: 'margin',
      sellthrough_fp: 'sellthrough_fp',
      lifetime: 'lifetime',
      gmroi: 'gmroi',
      potential_missed: 'potential_missed_unstocked',
    }

    this.metricsNamesDict = {
      original_value: 'Orig. value',
      cost_value: 'Cost value',
      original_price: 'Orig. price',
      cost_price: 'Cost price',
      pieces: 'Pieces',
      depth: 'Depth',
      width: 'Width',
    }

    return {
      loading: false,
      optionsMenus: {},
      isDeleteProductModalOpen: false,
      productToDelete: null,
      draggedOptionRow: null,
      draggedOptionRowIndex: null,
      selectedProduct: null,
      editModalOpened: false,
      chunkToDelete: null,
    }
  },
  computed: {
    sumMetricFilter () {
      return 'integer'
    },
    dimensionColumnLabel () {
      const parts = this.dimensionColumn.split('/')

      return this.$options.filters.formatString(parts.slice(-1)[0])
    },
    analyticsPropertiesList (){
      return Object.keys(this.analyticsProperties).sort()
    },
    parsedAnalyticsProperties (){
      const list = this.analyticsPropertiesList.filter(item => item.startsWith(PropKey))
      return list.map(item => ({
        label: item.split(PropKey)[1],
        value: item
      }))
    },
  },
  watch: {
    sumMetrics: {
      handler () {
        this.updateTotalTableWidth()
      }
    },
  },
  methods: {
    round,
    upperFirst,
    handleOptionDeleteClick (option) {
      this.isDeleteProductModalOpen = true
      this.productToDelete = option
    },
    handleDeleteProductCancel () {
      this.isDeleteProductModalOpen = false
      this.productToDelete = null
    },
    handleCardDragstart (option, rowIndex) {
      this.draggedOption = option
      this.draggedOptionRowIndex = rowIndex
    },
    handleCardDragover (event) {
      event.preventDefault()
      event.dataTransfer.dropEffect = 'move'
    },
    async handleCardDrop (dropRowIndex) {
      let payload = { product_keys: [this.draggedOption.product_key] }

      if (this.dimensionColumn.startsWith(PropKey)) {
        payload = {
          ...payload,
          properties: {
            [this.dimensionColumn.replace(PropKey, '')]: this.tableData[dropRowIndex][this.dimensionColumn]
          },
        }
      } else {
        payload = {
          ...payload,
          [this.dimensionColumn]: this.tableData[dropRowIndex][this.dimensionColumn],
        }
      }


      this.loading = true
      try {
        await updateStrategyCollectionProduct(payload)
        this.$emit('item:dropped')
      } finally {
        this.loading = false
        this.draggedOption = null
        this.draggedOptionRowIndex = null
      }
    },
    async handleDeleteProductConfirm () {
      this.loading = true

      try {
        await deleteStrategyCollectionProduct(this.productToDelete.product_key)
      } finally {
        this.loading = false
        this.isDeleteProductModalOpen = false
      }

      this.$emit('product-deleted', this.productToDelete)
    },
    async handleProductChanged (row) {
      if (this.validateProduct(row)) {
        await this.updateProduct(row)
      }
    },
    async updateProduct (product) {
      this.loading = true

      try {
        await updateStrategyCollectionProduct(product)
      } finally {
        this.$emit('updated-chunk', product.product_key)
        this.loading = false
      }
    },
    async handleAddChunkClick (row) {
      if (!this.validateChunk(row)) {
        return false
      }

      this.loading = true

      try {
        const payload = {
          ...row,
          product_key: row.product.product_key,
        }
        const newRow = await createStrategyCollectionChunk(payload)

        console.log(newRow)
      } finally {
        this.loading = false
      }
    },
    onChunkDeleted (row){
      this.chunkToDelete = row
      this.handleDeleteChunkConfirm()
    },
    validateProduct (product) {
      this.$set(product, 'errors', {})
      const fields = ['stores', 'stars', 'status']

      fields.forEach(field => {
        if (product[field] === undefined || product[field] === '') {
          this.$set(product.errors, field, ['Required'])
        }
      })

      return Object.keys(product.errors).length === 0
    },
    validateChunk (chunk) {
      this.$set(chunk, 'errors', {})
      const fields = ['type', 'week_number', 'pieces', 'original_price', 'cost_price']

      fields.forEach(field => {
        if (chunk[field] === undefined || chunk[field] === '') {
          this.$set(chunk.errors, field, ['Required'])
        }
      })
      return Object.keys(chunk.errors).length === 0
    },
    updateTotalTableWidth () {
      setTimeout(() => {
        const appendTable = this.$el.querySelector('.el-table__append-wrapper')
        if (appendTable) {
          appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
        }
      }, 100)
    },
  },
}
</script>

<style lang="scss">
.strategy-collection-matrix-table {
  .el-table th,
  .dark-bkg-column {
    background-color: #f5f7fa;
  }
  .options-cell {
    overflow-x: scroll;
    width: 100%;
  }
  .el-table .cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .metric-column {
    .cell {
      flex-direction: column;
    }
  }
  .placeholder {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .delta-value {
    text-align: center;
    &.increase {
      color: #4caf50;
    }
    &.decrease {
      color: #f44336;
    }
  }
  .total-row_column {
    background-color: #f5f7fa;
    height: 55px;
  }
}
</style>