<template>
  <div
    id="strategy-budget-cubes"
    class="pt-0"
  >
    <v-row class="align-baseline mt-3 mb-3">
      <v-col cols="3" class="py-0">
        <h3>Cubes</h3>
      </v-col>
      <v-spacer />
      <v-col md="2" cols="4" class="py-0">
        <StrategyCubeSelect
          ref="cubeSelect"
          @cube-inputs-changed="handleCubeInputsChanged"
          @cube-inputs-loaded="handleCubeInputsLoaded"
        />
      </v-col>
    </v-row>

    <template v-if="storeKeysToNames">
      <div class="p-relative">
        <v-overlay absolute color="white" :value="loading" >
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <v-card class="py-4">
          <div class="px-6">
            <template v-if="filtersDimensions.length">
              <v-row class="align-center">
                <v-col cols="10" class="pb-0">
                  <StrategyFilters
                    ref="filters"
                    :dimensions="filtersDimensionsWithoutStoreDimensions"
                    :storeDimensions="storeDimensions"
                    @filters-loaded="handleFiltersLoaded"
                    @filters-changed="handleFiltersChanged"
                  />
                </v-col>
                <v-col cols="2" class="pb-0 pt-0 text-right" id="menus-parent">
                  <v-menu
                    v-model="settingsMenuOpened"
                    :close-on-content-click="false"
                    attach="#menus-parent"
                    offset-y
                    left
                    min-width="450"
                    max-width="450"
                    nudge-bottom="20"
                    content-class="settings-menu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on">tune</v-icon>
                    </template>

                    <v-card>
                      <h4 class="ml-4 mb-2 pt-6">
                        Settings
                      </h4>
                      <div class="input-parameter">
                        <div class="parameter-name">View</div>
                        <div class="parameter-value">
                          <v-select
                            :items="settingsViews"
                            item-text="name"
                            return-object
                            class="pt-0"
                            :menu-props="{
                              left: true,
                              offsetY: true,
                              contentClass: 'select-dropdown-menu',
                            }"
                            @change="setMetricsFromView"
                            hide-details="auto"
                          />
                        </div>
                      </div>
                      <div class="input-parameter">
                        <div class="parameter-name">Metrics</div>
                        <div class="parameter-value">
                          <collapsible-select
                            autocomplete
                            clearable
                            v-model="currentMetrics"
                            :items="metricList"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            hide-details
                            :menu-props="{
                              left: true,
                              offsetY: true,
                              contentClass: 'select-dropdown-menu',
                            }"
                            @change="settingsChanged = true"
                          />
                        </div>
                      </div>
                      <div class="input-parameter">
                        <div class="parameter-name">Reference years</div>
                        <div class="parameter-value">
                          <collapsible-select
                            autocomplete
                            v-model="referenceYears"
                            :items="referenceYearList"
                            multiple
                            hide-details
                            clearable
                            :menu-props="{
                              left: true,
                              offsetY: true,
                              contentClass: 'select-dropdown-menu',
                            }"
                            @change="settingsChanged = true"
                          />
                        </div>
                      </div>
                      <div class="input-parameter">
                        <div class="parameter-name">LFL method</div>
                        <div class="parameter-value">
                          <v-select
                            :items="['open', 'lfl', 'all']"
                            v-model="lflMethod"
                            class="pt-0"
                            :menu-props="{
                              left: true,
                              offsetY: true,
                              contentClass: 'select-dropdown-menu',
                            }"
                            @change="settingsChanged = true"
                            hide-details="auto"
                          />
                        </div>
                      </div>
                      <div class="input-parameter">
                        <div class="parameter-name">No stores</div>
                        <div class="parameter-value">
                          <v-checkbox
                            v-model="noStores"
                            hide-details
                            class="my-1 pt-0"
                          ></v-checkbox>
                        </div>
                      </div>
                      <div class="input-parameter">
                        <div class="parameter-name">
                          Set shares
                          <v-tooltip top :max-width="250">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                            </template>
                            <span>
                              Total in page filtering stays the same, shares of dimension can be changed</span>
                          </v-tooltip>
                        </div>
                        <div class="parameter-value">
                          <v-checkbox
                            v-model="setShares"
                            hide-details
                            class="my-1 pt-0"
                            @change="settingsChanged = true"
                          ></v-checkbox>
                        </div>
                      </div>
                      <div class="input-parameter">
                        <div class="parameter-name">
                          Offset sales
                          <v-tooltip top :max-width="250">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                            </template>
                            <span>
                              Revenue remains the same when increasing or decreasing actual price</span>
                          </v-tooltip>
                        </div>
                        <div class="parameter-value">
                          <v-checkbox
                            v-model="offsetSales"
                            hide-details
                            class="my-1 pt-0"
                            @change="settingsChanged = true"
                          ></v-checkbox>
                        </div>
                      </div>
                      <div class="input-parameter">
                        <div class="parameter-name">
                          Scale prices
                          <v-tooltip top :max-width="250">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
                            </template>
                            <span>
                              All prices scale to keep the same discount and margin</span>
                          </v-tooltip>
                        </div>
                        <div class="parameter-value">
                          <v-checkbox
                            v-model="scalePrices"
                            hide-details
                            class="my-1 pt-0"
                            @change="settingsChanged = true"
                          ></v-checkbox>
                        </div>
                      </div>
                    </v-card>
                  </v-menu>
                  <v-menu
                    :close-on-content-click="false"
                    attach="#menus-parent"
                    offset-y
                    left
                    min-width="450"
                    max-width="450"
                    nudge-bottom="20"
                    content-class="download-menu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mx-2 action-btn-primary" v-bind="attrs" v-on="on">download</v-icon>
                    </template>

                    <v-card>
                      <h4 class="ml-4 mb-2 pt-6">
                        Download
                      </h4>
                      <div class="input-parameter">
                        <div class="parameter-name">Dimensions</div>
                        <div class="parameter-value">
                          <v-autocomplete
                            clearable
                            v-model="dimensionsToDownload"
                            :items="cubePropertiesGrouped"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            hide-details
                            :menu-props="{
                              left: true,
                              offsetY: true,
                              contentClass: 'select-dropdown-menu',
                            }"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip
                                small
                                close
                                outlined
                                @click:close="dimensionsToDownload.splice(index, 1)"
                              >
                                <span>{{ item.value }}</span>
                              </v-chip>
                            </template>
                          </v-autocomplete>
                        </div>
                      </div>
                      <div class="text-right py-2 px-4">
                        <v-btn
                          color="primary"
                          depressed
                          outlined
                          @click="handleDownloadClick"
                        >Download</v-btn>
                      </div>
                    </v-card>
                  </v-menu>
                  <input
                    id="cubesDataFileInput"
                    ref="cubesDataFileInput"
                    type="file"
                    class="d-none"
                    accept=".csv"
                    @change="handleCubesDataFileInputChange"
                  >
                  <label for="cubesDataFileInput">
                    <v-icon class="mx-2 action-btn-primary c-pointer">upload</v-icon>
                  </label>
                </v-col>
              </v-row>
            </template>
            <v-row class="mt-5 mb-8">
              <v-col>
                <h4 class="my-2 mb-6">KPIs</h4>
                <StrategyKPITiles
                  :kpi-items="kpiItems"
                  :is-changeable="true"
                  :items-number="7"
                  @tile-clicked="handleKPITileClick"
                  @tile-changed="handleKpiTileUpdate"
                />
              </v-col>
            </v-row>
            <StrategyDimensionsTabs
              v-if="savedTabs"
              ref="dimensionsTabs"
              :dimensions="filtersDimensionsWithoutStoreDimensions"
              :saved-tabs="savedTabs"
              :metrics="currentMetrics"
              :views="views"
              :cube-properties="cubeProperties"
              :store-keys-to-names="storeKeysToNames"
              :no-stores="noStores"
              @cell-click="fillModalWithIndexBelief"
              @cell-change="fillModalWithIndexBelief($event, true)"
              @drilldown-click="handleDrilldownClick"
              @chart-click="handleChartClicked"
              @tabs-changed="saveCustomDimensionsTabs"
              @custom-dimension-change="handleCustomDimensionsChanged"
              @custom-dimension-childs-change="handleCustomDimensionsChanged"
            />
          </div>
        </v-card>

        <v-card class="my-10" ref="actionsLogs">
          <v-tabs
            v-model="activeActionTab"
            show-arrows
          >
            <v-tabs-slider />

            <v-tab
              key="applied"
              class="px-8"
              style="width: 180px"
            >Applied ({{ logs.length }})</v-tab>
            <v-tab
              key="toApply"
              class="px-8"
              style="width: 180px"
            >To apply ({{ actionsToApply.length }})</v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeActionTab">
            <v-tab-item
              :transition="false"
              :reverse-transition="false"
              key="applied"
            >
              <v-card-text>
                <v-row class="px-5 pt-5" justify="space-between">
                  <h4>Log</h4>
                  <v-btn
                    @click="onAddActionClick"
                    icon
                    small
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="pl-2">New</div>
                    <div style="overflow-y: scroll; overflow-x: hidden" :style="{
                      height: logHeight + 'px',
                    }">
                      <v-timeline
                        dense
                      >
                        <v-timeline-item
                          v-for="(log, i) in logs"
                          :key="i"
                          small
                          fill-dot
                        >
                          <v-row justify="space-between">
                            <v-col cols="12" sm="6">
                              {{ formatLog(log) }}
                            </v-col>
                            <v-col cols="5" sm="2">
                              <v-chip
                                style="width: 110px"
                                label
                                :color="'rgba(0,122,255,0.46)'"
                                class="mx-4 px-3 text-center justify-center"
                              >
                                {{ log.impact > 0 ? '+' : '' }}{{ nonDecimalPriceFormatter.format(log.impact) }}
                              </v-chip>
                            </v-col>
                            <v-col cols="4" sm="2" class="pr-0">
                              <v-chip
                                style="width: 110px"
                                label
                                :color="'rgba(160,160,160,0.46)'"
                                class="mx-4 px-3 text-center justify-center"
                              >
                                {{ log.time_ago }} ago
                              </v-chip>
                            </v-col>
                            <v-col cols="2" class="pr-5 text-right">
                              <v-tooltip left :max-width="500">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    @click="copyToClipboard(pretty(log))" :size="21" class="ml-2 c-pointer" v-bind="attrs" v-on="on">info</v-icon>
                                </template>
                                <pre>{{ pretty(log) }}</pre>
                              </v-tooltip>

                              <v-tooltip left :max-width="500">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs" v-on="on"
                                    :size="21" class="ml-2 c-pointer" @click="handleRevertClick(log)">mdi-arrow-u-left-bottom</v-icon>
                                </template>
                                <span>Revert to this version</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-timeline-item>
                      </v-timeline>
                    </div>
                    <div class="pl-2">Old</div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tab-item>

            <v-tab-item
              :transition="false"
              :reverse-transition="false"
              key="toApply"
            >
              <v-card-text>
                <v-row class="px-5 pt-5" justify="space-between">
                  <h4>Log</h4>
                  <v-btn
                    color="primary"
                    depressed
                    outlined
                    @click="handleApplyActionsClick"
                  >Apply</v-btn>
                </v-row>
                <template v-if="actionsToApply.length">
                  <v-row class="mt-n6">
                    <v-col cols="12">
                      <div class="pl-2 mt-4">New</div>
                      <div style="overflow-y: scroll; overflow-x: hidden" :style="{
                        height: actionsHeight + 'px',
                      }">
                        <v-timeline
                          dense
                        >
                          <v-timeline-item
                            v-for="(log, i) in actionsToApply"
                            :key="i"
                            small
                            fill-dot
                          >
                            <v-row justify="space-between">
                              <v-col md="6">
                                {{ formatActionToApply(log) }}
                              </v-col>
                              <v-col md="4" class="pr-0">
                                <v-chip
                                  style="width: 180px"
                                  label
                                  :color="'rgba(160,160,160,0.46)'"
                                  class="mx-4 px-3 text-center justify-center"
                                >
                                  {{ formatActionToApplyTime(log) }}
                                </v-chip>
                              </v-col>
                              <v-col cols="2" class="pr-5 text-right">
                                <v-tooltip top :max-width="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon :size="21" class="ml-2 c-pointer" v-bind="attrs" v-on="on">info</v-icon>
                                  </template>
                                  <pre>{{ pretty(log) }}</pre>
                                </v-tooltip>
                                <v-icon :size="21" class="ml-2 c-pointer" @click="handleActionDeleteClick(log)">delete</v-icon>
                              </v-col>
                            </v-row>
                          </v-timeline-item>
                        </v-timeline>
                      </div>
                      <div class="pl-2 mb-4">Old</div>
                    </v-col>
                  </v-row>
                </template>
                <div v-else class="mt-4 ml-2">No actions to apply</div>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <div id="for-tooltip"></div>
        <v-card @click="handleApplyActionsClick"  class="info-icon">

          <v-tooltip top left attach="#for-tooltip" :max-width="500"  min-width="150" v-if="!actionsToApply.length">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="success" size="48" >check_circle_outline</v-icon>
            </template>
            <span>No pending changes</span>
          </v-tooltip>
          <v-tooltip  top left attach="#for-tooltip" :max-width="500" min-width="150" v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="warning" size="48" >error_outline</v-icon>
            </template>
            <span>Pending changes, click icon to apply</span>
          </v-tooltip>
        </v-card>
      </div>
    </template>

    <v-dialog
      v-model="skipsModalVisible"
      persistent
      scrollable
      width="1200px"
      @keydown.esc="skipsModalVisible = false"
    >
      <v-card class="strategy-action-modal p-relative">
        <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">
          Your upload has failed, the following rows were incorrect:
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <StrategyCubeSkips :table-data="uploadSkips" />
        </v-card-text>
        <v-divider/>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="skipsModalVisible = false"
          >Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addActionModalVisible"
      persistent
      scrollable
      width="600px"
      @keydown.esc="addActionModalVisible = false"
    >
      <strategy-action-modal
        v-if="addActionModalVisible"
        ref="addActionModal"
        :actions="actions"
        :metric-list="metricList"
        :parent-filters="filtersValues"
        :cube-key="this.$refs.cubeSelect?.currentCube"
        :version-key="this.$refs.cubeSelect?.currentVersion"
        :dimensions="filtersDimensions"
        :subDimensions="subDimensions"
        :totals="views[0].total"
        @close="addActionModalVisible = false"
        @apply="onActionApply"
        @applyLater="onActionApplyLater"
      />
    </v-dialog>
  </div>
</template>

<script>
import { debounce, round } from 'lodash'
import { formatDistance } from 'date-fns'
import { GetCubeDownloadLink, LoadComparison, PerformAction, UploadDataFile } from '@/api/cube'
import StrategyKPITiles from '@/components/Strategy/StrategyKPITiles'
import StrategyCubeSkips from '@/components/Strategy/StrategyCubeSkips'
import StrategyDimensionsTabs from '@/components/Strategy/StrategyDimensionsTabs'
import StrategyCubeSelect from '@/components/Strategy/StrategyCubeSelect'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import StrategyFilters from '@/components/Strategy/StrategyFilters'
import StrategyActionModal from '@/components/Strategy/StrategyActionModal'
import { formatString } from '@/variables'
import { headersFilters } from '@/utils'
import columnFilters from '@/mixins/columnFilters'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import { useCubesStore } from '@/store/pinia/cubesStore'
import { useStrategyFiltersStore } from '@/store/pinia/strategyFiltersStore'
import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'

export default {
  name: 'StrategyBudgetCubesPage',
  components: { CollapsibleSelect, StrategyCubeSelect, StrategyFilters, StrategyActionModal, StrategyKPITiles, StrategyCubeSkips, StrategyDimensionsTabs },
  mixins: [columnFilters],
  setup () {
    return {
      stockTransfersStore: useStockTransfersStore(),
      cubesStore: useCubesStore(),
      strategyFiltersStore: useStrategyFiltersStore()
    }
  },
  data () {
    return {
      loading: true,
      filtersDimensions: [],
      storeDimensions: [],
      subDimensions: [],
      filtersValues: {},
      filtersLoaded: false,
      settingsMenuOpened: false,
      offsetSales: false,
      setShares: false,
      scalePrices: true,
      settingsChanged: false,
      cubeProperties: [],
      cubeList: [],
      versionList: [],
      currentAction: null,
      currentConfig: undefined,
      referenceYearList: [],
      referenceYears: [],
      currentMetrics: ['revenue', 'sales_net', 'discount', 'margin_value'],
      lflMethod: 'open',
      noStores: true,
      metricList: [],
      actions: [],
      views: [],
      savedTabs: null,
      kpis: {},
      logs: [],
      comparisonDebounce: null,
      addActionModalVisible: false,
      showOnlyCurrentMetrics: false,
      dimensionsToDownload: [],
      skipsModalVisible: false,
      uploadSkips: [],
      activeActionTab: 0,
      actionsToApply: [],
    }
  },
  async mounted () {
    this.comparisonDebounce = debounce(this.loadComparison, 1000)
    this.loadSettings()
    document.addEventListener('keydown', e => {
      if (e.shiftKey && e.key.toLowerCase() === 's') {
        e.preventDefault()
        this.handleApplyActionsClick()
      }
    })
  },
  watch: {
    filtersOpened (newValue) {
      const state = this.strategyFiltersStore
      if (!newValue && state.filtersChanged) {
        if (state.period) {
          this.$refs.filters.setWeeksFromPeriod(state.period)
        }

        this.comparisonDebounce()
      }
    },
    settingsMenuOpened (val) {
      if (!val && this.settingsChanged) {
        this.comparisonDebounce()
        this.settingsChanged = false
        this.saveSettings()
      }
    },
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    stores () {
      return (this.appConfig?.stores || [])
    },
    storeKeysToNames () {
      const result = new Map()

      for (const store of this.stores) {
        result.set(store.store_key, store.store_name)
      }

      return result
    },
    cubePropertiesGrouped () {
      const result = []
      const propertiesSorted = this.cubeProperties.slice().sort()
      let lastHeader = null

      for (const prop of propertiesSorted) {
        const parts = prop.split('/')

        if (!lastHeader || parts[0] !== lastHeader) {
          result.push({
            header: formatString(parts[0]),
          })

          lastHeader = parts[0]
        }

        result.push({
          value: prop,
          text: formatString(parts[1]),
        })
      }

      return result
    },
    filtersOpened () {
      return this.strategyFiltersStore.filtersOpened
    },
    filtersDimensionsVisible () {
      return this.filtersDimensions.filter(d => this.noStores ? d.name !== 'store_keys' : true)
    },
    filtersDimensionsWithoutStoreDimensions () {
      return this.filtersDimensionsVisible
        .filter(d => this.noStores ? !this.storeDimensions.includes(d.name) : true)
    },
    currentCube () {
      return this.$refs.cubeSelect?.currentCube
    },
    currentVersion () {
      return this.$refs.cubeSelect?.currentVersion
    },
    settingsViews () {
      const views = this.cubesStore.cubesBrowser.views

      return this.noStores ? views : views.filter(v => !v.no_stores)
    },
    logHeight () {
      return this.logs.length >= 5 ? 300 : this.logs.length * 60
    },
    actionsHeight () {
      return this.actionsToApply.length >= 5 ? 300 : this.actionsToApply.length * 60
    },
    nonDecimalPriceFormatter () {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: this.$auth.getUserCurrency().currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
    kpiItems () {
      // this function will return an array of objects with keys as metric names, and values as bdg and ref
      return Object.keys(this.kpis).map(key => {
        let bdg = 0
        let ref = 0
        Object.keys(this.kpis[key]).forEach(k => {
          if (k.endsWith('(bdg)')) {
            bdg = this.kpis[key][k]
          } else if (k.endsWith('(ref)')) {
            ref = this.kpis[key][k]
          }
        })
        return {
          title: formatString(key),
          bdg,
          ref,
          delta: ref === 0 ? 0 : Math.round((bdg - ref) / ref * 100),
        }
      })
    },
  },
  methods: {
    copyToClipboard (text) {
      navigator.clipboard.writeText(text)
    },
    mainFilter: headersFilters,
    handleCubeInputsChanged () {
      this.loading = true
    },
    async handleCubeInputsLoaded ({ dimensions, sub_dimensions, store_dimensions }) {
      this.filtersDimensions = dimensions
      this.subDimensions = sub_dimensions
      this.storeDimensions = store_dimensions
      this.setCubeData()
      this.setCubeInputData()
      this.loadCustomDimensionsTabs()

      this.$nextTick(() => {
        if (this.strategyFiltersStore.period) {
          this.$refs.filters?.setWeeksFromPeriod(this.strategyFiltersStore.period)
        }

        if (this.filtersLoaded) {
          this.comparisonDebounce()
        }
      })

      this.loading = false
    },
    async handleFiltersLoaded (values) {
      this.filtersLoaded = true
      this.filtersValues = values
      this.loading = false

      if (this.filtersDimensions.length) {
        this.comparisonDebounce()
      }
    },
    saveSettings () {
      localStorage.setItem('strategyBudgetCubesSettings', JSON.stringify({
        currentMetrics: this.currentMetrics,
        referenceYears: this.referenceYears,
        lflMethod: this.lflMethod,
        noStores: this.noStores,
        offsetSales: this.offsetSales,
        setShares: this.setShares,
        scalePrices: this.scalePrices,
      }))
    },
    loadSettings () {
      const settings = JSON.parse(localStorage.getItem('strategyBudgetCubesSettings'))

      if (settings) {
        this.currentMetrics = settings.currentMetrics
        this.referenceYears = settings.referenceYears
        this.lflMethod = settings.lflMethod ?? 'open'
        this.noStores = settings.noStores ?? true
        this.offsetSales = settings.offsetSales ?? false
        this.setShares = settings.setShares ?? false
        this.scalePrices = settings.scalePrices ?? true
        if(!this.currentMetrics?.length){
          useGeneralStore().setSnack('warning', 'No metrics selected')
        }
      }
    },
    saveCustomDimensionsTabs () {
      const dimensionsTabs = JSON.parse(localStorage.getItem('strategyBudgetCubesDimensionsTabs')) || {}

      dimensionsTabs[this.currentCube] = this.$refs.dimensionsTabs?.customTabs.slice()

      localStorage.setItem('strategyBudgetCubesDimensionsTabs', JSON.stringify(dimensionsTabs))
    },
    loadCustomDimensionsTabs () {
      const dimensionsTabs = JSON.parse(localStorage.getItem('strategyBudgetCubesDimensionsTabs')) || {}

      this.savedTabs = dimensionsTabs[this.currentCube] || []
    },
    setMetricsFromView (view) {
      this.currentMetrics = view.metrics.slice()
      this.settingsChanged = true
    },
    handleCustomDimensionsChanged () {
      this.saveCustomDimensionsTabs()
      this.comparisonDebounce()
    },
    handleDrilldownClick ({ rows, dimension }) {
      if (dimension.indexOf('/') === -1) {
        if (dimension === 'week_numbers') {
          this.filtersValues.week_min = rows[0].week_numbers
          this.filtersValues.week_max = rows[rows.length - 1].week_numbers
        } else {
          this.filtersValues[dimension] = rows.map(item=> item[dimension])
        }
      } else {
        const strategyPageFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}

        strategyPageFilters[dimension] = rows.map(item=> item[dimension])

        localStorage.setItem('strategySideFilters', JSON.stringify(strategyPageFilters))
      }

      this.comparisonDebounce()
    },
    handleChartClicked ({ activeTab, clickData, pointDataValue, chartIndex }) {
      if (clickData.point) {
        const label = clickData.chart.data.datasets[clickData.point.datasetIndex].label
        let additionalFilters = []

        if (activeTab.isCustom) {
          activeTab.dimensions.forEach(d => {
            additionalFilters.push({
              name: d,
              value: this.views[chartIndex].data[clickData.point.index][d],
            })
          })
        } else {
          additionalFilters = [{
            name: activeTab.name,
            value: pointDataValue,
          }]
        }

        const valueRef = clickData.chart.data.datasets[1].data[clickData.point.index]
        const valueBdg = clickData.chart.data.datasets[2].data[clickData.point.index]
        const metric = label.substring(0, label.length - 6).toLowerCase()

        this.addActionModalVisible = true
        this.$nextTick(() => {
          this.$refs.addActionModal.fillIndexBeliefFromExternal(valueRef, valueBdg, metric, additionalFilters)
        })
      }
    },
    async handleRevertClick (logEntry) {
      this.addActionModalVisible = true
      this.$nextTick(() => {
        this.$refs.addActionModal.currentAction = this.actions.find(a => a.value.action === 'merge_version').value
        this.$refs.addActionModal.fromVersion = logEntry.version_key
        this.$refs.addActionModal.timestamp = logEntry.timestamp
      })
    },
    handleActionDeleteClick (logEntry) {
      this.actionsToApply = this.actionsToApply.filter(action => action !== logEntry)
    },
    handleFiltersChanged (values) {
      this.filtersValues = values
      this.comparisonDebounce()
    },
    handleKPITileClick (data) {
      const metric = data.title.toLowerCase().replace(' ', '_')

      this.addActionModalVisible = true
      this.$nextTick(() => {
        this.$refs.addActionModal.fillIndexBeliefFromExternal(data.ref, data.bdg, metric, null)
      })
    },
    handleKpiTileUpdate (data) {
      const metric = data.title.toLowerCase().replace(' ', '_')

      this.$nextTick(() => {
        this.applyForLater(data.ref, data.bdg, metric, null)
      })
    },
    async handleDownloadClick () {
      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}
      const payload = {
        cube_key: this.currentCube,
        version_key: this.currentVersion,
        dimensions: this.dimensionsToDownload,
        filters: {
          ...this.filtersValues,
          ...additionalFilters,
        },
      }

      this.loading = true

      try {
        const url = await GetCubeDownloadLink(payload)

        this.downloadingLoaded = false
        const link = document.createElement('a')
        link.setAttribute('href', url.url)
        link.setAttribute('download', url.url)
        link.click()
      } finally {
        this.loading = false
      }
    },
    async handleCubesDataFileInputChange (event) {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append('data', file)

      this.loading = true

      try {
        await UploadDataFile(this.currentCube, this.currentVersion, formData)
        await this.comparisonDebounce()
      } catch (error) {
        this.uploadSkips = Object.freeze(error.response.data.skips)
        this.skipsModalVisible = true
      } finally {
        this.loading = false
      }
    },

    getActionPayload (valueRef, valueBdg, metric, additionalFilters) {
      this.currentAction = this.actions.find(a => a.value.action === 'set_value').value
      let payload = {
        cube_key: this.currentCube,
        version_key: this.currentVersion,
        action: this.currentAction.action,
      }
      const totals = this.views[0].total
      const shareRef = totals[metric + ' (bdg)'] || valueRef
      const filters = {}
      additionalFilters?.forEach(item=> {
        if(item.name === 'week_numbers'){
          filters['week_min'] = item.value
          filters['week_max'] = item.value
        }else {
          filters[item.name] = [item.value]
        }
      })
      const sidePanelFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}
      payload = {
        // eslint-disable-next-line
          ...payload,
        config: {
          variable: metric,
          value: parseFloat(valueBdg),
          share: round(valueBdg / shareRef, 4),
          scale_prices: this.scalePrices,
          set_share: this.setShares,
          offset_sales: this.offsetSales
        },
        parent_filters: {
          ...this.filtersValues,
          ...sidePanelFilters,
        },
        filters: {
          ...sidePanelFilters,
          ...this.filtersValues,
          store_keys: [...(this.filtersValues.store_keys || [])],
          week_min: this.filtersValues.week_min,
          week_max: this.filtersValues.week_max,
          ...filters,
        },
      }
      return payload
    },
    async applyForLater (valueRef, valueBdg, metric, additionalFilters) {
      const payload = this.getActionPayload(valueRef, valueBdg, metric, additionalFilters)
      this.loading = true
      try {
        this.onActionApplyLater(payload)
      } catch (e) {
        console.error(e)
      }

      this.loading = false
    },
    fillModalWithIndexBelief ({ row, column, activeTab, isTotalRow }, directUpdate) {
      const metric = column.property.substring(0, column.property.length - 6)
      const valueRef = row[`${metric} (ref)`]
      const valueBdg = row[`${metric} (bdg)`]
      let additionalFilters = []

      this.addActionModalVisible = !directUpdate
      this.$nextTick(() => {
        if (!isTotalRow) {
          if (activeTab.isCustom) {
            let dimensionsToFilters = activeTab.dimensions.slice()

            if (row.isChild) {
              dimensionsToFilters = [
                ...dimensionsToFilters,
                ...activeTab.dimensionsChilds,
              ]
            }

            dimensionsToFilters.forEach(d => {
              additionalFilters.push({
                name: d,
                value: row[d]
              })
            })
          } else {
            additionalFilters = [{
              name: activeTab.name,
              value: row[activeTab.name]
            }]
          }
        }
        if (directUpdate){
          this.applyForLater(valueRef, valueBdg, metric, additionalFilters)
        } else {
          this.$refs.addActionModal.fillIndexBeliefFromExternal(valueRef, valueBdg, metric, additionalFilters)
        }
      })
    },
    loadComparison () {
      if (!this.currentMetrics.length) {
        return
      }

      const dimensions = this.filtersDimensionsWithoutStoreDimensions.slice()
      const payloadViews = dimensions.map(d => ({
        'dimensions': [d.name],
        'metrics': this.currentMetrics,
        'index': true,
      }))

      this.$refs.dimensionsTabs.customTabs.forEach(tab => {
        if (!tab.dimensions.length) {
          return
        }

        const view = {
          'dimensions': tab.dimensions,
          'metrics': this.currentMetrics,
          'index': true,
        }

        if (tab.dimensionsChilds?.length) {
          view.childs = tab.dimensionsChilds
        }

        payloadViews.push(view)
      })

      const additionalFilters = JSON.parse(localStorage.getItem('strategySideFilters')) || {}
      const payload = {
        'cube_key': this.currentCube,
        'version_key': this.currentVersion,
        'reference_years': this.referenceYears || [],
        'lfl_method': this.lflMethod,
        'no_stores': this.noStores,
        'views': payloadViews,
        'filters': {
          ...this.filtersValues,
          ...additionalFilters,
        },
      }
      this.loading = true

      // cancel previous request if any

      LoadComparison(payload)
        .then(({ views, kpis, logs }) => {
          views[0].data = views[0].data.map(row => {
            row['store_names'] = this.storeKeysToNames.get(row['store_keys'])
            return row
          })
          this.views = Object.freeze(views)
          this.logs = Object.freeze(logs.reverse())
          this.kpis = {
            revenue: kpis.revenue,
            sales_net: kpis.sales_net,
            original_price: kpis.original_price,
            discount: kpis.discount,
            actual_price: kpis.actual_price,
            margin: kpis.margin,
            margin_value: kpis.margin_value,
          }
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false
        })
    },
    setCubeInputData () {
      const { reference_years, properties } = this.cubesStore.cubeInputData

      this.referenceYearList = reference_years
      this.cubeProperties = properties
        .map(p => p.name)
        .filter(p => p.includes('/'))
    },
    setCubeData () {
      const { cube_keys, version_keys, actions, metrics } = this.cubesStore.cubesBrowser
      this.cubeList = cube_keys
      this.actions = actions
        .filter(action => ['set_value', 'new_version', 'merge_version', 'match_prices', 'add_dimension', 'remove_dimensions', 'sync_cube'].includes(action.action))
        .map(action => ({
          text: formatString(action.action),
          value: action,
        }))
      this.metricList = metrics
      this.versionList = version_keys
    },
    formatLog (log) {
      return `${log.user_name} has taken action ${log.action}`
    },
    formatActionToApply (log) {
      return `${log.user_name} is going to apply action ${log.action}`
    },
    formatActionToApplyTime (log) {
      const time = formatDistance(
        log.timestamp,
        new Date(),
        { includeSeconds: true }
      )

      return `${time} ago`
    },
    onAddActionClick () {
      this.addActionModalVisible = true
    },
    onActionApply () {
      this.comparisonDebounce()
    },
    onActionApplyLater (actionPayload) {
      const userId = parseInt(localStorage.getItem('user_id'))
      const user = this.appConfig?.users.find(u => u.id === userId)

      actionPayload.timestamp = new Date()
      actionPayload.user_name = `${user.first_name} ${user.last_name}`

      this.actionsToApply.unshift(actionPayload)
    },
    formatString,
    pretty (log) {
      const { filters, ...result } = { ...log }
      return JSON.stringify(result, null, 2)
    },
    async handleApplyActionsClick () {
      if(!this.actionsToApply?.length){
        return
      }
      this.loading = true
      const payload = {
        cube_key: this.$refs.cubeSelect?.currentCube,
        version_key: this.$refs.cubeSelect?.currentVersion,
        actions: this.actionsToApply.reverse(),
      }

      try {
        await PerformAction(payload)

        this.loadComparison()
        this.actionsToApply = []
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
#strategy-budget-cubes {
    .settings-menu,
    .download-menu {
      text-align: initial;
      .input-parameter {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid var(--border-color);
          padding: 0 16px;
          .parameter-name {
              width: 200px;
          }
          .parameter-value {
              width: 250px;
              padding: 8px 0;
          }
          .slider {
              padding-top: 4px;
              .slider-value-from,
              .slider-value-to {
                  color: #666;
                  font-size: 14px;
              }
          }
      }
    }
    .kpi-tiles {
      .kpi-tile {
        background-color: white;
        height: 130px;
        flex-basis: 170px;
        display: inline-block;
        .tile-title {
          font-size: 12px;
          font-weight: 500;
        }
        .tile-value {
          font-size: 16px;
          font-weight: 500;
        }
        .tile-subtitle-lg {
          font-size: 14px;
          font-weight: 500;
        }
        .tile-subtitle {
          font-size: 12px;
          font-weight: 500;
        }
        .increase {
          color: #4caf50;
        }
        .decrease {
          color: #f44336;
        }
      }
    }
    @media (min-width: 1600px) {
      .kpi-tiles {
        .kpi-tile {
          flex-basis: 190px!important;
        }
      }
    }
    @media (min-width: 1900px) {
      .kpi-tiles {
        .kpi-tile {
          flex-basis: 220px!important;
        }
      }
    }
    .v-text-field__prefix {
      font-weight: bold!important;
    }
  .info-icon {
    position: fixed;
    bottom: 36px;
    right: 36px;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
    z-index: 4;
  }
  #for-tooltip {
    position: fixed;
    bottom: 172px;
    right: 172px;
  }
}
</style>
