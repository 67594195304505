import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'
import {User} from '@/models/User'

interface BuySettingsPayload {
  buy_settings: {
    anchor_points: number[],
    order_points: number[],
  }
}
export async function saveBuySettings (payload: BuySettingsPayload) {
  const url = baseURLs.manageAPI + '/buy_settings'
  try {
    const response = await axios.post(url, payload)
    return response.data
  } catch (error) {
    console.error('Error saving buy settings', error)
  }
}

export async function saveUser (payload: User)  {
  const url = baseURLs.manageAPI + (payload.id ? '/user' : '/invite')
  const response = await axios.post(url, payload)
  return response.data?.msg?.user || payload
}

export async function changeRole (payload: User) {
  const url = baseURLs.manageAPI + '/change_role'
  await axios.post(url, payload)
}

export async function deleteUser (email: string) {
  const url = baseURLs.manageAPI + '/user'
  await axios.delete(url, { data: { email }})
}

interface UserActivityData {
  keys: string[],
  data: {
		date: string,
		user_id: number|null,
		events: number,
		last_login: string|null,
		first_page_view: string|null,
		last_page_view: string|null,
		days_online: number|null,
		events_total: number|null,
  }[]
}


export async function getUserActivity (from_date: string, user_id: number, module: 'all'|string): Promise<UserActivityData> {
  const url = baseURLs.manageAPI + '/data/general/activity/user_activity'
  const response = await axios.get(url, { params: { from_date, user_id, module }})
  return response.data as UserActivityData
}

interface ModuleActivityData {
  keys: string[],
  data: {
    date: string,
    module: string|null,
    events: number,
  }[]
}

export async function getModuleActivity (from_date: string, user_id: number, module: 'all'|string): Promise<ModuleActivityData> {
  const url = baseURLs.manageAPI + '/data/general/activity/module_activity'
  const response = await axios.get(url, { params: { from_date, user_id, module }})
  return response.data as ModuleActivityData
}
