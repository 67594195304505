
<script setup lang="ts">
// Inspired by vue-avatar
// Take in the first and last name
import {computed} from 'vue'
import {GetAvatarColor, LightenDarkenColor} from '@/libs/colors'

const name = defineProps({
  firstName: {
    type: String,
    default: ''
  },
  lastName: {
    type: String,
    default: ''
  }
})
const backgroundColor = computed(() => {
  return GetAvatarColor(name.firstName, name.lastName)
})
const fontColor = computed(() => {
  return LightenDarkenColor(backgroundColor.value, 100)
})

const colorStyle = computed(() => {
  return {
    backgroundColor: backgroundColor.value,
    color: fontColor.value
  }
})

const initials = computed(() => {
  return `${name.firstName.charAt(0)}${name.lastName.charAt(0)}`.toUpperCase()
})
</script>

<template>
  <div class="avatar-container" :style="colorStyle">
    <span>{{ initials }} </span>
  </div>
</template>

<style scoped lang="scss">
.avatar-container {
  display: inline-flex !important;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font: 14px / 35px Helvetica, Arial, sans-serif;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  background-color: rgb(255, 87, 34);
  color: rgb(255, 187, 134);
}
</style>
