<template>
  <div class="table-done">
    <v-flex class="justify-space-between d-flex pa-4 align-center">
      <div></div>
      <div>
        <v-btn @click="$emit('saveClick')" icon>
          <v-icon>save</v-icon>
        </v-btn>
      </div>
    </v-flex>
    <el-table
      v-loading="!doneData"
      :data="tableData"
      empty-text="Loading transfers"
      :span-method="objectSpanMethod"
    >
      <el-table-column prop='Option' label="Option" min-width="180">
        <template slot-scope="scope">
          <div class="d-flex  align-center">
            <img
              class="image-modal-responsive pr-4 image"
              :modal="scope.row['sku_key'].toString()"
              :src="scope.row['image'] || '@/assets/img/ts_placeholder.png'"
              @error="loadPlaceholder"
              height=""
              width='40%'
              style="max-width: 120px"
              @click="openImage(scope.row)">
            <v-dialog>
              <div>
                <img class="image-modal-responsive" :src="scope.row['image']" >
              </div>
            </v-dialog>
            <div
              class="d-flex align-center">
              <div class="d-flex flex-column align-start justify-start">
                <div style="font-weight: 500">{{scope.row['option'].split(' / ')[0]}}</div>
                <div style="font-size: 12px">{{scope.row['option'].split(' / ')[1]}}</div>
                <div
                  class="d-flex flex-column">
                  <span class="pb-1"
                    style=" color: var(--text-black)"
                  >{{scope.row['price']}}</span>

                  <v-menu
                    top
                    offset-x
                    min-width="200"
                    max-width="200"
                    content-class="option-info-menu"
                    :close-on-content-click="false"
                    v-model="optionInfoVisible[scope.row.option_key]"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row class="ma-0">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="handleOptionInfoClicked(scope.row)"
                        >info</v-icon>
                      </v-row>
                    </template>

                    <v-card class="py-2 px-4 p-relative">
                      <v-overlay absolute color="white"
                        :value="loadingOptionInfo"
                        :key="scope.row.option_key">

                        <v-progress-circular color="primary" indeterminate size="64" width="2" />
                      </v-overlay>
                      <div v-for="(value, key) in optionInfo[scope.row.option_key]" :key="key" class="text-body-2 my-1">
                        <b>{{ key }}:</b> {{ value }}
                      </div>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="col in tableHeaders"
        :key="col"
        :prop="col"
        :label="col | formatString"
        :formatter="formatter"
        :min-width="col !== 'transfer' ?55: 70">
      </el-table-column>
      <el-table-column prop='picked' label="Picked">
        <template slot-scope="scope">
          <v-select
            class="pb-1"
            v-model="scope.row.picked"
            dense
            hide-details
            @change="pickedChanged(scope.row)"
            :items="getTransferOptions(scope.row)">
          </v-select>
        </template>
      </el-table-column>
      <el-table-column prop='done' label="Done" align="center" width="50">
        <template slot-scope="scope">
          <v-checkbox
            class="mt-0"
            v-model="scope.row.done"
            @change="doneClicked(scope.row)"
            :ripple="null"
            hide-details></v-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap d-flex align-center px-4 justify-end" v-if="totalPages > 1">
      <el-pagination
        :page-count="totalPages"
        :page-size="2"
        class='pagination text-right pt-6 pb-4 mr-2 font'
        @current-change="setCurrentPage">
      </el-pagination>
    </div>
    <v-dialog v-model="modalImage.opened" max-width="500">
      <v-card class="d-flex flex-column px-8 py-3">
        <v-row class="d-flex justify-space-between font-weight-bold py-4 align-center">
          <h3>{{modalImage.title}}</h3>
          <v-btn icon>
            <v-icon @click="modalImage.opened = false">close</v-icon>
          </v-btn>
        </v-row>
        <v-row class="justify-center">
          <img class="image-modal-responsive" :src="modalImage.src">
        </v-row>
        <v-row class="py-6 justify-end">
          <v-btn @click="modalImage.opened = false">okay</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getHeadersWithAccessToken } from '@/variables'
import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'

import ts_placeholder from '@/assets/img/ts_placeholder.png'
import { useStorepageStore } from '@/store/pinia/storePageStore'

export default {
  name: 'StorePageTransfersTable',
  props: {
    doneData: {
      default: null,
    },
    width: {
      default: 0,
    }
  },
  setup () {
    return {
      storepageStore: useStorepageStore()
    }
  },
  data () {
    return {
      currentPage: 1,
      optionInfoVisible: {},
      loadingOptionInfo: false,
      optionInfo: {},
      modalImage: {
        opened: false,
        src: ''
      },
    }
  },
  computed: {
    totalPages () {
      return Math.max(...this.doneData.map(x => x.page))
    },
    tableData () {
      if (this.doneData.length) {
        const d = []
        for (const item of this.doneData) {
          if (item.page === this.currentPage) {
            d.push(item)
          }
        }
        return d
      } else {
        return []
      }
    },
    tableHeaders () {
      let h = this.storepageStore.skuTransfersTableHeaders
      h = h.filter((header) => header !== 'price' && header !== 'option_key')
      if (this.width < 480) {
        // Remove stock col in small devices.
        h = h.filter(header => header !== 'stock')
      }
      return h
    },
  },
  methods: {
    formatter (row, column) {
      return row[column.property]
    },
    getTransferOptions (row) {
      return [...Array(row.stock + 1).keys()].map((d, i) => ({
        text: `${i}`,
        value: d
      }))
    },
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    setCurrentPage (value) {
      this.currentPage = value
    },
    objectSpanMethod ({ row, columnIndex }) {
      if (columnIndex === 0) {
        if (row['count'] !== undefined) { // This means it is the first row of a new product
          return {
            rowspan: row['count'], // Set rowspan equal to number of rows coming with this product
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    handleOptionInfoClicked (row) {
      const url = baseURLs.storepageAPI + '/option_info?option_key=' + row.option_key

      this.loadingOptionInfo = true
      this.optionInfoVisible[row.option_key] = true
      return axios.get(url, {
        headers: getHeadersWithAccessToken()
      })
        .then(response => {
          this.optionInfo[row.option_key] = response.data.data[0].properties
          this.loadingOptionInfo = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    doneClicked (row) {
      if (row.done === false) {
        row['picked'] = null
        this.$emit('resetRow', row)
      } else {
        this.$emit('pickedChanged', row)
      }
    },
    openImage (row) {
      this.modalImage.src = row.image
      this.modalImage.opened = true
      this.modalImage.title = row.option
    },
    pickedChanged (row) {
      if (closed === false) {
        this.$emit('pickedChanged', row)
      }
    }
  }
}
</script>

<style lang="scss">
.table-done {

  .el-pagination .el-pager li, .el-pagination button .el-icon, .pagination-total{
    font-size: 18px;
  }
  .pagination-total {
    color: rgb(96, 98, 102)
  }

  .image {
    max-width: 120px;
  }
  .done-title {
    color: #838383;
  }

  .el-table__body-wrapper.is-scrolling-left {
    z-index: 1 !important;
  }
}
</style>
