import axios from '@/services/axios.js'
import baseURLs from '@/api/APIBaseURLs'
import {
  BuildData,
  BuildTotal,
  ChildsFreeItem,
  Free,
  TotalsFree,
  TransferBuildDetail,
  TransferDetail,
  TransfersBuildParams
} from '@/models/Transfers'

interface transfersListResponse {
  data: unknown;
  parent_table: unknown;
}
export async function getTransfersList (t: string, params: unknown, optimized: boolean): Promise<transfersListResponse> {
  const typeUrls: Record<string, string> = {
    combinations: '',
    send: '_send',
    receive: '_receive',
  }
  let url = baseURLs.manageAPI + '/data/stock/transfers/'
  if (t === 'accepted') {
    url = baseURLs.tsqlAPI + '/transfers_accepted'
  } else {
    url += (optimized ? `optimized${typeUrls[t]}_agg` : `profits${typeUrls[t]}_agg`)
  }
  return post<transfersListResponse>(url, params)
}

interface transfersFreeResponse {
  data: Free[];
  total: TotalsFree;
}
export async function getTransfersFree (accept: boolean, params: object): Promise<transfersFreeResponse> {
  const url = baseURLs.tsqlAPI + '/transfers_free'
  let response = await post<transfersFreeResponse|string>(url, { accept, ...params })
  if (typeof response === 'string') {
    response = JSON.parse(response.replace('NaN', 'null')) as transfersFreeResponse
  }
  return response
}

export async function getOptionTransfersFree (payload: object): Promise<transfersFreeResponse> {
  const url = baseURLs.tsqlAPI + '/transfers_free'
  let response = await post<transfersFreeResponse|string>(url, payload)
  if (typeof response === 'string') {
    response = JSON.parse(response.replace('NaN', 'null')) as transfersFreeResponse
  }
  return response
}

interface transfersFreeChildResponse {
  data: ChildsFreeItem[];
  records: unknown;
  total: {
    sku_names: [],
  }
}

export async function getTransfersFreeChildRows (payload: unknown) : Promise<transfersFreeChildResponse> {
  const url = baseURLs.tsqlAPI + '/transfers_free'
  return post<transfersFreeChildResponse>(url, payload)
}

export async function getTransferDetails (type: string, params: unknown, optimized?: boolean ) {
  let url: string
  if (type === 'accepted') {
    url = baseURLs.tsqlAPI + '/transfers_accepted'
  } else if (type === 'build') {
    url  = baseURLs.manageAPI + '/data/stock/transfers/build'
  } else if (optimized){
    url = baseURLs.manageAPI + '/data/stock/transfers/optimized'
  } else {
    url = baseURLs.manageAPI + '/data/stock/transfers/profits'
  }
  const response = (await post(url, params)) as {child_table: TransferDetail[], data: TransferDetail[]}
  if (type === 'accepted') {
    return response.child_table
  }
  return response.data
}

export async function getTransferBoxDetails (payload: unknown) {
  const url = baseURLs.tsqlAPI + '/transfers_box'
  return (await post(url, payload)) as {data: TransferDetail[]}
}

export async function getTransferDetailsTSQL (payload: unknown) {
  const url = baseURLs.tsqlAPI + '/transfers_build'
  return (await post(url, payload)) as {child_table: TransferBuildDetail[]}
}

function getDeleteQueryString (option_key: string | undefined, store_key: string | undefined, to_store_key: string | undefined, type: string | undefined, isChild: string | undefined) {
  let query = '?'
  if ((!to_store_key && !store_key) || isChild) {
    query += 'option_key=' + option_key
    if(!isChild) {
      query += '&option_view=true'
    }
  } else {
    query += 'option_key=all'
  }
  if (store_key !== 'multi' && store_key) {
    query += '&store_key=' + (store_key || null)
  }
  if (to_store_key !== 'multi' && to_store_key) {
    query += '&to_store_key=' + (to_store_key || null)
  }
  query += '&type=' + type
  return query
}

export async function deleteAcceptedTransfer (option_key: string | undefined, store_key: string | undefined, to_store_key: string | undefined, type: string | undefined, isChild?: string | boolean) {
  const url = baseURLs.manageAPI + '/transfers' + getDeleteQueryString(option_key, store_key, to_store_key, type, isChild)
  return axios.delete(url).then(r => r.data)
}

export interface SaveTransferRequest {
  to_store_key: string;
  store_key: string;
  sku_key: string;
  pieces: number;
  stock: number;
  keep: number;
  type?: string;
  profit: number;
  store_exclusion?: boolean;
  delta?: number;
}

export async function saveTransfers (payload: SaveTransferRequest[]) {
  const url = baseURLs.manageAPI + '/transfers'
  return post(url, payload)
}

interface acceptAllTransfersParams extends TransfersBuildParams {
  accept: boolean;
  store_exclusion: boolean;
}

export async function acceptAllBuiltTransfers (payload: acceptAllTransfersParams) {
  const url = baseURLs.tsqlAPI + '/transfers_build'
  return post(url, payload)
}

interface BuildTransfersResponse {
  total: BuildTotal;
  parent_table: BuildData[];

}
export async function buildTransfers (payload: TransfersBuildParams) : Promise<BuildTransfersResponse> {
  const url = baseURLs.tsqlAPI + '/transfers_build'
  return post<BuildTransfersResponse>(url, payload)
}

export async function saveSettings (payload: unknown) {
  const url = baseURLs.manageAPI + '/transfers_settings'
  return post(url, payload)
}

async function post<R = unknown> (url: string, payload: unknown): Promise<R> {
  return axios.post(url, payload).then(r => r.data)
}
