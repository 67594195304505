<template>
  <v-card min-height="350px" class="mt-6 recommendations">
    <h4 class="px-4 py-4">Recommendations</h4>
    <v-col cols="10" style="margin-right: -100px; padding-right: 100px" class="pt-0">
      <v-col cols="3" class="py-0 pa-0">
        <collapsible-select
          :items="dimensions"
          item-text="text"
          :filter="headersFilters"
          v-model="chosenDimension"
          autocomplete
          hide-details
          @input="saveToStorage"
        />
      </v-col>
    </v-col>
    <v-tabs v-model="activeTab">
      <v-tabs-slider />
      <v-tab v-for="item of tabs" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab">
      <v-tab-item :transition="false" :reverse-transition="false" key="Missed on DC">
        <RecommendationsTableDC
          :chosen-filters-list="chosenFiltersList"
          :chosen-filters="chosenFilter"
          @filters-updated="chooseFilter"
          :chosen-dimension="chosenDimension"></RecommendationsTableDC>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false" key="Missed Fairshare">
        <RecommendationsTableFairshare
          :chosen-filters-list="chosenFiltersList"
          :chosen-filters="chosenFilter"
          @filters-updated="chooseFilter"
          :chosen-dimension="chosenDimension"></RecommendationsTableFairshare>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false" key="High cover">
        <RecommendationsTableHighCover
          :chosen-filters-list="chosenFiltersList"
          :chosen-filters="chosenFilter"
          @filters-updated="chooseFilter"
          :chosen-dimension="chosenDimension"></RecommendationsTableHighCover>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false" key="Low stock">
        <RecommendationsTableLowCover
          :chosen-filters-list="chosenFiltersList"
          :chosen-filters="chosenFilter"
          @filters-updated="chooseFilter"
          :chosen-dimension="chosenDimension"></RecommendationsTableLowCover>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false" key="High sales">
        <RecommendationsTableHighSales
          :chosen-filters-list="chosenFiltersList"
          :chosen-filters="chosenFilter"
          @filters-updated="chooseFilter"
          :chosen-dimension="chosenDimension"></RecommendationsTableHighSales>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false" key="Low sales">
        <RecommendationsTableLowSales
          :chosen-filters-list="chosenFiltersList"
          :chosen-filters="chosenFilter"
          @filters-updated="chooseFilter"
          :chosen-dimension="chosenDimension"></RecommendationsTableLowSales>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import RecommendationsTableDC from './RecommendationsTableDC'
import { formatString } from '@/variables'
import { getDatasets } from '@/api/analytics'
import RecommendationsTableFairshare from './RecommendationsTableFairshare'
import RecommendationsTableHighCover from './RecommendationsTableHighCover'
import RecommendationsTableLowCover from './RecommendationsTableLowCover'
import RecommendationsTableLowSales from './RecommendationsTableLowSales'
import CollapsibleSelect from '../CollapsibleSelect'
import RecommendationsTableHighSales from './RecommendationsTableHighSales'
import { headersFilters } from '@/utils'
import { useAnalyticsFiltersStore } from '@/store/pinia/analyticsFiltersStore'

export default {
  setup () {
    return {
      analyticsFiltersStore: useAnalyticsFiltersStore()
    }
  },
  data () {
    return {
      activeTab: 0,
      chosenFilter: null,
      chosenFiltersList: null,
      chosenDimension: ['properties/article/group']
    }
  },
  computed: {
    datasets () {
      return this.analyticsFiltersStore.datasetsList
    },
    dimensions () {
      const dimensions = {}
      if (!this.datasets['stock_performance']) {
        return []
      }
      const dimensionsList = this.datasets['stock_performance'].dimensions
      dimensionsList.forEach((item) => {
        const data = item.split('/')
        const header = { header: formatString(data[1] || ''), text: [] }
        if (!dimensions[data[1]]) {
          dimensions[data[1]] = [header]
        }
        const text = data[2] === 'name' ? `Name (${formatString(data[1])})` : formatString(data[2] || '')
        dimensions[data[1]].push({ text, value: item, headerTitle: header.header })
        dimensions[data[1]][0].text.push(formatString(data[2] || ''), formatString(data[1] || ''))
      })
      const data = []
      for (const key in dimensions) {
        data.push(...dimensions[key])
      }
      return data
    },
    tabs () {
      return [ 'Missed on DC', 'Missed Fairshare', 'High cover', 'Low stock', 'High sales', 'Low sales' ]
    },
  },
  methods: {
    headersFilters,
    saveToStorage (data) {
      localStorage.setItem('Recommendations_Dimensions', JSON.stringify(data))
    },
    chooseFilter (filters) {
      this.chosenFiltersList = filters
      const filter = Object.keys(filters)[0]
      this.chosenFilter = filters[filter]
    }
  },
  created () {
    if (!Object.keys(this.analyticsFiltersStore.datasetsList).length) {
      getDatasets().then((data) => {
        this.analyticsFiltersStore.setDatasetsList(data)
      })
    }
    const chosenDimension = JSON.parse(localStorage.getItem('Recommendations_Dimensions'))
    if (chosenDimension && chosenDimension.length) {
      this.chosenDimension = chosenDimension
    }
  },
  components: {
    RecommendationsTableHighSales,
    CollapsibleSelect,
    RecommendationsTableLowSales,
    RecommendationsTableLowCover,
    RecommendationsTableHighCover,
    RecommendationsTableFairshare,
    RecommendationsTableDC,
  },
}
</script>

<style lang="scss">
.recommendations {
    .v-tab {
        width: 200px;
    }
}
</style>
