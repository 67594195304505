<template>
  <v-app-bar class="top-bar store-page-nav__bar d-print-none" height="56px" fixed>
    <v-overlay
      absolute
      class="overlay"
      :value="menuOpened  || searchOpened"
      @click.native="overlayClick"
    ></v-overlay>
    <div class="logo" @click="$router.push({ name: 'storepage-home' })">
      <logo class="icon"/>
    </div >
    <v-divider class="mt-3 mr-5 ml-4 py-3 divider" vertical></v-divider>

    <h3>Thunderstock</h3>

    <v-spacer></v-spacer>
    <v-menu
      v-model="menuOpened"
      class="menu"
      offset-y
      nudge-bottom="15"
    >
      <template v-slot:activator="{ on }">
        <div class="menu-activator mr-5" :class="{ 'active': menuOpened }">
          <v-icon v-on="on" class="ml-6" @click="expandClick">
            menu</v-icon>
        </div>
      </template>

      <v-list class="menu-list" two-line subheader min-width="280" elevation="0">
        <v-list-item :to="{ name: 'storepage-home' }" :class="{ 'active': currentModule === 'stock' }" @click="itemClick">
          Home
        </v-list-item>
        <v-list-item :to="{ name: 'storepage-overview' }" :class="{ 'active': currentModule !== 'stock' }" @click="itemClick">
          Overview
        </v-list-item>
        <v-list-item :to="{ name: 'storepage-transfers' }" :class="{ 'active': currentModule !== 'stock' }" @click="itemClick">
          Transfers
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script >
import Logo from './Logo'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'StoreNavBar',
  data: function () {
    return {
      menuOpened: false,
      searchOpened: false,
      generalStore: useGeneralStore()
    }
  },
  computed: {
    currentModule () {
      return this.generalStore.currentModule
    },
  },
  methods: {
    overlayClick () {
      this.hideOverlay()
    },
    itemClick () {
      this.hideOverlay()
    },
    hideOverlay () {
      this.menuOpened = false
    },
    expandClick () {
      this.menuOpened = !this.menuOpened
    },
  },
  components: {
    Logo,
  },
}
</script>

<style lang="scss">
.top-bar {
  box-shadow: var(--box-shadow) !important;
  background-color: #fff !important;
  .overlay {
    position: absolute;
    height: calc(100vh / var(--zoom));
    margin-top: 56px;
  }
  .divider {
    min-height: 0% !important;
    max-height: 0% !important;
  }
  .logo {
    cursor: pointer;
  }
}

.module-selector {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 0px 0px rgba(0, 0, 0, 0.2) !important;
  left: 0px !important;
  border-radius: 0px !important;
  .menu-list {
    padding-bottom: 0;
    box-shadow: inset 0px 2px 2px #ccc !important;
    .v-list-item {
      display: flex;
      justify-content: center;
      color: var(--text-black);
      font-size: 20px;
      font-weight: 500;
      &:first-child {
        border-bottom: 1px solid var(--border-color);
      }
      &:hover {
        &:before {
          opacity: 0;
        }
      }
    }
    .v-list-item.active {
      color: var(--primary);
      &:before {
        opacity: 0;
      }
    }
  }
}
</style>
