<template>
  <div class="dummy-price">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <div class="pa-4">
      <v-row>
        <v-col :cols="6">
          <h4 class="mt-2 mb-6">Prices</h4>
          <template v-if="currencies">
            <v-row v-for="(price, index) in selectedPrices" :key="index">
              <v-col :cols="5">
                <v-select
                  v-model="price.currency"
                  :items="getAvailableCurrencies(price)"
                  label="Currency"
                  hide-details
                  :menu-props="{
                    left: true,
                    offsetY: true,
                    contentClass: 'select-dropdown-menu',
                  }"/>
              </v-col>
              <v-col :cols="5">
                <v-text-field
                  v-if="price.currency"
                  v-model="price.value"
                  clearable
                  label="Price"
                  hide-details="auto"
                  @input="handlePriceInput(index)"
                  @change="handlePriceValueChange(index)" />
              </v-col>
              <v-col v-if="price.value" :cols="2">
                <v-icon
                  class="mt-5 action-btn-danger"
                  @click="handlePriceDeleteClick(index)"
                >delete</v-icon>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col>
              <v-icon class="plus-icon" @click="handleAddPriceClick">add</v-icon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div class="pa-4">
      <h4 class="mt-2 mb-6">Historical RoS vs. Price</h4>
      <div class="px-12">
        <HistoricalRoSPriceChart
          :chart-data="historicalChartData"
          :ros="ros"
          :default-currency-price="defaultCurrencyPrice"
        />
      </div>
    </div>
    <div class="pa-4" v-if="false">
      <h4 class="mt-2 mb-6">Price RoS impact</h4>
      <div class="px-12">
        <PriceRoSImpactChart
          :chart-data="impactChartData"
          :ros="ros"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as dummiesApi from '@/api/dummies'
import * as propertiesApi from '@/api/properties'
import HistoricalRoSPriceChart from './HistoricalRoSPriceChart.vue'
import PriceRoSImpactChart from './PriceRoSImpactChart.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  components: { HistoricalRoSPriceChart, PriceRoSImpactChart },
  props: {
    dummy: {
      type: Object,
      default: () => ({}),
    },
    properties: {
      type: Object,
      default: () => ({}),
    },
    ros: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      loading: false,
      selectedPrices: [],
      historicalChartData: null,
      impactChartData: null,
      defaultCurrency: localStorage.getItem('defaultCurrency'),
      defaultCurrencyPrice: null,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    currencies () {
      return this.appConfig?.currencies || []
    },
  },
  watch: {
    dummy: {
      handler (val) {
        if (!val) {
          return
        }

        this.selectedPrices = Object.keys(this.dummy.prices).reduce(
          (acc, cur) => {
            return [ ...acc, {
              currency: cur,
              value: this.dummy.prices[cur]
            }]
          },
          []
        )
        this.defaultCurrencyPrice = this.dummy.prices[this.defaultCurrency] || null
        this.loadChartData()
      },
      immediate: true,
    },
  },
  methods: {
    getAvailableCurrencies (selectedPrice) {
      const available = []

      if (selectedPrice.currency) {
        available.push({
          text: selectedPrice.currency,
          value: selectedPrice.currency,
        })
      }

      return [
        ...available,
        ...this.currencies
          .filter(c => !this.selectedPrices.map(sp => sp.currency).includes(c.iso_code))
          .map(c => ({
            value: c.iso_code,
            text: c.iso_code,
          })),
      ]
    },
    loadChartData () {
      return propertiesApi
        .getPricesChartData({
          filters: this.dummy.properties,
          lookalikes: this.dummy.lookalike_option_keys,
        })
        .then(data => {
          this.historicalChartData = data.scatter
          this.impactChartData = data.buckets
        })
    },
    saveDummy () {
      let payload = {
        ...this.dummy,
        prices: {
          ...this.selectedPrices.reduce(
            (acc, cur) => ({ ...acc, [cur.currency]: cur.value || 0 }),
            {}
          ),
        },
      }

      payload = {
        ...payload,
      }

      this.loading = true

      return dummiesApi
        .saveDummy(payload)
        .finally(() => {
          this.loading = false
        })
    },
    handleAddPriceClick () {
      if (!this.selectedPrices.length) {
        this.selectedPrices.push({
          currency: this.defaultCurrency,
          value: null
        })
        return
      }
      this.selectedPrices.push({
        name: null,
        value: null,
      })
    },
    handlePriceValueChange () {
      this.loading = true
      this
        .saveDummy()
        .then(updatedDummy => {
          this.$emit('dummy-saved', updatedDummy)
        })
    },
    handlePriceDeleteClick (index) {
      this.selectedPrices.splice(index, 1)
      this
        .saveDummy()
        .then(updatedDummy => {
          this.$emit('dummy-saved', updatedDummy)
        })
    },
    handlePriceInput (index) {
      this.$nextTick(() => {
        this.selectedPrices[index].value = this.selectedPrices[index].value.replace(',', '.')
      })
    },
  },
}
</script>

<style lang="scss">
.dummy-price {
}
</style>
