<template>
  <div class="dummy-info" id="dummyInfo">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-card class="pa-4 pt-0 dummy-card" flat>
      <div v-if="dummy" class="mb-0 dummy-title">
        <router-link
          class="dummy-name"
          target="_blank"
          :to="getDummyPageUrl(dummy.dummy_key)"
        >
          <template v-if="nameParts.length === 2">
            <span class="dummy-title">{{ nameParts[0] }} <span style="font-size: 11px" class="error--text ml-1" v-if="dummy?.status === 'in progress'"> Incomplete</span>
            </span>

            <span class="dummy-subtitle">
              {{ nameParts[1] }}
            </span>
          </template>
          <template v-else>
            <span class="dummy-title">{{ nameParts[0] }} <span style="font-size: 11px"  class="error--text ml-1" v-if="dummy?.status === 'in progress'"> Incomplete</span>
            </span>

          </template>
          <template>
            <p class="dummy-prices mb-0"> {{$auth.getUserCurrency().symbol}} {{ dummy.prices[$auth.getUserCurrency().currency] }}</p>
          </template>

        </router-link>
        <div class="d-flex align-baseline">
          <v-icon v-if="dummy.in_use" class="mr-3 error--text">warning</v-icon>
          <v-menu
            bottom
            offset-y
            content-class="option-info-menu"
            attach="#dummyInfo"
            min-width="450"
            max-width="450"
            nudge-bottom="20"
            :open-delay="200"
            :close-on-content-click="false"
            v-model="menuOpened"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :disabled="!getProperties()"
                class="mr-3 icon-highlight-on-focus"
                :class="{ active: menuOpened }"
                v-bind="attrs"
                v-on="on"
              >insert_link</v-icon>
            </template>
            <v-card class="pb-3">
              <h4 class="ml-4 mb-6 pt-6 edit-parameters-title">
                External
              </h4>
              <v-row class="align-center mx-1">
                <v-col cols="6">
                  <span>
                    Variation key
                  </span>
                </v-col>
                <v-col>
                  <v-text-field
                    v-if="variant"
                    class="mt-0 pt-0"
                    :value="variant.variation_key"
                    @input="variant.variation_key = $event ? $event : null"
                    :disabled="variantIsDisabled"
                    :readonly="variantIsDisabled"
                    :class="{'v-input--is-disabled': variantIsDisabled}"
                    hide-details
                    @change="saveVariant">
                    <v-icon class="p-absolute lock-icon" slot="append"
                      @click="variantIsDisabled = false"
                    >lock</v-icon>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="align-center mx-1">
                <v-col cols="6">
                  <span>
                    Option key
                  </span>
                  <v-menu
                    top
                    open-on-hover
                    offset-x
                    min-width="300"
                    max-width="500"
                    content-class="option-info-menu"
                    :open-delay="200"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-2 icon-has-action c-pointer"
                        v-bind="attrs"
                        v-on="on"
                      >info</v-icon>
                    </template>
                    <v-card
                      class="py-2 px-4 p-relative">
                      <b>Option key:</b><br>Used for all communication to external systems, not used for any calculations.
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-text-field
                    class="mt-0 pt-0"
                    :value="dummy.option_key"
                    @input="dummy.option_key = $event ? $event : null"
                    hide-details
                    :readonly="dummyDisabled"
                    :disabled="dummyDisabled"
                    @change="saveDummy"
                    :class="{'v-input--is-disabled': dummyDisabled}"
                  >
                    <v-icon class="p-absolute lock-icon" slot="append"
                      @click="dummyDisabled = false"
                    >lock</v-icon>
                  </v-text-field>
                </v-col>
              </v-row>
              <h4 class="ml-4 mb-6 mt-8">
                Linked pages
              </h4>
              <v-row class="align-center m-0 p-0 linked-pages-list">
                <v-col
                  v-for="page in linkedPages"
                  :key="page.name"
                  :cols="4"
                  class="px-0 py-0 list-item"
                >
                  <div class="py-4 linked-page">
                    <router-link
                      class="linked-page-link"
                      target="_blank"
                      :to="{
                        name: page.name,
                        query: page.query,
                      }">
                      <span v-html="page.text"></span>
                    </router-link>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-menu>

          <v-menu
            top
            open-on-hover
            offset-x
            min-width="200"
            max-width="200"
            content-class="option-info-menu"
            :open-delay="200"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :disabled="!getProperties()"
                class="mr-3 icon-has-action"
                v-bind="attrs"
                v-on="on"
              >info</v-icon>
            </template>
            <v-card
              class="py-2 px-4 p-relative"
              v-html="getProperties()"
            />
          </v-menu>
        </div>
      </div>

      <template v-if="dummy && item">
        <div class="status-row">
          <span :style="{ color: shopItemStatusColorMap[item.status] }">
            {{ item.status | capitalize | formatString }}
          </span>
        </div>

        <div class="image-row">
          <img
            class="my-2"
            height="160px"
            :src="dummy.image_url || '@/assets/img/ts_placeholder.png'"
            @error="loadPlaceholder"
            alt=""/>
        </div>
      </template>
    </v-card>
  </div>
</template>

<script>
import { getVariant, saveDummy, saveVariant } from '@/api/dummies'
import { formatString, shopItemStatusColorMap } from '@/variables.js'
import ts_placeholder from '@/assets/img/ts_placeholder.png'

export default {
  props: {
    dummy: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      shopItemStatusColorMap,
      loading: false,
      variantIsDisabled: true,
      dummyDisabled: true,
      variant: null,
      menuOpened: false,
    }
  },
  computed: {
    nameParts () {
      return this.dummy.name.split(' / ')
    },
    linkedPages () {
      return [{
        name: 'buy-reorder-page',
        text: 'Buy<br/>reorder',
        query: {
          'option_key': this.dummy?.option_key
        }
      }, {
        name: 'stock-option',
        text: 'Stock<br/>option',
        query: {
          'option_key': this.dummy?.option_key
        }
      }, {
        name: 'pricing-markdown-page',
        text: 'Pricing<br/>markdown',
        query: {
          'option_key': this.dummy?.option_key
        }
      }, {
        name: 'buy-studio-item-page',
        text: 'Buy<br/>studio item',
        query: {
          'dummy_key': this.dummy?.dummy_key
        }
      }]
    },
  },
  methods: {
    saveVariant () {
      saveVariant(this.variant)
    },
    saveDummy () {
      saveDummy(this.dummy)
    },
    getProperties () {
      let dataString = ''
      const data = this.dummy['properties']
      for (const key of Object.keys(data).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))) {
        const val =
          '<b>' + formatString(key) + '</b>: ' + (data[key] ? data[key].toLocaleString() : '')
        dataString += val + '<br>'
      }
      return dataString
    },
    getDummyPageUrl (dummyKey) {
      return {
        name: 'buy-studio-item-page',
        query: { dummy_key: dummyKey }
      }
    },
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
  },
  watch: {
    dummy: {
      handler () {
        if (!this.dummy) {
          return
        }
        getVariant(this.dummy.variant_key).then((variant) => {
          this.variant = variant
        })
      },
      immediate: true,
    }
    // getVariant(this.dummy.variant_key).then(console.log)

  }
}
</script>

<style lang="scss">
.dummy-info {
    .dummy-card {
        .dummy-title {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .icon-has-action:hover {
                color: var(--primary);
                cursor: pointer;
            }
        }
        .dummy-name {
            color: unset !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-decoration: none;
            .dummy-title {
                font-weight: 500;
                &:hover {
                    color: var(--primary);
                    cursor: pointer;
                }
            }
            .dummy-subtitle {
                color: var(--text-sub-black) !important;
                font-size: 12px;
            }
        }
        .image-row {
            display: flex;
            position: absolute;
            top: 24px;
            left: 30%;
            justify-content: center;
        }
        .dummy-prices {
            font-size:13px
        }
    }
    .option-info-menu {
        .linked-pages-list {
            .list-item {
                .linked-page {
                    border-top: 1px solid #e3e3e3;
                    border-bottom: 1px solid #e3e3e3;
                    text-align: center;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    position: relative;
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        border-left: 1px solid #e3e3e3;
                    }
                }
                &:last-child {
                    .linked-page {
                        border-top: 0;
                        border-bottom: 0;
                    }
                }
                .linked-page-link {
                    color: #000;
                    text-decoration: none;
                    text-transform: uppercase;
                }
            }
        }
    }
}
</style>

<style scoped lang="scss">

.dummy-link-to-page {
  text-decoration: none;
  color: unset;
  font-weight: 500;
    &:hover, &:hover .icon {
       color: var(--primary);
       cursor: pointer;
     }
}
</style>
