<template>
  <div class="assortment-clusters p-relative">
    <v-overlay absolute color="white" :value="stockManageStore.clustersTab.loadingResults">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <h6 v-if="optionKey" class="text-subtitle-1 font-weight-medium mt-2 ml-4">Clusters for option</h6>
    <h6 v-else class="text-subtitle-1 font-weight-medium mt-8 ml-6">Clusters</h6>
    <v-row>
      <div class="col text-right pr-6">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('clustersTableFilters', columnsToSearch, val)"
        />
        <v-icon class="mx-2 action-btn-primary" :disabled="!selectedRows[0]" @click="handleEditClick">edit</v-icon>
        <v-icon class="mx-2 action-btn-primary" @click="handleAddClick">add</v-icon>
        <v-icon class="mx-2 action-btn-danger" :disabled="!selectedRows[0] || selectedRows[0].in_use" @click="handleDeleteClick">delete</v-icon>
      </div>
    </v-row>
    <v-row>
      <div class="col">
        <data-tables
          style="width: 100%"
          :data="clustersList"
          :page-size="5"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :table-props="{ 'row-key': 'cluster_key' }"
          :filters="clustersTableFilters"
          filter-multiple
          @filter-change="filters => handleFilterChange('clustersTableFilters', filters)"
          @row-click="handleRowClick">
          <el-table-column prop="cluster_key" width="55">
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-4 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="option_set"
            column-key="option_set"
            label="Option set"
            sortable="custom"
            :filters="getColumnFilterValues('clustersList', 'option_set')"
          >
            <template slot-scope="scope">
              <div class="d-flex align-center justify-space-between">
                <div>
                  {{ scope.row.option_set }}
                </div>
                <v-chip
                  label
                  outlined
                  small
                  color="#4FB4AB"
                  class="mr-8 ml-4"
                  @click.stop="handleRowOptionSetClick(scope.row)"
                >
                  {{ scope.row.option_set_count }}
                </v-chip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="store_set"
            column-key="store_set"
            label="Store set"
            sortable="custom"
            :filters="getColumnFilterValues('clustersList', 'store_set')"
          >
            <template slot-scope="scope">
              <div class="d-flex align-center justify-space-between">
                <div>
                  {{ scope.row.store_set }}
                </div>
                <v-chip
                  label
                  outlined
                  small
                  color="#4FB4AB"
                  class="mr-8 ml-4"
                  @click.stop="handleRowStoreSetClick(scope.row)"
                >
                  {{ scope.row.store_set_count }}
                </v-chip>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date_min" label="Start" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.date_min }}
            </template>
          </el-table-column>
          <el-table-column prop="date_max" label="End" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.date_max }}
            </template>
          </el-table-column>
          <el-table-column
            prop="tags"
            column-key="tags"
            label="Tags"
            sortable="custom"
            :filters="getColumnFilterValues('clustersList', 'tags')"
          >
            <template slot-scope="scope">
              <ChipsDropdown :items="scope.row.tags" />
            </template>
          </el-table-column>
        </data-tables>
      </div>
    </v-row>

    <!-- Form dialog -->
    <v-dialog
      :value="formDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="stockManageStore.clustersTab.dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium mx-6 my-2">{{ `${currentCluster && currentCluster.cluster_key ? 'Edit cluster' : 'Add clusters'}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 text--primary">
          <div class="container px-12">
            <template v-if="currentCluster">
              <v-row class="align-baseline mt-4 mb-4">
                <v-col class="py-0">
                  <template v-if="currentCluster.cluster_key">
                    <label class="v-label theme--light mr-4">Option sets</label>
                    <v-chip
                      small
                      class="my-2">
                      {{ clustersList.find(x => x.cluster_key === currentCluster.cluster_key).option_set }}
                    </v-chip>
                  </template>
                  <collapsible-select
                    v-else
                    label="Option sets"
                    v-model="currentCluster.options_set_key"
                    :items="stockManageStore.clustersTab.clustersOptionSetsList"
                    :error-messages="currentClusterOptionSetsError"
                    item-text="set_name"
                    item-value="options_set_key"
                    autocomplete
                    show-select-all
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-4 mb-4">
                <v-col class="py-0">
                  <template v-if="currentCluster.cluster_key">
                    <label class="v-label theme--light mr-4">Store sets</label>
                    <v-chip
                      small
                      class="my-2">
                      {{ clustersList.find(x => x.cluster_key === currentCluster.cluster_key).store_set }}
                    </v-chip>
                  </template>
                  <collapsible-select
                    v-else
                    label="Store sets"
                    v-model="currentCluster.stores_set_key"
                    :items="stockManageStore.clustersTab.clustersStoreSetsList"
                    :error-messages="currentClusterStoreSetsError"
                    item-text="set_name"
                    item-value="stores_set_key"
                    autocomplete
                    show-select-all
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-4 mb-4">
                <v-col class="py-0 pr-8">
                  <v-menu
                    ref="dateMinPicker"
                    v-model="dateMinPickerActive"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="currentCluster.date_min"
                        clearable
                        label="Start"
                        hide-details
                        v-on="on"
                        @click:clear="currentCluster.date_min = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      :locale-first-day-of-year="4"
                      :show-week="true"
                      no-title
                      v-model="currentCluster.date_min"
                      @click="dateMinPickerActive = false"
                    />
                  </v-menu>
                </v-col>
                <v-col class="py-0 pl-8">
                  <v-menu
                    ref="dateMaxPicker"
                    v-model="dateMaxPickerActive"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="currentCluster.date_max"
                        clearable
                        label="End"
                        hide-details
                        v-on="on"
                        @click:clear="currentCluster.date_max = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      :locale-first-day-of-year="4"
                      :show-week="true"
                      no-title
                      v-model="currentCluster.date_max"
                      @input="dateMaxPickerActive = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="align-baseline mt-4 mb-4">
                <v-col class="py-0">
                  <v-combobox
                    v-model="currentCluster.tags"
                    label="Tags"
                    :items="clusterTags.concat(newTags)"
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    hide-details
                    :menu-props="{
                      left: true,
                      offsetY: true,
                      contentClass: 'select-dropdown-menu',
                    }"
                    @input="handleTagChanged"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" small close @click:close="currentCluster.tags.splice(0, 1)">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption mx-1">
                        +{{ currentCluster.tags.length - 1 }}
                      </span>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </template>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    depressed
                    text
                    @click="handleSaveCancel"
                  >Cancel</v-btn>
                  <v-btn
                    color="primary"
                    depressed
                    outlined
                    @click="currentCluster.cluster_key ? handleSaveConfirm() : handleSaveClick()"
                    class="mx-4"
                  >Save</v-btn>
                </v-col>
              </v-row>
              <template v-if="insertConfirmVisible">
                <v-row>
                  <v-col>
                    <div class="text-body-1 font-weight-medium mx-4">
                      Are you sure you want to insert {{ currentCluster.stores_set_key.length * currentCluster.options_set_key.length }} clusters?
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      color="primary"
                      depressed
                      text
                      @click="insertConfirmVisible = false"
                    >No</v-btn>
                    <v-btn
                      color="primary"
                      depressed
                      outlined
                      @click="handleSaveConfirm"
                      class="mx-4"
                    >Yes</v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Option set form dialog -->
    <v-dialog
      :value="optionSetFormDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="1200px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="stockManageStore.clustersTab.dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <v-card-title
          v-if="currentOptionSet"
          class="text-body-1 font-weight-medium mx-4 my-2"
        >
          <template v-if="currentOptionSetEditMode">
            {{ `${currentOptionSet.options_set_key ? 'Edit' : 'Add'} option set` }}
          </template>
          <template v-else>
            Option set
            <v-icon class="mx-2">arrow_forward</v-icon>
            {{ currentOptionSet.set_name }}
            <v-btn
              color="primary"
              small
              depressed
              outlined
              @click="currentOptionSetEditClick"
              class="ml-4"
            >Edit</v-btn>
          </template>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 text--primary">
          <OptionSetForm
            ref="optionSetForm"
            tab="clusters"
            :option-set="currentOptionSet"
            :edit-mode="currentOptionSetEditMode"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <template v-if="currentOptionSetEditMode">
                <v-btn
                  color="primary"
                  depressed
                  text
                  @click="handleOptionSetSaveCancel"
                >Cancel</v-btn>
                <v-btn
                  color="primary"
                  depressed
                  outlined
                  @click="handleOptionSetSaveConfirm"
                  class="mx-4"
                >Save</v-btn>
              </template>
              <template v-else>
                <v-btn
                  color="primary"
                  depressed
                  text
                  @click="handleOptionSetSaveCancel"
                >Close</v-btn>
              </template>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Store set form dialog -->
    <v-dialog
      :value="storeSetFormDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="1200px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="stockManageStore.clustersTab.dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <v-card-title
          v-if="currentStoreSet"
          class="text-body-1 font-weight-medium mx-4 my-2"
        >
          <template v-if="currentStoreSetEditMode">
            {{ `${currentStoreSet.stores_set_key ? 'Edit' : 'Add'} store set` }}
          </template>
          <template v-else>
            Store set
            <v-icon class="mx-2">arrow_forward</v-icon>
            {{ currentStoreSet.set_name }}
            <v-btn
              color="primary"
              small
              depressed
              outlined
              @click="currentStoreSetEditClick"
              class="ml-4"
            >Edit</v-btn>
          </template>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 text--primary">
          <StoreSetForm
            ref="storeSetForm"
            :store-set="currentStoreSet"
            :edit-mode="currentStoreSetEditMode"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <template v-if="currentStoreSetEditMode">
                <v-btn
                  color="primary"
                  depressed
                  text
                  @click="handleStoreSetSaveCancel"
                >Cancel</v-btn>
                <v-btn
                  color="primary"
                  depressed
                  outlined
                  @click="handleStoreSetSaveConfirm"
                  class="mx-4"
                >Save</v-btn>
              </template>
              <template v-else>
                <v-btn
                  color="primary"
                  depressed
                  text
                  @click="handleStoreSetSaveCancel"
                >Close</v-btn>
              </template>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog -->
    <v-dialog
      :title="selectedRows[0] ? selectedRows[0].name : null"
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="stockManageStore.clustersTab.dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium">Delete cluster</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to delete this cluster?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteConfirm"
                class="red--text mx-4"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import columnFilters from '@/mixins/columnFilters'
import OptionSetForm from '@/components/Assortment/OptionSets/OptionSetForm.vue'
import StoreSetForm from '@/components/Assortment/StoreSets/StoreSetForm.vue'
import TableSearch from '@/components/TableSearch.vue'
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import ChipsDropdown from '@/components/ChipsDropdown.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

import { useStockManageStore } from '@/store/pinia/stockManageStore'
export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  mixins: [columnFilters],
  data () {
    return {
      deleteDialogVisible: false,
      formDialogVisible: false,
      insertConfirmVisible: false,
      currentCluster: null,
      dateMinPickerActive: false,
      dateMaxPickerActive: false,
      currentClusterOptionSetsError: null,
      currentClusterStoreSetsError: null,
      newTags: [],
      clustersTableFilters: [],
      columnsToSearch: ['option_set', 'store_set', 'date_min', 'date_max', 'tags'],
      optionSetFormDialogVisible: false,
      currentOptionSet: null,
      currentOptionSetEditMode: false,
      storeSetFormDialogVisible: false,
      currentStoreSet: null,
      currentStoreSetEditMode: false,
      defaultCluster: {
        cluster_key: null,
        date_max: null,
        date_min: null,
        options_set_key: null,
        org_key: 0,
        stores_set_key: null,
        tags: [],
      },
    }
  },
  props: {
    optionKey: {
      type: String,
      default: '',
    },
  },
  components: {
    OptionSetForm,
    StoreSetForm,
    TableSearch,
    CollapsibleSelect,
    ChipsDropdown,
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    clusterTags () {
      return (this.appConfig?.cluster_tags || []).map(t => t.tags)
    },
    editedCluster () {
      return this.stockManageStore.clustersTab.editedCluster
    },
    editedOptionSet () {
      return this.stockManageStore.clustersTab.editedOptionSet
    },
    editedStoreSet () {
      return this.stockManageStore.clustersTab.editedStoreSet
    },
    clustersList () {
      return this.stockManageStore.clustersTab.clustersList
    },
    selectedRows () {
      return this.clustersList.filter(r => r.selected)
    },
  },
  methods: {
    async loadClusters () {
      if (this.optionKey) {
        return this.stockManageStore.loadClusters(this.optionKey)
      }
      return this.stockManageStore.loadClusters()
    },
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
    },
    handleEditClick () {
      this.formDialogVisible = true
      this.stockManageStore.getCluster(this.selectedRows[0].cluster_key)
        .then(() => {
          this.currentCluster = { ...this.editedCluster }
        })
    },
    handleAddClick () {
      this.currentCluster = Object.assign({}, this.defaultCluster)
      this.formDialogVisible = true
    },
    handleDeleteClick () {
      this.deleteDialogVisible = true
    },
    handleDeleteConfirm () {
      this.stockManageStore.deleteCluster(this.selectedRows[0].cluster_key)
        .then(() => {
          this.loadClusters()
          this.deleteDialogVisible = false
        })
    },
    handleSaveClick () {
      if (!this.validateCluster()) {
        return
      }

      this.insertConfirmVisible = true
    },
    handleSaveConfirm () {
      this.stockManageStore.saveCluster(this.currentCluster)
        .then(() => {
          this.formDialogVisible = false
          this.resetForm()
          this.loadClusters()
        })
    },
    handleSaveCancel () {
      this.formDialogVisible = false
      this.resetForm()
    },
    validateCluster () {
      this.currentClusterOptionSetsError = null
      this.currentClusterStoreSetsError = null

      if (!this.currentCluster.options_set_key || !this.currentCluster.options_set_key.length) {
        this.currentClusterOptionSetsError = 'At least one required'
      }

      if (!this.currentCluster.stores_set_key || !this.currentCluster.stores_set_key.length) {
        this.currentClusterStoreSetsError = 'At least one required'
      }

      return !this.currentClusterOptionSetsError && !this.currentClusterStoreSetsError
    },
    resetForm () {
      this.currentCluster = null
      this.insertConfirmVisible = false
      this.newTags = []
      this.currentClusterOptionSetsError = null
      this.currentClusterStoreSetsError = null
    },
    handleTagChanged (val) {
      val.forEach(tag => {
        if (!this.clusterTags.includes(tag)) {
          this.newTags.push(tag)
        }
      })
    },
    handleRowOptionSetClick (row) {
      const payload = {
        tab: 'clusters',
        key: row.options_set_key,
      }
      this.currentOptionSetEditMode = false
      this.optionSetFormDialogVisible = true
      this.stockManageStore.getOptionSet(payload)
        .then(() => {
          this.currentOptionSet = { ...this.editedOptionSet }
          this.$nextTick(this.$refs.optionSetForm.searchOptions)
        })
    },
    handleClusterStoreSetClick (storeSetName) {
      const row = this.clustersList.find(x => x.store_set === storeSetName)
      this.handleRowStoreSetClick(row)
    },
    handleRowStoreSetClick (row) {
      const payload = {
        tab: 'clusters',
        key: row.stores_set_key,
      }
      this.currentStoreSetEditMode = false
      this.storeSetFormDialogVisible = true
      this.stockManageStore.getStoreSet(payload)
        .then(() => {
          this.currentStoreSet = { ...this.editedStoreSet }
          this.$nextTick(this.$refs.storeSetForm.searchStores)
        })
    },
    handleOptionSetSaveConfirm () {
      if (!this.$refs.optionSetForm.validateOptionSet()) {
        return
      }

      const payload = this.$refs.optionSetForm.getSavePayload()

      this.stockManageStore.setDialogLoading({
        tab: 'clusters',
        value: true,
      })
      this.stockManageStore.saveOptionSet(payload)
        .then(() => {
          this.optionSetFormDialogVisible = false
          this.$refs.optionSetForm.resetForm()

          this.loadClusters()
            .finally(() => {
              this.stockManageStore.setDialogLoading({
                tab: 'clusters',
                value: false,
              })
            })
        })
    },
    handleOptionSetSaveCancel () {
      this.optionSetFormDialogVisible = false
      this.$refs.optionSetForm.resetForm()
    },
    currentOptionSetEditClick () {
      this.currentOptionSetEditMode = true
    },
    handleStoreSetSaveConfirm () {
      if (!this.$refs.storeSetForm.validateStoreSet()) {
        return
      }

      const payload = this.$refs.storeSetForm.getSavePayload()

      this.stockManageStore.setDialogLoading({
        tab: 'clusters',
        value: true,
      })
      this.stockManageStore.saveStoreSet(payload)
        .then(() => {
          this.storeSetFormDialogVisible = false
          this.$refs.storeSetForm.resetForm()

          this.loadClusters()
            .finally(() => {
              this.stockManageStore.setDialogLoading({
                tab: 'clusters',
                value: false,
              })
            })
        })
    },
    handleStoreSetSaveCancel () {
      this.storeSetFormDialogVisible = false
      this.$refs.storeSetForm.resetForm()
    },
    currentStoreSetEditClick () {
      this.currentStoreSetEditMode = true
    },
  },
  created () {
    this.loadClusters()
  },
}
</script>

<style lang="scss">
.assortment-clusters {
}
</style>
