<template>
  <div class="option-info-mark-down">
    <v-card class="product-card" min-height="350px">
      <div class="mb-4 product-title">
        <h4>Product</h4>

        <div class="d-flex">
          <LinkedPagesMenu
            :variation-key="article?.variation_key"
            :option-key="$route.query.option_key"
          />

          <v-menu
            top
            open-on-hover
            offset-x
            min-width="200"
            max-width="200"
            content-class="option-info-menu"
            :open-delay="200"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-3"
                v-bind="attrs"
                v-on="on"
              >info</v-icon>
            </template>

            <v-card class="py-2 px-4 p-relative" v-html="getProperties()"></v-card>
          </v-menu>
        </div>
      </div>

      <template v-if="loaded">
        <div class="image-row">
          <img height="160px" :src="imgUrl || '@/assets/img/ts_placeholder.png'" @error="loadPlaceholder"  alt=""/>
          <div class="product-text">
            <OptionVariationsMenu
              :option="article"
              :variations="variations"
            />

            <div class="mt-4">
              <template v-if="orderSettings.clusters && orderSettings.clusters.length > 0">
                <ChipsDropdown
                  :items="orderSettings.clusters"
                  color="success"
                />
              </template>
            </div>
          </div>
        </div>
        <v-divider />
        <template>
          <h4 class="my-5">Filters</h4>
          <div class="stores mb-5">
            <v-menu
              :close-on-content-click="true"
              v-model="datePickerActive"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <label>
                  <span style="font-size: 14px;font-weight: 370">End date</span>
                  <v-text-field
                    class="pt-0 mt-0"
                    :value="parameterValues.date_max"
                    clearable
                    @click:clear="clearDate"
                    hide-details="auto"
                    v-on="on"
                  />
                </label>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                :locale-first-day-of-year="4"
                :show-week="true"
                no-title
                @change="filterChanged"
                v-model="parameterValues.date_max"
              />
            </v-menu>
          </div>
          <div class="stores mb-3 py-1">
            <label style="font-size: 14px;font-weight: 380" class="mt-2">
              Stores
            </label>
            <ChipsDropdown
              :key="storesListKey"
              :items="storesFromParameter"
            />
          </div>
        </template>
      </template>
    </v-card>
  </div>
</template>

<script>
import * as optionsApi from '@/api/options'
import { formatString } from '@/variables'
import { format } from 'date-fns'
import ChipsDropdown from '@/components/ChipsDropdown'
import LinkedPagesMenu from '@/components/LinkedPagesMenu'
import OptionVariationsMenu from '@/components/OptionVariationsMenu.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import ts_placeholder from '@/assets/img/ts_placeholder.png'

export default {
  components: { ChipsDropdown, LinkedPagesMenu, OptionVariationsMenu },
  props: {
    orderSettings: {},
    optionKey: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      imgUrl: '',
      article: {},
      loaded: false,
      storesListKey: 0,
      datePickerActive: false,
      loading: true,
      parameters: [],
      parameterValues: {},
      variations: [],
    }
  },
  watch: {
    optionKey () {
      this.load()
    },
    storesFromParameter: {
      handler () {
        this.storesListKey++
      },
      immediate: true
    },
    orderSettings (value) {
      if (value) {
        this.parameterValues = value
      }
    }
  },
  methods: {
    format,
    getProperties () {
      let dataString = ''
      const data = this.article['properties'] || {}
      for (const key of Object.keys(data).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))) {
        const val =
          '<b>' + formatString(key) + '</b>: ' + data[key].toLocaleString()
        dataString += val + '<br>'
      }
      return dataString
    },
    getVariations () {
      return optionsApi
        .getOptionVariations(this.$route.query.option_key)
        .then(variations => {
          this.variations = variations
        })
    },
    load () {
      if (this.optionKey != null) {
        this.getData()
        this.getVariations()
      }
      this.storesListKey++
    },
    formatString (str) {
      return formatString(str)
    },
    getData () {
      optionsApi
        .loadOptionData({ option_key: this.optionKey })
        .then(response => {
          this.article = response.data.data
          this.imgUrl = response.data.data['image_url']
          delete response.data.data['image_url']
          const keys = Object.keys(response.data.data).slice(0, 5)
          this.article = {}
          for (const key of keys) {
            this.article[key] = response.data.data[key]
          }
          this.loaded = true
          this.loading = false
        })
        .catch(error => console.log(error))
    },
    loadPlaceholder (e) {
      e.target.src = ts_placeholder
    },
    filterChanged () {
      this.$emit('paramChanged', this.parameterValues)
    },
    clearDate () {
      this.parameterValues.date_max = null
      this.filterChanged()
    },
  },
  mounted () {
    this.load()
    this.parameterValues = this.orderSettings || {}
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    storesFromParameter () {
      return this.parameterValues.store_keys?.map(key => {
        const store = (this.appConfig?.stores || []).find(s => s.store_key === key)

        return store ? store.store_name : key
      })
    },
  }
}
</script>

<style lang="scss">
.option-info-mark-down {
    color: var(--text-black);
    height: 100%;
    .tooltip {
        width: 20px;
    }
    .product-card {
        padding: 16px;
        height: 100% !important;
        .product-title {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .icon:hover {
                color: var(--primary);
                cursor: pointer;
            }
        }
        .image-row {
            margin-bottom: 26px;
            display: flex;
            .product-text {
                font-size: 13px;
                margin-left: 16px;
                p {
                    margin: 0;
                }
                .product-title {
                    font-weight: 600;
                }
                .product-subtitle {
                    color: var(--text-sub-black);
                }
                .product-price {
                    margin-top: 6px;
                }
            }
        }
    }
    .sliders {
        display: grid;
        grid-template-columns: 1fr 3fr;
        .min-max {
            color: #666;
            font-size: 14px;
        }
      .slider {
        margin-right: -8px;
      }
    }
}
</style>
