// src/stores/user.ts
import {defineStore} from 'pinia'
import {changeRole, deleteUser, saveUser} from '@/api/userAPI'
import {User} from '@/models/User'

export const useUsersStore = defineStore('user', {
  state: () => ({
    deletingUser: false,
    savingUser: false
  }),

  actions: {
    async saveUser (user: User) {
      this.savingUser = true
      try {
        await saveUser(user)
      } finally {
        this.savingUser = false
      }
    },
    async changeRole (user: User) {
      await changeRole(user)
    },
    async deleteUser (email: string) {
      this.deletingUser = true
      try {
        await deleteUser(email)
      } finally {
        this.deletingUser = false
      }
    }
  }
})
