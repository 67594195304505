<template>
  <v-card class="buy-manage-suppliers-modal">
    <v-card-title class="text-body-1 font-weight-medium my-2 justify-space-between px-12">
      {{ `${supplier && supplier.supplier_key ? 'Edit supplier' : 'Add supplier'}` }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text--primary px-12">
      <v-row class="align-baseline mt-2">
        <v-col class="py-0 pr-8">
          <v-text-field
            v-model="supplier.supplier_name"
            clearable
            label="supplier name"
            :error-messages="validations.supplier_name"
          />
        </v-col>
      </v-row>
      <v-row class="align-baseline">
        <v-col class="py-0 pr-8">
          <v-autocomplete
            :menu-props="{
              left: true,
            }"
            v-model="supplier.country"
            clearable
            :items="countries"
            :item-text="(item)=> `${item.country} (${item.iso_code3})`"
            item-value="country"
            label="Country"
            :error-messages="validations.country"
          />
        </v-col>
      </v-row>
      <v-row class="align-baseline">
        <v-col class="py-0 pr-8">
          <v-autocomplete
            :menu-props="{
              left: true,
            }"
            v-model="supplier.currency"
            :items="currencies"
            :item-text="(item)=> `${item.name} (${item.iso_code})`"
            item-value="iso_code"
            clearable
            :error-messages="validations.currency"
            label="Currency"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="align-baseline">
        <v-col class="py-0 pr-8">
          <v-autocomplete
            :menu-props="{
              left: true,
            }"
            v-model="supplier.payment_key"
            :items="payments"
            :error-messages="validations.payment_key"
            item-text="payment_name"
            item-value="payment_key"
            clearable
            label="Payment"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="align-baseline">
        <v-col class="py-0 pr-8">
          <v-autocomplete
            :menu-props="{
              left: true,
            }"
            v-model="supplier.schedule_key"
            :items="schedules"
            :error-messages="validations.schedule_key"
            item-text="schedule_name"
            item-value="schedule_key"
            clearable
            label="Schedule"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="align-baseline">
        <v-col class="py-0 pr-8">
          <collapsible-select
            v-model="supplier.cost_keys"
            :items="costs"
            autocomplete
            item-text="cost_name"
            item-value="cost_key"
            clearable
            label="Costs"
          ></collapsible-select>
        </v-col>
      </v-row>
      <v-row class="align-baseline flex xs6">
        <v-col class="py-0 pr-8">
          <v-text-field
            v-model="supplier.markup"
            :error-messages="markupError"
            clearable
            type="number"
            step=".1"
            min="1"
            label="MarkUp"
            @input="()=> markupError = supplier.markup < 1 ? `Markup can only be greater/equal to 1`: ''"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="align-baseline mb-4 flex xs6">
        <v-col class="py-0 pr-8">
          <v-text-field
            v-model.number="supplier.moq"
            clearable
            type="number"
            min="0"
            step=".1"
            label="Moq"
            :error-messages="moqError"
            @input="()=> moqError = supplier.moq < 0 ? `Moq must be greater than 0`: ''"
          ></v-text-field>
        </v-col>
      </v-row>
      <modal-property-management
        :obj="supplier"
        objectName="suppliers"
        @validation="handlePropertyManagementValidation"
      />
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row class="dialog-footer text-right py-2 px-4">
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="$emit('close')"
              >Cancel</v-btn>
              <v-btn
                color="primary"
                :disabled="!arePropertiesValid"
                depressed
                outlined
                class="mx-4"
                @click="saveSupplier"
              >Save</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-card-actions>
  </v-card>
</template>

<script>
import { loadCountryList } from '@/api/countries'
import { saveSupplier } from '@/api/shop'
import CollapsibleSelect from '@/components/CollapsibleSelect'
import ModalPropertyManagement from '@/components/GeneralSettings/ModalPropertyManagement'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'BuyManageSuppliersModal',
  components: { ModalPropertyManagement, CollapsibleSelect },
  props: {
    supplier_key: {
      type: Number,
      default () {
        return null
      }
    },
    isEdit: {
      type: Boolean,
      default () {
        return false
      }
    },
    outerSupplier: null,
    payments: [],
    costs: [],
    schedules: []
  },
  data () {
    return {
      arePropertiesValid: true,
      lastProperty: '',
      lastDimension: '',
      countries: [],
      validations: {},
      markupError: '',
      moqError: '',
      supplier: {
        'org_key': undefined,
        'supplier_key': undefined,
        'supplier_name': undefined,
        'country': undefined,
        'currency': undefined,
        'markup': 1,
        'moq': 0,
        'schedule_key': undefined,
        'payment_key': undefined,
        'cost_keys': []
      },
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    currencies () {
      return this.appConfig?.currencies || []
    },
  },
  mounted () {
    loadCountryList().then(r => {
      this.countries = r
    })
    if (this.outerSupplier) {
      this.supplier = { ...this.outerSupplier }
    }
  },
  methods: {
    handlePropertyManagementValidation (validation) {
      this.arePropertiesValid = validation
    },
    saveSupplier () {
      this.validations = {}
      const validations = {}
      const arr = [
        'supplier_name',
        'country',
        'currency',
        'markup',
        'schedule_key',
        'payment_key',
      ]
      arr.forEach((item) => {
        if (!this.supplier[item]) {
          validations[item] = 'Field Cannot be Empty'
        }
      })
      if (Object.keys(validations).length) {
        this.validations = validations
        return
      }
      this.moqError = this.supplier.moq < 0 ? 'Moq must be greater than 0' : ''
      this.markupError = this.supplier.markup < 1 ? 'Markup can only be greater/equal to 1' : ''
      if (this.moqError || this.markupError) {
        return
      }
      saveSupplier(this.supplier).then((supplier) => this.$emit('save', supplier))
    }
  }
}
</script>

<style scoped lang="scss">
</style>
