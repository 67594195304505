import {defineStore} from 'pinia'
import * as api from '@/api/storePage'
import * as models from '@/models/StorePage'
import {SkuTransfersDoneItem, SkuTransfersTodoItem} from '@/models/StorePage'
import {Optional} from '@/models/TypeHelpers'

export const useStorepageStore = defineStore('storepage', {
  state: (): State => ({
    transfersTable: null,
    skuTransfersTodoTable: null,
    skuTransfersDoneTable: [],
    skuTransfersTableHeaders: [],
    transfersFromStoreKey: null,
    transfersFromStoreName: '',
    transfersToStoreKey: null,
    transfersToStoreName: '',
    trendData: null,
    bestsellersData: []
  }),
  actions: {
    async getTrendData (payload: models.TrendDataPayload) {
      try {
        const response = await api.getTrendData(payload)
        this.trendData = {
          data: {
            potential: response.data.map(elem => elem.potential),
            sales: response.data.map(elem => elem.sales),
            stock: response.data.map(elem => elem.stock),
            x_label: response.data.map(elem => elem.x_label)
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getBestsellersData (payload: models.BestsellersDataPayload) {
      try {
        const response = await api.getBestsellersData(payload)
        this.bestsellersData = response.data
      } catch (error) {
        console.error(error)
      }
    },
    async getTransfersTable (payload: models.TransfersTablePayload) {
      await this.getStoresInfo(payload)
      try {
        const response = await api.getTransfersTable(payload)
        this.transfersTable = response.data
      } catch (error) {
        console.error(error)
      }
    },
    async getSkuTransfersTable (payload: models.SkuTransfersTablePayload) {
      await this.getStoresInfo(payload)
      try {
        const response = await api.getSkuTransfersTable(payload)
        this.skuTransfersTodoTable = optionCounts(response.data_todo)
        this.skuTransfersDoneTable = optionCounts(response.data_done)
        this.skuTransfersTableHeaders = getSkuTransfersTableHeaders(response.columns)
      } catch (error) {
        console.error(error)
      }
    },
    async getStoresInfo (payload: models.StoresInfoPayload) {
      try {
        const response = await api.getStoresInfo(payload)
        const fromStore = response.data.find(store => store.store_key === payload.store_key)
        if (fromStore) {
          this.transfersFromStoreKey = fromStore.store_key
          this.transfersFromStoreName = fromStore.store_name
        }
        if (payload.to_store_key) {
          const toStore = response.data.find(store => store.store_key === payload.to_store_key)
          if (toStore) {
            this.transfersToStoreKey = toStore.store_key
            this.transfersToStoreName = toStore.store_name
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    $reset () {
      this.transfersToStoreKey = null
      this.transfersToStoreName = ''
      this.skuTransfersTodoTable = null
      this.skuTransfersDoneTable = []
      this.skuTransfersTableHeaders = []
    },
  }
})

interface State {
  transfersTable: Optional<models.TransfersTable>;
  skuTransfersTodoTable: Optional<models.SkuTransfersTodoItem[]>;
  skuTransfersDoneTable: models.SkuTransfersDoneItem[];
  skuTransfersTableHeaders: string[];
  transfersFromStoreKey: Optional<number>
  transfersFromStoreName: string;
  transfersToStoreKey: Optional<number>
  transfersToStoreName: string;
  trendData: Optional<models.TrendData>;
  bestsellersData: models.BestsellersData[];
}

function getSkuTransfersTableHeaders (columns: string[]): string[] {
  const removeList = ['store', 'image', 'option', 'page', 'done', 'picked', 'option_done', 'sku_key']
  return columns.filter(item => !removeList.includes(item))
}

function optionCounts (data: SkuTransfersDoneItem[]|SkuTransfersTodoItem[]) {
  if (data.length === 0) {
    return data
  }
  let count = 0
  for (let i = 0; i < data.length - 1; i++) {
    count++
    if (data[i].option !== data[i + 1].option) {
      const firstIndex = i - count + 1
      data[firstIndex].count = count
      count = 0
    }
  }
  data[data.length - 1 - count].count = count + 1
  return data
}
