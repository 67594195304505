import {defineStore} from 'pinia'
import {useStoreAndOptionSetsStore} from '@/store/pinia/useStoreAndOptionSetsStore'
import * as api from '@/api/stockManageAPI'
import * as models from '@/models/StockManage'

export const useStockManageStore = defineStore('stockManage', {
  state: (): models.State => ({
    storeSetsFiltersValues: {},
    loadingOptionsResults: false,
    loadingStoresResults: false,
    setsTab: {
      dialogLoading: false,
      editedOptionSet: null,
      editedStoreSet: null,
      optionsList: [],
    },
    clustersTab: {
      loadingResults: false,
      dialogLoading: false,
      clustersList: [],
      editedCluster: null,
      editedOptionSet: null,
      editedStoreSet: null,
      clusterTagsList: [],
      clustersOptionSetsList: [],
      clustersStoreSetsList: [],
    },
    timelinesTab: {
      ruleBlocksList: [],
      timelinesList: [],
      dialogLoading: false,
      loadingTimelines: false,
      loadingRuleblocks: false,
      loadingTimelinesMapping: false,
      timelinesMappingList: [],
      timelinesOptionSetsList: [],
    },
    factorsTab: {
      loadingFactors: false,
      loadingDoubleFactors: false,
      dialogLoading: false,
      factorsList: [],
      doubleFactorsList: [],
      editedFactor: null,
      editedOptionSet: null,
      factorsOptionSetsList: [],
    },
    capacityTab: {
      loadingOptionDynamic: false,
      loadingOptionStatic: false,
      dialogLoading: false,
      editedOptionSet: null,
      editedStoreSet: null,
      lowLevelCapacityList: [],
      staticCapacityList: [],
      editedDynamicCapacityOption: null,
      capacityOptionSetsList: [],
      capacityStoreSetsList: [],
    },
  }),

  actions: {
    async getStoreSetsFilterOptionValues (key: string) {
      this.setsTab.dialogLoading = true
      try {
        this.storeSetsFiltersValues[key] = api.getStoreSetsFilterOptionValues(key)
      } catch (error) {
        console.error(error)
      } finally {
        this.setsTab.dialogLoading = false
      }
    },

    async saveOptionSet (payload: models.OptionSetPayload) {
      return api.saveOptionSet(payload)
    },

    async saveStoreSet (payload: models.StoreSetPayload) {
      this.setsTab.dialogLoading = true
      try {
        return await api.saveStoreSet(payload)
      } finally {
        this.setsTab.dialogLoading = false
      }
    },

    async getOptionSet (payload: { key: number; tab: 'sets' | 'clusters' | 'factors' | 'capacity' }) {
      this[`${payload.tab}Tab`].dialogLoading = true
      try {
        this[`${payload.tab}Tab`].editedOptionSet = await api.getOptionSet(payload.key)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this[`${payload.tab}Tab`].dialogLoading = false
      }
    },

    async deleteOptionSet (keys: number[]) {
      this.setsTab.dialogLoading = true
      try {
        return await api.deleteOptionSet(keys)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.setsTab.dialogLoading = false
      }
    },

    async deleteStoreSet (keys: number[]) {
      this.setsTab.dialogLoading = true
      try {
        return await api.deleteStoreSet(keys)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.setsTab.dialogLoading = false
      }
    },

    async deleteCluster (key: number) {
      this.clustersTab.dialogLoading = true
      try {
        return await api.deleteCluster(key)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.clustersTab.dialogLoading = false
      }
    },

    async deleteFactor (key: number) {
      this.factorsTab.dialogLoading = true
      try {
        return await api.deleteFactor(key)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.factorsTab.dialogLoading = false
      }
    },

    async getOptionsPreviewByCriteria (payload: models.PreviewCriteriaPayload) {
      return api.getOptionsPreviewByCriteria(payload)
    },

    async getStoresPreviewByCriteria (payload: models.PreviewCriteriaPayload) {
      return api.getStoresPreviewByCriteria(payload)
    },

    async getStoreSet (payload: { key: number; tab: 'sets'|'clusters'|'capacity' }) {
      this[`${payload.tab}Tab`].dialogLoading = true
      try {
        this[`${payload.tab}Tab`].editedStoreSet = await api.getStoreSet(payload.key)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this[`${payload.tab}Tab`].dialogLoading = false
      }
    },

    async loadClusters (optionKey?: number) {
      this.clustersTab.loadingResults = true
      try {
        this.clustersTab.clustersList = await api.loadClusters(optionKey)
      } catch (error) {
        console.error(error)
      } finally {
        this.clustersTab.loadingResults = false
      }
    },

    async getCluster (key: number) {
      this.clustersTab.dialogLoading = true
      try {
        this.clustersTab.editedCluster = await api.getCluster(key)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.clustersTab.dialogLoading = false
      }
    },

    async getFactor (key: number) {
      this.factorsTab.dialogLoading = true
      try {
        this.factorsTab.editedFactor = await api.getFactor(key)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.factorsTab.dialogLoading = false
      }
    },

    async saveCluster (payload: models.Cluster) {
      this.clustersTab.dialogLoading = true
      try {
        return await api.saveCluster(payload)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.clustersTab.dialogLoading = false
      }
    },

    getEntityOptionSets (entity: models.EntityTab) {
      const storeAndOptionSetsStore = useStoreAndOptionSetsStore()
      const list = storeAndOptionSetsStore.optionSets.filter(s => s.label.includes('all') || s.label.includes(entity))
      switch (entity) {
      case 'clusters':
        this.clustersTab.clustersOptionSetsList = list
        break
      case 'factors':
        this.factorsTab.factorsOptionSetsList = list
        break
      case 'timelines':
        this.timelinesTab.timelinesOptionSetsList = list
        break
      case 'capacity':
        this.capacityTab.capacityOptionSetsList = list
        break
      }
    },

    getEntityStoreSets (entity: 'clusters' | 'capacity') {
      const storeAndOptionSetsStore = useStoreAndOptionSetsStore()
      const list = storeAndOptionSetsStore.storeSets.filter(s => s.label.includes('all') || s.label.includes(entity))
      switch (entity) {
      case 'clusters':
        this.clustersTab.clustersStoreSetsList = list
        break
      case 'capacity':
        this.capacityTab.capacityStoreSetsList = list
        break
      }
    },

    getEntitiesSets () {
      const entities: models.EntityTab[] = ['clusters', 'factors', 'timelines', 'capacity']
      entities.forEach(entity => {
        this.getEntityOptionSets(entity)
      })
      this.getEntityStoreSets('clusters')
      this.getEntityStoreSets('capacity')
    },

    async saveFactor (payload: models.Factor) {
      this.factorsTab.dialogLoading = true
      try {
        return await api.saveFactor(payload)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.factorsTab.dialogLoading = false
      }
    },

    async loadFactors (optionKey?: number) {
      this.factorsTab.loadingFactors = true
      try {
        this.factorsTab.factorsList = await api.loadFactors(optionKey)
      } catch (error) {
        console.error(error)
      } finally {
        this.factorsTab.loadingFactors = false
      }
    },

    async loadDoubleFactors () {
      this.factorsTab.loadingDoubleFactors = true
      try {
        this.factorsTab.doubleFactorsList = await api.loadDoubleFactors()
      } catch (error) {
        console.error(error)
      } finally {
        this.factorsTab.loadingDoubleFactors = false
      }
    },

    async loadRuleblocks () {
      this.timelinesTab.loadingRuleblocks = true
      try {
        this.timelinesTab.ruleBlocksList = api.getRuleblocks()
      } catch (error) {
        console.error(error)
      } finally {
        this.timelinesTab.loadingRuleblocks = false
      }
    },

    async loadTimelines () {
      this.timelinesTab.loadingTimelines = true
      try {
        this.timelinesTab.timelinesList = api.loadTimelines()
      } catch (error) {
        console.error(error)
      } finally {
        this.timelinesTab.loadingTimelines = false
      }
    },

    async saveRuleblock (payload: models.RuleBlockPayload) {
      return api.saveRuleblock(payload)
    },

    async saveTimeline (payload: models.TimelinePayload) {
      return api.saveTimeline(payload)
    },

    async deleteTimeline (key: number) {
      return api.deleteTimeline(key)
    },

    async deleteRuleblock (key: number) {
      return api.deleteRuleblock(key)
    },

    async getTimelineMappings () {
      this.timelinesTab.loadingTimelinesMapping = true
      try {
        this.timelinesTab.timelinesMappingList = await api.getTimelineMappings()
      } catch (error) {
        console.error(error)
      } finally {
        this.timelinesTab.loadingTimelinesMapping = false
      }
    },

    async saveTimelinesMapping (payload: models.TimelinesMappingPayload) {
      return api.saveTimelinesMapping(payload)
    },

    async loadOptionCapacity (optionKey: number) {
      if (this.capacityTab.loadingOptionDynamic || this.capacityTab.loadingOptionStatic) {
        return
      }
      this.capacityTab.loadingOptionDynamic = true
      this.capacityTab.loadingOptionStatic = true
      try {
        const response = await api.loadOptionCapacity(optionKey)
        this.capacityTab.lowLevelCapacityList = response.capacity
      } catch (error) {
        console.error(error)
      } finally {
        this.capacityTab.loadingOptionDynamic = false
        this.capacityTab.loadingOptionStatic = false
      }
    },

    async loadLowLevelCapacityList () {
      try {
        const response = await api.loadCapacity()
        this.capacityTab.lowLevelCapacityList = response.capacity
      } catch (error) {
        console.error(error)
      }
    },

    async getDynamicCapacityOption (key: number) {
      try {
        this.capacityTab.editedDynamicCapacityOption = await api.getCapacityOption(key)
      } catch (error) {
        console.error(error)
      }
    },

    async deleteDynamicCapacityOption (key: number) {
      return api.deleteCapacityOption(key)
    },

    async saveCapacityOption (payload: models.SaveCapacityOptionPayload) {
      this.capacityTab.dialogLoading = true
      try {
        return await api.saveCapacityOption(payload)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.capacityTab.dialogLoading = false
      }
    },

    async getStaticCapacityOption (key: number) {
      return api.getCapacityOption(key)
    },

    async deleteStaticCapacityOption (key: number) {
      return api.deleteCapacityOption(key)
    },

    setLoadingOptionsResults (value: boolean) {
      this.loadingOptionsResults = value
    },

    setLoadingStoresResults (value: boolean) {
      this.loadingStoresResults = value
    },

    setDialogLoading (payload: { tab: 'capacity'|'clusters'|'sets'|'factors', value: boolean }) {
      this[`${payload.tab}Tab`].dialogLoading = payload.value
    },

    setEditedOptionSet (payload: { tab: 'sets'|'factors'; optionSet: models.OptionSet | null }) {
      this[`${payload.tab}Tab`].editedOptionSet = payload.optionSet
    },

    setOptionsList (options: models.Option[]) {
      this.setsTab.optionsList = options
    },
    setLoadingTimelines (value: boolean) {
      this.timelinesTab.loadingTimelines = value
    },
    setLoadingRuleblocks (value: boolean) {
      this.timelinesTab.loadingRuleblocks = value
    },
    setLoadingTimelinesMapping (value: boolean) {
      this.timelinesTab.loadingTimelinesMapping = value
    },
    setTimelinesMapping (timelinesMapping: models.TimelineMapping[]) {
      this.timelinesTab.timelinesMappingList = timelinesMapping
    }
  },

  getters: {
    isLoadingStaticCapacity: (state) => state.capacityTab.loadingOptionStatic,
    isLoadingDynamicCapacity: (state) => state.capacityTab.loadingOptionDynamic,
  },
})
