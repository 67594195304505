<template>
  <div ref="loginPage" v-animate-css="'fadeIn'">
    <div class="login-header">
      <kinesis-container>
        <kinesis-element :strength="20">
          <img
            alt="Logo"
            :src="thunderstockNewLogo"
            @click="$router.push({ name: 'thunderstock' })"
          />
        </kinesis-element>
      </kinesis-container>
    </div>

    <div class="login-page">
      <div class="top-section" ref="topSection"></div>

      <v-alert
        v-if="mailError || mailSent"
        dense
        outlined
        :type="mailSent? 'success' : 'error'"
        transition="scale-transition"
        class="alert"
      >
        {{ mailSent ? 'Success, check your mail for a new login link' :'Failed resending login link'}}

      </v-alert>

      <div class="mid-section-background"></div>
      <div class="mid-section" ref="midSection">
        <v-card class="login-card" ref="loginCard">
          <div class="inner-wrapper mt-2">
            <div class="login-btn">
              <h3
                style="color: #ff9800; font-size: 20px"
                v-animate-css.hover="'pulse'"
              >
                Resend Login
              </h3>
            </div>

            <div>
              <form @submit.prevent="submit" id="check-login-form">
                <div>
                  <div :class="{ hasError: $v.form.email.$error }">
                    <p for="inputEmail" class="label">Email</p>
                    <br />
                    <input
                      v-model="form.email"
                      type="email"
                      id="inputEmail"
                      class="input"
                      required
                      autofocus
                    />
                  </div>
                  <div
                    :class="{ hasError: $v.form.key.$error }"
                  >
                    <p class="label">Store key</p>
                    <br />
                    <input
                      v-model="form.key"
                      type="number"
                      id="inputKey"
                      class="input"
                      required
                      autofocus
                    />
                  </div>
                  <div class="login-btn-wrapper">
                    <v-btn
                      class="login-btn"
                      type="submit"
                      outlined
                      form="check-login-form"
                      @click="submit"
                      v-animate-css.click="'tada'"
                      v-animate-css.hover="'pulse'"
                    >Login</v-btn>
                  </div>
                </div>

              </form>
            </div>
          </div>

          <hr class="hr" />

          <div class="card-footer align-center">
            <h4
              class="resend-description"
              style="width: 60%"
            >
              Your login link is outdated, fill the fields and receive a new one
            </h4>
          </div>
        </v-card>
      </div>

      <div class="bottom-section" ref="bottomSection"></div>
    </div>
  </div>
</template>

<script>
import { email, integer, required } from 'vuelidate/lib/validators'
import { headers } from '@/variables'
import thunderstockNewLogo from '@/assets/img/thunderstock-new-logo.png'

import axios from '@/services/axios'
import baseURLs from '@/api/APIBaseURLs'

export default {
  data () {
    return {
      thunderstockNewLogo,
      form: { email: '', key: '' },
      errorMessage: null,
      sending: false,
      mailSent: false,
      mailError: false
    }
  },

  validations: {
    form: {
      key: { required, integer },
      email: { required, email }
    }
  },

  methods: {
    submit () {
      this.errorMessage = null

      this.$v.form.$touch()
      if (this.$v.form.$error) {
        return
      }

      const payload = { token: this.$route.query.token, store_key: parseInt(this.form.key), address: this.form.email }
      this.sending = true
      this.mailError = false
      this.mailSent = false
      axios.post(baseURLs.storepageAPI + '/resend_token', payload, { headers: headers })
        .then(() => {
          this.sending = false
          this.mailSent = true
        })
        .catch(error => {
          console.error(error)
          if (error.response.status === 400) {
            this.sending = false
            this.mailError = true
          }
        })
    }
  },
}
</script>

<style scoped>
  .resend-description {
    text-align: center;
    line-height: 1.4em;
  }
</style>
