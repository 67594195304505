export const tableSelectMixin = {
  data () {
    return {
      filteredData: []
    }
  },
  computed: {
    allSelectedItems () {
      return this.filteredData.length > 0 && this.filteredData.every((item) => item.selected)
    },
    selectedItemsRows () {
      return this.filteredData.filter((item) => item.selected)
    }
  },
  methods: {
    handleSelectAllItemsChange () {
      const selected = !this.allSelectedItems
      this.filteredData.forEach((item) => {
        this.$set(item, 'selected', selected)
      })
      this.$emit('select-all-items-change', selected)
    },

    handleSelectItemRowChange (val, row) {
      this.$set(row, 'selected', val)
      this.$emit('select-item-row-change', val, row)
    }
  }
}
