<template>
  <div class="assortment-store-sets p-relative">
    <v-overlay absolute color="white" :value="loadingStoreSets">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <div class="d-flex align-center mt-8 ml-6">
      <h6 class="text-subtitle-1 font-weight-medium">Store sets</h6>
      <v-tooltip top :max-width="250">
        <template v-slot:activator="{ on, attrs }">
          <v-icon :size="21" class="ml-2" v-bind="attrs" v-on="on">info</v-icon>
        </template>
        <span>It takes between 5-10 minutes before changes appear in this table</span>
      </v-tooltip>
    </div>
    <v-row>
      <v-col class="text-right pr-6">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('storesTableFilters', columnsToSearch, val)"
        />
        <v-icon class="mx-2 action-btn-primary" :disabled="selectedRows.length !== 1" @click="handleEditClick">edit</v-icon>
        <v-icon class="mx-2 action-btn-primary" @click="handleAddClick">add</v-icon>
        <v-icon class="mx-2 action-btn-danger" :disabled="deleteDisabled" @click="handleDeleteClick">delete</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <data-tables
          style="width: 100%"
          :data="storeSets"
          :page-size="5"
          :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
          :table-props="{ 'row-key': 'stores_set_key' }"
          :filters="storesTableFilters"
          filter-multiple
          @filter-change="filters => handleFilterChange('storesTableFilters', filters)"
          @row-click="handleRowClick">
          <el-table-column prop="stores_set_key" width="55">
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                class="mt-0 ml-4 pt-0"
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column prop="set_name" label="Name" sortable="custom">
            <template slot-scope="scope">
              <div class="d-flex align-center justify-space-between">
                <div>
                  {{ scope.row.set_name}}
                </div>
                <v-chip
                  label
                  outlined
                  small
                  color="#4FB4AB"
                  class="mr-8 ml-4"
                  @click.stop="handleStoreTagClick(scope.row)"
                >
                  {{ scope.row.stores}}
                </v-chip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="static_set"
            column-key="static_set"
            label="Static"
            sortable="custom"
            :filters="getColumnFilterValues('storeSets', 'static_set')"
          >
            <template slot-scope="scope">
              <div class="d-flex">
                <v-simple-checkbox
                  disabled
                  dense
                  hide-details
                  class="shrink mt-0 pt-0"
                  :value="scope.row.static_set"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="in_use"
            column-key="in_use"
            label="In use"
            sortable="custom"
            :filters="getColumnFilterValues('storeSets', 'in_use')"
          >
            <template slot-scope="scope">
              <div class="d-flex">
                <v-simple-checkbox
                  disabled
                  dense
                  hide-details
                  class="shrink mt-0 pt-0"
                  :value="scope.row.in_use"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="label"
            column-key="label"
            label="Labels"
            sortable="custom"
            :filters="getColumnFilterValues('storeSets', 'label')"
          >
            <template slot-scope="scope">
              <ChipsDropdown :items="scope.row.label" />
            </template>
          </el-table-column>
        </data-tables>
      </v-col>
    </v-row>

    <!-- Form dialog -->
    <v-dialog
      :value="formDialogVisible"
      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="1200px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-overlay absolute color="white" :value="stockManageStore.setsTab.dialogLoading">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>

        <v-card-title class="text-body-1 font-weight-medium mx-4 my-2">{{ `${currentStoreSet && currentStoreSet.stores_set_key ? 'Edit' : 'Add'} store set` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 text--primary">
          <StoreSetForm
            ref="storeSetForm"
            :store-set="currentStoreSet"
            edit-mode
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2 px-4">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleSaveCancel"
              >Cancel</v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                @click="handleSaveConfirm"
                class="mx-4"
              >Save</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Store set form dialog -->
    <store-set-form-dialog
      ref="storeSetFormDialog"
      :is-open="storeSetFormDialogVisible"
      :current-store-set="currentStoreSet"
      @close="storeSetFormDialogVisible = false"
    />
    <!-- Delete dialog -->
    <v-dialog
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">Removing store set{{ selectedRows.length > 1 ? 's' : ''}}?</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Are you sure you want to remove the selected Store set{{ selectedRows.length > 1 ? 's' : ''}}?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteConfirm"
                class="red--text mx-4"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import columnFilters from '@/mixins/columnFilters'
import ChipsDropdown from '@/components/ChipsDropdown.vue'
import StoreSetForm from './StoreSetForm'
import TableSearch from '@/components/TableSearch.vue'
import StoreSetFormDialog from '@/components/Assortment/StoreSets/StoreSetFormDialog.vue'
import { mapState } from 'pinia'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'

import { useStockManageStore } from '@/store/pinia/stockManageStore'

export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  mixins: [columnFilters],
  data () {
    return {
      storeSetFormDialogVisible: false,
      deleteDialogVisible: false,
      formDialogVisible: false,
      storesTableFilters: [],
      columnsToSearch: ['set_name', 'stores', 'label'],
      currentStoreSet: null,
      defaultStoreSet: {
        'org_key': 0,
        'stores_set_key': null,
        'set_name': 'New stores set',
        'label': [
          'all'
        ],
        'one_stock': null,
        'country': [],
        'dc_key': null,
        'proposal_key': null,
        'properties_include': {},
        'properties_exclude': {},
        'store_keys_include': [],
        'store_keys_exclude': [],
        'static_set': false,
        'changed_at': null,
        'changed_by': null
      }
    }
  },
  components: {
    StoreSetFormDialog,
    TableSearch,
    StoreSetForm,
    ChipsDropdown,
  },
  computed: {
    ...mapState(useStoreAndOptionSetsStore, ['loadingStoreSets', 'storeSets']),
    editedStoreSet () {
      return this.stockManageStore.setsTab.editedStoreSet
    },
    selectedRows () {
      return this.storeSets.filter(r => r.selected)
    },
    storeSetFormRef () {
      return this.$refs.storeSetFormDialog.$refs.storeSetForm
    },
    deleteDisabled () {
      if (!this.selectedRows.length) {
        return true
      }
      return this.selectedRows.some(row => row.in_use)
    }
  },
  methods: {
    handleRowClick (row, col, event) {
      event.target.closest('.el-table__row').querySelector('.v-simple-checkbox').click()
    },
    handleSelectRowChange (val, row) {
      this.$set(row, 'selected', val)
    },
    handleEditClick () {
      const payload = {
        tab: 'sets',
        key: this.selectedRows[0].stores_set_key,
      }
      this.formDialogVisible = true
      this.stockManageStore.getStoreSet(payload)
        .then(() => {
          this.currentStoreSet = { ...this.editedStoreSet }
        })
    },
    handleAddClick () {
      this.formDialogVisible = true
      this.$nextTick(() => {
        this.currentStoreSet = { ...this.defaultStoreSet }
      })
    },
    handleDeleteClick () {
      this.deleteDialogVisible = true
    },
    handleDeleteConfirm () {
      this.stockManageStore.deleteStoreSet(this.selectedRows.map(r => r.stores_set_key))
        .then(async () => {
          await useStoreAndOptionSetsStore().loadStoreSets().catch(console.error)
          this.stockManageStore.getEntitiesSets()
          this.deleteDialogVisible = false
        })
    },
    handleSaveConfirm () {
      if (!this.$refs.storeSetForm.validateStoreSet()) {
        return
      }
      const payload = this.$refs.storeSetForm.getSavePayload()

      this.stockManageStore.setDialogLoading({
        tab: 'sets',
        value: true,
      })
      this.stockManageStore.saveStoreSet(payload)
        .then(() => {
          this.formDialogVisible = false
          this.resetForm()

          useStoreAndOptionSetsStore().loadStoreSets()
            .then(() => {
              this.stockManageStore.getEntitiesSets()
            })
            .catch(console.error)
            .finally(() => {
              this.stockManageStore.setDialogLoading({
                tab: 'sets',
                value: false,
              })
            })
        })
    },
    handleSaveCancel () {
      this.formDialogVisible = false
      this.resetForm()
    },
    resetForm () {
      this.currentStoreSet = null
      this.$refs.storeSetForm.resetForm()
    },
    handleStoreTagClick (row) {
      const payload = {
        tab: 'sets',
        key: row.stores_set_key,
      }
      this.storeSetFormDialogVisible = true
      this.stockManageStore.getStoreSet(payload)
        .then(() => {
          this.currentStoreSet = { ...this.editedStoreSet }
          this.$nextTick(this.storeSetFormRef.searchStores)
        })
    }
  },
}
</script>

<style lang="scss">
.assortment-store-sets {
}
</style>
