<template>
  <div class="store-sets-search-filters">
    <div class="text-body-1 font-weight-medium mt-4 pt-2 px-6">Filter</div>
    <v-container fluid>
      <div style="width: 475px">
        <v-row class="align-center">
          <v-col cols="11" class="px-6">
            <collapsible-select
              show-select-all
              autocomplete
              v-model="selectedFilters[0].values"
              :items="filter.options"
              :label="filter.label"
              hide-details="auto"
              item-text="label"
              @change="$emit('filtersChanged')"
            />
          </v-col>
          <v-col cols="1" class="pa-0 pt-6 pr-4">
            <slot></slot>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import CollapsibleSelect from '@/components/CollapsibleSelect.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  props: ['title', 'selectedFilters'],
  data () {
    return {
    }
  },
  components: {
    CollapsibleSelect,
  },
  computed: {
    ...mapState(useGeneralStore, ['mappedConfigStores']),
    filter () {
      return {
        label: 'Stores include',
        type: 'store_keys_include',
        options: this.mappedConfigStores,
      }
    }
  },
  methods: {
    validateFilters () {
      return true
    },
  },
}
</script>

<style lang="scss">
.store-sets-search-filters {
}
</style>
