<template>
  <div class="">
    <v-row class="ma-0" justify="space-between">
      <h4 class="pa-6 pl-7 pt-0 ">Suppliers</h4>
    </v-row>
    <v-row class="ma-4  mt-0 justify-end" >
      <div>
        <table-search
          @searchChanged="filters => handleSearchChanged('suppliersFilters',Object.keys(suppliers[0]), filters)"></table-search>
        <v-icon class="mx-2" @click="suppliersModalOpen = true; isEdit = true" :disabled="!selectedSuppliers">edit</v-icon>
        <v-icon class="mr-2" @click="suppliersModalOpen = true; isEdit = false">add</v-icon>
        <v-icon :disabled="!selectedSuppliers" @click="deleteDialogVisible = true">delete</v-icon>
      </div>
    </v-row>

    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <data-tables
      v-if="!loading"
      :data="suppliers"
      :filters="suppliersFilters"
      :table-props="{'row-key': 'supplier_key'}"
      :page-size="5"
      @row-click="(row)=> selectSuppliers(row)"
      @filter-change="filters => handleFilterChange('suppliersFilters', filters)"
      :pagination-props="{ pageSizes: [5, 10,15, 25, 50], class: 'el-pagination text-right mt-6 mb-4 mr-2' }"
    >
      <el-table-column
        label=""
        width="55"
      >
        <template slot-scope="scope">
          <v-simple-checkbox
            color="primary"
            dense
            hide-details
            class="ml-3"
            :value="selectedSuppliers && selectedSuppliers.supplier_key === scope.row.supplier_key"
            :ripple="false"
            :input-value="selectedSuppliers && selectedSuppliers.supplier_key === scope.row.supplier_key"
            @change="()=> selectSuppliers(scope.row)"
            @click.stop="()=> selectSuppliers(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="supplier_name"
        sortable
        label="Name"
      >
        <template slot-scope="scope">
          {{scope.row.supplier_name}}
        </template>
      </el-table-column>
      <el-table-column
        prop="country"
        sortable
        column-key="country"
        label="Country"
        :filters="getColumnFilterValues('suppliers', 'country')">
        <template slot-scope="scope">
          {{scope.row.country || '-'}}
        </template>
      </el-table-column>
      <el-table-column
        prop="currency"
        sortable
        label="Currency"
        column-key="currency"
        :filters="getColumnFilterValues('suppliers', 'currency')">
        <template slot-scope="scope">
          {{scope.row.currency || '-'}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="markup"
        label="Markup">
        <template slot-scope="scope">
          {{scope.row.markup}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="moq"
        label="MOQ">
        <template slot-scope="scope">
          {{scope.row.moq}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="schedule_key"
        label="Schedule"
        column-key="schedule_key"
        :filters="getColumnFilterValues('suppliers', 'schedule_key')">
        <template slot-scope="scope">
          {{getName(schedules, scope.row, 'schedule')}}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="payment_key"
        label="Payment"
        :filters="getColumnFilterValues('suppliers', 'payment_key')">
        <template slot-scope="scope">
          {{getName(payments, scope.row, 'payment')}}
        </template>
      </el-table-column>
    </data-tables>
    <v-divider></v-divider>
    <v-dialog
      v-model="suppliersModalOpen"
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <buy-manage-suppliers-modal
        :payments="payments"
        :costs="costs"
        v-if="suppliersModalOpen"
        @close="suppliersModalOpen = false"
        :isEdit="isEdit"
        :schedules="schedules"
        :outerSupplier="isEdit ? selectedSuppliers: null"
        @save="saveSupplier"
      ></buy-manage-suppliers-modal>
    </v-dialog>
    <v-dialog
      :value="deleteDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="p-relative rounded-0">
        <v-card-title class="text-body-1 font-weight-medium">
          Delete Suppliers
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Do you want to delete this Supplier?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="deleteDialogVisible = false"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="onDeleteClick"
                class="red--text mx-4"
              >Ok</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from '../services/axios'
import { getHeadersWithAccessToken } from '@/variables'
import columnFilters from '@/mixins/columnFilters'
import TableSearch from '../components/TableSearch'
import BuyManageSuppliersModal from './BuyManageSuppliersModal'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'
import baseURLs from '@/api/APIBaseURLs'

import { useFiltersStore } from '@/store/pinia/filtersStore'

export default {
  setup () {
    return {
      filtersStore: useFiltersStore()
    }
  },
  name: 'BuyManageSuppliers',
  mixins: [columnFilters],
  components: { BuyManageSuppliersModal, TableSearch },
  props: ['payments', 'costs', 'schedules'],
  data () {
    return {
      selectedSuppliers: null,
      suppliersFilters: [],
      suppliersModalOpen: false,
      deleteDialogVisible: false,
      isEdit: false,
      loading: false,
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['appConfig']),
    suppliers () {
      return (this.appConfig?.suppliers || [])
    },
  },
  methods: {
    selectSuppliers (supplier) {
      if (this.selectedSuppliers && this.selectedSuppliers.supplier_key === supplier.supplier_key) {
        this.selectedSuppliers = null
      } else {
        this.selectedSuppliers = supplier
      }
    },
    getName (list, value, prop) {
      if (!value[`${prop}_key`]) {
        return '-'
      }
      const filteredList = list.filter((item) => item[`${prop}_key`] === value[`${prop}_key`])
      if (filteredList.length) {
        return filteredList[0][`${prop}_name`]
      }
      return value[`${prop}_key`]
    },
    onDeleteClick () {
      const url = baseURLs.manageAPI

      axios
        .delete(url + '/supplier', {
          headers: getHeadersWithAccessToken(),
          data: { supplier_key: this.selectedSuppliers.supplier_key }
        })

        .then(async () => {
          this.deleteDialogVisible = false
          await this.$auth.refreshAppConfig()
          this.revertSuppliersFilter()
        })
    },
    revertSuppliersFilter () {
      const payload = {
        filterName: 'suppliers',
        value: this.suppliers.map(s => s.supplier_key.toString()),
      }

      this.filtersStore.setSelectedFilterOptions(payload)
      this.filtersStore.setLocalStorage('suppliers')
    },
    saveSupplier (supplier) {
      this.suppliersModalOpen = false
      this.$auth.refreshAppConfig()
        .then(() => {
          if (!this.isEdit) {
            0
            this.revertSuppliersFilter()
          }
        })

      this.selectedSuppliers = supplier
    }
  }
}
</script>

<style scoped>
</style>
