<template>
  <div class="transferDetails p-relative">
    <v-overlay absolute color="white" :value="!tableData">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row class="my-0" v-if="!isParentTable">
      <v-col class="text-right px-8">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('tableFilters', Object.keys(DataForTable[0]), val)"
        />
        <DataExport
          @item-click="downloadData"

        />
      </v-col>
    </v-row>
    <data-tables
      ref="transfersTable"
      class="table"
      :filters="isParentTable ? additionalFilters : tableFilters"
      style="overflow-y: auto"
      :data="DataForTable"
      :page-size="(DataForTable || []).length"
      :pagination-props="isParentTable ? {
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 mb-4 mr-2',
      } : {
        pageSizes: [(DataForTable || []).length],
        class: 'el-pagination text-right mt-6 mb-4 mr-2 d-none',
      }"
    >
      <slot name="append"></slot>
      <el-table-column label="Store" sortable="custom" prop="store_key" class-name="px-2" label-class-name="px-2">
        <template v-slot="scope">
          <div class="one-line" v-if="findStore(scope.row.store_key)">
            {{ scope.row.store_name }}
          </div>
          <span v-else>{{scope.row.store_key}}</span>
        </template>
      </el-table-column>

      <el-table-column label="Potential" sortable="custom" prop="potential">
        <template slot-scope="scope">
          <div class="one-line">
            {{ scope.row.potential }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Stock" sortable="custom" prop="stock">
        <template slot-scope="scope">
          <div class="one-line">
            {{ scope.row.stock }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Send" sortable="custom" prop="send">
        <template slot-scope="scope">
          <div class="one-line">
            {{ scope.row.send }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Receive" sortable="custom" prop="receive">
        <template slot-scope="scope">
          <div class="one-line">
            {{ scope.row.receive }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Goal" sortable="custom" prop="goal">
        <template slot-scope="scope">
          <div class="one-line">
            {{ scope.row.goal }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Profit" sortable="custom" prop="profit">
        <template slot-scope="scope">
          <div class="one-line">
            {{ priceFormatter(scope.row.profit) }}
          </div>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>

import columnFilters from '../../mixins/columnFilters'
import DataExport from '../DataExport'
import { downloadData } from '@/utils'
import TableSearch from '@/components/TableSearch.vue'
import { mapState } from 'pinia'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  components: { DataExport, TableSearch },
  mixins: [ columnFilters ],
  props: {
    tableData: {
      type: Array,
    },
    additionalFilters: {
      type: Array,
    },
    isParentTable: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      tableFilters: [],
    }
  },
  methods: {
    findStore (storeKey) {
      return this.findStoreWithKey(storeKey)
    },
    downloadData (sep) {
      const data = this.tableData.map((item) => {
        const store_key = item.store_key
        delete item.store_key
        return { 'Store': this.findStore(store_key).store_name, ...item }
      })
      downloadData(sep, data, 'Transfers_free_details')
    }
  },
  computed: {
    ...mapState(useGeneralStore, ['findStoreWithKey']),
    DataForTable () {
      return this.tableData?.map((item) => ({
        ...item,
        store_name: this.findStore(item.store_key)?.store_name || item.store_key,
      }))
    },
    priceFormatter () {
      const currency = this.$auth.getUserCurrency().currency || 'EUR'
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return formatter.format
    },
  }
}
</script>

<style lang="scss">
.transferDetails {
    thead {
        th {
            padding: 12px 0;
        }
    }

    .table::-webkit-scrollbar, .table .el-table__body-wrapper::-webkit-scrollbar {
      width: 4px;
    }
    .table .el-table__body-wrapper {
      max-height: 60vh;
      overflow-y: auto;
    }

    .table::-webkit-scrollbar-track, .table .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    }

    .table::-webkit-scrollbar-thumb, .table .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 3px;
    }
    .pagination-wrap {
      background: transparent;
    }
    .v-select__selection {
        font-size: 14px;
    }
}
</style>
