<template>
  <v-card class="my-6" min-height="350px">
    <h4 class="px-4 py-4">Tasks</h4>
    <v-tabs v-model="activeTab" grow show-arrows>
      <v-tabs-slider />
      <v-tab
        v-for="item of tabs"
        :key="item"
        class="px-8"
      >
        <!-- show badge for alerts tab and only if there is any alert -->
        <template v-if="item.includes('alerts') && tasksStore.tasks.alerts.data.filter(x => !x.handled).length">
          <v-badge color="red" dot>
            {{ formatKey(item) }}
          </v-badge>
        </template>
        <template v-else>
          {{ formatKey(item) }}
        </template>
      </v-tab>
    </v-tabs>

    <app-stock-tasks-table
      :key="activeTab"
      :taskType="formatForTable(tabs[activeTab])"
    ></app-stock-tasks-table>
  </v-card>
</template>

<script>
import StockTasksTable from '@/components/Home/StockTasksTable.vue'
import { useStockTasksStore } from '@/store/pinia/tasksStore'

export default {
  setup () {
    return {
      tasksStore: useStockTasksStore()
    }
  },
  computed: {
    tabs () {
      const data = this.tasksStore.taskEndpoints
      return data.sort()
    },
    activeTab: {
      set (val) {
        this.tasksStore.updateTasksTab(val)
      },
      get () {
        return this.tasksStore.tasksActiveTab
      },
    },
  },

  methods: {
    formatKey (key) {
      if (key) {
        key = key.substr(2)
        key = key.replace('_', ' ')
        key = key.replace('_', ' ')
        return key.charAt(0).toUpperCase() + key.slice(1)
      }
    },
    formatForTable (key) {
      if (key) {
        return key.substr(key.indexOf('_') + 1)
      }
    }
  },
  components: {
    appStockTasksTable: StockTasksTable,
  },
}
</script>

<style lang="scss">
</style>
