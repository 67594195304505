<template>
  <div class="stock-assortment-page mb-12">
    <h3 class="mb-6 mt-6">Manage</h3>

    <v-card>
      <v-tabs v-model="activeTab" grow show-arrows>
        <v-tabs-slider />
        <v-tab class="px-8">Sets</v-tab>
        <v-tab class="px-8">Clusters</v-tab>
        <v-tab class="px-8">Timelines</v-tab>
        <v-tab class="px-8">Capacity</v-tab>
        <v-tab class="px-8">Factors</v-tab>
        <v-tab class="px-8">Transfers</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item :transition="false" :reverse-transition="false">
          <OptionSets />
          <v-divider class="mt-2" />
          <storeSets />
        </v-tab-item>

        <v-tab-item eager :transition="false" :reverse-transition="false">
          <Clusters />
        </v-tab-item>

        <v-tab-item :transition="false" :reverse-transition="false">
          <Timelines />
        </v-tab-item>

        <v-tab-item eager :transition="false" :reverse-transition="false">
          <CapacityOptionDynamic :skus="skus" />
          <v-divider class="mt-6" />
          <GroupTable :skus="skus" />
          <v-divider />
          <BlocksTable />
        </v-tab-item>

        <v-tab-item eager :transition="false" :reverse-transition="false">
          <Factors />
        </v-tab-item>
        <v-tab-item eager :transition="false" :reverse-transition="false">
          <StockManageTransfers
            :store-sets="storeSets"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { findOptionsByFiltersTSQL } from '@/api/options'
import OptionSets from '@/components/Assortment/OptionSets/OptionSets.vue'
import storeSets from '@/components/Assortment/StoreSets/StoreSets.vue'
import Clusters from '@/components/Assortment/Clusters.vue'
import Timelines from '@/components/Assortment/Timelines.vue'
import Factors from '@/components/Assortment/Factors.vue'
import CapacityOptionDynamic from '@/components/Assortment/LowLevelCapacityOption.vue'
import GroupTable from '@/components/Assortment/GroupTable'
import { loadManageData } from '@/store/manageDataLoader'
import BlocksTable from '@/components/Assortment/BlocksTable.vue'
import StockManageTransfers from '@/components/Transfers/StockManageTransfers.vue'
import { mapState } from 'pinia'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'
import { useGeneralStore } from '@/store/pinia/generalStore'

import { useStockManageStore } from '@/store/pinia/stockManageStore'
import { getSKUNames } from '@/api/skusAPI'

export default {
  setup (){
    return {
      stockManageStore: useStockManageStore()
    }
  },
  data () {
    return {
      activeTab: 0,
      tabs: ['sets', 'clusters', 'timelines', 'capacity', 'factors', 'transfers'],
      skus: [],
    }
  },
  components: {
    StockManageTransfers,
    BlocksTable,
    GroupTable,
    OptionSets,
    storeSets,
    Clusters,
    Timelines,
    Factors,
    CapacityOptionDynamic,
  },
  computed: {
    ...mapState(useStoreAndOptionSetsStore, ['storeSets']),
    ...mapState(useGeneralStore, ['appConfig']),
  },
  created () {
    loadManageData()
    this.loadSKUNames()
    this.loadReorderOptions()
  },
  destroyed () {
    this.stockManageStore.setOptionsList([])
  },
  mounted () {
    if (this.$route.query.tab) {
      this.activeTab = this.tabs.indexOf(this.$route.query.tab)
    }
    this.setStoresSetsLabel (this.tabs[this.activeTab])
  },
  watch: {
    activeTab (val) {
      this.setStoresSetsLabel (this.tabs[val])
      if (this.$route.query.tab === this.tabs[val]) {
        return
      }
      const newRoute = { ...this.$route, query: { ...this.$route.query, tab: this.tabs[val] } }
      this.$router.replace(newRoute)
    },
  },
  methods: {
    async setStoresSetsLabel (label){
      const generalStore = useGeneralStore()
      if(label === 'sets'){
        generalStore.setCurrentStoresSetsLabel('all')
        await useStoreAndOptionSetsStore().loadStoreSets()
        await useStoreAndOptionSetsStore().loadOptionSets()
      }else{
        generalStore.setCurrentStoresSetsLabel('stock')
        await useStoreAndOptionSetsStore().loadStoreSets()
        await useStoreAndOptionSetsStore().loadOptionSets()

      }
      this.stockManageStore.getEntitiesSets()
    },
    async loadSKUNames () {
      this.skus = getSKUNames()
    },
    async loadReorderOptions () {
      const payload = {
        search_text: null,
        discount_min: null,
        discount_max: null,
        stock_active: null,
        osp_min: null,
        osp_max: null,
        asp_min: null,
        asp_max: null,
        stock_min: null,
        stock_max: null,
        inbounds_min: null,
        inbounds_max: null,
        option_keys_include: [],
        option_keys_exclude: [],
        properties_include: {},
        properties_exclude: {},
        filters: [],
      }
      return findOptionsByFiltersTSQL(payload, ['option_key', 'name'])
        .then(response => {
          this.stockManageStore.setOptionsList(Object.freeze(response.options))
        })
    },
  },
}
</script>

<style lang="scss">
.stock-assortment-page {}
</style>
