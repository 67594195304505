import Vue from 'vue'
import { format } from 'date-fns'
import { excludeFormatList } from './variables'
import { formatMillions, formatString, formatThousands, formatValueWithCurrency } from '@/utils'

Vue.filter('formatStoreName', function (value) {
  if (!value) {
    return ''
  }
  if (window.innerWidth >= 1600) {
    return value
  } else if (window.innerWidth < 1600 && window.innerWidth >= 1400) {
    return value.substring(0, 17)
  } else if (window.innerWidth < 1400 && window.innerWidth >= 1000) {
    return value.substring(0, 15)
  } else if (window.innerWidth < 1000) {
    return value.substring(0, 10)
  }
})

Vue.filter('formatString', formatString)

Vue.filter('formatStringNoUpperCase', function (value) {
  if (typeof value === 'boolean') {
    value = value ? 'yes' : 'no'
  }

  let variable = value.replace('_', ' ')
  variable = variable.replace('_', ' ')
  variable = variable.replace('_', ' ')
  variable = variable.replace('_', ' ')
  return variable
})

Vue.filter('capitalize', function (value) {
  if (!value) {
    return ''
  }
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('currency', function (value) {
  const val = String(value)
  return val.split('$').join('€')
})

Vue.filter('twoDecimals', function (value, header) {
  if (!value || excludeFormatList.includes(header)) {
    return value
  }
  return value.toLocaleString('en-GB', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
})

Vue.filter('toFixedZero', function (value, header) {
  if (!value || excludeFormatList.includes(header)) {
    return value
  }
  if (isNaN(value)) {
    return value
  }
  return value.toFixed(0)
})

Vue.filter('formatThousands', function (value, header) {
  if (!value || excludeFormatList.includes(header) || typeof value === 'string') {
    return value
  }
  return value.toLocaleString('en-GB')
})

Vue.filter('formatDate', function (value, pattern) {
  return format(new Date(value), pattern)
})

Vue.filter('splitFactorsKey', function (value) {
  return value.split('-')[0]
})

Vue.filter('delta', function (value, old) {
  const delta = value - old
  if (delta > 0) {
    return '+' + delta
  } else {
    return delta
  }
})

Vue.filter('addSign', function (value) {
  return (parseInt(value) >= 0 ? '+' : '') + value
})

Vue.filter('formatWeekNumber', function (value) {
  const valueStr = value.toString()

  if (valueStr.length < 4) {
    return ''
  }

  const year = valueStr.substring(0, 4)
  const week = valueStr.substring(4, 6)

  return `${year} - W${week}`
})

Vue.filter('formatPercents', function (value) {
  return Math.round(parseFloat(value) * 100)
})

Vue.filter('roundToStep', function (value, step) {
  return Math.round(value / step) * step
})

Vue.filter('currency_no_decimals', function (value) {
  if (Math.abs(value) > 1_000_000) {
    return (value < 0 ? '-€' : '€') + formatMillions(Math.abs(value))
  } else {
    return formatValueWithCurrency(value, 0)
  }
})

Vue.filter('currency', function (value) {
  if (Math.abs(value) > 1_000_000) {
    return '€' + formatMillions(value)
  } else {
    return formatValueWithCurrency(value, 2)
  }
})

Vue.filter('percentage', function (value) {
  if (value === null) {
    return ''
  }

  return value + '%'
})

Vue.filter('integer', function (value) {
  if (value === null) {
    return ''
  }

  value = Math.round(value)

  if (Math.abs(value) > 1_000_000) {
    return formatMillions(value)
  } else {
    return formatThousands(value)
  }
})
