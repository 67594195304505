<template>
  <v-dialog
    v-model="isOpen"
    persistent
    content-class="align-self-start mt-14"
    width="600px"
    max-width="90%">
    <v-card class="p-relative rounded-0">
      <v-card-title class="text-body-1 font-weight-medium">
        {{isAdd? "Add" : "Edit"}} {{ title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              class="pr-4 mt-5"
              v-model="name"
              :value="value"
              placeholder="Name"
              :label="textFieldLabel"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="dialog-footer text-right py-2">
          <v-col>
            <v-btn
              color="primary"
              depressed
              text
              @click="$emit('close')"> Cancel </v-btn>
            <v-btn
              depressed
              outlined
              @click="onSave"
              class="primary--text mx-4"> {{isAdd ? 'Add' : 'Save'}} </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'NameDialog',
  props: {
    isAdd: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    textFieldLabel: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      name: this.value || '',
    }
  },
  watch: {
    value (val) {
      this.name = val
    },
    isAdd (val) {
      if (val) {
        this.name = ''
      } else {
        this.name = this.value
      }
    }
  },
  methods: {
    onSave () {
      if (this.isAdd) {
        this.$emit('add', this.name)
      } else {
        this.$emit('save', this.name)
      }
      this.$emit('close')
    }
  }
}
</script>
