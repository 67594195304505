<template>
  <div class="option-timeline p-relative">
    <v-overlay absolute color="white" :value="loadingTimeline || gettingProposal">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row>
      <v-col class="pt-8 timeline-ruleblocks">
        <div
          v-for="(setting, index) in proposalSettings"
          :key="index"
          class="d-flex px-2"
        >
          <div class="ruleblock-mark p-relative d-flex align-start pt-4">
            <v-icon
              :color="setting.id === currentSetting.id ? 'primary' : null"
              @click="handleRuleblockClick(setting)"
            >radio_button_checked</v-icon>
          </div>
          <div class="mb-4 grow ruleblock-content">
            <div class="p-relative" >
              <v-overlay absolute color="white" :value="loadingRuleblockId === setting.id">
                <v-progress-circular color="primary" indeterminate size="64" width="2" />
              </v-overlay>
              <v-row class="align-center ruleblock-header" @click="handleRuleblockClick(setting)">
                <v-col cols="6" lg="4">
                  <div class="d-flex pt-6 pb-4 pl-5">
                    <v-select
                      @click.native.stop
                      v-model="setting.block_key"
                      :items="defaults"
                      item-text="block_name"
                      item-value="block_key"
                      hide-details
                      class="pt-0 mr-10 block-name-select"
                      :menu-props="{
                        left: true,
                        offsetY: true,
                        contentClass: 'select-dropdown-menu',
                      }"
                      @change="updateSetting(setting, index)"
                    />
                    <v-menu
                      :close-on-content-click="false"
                      min-width="auto"
                      offset-x
                      class="date-picker"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="pt-0"
                          v-model="setting.date_min"
                          readonly
                          hide-details
                          label="Start date"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :first-day-of-week="1"
                        :locale-first-day-of-year="4"
                        :show-week="true"
                        v-model="setting.date_min"
                        :min="getMinAllowedDate(setting)"
                        :max="getMaxAllowedDate(setting)"
                        @input="datepickerOpened = false"
                        @change="updateSetting(setting, index)"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
                <v-col cols="2" offset="4" offset-lg="6">
                  <div class="d-flex justify-end pr-4">
                    <v-menu :close-on-content-click="false" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="action-btn-primary" v-bind="attrs" v-on="on">tune</v-icon>
                      </template>

                      <v-card>
                        <h4 class="ml-4 mb-6 pt-6 edit-parameters-title">
                          Edit parameters
                        </h4>
                        <Parameters
                          v-for="(value, name, pIndex) in setting.parameters"
                          :index="index"
                          :setting="setting"
                          :parameterName="name"
                          :key="pIndex"
                          @changed="updateSetting(setting, index)"
                        />
                      </v-card>
                    </v-menu>
                    <v-icon
                      class="ml-6 mr-4 action-btn-danger"
                      :disabled="proposalSettings.length === 1"
                      @click.stop="deleteSetting(setting)"
                    >delete</v-icon>
                  </div>
                </v-col>
              </v-row>
              <v-container fluid class="pt-0" v-if="currentSetting.id === setting.id">
                <v-row class="bordered-list-header mt-2">
                  <v-col class="pl-5 pr-0">Rule</v-col>
                  <v-col class="pl-9 pr-0">Settings</v-col>
                  <v-col class="pl-0">Stock impact</v-col>
                </v-row>

                <v-row
                  v-for="(rule, rIndex) in setting.rules"
                  :key="rule.id"
                  class="business-rule-row"
                >
                  <BusinessRule
                    class="drag-item"
                    :setting="setting"
                    :rule="rule"
                    :index="rIndex"
                    @changed="updateSetting(setting, index)"
                  />
                </v-row>
              </v-container>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="ruleblock-mark p-relative d-flex align-start px-2 pt-4">
      <v-icon @click="addSetting()">add</v-icon>
    </div>

    <!-- Delete ruleblock confirm dialog -->
    <v-dialog
      :value="deleteRuleblockDialogVisible"
      persistent
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%">
      <v-card class="p-relative rounded-0">
        <v-overlay absolute color="white" :value="deletingRuleblock">
          <v-progress-circular color="primary" indeterminate size="64" width="2" />
        </v-overlay>
        <v-card-title class="text-body-1 font-weight-medium">
          Delete ruleblock
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-body-1 pt-8">Delete this ruleblock from timeline?</div>
        </v-card-text>
        <v-card-actions>
          <v-row class="dialog-footer text-right py-2">
            <v-col>
              <v-btn
                color="primary"
                depressed
                text
                @click="handleDeleteRuleblockCancel"
              >Cancel</v-btn>
              <v-btn
                depressed
                outlined
                @click="handleDeleteRuleblockConfirm"
                class="red--text mx-4"
              >Ok</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { addDays, endOfDay } from 'date-fns'
import * as optionsApi from '@/api/options'
import { serializeObjToQuery } from '@/utils'
import BusinessRule from './BusinessRule.vue'
import Parameters from './Parameters'

import { useStockProposalStore } from '@/store/pinia/stockProposalStore'

import { useArticleStore } from '@/store/pinia/stockArticlesStore'

export default {
  setup () {
    return {
      articlesStore: useArticleStore(),
      proposalStore: useStockProposalStore()}
  },
  components: {
    BusinessRule,
    Parameters,
  },
  props: {
    gettingProposal: {
      type: Boolean,
      default (){
        return false
      }
    }
  },
  data () {
    return {
      datepickerOpened: false,
      deletingRuleblock: false,
      loadingTimeline: false,
      loadingRuleblockId: null,
      deleteRuleblockDialogVisible: false,
      ruleblockToDelete: null,
    }
  },
  computed: {
    proposalSettings () {
      return this.proposalStore.getProposalSettings
    },
    currentSetting () {
      return this.proposalStore.getCurrentSetting
    },
    defaults () {
      return this.proposalStore.getDefaults.filter(d => d.active)
    },
    savingProposal () {
      return this.proposalStore.savingProposal
    }
  },
  methods: {
    deleteSetting (setting) {
      if (this.proposalSettings.length > 1) {
        this.deleteRuleblockDialogVisible = true
        this.ruleblockToDelete = { ...setting }
      }
    },
    getProposalSetting (settingId) {
      this.loadingTimeline = true
      this.proposalStore.setNewProposalSetting( {
        route: serializeObjToQuery(this.$route.query) + '&id=' + settingId,
      })
        .then(() => {
          this.loadingTimeline = false
          this.$emit('settingLoaded')
        })
    },
    addSetting () {
      this.loadingTimeline = true
      this.proposalStore.addProposalSetting( {
        route: serializeObjToQuery(this.$route.query),
      })
        .then(() => {
          this.loadingTimeline = false
        })
      this.setOptionActive()
    },
    updateSetting (setting, idx) {
      const ruleDefaults = this.defaults.find(d => d.block_key === setting.block_key)
      const typeChanged = this.proposalSettings[idx].type !== ruleDefaults.block_name
      const newSetting = {
        ...setting,
        rules: typeChanged ? ruleDefaults.rules : this.proposalSettings[idx].rules,
        parameters: typeChanged ? ruleDefaults.parameters : this.proposalSettings[idx].parameters,
        type: ruleDefaults.block_name,
      }

      this.loadingRuleblockId = setting.id
      this.proposalStore.setProposalSetting(newSetting)

      if (setting.id === this.currentSetting.id) {
        this.proposalStore.setProposalSetting(newSetting)
      }

      this.proposalStore.saveRuleblock( {
        setting: newSetting,
        query: serializeObjToQuery(this.$route.query),
      })
        .then(() => {
          this.loadingRuleblockId = null
        })

      this.setOptionActive()
      // when changing opened ruleblock, stores chart should be also reloaded
      this.$emit('settingUpdated')
    },
    setOptionActive () {
      if (!this.articlesStore.articleSettings['stock_active']) {
        this.$set(
          this.articlesStore.articleSettings,
          'stock_active',
          true
        )

        optionsApi.saveOptionSettings(this.articlesStore.articleSettings, this.$route.query)
      }
    },
    handleRuleblockClick (setting) {
      if (this.currentSetting.id !== setting.id) {
        this.getProposalSetting(setting.id)
      }
    },
    handleDeleteRuleblockCancel () {
      this.deleteRuleblockDialogVisible = false
      this.ruleblockToDelete = null
    },
    handleDeleteRuleblockConfirm () {
      if (this.proposalStore.proposalSettings.length > 1) {
        this.deletingRuleblock = true
        this.proposalStore.deleteProposalSetting( {
          route: serializeObjToQuery(this.$route.query) + '&id=' + this.ruleblockToDelete.id,
          id: this.ruleblockToDelete.id,
        })
          .then(() => {
            if (this.ruleblockToDelete.id === this.currentSetting.id) {
              this.getProposalSetting(this.proposalStore.proposalSettings[0].id)
            }
            this.deletingRuleblock = false
            this.deleteRuleblockDialogVisible = false
            this.ruleblockToDelete = null
            this.$emit('settingDeleted')
          })
      }

      this.setOptionActive()
    },
    getMinAllowedDate (setting) {
      const idx = this.proposalSettings.findIndex(x => x.id === setting.id)

      // if no previous block
      if (!this.proposalSettings[idx - 1]) {
        return null
      } else {
        // using endOfDay to negate timezone influence
        return addDays(endOfDay(new Date(this.proposalSettings[idx - 1].date_min)), 1).toISOString()
      }
    },
    getMaxAllowedDate (setting) {
      const idx = this.proposalSettings.findIndex(x => x.id === setting.id)

      // if no next block
      if (!this.proposalSettings[idx + 1]) {
        return null
      } else {
        return this.proposalSettings[idx + 1].date_min
      }
    },
  },
}
</script>
<style lang="scss">
.option-timeline {
    .timeline-ruleblocks {
        .ruleblock-mark {
            width: 40px;
            &:before {
                content: '';
                position: absolute;
                border-left: 1px solid var(--border-color);
                height: calc(100% - 30px);
                left: 11px;
                top: 43px;
            }
            .v-icon {
                cursor: pointer;
            }
        }
        .ruleblock-header {
            cursor: pointer;
        }
        .ruleblock-content {
            border: 1px solid var(--border-color);
            .block-name-select {
                width: 200px;
            }
        }
        .new-ruleblock {
            .ruleblock-mark {
                &:before,
                &:after {
                    content: none;
                }
            }
        }
        .business-rule-row {
            border-bottom: 1px solid var(--border-color);
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}
</style>
