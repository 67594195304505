<template>
  <div class="col-12 pb-0"
    v-if="reorderOptions">
    <v-row>
      <v-col class="text-right pr-6">
        <TableSearch
          class="mx-2"
          @searchChanged="val => handleSearchChanged('reorderOptionsFilters', sku_table_perc.columns, val)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <data-tables
          ref="dataTables"
          style="width: 100%"
          class="main-table"
          :key="tableKey"
          :data="reorderOptionsForTable"
          :filters="reorderOptionsFilters"
          :page-size="10"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 mb-4 mr-2 d-none',
          }"
        >
          <el-table-column
            prop="Name"
            label="Name"
            sortable
            class-name="pl-4"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.editable">
                Carry Over
              </div>
              <div v-else>
                Current stock
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="col in Object.keys(reorderOptions)"
            :key="col"
            :prop="col"
            :label="col"
            sortable
            class-name="pl-4"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.editable">
                <v-text-field
                  type="number"
                  v-model.number="scope.row[col]"
                  :error-messages="errors[col]"
                ></v-text-field>
              </div>
              <div v-else>
                {{ scope.row[col] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            key="Total"
            prop="Total"
            label="Total"
            sortable
            class-name="pl-4"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.editable">
                <v-text-field
                  type="number"
                  :value="calculateSumOfRow(scope.row)"
                  @input="calculateSumDebounce($event, scope.row)"
                  :error-messages="errors.total"
                >

                </v-text-field>
              </div>
              <div v-else>
                {{ calculateSumOfRow(scope.row) }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="Percentage"
            label="Percentage"
            sortable
            class-name="px-4"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.editable">
                <v-text-field
                  :value="calculatePercOfRow(scope.row)"
                  @input="calculatePercDebounce($event, scope.row)"
                  suffix="%"
                  type="number"
                  :error-messages="errors.perc"
                >

                </v-text-field>
              </div>
              <div v-else>
                100%
              </div>
            </template>
          </el-table-column>
        </data-tables>
        <v-row class="justify-end ma-0 pt-5">
          <v-btn
            color="primary"
            depressed
            outlined
            class="mx-4"
            @click="saveCarryOver"
          >Save</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </div>

</template>

<script>

import TableSearch from '@/components/TableSearch.vue'
import _ from 'lodash'

export default {
  name: 'ShopItemCarryOver',
  props: {
    reorderOptions: {
      default: null
    },
    carryOver: {
      default: null
    }
  },
  computed: {
    reorderOptionsForTable () {
      const carryOver = Object.keys(this.carryOver).length ? this.carryOver : this.reorderOptions
      return [this.reorderOptions, { ...carryOver, editable: true }]
    }
  },
  data () {
    return {
      reorderOptionsFilters: [],
      calculateSumDebounce: null,
      calculatePercDebounce: null,
      tableKey: 0,
      errors: {}
    }
  },
  methods: {
    saveCarryOver () {
      const changedCarryOver = { ...this.reorderOptionsForTable[1] }
      for (const key in changedCarryOver) {
        if (!changedCarryOver[key]) {
          changedCarryOver[key] = 0
        }
        if (changedCarryOver[key] < 0) {
          this.errors[key] = 'Value must be positive'
        } else {
          delete this.errors[key]
        }
      }
      this.errors = { ...this.errors }
      if (Object.keys(this.errors).length) {
        return
      }
      delete changedCarryOver.editable
      this.$emit('carry-over-changed', changedCarryOver)
    },
    onTotalsChange (input, row) {
      input = parseFloat(input)
      if (input < 0 || !_.isInteger(input)) {
        this.errors.perc = 'Value must be positive'
        this.errors = { ...this.errors }
        return
      }
      delete this.errors.total
      this.errors = { ...this.errors }
      const oldSum = this.calculateSumOfRow(row)
      const newObject = { ...row }
      for (const key in row) {
        if (key === 'editable') {
          continue
        }
        newObject[key] = input ? parseInt(row[key] / oldSum * input) : 0
      }
      this.$set(this.reorderOptionsForTable, 1, newObject)
      this.tableKey++
    },
    onPercChange (input, row) {
      input = parseFloat(input)
      if (input < 0 || !_.isInteger(input)) {
        this.errors.perc = 'Value must be positive integer'
        this.errors = { ...this.errors }
        return
      }
      delete this.errors.perc
      this.errors = { ...this.errors }
      const newObject = { ...row }
      for (const key in row) {
        if (key === 'editable') {
          continue
        }
        newObject[key] = input ? parseInt(this.reorderOptionsForTable[0][key] * input / 100) : 0
      }
      this.$set(this.reorderOptionsForTable, 1, newObject)
      this.tableKey++
    },
    calculateSumOfRow (row) {
      return Object.values(row).filter(item => item !== true).reduce((prev, curr) => prev + curr, 0)
    },
    calculatePercOfRow () {
      const sum1 = this.calculateSumOfRow(this.reorderOptionsForTable[0])
      const sum2 = this.calculateSumOfRow(this.reorderOptionsForTable[1])
      return _.round(sum2 / sum1 * 100)
    }
  },
  mounted () {
    this.calculateSumDebounce = _.debounce(this.onTotalsChange, 500)
    this.calculatePercDebounce = _.debounce(this.onPercChange, 500)
  },
  components: { TableSearch }
}
</script>

<style scoped>

</style>
