<template>
  <div class="p-relative" style="height: 90%">
    <div
      v-show="loaded"
      :class="forcedHeight ? null : (fixedHeight ? 'fixed-height-chart' : 'not-fixed')"
      :id="'chart-container-'+title"
      :style="padding ? {
        'position': 'relative',
        'padding-left': '50px',
        'padding-right': '50px',
        'height': forcedHeight ? `${forcedHeight} !important` : '450px',
        'width': null,
      } : {
        'position': 'relative',
        'padding-left': '0px',
        'padding-right': '0px',
        'height': forcedHeight ? `${forcedHeight} !important` : '100%',
        'width': '100%'
      }">
      <canvas :id="`${id|| title}`"></canvas>
    </div>

    <v-overlay absolute color="white" :value="!loaded">
      <v-progress-circular color="primary" indeterminate size="64" width="2"/>
    </v-overlay>
  </div>
</template>

<script>
import { dataConvertDict } from './ChartData.js'
import ChartClass from '../chart/ChartClass.js'

const overTimeTitles = ['stock_cover', 'popularity_differences', 'cover_outliers', 'cover_differences', 'option_differences']
export default {
  props: ['chartData', 'params', 'padding', 'title', 'fixedHeight', 'forcedHeight', 'dataType', 'id', 'avoidRedraw'],
  data () {
    this.chart = null

    return {
      loaded: false,
    }
  },
  watch: {
    chartData () {
      this.drawGraph()
    },
  },
  methods: {
    drawGraph (optionKey = undefined) {
      if (!Object.keys(this.chartData || {}).length) {
        this.loaded = true

        return
      }
      this.loaded = false
      const ctx = document.getElementById(`${this.id || this.title}`)?.getContext('2d')
      if (!ctx && this.counter < 10) {
        setTimeout(() => this.drawGraph(), 300)
        this.counter++
        return
      }
      let params = JSON.parse(JSON.stringify(this.params))
      if (optionKey !== undefined) {
        params['option_key'] = optionKey
      }

      if (overTimeTitles.includes(this.title)) {
        params = {
          weeks: 12,
          depth: 0,
          group: 'All',
        }
      }
      this.$emit('loaded', { isEmpty: !this.chartData.length })
      const chartData = dataConvertDict[this.title](this.chartData)
      if (params.order_parameters) {
        chartData.daysCover = params.order_parameters.leadtime * 7
      }
      if (this.chart && !this.avoidRedraw) {
        this.chart.delete()
      }

      chartData.onClick = (data) => {
        this.$emit('chart-click', data)
      }

      if (!this.chart || !this.avoidRedraw) {
        setTimeout(() => {
          this.chart = new ChartClass(
            ctx,
            chartData['labels'],
            chartData['data'],
            chartData['scales'],
            chartData['type'],
            chartData['lineToday'],
            chartData['lineTodayColor'],
            chartData['daysCover'],
            chartData['week'],
            chartData.plugins?.legend,
            chartData['onClick'],
            chartData.plugins?.annotation,
            true
          )
        })
      } else {
        this.chart.update(chartData['labels'], chartData['data'], chartData['type'], chartData['scales'])
      }
      this.$emit('loaded')
      this.loaded = true
      const container = document.getElementById('chart-container-' + this.title)
      container.style.height = '350px'
      if (this.forcedHeight) {
        container.style.height = this.forcedHeight
      } else if (this.title === 'prediction' || this.title === 'popularity') {
        container.style.height = '430px'
      } else if (['transfers_profit_chart', 'transfers_pieces_chart'].includes(this.title)) {
        container.style.height = '220px'
      }
    },
  },
  mounted () {
    this.counter = 0
    this.drawGraph()
  }
}
</script>

<style scoped>
.fixed-height-chart {
    height: 450px !important;
}

.not-fixed {
    height: 400px !important;
}

.progress {
    width: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
