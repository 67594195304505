<template>
  <div id="strategy-cube-select">
    <v-autocomplete
      hide-details="auto"
      :items="cubeVersions"
      :clearable="clearable"
      :disabled="disabled"
      class="pt-0"
      :filter="mainFilter"
      :label="inputLabel"
      v-model="currentCubeVersion"
      @input="onCubeVersionChange"
      :prefix="prefix"
      :menu-props="{
        left: true,
      }"
    />
  </div>
</template>

<script>
import { formatString } from '@/variables'
import { headersFilters } from '@/utils'
import { useCubesStore } from '@/store/pinia/cubesStore'

export default {
  name: 'StrategyCubeSelect',
  setup () {
    return {
      cubesStore: useCubesStore()
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    connectToPage: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String || null,
      default: null,
    },
  },
  data () {
    return {
      currentCube: null,
      currentVersion: null,
      currentCubeVersion: null,
    }
  },
  computed: {
    prefix () {
      if (!this.currentCubeVersion?.cube) {
        return ''
      }
      if (!this.cubesStore.cubeKeysToNames.has(this.currentCubeVersion.cube)) {
        return ''
      }
      return this.cubesStore.cubeKeysToNames.get(this.currentCubeVersion.cube) + ' / '
    },
    cubeList () {
      return this.cubesStore.cubesBrowser?.cube_keys ?? []
    },
    versionList () {
      return this.cubesStore.cubesBrowser?.version_keys ?? []
    },
    actions () {
      return this.cubesStore.cubesBrowser?.actions?.slice(0, 2).map((action) => {
        return {
          text: formatString(action.action),
          value: action,
        }
      }) ?? []
    },
    cubeVersions () {
      const list = []
      for (const cube of this.cubeList) {
        list.push({
          header: this.cubesStore.cubeKeysToNames.get(cube) || cube,
        })
        for (const version of this.versionList[cube]) {
          list.push({
            text: version,
            value: {
              cube: cube,
              version: version,
            }
          })
        }
      }

      return list
    },
    inputLabel () {
      if (this.label) {
        return this.label
      }

      return this.currentCubeVersion?.cube ? '' : 'Cube version'
    }
  },
  async mounted () {
    if (!this.cubesStore.cubesBrowser) {
      await Promise.all([ this.cubesStore.loadCubes(), this.cubesStore.loadCubesBrowser() ])
    }

    this.getInitialValues()

    let cubeInputData = this.cubesStore.cubeInputData
    if (!cubeInputData) {
      // select 1st option in selector in case if nothing was selected during current session
      if (!this.currentCube || !this.currentVersion) {
        const firstEntry = this.cubeVersions.find(cv => !cv.header)
        this.currentCubeVersion = {
          cube: firstEntry.value.cube,
          version: firstEntry.value.version
        }

        return this.onCubeVersionChange(this.currentCubeVersion)
      }

      cubeInputData = await this.loadCubeInputs()
    }
    if(this.$route.query.cube){
      localStorage.setItem('strategyCubeKey', this.$route.query.cube)
    }
    if(this.$route.query.version){
      localStorage.setItem('strategyVersionKey', this.$route.query.version)
    }
    if(!this.$route.query.version && !this.$route.query.cube){
      this.$router.push({...this.$route, query: {...this.$route.query, ...this.currentCubeVersion}})
    }
    this.$emit('cube-inputs-loaded', cubeInputData)
  },
  methods: {
    mainFilter: headersFilters,
    async loadCubeInputs (forceReload = false) {
      const payload = {
        cubeKey: this.currentCube,
        versionKey: this.currentVersion,
        forceReload,
      }
      return this.cubesStore.getAndLoadCubeInputs(payload)
    },
    getInitialValues () {
      const cube = this.$route.query.cube || localStorage.getItem('strategyCubeKey')
      const version = this.$route.query.version || localStorage.getItem('strategyVersionKey')
      if (cube) {
        this.currentCube = cube
      }
      if (version) {
        this.currentVersion = version
      }
      this.currentCubeVersion = {
        cube: this.currentCube,
        version: this.currentVersion,
      }
    },
    async onCubeVersionChange (cubeVersion) {
      if (!cubeVersion) {
        return
      }
      if (this.currentCube !== cubeVersion.cube) {
        this.currentVersion = null
        this.currentCube = cubeVersion.cube

        if (this.connectToPage) {
          localStorage.setItem('strategyCubeKey', cubeVersion.cube)
        }
      }
      if (this.currentVersion !== cubeVersion.version) {
        this.currentVersion = cubeVersion.version

        if (this.connectToPage) {
          localStorage.setItem('strategyVersionKey', cubeVersion.version)
        }
      }

      if (this.connectToPage) {
        this.$router.push({...this.$route, query: {...this.$route.query, ...this.currentCubeVersion}})
      }

      this.$emit('cube-inputs-changed')

      if (this.connectToPage) {
        const cubeInputData = await this.loadCubeInputs(true)
        this.$emit('cube-inputs-loaded', cubeInputData)
      }
    },
  },
}
</script>

<style lang="scss">
#strategy-cube-select {
    .v-text-field__prefix {
        font-weight: bold!important;
    }
}
</style>
