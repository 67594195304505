<template>
  <resizable
    id="strategy-collection-overview"
    class="p-relative"
    @resize="updateTotalTableWidth"
  >
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>

    <v-row>
      <div class="col text-right">
        <TableSearch
          @searchChanged="val => handleSearchChanged('tableFilters', columnsToSearch, val)" />
        <DataExport
          class="ml-2 text-left"
          @item-click="downloadOverviewData"
        />
        <v-menu
          :close-on-content-click="false"
          attach="#strategy-collection-overview"
          offset-y
          left
          min-width="450"
          max-width="450"
          nudge-bottom="20"
          content-class="settings-menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-2 action-btn-primary" v-bind="attrs" v-on="on">tune</v-icon>
          </template>

          <v-card>
            <v-tabs v-model="settingsActiveTab">
              <v-tabs-slider />
              <v-tab disabled>Dimensions</v-tab>
              <v-tab>Metrics</v-tab>
              <v-tab>References</v-tab>
            </v-tabs>
            <v-tabs-items v-model="settingsActiveTab" class="mt-2 pb-6 px-3">
              <v-tab-item :transition="false" :reverse-transition="false" eager>
                <div class="dimensions-list-wrapper">
                  <template v-for="prop, index in cubePropertiesGrouped">
                    <h5
                      v-if="prop.header"
                      :key="index + 'header'"
                      class="mt-2 mb-2"
                    >{{ prop.header }}</h5>
                    <v-checkbox
                      v-else
                      v-model="selectedDimensions"
                      :key="index"
                      :label="prop.text + (selectedDimensions.indexOf(prop.value) !== -1 ? `(${selectedDimensions.indexOf(prop.value) + 1})` : '')"
                      :value="prop.value"
                      :ripple="false"
                      :disabled="selectedDimensions.length >= 3 && !selectedDimensions.includes(prop.value)"
                      hide-details
                      class="mt-0 pt-0 dimension-checkbox"
                      @change="handleDimensionChange"
                    />
                  </template>
                </div>
              </v-tab-item>
              <v-tab-item :transition="false" :reverse-transition="false" eager>
                <v-row>
                  <v-col>
                    <v-checkbox
                      v-for="item of metrics"
                      v-model="selectedMetrics"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                      :ripple="false"
                      return-object
                      hide-details
                      class="mt-0 pt-0"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :transition="false" :reverse-transition="false" eager>
                <v-row>
                  <v-col>
                    <v-checkbox
                      v-for="item of selectableReferences"
                      v-model="selectedReferences"
                      :key="item"
                      :label="item.toString() | formatString"
                      :value="item"
                      :ripple="false"
                      hide-details
                      class="mt-0 pt-0"
                      @change="handleReferencesChange"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-menu>
      </div>
    </v-row>
    <v-row>
      <v-col>
        <data-tables
          style="width: 100%"
          class="overview-table"
          ref="overviewTable"
          :data="overviewTableData"
          :page-size="10"
          :pagination-props="{
            pageSizes: [5, 10,15, 25, 50],
            class: 'el-pagination text-right mt-6 mb-4 mr-2',
          }"
          :table-props="{
            // 'row-key': 'rowKey',
            // lazy: true,
            // load: loadChildData,
          }"
          :filters="tableFilters"
          filter-multiple
          @filter-change="filters => handleFilterChange('tableFilters', filters)"
        >
          <el-table-column
            prop="rowIndex"
            width="55"
            label-class-name="selection-col-header"
            fixed
          >
            <template slot-scope="scope">
              <v-simple-checkbox
                color="primary"
                dense
                hide-details
                :value="scope.row.selected"
                :ripple="false"
                @input="handleSelectRowChange($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-for="dimension in selectedDimensions"
            :key="dimension"
            :prop="dimension"
            :column-key="dimension"
            :label="dimension"
            label-class-name="dimension-col-header"
            class-name="dimension-col"
            fixed
          >
            <template slot-scope="scope">
              {{ scope.row[dimension].slice(0, 20) }}
            </template>
          </el-table-column>
          <el-table-column
            v-for="metric in metricsColumns"
            :key="metric.value"
            :label="metric.name"
            label-class-name="text-center metric-col-header"
            :class-name="(metric.value === 'width' ? 'width-col-cell ' : '')"
          >
            <el-table-column
              v-for="ref, refIndex in currentReferences"
              :key="`${metric.value}-${ref}`"
              :prop="`${metric.value}-${ref}`"
              :column-key="`${metric.value}-${ref}`"
              :label="ref.replace('reference_', '')"
              :label-class-name="'text-center text-capitalize ' + (ref === 'collection' ? 'collection-col-header' : '')"
              :class-name="'text-center ' + (refIndex === 0 ? 'metric-col-cell ' : '') + (ref === 'collection' ? 'collection-col-cell ' : '') + (ref === 'budget' ? 'budget-col-cell ' : '')"
              :width="(metric.value === 'width' && (ref === 'budget' ? 140 : (ref === 'collection' ? 120 : null)))"
              sortable
            >
              <template slot-scope="scope">
                <template v-if="['original_value', 'cost_value'].includes(metric.value)">
                  <div :class="getRedTextClass(ref, metric, scope)">
                    <template v-if="scope.row[ref]?.[metric.value]">
                      <template v-if="ref === 'index'">
                        {{ scope.row[ref][metric.value] }}
                      </template>
                      <template v-else>
                        {{ scope.row[ref][metric.value] | currency_no_decimals }}
                      </template>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                </template>
                <template v-else-if="['original_price', 'cost_price'].includes(metric.value)">
                  <template v-if="scope.row[ref]?.[metric.value]">
                    <template v-if="ref === 'index'">
                      {{ scope.row[ref][metric.value] }}
                    </template>
                    <template v-else>
                      {{ scope.row[ref][metric.value] | currency }}
                    </template>
                  </template>
                  <template v-else>
                    -
                  </template>
                </template>
                <template v-else-if="metric.value === 'margin'">
                  {{ scope.row[ref]?.[metric.value] ? (scope.row[ref]?.[metric.value] + (ref === 'index' ? '' : '%')) : '-'}}
                </template>
                <template v-else-if="metric.value === 'width'">
                  <template v-if="ref === 'collection'">
                    <div class="collection-action-icon-wrapper" :id="`collection-action-icon-wrapper-${scope.$index}`">
                      <v-tooltip top :max-width="250" :attach="`#collection-action-icon-wrapper-${scope.$index}`">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small :ripple="false" class="collection-action-icon" v-bind="attrs" v-on="on" @click="handleGenerateProductsClick(scope.row)">mdi-arrow-expand-right</v-icon>
                        </template>
                        <span>Generate products</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-if="scope.row[ref]?.['width']">
                    ({{ scope.row[ref]['width'] | formatThousands }})
                  </template>
                  <template v-else>
                    (-)
                  </template>
                  <template v-if="scope.row[ref]?.['depth']">
                    ({{ scope.row[ref]['depth'] | formatThousands }})
                  </template>
                  <template v-else>
                    (-)
                  </template>
                  <template v-if="ref === 'budget'">
                    <div class="budget-actions">
                      <v-icon :ripple="false" class="budget-action-icon-up" @click="handleBudgetUpClick(scope)">mdi-menu-up</v-icon>
                      <v-icon :ripple="false" class="budget-action-icon-down" @click="handleBudgetDownClick(scope)">mdi-menu-down</v-icon>
                    </div>
                  </template>
                </template>
                <template v-else-if="metric.value === 'pieces'">
                  <div :class="getRedTextClass(ref, metric, scope)">
                    <template v-if="scope.row[ref]?.[metric.value]">
                      {{ scope.row[ref][metric.value] | formatThousands }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                </template>
                <template v-else>
                  <template v-if="scope.row[ref]?.[metric.value]">
                    {{ scope.row[ref][metric.value] | formatThousands }}
                  </template>
                  <template v-else>
                    -
                  </template>
                </template>
              </template>
            </el-table-column>
          </el-table-column>

          <!-- Totals -->
          <data-tables
            slot="append"
            class="total-table"
            :data="[overviewTableTotals]"
            :pagination-props="{class: 'd-none'}"
          >
            <el-table-column
              label-class-name="d-none"
              class-name="total-row_column"
              width="54"
            />
            <el-table-column
              class-name="total-row_column dimension-col"
              label-class-name="d-none"
            />
            <el-table-column
              v-for="i in (selectedDimensions.length - 1)"
              :key="i"
              label-class-name="d-none"
              class-name="dimension-col total-row_column"
            />
            <el-table-column
              v-for="metric in metricsColumns"
              :key="metric.value"
              :prop="metric.value"
              class-name="total-row_column"
              label-class-name="d-none"
            >
              <el-table-column
                v-for="ref, refIndex in currentReferences"
                :key="ref"
                :prop="ref"
                :column-key="ref"
                :class-name="'total-row_column text-center ' + (refIndex === 0 ? 'metric-col-cell' : '') + (ref === 'collection' ? 'collection-col-cell ' : '')"
                label-class-name="d-none"
                :width="(metric.value === 'width' && (ref === 'budget' ? 140 : (ref === 'collection' ? 120 : null)))"
              >
                <template slot-scope="scope">
                  <template v-if="['original_value', 'cost_value'].includes(metric.value)">
                    <template v-if="scope.row[ref]?.[metric.value]">
                      <template v-if="ref === 'index'">
                        {{ scope.row[ref][metric.value] }}
                      </template>
                      <template v-else>
                        {{ scope.row[ref][metric.value] | currency_no_decimals }}
                      </template>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </template>
                  <template v-else-if="['original_price', 'cost_price'].includes(metric.value)">
                    <template v-if="scope.row[ref]?.[metric.value]">
                      <template v-if="ref === 'index'">
                        {{ scope.row[ref][metric.value] }}
                      </template>
                      <template v-else>
                        {{ scope.row[ref][metric.value] | currency }}
                      </template>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </template>
                  <template v-else-if="metric.value === 'margin'">
                    {{ scope.row[ref]?.[metric.value] ? (scope.row[ref]?.[metric.value] + (ref === 'index' ? '' : '%')) : '-'}}
                  </template>
                  <template v-else-if="metric.value === 'width'">
                    <template v-if="ref === 'collection'">
                      <div class="collection-action-icon-wrapper" id="collection-action-icon-wrapper-total">
                        <v-tooltip top :max-width="250" attach="#collection-action-icon-wrapper-total">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small :ripple="false" class="collection-action-icon" v-bind="attrs" v-on="on" @click="handleGenerateProductsClick(scope.row, true)">mdi-arrow-expand-right</v-icon>
                          </template>
                          <span>Generate products</span>
                        </v-tooltip>
                      </div>
                    </template>
                    <template v-if="scope.row[ref]?.['width']">
                      ({{ scope.row[ref]['width'] | formatThousands }})
                    </template>
                    <template v-else>
                      (-)
                    </template>
                    <template v-if="scope.row[ref]?.['depth']">
                      ({{ scope.row[ref]['depth'] | formatThousands }})
                    </template>
                    <template v-else>
                      (-)
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="scope.row[ref]?.[metric.value]">
                      {{ scope.row[ref][metric.value] | formatThousands }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </template>
                </template>
              </el-table-column>
            </el-table-column>
          </data-tables>
        </data-tables>
      </v-col>
    </v-row>
  </resizable>
</template>

<script>
import { round, uniq } from 'lodash'
import { getYear } from 'date-fns'
import columnFilters from '@/mixins/columnFilters'
import { formatString } from '@/variables'
import { downloadData } from '@/utils'
import Resizable from '@/components/Utility/Resizable.vue'
import TableSearch from '@/components/TableSearch.vue'
import DataExport from '@/components/DataExport'

export default {
  name: 'StrategyCollectionOverview',
  components: { Resizable, TableSearch, DataExport },
  mixins: [columnFilters],
  props: {
    cubeProperties: {
      type: Array,
      default: () => [],
    },
    subDimensions: {
      type: Array,
      default: () => [],
    },
    referenceYears: {
      type: Array,
      default: () => [],
    },
    overviewData: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    const metrics = [{
      value: 'original_value',
      name: 'Original value',
    }, {
      value: 'cost_value',
      name: 'Cost value',
    }, {
      value: 'margin',
      name: 'Initial margin',
    }, {
      value: 'original_price',
      name: 'Original price',
    }, {
      value: 'cost_price',
      name: 'Cost price',
    }, {
      value: 'pieces',
      name: 'Pieces',
    }]

    return {
      loading: false,
      settingsActiveTab: 1,
      selectedDimensions: [],
      metrics,
      selectedMetrics: [],
      selectedReferences: [],
      tableFilters: [],
      columnsToSearch: [],
      overviewTableTotals: {},
    }
  },
  computed: {
    cubePropertiesGrouped () {
      const result = []
      const propertiesSorted = this.cubeProperties.slice().sort()
      let lastHeader = null

      for (const prop of propertiesSorted) {
        const parts = prop.split('/')

        if (!lastHeader || parts[0] !== lastHeader) {
          // week_numbers is not allowed dimension for strategy_collection_overview endpoint
          if (parts[0] !== 'week_numbers') {
            result.push({
              header: formatString(parts[0]),
            })
          }

          lastHeader = parts[0]
        }

        // week_numbers is not allowed dimension for strategy_collection_overview endpoint
        if (lastHeader !== 'week_numbers') {
          result.push({
            value: prop,
            text: formatString(parts[1]),
          })
        }
      }

      return result
    },
    metricsColumns () {
      return [
        {
          value: 'width',
          name: 'Width (Depth)'
        },
        ...this.metrics.filter(m => this.selectedMetrics.includes(m.value)),
      ]
    },
    allReferences () {
      return [
        ...this.referenceYears.map(r => `reference_${r}`),
        'budget',
        'collection',
        'delta',
        'index',
      ]
    },
    selectableReferences () {
      return [
        ...this.referenceYears,
        'delta',
        'index'
      ]
    },
    currentReferences () {
      const result = [
        ...this.referenceYears
          .filter(r => this.selectedReferences.includes(r))
          .map(r => `reference_${r}`),
        'budget',
        'collection'
      ]

      const additionalRefs = ['delta', 'index']

      additionalRefs.forEach(r => {
        if (this.selectedReferences.includes(r)) {
          result.push(r)
        }
      })

      return result
    },
    overviewTableData () {
      const result = this.overviewData.slice()

      result.forEach((row, rowIndex) => {
        result[rowIndex] = this.processTableRow(row, rowIndex)

        // taking all metrics from budget field for example, and adding props to each row, which will be used be el-table-col
        Object
          .keys(result[rowIndex].budget)
          .forEach(metric => {
            this.allReferences.forEach(ref => {
              if (result[rowIndex][ref] && Object.keys(result[rowIndex][ref]).length) {
                result[rowIndex][`${metric}-${ref}`] = result[rowIndex][ref][metric]
              }
            })
          })
      })

      return result
    },
    selectedRows () {
      return this.overviewTableData.filter(r => r.selected)
    },
  },
  watch: {
    subDimensions: {
      handler () {
        this.selectedDimensions = this.subDimensions.map(d => `${d}/value`)
        this.columnsToSearch = this.subDimensions.map(d => `${d}/value`)
      },
      immediate: true,
    },
    selectedMetrics: {
      handler () {
        localStorage.setItem('strategyCollectionOverviewMetrics', JSON.stringify(this.selectedMetrics))
        setTimeout(() => {
          this.updateTotalTableWidth()
        }, 100)
        this.$emit('selected-metrics:changed')
      },
    },
    overviewTableData: {
      handler () {
        this.overviewTableTotals = this.calculateTotals()
      },
    },
    overviewTableTotals: {
      handler () {
        this.$el.querySelector('.el-table__append-gutter').innerHTML = '<div class="footer-total-caption">Total</div>'
        this.$emit('totals:changed')
      },
    },
    currentReferences: {
      handler () {
        setTimeout(() => {
          this.updateTotalTableWidth()
        }, 100)
      }
    }
  },
  methods: {
    updateTotalTableWidth () {
      const appendTable = this.$el.querySelector('.el-table__append-wrapper')
      if (appendTable) {
        appendTable.style.width = appendTable.parentNode.querySelector('table').style.width
      }
    },
    calculateTotals () {
      const columnsToSum = ['original_value', 'cost_value', 'original_value_ex_vat', 'pieces', 'width']

      if (!this.overviewTableData.length) {
        return {}
      }

      const totals = this.overviewTableData.reduce((acc, cur) => {
        this.allReferences.forEach(ref => {
          if (!['delta', 'index'].includes(ref)) {
            columnsToSum.forEach(col => {
              if (!acc[ref]) {
                acc[ref] = {}
              }

              if (!acc[ref][col]) {
                acc[ref][col] = 0
              }

              acc[ref][col] = round(acc[ref][col] + (cur[ref]?.[col] ?? 0), 2)
            })
          }
        })

        return acc
      }, {})

      this.allReferences.forEach(ref => {
        if (!['delta', 'index'].includes(ref)) {
          totals[ref] = this.calculateMetrics(totals[ref])
        }
      })

      const metrics = [
        ...this.metrics.map(m => m.value),
        'width',
        'depth',
      ]

      totals['delta'] = metrics.reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: round(totals['collection'][cur] - totals['budget'][cur], 1)
        }
      }, {})

      totals['index'] = metrics.reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: round(totals['collection'][cur] / totals['budget'][cur] * 100)
        }
      }, {})

      return totals
    },
    setSelectedRowByIndex (rowIndex) {
      const row = this.overviewTableData[rowIndex]

      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', true)
      this.$emit('selected-row:changed', row)
      this.$emit('output-dimensions:changed', this.getSelectedRowsDimensions())
    },
    processTableRow (row, rowIndex) {
      this.allReferences.forEach(ref => {
        if (row[ref] && Object.keys(row[ref]).length) {
          row[ref] = this.calculateMetrics(row[ref])
        }

        row['rowIndex'] = rowIndex
        row['selected'] = false
      })

      const metrics = [
        ...this.metrics.map(m => m.value),
        'width',
        'depth',
      ]

      row['delta'] = metrics.reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: round(row['collection'][cur] - row['budget'][cur], 1)
        }
      }, {})

      row['index'] = metrics.reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: round(row['collection'][cur] / row['budget'][cur] * 100)
        }
      }, {})

      return row
    },
    getRedTextClass (ref, metric, scope) {
      if (['collection', 'delta'].includes(ref) && Math.round(scope.row['collection']?.[metric.value]) > Math.round(scope.row['budget']?.[metric.value])) {
        return 'red--text'
      }
    },
    handleDimensionChange () {
      this.$emit('input-dimensions:changed', this.selectedDimensions)
    },
    handleReferencesChange () {
      localStorage.setItem('strategyCollectionOverviewReferences', JSON.stringify(this.selectedReferences))
    },
    handleSelectRowChange (val, row) {
      this.selectedRows.forEach(r => this.$set(r, 'selected', false))
      this.$set(row, 'selected', val)
      this.$emit('selected-row:changed', val ? row : null)
      this.$emit('output-dimensions:changed', this.getSelectedRowsDimensions())
    },
    handleGenerateProductsClick (row, isTotal = false) {
      const payload = {
        rows: isTotal ? this.overviewTableData : [row],
        isTotal
      }

      this.$emit('generate-products:clicked', payload)
    },
    handleBudgetUpClick ({ row }) {
      row.budget.width += 1
      row.budget.depth = round(row.budget.pieces / row.budget.width)

      this.overviewTableTotals = this.calculateTotals()
    },
    handleBudgetDownClick ({ row }) {
      if (row.budget.width === 0) {
        return
      }

      row.budget.width -= 1
      row.budget.depth = round(row.budget.pieces / row.budget.width)

      this.overviewTableTotals = this.calculateTotals()
    },
    getSelectedRowsDimensions () {
      const dimensions = {}

      this.selectedRows.forEach(row => {
        this.selectedDimensions.forEach(d => {
          if (!dimensions[d]) {
            dimensions[d] = []
          }

          dimensions[d] = uniq([
            ...dimensions[d],
            row[d],
          ])
        })
      })

      return dimensions
    },
    calculateMetrics (entry) {
      const originalValueRounded = round(entry.original_value, 2)
      const costValueRounded = round(entry.cost_value, 2)

      return {
        ...entry,
        original_value: originalValueRounded,
        cost_value: costValueRounded,
        original_price: round(originalValueRounded / entry.pieces, 2),
        cost_price: round(costValueRounded / entry.pieces, 2),
        margin: round((1 - costValueRounded / entry.original_value_ex_vat) * 100, 1),
        depth: round(entry.pieces / entry.width),
        width: round(entry.width, 1),
      }
    },
    downloadOverviewData (sep) {
      const data = this.overviewTableData

      downloadData(sep, data, 'strategy_collection_overview')
    },
  },
  mounted () {
    this.$emit('input-dimensions:changed', this.selectedDimensions)

    this.selectedReferences = JSON.parse(localStorage.getItem('strategyCollectionOverviewReferences')) || [getYear(new Date()) - 1]
    this.selectedMetrics = JSON.parse(localStorage.getItem('strategyCollectionOverviewMetrics')) || this.metrics.map(m => m.value)
  }
}
</script>

<style lang="scss">
#strategy-collection-overview {
  .settings-menu {
    .dimension-checkbox {
      .v-label {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .overview-table {
    th.metric-col-header {
      border-left: 2px solid #d6d6d8 !important;
    }
    .metric-col-cell {
      border-left: 2px solid #d6d6d8 !important;
    }
    .collection-col-cell {
      position: relative;
      .collection-action-icon-wrapper {
        position: absolute;
        left: 5px;
        .collection-action-icon:focus::after {
          opacity: 0 !important;
        }
      }
    }
    .width-col-cell {
      .budget-col-cell {
        position: relative;
        padding-right: 20px;
      }
    }
    .budget-actions {
      height: 100%;
      position: absolute;
      right: 40px;
      .budget-action-icon-up {
        position: absolute;
        height: 20px;
        top: 8px;
        &:focus::after {
          opacity: 0 !important;
        }
      }
      .budget-action-icon-down {
        position: absolute;
        height: 20px;
        bottom: 8px;
        &:focus::after {
          opacity: 0 !important;
        }
      }
    }
    .el-table__cell {
      border-right: 0;
    }
    .cell {
      font-size: 12px;
      white-space: nowrap;
      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .dimension-col {
      .cell {
        white-space: normal;
        overflow-y: hidden;
        justify-content: flex-start;
      }
    }
    .el-table__body {
      .cell {
        height: 46px; // 2 rows of text
      }
    }
    .total-row_column {
      background-color: #f5f7fa;
    }
    .el-table__fixed {
      background: #f5f7fa;
    }
    .el-table__body-wrapper.is-scrolling-left~.el-table__fixed {
      background: transparent;
    }
    .el-table__append-gutter {
      position: relative;
      .footer-total-caption {
        position: absolute;
        font-size: 12px;
        top: 18px;
        left: 65px;
      }
    }
  }
}
</style>
