<template>
  <v-card class="pb-1 pa-3 datasets-new-view">
    <v-col cols="6" md="3" class="py-0">
      <v-row class="ma-0 align-center flex-nowrap">
        <v-text-field
          label="Name"
          clearable
          :error-messages="errorName"
          @input="errorName = !$event ? 'Can not be empty' : null"
          v-model="newView.view_name"
        ></v-text-field>
        <v-icon
          v-if="changeView"
          class="ml-2"
          @click="viewDeleteModal = true">delete</v-icon>
      </v-row>
    </v-col>
    <v-col cols="6" md="3" class="py-0" v-if="newView.view_name">
      <v-row class="ma-0 align-center">
        <v-select
          label="Position"
          :items="positionsList"
          v-model="newView.position"
          @change="positionChanged = true"
        ></v-select>
        <v-icon class="ml-2" @click="openPositionsModal=true">edit</v-icon>
      </v-row>
    </v-col>
    <v-row v-if="newView.position && newView.view_name" class="mx-0 mt-0 mb-4 flex-column flex-sm-row">
      <v-col>
        <div
          class="d-flex
                            flex-grow-1
                            justify-center
                            align-center
                            rounded
                            p-relative
                            rounded
                            elevation-2
                            text-button
                            text-uppercase
                            operation-option mr-3"
          :class="{ active: newView.chart_type === 'table' }" @click="newView.chart_type = 'table'">
          <v-icon class="mr-3">mdi-view-compact-outline</v-icon>
          TABLE
        </div>
      </v-col>
      <v-col >
        <div
          class="d-flex
                    flex-grow-1
                    justify-center
                    align-center
                    rounded
                    p-relative
                    rounded
                    elevation-2
                    text-button
                    text-uppercase
                    operation-option mr-3"
          :class="{active: newView.chart_type.includes('chart')}" @click="newView.chart_type = 'chart'"
        >
          <v-icon class="mr-3">timeline</v-icon>
          GRAPH
        </div>
      </v-col>
      <v-col >
        <div
          class="
                    d-flex
                    flex-grow-1
                    justify-center
                    align-center
                    rounded
                    p-relative
                    rounded
                    elevation-2
                    text-button
                    text-uppercase
                    operation-option mr-3"
          :class="{active: newView.chart_type.includes('kpi')}" @click="newView.chart_type = 'kpi_tile'; onChartTypeChange('kpi_tile')"
        >
          <v-icon class="mr-3">calculate</v-icon>
          KPI
        </div>
      </v-col>
      <v-col>
        <div
          class="
                    d-flex
                    flex-grow-1
                    justify-center
                    align-center
                    rounded
                    p-relative
                    rounded
                    elevation-2
                    text-button
                    text-uppercase
                    operation-option mr-3"
          :class="{active: newView.chart_type.includes('text')}" @click="newView.chart_type = 'text_tile'"
        >
          <v-icon class="mr-3">message</v-icon>
          TEXT
        </div>
      </v-col>
      <v-col >
        <div
          class="
                    d-flex
                    flex-grow-1
                    justify-center
                    align-center
                    rounded
                    p-relative
                    rounded
                    elevation-2
                    text-button
                    text-uppercase
                    operation-option mr-3"
          :class="{active: newView.chart_type.includes('image')}" @click="newView.chart_type = 'image_tile'"
        >
          <v-icon class="mr-3">camera_alt</v-icon>
          image
        </div>
      </v-col>
      <v-col >
        <div
          class="
                    d-flex
                    flex-grow-1
                    justify-center
                    align-center
                    rounded
                    p-relative
                    rounded
                    elevation-2
                    text-button
                    text-uppercase
                    operation-option mr-3"
          :class="{active: newView.chart_type.includes('filters')}" @click="newView.chart_type = 'filters_tile'"
        >
          <v-icon class="mr-3">filter_list</v-icon>
          Filters
        </div>
      </v-col>
    </v-row>
    <v-col cols="6" md="3" class="py-0" v-if="newView.chart_type.includes('chart')">
      <v-select
        v-if="newView.chart_type !== 'table'"
        :items="[
          {value: 'line_chart', text: 'Line Chart'},
          {value: 'bar_chart', text: 'Bar chart'},
          {value: 'scatter_chart', text: 'Scatter chart'},
          {value: 'doughnut_chart', text: 'Doughnut chart'},
          {value: 'gauge_chart', text: 'Gauge chart'},
          {value: 'waterfall_chart', text: 'Waterfall chart'},
        ]"
        clearable
        v-model="newView.chart_type"
        @input="onChartTypeChange"
        label="Graph type"
      ></v-select>
    </v-col>

    <template v-if="newView.chart_type ==='text_tile'">
      <v-col cols="5" md="2">
        <v-text-field
          label="Title"
          v-model="newView.properties.title"
        ></v-text-field>
      </v-col>
      <md-editor v-model="newView.properties.sub_text" height="400px"></md-editor>
      <v-col v-if="false">
        <v-textarea
          outlined
          label="Sub text"
          v-model="newView.properties.sub_text"
        ></v-textarea>
      </v-col>
    </template>
    <template v-if="newView.chart_type ==='image_tile'">
      <v-col cols="12" class="py-0">
        <v-col cols="5" md="2">
          <span class=" d-flex align-center "
          >
            <v-text-field
              class="my-0 py-0 "
              hide-details
              label="Max height (px)"
              v-model="newView.properties.maxHeight"
            ></v-text-field>
          </span>
        </v-col>
      </v-col>
      <ViewImageUpload @image-uploaded="saveImageView" :view-image-url="newView.properties.image_url" @cancel="onCancelClick"/>

    </template>
    <template v-else>
      <v-col cols="6" md="3" class="py-0" v-if="newView.chart_type && newView.chart_type !== 'text_tile' && newView.chart_type !== 'image_tile' && newView.chart_type !== 'filters_tile'">
        <v-autocomplete
          :menu-props="{
            left: true,
          }"
          :items="dataSetForChoose"
          clearable
          v-model="newView.dataset"
          label="Dataset"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="3" class="py-0" v-if="newView.chart_type === 'doughnut_chart'">
        <v-select
          :items="[
            {value: 'regular', text: 'Regular'},
            {value: 'target', text: 'Target'},
          ]"
          @change="onDoughnutTypeChange"
          v-model="newView.properties.doughnut_type"
          label="Doughnut type"
        ></v-select>
      </v-col>
      <v-col cols="6" md="3" class="py-0" v-if="newView.chart_type === 'doughnut_chart'">
        <v-select
          :items="[
            {value: '180', text: '180'},
            {value: '360', text: '360'},
          ]"
          v-model="newView.properties.doughnut_degree"
          label="Doughnut degrees"
        ></v-select>
      </v-col>
      <v-col cols="6" md="3" class="py-0" v-if="newView.chart_type === 'bar_chart'">
        <v-select
          :items="[
            {value: 'stacked', text: 'Stacked'},
            {value: 'shadow', text: 'Shadow'},
            {value: 'grouped', text: 'Grouped'},
          ]"
          clearable
          v-model="newView.properties.bar_type"
          :error-messages="newView.properties.bar_type ? '' : 'Can not be empty' "
          label="Bar type"
        ></v-select>
      </v-col>
      <template v-if="chosenDataset || newView.properties.doughnut_type === 'target' || newView.chart_type === 'gauge_chart'">
        <template v-if="newView.chart_type === 'table'">
          <v-col cols="6" md="3" class="py-0" v-for="(dimension, index) of newView.dimensions" :key="index">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              clearable
              :items="dimensions"
              v-model="newView.dimensions[index]"
              @input="(value)=> {if(!value) newView.dimensions.splice(index, 1)}"
              :label="`Dimension ${index+1}`"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="3" class="py-0" :key="newView.dimensions.length +1">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              clearable
              :items="dimensions"
              v-model="newView.dimensions[newView.dimensions.length]"
              :error-messages="newView.dimensions.length || newView.dimensions[0] ? '' : 'Can not be empty' "
              :label="`Dimension ${newView.dimensions.length + 1}`"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="3" class="py-0">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              clearable
              :items="dimensions"
              v-model="newView.properties.child_rows"
              label="Child (optional)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="3" class="py-0">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              clearable
              :items="dimensions"
              v-model="newView.properties.matrix"
              label="Matrix (optional)"
            ></v-autocomplete>
          </v-col>
        </template>

        <template v-else-if="newView.chart_type !== 'chart' && newView.chart_type !== 'waterfall_chart' && newView.chart_type !== 'kpi_tile' && newView.chart_type !== 'text_tile'">
          <v-col cols="6" md="3" class="py-0" v-if="chosenDataset">
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              clearable
              :items="dimensions"
              v-model="newView.dimensions[0]"
              :error-messages="(newView.properties.doughnut_type === 'target' || newView.chart_type === 'gauge_chart' || newView.dimensions[0]) ? '' : 'Can not be empty' "
              :label="dimension1Label"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="3" class="py-0" v-if="chosenDataset && newView.chart_type !== 'scatter_chart' && newView.chart_type !== 'doughnut_chart' && newView.chart_type !== 'gauge_chart' && newView.chart_type !== 'kpi_tile'&& newView.chart_type !== 'text_tile' && newView.chart_type !== 'waterfall_chart'" >
            <v-autocomplete
              :menu-props="{
                left: true,
              }"
              clearable
              :filter="mainFilter"
              :items="dimensions"
              v-model="newView.dimensions[1]"
              @input="onSecondDimensionChange"
              :label="dimension2Label"
            ></v-autocomplete>
          </v-col>
        </template>
      </template>
      <v-row class="ma-0"
        v-if="(newView.chart_type === 'line_chart' || newView.chart_type === 'bar_chart') && chosenDataset">
        <v-row style="width: 100%">
          <v-col cols="12" class="pa-0">
            <h4 class="mx-6 py-4">Axis</h4>
          </v-col>
          <v-col cols="6" md="2" class="pa-0 pl-6">
            <v-text-field
              clearable
              v-model="axis.y1"
              :error-messages="axis.y1 ? '': 'Can not be empty' "
              label="Y-axis 1 name"
            ></v-text-field>
          </v-col>
          <v-col cols="2" md="1" class="pa-0 pl-6">
            <v-text-field
              clearable
              type="number"
              v-model.number="axis.y1_min"
              @input="(value)=> {
                if(value === null || value === '')
                  delete axis.y1_min
              }"
              label="Min"
            ></v-text-field>
          </v-col>
          <v-col cols="2" md="1" class="pa-0 pl-6">
            <v-text-field
              clearable
              type="number"
              v-model.number="axis.y1_max"
              @input="(value)=> {
                if(value === null || value === '')
                  delete axis.y1_max
              }"
              label="Max"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" class="pa-0">
            <h4 class="mx-6 py-0"></h4>
          </v-col>
          <v-col cols="6" md="2" class="pa-0 pl-6" v-if="!newView.dimensions[1]">
            <v-text-field
              clearable
              v-model="axis.y2"
              label="Y-axis 2 name"
            ></v-text-field>
          </v-col>
          <v-col cols="2" md="1" class="pa-0 pl-6" v-if="!newView.dimensions[1]">
            <v-text-field
              clearable
              type="number"
              v-model.number="axis.y2_min"
              label="Min"
              @input="(value)=> {
                if(value === null || value === '')
                  delete axis.y2_min
              }"
            ></v-text-field>
          </v-col>
          <v-col cols="2" md="1" class="pa-0 pl-6"  v-if="!newView.dimensions[1]">
            <v-text-field
              clearable
              type="number"
              v-model.number="axis.y2_max"
              @input="(value)=> {
                if(value === null || value === '')
                  delete axis.y2_max
              }"
              label="Max"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-row>
      <v-row class="ma-0"
        v-if="newView.chart_type === 'scatter_chart' && chosenDataset">
        <v-row style="width: 100%;">
          <v-col cols="12" class="pa-0">
            <h4 class="mx-6 py-4">Axis</h4>
          </v-col>
          <v-col cols="5" md="2" class="pa-0 pl-6">
            <v-text-field
              clearable
              v-model="axis.x"
              :error-messages="newView.measures[0]?.name ? '': 'Can not be empty' "
              @input="value=> newView.measures[0].name = value"
              label="X-axis name"
            ></v-text-field>
          </v-col>
          <v-col cols="5" md="2" class="pa-0 pl-6">
            <v-row class="ma-0 flex-nowrap align-center">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                v-if="!newView.measures[0].calculation  || typeof newView.measures[0].calculation === 'string'"
                clearable
                :error-messages="newView.measures[0].calculation ? '': 'Can not be empty' "
                v-model="newView.measures[0].calculation"
                label="X-axis"

                @input="
                  $set(newView.measures[0], 'name', newView.measures[0]?.name || formatString($event));
                  $set(axis, 'x', axis.x || formatString($event));
                "
                :items="chosenDataset.metrics.map((item)=>{ return {value: item, text: formatString(item)}})"
              ></v-autocomplete>
              <div
                v-else
                @click="openCalculationModal(newView.measures[0])"
                class="c-pointer">
                <v-text-field
                  readonly
                  outlined
                  style="pointer-events: none"
                  hide-details
                  append-icon="calculate"
                  label="Measure"
                >
                </v-text-field>
              </div>
              <v-icon
                v-if="!newView.measures[0].calculation  || typeof newView.measures[0].calculation === 'string'"
                @click="openCalculationModal(newView.measures[0])">calculate</v-icon>
            </v-row>
          </v-col>

          <v-col cols="auto" class="d-flex align-center justify-start pa-0 pl-6" >
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="action-btn-primary align-self-center" v-bind="attrs" v-on="on">tune</v-icon>
              </template>
              <v-card class="measures-properties pb-6" width="400">
                <h4 class="ml-3 mb-2 pt-6 edit-parameters-title"> Edit parameters </h4>
                <v-col class="pa-0 pl-2">
                  <v-text-field
                    clearable
                    type="number"
                    v-model.number="axis.x_min"
                    @input="(value)=> {
                      if(value === null || value === '')
                        delete axis.x_min
                    }"
                    label="Min"
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0 pl-2">
                  <v-text-field
                    clearable
                    type="number"
                    v-model.number="axis.x_max"
                    @input="(value)=> {
                      if(value === null || value === '')
                        delete axis.x_max
                    }"
                    label="Max"
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0 pl-2">
                  <v-text-field
                    type="number"
                    v-model.number="newView.measures[0].decimals"
                    label="Decimals"
                  ></v-text-field>
                </v-col>
              </v-card>
            </v-menu>
          </v-col>

        </v-row>
        <v-row style="width: 100%;">
          <v-col cols="12" class="px-12 py-0">
          </v-col>
          <v-col cols="5" md="2" class="pa-0 pl-6">
            <v-text-field
              clearable
              v-model="axis.y1"
              :error-messages="newView.measures[1]?.name ? '': 'Can not be empty' "
              @input="value=> newView.measures[1].name = value"
              label="Y-axis name"
            ></v-text-field>
          </v-col>
          <v-col cols="5" md="2" class="pa-0 pl-6">
            <v-row class="ma-0 flex-nowrap align-center">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                v-if="!newView.measures[1].calculation  || typeof newView.measures[1].calculation === 'string'"
                clearable
                v-model="newView.measures[1].calculation"
                :error-messages="newView.measures[1].calculation ? '': 'Can not be empty' "
                label="Y-axis"
                @input="
                  $set(newView.measures[1], 'name', newView.measures[1]?.name || formatString($event));
                  $set(axis, 'y1', axis.y1 || formatString($event));
                "

                :items="chosenDataset.metrics.map((item)=>{ return {value: item, text: formatString(item)}})"
              ></v-autocomplete>
              <div
                v-else
                @click="openCalculationModal(newView.measures[1])"
                class="c-pointer">
                <v-text-field
                  readonly
                  outlined
                  style="pointer-events: none"
                  hide-details
                  append-icon="calculate"
                  label="Measure"
                >
                </v-text-field>
              </div>
              <v-icon
                v-if="!newView.measures[1].calculation  || typeof newView.measures[1].calculation === 'string'"
                @click="openCalculationModal(newView.measures[1])">calculate</v-icon>
            </v-row>
          </v-col>

          <v-col cols="auto" class="d-flex align-center justify-start pa-0 pl-6" >
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="action-btn-primary align-self-center" v-bind="attrs" v-on="on">tune</v-icon>
              </template>
              <v-card class="measures-properties pb-6" width="400">
                <h4 class="ml-3 mb-2 pt-6 edit-parameters-title"> Edit parameters </h4>
                <v-col class="pa-0 pl-2">
                  <v-text-field
                    clearable
                    type="number"
                    hide-details
                    v-model.number="axis.y1_min"
                    label="Min"
                    @input="(value)=> {
                      if(value === null || value === '')
                        delete axis.y1_min
                    }"
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0 pl-2">
                  <v-text-field
                    clearable
                    type="number"
                    hide-details
                    v-model.number="axis.y1_max"
                    @input="(value)=> {
                      if(value === null || value === '')
                        delete axis.y1_max
                    }"
                    label="Max"
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0 pl-2">
                  <v-text-field
                    hide-details
                    type="number"
                    v-model.number="newView.measures[1].decimals"
                    label="Decimals"
                  ></v-text-field>
                </v-col>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row style="width: 100%;">
          <v-col cols="5" md="2" class="pa-0 pl-6">
            <v-text-field
              clearable
              v-model="axis.color_grading"
              @input="value=> newView.measures[2].name =value"
              :error-messages="!newView.measures[2]?.name && newView.measures[2].calculation ? 'Can not be empty': '' "
              label="Color grading name"
            ></v-text-field>
          </v-col>
          <v-col cols="5" md="2" class="pa-0 pl-6">
            <v-row class="ma-0 flex-nowrap align-center">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                clearable
                v-if="!newView.measures[2].calculation  || typeof newView.measures[2].calculation === 'string'"
                v-model="newView.measures[2].calculation"
                label="Color grading"

                @input="
                  $set(newView.measures[2], 'name', newView.measures[2]?.name || formatString($event));
                  $set(axis, 'color_grading', axis.color_grading || formatString($event));
                "
                :items="chosenDataset.metrics.map((item)=>{ return {value: item, text: formatString(item)}})"
              ></v-autocomplete>

              <div
                v-else
                @click="openCalculationModal(newView.measures[2])"
                class="c-pointer">
                <v-text-field
                  readonly
                  outlined
                  style="pointer-events: none"
                  hide-details
                  append-icon="calculate"
                  label="Measure"
                >
                </v-text-field>
              </div>
              <v-icon
                v-if="!newView.measures[2].calculation  || typeof newView.measures[2].calculation === 'string'"
                @click="openCalculationModal(newView.measures[2])">calculate</v-icon>
            </v-row>
          </v-col>
          <v-col cols="auto" class="d-flex align-center justify-start pa-0 pl-6" >
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="action-btn-primary align-self-center" v-bind="attrs" v-on="on">tune</v-icon>
              </template>
              <v-card class="measures-properties pb-6" width="400">
                <h4 class="ml-3 mb-2 pt-6 edit-parameters-title"> Edit parameters </h4>

                <v-col class="pa-0 pl-2">
                  <v-text-field
                    clearable
                    type="number"
                    v-model.number="axis.grading_min"
                    label="Min"
                    hide-details
                    @input="(value)=> {
                      if(value === null || value === '')
                        delete axis.grading_min
                    }"
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0 pl-2" >
                  <v-text-field
                    clearable
                    type="number"
                    hide-details
                    v-model.number="axis.grading_max"
                    @input="(value)=> {
                      if(value === null || value === '')
                        delete axis.grading_max
                    }"
                    label="Max"
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0 pl-2" >
                  <v-text-field
                    type="number"
                    hide-details
                    v-model.number="newView.measures[2].decimals"
                    label="Decimals"
                  ></v-text-field>
                </v-col>
              </v-card>
            </v-menu>
          </v-col>

        </v-row>
        <v-row style="width: 100%;">
          <v-col cols="12" class="px-12 py-0">
          </v-col>
          <v-col cols="5" md="2" class="pa-0 pl-6">
            <v-text-field
              clearable
              v-model="newView.measures[3].name"
              label="Size Name"
            ></v-text-field>
          </v-col>
          <v-col cols="5" md="2" class="pa-0 pl-6">
            <v-row class="ma-0 flex-nowrap align-center">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                clearable
                v-if="!newView.measures[3].calculation  || typeof newView.measures[3].calculation === 'string'"
                v-model="newView.measures[3].calculation"
                label="Size"
                @input="
                  $set(newView.measures[3], 'name', newView.measures[3]?.name || formatString($event));
                "
                :items="chosenDataset.metrics.map((item)=>{ return {value: item, text: formatString(item)}})"
              ></v-autocomplete>

              <div
                v-else
                @click="openCalculationModal(newView.measures[3])"
                class="c-pointer">
                <v-text-field
                  readonly
                  outlined
                  style="pointer-events: none"
                  hide-details
                  append-icon="calculate"
                  label="Measure"
                >
                </v-text-field>
              </div>
              <v-icon
                v-if="!newView.measures[3].calculation  || typeof newView.measures[3].calculation === 'string'"
                @click="openCalculationModal(newView.measures[3])">calculate</v-icon>
            </v-row>
          </v-col>
          <v-col cols="auto" class="d-flex align-center justify-start pa-0 pl-6" >
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="action-btn-primary align-self-center" v-bind="attrs" v-on="on">tune</v-icon>
              </template>
              <v-card class="measures-properties pb-6" width="400">
                <h4 class="ml-3 mb-2 pt-6 edit-parameters-title"> Edit parameters </h4>
                <v-col class="pa-0 pl-2">
                  <v-text-field
                    clearable
                    type="number"
                    hide-details
                    v-model.number="newView.measures[3].min"
                    label="Min"
                    @input="(value)=> {
                      if(value === null || value === '')
                        delete newView.measures[3].min
                    }"
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0 pl-2" >
                  <v-text-field
                    clearable
                    hide-details
                    type="number"
                    v-model.number="newView.measures[3].max"
                    @input="(value)=> {
                      if(value === null || value === '')
                        delete newView.measures[3].max
                    }"
                    label="Max"
                  ></v-text-field>
                </v-col>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>

        <v-col cols="12" class="pb-4 px-0">
          <v-col cols="6" md="2">
            <h4>Format</h4>
            <span class=" d-flex align-center mt-4"
            >
              <span class="theme--light v-label text-no-wrap pr-2">Linear Regression</span>
              <v-checkbox
                class="my-0 py-0 "
                hide-details
                v-model="newView.properties.regression_line"
              ></v-checkbox>
            </span>
            <span class=" d-flex align-center mt-4"
            >
              <span class="theme--light v-label text-no-wrap pr-2">Show color Bar</span>
              <v-checkbox
                class="my-0 py-0 "
                hide-details
                v-model="newView.properties.color_bar"
              ></v-checkbox>
            </span>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="ma-0 pt-6"
        v-if="(newView.chart_type === 'doughnut_chart' || newView.chart_type === 'gauge_chart')&& chosenDataset">
        <v-row style="width: 100%;">
          <v-col cols="5" md="2" class="pa-0 pl-6">
            <v-text-field
              clearable
              v-model="newView.measures[0].name"
              :error-messages="newView.measures[0]?.name ? '': 'Can not be empty' "
              label="Fill name"
            ></v-text-field>
          </v-col>
          <v-col cols="5" md="2" class="pa-0 pl-6">
            <v-row class="ma-0 flex-nowrap align-center">

              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                v-if="!newView.measures[0].calculation  || typeof newView.measures[0].calculation === 'string'"
                clearable
                :error-messages="newView.measures[0].calculation ? '': 'Can not be empty' "
                v-model="newView.measures[0].calculation"
                label="Fill"

                @input="
                  $set(newView.measures[0], 'name', newView.measures[0]?.name || formatString($event));
                "
                :items="chosenDataset?.metrics?.map((item)=>{ return {value: item, text: formatString(item)}})"
              ></v-autocomplete>

              <div
                v-else
                @click="openCalculationModal(newView.measures[0])"
                class="c-pointer">
                <v-text-field
                  readonly
                  outlined
                  style="pointer-events: none"
                  hide-details
                  append-icon="calculate"
                  label="Measure"
                >
                </v-text-field>
              </div>
              <v-icon
                v-if="!newView.measures[0].calculation  || typeof newView.measures[0].calculation === 'string'"
                @click="openCalculationModal(newView.measures[0])">calculate</v-icon>
            </v-row>
          </v-col>
          <v-col cols="auto" class="d-flex align-center justify-start pa-0 pl-6" >
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="action-btn-primary align-self-center" v-bind="attrs" v-on="on">tune</v-icon>
              </template>
              <v-card class="measures-properties" width="400">
                <h4 class="ml-3 mb-2 pt-6 edit-parameters-title"> Edit parameters </h4>
                <v-col class="py-0">
                  <v-text-field
                    type="number"
                    v-model.number="newView.measures[0].decimals"
                    label="Decimals"
                  ></v-text-field>
                </v-col>
              </v-card>
            </v-menu>
          </v-col>

        </v-row>
        <v-row style="width: 100%;" v-if="newView.properties.doughnut_type === 'target' && newView.measures[1]">
          <v-col cols="2" class="pa-0 pl-2">
            <v-text-field
              clearable
              v-model="newView.measures[1].name"
              :error-messages="newView.measures[1]?.name ? '': 'Can not be empty' "
              label="Target name"
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="pa-0 pl-6">
            <v-row class="ma-0 flex-nowrap align-center">

              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                v-if="!newView.measures[1].calculation  || typeof newView.measures[1].calculation === 'string'"
                clearable
                :error-messages="newView.measures[1].calculation ? '': 'Can not be empty' "
                v-model="newView.measures[1].calculation"
                label="Target"

                @input="
                  $set(newView.measures[1], 'name', newView.measures[1]?.name || formatString($event));
                "
                :items="chosenDataset?.metrics?.map((item)=>{ return {value: item, text: formatString(item)}})"
              ></v-autocomplete>

              <div
                v-else
                @click="openCalculationModal(newView.measures[1])"
                class="c-pointer">
                <v-text-field
                  readonly
                  outlined
                  style="pointer-events: none"
                  hide-details
                  append-icon="calculate"
                  label="Measure"
                >
                </v-text-field>
              </div>
              <v-icon
                v-if="!newView.measures[1].calculation  || typeof newView.measures[1].calculation === 'string'"
                @click="openCalculationModal(newView.measures[1])">calculate</v-icon>
            </v-row>
          </v-col>
          <v-col cols="1" class="pa-0 pl-6" >
            <v-text-field
              type="number"
              v-model.number="newView.measures[1].decimals"
              label="Decimals"
            ></v-text-field>
          </v-col>

          <v-col cols="1" class="pa-0 pl-6" >
            <v-checkbox
              type="number"
              v-model.number="newView.measures[1].normalize"
              label="Decimals"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-row>
      <v-row class="ma-0 pt-6"
        v-if="newView.chart_type === 'kpi_tile' && chosenDataset">
        <v-row style="width: 100%;">
          <v-col cols="5" md="2"  class="pa-0 pl-6">
            <v-text-field
              clearable
              v-model="newView.measures[0].name"
              :error-messages="newView.measures[0]?.name ? '': 'Can not be empty' "
              label="Value name"
            ></v-text-field>
          </v-col>
          <v-col cols="5" md="2" class="pa-0 pl-6">
            <v-row class="ma-0 flex-nowrap align-center">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                v-if="!newView.measures[0].calculation  || typeof newView.measures[0].calculation === 'string'"
                clearable
                :error-messages="newView.measures[0].calculation ? '': 'Can not be empty' "
                v-model="newView.measures[0].calculation"
                label="Value"
                @input="
                  $set(newView.measures[0], 'name', newView.measures[0]?.name || formatString($event));
                "
                :items="chosenDataset?.metrics?.map((item)=>{ return {value: item, text: formatString(item)}})"
              ></v-autocomplete>
              <div
                v-else
                @click="openCalculationModal(newView.measures[0])"
                class="c-pointer">
                <v-text-field
                  readonly
                  outlined
                  style="pointer-events: none"
                  hide-details
                  append-icon="calculate"
                  label="Measure"
                >
                </v-text-field>
              </div>
              <v-icon
                v-if="!newView.measures[0].calculation  || typeof newView.measures[0].calculation === 'string'"
                @click="openCalculationModal(newView.measures[0])">calculate</v-icon>
            </v-row>
          </v-col>

          <v-col cols="auto" class="d-flex align-center justify-start pa-0 pl-6" >
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="action-btn-primary align-self-center" v-bind="attrs" v-on="on">tune</v-icon>
              </template>
              <v-card class="measures-properties pb-6" width="400">
                <h4 class="ml-3 mb-2 pt-6 edit-parameters-title"> Edit parameters </h4>
                <v-col class="pa-0 pl-2">
                  <v-text-field
                    type="number"
                    v-model.number="newView.measures[0].decimals"
                    label="Decimals"
                  ></v-text-field>
                </v-col>

                <v-col class="pa-0 pl-2 align-center d-flex" >
                  <span class=" d-flex align-center justify-space-between flex xs12">
                    <span class="theme--light v-label text-no-wrap mr-2">Percentage</span>
                    <v-checkbox
                      class="my-0  mx-2 "
                      hide-details
                      v-model="newView.measures[0].percentage"
                    ></v-checkbox>
                  </span>
                </v-col>
              </v-card>
            </v-menu>
          </v-col>

        </v-row>
        <v-row style="width: 100%;" v-if="newView.measures[1]">
          <v-col cols="5" md="2"  class="pa-0 pl-6">
            <v-text-field
              clearable
              v-model="newView.measures[1].name"
              label="Target name"
            ></v-text-field>
          </v-col>
          <v-col  cols="5" md="2" class="pa-0 pl-6">
            <v-row class="ma-0 flex-nowrap align-center">
              <v-autocomplete
                :menu-props="{
                  left: true,
                }"
                v-if="!newView.measures[1].calculation  || typeof newView.measures[1].calculation === 'string'"
                clearable
                v-model="newView.measures[1].calculation"
                label="Target"
                @input="
                  $set(newView.measures[1], 'name', newView.measures[1]?.name || formatString($event));
                "
                :items="chosenDataset?.metrics?.map((item)=>{ return {value: item, text: formatString(item)}})"
              ></v-autocomplete>
              <div
                v-else
                @click="openCalculationModal(newView.measures[1])"
                class="c-pointer">
                <v-text-field
                  readonly
                  outlined
                  style="pointer-events: none"
                  hide-details
                  append-icon="calculate"
                  label="Measure"
                >
                </v-text-field>
              </div>
              <v-icon
                v-if="!newView.measures[1].calculation  || typeof newView.measures[1].calculation === 'string'"
                @click="openCalculationModal(newView.measures[1])">calculate</v-icon>
            </v-row>
          </v-col>

          <v-col cols="auto" class="d-flex align-center justify-start pa-0 pl-6" >
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="action-btn-primary align-self-center" v-bind="attrs" v-on="on">tune</v-icon>
              </template>
              <v-card class="measures-properties pb-6" width="400">
                <h4 class="ml-3 mb-2 pt-6 edit-parameters-title"> Edit parameters </h4>

                <v-col class="pa-0 pl-2" >
                  <v-text-field
                    type="number"
                    v-model.number="newView.measures[1].decimals"
                    label="Decimals"
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0 pl-2 d-flex align-center" >
                  <span class=" d-flex align-center align-self-center justify-space-between flex xs12">
                    <span class="theme--light v-label text-no-wrap mr-2">Percentage</span>
                    <v-checkbox
                      class="my-0  mx-2 "
                      hide-details
                      v-model="newView.measures[1].percentage"
                    ></v-checkbox>
                  </span>
                </v-col>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-row>
      <DatasetsViewDimensions
        v-if="newView.chart_type && newView.chart_type !== 'scatter_chart' && newView.chart_type !== 'doughnut_chart' && newView.chart_type !== 'gauge_chart' && newView.chart_type !== 'kpi_tile' && newView.chart_type !== 'text_tile' "
        ref="measures"
        :axis="axis"
        :canAddNew="canAddNew"
        :changeView="newView"
        :chosenDataset="chosenDataset"
      />
      <v-col cols="6" md="2" class="mt-2" v-if="newView.chart_type === 'doughnut_chart'">
        <h4>Format</h4>
        <span class=" d-flex align-center py-4"
        >
          <span class="theme--light v-label text-no-wrap mr-2">Show total value</span>
          <v-checkbox
            class="my-0 py-0"
            hide-details
            :input-value="!newView.properties.hide_total_value"
            @change="newView.properties.hide_total_value = !$event"
          ></v-checkbox>
        </span>
        <span class=" d-flex align-center py-4"
        >
          <span class="theme--light v-label text-no-wrap mr-2">Hide description</span>
          <v-checkbox
            class="my-0 py-0"
            hide-details
            :input-value="newView.properties.hide_description"
            @change="newView.properties.hide_description = $event"
          ></v-checkbox>
        </span>
      </v-col>
      <v-col cols="6" md="2" class="mt-2" v-if="newView.chart_type === 'gauge_chart'">
        <h4>Format</h4>
        <span class=" d-flex align-center py-4"
        >
          <v-text-field
            class="my-0 py-0"
            hide-details
            label="Medium start"
            v-model.number="newView.properties.middle_start"
          ></v-text-field>
        </span>
        <span class=" d-flex align-center py-4"
        >
          <v-text-field
            class="my-0 py-0"
            hide-details
            label="High start"
            v-model.number="newView.properties.high_start"
          ></v-text-field>
        </span><span class=" d-flex align-center py-4"
        >
          <v-text-field
            class="my-0 py-0"
            hide-details
            label="Max"
            v-model.number="newView.properties.high_end"
          ></v-text-field>
        </span>
      </v-col>
      <v-col cols="6" md="2"  class="pt-4" v-if="newView.chart_type === 'waterfall_chart'">
        <h4>Format</h4>
        <span class=" d-flex align-center py-4"
        >
          <v-menu
            top
            offset-x
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row
                class="ma-0 pa-0 justify-center"
                v-bind="attrs"
                v-on="on">
                <v-col class="py-0 pl-0">
                  <span class="theme--light v-label text-no-wrap" style="font-size: 14px">Base</span>
                  <div
                    class="color-picker"
                    :style="{backgroundColor: newView.properties.base || '#007AFF'}"
                  />
                </v-col>
              </v-row>
            </template>

            <v-card class="pa-0 pb-1">
              <color-picker
                :chosen-color="newView.properties.base || '#007AFF'"
                @input="$set(newView.properties, 'base', $event)">
                <v-card
                  class="color-picker no-color ma-0 pa-0"
                  @click="()=> $set(newView.properties, 'base', '')">
                  <div class="no-color-line"/>
                </v-card>
              </color-picker>
            </v-card>
          </v-menu>
          <v-menu
            top
            offset-x
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row
                class="ma-0 pa-0 justify-center"
                v-bind="attrs"
                v-on="on">
                <v-col class="py-0">
                  <span class="theme--light v-label text-no-wrap" style="font-size: 14px">Positive</span>
                  <div
                    class="color-picker"
                    :style="{backgroundColor: newView.properties.positive || '#4CAF50'}"
                  />
                </v-col>
              </v-row>
            </template>

            <v-card class="pa-0 pb-1">
              <color-picker
                :chosen-color="newView.properties.positive || '#4CAF50'"
                @input="$set(newView.properties, 'positive', $event)">
                <v-card
                  class="color-picker no-color ma-0 pa-0"
                  @click="()=> $set(newView.properties, 'positive', '')">
                  <div class="no-color-line"/>
                </v-card>
              </color-picker>
            </v-card>
          </v-menu>
          <v-menu
            top
            offset-x
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row
                class="ma-0 pa-0 justify-center"
                v-bind="attrs"
                v-on="on">
                <v-col class="py-0">
                  <span class="theme--light v-label text-no-wrap" style="font-size: 14px">Negative</span>
                  <div
                    class="color-picker"
                    :style="{backgroundColor: newView.properties.negative || '#EE3766'}"
                  />
                </v-col>
              </v-row>
            </template>

            <v-card class="pa-0 pb-1">
              <color-picker
                :chosen-color="newView.properties.negative || '#EE3766'"
                @input="$set(newView.properties, 'negative', $event)">
                <v-card
                  class="color-picker no-color ma-0 pa-0"
                  @click="()=> $set(newView.properties, 'negative', '')">
                  <div class="no-color-line"/>
                </v-card>
              </color-picker>
            </v-card>
          </v-menu>

        </span>
      </v-col>
    </template>
    <v-divider class="mt-6"  v-if="newView.chart_type !== 'image_tile'"></v-divider>
    <v-row class="justify-end mx-0 my-6" v-if="newView.chart_type !== 'image_tile'">
      <v-btn
        color="primary"
        depressed
        text
        @click="onCancelClick"
      >Cancel</v-btn>
      <v-btn
        color="primary"
        depressed
        outlined
        @click="saveView"
        class="mx-4"
      >Save</v-btn>
    </v-row>
    <DeleteDialog
      :visible="viewDeleteModal"
      content-title="Delete View"
      content-text="Are you sure you want to delete this view?"
      @confirm="$emit('delete-view',newView.position)"
      @cancel="viewDeleteModal = false"
    />
    <v-dialog
      v-if="metrics"
      v-model="calculationModal"
      persistent
      scrollable
      content-class="align-self-start mt-14"
      width="600px"
      max-width="90%"
    >
      <EditMeasure
        v-if="calculationModal"
        :metrics="metrics"
        :value="calculationRow.calculation ? [calculationRow.calculation] : []"
        @close="calculationModal= false"
        @value-set="(value)=> calculationRow.calculation =value "></EditMeasure>
    </v-dialog>
    <v-dialog

      v-model="openPositionsModal"
      scrollable
      content-class="align-self-start mt-14"
      width="90%"
      max-width="90%">
      <PositionsModal
        :dashboard-details="dashboardDetails"
        :views-map="viewsMap"
        @close="openPositionsModal = false"
        @saved="$emit('saved-positions', $event); $emit('cancel')"
      ></PositionsModal>
    </v-dialog>
  </v-card>
</template>

<script>

import { formatString } from '@/variables'
import { saveView } from '@/api/analytics'
import DatasetsViewDimensions from './DatasetsViewDimensions'
import DeleteDialog from '../DeleteDialog'
import { headersFilters } from '@/utils'
import EditMeasure from './EditMeasure'
import ColorPicker from '@/components/ColorPicker'
import ViewImageUpload from '@/components/AutomateDatasets/ViewImageUpload'
import PositionsModal from '@/components/AutomateDatasets/PositionsModal'
import MdEditor from '@kangc/v-md-editor'

import Prism from 'prismjs'
import 'prismjs/components/prism-json'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'

MdEditor.use(vuepressTheme, {
  Prism,
})
const ViewObject = {
  position: '1A',
  dataset: '',
  dimensions: [],
  measures: [],
  filters: {},
  properties: { rows: 10 },
  chart_type: '',
}
export default {
  name: 'DatasetsNewView',
  components: { PositionsModal, ViewImageUpload, ColorPicker, EditMeasure, DeleteDialog, DatasetsViewDimensions, MdEditor },
  data () {
    return {
      errorName: '',
      openPositionsModal: false,
      positionChanged: false,
      calculationRow: null,
      calculationModal: false,
      newView: { ...ViewObject },
      viewDeleteModal: false,
      createView: false,
      copyViewsPersonal: [],
      copyViewsShared: [],
      axis: {
        y1: '',
      }
    }
  },
  props: ['dataSetForChoose', 'positions', 'datasets', 'changeView', 'viewsMap', 'dashboardDetails'],
  computed: {
    positionsList () {
      const positions = []
      this.positions.forEach((position) => {
        positions.push(`${position}A`, `${position}B`, `${position}C`, `${position}D`)
      })
      return positions
    },
    metrics () {
      if (!this.chosenDataset) {
        return null
      }
      return this.chosenDataset.metrics.map((item) => {
        return { value: item, text: formatString(item) }
      })
    },
    dimension2Label () {
      switch (this.newView.chart_type) {
      case 'table' :
        return 'Dimension 2 (Child, optional)'
      case 'line_chart':
      case 'bar_chart':
        return 'Group By'
      default:
        return 'Dimension 2'
      }
    },
    dimension1Label () {
      switch (this.newView.chart_type) {
      case 'table' :
        return 'Dimension 1 Parent'
      case 'line_chart':
      case 'bar_chart':
        return 'X-axis'
      default:
        return 'Dimension'
      }
    },
    chosenDataset () {
      return this.datasets[this.newView.dataset]
    },
    canAddNew () {
      if ((this.newView.chart_type === 'doughnut_chart' || this.newView.chart_type === 'gauge_chart' || this.newView.chart_type === 'waterfall_name') && this.newView.measures?.length >= 1) {
        return false
      }
      return this.newView.chart_type === 'table' || this.newView.dimensions?.length < 2 || this.newView.measures?.length < 1
    },
    dimensions () {
      const dimensions = {}
      const dimensionsList = this.chosenDataset.dimensions
      dimensionsList.forEach((item) => {
        const data = item.split('/')
        const header = { header: formatString(data[1] || ''), text: [] }
        if (!dimensions[data[1]]) {
          dimensions[data[1]] = [header]
        }
        const text = data[2] === 'name' ? `Name (${formatString(data[1])})` : formatString(data[2] || '')
        dimensions[data[1]].push({ text, value: item, headerTitle: header.header })
        header.text.push(formatString(data[2] || ''), formatString(data[1] || ''))
      })
      const data = []
      for (const key in dimensions) {
        data.push(...dimensions[key])
      }
      return data
    }
  },
  mounted () {
    if (this.changeView) {
      if (this.changeView.chart_type === 'scatter_chart' && !this.changeView.measures[2]) {
        this.changeView.measures[2] = {}
      } if (this.changeView.chart_type === 'scatter_chart' && !this.changeView.measures[3]) {
        this.changeView.measures[3] = {}
      } if (this.changeView.chart_type === 'kpi_tile' && !this.changeView.measures[1]) {
        this.changeView.measures[1] = {}
      }
      const stringed = JSON.stringify(this.changeView)
      this.newView = JSON.parse(stringed)
      this.axis = (this.newView.properties && this.newView.properties.axis) || {}
    } else {
      this.newView.position = this.positions[this.positions.length - 1] + 'A'
    }
  },
  watch: {
    'newView.dimensions': function () {
      for (const measure of this.newView.measures) {
        if (measure.normalize_by && !this.newView.dimensions.find(item => measure.normalize_by.includes(item))) {
          delete measure.normalize_by
        }
        if (measure.cumulative_by && !this.newView.dimensions.find(item => measure.cumulative_by.includes(item))) {
          delete measure.cumulative_by
        }
      }
    }
  },
  methods: {
    saveImageView (url) {
      const newView = this.newView
      newView.properties.image_url = url

      let position = newView.position
      delete newView.position
      if(position === '1A'){
        position = this.positions.length + 'A'
      }
      saveView({ ...newView, dashboard_key: this.$route.query.dashboard_key }).then((view) => {
        if (view.response) {
          this.$emit('cancel')
          return
        }
        this.$emit('save', { view, position })
      })
    },
    saveFiltersView () {
      const newView = this.newView
      let position = newView.position
      delete newView.position
      if(!this.newView.view_key && !this.positionChanged){
        position = this.positions.length + 'A'
      }
      saveView({ ...newView, dashboard_key: this.$route.query.dashboard_key }).then((view) => {
        if (view.response) {
          this.$emit('cancel')
          return
        }
        this.$emit('save', { view, position })
      })
    },
    onChartTypeChange (value) {
      if (value === 'scatter_chart') {
        this.$set(this.newView, 'measures', [{}, {}, {}, {}])
      } else if (value === 'doughnut_chart') {
        this.$set(this.newView, 'measures', [{}, {}])
      } else if (value === 'gauge_chart') {
        this.$set(this.newView, 'measures', [{}])
      } else if (value === 'kpi_tile') {
        this.$set(this.newView, 'measures', [{}, {}])
      } else {
        this.newView.measures = []
      }
    },
    onDoughnutTypeChange (value) {
      this.newView.properties.doughnut_degree = value === 'regular' ? '360' : '180'
      if (value === 'target') {
        if (this.newView.measures.length < 2) {
          this.newView.measures.push({})
        }
      }
    },
    openCalculationModal (row) {
      this.calculationRow = row
      this.calculationModal = true
    },
    mainFilter: headersFilters,
    formatString,
    onSecondDimensionChange (input) {
      if (!this.canAddNew || !input) {
        this.newView.measures = [this.newView.measures[0]]
      }
      if (!input) {
        this.newView.dimensions = [this.newView.dimensions[0]]
      }
    },
    onCancelClick () {
      this.$emit('cancel', ViewObject)
      this.newView = { ...ViewObject }
    },
    async saveView () {
      if(!this.newView.view_name){
        this.errorName = 'Can not be empty'
        return
      }
      if(this.newView.chart_type === 'filters_tile'){
        this.saveFiltersView()
        return
      }
      if (this.newView.chart_type !== 'scatter_chart' && this.newView.chart_type !== 'text_tile' && (!this.newView.measures || !this.newView.measures.length)) {
        return
      }
      if (!this.newView.properties.child_limit) {
        delete this.newView.properties.child_limit
      }
      if (!this.newView.properties.parent_limit) {
        delete this.newView.properties.parent_limit
      }
      if (!this.newView.properties.limit) {
        delete this.newView.properties.limit
      }
      const newView = { ...this.newView }
      if (newView.chart_type === 'scatter_chart' && !newView.measures[2].calculation) {
        this.axis.grading_min = this.axis.grading_min || 0
        this.axis.grading_max = this.axis.grading_max || 0
      }
      if (newView.chart_type !== 'table') {
        newView.properties.axis = this.axis
        delete newView.properties.rows
        newView.measures.forEach((item) => {
          if (!item) {
            return
          }
          delete item.color_grading
          delete item.grading_max
          delete item.grading_min
        })
      } else {
        delete newView.properties.axis
        newView.measures.forEach((item) => {
          delete item.color_code
        })
      }

      const errors = document.querySelectorAll('.datasets-new-view .v-messages__message')
      if (errors.length) {
        return
      }
      newView.org_key = 0
      newView.measures = newView.measures.map((item) => {
        if (!Object.keys(item).length) {
          return {}
        }
        item.calculation = item.calculation || []
        return {
          ...item,
          calculation: item.calculation.length === 1 ? item.calculation[0] : item.calculation
        }
      })
      if (newView.chart_type === 'scatter_chart' && (!newView.measures[3]?.calculation || !newView.measures[3]?.calculation.length)) {
        newView.measures.splice(3, 1)
      }
      if (newView.chart_type === 'kpi_tile' && (!newView.measures[1]?.calculation || !newView.measures[1]?.calculation.length)) {
        newView.measures.splice(1, 1)
      }
      if (newView.chart_type === 'kpi_tile') {
        newView.dimensions = []
      }
      if ((newView.chart_type === 'bar_chart' || newView.chart_type === 'line_chart') && newView.dimensions[1]) {
        if (!newView.properties.axis.y1) {
          newView.properties.axis.y1 = 'Y1 Name'
        }
        delete newView.properties.axis.y2
        newView.measures[0].axis = 'y1'
      }
      if (this.changeView && newView.dataset !== this.changeView.dataset) {
        newView.filters = {}
      }
      if (newView.chart_type === 'doughnut_chart' && newView.properties.doughnut_type === 'target' && !newView.dimensions[0]) {
        newView.dimensions = []
      }
      if (newView.chart_type === 'gauge_chart' && !newView.dimensions[0]) {
        newView.dimensions = []
      }
      if (newView.chart_type === 'doughnut_chart' && newView.properties.doughnut_type === 'regular') {
        newView.measures = [newView.measures[0]]
      }
      let position = newView.position
      delete newView.position
      if(!this.newView.view_key && !this.positionChanged){
        position = this.positions.length + 'A'
      }
      if(newView.chart_type === 'kpi_tile') {
        newView.properties = {}
      }
      saveView({ ...newView, dashboard_key: this.$route.query.dashboard_key }).then((view) => {
        if (view.response) {
          this.$emit('cancel')
          return
        }
        this.$emit('save', { view, position })
        this.newView = { ...ViewObject }
      })
    },
  }
}
</script>

<style scoped>

.color-picker {
  width: 48px;
  min-height: 34px;
  max-height: 34px;
  border-radius: 4px;
}
</style>
<style>
.v-md-editor__toolbar {
  display: none;
}

.v-md-editor {
  box-shadow: none;
}
</style>
