import * as Sentry from '@sentry/vue'

const ENV = window?.location?.hostname === 'app.thunderstock.nl' ? 'production' : 'development'
// If we are on a local environment, we don't want to send data to Sentry
const isEnabled = window?.location?.hostname !== 'localhost' && ENV === 'production'

export function initSentry (Vue, router) {
  Sentry.init({
    Vue,
    logErrors: true,
    trackComponents: true,
    environment: ENV,
    enabled: isEnabled,
    dsn: 'https://917ba9bb36067e37c7c97f2c19e27d29@o477311.ingest.us.sentry.io/4507893799911424',
    integrations: [
      Sentry.browserTracingIntegration({
        router: router,
        routeLabel: 'path',
      }),
    ],
    tracesSampleRate: 0.05, //  Capture 5% of transactions
    maxBreadcrumbs: 15, // The maximum number of breadcrumbs to capture
    normalizeDepth: 5, // The maximum number of frames to be normalized
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      'https://app.thunderstock.nl',
      'https://staging--thunderstock.netlify.app',
      'https://rj5j2bgjs2.execute-api.eu-west-1.amazonaws.com',
      'https://gfpxhebbti.execute-api.eu-west-1.amazonaws.com',
      'https://r4xayb2z67.execute-api.eu-west-1.amazonaws.com',
      'https://n7mvmu03tg.execute-api.eu-west-1.amazonaws.com',
      'https://il9mo0v0we.execute-api.eu-west-1.amazonaws.com',
      'https://3r1i7yk969.execute-api.eu-west-1.amazonaws.com',
      'https://v1igyiow0a.execute-api.eu-west-1.amazonaws.com',
    ],
    // Profiling
    denyUrls: [
      'https://api.segment.io/v1/m',
      // Chrome extensions
      /extensions\//i,
      /^moz-extension:\/\//i,
      /^chrome:\/\//i,
    ],
  })
}
