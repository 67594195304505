<template>
  <resizable
    class="transferTableFree p-relative"
  >
    <v-overlay absolute color="white"
      :value="stockTransfersStore.loadingTransferDetailsFree || stockTransfersStore.acceptingTransferDetailsFree">
      <v-progress-circular color="primary" indeterminate size="64" width="2"/>
    </v-overlay>
    <v-row class="my-0 ml-0 pl-4 align-center flex-nowrap">
      <v-col cols="10" class="pl-0">
        <v-row class="flex-nowrap">
          <v-col>
            <v-select
              v-model.number="properties.view"
              @input="loadTransfersFreeDebounce()"
              :items="['Option', 'Store']"
              label="View"
            ></v-select>
          </v-col>
          <v-col>
            <div class="d-flex">
              <v-tooltip top :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-on="on" v-bind="attrs"
                    v-model.number="properties.profit_min"
                    clearable
                    @input="loadTransfersFreeDebounce()"
                    type="number"
                    label="Profit min"
                  ></v-text-field>
                </template>
                <span>
                  <b>Profit min:</b> Minimum profit per piece
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col>
            <div class="d-flex">
              <v-tooltip top :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-on="on" v-bind="attrs"
                    v-model.number="properties.send_min"
                    clearable
                    @input="loadTransfersFreeDebounce()"
                    type="number"
                    label="Send min"
                  ></v-text-field>

                </template>
                <span>
                  <b>Send min:</b> Minimum pieces send by a store
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col>
            <div class="d-flex">
              <v-tooltip top :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-on="on" v-bind="attrs"
                    v-model.number="properties.send_max"
                    clearable
                    type="number"
                    @input="loadTransfersFreeDebounce()"
                    label="Send max"
                  ></v-text-field>
                </template>
                <span>
                  <b>Send max:</b> Maximum pieces send by a store
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col>
            <div class="d-flex">

              <v-tooltip top :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-on="on" v-bind="attrs"
                    v-model.number="properties.receive_min"
                    clearable
                    type="number"
                    @input="loadTransfersFreeDebounce()"
                    label="Receive min"
                  ></v-text-field>

                </template>
                <span>
                  <b>Receive min:</b> Minimum pieces received by a store
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col>
            <div class="d-flex">
              <v-tooltip top :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-on="on" v-bind="attrs"
                    v-model.number="properties.receive_max"
                    clearable
                    type="number"
                    @input="loadTransfersFreeDebounce()"
                    label="Receive max"
                  ></v-text-field>

                </template>
                <span>
                  <b>Receive max:</b> Maximum pieces received by a store
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col>
            <div class="d-flex">
              <v-tooltip top :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-on="on" v-bind="attrs"
                    v-model.number="properties.pieces_max"
                    clearable
                    type="number"
                    @input="loadTransfersFreeDebounce()"
                    label="Total max"
                  ></v-text-field>
                </template>
                <span>
                  <b>Total max:</b> Maximum total amount of pieces
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col>
            <div class="d-flex fill-height">
              <v-tooltip top :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs" class="d-flex align-center">
                    <label class="v-label theme--light mr-4">Exclude</label>
                    <v-switch
                      v-on="on" v-bind="attrs"
                      class="mt-0"
                      v-model="properties.exclude"
                      clearable
                      hide-details
                      type="number"
                      @change="loadTransfersFreeDebounce()"
                    ></v-switch>
                  </span>

                </template>
                <span>
                  Exclude: When set to True, stores where all pieces for an article are send away due to 'exclude minimum' must have capacity, will be excluded.
                </span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="text-right pr-6" cols="2">
        <TableSearch class="mx-2" @searchChanged="handleSearchChanged"/>
        <DataExport
          @item-click="downloadData"
        />
      </v-col>
    </v-row>
    <data-tables
      v-if="properties.view === 'Option'"
      ref="transfersTable"
      :data="tableData"
      :page-size="10"
      @sort-change="sortingParams = $event"
      :table-props="{
        defaultSort: sortingParams,
        'expand-icon': 'add',
        'row-key': 'option_key',
      }"
      :pagination-props="{
        pageSizes: [5, 10,15, 25, 50],
        class: 'el-pagination text-right mt-6 mb-4 mr-2',
      }"
      :filters="transfersTableFilters"
      @expand-change="handleExpandChange"
      @filter-change="onFilterChange"
    >
      <data-tables
        slot="append"
        :index="null"
        class="total-table"
        :data="[totalsData]"
        :pagination-props="{class: 'd-none'}"
      >
        <el-table-column label-class-name="d-none" class-name="total-row_column" width="50" />
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
          width="167"
        >
          <template>
            <div>Total</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.stock | formatThousands }}</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.potential | formatThousands }}</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.to_stock | formatThousands }}</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.to_potential | formatThousands }}</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.pieces | formatThousands }}</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template slot-scope="scope">
            <div>{{ priceFormatterNoDigit(scope.row.profit)}}</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template slot-scope="scope">
            <div>{{ scope.row['profit / piece'] | currency }}</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
          width="150"
        >
          <template>
            <v-btn
              color="primary"
              width="130"
              depressed
              outlined
              @click="handleAcceptAllClick"
            >Accept all
            </v-btn>
          </template>
        </el-table-column>
      </data-tables>
      <el-table-column type="expand" :key="childKey" prop="expand">
        <template slot-scope="scope">
          <OptionTransfersTable
            :showTitle="false"
            :key="childKey"
            v-if="skuNames"
            :sku-names="skuNames[scope.row['option_key']]"
            :data="modifyTransfersFreeChildData(scope.row['option_key'])"/>
        </template>
      </el-table-column>
      <el-table-column
        key="name"
        prop="name"
        label="Name"
        width="170"
        class="pl-6"
        sortable
        column-key="name"
        :filtered-value="filters.value ? [filters.value] : []"
        :filters="nameColumnFilters"
      >
        <template slot-scope="scope">
          <v-menu
            :key="scope.row.option_key"
            right
            bottom
            :open-on-hover="!infoMenuOpened[scope.row.option_key]"
            nudge-bottom="10"
            :nudge-left="200"
            :disabled="!scope.row.option_key"
            v-model="infoMenuOpened[scope.row.option_key]"
            @input="onInfoMenuChange(scope.row.option_key, $event)"
            offset-x
            offset-y
            :open-delay="3000"
            :close-delay="1000"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" >

                <OptionDataCell :option="{
                  option: scope.row['name'],
                  image_url: scope.row.image_url
                }" :url="{path: scope.row.route}" :open-in-new-tab="true"></OptionDataCell>
              </div>
            </template>

            <info-menu
              @close="infoMenuOpened[scope.row.option_key] = false"
              v-if="infoMenuOpened[scope.row.option_key]"
              :row="scope.row"></info-menu>
          </v-menu>
        </template>
      </el-table-column>
      <el-table-column label="Stock" sortable="custom" prop="stock">
        <template slot-scope="scope">
          <div class="one-line">
            {{ scope.row.stock }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Potential" sortable="custom" prop="potential">
        <template slot-scope="scope">
          <div class="one-line">
            {{ scope.row.potential }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="To Stock" sortable="custom" prop="to_stock">
        <template slot-scope="scope">
          {{ scope.row.to_stock }}
        </template>
      </el-table-column>
      <el-table-column label="To Potential" sortable="custom" prop="to_potential">
        <template slot-scope="scope">
          {{ scope.row.to_potential }}
        </template>
      </el-table-column>
      <el-table-column label="Transfer" sortable="custom" prop="pieces">
        <template slot-scope="scope">
          {{ scope.row.pieces }}
        </template>
      </el-table-column>
      <el-table-column label="Profit" sortable="custom" prop="profit">
        <template slot-scope="scope">
          {{ priceFormatterNoDigit(scope.row.profit) }}
        </template>
      </el-table-column>
      <el-table-column label="Profit / Pieces" sortable="custom" prop="profit / piece">
        <template slot-scope="scope">
          {{ priceFormatter(scope.row['profit / piece']) }}
        </template>
      </el-table-column>
      <el-table-column width="150" prop="action">
        <template slot-scope="scope">
          <v-btn
            color="primary"
            depressed
            width="130"
            @click="handleAcceptClick(scope.row)"
            outlined>Accept
          </v-btn>
        </template>
      </el-table-column>
    </data-tables>
    <TransfersFreeDetails
      :key="childKey"
      v-else :tableData="tableData" :isParentTable="true" :additionalFilters="transfersTableFilters">
      <data-tables
        :index="null"
        class="total-table"
        :data="[totalsData]"
        :pagination-props="{class: 'd-none'}"
      >
        <el-table-column
          class-name="total-row_column px-2"
          label-class-name="d-none"
        >
          <template>
            <div>Total</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.potential | formatThousands }}</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.stock | formatThousands }}</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.send | formatThousands }}</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.receive | formatThousands }}</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.goal }}</div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="total-row_column"
          label-class-name="d-none"
        >
          <template>
            <v-btn
              color="primary"
              width="130"
              depressed
              outlined
              @click="handleAcceptAllClick"
            >Accept all
            </v-btn>
          </template>
        </el-table-column>
      </data-tables>

    </TransfersFreeDetails>

  </resizable>
</template>
<script>
import TableSearch from '@/components/TableSearch.vue'
import OptionDataCell from '@/components/OptionDataCell'

import TransfersFreeDetails from '@/components/Transfers/TransfersFreeDetails'
import _ from 'lodash'
import columnFilters from '@/mixins/columnFilters'
import DataExport from '../DataExport'
import { downloadData } from '@/utils'
import InfoMenu from '@/components/Home/InfoMenu'
import Resizable from '@/components/Utility/Resizable.vue'
import { useStockTransfersStore } from '@/store/pinia/stockTransfersStore'
import OptionTransfersTable from '@/components/Proposal/OptionTransfersTable.vue'

export default {
  setup () {
    return {
      stockTransfersStore: useStockTransfersStore(),
    }
  },
  name: 'TransfersFreeTable',
  mixins: [columnFilters],
  props: {
    filters: {
      type: Object,
      default: () => ({
        value: '',
      })
    }
  },
  components: {
    OptionTransfersTable,
    Resizable,
    InfoMenu,
    DataExport,
    TransfersFreeDetails,
    OptionDataCell,
    TableSearch,
  },
  data () {
    return {
      sortingParams: {},
      properties: {
        view: 'Option',
        profit_min: null,
        exclude: false,
        send_min: null,
        send_max: null,
        pieces_max: null,
      },
      childKey: 0,
      loaded: false,
      infoMenuOpened: false,
      transfersTableFilters: [
        {
          value: ''
        }
      ],
      expandedRow: null,
      loadTransfersFreeDebounce: null,
      rowExpandClicked: null,
    }
  },
  computed: {
    nameColumnFilters (){
      return this.getColumnFilterValues('tableData', 'name').sort((a, b)=> {
        if(a.text < b.text){
          return -1
        }
        return 1
      })
    },
    priceFormatter () {
      const currency = this.$auth.getUserCurrency().currency || 'EUR'
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return formatter.format
    },
    priceFormatterNoDigit () {
      const currency = this.$auth.getUserCurrency().currency || 'EUR'
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      return formatter.format
    },
    tableData () {
      return this.stockTransfersStore.transferTablesData.free
    },
    childsData () {
      return this.stockTransfersStore.transferTablesData.childs_free
    },
    skuNames () {
      return this.stockTransfersStore.transferTablesData.skuNames
    },
    totalsData () {
      return this.stockTransfersStore.transferTablesData.totals_free
    },
  },
  watch: {
    childsData (){
      setTimeout(()=> {
        this.childKey++
      }, 2000)
    },
    filters: {
      handler () {
        if (!this.filters.value) {
          this.transfersTableFilters = [
            {
              value: ''
            }
          ]
        } else {
          this.transfersTableFilters = [...this.transfersTableFilters, { prop: 'name', value: this.filters.value }]
        }
      },
      immediate: true
    },
  },
  methods: {
    modifyTransfersFreeChildData (option_key){
      const transfersData = this.childsData[option_key] || []
      transfersData.forEach((item)=> {
        item.sku_names.forEach((sku_name, index)=> {
          item[`${sku_name}_value`] = item.goals[index]
          item[sku_name] = {delta: item.deltas[index], goal: item.goals[index]}
        })
        this.skuNames[option_key]?.forEach(sku=> {
          item[`${sku}_value`] = item[`${sku}_value`] || 0
        })
      })
      return transfersData
    },
    onInfoMenuChange (option_key, value) {
      if (value) {
        this.infoMenuOpened = { [option_key]: value }
      }
    },
    handleAcceptAllClick () {
      this.loadTransfersFreeDebounce(true)
    },
    downloadData (sep) {
      const data = this.tableData
      downloadData(sep, data, 'Transfers_free')
    },
    async handleExpandChange (row) {
      if (!this.childsData[row['option_key']]) {
        await this.stockTransfersStore.getTransfersFreeChildRows(
          { child_filters: { option_key: [row['option_key']] }, ...this.properties, view: 'matrix', records: false })
      }
    },
    handleAcceptClick (row) {
      this.stockTransfersStore.setAcceptingTransferDetailsFree(true)
      try {
        this.stockTransfersStore.getTransfersFreeChildRows({
          ...this.properties,
          view: 'store',
          child_filters: { option_key: [row['option_key']] },
          records: true
        }).then(this.acceptTransfersList)
      } catch (e) {
        this.stockTransfersStore.setAcceptingTransferDetailsFree(false)
      }
    },
    acceptTransfersList (list) {
      try {
        this.stockTransfersStore.acceptFreeTransfer(list).then(() => {

          this.loadTransfersFree()
        })
      } catch (e) {
        this.stockTransfersStore.setAcceptingTransferDetailsFree(false)
      }
    },
    loadTransfersFree (accept) {
      const params = {
        ...this.properties,
        view: this.properties.view.toLowerCase(),
        accept: accept
      }
      this.stockTransfersStore.getTransfersFree(params)
        .then(() => {
          this.stockTransfersStore.setTransferTablesData('childs_free', {})
        })
    },
    handleSearchChanged (val) {
      this.transfersTableFilters[0].value = val
    },
    onFilterChange (f) {
      if (f.name && !f.name.length) {
        this.$emit('filters-removed')
      }
      this.handleFilterChange('transfersTableFilters', f)
    },

  },
  mounted () {
    this.loadTransfersFreeDebounce()
  },
  created () {
    this.loadTransfersFreeDebounce = _.debounce(this.loadTransfersFree, 500)
  },
  beforeDestroy () {
    // cleanup opened row, because table will be recreated
    if (!this.stockTransfersStore.detailsRowExpanded.accepted) {
      this.stockTransfersStore.setTransferDetailsData([])
      this.stockTransfersStore.setDetailsRowExpanded('build', false)
    }
  },
}
</script>

<style lang="scss">
.transferTableFree {
  .total-row_column {
    background-color: #f5f7fa;
  }
  .el-table__expanded-cell[class*=cell] {
    padding: 16px;
    background: #f9f9f9;

    &:hover {
      background: #f9f9f9 !important;
    }
  }

  .one-line {
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
