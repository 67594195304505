<template>
  <v-card  flat class="rounded-0">
    <v-overlay absolute color="white" :value="loading">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-card-text class="px-3 py-0 text--primary d-flex flex-column">
      <div class="ma-0 pa-0">
        <div v-if="imageFilePreview" class="align-center d-flex flex-column">
          <v-img
            :src="imageFilePreview"
            max-width="400"
            max-height="300"
          />
          <v-btn
            depressed
            outlined
            class="red--text my-4"
            @click="handleDeleteImageClick"
          >Delete</v-btn>
        </div>
        <div v-else-if="viewImageUrl" class="align-center d-flex flex-column">
          <v-img
            :src="viewImageUrl"
            max-width="400"
            max-height="300"
          />
          <v-btn
            depressed
            outlined
            class="red--text my-4"
            @click="handleDeleteImageClick"
          >Delete</v-btn>
        </div>
        <template v-else>
          <div class="pt-2 text-body-1 font-weight-medium">Info</div>
          <div class="text-body-1 text--secondary mt-2">- Format: JPG, JPEG, PNG</div>
          <div class="text-body-1 text--secondary">- File size: max 10MB</div>

          <template v-if="!imageUrl">
            <div class="mt-6 mb-2 text-body-1 font-weight-medium">Upload image</div>
            <div class="overflow-hidden">
              <div class="p-relative file-drop-zone" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <input
                  type="file"
                  id="assetsFieldHandle"
                  @change="onFileChange"
                  ref="file"
                  accept=".jpg,.jpeg,.png,"
                />
                <label for="assetsFieldHandle">
                  <span class="material-icons icon">system_update_alt</span>
                  <span>Choose a file or drag it here</span>
                </label>
              </div>
            </div>
          </template>

          <div class="mt-6 text-body-1 font-weight-medium">Image url</div>
          <v-textarea
            v-model="imageUrl"
            :error-messages="imageUrlError"
            placeholder="Or paste your image url here"
            clearable
            class="mt-0 pt-0"
            @input="handleImageUrlChange"
          />

          <div class="mb-4 text--darken-2 text-caption font-weight-medium error--text">
            {{ imageFileError }}
          </div>
        </template>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row class="dialog-footer text-right py-2 px-0">
        <v-col class="pr-0">
          <v-btn
            color="primary"
            depressed
            text
            @click="$emit('cancel')"
          >Cancel</v-btn>
          <v-btn
            color="primary"
            depressed
            outlined
            class="mx-4"
            @click="handleSaveClick"
          >Save</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as filesApi from '@/api/files'

export default {
  props: {
    viewImageUrl: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      maxFileSizeMB: 10,
      loading: false,
      imageUrl: null,
      imageUrlError: null,
      imageFile: null,
      imageFileError: null,
      imageFilePreview: null,
    }
  },
  computed: {
    saveDisabled () {
      return (!this.imageFile && !this.imageUrl) || !!this.imageFileError || !!this.imageUrlError
    }
  },
  methods: {
    dragover (event) {
      event.preventDefault()
      event.currentTarget.classList.add('hover')
    },
    dragleave (event) {
      event.currentTarget.classList.remove('hover')
    },
    drop (event) {
      event.preventDefault()
      event.currentTarget.classList.remove('hover')

      this.$refs.file.files = event.dataTransfer.files
      this.onFileChange()
    },
    onFileChange () {
      this.imageFile = this.$refs.file.files[0]
      this.imageFileError = null

      const sizeMB = (this.imageFile.size / 1024 / 1024).toFixed(2)
      const ext = this.imageFile.name.split('.').pop().toLowerCase()

      if (sizeMB > this.maxFileSizeMB) {
        this.imageFileError = 'File size: max 10MB'
      }

      if (!['jpg', 'jpeg', 'png'].includes(ext)) {
        this.imageFileError = 'Required formats are: JPG, JPEG, PNG'
      }

      if (!this.imageFileError) {
        this.imageFilePreview = URL.createObjectURL(this.imageFile)
      }
    },
    removeFile () {
      this.imageFile = null
    },
    handleImageUrlChange () {
      this.imageUrlError = null
      this.imageFile = null
      this.imageFileError = null

      if (!this.imageUrl) {
        return
      }

      const ext = this.imageUrl.split('.').pop()

      if (!['jpg', 'jpeg', 'png'].includes(ext)) {
        this.imageUrlError = 'Required formats are: JPG, JPEG, PNG'
      }
    },
    handleSaveClick () {
      if (this.saveDisabled) {
        this.$emit('image-uploaded', this.viewImageUrl)
        return
      }
      let payload
      let apiMethod

      if (this.imageFile) {
        payload = new FormData()
        payload.append('image', this.imageFile)
        apiMethod = 'uploadImage'
      }

      if (this.imageUrl) {
        payload = {
          image_url: this.imageUrl
        }
        apiMethod = 'saveImage'
      }

      this.loading = true

      filesApi[apiMethod](payload)
        .then(url => {
          this.imageFile = null
          this.imageFilePreview = null
          this.imageUrl = null
          this.$emit('image-uploaded', url)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleDeleteImageClick () {
      this.imageFile = null
      this.imageFilePreview = null
      this.viewImageUrl = null
    }
  }
}

</script>

<style lang="scss">
.image-upload-dialog {
    .file-drop-zone {
        input {
            position: absolute;
            opacity: 0;
        }
        label {
            border: 5px dashed #ccc;
            height: 250px;
            padding-top: 30px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .icon {
                font-size: 48px;
                margin-bottom: 20px;
            }
        }
        &:hover,
        &.hover {
            label {
                background: rgba(0, 122, 255, 0.06);
            }
        }
    }
}
</style>
