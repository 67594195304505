<template>
  <div class="main-layout-wrapper">
    <TopNavbar @menu-clicked="openSideBar"/>
    <BuySidebar ref="sideBar"/>
  </div>
</template>
<script lang="ts">
import {openSidebarMixin} from '@/views/modules/openSidebarMixin'
import TopNavbar from '@/components/TopNavbar'
import BuySidebar from '@/components/BuySidebar.vue'

export default {
  name: 'BuyLayout',
  mixins: [openSidebarMixin],
  components: {
    BuySidebar,
    TopNavbar
  }
}
</script>
