import axios from '@/services/axios'
import baseURLs from '@/api/APIBaseURLs'
import * as models from '@/models/StorePage'

export async function getTrendData (payload: models.TrendDataPayload): Promise<models.TrendDataResponse> {
  const url = baseURLs.storepageAPI + '/trend'
  return axios.post(url, payload).then(r => r.data)
}

export async function getBestsellersData (payload: models.BestsellersDataPayload): Promise<models.BestsellersDataResponse> {
  const url = baseURLs.storepageAPI + '/bestsellers'
  return axios.post(url, payload).then(r => r.data)
}

export async function getTransfersTable (payload: models.TransfersTablePayload): Promise<models.TransfersTableResponse> {
  const url = baseURLs.storepageAPI + '/store_transfers'
  return axios.post(url, payload).then(r => r.data)
}

export async function getSkuTransfersTable (payload: models.SkuTransfersTablePayload): Promise<models.SkuTransfersTableResponse> {
  const url = baseURLs.storepageAPI + '/sku_transfers'
  return axios.post(url, payload).then(r => r.data)
}

export async function getStoresInfo (payload: models.StoresInfoPayload): Promise<models.StoresInfoResponse> {
  const url = baseURLs.storepageAPI + '/store_info'
  return axios.post(url, { token:payload.token, store_key: payload.store_key }).then(r => r.data)
}
