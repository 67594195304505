<template>
  <div class="settings-data-properties">
    <!-- Loading Indicator -->
    <v-overlay absolute color="white" :value="false">
      <v-progress-circular color="primary" indeterminate size="64" width="2" />
    </v-overlay>
    <v-flex xs9 sm6 md4 xl3 class="pa-5">
      <h6 class="text-subtitle-1 font-weight-medium pb-1">Property Management</h6>
      <v-select label="Object" v-model="currentObjectName"
        :items="objectList" @change="objectChanged" class="text-capitalize"/>
    </v-flex>
    <div v-if="currentObjectName">
      <v-row class="pa-5">
        <h6 class="text-subtitle-1 font-weight-medium pb-1 pl-3">Properties</h6>
      </v-row>
      <properties-table :objectName="currentObjectName"
        @propertySelected="onPropertySelected"
        @refresh="propertiesRefresh"
      />
      <v-row v-if="currentProperty" class="pa-5">
        <h6 class="text-subtitle-1 font-weight-medium pb-1 pl-3">
          Values {{currentProperty.property_name ? `for ${currentProperty.property_name}` : "" }}
        </h6>
      </v-row>
      <values-table ref="valuesTable" :objectName="currentObjectName" :property="currentProperty"/>
    </div>
  </div>
</template>

<script>
import columnFilters from '@/mixins/columnFilters'
import {
  getPropertyManagementObjects,
} from '@/api/dataProperties'
import PropertiesTable from '@/components/StockSettings/PropertiesTable'
import ValuesTable from '@/components/StockSettings/ValuesTable'
export default {
  name: 'StoreSettingsDataProperties',
  mixins: [columnFilters],
  data () {
    return {
      currentObjectName: null,
      objectList: [],
      currentProperty: {
        property_name: '',
      },
    }
  },
  components: {
    ValuesTable,
    PropertiesTable
  },
  created () {
    this.getPropertyManagementObjects()
  },
  methods: {
    propertiesRefresh () {
      this.onPropertySelected(undefined)
      this.$refs.valuesTable.refreshProperties()
    },
    onPropertySelected (property) {
      if (!property) {
        this.currentProperty = {
          property_name: '',
        }
        return
      }
      this.currentProperty = property
    },
    getPropertyManagementObjects () {
      getPropertyManagementObjects().then(response => {
        // Capitalize first letter of each word
        this.objectList = response.object_names.map(name => name.charAt(0).toUpperCase() + name.slice(1))
      })
    },
    objectChanged () {
      this.currentProperty = {
        property_name: '',
      }
    }
  },
}
</script>
