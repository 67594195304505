import { addDays, format } from 'date-fns'
import { Chart } from 'chart.js/auto'
import { chartColors } from '@/variables'
import Auth from '@/auth/Auth'
import interpolate from 'color-interpolate'

const optionsExpectedChartsStock = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      align: 'center',
    },
    tooltip: {
      enabled: true,
      position: 'custom',
      callbacks: {
        // this callback is used to create the tooltip label
        label: function (tooltipItem) {
          return tooltipItem.dataset.data[tooltipItem.dataIndex].toLocaleString()
        }
      }
    },
  },
  scales: {
    'bar-x-axis1': {
      position: 'bottom',
      stacked: true,
      // barThickness: 40,
      title: {
        display: true,
        text: '',
        font: {
          size: 14
        },
      },
      grid: {
        display: false,
      },
    },
    'bar-x-axis2': {
      position: 'bottom',
      display: false,
      stacked: true,
      title: {
        display: true,
        text: 'Test',
      },
      // barThickness: 40,
      // these are needed because the bar controller defaults set only the first x axis properties
      type: 'category',
      // categoryPercentage: 0.8,
      // barPercentage: 0.9,
      grid: {
        display: false,
      },
      offset: true
    },
    yAxes: {
      'y-axis1': {
        position: 'left',
        stacked: false,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: 'Pieces',
          font: {
            size: 14
          },
        },
        min: 0,
        ticks: {
          font: {
            size: 14
          },
          padding: 16,
          callback: function (value) {
            const ranges = [
              { divider: 1e6, suffix: 'M' },
              { divider: 1e3, suffix: 'k' }
            ]

            function formatNumber (n) {
              for (let i = 0; i < ranges.length; i++) {
                if (n >= ranges[i].divider) {
                  return (n / ranges[i].divider).toFixed(0) + ranges[i].suffix
                }
              }
              return n
            }
            return formatNumber(value)
          },
        }
      },
      'y-axis2': {
        position: 'right',
        stacked: false,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: 'Ratio',
          font: {
            size: 14
          },
        },
        min: 0,
        ticks: {
          font: {
            size: 14
          },
        }
      }
    }
  },
}

const optionsExpectedChartsOrder = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      align: 'center',
    },
    tooltip: {
      enabled: true,
      position: 'custom',
      callbacks: {
        // this callback is used to create the tooltip label
        label: function (tooltipItem) {
          return tooltipItem.dataset.data[tooltipItem.dataIndex].toLocaleString()
        }
      }
    },
  },
  scales: {
    'bar-x-axis1': {
      position: 'bottom',
      stacked: true,
      // barThickness: 40,
      title: {
        display: true,
        text: '',
        font: {
          size: 14
        },
      },
      grid: {
        display: false,
      },
    },
    'bar-x-axis2': {
      position: 'bottom',
      display: false,
      stacked: true,
      title: {
        display: true,
        text: 'Test',
      },
      // barThickness: 40,
      // these are needed because the bar controller defaults set only the first x axis properties
      type: 'category',
      // categoryPercentage: 0.8,
      // barPercentage: 0.9,
      grid: {
        display: false,
      },
      offset: true
    },
    'y-axis1': {
      position: 'left',
      stacked: false,
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: 'Pieces',
        font: {
          size: 14
        },
      },
      min: 0,
      ticks: {
        font: {
          size: 14
        },
        padding: 16,
        callback: function (value) {
          const ranges = [
            { divider: 1e6, suffix: 'M' },
            { divider: 1e3, suffix: 'k' }
          ]

          function formatNumber (n) {
            for (let i = 0; i < ranges.length; i++) {
              if (n >= ranges[i].divider) {
                return (n / ranges[i].divider).toFixed(0) + ranges[i].suffix
              }
            }
            return n
          }
          return formatNumber(value)
        },
      }
    },
    'y-axis2': {
      position: 'right',
      stacked: false,
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: 'Weekscover',
        font: {
          size: 14
        },
      },
      min: 0,
      ticks: {
        font: {
          size: 14
        },
      }
    }
  }
}

export const dataConvertDict = {
  prediction: data => {
    const sales = data['sales']
    const potential = data['potential']
    const belief = data['belief']
    const stock = data['stock']
    const formatedLabels = data.period.map(date => `${format(new Date(date), 'dd MMM')}`)

    // Blue gradiant
    const ctx = document.getElementById('prediction').getContext('2d')
    const blueGradiant = ctx.createLinearGradient(0, 0, 0, 400)
    blueGradiant.addColorStop(1, 'RGBA(0,122,255,0)')
    blueGradiant.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
    blueGradiant.addColorStop(0, 'RGBA(0,122,255,0.15)')

    const greenGradiant = ctx.createLinearGradient(0, 0, 0, 400)
    greenGradiant.addColorStop(1, 'RGBA(76,175,80,0)')
    greenGradiant.addColorStop(0.5, 'RGBA(76,175,80,0.02)')
    greenGradiant.addColorStop(0, 'RGBA(76,175,80,0.15)')

    return {
      labels: formatedLabels,
      data: [
        {
          label: 'Potential',
          data: potential,
          radius: 0,
          fill: false,
          yAxisID: 'B',
          borderDash: [10, 5],
          borderColor: '#FF9800',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Belief',
          data: belief.map(item => item),
          radius: 0,
          yAxisID: 'B',
          fill: false,
          // borderDash: [10, 5],
          borderColor: '#FF9800',
          backgroundColor: '#FFFFFF',
        },
        { label: 'Sales', data: sales, radius: 0, yAxisID: 'B', borderColor: '#4CAF50', backgroundColor: greenGradiant },
        { label: 'Stock', data: stock, radius: 0, yAxisID: 'A', borderColor: '#007AFF', backgroundColor: blueGradiant },
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      week: false,
      scales: {
        'A': {
          position: 'left',
          title: {
            display: true,
            text: 'Stock',
          },
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
          min: 0,
        },
        'B': {
          position: 'right',
          title: {
            display: true,
            text: 'Sales',
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          grid: {
            display: false,
          },
          // offset: true,
          position: 'bottom'
        },
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)

              // Potential label
              labels[0].lineDash = [5, 5]
              // labels[1].lineDash = [5, 5]

              return labels
            },
          }
        },
      },
    }
  },
  store_prediction: data => {
    const sales = data['Sales']
    const potential = data['Potential']
    const proposal = data['Proposal']
    const stock = data['Stock']
    const goal = data['Goal']
    const formatedLabels = data.period.map(date => `${format(new Date(date), 'dd MMM')}`)

    // Blue gradiant
    const ctx = document.getElementById('prediction').getContext('2d')
    const blueGradiant = ctx.createLinearGradient(0, 0, 0, 400)
    blueGradiant.addColorStop(1, 'RGBA(0,122,255,0)')
    blueGradiant.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
    blueGradiant.addColorStop(0, 'RGBA(0,122,255,0.15)')

    const greenGradiant = ctx.createLinearGradient(0, 0, 0, 400)
    greenGradiant.addColorStop(1, 'RGBA(76,175,80,0)')
    greenGradiant.addColorStop(0.5, 'RGBA(76,175,80,0.02)')
    greenGradiant.addColorStop(0, 'RGBA(76,175,80,0.15)')

    return {
      labels: formatedLabels,
      data: [
        { label: 'Stock', data: stock, radius: 0, yAxisID: 'A', borderColor: '#007AFF', backgroundColor: blueGradiant },
        { label: 'Sales', data: sales, radius: 0, yAxisID: 'A', borderColor: '#4CAF50', backgroundColor: greenGradiant },
        {
          label: 'Potential',
          data: potential,
          radius: 0,
          yAxisID: 'A',
          borderDash: [10, 5],
          borderColor: '#FF9800',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Proposal',
          borderDash: [10, 5],
          data: proposal,
          radius: 0,
          yAxisID: 'A',
          borderColor: '#007AFF',
          backgroundColor: blueGradiant },
        {
          label: 'Goal',
          borderDash: [10, 5],
          data: goal,
          radius: 0,
          yAxisID: 'A',
          borderColor: '#000',
          backgroundColor: '#FFF'
        },
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      week: false,
      scales: {
        'A': {
          position: 'left',
          title: {
            display: true,
            text: 'Pieces',
          },
          min: 0,
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom'
        },
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)

              // Potential label
              labels[2].lineDash = [5, 5]
              labels[3].lineDash = [5, 5]
              labels[4].lineDash = [5, 5]

              return labels
            },
          }
        },
      },
    }
  },
  popularity: data => {
    const ros = data.data['Rate of sale']
    const rank = data.data['Rank in group']

    // Blue gradiant
    const ctx = document.getElementById('popularity').getContext('2d')

    const blueGradiant = ctx.createLinearGradient(0, 0, 0, 400)
    blueGradiant.addColorStop(1, 'RGBA(0,122,255,0)')
    blueGradiant.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
    blueGradiant.addColorStop(0, 'RGBA(0,122,255,0.15)')

    return {
      labels: data.data['x_label'],
      data: [
        {
          label: 'Rate of sale',
          data: ros,
          radius: 0,
          yAxisID: 'B',
          backgroundColor: blueGradiant,
          borderColor: '#007AFF'
        },
        {
          label: 'Rank',
          data: rank,
          radius: 0,
          yAxisID: 'A',
          borderDash: [10, 5],
          backgroundColor: '#FFFFFF',
          borderColor: '#FF9800'
        }
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      week: true,
      scales: {
        'A': {
          position: 'left',
          label: 'Rank',
          min: 1,
          max: 5,
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'B': {
          title: {
            display: true,
            text: 'Rate of sale',
          },
          position: 'right',
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom'
        },
      }
    }
  },
  stock_cover: data => {
    const sales = data.data['Net sales']
    const stock = data.data['Stock']
    const cover = data.data['Sales cover']
    return {
      labels: data.data['xlabel'],
      data: [
        {
          label: 'Net sales',
          data: sales,
          radius: 0,
          fill: false,
          yAxisID: 'A',
          lineTension: 0
        },
        { label: 'Stock', data: stock, fill: false, radius: 0, yAxisID: 'A' },
        {
          label: 'Sales cover',
          data: cover,
          fill: true,
          radius: 0,
          yAxisID: 'B'
        }
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      scales: {
        'A': {
          title: {
            display: true,
            text: 'Pieces',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'B': {
          title: {
            display: true,
            text: 'Cover',
          },
          position: 'right',
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom'
        },
      }
    }
  },
  cover_differences: data => {
    const cover = data.data['Sales cover']

    const min = data.data['Sales cover'].map(function (item, index) {
      // In this case item correspond to currentValue of array a,
      // using index to get value from array b
      return item - data.data['Stddev'][index]
    })
    const max = data.data['Sales cover'].map(function (item, index) {
      // In this case item correspond to currentValue of array a,
      // using index to get value from array b
      return item + data.data['Stddev'][index]
    })

    return {
      labels: data.data['xlabel'],
      data: [
        {
          label: 'Sales cover',
          data: cover,
          borderWidth: 3,
          fill: false,
          radius: 0,
          yAxisID: 'A',
          lineTension: 0,
          borderColor: '#90CAF9',
          backgroundColor: '#90CAF9'
        },
        {
          borderColor: '#90CAF9',
          backgroundColor: '#90CAF9',
          fillBetweenSet: 1,
          borderDash: [5, 10],
          fillBetweenColor: '#0f88eb',
          label: 'Minimum',
          data: min,
          fill: false,
          radius: 0,
          yAxisID: 'A'
        },
        {
          fillBetweenColor: '#0f88eb',
          borderDash: [5, 10],
          borderColor: '#90CAF9',
          backgroundColor: '#90CAF9',
          fillBetweenSet: 1,
          label: 'Maximum',
          data: max,
          fill: false,
          radius: 0,
          yAxisID: 'A'
        }
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      scales: {
        'A': {
          title: {
            display: true,
            text: 'Sales cover',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom'
        },
      }
    }
  },
  cover_outliers: data => {
    const stores = []
    for (const idx in data.data['Sales cover']) {
      const s = stores.find(x => x.label === data.data.store[idx])
      if (s === undefined) {
        const obj = {
          label: data.data['store'][idx],
          data: [data.data['Sales cover'][idx]],
          fill: false,
          yAxisID: 'A',
          lineTension: 0
        }
        stores.push(obj)
      } else {
        const index = stores.indexOf(s)
        stores[index].data.push(data.data['Sales cover'][idx])
      }
    }

    return {
      labels: Array.from(new Set(data.data['xlabel'])),
      data: stores,
      type: 'line',
      lineToday: true,
      daysCover: 0,
      scales: {
        'A': {
          title: {
            display: true,
            text: 'Sales cover',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom'
        },
      }
    }
  },
  popularity_differences: data => {
    const cover = data.data['Popularity']

    const min = data.data['Popularity'].map(function (item, index) {
      // In this case item correspond to currentValue of array a,
      // using index to get value from array b
      return item - data.data['Stddev'][index]
    })
    const max = data.data['Popularity'].map(function (item, index) {
      // In this case item correspond to currentValue of array a,
      // using index to get value from array b
      return item + data.data['Stddev'][index]
    })

    return {
      labels: data.data['xlabel'],
      data: [
        {
          label: 'Popularity',
          data: cover,
          borderWidth: 3,
          fill: false,
          radius: 0,
          yAxisID: 'A',
          lineTension: 0,
          borderColor: '#90CAF9',
          backgroundColor: '#90CAF9'
        },
        {
          borderColor: '#90CAF9',
          backgroundColor: '#90CAF9',
          fillBetweenSet: 1,
          borderDash: [5, 10],
          fillBetweenColor: '#0f88eb',
          label: 'Minimum',
          data: min,
          fill: false,
          radius: 0,
          yAxisID: 'A'
        },
        {
          fillBetweenColor: '#0f88eb',
          borderDash: [5, 10],
          borderColor: '#90CAF9',
          backgroundColor: '#90CAF9',
          fillBetweenSet: 1,
          label: 'Maximum',
          data: max,
          fill: false,
          radius: 0,
          yAxisID: 'A'
        }
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      scales: {
        'A': {
          title: {
            display: true,
            text: 'Popularity',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
          min: 2
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom'
        },
      }
    }
  },
  option_differences: data => {
    const cover = data.data['Options']

    const min = data.data['Options'].map(function (item, index) {
      // In this case item correspond to currentValue of array a,
      // using index to get value from array b
      return item - data.data['Stddev'][index]
    })
    const max = data.data['Options'].map(function (item, index) {
      // In this case item correspond to currentValue of array a,
      // using index to get value from array b
      return item + data.data['Stddev'][index]
    })

    return {
      labels: data.data['xlabel'],
      data: [
        {
          label: 'Options',
          data: cover,
          borderWidth: 3,
          fill: false,
          radius: 0,
          yAxisID: 'A',
          lineTension: 0,
          borderColor: '#90CAF9',
          backgroundColor: '#90CAF9'
        },
        {
          borderColor: '#90CAF9',
          backgroundColor: '#90CAF9',
          fillBetweenSet: 1,
          borderDash: [5, 10],
          fillBetweenColor: '#0f88eb',
          label: 'Minimum',
          data: min,
          fill: false,
          radius: 0,
          yAxisID: 'A'
        },
        {
          fillBetweenColor: '#0f88eb',
          borderDash: [5, 10],
          borderColor: '#90CAF9',
          backgroundColor: '#90CAF9',
          fillBetweenSet: 1,
          label: 'Maximum',
          data: max,
          fill: false,
          radius: 0,
          yAxisID: 'A'
        }
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      scales: {
        'A': {
          title: {
            display: true,
            text: 'Options',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom'
        },
      }
    }
  },
  groups_expected: data => {
    const chartData = [
      {
        data: data.data.predicted_n5w,
        fill: false,
        label: 'Predicted',
        yAxisID: 'A',
        type: 'bar'
      }
    ]
    // { 'data': data.data.sales_ly_n5w, 'label': 'Sales LY', 'yAxisID': 'A', 'type': 'bar' }
    const scales = {
      'A': {
        stacked: false,
        title: {
          display: true,
          text: 'Pieces',
        },
        position: 'left',
        grid: {
          drawTicks: false,
        },
        ticks: {
          padding: 16,
        },
      },
      'X': {
        title: {
          display: true,
          text: '',
        },
        stacked: false,
        position: 'bottom'
      },
    }
    return {
      labels: data.data.group,
      data: chartData,
      type: 'bar',
      lineToday: false,
      daysCover: 0,
      scales: scales
    }
  },
  stores_expected: data => {
    const chartLabels = data.data.store
    const datasets = []
    // datasets.push({ 'label': 'Sales LY', 'data': data.data.sales_ly_n5w })
    datasets.push({ label: 'Predicted', data: data.data.predicted_n5w })
    const scales = {
      'A': {
        position: 'left',
        title: {
          display: true,
          text: 'Pieces',
        },
        grid: {
          drawTicks: false,
        },
        ticks: {
          padding: 16,
        },
      },
      'X': {
        title: {
          display: true,
          text: '',
        },
        position: 'bottom'
      },
    }
    return {
      labels: chartLabels,
      data: datasets,
      type: 'bar',
      lineToday: false,
      daysCover: 0,
      scales: scales,
    }
  },
  transfersGroups: data => {
    const stock = data.data['stock']
    const sales = data.data['sales']
    const potential = data.data['potential']
    const thisTransfer = data.data['this_transfer']
    const allTransfers = data.data['all_transfers']

    const ctx = document.getElementById('transfersGroups').getContext('2d')

    const salesGradient = ctx.createLinearGradient(0, 0, 0, 400)
    salesGradient.addColorStop(1, 'RGBA(76,175,80,0)')
    salesGradient.addColorStop(0.5, 'RGBA(76,175,80,0.02)')
    salesGradient.addColorStop(0, 'RGBA(76,175,80,0.15)')

    const stockGradient = ctx.createLinearGradient(0, 0, 0, 400)
    stockGradient.addColorStop(1, 'RGBA(0,122,255,0)')
    stockGradient.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
    stockGradient.addColorStop(0, 'RGBA(0,122,255,0.15)')
    return {
      labels: data.data['x_label'],
      data: [
        { label: 'Stock', data: stock, radius: 0, lineTension: 0, backgroundColor: stockGradient },
        { label: 'Sales', data: sales, radius: 0, lineTension: 0, backgroundColor: salesGradient },
        {
          label: 'Potential',
          data: potential,
          radius: 0,
          lineTension: 0,
          borderDash: [10, 5],
          borderColor: '#A0A0A0',
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'This transfer',
          data: thisTransfer,
          radius: 0,
          lineTension: 0,
          fill: false,
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'All transfers',
          data: allTransfers,
          radius: 0,
          lineTension: 0,
          fill: false,
          borderColor: 'rgb(123, 119, 140, 1)',
          backgroundColor: '#FFFFFF'
        }
      ],
      type: 'line',
      lineToday: false,
      daysCover: 0,
      scales: {
        'A': {
          stacked: false,
          position: 'left',
          title: {
            display: true,
            text: 'Pieces',
          },
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom'
        },
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)

              // Potential label
              labels[2].lineDash = [5, 5]

              return labels
            },
          }
        },
      },
    }
  },
  transfersGroupsTo: data => {
    const stock = data.data['stock']
    const sales = data.data['sales']
    const potential = data.data['potential']
    const thisTransfer = data.data['this_transfer']
    const allTransfers = data.data['all_transfers']

    const ctx = document.getElementById('transfersGroups').getContext('2d')

    const salesGradient = ctx.createLinearGradient(0, 0, 0, 400)
    salesGradient.addColorStop(1, 'RGBA(76,175,80,0)')
    salesGradient.addColorStop(0.5, 'RGBA(76,175,80,0.02)')
    salesGradient.addColorStop(0, 'RGBA(76,175,80,0.15)')

    const stockGradient = ctx.createLinearGradient(0, 0, 0, 400)
    stockGradient.addColorStop(1, 'RGBA(0,122,255,0)')
    stockGradient.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
    stockGradient.addColorStop(0, 'RGBA(0,122,255,0.15)')
    return {
      labels: data.data['x_label'],
      data: [
        { label: 'Stock', data: stock, radius: 0, lineTension: 0, backgroundColor: stockGradient },
        { label: 'Sales', data: sales, radius: 0, lineTension: 0, backgroundColor: salesGradient },
        {
          label: 'Potential',
          data: potential,
          radius: 0,
          lineTension: 0,
          borderDash: [10, 5],
          borderColor: '#A0A0A0',
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'This transfer',
          data: thisTransfer,
          radius: 0,
          lineTension: 0,
          fill: false,
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'All transfers',
          data: allTransfers,
          radius: 0,
          lineTension: 0,
          fill: false,
          borderColor: 'rgb(123, 119, 140, 1)',
          backgroundColor: '#FFFFFF'
        }
      ],
      type: 'line',
      lineToday: false,
      daysCover: 0,
      scales: {
        'A': {
          stacked: false,
          position: 'left',
          title: {
            display: true,
            text: 'Pieces',
          },
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom'
        },
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)

              // Potential label
              labels[2].lineDash = [5, 5]

              return labels
            },
          }
        },
      },
    }
  },
  transfersStores: data => {
    const stock = data.data['stock']
    const sales = data.data['sales']
    const potential = data.data['potential']
    const thisTransfer = data.data['this_transfer']
    const allTransfers = data.data['all_transfers']

    const ctx = document.getElementById('transfersGroups').getContext('2d')

    const salesGradient = ctx.createLinearGradient(0, 0, 0, 400)
    salesGradient.addColorStop(1, 'RGBA(76,175,80,0)')
    salesGradient.addColorStop(0.5, 'RGBA(76,175,80,0.02)')
    salesGradient.addColorStop(0, 'RGBA(76,175,80,0.15)')

    const stockGradient = ctx.createLinearGradient(0, 0, 0, 400)
    stockGradient.addColorStop(1, 'RGBA(0,122,255,0)')
    stockGradient.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
    stockGradient.addColorStop(0, 'RGBA(0,122,255,0.15)')
    return {
      labels: data.data['x_label'],
      data: [
        { label: 'Stock', data: stock, radius: 0, lineTension: 0, backgroundColor: stockGradient },
        { label: 'Sales', data: sales, radius: 0, lineTension: 0, backgroundColor: salesGradient },
        {
          label: 'Potential',
          data: potential,
          radius: 0,
          lineTension: 0,
          borderDash: [10, 5],
          borderColor: '#A0A0A0',
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'This transfer',
          data: thisTransfer,
          radius: 0,
          lineTension: 0,
          fill: false,
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'All transfers',
          data: allTransfers,
          radius: 0,
          lineTension: 0,
          fill: false,
          borderColor: 'rgb(123, 119, 140, 1)',
          backgroundColor: '#FFFFFF'
        }
      ],
      type: 'line',
      lineToday: false,
      daysCover: 0,
      scales: {
        'A': {
          stacked: false,
          position: 'left',
          title: {
            display: true,
            text: 'Pieces',
          },
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom'
        },
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)

              // Potential label
              labels[2].lineDash = [5, 5]

              return labels
            },
          }
        },
      }
    }
  },
  transfersStoresTo: data => {
    const stock = data.data['stock']
    const sales = data.data['sales']
    const potential = data.data['potential']
    const thisTransfer = data.data['this_transfer']
    const allTransfers = data.data['all_transfers']

    const ctx = document.getElementById('transfersGroups').getContext('2d')

    const salesGradient = ctx.createLinearGradient(0, 0, 0, 400)
    salesGradient.addColorStop(1, 'RGBA(76,175,80,0)')
    salesGradient.addColorStop(0.5, 'RGBA(76,175,80,0.02)')
    salesGradient.addColorStop(0, 'RGBA(76,175,80,0.15)')

    const stockGradient = ctx.createLinearGradient(0, 0, 0, 400)
    stockGradient.addColorStop(1, 'RGBA(0,122,255,0)')
    stockGradient.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
    stockGradient.addColorStop(0, 'RGBA(0,122,255,0.15)')
    return {
      labels: data.data['x_label'],
      data: [
        { label: 'Stock', data: stock, radius: 0, lineTension: 0, backgroundColor: stockGradient },
        { label: 'Sales', data: sales, radius: 0, lineTension: 0, backgroundColor: salesGradient },
        {
          label: 'Potential',
          data: potential,
          radius: 0,
          lineTension: 0,
          borderDash: [10, 5],
          borderColor: '#A0A0A0',
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'This transfer',
          data: thisTransfer,
          radius: 0,
          lineTension: 0,
          fill: false,
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'All transfers',
          data: allTransfers,
          radius: 0,
          lineTension: 0,
          fill: false,
          borderColor: 'rgb(123, 119, 140, 1)',
          backgroundColor: '#FFFFFF'
        }
      ],
      type: 'line',
      lineToday: false,
      daysCover: 0,
      scales: {
        'A': {
          stacked: false,
          position: 'left',
          title: {
            display: true,
            text: 'Pieces',
          },
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom'
        },
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)

              // Potential label
              labels[2].lineDash = [5, 5]

              return labels
            },
          }
        },
      }
    }
  },
  groups_expected_new: data => {
    data = data.data
    const labels = data.group
    const bar1 = data.potential
    const bar2 = data.sales
    const line1 = data.ratio
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Ratio',
          borderColor: '#FF9800',
          backgroundColor: '#FF9800',
          borderWidth: 3,
          data: line1,
          type: 'line',
          yAxisID: 'y-axis2',
          showLine: false
        },
        {
          label: 'Sales',
          borderColor: 'RGBA(0,122,255,1)',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderWidth: 2,
          data: bar2,
          xAxisID: 'bar-x-axis2',
          yAxisID: 'y-axis1'
        },
        {
          label: 'Potential',
          backgroundColor: 'RGBA(0,122,255,0.21)',
          borderWidth: 2,
          data: bar1,
          xAxisID: 'bar-x-axis1',
          stack: 'background',
          yAxisID: 'y-axis1'
        },
      ]
    }

    return [chartData, optionsExpectedChartsStock]
  },
  stores_expected_new: data => {
    data = data.data
    const labels = data.store
    const bar1 = data.potential
    const bar2 = data.sales
    const line1 = data.ratio

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Ratio',
          borderColor: '#FF9800',
          backgroundColor: '#FF9800',
          borderWidth: 3,
          data: line1,
          type: 'line',
          yAxisID: 'y-axis2',
          showLine: false
        },
        {
          label: 'Sales',
          borderColor: 'RGBA(0,122,255,1)',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderWidth: 2,
          data: bar2,
          xAxisID: 'bar-x-axis2',
          yAxisID: 'y-axis1'
        },
        {
          label: 'Potential',
          backgroundColor: 'RGBA(0,122,255,0.21)',
          borderWidth: 2,
          data: bar1,
          xAxisID: 'bar-x-axis1',
          stack: 'background',
          yAxisID: 'y-axis1'
        },
      ]
    }
    return [chartData, optionsExpectedChartsStock]
  },
  stores_trend_graph: data => {
    const sales = data.data['sales']
    const potential = data.data['potential']

    const stock = data.data['stock']
    const labels = data.data['x_label']

    const ctx = document.getElementById('stores_trend_graph').getContext('2d')

    const salesGradient = ctx.createLinearGradient(0, 0, 0, 400)
    salesGradient.addColorStop(1, 'RGBA(76,175,80,0)')
    salesGradient.addColorStop(0.5, 'RGBA(76,175,80,0.02)')
    salesGradient.addColorStop(0, 'RGBA(76,175,80,0.15)')

    const stockGradient = ctx.createLinearGradient(0, 0, 0, 400)
    stockGradient.addColorStop(1, 'RGBA(0,122,255,0)')
    stockGradient.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
    stockGradient.addColorStop(0, 'RGBA(0,122,255,0.15)')

    return {
      labels,
      data: [
        {
          label: 'Potential',
          data: potential,
          radius: 0,
          yAxisID: 'B',
          borderDash: [10, 5],
          borderColor: '#FF9800',
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'Sales',
          data: sales,
          radius: 0,
          yAxisID: 'B',
          borderColor: '#4CAF50',
          backgroundColor: salesGradient
        },
        {
          label: 'Stock',
          data: stock,
          radius: 0,
          yAxisID: 'A',
          borderColor: '#007AFF',
          backgroundColor: stockGradient
        },
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      week: true,
      scales: {
        'A': {
          title: {
            display: true,
            text: 'Stock',
          },
          position: 'left',
          display: true,
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'B': {
          title: {
            display: true,
            text: 'Sales',
          },
          display: true,
          position: 'right',
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            suggestedMax: Math.max(...potential, ...sales) * 1.5,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom'
        },
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)

              // Potential label
              labels[0].lineDash = [5, 5]

              return labels
            },
          }
        },
      },
    }
  },
  data_sets_line_chart: data => {
    const columnProp = data.view.measuresProps
    const label = data.view.dimensions[0]
    const label2 = data.view.dimensions[1]
    const maxValuesY1 = []
    const maxValuesY2 = []
    data.data.forEach(item => {
      const arr1 = []
      const arr2 = []
      for (const key in item) {
        if (item[key]) {
          if (columnProp.axis === 'y1') {
            arr1.push(item[key])
          }
          if (columnProp.axis === 'y2') {
            arr2.push(item[key])
          }
        }
      }
      maxValuesY2.push(...arr2)
      maxValuesY1.push(...arr1)
    })
    const yAxes = []
    if (!label2 && data.view.properties.axis.y2) {
      yAxes.push(
        {
          title: {
            display: true,
            text: data.view.properties.axis.y2,
            font: {
              size: 14,
            }
          },
          id: 'B',
          position: 'right',
          max: data.view.properties.axis.y2_max,
          min: data.view.properties.axis.y2_min,
          ticks: {
            suggestedMax: data.view.properties.axis.y2_max || data.view.view_name === 'Trend graph' ? (Math.max(...maxValuesY1) * 1.5) : undefined,
          },
          grid: {
            display: false,
          },
        }
      )
    }
    if (data.view.properties.axis.y1) {
      yAxes.push({
        title: {
          text: data.view.properties.axis.y1,
          font: {
            size: 14,
          }
        },
        id: 'A',
        position: 'left',
        max: data.view.properties.axis.y1_max,
        min: data.view.properties.axis.y1_min,
        ticks: {
          padding: 16,
          suggestedMax: data.view.properties.axis.y1_max || data.view.view_name === 'Trend graph' ? (Math.max(...maxValuesY2) * 1.5) : undefined,
        },
        grid: {
          drawTicks: false,
        },
      })
    }
    const keys = Object.keys(data.data[0] || {}).filter(item => item !== label && item !== label2)
    let newData = null
    let externalKeys = keys
    const labels = []
    if (label2) {
      newData = {}
      externalKeys = []
      for (const item of data.data) {
        if (!externalKeys.includes(item[label2])) {
          externalKeys.push(item[label2])
        }
        if (!labels.includes(item[label])) {
          labels.push(item[label])
        }
      }
    }
    if(label2) {
      externalKeys.forEach(labelTitle => {
        newData[labelTitle] = new Array(labels.length)
        data.data.forEach(item => {
          if (item[label2] === labelTitle) {
            const index = labels.findIndex(labelItem => item[label] === labelItem)
            newData[labelTitle][index] = item
          }
        })
      })
    }
    return {
      labels: label2 ? labels : data.data.map((item) => item[label]),
      data: externalKeys.map((key) => {
        const measure = data.view.measuresProps[label2 ? keys[0] : key]
        const colorItem = !label2 && measure.color_code ? { borderColor: measure.color_code } : {}
        if (measure.hasGradient) {
          const ctx = document.getElementById('data_sets_line_chart' + data.index).getContext('2d')
          const gradient = ctx.createLinearGradient(0, 0, 0, 400)
          gradient.addColorStop(1, 'RGBA(0,122,255,0)')
          gradient.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
          gradient.addColorStop(0, 'RGBA(0,122,255,0.15)')
          colorItem.backgroundColor = gradient
        }
        let tempData
        if (label2) {
          tempData = newData[key].map(item => item[keys[0]])
        } else {
          tempData = data.data.map(item => item[key])
        }
        return {
          ...colorItem,
          label: key,
          data: tempData,
          radius: 0,
          type: 'line',
          hidden: measure.hidden,
          yAxisID: measure.axis === 'y1' ? 'A' : 'B',
          borderDash: measure.type === 'dotted' ? [5, 5] : [],
          backgroundColor: colorItem.backgroundColor || '#FFFFFF00',
          showLine: measure.type !== 'point',
          pointRadius: measure.type === 'point' ? 4 : 0,
        }
      }),
      type: 'line',
      lineToday: true,
      daysCover: data.chartName === 'stock_trend_graph' ? 'z' + format(new Date(), 'yyyy-\'W\'ww') : 0,
      week: true,
      scales: {
        yAxes,
        xAxes: {
          id: 'X',
          title: {
            display: true,
            text: '',
          },
        }
      },
      plugins: {
        legend: {
          display: !data.view?.properties?.hide_legend,
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)
              for (const index in labels) {
                if (data.view.measures[label2 ? 0 : index].type === 'dotted') {
                  labels[index].lineDash = [5, 5]
                }
              }

              return labels
            },
          },
        },
        annotation: {
          type: 'line',
          mode: 'vertical',
          scaleID: 'X',
          value: format(new Date(), 'yyyy-\'W\'ww'),
          borderColor: '#E35500',
          borderWidth: 2,
          borderDash: [5, 10],
          label: {
            display: false
          }
        },
      }
    }
  },
  data_sets_doughnut_chart: data => {
    const circum = parseInt(data.view.properties.doughnut_degree)
    const totalRow = (data?.total_row || [])[0] || {}
    const fill = data.view.measures[0]?.name
    const label = data.view.dimensions[0] || fill
    const datasetData = data.data.map(item => {
      return item[fill]
    })
    const total = totalRow[fill]
    const target = data.view.measures[1]?.name
    const targetValue = totalRow[target]
    const difference = (2 * targetValue) - total
    const labels = data.data.map(item => {
      return data.view.measuresProps[label]?.name || item[label]
    })
    const backgroundColor = chartColors.slice(0, datasetData.length)
    if (difference > 0) {
      datasetData.push(difference)
      backgroundColor.push('#fff')
    } else {
      backgroundColor.push(chartColors[datasetData.length + 2])
    }
    return [{
      labels: labels,
      datasets: [{
        data: datasetData,
        backgroundColor,
      }],
    }, {
      plugins: {
        legend: {
          position: 'top',
          align: 'center',
          maxHeight: 48,
          rtl: true,
          display: !data.view?.properties?.hide_description,
        },
      },
      circumference: circum,
      rotation: circum === 360 ? 0 : -90,
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
        position: 'custom',
        callbacks: {
          // this callback is used to create the tooltip label
          label: function (tooltipItem) {
            return tooltipItem.dataset.data[tooltipItem.dataIndex].toLocaleString()
          }
        }
      },
    }]
  },
  data_sets_gauge_chart: (data, dataIndex) => {
    const fill = data.view.measures[0]?.name
    const datasetData = [data.data[dataIndex]].map(item => {
      return item[fill]
    })
    const realValue = datasetData[0]
    const middleStart = data.view.properties.middle_start || 45
    const highStart = data.view.properties.high_start || 75
    const highEnd = data.view.properties.high_end || 100
    let interpolateColors
    let backgroundColor

    if (realValue < middleStart) {
      interpolateColors = interpolate(['#ff5721', '#FFAB00'])
      backgroundColor = [interpolateColors(realValue / middleStart)]
    } else if (realValue < highStart) {
      interpolateColors = interpolate(['#FFAB00', '#FFD202'])
      backgroundColor = [interpolateColors((realValue - middleStart) / (highStart - middleStart))]
    } else {
      interpolateColors = interpolate(['#FFD202', '#4caf50'])
      backgroundColor = [interpolateColors((realValue - highStart) / (highEnd - highStart))]
    }
    const difference = highEnd - datasetData[0]
    if (difference > 0) {
      datasetData.push(difference)
    } else {
      backgroundColor.push(chartColors[datasetData.length + 2])
    }
    return [{
      labels: [],
      datasets: [{
        label: [],
        data: datasetData,
        backgroundColor,
      }],
    }, {
      legend: {
        display: false,
        position: 'bottom',
        align: 'center',
        labels: {
          generateLabels: (chart) => {
            const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)

            // Potential label
            const newLabels = labels[0].text.reduce((prev, cur, currentIndex) => {
              return [...prev, {
                'text': cur,
                'fillStyle': backgroundColor[currentIndex],
                'hidden': false,
                'lineWidth': 2,
                'strokeStyle': '#fff',
                'datasetIndex': 0
              }]
            }, [])
            if (newLabels.length === 1 && newLabels[0].text === undefined) {
              return []
            }
            return newLabels
          },
        }
      },
      responsive: true,
      cutout: '85%',
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
        position: 'custom',
        callbacks: {
          // this callback is used to create the tooltip label
          label: function (tooltipItem) {
            return tooltipItem.dataset.data[tooltipItem.dataIndex].toLocaleString()
          }
        }
      },
    }]
  },
  data_sets_waterfall_chart: data => {
    const viewData = data.data[0]
    const labels = Object.keys(viewData)

    let start = 0

    // Preparing data for waterfall chart: every entry is array of start and end value. Start value for each bar is end of previous bar.
    const chartData = labels.map((m) => {
      let end = typeof viewData[m] !== 'number' ? 0 : viewData[m]
      if (viewData[m].relative) {
        end = start + end
      }
      const returnValue = [start, end]
      start = end
      return returnValue
    })
    chartData.push([0, start])
    labels.push(data.view.properties.endName || '')
    return [{
      datasets: [{
        data: chartData,
        backgroundColor: chartData.map(([start, end], index) => {
          if (index === chartData.length - 1 || !index) {
            return data.view.properties.base || '#007AFF'
          }
          return end - start > 0 ? data.view.properties.positive || '#4CAF50' : data.view.properties.negative || '#EE3766'
        })
      }],
      labels: labels,
    }, {
      legend: {
        position: 'bottom',
        align: 'center',
        display: false,
      },
      tooltips: {
        enabled: true,
        position: 'custom',
        callbacks: {
          // this callback is used to create the tooltip label
          label: function (tooltipItem) {
            return tooltipItem.dataset.data[tooltipItem.dataIndex].toLocaleString()
          }
        }
      },
      scales: {
        yAxes: [{
          label: data.view.properties.axis.y1,
          id: 'A',
          position: 'left',
          ticks: {
            font: {
              size: 14
            },
            padding: 16,
            callback: function (value) {
              const ranges = [
                { divider: 1e6, suffix: 'M' },
                { divider: 1e3, suffix: 'k' }
              ]

              function formatNumber (n) {
                for (let i = 0; i < ranges.length; i++) {
                  if (Math.abs(n) >= ranges[i].divider) {
                    return (n / ranges[i].divider).toFixed(0) + ranges[i].suffix
                  }
                }
                return n
              }
              return formatNumber(value)
            },
          },
          grid: {
            display: false,
          },
        }]

      },
      responsive: true,
      maintainAspectRatio: false,
    }]
  },
  prediction_graph_optimization: data => {
    const demand = data.data['demand']
    const skus = data.data['skus']
    const proposal = data.data['proposal']
    const skus_active = data.data['skus_active']
    const labels = data.data['job_date']
    const formattedLabels = labels.map(date => `${format(new Date(date), 'dd MMM')}`)
    const ctx = document.getElementById('prediction_graph_optimization').getContext('2d')

    const salesGradient = ctx.createLinearGradient(0, 0, 0, 400)
    salesGradient.addColorStop(1, 'RGBA(76,175,80,0)')
    salesGradient.addColorStop(0.5, 'RGBA(76,175,80,0.02)')
    salesGradient.addColorStop(0, 'RGBA(76,175,80,0.15)')

    const stockGradient = ctx.createLinearGradient(0, 0, 0, 400)
    stockGradient.addColorStop(1, 'RGBA(0,122,255,0)')
    stockGradient.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
    stockGradient.addColorStop(0, 'RGBA(0,122,255,0.15)')

    return {
      labels: formattedLabels,
      data: [
        {
          label: 'Skus',
          data: skus,
          radius: 0,
          yAxisID: 'A',
          borderDash: [10, 5],
          borderColor: 'black',
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'Proposal',
          data: proposal,
          radius: 0,
          yAxisID: 'A',
          borderDash: [10, 5],
          borderColor: '#007AFF',
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'Demand',
          borderDash: [10, 5],
          data: demand,
          radius: 0,
          yAxisID: 'A',
          borderColor: '#4CAF50',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Skus Active',
          data: skus_active,
          radius: 0,
          borderDash: [10, 5],
          yAxisID: 'A',
          borderColor: '#FF9800',
          backgroundColor: '#FFFFFF',
        },
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      week: false,
      scales: {
        'A': {
          title: {
            display: true,
            text: '',
          },
          min: 0,
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom',
        }
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)

              // Potential label
              labels.forEach((label) => {
                label.lineDash = [5, 5]
              })
              return labels
            },
          }
        },
      }
    }
  },
  prediction_graph_weeks_order: data => {
    data = data.prediction_graph
    const sales = data['Sales LY']
    const potential = data['Potential']
    const potentialAvg = data['Potential Avg.']
    const salesAvg = data['Sales LY Avg.']
    const labels = data['weeks']
    return {
      labels,
      data: [
        {
          label: 'Sales LY',
          data: sales,
          radius: 0,
          yAxisID: 'A',
          fill: false,
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Potential',
          data: potential,
          radius: 0,
          yAxisID: 'A',
          fill: false,
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Potential Avg.',
          data: potentialAvg,
          fill: false,
          radius: 0,
          yAxisID: 'A',
          borderDash: [10, 5],
          borderColor: '#4CAF50',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Sales Avg.',
          fill: false,
          data: salesAvg,
          radius: 0,
          yAxisID: 'A',
          borderDash: [10, 5],
          borderColor: '#90CAF9',
          backgroundColor: '#FFFFFF',
        }
      ],
      type: 'line',
      lineToday: true,
      week: true,
      daysCover: 0,
      scales: {
        'A': {
          title: {
            display: true,
            text: '',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom',
        }
      },
      legend: {
        labels: {
          generateLabels: (chart) => {
            const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)
            // avg labels
            labels[2].lineDash = [5, 5]
            labels[3].lineDash = [5, 5]

            return labels
          },
        }
      },
    }
  },
  sales_graph: data => {
    data = data.sales_graph
    const sales = data['Sales']
    const potential = data['Potential']
    const stock = data['Stock']
    const labels = data['weeks']

    const ctx = document.getElementById('sales_graph').getContext('2d')

    const salesGradient = ctx.createLinearGradient(0, 0, 0, 400)
    salesGradient.addColorStop(1, 'RGBA(76,175,80,0)')
    salesGradient.addColorStop(0.5, 'RGBA(76,175,80,0.02)')
    salesGradient.addColorStop(0, 'RGBA(76,175,80,0.15)')

    const stockGradient = ctx.createLinearGradient(0, 0, 0, 400)
    stockGradient.addColorStop(1, 'RGBA(0,122,255,0)')
    stockGradient.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
    stockGradient.addColorStop(0, 'RGBA(0,122,255,0.15)')

    return {
      labels,
      data: [
        {
          label: 'Potential',
          data: potential,
          radius: 0,
          yAxisID: 'B',
          borderDash: [10, 5],
          borderColor: '#FF9800',
          backgroundColor: '#FFFFFF'
        },
        { label: 'Sales', data: sales, radius: 0, yAxisID: 'B', borderColor: '#4CAF50', backgroundColor: salesGradient },
        { label: 'Stock', data: stock, radius: 0, yAxisID: 'A', borderColor: '#007AFF', backgroundColor: stockGradient },
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      week: true,
      scales: {
        'A': {
          title: {
            display: true,
            text: 'Stock',
          },
          stacked: false,
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'B': {
          title: {
            display: true,
            text: 'Sales',
          },
          position: 'right',
          stacked: false,
          ticks: {
            suggestedMax: Math.max(...potential, ...sales) * 1.5,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom',
        }
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)

              // Potential label
              labels[0].lineDash = [5, 5]

              return labels
            },
          }
        },
      }
    }
  },

  markdown_potential_chart: chartData => {
    const keys = Object.keys(chartData.potentials || {}).filter(item => item !== 'weeks')
    const data = keys.map((item) => {
      return {
        label: item,
        data: chartData.potentials[item],
        radius: 0,
        yAxisID: 'A',
        fill: false,
        backgroundColor: '#FFFFFF',
      }
    })
    const labels = chartData.potentials.weeks
    const lineTodayLabel = labels[chartData.weeks_history + chartData.weeks_left] || 'W' + (chartData.weeks_history + chartData.weeks_left)
    return {
      labels,
      data,
      type: 'line',
      lineToday: true,
      week: true,
      daysCover: labels[chartData.weeks_history] ? 'z' + labels[chartData.weeks_history] : chartData.weeks_history,
      scales: {
        'A': {
          title: {
            display: true,
            text: '',
          },
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
          position: 'left',
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          grid: {
            display: false,
          },
          // offset: true,
          position: 'bottom',
        },
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              return Chart.defaults.plugins.legend.labels.generateLabels(chart)
            },
          }
        },
        annotation: {
          type: 'line',
          mode: 'vertical',
          scaleID: 'X',
          value: lineTodayLabel,
          borderColor: '#E35500',
          borderWidth: 2,
          borderDash: [5, 10],
          label: {
            display: false
          }
        },
      },
    }
  },
  markdown_projection_chart: chartData => {
    const data = chartData.projection
    const keys = Object.keys(data).filter(item => item !== 'weeks')
    const labels = data['weeks']
    const lineTodayLabel = labels[chartData.weeks_history + chartData.weeks_left] || 'W' + (chartData.weeks_history + chartData.weeks_left)
    return {
      labels,
      data: keys.map((item) => {
        const yAxisID = item === 'Discount' ? 'B' : 'A'
        const label = item === 'Discount' ? item + '%' : item
        return {
          label: label,
          data: data[item],
          radius: 0,
          yAxisID,
          fill: false,
          backgroundColor: '#FFFFFF',
        }
      }),
      type: 'line',
      lineToday: true,
      week: true,
      daysCover: labels[chartData.weeks_history] ? 'z' + labels[chartData.weeks_history] : chartData.weeks_history,
      scales: {
        'A': {
          title: {
            display: true,
            text: '',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'B': {
          title: {
            display: true,
            text: 'Discount',
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          position: 'right',
          min: 0,
          max: 100,
          ticks: {
            callback: (item) => item + '%'
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          grid: {
            display: false,
          },
          // offset: true,
          position: 'bottom',
        },
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              return Chart.defaults.plugins.legend.labels.generateLabels(chart)
            },
          }
        },
        annotation: {
          type: 'line',
          mode: 'vertical',
          scaleID: 'X',
          value: lineTodayLabel,
          borderColor: '#E35500',
          borderWidth: 2,
          borderDash: [5, 10],
          label: {
            display: false
          }
        },
      }
    }
  },
  markdown_tradeoff_graph: data => {
    const pieces = data.pieces
    const values = data.value
    const labels = data.discount_buckets
    return {
      labels: labels,
      data: [
        {
          label: 'Pieces',
          type: 'bar',
          data: pieces,
          fill: true,
          radius: 0,
          yAxisID: 'A',
          borderColor: '#FF9800',
          backgroundColor: '#FF9800',
        },
        {
          type: 'line',
          label: 'Value',
          data: values,
          radius: 0,
          yAxisID: 'B',
          fill: false,
          borderColor: '#4da3f3',
          backgroundColor: '#4da3f3',
        },
      ],
      type: 'bar',
      daysCover: 0,
      scales: {
        'A': {
          title: {
            display: true,
            text: 'Pieces',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'B': {
          title: {
            display: true,
            text: 'Value',
          },
          position: 'right',
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        'X': {
          title: {
            display: true,
            text: 'Discount',
          },
          // offset: true,
          position: 'bottom',
        }
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              // avg labels
              // labels[2].lineDash = [5, 5]
              // labels[3].lineDash = [5, 5]

              return Chart.defaults.plugins.legend.labels.generateLabels(chart)
            },
          }
        },
      }
    }
  },
  store_margin_graph: chartData => {
    const data = chartData.store_margin_graph
    const keys = Object.keys(data).filter(item => item !== 'store_keys')
    const labels = data['store_keys']
    return {
      labels,
      data: keys.map((item) => {
        const yAxisID = 'A'
        return {
          label: item,
          data: data[item],
          radius: 0,
          yAxisID,
          fill: true,
        }
      }),
      type: 'bar',
      lineToday: true,
      week: true,
      scales: {
        'A': {
          title: {
            display: true,
            text: 'Margin'
          },
          suggestedMin: 0,
          position: 'left',
          stacked: false,
          grid: {
            drawTicks: false,
          },
          ticks: {
            callback: (label) => {
              return Auth.getUserCurrency().symbol + label
            },
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: ''
          },
          grid: {
            display: false,
          },
          position: 'bottom',
          stacked: false,
        }
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              return Chart.defaults.plugins.legend.labels.generateLabels(chart)
            },
          }
        },
      }
    }
  },
  markdown_discount_chart: chartData => {
    const data = chartData.discounts
    const maxes = data.map(() => 1)
    const labels = chartData.weeks
    return {
      labels,
      data: [
        {
          label: 'Discounts',
          data: data,
          radius: 0,
          yAxisID: 'A',
          borderColor: '#FF9800',
          fill: false,
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Max',
          data: maxes,
          radius: 0,
          yAxisID: 'A',
          fill: false,
          backgroundColor: '#FFFFFF',
        }
      ],
      type: 'line',
      lineToday: false,
      week: true,
      daysCover: 0,
      scales: {
        'A': {
          title: {
            display: true,
            text: '',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom',
        }
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              return Chart.defaults.plugins.legend.labels.generateLabels(chart)
            },
          }
        },
      }
    }
  },
  prediction_graph_stores_order: data => {
    const points = []

    // sorting data by potential from hight to low
    data.stores_graph['Potential'].forEach((x, i) =>
      points.push({
        potential: x,
        sales: data.stores_graph['Sales LY'][i],
        storeKey: data.stores_graph.store_keys[i]
      })
    )

    points.sort((a, b) => a.potential - b.potential).reverse()

    const chartData = {
      labels: points.map(x => x.storeKey),
      datasets: [
        {
          label: 'Potential',
          backgroundColor: 'rgba(144, 202, 249, 0.5)',
          borderWidth: 2,
          data: points.map(x => x.potential),
          xAxisID: 'bar-x-axis1',
          stack: 'background',
          yAxisID: 'y-axis1'
        },
        {
          label: 'Sales LY',
          borderColor: 'RGBA(0,122,255,1)',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderWidth: 2,
          data: points.map(x => x.sales),
          xAxisID: 'bar-x-axis2',
          yAxisID: 'y-axis1'
        }
      ]
    }

    return [chartData, optionsExpectedChartsOrder]
  },
  stock_graph_order: data => {
    data = data.stock_graph
    const reqmin = data['Required min']
    const reqmax = data['Required max']
    const projection = data['Projection']
    const projectionBlank = data['Projection_blank']
    // const projectionOptimal = data['Projection Optimal']
    // const dcMin = data['DC required min']
    // const dcMax = data['DC required max']
    const labels = data['weeks']
    return {
      labels,
      data: [
        {
          label: 'Projection',
          data: projection,
          radius: 0,
          yAxisID: 'A',
          fill: false,
          borderColor: '#007AFF',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Blank',
          data: projectionBlank,
          radius: 0,
          yAxisID: 'A',
          fill: false,
          borderDash: [10, 5],
          borderColor: '#90CAF9',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Required Min',
          data: reqmin,
          radius: 0,
          yAxisID: 'A',
          fill: false,
          borderColor: '#4CAF50',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Required Max',
          data: reqmax,
          radius: 0,
          yAxisID: 'A',
          fill: false,
          borderColor: '#FF9800',
          backgroundColor: '#FFFFFF',
        }
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      week: true,
      scales: {
        'A': {
          title: {
            display: true,
            text: '',
          },
          min: 0,
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom',
        }
      },
    }
  },
  buy_chart_carousel: data => {
    const labels = data.map(item => {
      item = item.week.toString()
      const year = item.substring(0, 4)
      const week = item.substring(4, 6)
      return year + '-W' + week
    })
    return {
      labels,
      data: [
        {
          label: 'Budget',
          data: data.map(item => item['Budget']),
          radius: 0,
          borderDash: [10, 5],
          yAxisID: 'A',
          fill: false,
          borderColor: '#00000099',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Ordered',
          data: data.map(item => item['Ordered']),
          radius: 0,
          yAxisID: 'A',
          fill: false,
          borderColor: '#4CAF50',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Ordered + Progress',
          data: data.map(item => item['Ordered + Progress']),
          radius: 0,
          yAxisID: 'A',
          fill: false,
          borderColor: '#007AFF',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Ordered + Progress + To order',
          data: data.map(item => item['Ordered + Progress + To order']),
          radius: 0,
          yAxisID: 'A',
          fill: false,
          borderColor: 'rgb(255, 210, 2)',
          backgroundColor: '#FFFFFF',
        },
      ],
      type: 'line',
      lineToday: true,
      week: true,
      daysCover: 'z' + format(new Date(), 'yyyy-\'W\'ww'),
      scales: {
        'A': {
          title: {
            display: true,
            text: '',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          grid: {
            display: false,
          },
          // offset: true,
          position: 'bottom',
        }
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)
              // avg labels
              labels[0].lineDash = [5, 5]

              return labels
            },
          }
        },
      }
    }
  },
  order_scenarios_graph: data => {
    data = data.scenario_graph
    const reqmin = data['Required min']
    const reqmax = data['Required max']
    const projection = data['Projection']
    const labels = data['weeks']
    return {
      labels,
      data: [
        {
          label: 'Projection',
          data: projection,
          radius: 0,
          yAxisID: 'A',
          fill: false,
          borderColor: '#007AFF',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Required Min',
          data: reqmin,
          radius: 0,
          yAxisID: 'A',
          fill: false,
          borderColor: '#4CAF50',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Required Max',
          data: reqmax,
          radius: 0,
          yAxisID: 'A',
          fill: false,
          borderColor: '#FF9800',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Projection LB',
          data: data['Projection_lb'],
          radius: 0,
          borderDash: [10, 5],
          yAxisID: 'A',
          fill: false,
          borderColor: '#4CAF50',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Projection UB',

          borderDash: [10, 5],
          data: data['Projection_ub'],
          radius: 0,
          yAxisID: 'A',
          fill: false,
          borderColor: '#FF9800',
          backgroundColor: '#FFFFFF',
        }
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      week: true,
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)
              // avg labels
              labels[3].lineDash = [5, 5]
              labels[4].lineDash = [5, 5]

              return labels
            },
          }
        },
      },
      scales: {
        'A': {
          title: {
            display: true,
            text: '',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          ticks: {
            padding: 16,
          },
          border: {
            display: false,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom',
        }
      },
    }
  },
  shop_item_lookalike: data => {
    const belief = data['Belief']
    const salesNet = data['Sales']
    const stock = data['Stock']
    const labels = data['weeks']
    return {
      labels,
      data: [
        {
          label: 'Stock',
          fill: false,
          data: stock,
          radius: 0,
          yAxisID: 'A',
          borderColor: '#4da3f3',
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Sales',
          data: salesNet,
          radius: 0,
          yAxisID: 'B',
          fill: false,
          borderColor: '#4CAF50',
          backgroundColor: 'transparent',
        },
        {
          label: 'Sales + Missed',
          data: data['Sales + Missed'],
          yAxisID: 'B',
          radius: 0,
          borderColor: '#4CAF50',
          backgroundColor: '#FFFFFF',
          fill: false,
          borderDash: [10, 5],
        },
        {
          label: 'Belief',
          data: belief,
          radius: 0,
          yAxisID: 'B',
          fill: false,
          borderColor: 'orange',
          backgroundColor: '#FFFFFF',
        }
      ],
      type: 'line',
      week: true,
      daysCover: 0,
      scales: {
        'A': {
          title: {
            display: true,
            text: 'Stock',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'B': {
          title: {
            display: true,
            text: 'Sales',
          },
          position: 'right',
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom',
        }
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)
              // avg labels
              labels[2].lineDash = [5, 5]
              // labels[1].lineDash = [5, 5]

              return labels
            },
          }
        },
      }
    }
  },
  shop_item_reorder: data => {
    const stock = data['stock']
    const labels = (data['week_number'] || []).map(item => {
      item = item.toString()
      const year = item.substring(0, 4)
      const week = item.substring(4, 6)
      return year + '-W' + week
    })
    return {
      labels,
      data: [
        {
          label: 'Projection',
          data: stock,
          radius: 0,
          yAxisID: 'A',
          fill: false,
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Required min',
          data: data.requirement_min,
          borderDash: [10, 5],
          borderColor: '#4CAF50',
          yAxisID: 'A',
          fill: false,
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Required max',
          data: data.requirement_max,
          borderDash: [10, 5],
          borderColor: '#EE3766',
          yAxisID: 'A',
          fill: false,
          backgroundColor: '#FFFFFF',
        },
        {
          label: 'Presentation',
          data: data.presentation,
          borderDash: [10, 5],
          borderColor: '#00000033',
          yAxisID: 'A',
          fill: false,
          backgroundColor: '#FFFFFF',
        },
      ],
      type: 'line',
      lineToday: true,
      week: true,
      daysCover: 'z' + format(addDays(new Date(), data.leadtime), 'yyyy-\'W\'ww'),
      scales: {
        'A': {
          title: {
            display: true,
            text: '',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          grid: {
            display: false,
          },
          // offset: true,
          position: 'bottom',
        }
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: (chart) => {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)
              labels[1].lineDash = [5, 5]
              labels[2].lineDash = [5, 5]
              labels[3].lineDash = [5, 5]

              return labels
            },
          }
        },
      }
    }
  },
  transfers_profit_chart: data => {
    const accepted = data.data['accepted']
    const proposal = data.data['proposal']
    const labels = data.data['x_label'] || []

    const ctx = document.getElementById('transfers_profit_chart').getContext('2d')

    const proposalGradient = ctx.createLinearGradient(0, 0, 0, 400)
    proposalGradient.addColorStop(1, 'RGBA(0,122,255,0)')
    proposalGradient.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
    proposalGradient.addColorStop(0, 'RGBA(0,122,255,0.15)')

    return {
      labels,
      data: [
        {
          label: 'Accepted',
          data: accepted,
          radius: 0,
          yAxisID: 'A',
          borderDash: [10, 5],
          borderColor: '#4CAF50',
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'Proposal',
          data: proposal,
          radius: 0,
          yAxisID: 'A',
          borderColor: '#007AFF',
          backgroundColor: proposalGradient,
        },
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      week: true,
      scales: {
        'A': {
          title: {
            display: true,
            text: 'Profit',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            maxTicksLimit: 5,
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom',
        }
      }
    }
  },
  transfers_pieces_chart: data => {
    const accepted = data.data['accepted']
    const proposal = data.data['proposal']
    const labels = data.data['x_label'] || []

    const ctx = document.getElementById('transfers_pieces_chart').getContext('2d')

    const proposalGradient = ctx.createLinearGradient(0, 0, 0, 400)
    proposalGradient.addColorStop(1, 'RGBA(0,122,255,0)')
    proposalGradient.addColorStop(0.5, 'RGBA(0,122,255,0.02)')
    proposalGradient.addColorStop(0, 'RGBA(0,122,255,0.15)')

    return {
      labels,
      data: [
        {
          label: 'Accepted',
          data: accepted,
          radius: 0,
          yAxisID: 'A',
          borderDash: [10, 5],
          borderColor: '#4CAF50',
          backgroundColor: '#FFFFFF'
        },
        {
          label: 'Proposal',
          data: proposal,
          radius: 0,
          yAxisID: 'A',
          borderColor: '#007AFF',
          backgroundColor: proposalGradient,
        },
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      week: true,
      scales: {
        'A': {
          title: {
            display: true,
            text: 'Pieces',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            maxTicksLimit: 5,
            padding: 16,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom',
        }
      }
    }
  },
  option_value_graph: data => {
    const formatedLabels = data.valueDates.map(date => `${format(new Date(date), 'dd MMM')}`)
    const todayPriceIndex = data.valueDates.indexOf(format(new Date(), 'yyyy-MM-dd'))
    const fakePrices = [...new Array(data.valuePrice.length).fill(0)]
    const pointsRadius = [...new Array(data.valuePrice.length).fill(0)]

    fakePrices[todayPriceIndex] = data.price
    pointsRadius[todayPriceIndex] = 5

    return {
      labels: formatedLabels,
      data: [
        {
          label: 'Value',
          data: data.valuePercentage,
          radius: 0,
          yAxisID: 'A',
          borderColor: '#007aff',
          backgroundColor: '#FFF',
        },
        {
          label: 'Actual price',
          data: fakePrices,
          radius: 0,
          yAxisID: 'B',
          borderColor: '#FF9800',
          backgroundColor: '#FFF',
          showLine: false,
          pointRadius: pointsRadius,
        },
      ],
      type: 'line',
      lineToday: true,
      daysCover: 0,
      week: false,
      scales: {
        'A': {
          title: {
            display: true,
            text: 'Value',
          },
          position: 'left',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
          min: 0,
          max: 1,
          ticks: {
            callback: (label) => {
              return label * 100 + '%'
            },
            padding: 16,
          },
        },
        'B': {
          title: {
            display: true,
            text: 'Price',
          },
          position: 'right',
          min: 0,
          max: data.originalPrice,
          ticks: {
            callback: (label) => {
              return data.currency.symbol + label
            }
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        'X': {
          title: {
            display: true,
            text: '',
          },
          // offset: true,
          position: 'bottom',
        }
      },
    }
  },
  strategy_cube_home_chart: chartData => {
    const data = chartData.data
    const labels = chartData.labels || []
    return strategy_cube_home_chart(data, labels, chartData.dimension, chartData.metric)
  },
}

const strategy_cube_home_chart = (data, labels, dimension, metric) => {
  labels = (labels || [])
  let labelBdg = `${metric} (bdg)`
  let labelRef = `${metric} (ref)`
  let labelIdx = `${metric} (idx)`
  const bdg = data.map(d => d[labelBdg] || 0)
  const ref = data.map(d => d[labelRef] || 0)
  const idx = data.map(d => d[labelIdx] > 200 ? 200 : d[labelIdx] || 0)

  // capitalize first letters
  labelBdg = labelBdg.charAt(0).toUpperCase() + labelBdg.slice(1)
  labelRef = labelRef.charAt(0).toUpperCase() + labelRef.slice(1)
  labelIdx = labelIdx.charAt(0).toUpperCase() + labelIdx.slice(1)
  const axisLabel = metric?.charAt(0).toUpperCase() + metric?.slice(1)
  return {
    labels,
    data: [
      {
        label: labelIdx,
        data: idx,
        radius: 4,
        yAxisID: 'B',
        // Blue for revenue, green for sales
        borderColor: '#085D70',
        fill: true,
        showLine: false,
        pointRadius: 4,
        type: 'bubble',
        backgroundColor: '#085D70',
      },
      {
        label: labelRef,
        data: ref,
        radius: 0,
        yAxisID: 'A',
        // Blue for revenue, green for sales
        borderColor: '#4CAF50',
        fill: false,
        backgroundColor: 'transparent',
        grouped: false,
      },
      {
        label: labelBdg,
        data: bdg,
        radius: 0,
        yAxisID: 'A',
        // Blue for revenue, green for sales
        borderColor: '#FF9800',
        fill: dimension !== 'week_numbers',
        backgroundColor: '#FF9800',
      },
    ],
    type: dimension === 'week_numbers' ? 'line' : 'bar',
    lineToday: dimension === 'week_numbers',
    week: true,
    daysCover: 0,
    scales: {
      'A': {
        title: {
          display: true,
          text: axisLabel,
        },
        min: 0,
        position: 'left',
        grid: {
          drawTicks: false,
        },
        ticks: {
          padding: 16,
        },
      },
      'B': {
        title: {
          display: true,
          text: 'Index',
        },
        position: 'right',
        grid: {
          display: false,
        },
        min: 0,
        max: 200,
      },
      'X': {
        stacked: true,
        title: {
          display: true,
          text: '',
        },
        position: 'bottom',
        ticks: {
          callback: function (label) {
            let val = this.getLabelForValue(label)
            if(typeof val !== 'string'){
              val = val.toString()
            }
            return val?.substring(0, 15)
          },
        },
      }
    },
    plugins: {
      legend: {
        labels: {
          useLineStyle: true,
          generateLabels: (chart) => {
            const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)
            return labels.map(label => {
              return {
                ...label,
              }
            })
          },
        }
      },
    }
  }
}
