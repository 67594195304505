<template>
  <div class="alert-name-cell">
    <div class="option">
      <span
        v-if="nameParts.length === 2"
        class="option-name"
      >
        <p class="font-weight-medium">{{ nameParts[0] }}</p>
        <p class="option-subtitle">
          {{ nameParts[1] }}
        </p>
      </span>
      <span v-else>{{ option.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {}
  },
  computed: {
    nameParts () {
      return this.option.name.split(' / ')
    }
  },
}
</script>

<style lang="scss">
    .alert-name-cell {
        font-size: 13px !important;
        letter-spacing: 0.2px;
        p {
            margin: 0;
        }
    }
</style>
