class APIBaseURLs {
  devMode = localStorage.getItem('devMode') === 'true'
  manageAPI = import.meta.env.VITE_API_MANAGE_URL
  logicAPI = import.meta.env.VITE_API_LOGIC_BASE_URL
  tsqlAPI = import.meta.env.VITE_API_TSQL_URL
  storepageAPI = import.meta.env.VITE_API_STOREPAGE
  constructor () {
    this._setURLs()
  }

  /**
   * Set the dev mode, which will change the base URLs
   * @param {boolean} isDev
   */
  setDevMode (isDev = true) {
    this.devMode = isDev
    this._setURLs(isDev)
  }

  _setURLs () {
    if (this.devMode) {
      this._setDevBaseURLs()
    } else {
      this._setLiveBaseURLs()
    }
  }

  _setLiveBaseURLs () {
    this.manageAPI = import.meta.env.VITE_API_MANAGE_URL
    this.logicAPI = import.meta.env.VITE_API_LOGIC_BASE_URL
    this.tsqlAPI = import.meta.env.VITE_API_TSQL_URL
    this.storepageAPI = import.meta.env.VITE_API_STOREPAGE
  }

  _setDevBaseURLs () {
    this.manageAPI = import.meta.env.VITE_API_MANAGE_URL_DEV
    this.logicAPI = import.meta.env.VITE_API_LOGIC_BASE_URL_DEV
    this.tsqlAPI = import.meta.env.VITE_API_TSQL_URL_DEV
    this.storepageAPI = import.meta.env.VITE_API_STOREPAGE_DEV
  }
}

const baseURLs = new APIBaseURLs()
export default baseURLs
