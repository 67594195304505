<template>
  <div class="operate-page mb-12">
    <h3 class="mb-6 mt-6">Operate</h3>

    <v-card>
      <v-tabs v-model="activeTab" show-arrows>
        <v-tabs-slider />
        <v-tab class="px-8">Criteria</v-tab>
        <v-tab class="px-8">Import</v-tab>
        <v-tab class="px-8">Option sets</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item eager :transition="false" :reverse-transition="false">
          <div class="mt-4 mx-6">
            <SearchFilters
              title="1. Search"
              :selected-filters="operateStore.filtersTab.selectedFilters"
              ref="searchFilters"
              @filterAdded="handleFilterAdded"
              @filtersChanged="handleFilterChanged"
              @filterRemoved="handleFilterRemoved"
            />
          </div>

          <div class="mx-6">
            <SearchProperties
              :properties-include="operateStore.filtersTab.propertiesInclude"
              :properties-exclude="operateStore.filtersTab.propertiesExclude"
              @propIncludeAdded="handlePropIncludeAdded"
              @propExcludeAdded="handlePropExcludeAdded"
              @propIncludeChanged="handlePropIncludeChanged"
              @propExcludeChanged="handlePropExcludeChanged"
              @propIncludeDeleted="handlePropIncludeDeleted"
              @propExcludeDeleted="handlePropExcludeDeleted"
              @propIncludeValuesChanged="handlePropIncludeValuesChanged"
              @propExcludeValuesChanged="handlePropExcludeValuesChanged"
            />
          </div>

          <div class="mx-6">
            <SearchText
              :search-string="operateStore.filtersTab.searchText"
              @change="handleSearchTextChanged"
            />
          </div>

          <div class="text-right">
            <v-btn
              class="mt-6 mr-6"
              color="primary"
              depressed
              outlined
              @click="handleSearchClick"
            >Search</v-btn>
          </div>

          <template v-if="operateStore.filtersTab.showSearchResults">
            <v-divider class="mt-10" />
            <div class="mt-8">
              <SearchResults
                tab="filters"
                :options-route="optionsRoute"
              />
            </div>
          </template>
          <template v-if="operateStore.searchResultsSelected.length">
            <v-divider class="mt-10" />
            <div class="mt-8">
              <SelectedResults
                tab="filters"
                :options-route="optionsRoute"
              />
            </div>
          </template>
          <template v-if="operateStore.searchResultsSelected.length">
            <v-divider class="mt-10" />
            <div class="mt-8">
              <PricingItemsOperations
                v-if="isPricing"
                tab="filters"
              />
              <StockItemsOperations
                v-else
                tab="filters"
              />
            </div>
          </template>
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false">
          <div class="mt-6">
            <OptionsFileSearch/>
          </div>
          <div class="mt-6">
            <OptionsSearchOperations
              v-if="hasParsedResults"
              @search-selected="setSearchSelected"
            />
          </div>
          <template v-if="operateStore.fileTab.showSearchResults && searchSelected">
            <v-divider class="mt-10" />
            <div class="mt-8">
              <SearchResults tab="file" />
            </div>
          </template>
          <template v-if="operateStore.searchResultsSelected.length && searchSelected">
            <v-divider class="mt-10" />
            <div class="mt-8">
              <SelectedResults tab="file" />
            </div>
          </template>
          <template v-if="operateStore.searchResultsSelected.length && searchSelected">
            <v-divider class="mt-10" />
            <div class="mt-8">
              <PricingItemsOperations v-if="isPricing" tab="file" />
              <StockItemsOperations v-else tab="file" />
            </div>
          </template>
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false">
          <div class="mt-6">
            <OptionSetsSearch />
          </div>

          <div class="mx-6 my-6">
            <v-btn
              color="primary"
              depressed
              outlined
              :disabled="optionSetsSearchDisabled"
              @click="handleOptionSetsSearchClick"
            >Search</v-btn>
          </div>

          <template v-if="operateStore.optionSetsTab.showSearchResults">
            <v-divider class="mt-10" />
            <div class="mt-8">
              <SearchResults tab="optionSets" />
            </div>
          </template>
          <template v-if="operateStore.searchResultsSelected.length">
            <v-divider class="mt-10" />
            <div class="mt-8">
              <SelectedResults tab="optionSets" />
            </div>
          </template>
          <template v-if="operateStore.searchResultsSelected.length">
            <v-divider class="mt-10" />
            <div class="mt-8">
              <PricingItemsOperations v-if="isPricing" tab="optionSets" />
              <StockItemsOperations v-else tab="optionSets" />
            </div>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import SearchFilters from '@/components/Operate/SearchFilters.vue'
import SearchProperties from '@/components/OptionSets/SearchProperties.vue'
import SearchText from '@/components/OptionSets/SearchText.vue'
import OptionsFileSearch from '@/components/Operate/OptionsFileSearch.vue'
import OptionSetsSearch from '@/components/Operate/OptionSetsSearch.vue'
import SearchResults from '@/components/Operate/SearchResults.vue'
import SelectedResults from '@/components/Operate/SelectedResults.vue'
import PricingItemsOperations from '@/components/Operate/PricingItemsOperations.vue'
import StockItemsOperations from '@/components/Operate/StockItemsOperations.vue'
import { optionsFiltersToFormParameters } from '@/utils'
import _ from 'lodash'
import { usePropertiesStore } from '@/store/pinia/propertiesStore'
import { useStoreAndOptionSetsStore } from '@/store/pinia/useStoreAndOptionSetsStore'

import { useStockOperateStore } from '@/store/pinia/stockOperateStore'
import { useRulesStore } from '@/store/pinia/rulesStore'
import OptionsSearchOperations from '@/components/Operate/OptionsSearchOperations.vue'
import { useGeneralStore } from '@/store/pinia/generalStore'

export default {
  name: 'OperatePage',
  setup () {
    return {
      generalStore: useGeneralStore(),
      operateStore: useStockOperateStore(),
      rulesStore: useRulesStore()
    }
  },
  data () {
    return {
      activeTab: 'criteria',
      searchSelected: true,
      searchDebounce: _.debounce(this.searchByFilters, 1000),
    }
  },
  components: {
    OptionsSearchOperations,
    SearchFilters,
    SearchProperties,
    SearchText,
    OptionsFileSearch,
    OptionSetsSearch,
    SearchResults,
    SelectedResults,
    PricingItemsOperations,
    StockItemsOperations,
  },
  computed: {
    optionsRoute () {
      return this.isPricing ? 'pricing/markdown' : 'stock/option'
    },
    isPricing () {
      return this.generalStore.currentModule === 'pricing'
    },
    optionSetsSearchDisabled () {
      return !this.operateStore.optionSetsTab.selectedOptionSetsKey.length
    },
    hasParsedResults () {
      return !!this.operateStore.fileTab.parsedResults?.length
    }
  },
  methods: {
    setSearchSelected (value) {
      this.searchSelected = value
    },
    getNonEmptyProperties (properties) {
      const result = Object.assign({}, properties)

      Object.keys(result).forEach(prop => {
        if (!result[prop].length) {
          delete result[prop]
        }
      })

      return result
    },
    searchByFilters () {
      if (!this.$refs.searchFilters.validateFilters()) {
        return
      }

      const payload = {
        ...optionsFiltersToFormParameters(this.operateStore.filtersTab.selectedFilters),
        properties_include: this.getNonEmptyProperties(this.operateStore.filtersTab.propertiesInclude),
        properties_exclude: this.getNonEmptyProperties(this.operateStore.filtersTab.propertiesExclude),
        search_text: this.operateStore.filtersTab.searchText || null,
      }

      this.operateStore.findOptionsByFilters(payload)
    },
    handleOptionSetsSearchClick () {
      this.operateStore.findOptionsByOptionSetKey(this.operateStore.optionSetsTab.selectedOptionSetsKey[0])
    },
    handlePropIncludeAdded (prop) {
      this.operateStore.addPropertyInclude(prop)
      this.searchDebounce()
    },
    handlePropExcludeAdded (prop) {
      this.operateStore.addPropertyExclude(prop)
      this.searchDebounce()
    },
    handlePropIncludeChanged (propData) {
      this.operateStore.changePropertyInclude(propData.oldProp, propData.newProp)
      this.searchDebounce()
    },
    handlePropExcludeChanged (oldAndNew) {
      this.operateStore.changePropertyExclude(oldAndNew.oldProp, oldAndNew.newProp)
      this.searchDebounce()
    },
    handlePropIncludeDeleted (prop) {
      this.operateStore.deletePropertyInclude(prop)
      this.searchDebounce()
    },
    handlePropExcludeDeleted (prop) {
      this.operateStore.deletePropertyExclude(prop)
      this.searchDebounce()
    },
    handlePropIncludeValuesChanged (propData) {
      this.operateStore.setPropertyIncludeValue(propData.prop, propData.value)
      this.searchDebounce()
    },
    handlePropExcludeValuesChanged (propData) {
      this.operateStore.setPropertyExcludeValue(propData.prop, propData.value)
      this.searchDebounce()
    },
    handleFilterAdded (newFilter) {
      this.operateStore.addSelectedFilter(newFilter)
      this.searchDebounce()
    },
    handleFilterChanged () {
      this.searchDebounce()
    },
    handleFilterRemoved (newFilter) {
      this.operateStore.removeSelectedFilter(newFilter)
      this.searchDebounce()
    },
    handleSearchTextChanged (text) {
      this.operateStore.setSearchText(text)
      this.searchDebounce()
    },
    handleSearchClick () {
      this.searchByFilters()
    }
  },
  created () {
    usePropertiesStore().loadSKUOptionsProperties().catch(console.error)
    useStoreAndOptionSetsStore().loadOptionSets().catch(console.error)
    this.operateStore.loadOperations()
    this.operateStore.loadImportOperations()
    this.rulesStore.setRules()
  },
  mounted () {
    this.searchDebounce()
  },
}
</script>

<style lang="scss">
.operate-page {
  .v-tab {
    width: 267px;
  }
  .criteria-search-btn {
    margin-top: 40px;
    width: 145px;
  }
  .option-sets-search-filters {
    width: 550px;
  }
}
</style>
